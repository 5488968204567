export enum District {
  // eslint-disable-next-line no-unused-vars
  NYC = 'NYC',
  // eslint-disable-next-line no-unused-vars
  LANSING = 'Lansing',
  // eslint-disable-next-line no-unused-vars
  SCHENECTADY = 'Schenectady',
  // eslint-disable-next-line no-unused-vars
  UNIONDALE = 'Uniondale',
  // eslint-disable-next-line no-unused-vars
  GO = 'GreatOaks',
  // eslint-disable-next-line no-unused-vars
  BROOME = 'BroomeStreet',
}
export type TDistricts = District.NYC
  | District.LANSING
  | District.SCHENECTADY
  | District.UNIONDALE
  | District.GO
  | District.BROOME
  | 'all';
