import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SchoolTwoColumnLayoutModule } from '../../../ng2/school/layouts/school-two-column-layout/school-two-column-layout.module';
import { AdminLeftNavModule } from '../admin-left-nav/admin-left-nav.module';
import { AdminLayoutComponent } from './admin-layout.component';
@NgModule({
  declarations: [AdminLayoutComponent],
  imports: [
    CommonModule,
    RouterModule,
    SchoolTwoColumnLayoutModule,
    AdminLeftNavModule,
  ],
  exports: [
    AdminLayoutComponent,
  ],
  providers: [],
})
export class AdminLayoutModule {}
