import { Subject } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { BackgroundJob } from 'Src/ng2/shared/services/background-job/background-job.service';
import { BatchActionOriginOptions, TBatchActionsOrigin } from 'Src/ng2/shared/components/nv-actions/nv-actions.interface';

export class BatchActionsEffectsUtilities {
  static getJobSubject (backgroundJob: BackgroundJob, { response, jobId }: { response: HttpResponse<any>, jobId?: string }): Subject<any> {
    const _jobId = jobId || response.headers.get('nv-background-jobs');
    const subject = (backgroundJob as any)(_jobId).subject();
    return subject;
  }

  static getJobSubjectFromGraphql (
    backgroundJob: BackgroundJob,
    { response, queryName },
  ): Subject<any> {
    const backgroundJobId = response.data[queryName].backgroundJobId;
    const subject = (backgroundJob as any)(backgroundJobId).subject();
    return subject;
  }

  static sendSnack (
    snackBarService,
    { success, origin }: { success: true | false; origin?: TBatchActionsOrigin },
  ): void {
    const delayToast = origin && origin === BatchActionOriginOptions.DATA_GRID;
    if (!delayToast) {
      snackBarService.showBatchActionToast({ success: !!success });
    }
  }
}
