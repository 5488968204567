import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ISidebarItem } from '../../../nvps-libraries/design/nv-sidebar-list/nv-sidebar.interface';
import { Router } from '@angular/router';
import { IUser } from 'Src/ng2/shared/typings/interfaces/user.interface';
import { SessionStorageService } from 'Src/ng2/shared/services/web-storage/session-storage/session-storage.service';
import { UrlPathService } from 'Src/ng2/shared/services/url-path-service/url-path.service';

const ADMIN_RELATIVE_ROUTE_LINKS = {
  Notifications: 'notifications',
};

/* eslint-disable no-unused-vars */
export enum AdminFeatures {
  Notifications = 'Notifications',
}

interface IPermissions {
  canManageNotifications?: boolean;
}

@Component({
  selector: 'admin-left-nav',
  templateUrl: './admin-left-nav.component.html',
  styleUrls: ['./admin-left-nav.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AdminLeftNavComponent implements OnInit {
  currentUser: IUser;
  public adminFeaturesAccess: string[] = [];
  public routeOptions: ISidebarItem[];
  public selectedSidebarItem: string;

  constructor (
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private urlPathService: UrlPathService,
  ) {}

  ngOnInit () {
    const permissions: IPermissions = {};
    const canManageNotifications = this.sessionStorageService.getItem('districts').find(({ permissions }) => permissions?.canManageNotifications).permissions.canManageNotifications;
    permissions.canManageNotifications = canManageNotifications;
    this.setSidenavOptions(permissions);
    this.routeOptions = this.getRouteOptions();
    this.selectedSidebarItem = this.routeOptions[0].key;
  }

  /**
   * Will determine which routes to show based on the user's permissions
   */
  public getRouteOptions (): ISidebarItem[] {
    const formattedRoutesOptions = this.adminFeaturesAccess.map(routeOption => {
      return {
        key: routeOption,
        human: routeOption,
      };
    });
    return formattedRoutesOptions;
  }

  public goToPicker () {
    this.router.navigate(['/district-picker']);
  }

  public changeView (route: string): void {
    const url = this.urlPathService.computeDistrictUrlPath(`admin/${ADMIN_RELATIVE_ROUTE_LINKS[route]}`);
    this.selectedSidebarItem = route;
    this.router.navigate([url]);
  }

  private setSidenavOptions (permissions: IPermissions): void {
    if (permissions?.canManageNotifications) this.adminFeaturesAccess.push(AdminFeatures.Notifications);
  }
}
