import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CreditGapsFreeformCourseModal } from './credit-gap-freeform-course-modal.component';
import { UtilitiesService } from 'Src/ng2/shared/services/utilities/utilities.service';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { ImGapPlan } from './../../services/im-models/im-gap-plan';
import { ImSchool } from './../../services/im-models/im-school';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
  imports: [
    MatDialogModule,
    MatInputModule,
    MatSelectModule,
    CommonModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatButtonModule,
  ],
  declarations: [CreditGapsFreeformCourseModal],
  providers: [
    ImSchool,
    UtilitiesService,
    ImGapPlan,
  ],
})
export class CreditGapFreeformModalModule { }
