<input
  #input
  type="number"
  [placeholder]="placeholder"
  [formControl]="numControl"
  class="{{ icon !== null ? 'left-icon' : '' }} {{ hasClearBtn ? 'right-icon' : '' }} {{ isTextValid ? '' : 'is-invalid' }}"
  (focus)="onFocus($event)"
  (blur)="onBlur($event)"
/>

<nv-icon *ngIf="icon !== null" class="left" name="{{ icon }}" [color]="isTextValid ? 'blue' : 'red'"></nv-icon>
