import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { ScheduledCoursePlanComponent } from './scheduled-course-plan.component';
import { AgGridModule } from '@ag-grid-community/angular';
import { NvDesignLibraryModule } from 'Src/nvps-libraries/design/nv-design-library.module';
import { ImStudentCreditGaps } from 'Src/ng2/shared/services/im-models/im-student-credit-gaps/im-student-credit-gaps';
import { UtilitiesService } from 'Src/ng2/shared/services/utilities/utilities.service';
import { ModalsService } from 'Src/ng2/shared/modals/modals.service';
import { ImSchool } from 'Src/ng2/shared/services/im-models/im-school';
import { ImGapPlan } from 'Src/ng2/shared/services/im-models/im-gap-plan';
import { ImCourseDiff } from 'Src/ng2/shared/services/im-models/im-course-diff';
import { RadioButtonRendererModule } from '../../server-side-grid/radio-button-renderer/radio-button-renderer.module';
import { NoRowsComponentModule } from '../../server-side-grid/no-rows/no-rows.module';

@NgModule({
  declarations: [ScheduledCoursePlanComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule,
    AgGridModule,
    NvDesignLibraryModule,
    RadioButtonRendererModule,
    NoRowsComponentModule,
  ],
  exports: [ScheduledCoursePlanComponent],
  providers: [
    ImStudentCreditGaps,
    UtilitiesService,
    ModalsService,
    ImSchool,
    ImGapPlan,
    ImCourseDiff,
  ],
})

export class ScheduledCoursePlanModule { }
