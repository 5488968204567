import { Injectable } from '@angular/core';

@Injectable()
export class NotificationMessageParser {
  constructor () {}

  /**
   * Notification message from DB may contain anchor tag w/ href (pointing to targeted feature)
   * If so, we need to dynamically create the url with the proper dbn
   *
   * IMPORTANT!: For this to work correctly, the message href should be wrapped in { }
   * Also note the leading slash ( before credit-gaps/next-term-planning ) in the example below
   *
   * Example message:
   * `
   *   New Feature!
   *   <a href='{/credit-gaps/next-term-planning}'>See how many students</a>
   *   are planned for each course in the next term.
   * `
   * (JJ)
   */
  parseMessage (notificationMsg: string): string {
    return notificationMsg;
  }
}
