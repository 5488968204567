<div class="ani-wrapper" @expandInAni [@collapseOutAni]="aniState" (@collapseOutAni.done)="emitCollapseOutDone()">
  <ng-container *ngIf="dataSource$.value.length; else emptylist">
    <list class="student-level student-level-sticky-header">
      <section class="section-list-header section-sticky-header">
        <listhead class="s1" [ngClass]="{ 'batch-edit-mode': batchActionsMode$ | async, shadow: showShadow$ | async }">
          <cell>
            <div *ngIf="(batchActionsMode$ | async)">
              <mat-checkbox
                (click)="emitBatchActionSectionIds()"
                [checked]="sectionHeaderIsChecked"
                [indeterminate]="sectionHeaderIsInd"
                class="nv-mat-checkbox"
                disableRipple
              ></mat-checkbox>
            </div>
          </cell>
          <ng-container *ngFor="let header of displayedHeaders; first as isFirst">
            <ng-container *ngIf="isFirst">
              <cell class="first" (click)="updateSort(header.key, sortKey$, sortDirection$)">
                <span
                  class="group-header"
                  (mouseenter)="checkHeaderOverflow('group-header')"
                  (mouseleave)="resetHeaderOverflow()"
                  nvTooltip
                  [tooltipData]="headerOverflow ? header.tooltip || header.human : header.tooltip"
                >
                  {{ header.human }}
                </span>
                <ng-container *ngIf="!allowEmptyTable">
                  <pill class="invert">{{ (dataSource$ | async).length }}</pill>
                  <span
                    class="sort-arrow"
                    [ngClass]="{
                      'fixed-up': sortKey$.value === header.key && sortDirection$.value === 'asc',
                      'fixed-down': sortKey$.value === header.key && sortDirection$.value === 'desc'
                    }"
                  ></span>
                </ng-container>
              </cell>
            </ng-container>
            <ng-container *ngIf="!isFirst">
              <cell class="center" (click)="updateSort(header.key, sortKey$, sortDirection$)">
                <div class="cell-sleeve">
                  <span></span> <span nvTooltip [tooltipData]="header.tooltip">{{ header.human }} </span>
                  <span
                    class="sort-arrow"
                    [ngClass]="{
                      'fixed-up': sortKey$.value === header.key && sortDirection$.value === 'asc',
                      'fixed-down': sortKey$.value === header.key && sortDirection$.value === 'desc'
                    }"
                  ></span>
                </div>
              </cell>
            </ng-container>
          </ng-container>
        </listhead>
      </section>
      <cdk-virtual-scroll-viewport itemSize="50" minBufferPx="100" maxBufferPx="120" class="vscroll-viewport" autosize>
        <div>
          <ng-container *cdkVirtualFor="let dataRow of (dataSource$ | async); templateCacheSize: 0">
            <row class="empty-row" *ngIf="allowEmptyTable">
              <cell class="empty-cell">
                <div class="cell-sleeve">
                  <span>{{ noDataMessage }}</span>
                </div>
              </cell>
            </row>
            <row
              *ngIf="!allowEmptyTable"
              class="s1 vscroll-item"
              [ngClass]="{ 'batch-edit-mode': batchActionsMode$ | async, 'batch-edit-mode-checked': dataRow.isChecked }"
            >
              <cell>
                <div *ngIf="(batchActionsMode$ | async)">
                  <mat-checkbox
                    (click)="emitBatchActionRowId(dataRow)"
                    [checked]="dataRow.isChecked"
                    class="nv-mat-checkbox"
                    disableRipple
                    [disabled]="dataRow[0].parsedMeta?.nonEditable"
                  ></mat-checkbox>
                </div>
              </cell>

              <cell
                *ngFor="let dataColumn of dataRow; let i = index; first as isFirst"
                class="{{ dataColumn.backgroundStyle }}"
                [ngClass]="{
                  initial: isFirst,
                  center: !isFirst,
                  pending: dataRow[0].sync,
                  'no-click': (isSummerSchoolList && !dataRow[0].click),
                  'batch-edit-mode-disabled-fields': (batchActionsMode$ | async) && !dataColumn.dynamic
                }"
                (click)="emitRowClick(dataRow, dataColumn)"
                nvTooltip [tooltipData]="(isSummerSchoolList && !dataRow[0].click) ? 'Student profile not available for students temporarily attending for summer school' : ''"
              >
              <!-- Dot Icon for logged user -->
              <nv-icon name="dot" *ngIf="isFirst && dataColumn.parsedMeta?.isSelf"></nv-icon> 

              <ng-container *ngIf="dataColumn.dynamic; else nonDynamic">
                  <ng-container *ngIf="!displayedHeaders[i].cellTooltip">
                    <span
                      *ngIf="dataColumn.style !== 'em-dash' && dataColumn.style !== 'row-action'"
                      dynamicComponent
                      [dynamicComponentTrigger]="dynamicComponentTrigger"
                      [dynamicComponentInput]="{
                        row: dataRow,
                        column: dataColumn,
                        dynamicComponentInputData: dynamicComponentInputData
                      }"
                      (dynamicComponentOutput)="emitDynamicComponent($event)"
                      [ngClass]="dataColumn.style"
                    >
                      <span>{{ dataColumn.data }}</span>
                    </span>
                    <span *ngIf="dataColumn.style === 'em-dash'" [ngClass]="dataColumn.style">
                      {{ dataColumn.data }}
                    </span>
                  </ng-container>
                  <span
                    *ngIf="displayedHeaders[i].cellTooltip"
                    [ngClass]="dataColumn.style"
                    dynamicComponent
                    [dynamicComponentTrigger]="dynamicComponentTrigger"
                    [dynamicComponentInput]="{
                      row: dataRow,
                      column: dataColumn,
                      dynamicComponentInputData: dynamicComponentInputData
                    }"
                    (dynamicComponentOutput)="emitDynamicComponent($event)"
                    nvTooltip
                    [tooltipData]="{
                      schoolId: schoolId,
                      calc: displayedHeaders[i].cellTooltip,
                      docId: dataRow[0].meta,
                      contextPartnerId: contextPartnerId,
                      contextPartnerType: contextPartnerType,
                      meta: dataRow[i].meta
                    }"
                  >
                    <span>{{ dataColumn.data }}</span>
                  </span>
                  <ng-container *ngIf="displayedHeaders[i].cellConfig">
                    <!-- TODO: confirm if dynamicComponent is still needed -->
                    <span
                      *ngIf="dataColumn.style !== 'em-dash' && dataColumn.style !== 'row-action'"
                      dynamicComponent
                      [dynamicComponentTrigger]="dynamicComponentTrigger"
                      [dynamicComponentInput]="{
                        row: dataRow,
                        column: dataColumn,
                        dynamicComponentInputData: dynamicComponentInputData
                      }"
                      nvTooltip
                      [tooltipData]="{
                        schoolId: schoolId,
                        contextPartnerId: contextPartnerId,
                        contextPartnerType: contextPartnerType,
                        calc: displayedHeaders[i].cellTooltip,
                        docId: dataRow[0].meta,
                        meta: dataRow[i].meta
                      }"
                      (dynamicComponentOutput)="emitDynamicComponent($event)"
                      [ngClass]="dataColumn.style"
                    >
                      <span>{{ dataColumn.data }}</span>
                    </span>
                    <span *ngIf="dataColumn.style === 'em-dash'" [ngClass]="dataColumn.style">
                      {{ dataColumn.data }}
                    </span>
                    <!-- no value necessary as the span is replaced by the directive -->
                    <span
                      *ngIf="dataColumn.style === 'row-action'"
                      [ngClass]="dataColumn.style"
                      dynamicComponent
                      (dynamicComponentOutput)="emitDynamicComponent($event)"
                      [dynamicComponentTrigger]="dynamicComponentTrigger"
                      [dynamicComponentInput]="{
                        row: dataRow,
                        column: dataColumn,
                        dynamicComponentInputData: dynamicComponentInputData
                      }"
                    ><nv-icon name="dot"></nv-icon>
                    </span>
                  </ng-container>
                </ng-container>
                <ng-template #nonDynamic>
                  <span *ngIf="!displayedHeaders[i].cellTooltip" [ngClass]="dataColumn.style">
                    <span>
                      <nv-icon (click)="openModal(dataRow);" class="nv-icon-wrapper nv-icon-left" 
                      *ngIf="!(batchActionsMode$ | async) && dataColumn.hasCellRenderer"
                      [name]="parseItem(dataColumn.meta).cellRendererParams?.iconName"
                      ></nv-icon> {{ dataColumn.data }}
                    </span>
                  </span>
                  <ng-container *ngIf="displayedHeaders[i].cellTooltip">
                    <span
                      *ngIf="dataColumn.style !== 'em-dash'"
                      nvTooltip
                      [tooltipData]="{
                        schoolId: schoolId,
                        calc: displayedHeaders[i].cellTooltip,
                        docId: dataRow[0].meta,
                        contextPartnerId: contextPartnerId,
                        contextPartnerType: contextPartnerType,
                        meta: dataRow[i].meta
                      }"
                      [ngClass]="dataColumn.style"
                      class="truncate"
                    >
                    <nv-icon (click)="openModal(dataRow);" class="nv-icon-wrapper nv-icon-left" 
                    *ngIf="!(batchActionsMode$ | async) && dataColumn.hasCellRenderer"
                    [name]="parseItem(dataColumn.meta).cellRendererParams?.iconName"
                  ></nv-icon> 
                      {{ dataColumn.data }}
                    </span>
                    <!-- TODO: Leaving this here interferes with support list tooltips. But this is currently used
                      by other lists. Will be discussed with Julia and at Dev Sync-->
                    <span *ngIf="dataColumn.style === 'em-dash'" [ngClass]="dataColumn.style">
                      {{ dataColumn.data }}
                    </span>
                  </ng-container>
                </ng-template>
              </cell>
            </row>
          </ng-container>
        </div>
        <ng-container *ngIf="currentFilterFormValue || (filterFormControl.valueChanges | async)">
          <div class="nv-button-clear"><nv-button (clickButton)="onClear()">Clear search</nv-button></div>
        </ng-container>
      </cdk-virtual-scroll-viewport>
    </list>
  </ng-container>
  <ng-template #emptylist>
    <list class="empty">{{ noDataMessage }}</list>
    <ng-container *ngIf="currentFilterFormValue">
      <div class="nv-button-clear"><nv-button (clickButton)="onClear()">Clear search</nv-button></div>
    </ng-container>
  </ng-template>
</div>
