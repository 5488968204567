import { NvSharedTooltipModule } from 'projects/shared/nvps-libraries/design/nv-tooltip/nv-shared-tooltip.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvSegmentedControlComponent } from './nv-segmented-control.component';

@NgModule({
  imports: [CommonModule, NvSharedTooltipModule],
  declarations: [NvSegmentedControlComponent],
  exports: [NvSegmentedControlComponent],
})
export class NvSegmentedControlModule {}
