<div class="find-a-course-table">
  <div class="filter-toolbar">
    <span>Filter By:</span>

    <div class="filters">
      <!-- Only show for future term -->
      <mat-form-field class="filter" *ngIf="termType === 'FUTURE'">
        <mat-label>Term</mat-label>
        <mat-select [(value)]="termYear" (selectionChange)="setCourses(termYear)">
          <mat-option *ngFor="let obj of allTermYears" [value]="obj.termYear">{{ obj.human }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- SUBJECT FILTER -->
    <mat-form-field class="filter">
      <mat-label>Subject</mat-label>
      <mat-select [(value)]="userFilter.subjectArea" (selectionChange)="setFilteredCourses(userFilter)">
        <mat-option *ngFor="let obj of subjectsMenu" [value]="obj.camelCase">{{ obj.humanByDistrict[district] || obj.human }}</mat-option>
      </mat-select>
    </mat-form-field>


    <!-- PERIOD FILTER -->
    <!-- Only show for current term -->
    <mat-form-field *ngIf="termType === 'CURRENT'" class="filter">
      <mat-label>Period</mat-label>
      <mat-select [(value)]="userFilter.period" (selectionChange)="setFilteredCourses(userFilter)">
        <mat-option [value]="">All</mat-option>
        <mat-option *ngFor="let obj of periodsMenu" [value]="obj">{{ obj }}</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- COURSE CODE FILTER -->
    <form class="filter">
      <mat-form-field>
        <input type="text" placeholder="Course Code" aria-label="Course Code" matInput [formControl]="courseForm"
          [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let option of courseFilterOptions$ | async" [value]="option.courseCodeWithSection">
            {{option.courseCodeWithSection}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </form>
  </div>

  <div class="course-table">
    <table class="nv-table nv-table-extra-dense">
      <thead>
        <tr>
          <th>Subject</th>
          <th>Course</th>
          <th class="center-align">Period</th>
          <th>Cycle</th>
          <th title="Current STARS enrollment">Enrollment</th>
          <th title="STARS enrollment +/- any pending ADDS/DROPS">Pending</th>
          <th class="center-align">Credits</th>
          <th class="center-align">Action</th>
        </tr>
      </thead>
      <tbody>
        <!-- EMPTY FILTER STATE -->
        <tr *ngIf="filteredCourses && filteredCourses.length === 0">
          <td colspan="7">No courses found.</td>
        </tr>

        <!-- NON EMPTY FILTER STATE -->
        <tr *ngFor="let course of filteredCourses">
          <!-- SUBJECT -->
          <td>{{course.subject}}</td>

          <!-- COURSE -->
          <td>
            <span *ngIf="hoveredCourseCodeWithSection !== course.courseCodeWithSection">
              {{course.courseCodeWithSection}}
            </span>
            <span *ngIf="hoveredCourseCodeWithSection === course.courseCodeWithSection" nvTooltip
              [tooltipData]="course.courseName">
              {{course.courseCodeWithSection}}
            </span>

          </td>

          <!-- PERIOD -->
          <td class="center-align" *ngIf="course.period">{{course.period}}</td>
          <td class="center-align" *ngIf="!course.period">--</td>

          <!-- CYCLE -->
          <td *ngIf="course.cycleDay">{{course.cycleDay}}</td>
          <td *ngIf="!course.cycleDay">--</td>

          <!-- ENROLLMENT -->
          <td *ngIf="course.enrollment && course.capacity">{{course.enrollment}} of {{course.capacity}}</td>
          <td *ngIf="!course.enrollment || !course.capacity">--</td>

          <!-- PENDING -->
          <td *ngIf="course.pendingEnrollment && course.capacity">{{course.pendingEnrollment}} of {{course.capacity}}</td>
          <td *ngIf="!course.pendingEnrollment || !course.capacity">--</td>

          <!-- CREDITS -->
          <td class="center-align" *ngIf="course.creditValue">{{course.creditValue}}</td>
          <td class="center-align" *ngIf="!course.creditValue">--</td>

          <!-- ACTION -->
          <td class="center-align col-actions">
            <a [ngClass]="{'disabled': !course.canBeAdded }" (click)="handleUserClick(course, termYear)">ADD</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>