import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvSecondaryFocusTileComponent } from './nv-secondary-focus-tile.component';
import { NvSharedTooltipModule } from 'projects/shared/nvps-libraries/design/nv-tooltip/nv-shared-tooltip.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@NgModule({
  imports: [CommonModule, NvSharedTooltipModule, NgxSkeletonLoaderModule],
  declarations: [NvSecondaryFocusTileComponent],
  exports: [NvSecondaryFocusTileComponent],
})
export class NvSecondaryFocusTileModule {}
