import { Injectable } from '@angular/core';
import { ILoadStudentAcadiencePayload } from 'Src/ng2/store/actions/student-acadience-actions/student-acadience-actions';

export interface TStudentAcadienceCalcConfig {
  field: string;
  graphQlKey: string;
  wildcardKey: string;
}

@Injectable()
export class GraphQLStudentAcadienceHelperService {
  getStudentAcadienceQuery ({ schoolId, studentId }: ILoadStudentAcadiencePayload): string {
    return `
    query ($calcConfig: [StudentAcadienceCalcConfig!]) {
      StudentAcadience(schoolId: "${schoolId}", studentId: "${studentId}", calcConfig: $calcConfig){
        COMPOSITE_SCORE
        COMPOSITE_SCORE_MATH
        ACADIENCE_HISTORICAL_DATA_TABLE_FOR_PANEL{
          performanceEachAdmin {
            termAndYear
            schoolYear
            termName
            subject
            compositeDate
            compositeScore
            compositeStatus
            compositePathway
            domains{
              name
              score
              status
              pathway
            }
          }
          progressBetweenAdmin {
            termAndYear
            schoolYear
            subject
            termName
            pathwaysOfProgress
            initialBenchmarkStatus
            finalBenchmarkStatus
            changeInBenchmarkStatus
          }
        }
      }
    }`;
  }
}
