type NvMask = {
  placeholder: string;
  maskFormat: string;
};
type NvMasks = {
  [maskName: string]: NvMask
};

const NvMaskFormats: NvMasks = {
  phoneNumber: {
    placeholder: '(___) ___-____',
    maskFormat: '(000) 000-0000',
  },
};

export default NvMaskFormats;
