import { Injectable } from '@angular/core';
import { SessionStorageService } from '../web-storage/session-storage/session-storage.service';

interface ISortData {
  sortKey: string;
  sortOptions: {
    sortByTerm?: () => void;
    sortBySubject?: () => void;
    sortByExamCode?: () => void;
    sortByAdminDate?: () => void;
    default: string;
  };
}
/**
 * TODO:
 * When SSV is connected to SDCStore
 * Sort should be persisted on store and not sessionStorage (JJ)
 */
@Injectable()
export class StudentPanelPersistance {
  constructor(private sessionStorageService: SessionStorageService) {
    // empty block
  }

  setInitialSort(sortData: ISortData): string {
    const {
      sortKey,
      sortOptions,
      sortOptions: { default: sortDefault },
    } = sortData;

    const sortInStorage: string = this.sessionStorageService.getItem(sortKey);

    if (sortInStorage) {
      sortOptions[sortInStorage]();
      return sortInStorage;
    } else {
      sortOptions[sortDefault]();
      return sortDefault;
    }
  }

  updateAndPersistSort(sortKey: string, sortValue: string): void {
    this.sessionStorageService.setItem(sortKey, sortValue);
  }
}
