import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ImSchool } from 'Src/ng2/shared/services/im-models/im-school';

export type TValidPriorityGroupKeys =
  | 'ONE_TERM_AWAY'
  | 'TWO_TERMS_AWAY'
  | 'THREE_TERMS_AWAY'
  | 'FOUR_TERMS_AWAY'
  | 'PLANNED_BEYOND_4'
  | 'PLANNED_NON_GRAD'
  | 'PLAN_INCOMPLETE'
  | 'NONE';

export interface IPriorityGroupDefinition {
  key: TValidPriorityGroupKeys;
  priorityVal: number;
  priorityLabel: string;
  studentPriorityGroupingValue: string;
  plannedGradDateFilter: string;
}

@Injectable()
export class CreditGapsPriorityGroupingsTransferService {
  constructor (private ImSchool: ImSchool) {}

  private next4GradDates = this.ImSchool.getNextFourGradDatesForTransfer();
  private oneTermAway = this.next4GradDates[0];
  private twoTermsAway = this.next4GradDates[1];
  private threeTermsAway = this.next4GradDates[2];
  private fourTermsAway = this.next4GradDates[3];

  private _priorityGroupings = [
    {
      key: 'ONE_TERM_AWAY',
      priorityVal: 1,
      priorityLabel: 'Planned ' + this.oneTermAway.humanName + ' Grads with unaddressed gaps.',
      studentPriorityGroupingValue: '1. Planned ' + this.oneTermAway.humanName + ' Grad w/ Unaddressed Gaps',
      // Jameson, you can use .plannedGradDateFilter to easily filter
      // priority groups for the groups relevant to the dropdown option selected
      // Feel free to change the value of .plannedGradDateFilter to whatever is easiest for filtering
      plannedGradDateFilter: this.oneTermAway.humanName,
    } as IPriorityGroupDefinition,
    {
      key: 'TWO_TERMS_AWAY',
      priorityVal: 2,
      priorityLabel: 'Planned ' + this.twoTermsAway.humanName + ' Grads with unaddressed gaps.',
      studentPriorityGroupingValue: '2. Planned ' + this.twoTermsAway.humanName + ' Grad w/ Unaddressed Gaps',
      plannedGradDateFilter: this.twoTermsAway.humanName,
    } as IPriorityGroupDefinition,
    {
      key: 'THREE_TERMS_AWAY',
      priorityVal: 3,
      priorityLabel: 'Planned ' + this.threeTermsAway.humanName + ' Grads with unaddressed gaps.',
      studentPriorityGroupingValue: '3. Planned ' + this.threeTermsAway.humanName + ' Grad w/ Unaddressed Gaps',
      plannedGradDateFilter: this.threeTermsAway.humanName,
    } as IPriorityGroupDefinition,
    {
      key: 'FOUR_TERMS_AWAY',
      priorityVal: 4,
      priorityLabel: 'Planned ' + this.fourTermsAway.humanName + ' Grads with unaddressed gaps.',
      studentPriorityGroupingValue: '4. Planned ' + this.fourTermsAway.humanName + ' Grad w/ Unaddressed Gaps',
      plannedGradDateFilter: this.fourTermsAway.humanName,
    } as IPriorityGroupDefinition,
    {
      key: 'PLANNED_BEYOND_4',
      priorityVal: 5,
      priorityLabel: 'Students planned to graduate beyond ' + this.fourTermsAway.humanName + ' with unaddressed gaps.',
      studentPriorityGroupingValue: '5. Planned Beyond ' + this.fourTermsAway.humanName + ' Grad w/ Unaddressed Gaps',
      plannedGradDateFilter: `Beyond ${this.fourTermsAway.humanName}`,
    } as IPriorityGroupDefinition,
    {
      key: 'PLANNED_NON_GRAD',
      priorityVal: 6,
      priorityLabel: 'Planned Non-Graduates with unaddressed gaps.',
      studentPriorityGroupingValue: '6. Planned Non-Grad w/ Unaddressed Gaps',
      plannedGradDateFilter: 'Planned Non-Grad',
    } as IPriorityGroupDefinition,
    {
      key: 'PLAN_INCOMPLETE',
      priorityVal: 7,
      priorityLabel: 'Students with incomplete grad plans with unaddressed gaps.',
      studentPriorityGroupingValue: '7. No Grad Plan w/ Unaddressed Gaps',
      plannedGradDateFilter: 'Plan Incomplete',
    } as IPriorityGroupDefinition,
    {
      key: 'NONE',
      priorityVal: 8,
      priorityLabel: 'Students with no unaddressed gaps.',
      studentPriorityGroupingValue: 'No Unaddressed Gaps',
      plannedGradDateFilter: 'None',
    } as IPriorityGroupDefinition,
  ];

  private _getPriorityGroupByKey (key: TValidPriorityGroupKeys): IPriorityGroupDefinition {
    return _.find(this._priorityGroupings, { key });
  }

  public getStudentValueForPriorityGroupByKey (key: TValidPriorityGroupKeys): string {
    const prioirtyGroup = this._getPriorityGroupByKey(key);
    return prioirtyGroup.studentPriorityGroupingValue;
  }

  // Jameson, the method below CreditGapsPriorityGroupingsTransferService.getPriorityGroupsByPlannedGradDateFilter()
  // is really the only function you should call from the wizard.
  // You'll need to pass in something that represents the cohort filtered in the wizard dropdown,
  // and make sure that value corresponds to .plannedGradDateFilter
  // Step 4 should contain a priority table - one row per priority grouping returned from this function
  // Use the .studentPriorityGroupingValue field on the priority grouping to count the number of students in that group
  //  i.e. all the students where student.creditGapPrioirtyGroup === priorityGroup.studentPriorityGroupingValue
  // Display prioirtyVal and priorityLabel in each row
  // If the wizard is not filtered for ALL (plannedGradDateFilter === null) there is only one priority grouping
  // so don't display priority value
  public getPriorityGroupsByPlannedGradDateFilter (plannedGradDateFilter): IPriorityGroupDefinition[] {
    return plannedGradDateFilter
      ? (_.filter(this._priorityGroupings, { plannedGradDateFilter }) as IPriorityGroupDefinition[])
      : this._priorityGroupings;
  }
}
