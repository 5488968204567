import { SorterColumn } from 'Src/ng2/shared/services/sorter-column/sorter-column.service';
import { TSorterColumnDataType } from './../../constants/sorter-column-data-type.constant';
import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  concat,
  each,
  filter,
  find,
  findIndex,
  includes,
  map,
  reduce,
  sortBy,
} from 'lodash';
import { Observable, Subject } from 'rxjs';
import { TValidSchoolTypes } from '../../../shared/typings/interfaces/school.interface';
import {
  BulkUpdateStudents,
} from '../../../store';
import { IRowDataWithBatchActions } from '../../components/list/fixed-table/fixed-table.component';
import { IStudentEditableField, studentEditableFieldMap } from '../../constants/paths/student-editable-map.constant';
import {
  IBatchEditorProjection,
  IEditableFields,
  IBatchEditStudent,
} from '../../modals/batch-edit/batch-edit-modal.component';
import { IRowData, TList } from '../../models/list-models';
import { IUser, IUserMiniWithRole, IUserMini } from '../../typings/interfaces/user.interface';
import { ApiService } from '../api-service/api-service';
import { ImSchool } from '../im-models/im-school';
import { ImUser } from '../im-models/im-user';
import { FLAG_POINT_PEOPLE_TYPES } from '../../constants/point-people-types.constant';
import { SorterColumnDataType } from '../../constants/sorter-column-data-type.constant';
import { FormGroup } from '@angular/forms';
import { TValidDiplomaEndorsements } from '../../constants/planned-diploma-endorsements.constant';
import { TValidDiplomaCredentials } from '../../constants/planned-diploma-credentials.constant';
import { TBatchActionsOrigin } from '../../components/nv-actions/nv-actions.interface';
import { IDropdownOption } from '../../../../../projects/shared/nvps-libraries/design/interfaces/design-library.interface';

export interface IBatchEditStudentParams {
  studentIds: string[];
  schoolId: string;
  patch: {
    path: any;
    newValue: any;
    dataType: any;
    key: any;
    meta?: {
      subType?: string;
    };
  };
  destroy$: Subject<any>;
  origin?: TBatchActionsOrigin;
}

export type TValidEditableSubtype = 'ENDORSEMENT';

export interface IEditableRecord {
  humanName: string;
  editable: boolean;
  dataType: TSorterColumnDataType;
  dataTypeOptions?: {
    canBeNull?: boolean;
    values?: any[];
    canBeRemoved?: boolean;
    subType?: TValidEditableSubtype;
  };
  category?: string;
  schoolLevels?: Array<'ES' | 'MS' | 'HS'>;
}

export interface IExistingBatchActionData {
  mappedData: IRowDataWithBatchActions[];
  count: number;
}

export interface IPointPersonUpdate {
  user: IUserMini;
  type: string;
}

export interface IStudentPointPersonUpdate {
  _id: string;
  dataType: string;
  value: IPointPersonUpdate[];
}

export type TBatchEditSaveValue = IUserMini | IUserMiniWithRole | string
export interface IBatchEditProcessPayload {
  form?: FormGroup;
  saveValue: TBatchEditSaveValue;
  selectedStudentIds: string[];
  currentStudents: IBatchEditStudent[];
  humanName?: string;
  meta?: {
    endorsement?: string;
    credential?: string;
  };
}

export interface IStudentEndorsementOrCredUpdate {
  _id: string;
  dataType: string;
  value: TValidDiplomaEndorsements[] | TValidDiplomaCredentials[];
}

@Injectable()
export class BatchEditService implements OnDestroy {
  constructor (
    public sorterColumn: SorterColumn,
    private imUser: ImUser,
    private imSchool: ImSchool,
    private apiService: ApiService,
    private store: Store<any>,
  ) {}

  editableFields: any;
  items;
  pointPersonFields;

  ngOnDestroy () {
    // console.log('BatchEditService is destroyed');
  }

  getStudentsPointPeople (params: IBatchEditorProjection): Observable<any> {
    return this.apiService.getStudents(params);
  }

  isAuthorizedPointPerson (user: IUser, schoolId: string) {
    const isViewUser = this.imUser.isViewType(user, { partnerId: schoolId });
    const isClusterUser = this.imUser.isClusterUser(user);
    const isNoAccessUser = this.imUser.isNoAccessUser(user, { partnerId: schoolId });
    const isActive = this.imUser.isActive(user);
    return !isViewUser && !isClusterUser && !isNoAccessUser && isActive;
  };

  getAuthorizedPointPeople (users: IUserMiniWithRole[], schoolId: string) {
    const authorizedUsers = filter(users, (user: IUser) => {
      return this.isAuthorizedPointPerson(user, schoolId);
    });
    return sortBy(authorizedUsers, (user: IUser) => {
      return user.name.firstName;
    });
  };

  isAuthorizedAdvisor (user: IUser, schoolId: string) : boolean {
    const isClusterUser = this.imUser.isClusterUser(user);
    const isNoAccessUser = this.imUser.isNoAccessUser(user, { partnerId: schoolId });
    const isActive = this.imUser.isActive(user);

    return !isClusterUser && !isNoAccessUser && isActive;
  };

  getAuthorizedAdvisors (users: IUserMiniWithRole[], schoolId: string) {
    // view type users can be advisors / guidance counselors (JE)
    const authorizedUsers = filter(users, (user: IUser) => {
      return this.isAuthorizedAdvisor(user, schoolId);
    });
      return sortBy(authorizedUsers, (user: IUser) => {
        return user.name.firstName;
      });
  }

  getEditableFields (currentUser: Partial<IUser>, schoolType?: TValidSchoolTypes): IEditableFields[] {
    // load permitted fields from sorter col service
    this.sorterColumn.setEditablePermissions(currentUser);
    const sorterCols = this.sorterColumn.getSorterColumnNamesAndCategories({ editable: true });
    const sorterColsSubset = this.restrictSorterColumns(sorterCols as any);
    const editableFields = map(studentEditableFieldMap, (studentEditableField: IStudentEditableField) => {
      const { humanName, category, schoolLevels } = studentEditableField;
      return { humanName, category, schoolLevels };
    });
    const sorterAndEditableFieldsCols = concat(sorterColsSubset, editableFields);
    const colsBySchoolType = this.getColumnsBySchoolType(sorterAndEditableFieldsCols, schoolType);

    return colsBySchoolType;
  }

  getColumnsBySchoolType (columns: IEditableFields[], schoolType?: TValidSchoolTypes): IEditableFields[] {
    if (schoolType) {
      const schoolLevel = this.imSchool.getSchoolLevel(schoolType);
      columns = filter(columns, (col: IEditableFields) => {
        return schoolLevel.some(level => col.schoolLevels.includes(level));
      });
    }
    return columns;
  }

  getBooleanDropdown (): IDropdownOption[] {
    return [
      {
        key: 'yes',
        human: 'Yes',
      },
      {
        key: 'no',
        human: 'No',
      },
    ];
  }

  getUsersDropdown (users: IUserMiniWithRole[]): IDropdownOption[] {
    return users.map(user => {
      const { _id, gafeEmail, doeEmail, name: { firstName, lastName }, preferredName: { firstName: preferredFirstName, lastName: preferredLastName } } = user;
      const humanName = (preferredFirstName && preferredLastName) ? `${preferredFirstName} ${preferredLastName}` : `${firstName} ${lastName}`;
      return {
        key: _id,
        human: humanName,
        tags: [this.imUser.getEffectiveEmail({ gafeEmail, doeEmail } as IUser)],
      };
    });
  }

  getEnumDropdown (field: IStudentEditableField): IDropdownOption[] {
    const { dataTypeOptions: { values, canBeNull } } = field;
    const options = values.map(value => ({
      key: value.replace(/( )/g, '-').toLowerCase(),
      human: value,
    }));

    // Add a null option if applicable
    if (canBeNull && options[0].human !== '—') {
      options.unshift({
        key: 'null',
        human: '—',
      });
    }

    return options;
  }

  getFieldValueFromOption (option: IDropdownOption, dataType: TSorterColumnDataType, users?: IUserMiniWithRole[]): TBatchEditSaveValue {
    if (dataType === 'USER_MINI') {
      if (users) {
        const { _id, name: { firstName, lastName }, gafeEmail = null, doeEmail = null, dhsEmail = null } = users.find(user => user._id === option.key) || {};
        const formattedUser: IUserMini = { userId: _id, firstName, lastName, gafeEmail, doeEmail, dhsEmail };
        return formattedUser;
      } else {
        return null;
      }
    } else if (dataType === 'BOOLEAN_YES_NO') {
      return option.human;
    } else if (dataType === 'ENUM' || dataType === 'REGENTS_ADMIN') {
      return option.key === 'null' ? null : option.human;
    }
  }

  sortUsersByLastName (users: IUserMiniWithRole[]) {
    return sortBy(users, (user: IUserMiniWithRole) => {
      return user.name.lastName;
    });
  }

  // FORM FIELDS - PROCESSING USER INPUT

  getField (newField: string): IStudentEditableField {
    if (!newField) return;
    let field;
    const v3Field = find(studentEditableFieldMap, field => {
      return field.humanName === newField;
    });

    if (v3Field) field = v3Field;
    else field = this.sorterColumn.getByHumanName(newField);

    return field;
  }

  setBooleanPatch (field: string) {
    switch (field) {
      case 'Yes':
        return true;
      case 'No':
        return false;
      case '-':
        return null;
      case null:
        return null;
      default:
        console.warn('Invalid menu choice');
    }
  }

  filterInput (val: string, editableFieldOptions: IEditableFields[]) {
    return editableFieldOptions.filter(option => {
      const valLowerCase = val.toLowerCase();
      return (
        option.humanName.toLowerCase().includes(valLowerCase) || option.category.toLowerCase().includes(valLowerCase)
      );
    });
  }

  restrictSorterColumns (sorterCols: IEditableFields[]): IEditableFields[] {
    const colsToRemove = [
      'Applying to CUNY',
      'Expected to Meet CUNY Benchmarks',
      'Post Secondary Plans',
      'Planned Graduation Date',
      'Planned Diploma Type',
      'CC ELA: Next Scheduled',
      'Alg: Next Scheduled',
      'CC Geom: Next Scheduled',
      'CC Trig: Next Scheduled',
      'Liv Env: Next Scheduled',
      'Earth: Next Scheduled',
      'Chem: Next Scheduled',
      'Physics: Next Scheduled',
      'Global II: Next Scheduled',
      'US (Framework): Next Scheduled',
      'LOTE: Next Scheduled',
      'Planned LOTE Exam Name',
    ];
    return filter(sorterCols, (col: IEditableFields) => {
      return !includes(colsToRemove, col.humanName);
    });
  }

  // accessed by v2 grid toolbar and v3 batch actions
  batchEditStudents (params: IBatchEditStudentParams): void {
    const { studentIds, patch, origin } = params; // add type for use by calling comps as well
    const { path, newValue } = patch;
    const payload = {
      patches: {
        _ids: studentIds,
        path,
        newValue,
      },
      origin,
    };
    this.store.dispatch(new BulkUpdateStudents(payload));
  }

  getSelectedRowsHash (batchEditRowsIds: string[]): { [key: string]: boolean } {
    const rowIdHash = batchEditRowsIds.reduce((hash, rowId) => {
      hash[rowId] = true;
      return hash;
    }, {});
    return rowIdHash;
  }

  getRowDataWithBatchActions (
    idHash: { [key: string]: boolean },
    rowData: IRowData[][],
    listType: TList,
  ): IExistingBatchActionData {
    let matched = 0;
    const batchActionRows = {
      mappedData: rowData.map(
        (row: any): IRowDataWithBatchActions => {
          const id = this._getIdFromRow(row,listType);
          const matchedId = idHash[id];
          row.isChecked = !!matchedId;
          if (row.isChecked) matched++;
          return row;
        },
      ),
      count: matched,
    };
    return batchActionRows;
  }

  // 1. using student._id in new student lists but batch actions requires osis id
  // 2. using user._id in user lists and batch actions uses the same id
  // 3. using shelterId in shelter user lists and batch actions uses the same id.
  _getIdFromRow(row: IRowData[], listType: TList): string {
    const stub = row[0];
    const metaData = stub.meta.data ? stub.meta : JSON.parse(stub.meta);
    let id = metaData.data;
    switch (listType) {
      case 'USER_SCHOOL_PORTFOLIO':
      case 'USER_SHELTER_PORTFOLIO':
      case 'GRID_COLUMNS':
      case 'USER_MANAGEMENT':
      case 'MASTER_PROGRAM':
        id = metaData.data;;
        break;
      case 'SHELTER_STUDENT':
        id = metaData.caresId;
        break;
      default:
        id = id.slice(0, -6)
        break;
    }
    return id;
  }

  _processMultiPointPersonUpdate (payload: IBatchEditProcessPayload): IStudentPointPersonUpdate[] {
    const { saveValue, selectedStudentIds, currentStudents } = payload;
    const removePp = saveValue === null;
    const updatedStudents: IStudentPointPersonUpdate[] = [];

    each(selectedStudentIds, (studentId: string) => {
      const storedStudent = find(currentStudents, (storedStudent: any) => includes(storedStudent._id, studentId));
      // do not overwrite non flag point people (CM)
      const nonFlagPp = storedStudent.pointPeople.reduce((acc, pP) => {
        const flagPp = includes(FLAG_POINT_PEOPLE_TYPES, pP.type);
        if (!flagPp) acc.push(pP);
        return acc;
      }, []);
      let updatedPp = [...nonFlagPp];

      // add user as point person for all flag types (CM)
      if (!removePp) {
        const updatedFlagPp = FLAG_POINT_PEOPLE_TYPES.map(ppType => {
          const pointPerson = {
            user: saveValue,
            type: ppType,
          };
          return pointPerson;
        });
        updatedPp = [...updatedFlagPp, ...updatedPp];
      }
      /**
       * each pointPerson array update could be different for each student in the batch.
       * passing along _id so each diff can be identified with the correct student
       */
      const updatedStudent: IStudentPointPersonUpdate = {
        _id: storedStudent._id,
        dataType: SorterColumnDataType.ARRAY,
        value: updatedPp,
      };
      updatedStudents.push(updatedStudent);
    });
    return updatedStudents;
  }

  _findField (formValue, studentEditableFields) {
    return find(studentEditableFields, (field: { type: string; humanName: string }) => {
      return field.humanName === formValue;
    });
  }

  _processSinglePointPersonUpdate (payload: IBatchEditProcessPayload): IStudentPointPersonUpdate[] {
    const { form, saveValue, selectedStudentIds, currentStudents } = payload;
    const field = this._findField(form.value.searchField, studentEditableFieldMap);
    const request = {
      user: saveValue,
      type: field.type,
    };
    let updatedStudent;
    const updatedStudents = [];
    const removePointPerson = request.user === null;

    each(selectedStudentIds, (studentId: string) => {
      const storedStudent = find(currentStudents, (storedStudent: any) => includes(storedStudent._id, studentId));
      let updatedPp = [];

      if (storedStudent.pointPeople) {
        const idx = findIndex(
          storedStudent.pointPeople,
          (pp: { type: string; user: IUserMini }) => pp.type === field.type,
        );
        if (removePointPerson) updatedPp = storedStudent.pointPeople.filter(({ type }) => type !== field.type);
        else if (idx === -1) {
          updatedPp = [...storedStudent.pointPeople, request]; // pointPerson for type does not exist (CM)
        } else {
          // change pointPerson for type
          updatedPp = [...storedStudent.pointPeople];
          updatedPp[idx] = request;
        }

        /**
         * each pointPerson array update could be different for each student in the batch.
         * passing along _id so each diff can be identified with the correct student
         */
        updatedStudent = {
          _id: storedStudent._id,
          dataType: SorterColumnDataType.ARRAY,
          value: updatedPp,
        };
        updatedStudents.push(updatedStudent);
      }
    });
    return updatedStudents;
  }

  _processEndorsementOrCredentialUpdate (
    payload: IBatchEditProcessPayload,
    subType: 'ENDORSEMENT' | 'CREDENTIAL',
  ): IStudentEndorsementOrCredUpdate[] {
    const {
      saveValue,
      selectedStudentIds,
      currentStudents,
      meta: { endorsement, credential },
    } = payload;
    const updatedValues = reduce(
      selectedStudentIds,
      (studentUpdates, studentId) => {
        // for each student, get their endorsements
        const {
          _id,
          gradPlanningDetails: { plannedCreds, plannedEndorsements },
        } = find(currentStudents, (storedStudent: any) => storedStudent.studentId === studentId);
        let original;
        let newVal;
        if (subType === 'ENDORSEMENT') {
          original = plannedEndorsements;
          newVal = endorsement;
        } else {
          original = plannedCreds;
          newVal = credential;
        }
        const hasValue = includes(original, newVal);
        let updatedVals = [...original];
        // add endorsement if not already in student endorsemnts
        if (saveValue === 'Planned' && !hasValue) updatedVals.push(newVal);
        else if (saveValue === 'Not planned') updatedVals = filter(updatedVals, val => val !== newVal);

        // push object to endorsements
        const update = {
          _id,
          dataType: SorterColumnDataType.ARRAY,
          value: updatedVals,
        };
        studentUpdates.push(update);
        return studentUpdates;
      },
      [],
    );

    return updatedValues;
  }

  _processEopFinancialEligibilityUpdate(payload: IBatchEditProcessPayload, currentUser: IUser) {
    return {
      status: payload.saveValue,
      lastUpdatedBy: this.imUser.toMiniUser(currentUser)
    }
  }
}
