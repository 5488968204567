import { Injectable } from '@angular/core';
import { each, filter, find, findIndex, get, has, includes, reduce, startCase, toLower, uniqBy, without } from 'lodash';
import { Observable } from 'rxjs';
import { map as rxMap } from 'rxjs/operators';
import * as moment from 'moment';
import { DateHelpers } from '../../../../../projects/shared/services/date-helpers/date-helpers.service';

import { SubjectAreas } from '../../../shared/constants/subject-areas.constant';
import { CLASS_OF_MAPPING } from '../../constants/class-of-mapping.constant';
import { CurrentSchoolYear } from '../../constants/current-school-year.constant';
import { GRADE_MAPPING } from '../../constants/grade-mapping.constant';
import { COLUMN_DATA_TYPE } from '../../constants/list-view/cell-type.constant';
import {
  STUDENT_PATH_APPLIED_STATUSES,
  STUDENT_PATH_FINAL_DECISION_STATUSES,
  STUDENT_PATH_GROUP,
} from '../../constants/student-paths.constant';
import { IListViewData } from '../../typings/interfaces/list-view.interface';
import { IStudentPath } from '../../typings/interfaces/studentPaths.interface';
import { PATH_TYPE } from '../api-service/api-service';
import { ImStudent } from '../im-models/im-student.service';
import { GRAD_PLAN_MAPPING } from './../../constants/grad-plan-mapping.constant';
import { FORMATTED_GROUPING_LISTS } from './mad-lib-groupings.constant';
import { MadLibHelpers } from './mad-lib-helpers';

import { SPEC_OPP_KEY_MAP } from '../../../school/lists/list-display/list-display-path/list-display-college-path/list-display-college-path.component';
import { IPointPerson } from '../../../shared/typings/interfaces/student.interface';

const ATTENDANCE_TREND_MAPPING = {
  TODAY: 'att.today',
  '5_DAYS': 'att.last5.trend',
  '20_DAYS': 'att.last20.trend',
  THIS_TERM: 'att.currTerm.trend',
  THIS_YEAR: 'att.currSy.trend',
};

/**
 * TODO!
 * Since this file will only continue to grow
 * we should pull each of these functions out into their own folder with the method and test file
 * then we can import only the grouping fn that we need and not an entire libarary of grouping methods
 * This will allow us to better test these functions
 * JJ
 */

/* istanbul ignore next */
@Injectable()
export class MadLibGroupings {
  constructor (private madLibHelpers: MadLibHelpers, private ImStudent: ImStudent, private dateHelpers: DateHelpers) {}

  getTrendGroupings (filteredStudents$, madLibsModel, columns, listType): Observable<any> {
    return filteredStudents$.pipe(
      rxMap((students: any[]) => {
        const formattedList: IListViewData = FORMATTED_GROUPING_LISTS.trend.getFormattedList({ columns, listType });
        const {
          Dimension2: { key: currentDim2Selection },
        } = madLibsModel;
        const path = ATTENDANCE_TREND_MAPPING[currentDim2Selection];

        return students.reduce((formattedList, student) => {
          const studentTrendGroup = get(student, path);
          const studentRow = this.convertColumnsToRow(student, columns, madLibsModel, listType);

          if (!studentTrendGroup) {
            const noDataIndex = formattedList.sections.length - 1;
            formattedList.sections[noDataIndex].data.push(studentRow);
            formattedList.sections[noDataIndex].count++;
            return formattedList;
          }

          let sectionIndex = findIndex(formattedList.sections, { name: studentTrendGroup });
          if (sectionIndex === -1) {
            this.appendNewGrouping(formattedList, sectionIndex, studentTrendGroup);
            sectionIndex = 0;
          }
          formattedList.sections[sectionIndex].data.push(studentRow);
          formattedList.sections[sectionIndex].count++;

          return formattedList;
        }, formattedList);
      }),
    );
  }

  getAttRiskGroupings (filteredStudents$, madLibsModel, columns, listType): Observable<any> {
    const path = 'att.riskGroup';
    return filteredStudents$.pipe(
      rxMap((students: any[]) => {
        const formattedList: IListViewData = FORMATTED_GROUPING_LISTS.att_risk_group.getFormattedList({
          columns,
          listType,
        });

        return students.reduce((formattedList, student) => {
          const studentRiskGroup = get(student, path);
          const studentRow = this.convertColumnsToRow(student, columns, madLibsModel, listType);

          if (!studentRiskGroup) {
            const noDataIndex = formattedList.sections.length - 1;
            formattedList.sections[noDataIndex].data.push(studentRow);
            formattedList.sections[noDataIndex].count++;
            return formattedList;
          }

          let sectionIndex = findIndex(formattedList.sections, { name: studentRiskGroup });
          if (sectionIndex === -1) {
            this.appendNewGrouping(formattedList, sectionIndex, studentRiskGroup);
            sectionIndex = 0;
          }

          formattedList.sections[sectionIndex].data.push(studentRow);
          formattedList.sections[sectionIndex].count++;

          return formattedList;
        }, formattedList);
      }),
    );
  }

  getAdmitStatusGroupings (filteredStudents$, madLibsModel, columns, listType): Observable<any> {
    const path = 'isNewStudent';

    return filteredStudents$.pipe(
      rxMap((students: any[]) => {
        const formattedList: IListViewData = FORMATTED_GROUPING_LISTS.admit_status.getFormattedList({
          columns,
          listType,
        });

        return students.reduce((formattedList, student) => {
          const studentAdmitGroup = get(student, path);
          const studentRow = this.convertColumnsToRow(student, columns, madLibsModel, listType);
          if (studentAdmitGroup) {
            formattedList.sections[0].data.push(studentRow);
            formattedList.sections[0].count++;
          } else if (studentAdmitGroup == null) {
            formattedList.sections[2].data.push(studentRow);
            formattedList.sections[2].count++;
          } else {
            formattedList.sections[1].data.push(studentRow);
            formattedList.sections[1].count++;
          }
          return formattedList;
        }, formattedList);
      }),
    );
  }

  getSupportGroupings (filteredStudents$, columns, listType, madLibModel): Observable<any> {
    const path = 'activeStudentSupports';
    return filteredStudents$.pipe(
      rxMap((students: any[]) => {
        const formattedList: IListViewData = FORMATTED_GROUPING_LISTS.supports.getFormattedList({ columns, listType });
        return students.reduce((formattedList, student) => {
          const studentSupports = get(student, path);
          const studentSupportsForCat = this.madLibHelpers.getStudentSupportsByCategory(studentSupports, listType);
          const studentRow = this.convertColumnsToRow(student, columns, madLibModel, listType);

          if (!studentSupportsForCat.length) {
            const noDataIndex = formattedList.sections.length - 1;
            formattedList.sections[noDataIndex].data.push({
              ...studentRow,
              ...{ assigned: { data: null, dependencies: {} } },
            });
            formattedList.sections[noDataIndex].count++;
            return formattedList;
          }

          studentSupportsForCat.forEach(studentSupport => {
            const {
              support: { name },
            } = studentSupport;
            let sectionIndex = findIndex(formattedList.sections, { name });

            if (sectionIndex === -1) {
              this.appendNewGrouping(formattedList, sectionIndex, name);
              sectionIndex = 0;
            }

            formattedList.sections[sectionIndex].data.push({
              ...studentRow,
              ...{ assigned: { data: studentSupport.startsOn, dependencies: {} } },
            });
            formattedList.sections[sectionIndex].count++;
          });
          return formattedList;
        }, formattedList);
      }),
    );
  }

  getFlagGroupings (filteredStudents$, columns, listType, model?): Observable<any> {
    return filteredStudents$.pipe(
      rxMap((students: any[]) => {
        const formattedList = FORMATTED_GROUPING_LISTS.flags.getFormattedList({ columns, listType });

        return students.reduce((formattedList, student) => {
          const studentFlags = student.flags;
          const studentRow = this.convertColumnsToRow(student, columns, model, listType);

          if (!studentFlags.length) {
            const noDataIndex = formattedList.sections.length - 1;
            const lastSection = formattedList.sections[noDataIndex];
            lastSection.data.push(studentRow);
            lastSection.count++;
            return formattedList;
          }

          studentFlags.forEach(studentFlag => {
            const { type: name } = studentFlag;
            let sectionIndex = findIndex(formattedList.sections, { name });
            if (sectionIndex === -1) {
              this.appendNewGrouping(formattedList, sectionIndex, name);
              sectionIndex = 0;
            }
            formattedList.sections[sectionIndex].data.push(studentRow);
            formattedList.sections[sectionIndex].count++;
          });
          return formattedList;
        }, formattedList);
      }),
    );
  }

  getSchoolRiskGroupings (filteredStudents$, madLibModel, columns, listType, isIreadySchool?): Observable<any> {
    return filteredStudents$.pipe(
      rxMap((students: any[]) => {
        const formattedList = FORMATTED_GROUPING_LISTS.academic_risk_groups.getFormattedList({
          columns,
          listType,
          madLibModel,
        });

        return students.reduce((formattedList, student) => {
          const studentRow = this.convertColumnsToRow(
            student,
            columns,
            madLibModel,
            listType,
            undefined,
            isIreadySchool,
          );
          let doeRiskGroup = null;
          let schoolRiskGroup = null;

          if (has(studentRow, 'iReady')) {
            doeRiskGroup = studentRow.iReady.dependencies.doeRiskGroup;
            // schoolRiskGroup is aligned to the dimension2 selection on the madLibModel
            schoolRiskGroup = studentRow.iReady.dependencies.schoolRiskGroup;
            // if there is no schoolRiskGroup for the selected IReady subject, we default to the doeRiskGroup
          }

          if (madLibModel.Groupings.validString.includes('ELA')) {
            if (has(studentRow, 'elaStateExam')) {
              doeRiskGroup = studentRow.elaStateExam.dependencies.doeRiskGroup;
              schoolRiskGroup = studentRow.elaStateExam.dependencies.schoolRiskGroup;
            }
          }

          if (madLibModel.Groupings.validString.includes('MATH')) {
            if (has(studentRow, 'mathStateExam')) {
              doeRiskGroup = studentRow.mathStateExam.dependencies.doeRiskGroup;
              schoolRiskGroup = studentRow.mathStateExam.dependencies.schoolRiskGroup;
            }
          }

          const studentRiskGroup = schoolRiskGroup || doeRiskGroup;

          if (!studentRiskGroup) {
            const noDataIndex = formattedList.sections.length - 1;
            formattedList.sections[noDataIndex].data.push(studentRow);
            formattedList.sections[noDataIndex].count++;
            return formattedList;
          }

          let sectionIndex = findIndex(formattedList.sections, { name: studentRiskGroup });
          if (sectionIndex === -1) {
            this.appendNewGrouping(formattedList, sectionIndex, studentRiskGroup);
            sectionIndex = 0;
          }

          formattedList.sections[sectionIndex].data.push(studentRow);
          formattedList.sections[sectionIndex].count++;
          return formattedList;
        }, formattedList);
      }),
    );
  }

  getDoeRiskGroupings (filteredStudents$, madLibModel, columns, listType, isIreadySchool?): Observable<any> {
    return filteredStudents$.pipe(
      rxMap((students: any[]) => {
        const formattedList = FORMATTED_GROUPING_LISTS.academic_risk_groups.getFormattedList({
          columns,
          listType,
          madLibModel,
        });
        return students.reduce((formattedList, student) => {
          const studentRow = this.convertColumnsToRow(
            student,
            columns,
            madLibModel,
            listType,
            undefined,
            isIreadySchool,
          );
          let doeRiskGroup = null;

          if (has(studentRow, 'iReady')) {
            doeRiskGroup = studentRow.iReady.dependencies.doeRiskGroup;
          }

          if (madLibModel.Groupings.validString.includes('ELA')) {
            if (has(studentRow, 'elaStateExam')) {
              doeRiskGroup = studentRow.elaStateExam.dependencies.doeRiskGroup;
            }
          }

          if (madLibModel.Groupings.validString.includes('MATH')) {
            if (has(studentRow, 'mathStateExam')) {
              doeRiskGroup = studentRow.mathStateExam.dependencies.doeRiskGroup;
            }
          }

          if (!doeRiskGroup) {
            const noDataIndex = formattedList.sections.length - 1;
            formattedList.sections[noDataIndex].data.push(studentRow);
            formattedList.sections[noDataIndex].count++;
            return formattedList;
          }

          let sectionIndex = findIndex(formattedList.sections, { name: doeRiskGroup });
          if (sectionIndex === -1) {
            this.appendNewGrouping(formattedList, sectionIndex, doeRiskGroup);
            sectionIndex = 0;
          }

          formattedList.sections[sectionIndex].data.push(studentRow);
          formattedList.sections[sectionIndex].count++;
          return formattedList;
        }, formattedList);
      }),
    );
  }

  getHasFlagsGroupings (filteredStudents$, madLibModel, columns, listType): Observable<any> {
    return filteredStudents$.pipe(
      rxMap((students: any[]) => {
        const formattedList = FORMATTED_GROUPING_LISTS.has_flags.getFormattedList({ columns, listType });
        return students.reduce((formattedList, student) => {
          const studentRow = this.convertColumnsToRow(student, columns, madLibModel, listType);
          const { academicFlags, attendanceFlags } = student;
          // currently there are no postsecondary flags
          const allFlags = without([...academicFlags, ...attendanceFlags], undefined);
          if (allFlags.length) {
            formattedList.sections[0].data.push(studentRow);
            formattedList.sections[0].count++;
            return formattedList;
          }

          formattedList.sections[1].data.push(studentRow);
          formattedList.sections[1].count++;

          return formattedList;
        }, formattedList);
      }),
    );
  }

  getHasPointPeopleGroupings (filteredStudents$, madLibModel, columns, listType): Observable<any> {
    return filteredStudents$.pipe(
      rxMap((students: any[]) => {
        const formattedList = FORMATTED_GROUPING_LISTS.has_point_people.getFormattedList({ columns, listType });
        return students.reduce((formattedList, student) => {
          const studentRow = this.convertColumnsToRow(student, columns, madLibModel, listType);
          const { pointPeople } = student;
          if (pointPeople.length) {
            formattedList.sections[1].data.push(studentRow);
            formattedList.sections[1].count++;
            return formattedList;
          }

          formattedList.sections[0].data.push(studentRow);
          formattedList.sections[0].count++;

          return formattedList;
        }, formattedList);
      }),
    );
  }

  getProgressGroupings (filteredStudents$, madLibModel, columns, listType): Observable<any> {
    return filteredStudents$.pipe(
      rxMap((students: any[]) => {
        const formattedList: IListViewData = FORMATTED_GROUPING_LISTS.progress.getFormattedList({ columns, listType });
        const {
          Dimension2: { key },
        } = madLibModel;

        return students.reduce((formattedList, student) => {
          const studentRow = this.convertColumnsToRow(student, columns, madLibModel, listType);
          const studentProgress =
            key === 'COURSE_GRADES' ? student.currProgram.progress : student.currProgram.progressHP;
          switch (studentProgress) {
            case 'Needs attention':
              formattedList.sections[0].data.push(studentRow);
              formattedList.sections[0].count++;
              break;
            case 'Passing':
              formattedList.sections[1].data.push(studentRow);
              formattedList.sections[1].count++;
              break;
            case 'No grades':
              formattedList.sections[2].data.push(studentRow);
              formattedList.sections[2].count++;
              break;
            case 'No courses':
              formattedList.sections[3].data.push(studentRow);
              formattedList.sections[3].count++;
              break;
            default:
              break;
          }
          return formattedList;
        }, formattedList);
      }),
    );
  }

  getClassOfGroupings ({ filteredStudents$, columns, listType, navDataType, navRoute, navData }): Observable<any> {
    const currentGradYear = Number(CurrentSchoolYear.ENDFULL);
    const classOfData$ = filteredStudents$.pipe(
      rxMap((students: any[]) =>
        students.reduce((classOfData, student) => {
          const {
            studentDetails: { classOf },
          } = student;
          const isSeniorOrSuper: boolean = Number(classOf) <= currentGradYear;

          if (!classOf) {
            const noClassOfDefault = 'None';
            if (!classOfData[noClassOfDefault]) classOfData[noClassOfDefault] = [];
            classOfData[noClassOfDefault].push(student);
            return classOfData;
          }

          const groupingExists: boolean = !!classOfData[classOf];
          if (classOf && !isSeniorOrSuper && !groupingExists) classOfData[classOf] = [];
          if (isSeniorOrSuper) {
            if (!classOfData[`${currentGradYear}+`]) classOfData[`${currentGradYear}+`] = [];
            classOfData[`${currentGradYear}+`].push(student);
          } else {
            classOfData[classOf].push(student);
          }
          return classOfData;
        }, {}),
      ),
    );

    return classOfData$.pipe(
      rxMap((data: any[]) => {
        const formattedList: IListViewData = FORMATTED_GROUPING_LISTS.class_of.getFormattedList({
          columns,
          listType,
          navDataType,
          navData,
          navRoute,
        });

        const formattedGrouping = reduce(
          data,
          (formattedList, students: any[], classOf) => {
            const studentIds = students.map(({ studentId }) => studentId);
            const classConstant: any = find(CLASS_OF_MAPPING, { key: `${classOf}` });
            const { filterValue, order } = classConstant;
            const classOfColumn = { data: classOf, dependencies: { filterValue, studentIds } };

            const dataObj = columns.reduce(
              (result, column) => {
                const { key, getValueAtPath } = column;
                result[key] = getValueAtPath(students);
                return result;
              },
              { classOf: classOfColumn },
            );

            formattedList.sections[0].count++;
            formattedList.sections[0].data[order] = dataObj;
            return formattedList;
          },
          formattedList,
        );

        formattedGrouping.sections[0].data = filter(formattedGrouping.sections[0].data, arrVal => arrVal);
        return formattedGrouping;
      }),
    );
  }

  getGradPlanGroupings ({
    filteredStudents$,
    columns,
    listType,
    navDataType,
    navRoute,
    navData,
    schoolType,
  }): Observable<any> {
    const gradPlanData$ = filteredStudents$.pipe(
      rxMap((students: any[]) =>
        students.reduce((gradPlanData, student) => {
          // get gradPlan based on schoolType -- since this is calculated on frontend
          const gradPlan =
            schoolType === 'Transfer'
              ? this.ImStudent.getCurrentGradPlanTransfer(student)
              : this.ImStudent.getCurrentGradPlan(student);
          // send all nulls to a custom bucket called `None`
          if (!gradPlan) {
            const nogradPlanDefault = 'None';
            if (!gradPlanData[nogradPlanDefault]) gradPlanData[nogradPlanDefault] = [];
            gradPlanData[nogradPlanDefault].push(student);
            return gradPlanData;
          }

          if (gradPlan && !gradPlanData[gradPlan]) gradPlanData[gradPlan] = [];
          if (gradPlan) gradPlanData[gradPlan].push(student);
          return gradPlanData;
        }, {}),
      ),
    );

    return gradPlanData$.pipe(
      rxMap((data: any[]) => {
        const formattedList: IListViewData = FORMATTED_GROUPING_LISTS.grad_plan.getFormattedList({
          columns,
          listType,
          navDataType,
          navData,
          navRoute,
        });

        const formattedGrouping: any = reduce(
          data,
          (formattedList, students: any[], gradPlan) => {
            const studentIds = students.map(({ studentId }) => studentId);
            const { filterValue, order } =
              schoolType === 'Transfer'
                ? find(GRAD_PLAN_MAPPING.TRANSFER, { key: `${gradPlan}` })
                : find(GRAD_PLAN_MAPPING.NON_TRANSFER, { key: `${gradPlan}` });

            const gradPlanColumn = { data: gradPlan, dependencies: { filterValue, studentIds } };
            const dataObj = columns.reduce(
              (result, column) => {
                const { key, getValueAtPath } = column;
                result[key] = getValueAtPath(students);
                return result;
              },
              { gradPlan: gradPlanColumn },
            );

            formattedList.sections[0].count++;
            formattedList.sections[0].data[order] = dataObj;
            return formattedList;
          },
          formattedList,
        );
        // remove empties -- todo: wrap in fn
        formattedGrouping.sections[0].data = filter(formattedGrouping.sections[0].data, arrVal => arrVal);

        return formattedGrouping;
      }),
    );
  }

  getGradeGroupings ({ filteredStudents$, columns, listType, navDataType, navRoute, navData }): Observable<any> {
    const gradeData$ = filteredStudents$.pipe(
      rxMap((students: any[]) =>
        students.reduce((gradeData, student) => {
          const {
            studentDetails: { grade },
          } = student;
          const formattedGrade = GRADE_MAPPING[grade].human;
          if (grade && !gradeData[formattedGrade]) gradeData[formattedGrade] = [];
          if (grade) gradeData[formattedGrade].push(student);
          return gradeData;
        }, {}),
      ),
    );

    // temporary sorting function until sorting is implemented
    const sortedGradeData$ = gradeData$.pipe(
      rxMap(unsortedData => {
        const unsortedKeys = Object.keys(unsortedData);

        const sortedKeys = unsortedKeys.sort((a, b) => {
          const aNum = +a.split(' ')[1];
          const bNum = +b.split(' ')[1];
          if (bNum > aNum) return 1;
          if (aNum > bNum) return -1;
          else return 0;
        });

        return reduce(
          sortedKeys,
          (sortedData, key) => {
            sortedData[key] = unsortedData[key];
            return sortedData;
          },
          {},
        );
      }),
    );

    return sortedGradeData$.pipe(
      rxMap((data: any[]) => {
        const formattedList: IListViewData = {
          listType,
          columns: this.formatColumnsForList(columns),
          sections: [{ name: 'Grade', count: 0, data: [], defaultDisplayCount: 10, key: 'grade', dataType: 'Number' }],
          navDataType,
          navRoute,
          navData,
        };

        const formattedGrouping = reduce(
          data,
          (formattedList, students: any[], grade) => {
            const studentIds = students.map(({ studentId }) => studentId);
            const gradeConstant = find(GRADE_MAPPING, { human: `${grade}` });
            const { sortValue, order } = gradeConstant;
            // constant 'sortValue' used for grades which include strings ('PK') and numbers (8)
            const gradeColumn = { data: grade, dependencies: { filterValue: sortValue, studentIds } };

            // TODO : WRAP THIS UP IN A FUNCTION... this is ugly and we will be doing this in others
            const dataObj = columns.reduce(
              (result, column) => {
                const { key, getValueAtPath } = column;
                result[key] = getValueAtPath(students);
                return result;
              },
              { grade: gradeColumn },
            );

            formattedList.sections[0].count++;
            formattedList.sections[0].data[order] = dataObj;
            return formattedList;
          },
          formattedList,
        );
        // remove empties
        formattedGrouping.sections[0].data = filter(formattedGrouping.sections[0].data, arrVal => arrVal);
        return formattedGrouping;
      }),
    );
  }

  // POINT PERSON
  getPointPersonGroupings ({ filteredStudents$, columns, listType, navDataType, navRoute, navData }): Observable<any> {
    const pointPersonData$ = filteredStudents$.pipe(
      rxMap((students: any[]) =>
        students.reduce((pointPersonData, student) => {
          const { pointPeople } = student;
          // uniquePointPeople controls for when a student has the same point person assigned to multiple point person roles
          // otherwise, the student gets counted for every instance of the point person's name. (SR)
          const uniquePointPeople = uniqBy(pointPeople, (pp: IPointPerson) => {
            return pp.user.userId;
          });
          if (!uniquePointPeople.length) {
            const noPointPersonDefault = 'None';
            if (!pointPersonData[noPointPersonDefault]) pointPersonData[noPointPersonDefault] = [];
            pointPersonData[noPointPersonDefault].push(student);
            return pointPersonData;
          } else {
            each(uniquePointPeople, (pointPerson: IPointPerson) => {
              const { firstName, lastName } = pointPerson.user;
              const pointPersonName = `${firstName} ${lastName}`;
              if (!pointPersonData[pointPersonName]) pointPersonData[pointPersonName] = [];
              pointPersonData[pointPersonName].push(student);
            });
          }
          return pointPersonData;
        }, {}),
      ),
    );

    return pointPersonData$.pipe(
      rxMap((data: any[]) => {
        const formattedList: IListViewData = FORMATTED_GROUPING_LISTS.point_person.getFormattedList({
          columns,
          listType,
          navDataType,
          navRoute,
          navData,
        });

        return reduce(
          data,
          (formattedList, students: any[], pointPerson) => {
            const studentIds = students.map(({ studentId }) => studentId);
            const pointPersonColumn = { data: pointPerson, dependencies: { filterValue: pointPerson, studentIds } };

            const dataObj = columns.reduce(
              (result, column) => {
                const { key, getValueAtPath } = column;
                result[key] = getValueAtPath(students);
                return result;
              },
              { pointPerson: pointPersonColumn },
            );

            formattedList.sections[0].count++;
            formattedList.sections[0].data.push(dataObj);
            return formattedList;
          },
          formattedList,
        );
      }),
    );
  }

  getFlagStatusGrouping (filteredStudents$, columns, listType, flagCategory, flagType) {
    return filteredStudents$.pipe(
      rxMap((students: any[]) => {
        const formattedList = {
          listType,
          columns: this.formatColumnsForList(columns),
          sections: [
            { name: 'Unresolved', count: 0, data: [], defaultDisplayCount: 10 },
            { name: 'Ignored', count: 0, data: [], defaultDisplayCount: 10 },
            { name: 'Resolved', count: 0, data: [], defaultDisplayCount: 10 },
            { name: 'No Flags', count: 0, data: [], defaultDisplayCount: 10 }, // if absentToday this includes closed
          ],
        };
        return students.reduce((formattedList, student) => {
          const studentRow = columns.reduce((studentRow, { key, getValueAtPath }) => {
            // this would need to change to use the same
            studentRow[key] = getValueAtPath(student, flagCategory, flagType);
            return studentRow;
          }, {});

          const flag: any = find(student.flags, { type: flagType });

          if (!flag) {
            const noDataIndex = formattedList.sections.length - 1;
            formattedList.sections[noDataIndex].data.push(studentRow);
            formattedList.sections[noDataIndex].count++;
            return formattedList;
          }

          let { status: flagStatus, isIgnored } = flag;
          // there is no ignored status, so if active we need to differentiate between just active and ignoreds
          if (flagStatus === 'ACTIVE' && isIgnored) flagStatus = 'Ignored';
          else if (flagStatus === 'ACTIVE' && !isIgnored) flagStatus = 'Unresolved';
          else if (flagStatus === 'RESOLVED') flagStatus = 'Resolved';
          else if (flagStatus === 'CLOSED') flagStatus = 'No Flags';

          const formattedFlagStatus = startCase(toLower(flagStatus));

          let sectionIndex = findIndex(formattedList.sections, { name: formattedFlagStatus });

          if (sectionIndex === -1) {
            const section = {
              name: formattedFlagStatus,
              count: 0,
              data: [],
              defaultDisplayCount: 10,
            };
            formattedList.sections.push(section);
            sectionIndex = formattedList.sections.length - 1;
          }

          formattedList.sections[sectionIndex].data.push(studentRow);
          formattedList.sections[sectionIndex].count++;
          return formattedList;
        }, formattedList);
      }),
    );
  }

  getDefaultGrouping (filteredStudents$, columns, listType, flagCategory, flagType) {
    return filteredStudents$.pipe(
      rxMap((students: any[]) => {
        const formattedList = {
          listType,
          columns: this.formatColumnsForList(columns),
          sections: [
            {
              name: '',
              count: 0,
              data: [],
              defaultDisplayCount: 10,
            },
          ],
        };

        columns.forEach((col: any) => {
          if (col.cellType === COLUMN_DATA_TYPE.SECTION_HEADER) {
            formattedList.sections[0].name = col.human;
          } else {
            formattedList.columns[col.key] = {
              name: col.human,
              cellType: col.cellType,
              cellConfig: col.cellConfig,
              dataType: col.dataType,
              orderBy: col.orderBy,
            };
          }
        });

        return students.reduce((formattedList, student) => {
          const studentRow = columns.reduce((studentRow, { key, getValueAtPath }) => {
            studentRow[key] = getValueAtPath(student, flagCategory, flagType);
            return studentRow;
          }, {});
          formattedList.sections[0].data.push(studentRow);
          formattedList.sections[0].count++;
          return formattedList;
        }, formattedList);
      }),
    );
  }


  getPostsecondaryApplicationStatusGrouping (filteredStudents$, columns, listType): Observable<Partial<IListViewData>> {
    return filteredStudents$.pipe(
      rxMap((students: any[]) => {
        const formattedList: IListViewData = FORMATTED_GROUPING_LISTS.postsecondary_group_app_status.getFormattedList({
          columns,
          listType,
        });
        return students.reduce((formattedList, student) => {
          const calculatedFields = student.calculatedFields[0];
          const studentRow = columns.reduce((studentRow, { key, getValueAtPath }) => {
            studentRow[key] = getValueAtPath(calculatedFields);
            return studentRow;
          }, {});

          let name;
          let sectionIndex;

          const appliedTotalPaths = calculatedFields.applied.totalPaths;
          const appliedTotalPathConditions = {
            hasFinalDecision: false,
            hasAppliedToAllPaths: true,
            hasAppliedToAllPathSoFar: true,
          };

          const { hasFinalDecision, hasAppliedToAllPaths } = this.groupByAppliedTotalPaths(
            appliedTotalPaths,
            appliedTotalPathConditions,
          );
          const needtoApply =
            !calculatedFields.applied.totalPaths.length || (!hasAppliedToAllPaths && !hasFinalDecision);
          const needFinalDecision = hasAppliedToAllPaths && !hasFinalDecision;
          const committed = hasFinalDecision;

          if (needtoApply) name = STUDENT_PATH_GROUP.NEED_TO_APPLY;
          else if (needFinalDecision) name = STUDENT_PATH_GROUP.NEED_FINAL_DECISION;
          else if (committed) name = STUDENT_PATH_GROUP.COMMITTED;

          sectionIndex = findIndex(formattedList.sections, { name });

          if (sectionIndex === -1) {
            const section = {
              name,
              count: 0,
              data: [],
              defaultDisplayCount: 10,
            };
            formattedList.sections.push(section);
            sectionIndex = formattedList.sections.length - 1;
          }
          formattedList.sections[sectionIndex].data.push(studentRow);
          formattedList.sections[sectionIndex].count++;
          return formattedList;
        }, formattedList);
      }),
    );
  }

  groupByAppliedTotalPaths = (appliedTotalPaths, appliedTotalPathConditions) => {
    return appliedTotalPaths.reduce((accumulator, path, currentIndex): {
      hasFinalDecision: boolean;
      hasAppliedToAllPaths: boolean;
      hasAppliedToAllPathSoFar: boolean;
    } => {
      if (!accumulator.hasFinalDecision) {
        accumulator.hasFinalDecision = includes(STUDENT_PATH_FINAL_DECISION_STATUSES, path.status);
      }

      if (accumulator.hasAppliedToAllPathSoFar) {
        const includesAppliedStatuses = includes(STUDENT_PATH_APPLIED_STATUSES, path.status);
        accumulator.hasAppliedToAllPathSoFar = accumulator.hasAppliedToAllPathSoFar && includesAppliedStatuses;
        accumulator.hasAppliedToAllPaths = accumulator.hasAppliedToAllPathSoFar;
      }
      if (currentIndex === appliedTotalPaths.length) {
        accumulator.hasAppliedToAllPaths = accumulator.hasAppliedToAllPaths && accumulator.hasAppliedToAllPathSoFar;
      }
      return accumulator;
    }, appliedTotalPathConditions);
  };

  convertColumnsToRow (student, columns, madLibsModel, listType, code?, isIreadySchool?) {
    const studentRow = columns.reduce((studentRow, { key, getValueAtPath }) => {
      studentRow[key] = getValueAtPath(student, madLibsModel, listType, code, isIreadySchool);
      return studentRow;
    }, {});
    return studentRow;
  }

  appendNewGrouping (formattedList, sectionIndex, groupName) {
    const section = {
      name: groupName,
      count: 0,
      data: [],
      defaultDisplayCount: 10,
    };
    formattedList.sections.unshift(section);
    return formattedList;
  }

  formatColumnsForList (columns) {
    const formattedColumns = columns.reduce((formattedList, col) => {
      if (col.cellType !== COLUMN_DATA_TYPE.SECTION_HEADER) {
        formattedList[col.key] = {
          name: col.human,
          cellType: col.cellType,
          cellConfig: col.cellConfig,
          dataType: col.dataType,
          orderBy: col.orderBy,
          tooltip: col.tooltip,
        };
      }
      return formattedList;
    }, {});
    return formattedColumns;
  }

  getCollegePathGrouping (studentPaths$, columns, listType, currentStudentId): Observable<any> {
    return studentPaths$.pipe(
      rxMap(path => {
        const formattedList: IListViewData = FORMATTED_GROUPING_LISTS.college_path.getFormattedList({
          columns,
          listType,
        });

        const allPaths = [];
        Object.keys(path).map(key => {
          const stuPath = path[key];
          if (
            stuPath.studentId === currentStudentId &&
            stuPath.path.type === PATH_TYPE.COLLEGE &&
            stuPath.status !== 'DELETED' &&
            stuPath.status !== 'CLOSED'
          ) {
            allPaths.push(stuPath);
          }
        });

        return allPaths.reduce((formattedList, stuPath) => {
          const dueDate = stuPath.dueAt ? moment(stuPath.dueAt).format('MMM DD, YYYY') : '-';
          const parsedStatus = SPEC_OPP_KEY_MAP[stuPath.status];
          const stuPathStatus = parsedStatus || stuPath.status;
          const dataObj = {
            collegeName: {
              data: stuPath.path.name,
              dependencies: { city: stuPath.path.city, state: stuPath.path.state },
            },
            status: { data: stuPathStatus, dependencies: { studentPathId: stuPath._id } },
            dueBy: { data: dueDate },
          };
          formattedList.sections[0].count++;
          formattedList.sections[0].data.push(dataObj);
          return formattedList;
        }, formattedList);
      }),
    );
  }

  getSubjectSupportGroupings (filteredStudents$, columns, listType, madLibModel): Observable<any> {
    const {
      Dimension2: { subject },
    } = madLibModel;
    return filteredStudents$.pipe(
      rxMap((students: any[]) => {
        const formattedList: IListViewData = FORMATTED_GROUPING_LISTS.subjectSupports.getFormattedList({
          columns,
          listType,
          subject,
        });

        return students.reduce((formattedList, student) => {
          const {
            activeStudentSupports,
            currProgram: { grades },
          } = student;
          const selectedSubject = madLibModel.Dimension2.key;
          const selectedSubjectHumanShort = SubjectAreas[selectedSubject].humanShort;
          const studentSupportsForCat = activeStudentSupports.filter(
            studentSupport => studentSupport.support.metaData && studentSupport.support.category === listType,
          );

          const supportsForSubject = studentSupportsForCat.reduce(
            (
              acc,
              {
                support: {
                  name,
                  metaData: { subject },
                },
              },
            ) => {
              // not all academic supports will have a subject
              if (!subject) return acc;
              const subjectKey = find(SubjectAreas, { humanShort: subject }).key;
              if (subject === selectedSubjectHumanShort) {
                if (!acc[subjectKey]) acc[subjectKey] = [];
                acc[subjectKey].push(name);
              }
              return acc;
            },
            {},
          );

          const gradesForSelectedSubject = grades.filter(
            ({ subj: courseSubj, pf, mostRecent }) => courseSubj === selectedSubject && pf === 'F' && mostRecent,
          );

          gradesForSelectedSubject.forEach(grade => {
            const studentRow = this.convertColumnsToRow(student, columns, madLibModel, listType, grade.code);
            const supportNames = supportsForSubject[grade.subj];

            if (supportNames) {
              supportNames.forEach(name => {
                let sectionIndex = findIndex(formattedList.sections, { name });
                if (sectionIndex === -1) {
                  this.appendNewGrouping(formattedList, sectionIndex, name);
                  sectionIndex = 0;
                }
                formattedList.sections[sectionIndex].data.push(studentRow);
                formattedList.sections[sectionIndex].count++;
              });
            } else {
              const noDataIndex = formattedList.sections.length - 1;
              formattedList.sections[noDataIndex].data.push(studentRow);
              formattedList.sections[noDataIndex].count++;
            }
          });
          return formattedList;
        }, formattedList);
      }),
    );
  }

  // todo: add spec
  getCareerPathGrouping (studentPaths$, columns, listType, currentStudentId): Observable<any> {
    return studentPaths$.pipe(
      rxMap(path => {
        const formattedList: IListViewData = FORMATTED_GROUPING_LISTS.career_path.getFormattedList({
          columns,
          listType,
        });

        const allPaths = [];
        Object.keys(path).map(key => {
          const stuPath = path[key];
          if (
            stuPath.studentId === currentStudentId &&
            stuPath.path.type === PATH_TYPE.CAREER &&
            stuPath.status !== 'DELETED' &&
            stuPath.status !== 'CLOSED'
          ) { allPaths.push(stuPath); }
        });

        return allPaths.reduce((formattedList, stuPath) => {
          const parsedStatus = SPEC_OPP_KEY_MAP[stuPath.status];
          const stuPathStatus = parsedStatus || stuPath.status;
          const dataObj = {
            careerName: { data: stuPath.path.name },
            status: { data: stuPathStatus, dependencies: { studentPathId: stuPath._id } },
          };
          formattedList.sections[0].count++;
          formattedList.sections[0].data.push(dataObj);
          return formattedList;
        }, formattedList);
      }),
    );
  }
}
