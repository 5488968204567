import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import * as _ from 'lodash';
import { ImSchool } from 'Src/ng2/shared/services/im-models/im-school';
import { UtilitiesService } from 'Src/ng2/shared/services/utilities/utilities.service';
import { GraduationPlanTransfer } from '../../../../../../ng2/shared/constants/graduation-plan-transfer.constant';
import { CreditGapsPriorityGroupingsTransferService, IPriorityGroupDefinition } from '../../../services/credit-gaps-priority-groupings/credit-gaps-priority-groupings-transfer.service';

type TToggleValue = 'creditMaxGapsNoPlan' | 'creditMaxGaps';

@Component({
  selector: 'credit-gaps-transfer-table',
  templateUrl: './credit-gaps-transfer-table.component.html',
  styleUrls: ['./styles/credit-gaps-generic-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class CreditGapsWizardTransferTable implements OnInit {
  @Input() filteredStudents: any;
  @Input() currentFilter: string;

  public tableToggle: TToggleValue = 'creditMaxGaps';
  public tableHeaders: string[];
  public table;
  public totalsData;
  public studentWithUnaddressedGaps: string[];
  public studentWithGaps: string[];
  public options: { columnKeys: string[] } = {
    columnKeys: ['gradPlan', 'creditMaxGaps', 'creditMaxGapsNoPlan'],
  }

  private tableData;
  private creditGapThresholdBuckets;
  private priorityGrouping: IPriorityGroupDefinition[];
  private nextFourGradDates;
  private creditGapThresholdsBaseObj = {
    '16+': {
      human: '16.51+',
      buckets: {
        Total: [],
      },
      color: 'red',
    },
    '11To16': {
      human: '11.01-16.5',
      buckets: {
        Total: [],
      },
      color: 'pink',
    },
    '5To11': {
      human: '5.51-11.0',
      buckets: {
        Total: [],
      },
      color: 'orange',
    },
    '1To5': {
      human: '0.01-5.5',
      buckets: {
        Total: [],
      },
      color: 'yellow',
    },
    zero: {
      human: '0',
      buckets: {
        Total: [],
      },
      color: 'green',
    },
    // Total row:
    totals: {
      Total: [],
    },
  };

  constructor (
    private creditGapsPriorityGroupingsTransferService: CreditGapsPriorityGroupingsTransferService,
    private imSchool: ImSchool,
    private utilitiesService: UtilitiesService,
  ) { }

  ngOnInit () {
    this.nextFourGradDates = this.imSchool.getNextFourGradDatesForTransfer();
    this.priorityGrouping = _.dropRight(
      this.creditGapsPriorityGroupingsTransferService.getPriorityGroupsByPlannedGradDateFilter(null),
      1,
    );
    this.updateTable();
  }

  ngOnChanges ({ currentFilter, filteredStudents }): void {
    if (!currentFilter.firstChange || !filteredStudents.firstChange) {
      this.updateTable();
    }
  }

  updateTable (): void {
    this.creditGapThresholdBuckets = this.generateCreditGapBuckets();
    this.tableHeaders = this.generateTableHeaders();
    this.tableData = this.buildCreditGapsTable();
    this.table = _.omit(this.tableData, 'totals');
    this.totalsData = _.get(this.tableData, 'totals');

    this.studentWithUnaddressedGaps = _.reduce(
      this.filteredStudents,
      (result, { creditMaxGapsNoPlan, studentId }) => {
        if (creditMaxGapsNoPlan < Math.abs(0)) result.push(studentId);
        return result;
      },
      [],
    );

    this.studentWithGaps = _.reduce(
      this.filteredStudents,
      (result, { creditMaxGaps, studentId }) => {
        if (creditMaxGaps < Math.abs(0)) result.push(studentId);
        return result;
      },
      [],
    );
  }

  orderTableRows = (a, b) => a.key;

  private generateTableHeaders (): string[] {
    return _.reduce(
      this.priorityGrouping,
      (result: any, group) => {
        const { plannedGradDateFilter: gradDate } = group;
        result.push(gradDate);
        return result;
      },
      ['Total'],
    ); // ensure column order is correct
  }

  private generateCreditGapBuckets () {
    return _.forOwn(this.creditGapThresholdsBaseObj, (row: any, k) => {
      if (k === 'totals') _.each(this.priorityGrouping, group => (row[group.plannedGradDateFilter] = []));
      else _.each(this.priorityGrouping, group => (row.buckets[group.plannedGradDateFilter] = []));
    });
  }

  private mapGradPlanToTableHeaders (gradPlan: string) {
    // handle plans that don't map cleanly columnHeaders / baseObj buckets
    if (gradPlan === GraduationPlanTransfer.PLAN_IN_PAST.humanName) {
      return _.find(this.priorityGrouping, { key: 'PLAN_INCOMPLETE' }).plannedGradDateFilter;
    }
    if (gradPlan === GraduationPlanTransfer.NON_GRADUATE.humanName) {
      return _.find(this.priorityGrouping, { key: 'PLANNED_NON_GRAD' }).plannedGradDateFilter;
    }
    // find in GraduationPlanTransfer constant - sanity check
    const planObject = _.find(GraduationPlanTransfer, { humanName: gradPlan });
    // is it `planned` with date or not
    if (!planObject.date) return planObject.humanName;
    // if so, is that date beyond the next four grad dates?
    else if (planObject.date && _.find(this.nextFourGradDates, { humanName: planObject.date })) {
      // no
      return planObject.date;
    } else {
      // yes
      return _.find(this.priorityGrouping, { key: 'PLANNED_BEYOND_4' }).plannedGradDateFilter;
    }
  }

  private buildCreditGapsTable () {
    // maintains a clean copy of the buckets on each filter change
    const creditGapThresholdBuckets = this.utilitiesService.copyPOJO(this.creditGapThresholdBuckets);
    // wrapper for repeated functionality in .each
    const setValues = (studentId, gradPlan, creditThreshold): void => {
      creditGapThresholdBuckets[creditThreshold].buckets[gradPlan].push(studentId);
      creditGapThresholdBuckets[creditThreshold].buckets.Total.push(studentId);
      creditGapThresholdBuckets.totals[gradPlan].push(studentId);
      // always push, this is the total row:
      creditGapThresholdBuckets.totals.Total.push(studentId);
    };
    _.each(this.filteredStudents, student => {
      // const set dynamically via table toggle
      const creditGaps = student[this.tableToggle];
      const { studentId, gradPlan } = student;
      const mappedGradPlan = this.mapGradPlanToTableHeaders(gradPlan);
      // bucket logic
      if (creditGaps === Math.abs(0)) setValues(studentId, mappedGradPlan, 'zero');
      else if (creditGaps < 0 && creditGaps >= -5.5) setValues(studentId, mappedGradPlan, '1To5');
      else if (creditGaps < -5.5 && creditGaps >= -11.0) setValues(studentId, mappedGradPlan, '5To11');
      else if (creditGaps < -11.0 && creditGaps >= -16.5) setValues(studentId, mappedGradPlan, '11To16');
      else if (creditGaps < -16.51) setValues(studentId, mappedGradPlan, '16+');
    });
    return creditGapThresholdBuckets;
  }
}
