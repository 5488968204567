import { NvNoteSummerModule } from '../nv-note-summer/nv-note-summer.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvProfilePanelContainerComponent } from './nv-profile-panel-container.component';

@NgModule({
  imports: [CommonModule, NvNoteSummerModule],
  declarations: [NvProfilePanelContainerComponent],
  exports: [NvProfilePanelContainerComponent],
})
export class NvProfilePanelContainerModule {}
