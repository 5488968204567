import { StudentSupportStatuses } from './../../../../shared/constants/student-support-statuses.constant';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { IRowData } from 'Src/ng2/shared/models/list-models';
import { IDropdownOption } from '../../../../../../projects/shared/nvps-libraries/design/interfaces/design-library.interface';
import { capitalize } from 'lodash';

// Export for tests
export const STUDENT_SUPPORTS_PANEL_LIST_KEBAB_OPTIONS = ['UPDATE_SUPPORT', 'MARK_SUPPORT_COMPLETE', 'DELETE_SUPPORT_RECORD'];
export const STUDENT_SUPPORTS_PANEL_LIST_KEBAB_OPTIONS_COMPLETED = ['UPDATE_SUPPORT', 'DELETE_SUPPORT_RECORD'];
const ROW_INDEXES = {
  SUPPORT_NAME: 0,
  SUPPORT_TIER: 1,
  STUDENT_SUPPORT_STATUS: 2,
  STUDENT_SUPPORT_ATTENDANCE: 3,
  MISSED_STUDENT_SUPPORT_SESSIONS: 4,
  STUDENT_SUPPORT_START_AND_END_DATE: 5,
  STUDENT_SUPPORT_UPDATE_KEBAB: 6,
};

@Component({
  selector: 'student-supports-panel-list-kebab',
  templateUrl: './student-supports-panel-list-kebab.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class StudentSupportsPanelListKebabComponent implements OnInit {
  @Input() input: { row: IRowData[] };
  @Output() output = new EventEmitter();
  options: IDropdownOption[];
  studentSupport: {
    _id: string;
    startsOn: string;
    endsOn: string;
    support: {
      name: string;
      schedule: {
        startsOn: string;
        endsOn: string;
      };
    };
  };

  public ngOnInit () {
    const { row } = this.input;
    if (row && row.length) {
      const {
        SUPPORT_NAME,
        STUDENT_SUPPORT_STATUS,
        STUDENT_SUPPORT_UPDATE_KEBAB,
        STUDENT_SUPPORT_START_AND_END_DATE,
      } = ROW_INDEXES;
      const studentSupportId = row[STUDENT_SUPPORT_UPDATE_KEBAB].data;
      const supportName: string = row[SUPPORT_NAME].data;
      
      const {
        support: { startDate: supportStartsOn, endDate: supportEndsOn },
        studentSupport: { startDate: studentSupportStartsOn, endDate: studentSupportEndsOn },
      } = JSON.parse(row[STUDENT_SUPPORT_START_AND_END_DATE].meta);

      this.studentSupport = {
        _id: studentSupportId,
        startsOn: studentSupportStartsOn,
        endsOn: studentSupportEndsOn,
        support: {
          name: supportName,
          schedule: {
            startsOn: supportStartsOn,
            endsOn: supportEndsOn,
          },
        },
      };
      // Initialize kebab options
      const status = row[STUDENT_SUPPORT_STATUS].data.toUpperCase();
      const isCompleted = status === StudentSupportStatuses.backend.COMPLETED.toUpperCase();
      const options = isCompleted
        ? STUDENT_SUPPORTS_PANEL_LIST_KEBAB_OPTIONS_COMPLETED
        : STUDENT_SUPPORTS_PANEL_LIST_KEBAB_OPTIONS;
      this.options = this._initDropdownOptions(options);
    }
  }

  private _initDropdownOptions (options: string[]): IDropdownOption[] {
    return options.map(option => ({
      key: option,
      human: capitalize(option.split('_').join(' ')), // titlecase
    }));
  }

  public onSelect (action: string): void {
    this.output.emit({
      action,
      studentSupport: this.studentSupport,
    });
  }
}
