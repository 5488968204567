import { IPathSubdoc } from './../../../../typings/interfaces/studentPaths.interface';
import { formToStudentPathSubdoc } from './../path-category-form-utils';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PATH_CATEGORY_KEYS } from '../../../../constants/student-paths.constant';
import { BaseStudentPathModalForm } from '../base-student-path-modal-form.component';
import { ApiService } from '../../../../services/api-service/api-service';

@Component({
  selector: 'career-training-program-form',
  templateUrl: './career-training-program-form.component.html',
  encapsulation: ViewEncapsulation.None,
})

export class CareerTrainingProgramForm extends BaseStudentPathModalForm implements OnInit {
  public formGroup: FormGroup;

  // empty state text
  public readonly emptyStateOrganizations = 'No organizations found';
  public readonly emptyStateSpecialtyArea = 'No specialty area found';
  public readonly emptyStateTransitionSupportOrg = 'No transition support organizations found';

  constructor (
    apiService: ApiService,
    private formBuilder: FormBuilder,
  ) {
    super(apiService);
  };

  ngOnInit (): void {
    this.formGroup = this.formBuilder.group({
      areaOfStudy: [null, Validators.required],
      organization: [null],
      specialtyArea: [null],
      transitionSupportOrg: [null],
    });

    super.initForm(PATH_CATEGORY_KEYS.CAREER_TRAINING_PROGRAM, this.schoolId);
  }

  getStudentPath (): IPathSubdoc {
    const studentPath = formToStudentPathSubdoc(this.formGroup);
    return studentPath;
  }

  handleClick (): void {
    this.onEmitStudentPath();
  }
}
