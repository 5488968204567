import { createFeatureSelector, createSelector } from '@ngrx/store';
import { map } from 'lodash';
import { IDocLogsState } from './../reducers/doc-logs-reducer';

const getPortalState = createFeatureSelector('PORTAL_STORE');

export const getDocLogsState = createSelector(
  getPortalState,
  (state: any) => {
    return state.docLogs;
  },
);

export const getDocLogsEntities = createSelector(
  getDocLogsState,
  (state: IDocLogsState) => {
    return state.docLogsEntities;
  },
);

export const getDocLogsEntitiesList = createSelector(
  getDocLogsState,
  (state: IDocLogsState) => {
    const docLogsEntities = state.docLogsEntities;
    return map(docLogsEntities, (v, k) => v);
  },
);

export const getDocLogsLoadingStatus = createSelector(
  getDocLogsState,
  (state: IDocLogsState) => {
    return state.loading;
  },
);

export const getDocLogsLoadedStatus = createSelector(
  getDocLogsState,
  (state: IDocLogsState) => {
    return state.loaded;
  },
);
