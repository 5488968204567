import { CommonModule } from '@angular/common';
import { NetworkLeftSideNavModule } from '../../../network/network-left-side-nav/network-left-side-nav.module';
import { SideNavNgModule } from '../../../shell/side-nav/side-nav.module';
import { MaintenanceMode } from './maintenance-mode.component';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [
    CommonModule,
    SideNavNgModule,
    NetworkLeftSideNavModule,
  ],
  declarations: [MaintenanceMode],
  exports: [MaintenanceMode],
})
export class MaintenanceModeModule {}
