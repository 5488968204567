export const STUDENT_PATH_PROJECTION = {
  _id: true,
  studentId: true,
  key: true,
  dueAt: true,
  status: true,
  'path.name': true,
  'path.type': true,
  'path.careerType': true,
  'path.years': true,
  latestCUNYReport: true,
};

export const STUDENT_PATH_JOINS = [];
