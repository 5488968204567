import { IPatchEvent, WizardHelpers } from './../../../../services/wizard-helpers.service';
import { RegentsExam, IRegentsExam } from './../../../../../../shared/constants/regents.constant';
import { Component, Input, OnInit, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { each, reduce, map } from 'lodash';

interface IStudentRecRatCourseData {
  _id: string;
  studentId: string;
  recommendedRegentsBasedOnCulminatingCourseRationle: string[]; // examNames[ ]
}

@Component({
  selector: 'culminating-course-step',
  templateUrl: './culminating-course-step.component.html',
  styleUrls: ['../shared-step-styles.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class CulminatingCourseStep implements OnInit {
  @Input() filteredStudents
  @Output() studentPatch: EventEmitter<any> = new EventEmitter();

  public stepLabel: string = 'Create Regents plans for students in Regents-culminating courses'

  public studentRecRationalCulmCourseData: IStudentRecRatCourseData[];
  public regentsRecRationalsByExamName;
  public totalExamsWithRecRationalCourse: number;
  public studentIdsWithRecRationalCourse: string[];
  public patches;
  public languageHelper;
  public options;

  constructor (public wizardHelpers:WizardHelpers) {}

  ngOnInit () {
    this.studentRecRationalCulmCourseData = this.getStudentRegentsRecRationalesDataCulmCourse();
    this.regentsRecRationalsByExamName = this.organizeRegentsRecRationalDataByExamName();
    this.studentIdsWithRecRationalCourse = this.getStudentIdsWithRegentsRecRationalCourse();
    this.totalExamsWithRecRationalCourse = this.wizardHelpers.getCountOfStudentsToBePatched(
      this.regentsRecRationalsByExamName,
    );
    this.patches = this.wizardHelpers.getRegentsExamBulkPatch(this.regentsRecRationalsByExamName, 'humanName');
    this.languageHelper = this.wizardHelpers.getRegentsWizardLanguageHelper('humanName');

    this.options = {
      columnKeys: [
        'recommendedRegentsBasedOnCulminatingCourseRationle',
        'regentsSchedForNextAdmin',
        'currentCourseCodes',
      ],
    };
  }

  ngOnChanges ({ filteredStudents }) {
    if (filteredStudents && !filteredStudents.isFirstChange()) {
      this.ngOnInit();
    }
  }

  private getStudentRegentsRecRationalesDataCulmCourse (): IStudentRecRatCourseData[] {
    return map(this.filteredStudents, ({ _id, studentId, recommendedRegentsBasedOnCulminatingCourseRationle }) => ({
      _id,
      studentId,
      recommendedRegentsBasedOnCulminatingCourseRationle,
    }));
  }

  private organizeRegentsRecRationalDataByExamName () {
    const baseObj = reduce(
      RegentsExam,
      (result: any, { humanName }: IRegentsExam) => {
        result[humanName] = { _ids: [], studentIds: [] };
        return result;
      },
      {},
    );
    each(this.studentRecRationalCulmCourseData, studentData => {
      const { _id, studentId, recommendedRegentsBasedOnCulminatingCourseRationle: recommendedRegents } = studentData;
      each(recommendedRegents, examShortName => {
        // logic to check for global and us regents can be removed once RegentsExam.GLOBAL.isOffered and RegentsExam.US.isOffered are set to `false`
        // more info: https://newvisions.atlassian.net/browse/PFD-12096
        if (examShortName !== RegentsExam.US.shortName && examShortName !== RegentsExam.GLOBAL.shortName) {
          baseObj[examShortName]._ids.push(_id);
          baseObj[examShortName].studentIds.push(studentId);
        }
      });
    });
    return baseObj;
  }

  private getStudentIdsWithRegentsRecRationalCourse (): string[] {
    return reduce(
      this.studentRecRationalCulmCourseData,
      (studentIdsArr, { recommendedRegentsBasedOnCulminatingCourseRationle, studentId }) => {
        if (recommendedRegentsBasedOnCulminatingCourseRationle.length >= 1) studentIdsArr.push(studentId);
        return studentIdsArr;
      },
      [],
    );
  }

  patchStudents (patches, count) {
    const patchEvent: IPatchEvent = {
      message: `This will bulk schedule ${count} exams`,
      patches,
    };
    this.studentPatch.emit(patchEvent);
  }
}
