import { MaintenanceModeModule } from './../../../shared/components/maintenance-mode/maintenance-mode.module';
import { NetworkLeftSideNavModule } from './../../network-left-side-nav/network-left-side-nav.module';
import { NetworkStandardLayoutComponent } from './network-standard-layout.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [NetworkStandardLayoutComponent],
  imports: [CommonModule, RouterModule, NetworkLeftSideNavModule, MaintenanceModeModule],
  exports: [NetworkStandardLayoutComponent],
  providers: [],
  schemas: [],
})
export class NetworkStandardLayoutModule {}
