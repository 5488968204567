import { UrlMatchResult, UrlSegment } from '@angular/router';
import { RedirectGuard } from '../../guards/redirect-guard/redirect-guard';

function bookmarkedNYCUrlMatcher (url: UrlSegment[]): UrlMatchResult | null {
  const urlsToMatch = ['network', 'cuny', 'school-picker', 'explore', 'school', 'nyc-expand-ed', 'cuny-grid'];
  if (url.length && urlsToMatch.includes(url[0].path)) {
    return {
      consumed: url,
    };
  }
  return null;
}

export const redirectRoute = {
  matcher: bookmarkedNYCUrlMatcher,
  canActivate: [RedirectGuard],
  children: [],
};
