/* tslint:disable */
import { Action } from '@ngrx/store';
import { TBatchActionsOrigin } from 'Src/ng2/shared/components/nv-actions/nv-actions.interface';

export * from './students-actions/students-postsecondary-actions';

export interface IListSort {
  [key: number]: {
    columnKey: string;
    sort: 'asc' | 'desc';
  };
}

export interface IListDimensions {
  [key: string]: string;
}

export interface IBulkUpdateStudentsPayload {
  patches: {
    _ids: string[];
    path: string,
    newValue: any
  },
  origin?: TBatchActionsOrigin // the origin where originates the batch action requests to the api
}

/** ALL STUDENTS ACTIONS */
export const BULK_UPDATE_STUDENTS = '[Portal] Bulk Update Students';
export const BULK_UPDATE_STUDENTS_SUCCESS = '[Portal] Bulk Update Students Success';
export const BULK_UPDATE_STUDENTS_FAIL = '[Portal] Bulk Update Students Fail';

export class BulkUpdateStudents implements Action {
  readonly type = BULK_UPDATE_STUDENTS;
  constructor(public payload: IBulkUpdateStudentsPayload) {}
}

export class BulkUpdateStudentsSuccess implements Action {
  readonly type = BULK_UPDATE_STUDENTS_SUCCESS;
  constructor(public payload: any) {}
}

export class BulkUpdateStudentsFail implements Action {
  readonly type = BULK_UPDATE_STUDENTS_FAIL;
  constructor(public payload: any) {}
}

// action types
export type StudentAction =
  // ALL STUDENTS
  BulkUpdateStudents | BulkUpdateStudentsSuccess | BulkUpdateStudentsFail;
