<base-modal [itemCount]="itemCount" [itemType]="itemType" [title]="title">
  {{ itemCount }}
  <span [ngPlural]="itemCount">
    <ng-template ngPluralCase="=1">{{ itemType }}</ng-template>
    <ng-template ngPluralCase="other">{{ itemType }}s</ng-template>
  </span>

  will be planned for the <b> {{ regentsExam }} </b> exam in <b> {{ nextRegentsAdminDate }}</b>. Any student already
  planned for {{ regentsExam }} in a future admin will have their plan changed to <b> {{ nextRegentsAdminDate }}</b>.

  <div class="dialog-actions">
    <nv-button type="secondary" (click)="close()"> Cancel </nv-button>
    <nv-button htmlType="submit" (click)="close(true)"> Assign </nv-button>
  </div>
</base-modal>
