<base-modal
  [title]="title"
  [itemCount]="itemCount"
  [itemType]="itemType"
  [isProfileMode]="isProfileMode"
>
  <!-- progress states -->
  <mat-dialog-content *ngIf="!underStudentLimit">
    <p>Please select 200 students or less</p>
  </mat-dialog-content>

  <mat-dialog-content *ngIf="underStudentLimit" [ngClass]="state.toLowerCase()">
    
    <ng-container *ngIf="state === 'NOT_STARTED'">
      <nv-form-field label="Report type">
        <nv-dropdown
          [selected]="selectedReportId"
          [options]="reportOptions"
          [returnObject]="true"
          (selectOption)="handleReportTypeSelect($event)"
        ></nv-dropdown>
      </nv-form-field>
    </ng-container>

    <ng-container *ngIf="state === 'GENERATING'">
      <div class="loading-spinner">
        <ng-container *ngTemplateOutlet="loadingIllustration"></ng-container>
        <mat-progress-spinner diameter="50" [mode]="mode"></mat-progress-spinner>
      </div>
      <div class="loading-text">
        <div class="loading-text-line1">Generating PDF&hellip;</div>
        <div class="loading-text-line2">This could take several minutes</div>
      </div>
    </ng-container>

  </mat-dialog-content>

  <mat-dialog-actions class="dialog-actions">
    <nv-button 
      [type]="state === 'NOT_STARTED' ? 'secondary' : 'primary'" 
      (clickButton)="cancel()"
    >Cancel</nv-button>
    <nv-button 
      *ngIf="state === 'NOT_STARTED'"
      [disabled]="!underStudentLimit"
      (clickButton)="generate()"
    >Generate</nv-button>
  </mat-dialog-actions>

</base-modal>

<ng-template #loadingIllustration>
<svg class="loading-illustration" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="109" height="139" viewBox="0 0 109 139">
  <defs>
    <path id="a" d="M0 0h108.856v138.791H0z"/>
    <mask id="b" fill="#fff"><use xlink:href="#a"/></mask>
  </defs>
  <g fill="none" fill-rule="evenodd">
    <use fill="#FFF" xlink:href="#a"/>
    <path fill="#F3F6FD" d="M0 0h108.856v43.542H0z" mask="url(#b)"/>
    <path stroke="#CEDBF6" d="M.5.5h107.856v137.791H.5z"/>
    <path fill="#F3F6FD" d="M6.317 51.346h96.374V90.6H6.317zM6.372 98.486h96.295v23.482H6.372z"/>
    <rect width="22.606" height="2.804" x="7.886" y="11.916" fill="#CEDBF6" rx="1.402"/>
    <rect width="9.113" height="2.453" x="5.958" y="4.907" fill="#CEDBF6" rx="1.227"/>
    <rect width="21.379" height="1.752" x="83.765" y="3.505" fill="#CEDBF6" rx=".876"/>
    <rect width="3.68" height="2.453" x="102.166" y="133.709" fill="#F3F6FD" rx="1.227"/>
    <rect width="64.489" height="1.752" x="14.895" y="16.122" fill="#E7EDFB" rx=".876"/>
    <rect width="15.071" height="1.752" x="6.484" y="25.41" fill="#E7EDFB" rx=".876"/>
    <rect width="15.071" height="1.752" x="30.842" y="25.41" fill="#E7EDFB" rx=".876"/>
    <rect width="15.071" height="1.752" x="54.5" y="25.41" fill="#E7EDFB" rx=".876"/>
    <rect width="18.225" height="1.752" x="74.302" y="25.41" fill="#E7EDFB" rx=".876"/>
    <rect width="64.489" height="1.752" x="14.895" y="18.4" fill="#CEDBF6" rx=".876"/>
  </g>
</svg>
</ng-template>
