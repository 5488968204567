import { assign, map, reduce } from 'lodash';
import { STUDENT_PATH_GROUP } from '../../constants/student-paths.constant';
import { IListViewSection } from '../../typings/interfaces/list-view.interface';
import { COLUMN_DATA_TYPE } from './../../constants/list-view/cell-type.constant';
import { F_AND_P_LEVELS } from './grouping-functions/assessments/assessments-groupings';

export interface IFormattedListSection {
  name: string;
  count: number;
  data: any[];
  defaultDisplayCount: number;
  tooltip?: string;
}
export interface IFormattedList {
  listType: string;
  columns: any[];
  sections: IFormattedListSection[];
}

/* istanbul ignore next */
export const FORMATTED_GROUPING_LISTS = {
  // doeRiskGroup and schoolRiskGroup share the same formatedList
  // but tooltip is now dynamically set (JChu)
  academic_risk_groups: {
    getFormattedList ({ columns, listType, madLibModel }) {
      const formattedList = {
        listType,
        columns: _formatColumnsForList(columns),
        sections: [
          {
            name: 'Pushable 2',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
            dataType: 'String',
            key: 'pushable2',
          },
          {
            name: 'Pushable 3',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
            dataType: 'String',
            key: 'pushable3',
          },
          {
            name: 'Slippable 2',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
            dataType: 'String',
            key: 'slippable2',
          },
          {
            name: 'Slippable 3',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
            dataType: 'String',
            key: 'slippable3',
          },
          {
            name: 'Below proficiency',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
            dataType: 'String',
            key: 'belowProficiency',
          },
          {
            name: 'Proficient',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
            dataType: 'String',
            key: 'proficient',
          },
          {
            name: 'No data',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
            dataType: 'String',
            key: 'noData',
          },
        ],
      };
      this._setTooltips(formattedList, madLibModel);
      return formattedList;
    },
    // currently only restricted to risk groupings in academic list
    // could be modified to be reusable down the road (JChu)
    // no arrow func here to capture `this` owning object (JChu)
    _setTooltips (formattedList, madLibModel) {
      const _TOOLTIPS = {
        SET_1: {
          belowProficiency: 'Students whose Proficiency Rating falls below 1.75 or between 2.00 - 2.75 in this subject',
          noData: 'Students with no NYS Exam scores on file',
          proficient: 'Students whose Proficiency Rating falls above 3.25',
        },
        SET_2: {
          pushable2: 'Students whose Proficiency Rating falls between 1.75 - 1.99 in this subject',
          pushable3: 'Students whose Proficiency Rating falls between 2.75 - 2.99 in this subject',
          slippable2: 'Students whose Proficiency Rating falls between 2.00 - 2.25 in this subject',
          slippable3: 'Students whose Proficiency Rating falls between 3.00 - 3.25 in this subject',
        },
        SET_2_APPEND: 'or who have been manually assigned into this category',
      };
      let tooltipsToSet: { [header: string]: string };
      switch (madLibModel.Groupings.key) {
        case 'DOE_RISK_GROUP':
          tooltipsToSet = assign({}, _TOOLTIPS.SET_1, _TOOLTIPS.SET_2);
          break;
        case 'SCHOOL_RISK_GROUP':
          tooltipsToSet = reduce(
            _TOOLTIPS.SET_2,
            (res, tip, header) => {
              res[header] = `${tip} ${_TOOLTIPS.SET_2_APPEND}`;
              return res;
            },
            {},
          );
          break;
        // default will never be called, put default here to avoid ts-lint error (JChu)
        default:
          break;
      }
      formattedList.sections.forEach((section: IListViewSection) => {
        section.tooltip = tooltipsToSet[section.key];
      });
    },
  },
  supports: {
    getFormattedList: ({ columns, listType }) => {
      return {
        listType,
        columns: _formatColumnsForList(columns),
        sections: [
          {
            name: `No ${listType.toLowerCase()} supports`,
            count: 0,
            data: [],
            defaultDisplayCount: 10,
            dataType: 'String',
            key: 'supports',
          },
        ],
      };
    },
  },
  subjectSupports: {
    getFormattedList: ({ columns, listType, subject }) => {
      return {
        listType,
        columns: _formatColumnsForList(columns),
        sections: [
          {
            name: `No ${subject} supports`,
            count: 0,
            data: [],
            defaultDisplayCount: 10,
            dataType: 'String',
            key: 'subjectSupports',
          },
        ],
      };
    },
  },
  priority: {
    getFormattedList: ({ columns, listType }) => {
      return {
        listType,
        columns: _formatColumnsForList(columns),
        sections: [
          // this should never happen... will remove this
          {
            name: 'No priority',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
            dataType: 'String',
            key: 'priority',
          },
        ],
      };
    },
  },
  progress: {
    getFormattedList: ({ columns, listType }) => {
      return {
        listType,
        columns: _formatColumnsForList(columns),
        sections: [
          {
            name: 'Need attention',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
            dataType: 'String',
            key: 'progress',
            tooltip: 'Students currently failing or borderline one or more courses',
          },
          {
            name: 'Passing',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
            dataType: 'String',
            key: 'progress',
          },
          {
            name: 'No grades',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
            dataType: 'String',
            key: 'progress',
          },
          {
            name: 'No courses',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
            dataType: 'String',
            key: 'progress',
          },
        ],
      };
    },
  },
  flags: {
    getFormattedList: ({ columns, listType }) => {
      return {
        listType,
        columns: _formatColumnsForList(columns),
        sections: [
          {
            name: `No ${listType.toLowerCase()} flags`,
            count: 0,
            data: [],
            defaultDisplayCount: 10,
            dataType: 'String',
            key: 'flags',
          },
        ],
      };
    },
  },
  has_flags: {
    getFormattedList: ({ columns, listType }) => {
      return {
        listType,
        columns: _formatColumnsForList(columns),
        sections: [
          {
            name: 'Have flags',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
            dataType: 'String',
            key: 'hasFlags',
          },
          {
            name: 'Do not have flags',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
            dataType: 'String',
            key: 'hasFlags',
          },
        ],
      };
    },
  },
  trend: {
    getFormattedList: ({ columns, listType }) => {
      return {
        listType,
        columns: _formatColumnsForList(columns),
        sections: [
          {
            name: 'No data',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
            dataType: 'String',
            key: 'trend',
          },
        ],
      };
    },
  },
  att_risk_group: {
    getFormattedList: ({ columns, listType }) => {
      return {
        listType,
        columns: _formatColumnsForList(columns),
        sections: [
          {
            name: 'Severely chronically absent',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
            dataType: 'String',
            key: 'attRiskGroup',
            tooltip: 'Less than 80% year-to-date attendance',
          },
          {
            name: 'Chronically absent',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
            dataType: 'String',
            key: 'attRiskGroup',
            tooltip: '80-89.99% year-to-date attendance',
          },
          {
            name: 'At risk',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
            dataType: 'String',
            key: 'attRiskGroup',
            tooltip: '90-94.99% year-to-date attendance',
          },
          {
            name: 'Not at risk',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
            dataType: 'String',
            key: 'attRiskGroup',
            tooltip: '95+% year-to-date attendance',
          },
          {
            name: 'No data',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
            dataType: 'String',
            key: 'attRiskGroup',
            tooltip: 'No year-to-date attendance on file',
          },
        ],
      };
    },
  },
  milestone: {
    getFormattedList: ({ columns, listType }) => {
      return {
        listType,
        columns: _formatColumnsForList(columns),
        sections: [
          {
            name: 'College milestones',
            count: 0,
            data: [],
            defaultDisplayCount: 100,
            dataType: 'String',
            key: 'milestone',
          },
        ],
      };
    },
  },
  postsecondary_group_milestones: {
    getFormattedList: ({ columns, listType }) => {
      return {
        listType,
        columns: _formatColumnsForList(columns),
        sections: [
          {
            name: 'Complete',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
            dataType: 'String',
            key: 'postsecondaryGroupMilestones',
            tooltip: 'All milestones complete',
          },
          {
            name: 'Incomplete',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
            dataType: 'String',
            key: 'postsecondaryGroupMilestones',
            tooltip: 'Some milestones incomplete',
          },
        ],
      };
    },
  },
  postsecondary_group_app_status: {
    getFormattedList: ({ columns, listType }) => {
      return {
        listType,
        columns: _formatColumnsForList(columns),
        sections: [
          {
            name: STUDENT_PATH_GROUP.NEED_TO_APPLY,
            count: 0,
            data: [],
            defaultDisplayCount: 10,
            dataType: 'String',
            key: 'postsecondaryGroupAppStatus',
            tooltip: 'Students who have not applied to all colleges and careers on their list',
          },
          {
            name: STUDENT_PATH_GROUP.NEED_FINAL_DECISION,
            count: 0,
            data: [],
            defaultDisplayCount: 10,
            dataType: 'String',
            key: 'postsecondaryGroupAppStatus',
            tooltip: 'Students who have completed their applications but have no final decision',
          },
        ],
      };
    },
  },
  admit_status: {
    getFormattedList: ({ columns, listType }) => {
      return {
        listType,
        columns: _formatColumnsForList(columns),
        sections: [
          {
            name: 'New',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
            dataType: 'String',
            key: 'admitStatus',
          },
          {
            name: 'Returning',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
            dataType: 'String',
            key: 'admitStatus',
          },
          {
            name: 'No data',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
            dataType: 'String',
            key: 'admitStatus',
          },
        ],
      };
    },
  },
  class_of: {
    getFormattedList: groupingData => {
      const { columns, listType, navData, navDataType, navRoute } = groupingData;
      return {
        listType,
        columns: _formatColumnsForList(columns),
        sections: [
          {
            name: 'Class',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
            dataType: 'String',
            key: 'classOf',
          },
        ],
        navDataType,
        navData,
        navRoute,
      };
    },
  },
  grade: {
    getFormattedList: groupingData => {
      const { columns, listType, navData, navDataType, navRoute } = groupingData;
      return {
        listType,
        columns: _formatColumnsForList(columns),
        sections: [
          {
            name: 'Grade',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
            dataType: 'Number',
            key: 'grade',
          },
        ],
        navDataType,
        navData,
        navRoute,
      };
    },
  },
  grad_plan: {
    getFormattedList: groupingData => {
      const { columns, listType, navData, navDataType, navRoute } = groupingData;
      return {
        listType,
        columns: _formatColumnsForList(columns),
        sections: [
          { name: 'Grad Plan', count: 0, data: [], defaultDisplayCount: 10, dataType: 'String', key: 'gradPlan' },
        ],
        navDataType,
        navData,
        navRoute,
      };
    },
  },
  point_person: {
    getFormattedList: groupingData => {
      const { columns, listType, navData, navDataType, navRoute } = groupingData;
      return {
        listType,
        columns: _formatColumnsForList(columns),
        sections: [
          { name: 'Point Person', count: 0, data: [], defaultDisplayCount: 10, dataType: 'String', key: 'pointPerson' },
        ],
        navDataType,
        navData,
        navRoute,
      };
    },
  },
  has_point_people: {
    getFormattedList: ({ columns, listType }) => {
      return {
        listType,
        columns: _formatColumnsForList(columns),
        sections: [
          {
            name: 'Need point people',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
            dataType: 'String',
            key: 'hasPointPeople',
          },
          {
            name: 'Have point people',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
            dataType: 'String',
            key: 'hasPointPeople',
          },
        ],
      };
    },
  },
  college_path: {
    getFormattedList: ({ columns, listType }) => {
      return {
        listType,
        columns: _formatColumnsForList(columns),
        sections: [
          {
            name: 'College list',
            count: 0,
            data: [],
            defaultDisplayCount: 100,
            dataType: 'String',
            key: 'collegePath',
          },
        ],
      };
    },
  },
  career_path: {
    getFormattedList: ({ columns, listType }) => {
      return {
        listType,
        columns: _formatColumnsForList(columns),
        sections: [
          { name: 'Career list', count: 0, data: [], defaultDisplayCount: 100, dataType: 'String', key: 'careerPath' },
        ],
      };
    },
  },
  applied_to_all_colleges: {
    getFormattedList: ({ columns, listType }) => {
      return {
        listType,
        columns: _formatColumnsForList(columns),
        sections: [
          { name: 'Applied to all', count: 0, data: [], defaultDisplayCount: 10, tooltip: 'Applied to all colleges' },
          { name: 'Applied to some', count: 0, data: [], defaultDisplayCount: 10, tooltip: 'Applied to some colleges' },
          { name: 'Applied to none', count: 0, data: [], defaultDisplayCount: 10, tooltip: 'Applied to no colleges' },
        ],
      };
    },
  },
  created_postsecondary_list: {
    getFormattedList: ({ columns, listType }) => {
      return {
        listType,
        columns: _formatColumnsForList(columns),
        sections: [
          {
            name: 'Complete',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
            tooltip: 'Complete Finalized College List',
          },
          {
            name: 'Incomplete',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
            tooltip: 'Incomplete Finalized College List',
          },
        ],
      };
    },
  },
  accepted_to_one_or_more_colleges: {
    getFormattedList: ({ columns, listType }) => {
      return {
        listType,
        columns: _formatColumnsForList(columns),
        sections: [
          {
            name: 'Have acceptances',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
            tooltip: 'Accepted to one or more college',
          },
          {
            name: 'No acceptances',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
            tooltip: 'Has some responses, but no acceptances',
          },
          {
            name: 'No responses',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
            tooltip: 'Has no responses',
          },
        ],
      };
    },
  },
  have_final_decision_institution: {
    getFormattedList: ({ columns, listType }): IFormattedList => {
      return {
        listType,
        columns: _formatColumnsForList(columns),
        sections: [
          {
            name: 'No decision',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
            tooltip: 'Have no final decision',
          },
        ],
      };
    },
  },
  have_final_decision_type: {
    getFormattedList: ({ columns, listType }): IFormattedList => {
      return {
        listType,
        columns: _formatColumnsForList(columns),
        sections: [
          {
            name: '2 year college',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
          },
          {
            name: '4 year college',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
          },
          {
            name: 'Job training',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
          },
          {
            name: 'Certification program',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
          },
          {
            name: 'Apprenticeship',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
          },
          {
            name: 'Public service',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
          },
          {
            name: 'Military',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
          },
          {
            name: 'Other career',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
          },
          {
            name: 'No decision',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
            tooltip: 'Have no final decision',
          },
        ],
      };
    },
  },
  f_and_p_relative_score: {
    getFormattedList: ({ columns, listType }): IFormattedList => {
      return {
        listType,
        columns: _formatColumnsForList(columns),
        sections: [
          {
            name: '3+ below',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
            tooltip: '',
          },
          {
            name: '2 below',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
            tooltip: '',
          },
          {
            name: '1 below',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
            tooltip: '',
          },
          {
            name: 'At or above level',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
            tooltip: '',
          },
          {
            name: 'Need assessment',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
            tooltip: '',
          },
        ],
      };
    },
  },

  f_and_p_instruc_score: {
    getFormattedList: ({ columns, listType }): IFormattedList => {
      const sectionValues = Object.keys(F_AND_P_LEVELS);
      const sections = map(sectionValues, val => {
        return {
          name: val,
          count: 0,
          data: [],
          defaultDisplayCount: 10,
          tooltip: '',
        };
      });
      const needsAssessment = {
        name: 'Needs assessment',
        count: 0,
        data: [],
        defaultDisplayCount: 10,
        tooltip: '',
      };
      return {
        listType,
        columns: _formatColumnsForList(columns),
        sections: [...sections, needsAssessment],
      };
    },
  },

  f_and_p_support: {
    getFormattedList: ({ columns, listType }): IFormattedList => {
      return {
        listType,
        columns: _formatColumnsForList(columns),
        sections: [
          {
            name: 'No supports',
            count: 0,
            data: [],
            defaultDisplayCount: 10,
            tooltip: '',
          },
        ],
      };
    },
  },
};

function _formatColumnsForList (columns) {
  const formattedColumns = columns.reduce((formattedList, col) => {
    if (col.cellType !== COLUMN_DATA_TYPE.SECTION_HEADER) {
      formattedList[col.key] = {
        name: col.human,
        cellType: col.cellType,
        cellConfig: col.cellConfig,
        dataType: col.dataType,
        orderBy: col.orderBy,
        tooltip: col.tooltip,
      };
    }
    return formattedList;
  }, {});
  return formattedColumns;
}
