import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { without } from 'lodash';
import { FormValidatorsService } from 'Src/ng2/shared/services/form-validators/form-validators.service';

type TNonEmailFormControls = {
  [controlName: string]: FormControl;
};

type TEmailFormControls = {
  [controlName: string]: {
    formControl: FormControl;
    emailType: string;
    placeholder: string;
  };
};

@Injectable()
export class ClusterUserModalShellService {
  constructor(private formValidatorService: FormValidatorsService) {}

  /**
   * Returns form controls for non email fields
   *
   * @param {*} { userBasic}
   * @returns {TNonEmailFormControls}
   * @memberof ClusterUserModalShellService
   */
  public getNonEmailFormControls({ userBasic }): TNonEmailFormControls {
    const firstName = (userBasic && userBasic.firstName) || '';
    const firstNameControl = this._getFormControlForName(firstName);
    const lastName = (userBasic && userBasic.lastName) || '';
    const lastNameControl = this._getFormControlForName(lastName);
    const title = (userBasic && userBasic.title) || '';
    const titleControl = this._getFormControlForTitle(title);
    const canManageUsers = (userBasic && userBasic.canManageUsers) || false;
    const canManageUsersControl = this._getFormControlForCheckbox(canManageUsers);
    return {
      firstNameControl,
      lastNameControl,
      titleControl,
      canManageUsersControl,
    };
  }

  /**
   * Returns form controls for email fields
   *
   * @param {*} { userBasic, clusterDetail, emailDupes }
   * @returns {TEmailFormControls}
   * @memberof ClusterUserModalShellService
   */
  public getEmailFormControls({ userBasic, clusterDetail, emailDupes }): TEmailFormControls {
    const requireEmailControl = this._getFormControlForEmail({
      userBasic,
      clusterDetail,
      emailDupes,
      isRequiredEmail: true,
    });
    let optionalEmailControl;
    if (clusterDetail.loginMethod.optional) {
      optionalEmailControl = this._getFormControlForEmail({
        userBasic,
        clusterDetail,
        emailDupes,
        isRequiredEmail: false,
      });
    }
    return {
      requireEmailControl,
      optionalEmailControl,
    };
  }

  /**
   *
   *
   * @private
   * @param {*} name
   * @returns {FormControl}
   * @memberof ClusterUserModalShellService
   */
  private _getFormControlForName(name): FormControl {
    const peopleNameValidator = this.formValidatorService.peopleNameValidatorCtr();
    const formControl = new FormControl(name, {
      validators: [Validators.required, peopleNameValidator],
    });
    return formControl;
  }

  /**
   *
   *
   * @private
   * @param {*} title
   * @returns {FormControl}
   * @memberof ClusterUserModalShellService
   */
  private _getFormControlForTitle(title): FormControl {
    return new FormControl(title);
  }

  /**
   *
   *
   * @private
   * @param {*} canManageUsers
   * @returns {FormControl}
   * @memberof ClusterUserModalShellService
   */
  private _getFormControlForCheckbox(canManageUsers): FormControl {
    return new FormControl(canManageUsers);
  }

  /**
   *
   *
   * @private
   * @param {*} {
   *     userBasic,
   *     clusterDetail,
   *     emailDupes,
   *     isRequiredEmail,
   *   }
   * @returns {{
   *     formControl: FormControl;
   *     emailType: string;
   *     placeholder: string;
   *   }}
   * @memberof ClusterUserModalShellService
   */
  private _getFormControlForEmail({
    userBasic,
    clusterDetail,
    emailDupes,
    isRequiredEmail,
  }): {
    formControl: FormControl;
    emailType: string;
    placeholder: string;
  } {
    const gafeEmail = (userBasic && userBasic.gafeEmail) || '';
    const doeEmail = (userBasic && userBasic.doeEmail) || '';
    const isClusterTrustedUser = userBasic && userBasic.isClusterTrustedUser;
    const { approvedDomains, loginMethod } = clusterDetail;
    const methodName = isRequiredEmail ? loginMethod.required : loginMethod.optional;
    const { emailType, placeholder, domains, initialVal } = this._getFormControlUiValues({
      methodName,
      approvedDomains,
      gafeEmail,
      doeEmail,
    });
    let validators = [
      Validators.email,
      this.formValidatorService.emailDomainValidatorCtr({ domains }),
      this.formValidatorService.emailDupesValidatorCtr({ emailDupes }),
    ];
    if (isRequiredEmail) {
      validators = validators.concat(Validators.required);
    }
    const formControl = new FormControl(initialVal, validators);
    if (isClusterTrustedUser === false) {
      formControl.disable();
    }
    return { formControl, emailType, placeholder };
  }

  /**
   *
   *
   * @private
   * @param {*} {
   *     methodName,
   *     approvedDomains,
   *     gafeEmail,
   *     doeEmail,
   *   }
   * @returns {{ emailType: string; placeholder: string; domains: string[]; initialVal: string }}
   * @memberof ClusterUserModalShellService
   */
  private _getFormControlUiValues({
    methodName,
    approvedDomains,
    gafeEmail,
    doeEmail,
  }): { emailType: string; placeholder: string; domains: string[]; initialVal: string } {
    let domains;
    let emailType;
    let initialVal;
    switch (methodName) {
      case 'DOE':
        domains = ['schools.nyc.gov'];
        emailType = 'doeEmail';
        initialVal = doeEmail;
        break;
      case 'Google':
        const argArray = [approvedDomains].concat(['schools.nyc.gov']);
        domains = without.apply(this, argArray);
        emailType = 'gafeEmail';
        initialVal = gafeEmail;
      default:
        break;
    }
    const placeholder = domains.join(', ');
    return { emailType, placeholder, domains, initialVal };
  }

  /**
   *
   *
   * @param {*} { firstNameFormControl, lastNameFormControl }
   * @returns {string}
   * @memberof ClusterUserModalShellService
   */
  public getUserFullName({ firstNameFormControl, lastNameFormControl }): string {
    const first = firstNameFormControl.value;
    const last = lastNameFormControl.value;
    return `${first} ${last}`;
  }
}
