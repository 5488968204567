import { BatchActionsService } from 'Src/ng2/school/lists/services/batch-actions/batch-actions.service';
import { NextTermPlanningDataService } from './next-term-planning-data/next-term-planning-data.service';
import { ColDef } from '@ag-grid-community/core';
import { GridService } from './../../grid/services/grid.service';
import { Component, OnInit, Inject, ComponentFactoryResolver, ViewContainerRef, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { find } from 'lodash';
import * as moment from 'moment';

import { CsvExporterService, CsvType } from 'Src/ng2/shared/services/csv-exporter/csv-exporter.service';
import { ISchool } from '../../../shared/typings/interfaces/school.interface';
import { IGapPlan } from '../../../shared/typings/interfaces/gap-plan.interface';
import { unsubscribeComponent } from '../../../shared/helpers/unsubscribe-decorator/unsubscribe-decorators.helper';
import { IGroupData, IListConfig, IRowData } from 'Src/ng2/shared/models/list-models';
import { TSortDirections } from 'Src/ng2/shared/services/list-services/sort-and-filter.service';
import { IDropdownOption } from 'projects/shared/nvps-libraries/design/interfaces/design-library.interface';
import { ListHeaderService } from '../../lists/services/list-headers/list-headers.service';
import { FixedToInfiniteViewComponent } from 'Src/ng2/shared/components/list/fixed-to-infinite-view-container/fixed-to-infinite-view.component';
import { IListData } from 'Src/ng2/shell/content-tools/content-tools.component';
import { ListSummaryGroupings } from 'Src/ng2/shared/services/list-summary-groupings/list-summary-groupings.service';
import { MadlibService } from '../../lists/services/madlib/madlib.service';
import { IListSummaryGrouping } from 'Src/ng2/shared/components/list-summary/list-summary.component';
import { SpinnerService } from 'Src/ng2/shared/components/spinner/spinner-modal.service';
import { ObjectCache } from 'Src/ng2/shared/services/object-cache/object-cache.service';
import { ImSchool } from 'Src/ng2/shared/services/im-models/im-school';
import { Store } from '@ngrx/store';
import { WindowRef } from 'projects/shared/services/window-ref/windowRef';
import { RollbarService } from 'Src/ng2/shared/services/rollbar/rollbar.service';
import * as Rollbar from 'rollbar';
import { UrlPathService } from 'Src/ng2/shared/services/url-path-service/url-path.service';
import { MISC_PAGE_ORIGINS } from 'Src/ng2/shared/services/mixpanel/event-interfaces/misc-page';
import { SharedEventTrackers } from 'Src/ng2/shared/services/mixpanel/event-trackers/shared-tracking.service';
import { PORTAL_TYPES } from 'Src/ng2/shared/typings/interfaces/portal.interface';

@Component({
  selector: 'next-term-planning-container',
  templateUrl: './next-term-planning-container.component.html',
  styleUrls: ['./next-term-planning-container.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
@unsubscribeComponent
// @ts-ignore
export class NextTermPlanningContainer extends FixedToInfiniteViewComponent implements OnInit {
  private school: ISchool;
  // MetaData
  public schoolId: string;
  public listData: IListData;
  public pageHeaderMeta = {
    title: 'Next Term Planning',
    subTitle: null,
    icon: {
      tooltip: null,
    },
  };

  public listSummaryGroupData: IListSummaryGrouping[];
  public activeList: 'INFINITE' | 'FIXED';
  public options: IDropdownOption[];
  public optionSelected: string;
  private defaultTermYear;
  public currentTermYear: number;
  public gapPlans: IGapPlan[];
  public madLibSelection: number;
  public columnsProjection: string[] = ['DEPARTMENT_NAME', 'STUDENTS'];

  // list container props
  public listConfig: IListConfig;
  public columns;
  public dynamicColumns = new BehaviorSubject(null);
  public dynamicColumnIndexMap = new BehaviorSubject(null);
  public columnIndexMap: { [columnKey: string]: number };
  public groupingData$: BehaviorSubject<IGroupData[]> = new BehaviorSubject(null);
  public dynamicComponentInputData: any;

  public filterFormControl: FormControl;
  public sortKey$: BehaviorSubject<string>;
  public sortDirection$: BehaviorSubject<TSortDirections>;
  public updateSort: Function;
  public batchActionsMode$: Observable<boolean>;
  public onRowClick: Function;

  // SuperProps
  public onFixed: Function;
  public onInfinite: Function;
  public onBatchAction: Function;
  public onDynamicComponentClick: Function = () => null;
  public onUiRowDataUpdate: Function;
  public uiRowData;

  // Refs
  @ViewChild('entry', { read: ViewContainerRef, static: true }) entry: ViewContainerRef;
  @ViewChild('listContent', { static: true }) listContent: ElementRef;

  public noDataMessage: any;

  constructor (
    private nextTermPlanningDataService: NextTermPlanningDataService,
    private spinnerService: SpinnerService,
    public resolver: ComponentFactoryResolver,
    public listSummaryGroupings: ListSummaryGroupings,
    public madlibService: MadlibService,
    private route: ActivatedRoute,
    private csvExporterService: CsvExporterService,
    private listHeaderService: ListHeaderService,
    private gridService: GridService,
    private objectCache: ObjectCache,
    private imSchool: ImSchool,
    private store: Store<any>,
    private windowRef: WindowRef,
    private batchActionsService: BatchActionsService,
    private urlPathService: UrlPathService,
    private sharedEventTrackers: SharedEventTrackers,
    @Inject(RollbarService) private Rollbar: Rollbar,

  ) {
    // We are extending the fixed-to-infinite functionality
    super(resolver, batchActionsService, store);
  }

  ngOnInit (): void {
    this.sortKey$ = new BehaviorSubject(null);
    this.sortDirection$ = new BehaviorSubject(null);
    this.batchActionsMode$ = of(false);
    this.filterFormControl = new FormControl();
    this.columns = this.nextTermPlanningDataService.getGapPlansColumns();
    this.onRowClick = this.goToGrid;
    this.nextTermPlanningDataService.getSchool$().pipe(
      tap(school => {
        this.school = school;
      },
      ),
    ).subscribe();

    const { schoolId } = this.route.snapshot.params;
    this.schoolId = schoolId;

    this.columnIndexMap = this.columns.reduce((mapping, col: any, i: number) => {
      mapping[col.graphQlKey] = i;
      return mapping;
    }, {});

    this.onFixed = this.updateCssForListType('FIXED');
    this.onInfinite = this.updateCssForListType('INFINITE');
    this.listConfig = {
      emptyTableMessage: 'No planned courses found. Please select another term.',
      listType: 'GAP_PLANS',
      noDataMessage: 'No planned courses found. Please select another term.',
      sortableColumns: false,
      maximumVisibleRowsPerGroup: 5,
    };

    this.onUiRowDataUpdate = ($event: IGroupData) => {
      const groupings = Object.values($event);
      this.uiRowData = groupings;
      this.updateContentToolsData(groupings);
    };

    this.trackMiscPageView();
    this.updateGroupingData();
  }

  private updateGroupingData (): void {
    const { schoolId } = this.route.snapshot.params;
    this.nextTermPlanningDataService.getGapPlans$(schoolId, this.optionSelected, this.columnsProjection)
      .pipe(
        tap(res => {
          const {
            data: {
              GapPlansGrouping: { groupData, groupingOptions, defaultTermYear },
            },
          } = res;
          this.groupingData$.next(groupData);
          this.options = groupingOptions;
          this.setDefaultOption(defaultTermYear);
        }),
      ).subscribe();
  }

  private setDefaultOption (defaultTermYear) {
    if (!this.defaultTermYear) {
      this.defaultTermYear = defaultTermYear;
      const option = this.options.find(option => option.key === this.defaultTermYear);
      this.optionSelected = option ? option.key : this.options[0].key;
    }
  }

  public onMadLibChange (e: string): void {
    this.optionSelected = e;
    this.updateGroupingData();
  }

  public generateCsv (): void {
    const docType = 'text/csv;charset=utf-8';
    const termYear = parseInt(this.optionSelected, 10);
    const currentDateAndTime = moment().format('MM-DD-YYYY hmma');
    const fileName = `Planned Courses for ${this.getHumanReadableTerm(this.optionSelected)} ${currentDateAndTime}.csv`;
    const spinner = this.spinnerService.openSpinner({ message: 'Generating Export' });

    this.csvExporterService.createNextTermPlanningCsv(this.school, termYear)
      .then(csvString => {
        spinner.close();
        this.csvExporterService.exportCsv(csvString, fileName, docType, CsvType.Student);
      })
      .catch(err => {
        this.Rollbar.warning('Unable to complete Next Term Planning CSV export');
        this.Rollbar.warning(err);
      });
  }

  private goToGrid (rowData: IRowData[]): void {
    const row = find(rowData, 'meta');
    const studentId = JSON.parse(row.meta);
    const schoolId = this.schoolId;
    const filter = this.objectCache.cacheObject({ studentId });
    const url = this.urlPathService.computeDistrictUrlPath(`school/${schoolId}/credit-gaps/grid?filter=${filter}`);
    this.windowRef.nativeWindow.open(url, '_blank');
  }

  // Not removing method in case we need to route user to new Data Grid in the future
  private routeToNewDataGrid ({ schoolId, studentId }) {
    const gridType = this.imSchool.getGridType(this.school);
    const columnFieldsNeededInOrder = this.nextTermPlanningDataService.getColumnFieldsForDataGrid(gridType);
    this.gridService.getGridConfig$({ schoolId, gridType }).pipe(
      map((columns) => this.getNeededColumnDefsInOrder({ allColumnDefs: columns, columnFieldsNeededInOrder })),
      tap(columns => {
        const filterHash = this.objectCache.cacheObject({ OSIS_NUMBER: studentId });
        const stateHash = this.objectCache.cacheObject(columns);
        const queryParams = `filter=${filterHash}&state=${stateHash}&isNextTermPlanningGridRunning=true`;
        const url = this.urlPathService.computeDistrictUrlPath(`/school/${schoolId}/data-grid?${queryParams}`);
        this.windowRef.nativeWindow.open(url, '_blank');
      }),
    ).subscribe();
  }

  private getNeededColumnDefsInOrder ({ allColumnDefs, columnFieldsNeededInOrder }): ColDef[] {
    const hiddenColumns = allColumnDefs.reduce((acc, def) => {
      if (!columnFieldsNeededInOrder.includes(def.field)) {
        acc.push({ ...def, hide: true, colId: def.field });
      }
      return acc;
    }, []);
    const columnsToShow = columnFieldsNeededInOrder.map((field) => {
      const columnDef = allColumnDefs.find((def) => def.field === field);
      return { ...columnDef, hide: false, colId: field };
    }, {});

    return [...columnsToShow, ...hiddenColumns];
  }

  private getHumanReadableTerm (termYear: string): string {
    const termCode = termYear.toString();
    const term = termCode.slice(2) === '7' ? 'Summer' : `Term ${Number(termCode.slice(2))}`;
    const startSchoolYear = Number(termCode.slice(0, 2));
    const endSchoolYear = startSchoolYear + 1;
    return `${term} SY${startSchoolYear}-${endSchoolYear}`;
  }

  public updateCssForListType (listMode: 'INFINITE' | 'FIXED'): Function {
    return (instance?: ElementRef): void => {
      this.activeList = listMode;
      const addClassForListMode = listMode === 'INFINITE' ? 'infinite' : 'fixed';
      this.listContent.nativeElement.classList.add(addClassForListMode);
      const removeClassForListMode = listMode === 'INFINITE' ? 'fixed' : 'infinite';
      this.listContent.nativeElement.classList.remove(removeClassForListMode);
      this.pageHeaderMeta =
          listMode === 'INFINITE'
            ? { ...this.pageHeaderMeta, ...{ icon: this.listHeaderService.getInfiniteHeaderIcon(instance) } }
            : { ...this.pageHeaderMeta, ...{ icon: this.listHeaderService.getFixedHeaderIcon() } };
    };
  }

  public updateContentToolsData (groupData: IGroupData[], visibleGroupings?: string[]): void {
    const groupings = visibleGroupings ? groupData.filter(group => visibleGroupings.includes(group.key)) : groupData;
    this.listData = this.listSummaryGroupings.getExportListData({
      groupings,
      columns: this.madlibService.getColumnDataBasedOnMadlibModel(this.madlibModel).columns,
      madlibModel: this.madlibModel,
      listType: 'NextTermPlanning',
    });
    this.listSummaryGroupData = [this.listSummaryGroupings.getGroupings(this.madlibModel.value, groupings)];
  }

  public onFocusedGroup ($event: {
    groupData: IGroupData;
    sortKey: string;
    sortDirection: TSortDirections;
    groupIndx: number;
  }) {
    const { groupIndx } = $event;
    const newGroupings: IGroupData[] = [];
    this.groupingData$.value.forEach((grouping: IGroupData, i: number) => {
      const newGrouping = { ...grouping };
      if (i === groupIndx) {
        newGrouping.showAll = true;
      }
      newGroupings.push(newGrouping);
    });
    this.groupingData$.next(newGroupings);
  }

  private trackMiscPageView () {
    const pageName = 'Next Term Planning';
    const origin = MISC_PAGE_ORIGINS.OTHER_TOOLS;
    const portal = PORTAL_TYPES.SCHOOL;
    this.sharedEventTrackers.trackMiscPageView({ pageName, origin, portal });
  }
}
