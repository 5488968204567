import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BaseModalModule } from '../base-modal.module';
import { MultiSelectFilterModalComponent } from './multi-select-filter-modal.component';
import { NvMultiSelectFilterSectionModule } from 'Src/nvps-libraries/design/nv-multi-select-filter-section/nv-multi-select-filter-section.module';
import { NvButtonModule } from 'projects/shared/nvps-libraries/design/nv-button/nv-button.module';

@NgModule({
  declarations: [MultiSelectFilterModalComponent],
  imports: [
    CommonModule,
    BaseModalModule,
    NvMultiSelectFilterSectionModule,
    NvButtonModule,
  ],
  exports: [],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MultiSelectFilterModalModule {}
