<input
  #input
  type="text"
  class="sentence-case {{ icon !== null ? 'left-icon' : '' }} {{ isClearBtnVisible ? 'right-icon' : '' }} {{
    isTextValid ? '' : 'is-invalid'
  }}"
  [placeholder]="placeholder"
  [formControl]="textControl"
  [mask]="maskFormat"
  (focus)="onFocus($event)"
  (click)="onClick($event)"
  data-lpignore="true"
  autocomplete="off"
  [maxlength]="maxLength ?? null"
/>
<!-- Angular only allows one ControlValueAccessor per element. ngx-mask and matAutocompleteTrigger have their own respective ControlValueAccessors, so they cannot be associated to the same element at the same time. In order for mask feature to work alongside autocomplete feature, the following input is hidden, with its only purpose is to trigger autocomplete panel when user focuses on and types in the maskable, visible input, above. -->

<!-- We are hiding the following input element using CSS (visibility: hidden) instead of type="hidden" in the element itself. The reason is due to type="hidden" treating the element as display: none; however, we require the input to behave as visibility: hidden. If not, the autocomplete panel anchors to the top left of the view port. -->
<input
  class="hidden-input"
  type="text"
  #autocompleteTrigger="matAutocompleteTrigger"
  [matAutocomplete]="menu"
  data-lpignore="true"
/>

<nv-icon *ngIf="icon !== null" class="left" [name]="icon" [color]="isTextValid ? 'blue' : 'red'"></nv-icon>

<nv-icon-button 
  *ngIf="hasClearBtn && isClearBtnVisible"
  name="close-large-blue"
  [color]="isTextValid ? 'blue' : 'red'"
  (clickButton)="clearSearch()"
  (click)="onClick($event)"
  class="close-button"
></nv-icon-button>

<mat-autocomplete #menu="matAutocomplete" class="nv-mat-autocomplete" [disableRipple]="false"
  [autoActiveFirstOption]="false">
  <mat-option 
    [value]="option"
    [ngClass]="option.selectable ? 'nv-mat-option' : 'nv-mat-option nv-mat-option-empty_state'" 
    *ngFor="let option of autocompleteOptions
      | filterMatDisplayOptions: textControl.value: optionsPredicateCb
      | transformToMatDisplayOptions
      | sortByPriorityOptions: textControl.value: weightedOptionsSortingEnabled
      | limitMatDisplayOptions: optionsLimit: moreOptionsText
      | appendEmptyStateOption: emptyStateText">

    <div [ngClass]="{ 'nv-mat-option-label': option.selectable }">{{ option.human }}</div>

    <div class="nv-mat-option-tags" *ngIf="option.tags">
      <ng-container *ngFor="let tag of option.tags; let i = index">
        {{ tag.human }}<ng-container *ngIf="i < option.tags.length - 1">,</ng-container>&nbsp;
      </ng-container>
    </div>
  </mat-option>

  <ng-container *ngIf="isAutocompleteActionVisible">
    <ng-content select="nv-autocomplete-action"></ng-content>
  </ng-container>
</mat-autocomplete>
