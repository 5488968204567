import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BackgroundJobSpinnerModal } from './background-job-spinner-modal.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { BaseModalModule } from '../base-modal.module';

@NgModule({
  declarations: [BackgroundJobSpinnerModal],
  imports: [
    CommonModule,
    BaseModalModule,
    MatProgressSpinnerModule,
    MatDialogModule,
  ],
  exports: [BackgroundJobSpinnerModal],
})
export class BackgroundJobSpinnerModalModule {}
