import { Injectable } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { TTooltipData, ITooltipContent } from './nv-shared-tooltip.interface';

@Injectable()
export class NvSharedTooltipDataService {
  getFormattedRowData (rowData: ITooltipContent['rowData']): ITooltipContent['rowData'] {
    return rowData;
  }

  getTooltipContent (tooltipData: TTooltipData): Observable<any> {
    if (!tooltipData) return EMPTY;
    if (typeof tooltipData === 'string') return of({ data: { Tooltip: { content: { simpleContent: tooltipData } } } });
    const { simpleContent, tableContent } = tooltipData;
    if (simpleContent) return of({ data: { Tooltip: { content: { simpleContent } } } });
    if (tableContent) return of({ data: { Tooltip: { content: { tableContent } } } });
    return EMPTY;
  }
}
