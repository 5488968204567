import * as _ from 'lodash';

import { UtilitiesService } from 'Src/ng2/shared/services/utilities/utilities.service';
import { ImModelsHelpers } from '../../../helpers/im-models/im-models.helper';
import { ImStudent } from '../im-student.service';
import {
  ICurrProgramCourse,
  IStudent,
  TValidCoursePriorities,
} from '../../../typings/interfaces/student.interface';
import { Injectable } from '@angular/core';
import { ImStudentCurrentProgramHelpers } from '../im-student-credit-gaps-helpers/im-student-current-program-helpers';

export interface ICourseWarnings {
  show: boolean;
  messages: string[];
}

/**
 * `@depends` decorator
 *
 * Use this decorator to state that an ImStudentCurrentProgram method depends on certain paths, joins or other methods:
 *    @depends({ paths: ['studentDetails.name'], joins: ['courseDiffs'], methods: ['_getSomething']})
 *    fullName(student) {  }
 *
 * @param depends Object an object with three optional keys: `paths`, `joins` and `methods`
 * @return {dependencyDecorator}
 */
function depends (depends) {
  return function dependencyDecorator (target, key, descriptor) {
    descriptor.value.depends = depends;
    return descriptor;
  };
}

@Injectable()
export class ImStudentCurrentProgram {
  constructor (private ImStudent: ImStudent, private UtilitiesService: UtilitiesService) {}

  /**
   * Removes fields from `student` that are not included in the dependent paths or joins
   *
   * @param method
   * @returns {Student} masked student
   */
  maskedStudentForMethod (student, method) {
    const paths = this.pathsFor(method);
    const joins = this.joinsFor(method);
    _.each(joins, join => {
      paths.push(`join_${join}`);
    });
    return this.UtilitiesService.generatePatch(student, paths);
  }

  _dependentMethods (method) {
    const resolvedMethods = [];
    const unresolvedMethods = [];

    // See here for the dependency resolution algorithm used:
    // https://www.electricmonk.nl/log/2008/08/07/dependency-resolving-algorithm/
    const resolveMethods = (method, resolvedMethods, unresolvedMethods) => {
      unresolvedMethods.push(method);
      const methods = (this[method].depends || {}).methods || [];
      _.each(methods, m => {
        if (!_.includes(resolvedMethods, m)) {
          if (_.includes(unresolvedMethods, m)) {
            throw new Error(`Circular method dependency detected: ${method} -> ${m}`);
          }
          resolveMethods(m, resolvedMethods, unresolvedMethods);
        }
      });
      resolvedMethods.push(method);
      _.pull(unresolvedMethods, method);
    };

    resolveMethods(method, resolvedMethods, unresolvedMethods);
    return resolvedMethods;
  }

  /**
   * Traverses the method dependency graph and returns all of the paths that `method` depends on
   *
   * @param method a ImStudentCurrentProgram method
   * @return {Array} and array of paths
   */
  pathsFor (method) {
    if (!this[method]) throw new Error(`method '${method}' does not exist`);

    if (!(this[method].depends || {}).methods) {
      const paths = (this[method].depends || {}).paths;
      if (!_.isArray(paths)) {
        console.warn(
          `ImStudent#pathsFor('${method}') was called, but ${method} didn't declare any dependent paths. ` +
            `If ${method} doesn't depend on any paths, annotate it with \`@depends({ paths: [] })\``,
        );
      }
      return paths;
    }
    const dependentMethods = this._dependentMethods(method);
    const dependentPaths = _.uniq(
      _.flatten(
        _.map(dependentMethods, m => {
          const depends = this[m].depends;
          if (!depends) {
            console.warn(`\`${m}\` was detected as a dependent method, but it didn't register any dependencies itself`);
            return [];
          }
          return depends.paths || [];
        }),
      ),
    );
    return dependentPaths;
  }

  /**
   * Traverses the method dependency graph and returns all of the joins that `method` depends on
   *
   * @param method a ImStudentCurrentProgram method
   * @return {Array} and array of joins
   */
  joinsFor (method) {
    if (!this[method]) throw new Error(`method '${method}' does not exist`);

    if (!(this[method].depends || {}).methods) return (this[method].depends || {}).joins;
    const dependentMethods = this._dependentMethods(method);
    const dependentJoins = _.uniq(
      _.flatten(
        _.map(dependentMethods, m => {
          const depends = this[m].depends;
          if (!depends) {
            console.warn(`\`${m}\` was detected as a dependent method, but it didn't register any dependencies itself`);
            return [];
          }
          return depends.joins || [];
        }),
      ),
    );
    return dependentJoins;
  }

  @depends({
    paths: [...ImStudent.prototype.pathsFor(['getCoursesForCurrentTermYear'])],
    methods: [],
  })
  getHighPriorityCourses (student: IStudent, mp?): ICurrProgramCourse[] {
    const coursesForCurrentTermYear = this.ImStudent.getCoursesForCurrentTermYear(student);
    const hpCourses = _.filter(coursesForCurrentTermYear, { priority: 'high' });
    return hpCourses;
  }

  @depends({
    paths: [...ImStudent.prototype.pathsFor(['getCoursesForCurrentTermYear'])],
    methods: [],
  })
  getCoursesFailingForMp (student: IStudent, mp?): ICurrProgramCourse[] {
    const coursesForCurrentTermYear: ICurrProgramCourse[] = this.ImStudent.getCoursesForCurrentTermYear(student);
    const mpFilter = !mp ? { isMostRecent: true } : { mp };
    const failingCourses = _.filter(coursesForCurrentTermYear, course => {
      // TODO: Add typings? (SR)
      const courseMark: any = _.find(course.marks, mpFilter);
      return courseMark && courseMark.isFailing;
    }) as ICurrProgramCourse[];
    return failingCourses;
  }

  @depends({
    paths: [],
    methods: ['getCoursesFailingForMp'],
  })
  getCoursesFailingForMpByPriority (student: IStudent, priority: TValidCoursePriorities, mp?): ICurrProgramCourse[] {
    const coursesFailingForMp = this.getCoursesFailingForMp(student, mp);
    const priorityFailures = _.filter(coursesFailingForMp, { priority });
    return priorityFailures;
  }

  @depends({
    paths: [...ImModelsHelpers.expandPath('creditDetails.byArea.<gradReq>.earned')],
    methods: [],
  })
  getCurrSyCourseWarningInfo (student: IStudent, course: ICurrProgramCourse, district: string): ICourseWarnings {
    const creditedOnTranscript = course.isCreditedOnTranscript;
    const overCreditLimit = course.isOverCreditLimit;
    const show = creditedOnTranscript || overCreditLimit;
    const messages = [];

    if (creditedOnTranscript) {
      const message = 'Student has already earned credit for this course.';
      messages.push(message);
    }

    if (overCreditLimit) {
      const subjectArea = ImStudentCurrentProgramHelpers.getHumanReadableSubjectAreaForCourse(course, district);
      const suffix = subjectArea || 'subject';
      const message = 'Student has already earned the maximum credits allowed for ' + suffix + '.';
      messages.push(message);
    }

    return {
      show,
      messages,
    };
  }
}
