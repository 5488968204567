import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { NvAriaLabelModule } from 'projects/shared/nvps-libraries/design/directives/nv-aria-label/nv-aria-label.module';
import { NvIconModule } from 'projects/shared/nvps-libraries/design/nv-icon/nv-icon.module';
import { NvDropdownMenuModule } from '../nv-dropdown-menu/nv-dropdown-menu.module';
import { NvSidebarItemComponent } from './nv-sidebar-item.component';

@NgModule({
  imports: [
    A11yModule,
    CommonModule,
    MatMenuModule,
    MatRippleModule,
    NvAriaLabelModule,
    NvDropdownMenuModule,
    NvIconModule,
    RouterModule,
  ],

  declarations: [NvSidebarItemComponent],
  exports: [NvSidebarItemComponent],
})
export class NvSidebarItemModule {}
