import { Component, ElementRef, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseModalComponent } from '../../modals/base-modal.component';

export interface ISpinnerModalComponentData {
  message: string;
}

@Component({
  selector: 'spinner-modal',
  templateUrl: './spinner-modal.component.html',
  styleUrls: ['./spinner-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SpinnerModalComponent extends BaseModalComponent implements OnInit {
  message: string;

  constructor (
    dialogRef: MatDialogRef<SpinnerModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ISpinnerModalComponentData,
  ) {
    super(dialogRef);
  }

  ngOnInit (): void {
    this.message = this.data.message;
  }
}
