import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CreditGapsWizardContainerRoutingModule } from './credit-gaps-wizard-container-routing.module';
import { CreditGapsWizardContainer } from './credit-gaps-wizard-container.component';
import { CreditGapsNonTransferWizardModule } from './non-transfer/credit-gaps-non-transfer-wizard.module';
import { CreditGapsTransferWizardModule } from './transfer/credit-gaps-transfer-wizard.module';
@NgModule({
  declarations: [
    CreditGapsWizardContainer,
  ],
  imports: [CommonModule, CreditGapsWizardContainerRoutingModule, CreditGapsNonTransferWizardModule, CreditGapsTransferWizardModule],
  exports: [CreditGapsWizardContainer],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CreditGapsWizardContainerModule { }
