import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';
import { BaseModalComponent } from '../base-modal.component';
import { IBaseModalData } from '../modals.service';
import { RegularExpressionsUtility } from '../../utilities/regular-expressions/regular-expressions.utility';
import { GridIconMenuActions } from 'Src/ng2/school/server-side-grid/server-side-grid.component';

export interface IGridViewMiniInfo {
  gridViewName?: string | null;
  gridViewId?: string | null;
}

export interface IGridViewNameModalComponentData extends IBaseModalData, IGridViewMiniInfo {
  title: string;
  subtitle: string | null;
  gridViewName: string | null;
  gridViewId: string | null;
  existingGridViews: any [];
  menuAction: GridIconMenuActions;
}

@Component({
  selector: 'gridview-name-modal',
  templateUrl: './gridview-name-modal.component.html',
  styleUrls: ['./gridview-name-modal.component.scss'],
})
export class GridViewNameModalComponent extends BaseModalComponent implements OnInit {
  public gridViewForm: FormGroup;
  public placeholder: string = 'Enter view name';
  public iconName: string = 'close-large-blue';
  private isInvalidGridViewName: boolean = false;

  public gridViewName: string;
  public characterLimit: number = 60;
  private existingGridViews: any[];
  public warningMessage = '';
  public isExistingGridViewName: boolean = false;
  public isNameInValid: boolean = false;
  public isExceededCharLimit: boolean = false;
  public modalAction: GridIconMenuActions;

  // Modal Configurations
  public title: string;
  public subtitle: string | null = null;
  public mainButtonLabel: string;

  constructor (
    @Inject(MAT_DIALOG_DATA) public data: IGridViewNameModalComponentData,
    dialogRef: MatDialogRef<GridViewNameModalComponent>,
    private formBuilder: FormBuilder,
  ) {
    super(dialogRef);
  }

  public ngOnInit (): void {
    const {
      title,
      subtitle,
      gridViewName,
      existingGridViews,
      menuAction,
    } = cloneDeep(this.data);

    this.title = title;
    this.subtitle = subtitle;
    this.existingGridViews = existingGridViews;
    this.modalAction = menuAction;
    this.mainButtonLabel = (menuAction === GridIconMenuActions.Create) ? 'Next' : 'Save';
    this.gridViewForm = this.formBuilder.group({
      gridViewName: [gridViewName || ''],
    });

    // Listen to value changes of gridViewName control and update isInvalidGridViewName result
    this.gridViewForm.get('gridViewName').valueChanges.subscribe(() => {
      this.isInvalidGridViewName = this.validateGridName();
    });
  }

  public onCancel (): void {
    this.dialogRef.close();
  }

  public onSave (): void {
    const newGridViewName = this.gridViewForm.controls.gridViewName.value;

    if (!this.isUniqueGridViewName(newGridViewName)) {
      this.dialogRef.close({ newGridViewName });
    } else {
      this.warningMessage = 'Please enter a unique view name';
      this.gridViewForm.get('gridViewName').setErrors({ incorrect: true });
      this.isExistingGridViewName = true;
    }
  }

  public isUniqueGridViewName (newGridViewName: string): boolean {
    const existingName = this.existingGridViews.find((gvName) => gvName.human.trim() === newGridViewName.trim());
    return (existingName?.key?.length > 0);
  }

  public onClearInput (): void {
    this.gridViewForm.get('gridViewName')?.setValue('');
    this.gridViewForm.get('gridViewName')?.markAsDirty();
  }

  public validateGridName (): boolean {
    const gvNameText = this.gridViewForm.get('gridViewName')?.value;
    const isChanged = this.gridViewForm.dirty; // Check if user made changes
    this.isNameInValid = gvNameText ? this.gridViewNameValidator(gvNameText) : false;
    this.isExistingGridViewName = this._isExistingGridViewName(gvNameText, isChanged);
    this.isExceededCharLimit = gvNameText.length < 1 || gvNameText.length > this.characterLimit;

    this.setErrors();
    this.setWarningMessage(gvNameText);

    return gvNameText && (this.isExceededCharLimit || this.isExistingGridViewName || this.isNameInValid);
  }

  private setErrors (): void {
    this.isExceededCharLimit || this.isExistingGridViewName || this.isNameInValid
      ? this.gridViewForm.get('gridViewName').setErrors({ incorrect: true })
      : this.gridViewForm.get('gridViewName').setErrors(null);
  }

  private setWarningMessage (gvNameText: string): void {
    if (this.isExistingGridViewName) {
      this.warningMessage = 'Please enter a unique view name';
    } else if (this.isNameInValid) {
      this.warningMessage = 'Name cannot contain special characters';
    } else if (gvNameText.length > this.characterLimit) {
      this.warningMessage = 'View name must not exceed ' + this.characterLimit + ' characters';
    } else {
      this.warningMessage = '';
    }
  }

  private _isExistingGridViewName (gvNameText: string, isChanged: boolean): boolean {
    const existsName: boolean = this.isUniqueGridViewName(gvNameText);
    if (this.modalAction === GridIconMenuActions.Save || this.modalAction === GridIconMenuActions.Create) return existsName;
    if (this.modalAction === GridIconMenuActions.Rename) return isChanged && existsName;
  }

  public gridViewNameValidator (gvNameText: string): boolean {
    let isInValid = false;
    if (gvNameText) {
      const gridViewNameRegEx = RegularExpressionsUtility.gridViewNameRegexCtr();
      const valid = gvNameText.match(gridViewNameRegEx);
      if (!valid) {
        isInValid = true;
      }
    }
    return isInValid;
  }
}
