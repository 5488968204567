export const Toggles = {
  TOGGLE_V3_ACCESS: 'TOGGLE_V3_ACCESS',
  TOGGLE_MANAGER_VIEW: 'TOGGLE_MANAGER_VIEW',
  TOGGLE_NV_SYNC: 'TOGGLE_NV_SYNC',
  TOGGLE_NETWORK_DASHBOARD: 'TOGGLE_NETWORK_DASHBOARD',
  TOGGLE_STUD_LEVEL_NET_DASH: 'TOGGLE_STUD_LEVEL_NET_DASH',
  TOGGLE_NYCT_MVP: 'TOGGLE_NYCT_MVP',
  TOGGLE_COLLEGE_LIST_CSV_UPLOAD: 'TOGGLE_COLLEGE_LIST_CSV_UPLOAD',
  TOGGLE_REMOTE_INTERACTION: 'TOGGLE_REMOTE_INTERACTION',
  TOGGLE_GOOGLE_CLASSROOM: 'TOGGLE_GOOGLE_CLASSROOM',
  TOGGLE_CENTRAL_DOMAIN_MEET: 'TOGGLE_CENTRAL_DOMAIN_MEET',
  TOGGLE_DATA_GRID: 'TOGGLE_DATA_GRID',
  TOGGLE_SUMMER_SCHOOL: 'TOGGLE_SUMMER_SCHOOL',
  TOGGLE_ATTENDANCE_TRENDS: 'TOGGLE_ATTENDANCE_TRENDS',
  TOGGLE_START_OF_SCHOOL_YEAR: 'TOGGLE_START_OF_SCHOOL_YEAR',
  TOGGLE_CREDIT_GAPS: 'TOGGLE_CREDIT_GAPS', // Allows to merge ongoing Credits rebuild work to master without deploying to production
  TOGGLE_GRADUATION: 'TOGGLE_GRADUATION',
  TOGGLE_SDC_REGENTS: 'TOGGLE_SDC_REGENTS', // Allows to merge ongoing Regents rebuild work to master without deploying to production
  TOGGLE_CONTENT_AREA_DASHBOARD: 'TOGGLE_CONTENT_AREA_DASHBOARD',
  TOGGLE_ATTENDANCE_CONTENT_AREA_DASHBOARD: 'TOGGLE_ATTENDANCE_CONTENT_AREA_DASHBOARD',
  TOGGLE_V4_NEW_SKIN_MODE: 'TOGGLE_V4_NEW_SKIN_MODE',
  TOGGLE_SCHOOL_CONVERSATIONS: 'TOGGLE_SCHOOL_CONVERSATIONS',
};

// export type TToggles = keyof typeof Toggles;
export type TToggles = typeof Toggles[keyof typeof Toggles];
