import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { TrackAttendanceStep } from './track-attendance-step.component';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [TrackAttendanceStep],
  imports: [CommonModule, MatButtonModule],
  exports: [TrackAttendanceStep],
})
export class TrackAttendanceStepModule {

}
