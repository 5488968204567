import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NvIconButtonModule } from '../../../nvps-libraries/design/nv-icon-button/nv-icon-button.module';
import { NvSidebarListModule } from '../../../nvps-libraries/design/nv-sidebar-list/nv-sidebar-list.module';
import { AdminLeftNavComponent } from './admin-left-nav.component';

@NgModule({
  declarations: [AdminLeftNavComponent],
  imports: [CommonModule, NvIconButtonModule, NvSidebarListModule],
  exports: [AdminLeftNavComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})

export class AdminLeftNavModule {}
