import { ActivatedRouteSnapshot, BaseRouteReuseStrategy } from '@angular/router';

export class AppRouteReuseStrategy extends BaseRouteReuseStrategy {
  // retain all other default methods

  /*
  * Determines if a route should be reused.
  * This strategy returns `true` when the future route config and current route config are identical.
  * This is the default behavior.
  *
  * Our modification returns `false` when a route has a definition that contains data.refreshComponent is true.
  * By doing this, we allow the component to refresh when this method is triggered. Otherwise, we perform the default behaviour.
  * */
  shouldReuseRoute (future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.data.refreshComponent ? false : future.routeConfig === curr.routeConfig;
  }
}
