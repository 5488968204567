<ng-container *ngIf="timeRangeForm">
  <!-- event start time -->
  <nv-time-picker
    [placeholderText]="'Start time'"
    [control]="timeRangeForm.controls.startTime"
    [icon]="icon"
    [hasClearBtn]="hasClearBtn"
    [interval]="interval"
    [rangeStartTime]="rangeStartTime"
    [rangeEndTime]="rangeEndTime"
    (selectedOption)="updateRightPicker($event)"
    [resetRequested]="resetRequested"
    (openPanel)="closePanel($event.menuTrigger, 'left')"
    tabindex="0"
  >
  </nv-time-picker>

  <nv-icon
    [name]="'arrow-right-default'"
    class="arrow-icon"
    [color]="getStyle()"
    [ngClass]="isDisabled ? 'isDisabled' : null"
  ></nv-icon>

  <!-- event end time -->
  <nv-time-picker
    [placeholderText]="'End time'"
    [control]="timeRangeForm.controls.endTime"
    [icon]="icon"
    [hasClearBtn]="hasClearBtn"
    [interval]="interval"
    [rangeStartTime]="rangeStartTime"
    [rangeEndTime]="rangeEndTime"
    (selectedOption)="updateLeftPicker($event)"
    (openPanel)="closePanel($event.menuTrigger, 'right')"
    tabindex="0"
  >
  </nv-time-picker>
</ng-container>
