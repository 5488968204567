import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SchoolTwoColumnLayoutComponent } from './school-two-column-layout.component';
import { SettingsLeftNavModule } from '../../../shared/components/settings/settings-left-nav/settings-left-nav.module';
import { AdminLeftNavModule } from 'Src/ng2/admin/admin-left-nav/admin-left-nav.module';

@NgModule({
  declarations: [SchoolTwoColumnLayoutComponent],
  imports: [CommonModule, RouterModule, SettingsLeftNavModule, AdminLeftNavModule],
  exports: [SchoolTwoColumnLayoutComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})

export class SchoolTwoColumnLayoutModule {}
