<div class="list-content-wrapper">
  <div #listContent class="list-content">
    <ng-container *ngIf="groupingData$.value">
      <page-header [pageHeaderMeta]="pageHeaderMeta" [filterFormControl]="filterFormControl">
        <madlib
          *ngIf="activeList !== 'INFINITE'"
          [fociByCategory]="fociByCategory"
          [madlibModel]="madlibModel"
          (madlibChange)="onMadlibChange($event, schoolId, madlibModel)"
        >
        </madlib>

        <div *ngIf="activeList === 'INFINITE'">
          <span class="madlib-no_options">{{ madlibModel.value.focus.label }}</span> <span> for </span>
          <span class="madlib-no_options">{{ madlibModel.value.filter.label }} students</span>
          <span> grouped by </span> <span class="madlib-no_options">{{ madlibModel.value.grouping.label }}</span>
        </div>
      </page-header>
    </ng-container>

    <!-- fixed to infinite list entry -->
    <ng-container #entry></ng-container>
  </div>
</div>

<div class="list-tools">
  <content-tools [isRefactoredList]="isRefactoredList" [listData]="listData" [listSummaryGroupData]="listSummaryGroupData"></content-tools>
</div>
