export const UserRoleTypeToAccessLevelMapping = {
  delegated_school_admin: 'Delegated Admin',
  school: 'No Access',
  school_admin: 'School Admin',
  edit_all: 'School Wide Editor',
  edit_caseload: 'Caseload Editor',
  view_all: 'School Wide Viewer',
  view_caseload: 'Caseload Viewer',
};

export const UserAccessLevelToRoleTypeMapping = {
  'Delegated Admin': 'delegated_school_admin',
  'No Access': 'school',
  'School Admin': 'school_admin',
  'School Wide Editor': 'edit_all',
  'Caseload Editor': 'edit_caseload',
  'School Wide Viewer': 'view_all',
  'Caseload Viewer': 'view_caseload',
};

export const UserAccessLevelToRoleTypeNoSchoolAdminMapping = {
  'Delegated Admin': 'delegated_school_admin',
  'No Access': 'school',
  'School Wide Editor': 'edit_all',
  'Caseload Editor': 'edit_caseload',
  'School Wide Viewer': 'view_all',
  'Caseload Viewer': 'view_caseload',
};

// TODO UPDATE: Keep in sync with backend jobRoles constant:
// https://github.com/NewVisionsForPublicSchools/nv-student-api/blob/master/constants/user.json#L43
export const JobRolesOptions: string[] = [
  'Attendance Staff',
  'Business Manager/APO',
  'College Advisor',
  'Data Specialist',
  'Dean',
  'Department Head',
  'Lead Teacher',
  'Other',
  'Paraprofessional',
  'Parent Coordinator',
  'Programmer',
  'School Aide',
  'Teacher',
  'Tech Liaison',
];
