import { Injectable } from '@angular/core';
import { ISELParams, ISELQueryParams } from 'Src/ng2/school/lists/sel-support-roster-list/sel-support-roster-params.interface';

@Injectable()
export class GraphQLSELSupportHelperService {
  public getSELInterventionTypeTierQuery (params: ISELQueryParams): string {
    return `
    {
      SupportGroupings(
        schoolId: "${params.schoolId}",
        id: "",
        baseCollectionName: "students",
        filterKeys: \[${params.filter}\],
        groupingKey: "${params.grouping}",
        columns: \[${params.columns}\]
      ){
        key
        human
        tooltip
        rowData {
          data
          meta
          columnKey
        }
        allowEmptyTable
      }
    }`;
  }

  getStudentSelSupportsQuery ({ schoolId, studentId }): string {
    return `{
      ApertureStudentSupport(schoolId: "${schoolId}", studentId: "${studentId}") {
        _id
        support {
          interventionType
          status
          startDate
          endDate
          scope
          tier
          frequency
          schoolYear
        }
      }
    }`;
  }

  public buildSELFilterString(params: ISELParams) : string {
    const withFilter = params.filter;
    const withTier = params.tier;
    const nullTier = 'No Tier';
    let filterString = "";
    if(withFilter && withTier){
      filterString = `"SEL_SUPPORT_STATUS=\\"ACTIVE\\"","SEL_SUPPORT_INTERVENTION_TYPE=\\"${params.interventionTypeCategory}\\"","SEL_SUPPORT_INTERVENTION_TIER=${params.tier}", "${params.filter}"`;
    } else if (withTier && !withFilter){
      filterString = `"SEL_SUPPORT_STATUS=\\"ACTIVE\\"","SEL_SUPPORT_INTERVENTION_TYPE=\\"${params.interventionTypeCategory}\\"","SEL_SUPPORT_INTERVENTION_TIER=${params.tier}"`;
    } else if (withFilter && !withTier){
      filterString = `"SEL_SUPPORT_STATUS=\\"ACTIVE\\"","SEL_SUPPORT_INTERVENTION_TYPE=\\"${params.interventionTypeCategory}\\"","SEL_SUPPORT_INTERVENTION_TIER=\\"${nullTier}\\"", "${params.filter}"`;
    } else {
      filterString = `"SEL_SUPPORT_STATUS=\\"ACTIVE\\"","SEL_SUPPORT_INTERVENTION_TYPE=\\"${params.interventionTypeCategory}\\"","SEL_SUPPORT_INTERVENTION_TIER=\\"${nullTier}\\""`;
    }
    return filterString;
  }
};
