import { TValidGradDates } from '../../../shared/constants/graduation-date.constant';
import { TValidDiplomaCredentials } from '../../../shared/constants/planned-diploma-credentials.constant';
import { TValidDiplomaEndorsements } from '../../../shared/constants/planned-diploma-endorsements.constant';
import { TCreditSubjAreas, TValidTermTypes } from './school.interface';
import { IActiveStudentSupport } from './student-support.interface';
import { IUserMini } from './user.interface';
import { IMapGrowth } from './map-growth.interface';
import { IStudentiReady } from './iready.interface';
import { IStudentDessa } from './dessa.interface';
import { EM_DASH } from '../../constants/em-dash.constant';

export interface IStudentMini {
  studentId: string;
  lastFirst: string;
}

export interface IAssessmentSummaryItem {
  doeRiskGroup: string;
  name: 'iReady' | 'Ready' | 'State Assessment';
  subj: 'Math' | 'ELA';
  latestScore?: number;
  score?: number;
  latestAbsLvl?: string;
  latestRelLvl?: string;
  prevScore?: number;
  prevAbsLvl?: string;
  prevRelLvl?: string;
  pctGrowth?: number;
}

export interface IAssessmentHistoryItem {
  name: 'iReady' | 'Ready' | 'State Assessment';
  subj: 'Math' | 'ELA';
  admin: number;
  score: number;
  absLvl?: string;
  relLvl?: string;
  skill?: string;
  year?: string;
  pr?: number;
  growth?: number;
}

export interface IIreadyHistoryItem {
  subject: string;
  year: string;
  date: string;
  scoreOrder: number;
  score: number;
  scoreBaseline: number;
  rushFlag: string;
  absLvl: string;
  relLvl: string;
  progressGrowthTypical: number;
  progressGrowthStretch: number;
  isBaselineScore: boolean;
  isMostRecent: boolean;
}

export interface IIreadyGrowthItem {
  year: string;
  subject: string;
  expectedGrowthTypical: number;
  expectedGrowthStretch: number;
}

export interface IAssessmentIready {
  history: IIreadyHistoryItem[];
  growth: IIreadyGrowthItem[];
}

export interface ICreditArea {
  attempted: number;
  earned: number;
  earnedPlusScheduled: number;
  schoolCreditGap: number;
  scheduled: number;
  creditsBehind: number;
}

export interface IAtsContact {
  last?: string;
  first?: string;
  phone?: string;
}

export interface IApExamScoreHistory {
  courseTitle: string;
  score: number;
  examCode: number;
  calYear: string;
}

export interface IPsatExamScoreHistory {
  math?: number;
  reading?: number;
  writing?: number;
  total?: number;
  calYear?: string;
}

export interface ISatExamScoreHistory {
  math?: number;
  month?: number;
  reading?: number;
  total1600?: number;
  total2400?: number;
  calYear?: string;
}

export interface INextScheduledRegentsExam {
  adminDate?: string;
  examLanguage?: string;
}

export interface ICurrProgramCourseTeacher {
  doeEmail: string;
  nickname: string;
}

export interface ICurrProgramCourseMark {
  mp: number;
  string: string;
  num: number;
  isMostRecent: boolean;
  isFailing: boolean;
}

export type TValidCoursePriorities = 'high' | 'medium' | 'low';
export interface ICurrProgramCourse {
  courseId: string;
  isCurrentTermYear: boolean;
  schoolYear: string;
  term: string;
  termYear: number;
  courseCode: string;
  courseName: string;
  gradReq: string;
  coursePrepsFor: string;
  isRegentsCulminating: boolean;
  creditValue: number;
  period: string | number;
  cyclePeriod: string;
  section: string;
  priority: TValidCoursePriorities;
  subjectArea: TCreditSubjAreas;
  teachers: ICurrProgramCourseTeacher[];
  marks: ICurrProgramCourseMark[];
  department: string;
  isCreditedOnTranscript: boolean;
  isOverCreditLimit: boolean;
  isCreditsOverride: boolean;
  cycleDay?: string; // this is not on the schema but will sometimes get appended to the course by the frontend
}

export interface ITestScore {
  num: number;
  string: string;
}

export interface IExamScoreHistory {
  grade: number;
  gs: number;
  pr: number;
  subject: string;
  year: string;
}

export interface IPromotedHistory {
  promoted: string;
  year: string;
}

export interface ITranscriptCourse {
  schoolYear: string;
  courseSchoolId?: string;
  term: number;
  termYear: number;
  courseName: string;
  courseCode?: string;
  creditValue: number;
  mark: ITestScore;
  passFailEquivalent: string;
  subjectArea: TCreditSubjAreas;
  alignedRegents?: string;
  discountedFromCreditsEarned: boolean;
}

export interface ISorterOnlyRegentsExamTranscript {
  humanReadableKey: string;
  date: string;
  school: string;
  mark: string;
}

export interface IAttendanceBySy {
  year: string;
  attended: boolean;
}

export interface ITestingModification {
  code: string;
  description: string;
  mod: number;
}

export interface ICourseSummary {
  code: string;
  subjectArea: string;
  creditArea: string;
  title: string;
  section: string;
  period: string | number;
  teacher: string;
  priority: string;
  creditValue: string;
  grades: {
    mp1: string;
    mp2: string;
    mp3: string;
    mp4: string;
    mp5: string;
    mp6: string;
    mp7: string;
    mp8: string;
    final: string;
  };
}

export interface ITeacher {
  doeEmail: string;
  nickname?: string;
}

export interface IMarks {
  mp: number;
  string: string;
  num: number;
  isFailing: boolean;
  isMostRecent: boolean;
}

export interface IAttendanceHistory {
  sep: number;
  oct: number;
  nov: number;
  dec: number;
  jan: number;
  feb: number;
  mar: number;
  apr: number;
  may: number;
  jun: number;
  ytd: number;
  year: string;
  overallPctPres: number;
}

export interface ICurrentGrade {
  code: string;
  section: string;
  name: string;
  mostRecent: boolean;
  subj: string;
  mp: number;
  priority: string;
  teacher: {
    nickName: string;
    fullName: string;
    doeEmail: string;
  };
  num: number;
  str: string;
  pf: 'P' | 'F' | 'NC';
  department: string;
  isExemptEligibleRegents: boolean;
  isExemptEligibleAdvanced: boolean;
}

export interface ITermMp {
  termId: number | null;
  gpaStartOfTerm: number | null;
  mp: number | null;
  mpAverage: number | null;
  countOfMpCourses: number | null;
  countOfMpCoursesWithMarks: number | null;
  dataAvailability: string | null;
};

export type TValidSchoolRiskGroups =
  | 'Pushable 2'
  | 'Pushable 3'
  | 'Slippable 2'
  | 'Slippable 3'
  | 'No data'
  | 'Below grade level'
  | 'On grade level';

export type TEopFinancialEligibility = 'Eligible' | 'Not eligible';

export type TOfFiveCategories = 'ELA' | 'Math' | 'Sci' | 'SS' | 'Plus One';

export type TOfNineCategories = 'ELA' | 'Alg' | 'Geom' | 'Trig' | 'Living' | 'Physical' | 'Global' | 'US' | 'LOTE';

export type TCollegeReady = 'ELA' | 'Math';

export type TCoursePriority = 'high' | 'medium' | 'low';

export type TRegentsAppealTypes =
  | 'Low Score'
  | 'New Arrival ELA'
  | 'Safety Net'
  | 'New ArrivalELA & Low Score'
  | 'Local Diploma Review'
  | 'Local Diploma Review w/ Appeal';

export type TRegentsAppealDiploma = 'Local' | 'Regents';

export type TRegentsExamShortNames =
  | 'Comp ELA'
  | 'CC ELA'
  | 'Int Alg'
  | 'CC Alg'
  | 'Geom'
  | 'CC Geom'
  | 'Trig'
  | 'CC Trig'
  | 'Liv Env'
  | 'Chem'
  | 'Physics'
  | 'Earth'
  | 'Global'
  | 'US'
  | 'LOTE'
  | 'CDOS';

export enum RegentsStatusOriginalValue {
  NOT_PASSED = 'Not Passed',
  MET_55_PLUS = 'Met 55+',
  MET_65_PLUS = 'Met 65+',
  MET_CR = 'Met CR',
  MET_CDOS = 'Met CDOS',
  WAIVED = 'Waived',
  COMP = 'Comp',
  POTENTIAL_COMP = 'Potential Comp',
  NA = 'N/A'
}

export type TRegentsStatusLocal =
  | typeof RegentsStatusOriginalValue.NOT_PASSED
  | typeof RegentsStatusOriginalValue.MET_55_PLUS
  | typeof RegentsStatusOriginalValue.MET_65_PLUS
  | typeof RegentsStatusOriginalValue.MET_CR
  | typeof RegentsStatusOriginalValue.COMP
  | typeof RegentsStatusOriginalValue.POTENTIAL_COMP
  | typeof RegentsStatusOriginalValue.MET_CDOS
  | typeof RegentsStatusOriginalValue.WAIVED
  | typeof RegentsStatusOriginalValue.NA;

export type TRegentsStatusRegents =
  | typeof RegentsStatusOriginalValue.NOT_PASSED
  | typeof RegentsStatusOriginalValue.MET_55_PLUS
  | typeof RegentsStatusOriginalValue.MET_65_PLUS
  | typeof RegentsStatusOriginalValue.MET_CR
  | typeof RegentsStatusOriginalValue.MET_CDOS
  | typeof RegentsStatusOriginalValue.WAIVED
  | typeof RegentsStatusOriginalValue.NA;

export type TRegentsCompensatedExamS3Keys =
  | 'ccAlg'
  | 'ccEla'
  | 'ccGeom'
  | 'ccTrig'
  | 'chem'
  | 'compEla'
  | 'earth'
  | 'oldGeom'
  | 'global'
  | 'intAlg'
  | 'lote'
  | 'livingEng'
  | 'physics'
  | 'oldTrig'
  | 'us';

export type TIEPStatus = 'Yes' | 'Yes*' | 'Yes**' | 'No';

export type TPotentialSafetyNetStatus = 'Current 504' | 'Previous 504' | 'Prior IEP';

export type TCreditGradReqs =
  | 'art'
  | 'cte'
  | 'elaCore'
  | 'elaOther'
  | 'health'
  | 'hpe'
  | 'lote'
  | 'math'
  | 'misc'
  | 'pe'
  | 'sciCore'
  | 'sciLife'
  | 'sciOther'
  | 'sciPhysical'
  | 'ssEcon'
  | 'ssElective'
  | 'ssGlobal'
  | 'ssGovt'
  | 'ssGovtEcon'
  | 'ssUs';

export type TRegentsExamSubjects =
  | 'ela'
  | 'alg'
  | 'geom'
  | 'trig'
  | 'livingEnv'
  | 'chem'
  | 'physics'
  | 'earth'
  | 'global'
  | 'us'
  | 'lote';

export type TSubjectArea =
  | 'advisory'
  | 'advisoryMs'
  | 'art'
  | 'artMs'
  | 'cte'
  | 'cteMs'
  | 'elaCore'
  | 'elaCoreMs'
  | 'elaElect'
  | 'elaElectMs'
  | 'elaEsl'
  | 'functional'
  | 'functionalId'
  | 'functionalIdMs'
  | 'functionalLta'
  | 'functionalLtaMs'
  | 'functionalMs'
  | 'functionalRs'
  | 'functionalRsMs'
  | 'functionalSetts'
  | 'functionalSettsMs'
  | 'guidance'
  | 'guidanceMS'
  | 'guidanceMs'
  | 'health'
  | 'healthMs'
  | 'lote'
  | 'loteMs'
  | 'math'
  | 'mathAlg'
  | 'mathCalc'
  | 'mathCoreMs'
  | 'mathElect'
  | 'mathElectMs'
  | 'mathGeom'
  | 'mathOther'
  | 'mathPreCalc'
  | 'mathStats'
  | 'mathTrig'
  | 'misc'
  | 'pe'
  | 'peMs'
  | 'sciBio'
  | 'sciChem'
  | 'sciCoreMs'
  | 'sciEarth'
  | 'sciForensics'
  | 'sciLabMs'
  | 'sciLife'
  | 'sciLiving'
  | 'sciOther'
  | 'sciOtherMs'
  | 'sciPhysical'
  | 'sciPhysics'
  | 'ssCoreMs'
  | 'ssEcon'
  | 'ssElectMs'
  | 'ssElective'
  | 'ssGlobal'
  | 'ssGovt'
  | 'ssGovtEcon'
  | 'ssUs';

export type TAcademicGroup =
  | 'On Grade Level'
  | 'Slippable 3'
  | 'Pushable 3'
  | 'Slippable 2'
  | 'Pushable 2'
  | 'Below Grade Level';

export type TDepartments = 'art' | 'cte' | 'ela' | 'functional' | 'guidance' | 'hpe' | 'lote' | 'math' | 'sci' | 'ss';

export type TValidPlannedPostSecondary = '2 Yr College' | '4 Yr College' | 'Job Force' | 'Military' | 'Trade School';
/**
 * The same as ICurrProgramCourse, except all fields are optional
 * Useful for merging courses with code deck
 */
export type ICurrProgramCoursePartial = Partial<ICurrProgramCourse>;

interface IStudentId {
  id: string;
}

interface IRegentsExamScore {
  num: number;
  string: string;
}

// TODO: to be deprecated and replaced by IRegentsTranscript
interface IRegentsExamTranscript {
  code: string;
  year: string;
  month: string;
  school: string;
  termCd: number;
  mark: IRegentsExamScore;
}

export interface IRegentsTranscript {
  examCode: string;
  examName: string;
  schoolYear: string;
  month: string;
  mark: IRegentsExamScore;
  examSchoolId: string;
}

export interface IRegentsExamDetails {
  exam: TRegentsExamShortNames;
  attempts: number;
  maxScore: IRegentsExamScore;
  fulfilled: boolean;
  fulfilledLocal: boolean;
  fulfilledRegents: boolean;
  fulfilledCr: boolean;
  schedInStars: boolean;
  starsSection: number;
  hasRegentsRecRationaleCourse: boolean;
  hasRegentsRecRationaleCommonCore: boolean;
  hasRegentsRecRationaleCollegeReadiness: boolean;
  hasRegentsRecRationaleMetric: boolean;
  recommendation: string;
  recommendationRationale: string[];
  transcript: IRegentsExamTranscript[]; // TODO: to be deprecated for regentsDetails.transcript
}

export type TPointPersonType = 'ACADEMIC' | 'ATTENDANCE' | 'POSTSECONDARY' | 'GUIDANCE_COUNSELOR' | 'ADVISOR';

export interface IPointPerson {
  type: string;
  user: IUserMini;
}

export type TValidPlannedDiplomaTypes = 'Regents' | 'Advanced Regents' | 'Local' | 'Non-Graduate' | 'No plan' | typeof EM_DASH | null;

export enum StudentOtherSchoolType {
  SUMMER = 'summer',
  OPSR = 'opsr',
  // eslint-disable-next-line no-unused-vars
  CUNY = 'cuny',
}

export type TSingleStudentViewOtherSchoolTypes = typeof StudentOtherSchoolType.OPSR | typeof StudentOtherSchoolType.SUMMER | typeof StudentOtherSchoolType.CUNY | null;

export type TValidOtherSchoolTypes = typeof StudentOtherSchoolType.SUMMER;

export type TValidLearningPreferenceTypes = 'Blended' | 'Fully remote' | 'Blended (no response)';

export interface IOtherSchool {
  schoolId: string | null;
  schoolName: string | null;
  type: TValidOtherSchoolTypes;
  termYear: number | null;
}

export interface IRemoteInteractionTermSummary {
  remoteInteractionRateTotal: number | null;
  remoteInteractionDaysTotal: number | null;
  remoteDaysIncludedTotal: number | null;
  remoteInteractionRateLast5: number | null;
  remoteInteractionDaysLast5: number | null;
  remoteDaysIncludedLast5: number | null;
  remoteInteractionRateLast20: number | null;
  remoteInteractionDaysLast20: number | null;
  remoteDaysIncludedLast20: number | null;
  remoteInteractionLastSchoolDay: boolean | null;
  remoteInteractionDifferenceFromAttendance: number | null;
  disconnected: boolean | null;
  newlyDisconnected: boolean | null;
  continuedDisconnected: boolean | null;
  attendancePriorToRemote: number | null;
  remoteInteractionStreak: number | null;
  termYear: number | null;
  termYearDescription: string | null;
  schoolYear: string | null;
  lastDay: string | null;
  previousTerm: boolean | null;
}

export interface IGoogleClassroomTermSummary {
  remoteEngagement: number;
  remoteEngagementLast5: number;
  totalAssignments: number;
  totalEngagedAssignments: number;
  totalTurnedInLast5: number;
  totalTurnedInLastDay: number;
  attendancePrior: number;
  isActive: boolean;
  totalEnrolled: number;
  studentEmail: string;
  courses: Array<{
    last: string;
    first: string;
    courseId: string;
    domain: string;
    assignmentsTurnedIn: number;
    assignmentsGiven: number;
    ownerId: string;
    teacherGafeEmail: string;
    teacherName: string;
    name: string;
    section: string;
    descriptionHeading: string;
    folderLink: string;
    mostRecentSubmittedAssignment: string;
    id: string;
    assignments: Array<{
      domain: string;
      courseId: string;
      assignmentName: string;
      assignmentId: string;
      submissionId: string;
      actionDate: string;
      driveFileId: string;
      id: string;
    }>;
  }>;
  termYear: number | null;
  termYearDescription: string | null;
  schoolYear: string | null;
  lastDay: string | null;
  previousTerm: boolean | null;
}

export interface IGoogleHangoutTermSummary {
  isActive: boolean | null;
  remoteEngagement: number | null;
  remoteEngagementLast5: number | null;
  remoteEngagementLast1: number | null;
  total: number | null;
  totalLast5: number | null;
  totalLast1: number | null;
  totalDays: number | null;
  totalDaysLast5: number | null;
  totalDaysLast1: number | null;
  devices: string[] | null;
  devicesLast5: string[] | null;
  devicesLast1: string[] | null;
  termYear: number | null;
  termYearDescription: string | null;
  schoolYear: string | null;
  lastDay: string | null;
  previousTerm: boolean | null;
}

export interface IAttLastXDays {
  ytdStart: number;
  daysAbs: string[];
  daysAbsRemote: string[];
  daysAbsInPerson: string[];
  daysLate: string[];
  pctChange: number;
  trend: 'Got worse' | 'Stayed the same' | 'Got better';
  abs: number;
  absInPerson: number;
  absRemote: number;
  late: number;
  pctPres: number;
  pctPresInPerson: number;
  pctPresRemote: number;
}

export interface ICalculatedStudent {
  [key: string]: any;
}
export interface IPostsecondaryExamsCoreHistory {
  math: number;
  reading: number;
  writing: number;
  total: number;
}

export interface IPostsecondaryFuturePlan {
  advisor?: string;
  careerInterest?: string;
  futureGoals?: string;
}

// @ts-ignore
export interface IStudent {
  // static methods
  // eslint-disable-next-line
  get ({ }: IStudentId): {
    $promise: any;
  };
  // Static schema
  _id: string;
  studentId: string;
  isHS: boolean;
  isMS: boolean;
  isES: boolean;
  schoolStatus: string;
  schoolId: string;
  schemaVersion: string;
  mongoStatus: string;
  assmnts: {
    summary: IAssessmentSummaryItem[];
    history: IAssessmentHistoryItem[];
    iReady: IAssessmentIready;
    doeRiskGroup: TAcademicGroup;
  };
  currProgram?: {
    countBorderline: number;
    countBorderlineHP: number;
    countFailingHP: number;
    countFailing: number;
    borderline: string[];
    borderlineHP: string[];
    failing: string[];
    grades: ICurrentGrade[];
    courses: ICurrProgramCourse[];
  };
  att: {
    currSyYtdPercentLate: number;
    absStreak: number;
    currSy: {
      abs: number;
      daysAbs: string[];
      daysLate: string[];
      late: number;
      trend: string;
      ytdStart: number;
    };
    currTerm: IAttLastXDays;
    daysBeforeSlipping: number;
    history: IAttendanceHistory[];
    last5: IAttLastXDays;
    last10: IAttLastXDays;
    last20: IAttLastXDays;
    lateStreak: number;
    riskGroup: string;
    today: string;
    ytd: number;
    ytdLastSy: number;
    overallLastSy: number;
    ytdDaysPresentOrLate: number;
    ytdDaysIncluded: number;
    ytdInPersonRate: number;
    ytdInPersonDaysPresent: number;
    ytdInPersonDaysIncluded: number;
    ytdOnlineRate: number;
    ytdOnlineDaysPresent: number;
    ytdOnlineDaysIncluded: number;
    ytdInPersonDifferenceFromOnline: number;
    absMon: number;
    absTues: number;
    absWed: number;
    absThurs: number;
    absFri: number;
    attStreak: number;
    remoteAbsStreak: number;
    inPersonAbsStreak: number;
    byDayBlended: {
      absMonRemote: number;
      absTuesRemote: number;
      absWedRemote: number;
      absThursRemote: number;
      absFriRemote: number;

      absMonInPerson: number;
      absTuesInPerson: number;
      absWedInPerson: number;
      absThursInPerson: number;
      absFriInPerson: number;
    };
    remoteInteraction: {
      byMonth: {
        month: number;
        schoolYear: string;
        pct: number;
        type: 'Remote' | 'In Person';
      }[];
    };
  };
  behaviorDetails: {
    suspensions: {
      currSy: {
        principal?: number;
        superintendent?: number;
      };
      history: {
        schoolYear: string;
        principal?: number;
        superintendent?: number;
      };
    };
  };
  behavioralIncidents: {
    incidents: Array<{
      id: string;
      date: string;
      description: string;
      createdBy: string;
      studentRole: string;
      consequenceAction: string;
      consequenceAssigned: string;
      consequenceAssignedFromDate: string;
      consequenceAssignedToDate: string;
    }>;
  }
  charterDetails: {
    isCharter: boolean;
    mathScaledScoreMostRecent?: number;
    mathScaledScoreTestDate?: string;
    maxScoreActEnglish?: number;
    maxScoreActMath?: number;
    readingLexileMostRecent?: number;
    readingLexileTestDate?: string;
    readingRtiTier?: string;
  };
  atsContactDetails: {
    adult1: IAtsContact;
    adult2: IAtsContact;
    adult3: IAtsContact;
    home: {
      apt?: any;
      city?: string;
      email?: string;
      phone?: string;
      streetNum?: string;
      street?: string;
      zip?: string;
    };
    rbir: {
      ddbnnn: string;
      lastName: string;
      firstName: string;
      studentId: string;
      sex: string;
      birthDt: string;
      offCls: string;
      grdCd: string;
      grdLvl: string;
      streetNum: string;
      street: string;
      apt: string;
      city: string;
      st: string;
      zip: string;
      homePhone: string;
      adultLast1: string;
      adultFirst1: string;
      adultPhone1: string;
      adultLast2: string;
      adultFirst2: string;
      adultPhone2: string;
      adultLast3: string;
      adultFirst3: string;
      adultPhone3: string;
      ytdAttdPct: string;
      email: string;
    };
  };
  creditDetails: {
    byArea: {
      art: ICreditArea;
      elaCore: ICreditArea;
      health: ICreditArea;
      math: ICreditArea;
      pe: ICreditArea;
      hpe: ICreditArea;
      sciPhysical: ICreditArea;
      ssEcon: ICreditArea;
      ssGlobal: ICreditArea;
      ssGovtEcon: ICreditArea;
      total: ICreditArea;
      cte: ICreditArea;
      elaOther: ICreditArea;
      lote: ICreditArea;
      misc: ICreditArea;
      sciLife: ICreditArea;
      sciTotal: ICreditArea;
      sciOther: ICreditArea;
      sciCore: ICreditArea;
      ssElective: ICreditArea;
      ssGovt: ICreditArea;
      ssUs: ICreditArea;
    };
    creditRecovery: {
      eligibleCoursesString?: string;
    };
    currSy: {
      attempted?: number;
      earned?: number;
      earnedPlusScheduled?: number;
      earned3Of4Core?: boolean;
      earnedCore?: number;
      earnedPlusScheduledCore?: number;
      failed?: number;
      mostRecentMps: {
        totalFailing: number;
        totalFailingHighPriority: number;
      };
    };
    priorSy: {
      failed?: number;
      earned10Plus?: boolean;
    };
    summaryByYear: Array<{
      schoolYear: string;
      attempted: number;
      earned: number;
      numCoursesFailed: number;
      earnedPriorToSy: number;
    }>;
    creditGaps: {
      maxGaps?: number;
      subjectsWithGaps: string[];
    };
    transcriptGradeAverage: {
      gpa?: number;
      year?: string;
      week?: number;
    };
    transcript: ITranscriptCourse[];
    GPA: number;
    maxCreditsBehind: number;
  };

  regentsDetails: {
    countOfNextSchedStars: number;
    countOfNeededNotSchedStars: number;
    yearStartedHsMath: string;
    nysaaEligible: boolean;
    needed: {
      onTrack: {
        local: TOfFiveCategories[];
        regents: TOfFiveCategories[];
        grad: TOfFiveCategories[];
        advanced: TOfNineCategories[];
        collegeReady: TCollegeReady[];
      };
      onTrackPastDue: {
        local: TOfFiveCategories[];
        regents: TOfFiveCategories[];
        grad: TOfFiveCategories[];
        advanced: TOfNineCategories[];
        collegeReady: TCollegeReady[];
      };
      all: {
        local: TOfFiveCategories[];
        regents: TOfFiveCategories[];
        grad: TOfFiveCategories[];
        advanced: TOfNineCategories[];
        collegeReady: TCollegeReady[];
      };
    };
    numberPassed: {
      of5Grad: number;
      of5GradPriorToCurrSy: number;
      of5Local: number;
      of5Regents: number;
      of9Advanced: number;
    };
    appealDetails: {
      category: string;
      type: TRegentsAppealTypes;
      diploma: TRegentsAppealDiploma;
    };
    byCategory5: {
      ela: {
        maxScore: ITestScore;
        schedStatusStars: string;
        statusLocal: TRegentsStatusLocal;
        statusRegents: TRegentsStatusRegents;
        regentsName: TRegentsExamShortNames;
        appealable: boolean;
        appealDetails: {
          type: TRegentsAppealTypes;
          examKey: TRegentsCompensatedExamS3Keys;
        };
        fulfilled: boolean;
        fulfilledLocal: boolean;
        fulfilledRegents: boolean;
        fulfilledCr: boolean;
      };
      math: {
        maxScore: ITestScore;
        schedStatusStars: string;
        statusLocal: TRegentsStatusLocal;
        statusRegents: TRegentsStatusRegents;
        regentsName: TRegentsExamShortNames;
        appealable: boolean;
        appealDetails: {
          type: TRegentsAppealTypes;
          examKey: TRegentsCompensatedExamS3Keys;
        };
        fulfilled: boolean;
        fulfilledLocal: boolean;
        fulfilledRegents: boolean;
        fulfilledCr: boolean;
      };
      sci: {
        maxScore: ITestScore;
        schedStatusStars: string;
        statusLocal: TRegentsStatusLocal;
        statusRegents: TRegentsStatusRegents;
        regentsName: TRegentsExamShortNames;
        appealable: boolean;
        appealDetails: {
          type: TRegentsAppealTypes;
          examKey: TRegentsCompensatedExamS3Keys;
        };
        fulfilled: boolean;
        fulfilledLocal: boolean;
        fulfilledRegents: boolean;
        fulfilledCr: boolean;
      };
      ss: {
        maxScore: ITestScore;
        schedStatusStars: string;
        statusLocal: TRegentsStatusLocal;
        statusRegents: TRegentsStatusRegents;
        regentsName: TRegentsExamShortNames;
        appealable: boolean;
        appealDetails: {
          type: TRegentsAppealTypes;
          examKey: TRegentsCompensatedExamS3Keys;
        };
        fulfilled: boolean;
        fulfilledLocal: boolean;
        fulfilledRegents: boolean;
        fulfilledCr: boolean;
      };
      plus1: {
        maxScore: ITestScore;
        schedStatusStars: string;
        statusLocal: TRegentsStatusLocal;
        statusRegents: TRegentsStatusRegents;
        regentsName: TRegentsExamShortNames;
        appealable: boolean;
        appealDetails: {
          type: TRegentsAppealTypes;
          examKey: TRegentsCompensatedExamS3Keys;
        };
        fulfilled: boolean;
        fulfilledLocal: boolean;
        fulfilledRegents: boolean;
        fulfilledCr: boolean;
      };
      lote: {
        maxScore: ITestScore;
        schedStatusStars: string;
        statusLocal: TRegentsStatusLocal;
        statusRegents: TRegentsStatusRegents;
        regentsName: TRegentsExamShortNames;
        appealable: boolean;
        appealDetails: {
          type: TRegentsAppealTypes;
          examKey: TRegentsCompensatedExamS3Keys;
        };
        fulfilled: boolean;
        fulfilledLocal: boolean;
        fulfilledRegents: boolean;
        fulfilledCr: boolean;
      };
      secondSs: {
        maxScore: ITestScore;
        schedStatusStars: string;
        statusLocal: TRegentsStatusLocal;
        statusRegents: TRegentsStatusRegents;
        regentsName: TRegentsExamShortNames;
        fulfilled: boolean;
        fulfilledLocal: boolean;
        fulfilledRegents: boolean;
        fulfilledCr: boolean;
      };
      secondSci: {
        maxScore: ITestScore;
        schedStatusStars: string;
        statusLocal: TRegentsStatusLocal;
        statusRegents: TRegentsStatusRegents;
        regentsName: TRegentsExamShortNames;
        fulfilled: boolean;
        fulfilledLocal: boolean;
        fulfilledRegents: boolean;
        fulfilledCr: boolean;
      };
      secondMath: {
        maxScore: ITestScore;
        schedStatusStars: string;
        statusLocal: TRegentsStatusLocal;
        statusRegents: TRegentsStatusRegents;
        regentsName: TRegentsExamShortNames;
        fulfilled: boolean;
        fulfilledLocal: boolean;
        fulfilledRegents: boolean;
        fulfilledCr: boolean;
      };
      thirdMath: {
        maxScore: ITestScore;
        schedStatusStars: string;
        statusLocal: TRegentsStatusLocal;
        statusRegents: TRegentsStatusRegents;
        regentsName: TRegentsExamShortNames;
        fulfilled: boolean;
        fulfilledLocal: boolean;
        fulfilledRegents: boolean;
        fulfilledCr: boolean;
      };
    };
    byCategory9: {
      ela: {
        maxScore: ITestScore;
        schedStatusStars: string;
        statusLocal: TRegentsStatusLocal;
        statusRegents: TRegentsStatusRegents;
        regentsName: TRegentsExamShortNames;
        fulfilled: boolean;
        fulfilledLocal: boolean;
        fulfilledRegents: boolean;
        fulfilledCr: boolean;
      };
      global: {
        maxScore: ITestScore;
        schedStatusStars: string;
        statusLocal: TRegentsStatusLocal;
        statusRegents: TRegentsStatusRegents;
        regentsName: TRegentsExamShortNames;
        fulfilled: boolean;
        fulfilledLocal: boolean;
        fulfilledRegents: boolean;
        fulfilledCr: boolean;
      };
      us: {
        maxScore: ITestScore;
        schedStatusStars: string;
        statusLocal: TRegentsStatusLocal;
        statusRegents: TRegentsStatusRegents;
        regentsName: TRegentsExamShortNames;
        fulfilled: boolean;
        fulfilledLocal: boolean;
        fulfilledRegents: boolean;
        fulfilledCr: boolean;
      };
      alg: {
        maxScore: ITestScore;
        schedStatusStars: string;
        statusLocal: TRegentsStatusLocal;
        statusRegents: TRegentsStatusRegents;
        regentsName: TRegentsExamShortNames;
        fulfilled: boolean;
        fulfilledLocal: boolean;
        fulfilledRegents: boolean;
        fulfilledCr: boolean;
      };
      geom: {
        maxScore: ITestScore;
        schedStatusStars: string;
        statusLocal: TRegentsStatusLocal;
        statusRegents: TRegentsStatusRegents;
        regentsName: TRegentsExamShortNames;
        fulfilled: boolean;
        fulfilledLocal: boolean;
        fulfilledRegents: boolean;
        fulfilledCr: boolean;
      };
      trig: {
        maxScore: ITestScore;
        schedStatusStars: string;
        statusLocal: TRegentsStatusLocal;
        statusRegents: TRegentsStatusRegents;
        regentsName: TRegentsExamShortNames;
        fulfilled: boolean;
        fulfilledLocal: boolean;
        fulfilledRegents: boolean;
        fulfilledCr: boolean;
      };
      lifeSci: {
        maxScore: ITestScore;
        schedStatusStars: string;
        statusLocal: TRegentsStatusLocal;
        statusRegents: TRegentsStatusRegents;
        regentsName: TRegentsExamShortNames;
        fulfilled: boolean;
        fulfilledLocal: boolean;
        fulfilledRegents: boolean;
        fulfilledCr: boolean;
      };
      physSci: {
        maxScore: ITestScore;
        schedStatusStars: string;
        statusLocal: TRegentsStatusLocal;
        statusRegents: TRegentsStatusRegents;
        regentsName: TRegentsExamShortNames;
        fulfilled: boolean;
        fulfilledLocal: boolean;
        fulfilledRegents: boolean;
        fulfilledCr: boolean;
      };
      lote: {
        maxScore: ITestScore;
        schedStatusStars: string;
        statusLocal: TRegentsStatusLocal;
        statusRegents: TRegentsStatusRegents;
        regentsName: TRegentsExamShortNames;
        fulfilled: boolean;
        fulfilledLocal: boolean;
        fulfilledRegents: boolean;
        fulfilledCr: boolean;
      };
    };
    byExam: {
      compEla: IRegentsExamDetails;
      ccEla: IRegentsExamDetails;
      intAlg: IRegentsExamDetails;
      ccAlg: IRegentsExamDetails;
      oldGeom: IRegentsExamDetails;
      ccGeom: IRegentsExamDetails;
      oldTrig: IRegentsExamDetails;
      ccTrig: IRegentsExamDetails;
      global: IRegentsExamDetails;
      us: IRegentsExamDetails;
      earth: IRegentsExamDetails;
      chem: IRegentsExamDetails;
      livingEnv: IRegentsExamDetails;
      physics: IRegentsExamDetails;
      lote: IRegentsExamDetails;
    };
    transcript: IRegentsTranscript[];
    exemptions: {
      statusLocal: string;
      statusRegents: string;
      statusAdvanced: string;
      completeLocal: number;
      completeRegents: number;
      completeAdvanced: number;
      inProgressLocal: number;
      inProgressRegents: number;
      inProgressAdvanced: number;
      notEligibleLocal: number;
      notEligibleRegents: number;
      notEligibleAdvanced: number;
    };
  };
  schoolDetails: {
    schoolId: string;
    district: number;
    schoolName: string;
    schoolNick: string;
    termStructure: TValidTermTypes;
  };
  spedDetails: {
    isSped: boolean;
    iep: TIEPStatus;
    potentialSafetyNet: TPotentialSafetyNetStatus;
    program: string;
    classification: string;
    wheelchair: string;
  };
  studentDetails: {
    transitTimeMinutes: number;
    admitDate: string;
    dischargeDate: string;
    cell: string;
    classOrGrade: string;
    classOf: string;
    grade?: string;
    currGradeLevel: string;
    dischargeCode: string;
    dob: string;
    email: string;
    ethnicity: string;
    gender: string;
    officialClass: string;
    sendingSchool: string;
    slc: string;
    tempResFlag: boolean;
    housingStatus: string;
    counselor: string;
    counselorEmail: string;
    guidanceCounselorTemp: string;
    guidanceCounselorEmailTemp: string;
    advisorTemp: string;
    advisorEmailTemp: string;
    name: {
      first: string;
      last: string;
      lastFirst: string;
    };
    summerSchool: {
      attendedInPriorSy: boolean;
      attendanceBySy: IAttendanceBySy[];
    };
    testingModifications: {
      codeString: string;
      allMods: ITestingModification[];
    };
    homeLanguage: string;
    learningPreference: TValidLearningPreferenceTypes;
  isECFIKEligible?: boolean;
  };
  studentProgramDetails: {
    currCourses: string;
    currSy: ICurrProgramCourse[];
  };
  transferDetails: {
    isTransfer: boolean;
    ageOn1231ThisSy: number;
    countOfAdmitsToCurrSchool: number;
    creditsEarnedBucketPriorToCurrSy: string;
    creditsEarnedPriorToFirstEntry: number;
    isSenior: boolean;
    intakeElaAssessmentData: string;
    intakeMathAssessmentData: string;
    mostAtRisk: boolean;
    overAgeUnderCredited: boolean;
    regentsPassedAt65PlusPriorToFirstEntry: number;
    yearOfEntry: string;
  };
  ellDetails: {
    isEll: boolean;
    ellStatus: string;
    lepStatus: string;
    sife: boolean;
    testOutYr: string;
    nyseslat: {
      priorSy: string;
      twoSyPrior: string;
      threeSyPrior: string;
      examScoreHistory: {
        nyseslat: string;
        year: string;
      }[];
    };
  };
  gradDetails: {
    threatsToGrad: {
      courseFailureThreat: boolean;
      creditGapThreat: boolean;
      regentsThreat: boolean;
      attendanceThreat: boolean;
      totalThreats: number;
    };
    progress: {
      grad: {
        num: number;
        string: string;
      };
      cuny: {
        num: number;
        string: string;
      };
      termsToGrad: {
        num: number;
        string: string;
      };
    };
  };
  lastUpdateDetails: {
    ats: string;
    attendance: string;
    collegeBoard: string;
    rdal: string;
    sesis: string;
    stars: string;
  };
  middleSchoolDetails: {
    courseMarkHistory: {
      eighthGrade: {
        year: string;
        elaCoreMs: {
          mark: ITestScore;
        };
        mathCoreMs: {
          mark: ITestScore;
        };
        ssCoreMs: {
          mark: ITestScore;
        };
        sciCoreMs: {
          mark: ITestScore;
        };
      };
      seventhGrade: {
        year: string;
        elaCoreMs: {
          mark: ITestScore;
        };
        mathCoreMs: {
          mark: ITestScore;
        };
        ssCoreMs: {
          mark: ITestScore;
        };
        sciCoreMs: {
          mark: ITestScore;
        };
      };
      sixthGrade: {
        year: string;
        elaCoreMs: {
          mark: ITestScore;
        };
        mathCoreMs: {
          mark: ITestScore;
        };
        ssCoreMs: {
          mark: ITestScore;
        };
        sciCoreMs: {
          mark: ITestScore;
        };
      };
    };
    examScoreSummary: {
      pr8thGrade: {
        rd: number;
        ma: number;
        avg: number;
      };
      pr7thGrade: {
        rd: number;
        ma: number;
        avg: number;
      };
    };
    examScoreHistory: IExamScoreHistory[];
    lowestThird: {
      cityWide: boolean;
      nv: boolean;
      school: boolean;
    };
    promotedInSy: IPromotedHistory[];
    mpDetails: {
      currentTerm: number | null;
      priorMp: number | null;
      latestMp: number | null;
      mostRecentChangeMpAverage: number | null;
      termMp: ITermMp[];
    };
  };
  gradPlanningDetails: {
    plannedCreds: TValidDiplomaCredentials[];
    plannedDiplomaType: TValidPlannedDiplomaTypes;
    plannedEndorsements: TValidDiplomaEndorsements[];
    plannedGraduationDate?: TValidGradDates;
    schoolVerifiedSafetyNetEligibility?: boolean;
  };
  nextScheduledRegents: {
    global: INextScheduledRegentsExam;
    us: INextScheduledRegentsExam;
    compEla: INextScheduledRegentsExam;
    ccEla: INextScheduledRegentsExam;
    intAlg: INextScheduledRegentsExam;
    ccAlg: INextScheduledRegentsExam;
    oldGeom: INextScheduledRegentsExam;
    ccGeom: INextScheduledRegentsExam;
    oldTrig: INextScheduledRegentsExam;
    ccTrig: INextScheduledRegentsExam;
    livingEnv: INextScheduledRegentsExam;
    chem: INextScheduledRegentsExam;
    physics: INextScheduledRegentsExam;
    earth: INextScheduledRegentsExam;
    lote: INextScheduledRegentsExam;
  };
  studentContactDetails: {
    email: string | null;
    mobile: string | null;
    parentEmail: string | null;
  };
  pointPeople?: IPointPerson[];
  otherStaff?: IPointPerson[];
  activeStudentSupports?: IActiveStudentSupport[];

  schoolRiskGroupELA?: TValidSchoolRiskGroups;
  schoolRiskGroupMath?: TValidSchoolRiskGroups;

  eopFinancialEligibility?: { status: TEopFinancialEligibility; lastUpdatedBy?: IUserMini };
  postsecondary?: {
    isELACR: boolean;
    isMathCR: boolean;
    isElaCrAndMathCr: string;
    ACT: {
      readingMax: number;
      mathMax: number;
      compositeMax: number;
    };
    PSAT: {
      totalMax: number;
      readingMax: number;
      mathMax: number;
      writingMax: number;
      attempts: number;
      examScoreHistory: IPostsecondaryExamsCoreHistory[];
    };
    SAT: {
      total1600Max: number;
      readingMax: number;
      mathMax: number;
      writingMax: number;
      total2400Max: number;
      attempts: number;
      superScore: number;
      examScoreHistory: IPostsecondaryExamsCoreHistory[];
    };
    ap: {
      countOfExams: number;
      countOfExamsWith3: number;
      examScoreHistory: IPostsecondaryExamsCoreHistory[]
    };
  };
  postsecondaryFuturePlan?: IPostsecondaryFuturePlan;

  // google classroom
  googleClassroom: {
    remoteEngagement: number;
    remoteEngagementLast5: number;
    totalAssignments: number;
    totalEngagedAssignments: number;
    totalTurnedInLast5: number;
    totalTurnedInLastDay: number;
    attendancePrior: number;
    isActive: boolean;
    totalEnrolled: number;
    studentEmail: string;
    courses: Array<{
      last: string;
      first: string;
      courseId: string;
      domain: string;
      assignmentsTurnedIn: number;
      assignmentsGiven: number;
      ownerId: string;
      teacherGafeEmail: string;
      teacherName: string;
      name: string;
      section: string;
      descriptionHeading: string;
      folderLink: string;
      mostRecentSubmittedAssignment: string;
      id: string;
      assignments: Array<{
        domain: string;
        courseId: string;
        assignmentName: string;
        assignmentId: string;
        submissionId: string;
        actionDate: string;
        driveFileId: string;
        id: string;
      }>;
    }>;
    termYear: number | null;
    termYearDescription: string | null;
    schoolYear: string | null;
    lastDay: string | null;
    termSummary: Array<IGoogleClassroomTermSummary>;
  };

  // device access
  deviceAccess: {
    hasComputerAccess: boolean | null;
    hasInternetAccess: boolean | null;
    hasSmartPhoneAccess: boolean | null;
    deviceAgreement: boolean | null;
    requestStatus: string | null;
    deliveredDate: string | null;
    shippedDate: string | null;
    expectedDeliveryDate: string | null;
  };

  // remote interaction
  remoteInteraction: {
    remoteInteractionRateTotal: number | null;
    remoteInteractionDaysTotal: number | null;
    remoteDaysIncludedTotal: number | null;
    remoteInteractionRateLast5: number | null;
    remoteInteractionDaysLast5: number | null;
    remoteDaysIncludedLast5: number | null;
    remoteInteractionRateLast20: number | null;
    remoteInteractionDaysLast20: number | null;
    remoteDaysIncludedLast20: number | null;
    remoteInteractionLastSchoolDay: boolean | null;
    remoteInteractionDifferenceFromAttendance: number | null;
    disconnected: boolean | null;
    newlyDisconnected: boolean | null;
    continuedDisconnected: boolean | null;
    attendancePriorToRemote: number | null;
    remoteInteractionStreak: number | null;
    termYear: number | null;
    termYearDescription: string | null;
    schoolYear: string | null;
    lastDay: string | null;
    termSummary: Array<IRemoteInteractionTermSummary>;
  };

  // google hangout
  googleHangout: {
    isActive: boolean | null;
    remoteEngagement: number | null;
    remoteEngagementLast5: number | null;
    remoteEngagementLast1: number | null;
    total: number | null;
    totalLast5: number | null;
    totalLast1: number | null;
    totalDays: number | null;
    totalDaysLast5: number | null;
    totalDaysLast1: number | null;
    devices: string[] | null;
    devicesLast5: string[] | null;
    devicesLast1: string[] | null;
    termYear: number | null;
    termYearDescription: string | null;
    schoolYear: string | null;
    lastDay: string | null;
    termSummary: Array<IGoogleHangoutTermSummary>;
  };

  otherSchools: IOtherSchool[];

  // Joins
  // eslint-disable-next-line
  join_studentSupports: any; // TODO: Should be an array of studentSupport interfaces once we have those
  // eslint-disable-next-line
  join_pendingCourseDiffs: any; // TODO: Should be an array of courseDiff interfaces once we have those
  // eslint-disable-next-line
  join_activeGapPlans: any; // TODO: Should be an array of gapPlan interfaces once we have those
  summerAtt: any;
  // eslint-disable-next-line
  join_student_mapGrowth: IMapGrowth; // TODO: add interfaces
  // eslint-disable-next-line
  join_student_iready: IStudentiReady;
  // eslint-disable-next-line
  join_student_dessa: IStudentDessa;

  // Note: we leave off sorterDetails since the front-end should never be using that
}
