/* tslint:disable */

import { Action } from '@ngrx/store';

export const LOAD_STUDENT = '[Portal] Load Student';
export const LOAD_STUDENT_SUCCESS = '[Portal] Load Student Success';
export const LOAD_STUDENT_FAIL = '[Portal] Load Student Fail';

export const LOAD_STUDENT_FROM_OTHER_SCHOOL = '[Portal] Load From Other School Student';
export const LOAD_STUDENT_FROM_OTHER_SCHOOL_SUCCESS = '[Portal] Load Student From Other School Success';
export const LOAD_STUDENT_FROM_OTHER_SCHOOL_FAIL = '[Portal] Load Student From Other School Fail';

export const UPDATE_STUDENT_SDC_STORE = '[Portal] Update Student Through SDC Store';
export const UPDATE_STUDENT_SDC_STORE_SUCCESS = '[Portal] Update Student Through SDC Store Success';

export const UPDATE_STUDENT = '[Portal] Update Student';
export const UPDATE_STUDENT_SUCCESS = '[Portal] Update Student Success';
export const OTHER_SINGLE_STUDENT_UPDATE_SUCCESS = '[Portal] Other Single Student Update Success';
export const UPDATE_STUDENT_FAIL = '[Portal] Update Student Fail';

export const UPDATE_STUDENT_FROM_PUSHER = '[Portal] Update Student From Pusher';

export class LoadSingleStudent implements Action {
  readonly type = LOAD_STUDENT;
  constructor (public payload: any) {}
}
export class LoadSingleStudentSuccess implements Action {
  readonly type = LOAD_STUDENT_SUCCESS;
  constructor (public payload: any) {}
}
export class LoadSinglesStudentFail implements Action {
  readonly type = LOAD_STUDENT_FAIL;
  constructor (public payload: any) {}
}

export class LoadSingleStudentFromOtherSchool implements Action {
  readonly type = LOAD_STUDENT_FROM_OTHER_SCHOOL;
  constructor (public payload: any) {}
}
export class LoadSingleStudentFromOtherSchoolSuccess implements Action {
  readonly type = LOAD_STUDENT_FROM_OTHER_SCHOOL_SUCCESS;
  constructor (public payload: any) {}
}
export class LoadSinglesStudentFromOtherSchoolFail implements Action {
  readonly type = LOAD_STUDENT_FROM_OTHER_SCHOOL_FAIL;
  constructor (public payload: any) {}
}

export class UpdateSingleStudentThroughSDCStore implements Action {
  readonly type = UPDATE_STUDENT_SDC_STORE;
  constructor (public payload: any) {}
}
export class UpdateSingleStudentThroughSDCStoreSuccess implements Action {
  readonly type = UPDATE_STUDENT_SDC_STORE_SUCCESS;
  constructor (public payload: any) {}
}

export class UpdateSingleStudent implements Action {
  readonly type = UPDATE_STUDENT;
  constructor (public payload: any) {}
}

export class UpdateSingleStudentSuccess implements Action {
  readonly type = UPDATE_STUDENT_SUCCESS;
  constructor (public payload: any) {}
}

export class OtherSingleStudentUpdateSuccess implements Action {
  readonly type = OTHER_SINGLE_STUDENT_UPDATE_SUCCESS;
  constructor (public payload: any) {}
}

export class UpdateSingleStudentFail implements Action {
  readonly type = UPDATE_STUDENT_FAIL;
  constructor (public payload: any) {}
}
export class UpdateSingleStudentFromPusher implements Action {
  readonly type = UPDATE_STUDENT_FROM_PUSHER;
  constructor (public payload: any) {}
}

// action types
export type SingleStudentAction =
  | LoadSingleStudent
  | LoadSingleStudentSuccess
  | LoadSinglesStudentFail
  | OtherSingleStudentUpdateSuccess
  | UpdateSingleStudent
  | UpdateSingleStudentFail
  | UpdateSingleStudentFromPusher
  | UpdateSingleStudentSuccess
  | UpdateSingleStudentThroughSDCStore
  | UpdateSingleStudentThroughSDCStoreSuccess;
