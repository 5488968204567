<base-modal 
  class="base-modal college-now-eligibility-modal" 
  [title]="title" 
  [isProfileMode]="isProfileMode"
>
  <p id="eligibility-body-text">You have the option to change student eligibility if you believe they're just a few points short or if there's a specific subject holding them back.</p>

  <nv-form-field>
    <nv-dropdown
      [options]="eligibilityOptions"
      [selected]="selectedOption"
      (selectOption)="onOptionChange($event)"
    >
    </nv-dropdown>
  </nv-form-field>

  <nv-form-field *ngIf="isEligibleSelected" label="Comment">
    <nv-textarea
      id="comment-field"
      [formControl]="formControl.controls.comment"
      [maxCharacters]="150"
      placeholder="Enter a reason for changing student's eligibility…"
    ></nv-textarea>
  </nv-form-field>

  <mat-dialog-actions class="dialog-actions">
    <nv-button type="secondary" (clickButton)="onCancel()">
      Cancel
    </nv-button>
    <nv-button htmlType="submit" (clickButton)="onSave()" [disabled]="formControl.pristine || formControl.invalid">
      Save
    </nv-button>
  </mat-dialog-actions>
</base-modal>
