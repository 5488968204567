import { SubjectAreas } from '../../constants/subject-areas.constant';

const {
  // ES & MS
  ELA_CORE_MS,
  MATH_CORE_MS,
  SCI_CORE_MS,
  SS_CORE_MS,
  // HS
  ELA_CORE,
  MATH_ALG,
  MATH_GEOM,
  MATH_TRIG,
  SS_GLOBAL,
  SS_US,
  SS_GOVT,
  SS_ECON,
  SS_GOVT_ECON,
  SCI_LIVING,
  SCI_BIO,
  SCI_CHEM,
  SCI_EARTH,
  SCI_PHYSICS,
  LOTE,
  PE,
  HEALTH,
  ART,
  CTE,
  // HYBRID IS THE COMBO OF BOTH
} = SubjectAreas;

export const supportSubjectOptionsBySchool: any = {
  'K to 5': [ELA_CORE_MS, MATH_CORE_MS, SCI_CORE_MS, SS_CORE_MS],
  'K to 8': [ELA_CORE_MS, MATH_CORE_MS, SCI_CORE_MS, SS_CORE_MS],
  '6 to 8': [ELA_CORE_MS, MATH_CORE_MS, SCI_CORE_MS, SS_CORE_MS],
  'K to 12': [
    // ES & MS
    ELA_CORE_MS,
    MATH_CORE_MS,
    SCI_CORE_MS,
    SS_CORE_MS,
    // HS
    ELA_CORE,
    MATH_ALG,
    MATH_GEOM,
    MATH_TRIG,
    SS_GLOBAL,
    SS_US,
    SS_GOVT,
    SS_ECON,
    SS_GOVT_ECON,
    SCI_LIVING,
    SCI_BIO,
    SCI_CHEM,
    SCI_EARTH,
    SCI_PHYSICS,
    LOTE,
    PE,
    HEALTH,
    ART,
    CTE,
  ],
  '6 to 12': [
    // ES & MS
    ELA_CORE_MS,
    MATH_CORE_MS,
    SCI_CORE_MS,
    SS_CORE_MS,
    // HS
    ELA_CORE,
    MATH_ALG,
    MATH_GEOM,
    MATH_TRIG,
    SS_GLOBAL,
    SS_US,
    SS_GOVT,
    SS_ECON,
    SS_GOVT_ECON,
    SCI_LIVING,
    SCI_BIO,
    SCI_CHEM,
    SCI_EARTH,
    SCI_PHYSICS,
    LOTE,
    PE,
    HEALTH,
    ART,
    CTE,
  ],
  'Large HS': [
    ELA_CORE,
    MATH_ALG,
    MATH_GEOM,
    MATH_TRIG,
    SS_GLOBAL,
    SS_US,
    SS_GOVT,
    SS_ECON,
    SS_GOVT_ECON,
    SCI_LIVING,
    SCI_BIO,
    SCI_CHEM,
    SCI_EARTH,
    SCI_PHYSICS,
    LOTE,
    PE,
    HEALTH,
    ART,
    CTE,
  ],
  'Small HS': [
    ELA_CORE,
    MATH_ALG,
    MATH_GEOM,
    MATH_TRIG,
    SS_GLOBAL,
    SS_US,
    SS_GOVT,
    SS_ECON,
    SS_GOVT_ECON,
    SCI_LIVING,
    SCI_BIO,
    SCI_CHEM,
    SCI_EARTH,
    SCI_PHYSICS,
    LOTE,
    PE,
    HEALTH,
    ART,
    CTE,
  ],
  Charter: [
    ELA_CORE,
    MATH_ALG,
    MATH_GEOM,
    MATH_TRIG,
    SS_GLOBAL,
    SS_US,
    SS_GOVT,
    SS_ECON,
    SS_GOVT_ECON,
    SCI_LIVING,
    SCI_BIO,
    SCI_CHEM,
    SCI_EARTH,
    SCI_PHYSICS,
    LOTE,
    PE,
    HEALTH,
    ART,
    CTE,
  ],
  Transfer: [
    ELA_CORE,
    MATH_ALG,
    MATH_GEOM,
    MATH_TRIG,
    SS_GLOBAL,
    SS_US,
    SS_GOVT,
    SS_ECON,
    SS_GOVT_ECON,
    SCI_LIVING,
    SCI_BIO,
    SCI_CHEM,
    SCI_EARTH,
    SCI_PHYSICS,
    LOTE,
    PE,
    HEALTH,
    ART,
    CTE,
  ],
};
