import { CurrentSchoolYear } from 'Src/ng2/shared/constants/current-school-year.constant';
import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { uniq } from 'lodash';
import { UpdateDashboardStudentsSuccess } from './../../../store/actions/dashboard-actions';
import { UpdatePartnerOrgSuccess } from './../../../store/actions/partner-orgs-actions';
import { UpdateSingleStudentFromPusher } from './../../../store/actions/single-student-actions';

import { MatSnackBar } from '@angular/material/snack-bar';
import { NvToastComponent } from '../../../../nvps-libraries/design/nv-toast/nv-toast.component';
import {
  BulkCreateSchoolAssessmentsSuccess,
  BulkUpdateSchoolAssessmentsSuccess,
  CreateSyncToolFail,
  CreateSyncToolSuccess,
  DeleteSyncToolFail,
  DeleteSyncToolSuccess,
  LoadDataLoadsSuccess,
  LoadDocLogs,
  LoadPartnerOrgsSuccess,
  LoadStudentAssessments,
  UpdateStudentAssessmentSuccess,
  UpdateSyncToolSuccess,
  UpdateSyncToolFail,
} from '../../../store';
import { ModalsService } from '../../modals/modals.service';
import { IFlag } from '../../typings/interfaces/flags.interface';
import { IStudentSupport } from '../../typings/interfaces/student-support.interface';
import { ITool } from '../../typings/interfaces/tool.interface';
import { LoadCareerPath } from './../../../store/actions/career-path-actions';
import { LoadFlags } from './../../../store/actions/flag-actions';
import {
  CreateStudentPathSuccess,
  LoadAllStudentPaths,
  UpdateStudentPathSuccess,
} from './../../../store/actions/student-paths-actions';
import {
  //  BulkCreateStudentSupportsSuccess,
  CreateStudentSupportSuccess,
  LoadStudentSupports,
} from './../../../store/actions/student-supports-actions';
import { CHANNELS } from './pusher-channels.constant';
import { StudentUpdateNotificationService } from '../student-update-notification-service.ts/student-update-notification.service';

/* istanbul ignore next */
@Injectable()
export class PusherListeners {
  public activeListeners = [];
  constructor (
    private store: Store<any>,
    private injector: Injector,
    private snackBar: MatSnackBar,
    private modalsService: ModalsService,
    private studentUpdateNotificationService: StudentUpdateNotificationService,
  ) {}

  /** Bind all relevant events to channel when invoking this function(JJ) */
  initializePusherListeners (channel) {
    /**
     * Unsubscribe all previously activeListeners
     * Use case: Portal user changes schools & reinitializes a new pusher channel
     * In that case we want to unsubscribe before moving on
     */
    this.destroyListeners();

    this.onStudentsPatch(channel, data => {
      const { patches } = data;
      const processedPatches = patches.map(patchDetails => {
        const { studentIds: _ids, patch } = patchDetails;
        return { _ids, patch };
      });
      // Send student's ids that were updated by batch actions
      const uniqueStudentIds: string[] = uniq(processedPatches.map((patch) => patch._ids).flat());
      this.studentUpdateNotificationService.sendStudentUpdateNotificationMessage({
        update: 'BulkStudentUpdate',
        studentId: uniqueStudentIds,
      });
      // Dispatch actions to update all student slices
      const payload = { patches: processedPatches };
      this.store.dispatch(new UpdateDashboardStudentsSuccess(payload));
      this.store.dispatch(new UpdateSingleStudentFromPusher(payload));
    });

    this.onFlagPatched(channel, (flagData: IFlag) => {
      const payload = {
        schoolId: flagData[0].schoolId,
      };
      this.store.dispatch(new LoadFlags(payload));
    });

    // docLogs payload does not currently have a shape that matches IDocLog[]. Adjust payload
    this.onDocLogAdded(channel, docLogs => {
      const { schoolId, studentId } = docLogs.itemToAdd;
      const payload = { studentId, schoolId };
      this.store.dispatch(new LoadDocLogs(payload));
    });

    this.onDocLogPatched(channel, docLogs => {
      const { schoolId, studentId, patch } = docLogs[0];
      const studId = studentId || patch.studentId;
      const payload = { studentId: studId, schoolId };
      this.store.dispatch(new LoadDocLogs(payload));
    });

    this.onPartnerOrgAdded(channel, activity => {
      const addedPartnerOrg = activity.itemToAdd;
      const payload = { data: { PartnerOrgs: [addedPartnerOrg] } };
      this.store.dispatch(new LoadPartnerOrgsSuccess(payload));
    });

    this.onPartnerOrgPatched(channel, activity => {
      const { itemId, patch } = activity[0];
      const payload = { partnerOrgId: itemId, patch };
      this.store.dispatch(new UpdatePartnerOrgSuccess(payload));
    });

    this.onStudentSupportAdded(
      channel,
      (payload: { studentIdsToItemIds: { [key: string]: string }; itemToAdd: IStudentSupport }) => {
        // TODO: pusher message for `bulk` background jobs for students supports only includes a partial document.
        // Hence, we are reloading the student supports on bulk updates.
        // Once, we have a pattern to handle bulk updates, this should be refactored to follow that pattern (CM).

        // bulk pusher message does not include `student`.
        const isBulkUpdate = payload.itemToAdd.student === undefined || payload.itemToAdd.student.studentId === null;

        if (isBulkUpdate) {
          this.store.dispatch(new LoadStudentSupports({ schoolId: payload.itemToAdd.schoolId }));

          // ideally we should dispatch BulkCreateStudentSupportsSuccess with a payload containing
          // the full studentSupport doc - but Pusher does not return full doc from the backend
          // so we have to populate the store by requerying the API for all studentSupports and
          // loading the full collection into ngrx

          // const storePayload = { studentSupport: payload.itemToAdd };
          // this.store.dispatch(new BulkCreateStudentSupportsSuccess(storePayload));'
        } else {
          this.store.dispatch(new CreateStudentSupportSuccess(payload.itemToAdd));
        }
      },
    );

    this.onStudentSupportPatched(channel, studentSupports => {
      const payload = {
        schoolId: studentSupports[0].schoolId,
      };
      this.store.dispatch(new LoadStudentSupports(payload));
    });

    this.onSchoolAssessmentAdded(channel, payload => {
      const { itemToAdd } = payload;
      const data = {
        createSchoolAssessment: itemToAdd,
      };
      this.store.dispatch(new BulkCreateSchoolAssessmentsSuccess(data));
    });

    this.onSchoolAssessmentPatched(channel, payload => {
      const { patches } = payload;
      const data = {
        updateSchoolAssessment: patches,
      };
      this.store.dispatch(new BulkUpdateSchoolAssessmentsSuccess(data));
    });

    this.onStudentAssessmentsAdded(channel, payload => {
      const { schoolId } = payload.itemToAdd;
      // dispatch action to load all student assessments, as bulk create is done
      this.store.dispatch(new LoadStudentAssessments({ schoolId, schoolYear: CurrentSchoolYear.WITH_SY_PREFIX }));
    });

    this.onStudentAssessmentPatched(channel, payload => {
      const { patches } = payload;
      this.store.dispatch(new UpdateStudentAssessmentSuccess(patches[0]));
    });

    this.onStudentPathsAdded(channel, payload => {
      // TODO: pusher message for `bulk` background jobs for students paths only includes a partial document.
      // Hence, we are reloading the student paths on bulk updates.
      // Once we have a pattern to handle bulk updates, this should be refactored to follow that pattern (JE).

      // bulk pusher message does not include `dueAt`.
      const { itemToAdd } = payload;
      const isBulkUpdate = itemToAdd.dueAt === undefined;
      const { schoolId } = itemToAdd;

      if (isBulkUpdate) {
        this.store.dispatch(new LoadAllStudentPaths({ schoolId }));

        // ideally we should dispatch BulkCreateStudentPathsSuccess with a payload containing
        // the full studentPath doc - but Pusher does not return full doc from the backend
        // so we have to populate the store by requerying the API for all studentPaths and
        // loading the full collection into ngrx

        // const storePayload = { studentPath: payload.itemToAdd };
        // this.store.dispatch(new BulkCreateStudentPathsSuccess(storePayload));'
      } else {
        this.store.dispatch(new CreateStudentPathSuccess(itemToAdd));
      }
      // update CAREER path slices if new career added (JE)
      if (itemToAdd.path && itemToAdd.path.type === 'CAREER') {
        this.store.dispatch(new LoadCareerPath({ schoolId, pathType: 'CAREER' }));
      }
    });

    this.onStudentPathsPatched(channel, studentPath => {
      const { patch, schoolId } = studentPath[0];
      // TODO: pusher message for `bulk` background jobs for students paths only includes a partial document.
      // Hence, we are reloading the student paths on bulk updates.
      // Once we have a pattern to handle bulk updates, this should be refactored to follow that pattern (JE).

      // bulk pusher message does not include `path`.
      const isBulkUpdate = !patch.path;
      if (isBulkUpdate) {
        this.store.dispatch(new LoadAllStudentPaths({ schoolId }));

        // ideally we should dispatch BulkCreateStudentPathsSuccess with a payload containing
        // the full studentPath doc - but Pusher does not return full doc from the backend
        // so we have to populate the store by requerying the API for all studentPaths and
        // loading the full collection into ngrx

        // const storePayload = { studentPath: payload.itemToAdd };
        // this.store.dispatch(new BulkCreateStudentPathsSuccess(storePayload));'
      } else {
        this.store.dispatch(new UpdateStudentPathSuccess(patch));
      }
    });

    this.onDataLoadAdded(channel, dataLoad => {
      const addedDataLoad = dataLoad.itemToAdd;
      const payload = { data: { DataLoads: [addedDataLoad] } };
      this.store.dispatch(new LoadDataLoadsSuccess(payload));
    });

    this.onCreateSyncToolSuccess(channel, (data: { itemToAdd: ITool }) => {
      this.store.dispatch(new CreateSyncToolSuccess(data.itemToAdd));
    });

    this.onCreateSyncToolFailure(channel, (data: Array<{ itemId: string; patch: ITool; schoolId: string }>) => {
      this.store.dispatch(new CreateSyncToolFail(data[0].patch));
      this.snackBar.openFromComponent(NvToastComponent, {
        data: {
          toastText: 'There was an error creating your Google sheet. Please try again later.',
          hasClearButton: false,
          actionText: null,
        },
        duration: 2000,
      });
    });

    this.onDeleteSyncToolSuccess(channel, (data: Array<{ itemId: string; patch: ITool; schoolId: string }>) => {
      this.store.dispatch(new DeleteSyncToolSuccess(data[0].patch));
      this.snackBar.openFromComponent(NvToastComponent, {
        data: {
          toastText: 'Synced sheet deleted',
          hasClearButton: false,
          actionText: null,
        },
        duration: 2000,
      });
    });

    this.onDeleteSyncToolFailure(channel, (data: Array<{ itemId: string; patch: ITool; schoolId: string }>) => {
      this.store.dispatch(new DeleteSyncToolFail(data[0].patch));
      this.modalsService.openErrorModal({
        title: 'Unable to Delete Synced Sheet',
        message: 'The synced sheet could not be deleted. Please try again.',
      });
    });

    this.onUpdateSyncToolSuccess(channel, (data: Array<{ itemId: string; patch: ITool; schoolId: string }>) => {
      this.store.dispatch(new UpdateSyncToolSuccess(data[0].patch));
      this.snackBar.openFromComponent(NvToastComponent, {
        data: {
          toastText: 'Synced sheet updated',
          hasClearButton: false,
          actionText: null,
        },
        duration: 2000,
      });
    });

    this.onUpdateSyncToolFailure(channel, (data: Array<{ itemId: string; patch: ITool; schoolId: string }>) => {
      this.store.dispatch(new UpdateSyncToolFail(data[0].patch));
      this.snackBar.openFromComponent(NvToastComponent, {
        data: {
          toastText: 'There was an error updating your Google sheet. Please try again later.',
          hasClearButton: false,
          actionText: null,
        },
        duration: 2000,
      });
    });

    // TODO: Add functionality as needed
    // TODO: Which of these apply for v3? i.e. - where would we be patching a courseDiff?
    // this.onSchoolPatch(channel, (data) => { });
    // this.onGapPlanAdded(channel, (data) => { });
    // this.onGapPlanPatched(channel, (data) => { });
    // this.onCourseDiffAdded(channel, (data) => { });
    // this.onCourseDiffPatched(channel, (data) => { });
    // this.onSchoolSupportAdded(channel, (data) => { });
    // this.onSchoolSupportPatched(channel, (data) => { });
  }

  onStudentsPatch (channel, handler) {
    channel.bind('students-patch', handler);
    const unsubscribe = () => channel.unbind('students-patch', handler);
    this.activeListeners.push(unsubscribe);
  }

  // onStudentsDirty(channel, handler) {
  //   channel.bind('students-dirty', handler);
  //   const unsubscribe = () => channel.unbind('students-dirty', handler);
  //   this.activeListeners.push(unsubscribe);
  // }

  // onSchoolPatch(channel, handler) {
  //   channel.bind('school-patch', handler);
  //   const unsubscribe = () => channel.unbind('school-patch', handler);
  //   this.activeListeners.push(unsubscribe);
  // }

  // onGapPlanAdded(channel, handler) {
  //   channel.bind('gap-plan-added', handler);
  //   const unsubscribe = () => channel.unbind('gap-plan-added', handler);
  //   this.activeListeners.push(unsubscribe);
  // }

  // onGapPlanPatched(channel, handler) {
  //   channel.bind('gap-plan-patched', handler);
  //   const unsubscribe = () => channel.unbind('gap-plan-patched', handler);
  //   this.activeListeners.push(unsubscribe);
  // }

  // onCourseDiffAdded(channel, handler) {
  //   channel.bind('course-diff-added', handler);
  //   const unsubscribe = () => channel.unbind('course-diff-added', handler);
  //   this.activeListeners.push(unsubscribe);
  // }

  // onCourseDiffPatched(channel, handler) {
  //   channel.bind('course-diff-patched', handler);
  //   const unsubscribe = () => channel.unbind('course-diff-patched', handler);
  //   this.activeListeners.push(unsubscribe);
  // }

  // onSchoolSupportAdded(channel, handler) {
  //   channel.bind('school-support-added', handler);
  //   const unsubscribe = () => channel.unbind('school-support-added', handler);
  //   this.activeListeners.push(unsubscribe);
  //  }

  // onSchoolSupportPatched(channel, handler) {
  //   channel.bind('school-support-patched', handler);
  //   const unsubscribe = () => channel.unbind('school-support-patched', handler);
  //   this.activeListeners.push(unsubscribe);
  // }

  onFlagPatched (channel, handler) {
    channel.bind(CHANNELS.FLAG.PATCHED, handler);
    const unsubscribe = () => channel.unbind(CHANNELS.FLAG.PATCHED, handler);
    this.activeListeners.push(unsubscribe);
  }

  onDocLogAdded (channel, handler) {
    channel.bind(CHANNELS.DOC_LOG.ADDED, handler);
    const unsubscribe = () => channel.unbind(CHANNELS.DOC_LOG.ADDED, handler);
    this.activeListeners.push(unsubscribe);
  }

  onDocLogPatched (channel, handler) {
    channel.bind(CHANNELS.DOC_LOG.PATCHED, handler);
    const unsubscribe = () => channel.unbind(CHANNELS.DOC_LOG.PATCHED, handler);
    this.activeListeners.push(unsubscribe);
  }

  onPartnerOrgAdded (channel, handler) {
    channel.bind(CHANNELS.PARTNER_ORG.ADDED, handler);
    const unsubscribe = () => channel.unbind(CHANNELS.PARTNER_ORG.ADDED, handler);
    this.activeListeners.push(unsubscribe);
  }

  onPartnerOrgPatched (channel, handler) {
    channel.bind(CHANNELS.PARTNER_ORG.PATCHED, handler);
    const unsubscribe = () => channel.unbind(CHANNELS.PARTNER_ORG.PATCHED, handler);
    this.activeListeners.push(unsubscribe);
  }

  onCalculatedFieldsPatched (channel, handler) {
    channel.bind(CHANNELS.CALCULATED_FIELDS.PATCHED, handler);
    const unsubscribe = () => channel.unbind(CHANNELS.CALCULATED_FIELDS.PATCHED, handler);
    this.activeListeners.push(unsubscribe);
  }

  onStudentSupportAdded (channel, handler) {
    channel.bind(CHANNELS.STUDENT_SUPPORT.ADDED, handler);
    const unsubscribe = () => channel.unbind(CHANNELS.STUDENT_SUPPORT.ADDED, handler);
    this.activeListeners.push(unsubscribe);
  }

  onStudentSupportPatched (channel, handler) {
    channel.bind(CHANNELS.STUDENT_SUPPORT.PATCHED, handler);
    const unsubscribe = () => channel.unbind(CHANNELS.STUDENT_SUPPORT.PATCHED, handler);
    this.activeListeners.push(unsubscribe);
  }

  onStudentPathsAdded (channel, handler) {
    channel.bind(CHANNELS.STUDENT_PATHS.ADDED, handler);
    const unsubscribe = () => channel.unbind(CHANNELS.STUDENT_PATHS.ADDED, handler);
    this.activeListeners.push(unsubscribe);
  }

  onStudentPathsPatched (channel, handler) {
    channel.bind(CHANNELS.STUDENT_PATHS.PATCHED, handler);
    const unsubscribe = () => channel.unbind(CHANNELS.STUDENT_PATHS.PATCHED, handler);
    this.activeListeners.push(unsubscribe);
  }

  onCreateSyncToolSuccess (channel, handler) {
    channel.bind(CHANNELS.SYNC_TOOL.ADDED, handler);
    const unsubscribe = () => channel.unbind(CHANNELS.SYNC_TOOL.ADDED, handler);
    this.activeListeners.push(unsubscribe);
  }

  onCreateSyncToolFailure (channel, handler) {
    channel.bind(CHANNELS.SYNC_TOOL.FAILED_CREATION, handler);
    const unsubscribe = () => channel.unbind(CHANNELS.SYNC_TOOL.FAILED_CREATION, handler);
    this.activeListeners.push(unsubscribe);
  }

  onDeleteSyncToolSuccess (channel, handler) {
    channel.bind(CHANNELS.SYNC_TOOL.DELETED, handler);
    const unsubscribe = () => channel.unbind(CHANNELS.SYNC_TOOL.DELETED, handler);
    this.activeListeners.push(unsubscribe);
  }

  onDeleteSyncToolFailure (channel, handler) {
    channel.bind(CHANNELS.SYNC_TOOL.FAILED_DELETION, handler);
    const unsubscribe = () => channel.unbind(CHANNELS.SYNC_TOOL.FAILED_DELETION, handler);
    this.activeListeners.push(unsubscribe);
  }

  onUpdateSyncToolSuccess (channel, handler) {
    channel.bind(CHANNELS.SYNC_TOOL.UPDATED, handler);
    const unsubscribe = () => channel.unbind(CHANNELS.SYNC_TOOL.UPDATED, handler);
    this.activeListeners.push(unsubscribe);
  }

  onUpdateSyncToolFailure (channel, handler) {
    channel.bind(CHANNELS.SYNC_TOOL.FAILED_UPDATE, handler);
    const unsubscribe = () => channel.unbind(CHANNELS.SYNC_TOOL.FAILED_UPDATE, handler);
    this.activeListeners.push(unsubscribe);
  }

  onSchoolAssessmentAdded (channel, handler) {
    channel.bind(CHANNELS.SCHOOL_ASSESSMENT.ADDED, handler);
    const unsubscribe = () => channel.unbind(CHANNELS.SCHOOL_ASSESSMENT, handler);
    this.activeListeners.push(unsubscribe);
  }

  onSchoolAssessmentPatched (channel, handler) {
    channel.bind(CHANNELS.SCHOOL_ASSESSMENT.PATCHED, handler);
    const unsubscribe = () => channel.unbind(CHANNELS.SCHOOL_ASSESSMENT, handler);
    this.activeListeners.push(unsubscribe);
  }

  onStudentAssessmentsAdded (channel, handler) {
    channel.bind(CHANNELS.STUDENT_ASSESSMENT.ADDED, handler);
    const unsubscribe = () => channel.unbind(CHANNELS.STUDENT_ASSESSMENT, handler);
    this.activeListeners.push(unsubscribe);
  }

  onStudentAssessmentPatched (channel, handler) {
    channel.bind(CHANNELS.STUDENT_ASSESSMENT.PATCHED, handler);
    const unsubscribe = () => channel.unbind(CHANNELS.STUDENT_ASSESSMENT, handler);
    this.activeListeners.push(unsubscribe);
  }

  onDataLoadAdded (channel, handler) {
    channel.bind(CHANNELS.DATA_LOAD.ADDED, handler);
    const unsubscribe = () => channel.unbind(CHANNELS.DATA_LOAD, handler);
    this.activeListeners.push(unsubscribe);
  }

  destroyListeners () {
    this.activeListeners.forEach(unsubscribeFn => unsubscribeFn());
    this.activeListeners = [];
  }
}
