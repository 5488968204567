import { LOAD_CURRENT_USER_SUCCESS } from './../../../store/actions/current-user-actions';
/* tslint:disable */

import { INITIALIZE_PUSHER } from './../../../store/actions/pusher-actions';
import { PusherListeners } from './../pusher/pusher-listeners';
import {
  BULK_UPDATE_STUDENTS_SUCCESS,
} from './../../../store/actions/students-actions';
import { LOAD_TOGGLES_SUCCESS } from './../../../store/actions/toggle-actions';
import { LOAD_SCHOOL_SUCCESS } from './../../../store/actions/school-actions';
import { Store } from '@ngrx/store';
import { EventEmitter, Injectable } from '@angular/core';

export class Action {
  constructor (public type: string, public payload: any) {}
}

@Injectable()
export class EventBus {
  dispatcher: EventEmitter<any> = new EventEmitter();

  constructor (public store: Store<any>, public pusherListeners: PusherListeners) {}

  emitAction (action) {
    this.dispatcher.emit(action);
  }

  getAction () {
    return this.dispatcher;
  }

  dispatchAction (action) {
    this.store.dispatch(action);
  }

  initializeNgrxActionPipeline () {
    this.getAction().subscribe(action => {
      const { type, payload } = action;

      switch (type) {
        case BULK_UPDATE_STUDENTS_SUCCESS:
          this.dispatchAction(action);
          break;

        case LOAD_TOGGLES_SUCCESS:
          this.dispatchAction(action);
          break;

        case LOAD_CURRENT_USER_SUCCESS:
          this.dispatchAction(action);
          break;

        case LOAD_SCHOOL_SUCCESS:
          this.dispatchAction(action);
          break;

        case INITIALIZE_PUSHER: {
          const channel = payload;
          this.pusherListeners.initializePusherListeners(channel);
          break;
        }

        default:
      }
    });
  }
}
