import { Component, Input, ViewEncapsulation } from '@angular/core';

/* istanbul ignore next */
@Component({
  selector: 'nv-autocomplete-action',
  templateUrl: './nv-autocomplete-action.component.html',
  styleUrls: ['./nv-autocomplete-action.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NvAutocompleteActionComponent {
  @Input() icon: string;

  get showIcon() {
    return this.icon ? true : false;
  }
}
