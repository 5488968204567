import { Injectable } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { SpinnerModalComponent } from './spinner-modal.component';

export interface ISpinnerData {
  message: string;
}

@Injectable()
export class SpinnerService {
  constructor(public dialog: MatDialog) {}

  public openSpinner(data: ISpinnerData): MatDialogRef<SpinnerModalComponent> {
    const spinnerClass = {
      autoFocus: false,
      panelClass: 'nv-mat-dialog-sm', // same as the background job spinner modal
    };
    const additionalConfig = { disableClose: true };
    const modalConfig = { ...spinnerClass, ...additionalConfig, ...{ data } };
    const modalRef = this.dialog.open(SpinnerModalComponent, modalConfig);
    return modalRef;
  }
}
