import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseModalComponent } from '../base-modal.component';
import { actionItems } from '../../../school/content-area/regents/mock-regents-splash/mock-regents-splash.constant';
import { IActionItem } from 'Src/ng2/school/content-area/regents/mock-regents-splash/mock-regents-splash.component';

@Component({
  selector: 'add-mock-regents-exams',
  templateUrl: './add-mock-regents-exams.component.html',
  styleUrls: ['./add-mock-regents-exams.component.scss'],
})
export class AddMockRegentsExamsComponent extends BaseModalComponent implements OnInit {
  public title = 'How to add exam data to the Portal';
  public actionItems: IActionItem[];
  public isProfileMode: boolean = true;

  constructor (
    public dialogRef: MatDialogRef<AddMockRegentsExamsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super(dialogRef);
  }

  ngOnInit (): void {
    this.actionItems = actionItems;
  }

  public goToURL (url: string) {
    window.open(url, '_blank');
  }
}
