import { Options } from '@angular-slider/ngx-slider';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { IMultiSelectionOption, TMultiSelectOptionTypes } from './nv-multi-select-filter-section.interface';

@Component({
  selector: 'nv-multi-select-filter-section',
  templateUrl: './nv-multi-select-filter-section.component.html',
  styleUrls: ['./nv-multi-select-filter-section.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NvMultiSelectFilterSectionComponent implements OnInit {
  @Input() sectionTitle?: string = 'Multi Select Filter Section';
  @Input() hasSearch?: boolean = false;
  @Input() optionType?: TMultiSelectOptionTypes = 'radio';
  @Input() options: IMultiSelectionOption[];
  @Input() totalOptions?: number = 0;
  @Input() maxDefaultOptions?: number = 8;
  @Input() optionId?: string = '';
  @Input() currentlySelected: { [key: string]: string[] } = {};
  @Input() searchPlaceholder?: string = 'Search for';
  @Input() rangeFloor?: number = 0;
  @Input() rangeCeiling?: number = 100;
  @Output() optionChosen: EventEmitter<any> = new EventEmitter<any>();

  public currentCountText: string = '';

  public allChosen: boolean = false;

  public defaultOptions: Array<IMultiSelectionOption> = [];

  public seeMoreOptions: Array<IMultiSelectionOption> = [];

  public searchedOptions: Array<IMultiSelectionOption> = [];

  public toggleSeeMore: boolean = false;

  public searchInput: FormControl = new FormControl();

  public rangeOptions: Options = new Options();

  public sliderLowVal: number = 0;

  public sliderHighVal: number = 0;

  ngOnInit (): void {
    if (this.optionType === 'range') {
      this.setupRangeSlider();
    } else {
      this.createMultiSelectionOptionRow(this.options);
      if (this.seeMoreOptions.length > 0) {
        this.toggleSeeMore = true;
      }
      this.currentCountText = `0 of ${this.totalOptions}`;
    }
    if (this.currentlySelected !== null || this.currentlySelected !== undefined) {
      this.selectCurrentlySelected();
    }
  }

  ngAfterViewInit () {
    this.searchInput.valueChanges.pipe(debounceTime(300)).subscribe(res => {
      if (res) {
        this.searchedOptions = this.options.filter(option => {
          return option.value.toLowerCase().includes(res.toLowerCase());
        });
      }
    });
  }

  private createMultiSelectionOptionRow (input: IMultiSelectionOption[]) {
    for (let i = 0; i < input.length; i++) {
      if (i < this.maxDefaultOptions) {
        this.defaultOptions.push(input[i]);
      } else {
        this.seeMoreOptions.push(input[i]);
      }
    }
  }

  private setupRangeSlider () {
    this.rangeOptions = {
      floor: this.rangeFloor,
      ceil: this.rangeCeiling,
    };
    this.sliderLowVal = this.rangeFloor;
    this.sliderHighVal = this.rangeCeiling;
  }

  public setChosenForRadio (value: string): void {
    for (let i = 0; i < this.options.length; i++) {
      const currentOption = this.options[i];
      if (currentOption.value === value) {
        currentOption.chosen = true;
      } else {
        currentOption.chosen = false;
      }
    }
  }

  public setChosenForCheckbox (value: string): void {
    let chosenCheckboxes = 0;
    for (let i = 0; i < this.options.length; i++) {
      const currentOption = this.options[i];
      if (currentOption.value === value) {
        currentOption.chosen = !currentOption.chosen;
      }
      if (currentOption.chosen && currentOption.value !== 'All') {
        chosenCheckboxes++;
      }
    }
    if (chosenCheckboxes === this.totalOptions) {
      this.allChosen = true;
    } else {
      this.allChosen = false;
    }
  }

  public setAll (checked: boolean) {
    this.allChosen = checked;
    if (this.allChosen) {
      this.options.forEach(option => (option.chosen = true));
    } else {
      this.options.forEach(option => (option.chosen = false));
    }
    this.emitOptionChosen({ value: 'All' });
  }

  public emitOptionChosen (event) {
    switch (this.optionType) {
      case 'radio':
        this.setChosenForRadio(event.value);
        this.optionChosen.emit({ event, optionType: this.optionType, options: this.options, optionId: this.optionId });
        this.setCurrentCountText(event);
        break;
      case 'checkbox':
        if (event.value !== 'All') {
          this.setChosenForCheckbox(event.value);
        }
        this.optionChosen.emit({ event, optionType: this.optionType, options: this.options, optionId: this.optionId });
        this.setCurrentCountText(event);
        break;
      case 'range': {
        this.optionChosen.emit({
          value: event.$event.value,
          highValue: event.$event.highValue,
          optionType: this.optionType,
          optionId: this.optionId,
        });
        break;
      }
      default:
        this.optionChosen.emit({ event });
        break;
    }
  }

  public getCurrentCountText () {
    return this.currentCountText;
  }

  public setCurrentCountText (event) {
    if (this.optionType === 'radio') {
      if (event.value === 'All') {
        this.currentCountText = `${this.totalOptions} of ${this.totalOptions}`;
      } else {
        this.currentCountText = `1 of ${this.totalOptions}`;
      }
    } else if (this.optionType === 'checkbox') {
      if (event.value === 'All') {
        if (this.allChosen === true) {
          this.currentCountText = `${this.totalOptions} of ${this.totalOptions}`;
        } else {
          this.currentCountText = `0 of ${this.totalOptions}`;
        }
      } else {
        const currentCount = this.options.filter(option => {
          if (option.value !== 'All') {
            return option.chosen === true;
          } else {
            return false;
          }
        }).length;
        this.currentCountText = `${currentCount} of ${this.totalOptions}`;
      }
    }
  }

  public selectCurrentlySelected () {
    for (const [key, value] of Object.entries(this.currentlySelected)) {
      if (key === this.optionId) {
        if (value !== null) {
          const currentlyChosen: any = value;
          const allSelected = value.length === this.totalOptions;
          if (this.optionType === 'radio') {
            if (currentlyChosen === 'Your Schools') {
              this.setChosenForRadio('All');
            } else {
              this.setChosenForRadio(currentlyChosen);
            }
          } else if (this.optionType === 'checkbox') {
            if (allSelected) {
              this.allChosen = true;
            }
            this.options.forEach(option => {
              const currentId = option.value;
              if (currentlyChosen.some(option => option === currentId)) {
                option.chosen = true;
              } else {
                option.chosen = false;
              }
            });
          } else if (this.optionType === 'range') {
            this.sliderLowVal = Number(this.options[0].value);
            this.sliderHighVal = Number(this.options[1].value);
          }
        } else {
          if (this.optionType === 'radio') {
            this.setChosenForRadio('All');
          }
        }
      }
    }
    this.setCurrentCountText({});
  }

  public onClearInput (): void {
    this.searchInput.setValue('');
  }

  public onSelectOption (option: IMultiSelectionOption): void {
    const { value } = option;
    this.searchInput.setValue(value);
  }

  public getFloor () {
    return this.sliderLowVal;
  }

  public getCeiling () {
    return this.sliderHighVal;
  }
}
