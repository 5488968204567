import { each, includes, pull, flatten, uniq, map, isArray } from 'lodash';
import { IMethodDependency } from './im-student.service';
import { STUDENT_IREADY_CURR_SCHOOL_YEAR } from '../../constants/current-school-year.constant';
import { Injectable } from '@angular/core';
import { IStudent } from '../../../shared/typings/interfaces/student.interface';
import { UtilitiesService } from '../utilities/utilities.service';
import { IStudentiReady } from '../../typings/interfaces/iready.interface';
import { ONE_BELOW, ONE_BELOW_SHORT, TWO_BELOW, TWO_BELOW_SHORT, THREE_PLUS_BELOW, THREE_PLUS_BELOW_SHORT, EARLY_ON, EARLY_ON_SHORT, MID_OR_ABOVE, MID_OR_ABOVE_SHORT } from '../../constants/iready.constant';

/**
 * `@depends` decorator
 *
 * Use this decorator to state that an ImStudent method depends on certain paths, joins or other methods:
 *    @depends({ paths: ['studentDetails.name'], joins: ['courseDiffs'], methods: ['_getSomething']})
 *    static fullName(student) {  }
 *
 * @param depends Object an object with three optional keys: `paths`, `joins` and `methods`
 * @return {dependencyDecorator}
 */
function depends (depends: IMethodDependency) {
  return function dependencyDecorator (target: any, key: string, descriptor: PropertyDescriptor) {
    descriptor.value.depends = depends;
    return descriptor;
  };
}

@Injectable()
export class ImStudentIReady {
  constructor (
    private utilitiesService: UtilitiesService,
  ) { }

  /**
   * Removes fields from `student` that are not included in the dependent paths or joins
   *
   * @param method
   * @returns {Student} masked student
   */
  maskedStudentForMethod (student: IStudent, method) {
    const paths = this.pathsFor(method);
    const joins = this.joinsFor(method);
    each(joins, join => {
      paths.push(`join_${join}`);
    });
    return this.utilitiesService.generatePatch(student, paths);
  }

  _dependentMethods (method) {
    const resolvedMethods = [];
    const unresolvedMethods = [];

    // See here for the dependency resolution algorithm used:
    // https://www.electricmonk.nl/log/2008/08/07/dependency-resolving-algorithm/
    const resolveMethods = (method, resolvedMethods, unresolvedMethods) => {
      unresolvedMethods.push(method);
      const methods = (this[method].depends || {}).methods || [];
      each(methods, m => {
        if (!includes(resolvedMethods, m)) {
          if (includes(unresolvedMethods, m)) {
            throw new Error(`Circular method dependency detected: ${method} -> ${m}`);
          }
          resolveMethods(m, resolvedMethods, unresolvedMethods);
        }
      });
      resolvedMethods.push(method);
      pull(unresolvedMethods, method);
    };

    resolveMethods(method, resolvedMethods, unresolvedMethods);
    return resolvedMethods;
  }

  /**
   * Traverses the method dependency graph and returns all of the joins that `method` depends on
   *
   * @param method a ImStudent method
   * @return {Array} and array of joins
   */
  joinsFor (method) {
    if (!this[method]) throw new Error(`method '${method}' does not exist`);

    if (!(this[method].depends || {}).methods) return (this[method].depends || {}).joins;
    const dependentMethods = this._dependentMethods(method);
    const dependentJoins = uniq(
      flatten(
        map(dependentMethods, m => {
          const depends = this[m].depends;
          if (!depends) {
            console.warn(`\`${m}\` was detected as a dependent method, but it didn't register any dependencies itself`);
            return [];
          }
          return depends.joins || [];
        }),
      ),
    );
    return dependentJoins;
  }

  pathsFor (method) {
    if (!this[method]) throw new Error(`method '${method}' does not exist`);

    if (!(this[method].depends || {}).methods) {
      const paths = (this[method].depends || {}).paths;
      if (!isArray(paths)) {
        console.warn(
          `this.pathsFor('${method}') was called, but ${method} didn't declare any dependent paths. ` +
          `If ${method} doesn't depend on any paths, annotate it with \`@depends({ paths: [] })\``,
        );
      }
      return paths;
    }
    const dependentMethods = this._dependentMethods(method);
    const dependentPaths = uniq(
      flatten(
        map(dependentMethods, m => {
          const depends = this[m].depends;
          if (!depends) {
            console.warn(`\`${m}\` was detected as a dependent method, but it didn't register any dependencies itself`);
            return [];
          }
          return depends.paths || [];
        }),
      ),
    );
    return dependentPaths;
  }

  @depends({
    paths: ['iReady.history'],
    methods: [],
  })
  findIReadyBaselineInTerm (
    doc: IStudentiReady,
    subject: string,
  ) {
    const assessment = doc.iReady.history.find(({ general }) => (
      general.subject === subject &&
      general.year === STUDENT_IREADY_CURR_SCHOOL_YEAR &&
      general.isMostRecentBaselineScore));
    return assessment;
  }

  @depends({
    paths: ['iReady.history'],
    methods: [],
  })
  findIReadyMostRecentInTerm (
    doc: IStudentiReady,
    subject: string,
    term: string,
  ) {
    const assessment = doc.iReady.history.find(({ general }) => (general.termName === term &&
      general.subject === subject &&
      general.year === STUDENT_IREADY_CURR_SCHOOL_YEAR &&
      general.isMostRecentScoreInTerm));
    return assessment;
  }

  @depends({
    paths: ['iReady.history'],
    methods: ['findIReadyBaselineInTerm'],
  })
  getIReadyBaselineData (
    doc: IStudentiReady,
    subject: string,
    desiredField: string,
  ) {
    const assessment = this.findIReadyBaselineInTerm(doc, subject);
    return assessment?.general[desiredField];
  };

  @depends({
    paths: ['iReady.history'],
    methods: [],
  })
  getIReadyGeneralData (
    doc: IStudentiReady,
    subject: string,
    term: string,
    desiredField: string,
  ) {
    const assessment = this.findIReadyMostRecentInTerm(doc, subject, term);
    return assessment?.general[desiredField];
  };

  @depends({
    paths: ['iReady.history'],
    methods: [],
  })
  getIReadyLexileMaxDataInTerm (
    doc: IStudentiReady,
    term: string,
    desiredField: string,
  ) {
    const assessment = this.findIReadyLexileMaxInTerm(doc, term);
    return assessment?.general[desiredField];
  };

  @depends({
    paths: ['iReady.history'],
    methods: [],
  })

  findIReadyLexileMaxInTerm (
    doc: IStudentiReady,
    term: string,
  ) {
    const assessment = doc.iReady.history.find(({ general }) => (
      general.termName === term &&
      general.subject === 'ELA' &&
      general.year === STUDENT_IREADY_CURR_SCHOOL_YEAR &&
      general.isHighestLexileScoreInItsYearAndTerm));
    return assessment;
  }

  // helper functions for formatting values during additionalCalculations
  static shortenRelLvl (relLvl: string): string {
    let shortenedRelLvl;

    switch (relLvl) {
      case ONE_BELOW:
        shortenedRelLvl = ONE_BELOW_SHORT;
        break;
      case TWO_BELOW:
        shortenedRelLvl = TWO_BELOW_SHORT;
        break;
      case THREE_PLUS_BELOW:
        shortenedRelLvl = THREE_PLUS_BELOW_SHORT;
        break;
      case EARLY_ON:
        shortenedRelLvl = EARLY_ON_SHORT;
        break;
      case MID_OR_ABOVE:
        shortenedRelLvl = MID_OR_ABOVE_SHORT;
        break;
      default:
        shortenedRelLvl = null;
    }

    return shortenedRelLvl;
  };

  static formatIreadyGrowthData (growthData: number | string): string {
    const formatNumber = num => `${Number((num * 100).toFixed(2))}%`;
    return typeof growthData === 'number' ? formatNumber(growthData) : growthData;
  };
};
