import { createSelector } from '@ngrx/store';
import { createFeatureSelector } from '@ngrx/store';

const getPortalState = createFeatureSelector('PORTAL_STORE');
export const getStudentState = createSelector(
  getPortalState,
  (state: any) => state.student,
);
export const getStudentLoadedStatus = createSelector(
  getStudentState,
  (state: any) => {
    return state.studentEntities;
  },
);
export const getStudentLoadingStatus = createSelector(
  getStudentState,
  (state: any) => state.loading,
);
export const getStudent = createSelector(
  getStudentState,
  (state: any) => {
    return state.studentEntities;
  },
);

export const getCurrentStudent = (studentId: string) => createSelector(
  getStudentState,
  (state: any) => {
    return state.studentEntities[studentId];
  },
);

export const getStudentList = (arrayOfIds: string[]) => createSelector(
  getStudentState,
  (state: any) => arrayOfIds.map(id => state.studentEntities[id]),
);
