export interface ITooltipDataObject {
  [key: string]: any;
}
export interface ITooltipContent {
  template?: string;
  headers?: string[] | string[][];
  rowData: string[][] | ITooltipDataObject[][];
  typeOfTable?: string; // optional, in case we need to know the type of table
  options?: {
    preserveTwoColumnLayout?: boolean
  }
}
export interface ITooltipData {
  type?: 'sync' | 'async';
  calc?: string;
  content?: ITooltipContent | any;
}

export type TTooltipElType = 'PANEL_FIELD' | 'FIXED_OR_INFINITE_LIST_CELL';

export interface ICoreNvTooltipData {
  connectedElType?: TTooltipElType;
}

export interface IStaticNvTooltipData extends ICoreNvTooltipData {
  simpleContent?: string;
  tableContent?: ITooltipContent;
}

export type TTooltipData = string | ITooltipDataObject;

export function isTooltipContent (ttTest) {
  if (typeof ttTest !== 'object') return false;
  const hasRowData = Object.keys(ttTest).includes('rowData') && Array.isArray(ttTest.rowData);
  const rowsAreArrays = ttTest.rowData.every(row => Array.isArray(row));
  return hasRowData && rowsAreArrays;
}
