
import { NvButtonModule } from 'projects/shared/nvps-libraries/design/nv-button/nv-button.module';
import { NoRowsComponent } from './no-rows.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  exports: [NoRowsComponent],
  declarations: [NoRowsComponent],
  imports: [
    CommonModule,
    NvButtonModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class NoRowsComponentModule { }
