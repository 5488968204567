import * as careerPathAction from '../actions/career-path-actions';

export interface ICareerPathState {
  loading: boolean;
  loaded: boolean;
  careerPathEntities: object;
}

export const initialState: ICareerPathState = {
  loading: false,
  loaded: false,
  careerPathEntities: {},
};

export function reducer(
  state: ICareerPathState = initialState,
  action: careerPathAction.CareerPathAction,
): ICareerPathState {
  switch (action.type) {
    case careerPathAction.LOAD_CAREER_PATH:
      return {
        ...state,
        loading: true,
      };

    case careerPathAction.LOAD_CAREER_PATH_SUCCESS: {
      const careerPathEntities = action.payload.reduce((accum, path) => {
        return { ...accum, [path._id]: path };
      }, {});

      return {
        ...state,
        loaded: true,
        loading: false,
        careerPathEntities,
      };
    }

    case careerPathAction.LOAD_CAREER_PATH_FAIL:
      return { ...state, loaded: false, loading: false };

    case careerPathAction.CREATE_CAREER_PATH:
      return {
        ...state,
        loading: true,
      };

    case careerPathAction.CREATE_CAREER_PATH_SUCCESS: {
      const path = action.payload;
      const careerPathEntities = { ...state.careerPathEntities, [path._id]: path };

      return {
        ...state,
        loaded: true,
        loading: false,
        careerPathEntities,
      };
    }

    case careerPathAction.CREATE_CAREER_PATH_FAIL:
      return { ...state, loaded: false, loading: false };

    default:
      return state;
  }
}
