import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';

/**
 *
 * A basic display component used for presenting data.
 *
 * The Pill on its own is not clickable—use the Pill Button for interactivity.
 *
 */
@Component({
  selector: 'nv-pill',
  templateUrl: 'nv-pill.component.html',
  styleUrls: ['nv-pill.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NvPillComponent implements OnChanges, OnInit {
  /**
   *
   * Recommended way of setting the text. Projection will work, but styling may be inconsistent
   */
  @Input() text: string;

  /**
   *
   * The icon to display on the right
   * Does not display if `isSmall` is set to `true`
   */
  @Input() icon: string;

  /**
   *
   * Where the icon is displayed.
   * Ignored if `icon` is unset.
   */
  @Input() iconPosition: 'left' | 'right' = 'right';

  /**
   *
   * The base color of the pill
   */
  @Input() color: string = 'blue';

  /**
   *
   * Sets the text to `color`, and the background to a light version of `color`
   */
  @Input() isInverted: boolean = false;

  /**
   *
   * Makes the pill smaller. Ignores any icon
   */
  @Input() isSmall: boolean = false;

  /**
   *
   *
   */
  @Input() isDisabled: boolean = false;

  @Input() textIsPlaceholder: boolean = false;

  @Input() ariaLabelData?: {
    iconAriaLabel?: string;
  } = {
      iconAriaLabel: null,
    };

  @ViewChild('textElement', { static: false }) textElement: ElementRef;

  pillClass: string = '';
  hasText: boolean = false;

  get hasIcon (): boolean {
    return this.icon !== undefined && this.icon.length > 0 && !(this.isSmall === true);
  }

  ngOnInit (): void {
    this.hasText = !!this.text;
    this.setPillClass();
  }

  ngOnChanges (changes: SimpleChanges): void {
    if (changes.text) {
      this.hasText = !!this.text;
      this.setPillClass();
    } else {
      this.setPillClass();
    }
  }

  setPillClass () {
    const tmpClass: string[] = [];
    tmpClass.push(this.color.toLowerCase());
    if (this.isInverted) tmpClass.push('invert');
    if (this.isSmall) tmpClass.push('small');
    if (this.isDisabled) tmpClass.push('disabled');
    if (this.textIsPlaceholder) tmpClass.push('placeholder');
    if (this.hasIcon) {
      tmpClass.push('has-icon');
      if (this.hasText) {
        tmpClass.push(`icon-${this.iconPosition}`);
      }
    }
    if (!this.hasText) tmpClass.push('no-text');
    this.pillClass = tmpClass.join(' ');
  }
}
