import { RollbarService } from './../rollbar/rollbar.service';
import { HttpClient } from '@angular/common/http';
import { Injectable, Injector, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { getToggleEntities } from './../../../store/selectors/toggles-selectors';
import { PortalConfig } from './../portal-config';
import * as Rollbar from 'rollbar';
import { take, tap, filter } from 'rxjs/operators';
import { Auth } from '../../auth/auth.service';

@Injectable()
export class ToggleService {
  private publicConfig;
  public API_URL;
  public togglesFromStore: { [key: string]: boolean };

  constructor (
    private http: HttpClient,
    private portalConfig: PortalConfig,
    private store: Store<any>,
    public injector: Injector,
    @Inject(RollbarService) private rollbar: Rollbar,
    private authService: Auth,
  ) {
    this.publicConfig = portalConfig.publicConfig;
    this.API_URL = this.publicConfig.NV_API_ORIGIN + '/v1/toggle/';
  }

  getTogglesFromStore () {
    const toggles$ = this.store.select(getToggleEntities);
    toggles$.pipe(
      filter(data => !_.isEmpty(data)),
      tap(data => (this.togglesFromStore = data)),
      take(1)
    )
    .subscribe();
  }

  setTogglesFromStore (toggleEntities: { [key: string]: boolean }) {
    this.togglesFromStore = toggleEntities;
  }

  getToggleState (toggleName: string) {
    this.getTogglesFromStore();
    const toggleState = this.togglesFromStore[toggleName];

    // Can't inject rollbar in the constructor since it belongs to the old app and won't be available yet.
    // If it's a component, it can be done in OnInit

    if (!toggleState) {
      // false or undefined
      if (_.isUndefined(toggleState) && this.authService.isAuthenticated()) {
        const errMsg = `Can\'t get the state for toggle ${toggleName}`;
        this.rollbar.error(new Error(errMsg));
      }
      return false;
    } else if (toggleState !== true) {
      // not a boolean; possibly an error object
      const errMsg = `Possible error in toggle state for toggle ${toggleName}. State: ${toggleState}`;
      this.rollbar.error(new Error(errMsg));
      return false;
    } else if (toggleState === true) {
      return true;
    } else {
      return false;
    }
  }

  getAllToggles () {
    const endPoint = this.API_URL;
    return this.http.get(endPoint);
  }

  getAllComputedToggles (configOptions) {
    const endPoint = this.API_URL + 'computed/' + JSON.stringify(configOptions);
    return this.http.get(endPoint);
  }
}
