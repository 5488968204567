import { IExplanationCard, IActionItem } from './mock-regents-splash.component';

export const mockRegentsIsActive = true;

export const mockRegentsSplashMessages = {
  welcome: 'Welcome',
  announcementTitle: 'Boost student readiness for January exams!',
  announcementParagraph: 'Administering Mock Regents will help you assess student readiness, improve test performance, and keep students on track for graduation.',
  announcementButton: 'Sign Up For Training Session',
  announcementGuideLink: 'Learn more',
  explanationTitle: "What's included in mock Regents results",
  actionTitle: 'Get started now!',
};

export const mockRegentsClosedSplashMessages = {
  announcementTitle: 'Mock Regents closed for Spring 2024',
  announcementParagraph: 'We are no longer accepting answer sheets for the Spring 2024 administration. Check back for details about our next mock administration.',
  announcementButton: 'Learn More',
  explanationTitle: "What's included in mock Regents results",
};

export const links = {
  trainingSessionLink: 'https://events.newvisions.org/portalhubpage',
  howToLink: 'https://docs.google.com/document/d/1XtHp8wAWSabs-zcS9iz2ZDEDS45BSOo2oEFqjm_wXHs/edit?usp=sharing',
  learnMoreLink: 'https://intercom.help/portal-by-new-visions/en/articles/9424799-prepare-students-for-exams-with-the-portal-s-mock-regents-feature',
};

export const explanationCards = [
  {
    icon: 'standard-default',
    title: 'Area/Standard Analysis',
    detail: 'Identify the areas/standards that presented challenges for students, and which students struggled the most.',
  } as IExplanationCard,
  {
    icon: 'student-default',
    title: 'Student Results',
    detail: 'View results for each student, along with key data like exam status, course details, grades, attendance, and more.',
  } as IExplanationCard,
  {
    icon: 'question-default',
    title: 'Question Analysis',
    detail: 'Assess which multiple choice questions were challenging, and which answers were most distracting.',
  } as IExplanationCard,
];

export const actionItems = [
  {
    step: 'Step 1:',
    title: 'Select from the exams offered then download and print',
    description: 'Download and print exam booklets and answer sheets for the exams you chose. You must use New Visions provided answer sheets.',
    subTitle: 'Exams offered this Fall:',
    subDescription: 'ELA (June 2023), Algebra I (August 2022), Living Environment (August 2022), US History (June 2023)',
    hasButton: true,
    buttonText: 'View exam resources',
    buttonDisabled: false,
    link: 'https://drive.google.com/drive/folders/1ZiuweIrKww71lXB4O-umwiSKvMqgeE5F',
    icon: 'download-default',
    hasPill: false,
  } as IActionItem,
  {
    step: 'Step 2:',
    title: 'Administer and score',
    description: 'Administer the mock Regents exams to students then score the constructed response and essay questions and bubble in points. Please verify student OSIS numbers are bubbled in correctly on each answer sheet.',
    hasButton: true,
    buttonText: 'View scoring materials',
    buttonDisabled: false,
    link: 'https://drive.google.com/drive/folders/1ZiuweIrKww71lXB4O-umwiSKvMqgeE5F',
    icon: 'administer-default',
    hasPill: false,
  } as IActionItem,
  {
    step: 'Step 3:',
    title: 'Scan to PDF',
    description: 'Using a printer in your school scan answer sheets into a single PDF file for each exam. Most printers have a scan to PDF setting.',
    hasButton: false,
    buttonDisabled: true,
    icon: 'scan-default',
    hasPill: false,
  } as IActionItem,
  {
    step: 'Step 4:',
    title: 'Submit PDF files',
    description: 'Submit PDF files for each exam to New Visions using the Google Form linked below. A New Visions staff member will reach out to you when your results are available.',
    hasButton: true,
    buttonText: 'Submit data',
    buttonDisabled: false,
    link: 'https://docs.google.com/forms/d/e/1FAIpQLSf66PMFJ1RegYRv62GBrlDayDGU-X21Bb7ThsCuuvFEBcZcSw/viewform',
    icon: 'upload-default',
    hasPill: false,
    pillText: 'Now Available',
  } as IActionItem,
];

export const pageBannerMeta = {
  titleOrMessage: 'Mock Regents',
};
