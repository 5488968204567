import { Injectable } from '@angular/core';
import { HideSideNavAction, ShowSideNavAction } from './../../../shell/side-nav/side-nav.actions';
import { EventBus } from './../event-bus/event-bus';

@Injectable()
export class SideNavService {
  constructor(private eventBus: EventBus) {
    //
  }

  public hideSideNav() {
    this.eventBus.emitAction(new HideSideNavAction(null));
  }

  public showSideNav() {
    this.eventBus.emitAction(new ShowSideNavAction(null));
  }
}
