import { ModalsService } from 'Src/ng2/shared/modals/modals.service';
import { StudentSupportsPanelListKebabComponent } from './student-supports-panel-list-kebab/student-supports-panel-list-kebab.component';
import { ListHeaderService } from './../../../school/lists/services/list-headers/list-headers.service';
import { ListNavigationService } from './../../../school/lists/services/list-navigation/list-navigation.service';
import { MadlibService } from './../../../school/lists/services/madlib/madlib.service';
import { BatchActionsService } from './../../../school/lists/services/batch-actions/batch-actions.service';
import { StudentSupportsPanelListDataService } from './student-supports-panel-list-data/student-supports-panel-list-data.service';
import { StudentSupportsPanelListContainer } from './student-supports-panel-list.component';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { NvDesignLibraryModule } from 'Src/nvps-libraries/design/nv-design-library.module';
import { PageHeaderModule } from '../../../shared/components/header/page-header/page-header.module';
import { FixedTableModule } from '../../../shared/components/list/fixed-table/fixed-table.module';
import { FixedToInfiniteViewModule } from '../../../shared/components/list/fixed-to-infinite-view-container/fixed-to-infinite-view.module';
import { ListContainerModule } from '../../../shared/components/list/list-container/list-container.module';
import { SharedServicesModule } from '../../../shared/services/shared.services.module';
import { WildcardService } from 'Src/ng2/shared/services/list-services/wildcard.service';

@NgModule({
  declarations: [StudentSupportsPanelListContainer, StudentSupportsPanelListKebabComponent],
  imports: [
    CommonModule,
    ListContainerModule,
    FixedToInfiniteViewModule,
    FixedTableModule,
    PageHeaderModule,
    SharedServicesModule,
    NvDesignLibraryModule,
    MatMenuModule,
  ],
  exports: [StudentSupportsPanelListContainer],
  providers: [
    StudentSupportsPanelListContainer,
    StudentSupportsPanelListDataService,
    BatchActionsService,
    MadlibService,
    WildcardService,
    ListNavigationService,
    ListHeaderService,
    ModalsService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class StudentSupportsPanelListModule {}
