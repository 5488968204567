import { IExperience } from './../../../../typings/interfaces/experiences.interface';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseModalComponent } from '../../../base-modal.component';
import { ExperienceModalService } from '../experience-modal-service/experience-modal-service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IModalConfig } from '../experience-form/experience-form.component';
import { EM_DASH } from 'Src/ng2/shared/constants/em-dash.constant';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'experience-details-modal',
  templateUrl: './experience-details-modal.component.html',
  styleUrls: ['./experience-details-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExperienceDetailsModalComponent extends BaseModalComponent implements OnInit {
  public title: string = 'Experience details';
  public itemType: string = 'student';
  public isProfileMode?: boolean;

  // template data
  public experience: IExperience;
  public experienceConfig$: Observable<IModalConfig>;
  public dateRangeForm: FormGroup;

  public EM_DASH = EM_DASH;

  constructor (
    dialogRef: MatDialogRef<ExperienceDetailsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private experienceModalService: ExperienceModalService,
  ) {
    super(dialogRef);
  }

  ngOnInit (): void {
    const { experience, isProfileMode } = this.data;
    this.isProfileMode = isProfileMode;
    this.experience = experience;
    const { milestone } = this.experience;
    /**
     * Get configs of the experience type based on the milestone key
     * to determine the formatting of the ready-only data
     */

    this.experienceConfig$ = this.experienceModalService.getExperienceModalConfig$('experiencesModal', milestone).pipe(
      map(payload => {
        const [config] = payload;
        return config;
      }),
    );

    this.dateRangeForm = this.getDateFormGroup();
  }

  private getDateFormGroup () {
    return new FormGroup({
      startDate: new FormControl(this.experience?.startDate || '', Validators.required),
      endDate: new FormControl(this.experience?.endDate || '', Validators.required),
    });
  }
}
