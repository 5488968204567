import { Injectable } from '@angular/core';

@Injectable()
export class GraphQLProgramChangesHelperService {
  private getColumnKeys (projection: string[]): string[] {
    return projection.map(key => {
      return `"${key}"`;
    });
  }

  public getProgramChangesQuery ({ schoolId, fociOption, groupingOption, projection }): string {
    const columnKeys = this.getColumnKeys(projection);
    const query = `{
        ProgramChangesGrouping(
          schoolId: "${schoolId}",
          fociKey: "${fociOption}",
          groupingKey: "${groupingOption}",
          columns: [${columnKeys}]
        ) {
            groupData {
              key
              human
              tooltip
              rowData {
                data
                meta
                columnKey
              }
            }
            fociOptions{
              human
              key
            }
          }
      }`;
    return query;
  }
}
