/* tslint:disable */
import { Action } from '@ngrx/store';
export const SET_MOCK_REGENTS_GRID_STATE = '[Portal] Set Mock Regents Grid State';

export class SetMockRegentsGridState implements Action {
  readonly type = SET_MOCK_REGENTS_GRID_STATE;
  constructor (public payload: any) {}
}

export type MockRegentsGridAction = SetMockRegentsGridState;
