import { DocMouseMoveService } from '../../../services/document-mousemove-service/document-mousemove.service';
import {
  ConnectedPosition,
  FlexibleConnectedPositionStrategy,
  Overlay,
  OverlayConfig,
  OverlayPositionBuilder,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Directive, ElementRef, HostListener, Inject, InjectionToken, Injector, Input, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { debounceTime, filter, switchMap, take, tap } from 'rxjs/operators';
import { NvTooltipComponent } from './nv-tooltip.component';
import { ITooltipData, ITooltipContent, TTooltipData } from './nv-shared-tooltip.interface';

export interface ITooltipService {
  getFormattedRowData (rowData: ITooltipContent['rowData']): ITooltipContent['rowData'];
  getTooltipContent (tooltipData: TTooltipData): Observable<any>;
}

export const TOOLTIP_DATA = new InjectionToken<{}>('TOOLTIP_DATA');
export const TOOLTIP_SERVICE_TOKEN = new InjectionToken<ITooltipService>('ITooltipService');

@Directive({ selector: '[nvTooltip]' })
export class NvTooltipDirective implements OnDestroy {
  @Input() tooltipData: TTooltipData;

  private overlayRef: OverlayRef;
  private isTooltipActive$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private onMouseout$: Observable<any>;
  private onMouseover$: Observable<any>;

  constructor (
    private el: ElementRef,
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private injector: Injector,
    private documentMousemove: DocMouseMoveService,
    @Inject(TOOLTIP_SERVICE_TOKEN) private tooltipDataService: ITooltipService,
  ) { }

  @HostListener('mouseenter')
  onMouseEnter () {
    const isMouseInElement$ = this.documentMousemove.event$.pipe(
      switchMap(evt => this.isEventInElement(evt, this.el.nativeElement)),
    );

    this.onMouseout$ = this.createMouseOutStream(isMouseInElement$);
    this.onMouseover$ = this.createMouseOverStream(isMouseInElement$);
    combineLatest([this.onMouseout$, this.onMouseover$])
      .pipe(take(1))
      .subscribe();
  }

  ngOnInit () {
    this.overlayRef = this.createOverlay(this.overlay);
  }

  ngOnDestroy () {
    if (this.overlayRef) {
      this.overlayRef.detach();
      this.overlayRef.dispose();
    }
  }

  private createMouseOutStream (isMouseInElement$: Observable<boolean>): Observable<any> {
    return isMouseInElement$.pipe(
      filter(isHovered => !isHovered && this.isTooltipActive$.value),
      tap(() => {
        this.overlayRef.detach();
        this.isTooltipActive$.next(false);
      }),
    );
  }

  private createMouseOverStream (isMouseInElement$: Observable<boolean>): Observable<any> {
    return isMouseInElement$.pipe(
      debounceTime(50),
      filter(isHovered => isHovered && !this.isTooltipActive$.value),
      switchMap(() => this.tooltipDataService.getTooltipContent(this.tooltipData)),
      tap((tooltipData: any) => {
        const content = tooltipData.data.Tooltip ? tooltipData.data.Tooltip.content : {};

        // is simple or table
        const { tableContent, simpleContent } = content || {};

        // Format rowdata for table content
        if (tableContent) {
          tableContent.rowData = this.tooltipDataService.getFormattedRowData(tableContent.rowData);
        }

        const inputs: ITooltipData = { content: tableContent || simpleContent };
        this.isTooltipActive$.next(true);
        if (!this.overlayRef.hasAttached() && this.shouldDisplayTooltip(tableContent, simpleContent)) { this.attachTooltipOverlay(inputs); }
      }),
    );
  }

  public attachTooltipOverlay (inputs: ITooltipData) {
    const tooltipPosition = this.getTooltipPosition(this.el);
    const newPositionStrategy: FlexibleConnectedPositionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.el)
      .withPositions([tooltipPosition])
      .withViewportMargin(20);
    this.overlayRef.updatePositionStrategy(newPositionStrategy);
    this.overlayRef.attach(new ComponentPortal(NvTooltipComponent, null, this.createInjector(inputs)));
  }

  private getTooltipPosition (element: ElementRef<any>): ConnectedPosition {
    const isTooCloseToBottom = this.isCloseToBottom(element);
    if (isTooCloseToBottom) {
      return {
        originX: 'center',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'bottom',
      } as ConnectedPosition;
    }
    return {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'top',
    } as ConnectedPosition;
  }

  private isCloseToBottom (element) {
    let isTooCloseToBottom;
    const elementRect = element.nativeElement.getBoundingClientRect();
    const screenHeight = window.innerHeight;
    // tooltip used to set threshold was about 80px high, plus 20px for withViewPortMargin(20) = 100. If issue continues due to larger tooltip, increase threshold in following line
    if ((screenHeight - (elementRect.bottom)) < 100) isTooCloseToBottom = true;
    else isTooCloseToBottom = false;
    return isTooCloseToBottom;
  };

  // validate table content to have row data and simple content to some length before displaying(Jack)
  private shouldDisplayTooltip (tableContent, simpleContent): boolean {
    const isValidSimpleContent = this.isValidSimpleContent(simpleContent);
    const isValidTableContent = this.isValidTableContent(tableContent);
    return isValidSimpleContent || isValidTableContent;
  }

  private isValidTableContent (tableContent): boolean {
    return !!(tableContent && tableContent.rowData && tableContent.rowData.length);
  }

  private isValidSimpleContent (simpleContent: String): boolean {
    return !!(simpleContent && simpleContent.length);
  }

  private createInjector (tooltipData: ITooltipData): PortalInjector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(TOOLTIP_DATA, tooltipData);
    return new PortalInjector(this.injector, injectorTokens);
  }

  private createOverlay (overlay: Overlay) {
    const config: OverlayConfig = new OverlayConfig();
    return overlay.create(config);
  }

  private isEventInElement (event, element): Observable<boolean> {
    const rect = element.getBoundingClientRect();
    const x = event.clientX;
    if (x < rect.left || x >= rect.right) return of(false);
    const y = event.clientY;
    if (y < rect.top || y >= rect.bottom) return of(false);
    return of(true);
  }
}
