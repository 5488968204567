import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SchoolTwoColumnLayoutModule } from 'Src/ng2/school/layouts/school-two-column-layout/school-two-column-layout.module';
import { SettingsLeftNavModule } from './../settings-left-nav/settings-left-nav.module';
import { SettingsLayoutComponent } from './settings-layout.component';
@NgModule({
  declarations: [SettingsLayoutComponent],
  imports: [CommonModule, RouterModule, SchoolTwoColumnLayoutModule, SettingsLeftNavModule],
  exports: [SettingsLayoutComponent],
  providers: [],
})
export class SettingsLayoutModule {}
