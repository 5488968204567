<base-modal
  class="base-modal-shell"
  [title]="title"
  [itemType]="itemType"
  [isProfileMode]="isProfileMode"
>
  <ng-container *ngIf="(experienceConfig$ | async) as experienceConfig">
    <div *ngFor="let config of experienceConfig.options" class="detail-section">
      <nv-form-field [label]="config.human" [labelIcon]="labelIcon" [labelIconTooltip]="config.meta.labelIconTooltip">
        <ng-container [ngSwitch]="config.meta.controlType">

          <nv-date-range-picker
            *ngSwitchCase="'date-picker'"
            [dateRangeForm]="dateRangeForm"
            [startLimit]="experience.startDate"
            [endLimit]="experience.endDate"
            isDisabled="true"
          ></nv-date-range-picker>

          <mat-checkbox
            *ngSwitchCase="'checkbox'"
            [formControl]="form"
            [checked]="experience[config.key]"
            class="nv-mat-checkbox"
            name="config.key"
            disabled
          >
            {{ config.meta.placeholder }}
          </mat-checkbox>

          <div *ngSwitchDefault class="detail-text">{{ experience[config.key] || EM_DASH }}</div>
        </ng-container>

      </nv-form-field>
    </div>
  </ng-container>
</base-modal>
