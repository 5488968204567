import { Action } from '@ngrx/store';
import { TBatchActionsOrigin } from 'Src/ng2/shared/components/nv-actions/nv-actions.interface';
import { ICourseDiff } from 'Src/ng2/shared/typings/interfaces/course-diff.interface';

export const CREATE_COURSE_DIFF = '[Portal] Create Course Diff';
export const CREATE_COURSE_DIFF_SUCCESS = '[Portal] Create Course Diff Success';
export const CREATE_COURSE_DIFF_FAILURE = '[Portal] Create Course Diff Failure';
export const BULK_CREATE_COURSE_DIFF = '[Portal] Bulk Create Course Diff';
export const BULK_CREATE_COURSE_DIFF_SUCCESS = '[Portal] Bulk Create Course Diff Success';
export const BULK_CREATE_COURSE_DIFF_FAILURE = '[Portal] Bulk Create Course Diff Failure';

export interface IBulkCreateCourseDiff {
  courseDiff: ICourseDiff;
  studentIds: string[];
  origin?: TBatchActionsOrigin;
}

// register store actions

export class CreateCourseDiff implements Action {
  readonly type = CREATE_COURSE_DIFF;
  constructor (
    public payload: { courseDiff: ICourseDiff; studentId: string; schoolId: string },
  ) { }
}

export class CreateCourseDiffSuccess implements Action {
  readonly type = CREATE_COURSE_DIFF_SUCCESS;
  constructor (public payload: ICourseDiff) { }
}

export class CreateCourseDiffFailure implements Action {
  readonly type = CREATE_COURSE_DIFF_FAILURE;
  constructor (public payload: any) { }
}

export class BulkCreateCourseDiff implements Action {
  readonly type = BULK_CREATE_COURSE_DIFF;
  constructor (public payload: IBulkCreateCourseDiff) { }
}

export class BulkCreateCourseDiffSuccess implements Action {
  readonly type = BULK_CREATE_COURSE_DIFF_SUCCESS;
  constructor (public payload: any) { }
}

export class BulkCreateCourseDiffFail implements Action {
  readonly type = BULK_CREATE_COURSE_DIFF_FAILURE;
  constructor (public payload: any) { }
}

// action types
export type CourseDiffAction =
  | CreateCourseDiff
  | CreateCourseDiffSuccess
  | CreateCourseDiffFailure
  | BulkCreateCourseDiff
  | BulkCreateCourseDiffSuccess
  | BulkCreateCourseDiffFail;
