import { ChangeDetectorRef, Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay, startWith, tap } from 'rxjs/operators';
import { CreditGapsConfigService } from 'Src/app/sdc/sdc-state/sdc-configs/credit-gaps-config.service';
import { SpinnerService } from 'Src/ng2/shared/components/spinner/spinner-modal.service';
import { ImSchool } from 'Src/ng2/shared/services/im-models/im-school';
import { ISchool } from 'Src/ng2/shared/typings/interfaces/school.interface';
import { WizardHelpers } from '../../../services/wizard-helpers.service';
import { InstructionStep } from '../../../shared/components/wizard-steps/instruction-step/instruction-step.component';
import { GradPlanningStep } from './steps/grad-planning/grad-planning-step-component';
import { ReviewGapsStep } from './steps/review-gaps/review-gaps-component';
import { DoFinalCheckStep } from '../non-transfer/steps/do-final-check/do-final-check.component';

@Component({
  selector: 'credit-gaps-transfer-wizard',
  templateUrl: './credit-gaps-transfer-wizard.component.html',
  styleUrls: ['./credit-gaps-transfer-wizard.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CreditGapsTransferWizard {
  public school$: Observable<ISchool>
  public flattenedStudents$: Observable<any>;
  public schoolSupports$: Observable<any>;
  public studentSupports$: Observable<any>;
  public classOfDropdownOptions: Array<{ key: string, human: string }>
  public currentUser$: Observable<any>;
  public dropdownSelection: FormControl;
  public spinner;
  private schoolId: string;
  public selectedStepIndex: number;
  public creditGapsService // credit-gaps-config.service
  public instructionDescription: string = 'The purpose of this wizard is to ensure that all students with credit gaps have plans to address their gaps this term and/or in a future term.';
  public isLinear: boolean = false;

  constructor (
    private cd: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private imSchool: ImSchool,
    private spinnerService: SpinnerService,
    private wizardHelpers: WizardHelpers,
  ) { }

  @ViewChild(MatStepper) stepper: MatStepper;
  @ViewChild(InstructionStep) instructionStep: InstructionStep;
  @ViewChild(GradPlanningStep) gradPlanningStep: GradPlanningStep;
  @ViewChild(ReviewGapsStep) reviewGapsStep: ReviewGapsStep;
  @ViewChild(DoFinalCheckStep) doFinalCheckStep: DoFinalCheckStep;

  ngOnInit () {
    const config = new CreditGapsConfigService(this.imSchool, null);
    this.selectedStepIndex = this.activatedRoute.snapshot.queryParams.activeWizardStep || 0;
    this.spinner = this.spinnerService.openSpinner({ message: 'Loading...' });
    this.classOfDropdownOptions = this.wizardHelpers.generateTransferDropdown(this.imSchool.getNextFourGradDatesForTransfer());
    this.dropdownSelection = new FormControl(this.activatedRoute.snapshot.data?.filter?.dropdownSelection || 'All');
    this.schoolId = this.activatedRoute.snapshot.params.schoolId;
    this.school$ = this.wizardHelpers.getSchool$();
    this.schoolSupports$ = this.wizardHelpers.getSchoolSupports$(this.schoolId);
    this.studentSupports$ = this.wizardHelpers.getStudentSupports$(this.schoolId);
    this.currentUser$ = this.wizardHelpers.getUser$();
    this.flattenedStudents$ = combineLatest([
      this.wizardHelpers.getSdcStudents(config),
      this.school$,
      this.dropdownSelection.valueChanges.pipe(startWith(this.dropdownSelection.value)),
    ]).pipe(
      tap(([_, __, dropdownSelection]) => this.wizardHelpers.updateUrl(dropdownSelection, this.selectedStepIndex)),
      map(([students, school, dropdownSelection]) => {
        return this.wizardHelpers.getFilteredStudents({
          students,
          filters: this.wizardHelpers.getFilters(this.wizardHelpers.getGradPlanFilter(dropdownSelection, this.classOfDropdownOptions), { status: { values: ['A'] } }),
        });
      }),
      tap(() => this.spinner.close()),
      shareReplay(1),
    );
  }

  ngAfterViewInit () {
    this.stepper._getIndicatorType = () => 'number';
  }

  ngAfterContentChecked () {
    this.cd.detectChanges();
  }

  public onStepChange ({ selectedIndex }) {
    this.selectedStepIndex = selectedIndex;
    this.wizardHelpers.updateUrl(this.dropdownSelection.value, this.selectedStepIndex);
  }

  public handleDropdownChange ($event) {
    this.dropdownSelection.patchValue($event);
  }
}
