import { EventFormatterService } from 'Src/ng2/shared/services/mixpanel/event-formatter.service';
import { Component, EventEmitter, Input, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import {
  ColDef,
  GridOptions,
  Module,
  GridApi,
  ValueFormatterParams,
  CellClickedEvent,
  GridReadyEvent,
  RowClassParams,
} from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import * as moment from 'moment';
import { EMPTY, Observable, Unsubscribable, of, throwError } from 'rxjs';
import { catchError, switchMap, take, tap } from 'rxjs/operators';

import { StudentTasksNotesService } from './tasks-notes-activity-table.service';
import { ImUser } from '../../../shared/services/im-models/im-user';
import { IUser } from '../../../shared/typings/interfaces/user.interface';
import { ISingleStudent } from '../../../student/student-data-service/single-student-data.interface';
import { ISchool } from '../../../shared/typings/interfaces/school.interface';
import { PartnerTypes, TValidPartnerTypes } from '../../../shared/typings/interfaces/partner.interface';
import { INotesData } from '../../../shared/modals/notes/notes-modal-shell.component';
import { NoRowsComponent } from '../../../shared/components/server-side-grid/no-rows/no-rows.component';
import { HistoryAllModalMoreButtonComponent } from '../../../shared/components/server-side-grid/history-all-modal-more-button/history-all-modal-more-button.component';
import { ApiService } from '../../../shared/services/api-service/api-service';
import { ModalsService } from '../../../shared/modals/modals.service';
import { ISchoolNote, VALID_NOTE_MODES } from '../../../shared/typings/interfaces/note.interface';
import { SnackBarService } from '../../../shared/services/snackbar/snackbar.service';
import { CategoryTagsComponent } from '../../../shared/components/server-side-grid/category-tags-renderer/category-tags-renderer.component';
import { IConfirmModalComponentData } from '../../../shared/modals/confirm/confirm-modal.component';
import { IValidTaskModes, ValidTaskModeType } from '../../../shared/typings/interfaces/task.interface';
import { ITaskData, TStudentPanelOrigin } from '../../../shared/modals/task/task-modal.component';
import { MatDialogRef } from '@angular/material/dialog';
import { SpinnerModalComponent } from '../../../shared/components/spinner/spinner-modal.component';
import { SpinnerService } from '../../../shared/components/spinner/spinner-modal.service';
import { MenuCellRenderer } from '../../../shared/components/server-side-grid/menu-cell-renderer/menu-cell-renderer.component';
import { PillCellRenderer } from '../../../shared/components/server-side-grid/pill-cell-renderer/pill-cell-renderer.component';
import { UtilitiesService } from '../../services/utilities/utilities.service';
import { AgTooltipComponent } from '../../../shared/components/ag-grid/ag-tooltip/ag-tooltip.component';
import { BackgroundJobNotificationService } from '../../services/background-job-notification-service/background-job-notification-service';
import { THistoryLogsComponent } from '../../modals/history-all/history-all-modal.component';
import { StudentPanelService } from 'Src/ng2/student/common-panels/student-panel.service';

export enum TASK_NOTES_ACTIVITY_TOGGLE_INDEX {
  // eslint-disable-next-line no-unused-vars
  TASKS = 0,
  // eslint-disable-next-line no-unused-vars
  NOTES = 1,
  // eslint-disable-next-line no-unused-vars
  ACTIVITY = 2,
};

export enum SHELTER_NOTES_ACTIVITY_TOGGLE_INDEX {
  // eslint-disable-next-line no-unused-vars
  NOTES = 0,
  // eslint-disable-next-line no-unused-vars
  ACTIVITY = 1,
};

export enum TasksNotesPanelCollections {
  // eslint-disable-next-line no-unused-vars
  TASKS = 'tasks',
  // eslint-disable-next-line no-unused-vars
  NOTES = 'notes',
  // eslint-disable-next-line no-unused-vars
  ACTIVITY = 'doc_logs',
}

export interface TasksNotesActivityTableStudentData {
  studentName: string;
  studentId: string;
}

const STATUS_HUMAN_MAP = {
  ACTIVE: 'To Do',
  COMPLETE: 'Complete',
  DELETED: 'Deleted',
};

@Component({
  selector: 'tasks-notes-activity-table',
  templateUrl: './tasks-notes-activity-table.component.html',
  styleUrls: ['./tasks-notes-activity-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TasksNotesActivityTableComponent {
  @Input() singleStudent: ISingleStudent;
  @Input() partnerType: TValidPartnerTypes;
  @Input() currentSchool: ISchool;
  @Input() studentData: TasksNotesActivityTableStudentData;
  @Input() currentUser: IUser;
  @Input() segmentedControlIndex: number;
  @Input() contextPartnerId: string;
  @Input() listType: THistoryLogsComponent;
  @Input() calcValueField?: string;
  @Output() saveStudent: EventEmitter<any> = new EventEmitter();
  @Output() updateIndex: EventEmitter<any> = new EventEmitter();

  // Segmented toggle
  public segmentedControlOptions: String[] = ['Tasks', 'Notes', 'Activity'];
  public shelterSegmentedControlOptions: String[] = ['Notes', 'Activity'];

  public isShelterStudent: boolean;
  public isSchoolStudent: boolean;

  // Component data
  public studentId: string;
  public currentNoteData: ISchoolNote;
  public noRowOverlayConfig: any;
  public userCanCreateTasks: boolean;
  private spinner: MatDialogRef<SpinnerModalComponent>;
  private schoolUsers: IUser[];
  public betaMessageData = {
    betaFeatureName: 'Tasks',
    betaLearnMoreUrl: 'https://intercom.help/portal-by-new-visions/en/articles/8800157-getting-started-with-tasks',
    betaShareFeedback: true,
  };

  // SubSinks
  public schoolUsers$: Unsubscribable;

  // Ag-grid
  private gridApi: GridApi;
  public modules: Module[] = [ClientSideRowModelModule];
  public gridOptions: GridOptions;
  public columnDefs: ColDef[];
  private currentSelectedRow: any;
  public rowData!: any[];

  // Paginator
  public currentPage: number = 1;
  public totalPages: number;
  public totalRows: number;
  public pageSize: number = 10;
  private subscription: Unsubscribable;

  constructor (
    private imUser: ImUser,
    private studentTasksNotesService: StudentTasksNotesService,
    public apiService: ApiService,
    private modalsService: ModalsService,
    private snackBarService: SnackBarService,
    private spinnerService: SpinnerService,
    private utilitiesService: UtilitiesService,
    private backgroundJobNotificationService: BackgroundJobNotificationService,
    private eventFormatterService: EventFormatterService,
    private studentPanel: StudentPanelService,
  ) {}

  public ngOnInit () {
    const { studentId } = this.studentData;
    this.studentId = studentId;
    this.isShelterStudent = this.partnerType === PartnerTypes.SHELTER;
    this.isSchoolStudent = this.partnerType === PartnerTypes.SCHOOL;
    this.subscription = this.studentPanel.listToggleState$?.subscribe(event => {
      this.onListToggle(event);
    });
    // Cluster users are NOT able to create tasks
    this.userCanCreateTasks = !this.imUser.isClusterUser(this.currentUser, this.partnerType) && this.imUser.isActive(this.currentUser, this.partnerType);
    this.columnDefs = this._getColumnDef(this.segmentedControlIndex);
    this.noRowOverlayConfig = this.getNoRowOverlayConfig();
    this.gridOptions = {
      rowModelType: 'clientSide',
      getRowId: ({ data: { _id } }) => (_id),
      domLayout: 'autoHeight', // sets the custom paginator to the bottom of the grid
      rowSelection: 'single',
      rowHeight: 50,
      pagination: true,
      paginationPageSize: this.pageSize,
      suppressPaginationPanel: true,
      suppressScrollOnNewData: true,
      suppressContextMenu: true,
      components: {
        noRowsComponent: NoRowsComponent,
        historyAllModalMoreButtonCellRenderer: HistoryAllModalMoreButtonComponent,
        CategoryTagsComponent,
        MenuCellRenderer,
        PillCellRenderer,
        agCustomTooltip: AgTooltipComponent,
      },
      noRowsOverlayComponent: 'noRowsComponent',
      noRowsOverlayComponentParams: this.noRowOverlayConfig,
      onFirstDataRendered: (params) => {
        params.api.sizeColumnsToFit();
      },
      rowClassRules: {
        'row-grey': this._getRowColor,
      },
    };
    if (!this.userCanCreateTasks) this.gridOptions.rowClass = 'no-click-cursor';
    this.refreshDataForStudent(this.currentPage);
  }

  public ngOnChanges (changes: SimpleChanges) {
    if (changes.studentData) this.studentId = changes.studentData.currentValue.studentId;
    else this.studentId = this.studentData.studentId;

    this.currentPage = 1;
    this.refreshDataForStudent(this.currentPage);
  }

  public onGridReady (params: GridReadyEvent<any>) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  public refreshDataForStudent (page: number): void {
    const collection = this._getCollectionName();
    const payload: any = {
      studentId: this.studentId,
      schoolId: this.partnerType === PartnerTypes.SHELTER ? this.contextPartnerId : this.currentSchool._id,
      partnerType: this.partnerType,
      collection,
    };

    if (this.calcValueField) {
      payload.calcValueField = this.calcValueField;
    }

    const queryOptions = {
      page,
      limit: this.pageSize,
    };

    this.studentTasksNotesService.getPaginatedRowData(payload, queryOptions).pipe(
      take(1),
    ).subscribe((data) => {
      this._processPaginatedData(data, collection);
      this.spinner?.close();
    });
  }

  public onCellClicked (event: CellClickedEvent): void {
    const colDef = event.colDef;
    const selectedRows = event.api.getSelectedRows();
    const rowData = selectedRows[0];
    this.currentSelectedRow = rowData;
    // Do not select rows if the 'Three dot menu' column was clicked
    const headerName = colDef.headerName;
    if (headerName === '') return;
    const isSelected = event.node.isSelected();
    const isNotesView = this.segmentedControlIndex === TASK_NOTES_ACTIVITY_TOGGLE_INDEX.NOTES;
    const isTaskView = this.segmentedControlIndex === TASK_NOTES_ACTIVITY_TOGGLE_INDEX.TASKS;
    // SHELTER
    const isShelterNotesView = this.segmentedControlIndex === SHELTER_NOTES_ACTIVITY_TOGGLE_INDEX.NOTES;

    if ((isSelected && isNotesView) || (isSelected && isShelterNotesView)) {
      const { attachments, categories, body, createdBy, dateCreated, ecfik } = this.currentSelectedRow;
      const nameFormatted = this.isShelterStudent ? attachments.shelterStudent.firstLast.toLocaleLowerCase().replace(/\b\w/g, firstLetter => firstLetter.toUpperCase()) : attachments.student.lastFirst.toLocaleLowerCase().replace(/\b\w/g, firstLetter => firstLetter.toUpperCase());
      const dateFormatted = moment(dateCreated).format('MMM DD, YYYY');
      const logDateFormatted = ecfik?.logDate && moment(ecfik.logDate).format('MMM DD');
      const data = [
        { sectionTitle: 'Student name', sectionText: nameFormatted },
        { sectionTitle: 'Categories', sectionText: categories.join(', ') },
        { sectionTitle: 'Note', sectionText: body },
        ...(ecfik?.logDate ? [{ sectionTitle: 'Date', sectionText: logDateFormatted }] : []),
        ...(ecfik?.type ? [{ sectionTitle: 'Type', sectionText: ecfik.type }] : []),
        ...(ecfik?.status ? [{ sectionTitle: 'Status', sectionText: ecfik.status }] : []),
        ...(ecfik?.needs ? categories.includes('ECFIK') && [{ sectionTitle: 'Needs (optional)', sectionText: ecfik.needs.length ? ecfik.needs.join(', ') : '-' }] : []),
        ...(ecfik?.serviceReferral ? categories.includes('ECFIK') && [{ sectionTitle: 'Service referral', sectionText: ecfik.serviceReferral === true ? 'Yes' : 'No' }] : []),
        { sectionTitle: 'Added by', sectionText: `${createdBy.firstName} ${createdBy.lastName} on ${dateFormatted}` },
      ];
      this.openNoteDetailsModal(data);
    }
    if (isSelected && isTaskView && this.isSchoolStudent) {
      const canEditTask = this.studentTasksNotesService.getEditTasksPermissions(this.currentUser, this.partnerType, event.data);
      this.spinner = this.spinnerService.openSpinner({ message: 'Loading...' });
      const task = event.data;
      task.name = this.utilitiesService.formatFullName(event.data.student.lastFirst);
      const origin: TStudentPanelOrigin = 'student-tasks-notes-panel';
      const data = {
        mode: ValidTaskModeType.TASK_DETAILS_SCHOOL,
        currentUser: this.currentUser,
        task,
        canEditTask,
        origin,
      };

      this.spinner?.close();
      this.modalsService
        .openTaskModal(data)
        .afterClosed()
        .subscribe(res => {
          if (res) {
            this.onActionSuccess({ toastText: 'Task has been updated' });
          }
        });
    }
  }

  private _getCollectionName (): string {
    if (this.partnerType === PartnerTypes.SCHOOL) {
      if (this.segmentedControlIndex === TASK_NOTES_ACTIVITY_TOGGLE_INDEX.TASKS) return TasksNotesPanelCollections.TASKS;
      if (this.segmentedControlIndex === TASK_NOTES_ACTIVITY_TOGGLE_INDEX.NOTES) return TasksNotesPanelCollections.NOTES;
      if (this.segmentedControlIndex === TASK_NOTES_ACTIVITY_TOGGLE_INDEX.ACTIVITY) return TasksNotesPanelCollections.ACTIVITY;
    }
    // SHELTER
    if (this.partnerType === PartnerTypes.SHELTER) {
      if (this.segmentedControlIndex === SHELTER_NOTES_ACTIVITY_TOGGLE_INDEX.NOTES) return TasksNotesPanelCollections.NOTES;
      if (this.segmentedControlIndex === SHELTER_NOTES_ACTIVITY_TOGGLE_INDEX.ACTIVITY) return TasksNotesPanelCollections.ACTIVITY;
    }
  }

  public onAddNote (): void {
    if (this.isSchoolStudent) this._openSchoolAddNoteModal();
    if (this.isShelterStudent) this._openShelterAddNoteModal();
  };

  private _openShelterAddNoteModal (): void {
    const data: INotesData = {
      partnerType: PartnerTypes.SHELTER,
      shelterId: this.contextPartnerId,
      currentUser: this.currentUser,
      mode: VALID_NOTE_MODES.CREATE_SHELTER,
      studentName: this.studentData.studentName,
      caresId: this.studentData.studentId,
      listType: this.listType,
    };
    this.modalsService
      .openNoteModal(data)
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.onActionSuccess({ toastText: 'Note has been added' });
        }
      });
  }

  private _openSchoolAddNoteModal (): void {
    const data: INotesData = {
      partnerType: PartnerTypes.SCHOOL,
      school: this.currentSchool,
      currentUser: this.currentUser,
      mode: VALID_NOTE_MODES.CREATE,
      studentName: this.studentData.studentName,
      studentId: this.studentData.studentId,
      isStudentECFIKEligible: this.singleStudent?.IS_ECFIK_ELIGIBLE,
      listType: this.listType,
    };
    this.modalsService
      .openNoteModal(data)
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.onActionSuccess({ toastText: 'Note has been added' });
        }
      });
  }

  public editNoteButton () {
    const mode = 'EDIT_PROFILE';
    let data: INotesData;
    if (this.isSchoolStudent) {
      data = {
        partnerType: PartnerTypes.SCHOOL,
        school: this.currentSchool,
        currentUser: this.currentUser,
        note: this.currentSelectedRow,
        mode,
        studentName: this.studentData.studentName,
        studentId: this.studentData.studentId,
        listType: this.listType,
      };
    }
    if (this.isShelterStudent) {
      data = {
        partnerType: PartnerTypes.SHELTER,
        shelterId: this.contextPartnerId,
        currentUser: this.currentUser,
        note: this.currentSelectedRow,
        mode,
        studentName: this.studentData.studentName,
        caresId: this.studentData.studentId,
        listType: this.listType,
      };
    }

    this.modalsService
      .openNoteModal(data)
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.onActionSuccess({ toastText: 'Note has been updated' });
        }
      });
  }

  public deleteNoteButton (docId: any) {
    const data: IConfirmModalComponentData = {
      title: 'Delete note',
      message: 'Are you sure you would like to delete this note? This note will be removed from the student\'s records.',
      confirmText: 'Delete',
    };
    const deleteNoteEvent = this.eventFormatterService.getDeleteNoteEvent({
      view: this.listType === 'STUDENT' ? 'STUDENT-PROFILE' : 'GRID-ROW',
      portal: 'SCHOOL', // tasks-notes-activity-table used to manage notes in school portal only
      categories: [],
    });

    this.modalsService
      .openConfirmModal(data)
      .afterClosed()
      .pipe(
        switchMap((confirmed: boolean) => {
          if (confirmed) {
            const noteId = docId;
            return this.apiService.patchNote(noteId, { status: 'DELETED' }, this.partnerType, deleteNoteEvent);
          } else {
            return of(false);
          }
        },
        ),
      ).subscribe(res => {
        if (res) {
          this.onActionSuccess({ toastText: 'Note has been deleted' });
        }
      });
  }

  public onActionSuccess ({ toastText }: { toastText?: string | null } = {}) {
    this.spinner = this.spinnerService.openSpinner({ message: 'Loading...' });
    if (toastText) {
      this.snackBarService.showToast({ toastText });
      // Using to BG notification service to update Tasks To Do column in the Data Grid
      this.backgroundJobNotificationService.sendMessage({ backgroundJob: 'BulkStudentTaskCreate' });
    }
    this.saveStudent.emit();
    this.currentPage = 1;
    this.refreshDataForStudent(this.currentPage);
  }

  public openNoteDetailsModal (rowData: any) {
    this.modalsService
      .openNoteDetailsModal({ rowData, listType: this.listType })
      .afterClosed()
      .subscribe(res => {
        this.gridApi.deselectAll();
      });
  }

  public onAddTask (): void {
    this.spinner = this.spinnerService.openSpinner({ message: 'Loading...' });
    this.getSchoolUser$()
      .pipe(
        take(1),
        tap((schoolUsers: IUser[]) => {
          const data: ITaskData = {
            partnerType: this.partnerType,
            school: this.currentSchool,
            currentUser: this.currentUser,
            schoolUsers,
            mode: ValidTaskModeType.CREATE_SINGLE_TASK_SCHOOL,
            studentData: this.studentData,
          };
          this.spinner?.close();
          this.modalsService
            .openTaskModal(data)
            .afterClosed()
            .subscribe((res: any) => {
              if (res?.errors) {
                this.spinner?.close();
                this.modalsService.openAlertModal({
                  confirmText: 'Close',
                  message: 'Task cannot be created because the selected recipient does not have access to this student.',
                  title: 'Unable to create task',
                });
                return EMPTY;
              }

              if (res) {
                this.onActionSuccess({ toastText: 'Task has been created' });
              }
            });
        }),
      ).subscribe();
  };

  private getSchoolUser$ (): Observable<IUser[]> {
    if (this.schoolUsers) {
      return of(this.schoolUsers);
    }
    return this.apiService
      .getUsers(this.currentSchool._id, JSON.stringify({ where: { authorizationStatus: { $eq: 'FULL_ACCESS' } } }))
      .pipe(
        take(1),
        tap((schoolUsers: IUser[]) => {
          this.schoolUsers = schoolUsers;
        }),
      );
  }

  public onMenuCellRendererClick (mode: IValidTaskModes, data: any): void {
    const task = data;
    this.spinner = this.spinnerService.openSpinner({ message: 'Loading...' });
    this.getSchoolUser$()
      .pipe(
        take(1),
        tap((schoolUsers: IUser[]) => {
          const data: ITaskData = {
            partnerType: this.partnerType,
            school: this.currentSchool,
            studentData: this.studentData,
            currentUser: this.currentUser,
            schoolUsers,
            mode,
            task,
          };
          this.spinner?.close();
          this.modalsService
            .openTaskModal(data)
            .afterClosed()
            .subscribe((res: any) => {
              if (res?.errors) {
                this.spinner?.close();
                this.modalsService.openAlertModal({
                  confirmText: 'Close',
                  message: res.errors,
                  title: 'Unable to edit task',
                });
                return EMPTY;
              }

              if (res) {
                let toastText: string;
                if (mode === ValidTaskModeType.EDIT_TASK_SCHOOL) toastText = 'Task has been updated';
                if (mode === ValidTaskModeType.DELETE_TASK_SCHOOL) toastText = 'Task has been deleted';
                this.onActionSuccess({ toastText });
              }
            });
        }),
        catchError(err => {
          if (err) {
            this.spinner?.close();
            return throwError(EMPTY);
          }
        }),
      )
      .subscribe();
  }

  public onListToggle (event: number): void {
    if (this.segmentedControlIndex !== event) {
      this.segmentedControlIndex = event;
      this.updateIndex.emit(this.segmentedControlIndex);
      this.columnDefs = this._getColumnDef(this.segmentedControlIndex);
      this.noRowOverlayConfig = this.getNoRowOverlayConfig();
      this.gridOptions.noRowsOverlayComponentParams = this.noRowOverlayConfig;
      if (this.isSchoolStudent) {
        if (this.segmentedControlIndex === TASK_NOTES_ACTIVITY_TOGGLE_INDEX.ACTIVITY) {
          this.gridOptions.rowClass = 'no-click-cursor';
        }
      }
      // SHELTER
      if (this.isShelterStudent) {
        if (this.segmentedControlIndex === SHELTER_NOTES_ACTIVITY_TOGGLE_INDEX.ACTIVITY) {
          this.gridOptions.rowClass = 'no-click-cursor';
        }
      } else {
        delete this.gridOptions.rowClass;
      }
      this.currentPage = 1;
      this.studentPanel.setPanelIndex('student-tasks-notes-panel', this.segmentedControlIndex);
      this.refreshDataForStudent(this.currentPage);
    }
  }

  private _getColumnDef (listIndex: number): ColDef[] {
    const FIRST_COLUMN_WIDTH = (this.listType === 'STUDENT') ? 550 : 324;

    const COLUMN_DEFS_NOTES: ColDef[] = [
      {
        field: 'notes',
        headerName: 'Notes',
        width: FIRST_COLUMN_WIDTH,
        wrapText: true,
        suppressMovable: true,
        suppressMenu: true,
        headerClass: 'header-first-column-custom-text',
        cellClass: 'cell-custom-text',
        cellStyle: {
          wordBreak: 'pre-line',
        },
        cellRenderer: CategoryTagsComponent,
        cellRendererParams: {
          listType: this.listType,
          collection: TasksNotesPanelCollections.NOTES,
          displayCatsInBody: false,
        },
      },
      {
        field: 'categories',
        headerName: 'Categories',
        flex: 1,
        suppressMovable: true,
        suppressMenu: true,
        wrapText: true,
        headerClass: 'header-custom-text',
        cellClass: ['cell-custom-text'],
        cellStyle: {
          wordBreak: 'normal',
          'line-height': 'normal',
        },
        tooltipValueGetter: (params: any) => {
          if (!params.node.data) return;
          const categories = params.data.categories || params.data.tags;
          if (!categories || !categories.length || categories.length === 1) return;
          const filteredTags: string[] = categories.filter((category) => category !== 'Notes').sort();
          return filteredTags.join(', ');
        },
        valueFormatter: (params: ValueFormatterParams) => {
          if (!params.node.data) return;
          const categories = params.data.categories || params.data.tags;
          if (!categories || !categories.length) return;
          const filteredTags: string[] = categories.filter((category) => category !== 'Notes').sort();
          const categoryTagsArray = [filteredTags[0]]; // Add the first category
          if (filteredTags.length > 1) {
            const numUnnamed = filteredTags.length - 1;
            categoryTagsArray.push(`+${numUnnamed}`);
          }
          return categoryTagsArray.join(', ');
        },
      },
      {
        field: 'addedBy',
        headerName: 'Added by',
        flex: 1,
        wrapText: true,
        suppressMovable: true,
        suppressMenu: true,
        headerClass: 'header-custom-text',
        cellClass: ['cell-custom-text'],
        valueFormatter: (params: ValueFormatterParams) => {
          if (!params.node.data) return;
          // Replace 'ETL User' with 'The Portal' to indicate logs created by an automated process.
          const { firstName, lastName } = params.data.createdBy;

          if (firstName?.toLowerCase() === 'etl' && lastName?.toLowerCase() === 'user') {
            return 'The Portal';
          }

          return `${firstName} ${lastName}`;
        },
      },
      {
        field: 'addedOn',
        headerName: 'Added on',
        flex: 1,
        suppressMovable: true,
        suppressMenu: true,
        headerClass: 'header-custom-text',
        cellClass: ['cell-custom-text'],
        valueFormatter: (params: ValueFormatterParams) => {
          if (!params.node.data) return;
          const createdAt = params.data.dateCreated;
          const dateFormatted = moment.utc(createdAt).format('MMM D, YYYY');
          return dateFormatted;
        },
      },
      {
        field: 'historyAllModalMoreButton',
        headerName: '',
        width: 48,
        suppressMovable: true,
        suppressMenu: true,
        cellClass: ['cell-custom-text', 'cell-custom-text-center'],
        cellRenderer: 'historyAllModalMoreButtonCellRenderer',
        cellRendererParams: {
          collection: 'notes',
          parentComponent: this,
        },
      },
    ];

    const COLUMN_DEFS_ACTIVITY: ColDef[] = [
      {
        field: 'description',
        headerName: 'Activity',
        width: FIRST_COLUMN_WIDTH,
        wrapText: true,
        suppressMovable: true,
        suppressMenu: true,
        headerClass: 'header-first-column-custom-text',
        cellClass: ['cell-custom-text'],
        cellStyle: {
          wordBreak: 'pre-line',
        },
        valueFormatter: (params: ValueFormatterParams) => {
          if (!params.node.data) return;
          const description = params.data.description;
          const maxLength = (this.listType === 'STUDENT') ? 150 : 90;
          if (description?.length > maxLength) {
            return description.substring(0, maxLength + 10) + '...';
          } else return description;
        },
        tooltipField: 'description',
        tooltipComponent: AgTooltipComponent,
        tooltipComponentParams: {
          tooltipTemplate: 'TableTooltipSingleColumn',
          hasTooltipData: true,
        },
      },
      COLUMN_DEFS_NOTES[1],
      {
        ...COLUMN_DEFS_NOTES[2],
        headerName: 'Action taken by',
      },
      {
        ...COLUMN_DEFS_NOTES[3],
        headerName: 'Action taken on',
        valueFormatter: (params: ValueFormatterParams) => {
          if (!params.node.data) return;
          const createdAt = params.data.createdAt;
          const dateFormatted = moment(createdAt).format('MMM D, YYYY');
          return dateFormatted;
        },
      },
    ];

    const COLUMN_DEFS_TASKS: ColDef[] = [
      {
        ...COLUMN_DEFS_NOTES[0],
        field: 'tasks',
        headerName: 'Tasks',
        cellRenderer: CategoryTagsComponent,
        cellRendererParams: {
          listType: this.listType,
          collection: TasksNotesPanelCollections.TASKS,
          displayCatsInBody: false,
        },
      },
      COLUMN_DEFS_NOTES[1],
      {
        ...COLUMN_DEFS_NOTES[2],
        headerName: 'Assigned to',
        valueFormatter: (params: ValueFormatterParams) => {
          if (!params.node.data) return;
          const createdBy = params.data.assignee;
          const name = `${createdBy?.firstName} ${createdBy?.lastName}`;
          return name;
        },
      },
      {
        ...COLUMN_DEFS_NOTES[3],
        headerName: 'Created on',
        valueFormatter: (params: ValueFormatterParams) => {
          if (!params.node.data) return;
          const createdAt = params.data.createdAt;
          const dateFormatted = moment(createdAt).format('MMM D, YYYY');
          return dateFormatted;
        },
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        suppressMovable: true,
        suppressMenu: true,
        headerClass: 'header-custom-text',
        cellClass: ['cell-custom-text'],
        cellRenderer: 'PillCellRenderer',
        cellRendererParams: {
          getPillColor: this._getPillColor,
          getFormattedStatus: this._getFormattedStatus,
        },
      },
      {
        headerName: '',
        field: 'tasksModalMoreButton',
        width: 50,
        suppressMovable: true,
        suppressMenu: true,
        cellRenderer: 'MenuCellRenderer',
        cellClass: ['cell-custom-text', 'cell-custom-text-center'],
        cellRendererParams: {
          params: {
            parentName: 'my-tasks',
            parent: this,
            options: [
              {
                key: ValidTaskModeType.EDIT_TASK_SCHOOL,
                human: 'Edit task',
                customClass: 'not-text-transform',
              },
              {
                key: ValidTaskModeType.DELETE_TASK_SCHOOL,
                human: 'Delete task',
                customClass: 'delete-note not-text-transform',
              },
            ],
            checkTaskEditPermissions: true,
          },
        },
      },
    ];
    if (this.isSchoolStudent) {
      if (listIndex === TASK_NOTES_ACTIVITY_TOGGLE_INDEX.TASKS) return COLUMN_DEFS_TASKS;
      if (listIndex === TASK_NOTES_ACTIVITY_TOGGLE_INDEX.NOTES) return COLUMN_DEFS_NOTES;
      if (listIndex === TASK_NOTES_ACTIVITY_TOGGLE_INDEX.ACTIVITY) return COLUMN_DEFS_ACTIVITY;
    }
    // SHELTER
    if (this.isShelterStudent) {
      if (listIndex === SHELTER_NOTES_ACTIVITY_TOGGLE_INDEX.NOTES) return COLUMN_DEFS_NOTES;
      if (listIndex === SHELTER_NOTES_ACTIVITY_TOGGLE_INDEX.ACTIVITY) return COLUMN_DEFS_ACTIVITY;
    }
  }

  private getNoRowOverlayConfig (): any {
    const segment = this.isShelterStudent ? this.shelterSegmentedControlOptions[this.segmentedControlIndex] : this.segmentedControlOptions[this.segmentedControlIndex];
    let noRowOverlayConfig = {
      parentComponent: null,
      parentName: null,
      buttonText: null,
      primaryEmptyStateHeader: null,
      secondaryEmptyStateMessage: null,
      showButton: null,
      linkUrl: null,
      linkLabel: null,
      disabledButton: false,
    };
    switch (segment) {
      case 'Notes': {
        noRowOverlayConfig = {
          parentComponent: this,
          parentName: 'student-tasks-notes-panel',
          buttonText: 'Add note',
          primaryEmptyStateHeader: 'No notes added',
          secondaryEmptyStateMessage: 'Add notes to share information with your colleagues or log your interactions with students and their families.',
          showButton: true,
          linkUrl: null,
          linkLabel: null,
          disabledButton: false,
        };
        break;
      }
      case 'Activity': {
        noRowOverlayConfig = {
          parentComponent: this,
          parentName: 'student-tasks-notes-panel',
          buttonText: '',
          primaryEmptyStateHeader: 'No activity for this student',
          secondaryEmptyStateMessage: 'Activity logs are generated when actions are taken in the Portal such as adding a note, creating a task, or assigning supports or plans.',
          showButton: false,
          linkUrl: null,
          linkLabel: null,
          disabledButton: false,
        };
        break;
      }

      case 'Tasks': {
        noRowOverlayConfig = {
          parentComponent: this,
          parentName: 'student-tasks-notes-panel',
          buttonText: 'Create task',
          disabledButton: !this.userCanCreateTasks,
          primaryEmptyStateHeader: 'No tasks created',
          secondaryEmptyStateMessage: 'Create tasks to keep track of student-level to dos like collecting event sign ups, conducting attendance outreach, or reviewing student progress.',
          showButton: true,
          linkUrl: 'https://intercom.help/portal-by-new-visions/en/articles/8800157-getting-started-with-tasks',
          linkLabel: 'Learn more >',
        };
        break;
      }
      default: {
        break;
      }
    }
    return noRowOverlayConfig;
  }

  private _processPaginatedData (data: any, collection: string): void {
    const documents = data.data;
    let sortedData: any[];
    if (collection === 'notes') {
      sortedData = this._sortByDateField(documents, 'dateCreated');
    }

    if (collection === 'doc_logs') {
      sortedData = this._sortByDateField(documents, 'createdAt');
    }

    if (collection === 'tasks') {
      sortedData = documents.sort(this._sortTasksData);
    }

    this.rowData = sortedData;
    this.gridApi?.setRowData(this.rowData); // Needs to call in order for custom render to pick up changes
    this.currentPage = data.pages.current;
    this.totalRows = data.items.total;
    this.totalPages = Math.ceil(this.totalRows / this.pageSize);
    this.gridApi?.redrawRows();
  }

  private _sortByDateField (data: any[], sortField): any[] {
    const sortedData = data.sort((document1: any, document2: any) => {
      const date1Timestamp = new Date(document1[sortField]).getTime();
      const date2Timestamp = new Date(document2[sortField]).getTime();
      return date2Timestamp - date1Timestamp;
    });

    return sortedData;
  }

  private _sortTasksData (a, b): any {
    // Sorting by status ('ACTIVE' first)
    const statusOrder = { ACTIVE: 1, COMPLETE: 2 };
    const statusComparison = statusOrder[a.status] - statusOrder[b.status];

    // If the status is the same, compare createdAt or resolvedAt (if resolvedAt is null, turn nulls into dates in the future)
    if (statusComparison === 0) {
      const aDate: any = a.status === 'ACTIVE' ? new Date(a.createdAt) : new Date(a.resolvedAt || '9999-12-31');
      const bDate: any = b.status === 'ACTIVE' ? new Date(b.createdAt) : new Date(b.resolvedAt || '9999-12-31');

      // Sorting by date (most recent to oldest)
      return bDate - aDate;
    }

    return statusComparison;
  }

  private _getRowColor (params: RowClassParams): boolean {
    const {
      data: { status },
    } = params;
    if (!status) return false;
    else return status !== 'ACTIVE';
  }

  private _getPillColor (data: any): string {
    const { status } = data;
    let pillColor = 'blue';

    switch (status) {
      case 'ACTIVE': {
        pillColor = 'blue-light-7 dark-text font-weight-normal';
        break;
      }
      case 'COMPLETE': {
        pillColor = 'green-light-1 dark-text font-weight-normal';
        break;
      }
      default: {
        pillColor = 'blue';
        break;
      }
    }
    return pillColor;
  }

  private _getFormattedStatus (data: any): string {
    const { status } = data;
    const formattedStatus = STATUS_HUMAN_MAP[status];
    return formattedStatus;
  }

  public onBtNext (action: number) {
    this.refreshDataForStudent(action);
  }

  public onBtPrevious (action: number) {
    this.refreshDataForStudent(action);
  }
}
