import { NvNoteSummerModule } from './../nv-note-summer/nv-note-summer.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvIconModule } from 'projects/shared/nvps-libraries/design/nv-icon/nv-icon.module';
import { NvSnapshotCardComponent } from './nv-snapshot-card.component';

@NgModule({
  imports: [CommonModule, NvIconModule, NvNoteSummerModule],
  declarations: [NvSnapshotCardComponent],
  exports: [NvSnapshotCardComponent],
})
export class NvSnapshotCardModule {}
