import { isObservable } from 'rxjs';
import { CanActivate, Route, Router, UrlTree, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable, Injector } from '@angular/core';

@Injectable()
export class RouteActivationService {
  constructor (
    private injector: Injector,
    private router: Router,
  ) {}

  // TODO: create function that just takes a URL and route snapshot (https://newvisions.atlassian.net/browse/PI-2672)
  async canActivateRouteConfig (routeConfig: Route, route: ActivatedRouteSnapshot): Promise<boolean> {
    const guards = routeConfig.canActivate || [];
    for (const guard of guards) {
      const instance: CanActivate = this.injector.get(guard);
      const asyncResult = await instance.canActivate({ ...route, ...routeConfig, routeConfig } as ActivatedRouteSnapshot, this.router.routerState.snapshot);
      const result = isObservable(asyncResult) ? await asyncResult.toPromise().catch(() => false) : asyncResult; // accounts for SyncCombinatorGuard and other async guards
      if (result === false || result instanceof UrlTree) return false;
    }
    return true;
  }
}
