<div *ngIf="!!title" class="nv-sidebar-title-cell">
  <div class="nv-sidebar-title">
    <div class="nv-sidebar-title-left">
      <span>{{ title }}</span>
      <!-- Optionally display an icon with a tooltip if 'icon' is provided -->
      <nv-icon *ngIf="icon" nvTooltip [name]="icon" [tooltipData]="iconTooltip"></nv-icon>
    </div>
    <div class="nv-sidebar-title-right">
      <!-- Content projection: any element with the 'title-right' attribute will be projected here from the parent component -->
      <ng-content select="[title-right]"></ng-content>
    </div>
  </div>
  <div *ngIf="!!subtitle" class="nv-sidebar-subtitle" [ngClass]="{ pulse: pulse }">{{ subtitle }}</div>
</div>
