import { Injectable } from '@angular/core';
import { reduce } from 'lodash';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { SubjectAreas } from 'Src/ng2/shared/constants/subject-areas.constant';
import { IListViewData } from '../../../../typings/interfaces/list-view.interface';
import { COLUMN_DATA_TYPE } from './../../../../constants/list-view/cell-type.constant';

interface IEnrolledAndFailingData {
  studentsWithSubj: ISubjectData;
  studentsFailingCourseInSubj: ISubjectData;
}

interface IOptions {
  columns: Array<object>;
  listType: string;
  navDataType: string;
  navRoute: string;
  navData: { index: number; key: string };
}

interface ISubjectData {
  // value is a student, the projection matching the dashboard students projection - needs interface
  [key: string]: Array<object>;
}

// takes students and an options object
@Injectable()
export class SubjectRollupGrouping {
  public getGrouping(filteredStudents$: Observable<any>, options: IOptions): Observable<any> {
    const { columns } = options;
    return filteredStudents$.pipe(
      take(1),
      map(students => {
        const subjectData: IEnrolledAndFailingData = this.getEnrolledAndFailedStudentsBySubject(students);
        // empty list
        const formattedList: IListViewData = this.getFormattedListShell(options);
        const groupingData: object = { students, subjectData, columns, formattedList };
        // populated list
        const grouping: IListViewData = this.generateGrouping(groupingData);
        return grouping;
      }),
    );
  }

  private getFormattedListShell(args): IListViewData {
    const { columns, listType, navDataType, navRoute, navData } = args;
    return {
      listType,
      columns: this.formatColumnsForList(columns),
      sections: [
        {
          name: 'Subject',
          count: 0,
          data: [],
          defaultDisplayCount: 10,
          dataType: 'String',
          key: 'subject',
        },
      ],
      navDataType,
      navData,
      navRoute,
    };
  }

  private formatColumnsForList(columns) {
    const formattedColumns = columns.reduce((formattedColumns, col) => {
      if (col.cellType !== COLUMN_DATA_TYPE.SECTION_HEADER) {
        formattedColumns[col.key] = {
          name: col.human,
          cellType: col.cellType,
          cellConfig: col.cellConfig,
          dataType: col.dataType,
          orderBy: col.orderBy,
        };
      }
      return formattedColumns;
    }, {});
    return formattedColumns;
  }

  private getEnrolledAndFailedStudentsBySubject(students: any[]): IEnrolledAndFailingData {
    return students.reduce(
      (subjectData, student) => {
        // use map to prevent duplicates without having to iterate on results to filter uniques
        const enrolledSubjectsStudentWasAdded = {};
        const failedSubjectsStudentWasAdded = {};
        const {
          currProgram: { grades },
        } = student;
        // bucket out by grade
        grades.forEach(grade => {
          const { subj, mostRecent, pf } = grade;
          // if we see a new subj, initialize it in accum
          if (subj && !subjectData.studentsWithSubj[subj]) subjectData.studentsWithSubj[subj] = [];
          // we want to keep a tally of all students who `currently` have a course in the subj - regardless of P / F
          // notice we don't want a student to be pushed to a subject twice, so we keep a tab on that
          if (subj && mostRecent && !enrolledSubjectsStudentWasAdded[subj]) {
            subjectData.studentsWithSubj[subj].push(student);
            enrolledSubjectsStudentWasAdded[subj] = true;
          }
          // we want to track all students `currently` failing with no dupes
          if (subj && mostRecent && pf === 'F' && !failedSubjectsStudentWasAdded[subj]) {
            // if we see a new subj, initialize it in accum
            if (!subjectData.studentsFailingCourseInSubj[subj]) subjectData.studentsFailingCourseInSubj[subj] = [];
            // push student & prevent dupes
            subjectData.studentsFailingCourseInSubj[subj].push(student);
            failedSubjectsStudentWasAdded[subj] = true;
          }
        });
        return subjectData;
      },
      { studentsWithSubj: {}, studentsFailingCourseInSubj: {} },
    );
  }

  private generateGrouping(args) {
    const { subjectData, columns, formattedList } = args;
    const grouping = reduce(
      subjectData.studentsFailingCourseInSubj,
      (formattedList, _, subject) => {
        const config = { subject, grouping: 'SUBJECT' };
        const humanReadableSubjectName = SubjectAreas[subject].human;

        const subjectColumn = {
          data: humanReadableSubjectName,
          dependencies: {
            filterValue: subject,
            studentIds: subjectData.studentsFailingCourseInSubj[subject].map(({ studentId }) => studentId),
            sortOrder: SubjectAreas[subject].sortOrder,
          },
        };
        const dataObj = columns.reduce(
          (result, { key, getValueAtPath }) => {
            result[key] = getValueAtPath(subjectData.studentsWithSubj[subject], config);
            return result;
          },
          { subject: subjectColumn },
        );

        formattedList.sections[0].count++;
        formattedList.sections[0].data.push(dataObj);
        return formattedList;
      },
      formattedList,
    );
    return grouping;
  }
}
