import { RollbarService } from './../../rollbar/rollbar.service';
import { ImStudent } from '../im-student.service';
import { UtilitiesService } from '../../utilities/utilities.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ImSchool } from 'Src/ng2/shared/services/im-models/im-school';
import { Cohort } from '../../../constants/cohort.constant';
import { CurrentGraduationThresholdAsOfMonth } from '../../../constants/current-graduation-threshold-as-of-month.constant';
import { CurrentSchoolYear } from '../../../constants/current-school-year.constant';
import { GraduationPlanTransfer } from '../../../constants/graduation-plan-transfer.constant';
import { GraduationPlanWarnings, GraduationPlanWarningsCreditAndRegentsThresholds } from '../../../constants/graduation-plan-warnings.constant';
import * as Rollbar from 'rollbar';
import { TermsToGradMetrics } from '../../../constants/terms-to-grad-metrics.constant';
import { GraduationPlan } from '../../../constants/graduation-plan.constant';
import { Inject, Injectable } from '@angular/core';

// this should be moved to a shared service (Carlos)
function getOverallPctPres (student) {
  const {
    att: { history },
  } = student;
  const oneYearprior = history.find(({ year }) => year === 'SY2019-20');
  const overallPctPres = (oneYearprior && oneYearprior.overallPctPres) || null;

  return overallPctPres;
}

/*
     * `@depends` decorator
     *
     * Use this decorator to state that an ImStudent method depends on certain paths, joins or other methods:
     *    @depends({ paths: ['studentDetails.name'], joins: ['courseDiffs'], methods: ['_getSomething']})
     *    static fullName(student) {  }
     *
     * @param depends Object an object with three optional keys: `paths`, `joins` and `methods`
     * @return {dependencyDecorator}
     */
function depends (depends) {
  return function dependencyDecorator (target, key, descriptor) {
    descriptor.value.depends = depends;
    return descriptor;
  };
}

@Injectable()
export class ImStudentGradPlanning {
  constructor (
    public ImSchool: ImSchool,
    public ImStudent: ImStudent,
    public Utils: UtilitiesService,
    @Inject(RollbarService) private rollbar: Rollbar,
  ) { }

  /**
       * Removes fields from `student` that are not included in the dependent paths or joins
       *
       * @param method
       * @returns {Student} masked student
       */
  maskedStudentForMethod (student, method) {
    const paths = this.pathsFor(method);
    const joins = this.joinsFor(method);
    _.each(joins, join => {
      paths.push(`join_${join}`);
    });
    return this.Utils.generatePatch(student, paths);
  }

  _dependentMethods (method) {
    const resolvedMethods = [];
    const unresolvedMethods = [];

    // See here for the dependency resolution algorithm used:
    // https://www.electricmonk.nl/log/2008/08/07/dependency-resolving-algorithm/
    const resolveMethods = (method, resolvedMethods, unresolvedMethods) => {
      unresolvedMethods.push(method);
      const methods = (this[method].depends || {}).methods || [];
      _.each(methods, m => {
        if (!_.includes(resolvedMethods, m)) {
          if (_.includes(unresolvedMethods, m)) {
            throw new Error(`Circular method dependency detected: ${method} -> ${m}`);
          }
          resolveMethods(m, resolvedMethods, unresolvedMethods);
        }
      });
      resolvedMethods.push(method);
      _.pull(unresolvedMethods, method);
    };

    resolveMethods(method, resolvedMethods, unresolvedMethods);
    return resolvedMethods;
  }

  /**
       * Traverses the method dependency graph and returns all of the paths that `method` depends on
       *
       * @param method a ImStudentGradPlanning method
       * @return {Array} and array of paths
       */
  pathsFor (method) {
    if (!this[method]) throw new Error(`method '${method}' does not exist`);

    if (!(this[method].depends || {}).methods) {
      const paths = (this[method].depends || {}).paths;
      if (!_.isArray(paths)) {
        console.warn(
          `ImStudentGradPlanning.pathsFor('${method}')` +
          ` was called, but ${method} didn't declare any dependent paths. ` +
          `If ${method} doesn't depend on any paths, annotate it with \`@depends({ paths: [] })\``,
        );
      }
      return paths;
    }
    const dependentMethods = this._dependentMethods(method);
    const dependentPaths = _.uniq(
      _.flatten(
        _.map(dependentMethods, m => {
          const depends = this[m].depends;
          if (!depends) {
            console.warn(
              `\`${m}\` was detected as a dependent method, but it didn't register any dependencies itself`,
            );
            return [];
          }
          return depends.paths || [];
        }),
      ),
    );
    return dependentPaths;
  }

  /**
       * Traverses the method dependency graph and returns all of the joins that `method` depends on
       *
       * @param method a ImStudentGradPlanning method
       * @return {Array} and array of joins
       */
  joinsFor (method) {
    if (!this[method]) throw new Error(`method '${method}' does not exist`);

    if (!(this[method].depends || {}).methods) { return (this[method].depends || {}).joins; }
    const dependentMethods = this._dependentMethods(method);
    const dependentJoins = _.uniq(
      _.flatten(
        _.map(dependentMethods, m => {
          const depends = this[m].depends;
          if (!depends) {
            console.warn(
              `\`${m}\` was detected as a dependent method, but it didn't register any dependencies itself`,
            );
            return [];
          }
          return depends.joins || [];
        }),
      ),
    );
    return dependentJoins;
  }

  /**
   * @param {Object} student
   * @return {String} returns the students calculated grad plan based on
   * regents/credits earned against the grad planning thresholds set by the school
   */
  @depends({
    paths: [
      ...ImStudent.prototype.pathsFor('isSafetyNetEligible'),
      ...ImStudent.prototype.pathsFor('isActive'),
      ...ImStudent.prototype.pathsFor('isSuperSenior'),
      'studentDetails.classOf',
      'creditDetails.byArea.total.earned',
      'regentsDetails.numberPassed.of5Local',
      'regentsDetails.numberPassed.of5Regents',
      'regentsDetails.numberPassed.of9Advanced',
    ],
    methods: [],
  })
  getCalculatedGradPlan (student, school, asOfMonth?) {
    let calculatedGradPlan = '';

    const cohort = student.studentDetails.classOf;
    if (!cohort) return calculatedGradPlan;

    const isActive = this.ImStudent.isActive(student);
    if (!isActive) return calculatedGradPlan;

    const isSuperSeniorForGradPlanning = this.ImStudent.isSuperSeniorForGradPlanning(student);
    if (isSuperSeniorForGradPlanning) return calculatedGradPlan;

    asOfMonth = asOfMonth || CurrentGraduationThresholdAsOfMonth;

    // Get the correct metrics for this student
    const gradPlanningThresholds = this.ImSchool.getGradPlanningThresholdsForCohort(school, cohort, asOfMonth);

    // credit thresholds
    const minCreditsForAug = gradPlanningThresholds.aug.credits;
    const minCreditsForJune = gradPlanningThresholds.june.credits;

    // regents thresholds for advanced regents diploma
    const minRegentsForAdvancedAug = gradPlanningThresholds.aug.advanced;
    const minRegentsForAdvancedJune = gradPlanningThresholds.june.advanced;

    // regents thresholds for regents diploma
    const minRegentsForRegentsAug = gradPlanningThresholds.aug.regents;
    const minRegentsForRegentsJune = gradPlanningThresholds.june.regents;

    // regents thresholds for local diploma
    const minRegentsForLocalAug = gradPlanningThresholds.aug.local;
    const minRegentsForLocalJune = gradPlanningThresholds.june.local;

    // Get the students total credits earned and regents passed
    const totalCredits = student.creditDetails.byArea.total.earned;
    const countOfRegentsPassedForAdvanced = student.regentsDetails.numberPassed.of9Advanced;
    const countOfRegentsPassedForRegents = student.regentsDetails.numberPassed.of5Regents;
    const countOfRegentsPassedForLocal = student.regentsDetails.numberPassed.of5Local;

    // local
    const isSafetyNetEligible = this.ImStudent.isSafetyNetEligible(student);

    // FYI - the order of if blocks bellow matter!! DO NOT REORDER!!

    // probably non-grad
    if (totalCredits < minCreditsForAug) {
      calculatedGradPlan = '';
      return calculatedGradPlan;
    }

    // june

    if (totalCredits >= minCreditsForJune) {
      // advanced june
      if (countOfRegentsPassedForAdvanced >= minRegentsForAdvancedJune) {
        calculatedGradPlan = GraduationPlan.ADVANCED_REGENTS_4_YEAR_JUNE.humanName;
        return calculatedGradPlan;
      }

      // regents june
      if (countOfRegentsPassedForRegents >= minRegentsForRegentsJune) {
        calculatedGradPlan = GraduationPlan.REGENTS_4_YEAR_JUNE.humanName;
        return calculatedGradPlan;
      }

      // local june
      if (isSafetyNetEligible && countOfRegentsPassedForLocal >= minRegentsForLocalJune) {
        calculatedGradPlan = GraduationPlan.LOCAL_4_YEAR_JUNE.humanName;
        return calculatedGradPlan;
      }
    }

    // aug

    // advanced aug
    if (countOfRegentsPassedForAdvanced >= minRegentsForAdvancedAug) {
      calculatedGradPlan = GraduationPlan.ADVANCED_REGENTS_4_YEAR_AUG.humanName;
      return calculatedGradPlan;
    }

    // regents aug
    if (countOfRegentsPassedForRegents >= minRegentsForRegentsAug) {
      calculatedGradPlan = GraduationPlan.REGENTS_4_YEAR_AUG.humanName;
      return calculatedGradPlan;
    }

    // local aug
    if (isSafetyNetEligible && countOfRegentsPassedForLocal >= minRegentsForLocalAug) {
      calculatedGradPlan = GraduationPlan.LOCAL_4_YEAR_AUG.humanName;
      return calculatedGradPlan;
    }

    return calculatedGradPlan;
  }

  /**
   * @param {Object} student
   * @return {String} returns the students calculated grad plan based on
   * regents/credits earned against the grad planning thresholds set by the transfer school
   */
  @depends({
    paths: [
      ...ImStudent.prototype.pathsFor(['isSafetyNetEligible']),
      ...ImStudent.prototype.pathsFor(['isActive']),
      'creditDetails.byArea.total.earned',
      'regentsDetails.numberPassed.of5Regents',
      'regentsDetails.numberPassed.of5Local',
      'isHS',
    ],
    methods: [],
  })

  // FYI this could all be based on .distanceToGrad field coming in from the data
  getCalculatedGradPlanTransfer (student) {
    let calculatedGradPlan = '';
    let totalRegentsPassed;
    let diplomaType;

    // destructured student
    const {
      isHS,
      creditDetails: {
        byArea: {
          total: { earned: totalCreditsEarned },
        },
      },
    } = student;

    // dont calculate grad plans for middle school students
    if (!isHS) return calculatedGradPlan;

    // dont calculate grad plans for non-active students
    const isActive = this.ImStudent.isActive(student);
    if (!isActive) return calculatedGradPlan;

    // protect against null values;
    const regentsPassedAt65 = student.regentsDetails.numberPassed.of5Regents || 0;
    const regentsPassedAt55 = student.regentsDetails.numberPassed.of5Local || 0;

    // safetyNetEligibility
    const isSafetyNetEligible = this.ImStudent.isSafetyNetEligible(student);

    if (isSafetyNetEligible) {
      diplomaType = regentsPassedAt65 >= regentsPassedAt55 ? 'Regents' : 'Local';
      totalRegentsPassed = regentsPassedAt65 >= regentsPassedAt55 ? regentsPassedAt65 : regentsPassedAt55;
    } else {
      diplomaType = 'Regents';
      totalRegentsPassed = regentsPassedAt65;
    }

    // 0-indexed
    const termsUntilGraduation = _.findIndex(_.toArray(TermsToGradMetrics), term => {
      const { regentsReq, creditReq } = term;
      return totalRegentsPassed >= regentsReq && totalCreditsEarned >= creditReq;
    });

    const upcomingGradDates = this.ImSchool.getUpcomingGradDates();

    // find projected term of graduation
    const projectedStudentGradDate = upcomingGradDates[termsUntilGraduation];
    if (projectedStudentGradDate) calculatedGradPlan = `${diplomaType} ${projectedStudentGradDate.humanName}`;

    return calculatedGradPlan;
  }

  /**
   * @param {Object} student
   * @return {Boolean} whether the student's current grad plan is planned 4 year grad
   */
  @depends({
    paths: [...ImStudent.prototype.pathsFor('getCurrentGradPlan'), 'studentDetails.classOf'],
    methods: [],
  })
  getIsPlanned4YearGrad (student) {
    // Return false for middle/elementary school students
    const cohort = student.studentDetails.classOf;
    if (!cohort) return false;

    // Get the students current grad plan
    const currentGradPlan = this.ImStudent.getCurrentGradPlan(student);
    if (!currentGradPlan) return false;

    // Get the grad plan constant
    const gradPlanDetails = _.find(GraduationPlan, { humanName: currentGradPlan });
    if (!gradPlanDetails) return false; // This can be removed once 'with CTE' planned diploma types are removed
    const isPlanned4YearGradPlan = gradPlanDetails.isPlanned4YearGradPlan;

    return isPlanned4YearGradPlan;
  }

  /**
   * @param {Object} student
   * @return {Number}
   */
  @depends({
    paths: [
      ...ImStudent.prototype.pathsFor('isSafetyNetEligible'),
      ...ImStudent.prototype.pathsFor('getCurrentGradPlan'),
      ...ImStudent.prototype.pathsFor('getNumberRegentsPassedBasedOnDiplomaType'),
      'studentDetails.classOf',
      'schoolStatus',
      'att.history',
      'creditDetails.byArea.total.earned',
      'creditDetails.creditGaps.maxGaps',
      'regentsDetails.numberPassed.of5Regents',
      'isHS',
    ],
    methods: ['getIsPlanned4YearGrad'],
  })
  getGradPlanWarnings (student) {
    const gradPlanWarnings = [];

    // return no warnings for middle school and elementary school students
    const isHS = student.isHS;
    if (!isHS) return gradPlanWarnings;

    // Get the students current grad plan
    const cohort = student.studentDetails.classOf;
    const currentGradPlan = this.ImStudent.getCurrentGradPlan(student);
    const gradPlanDetails = _.find(GraduationPlan, { humanName: currentGradPlan });
    if (gradPlanDetails.outcomeCategory === '4 Year March') {
      this.rollbar.info('Non-transfer school user selected 4 Year March diploma for student.');
    }

    // incompleteGradPlan
    const gradPlanIsIncomplete = currentGradPlan === GraduationPlan.PLAN_INCOMPLETE.humanName;
    const gradPlanIsInPast = currentGradPlan === GraduationPlan.PLAN_IN_PAST.humanName;
    if (gradPlanIsIncomplete || gradPlanIsInPast) {
      gradPlanWarnings.push(GraduationPlanWarnings.incompleteGradPlan);
    }

    // localEligiblePlannedForRegents
    const regentsPassedAt65 = student.regentsDetails.numberPassed.of5Regents;
    const plannedForRegentsOrAdvanced =
      gradPlanDetails.diploma === 'Regents' || gradPlanDetails.diploma === 'Advanced Regents';
    const localEligible = this.ImStudent.isSafetyNetEligible(student);

    if (localEligible && plannedForRegentsOrAdvanced && regentsPassedAt65 < 5) {
      gradPlanWarnings.push(GraduationPlanWarnings.localEligiblePlannedForRegents);
    }

    // planned4YearGrad
    // TODO: DS should this be here?
    const isPlanned4YearGrad = this.getIsPlanned4YearGrad(student);
    if (!isPlanned4YearGrad) return gradPlanWarnings;

    // lessThan90AttendanceLastYear
    const priorSyAttendance = getOverallPctPres(student);
    if (typeof priorSyAttendance === 'number' && priorSyAttendance < 0.9) {
      gradPlanWarnings.push(GraduationPlanWarnings.lessThan90AttendanceLastYear);
    }

    // lessThanCreditsRegentsCheck
    const cohortDetails = _.find(Cohort, { humanName: cohort });
    // use student year on cohort for grad planning constant not ImStudent.getStudentYear();
    const studentYear = cohortDetails.studentYear > 4 ? 4 : cohortDetails.studentYear;
    const totalCredits = student.creditDetails.byArea.total.earned;
    const countOfRegentsPassedForDiplomaType = this.ImStudent.getNumberRegentsPassedBasedOnDiplomaType(student);
    const creditAndRegentsWarningThresholds = GraduationPlanWarningsCreditAndRegentsThresholds[studentYear];
    const checkCredits =
      creditAndRegentsWarningThresholds.lessThanCredits &&
      totalCredits < creditAndRegentsWarningThresholds.lessThanCredits;
    const checkRegents =
      creditAndRegentsWarningThresholds.lessThanRegents &&
      countOfRegentsPassedForDiplomaType < creditAndRegentsWarningThresholds.lessThanRegents;

    if (checkCredits || checkRegents) {
      gradPlanWarnings.push(GraduationPlanWarnings.lessThanCreditsRegentsCheck);
    }

    // greaterOrEqualTo2CreditGaps
    const maxCreditGaps = student.creditDetails.creditGaps.maxGaps;
    if (maxCreditGaps >= 2) {
      gradPlanWarnings.push(GraduationPlanWarnings.greaterOrEqualTo2CreditGaps);
    }

    return gradPlanWarnings;
  }

  /**
   * @param {Object} student
   * @return {Number}
   */
  @depends({
    paths: [
      ...ImStudent.prototype.pathsFor('isSafetyNetEligible'),
      ...ImStudent.prototype.pathsFor('getCurrentGradPlanTransfer'),
      ...ImStudent.prototype.pathsFor('getNumberRegentsPassedBasedOnDiplomaType'),
      'studentDetails.classOf',
      'studentDetails.dob',
      'att.history',
      'creditDetails.byArea.total.earned',
      'creditDetails.creditGaps.maxGaps',
      'regentsDetails.numberPassed.of5Regents',
      'isHS',
    ],
    methods: ['getIsPlanned4YearGrad'],
  })
  getGradPlanWarningsTransfer (student) {
    const gradPlanWarnings = [];

    // Return no warnings for middle school and elementary school students
    const isHS = student.isHS;
    if (!isHS) return gradPlanWarnings;

    // Get the students current grad plan
    const cohort = student.studentDetails.classOf;

    // A random set of students have no cohort so we can't really give them warnings (DS)
    if (!cohort) return gradPlanWarnings;

    const currentGradPlan = this.ImStudent.getCurrentGradPlanTransfer(student);
    const gradPlanDetails = _.find(GraduationPlanTransfer, { humanName: currentGradPlan });

    // incompleteGradPlan
    const gradPlanIsIncomplete = currentGradPlan === GraduationPlan.PLAN_INCOMPLETE.humanName;
    const gradPlanIsInPast = currentGradPlan === GraduationPlan.PLAN_IN_PAST.humanName;
    if (gradPlanIsIncomplete || gradPlanIsInPast) {
      gradPlanWarnings.push(GraduationPlanWarnings.incompleteGradPlan);
    }

    // lessThan75AttendanceLastYear
    const priorSyAttendance = getOverallPctPres(student);
    if (typeof priorSyAttendance === 'number' && priorSyAttendance < 0.65) gradPlanWarnings.push(GraduationPlanWarnings.lessThan65AttendanceLastYear);

    // lessThanCreditsRegentsCheck
    const cohortDetails = _.find(Cohort, { humanName: cohort });
    // use student year on cohort for grad planning constant not ImStudent.getStudentYear();
    const studentYear = cohortDetails.studentYear > 4 ? 4 : cohortDetails.studentYear;
    const totalCredits = student.creditDetails.byArea.total.earned;
    const countOfRegentsPassedForDiplomaType = this.ImStudent.getNumberRegentsPassedBasedOnDiplomaType(student);
    const creditAndRegentsWarningThresholds = GraduationPlanWarningsCreditAndRegentsThresholds[studentYear];
    const checkCredits =
      creditAndRegentsWarningThresholds.lessThanCredits &&
      totalCredits < creditAndRegentsWarningThresholds.lessThanCredits;
    const checkRegents =
      creditAndRegentsWarningThresholds.lessThanRegents &&
      countOfRegentsPassedForDiplomaType < creditAndRegentsWarningThresholds.lessThanRegents;

    if (checkCredits || checkRegents) {
      gradPlanWarnings.push(GraduationPlanWarnings.lessThanCreditsRegentsCheck);
    }

    // localEligiblePlannedForRegents
    const regentsPassedAt65 = student.regentsDetails.numberPassed.of5Regents;
    const plannedForRegentsOrAdvanced =
      gradPlanDetails.diploma === 'Regents' || gradPlanDetails.diploma === 'Advanced Regents';
    const localEligible = this.ImStudent.isSafetyNetEligible(student);
    if (localEligible && plannedForRegentsOrAdvanced && regentsPassedAt65 < 5) {
      gradPlanWarnings.push(GraduationPlanWarnings.localEligiblePlannedForRegents);
    }

    // ageOut: 21 years before July 1st ( 3 month window? )
    const {
      studentDetails: { dob },
    } = student;
    const yearEnd = +CurrentSchoolYear.ENDFULL;
    const years = moment(`${yearEnd}-7-1`, 'YYYY-MM-DD').diff(dob, 'years');
    if (years >= 21) gradPlanWarnings.push(GraduationPlanWarnings.ageOut);

    return gradPlanWarnings;
  }

  /**
   * @param {Object} student, {Object} patch
   * @return {Boolean}
   */
  @depends({
    paths: ['gradPlanningDetails.plannedDiplomaType'],
    methods: ['_isLocalPlannedDiplomaTypeValid'],
  })
  hasValidSafetyNetEligibility (
    student,
    patch,
    plannedDiplomaType = student.gradPlanningDetails.plannedDiplomaType,
  ) {
    const plannedDiplomaTypeIsLocal = plannedDiplomaType === 'Local';

    return plannedDiplomaTypeIsLocal
      ? this._isLocalPlannedDiplomaTypeValid(student, plannedDiplomaType, patch)
      : true;
  }

  /**
   * @param {Object} student, {Object} patch
   * @return {Boolean}
   */
  @depends({
    paths: [
      'gradPlanningDetails.plannedDiplomaType',
      'spedDetails.isSped',
      'gradPlanningDetails.schoolVerifiedSafetyNetEligibility',
    ],
    methods: [],
  })
  _isLocalPlannedDiplomaTypeValid (student, plannedDiplomaType, patch) {
    if (plannedDiplomaType !== 'Local') {
      throw new Error(
        'student#_isLocalPlannedDiplomaTypeValid can only be called with a plannedDiplomaType of "Local"',
      );
    } else {
      let isSped;
      let schoolVerifiedSafetyNetEligibility;

      if (patch) {
        isSped = patch.spedDetails && patch.spedDetails.isSped;
        schoolVerifiedSafetyNetEligibility =
          patch.gradPlanningDetails && patch.gradPlanningDetails.schoolVerifiedSafetyNetEligibility;
      }

      if (isSped === undefined) isSped = student.spedDetails.isSped;
      if (schoolVerifiedSafetyNetEligibility === undefined) { schoolVerifiedSafetyNetEligibility = student.gradPlanningDetails.schoolVerifiedSafetyNetEligibility; }

      return !!isSped || !!schoolVerifiedSafetyNetEligibility;
    }
  }
}
