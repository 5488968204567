import { IUser } from '../../../../typings/interfaces/user.interface';
import { IShelterClusterUserListMoreColmnData } from 'Src/ng2/shelter/shelter-network-settings/shelter-network-settings-more-button/shelter-network-settings-more-button.component';
import { BehaviorSubject } from 'rxjs';
import { Direction, TSortDirections } from 'Src/ng2/shared/services/list-services/sort-and-filter.service';

export type TShelterClusterUserModalViewMode = 'CREATE' | 'EDIT' | 'PORTFOLIO' | 'REMOVE';

export const SHELTER_CLUSTER_USER_BTN_CONFIG = {
  CREATE: 'Create',
  EDIT: 'Save',
  NEXT: 'Continue',
  CREATE_LONG: 'Create user',
  EDIT_LONG: 'Save changes',
};

export const SHELTER_CLUSTER_PORTFOLIO_COLUMNS = [
  {
    columnName: 'Name',
    columnOrder: 0,
    graphQlKey: 'SHELTER_NAME',
  },
  {
    columnName: 'Shelter ID',
    columnOrder: 1,
    graphQlKey: 'SHELTER_ID',
  },
  {
    columnName: 'permissions',
    columnDataFormat: 'SHELTER_EDITABLE_DROPDOWN',
    columnOrder: 2,
    graphQlKey: 'SHELTER_PERMISSION',
  },
];

export const SHELTER_CLUSTER_PORTFOLIO_LIST_COMPONENT_CONFIG = {
  searchboxPlaceholder: 'Find a shelter...',
  sortKey$: new BehaviorSubject('SHELTER_NAME'),
  groupingColumnKey: 'SHELTER_PERMISSION',
  sortDirection$: new BehaviorSubject<TSortDirections>(Direction.asc),
  columns: SHELTER_CLUSTER_PORTFOLIO_COLUMNS,
  columnIndexMap: SHELTER_CLUSTER_PORTFOLIO_COLUMNS.reduce((mapping, col: any, i: number) => {
      mapping[col.graphQlKey] = i;
      return mapping;
    }, {}),
  listConfig: {
    emptyTableMessage: 'No shelters at this permission level',
    listType: 'USER_SHELTER_PORTFOLIO',
    noDataMessage: 'No shelters to display',
    sortableColumns: false,
    maximumVisibleRowsPerGroup: 5,
  },
};

export interface IShelterClusterUserIdentifier {
  name: string;
  id: string;
}

export interface IShelterClusterUserModalShellData {
  shelterClusterUserGroupingColumns?: string[];
  clusterId?: string;
  user?: IShelterClusterUserIdentifier;
  mode: TShelterClusterUserModalViewMode;
  currentUser?: IUser;
  hybridClusterUserContext?: { [adminsType: string]: IShelterClusterUserListMoreColmnData['schoolClusterAdmins'] };
}
