<p class="mat-body-2">
  <wizard-filter-actions [data]="totalStudents" [options]="options"></wizard-filter-actions>

  <ng-container [ngPlural]="totalStudents.length">
    <ng-template ngPluralCase="0"> students have </ng-template>
    <ng-template ngPluralCase="1"> student has </ng-template>
    <ng-template ngPluralCase="other"> students have </ng-template>
  </ng-container>

  no preparation for {{ totalExams }} planned exam.
</p>

<div *ngIf="totalStudents.length">
  <span class="mat-subtitle-2">There are three ways to assign students to supports:</span>
  <ol class="mat-caption" flex="95">
    <li>
      <b>Bulk Assign:</b> Hover over numbers in the Planned Regents Exams By Prep Status table and select 'Assign
      students to same support'.
    </li>
    <br />
    <li>
      <b>Assign from the Grid:</b> Hover over numbers in the Planned Regents Exams By Prep Status table and select
      'View in grid'. From the grid you can select specific groups of students to assign to a support.
    </li>
    <br />
    <li>
      <b>Assign from the Student Profile:</b> Hover over numbers in the Planned Regents Exams By Prep Status table
      and select 'View profiles' or click the 'View profiles' buttons the table below.
    </li>
  </ol>
</div>

<p *ngIf="totalStudents.length" class="mat-subtitle-2">Students Needing Prep by Priority</p>

<table *ngIf="totalStudents.length" class="mat-body-2 priority-table">
  <tr *ngFor="let data of organizedPriorityData | keyvalue">
    <td>
      <span class="description"> Priority {{data.key}}: </span>
      <wizard-filter-actions [data]="data.value.studentIds" [options]="options"></wizard-filter-actions>
      <span> {{data.value.priorityLabel}} </span>
    </td>
    <td>
      <button
        mat-raised-button
        class="step-action-button"
        [disabled]="!data.value.studentIds.length"
        (click)="goToStudentProfile(data.value.studentIds)"
      >
        Review {{data.value.studentIds.length}}
      </button>
    </td>
  </tr>
</table>

