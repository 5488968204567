import { TValidSchoolTypes } from 'Src/ng2/shared/typings/interfaces/school.interface';
import { ImSchool } from './../../shared/services/im-models/im-school';
import { EventFormatterService } from './../../shared/services/mixpanel/event-formatter.service';
import { MixpanelService } from 'Src/ng2/shared/services/mixpanel/mixpanel.service';
import { PortalConfig } from './../../shared/services/portal-config';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { take, tap } from 'rxjs/operators';
import { WindowRef } from 'projects/shared/services/window-ref/windowRef';
import { HelpDeskService } from '../../shared/services/help-desk/help-desk.service';
import { ImUser } from '../../shared/services/im-models/im-user';
import { IUser } from '../../shared/typings/interfaces/user.interface';
import { getCurrentUser } from './../../store/selectors/current-user-selectors';
import { Subject } from 'rxjs';
import { INetworkSideNavConfigOptions, ISidebarItem } from 'Src/nvps-libraries/design/nv-sidebar-list/nv-sidebar.interface';
import { PORTAL_TYPES, TPortalLocation } from 'Src/ng2/shared/typings/interfaces/portal.interface';
import { ISmallMenuNavItem, UserMenuOptions, getMiddleSectionSidebarOptions, getTopSectionSidebarOptions, getUserMenuOptions } from './network-left-side-nav.config';
import { CLUSTER_TYPES, TValidClusterTypes } from 'Src/ng2/shared/typings/interfaces/cluster.interface';
import { TValidPartnerTypes } from 'Src/ng2/shared/typings/interfaces/partner.interface';

@Component({
  selector: 'network-left-side-nav',
  templateUrl: './network-left-side-nav.component.html',
  styleUrls: ['./network-left-side-nav.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NetworkLeftSideNavComponent implements OnInit {
  @Input() sideNavOptions: INetworkSideNavConfigOptions;
  public currentUser: IUser;
  public label: string;
  private clusterType: TValidClusterTypes;
  private partnerType: TValidPartnerTypes;
  public selectedView: TPortalLocation;

  // side nav options
  public topSectionSidebarOptions: ISidebarItem[];
  public middleSectionSidebarOptions: ISidebarItem[];
  public userMenuOptions: ISmallMenuNavItem[];

  // selection logic variables
  public selectedSidebarKey: string;
  public activeUrl: string;

  public destroy$: Subject<boolean> = new Subject<boolean>();

  constructor (
    private helpDeskService: HelpDeskService,
    private imUser: ImUser,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<any>,
    private windowRef: WindowRef,
    private portalConfig: PortalConfig,
    private eventFormatterService: EventFormatterService,
    private mixPanelService: MixpanelService,
    private imSchool: ImSchool,
  ) { }

  ngOnInit () {
    this.store
      .pipe(
        select(getCurrentUser),
        tap(currentUser => {
          this.currentUser = currentUser;
          this.label = this.getClusterName();
        }),
        take(1),
      )
      .subscribe();


    const { clusterId } = this.route.snapshot.params;
    const { selectedCategory } = this.route.snapshot.queryParams;
    const { featureSet, schoolTypes, clusterType } = this.sideNavOptions;
    this.clusterType = this.route.snapshot.data.clusterType;
    this.partnerType = this.route.snapshot.data.partnerType;

    this.selectedView = this.clusterType === CLUSTER_TYPES.SCHOOL ? PORTAL_TYPES.NETWORK : PORTAL_TYPES.SHELTER_NETWORK;
    const allSchoolLevels: any[] = schoolTypes.flatMap((level: TValidSchoolTypes) => this.imSchool.getSchoolLevel(level));
    const userSchoolTypes = Array.from(new Set(allSchoolLevels));

    // fetch and set sidenav options
    this.topSectionSidebarOptions = getTopSectionSidebarOptions(featureSet, clusterId, this.route);
    this.userMenuOptions = UserMenuOptions;
    this.middleSectionSidebarOptions = getMiddleSectionSidebarOptions(featureSet, userSchoolTypes, clusterType, clusterId, this.route);

    // determine if User Mangement link is displayed
    const canViewNetworkSettings = this.imUser.canViewNetworkSettings(this.currentUser, this.partnerType);
    this.userMenuOptions = getUserMenuOptions(canViewNetworkSettings);

    this.activeUrl = this.getActiveUrl(this.router.routerState.snapshot.url);
    this.updateSelectedItem([...this.topSectionSidebarOptions, ...this.middleSectionSidebarOptions], { activeUrl: this.activeUrl, selectedCategory });
  }

  public updateSelectedItem (
    menuConfigs: ISidebarItem[],
    { selectedItemKey, activeUrl, selectedCategory }: { selectedItemKey?: string, activeUrl?: string, selectedCategory?: string },
  ): void {
    if (selectedItemKey || selectedCategory) { // find item by key
      const navItem = menuConfigs.find(navItem => {
        if (navItem.key === selectedItemKey || navItem.key === selectedCategory) return true;
        if (navItem.children) return this.updateSelectedItem(navItem.children, { selectedItemKey });
        return false;
      });
      if (!navItem?.children) this.selectedSidebarKey = selectedItemKey || selectedCategory;
    } else { // find item by url
      menuConfigs.find(navItem => {
        // may need to rethink how urls are stored if they become more complex
        const relativeUrl = navItem.url.split('../').join('');
        if (activeUrl === relativeUrl) {
          this.selectedSidebarKey = navItem.key;
          return true;
        }
        if (navItem?.children) return this.updateSelectedItem(navItem.children, { activeUrl });
        return false;
      });
    }
    const human = menuConfigs.find(option => option.key === selectedItemKey)?.human;
    if (human) this.trackLefthandNavEvent(human);
  }

  public goToState (option: ISmallMenuNavItem): void {
    const window = this.windowRef.nativeWindow;
    switch (option.key) {
      case 'networkSettings':
        this.router.navigate(['../settings'], { relativeTo: this.route });
        this.trackLefthandNavEvent('Network Settings');
        break;
      case 'getHelp':
        this.helpDeskService.showHelp();
        this.trackLefthandNavEvent('Get Help');
        break;
      case 'privacyAndTerms':
        window.open(this.portalConfig.publicConfig.PRIVACY_POLICY, '_blank');
        this.trackLefthandNavEvent('Privacy & Terms');
        break;
      default:
        return null;
    }
  }

  private trackLefthandNavEvent (selectedItemKey: string): void {
    const event = this.eventFormatterService.getLefthandNavEvent({
      navItem: selectedItemKey,
      portal: this.selectedView,
    });
    this.mixPanelService.trackEvents([event]);
  }

  private getClusterName (): string {
    return this.currentUser._role_cluster
      ? this.currentUser._role_cluster.clusterName
      : this.route.snapshot.data.cluster.clusterName;
  }

  private getActiveUrl (url: string): string {
    if (!url) return;
    const [urlWithoutParams] = url.split('?');
    const remainingPath = urlWithoutParams.split('/').slice(4);
    const activeUrl = remainingPath.join('/');
    return activeUrl;
  }

  public navigateToHomepage (): void {
    this.router.navigate(['../dashboard'], { relativeTo: this.route });
  }
}
