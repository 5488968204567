import { ICollegePath } from '../../shared/typings/interfaces/paths.interface';
import * as collegePathAction from '../actions/college-path-actions';

export interface ICollegePathState {
  loading: boolean;
  loaded: boolean;
  collegePathEntities: {
    [id: string]: ICollegePath;
  };
}

export const initialState: ICollegePathState = {
  loading: false,
  loaded: false,
  collegePathEntities: {},
};

export function reducer (
  state: ICollegePathState = initialState,
  action: collegePathAction.CollegePathAction,
): ICollegePathState {
  switch (action.type) {
    case collegePathAction.LOAD_COLLEGE_PATH:
      return {
        ...state,
        loading: true,
      };

    case collegePathAction.LOAD_COLLEGE_PATH_SUCCESS: {
      const collegePathEntities = action.payload;

      return {
        ...state,
        loaded: true,
        loading: false,
        collegePathEntities,
      };
    }

    case collegePathAction.LOAD_COLLEGE_PATH_FAIL:
      return { ...state, loaded: false, loading: false };

    default:
      return state;
  }
}
