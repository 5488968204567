import { Injectable } from '@angular/core';

export interface TStudentMapGrowthCalcConfig {
  field: string;
  graphQlKey: string;
  wildcardKey: string;
}

@Injectable()
export class GraphQLStudentMapGrowthHelperService {
  getStudentMapGrowthQuery ({ schoolId, studentId }): string {
    return `
    query ($calcConfig: [StudentMapGrowthCalcConfig!]) {
      StudentMapGrowth(schoolId: "${schoolId}", studentId: "${studentId}", calcConfig: $calcConfig){
        MAP_LANGUAGE_USE_CURRENT_RIT_SCORE
        MAP_READING_CURRENT_RIT_SCORE
        MAP_MATH_CURRENT_RIT_SCORE
        MAP_GROWTH_RIT_SCORE_ALGEBRA_1_CURR_TERM
        MAP_GROWTH_RIT_SCORE_ALGEBRA_2_CURR_TERM
        MAP_GROWTH_RIT_SCORE_GEOMETRY_CURR_TERM
        MAP_HISTORICAL_DATA_TABLE_FOR_PANEL {
          performanceEachAdmin {
            termNameYear
            termNameSeason
            course
            termAndYear
            date
            rit
            achievementLevel
            stateExamPrediction
            domainLevel {
              goalNumber
              goalName
              goalRitScore
              goalAdjective
            }
          }
          progressBetweenAdmin {
            termAndYear
            termBand
            termNameSeason
            course
            termNameYear
            growthQuintile 
            conditionalGrowthPercentile
            initialPercentile {
              cellTooltipGraphQl
              cellValue {
                ... on IntValue {
                  value
                }
              }              
            }
            finalPercentile {
              cellTooltipGraphQl
              cellValue {
                ... on IntValue {
                  value
                }
              }
            }
          }
        }
      }
    }`;
  }
}
