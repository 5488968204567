import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { getCurrentUser } from 'Src/ng2/store';

@Component({
  selector: 'sdc-root-container',
  templateUrl: './sdc-root-container.component.html',
  styleUrls: ['./sdc-root-container.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SdcRootContainer implements OnInit {
  public currentUser;
  public currentState;
  public isFullScreen;
  public sdcKey: string;
  public sdcView: string;
  public header: string;
  public navItems;

  constructor (
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<any>,
  ) {}

  ngOnInit (): void {
    this.initBindingProperties();
  }

  private initBindingProperties () {
    this.store
      .pipe(
        select(getCurrentUser),
        take(1),
      )
      .subscribe(currentUser => {
        this.currentUser = currentUser;
      });
    this.isFullScreen = this.route.snapshot.data.isFullScreen;
    this.sdcKey = this.route.snapshot.data.sdcKey;
    this.header = this.route.snapshot.data.header;
    this.navItems = this.route.snapshot.data.navItems;
    this.sdcView = this.route.snapshot.data.sdcView;
  }
}
