import { ActivatedRoute } from '@angular/router';
import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'school-single-column-layout',
  templateUrl: './school-single-column-layout.component.html',
  styleUrls: ['./school-single-column-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SchoolSingleColumnLayoutComponent {
  public exitState: {url: string};
  public isInMaintenanceMode: boolean;

  constructor (private route: ActivatedRoute) { }

  ngOnInit () {
    this.exitState = this.route.snapshot.data.exitState;
    this.isInMaintenanceMode = this.route.snapshot.data.maintenanceMode;
  }
}
