import { CommonModule } from '@angular/common';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatExpansionModule } from '@angular/material/expansion';
import { NvDesignLibraryModule } from '../../../../../nvps-libraries/design/nv-design-library.module';
import { FilterLogsOptionsComponent } from './filter-logs-options.component';

@NgModule({
  declarations: [FilterLogsOptionsComponent],
  imports: [
    CommonModule,
    MatListModule,
    MatMenuModule,
    MatIconModule,
    MatCardModule,
    MatGridListModule,
    MatExpansionModule,
    NvDesignLibraryModule,
  ],
  exports: [FilterLogsOptionsComponent],
  providers: [],
  schemas: [NO_ERRORS_SCHEMA],
})
export class FiltersLogsOptionsModule {}
