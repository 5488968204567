<mat-form-field [floatLabel]="'never'" class="chromatic-ignore">
  <input
    matInput
    #input
    [value]="calendarDate"
    [matDatepicker]="picker"
    (focus)="openCalendar()"
    autocomplete="off"
    readonly
    [min]="min"
    [max]="max"
    (dateChange)="selectDate($event)"
  />
  <mat-datepicker #picker [opened]="true" startAt="!dateControl.value ? null : min" [calendarHeaderComponent]="nvDatePickerHeader"></mat-datepicker>
</mat-form-field>
