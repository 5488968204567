import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseModalComponent } from '../base-modal.component';
import { IBaseModalData } from './../modals.service'; 

export interface IStudentSelSupportsHistoricalData extends IBaseModalData {
  studentSelSupports: any;
}

@Component({
  selector: 'student-sel-supports-historical-data-modal',
  templateUrl: './student-sel-supports-historical-data-modal.component.html',
  styleUrls: ['./student-sel-supports-historical-data-modal.component.scss'],
})
export class StudentSelSupportsHistoricalDataModalComponent extends BaseModalComponent {
  public title: string = 'Historical Data';
  public isProfileMode: boolean = true;
  public showHistoricalData: boolean = true;
  public studentSelSupports: any;

  constructor(
    dialogRef: MatDialogRef<StudentSelSupportsHistoricalDataModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IStudentSelSupportsHistoricalData,
  ) {
    super(dialogRef);
  }

  ngOnInit (): void {
    const { studentSelSupports } = this.data;
    this.studentSelSupports = studentSelSupports;
  }
}