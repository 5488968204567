import { IStudentPath } from 'Src/ng2/shared/typings/interfaces/studentPaths.interface';
import { DEFAULT_STATUS_OPTION } from './status-dropdown/status-dropdown.component';
import { IDropdownOption } from 'projects/shared/nvps-libraries/design/interfaces/design-library.interface';
import { IPathSubdoc } from './../../../typings/interfaces/studentPaths.interface';
import { Output, EventEmitter, Input, Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { ApiService } from '../../../services/api-service/api-service';
import { IFieldOptionsDict } from '../../../typings/interfaces/studentPaths.interface';
import { TPathCategory } from '../../../typings/types/student-path.types';
import { map } from 'rxjs/operators';
import { cloneDeep, startCase } from 'lodash';
import { IACOption } from 'projects/shared/nvps-libraries/design/nv-textbox/nv-textbox.interface';

export interface IEmitFormPayload {
  studentPath: IPathSubdoc;
  status: string;
}

@Component({
  templateUrl: 'base-student-path-modal-form.component.html',
})

export abstract class BaseStudentPathModalForm {
  @Output() submit: EventEmitter<IEmitFormPayload> = new EventEmitter();
  @Output() close: EventEmitter<Event> = new EventEmitter<Event>();

  @Input() readonly schoolId: string;
  @Input() readonly action: string;
  @Input() readonly studentPaths: IStudentPath[];

  private userCreatedOption$: BehaviorSubject<{ fieldKey: string; option: IACOption }> = new BehaviorSubject(null);
  public autoCompleteOptions$: Observable<IFieldOptionsDict>;
  public selectedStatusOption: IDropdownOption = DEFAULT_STATUS_OPTION;

  public emptyStateIcon = 'add-large-blue';
  public readonly optionsLimit = 20;

  public abstract formGroup: FormGroup;

  constructor (
    private apiService: ApiService,
  ) {}

  abstract getStudentPath (): IPathSubdoc;

  initForm (pathCategory: TPathCategory, schoolId: string): void {
    const apiAcOptions$ = this.apiService.getPostsecPathFieldOptions({ pathCategory, schoolId });

    this.autoCompleteOptions$ = combineLatest([
      apiAcOptions$,
      this.userCreatedOption$,
    ]).pipe(
      map(
        ([apiAcOptions, userCreatedOption]) => {
          const updatedAcOptions = cloneDeep(apiAcOptions);

          if (userCreatedOption) {
            const { fieldKey, option } = userCreatedOption;
            updatedAcOptions[fieldKey].push(option);
          }

          return updatedAcOptions;
        },
      ),
    );
  }

  optionsPredicateCb (option: IACOption, searchValue: string): boolean {
    const sanitizedSearchWordList = searchValue
      ?.trim()
      .toLowerCase()
      .replace(/[^\w]|_/g, ' ')
      .split(/[\s,]+/) || [];

    const { human, tags } = option;
    const tagsAsConcatString = tags?.reduce(
      (acc, { human }) => {
        acc += ` ${human}`;
        return acc;
      }, '',
    );

    const fullPathString = (
      tags
        ? `${human}${tagsAsConcatString}`
        : `${human}`
    ).toLowerCase();

    return sanitizedSearchWordList.every(val => fullPathString.includes(val));
  }

  onClearFieldSelection (fieldKey: string): void {
    this.formGroup.controls[fieldKey].setValue(null);
  }

  onFieldSelect (option: IACOption, fieldKey: string): void {
    this.formGroup.controls[fieldKey].setValue(option.human);
  }

  onSelectStatus (statusOption: IDropdownOption): void {
    this.selectedStatusOption = statusOption;
  }

  onCreateOption (fieldKey: string): void {
    const formVal = this.formGroup.controls[fieldKey].value;
    // Guard against clicking 'add' on an empty state form val
    if (formVal) {
      const titleCasedFormVal = startCase(formVal.toLowerCase());

      const option: IACOption = {
        key: titleCasedFormVal,
        human: titleCasedFormVal,
        tags: null,
      };

      this.userCreatedOption$.next({ fieldKey, option });
      this.formGroup.controls[fieldKey].setValue(titleCasedFormVal);
    }
  }

  onEmitStudentPath (): void {
    const status = this.selectedStatusOption.human;
    const studentPath = this.getStudentPath();
    this.submit.emit({ studentPath, status });
  }

  onEmitCloseModal (): void {
    this.close.emit();
  }
}
