const IREADY_READING = {
  iReadyReadingScoreDateFall: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady'],
    humanName: 'i-Ready Reading: Score Date (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  iReadyReadingScaledScoreFall: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady'],
    humanName: 'i-Ready Reading: Scaled Score (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  iReadyReadingOverallRelativePlacementFall: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady'],
    humanName: 'i-Ready Reading: Overall Relative Placement (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  iReadyReadingOverallPlacementFall: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady'],
    humanName: 'i-Ready Reading: Overall Placement (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  iReadyReadingScoreDateWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady'],
    humanName: 'i-Ready Reading: Score Date (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  iReadyReadingScaledScoreWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady'],
    humanName: 'i-Ready Reading: Scaled Score (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  iReadyReadingOverallRelativePlacementWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady'],
    humanName: 'i-Ready Reading: Overall Relative Placement (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  iReadyReadingOverallPlacementWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady'],
    humanName: 'i-Ready Reading: Overall Placement (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  iReadyReadingScoreDateSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady'],
    humanName: 'i-Ready Reading: Score Date (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  iReadyReadingScaledScoreSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady'],
    humanName: 'i-Ready Reading: Scaled Score (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  iReadyReadingOverallRelativePlacementSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady'],
    humanName: 'i-Ready Reading: Overall Relative Placement (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  iReadyReadingOverallPlacementSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady'],
    humanName: 'i-Ready Reading: Overall Placement (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  iReadyReadingProgressOnTypicalAnnualGrowthFallToWinterProgress: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady'],
    humanName: 'i-Ready Reading: Progress on Typical Annual Growth (Fall to Winter Progress)',
    calculation (doc) {
      return doc;
    },
  },
  iReadyReadingProgressOnStretchAnnualGrowthFallToWinterProgress: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady'],
    humanName: 'i-Ready Reading: Progress on Stretch Annual Growth (Fall to Winter Progress)',
    calculation (doc) {
      return doc;
    },
  },
  iReadyReadingProgressOnTypicalAnnualGrowthFallToSpringProgress: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady'],
    humanName: 'i-Ready Reading: Progress on Typical Annual Growth (Fall to Spring Progress)',
    calculation (doc) {
      return doc;
    },
  },
  iReadyReadingProgressOnStretchAnnualGrowthFallToSpringProgress: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady'],
    humanName: 'i-Ready Reading: Progress on Stretch Annual Growth (Fall to Spring Progress)',
    calculation (doc) {
      return doc;
    },
  },
};

const IREADY_READING_DOMAINS = {
  iReadyReadingDomainsPhonologicalAwarenessScaledScoreSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Reading Domains: Phonological Awareness Scaled Score (Spring)',
    calculation(doc) {
      return doc;
    },
  },

  iReadyReadingDomainsPhonologicalAwarenessOverallPlacementSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Reading Domains: Phonological Awareness Overall Placement (Spring)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyReadingDomainsPhonicsScaledScoreSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Reading Domains: Phonics Scaled Score (Spring)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyReadingDomainsPhonicsOverallPlacementSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Reading Domains: Phonics Overall Placement (Spring)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyReadingDomainsHighFrequencyWordsScaledScoreSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Reading Domains: High-Frequency Words Scaled Score (Spring)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyReadingDomainsHighFrequencyWordsOverallPlacementSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Reading Domains: High-Frequency Words Overall Placement (Spring)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyReadingDomainsVocabularyScaledScoreSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Reading Domains: Vocabulary Scaled Score (Spring)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyReadingDomainsVocabularyOverallPlacementSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Reading Domains: Vocabulary Overall Placement (Spring)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyReadingDomainsComprehensionLiteratureScaledScoreSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Reading Domains: Comprehension - Literature Scaled Score (Spring)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyReadingDomainsComprehensionLiteratureOverallPlacementSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Reading Domains: Comprehension - Literature Overall Placement (Spring)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyReadingDomainsComprehensionInformationalTextScaledScoreSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Reading Domains: Comprehension - Informational Text Scaled Score (Spring)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyReadingDomainsComprehensionInformationalTextOverallPlacementSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Reading Domains: Comprehension - Informational Text Overall Placement (Spring)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyReadingDomainsPhonologicalAwarenessScaledScoreWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Reading Domains: Phonological Awareness Scaled Score (Winter)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyReadingDomainsPhonologicalAwarenessOverallPlacementWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Reading Domains: Phonological Awareness Overall Placement (Winter)',
    calculation(doc) {
      return doc;
    },
  },
   iReadyReadingDomainsPhonicsScaledScoreWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Reading Domains: Phonics Scaled Score (Winter)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyReadingDomainsPhonicsOverallPlacementWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Reading Domains: Phonics Overall Placement (Winter)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyReadingDomainsHighFrequencyWordsScaledScoreWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Reading Domains: High-Frequency Words Scaled Score (Winter)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyReadingDomainsHighFrequencyWordsOverallPlacementWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Reading Domains: High-Frequency Words Overall Placement (Winter)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyReadingDomainsVocabularyScaledScoreWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Reading Domains: Vocabulary Scaled Score (Winter)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyReadingDomainsVocabularyOverallPlacementWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Reading Domains: Vocabulary Overall Placement (Winter)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyReadingDomainsComprehensionLiteratureScaledScoreWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Reading Domains: Comprehension - Literature Scaled Score (Winter)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyReadingDomainsComprehensionLiteratureOverallPlacementWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Reading Domains: Comprehension - Literature Overall Placement (Winter)',
    calculation(doc: any) {
      return doc;
    },
  },
  iReadyReadingDomainsComprehensionInformationalTextScaledScoreWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Reading Domains: Comprehension - Informational Text Scaled Score (Winter)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyReadingDomainsComprehensionInformationalTextOverallPlacementWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Reading Domains: Comprehension - Informational Text Overall Placement (Winter)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyReadingDomainsPhonologicalAwarenessScaledScoreFall: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Reading Domains: Phonological Awareness Scaled Score (Fall)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyReadingDomainsPhonologicalAwarenessOverallPlacementFall: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Reading Domains: Phonological Awareness Overall Placement (Fall)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyReadingDomainsPhonicsScaledScoreFall: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Reading Domains: Phonics Scaled Score (Fall)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyReadingDomainsPhonicsOverallPlacementFall: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Reading Domains: Phonics Overall Placement (Fall)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyReadingDomainsHighFrequencyWordsScaledScoreFall: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Reading Domains: High-Frequency Words Scaled Score (Fall)',
    calculation(doc: any) {
      return doc;
    },
  },
  iReadyReadingDomainsHighFrequencyWordsOverallPlacementFall: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Reading Domains: High-Frequency Words Overall Placement (Fall)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyReadingDomainsVocabularyScaledScoreFall: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Reading Domains: Vocabulary Scaled Score (Fall)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyReadingDomainsVocabularyOverallPlacementFall: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Reading Domains: Vocabulary Overall Placement (Fall)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyReadingDomainsComprehensionLiteratureScaledScoreFall: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Reading Domains: Comprehension - Literature Scaled Score (Fall)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyReadingDomainsComprehensionLiteratureOverallPlacementFall: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Reading Domains: Comprehension - Literature Overall Placement (Fall)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyReadingDomainsComprehensionInformationalTextScaledScoreFall: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Reading Domains: Comprehension - Informational Text Scaled Score (Fall)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyReadingDomainsComprehensionInformationalTextOverallPlacementFall: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Reading Domains: Comprehension - Informational Text Overall Placement (Fall)',
    calculation(doc) {
      return doc;
    },
  },
};

const IREADY_READING_HISTORICAL = {
  oneYearPriorIReadyReadingScoreDateSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'One Year Prior'],
    humanName: 'One Year Prior - i-Ready Reading: Score Date (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  oneYearPriorIReadyReadingScaledScoreSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'One Year Prior'],
    humanName: 'One Year Prior - i-Ready Reading: Scaled Score (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  oneYearPriorIReadyReadingOverallRelativePlacementSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'One Year Prior'],
    humanName: 'One Year Prior - i-Ready Reading: Overall Relative Placement (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  oneYearPriorIReadyReadingOverallPlacementSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'One Year Prior'],
    humanName: 'One Year Prior - i-Ready Reading: Overall Placement (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  oneYearPriorIReadyReadingProgressOnTypicalAnnualGrowthFallToSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'One Year Prior'],
    humanName: 'One Year Prior - i-Ready Reading: Progress on Typical Annual Growth (Fall to Spring)',
    calculation (doc) {
      return doc;
    },
  },
  oneYearPriorIReadyReadingProgressOnStretchAnnualGrowthFallToSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'One Year Prior'],
    humanName: 'One Year Prior - i-Ready Reading: Progress on Stretch Annual Growth (Fall to Spring)',
    calculation (doc) {
      return doc;
    },
  },
  oneYearPriorIReadyReadingScoreDateWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'One Year Prior'],
    humanName: 'One Year Prior - i-Ready Reading: Score Date (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  oneYearPriorIReadyReadingScaledScoreWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'One Year Prior'],
    humanName: 'One Year Prior - i-Ready Reading: Scaled Score (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  oneYearPriorIReadyReadingOverallRelativePlacementWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'One Year Prior'],
    humanName: 'One Year Prior - i-Ready Reading: Overall Relative Placement (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  oneYearPriorIReadyReadingOverallPlacementWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'One Year Prior'],
    humanName: 'One Year Prior - i-Ready Reading: Overall Placement (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  oneYearPriorIReadyReadingProgressOnTypicalAnnualGrowthFallToWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'One Year Prior'],
    humanName: 'One Year Prior - i-Ready Reading: Progress on Typical Annual Growth (Fall to Winter)',
    calculation (doc) {
      return doc;
    },
  },
  oneYearPriorIReadyReadingProgressOnStretchAnnualGrowthFallToWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'One Year Prior'],
    humanName: 'One Year Prior - i-Ready Reading: Progress on Stretch Annual Growth (Fall to Winter)',
    calculation (doc) {
      return doc;
    },
  },
  oneYearPriorIReadyReadingScoreDateFall: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'One Year Prior'],
    humanName: 'One Year Prior - i-Ready Reading: Score Date (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  oneYearPriorIReadyReadingScaledScoreFall: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'One Year Prior'],
    humanName: 'One Year Prior - i-Ready Reading: Scaled Score (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  oneYearPriorIReadyReadingOverallRelativePlacementFall: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'One Year Prior'],
    humanName: 'One Year Prior - i-Ready Reading: Overall Relative Placement (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  oneYearPriorIReadyReadingOverallPlacementFall: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'One Year Prior'],
    humanName: 'One Year Prior - i-Ready Reading: Overall Placement (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  twoYearsPriorIReadyReadingScoreDateSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'Two Years Prior'],
    humanName: 'Two Years Prior - i-Ready Reading: Score Date (Spring)',
    calculation(doc) {
      return doc;
    },
  },
  twoYearsPriorIReadyReadingScaledScoreSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'Two Years Prior'],
    humanName: 'Two Years Prior - i-Ready Reading: Scaled Score (Spring)',
    calculation(doc) {
      return doc;
    },
  },
  twoYearsPriorIReadyReadingOverallRelativePlacementSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'Two Years Prior'],
    humanName: 'Two Years Prior - i-Ready Reading: Overall Relative Placement (Spring)',
    calculation(doc) {
      return doc;
    },
  },
  twoYearsPriorIReadyReadingOverallPlacementSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'Two Years Prior'],
    humanName: 'Two Years Prior - i-Ready Reading: Overall Placement (Spring)',
    calculation(doc) {
      return doc;
    },
  },
  twoYearsPriorIReadyReadingProgressOnTypicalAnnualGrowthFallToSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'Two Years Prior'],
    humanName: 'Two Years Prior - i-Ready Reading: Progress on Typical Annual Growth (Fall to Spring)',
    calculation(doc) {
      return doc;
    },
  },
  twoYearsPriorIReadyReadingProgressOnStretchAnnualGrowthFallToSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'Two Years Prior'],
    humanName: 'Two Years Prior - i-Ready Reading: Progress on Stretch Annual Growth (Fall to Spring)',
    calculation(doc) {
      return doc;
    },
  },
  twoYearsPriorIReadyReadingScoreDateWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'Two Years Prior'],
    humanName: 'Two Years Prior - i-Ready Reading: Score Date (Winter)',
    calculation(doc) {
      return doc;
    },
  },
  twoYearsPriorIReadyReadingScaledScoreWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'Two Years Prior'],
    humanName: 'Two Years Prior - i-Ready Reading: Scaled Score (Winter)',
    calculation(doc) {
      return doc;
    },
  },
  twoYearsPriorIReadyReadingOverallRelativePlacementWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'Two Years Prior'],
    humanName: 'Two Years Prior - i-Ready Reading: Overall Relative Placement (Winter)',
    calculation(doc) {
      return doc;
    },
  },
  twoYearsPriorIReadyReadingOverallPlacementWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'Two Years Prior'],
    humanName: 'Two Years Prior - i-Ready Reading: Overall Placement (Winter)',
    calculation(doc) {
      return doc;
    },
  },
  twoYearsPriorIReadyReadingProgressOnTypicalAnnualGrowthFallToWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'Two Years Prior'],
    humanName: 'Two Years Prior - i-Ready Reading: Progress on Typical Annual Growth (Fall to Winter)',
    calculation(doc) {
      return doc;
    },
  },
  twoYearsPriorIReadyReadingProgressOnStretchAnnualGrowthFallToWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'Two Years Prior'],
    humanName: 'Two Years Prior - i-Ready Reading: Progress on Stretch Annual Growth (Fall to Winter)',
    calculation(doc) {
      return doc;
    },
  },
  twoYearsPriorIReadyReadingScoreDateFall: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'Two Years Prior'],
    humanName: 'Two Years Prior - i-Ready Reading: Score Date (Fall)',
    calculation(doc) {
      return doc;
    },
  },
  twoYearsPriorIReadyReadingScaledScoreFall: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'Two Years Prior'],
    humanName: 'Two Years Prior - i-Ready Reading: Scaled Score (Fall)',
    calculation(doc) {
      return doc;
    },
  },
  twoYearsPriorIReadyReadingOverallRelativePlacementFall: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'Two Years Prior'],
    humanName: 'Two Years Prior - i-Ready Reading: Overall Relative Placement (Fall)',
    calculation(doc) {
      return doc;
    },
  },
  twoYearsPriorIReadyReadingOverallPlacementFall: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'Two Years Prior'],
    humanName: 'Two Years Prior - i-Ready Reading: Overall Placement (Fall)',
    calculation(doc) {
      return doc;
    },
  },
};

const IREADY_MATH = {
  iReadyMathScoreDateFall: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady'],
    humanName: 'i-Ready Math: Score Date (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  iReadyMathScaledScoreFall: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady'],
    humanName: 'i-Ready Math: Scaled Score (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  iReadyMathOverallRelativePlacementFall: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady'],
    humanName: 'i-Ready Math: Overall Relative Placement (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  iReadyMathOverallPlacementFall: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady'],
    humanName: 'i-Ready Math: Overall Placement (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  iReadyMathScoreDateWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady'],
    humanName: 'i-Ready Math: Score Date (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  iReadyMathScaledScoreWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady'],
    humanName: 'i-Ready Math: Scaled Score (Winter)',
    calculation (doc) {
      return doc;
    },
  },

  iReadyMathOverallRelativePlacementWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady'],
    humanName: 'i-Ready Math: Overall Relative Placement (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  iReadyMathOverallPlacementWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady'],
    humanName: 'i-Ready Math: Overall Placement (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  iReadyMathScoreDateSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady'],
    humanName: 'i-Ready Math: Score Date (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  iReadyMathScaledScoreSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady'],
    humanName: 'i-Ready Math: Scaled Score (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  iReadyMathOverallRelativePlacementSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady'],
    humanName: 'i-Ready Math: Overall Relative Placement (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  iReadyMathOverallPlacementSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady'],
    humanName: 'i-Ready Math: Overall Placement (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  iReadyMathProgressOnTypicalAnnualGrowthFallToWinterProgress: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady'],
    humanName: 'i-Ready Math: Progress on Typical Annual Growth (Fall to Winter Progress)',
    calculation (doc) {
      return doc;
    },
  },
  iReadyMathProgressOnStretchAnnualGrowthFallToWinterProgress: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady'],
    humanName: 'i-Ready Math: Progress on Stretch Annual Growth (Fall to Winter Progress)',
    calculation (doc) {
      return doc;
    },
  },
  iReadyMathProgressOnTypicalAnnualGrowthFallToSpringProgress: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady'],
    humanName: 'i-Ready Math: Progress on Typical Annual Growth (Fall to Spring Progress)',
    calculation (doc) {
      return doc;
    },
  },
  iReadyMathProgressOnStretchAnnualGrowthFallToSpringProgress: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady'],
    humanName: 'i-Ready Math: Progress on Stretch Annual Growth (Fall to Spring Progress)',
    calculation (doc) {
      return doc;
    },
  },
};

const IREADY_MATH_DOMAINS = {
  iReadyMathDomainsNumberAndOperationsScaledScoreSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Math Domains: Number and Operations Scaled Score (Spring)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyMathDomainsNumberAndOperationsOverallPlacementSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Math Domains: Number and Operations Overall Placement (Spring)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyMathDomainsAlgebraAndAlgebraicThinkingScaledScoreSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Math Domains: Algebra and Algebraic Thinking Scaled Score (Spring)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyMathDomainsAlgebraAndAlgebraicThinkingOverallPlacementSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Math Domains: Algebra and Algebraic Thinking Overall Placement (Spring)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyMathDomainsMeasurementAndDataScaledScoreSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Math Domains: Measurement And Data Scaled Score (Spring)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyMathDomainsMeasurementAndDataOverallPlacementSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Math Domains: Measurement And Data Overall Placement (Spring)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyMathDomainsGeometryScaledScoreSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Math Domains: Geometry Scaled Score (Spring)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyMathDomainsGeometryOverallPlacementSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domai'],
    humanName: 'i-Ready Math Domains: Geometry Overall Placement (Spring)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyMathDomainsNumberAndOperationsScaledScoreWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Math Domains: Number and Operations Scaled Score (Winter)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyMathDomainsNumberAndOperationsOverallPlacementWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Math Domains: Number and Operations Overall Placement (Winter)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyMathDomainsAlgebraAndAlgebraicThinkingScaledScoreWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Math Domains: Algebra and Algebraic Thinking Scaled Score (Winter)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyMathDomainsAlgebraAndAlgebraicThinkingOverallPlacementWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Math Domains: Algebra and Algebraic Thinking Overall Placement (Winter)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyMathDomainsMeasurementAndDataScaledScoreWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Math Domains: Measurement And Data Scaled Score (Winter)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyMathDomainsMeasurementAndDataOverallPlacementWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Math Domains: Measurement And Data Overall Placement (Winter)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyMathDomainsGeometryScaledScoreWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Math Domains: Geometry Scaled Score (Winter)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyMathDomainsGeometryOverallPlacementWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Math Domains: Geometry Overall Placement (Winter)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyMathDomainsNumberAndOperationsScaledScoreFall: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Math Domains: Number and Operations Scaled Score (Fall)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyMathDomainsNumberAndOperationsOverallPlacementFall: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Math Domains: Number and Operations Overall Placement (Fall)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyMathDomainsAlgebraAndAlgebraicThinkingScaledScoreFall: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Math Domains: Algebra and Algebraic Thinking Scaled Score (Fall)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyMathDomainsAlgebraAndAlgebraicThinkingOverallPlacementFall: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Math Domains: Algebra and Algebraic Thinking Overall Placement (Fall)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyMathDomainsMeasurementAndDataScaledScoreFall: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Math Domains: Measurement And Data Scaled Score (Fall)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyMathDomainsMeasurementAndDataOverallPlacementFall: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Math Domains: Measurement And Data Overall Placement (Fall)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyMathDomainsGeometryScaledScoreFall: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Math Domains: Geometry Scaled Score (Fall)',
    calculation(doc) {
      return doc;
    },
  },
  iReadyMathDomainsGeometryOverallPlacementFall: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'i-Ready Domain'],
    humanName: 'i-Ready Math Domains: Geometry Overall Placement (Fall)',
    calculation(doc) {
      return doc;
    },
  },
};

const IREADY_MATH_HISTORICAL = {
  oneYearPriorIReadyMathScoreDateSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'One Year Prior'],
    humanName: 'One Year Prior - i-Ready Math: Score Date (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  oneYearPriorIReadyMathScaledScoreSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'One Year Prior'],
    humanName: 'One Year Prior - i-Ready Math: Scaled Score (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  oneYearPriorIReadyMathOverallRelativePlacementSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'One Year Prior'],
    humanName: 'One Year Prior - i-Ready Math: Overall Relative Placement (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  oneYearPriorIReadyMathOverallPlacementSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'One Year Prior'],
    humanName: 'One Year Prior - i-Ready Math: Overall Placement (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  oneYearPriorIReadyMathProgressOnTypicalAnnualGrowthFallToSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'One Year Prior'],
    humanName: 'One Year Prior - i-Ready Math: Progress on Typical Annual Growth (Fall to Spring)',
    calculation (doc) {
      return doc;
    },
  },
  oneYearPriorIReadyMathProgressOnStretchAnnualGrowthFallToSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'One Year Prior'],
    humanName: 'One Year Prior - i-Ready Math: Progress on Stretch Annual Growth (Fall to Spring)',
    calculation (doc) {
      return doc;
    },
  },
  oneYearPriorIReadyMathScoreDateWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'One Year Prior'],
    humanName: 'One Year Prior - i-Ready Math: Score Date (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  oneYearPriorIReadyMathScaledScoreWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'One Year Prior'],
    humanName: 'One Year Prior - i-Ready Math: Scaled Score (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  oneYearPriorIReadyMathOverallRelativePlacementWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'One Year Prior'],
    humanName: 'One Year Prior - i-Ready Math: Overall Relative Placement (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  oneYearPriorIReadyMathOverallPlacementWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'One Year Prior'],
    humanName: 'One Year Prior - i-Ready Math: Overall Placement (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  oneYearPriorIReadyMathProgressOnTypicalAnnualGrowthFallToWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'One Year Prior'],
    humanName: 'One Year Prior - i-Ready Math: Progress on Typical Annual Growth (Fall to Winter)',
    calculation (doc) {
      return doc;
    },
  },
  oneYearPriorIReadyMathProgressOnStretchAnnualGrowthFallToWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'One Year Prior'],
    humanName: 'One Year Prior - i-Ready Math: Progress on Stretch Annual Growth (Fall to Winter)',
    calculation (doc) {
      return doc;
    },
  },
  oneYearPriorIReadyMathScoreDateFall: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'One Year Prior'],
    humanName: 'One Year Prior - i-Ready Math: Score Date (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  oneYearPriorIReadyMathScaledScoreFall: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'One Year Prior'],
    humanName: 'One Year Prior - i-Ready Math: Scaled Score (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  oneYearPriorIReadyMathOverallRelativePlacementFall: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'One Year Prior'],
    humanName: 'One Year Prior - i-Ready Math: Overall Relative Placement (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  oneYearPriorIReadyMathOverallPlacementFall: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'One Year Prior'],
    humanName: 'One Year Prior - i-Ready Math: Overall Placement (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  twoYearsPriorIReadyMathScoreDateSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'Two Years Prior'],
    humanName: 'Two Years Prior - i-Ready Math: Score Date (Spring)',
    calculation(doc) {
      return doc;
    },
  },
  twoYearsPriorIReadyMathScaledScoreSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'Two Years Prior'],
    humanName: 'Two Years Prior - i-Ready Math: Scaled Score (Spring)',
    calculation(doc) {
      return doc;
    },
  },
  twoYearsPriorIReadyMathOverallRelativePlacementSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'Two Years Prior'],
    humanName: 'Two Years Prior - i-Ready Math: Overall Relative Placement (Spring)',
    calculation(doc) {
      return doc;
    },
  },
  twoYearsPriorIReadyMathOverallPlacementSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'Two Years Prior'],
    humanName: 'Two Years Prior - i-Ready Math: Overall Placement (Spring)',
    calculation(doc) {
      return doc;
    },
  },
  twoYearsPriorIReadyMathProgressOnTypicalAnnualGrowthFallToSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'Two Years Prior'],
    humanName: 'Two Years Prior - i-Ready Math: Progress on Typical Annual Growth (Fall to Spring)',
    calculation(doc) {
      return doc;
    },
  },
  twoYearsPriorIReadyMathProgressOnStretchAnnualGrowthFallToSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'Two Years Prior'],
    humanName: 'Two Years Prior - i-Ready Math: Progress on Stretch Annual Growth (Fall to Spring)',
    calculation(doc) {
      return doc;
    },
  },
  twoYearsPriorIReadyMathScoreDateWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'Two Years Prior'],
    humanName: 'Two Years Prior - i-Ready Math: Score Date (Winter)',
    calculation(doc) {
      return doc;
    },
  },
  twoYearsPriorIReadyMathScaledScoreWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'Two Years Prior'],
    humanName: 'Two Years Prior - i-Ready Math: Scaled Score (Winter)',
    calculation(doc) {
      return doc;
    },
  },
  twoYearsPriorIReadyMathOverallRelativePlacementWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'Two Years Prior'],
    humanName: 'Two Years Prior - i-Ready Math: Overall Relative Placement (Winter)',
    calculation(doc) {
      return doc;
    },
  },
  twoYearsPriorIReadyMathOverallPlacementWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'Two Years Prior'],
    humanName: 'Two Years Prior - i-Ready Math: Overall Placement (Winter)',
    calculation(doc) {
      return doc;
    },
  },
  twoYearsPriorIReadyMathProgressOnTypicalAnnualGrowthFallToWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'Two Years Prior'],
    humanName: 'Two Years Prior - i-Ready Math: Progress on Typical Annual Growth (Fall to Winter)',
    calculation(doc) {
      return doc;
    },
  },
  twoYearsPriorIReadyMathProgressOnStretchAnnualGrowthFallToWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'Two Years Prior'],
    humanName: 'Two Years Prior - i-Ready Math: Progress on Stretch Annual Growth (Fall to Winter)',
    calculation(doc) {
      return doc;
    },
  },
  twoYearsPriorIReadyMathScoreDateFall: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'Two Years Prior'],
    humanName: 'Two Years Prior - i-Ready Math: Score Date (Fall)',
    calculation(doc) {
      return doc;
    },
  },
  twoYearsPriorIReadyMathScaledScoreFall: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'Two Years Prior'],
    humanName: 'Two Years Prior - i-Ready Math: Scaled Score (Fall)',
    calculation(doc) {
      return doc;
    },
  },
  twoYearsPriorIReadyMathOverallRelativePlacementFall: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'Two Years Prior'],
    humanName: 'Two Years Prior - i-Ready Math: Overall Relative Placement (Fall)',
    calculation(doc) {
      return doc;
    },
  },
  twoYearsPriorIReadyMathOverallPlacementFall: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'iReady', 'Two Years Prior'],
    humanName: 'Two Years Prior - i-Ready Math: Overall Placement (Fall)',
    calculation(doc) {
      return doc;
    },
  },
};

const IREADY_LEXILE = {
  iReadyLexileMaxDateFall: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'Lexile'],
    humanName: 'i-Ready Lexile: Max Date (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  iReadyLexileMaxScoreFall: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'Lexile'],
    humanName: 'i-Ready Lexile: Max Score (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  iReadyLexileMaxDateWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'Lexile'],
    humanName: 'i-Ready Lexile: Max Date (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  iReadyLexileMaxScoreWinter: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'Lexile'],
    humanName: 'i-Ready Lexile: Max Score (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  iReadyLexileMaxDateSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'Lexile'],
    humanName: 'i-Ready Lexile: Max Date (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  iReadyLexileMaxScoreSpring: {
    tag: 'i-Ready',
    tags: ['i-Ready', 'Lexile'],
    humanName: 'i-Ready Lexile: Max Score (Spring)',
    calculation (doc) {
      return doc;
    },
  },
};

export const IREADY_FLATTENED_STUDENT_MAP = {
  ...IREADY_READING,
  ...IREADY_MATH,
  ...IREADY_LEXILE,
  ...IREADY_READING_HISTORICAL,
  ...IREADY_READING_DOMAINS,
  ...IREADY_MATH_HISTORICAL,
  ...IREADY_MATH_DOMAINS,
};
