import * as patchProcessingActions from '../actions/patch-processing-actions';

export interface IPatchProcessing {
  patchProcessing: boolean;
}

export const initialState: IPatchProcessing = {
  patchProcessing: false,
};

export function reducer(
  state: IPatchProcessing = initialState,
  action: patchProcessingActions.PatchProcessingAction,
): IPatchProcessing {
  switch (action.type) {
    case patchProcessingActions.LOAD_PATCH_PROCESSING: {
      return { patchProcessing: state.patchProcessing };
    }

    case patchProcessingActions.UPDATE_PATCH_PROCESSING: {
      return Object.assign({}, { patchProcessing: !state.patchProcessing });
    }
    default:
      return state;
  }
}
