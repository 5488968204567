import { ModalsService } from './../../../modals/modals.service';
import { forEach } from 'lodash';

import { Component, Input, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { IRowData, IColumn} from '../../../models/list-models';
import { toKebabFromPascal } from 'projects/shared/nvps-libraries/design/utilities/helpers';
import { IHistoryModalData } from 'Src/ng2/shared/modals/history-all/history-all-modal.component';
import { TASK_NOTES_ACTIVITY_TOGGLE_INDEX } from '../../tasks-notes-activity-table/tasks-notes-activity-table.component';
import { PartnerTypes, TValidPartnerTypes } from 'Src/ng2/shared/typings/interfaces/partner.interface';

@Component({
  selector: 'app-icon-cell',
  templateUrl: './icon-cell-renderer.component.html',
  styleUrls: ['./icon-cell-renderer.component.scss'],
})

export class IconCellRenderer implements ICellRendererAngularComp, OnInit {
  @Input() input: { 
    row: IRowData[]; column: IRowData; dynamicComponentInputData?: any 
  };
  public displayValue: string;
  public icon: String;
  protected field: String;
  protected params: ICellRendererParams;
  protected data: IHistoryModalData;
  public contextPartnerType: TValidPartnerTypes;

  constructor (
    private modalsService: ModalsService,
  ) {}

  //agInit is used by the grid cell renderer pattern
  public agInit(params: ICellRendererParams): void {
    this.params = params;
    this.field = params?.colDef?.field;
    this.contextPartnerType = this.params.context.parentComponent.contextPartnerType;

    if(params.value){
      this.displayValue = `${params.value}`;
    } else {
      this.displayValue = "";
    }

    const cellParams = this.params?.colDef?.cellRendererParams?.params;
    const [key, value] = cellParams?.split(":");
    if (key.toLowerCase() === 'iconname') {  
         const iconName = value.trim();
         this.setIcon(iconName);
    }
  }

  //ngOnInit is used by the list dynamic component pattern
  public ngOnInit(): void {
    if (this.input && this.input.column && this.input.column.data) {
      this.displayValue = this.input.column.data.toString();
      this.setIcon(this.input.column.data);
    }
  }

  protected setIcon(iconName: string): void {
    this.icon = toKebabFromPascal(iconName);
  }

  public refresh(params: ICellRendererParams): boolean {
    return false;
  }

  public openModal (): void {
    if(this.field.toUpperCase() === "HISTORY_LOG") {
      const studentData = this.params.data;
      const isShelter = this.contextPartnerType === PartnerTypes.SHELTER;
      const contextPartnerId = this.params.context.parentComponent.contextPartnerId;
      if (isShelter) {
        const modalData: IHistoryModalData = {
          studentData,
          shelterId: contextPartnerId,
          listType: 'SHELTER_DATA_GRID',
        };

        this.modalsService
          .openHistoryModal(modalData)
          .afterClosed();
      } else {
        // DATA-GRID data
        const { contextPartnerType, school, currentUser } = this.params.context.parentComponent;
        // Row Data for the student: OSIS_NUMBER, STUDENT_NAME
        const studentData = this.params.data;
        const tasksNotesActivityModalData = {
          partnerType: contextPartnerType,
          listType: 'SCHOOL_DATA_GRID',
          currentSchool: school,
          studentData,
          currentUser,
          segmentedControlIndex: TASK_NOTES_ACTIVITY_TOGGLE_INDEX.NOTES,
        };

        this.modalsService
          .openTasksNotesActivityModal(tasksNotesActivityModalData)
          .afterClosed();
      }
    }
  }
}
