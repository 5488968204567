import { NvFormFieldModule } from './../../../../../../../projects/shared/nvps-libraries/design/nv-form-field/nv-form-field.module';
import { NvDateRangePickerModule } from './../../../../../../nvps-libraries/design/nv-date-range-picker/nv-date-range-picker.module';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { BaseModalModule } from '../../../base-modal.module';
import { ExperienceDetailsModalComponent } from './experience-details-modal.component';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  declarations: [ExperienceDetailsModalComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    BaseModalModule,
    NvFormFieldModule,
    NvDateRangePickerModule,
    MatCheckboxModule,
  ],
  exports: [ExperienceDetailsModalComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ExperienceDetailsModalModule {}
