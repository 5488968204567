import { ApiService } from './../../../shared/services/api-service/api-service';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

@Injectable()
export class SchoolResourceResolver implements Resolve<any> {
  constructor (
    private ApiService: ApiService
  ) {}

  resolve (route: ActivatedRouteSnapshot) {
    const { schoolId } = route.params;
    return this.ApiService.getSchool(schoolId);
  }
}
