<header class="sidebar-header">
  <nv-logo (click)="navigateToHomepage()" [pointerOnHover]="true" class="logo" type="portal"></nv-logo>
  <portal-switcher [currentUser]="currentUser" [selectedView]="selectedView" [label]="label"></portal-switcher>
</header>
<div class="side-content-wrap">
  <nv-sidebar-list
    [listData]="topSectionSidebarOptions"
    [selectedKey]="selectedSidebarKey"
    (sidebarListItemSelect)="updateSelectedItem(topSectionSidebarOptions, { selectedItemKey: $event })"
  ></nv-sidebar-list>
  <nv-sidebar-list
    [listData]="middleSectionSidebarOptions"
    [selectedKey]="selectedSidebarKey"
    (sidebarListItemSelect)="updateSelectedItem(middleSectionSidebarOptions, { selectedItemKey: $event })"
  ></nv-sidebar-list>
  <nav-content class="nv-sidebar-nav-menu-small">
    <item-list class="nv-sidebar-list-item nv-sidebar-list-item-small" *ngFor="let option of userMenuOptions">
      <a class="unformatted-link" *ngIf="option.key === 'privacyAndTerms'" href="{{portalConfig.publicConfig.PRIVACY_POLICY}}" target="_blank">
        <label for="{{ option.humanName }}"> {{ option.humanName }} </label>
      </a>
      <label *ngIf="option.key !== 'privacyAndTerms'" for="{{ option.humanName }}" (click)="goToState(option)"> {{ option.humanName }} </label>
      <span id="{{ option.humanName }}"></span>
    </item-list>
    <item-list class="nv-sidebar-list-item nv-sidebar-list-item-small"> <nvps-log-out portalType="NETWORK"></nvps-log-out> </item-list>
  </nav-content>
</div>
<iframe id="logout-frame" class="hide"></iframe>
