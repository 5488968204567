export const NEXT_TERM_PLANNING_NEW_DATA_GRID_COLUMNS = {
  TRANSFER_HS: [
    'OSIS_NUMBER',
    'STUDENT_NAME',
    'CLASS',
    'STATUS',
    'SENIOR_FLAG',
    'ADMIT_DATE',
    'DISCHARGE_CODE',
    'DISCHARGE_DATE',
    'GRADE',
    'OFFICIAL_CLASS',
    'ATS_COUNSELOR_DETAILS',
    'ADVISOR_DETAILS',
    'IS_SPED',
    'IS_ELL',
    'HOME_LANGUAGE',
    'CURR_YTD_ATT',
    'PLANNED_GRAD_DATE',
    'NON_TRANSFER_GRADUATION_PLAN',
    'CURRENT_COURSES',
    'TOTAL_CREDS_EARNED',
    'SUBJECTS_WITH_CREDIT_GAPS',
    'MAX_CREDIT_GAPS',
    'MAX_UNADDRESSED_CREDIT_GAPS',
    'COURSES_POTENTIALLY_ELIGIBLE_FOR_CREDIT_RECOVERY',
    'COURSES_WITH_ISSUES_ON_CURR_PROGRAM',
    'COUNT_OF_PENDING_PROGRAM_CHANGES',
    'PENDING_ADDS_FOR_CURRENT_TERM',
    'OTHER_COURSE_PLANS_FOR_CURRENT_TERM',
    'PENDING_DROPS_FOR_CURRENT_TERM',
    'COURSE_PLANS_FOR_FUTURE_TERM',
    'HAS_ISSUES_ON_CURR_PROGRAM',
    'HAS_ISSUES_ON_TRANSCRIPT',
    'HAS_COURSE_PLANS_BEYOND_GRAD_DATE',
    'COUNT_REGENTS_FULFILLED_FOR_PLANNED_DIP',
    'COUNT_OF_REGENTS_FULFILLED_FOR_GRADUATION',
    'COUNT_OF_REGENTS_FULFILLED_FOR_REGENTS_DIP',
    'COUNT_OF_REGENTS_FULFILLED_FOR_LOCAL_DIP',
    'COUNT_OF_REGENTS_FULFILLED_FOR_ADV_DIP',
    'TOTAL_44REQ_CREDIT_GAPS',
    'TOTAL_44REQ_NET_CREDIT_GAPS',
    'ELA_CORE_CREDIT_GAPS',
    'ELA_CORE_NET_CREDIT_GAPS',
    'MATH_CREDIT_GAPS',
    'MATH_NET_CREDIT_GAPS',
    'SS_GLOBAL_CREDIT_GAPS',
    'SS_GLOBAL_NET_CREDIT_GAPS',
    'SS_US_CREDIT_GAPS',
    'SS_US_NET_CREDIT_GAPS',
    'SS_GOVT_ECON_CREDIT_GAPS',
    'SS_GOVT_ECON_NET_CREDIT_GAPS',
    'SCI_TOTAL_CREDIT_GAPS',
    'SCI_TOTAL_NET_CREDIT_GAPS',
    'SCI_PHYSICAL_CREDIT_GAPS',
    'SCI_PHYSICAL_NET_CREDIT_GAPS',
    'SCI_LIFE_CREDIT_GAPS',
    'SCI_LIFE_NET_CREDIT_GAPS',
    'LOTE_CREDIT_GAPS',
    'LOTE_NET_CREDIT_GAPS',
    'CTE_CREDIT_GAPS',
    'CTE_NET_CREDIT_GAPS',
    'ART_CREDIT_GAPS',
    'ART_NET_CREDIT_GAPS',
    'PE_CREDIT_GAPS',
    'PE_NET_CREDIT_GAPS',
    'HEALTH_CREDIT_GAPS',
    'HEALTH_NET_CREDIT_GAPS',
  ],
  NON_TRANSFER_HS: [
    'OSIS_NUMBER',
    'STUDENT_NAME',
    'CLASS',
    'STATUS',
    'ADMIT_DATE',
    'DISCHARGE_CODE',
    'DISCHARGE_DATE',
    'GRADE',
    'OFFICIAL_CLASS',
    'ATS_COUNSELOR_DETAILS',
    'ADVISOR_DETAILS',
    'IS_SPED',
    'IS_ELL',
    'HOME_LANGUAGE',
    'CURR_YTD_ATT',
    'PLANNED_GRAD_DATE',
    'NON_TRANSFER_GRADUATION_PLAN',
    'CURRENT_COURSES',
    'TOTAL_CREDS_EARNED',
    'SUBJECTS_WITH_CREDIT_GAPS',
    'MAX_CREDIT_GAPS',
    'MAX_UNADDRESSED_CREDIT_GAPS',
    'COURSES_POTENTIALLY_ELIGIBLE_FOR_CREDIT_RECOVERY',
    'COURSES_WITH_ISSUES_ON_CURR_PROGRAM',
    'COUNT_OF_PENDING_PROGRAM_CHANGES',
    'PENDING_ADDS_FOR_CURRENT_TERM',
    'OTHER_COURSE_PLANS_FOR_CURRENT_TERM',
    'PENDING_DROPS_FOR_CURRENT_TERM',
    'COURSE_PLANS_FOR_FUTURE_TERM',
    'HAS_ISSUES_ON_CURR_PROGRAM',
    'HAS_ISSUES_ON_TRANSCRIPT',
    'HAS_COURSE_PLANS_BEYOND_GRAD_DATE',
    'COUNT_REGENTS_FULFILLED_FOR_PLANNED_DIP',
    'COUNT_OF_REGENTS_FULFILLED_FOR_GRADUATION',
    'COUNT_OF_REGENTS_FULFILLED_FOR_REGENTS_DIP',
    'COUNT_OF_REGENTS_FULFILLED_FOR_LOCAL_DIP',
    'COUNT_OF_REGENTS_FULFILLED_FOR_ADV_DIP',
    'TOTAL_44REQ_CREDIT_GAPS',
    'TOTAL_44REQ_NET_CREDIT_GAPS',
    'ELA_CORE_CREDIT_GAPS',
    'ELA_CORE_NET_CREDIT_GAPS',
    'MATH_CREDIT_GAPS',
    'MATH_NET_CREDIT_GAPS',
    'SS_GLOBAL_CREDIT_GAPS',
    'SS_GLOBAL_NET_CREDIT_GAPS',
    'SS_US_CREDIT_GAPS',
    'SS_US_NET_CREDIT_GAPS',
    'SS_GOVT_ECON_CREDIT_GAPS',
    'SS_GOVT_ECON_NET_CREDIT_GAPS',
    'SCI_TOTAL_CREDIT_GAPS',
    'SCI_TOTAL_NET_CREDIT_GAPS',
    'SCI_PHYSICAL_CREDIT_GAPS',
    'SCI_PHYSICAL_NET_CREDIT_GAPS',
    'SCI_LIFE_CREDIT_GAPS',
    'SCI_LIFE_NET_CREDIT_GAPS',
    'LOTE_CREDIT_GAPS',
    'LOTE_NET_CREDIT_GAPS',
    'CTE_CREDIT_GAPS',
    'CTE_NET_CREDIT_GAPS',
    'ART_CREDIT_GAPS',
    'ART_NET_CREDIT_GAPS',
    'PE_CREDIT_GAPS',
    'PE_NET_CREDIT_GAPS',
    'HEALTH_CREDIT_GAPS',
    'HEALTH_NET_CREDIT_GAPS',
  ],
};
