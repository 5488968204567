// tslint:disable:max-classes-per-file
import { Action } from '@ngrx/store';
import { ICurrentGrouping, IStateGoOpts } from '../../shared/services/prev-state/prev-state.service';
export const UPDATE_PREVIOUS_STATES = '[Portal] Update Previous States';
export const REMOVE_PREVIOUS_STATE = '[Portal] Remove Previous State';
export const SET_GROUPING = '[Portal] Set Grouping';

// register store actions
export class UpdatePreviousStates implements Action {
  readonly type = UPDATE_PREVIOUS_STATES;
  constructor(public payload: IStateGoOpts) {}
}

export class RemovePreviousState implements Action {
  readonly type = REMOVE_PREVIOUS_STATE;
  constructor() {
    //
  }
}

export class SetGrouping implements Action {
  readonly type = SET_GROUPING;
  constructor(public payload: ICurrentGrouping) {}
}

// action types
export type PreviousStatesAction = SetGrouping | UpdatePreviousStates | RemovePreviousState;
