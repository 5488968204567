<div [formGroup]="inputGroup" class="batch-edit-modal-input-container">
  <!-- STRING -->
  <ng-container *ngIf="isInput && !hasSearchableAcOptions">
    <nv-textbox
      [textControl]="inputGroup.controls.textarea"
    ></nv-textbox>
  </ng-container>

  <ng-container *ngIf="isInput && hasSearchableAcOptions">
    <nv-textbox
      [textControl]="inputGroup.controls.textarea"
      [autocompleteOptions]="acOptions"
      restrictToOptions="true"
      hasClearBtn="true"
      (selectOption)="onAcSelection($event)"
      (clearValue)="clearAcSelection()"
    ></nv-textbox>
  </ng-container>

  <!-- BOOLEAN_YES_NO, USER_MINI, and ENUM -->
  <ng-container *ngIf="isDropdown">
    <!-- TODO : Enable tags for dropdown options -->
    <nv-dropdown 
      placeholder="—"
      [selected]="null"
      [options]="dropdownItems.items"
      [returnObject]="true"
      [customClass]="'batch-edit-modal-input-menu'"
      (selectOption)="onSelection($event)"
    ></nv-dropdown>
  </ng-container>

  <!-- Milestones (radio options) -->
  <ng-container *ngIf="isRadio">
    <mat-radio-group class="nv-mat-radio-group" layout="row">
      <mat-radio-button *ngFor="let item of radioItems" [value]="item" (change)="onRadioChange(item)">{{
        item
      }}</mat-radio-button>
    </mat-radio-group>
  </ng-container>
</div>
