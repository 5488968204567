import { MatButtonModule } from '@angular/material/button';
import { WizardFilterActionsModule } from '../../../../wizard-filter-actions/wizard-filter-actions.module';
import { CommonModule } from '@angular/common';
import { CompleteGradPlanningStep } from './complete-grad-planning-step.component';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [CompleteGradPlanningStep],
  imports: [CommonModule, WizardFilterActionsModule, MatButtonModule],
  exports: [CompleteGradPlanningStep],
})
export class CompleteGradPlanningStepModule {
  //
}
