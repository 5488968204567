import { reduce } from 'lodash';
import { IPartnerOrg } from './../../shared/typings/interfaces/partner-org.interface';
import * as partnerOrgsActions from './../actions/partner-orgs-actions';

export interface IPartnerOrgsState {
  loading: boolean;
  loaded: boolean;
  partnerOrgsEntities: {
    [id: string]: IPartnerOrg;
  };
}

export const initialState: IPartnerOrgsState = {
  loading: false,
  loaded: false,
  partnerOrgsEntities: {},
};

export function reducer(
  state: IPartnerOrgsState = initialState,
  action: partnerOrgsActions.PartnerOrgsActions,
): IPartnerOrgsState {
  let partnerOrg;
  let partnerOrgsEntities;
  switch (action.type) {
    case partnerOrgsActions.LOAD_PARTNER_ORGS:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case partnerOrgsActions.LOAD_PARTNER_ORGS_SUCCESS:
      const partnerOrgs = action.payload.data.PartnerOrgs;
      const loadedPartnerOrgs: IPartnerOrgsState['partnerOrgsEntities'] = reduce(
        partnerOrgs,
        (acc, partnerOrg: IPartnerOrg) => {
          acc[partnerOrg._id] = partnerOrg;

          return acc;
        },
        {},
      );
      const mergedPartnerOrgs = { ...state.partnerOrgsEntities, ...loadedPartnerOrgs };
      return {
        ...state,
        loading: false,
        loaded: true,
        partnerOrgsEntities: mergedPartnerOrgs,
      };
    case partnerOrgsActions.LOAD_PARTNER_ORGS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case partnerOrgsActions.UPDATE_PARTNER_ORG_SUCCESS:
      const { partnerOrgId, patch } = action.payload;
      partnerOrgsEntities = Object.assign({}, state.partnerOrgsEntities);
      partnerOrg = partnerOrgsEntities[partnerOrgId];
      const updatedPartnerOrg = Object.assign(partnerOrg, patch);
      partnerOrgsEntities[partnerOrgId] = updatedPartnerOrg;

      return {
        ...state,
        partnerOrgsEntities,
      };
    case partnerOrgsActions.CREATE_PARTNER_ORG_SUCCESS:
      partnerOrg = action.payload.data.createPartnerOrg;
      partnerOrgsEntities = Object.assign({}, state.partnerOrgsEntities);
      partnerOrgsEntities[partnerOrg._id] = partnerOrg;

      return {
        ...state,
        partnerOrgsEntities,
      };
    default:
      return state;
  }
}
