import { Component, Output, ViewEncapsulation, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'submit-button',
  templateUrl: './submit-button.component.html',
  encapsulation: ViewEncapsulation.None,
})

export class SubmitButton {
  @Input() isDisabled: boolean;
  @Input() confirmationButtonLabel: string;

  @Output() handleClick: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() handleClose: EventEmitter<Event> = new EventEmitter<Event>();

  constructor () {};

  onClick (): void {
    this.handleClick.emit();
  }

  close (): void {
    this.handleClose.emit();
  }
}
