/* tslint:disable */

import { Action } from '@ngrx/store';
import { IStudentAssessment } from '../../../shared/typings/interfaces/student-assessment.interface';

export const LOAD_STUDENT_ASSESSMENTS = '[Portal] Load StudentAssessments';
export const LOAD_STUDENT_ASSESSMENTS_SUCCESS = '[Portal] Load StudentAssessments Success';
export const LOAD_STUDENT_ASSESSMENTS_FAIL = '[Portal] Load StudentAssessments Failure';

export const UPDATE_STUDENT_ASSESSMENT_SUCCESS = '[Portal] Update StudentAssessment Success';

export const BULK_UPDATE_STUDENT_ASSESSMENTS = '[Portal] Bulk Update StudentAssessments';
export const BULK_UPDATE_STUDENT_ASSESSMENTS_SUCCESS = '[Portal] Bulk Update StudentAssessments Success';
export const BULK_UPDATE_STUDENT_ASSESSMENTS_FAIL = '[Portal] Bulk Update StudentAssessments Failure';

export interface ILoadStudentAssessmentsPayload {
  schoolId: string;
  schoolYear?: string;
  where?: string;
  status?: string;
}

export interface IStudentAssessmentItem {
  section: string;
  value?: string;
}

export interface IStudentAssessmentUpdateOption {
  schoolAssessmentId: string;
  studentAssessmentId: string;
  items?: IStudentAssessmentItem[];
  assessedOn?: string;
  status?: string;
}

export interface IBulkUpdateStudentAssessmentsPayload {
  payload: IStudentAssessmentUpdateOption[];
}

// register store actions
export class LoadStudentAssessments implements Action {
  readonly type = LOAD_STUDENT_ASSESSMENTS;
  constructor(public payload: ILoadStudentAssessmentsPayload) {}
}

export class LoadStudentAssessmentsSuccess implements Action {
  readonly type = LOAD_STUDENT_ASSESSMENTS_SUCCESS;
  constructor(public payload: { data: { StudentAssessments: IStudentAssessment[] } }) {}
}

export class BulkCreateStudentAssessmentsSuccess implements Action {
  readonly type = LOAD_STUDENT_ASSESSMENTS_SUCCESS;
  constructor(public payload: any) {}
}

export class LoadStudentAssessmentsFail implements Action {
  readonly type = LOAD_STUDENT_ASSESSMENTS_FAIL;
  constructor(public payload: any) {}
}

export class BulkUpdateStudentAssessments implements Action {
  readonly type = BULK_UPDATE_STUDENT_ASSESSMENTS;
  constructor(public payload: IBulkUpdateStudentAssessmentsPayload) {}
}

export class UpdateStudentAssessmentSuccess implements Action {
  readonly type = UPDATE_STUDENT_ASSESSMENT_SUCCESS;
  constructor(public payload: any) {}
}

export class BulkUpdateStudentAssessmentsFail implements Action {
  readonly type = BULK_UPDATE_STUDENT_ASSESSMENTS_FAIL;
  constructor(public payload: any) {}
}

// action types
export type StudentAssessmentsAction =
  | LoadStudentAssessments
  | LoadStudentAssessmentsSuccess
  | LoadStudentAssessmentsFail
  | BulkCreateStudentAssessmentsSuccess
  | BulkUpdateStudentAssessments
  | UpdateStudentAssessmentSuccess
  | BulkUpdateStudentAssessmentsFail;
