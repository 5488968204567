<div class="error-dialog">
  <div class="dialog-title">
    <nv-icon [name]="'warning-large'" class="alert-icon"></nv-icon>
    <h2>{{ title }}</h2>
  </div>
  <div class="dialog-subtitle" *ngIf="countStudents">{{countStudents}} {{studentNoun}} selected</div>

  <div class="dialog-body">
    <mat-dialog-content>
      <p>{{ message }}</p>
    </mat-dialog-content>
  </div>

  <mat-dialog-actions class="dialog-actions">
    <nv-button (click)="onConfirm()">{{ confirmText }}</nv-button>
  </mat-dialog-actions>
</div>
