import { Injectable } from '@angular/core';

@Injectable()
export class GraphQLStudentDocLogsHelperService {
  getStudentDocLogsFilterOptionsQuery ({ studentId, schoolId }): string {
    return `{
      DocLogsFilterOptions(
        studentId: "${studentId}",
        schoolId: "${schoolId}"
      ) {
          categories
          users {
            key
            human
          }
        }
      }`;
  }

  getShelterDocLogsFilterOptionsQuery ({ caresId, shelterId }): string {
    return `{
      DocLogsFilterOptions(
        caresId: "${caresId}",
        shelterId: "${shelterId}"
      ) {
          categories
          users {
            key
            human
          }
        }
    }`;
  }

  getSchoolStudentDocLogsQuery ({ studentId, schoolId, partnerType }): string {
    return `{
      StudentDocLogs(
        studentId: "${studentId}"
        schoolId: "${schoolId}"
        partnerType: "${partnerType}"
      ) {
        _id
        schoolId
        collection
        contextPartnerType
        createdAt
        createdBy {
          firstName
          lastName
        }
        metaData {
          college
          pathCategory
          fieldHuman
          noteMetaData
        }
        historyType
        tags
        type
        studentId
        newDoc {
          student {
            studentId
            lastFirst
          }
          courseId
          termYear
          createdBy {
            firstName
            lastName
          }
          createdAt
          action
          status
          body
        }
        diffs {
          path
          newVal {
            ... on StringType {
              stringValue
            }
            ... on NumberType {
              numberValue
            }
            ... on BooleanType {
              booleanValue
            }
            ... on StringArrayType {
              stringArrayValue
            }
          }
          oldVal {
            ... on StringType {
              stringValue
            }
            ... on NumberType {
              numberValue
            }
            ... on BooleanType {
              booleanValue
            }
            ... on StringArrayType {
              stringArrayValue
            }
          }
        }
      }
    }`;
  }
};
