import { TransferStudentReviewStep } from './student-review-step.component';
import { MatButtonModule } from '@angular/material/button';
import { WizardFilterActionsModule } from '../../../../wizard-filter-actions/wizard-filter-actions.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [CommonModule, WizardFilterActionsModule, MatButtonModule],
  exports: [TransferStudentReviewStep],
  declarations: [TransferStudentReviewStep],
})
export class TransferStudentReviewStepModule {
  //
}
