import { DynamicComponentModule } from '../../../../shared/directives/dynamic-component/dynamic-component.module';
import { DocMouseMoveService } from '../../../../shared/services/document-mousemove-service/document-mousemove.service';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvTooltipComponent } from './nv-tooltip.component';
import { NvTooltipDirective, TOOLTIP_SERVICE_TOKEN } from './nv-tooltip.directive';
import { NvGroupedTablesTooltipComponent } from './templates/grouped-tables/nv-grouped-tables-tooltip.component';
import { NvSharedTooltipDataService } from './nv-shared-tooltip.service';

@NgModule({
  declarations: [NvTooltipComponent, NvTooltipDirective, NvGroupedTablesTooltipComponent],
  providers: [
    DocMouseMoveService,
    { provide: TOOLTIP_SERVICE_TOKEN, useClass: NvSharedTooltipDataService },
  ],
  imports: [OverlayModule, CommonModule, DynamicComponentModule],
  exports: [NvTooltipDirective, NvTooltipComponent],
})
export class NvSharedTooltipModule {}
