import { UtilitiesService } from 'Src/ng2/shared/services/utilities/utilities.service';
import { ISchool } from 'Src/ng2/shared/typings/interfaces/school.interface';
import { IGapPlan } from 'Src/ng2/shared/typings/interfaces/gap-plan.interface';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'Src/ng2/shared/services/api-service/api-service';
import { Component, Inject, OnInit, EventEmitter, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { ICourseDiff } from '../../typings/interfaces/course-diff.interface';

interface ICreditGapsCoursePickerInputs {
  termDetails: any;
  school: ISchool;
}

@Component({
  selector: 'credit-gaps-course-picker-modal',
  templateUrl: './credit-gaps-course-picker-modal.component.html',
  styleUrls: ['./credit-gaps-course-picker-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CreditGapsCoursePickerModal implements OnInit {
  public title: string;
  public schoolCourseDiffs$: Observable<Array<ICourseDiff>>;
  public schoolGapPlans$: Observable<Array<IGapPlan>>
  public newCourseDiff: EventEmitter<any> = new EventEmitter();
  public newGapPlan: EventEmitter<any> = new EventEmitter();

  constructor (
    private apiService: ApiService,
    private utils: UtilitiesService,
    private dialogRef: MatDialogRef<CreditGapsCoursePickerModal>,
    @Inject(MAT_DIALOG_DATA) public data: ICreditGapsCoursePickerInputs,
  ) { }

  ngOnInit (): void {
    const { school, termDetails } = this.data;
    const { termType, value: termYear } = termDetails;
    this.schoolCourseDiffs$ = this.apiService.getCourseDiffs({ where: JSON.stringify({ schoolId: school._id, status: 'PENDING' }) });
    this.schoolGapPlans$ = this.apiService.getGapPlans({ where: JSON.stringify({ schoolId: school._id, status: 'ACTIVE' }) });

    switch (termType) {
      case 'FUTURE':
        this.title = 'Add courses to future terms';
        break;
      case 'CURRENT':
        this.title = 'Add courses to this term';
        break;
      case 'NEXT': {
        const termYearHuman = this.utils.getHumanReadableTerm(termYear);
        this.title = `Add courses to ${termYearHuman}`;
        break;
      }
      default:
        throw new Error(`termDetails termType of ${termType} is not valid`);
    }
  }

  handleCourseDiff ($event) {
    this.newCourseDiff.emit($event);
  }

  handleGapPlan ($event) {
    this.newGapPlan.emit($event);
  }

  close () {
    this.dialogRef.close();
  }
}
