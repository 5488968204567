import { Injectable } from '@angular/core';

@Injectable()
export class GraphQLGapPlansHelperService {
  public getGapPlansQuery ({ schoolId, grouping, projection }): string {
    const columnKeys = this.getColumnKeys(projection);

    const query = `{
        GapPlansGrouping(
          schoolId: "${schoolId}",
          groupingKey: "${grouping}",
          columns: [${columnKeys}]
        ) {
            groupData {
              key
              human
              tooltip
              rowData {
                data
                meta
                columnKey
              }
            }
            groupingOptions{
              human
              key
            }
            defaultTermYear
          }
      }`;

    return query;
  }

  private getColumnKeys (projection: string[]): string[] {
    return projection.map(key => {
      return `"${key}"`;
    });
  }
}
