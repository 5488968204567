import { NgModule } from '@angular/core';
import { NvDatePickerModule as NvSharedDatePickerModule } from '../../../../projects/shared/nvps-libraries/design/nv-date-picker/nv-date-picker.module';

@NgModule({
  imports: [
    NvSharedDatePickerModule,
  ],
  exports: [NvSharedDatePickerModule],
})
export class NvDatePickerModule {}
