<p class="mat-body-2">Below are several common groups of students to double check:</p>

<ul>
  <li class="mat-body-2">
    <wizard-filter-actions
      [data]="studentsWithConflictingExams.data"
      [options]="studentsWithConflictingExams.options"
    ></wizard-filter-actions>
    
    <ng-container [ngPlural]="studentsWithConflictingExams.data.length">
      <ng-template ngPluralCase="0"> students are</ng-template>
      <ng-template ngPluralCase="1"> student is</ng-template>
      <ng-template ngPluralCase="few"> students are</ng-template>
      <ng-template ngPluralCase="other"> students are</ng-template>
    </ng-container>

    planned for 2 or more exams in the same AM or PM exam session in the next Regents administration
  </li>
  <li class="mat-body-2">

    <wizard-filter-actions
      [data]="studentsPlannedForThreeOrMore.data"
      [options]="studentsPlannedForThreeOrMore.options"
    ></wizard-filter-actions>

    <ng-container [ngPlural]="studentsPlannedForThreeOrMore.data.length">
      <ng-template ngPluralCase="0"> students are</ng-template>
      <ng-template ngPluralCase="1"> student is</ng-template>
      <ng-template ngPluralCase="few"> students are</ng-template>
      <ng-template ngPluralCase="other"> students are</ng-template>
    </ng-container>


    planned for 3 or more exams in the next Regents administration
  </li>
  <li class="mat-body-2">
    <wizard-filter-actions
      [data]="studentsPlannedForExamsAttemptedThreeOrMoreTimes.data"
      [options]="studentsPlannedForExamsAttemptedThreeOrMoreTimes.options"
    ></wizard-filter-actions>

    <ng-container [ngPlural]="studentsPlannedForExamsAttemptedThreeOrMoreTimes.data.length">
      <ng-template ngPluralCase="0"> students are</ng-template>
      <ng-template ngPluralCase="1"> student is</ng-template>
      <ng-template ngPluralCase="few"> students are</ng-template>
      <ng-template ngPluralCase="other"> students are</ng-template>
    </ng-container>



    planned for exams in the next Regents administration that they have attempted 3 or more times previously
  </li>
  <li class="mat-body-2">
    <wizard-filter-actions
      [data]="studentsNotPlannedForNeededExamWithNoExamsPlannedInNextAdmin.data"
      [options]="studentsNotPlannedForNeededExamWithNoExamsPlannedInNextAdmin.options"
    ></wizard-filter-actions>


    <ng-container [ngPlural]="studentsNotPlannedForNeededExamWithNoExamsPlannedInNextAdmin.data.length">
      <ng-template ngPluralCase="0"> students </ng-template>
      <ng-template ngPluralCase="1"> student </ng-template>
      <ng-template ngPluralCase="few"> students </ng-template>
      <ng-template ngPluralCase="other"> students </ng-template>
    </ng-container>


    with exams needed be on-track


    <ng-container [ngPlural]="studentsNotPlannedForNeededExamWithNoExamsPlannedInNextAdmin.data.length">
      <ng-template ngPluralCase="0"> are </ng-template>
      <ng-template ngPluralCase="1"> is </ng-template>
      <ng-template ngPluralCase="few"> are </ng-template>
      <ng-template ngPluralCase="other"> are </ng-template>
    </ng-container>

    not planned for any exams in the next Regents administration
  </li>
  <li class="mat-body-2">
    <wizard-filter-actions
      [data]="studentsPlannedForRegentsExamsBeyondGradDate.data"
      [options]="studentsPlannedForRegentsExamsBeyondGradDate.options"
    ></wizard-filter-actions>

    <ng-container [ngPlural]="studentsPlannedForRegentsExamsBeyondGradDate.data.length">
      <ng-template ngPluralCase="0"> students </ng-template>
      <ng-template ngPluralCase="1"> student </ng-template>
      <ng-template ngPluralCase="few"> students </ng-template>
      <ng-template ngPluralCase="other"> students </ng-template>
    </ng-container>

    <ng-container [ngPlural]="studentsPlannedForRegentsExamsBeyondGradDate.data.length">
      <ng-template ngPluralCase="0"> are </ng-template>
      <ng-template ngPluralCase="1"> is </ng-template>
      <ng-template ngPluralCase="few"> are </ng-template>
      <ng-template ngPluralCase="other"> are </ng-template>
    </ng-container>


    planned for one or more exams beyond

    <ng-container [ngPlural]="studentsPlannedForRegentsExamsBeyondGradDate.data.length">
      <ng-template ngPluralCase="0"> their </ng-template>
      <ng-template ngPluralCase="1"> his or her </ng-template>
      <ng-template ngPluralCase="few"> their </ng-template>
      <ng-template ngPluralCase="other"> their </ng-template>
    </ng-container>

    planned graduation

    <ng-container [ngPlural]="studentsPlannedForRegentsExamsBeyondGradDate.data.length">
      <ng-template ngPluralCase="0"> dates. </ng-template>
      <ng-template ngPluralCase="1"> date.  </ng-template>
      <ng-template ngPluralCase="few"> dates. </ng-template>
      <ng-template ngPluralCase="other"> dates. </ng-template>
    </ng-container>


  </li>
</ul>

