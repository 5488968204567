import { Injectable } from '@angular/core';
import { TValidPartnerTypes } from 'Src/ng2/shared/typings/interfaces/partner.interface';

@Injectable()
export class GraphQlNetworkSideNavHelperService {
  getNetworkSideNavConfigQuery (clusterId: string, contextPartnerType: TValidPartnerTypes): string {
    const validClusterId = clusterId ? `"${clusterId}"` : 'null';
    const query = `{
      NetworkSideNavConfig(
        clusterId: ${validClusterId},
        contextPartnerType: "${contextPartnerType}"
      ) {
          featureSet
          clusterType
          schoolTypes
        }
      }`;
    return query;
  }
}
