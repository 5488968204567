import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from 'Src/ng2/shared/services/api-service/api-service';
import { IPartner } from 'Src/ng2/shared/typings/interfaces/partner.interface';

@Injectable()
export class ShelterIdResolver implements Resolve<any> {
  constructor (private apiService: ApiService) {}
  resolve (route: ActivatedRouteSnapshot): Observable<IPartner> | Observable<never> {
    const shelterId = route.paramMap.get('shelterId');
    const params = {
      type: 'shelter',
      _id: shelterId,
    };
    const projections = ['partnerName', 'subLocations'];
    return this.apiService.getPartner({ params, projections });
  }
}
