import { ImModelsHelpers } from '../../../helpers/im-models/im-models.helper';
import { IStudent, ICurrentGrade } from '../../../typings/interfaces/student.interface';
import { RegentsExam } from '../../../constants/regents.constant';
import * as _ from 'lodash';
import { Injectable } from '@angular/core';

export interface ICourseForRegentsExemption {
  codeAndSection: string;
  titleAndTeacher: string;
  currentGrade: string;
  status: 'N/A' | 'Passing' | 'Not Passing';
  priority: string;
}

/**
 * `@depends` decorator
 *
 * Use this decorator to state that an this method depends on certain paths, joins or other methods:
 *    @depends({ paths: ['studentDetails.name'], joins: ['courseDiffs'], methods: ['_getSomething']})
 *    fullName(student) {  }
 *
 * @param depends Object an object with three optional keys: `paths`, `joins` and `methods`
 * @return {dependencyDecorator}
 */
/* istanbul ignore next */
function depends (depends) {
  return function dependencyDecorator (target, key, descriptor) {
    descriptor.value.depends = depends;
    return descriptor;
  };
}

// TODO: REMOVE WHEN REGENTS ARE REINSTATED (this service file will no longer be needed)
/* istanbul ignore next */
@Injectable()
export class ImStudentRegentsExemptions {
  readonly REGENTS_EXAMS_ELIGIBLE_TO_EXEMPT = [
    'ccEla',
    'ccAlg',
    'ccGeom',
    'ccTrig',
    'us',
    'globalTwo',
    'livingEnv',
    'earth',
    'chem',
    'physics',
    'lote',
  ];

  readonly RegentsExamToSubjectForExemption = _.reduce(
    RegentsExam,
    (acc, exam) => {
      acc[exam.key] = exam.subject.forExemption;
      return acc;
    },
    {},
  );

  _dependentMethods (method) {
    const resolvedMethods = [];
    const unresolvedMethods = [];

    // See here for the dependency resolution algorithm used:
    // https://www.electricmonk.nl/log/2008/08/07/dependency-resolving-algorithm/
    const resolveMethods = (method, resolvedMethods, unresolvedMethods) => {
      unresolvedMethods.push(method);
      const methods = (this[method].depends || {}).methods || [];
      _.each(methods, m => {
        if (!_.includes(resolvedMethods, m)) {
          if (_.includes(unresolvedMethods, m)) {
            throw new Error(`Circular method dependency detected: ${method} -> ${m}`);
          }
          resolveMethods(m, resolvedMethods, unresolvedMethods);
        }
      });
      resolvedMethods.push(method);
      _.pull(unresolvedMethods, method);
    };

    resolveMethods(method, resolvedMethods, unresolvedMethods);
    return resolvedMethods;
  }

  /**
   * Traverses the method dependency graph and returns all of the paths that `method` depends on
   *
   * @param method a this method
   * @return {Array} and array of paths
   */
  pathsFor (method) {
    if (!this[method]) throw new Error(`method '${method}' does not exist`);

    if (!(this[method].depends || {}).methods) {
      const paths = (this[method].depends || {}).paths;
      if (!_.isArray(paths)) {
        console.warn(
          `this.pathsFor('${method}') was called, but ${method} didn't declare any dependent paths. ` +
            `If ${method} doesn't depend on any paths, annotate it with \`@depends({ paths: [] })\``,
        );
      }
      return paths;
    }
    const dependentMethods = this._dependentMethods(method);
    const dependentPaths = _.uniq(
      _.flatten(
        _.map(dependentMethods, m => {
          const depends = this[m].depends;
          if (!depends) {
            console.warn(`\`${m}\` was detected as a dependent method, but it didn't register any dependencies itself`);
            return [];
          }
          return depends.paths || [];
        }),
      ),
    );
    return dependentPaths;
  }

  // used for regents panel tooltip
  private getCourseForRegentsExemptionDetails (course: ICurrentGrade): ICourseForRegentsExemption {
    const { code, name, str, priority, section, teacher } = course;
    const teacherNickName = teacher && teacher.nickName && teacher.nickName.split(' ');
    const teacherLastName = teacherNickName && teacherNickName[teacherNickName.length - 1];

    const courseDetails = {
      codeAndSection: `${code}-${section}`,
      titleAndTeacher: `${teacherLastName ? name + ' (' + teacherLastName + ')' : name}`,
      currentGrade: str,
      status: this.getCourseStatus(course),
      priority: _.upperFirst(priority),
    };

    return courseDetails;
  }

  // used for regents panel tooltip
  private getCourseStatus (course: ICurrentGrade) {
    const { str, pf } = course;
    let status;

    if (str === null) status = 'N/A';
    else if (pf === 'P') status = 'Passing';
    else status = 'Not Passing';

    return status;
  }

  private getRegentsCountForExemptStatus (student: IStudent, status: string): number {
    const count = this.REGENTS_EXAMS_ELIGIBLE_TO_EXEMPT.reduce((acc, examKey) => {
      const addToSum = student.regentsDetails.byExam[examKey].exemptStatusRegents === status;

      if (addToSum) acc += 1;

      return acc;
    }, 0);

    return count;
  }

  // RE106-RE118
  @depends({
    paths: [
      'isHS',
      ...ImModelsHelpers.expandPath('regentsDetails.byExam.<examKey>.transcript.month'),
      ...ImModelsHelpers.expandPath('regentsDetails.byExam.<examKey>.transcript.year'),
      ...ImModelsHelpers.expandPath('regentsDetails.byExam.<examKey>.transcript.mark'),
    ],
  })
  mostRecentRegentsAttemptByExam (student: IStudent, examKey) {
    if (student.isHS === false) return null;
    const { transcript } = student.regentsDetails.byExam[examKey];
    let index;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < transcript.length; i++) {
      const { num } = transcript[i].mark;
      if (num >= 1) {
        index = i;
        break;
      }
    }
    if (index === undefined) return null;
    const mostRecentRegentsAttempt = transcript[index];
    const { month, year } = mostRecentRegentsAttempt;
    let _year = year;
    let str = '';

    if (month && year) {
      _year = year.slice(2); // remove SY
      _year = _year.split('-') as any;
      _year = `${_year[0].slice(0, 2)}${_year[1]}`;
      str = `${month} ${_year}`;
    } else if (month) {
      str = `${month}`;
    } else if (year) {
      str = `${year}`;
    }

    return str;
  }

  // RE119-131
  @depends({
    paths: [
      'isHS',
      'regentsDetails.byExam.lote.exemptStatusAdvanced',
      ...ImModelsHelpers.expandPath('regentsDetails.byExam.<examKey>.exemptStatusRegents'),
    ],
    methods: ['getEffectivePlannedDiplomaType'],
  })
  exemptionStatusByExam (student, examKey) {
    if (student.isHS === false) return null;
    let exemptStatusKey;

    if (examKey === 'lote') {
      const effectivePlannedDiplomaType = this.getEffectivePlannedDiplomaType(student);

      if (effectivePlannedDiplomaType === 'Local' || effectivePlannedDiplomaType === 'Regents') {
        exemptStatusKey = 'exemptStatusRegents';
      } else if (effectivePlannedDiplomaType === 'Advanced Regents') {
        exemptStatusKey = 'exemptStatusAdvanced';
      }
    } else {
      exemptStatusKey = 'exemptStatusRegents';
    }

    const exemptStatus = student.regentsDetails.byExam[examKey][exemptStatusKey];
    return exemptStatus;
  }

  // RE132-RE144, RE149-RE161
  @depends({
    paths: ['currProgram.grades', ...ImModelsHelpers.expandPath('regentsDetails.byExam.<examKey>.exemptStatusRegents')],
    methods: ['exemptionStatusByExam'],
  })
  coursesForExemptionByExam (student, examKey): ICourseForRegentsExemption[] {
    const exemptionStatus = this.exemptionStatusByExam(student, examKey);
    if (exemptionStatus !== 'In progress' && exemptionStatus !== 'In progress (DOE)') return null;

    const coursesForRegentsExemption = student.currProgram.grades.reduce((acc, course) => {
      const { mostRecent, subj } = course;
      const isMostRecent = mostRecent === true || mostRecent === null;
      if (isMostRecent && subj === this.RegentsExamToSubjectForExemption[examKey]) {
        const _course = this.getCourseForRegentsExemptionDetails(course);
        acc.push(_course);
      }
      return acc;
    }, []);
    return coursesForRegentsExemption;
  }

  // G102
  @depends({
    paths: ['isHS', 'gradPlanningDetails.plannedDiplomaType'],
  })
  isValidPlannedDiplomaType (student: IStudent) {
    const { isHS, gradPlanningDetails } = student;
    const { plannedDiplomaType } = gradPlanningDetails;

    if (!isHS) return null;
    if (plannedDiplomaType === null || plannedDiplomaType === 'No plan') return false;
    return true;
  }

  // G100
  @depends({
    paths: ['gradPlanningDetails.plannedDiplomaType'],
  })
  getPlannedDiplomaType (student: IStudent) {
    const {
      gradPlanningDetails: { plannedDiplomaType },
    } = student;
    return plannedDiplomaType;
  }

  // G106
  @depends({
    paths: ['isHS'],
    methods: ['getSafetyNetEligibility'],
  })
  getMinimumDiplomaType (student: IStudent) {
    const { isHS } = student;
    const isSafetyNetEligible = this.getSafetyNetEligibility(student);
    if (!isHS) {
      return false;
    } else if (isSafetyNetEligible) {
      return 'Local';
    } else {
      return 'Regents';
    }
  }

  // R104
  @depends({
    paths: ['isHS', 'gradPlanningDetails.schoolVerifiedSafetyNetEligibility', 'spedDetails.isSped'],
  })
  getSafetyNetEligibility (student: IStudent) {
    const {
      isHS,
      gradPlanningDetails: { schoolVerifiedSafetyNetEligibility },
      spedDetails: { isSped },
    } = student;

    if (!isHS) {
      return false;
    } else if (schoolVerifiedSafetyNetEligibility) {
      return true;
    } else if (schoolVerifiedSafetyNetEligibility === false) {
      return false;
    } else {
      // if schoolVerifiedSafetyNetEligibility is null, return isSped (Jack)
      return isSped;
    }
  }

  // G104
  @depends({
    paths: ['gradPlanningDetails.plannedDiplomaType'],
    methods: ['isValidPlannedDiplomaType', 'getPlannedDiplomaType', 'getMinimumDiplomaType'],
  })
  getEffectivePlannedDiplomaType (student: IStudent) {
    const {
      gradPlanningDetails: { plannedDiplomaType },
    } = student;
    const isValidPlannedDiplomaType = this.isValidPlannedDiplomaType(student);
    if (isValidPlannedDiplomaType && plannedDiplomaType !== 'Non-Graduate') {
      const plannedDiplomaType = this.getPlannedDiplomaType(student);
      return plannedDiplomaType;
    } else {
      const minimumDiplomaType = this.getMinimumDiplomaType(student);
      return minimumDiplomaType;
    }
  }

  @depends({
    paths: ['isHS', 'regentsDetails.exemptions'],
    methods: ['getEffectivePlannedDiplomaType'],
  })
  getExemption (exemptionsArr) {
    return (student: IStudent) => {
      const { isHS, regentsDetails } = student;
      if (isHS === false || !regentsDetails.exemptions) return null;
      const effectivePlannedDiplomaType = this.getEffectivePlannedDiplomaType(student);

      let path;
      switch (effectivePlannedDiplomaType) {
        case 'Local':
          path = exemptionsArr[0];
          break;
        case 'Regents':
          path = exemptionsArr[1];
          break;
        case 'Advanced Regents':
          path = exemptionsArr[2];
          break;
      }
      const exemption = regentsDetails.exemptions[path];
      return exemption;
    };
  }

  // RE145
  @depends({
    methods: ['getExemption'],
  })
  doeIdentifiedRegentsReqExemptions (student: IStudent) {
    return this.getExemption(['doeVerifiedLocal', 'doeVerifiedRegents', 'doeVerifiedAdvanced'])(student);
  }

  // RE102
  @depends({
    methods: ['getExemption'],
  })
  regentsReqsWithCourseworkCompleted (student: IStudent) {
    return this.getExemption(['completeLocal', 'completeRegents', 'completeAdvanced'])(student);
  }

  // RE103
  @depends({
    methods: ['getExemption'],
  })
  regentsReqsWithCourseworkInProgress (student: IStudent) {
    return this.getExemption(['inProgressLocal', 'inProgressRegents', 'inProgressAdvanced'])(student);
  }

  // RE104
  @depends({
    methods: ['getExemption'],
  })
  regentsReqsNotEligibleForExemption (student: IStudent) {
    return this.getExemption(['notEligibleLocal', 'notEligibleRegents', 'notEligibleAdvanced'])(student);
  }

  // RE146
  @depends({
    paths: ImModelsHelpers.expandPath('regentsDetails.byExam.<examKey>.exemptStatusRegents'),
  })
  countOfRegentsExamsIdentifiedForExemption (student: IStudent) {
    return this.getRegentsCountForExemptStatus(student, 'Completed (DOE)');
  }

  // RE148
  @depends({
    paths: ImModelsHelpers.expandPath('regentsDetails.byExam.<examKey>.exemptStatusRegents'),
  })
  countOfRegentsExamsWithCourseworkCompleted (student: IStudent) {
    const statusCompleted = 'Completed';
    const statusCompletedPrior = 'Completed prior';
    let count = this.getRegentsCountForExemptStatus(student, statusCompleted);
    count += this.getRegentsCountForExemptStatus(student, statusCompletedPrior);
    return count;
  }

  // RE147
  @depends({
    paths: ImModelsHelpers.expandPath('regentsDetails.byExam.<examKey>.exemptStatusRegents'),
  })
  countOfRegentsExamsWithCourseworkInProgress (student: IStudent) {
    return this.getRegentsCountForExemptStatus(student, 'In progress');
  }

  // RE164
  @depends({
    paths: ImModelsHelpers.expandPath('regentsDetails.byExam.<examKey>.exemptStatusRegents'),
  })
  countOfRegentsExamsIdentifiedForExemptionInProgress (student: IStudent) {
    return this.getRegentsCountForExemptStatus(student, 'In progress (DOE)');
  }

  @depends({
    paths: [
      'isHS',
      ...ImModelsHelpers.expandPath('regentsDetails.byExam.<examKey>.schedInStars'),
      ...ImModelsHelpers.expandPath('regentsDetails.byExam.<examKey>.starsSection'),
    ],
  })
  scheduledInStarsStatus (student: IStudent, examKey) {
    const { schedInStars, starsSection } = student.regentsDetails.byExam[examKey];

    let status;

    if (student.isHS === false || schedInStars === false) {
      status = null;
    } else if (schedInStars && !starsSection) {
      status = 'Yes';
    } else if (schedInStars && starsSection) {
      status = `Yes (${starsSection})`;
    }

    return status;
  }
}
