import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NextRegentsAdminDate } from '../../constants/next-regents-admin-date.constant';
import { BaseModalComponent } from '../base-modal.component';

export interface IRegentsNextAdminConflictModalComponentData {
  canExamBeSched: any;
  examName: string;
}

@Component({
  selector: 'regents-next-admin-conflict-modal',
  templateUrl: './regents-next-admin-conflict.component.html',
  styleUrls: ['./regents-next-admin-conflict.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RegentsNextAdminConflictModalComponent implements OnInit {
  textContent: string;

  constructor (
    public dialogRef: MatDialogRef<RegentsNextAdminConflictModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IRegentsNextAdminConflictModalComponentData,
  ) {}

  ngOnInit (): void {
    const { canExamBeSched, examName } = this.data;

    if (canExamBeSched.canBeSched) {
      throw new Error('This modal should only be called for regents exams that cannot be sched.');
    }

    if (canExamBeSched.conflictingExams.length) {
      // tslint:disable-next-line:max-line-length
      this.textContent = `${examName} is offered for ${NextRegentsAdminDate}. However, the student is already scheduled for an exam being administered simultaneously (${
        canExamBeSched.conflictingExams[0].humanName
      }). Do you still want to go ahead and schedule ${examName}?`;
    } else {
      // tslint:disable-next-line:max-line-length
      this.textContent = `${examName} is not offered for ${NextRegentsAdminDate}. Do you still want to go ahead and schedule ${examName}?`;
    }
  }

  confirm () {
    this.dialogRef.close(true);
  }

  cancel () {
    this.dialogRef.close();
  }
}
