import { Injectable } from '@angular/core';
import { reduce } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IListViewData } from '../../../../typings/interfaces/list-view.interface';
import { COLUMN_DATA_TYPE } from './../../../../constants/list-view/cell-type.constant';

interface IEnrolledAndFailingData {
  studentsWithCourse: ICourseData;
  studentsFailingCourse: ICourseData;
}

interface IOptions {
  columns: Array<object>;
  listType: string;
  navDataType: string;
  navRoute: string;
  navData: { index: number; key: string };
}

interface ICourseData {
  // value is a student, the projection matching the dashboard students projection - needs interface
  [key: string]: Array<object>;
}

// takes students and an options object
@Injectable()
export class CourseRollupGrouping {
  public getGrouping(filteredStudents$: Observable<any>, options: IOptions): Observable<any> {
    const { columns } = options;

    return filteredStudents$.pipe(
      map(students => {
        const courseData: IEnrolledAndFailingData = this.getEnrolledAndFailedStudentsByCourse(students);
        // empty list
        const formattedList: IListViewData = this.getFormattedListShell(options);
        const groupingData: object = { students, courseData, columns, formattedList };
        // populated list
        const grouping: IListViewData = this.generateGrouping(groupingData);
        return grouping;
      }),
    );
  }

  private getFormattedListShell(args): IListViewData {
    const { columns, listType, navDataType, navRoute, navData } = args;
    return {
      listType,
      columns: this.formatColumnsForList(columns),
      sections: [
        {
          name: 'Course',
          count: 0,
          data: [],
          defaultDisplayCount: 10,
          dataType: 'String',
          key: 'course',
        },
      ],
      navDataType,
      navData,
      navRoute,
    };
  }

  private formatColumnsForList(columns) {
    const formattedColumns = columns.reduce((formattedList, col) => {
      if (col.cellType !== COLUMN_DATA_TYPE.SECTION_HEADER) {
        formattedList[col.key] = {
          name: col.human,
          cellType: col.cellType,
          cellConfig: col.cellConfig,
          dataType: col.dataType,
          orderBy: col.orderBy,
        };
      }
      return formattedList;
    }, {});
    return formattedColumns;
  }

  private getEnrolledAndFailedStudentsByCourse(students: any[]): IEnrolledAndFailingData {
    return students.reduce(
      (courseData, student) => {
        // use map to prevent duplicates without having to iterate on results to filter uniques
        const withCourseStudentWasAdded = {};
        const failingCourseStudentWasAdded = {};
        const {
          currProgram: { grades },
        } = student;

        grades.forEach(grade => {
          const { code, subj, mostRecent, pf } = grade;
          courseData.courseToSubjectMap[code] = subj;
          // if we see a new code, create it in accum
          if (code && !courseData.studentsWithCourse[code]) courseData.studentsWithCourse[code] = [];
          // we want to keep a tally of all students `currently` in the course
          if (code && mostRecent && !withCourseStudentWasAdded[code]) {
            courseData.studentsWithCourse[code].push(student);
            withCourseStudentWasAdded[code] = true;
          }
          // we want to track all students `currently` failing
          if (mostRecent && pf === 'F' && !failingCourseStudentWasAdded[code]) {
            if (!courseData.studentsFailingCourse[code]) courseData.studentsFailingCourse[code] = [];
            courseData.studentsFailingCourse[code].push(student);
            failingCourseStudentWasAdded[code] = true;
          }
        });
        return courseData;
      },
      { studentsWithCourse: {}, studentsFailingCourse: {}, courseToSubjectMap: {} },
    );
  }

  private generateGrouping(args) {
    const { courseData, columns, formattedList } = args;

    return reduce(
      courseData.studentsFailingCourse,
      (formattedList, _, courseKey) => {
        const config = {
          grouping: 'COURSE',
          subject: courseData.courseToSubjectMap[courseKey],
          course: courseKey,
        };
        // first column
        const courseColumn = {
          data: courseKey,
          dependencies: {
            filterValue: courseKey,
            studentIds: courseData.studentsFailingCourse[courseKey].map(({ studentId }) => studentId),
            subject: courseData.courseToSubjectMap[courseKey],
          },
        };

        const courseRow = columns.reduce(
          (result, { key, getValueAtPath }) => {
            result[key] = getValueAtPath(courseData.studentsWithCourse[courseKey], config);
            return result;
          },
          { course: courseColumn },
        );

        // add & increment list group
        formattedList.sections[0].count++;
        formattedList.sections[0].data.push(courseRow);
        // return accumulator
        return formattedList;
      },
      formattedList,
    );
  }
}
