import * as dashboardActions from '../actions/dashboard-actions';
import { MergeUtilities } from './../utilities/merge-utilities';

interface IDashboardState {
  madlib: object;
  students: any;
  graphs: object;
  studentSupports: object;
}
export const initialState: IDashboardState = { madlib: {}, students: {}, studentSupports: {}, graphs: {} };

export function reducer(
  state: IDashboardState = initialState,
  action: dashboardActions.DashboardAction,
): IDashboardState {
  switch (action.type) {
    case dashboardActions.LOAD_DASHBOARD: {
      const currentDashboardGraphs = state.graphs;
      const { graphName } = action.payload;

      const updatedGraphs = {
        ...currentDashboardGraphs,
        ...{
          [graphName]: {
            loading: true,
            loaded: false,
          },
        },
      };
      return { ...state, ...{ graphs: updatedGraphs } };
    }

    case dashboardActions.SET_CALCULATED_GRAPH: {
      const { graphState, graphName }: any = action.payload;
      const currentDashboardGraphs = state.graphs;
      const updatedGraphs = { ...currentDashboardGraphs, ...{ [graphName]: graphState } };
      return {
        ...state,
        ...{ graphs: updatedGraphs },
      };
    }

    case dashboardActions.LOAD_DASHBOARD_SUCCESS: {
      const dashboard: any = action.payload;
      const { dashName: graphName, rows } = dashboard;
      const graphData = { data: rows, loading: false, loaded: true };

      const currentDashboardGraphs = state.graphs;
      const updatedGraphs = { ...currentDashboardGraphs, ...{ [graphName]: graphData } };

      return {
        ...state,
        ...{ graphs: updatedGraphs },
      };
    }

    case dashboardActions.UPDATE_DASHBOARD_STUDENTS_SUCCESS: {
      const { patches } = action.payload;
      const updatedStudents = patches.reduce(
        (studentEntities, { _ids, patch }) => {
          _ids.forEach(_id => {
            studentEntities[_id] = MergeUtilities.deepMergeObjs(studentEntities[_id], patch);
          });
          return studentEntities;
        },
        { ...state.students.studentEntities },
      );
      const students = { ...state.students };
      students.studentEntities = { ...state.students.studentEntities, ...updatedStudents };
      return { ...state, students };
    }

    case dashboardActions.LOAD_DASHBOARD_FAIL: {
      const { graphName } = action.payload;
      const currentDashboardGraphs = state.graphs;
      const graphData = { data: null, loading: false, loaded: false };
      const updatedGraphs = { ...currentDashboardGraphs, ...{ [graphName]: graphData } };
      return { ...state, ...{ graphs: updatedGraphs } };
    }

    case dashboardActions.LOAD_DASHBOARD_STUDENTS: {
      const studentState = { students: { loading: true, loaded: false, studentEntities: {} } };
      return { ...state, ...studentState };
    }

    case dashboardActions.LOAD_DASHBOARD_STUDENTS_SUCCESS: {
      const studentList = action.payload;
      const studentEntities = studentList.reduce((studentEntities: { [id: number]: any }, student) => {
        studentEntities[student._id] = student;
        return studentEntities;
      }, {});

      const updatedStudentState = { students: { loading: false, loaded: true, studentEntities } };
      return { ...state, ...updatedStudentState };
    }

    // student supports
    case dashboardActions.LOAD_DASHBOARD_STUDENT_SUPPORTS: {
      return { ...state, studentSupports: { loading: true, loaded: false, studentSupportEntities: {} } };
    }

    case dashboardActions.LOAD_DASHBOARD_STUDENT_SUPPORTS_SUCCESS: {
      const studentSupports = action.payload;
      const studentSupportEntities = studentSupports.reduce((acc, studentSupport) => {
        acc[studentSupport._id] = studentSupport;
        return acc;
      }, {});
      return { ...state, studentSupports: { loading: false, loaded: true, studentSupportEntities } };
    }

    case dashboardActions.UPDATE_MADLIB: {
      const madlib = action.payload;
      return { ...state, madlib };
    }

    case dashboardActions.RESET_MADLIB: {
      return { ...state, madlib: {} };
    }

    default:
      return state;
  }
}
