import { Injectable } from '@angular/core';
import { TValidPartnerTypes } from 'Src/ng2/shared/typings/interfaces/partner.interface';
import { IStudent } from 'Src/ng2/shared/typings/interfaces/student.interface';

@Injectable()
export class GraphQLTasksHelperService {
  public getBulkCreateTaskQuery (studentIds: string[], payload: any, partnerType: TValidPartnerTypes): string {
    if (partnerType === 'school') {
      const options = this._getOptions(studentIds, payload);
      const query = `mutation {
          bulkCreateTask(options: {${options}}) {
            backgroundJobId
            metaData {
              validStudentIds
              invalidStudentIds
            }
          }
        }`;

      return query;
    }
  }

  public getEditTaskQuery (payload: any): string {
    const optionsString = Object.entries(payload).reduce(this.stringBuilderFn, '');
    const query = `
      mutation {
        updateTask(patch: {${optionsString}}) {
          _id
        }
      }
    `;
    return query;
  }

  public getCreateSingleTaskQuery (studentData: any, payload: any, partnerType: TValidPartnerTypes): string {
    if (partnerType === 'school') {
      const optionsString = this._getOptionsForSingleTaskCreate(studentData, payload);

      return `
      mutation {
        createSingleTask(options: {${optionsString}}) {
          _id
        }
      }`;
    }
  }

  // HELPER METHODS
  private _getOptions (studentIds: any, payload: any): string {
    const idsFormatted = this._getArrFormatted(studentIds);
    const { recipient, categories, description, schoolId, schoolYear, district } = payload;
    const categoriesFormatted = this._getArrFormatted(categories);
    const { userId, firstName, lastName, gafeEmail, doeEmail, dhsEmail } = recipient;
    const options = `
      studentIds: [${idsFormatted}],
      schoolId: "${schoolId}",
      district: "${district}",
      schoolYear: "${schoolYear}",
      categories: [${categoriesFormatted}],
      description: "${this._sanitizeUserInput(description)}",
      userId: "${userId}",
      firstName: "${firstName}",
      lastName: "${lastName}",
      gafeEmail: "${gafeEmail}",
      doeEmail: "${doeEmail}",
      dhsEmail: "${dhsEmail}",
  `;

    return options;
  }

  private _getOptionsForSingleTaskCreate (studentData: any, payload: any): string {
    const { studentId, studentName } = studentData;
    const studentLastFirst = studentName;
    const { recipient, categories, description, schoolId, schoolYear, district } = payload;
    const categoriesFormatted = this._getArrFormatted(categories);
    const { userId, firstName, lastName, gafeEmail, doeEmail, dhsEmail } = recipient;

    const options = `
      studentId: "${studentId}",
      studentLastFirst: "${studentLastFirst}",
      schoolId: "${schoolId}",
      district: "${district}",
      schoolYear: "${schoolYear}",
      categories: [${categoriesFormatted}],
      description: "${this._sanitizeUserInput(description)}",
      userId: "${userId}",
      firstName: "${firstName}",
      lastName: "${lastName}",
      gafeEmail: "${gafeEmail}",
      doeEmail: "${doeEmail}",
      dhsEmail: "${dhsEmail}",
  `;

    return options;
  }

  private _getArrFormatted (ids: string[]): string[] {
    return ids.map(id => {
      return `"${id}"`;
    });
  }

  private stringBuilderFn = (acc, [key, value]): string => {
    if (value === null) {
      acc += `${key}: ${value}, \n`;
    } else if (Array.isArray(value)) {
      acc += `${key}: [${value.map(val => `"${this._sanitizeUserInput(val)}"`)}]`;
    } else if (typeof value !== 'string') {
      acc += `${key}: ${this._sanitizeUserInput(value)}, \n`;
    } else {
      acc += `${key}: "${this._sanitizeUserInput(value)}", \n`;
    }
    return acc;
  };

  // The replace function is used to escape double quotes and backslashes in the user input.
  // The regular expression (/(["\\])/g) captures either a double quote or a backslash, and \\$1 is used to replace it with an escaped version.
  // The second replace function is used to handle newline characters.
  private _sanitizeUserInput (input: string): string {
    return `${input.replace(/(["\\])/g, '\\$1').replace(/\n/g, '\\n')}`;
  }
}
