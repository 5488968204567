import { ObjectCache } from 'Src/ng2/shared/services/object-cache/object-cache.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { from, Observable } from 'rxjs';

interface ICacheObject {
  id: string;
  json: string;
}

@Injectable()
export class SchoolSsvSortResolver implements Resolve<any> {
  constructor (private objectCache: ObjectCache) {}

  resolve (route: ActivatedRouteSnapshot): Observable<ICacheObject> {
    const { sort } = route.queryParams;
    const sortPromise: Promise<ICacheObject> = sort ? this.objectCache.getObject(sort) : Promise.resolve(null);
    return from(sortPromise);
  }
}
