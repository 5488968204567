<ng-container *ngIf="v4ModeIsOn; else oldSkin">
<span class="container">
  <span class="filter-text">Filter for</span>
  <nv-pill-button [isInverted]="true" [text]="filterText" icon="dropdown-blue" [matMenuTriggerFor]="menu"></nv-pill-button>
</span>
<nv-button class="uncheck-button" type="secondary" icon="reset-small-blue" (clickButton)="onUncheckAll()">Uncheck selections</nv-button>
</ng-container>
<ng-template #oldSkin>
  <span class="container">
    <span class="filter-text">Filter for</span>
    <nv-pill-button [text]="filterText" icon="filter-small-blue" [matMenuTriggerFor]="menu"></nv-pill-button>
  </span>
  <nv-button class="uncheck-button" type="secondary" icon="uncheck" (clickButton)="onUncheckAll()">Clear all</nv-button>
</ng-template>

<mat-menu #menu="matMenu" class="grid-filter-panel">
  <!-- TODO: confirm length of when search box is required -->
  <div class="filter-small-blue">
    <label for="category">Category</label>
    <nv-multi-picker
      id="category"
      (click)="$event.stopPropagation()"
      [options]="formattedCategories"
      [(selectedOptions)]="selectedCategories"
      [hasSearchBox]="categories.length > 5"
    ></nv-multi-picker>
  </div>

  <div class="filter" *ngIf="tags.length">
    <label for="tags">Tags</label>
    <nv-multi-picker
      id="tags"
      (click)="$event.stopPropagation()"
      [options]="formattedTags"
      [(selectedOptions)]="selectedTags"
      [hasSearchBox]="tags.length > 5"
    ></nv-multi-picker>
  </div>
  <div class="filter-buttons">
    <nv-button (clickButton)="resetSelectedFilters()" type="secondary">Cancel</nv-button>
    <nv-button (clickButton)="onFilter()">Filter</nv-button>
  </div>
</mat-menu>
