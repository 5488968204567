import * as _ from 'lodash';
import { ImUser } from 'Src/ng2/shared/services/im-models/im-user';
import { IUserMini } from '../../../../../ng2/shared/typings/interfaces/user.interface';

export class CellRenderer {
  static renderAsPercent(params) {
    if (params.value !== null) {
      const val = (parseFloat(params.value) * 100).toFixed(2) + '%';
      return val;
    } else {
      return '';
    }
  }

  // TODO: create a custom cell render : no percentage / no decimal
  static renderWithoutDecimal(params) {
    if (params.value !== null) {
      const val = (parseFloat(params.value) * 100).toFixed() + '%';
      return val;
    } else {
      return '';
    }
  }

  static renderWithOneDecimal(params) {
    if (params.value !== null) {
      const val = (parseFloat(params.value) * 100).toFixed(1) + '%';
      return val;
    } else {
      return '';
    }
  }

  static renderBooleanAsYesNo(params) {
    if (params.value === true) {
      return 'Yes';
    } else if (params.value === false) {
      return 'No';
    } else if (_.isNull(params.value)) {
      return '-';
    } else {
      // This branch is needed because this cell render is also used for the cell editor, and in the cell editor
      // for these columns, there is a fourth value ("-") that needs to be rendered. This is because the AgGrid
      // agRichSelectCellEditor cell editor doesn't support nulls.
      return params.value;
    }
  }

  static renderBooleanAsEligible(params) {
    if (params.value === true) {
      return 'Eligible';
    } else if (params.value === false) {
      return `Not Eligible`;
    } else {
      return '-';
    }
  }

  static renderEnumWithNull(params) {
    if (_.isNull(params.value)) {
      return '-';
    } else {
      return params.value;
    }
  }

  static renderWithNaN(params) {
    if (params.value !== null && !Number.isNaN(params.value)) {
      return params.value;
    } else {
      return '-';
    }
  }

  static renderArrayAsCSV(params) {
    if (params.value && params.value.length) {
      return params.value.join(', ');
    } else {
      return '-';
    }
  }

  static renderUserMini(params) {
    const userMini: IUserMini = params.value;
    const noVal = '(None)';
    if (!userMini) {
      return noVal;
    }

    // There should be a single "null" user, display as "(None)"
    if (userMini.userId === null) return noVal;
    return ImUser.prototype.getFullNameWithEmailFromMini(userMini);
  }
}
