import { ISchoolSupport } from '../../shared/typings/interfaces/support.interface';
import * as supportsActions from './../actions/supports-actions';

export interface ISupportsState {
  loading: boolean;
  loaded: boolean;
  supportsEntities: {
    [id: string]: ISchoolSupport;
  };
}

export const initialState: ISupportsState = {
  loading: false,
  loaded: false,
  supportsEntities: {},
};

export function reducer (state: ISupportsState = initialState, action: supportsActions.SupportsAction): ISupportsState {
  switch (action.type) {
    case supportsActions.LOAD_SUPPORTS:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case supportsActions.LOAD_SUPPORTS_SUCCESS: {
      const supports = action.payload.data;
      const loadedSupports: ISupportsState['supportsEntities'] = supports
        .reduce((acc, support: ISchoolSupport) => {
          acc[support._id] = support;
          return acc;
        }, {});

      const mergedSupports = { ...state.supportsEntities, ...loadedSupports };
      return {
        ...state,
        loading: false,
        loaded: true,
        supportsEntities: mergedSupports,
      };
    }
    case supportsActions.LOAD_SUPPORTS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case supportsActions.UPDATE_SUPPORT_SUCCESS: {
      const { support: { _id: supportId }, patch } = action.payload;
      const supportsEntities = Object.assign({}, state.supportsEntities);
      const support = supportsEntities[supportId] ? supportsEntities[supportId] : {};
      const updatedSupport = Object.assign(support, patch);
      supportsEntities[supportId] = updatedSupport;

      return {
        ...state,
        supportsEntities,
      };
    }
    case supportsActions.CREATE_SUPPORT_SUCCESS: {
      const { support } = action.payload;
      const supportsEntities = Object.assign({}, state.supportsEntities);
      supportsEntities[support._id] = support;

      return {
        ...state,
        supportsEntities,
      };
    }
    default:
      return state;
  }
}
