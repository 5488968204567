import { ARTICLE_URLS } from './../../../../constants/article-urls.constant';
import { ChangeDetectorRef, Component, ElementRef, forwardRef, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { unsubscribeComponent } from 'Src/ng2/shared/helpers/unsubscribe-decorator/unsubscribe-decorators.helper';
import { ApiService } from 'Src/ng2/shared/services/api-service/api-service';
import { IDropdownOption } from 'projects/shared/nvps-libraries/design/interfaces/design-library.interface';
import { HelpDeskService } from '../../../../services/help-desk/help-desk.service';
import { IRowData } from './../../../../models/list-models';
import { FormValidatorsService } from './../../../../services/form-validators/form-validators.service';
import { MODALS_CONFIG_COMMON_MARKUP, MODALS_CONFIG_WIDE_NO_PADDING } from './../../../modals.config';
import {
  ISchoolUserModalShellData,
  SCHOOL_USER_BTN_CONFIG,
  TSchoolUserModalViewMode,
} from './../school-user-modals.config';
import { SchoolUserModalsDataService } from './../school-user-modals.data.service';
import { Toggles } from 'Src/ng2/shared/constants/toggles.constant';
import { ToggleService } from 'Src/ng2/shared/services/toggle/toggle.service';

@Component({
  templateUrl: './school-user-modal-shell.component.html',
  styleUrls: ['./school-user-modal-shell.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
@unsubscribeComponent
export class SchoolUserModalShellComponent implements OnInit {
  @ViewChild('warningContent', { static: false }) warningContentEl: ElementRef;
  public userBasicForm: FormGroup;
  public isCreateOrUpdateMode: boolean;
  public isCreateOnlyMode: boolean;
  public isDOEemployee: boolean;
  public canProceedToConfirm = false;
  public title: 'Add user' | 'Edit user';
  public iconName: string;
  public schoolId: string;
  public groupData: IRowData[][];
  public isTrustedAtSchoolLevel: boolean;

  // FOR 3 DROPDOWNS: (employee type, Job Role and Access Level)
  public confirmEmployeeTypeOptions: IDropdownOption[];
  public accessLevels: IDropdownOption[];
  public jobRoles: IDropdownOption[];
  // public selectedEmployeeTypeKey: boolean;
  public selectedAccessLevelKey: string;
  public selectedJobRoleKey: string;

  // FOR TEMPLATE RENDERING AND INTERPOLATION
  public userDetail$: BehaviorSubject<any> = new BehaviorSubject(null);
  public needsHelpLink: boolean;
  public emailDupesErrMsg: string;
  public generalErrMsg: string;
  public warningContent: string;
  public primaryBtnName: string;
  public originalUserAccessLevelKey: string;
  public reqEmailPlaceholder: string;
  public reqEmailType: string;
  public optEmailPlaceholder: string | null = null;
  public optEmailType: string | null = null;
  public showPrivilegeBox: boolean;
  public v4ModeIsOn: boolean;

  // PASSDOWN TO CHILD
  public user: {
    name: string;
    id: string | null;
  };

  // NOT FOR VIEW - LOCAL STATE MAINTAINING AND MANAGEMENT
  private emailDupes: string[] = [];
  private apiMode: TSchoolUserModalViewMode;

  // CONSTANTS
  readonly DOE_EMAIL_DOMAINS = ['schools.nyc.gov'];
  readonly CUSTOM_ERRS = {
    'User already exists in this school.': { needsSupport: true },
    'User already exists in another school.': { needsSupport: true },
    'User already exists as a network user.': { needsSupport: false },
  };

  readonly commonMarkupClass = MODALS_CONFIG_COMMON_MARKUP.panelClass;
  readonly noPaddingWideClass = MODALS_CONFIG_WIDE_NO_PADDING.panelClass;
  readonly manageUsersLink = ARTICLE_URLS.manageUsersLink;

  constructor (
    @Inject(MAT_DIALOG_DATA) public data: ISchoolUserModalShellData,
    @Inject(forwardRef(() => HelpDeskService)) private helpDeskService: HelpDeskService,
    private apiService: ApiService,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private formValidatorService: FormValidatorsService,
    public dataService: SchoolUserModalsDataService,
    public dialogRef: MatDialogRef<SchoolUserModalShellComponent>,
    public toggleService: ToggleService,
    private elementRef: ElementRef,
  ) {
    // ..
  }

  ngOnInit (): void {
    this.toggleV4NewSkinMode();
    switch (this.data.mode) {
      case 'CREATE':
        this.initFormControlsToCreateUser(null);
        this.title = 'Add user';
        this.primaryBtnName = SCHOOL_USER_BTN_CONFIG.CREATE;
        this.iconName = 'close-large-blue';
        this.apiMode = 'CREATE';
        this.isCreateOnlyMode = true;
        this.isCreateOrUpdateMode = true;
        break;
      case 'EDIT':
        this.getSchoolUserDetail();
        this.title = 'Edit user';
        this.primaryBtnName = SCHOOL_USER_BTN_CONFIG.EDIT;
        this.iconName = 'close-large-blue';
        this.apiMode = 'EDIT';
        this.isCreateOrUpdateMode = true;
        break;
      default:
        break;
    }

    this.schoolId = this.data.schoolId;
    this.accessLevels = this.dataService.ACCESS_LEVELS_OPTIONS;
    this.jobRoles = this.dataService.JOB_ROLES_OPTIONS;
    this.confirmEmployeeTypeOptions = this.dataService.EMPLOYEE_TYPE_OPTIONS;
  }

  private toggleV4NewSkinMode () : void {
    this.v4ModeIsOn = this.toggleService.getToggleState(Toggles.TOGGLE_V4_NEW_SKIN_MODE);
    if (this.v4ModeIsOn) {
      this.elementRef.nativeElement.classList.add('v4');
    }
  }

  ngAfterViewChecked (): void {
    this.cdr.detectChanges();
  }

  private getSchoolUserDetail (): void {
    const userId = this.data.user ? this.data.user.id : null;
    const schoolId = this.data.schoolId;
    this.dataService
      .getSchoolUserDetail({ userId, schoolId })
      .pipe(
        tap((res: any) => {
          const {
            data: { SchoolUserDetail },
          } = res;
          const userDetails = { details: SchoolUserDetail };
          this.isTrustedAtSchoolLevel = userDetails.details.isTrustedAtSchoolLevel;
          this.initFormControlsToCreateUser(userDetails);
        }),
      )
      .subscribe();
  }

  // CREATE: userDetails is null; UPDATE: userDetails are provided;
  public initFormControlsToCreateUser (userDetails) {
    let firstName, lastName, doeEmail, gafeEmail, jobRole, mappedSchoolLevelRole;

    if (userDetails) {
      firstName = userDetails.details.firstName;
      lastName = userDetails.details.lastName;
      doeEmail = userDetails.details.doeEmail;
      gafeEmail = userDetails.details.gafeEmail;
      jobRole = userDetails.details.jobRole;
      mappedSchoolLevelRole = userDetails.details.mappedSchoolLevelRole;
    }
    if ([null, 'school'].includes(mappedSchoolLevelRole)) mappedSchoolLevelRole = 'no_access';
    const isDoeEmployee = !!(userDetails && doeEmail) || this.data.mode === 'CREATE';

    const peopleNameValidator = this.formValidatorService.peopleNameValidatorCtr();

    // Set common validators for DOE employees and users using different email domains
    this.userBasicForm = this.fb.group({
      firstName: [(userDetails && firstName) || '', Validators.compose([Validators.required, peopleNameValidator])],
      lastName: [(userDetails && lastName) || '', Validators.compose([Validators.required, peopleNameValidator])],
      doeEmail: [(userDetails && doeEmail) || '', Validators.compose([Validators.required, Validators.email])],
      gafeEmail: [(userDetails && gafeEmail) || '', Validators.compose([Validators.email])],
      jobRole: [(userDetails && jobRole) || ''],
      delegatedRole: [(userDetails && mappedSchoolLevelRole) || '', Validators.compose([Validators.required])],
      isDOEemployee: isDoeEmployee,
    });

    this.onSelectEmployeeType(this.userBasicForm.get('isDOEemployee').value); // Set new validators depending of type of employee
    this.selectedAccessLevelKey = userDetails ? mappedSchoolLevelRole : '';
    this.selectedJobRoleKey = userDetails ? jobRole : '';
    this.isDOEemployee = this.userBasicForm.get('isDOEemployee').value;
  }

  // EVENT HANDLERS

  public onSelectAccessLevel ($key): void {
    if (this.userBasicForm.pristine) {
      this.userBasicForm.markAsDirty();
    }
    this.selectedAccessLevelKey = $key;
    this.userBasicForm.patchValue({
      delegatedRole: $key,
    });
  }

  public onSelectJobRole ($key: string): void {
    if (this.userBasicForm.pristine) {
      this.userBasicForm.markAsDirty();
    }
    this.selectedJobRoleKey = $key;
    this.userBasicForm.patchValue({
      jobRole: $key,
    });
  }

  public onSelectEmployeeType ($key: boolean): void {
    this.userBasicForm.patchValue({
      isDOEemployee: $key,
    });
    this.isCreateOrUpdateMode = true;
    this.isDOEemployee = this.userBasicForm.get('isDOEemployee').value;
    const hasSameGafeAndDoeEmails =
      this.userBasicForm.get('doeEmail').value === this.userBasicForm.get('gafeEmail').value;

    // Custom Validators for DOE and Google email fields;
    const validDOEemailValidator = this.formValidatorService.emailDomainValidatorCtr({ domains: ['schools.nyc.gov'] });
    const invalidDOEemailInGoogleFieldValidator = this.formValidatorService.emailDomainValidatorCtrForInvalidDomain({
      domain: 'schools.nyc.gov',
    });
    const invalidGmailDomainValidator = this.formValidatorService.emailDomainValidatorCtrForInvalidDomain({
      domain: 'gmail.com',
    });
    const invalidGoogleMailValidator = this.formValidatorService.emailDomainValidatorCtrForInvalidDomain({
      domain: 'googlemail.com',
    });

    // When the user is a DOE employee, google email field is optional / DOE email required
    if (this.isDOEemployee) {
      // If a scenario occurs where: @schools.nyc.gov email lands in the Google email field, we should check if that @schools.nyc.gov is also in the DOE email field before throwing an error:
      // If @schools.nyc.gov is in the DOE email field and is in the Google email field: VALID
      if (hasSameGafeAndDoeEmails && this.userBasicForm.get('gafeEmail').value.length > 0) {
        this.userBasicForm.get('gafeEmail').clearValidators();
        this.userBasicForm
          .get('gafeEmail')
          .setValidators([Validators.email, invalidGmailDomainValidator, invalidGoogleMailValidator]);
        this.userBasicForm.get('gafeEmail').updateValueAndValidity();
        this.userBasicForm
          .get('doeEmail')
          .setValidators([Validators.required, Validators.email, validDOEemailValidator]);
        this.userBasicForm.updateValueAndValidity();
      } else {
        this.userBasicForm.get('gafeEmail').clearValidators();
        this.userBasicForm
          .get('gafeEmail')
          .setValidators([
            Validators.email,
            invalidGmailDomainValidator,
            invalidDOEemailInGoogleFieldValidator,
            invalidGoogleMailValidator,
          ]);
        this.userBasicForm.get('gafeEmail').updateValueAndValidity();
        this.userBasicForm
          .get('doeEmail')
          .setValidators([Validators.required, Validators.email, validDOEemailValidator]);
        this.userBasicForm.updateValueAndValidity();
      }
    } else {
      this.userBasicForm.get('doeEmail').clearValidators();
      this.userBasicForm.get('doeEmail').patchValue('');
      this.userBasicForm.get('doeEmail').updateValueAndValidity();
      this.userBasicForm
        .get('gafeEmail')
        .setValidators([
          Validators.required,
          Validators.email,
          invalidGmailDomainValidator,
          invalidDOEemailInGoogleFieldValidator,
          invalidGoogleMailValidator,
        ]);
      this.userBasicForm.updateValueAndValidity();
    }
  }

  public onClickPrimaryBtn (): void {
    switch (this.primaryBtnName) {
      case SCHOOL_USER_BTN_CONFIG.CREATE:
        this._prepPayloadForApi(this.canProceedToConfirm);
        break;
      case SCHOOL_USER_BTN_CONFIG.EDIT:
        this._prepPayloadForApi(true);
        break;
      default:
        break;
    }
  }

  public onCancel (): void {
    this.dialogRef.close();
  }

  public onClearInput (controlName: string): void {
    this.userBasicForm.controls[controlName].setValue('');
    this.userBasicForm.controls[controlName].markAsDirty();
  }

  public getHelp (): void {
    this.helpDeskService.showHelp();
  }

  // API CALLS

  public _prepPayloadForApi (canProceedToConfirm): void {
    let userPayload: any = {};
    if (this.userBasicForm) {
      userPayload = {
        ...this.userBasicForm.value,
        userType: 'NYC_SCHOOL',
        nvRole: {
          type: 'school',
          schoolId: this.schoolId,
        },
        schoolType: this.data.schoolType,
      };
    }
    const jobRole = this.userBasicForm.get('jobRole').value;
    if (jobRole) {
      userPayload.jobRole = [jobRole];
    }

    if (this.data.user) {
      const userId = this.data.user.id;
      userPayload = { ...userPayload, userId };
    }
    this._sendToApi({ userPayload }, canProceedToConfirm);
  }

  private _sendToApi ({ userPayload }, canProceedToConfirm): void {
    if (!canProceedToConfirm) {
      this.apiService
        .checkDupesEmails({ userPayload }, this.apiMode, this.schoolId)
        .pipe(
          tap((res: { data: { [mutationName: string]: IRowData[][] }; errors: any[] }) => {
            this._onApiRes(res);
          }),
        )
        .subscribe();
    } else {
      this.apiService
        .mutateSchoolUser({ userPayload, columns: this.data.projectionColumns }, this.apiMode, this.schoolId)
        .pipe(
          tap((res: { data: { [mutationName: string]: IRowData[][] }; errors: any[] }) => {
            this._onApiRes(res);
          }),
        )
        .subscribe();
    }
  }

  private _onApiRes (res: { data: { [mutationName: string]: IRowData[][] }; errors: any[] }): void {
    const { data, errors } = res;
    if (errors && errors[0]) {
      const {
        extensions: {
          exception: { data },
        },
        message: errMessage,
      } = errors[0];
      this._resetErrMessages();
      const isDupesErr = !!this.CUSTOM_ERRS[errMessage];
      if (isDupesErr) {
        this._setDupesErr(errMessage, data.devMessage);
      } else {
        this.generalErrMsg = errMessage;
      }
      this.canProceedToConfirm = false;
      this.isCreateOrUpdateMode = true;
    } else if (data && Object.keys(data).length) {
      if (data.SchoolUserDupesEmails) {
        this.isCreateOrUpdateMode = false;
        this.canProceedToConfirm = true;
      } else {
        this.groupData = data.createSchoolUser || data.updateSchoolUser;
        this.dialogRef.close(this.groupData);
      }
    }
  }

  private _resetErrMessages (): void {
    this.generalErrMsg = '';
    this.emailDupesErrMsg = '';
  }

  private _setDupesErr (errMessage, devMessage): void {
    this.needsHelpLink = this.CUSTOM_ERRS[errMessage].needsSupport;
    this.emailDupesErrMsg = errMessage;
    const emailType = this._getEmailTypeForDupesErr(devMessage);
    this.userBasicForm.controls[emailType].setErrors({ dupes: true });
    this.emailDupes.push(this.userBasicForm.controls[emailType].value.trim().toLowerCase());
  }

  private _getEmailTypeForDupesErr (devMessage): string {
    let emailType = '';
    if (devMessage.match('gafeEmail')) {
      emailType = 'gafeEmail';
    }
    if (devMessage.match('doeEmail')) {
      emailType = 'doeEmail';
    }
    return emailType;
  }

  public onConfirmTrustedUser (): void {
    this._prepPayloadForApi(this.canProceedToConfirm);
  }

  public onCancelCreateUser (): void {
    this.dialogRef.close();
  }

  public onBack (): void {
    this.isCreateOrUpdateMode = true;
    this.canProceedToConfirm = false;
  }
}
