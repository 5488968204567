import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvRadialGraphComponent } from './nv-radial-graph.component';

@NgModule({
  declarations: [NvRadialGraphComponent],
  exports: [NvRadialGraphComponent],
  imports: [CommonModule],
})
export class NvRadialGraphModule {}
