<ng-container *ngIf="mode === 'attendanceLogs'">
  <base-modal class="base-modal" [title]="title" [isProfileMode]="isProfileMode">
    <support-attendance-logs-list-v2
      [schoolId]="schoolId"
      [supportId]="supportId"
      [batchActionsMode$]="batchActionsMode$"
      (openTakeAttendanceList)="openTakeAttendanceList($event)"
    ></support-attendance-logs-list-v2>
  </base-modal>
</ng-container>

<ng-container *ngIf="mode === 'takeAttendance'">
  <div class="base-modal-shell" [ngStyle]="{ display: showConfirm ? 'none' : 'block' }">
    <support-attendance-form-list-v2
      [schoolId]="schoolId"
      [supportId]="supportId"
      [eventDate]="eventDate"
      [batchActionsMode$]="batchActionsMode$"
      (goBackToSessionList)="goBackToSessionList($event)"
    ></support-attendance-form-list-v2>
  </div>
</ng-container>

<ng-container *ngIf="showConfirm === true">
  <div class="base-modal-shell">
    <div class="dialog-title">
      <div class="icon clickable close" (click)="discardChanges(false)"></div>
      <h1>{{ confirmTitle }}</h1>
    </div>
    <p>
      You have unsaved changes in <b>{{ formattedEventDate }}</b> log. Are you sure you want to discard these changes?
    </p>
  </div>
  <div class="buttons-container">
    <nv-button (click)="discardChanges(false)" type="secondary">Cancel</nv-button>
    <nv-button (click)="discardChanges(true)">Discard</nv-button>
  </div>
</ng-container>
