import { SideNavNgModule } from './../../../shell/side-nav/side-nav.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SchoolStandardLayoutComponent } from './school-standard-layout.component';

@NgModule({
  declarations: [SchoolStandardLayoutComponent],
  imports: [CommonModule, RouterModule, SideNavNgModule],
  exports: [SchoolStandardLayoutComponent],
  providers: [],
  schemas: [],
})
export class SchoolStandardLayoutModule {}
