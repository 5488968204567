import { tap, filter, switchMap, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Store, MemoizedSelector, select } from '@ngrx/store';
import { Injectable } from '@angular/core';

@Injectable()
export class StoreDataService {
  constructor (private store: Store<any>) {}

  /**
   * Returns slice of store (assumes data is loaded)
   */
  public getDataFromStore$ (selector: MemoizedSelector<object, any>): Observable<any> {
    return this.store.select(selector);
  }

  /**
   * Loads data to store if not loaded and takes the latest value
   */
  public loadDataToStore$ (payload: any, loadSelector: MemoizedSelector<object, boolean>, Action: any, sliceSelector: any): Observable<any> {
    return this.loadDataAndGetStream$(payload, loadSelector, Action, sliceSelector).pipe(take(1));
  }

  /**
   * Loads data to store if not loaded and subscribes to the slice
   */
  public loadDataAndGetStream$ (payload: any, loadSelector: MemoizedSelector<object, boolean>, Action: any, sliceSelector: any): Observable<any> {
    return this.store.pipe(
      select(loadSelector),
      tap(loaded => {
        if (!loaded) this.store.dispatch(new Action(payload));
      }),
      filter(loaded => loaded),
      switchMap(() => {
        return this.getDataFromStore$(sliceSelector);
      }),
    );
  }
}
