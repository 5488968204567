import { createFeatureSelector, createSelector } from '@ngrx/store';
import { map } from 'lodash';
import { IStudentPathsState } from '../reducers/student-paths-reducer';

const getPortalState = createFeatureSelector('PORTAL_STORE');

export const getStudentPathsState = createSelector(
  getPortalState,
  (state: any) => {
    return state.studentPaths;
  },
);

export const getStudentPathsEntities = createSelector(
  getStudentPathsState,
  (state: IStudentPathsState) => {
    return state.studentPathsEntities;
  },
);

export const getStudentPathsEntitiesList = createSelector(
  getStudentPathsState,
  (state: IStudentPathsState) => {
    const studentPathEntities = state.studentPathsEntities;
    return map(studentPathEntities, (v, k) => v);
  },
);

export const getStudentPathsLoadingStatus = createSelector(
  getStudentPathsState,
  (state: IStudentPathsState) => {
    return state.loading;
  },
);

export const getStudentPathsLoadedStatus = createSelector(
  getStudentPathsState,
  (state: IStudentPathsState) => {
    return state.loaded;
  },
);

export const getStudentPathsLoadedEntities = createSelector(
  getStudentPathsState,
  (state: IStudentPathsState) => {
    return state.loadedEntities;
  },
);

export const getAllStudentPathsLoadedStatus = createSelector(
  getStudentPathsState,
  (state: IStudentPathsState) => {
    return state.allLoaded;
  },
);
