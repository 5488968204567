import { Injectable } from '@angular/core';
import { DEPRECATED_ROUTE_PARAMS_BY_SCHOOL_ROUTE_STATE as DEPRECATED_PARAMS } from '../../constants/deprecated-routes.constant';

@Injectable()
export class DeprecatedRouteService {
  readonly DynamicRouteMapping = {
    dash_foci_att_risk_group_based_on_school_type: ({ schoolType }) => {
      if (schoolType === 'Transfer') {
        return 'ATT_RISK_GROUP_TRANSFER';
      } else {
        return 'ATT_RISK_GROUP';
      }
    },
  };

  // Decide whether the current param is deprecated
  isParamDeprecated ({
    stateOrUrl,
    routeParams,
    param,
  }: {
    stateOrUrl: string;
    routeParams: object;
    param?: string;
  }): boolean {
    if (!param) {
      return false;
    }
    const deprecatedParamsList: string[] =
      (DEPRECATED_PARAMS[stateOrUrl] &&
        DEPRECATED_PARAMS[stateOrUrl][param] &&
        Object.keys(DEPRECATED_PARAMS[stateOrUrl][param])) ||
      [];
    const isDeprecated = deprecatedParamsList.includes(routeParams[param]);
    return isDeprecated;
  }

  // Get new params for re-direct
  getNewParamsForRedirect ({
    stateOrUrl,
    routeParams,
    param,
    schoolType,
  }: {
    stateOrUrl: string;
    routeParams: object;
    param: string;
    schoolType?: string;
  }) {
    const mappedNewParamVal = DEPRECATED_PARAMS[stateOrUrl][param][routeParams[param]];
    const formattedNewParamVal = this.formatDynamicReference({
      targetStr: mappedNewParamVal,
      calcParam: { schoolType },
      DynamicRouteMapping: this.DynamicRouteMapping,
    });
    const paramsForRedirect = Object.assign({}, routeParams, {
      [param]: formattedNewParamVal,
    });
    return paramsForRedirect;
  }

  formatDynamicReference ({ targetStr, calcParam, DynamicRouteMapping }) {
    // extract data from brackets
    const regex = /\[(.*?)\]/;
    const match = regex.exec(targetStr);
    const calcFunc = match && DynamicRouteMapping[match[1]];
    if (calcFunc) {
      const newStr = calcFunc(calcParam);
      if (newStr) return targetStr.replace(match[0], newStr);
    }
    // if !match fallback to columnName
    return targetStr;
  }
}
