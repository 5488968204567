import { ExpandEdGuard } from './../../guards/expand-ed-guard/expand-ed-guard';
import { CurrentUserGuard } from '../../guards/current-user-guard/current-user-guard';
import { RouteGuard } from '../../guards/route-guard/route-guard';
import { TogglesGuard } from '../../guards/toggles-guard/toggles-guards';

export const nycExpandEdRoute = {
  path: 'expand-ed-grid',
  url: '/expand-ed-grid',
  canLoad: [CurrentUserGuard],
  data: {
    guards: [RouteGuard, TogglesGuard],
  },
  loadChildren: () =>
    import('../../../school/server-side-grid/server-side-grid.module').then(m => m.ServerSideGridModule),
  canActivate: [TogglesGuard, ExpandEdGuard],
};
