import { createFeatureSelector, createSelector } from '@ngrx/store';
import { map } from 'lodash';
import { IDataLoadsState } from '../reducers/data-loads-reducer';

const getPortalState = createFeatureSelector('PORTAL_STORE');

export const getDataLoadsState = createSelector(
  getPortalState,
  (state: any) => {
    return state.dataLoads;
  },
);

export const getDataLoadsEntities = createSelector(
  getDataLoadsState,
  (state: IDataLoadsState) => {
    return state.dataLoadsEntities;
  },
);

export const getDataLoadsEntitiesList = createSelector(
  getDataLoadsState,
  (state: IDataLoadsState) => {
    const dataLoadsEntities = state.dataLoadsEntities;
    return map(dataLoadsEntities, (v, k) => v);
  },
);

export const getDataLoadsLoadingStatus = createSelector(
  getDataLoadsState,
  (state: IDataLoadsState) => {
    return state.loading;
  },
);

export const getDataLoadsLoadedStatus = createSelector(
  getDataLoadsState,
  (state: IDataLoadsState) => {
    return state.loaded;
  },
);
