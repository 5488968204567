import * as focusActions from '../../actions/focus-actions/focus-actions';

export const initialState = {
  loading: false,
  loaded: false,
  focusData: [],
};

export function reducer(state = initialState, action: focusActions.FocusAction) {
  switch (action.type) {
    case focusActions.LOAD_FOCUS:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case focusActions.RESET_FOCUS:
      return {
        ...state,
        loading: false,
        loaded: false,
      };

    case focusActions.LOAD_FOCUS_SUCCESS:
      const focusData = action.payload;
      return {
        ...state,
        loading: false,
        loaded: true,
        focusData: { ...focusData },
      };
    default:
      return state;
  }
}
