import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { NvIconButtonModule } from '../nv-icon-button/nv-icon-button.module';
import { NvPillButtonModule } from '../nv-pill-button/nv-pill-button.module';
import { NvDatePickerCalendarComponent } from './nv-date-picker-calendar/nv-date-picker-calendar.component';
import { NvDatePickerHeaderComponent } from './nv-date-picker-header/nv-date-picker-header.component';
import { NvDatePickerComponent } from './nv-date-picker.component';

@NgModule({
  imports: [
    CommonModule,
    NvPillButtonModule,
    NvIconButtonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
  ],
  declarations: [NvDatePickerComponent, NvDatePickerCalendarComponent, NvDatePickerHeaderComponent],
  exports: [NvDatePickerComponent],
})
export class NvDatePickerModule {}
