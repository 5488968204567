<div class="nv-wizard-table credit-gaps-subject-table">
    <div class="app-headline">
        <h3>Students With Credit Gaps By Subject</h3>
    </div>

    <table class="nv-table nv-table-dense">
        <thead>
            <tr>
                <th>
                    <div>Students with</div>
                </th>
                <th *ngFor="let creditReq of orderedCreditReqs">{{ creditReq.humanShort }}</th>
            </tr>
        </thead>

        <tbody>
            <!-- ANY UNADDRESSED GAP IN SUBJECT AREA -->
            <tr class="row-unaddressed-gaps">
                <td class="mat-subtitle-2">any gap unaddressed</td>
                <td *ngFor="let cell of table.unaddressedGaps | keyvalue: orderTableRows"
                    [ngClass]="{ 'is-hovered': cell.hover && cell.value.students.length > 0 }"
                    (mouseenter)="cell.hover=true" (mouseleave)="cell.hover=false">
                    <wizard-filter-actions [data]="cell.value.students" [options]="filterOptions">
                    </wizard-filter-actions>
                </td>
            </tr>

            <!-- ALL GAPS ADDRESSED IN SUBJECT AREA -->
            <tr class="row-addressed-gaps">
                <td class="mat-subtitle-2">all gaps addressed</td>
                <td *ngFor="let cell of table.addressedGaps | keyvalue: orderTableRows"
                    [ngClass]="{ 'is-hovered': cell.hover && cell.value.students.length > 0 }"
                    (mouseenter)="cell.hover=true" (mouseleave)="cell.hover=false">
                    <wizard-filter-actions [data]="cell.value.students" [options]="filterOptions">
                    </wizard-filter-actions>
                </td>
            </tr>

            <!-- NO GAPS IN SUBJECT AREA -->
            <tr class="row-no-gaps">
                <td class="mat-subtitle-2">no gaps</td>
                <td *ngFor="let cell of table.noGaps | keyvalue: orderTableRows"
                    [ngClass]="{ 'is-hovered': cell.hover && cell.value.students.length > 0 }"
                    (mouseenter)="cell.hover=true" (mouseleave)="cell.hover=false">
                    <wizard-filter-actions [data]="cell.value.students" [options]="filterOptions">
                    </wizard-filter-actions>
                </td>
            </tr>
        </tbody>
    </table>
</div>