import { Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { IDropdownOption } from '../interfaces/design-library.interface';

/* istanbul ignore next */

/**
 *
 * This is the dropdown menu component used by `nv-dropdown`.
 *
 * Typically you won't need to use this component directly.
 * It's recommended to use the `nv-dropdown` if you need dropdown menu functionality.
 *
 * However there are times when you might need to trigger a menu from a different component.
 *
 * To build a custom dropdown (i.e. using a `nv-button` as a trigger), attach a `mat-menu` to the trigger as documented in the
 * [Material Docs](https://material.angular.io/components/menu/overview), and use this component as the contents of `mat-menu`:
 *
 * See implementation in `nv-dropdown.component.html` for an example.
 * ```
 */
@Component({
  selector: 'nv-dropdown-menu',
  templateUrl: './nv-dropdown-menu.component.html',
  styleUrls: ['./nv-dropdown-menu.component.scss'],
  exportAs: 'nvDropdownMenu',
  encapsulation: ViewEncapsulation.None,
})
export class NvDropdownMenuComponent {
  /**
   * The key of the selected option. Used for styling the selected option and its parents
   */
  @Input() selected: string;
  /**
   * The optional styling class to be applied to the menu
   */
  @Input() customClass: string;

  /**
   *
   * Array of `IDropdownOption` for the menu items
   *
   * ```
   * interface IDropdownOption {
   *  key: string,
   *  human: string,
   *  disabled?: boolean,
   *  options?: Array<IDropdownOption>
   * }
   * ```
   */
  @Input() options: IDropdownOption[];

  /**
   *
   * Emits the key of the selected `IDropdownOption`
   */
  @Output() selectItem: EventEmitter<IDropdownOption> = new EventEmitter<IDropdownOption>();

  /**
   *
   * Emits the key of the selected `IDropdownOption` by the Icon
   */
  @Output() clickIconAction: EventEmitter<IDropdownOption> = new EventEmitter<IDropdownOption>();

  public actionIconClicked: boolean = false;

  @ViewChild(MatMenu, { static: true }) public menu: MatMenu;
  @ViewChild('childMenu', { static: true }) public childMenu: MatMenu;

  handleSelectSuboption (option: IDropdownOption): void {
    this.selected = option?.key;
    this.selectItem?.emit(option);
  }

  handleItemClick (item: any): void {
    if (item?.disabled) {
      return;
    }

    if (this.actionIconClicked) {
      this.actionIconClicked = false;
    } else {
      this.selected = item?.key;
      this.selectItem?.emit(item);
    }
  }

  handleIconClick (option: any): void {
    this.actionIconClicked = true;
    if (option.disabled) {
      return;
    }

    this.clickIconAction.emit(option);
  }

  isCurrentSelected (option: IDropdownOption): boolean {
    return (option.options?.length > 0) ? option.options?.some((option) => this.isCurrentSelected(option)) : option.key === this.selected;
  }

  getBtnClass (option: IDropdownOption) {
    return this.isCurrentSelected(option) ? 'selection' : this.isParentOfSelection(option) ? 'parent-of-selection' : '';
  }

  public trackByKey (index, option) {
    return option.key;
  }

  private isParentOfSelection (option: IDropdownOption): boolean {
    // Are we a leaf?
    if (!option.options) {
      return option.key === this.selected;
    } else {
      // loop over each suboption
      for (const subopt of option.options) {
        // If we know we're a parent of the selection, then return
        if (this.isParentOfSelection(subopt)) {
          return true;
        }
      }
      return false;
    }
  }
}
