// (DS) See this ss for more details about the relationship between Credit Requirements, Departments, and Subjects:
// https://docs.google.com/spreadsheets/d/1rD_VKVzx8fJ2u0i510l9VI0ZlUtSKz_LoDnxiBHaN3g/edit#gid=2042731958

export interface IDepartment {
  camelCase: string;
  human: string;
  humanShort: string;
  sortOrder: number;
}

export const Departments = {
  ELA: {
    camelCase: 'ela',
    human: 'ELA',
    humanShort: 'ELA',
    sortOrder: 1,
  } as IDepartment,
  MATH: {
    camelCase: 'math',
    human: 'Math',
    humanShort: 'Math',
    sortOrder: 2,
  } as IDepartment,
  SS: {
    camelCase: 'ss',
    human: 'Social Studies',
    humanShort: 'SS',
    sortOrder: 3,
  } as IDepartment,
  SCI: {
    camelCase: 'sci',
    human: 'Science',
    humanShort: 'Sci',
    sortOrder: 4,
  } as IDepartment,
  LOTE: {
    camelCase: 'lote',
    human: 'LOTE',
    humanShort: 'LOTE',
    sortOrder: 5,
  } as IDepartment,
  HPE: {
    camelCase: 'hpe',
    human: 'Health/PE',
    humanShort: 'HPE',
    sortOrder: 6,
  } as IDepartment,
  ART: {
    camelCase: 'art',
    human: 'Art',
    humanShort: 'Art',
    sortOrder: 7,
  } as IDepartment,
  CTE: {
    camelCase: 'cte',
    human: 'CTE',
    humanShort: 'CTE',
    sortOrder: 8,
  } as IDepartment,
  GUIDANCE: {
    camelCase: 'guidance',
    human: 'Guidance',
    humanShort: 'Guid',
    sortOrder: 9,
  } as IDepartment,
  FUNCTIONAL: {
    camelCase: 'functional',
    human: 'Functional',
    humanShort: 'Func',
    sortOrder: 10,
  } as IDepartment,
};
