<span nvTooltip *ngIf="preps.length === 1">
  <nv-pill-button text="{{ preps[0] }}" [isInverted]="true"></nv-pill-button>
</span>

<span nvTooltip *ngIf="preps.length > 1">
  <nv-pill-button text="{{ preps[0] }}" [isInverted]="true"></nv-pill-button>
  <nv-pill-button class="second-pill" text="+{{ preps.length - 1 }}" [isInverted]="true"></nv-pill-button>
</span>

<!-- If course has no preps and array returns null  → display em-dash ( – ) only ( do not display pill for null values) -->
<span *ngIf="!preps.length">—</span>
