import { DefaultSdcConfigService } from './../../../../../app/sdc/sdc-state/sdc-configs/default-sdc-config.service';
import { getSdcGridState } from './../../../../store/selectors/sdc-selectors/sdc-selectors';
import { CreditGapsConfigService } from './../../../../../app/sdc/sdc-state/sdc-configs/credit-gaps-config.service';
import { GradPlanningConfigService } from 'Src/app/sdc/sdc-state/sdc-configs/grad-planning-config.service';
import { RegentsPrepConfigService } from './../../../../../app/sdc/sdc-state/sdc-configs/regents-prep-config.service';
import { RegentsPlanningConfigService } from './../../../../../app/sdc/sdc-state/sdc-configs/regents-planning-config.service';
import { map, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ObjectCache } from 'Src/ng2/shared/services/object-cache/object-cache.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { from } from 'rxjs';

interface ICacheObject {
  id: string;
  json: string;
}

@Injectable()
export class InitialGridStateResolver implements Resolve<any> {
  private configMap;

  constructor (
    private store: Store<any>,
    private objectCache: ObjectCache,
    private regentsPlanningConfig: RegentsPlanningConfigService,
    private regentsPrepConfig: RegentsPrepConfigService,
    private gradPlanningConfig: GradPlanningConfigService,
    private creditGapsConfig: CreditGapsConfigService,
    private defaultGridConfig: DefaultSdcConfigService,
  ) {
    this.configMap = {
      'regents-planning': this.regentsPlanningConfig,
      'regents-prep': this.regentsPrepConfig,
      'grad-planning': this.gradPlanningConfig,
      'credit-gaps': this.creditGapsConfig,
      'default-sdc': this.defaultGridConfig,
    };
  }

  resolve (route: ActivatedRouteSnapshot) {
    const { sdcKey } = route.data;

    const defaultConfig = this.configMap[sdcKey];

    const defaultGridState = {
      columnKeys: defaultConfig.gridColumns,
      joins: defaultConfig.joins,
      filterModel: defaultConfig.filter,
      sortModel: defaultConfig.sort,
    };

    const { gridState: gridStateHash, filter: filterHash } = route.queryParams;

    // angularJS components currently navigate to the grid with a `filter` hash, this ensures we continue to support that
    if (filterHash) {
      const cacheObjectPromise: Promise<ICacheObject> = this.objectCache.getObject(filterHash);
      return from(cacheObjectPromise).pipe(map(filterModel => ({
        columnKeys: defaultConfig.gridColumns,
        joins: defaultConfig.joins,
        sortModel: defaultConfig.sort,
        filterModel,
      })));
    }

    if (gridStateHash) {
      const cacheObjectPromise: Promise<ICacheObject> = this.objectCache.getObject(gridStateHash);
      return from(cacheObjectPromise);
    }

    return this.store.select(getSdcGridState(sdcKey)).pipe(
      map((storeState) => {
        return storeState ? { ...defaultGridState, ...storeState } : defaultGridState;
      }),
      take(1),
    );
  }
}
