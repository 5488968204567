import { StoreDataService } from './../../../shared/services/store-data-service/store-data.service';
import { CourseDiffsAndGapPlansDataService } from './course-diffs-and-gap-plans-data/course-diffs-and-gap-plans-data.service';
import { CourseDiffsAndGapPlansRoutingModule } from './course-diffs-and-gap-plans-routing.module';
import { CourseDiffsAndGapPlansContainer } from './course-diffs-and-gap-plans-container.component';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ListContainerModule } from 'Src/ng2/shared/components/list/list-container/list-container.module';
import { NvDesignLibraryModule } from 'Src/nvps-libraries/design/nv-design-library.module';
import { PageHeaderModule } from 'Src/ng2/shared/components/header/page-header/page-header.module';
import { NvIconModule } from 'projects/shared/nvps-libraries/design/nv-icon/nv-icon.module';

@NgModule({
  declarations: [
    CourseDiffsAndGapPlansContainer,
  ],
  providers: [CourseDiffsAndGapPlansDataService, StoreDataService],
  imports: [CommonModule, CourseDiffsAndGapPlansRoutingModule, ListContainerModule, NvDesignLibraryModule, PageHeaderModule, NvIconModule],
  exports: [CourseDiffsAndGapPlansContainer],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CourseDiffsAndGapPlansContainerModule {}
