import { getSdcFlattenedStudentList } from './../../../store/selectors/sdc-selectors/sdc-selectors';
import { MatDialog } from '@angular/material/dialog';
import { tap, take, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ObjectCache } from 'Src/ng2/shared/services/object-cache/object-cache.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { StudentListPreviewV2 } from 'Src/ng2/shared/modals/student-list-preview-v2/student-list-preview-v2.component';
import { UrlPathService } from 'Src/ng2/shared/services/url-path-service/url-path.service';

@Component({
  selector: 'wizard-filter-actions',
  templateUrl: 'wizard-filter-actions.component.html',
  styleUrls: ['./wizard-filter-actions.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WizardFilterActions {
  public uiReady: boolean = false;
  public filterActions;
  public displayBody: boolean;

  @Input() data;
  @Input() options;
  @Input() additionalActions;
  @Input() sdcKey: string;

  constructor (
    private objectCache: ObjectCache,
    private route: ActivatedRoute,
    private Router: Router,
    private store: Store<any>,
    public dialog: MatDialog,
    private urlPathService: UrlPathService,
  ) { }

  ngOnInit (): void {
    this.filterActions = Object.assign({}, this.filterDefaultActions, this.additionalActions);
  }

  private setFilter (ids: Array<string>): string {
    return this.objectCache.cacheObject({ studentId: { values: ids } });
  }

  private filterDefaultActions = {
    0: {
      open: (studentIds: string[], { columnKeys }): void => {
        this.store.select(getSdcFlattenedStudentList)
          .pipe(
            map((students) => students.filter(({ studentId }) => studentIds.includes(studentId))),
            tap((filteredStudents) => this.dialog.open(StudentListPreviewV2, {
              data: {
                students: filteredStudents,
                columnDefs: columnKeys,
              },
              panelClass: 'student-list-preview-v2',
            })),
            take(1),
          ).subscribe();
      },
      label: 'Preview students',
    },
    1: {
      open: (studentIds: string[], options): void => {
        const filter = this.setFilter(studentIds);
        const { schoolId } = this.route.snapshot.params;
        const url = this.urlPathService.computeDistrictUrlPath(`/school/${schoolId}/${this.route.snapshot.data.sdcKey}/grid`);
        this.Router.navigate([url], { queryParams: { filter } });
      },
      label: 'View in grid',
    },
    2: {
      open: (studentIds: string[]): void => {
        const { schoolId } = this.route.snapshot.params;
        const _id = studentIds.map((studentId) => `${studentId}${schoolId}`);
        const filter = this.objectCache.cacheObject({ _id });
        const url = this.urlPathService.computeDistrictUrlPath(`/school/${schoolId}/profile`);
        this.Router.navigate([url], { queryParams: { filter }, state: { sdcKey: this.route.snapshot.data.sdcKey } });
      },
      label: 'View profiles',
    },
  };
}
