import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'Src/ng2/shared/services/api-service/api-service';
import { ICluster } from 'Src/ng2/shared/typings/interfaces/cluster.interface';
import { TValidPartnerTypes, IPartner } from 'Src/ng2/shared/typings/interfaces/partner.interface';

@Injectable()
export class PickerDataService {
  constructor(private apiService: ApiService) {}

  // get schools or shelters that this user has access to
  getPartners(partnerType: TValidPartnerTypes): Observable<IPartner[]> {
    const params = { type: partnerType };
    let projections;
    switch (partnerType) {
      case 'shelter':
        projections = ['_id', 'partnerName', 'subLocations'];
        break;
      case 'school':
      default:
        projections = ['_id', 'fullName', 'nickName'];
        break;
    }
    return this.apiService.getPartners({ params, projections });
  }

  getPartner (partnerType: TValidPartnerTypes, partnerId: string): Observable<IPartner> {
    const params = { type: partnerType, partnerId };
    const projections = ['_id', 'partnerName', 'subLocations'];
    return this.apiService.getPartner({ params, projections });
  }

  // get school clusters or shelter clusters that this user has access to
  getClusters(partnerType: TValidPartnerTypes): Observable<ICluster[]> {
    const params = { type: partnerType };
    const projections = ['_id', 'clusterName'];
    return this.apiService.getClustersByClusterType({ params, projections });
  }
}
