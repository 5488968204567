export const FLATTENED_STUDENT_MAP_FOR_DESSA = {
  dessaSelRatingDatePreAssessment: {
    tag: 'Dessa',
    tags: ['Dessa', 'SEL'],
    humanName: 'DESSA (SEL): Rating Date (Pre-Assessment)',
  },
  dessaSelRatingDatePostAssessment: {
    tag: 'Dessa',
    tags: ['Dessa', 'SEL'],
    humanName: 'DESSA (SEL): Rating Date (Post-Assessment)',
  },
  dessaSelDescriptiveRangePreAssessment: {
    tag: 'Dessa',
    tags: ['Dessa', 'SEL'],
    humanName: 'DESSA (SEL): Descriptive Range (Pre-Assessment)',
  },
  dessaSelDescriptiveRangePostAssessment: {
    tag: 'Dessa',
    tags: ['Dessa', 'SEL'],
    humanName: 'DESSA (SEL): Descriptive Range (Post-Assessment)',
  },
  dessaSelSecTScorePreAssessment: {
    tag: 'Dessa',
    tags: ['Dessa', 'SEL'],
    humanName: 'DESSA (SEL): SEC (T-Score) (Pre-Assessment)',
  },
  dessaSelSecTScorePostAssessment: {
    tag: 'Dessa',
    tags: ['Dessa', 'SEL'],
    humanName: 'DESSA (SEL): SEC (T-Score) (Post-Assessment)',
  },
  dessaSelSelfAwarePreAssessment: {
    tag: 'Dessa',
    tags: ['Dessa', 'SEL'],
    humanName: 'DESSA (SEL): Self-Aware (Pre-Assessment)',
  },
  dessaSelSelfAwarePostAssessment: {
    tag: 'Dessa',
    tags: ['Dessa', 'SEL'],
    humanName: 'DESSA (SEL): Self-Aware (Post-Assessment)',
  },
  dessaSelSelfMgmtPreAssessment: {
    tag: 'Dessa',
    tags: ['Dessa', 'SEL'],
    humanName: 'DESSA (SEL): Self-Mgmt (Pre-Assessment)',
  },
  dessaSelSelfMgmtPostAssessment: {
    tag: 'Dessa',
    tags: ['Dessa', 'SEL'],
    humanName: 'DESSA (SEL): Self-Mgmt (Post-Assessment)',
  },
  dessaSelSocialAwarePreAssessment: {
    tag: 'Dessa',
    tags: ['Dessa', 'SEL'],
    humanName: 'DESSA (SEL): Social Aware (Pre-Assessment)',
  },
  dessaSelSocialAwarePostAssessment: {
    tag: 'Dessa',
    tags: ['Dessa', 'SEL'],
    humanName: 'DESSA (SEL): Social Aware (Post-Assessment)',
  },
  dessaSelRelSkillsPreAssessment: {
    tag: 'Dessa',
    tags: ['Dessa', 'SEL'],
    humanName: 'DESSA (SEL): Rel. Skills (Pre-Assessment)',
  },
  dessaSelRelSkillsPostAssessment: {
    tag: 'Dessa',
    tags: ['Dessa', 'SEL'],
    humanName: 'DESSA (SEL): Rel. Skills (Post-Assessment)',
  },
  dessaSelGoalDirectedPreAssessment: {
    tag: 'Dessa',
    tags: ['Dessa', 'SEL'],
    humanName: 'DESSA (SEL): Goal-Directed (Pre-Assessment)',
  },
  dessaSelGoalDirectedPostAssessment: {
    tag: 'Dessa',
    tags: ['Dessa', 'SEL'],
    humanName: 'DESSA (SEL): Goal-Directed (Post-Assessment)',
  },
  dessaSelPersonalResponsPreAssessment: {
    tag: 'Dessa',
    tags: ['Dessa', 'SEL'],
    humanName: 'DESSA (SEL): Personal Respons. (Pre-Assessment)',
  },
  dessaSelPersonalResponsPostAssessment: {
    tag: 'Dessa',
    tags: ['Dessa', 'SEL'],
    humanName: 'DESSA (SEL): Personal Respons. (Post-Assessment)',
  },
  dessaSelDecisionMakingPreAssessment: {
    tag: 'Dessa',
    tags: ['Dessa', 'SEL'],
    humanName: 'DESSA (SEL): Decision Making (Pre-Assessment)',
  },
  dessaSelDecisionMakingPostAssessment: {
    tag: 'Dessa',
    tags: ['Dessa', 'SEL'],
    humanName: 'DESSA (SEL): Decision Making (Post-Assessment)',
  },
  dessaSelOptimThinkingPreAssessment: {
    tag: 'Dessa',
    tags: ['Dessa', 'SEL'],
    humanName: 'DESSA (SEL): Optim. Thinking (Pre-Assessment)',
  },
  dessaSelOptimThinkingPostAssessment: {
    tag: 'Dessa',
    tags: ['Dessa', 'SEL'],
    humanName: 'DESSA (SEL): Optim. Thinking (Post-Assessment)',
  },
};
