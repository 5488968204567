/* eslint-disable no-unused-vars */
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { IRowData, IColumn } from 'Src/ng2/shared/models/list-models';
import _ from 'lodash';

enum Arrow {
  Increase = 'increase-green',
  Decrease = 'decrease-red',
}

@Component({
  selector: 'app-arrow-cell',
  templateUrl: './arrow-cell-renderer.component.html',
  styleUrls: ['./arrow-cell-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class ArrowCellRenderer implements ICellRendererAngularComp, OnInit {
  @Input() input: { 
    row: IRowData[]; column: IRowData; dynamicComponentInputData?: any 
  };
  public displayValue: string;
  public icon: Arrow;
  protected params: ICellRendererParams;
  public emDash: boolean
  public cellClass: string;

  //agInit is used by the grid cell renderer pattern
  public agInit(params: ICellRendererParams): void {
    this.params = params;
    if(this.params) {
      this.valueFormatter(params.value);
    }
  }

  //ngOnInit is used by the list dynamic component pattern
  public ngOnInit(): void {
    if (this?.input?.column?.data) {
      this.valueFormatter(this.input.column.data);
    } else {
      // We are checking for the Data Grid here
      if(this.params){
        this.valueFormatter(this.params.value);
      } else {
        this.displayValue = '—';
        this.cellClass = 'em-dash';
      }
    }
  }

  public valueFormatter(value){
    if(value){
      if(value === '—'){
        this.displayValue = '—';
        this.cellClass = 'em-dash';
      } else {
        this.displayValue = `${value}`;
        this.setIcon(value);
      }
    } else {
      this.displayValue = '—';
      this.cellClass = 'em-dash';
    }
  }

  protected setIcon(paramValue: any): void {
    if (parseFloat(paramValue) > 0) {
      this.icon = Arrow.Increase;
    } else if (parseFloat(paramValue) < 0) {
      this.icon = Arrow.Decrease;
    }
  }

  protected includesPercentSign(value): boolean {
    if (_.isString(value)) {
      return value.includes("%") ? true : false;
    } else {
      return false;
    }
  }

  public refresh(params: ICellRendererParams): boolean {
    return false;
  }
}
