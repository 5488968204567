<base-modal
  class="base-modal support-status-modal"
  [title]="title"
  [itemCount]="itemCount"
  [itemType]="itemType"
  [isProfileMode]="isProfileMode"
>
  <div class="modal-message">
    Are you sure you want to delete the support:
    <div class="support-name">{{ supportName }}<span class="modal-message">?</span></div>
    Attendance logged for this support for each student will be removed.
  </div>
  <mat-dialog-actions class="dialog-actions">
    <button class="v3-btn secondary" type="button" (click)="close()" mat-button>{{ cancelButtonLabel }}</button>
    <button class="v3-btn primary" mat-button (click)="confirmAction(mode)">{{ confirmationButtonLabel }}</button>
  </mat-dialog-actions>
</base-modal>
