import { PartnerTypes } from './partner.interface';

export enum PORTAL_TYPES {
  // eslint-disable-next-line no-unused-vars
  ALL = 'all',
  // eslint-disable-next-line no-unused-vars
  // keep NETWORK in order to track original school network events in Mixpanel
  NETWORK = 'NETWORK',
  // eslint-disable-next-line no-unused-vars
  SCHOOL = 'SCHOOL',
  // eslint-disable-next-line no-unused-vars
  SHELTER = 'SHELTER',
  // eslint-disable-next-line no-unused-vars
  SHELTER_NETWORK = 'SHELTER_NETWORK',
}

export type TPortalLocation = keyof typeof PORTAL_TYPES;

export const PORTAL_TYPE_MAP_TO_PARTNER_TYPE = {
  [PORTAL_TYPES.NETWORK]: PartnerTypes.SCHOOL_NETWORK,
  [PORTAL_TYPES.SCHOOL]: PartnerTypes.SCHOOL,
  [PORTAL_TYPES.SHELTER_NETWORK]: PartnerTypes.SHELTER_NETWORK,
  [PORTAL_TYPES.SHELTER]: PartnerTypes.SHELTER,
};

export const PARTNER_TYPE_MAP_TO_PORTAL_TYPE = {
  [PartnerTypes.SCHOOL_NETWORK]: PORTAL_TYPES.NETWORK,
  [PartnerTypes.SCHOOL]: PORTAL_TYPES.SCHOOL,
  [PartnerTypes.SHELTER_NETWORK]: PORTAL_TYPES.SHELTER_NETWORK,
  [PartnerTypes.SHELTER]: PORTAL_TYPES.SHELTER,
};
