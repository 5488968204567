import { Injectable, Injector } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import * as partnerOrgsActions from '../actions/partner-orgs-actions';
import { ApiService } from './../../shared/services/api-service/api-service';

@Injectable()
export class PartnerOrgsEffects {
  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store: Store<any>,
    private injector: Injector,
  ) {}

  loadPartnerOrgs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(partnerOrgsActions.LOAD_PARTNER_ORGS),
      switchMap((action: { type: string; payload: any }) => {
        const { schoolId, where } = action.payload;
        return this.apiService.getPartnerOrgs({ schoolId, where }).pipe(
          switchMap((partnerOrgs: any) => [new partnerOrgsActions.LoadPartnerOrgsSuccess(partnerOrgs)]),
          catchError(error => of(new partnerOrgsActions.LoadPartnerOrgsFail(error))),
        );
      }),
    );
  });

  updatePartnerOrg$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(partnerOrgsActions.UPDATE_PARTNER_ORG),
      switchMap((action: { type: string; payload: any }) => {
        const { partnerOrgId, patch } = action.payload;

        return this.apiService.patchPartnerOrg({ partnerOrgId, patch }).pipe(
          switchMap((partnerOrg: any) => {
            return [new partnerOrgsActions.UpdatePartnerOrgSuccess(partnerOrg)];
          }),
          catchError(error => {
            return of(new partnerOrgsActions.UpdatePartnerOrgFail(error));
          }),
        );
      }),
    );
  });

  createPartnerOrg$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(partnerOrgsActions.CREATE_PARTNER_ORG),
      switchMap((action: { type: string; payload: { schoolId: string; partnerOrgParams: { name: string } } }) => {
        const { schoolId, partnerOrgParams } = action.payload;

        return this.apiService.createPartnerOrg(schoolId, partnerOrgParams).pipe(
          switchMap((partnerOrg: any) => {
            return [new partnerOrgsActions.CreatePartnerOrgSuccess(partnerOrg)];
          }),
          catchError(error => {
            return of(new partnerOrgsActions.CreatePartnerOrgFail(error));
          }),
        );
      }),
    );
  });
}
