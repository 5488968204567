import { NvTooltipModule } from 'Src/ng2/shared/nv-tooltip/nv-tooltip.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NvIconModule } from 'projects/shared/nvps-libraries/design/nv-icon/nv-icon.module';
import { NvDropdownModule } from './../../../../nvps-libraries/design/nv-dropdown/nv-dropdown.module';
import { ECFIKManagementService } from './../../../school/ecfik-management/ecfik-management.service';
import { AddCheckInLogComponent } from './add-check-in-log/add-check-in-log.component';
import { NvDatePickerModule } from './../../../../nvps-libraries/design/nv-date-picker/nv-date-picker.module';
import { NvFormFieldModule } from './../../../../nvps-libraries/design/nv-form-field/nv-form-field.module';
import { BaseModalModule } from './../base-modal.module';
import { NvMultipickerModule } from '../../../../nvps-libraries/design/nv-multi-picker/nv-multi-picker.module';
import { NvTextareaModule } from '../../../../nvps-libraries/design/nv-textarea/nv-textarea.module';
import { MatDialogModule } from '@angular/material/dialog';
import { NvButtonModule } from 'projects/shared/nvps-libraries/design/nv-button/nv-button.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CheckInLogModalComponent } from './check-in-log-modal.component';
import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    ReactiveFormsModule,
    BaseModalModule,
    NvFormFieldModule,
    NvButtonModule,
    NvDatePickerModule,
    NvDropdownModule,
    NvTextareaModule,
    NvMultipickerModule,
    NvIconModule,
    NvTooltipModule,
    MatCheckboxModule,
  ],
  exports: [],
  declarations: [
    CheckInLogModalComponent,
    AddCheckInLogComponent,
  ],
  providers: [ECFIKManagementService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CheckInLogModalModule {}
