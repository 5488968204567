import { Injectable } from '@angular/core';

import { TValidShelterSuccessMentorModes } from 'Src/ng2/shared/modals/shelter/shelter-success-mentor/shelter-success-mentor-shell/shelter-success-mentor-shell.component';
import { SHELTER_SUCCESS_MENTOR_CONFIGS } from 'Src/ng2/shared/modals/shelter/shelter-success-mentor/shelter-success-mentor-shell/shelter-success-mentor-shell.config';

@Injectable()
export class GraphQlShelterSuccessMentorHelperService {
  public getInProgressShelterSuccessMentorQuery (payload): string {
    const { caresIds, shelterId } = payload;
    const caresIdsFormatted = this.getStudentIds(caresIds);

    return `{
      ShelterInProgressSuccessMentor(
        caresIds: [${caresIdsFormatted}],
        shelterId: "${shelterId}",
      ) {
        caresId
        _id
        startDate
      }
    }`;
  }

  public getMutateShelterSuccessMentorQueryForProfile (payload: any, mode: TValidShelterSuccessMentorModes): string {
    let mutationName: string;
    let options = '';
    switch (mode) {
      case SHELTER_SUCCESS_MENTOR_CONFIGS.ASSIGN.mode:
        mutationName = 'createShelterSuccessMentor';
        break;
      case SHELTER_SUCCESS_MENTOR_CONFIGS.MARK_COMPLETE.mode:
        mutationName = 'updateShelterSuccessMentor';
        break;
      case SHELTER_SUCCESS_MENTOR_CONFIGS.DELETE.mode:
        mutationName = 'updateShelterSuccessMentor';
        break;
      case SHELTER_SUCCESS_MENTOR_CONFIGS.EDIT.mode:
        mutationName = 'updateShelterSuccessMentor';
        break;
    }
    options += this._getProfilePayload(payload, mode);

    return `mutation {
      ${mutationName}(
        options: {${options}}
      )
      {
        _id
        startDate
        endDate
      }
    }`;
  }

  public getMutateShelterSuccessMentorQuery (payload: any, mode: TValidShelterSuccessMentorModes): string {
    let mutationName: string;
    let options = '';
    switch (mode) {
      case SHELTER_SUCCESS_MENTOR_CONFIGS.ASSIGN.mode:
        mutationName = 'bulkCreateShelterSuccessMentor';
        break;
      case SHELTER_SUCCESS_MENTOR_CONFIGS.MARK_COMPLETE.mode:
        mutationName = 'bulkUpdateShelterSuccessMentor';
        break;
      case SHELTER_SUCCESS_MENTOR_CONFIGS.DELETE.mode:
        mutationName = 'bulkUpdateShelterSuccessMentor';
        break;
    }

    options += this._getBasicPayload(payload, mode);

    return `mutation {
      ${mutationName}(
        options: {${options}}
      ) {
        backgroundJobId
      }
    }`;
  }

  // HELPER METHODS

  private _getBasicPayload (payload, mode: string): string {
    const {caresIds, shelterId, mentor, frequency, startDate, status, endDate } = payload;
    const caresIdsFormatted = this.getStudentIds(caresIds);
    let options;

    if(mode === SHELTER_SUCCESS_MENTOR_CONFIGS.ASSIGN.mode) {
      options = `
        caresIds: [${caresIdsFormatted}], 
        shelterId: "${shelterId}",
        mentor: "${mentor}",
        frequency: "${frequency}", 
        startDate: "${startDate}" 
    `;
    }

    if((mode === SHELTER_SUCCESS_MENTOR_CONFIGS.MARK_COMPLETE.mode) || 
       (mode === SHELTER_SUCCESS_MENTOR_CONFIGS.DELETE.mode)) {
      options = `
        caresIds: [${caresIdsFormatted}], 
        shelterId: "${shelterId}",
        status: "${status}",
        endDate: "${endDate}"
    `;
    }
    
    if((mode === SHELTER_SUCCESS_MENTOR_CONFIGS.EDIT.mode)) {
      options = `
        caresIds: [${caresIdsFormatted}], 
        shelterId: "${shelterId}",
        status: "${status}",
        startDate: "${startDate}"
    `;
    }

    return options;
  }

  private _getProfilePayload (payload, mode: string): string {
    const {caresIds, shelterId, mentor, frequency, startDate, status, endDate, _id } = payload;
    let options;

    if(mode === SHELTER_SUCCESS_MENTOR_CONFIGS.ASSIGN.mode) {
      options = `
        caresId: "${caresIds[0]}", 
        shelterId: "${shelterId}",
        mentor: "${mentor}",
        frequency: "${frequency}", 
        startDate: "${startDate}" 
    `;
    }

    if((mode === SHELTER_SUCCESS_MENTOR_CONFIGS.MARK_COMPLETE.mode) || 
       (mode === SHELTER_SUCCESS_MENTOR_CONFIGS.DELETE.mode)) {
      options = `
        _id: "${_id}", 
        shelterId: "${shelterId}",
        status: "${status}",
        startDate: "${startDate}",
        endDate: "${endDate}"
    `;
    }
    
    if((mode === SHELTER_SUCCESS_MENTOR_CONFIGS.EDIT.mode)) {
      options = `
        _id: "${_id}",  
        shelterId: "${shelterId}",
        status: "${status}",
        startDate: "${startDate}"
    `;
    }

    return options;
  }

  private getStudentIds (ids: string[]): string[] {
    return ids.map(id => {
      return `"${id}"`;
    });
  }
}
