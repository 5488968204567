export const REMOTE_INTERACTION_TILES = {
  'Interaction rate during remote learning': 'Interaction rate during remote learning',
  'Disconnected since start of remote learning': 'Disconnected since start of remote learning',
  'Interaction rate for last school day': 'Interaction rate for last school day',
};

export const REMOTE_INTERACTION_MIDLEVEL = {
  INTERACTION_RATE_LAST_DAY: 'INTERACTION_RATE_LAST_DAY',
  DISCONNECTED_SINCE_REMOTE: 'DISCONNECTED_SINCE_REMOTE',
  INTERACTION_RATE_SINCE_REMOTE: 'INTERACTION_RATE_SINCE_REMOTE',
};
