import { ObjectCache } from 'Src/ng2/shared/services/object-cache/object-cache.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, from } from 'rxjs';

interface ICacheObject {
  id: string;
  json: string;
}

@Injectable()
export class CaresIdsResolver implements Resolve<any> {
  constructor (private objectCache: ObjectCache) {}

  resolve (route: ActivatedRouteSnapshot): Observable<ICacheObject> {
    const { students: caresIdsHash } = route.queryParams;
    const cacheObjectPromise: Promise<ICacheObject> = this.objectCache.getObject(caresIdsHash);
    return from(cacheObjectPromise);
  }
}
