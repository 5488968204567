<textArea
  ngDefaultControl
  class="textarea-content"
  [(ngModel)]="textContent"
  [attr.maxlength]="maxCharacters"
  [placeholder]="placeholder"
  (input)="handleChange($event)"
></textArea>

<div id="textarea-character-count" *ngIf="maxCharacters && !hideCharsRemaining">
  {{ charsRemaining }}
</div>
