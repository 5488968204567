import { IBatchActionMetadata } from './../mixpanel/event-interfaces/batch-action';
import { IExportedCsvMetadata } from 'Src/ng2/shared/services/mixpanel/event-interfaces/export-csv';
import { GraphQLExperiencesHelperService } from './../graphql-helpers/experiences/experiences-queries.service';
import { ISELParams } from './../../../school/lists/sel-support-roster-list/sel-support-roster-params.interface';
import { IFieldOptionsDict } from './../../typings/interfaces/studentPaths.interface';
import { GraphQlStudentPathsHelperService } from './../graphql-helpers/student-paths/student-paths-queries.service';
import { GraphQLStudentDessaHelperService } from './../graphql-helpers/student-dessa/student-dessa-queries.service';
import { ICourseDiff } from 'Src/ng2/shared/typings/interfaces/course-diff.interface';
import { IGapPlan } from 'Src/ng2/shared/typings/interfaces/gap-plan.interface';
import { ISupportOptPayload } from './../../modals/support/support-modal.interface';
import { ISchool } from 'Src/ng2/shared/typings/interfaces/school.interface';
import { IUser } from 'Src/ng2/shared/typings/interfaces/user.interface';
import { IAttendanceRecord } from 'Src/ng2/shared/typings/interfaces/attendance-record.interface';
import { ILoadSchoolAssessmentsPayload } from './../../../store/actions/assessments-actions/school-assessments-actions';
import { ILoadStudentAssessmentsPayload } from './../../../store/actions/assessments-actions/student-assessments-actions';
import { IStudent, TSingleStudentViewOtherSchoolTypes } from './../../typings/interfaces/student.interface';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable, SecurityContext } from '@angular/core';
import { map, set, size, chunk } from 'lodash';
import { Observable, throwError, forkJoin, of } from 'rxjs';
import { catchError, map as rxMap, tap } from 'rxjs/operators';
import {
  IBulkUpdateSchoolAssessmentsPayload,
  IBulkUpdateStudentAssessmentsPayload,
  ICreateSchoolAssessmentPayload,
} from '../../../store';
import { ICreateSyncToolPayload, IDeleteSyncToolPayload, IUpdateSyncToolPayload } from '../../../store/actions/tools-actions';
import { SorterColumnDataType } from '../../constants/sorter-column-data-type.constant';
import { TClusterUserModalViewMode } from '../../modals/user-management/cluster-user/school-cluster-user/cluster-user-modals.config';
import { IDataLoadParams } from '../../typings/interfaces/data-load.interface';
import { IStudentSupport } from '../../typings/interfaces/student-support.interface';
import { ISchoolSupport } from '../../typings/interfaces/support.interface';
import { ITool } from '../../typings/interfaces/tool.interface';
import { GraphQLActivitiesHelperService } from '../graphql-helpers/activities/activities-queries.service';
import { GraphQLDataLoadsHelperService } from '../graphql-helpers/data-loads/data-loads-queries.service';
import { GraphQLSchoolAssessmentsHelperService } from '../graphql-helpers/school-assessments/school-assessments-queries.service';
import { GraphQLPartnerHelperService } from '../graphql-helpers/partners/partner-queries.service';
import { GraphQLClusterHelperService } from '../graphql-helpers/clusters/cluster-queries.service';
import { GraphQLStudentAssessmentsHelperService } from '../graphql-helpers/student-assessments/student-assessments-queries.service';
import { GraphQLSchoolClusterUserHelperService } from '../graphql-helpers/user-management/cluster-user/school-cluster-user-queries.service';
import { PortalConfig } from '../portal-config';
import { IFlag } from './../../typings/interfaces/flags.interface';
import { UtilitiesService } from './../utilities/utilities.service';
import { IApi, IApiStatus } from './api-service.interface';
import { ALL_PARTNER_TYPES, IPartner, PartnerTypes, TValidPartnerTypes } from '../../typings/interfaces/partner.interface';
import { GraphQlShelterClusterUserHelperService } from '../graphql-helpers/user-management/cluster-user/shelter-cluster-user-queries.service';
import { INotification } from '../../typings/interfaces/notification.interface';
import { GraphQLSchoolUserHelperService } from '../graphql-helpers/user-management/school-user/school-user-queries.service';
import { TSchoolUserModalViewMode } from '../../modals/user-management/school-user/school-user-modals.config';
import { ISchoolNote, IShelterNote } from '../../typings/interfaces/note.interface';
import { ICluster } from '../../typings/interfaces/cluster.interface';
import { IStudentMapGrowthEntity } from 'Src/ng2/store/reducers/map-growth-reducer/student-map-growth-reducer';
import { IStudentAcadienceEntity } from 'Src/ng2/store/reducers/student-acadience-reducer/student-acadience-reducer';
import { IIntercomUser } from '../help-desk/help-desk.service';
import { IStudentSelSupport } from '../../typings/interfaces/student-sel-support.interface';
import { GraphQLStudentMapGrowthHelperService } from '../graphql-helpers/student-map-growth/student-map-growth-queries.service';
import { GraphQLProgramChangesHelperService } from '../graphql-helpers/course-diffs-and-gap-plans/program-changes-queries.service';
import { GraphQLGapPlansHelperService } from './../graphql-helpers/gap-plans/gap-plans-queries.service';
import { GraphQLStudentAcadienceHelperService } from '../graphql-helpers/student-acadience/student-acadience-queries.service';
import { GraphQLStudentIReadyHelperService } from '../graphql-helpers/student-iready/student-iready-queries.service';
import { GraphQLSELSupportHelperService } from '../graphql-helpers/sel-supports/sel-supports-queries.service';
import { GraphQLCsvDownloadsService } from '../graphql-helpers/csv-downloads/csv-downloads.queries.service';
import { IPostSecondaryPathFieldOptionParams } from '../../typings/interfaces/studentPaths.interface';
import { GraphQLStudentDocLogsHelperService } from '../graphql-helpers/student-docLogs/student-docLogs-queries.service';
import { CsvType } from '../csv-exporter/csv-exporter.service';
import { GraphQlShelterSuccessMentorHelperService } from '../graphql-helpers/shelter/success-mentor/success-mentor-queries.service';
import { IAssignSuccessMentorPayload, IUpdateSuccessMentorPayload, TValidShelterSuccessMentorModes } from '../../modals/shelter/shelter-success-mentor/shelter-success-mentor-shell/shelter-success-mentor-shell.component';
import { GraphQLNvConfigsHelperService } from '../graphql-helpers/nv-configs/nv-configs.queries.service';
import { GraphQLGridViewHelperService } from '../graphql-helpers/gridviews/gridviews-queries.service';
import { IRespondGraduationSingleStudent } from 'Src/ng2/school/content-area/graduation/graduation-respond/graduation-respond.service';
import { MixpanelService, TMixpanelEvent } from '../mixpanel/mixpanel.service';
import { INoteMetadata } from '../mixpanel/event-interfaces/note-action';
import { ISupportsMetadata } from '../mixpanel/event-interfaces/supports-action';
import { IGenerateReportMetadata } from './../mixpanel/event-interfaces/generate-report';
import { IStudentPathMetadata } from './../mixpanel/event-interfaces/student-path-action';
import { IExperienceMetadata } from './../mixpanel/event-interfaces/experience-action';
import { GraphQLTasksHelperService } from '../graphql-helpers/tasks/tasks-queries.services';
import { ITaskMetadata } from '../mixpanel/event-interfaces/task-action';
import { GraphQLActionsHelperService } from '../graphql-helpers/actions/actions-queries.service';
import { IAction, BatchActionOriginOptions, TBatchActionsOrigin } from '../../components/nv-actions/nv-actions.interface';
import { ImCachedObject } from '../im-models/im-cached-object.service';
import { GraphQlNetworkSideNavHelperService } from '../graphql-helpers/network-side-nav/network-side-nav-queries.service';
import { GraphQLCollegeNowHelperService } from '../graphql-helpers/college-now/college-now-queries.services';
import { IConvoDetailsRequestParams } from '../school-conversations-service/school-conversations-service';
import { DomSanitizer } from '@angular/platform-browser';
import appEnv from '../../../../../app.env.json';

interface IStudentListData {
  schoolId: string;
  projection: object;
  joins: string[];
}

// Interface of the results return by backend for paginated results
export interface IPaginatedResult<T> {
  data: T[];
  items: {
    begin: number;
    end: number;
    limit: number;
    total: number;
  };
  pages: {
    current: number;
    hasNext: boolean;
    hasPrev: boolean;
    next: number;
    prev: number;
    total: number;
  };
}

export enum PATH_TYPE {
  COLLEGE = 'COLLEGE', // eslint-disable-line
  CAREER = 'CAREER', // eslint-disable-line
}

// TODO: shoud `get` methods be renamed to `fetch` to follow our norming convention
// around naming api requests? (CM).

/* istanbul ignore next: JJ */
@Injectable()
export class ApiService {
  private publicConfig;
  private NV_API_ORIGIN;
  public SINGLE_STUDENT_URL;
  public SINGLE_STUDENT_FROM_OTHER_SCHOOL_URL: string;
  public STUDENT_LIST_URL;
  public GRAPHQL_URL;
  public SCHOOL_URL;
  public FLAGS_URL;
  public USERS_URL;
  public DOC_LOGS_LIST_URL: string;
  public DOC_LOGS_LIST_URL_SHELTER: string;
  public SUPPORTS_URL: string;
  public SUPPORTS_CREATE_URL: string;
  public STUDENT_SUPPORTS_LIST_URL: string;
  public DASHBOARD_URL: string;
  public STUDENT_SUPPORTS_URL: string;
  public STUDENT_SUPPORTS_CREATE_URL: string;
  public STUDENT_SUPPORTS_BULK_CREATE_URL: string;
  public STUDENT_SUPPORTS_BULK_UPDATE_URL: string;
  public ATTENDANCE_RECORDS_URL: string;
  public ATTENDANCE_RECORDS_CREATE_URL: string;
  public ATTENDANCE_RECORDS_BULK_CREATE_URL: string;
  public ATTENDANCE_RECORDS_BULK_UPDATE_URL: string;
  public STUDENTS_BULK_URL: string;
  public NOTIFICATION_URL: string;
  public STUDENT_PATH_URL: string;
  public PATHS_URL: string;
  public TOOLS_URL: string;
  public SYNC_URL: string;
  private NOTES_URL: string;
  private NOTES_BULK_CREATE_URL: string;
  public API_STATUS_URL: string;
  public CACHED_OBJECT_URL: string;
  public COURSE_DIFFS_URL: string;
  public GAP_PLANS_URL: string;
  public BULK_CREATE_GAP_PLANS_URL: string;
  public BULK_CREATE_COURSE_DIFFS_URL: string;
  public REPORTS_URL: string;
  public GRIDVIEW_URL: string;
  public GRIDVIEW_CREATE_URL: string;
  public GRIDVIEW_UPDATE_URL: string;
  public GRIDVIEW_DELETE_URL: string;

  constructor (
    private http: HttpClient,
    private portalConfig: PortalConfig,
    private utilitiesService: UtilitiesService,
    private graphQLDataLoadsHelperService: GraphQLDataLoadsHelperService,
    private graphQlActivitiesHelperService: GraphQLActivitiesHelperService,
    private graphQlSchoolAssessmentsHelperService: GraphQLSchoolAssessmentsHelperService,
    private graphQlStudentAssessmentsHelperService: GraphQLStudentAssessmentsHelperService,
    private graphQlSchoolClusterUserHelperService: GraphQLSchoolClusterUserHelperService,
    private graphQlShelterClusterUserHelperService: GraphQlShelterClusterUserHelperService,
    private graphQlSchoolUserHelperService: GraphQLSchoolUserHelperService,
    private graphQLPartnerHelperService: GraphQLPartnerHelperService,
    private graphQLClusterHelperService: GraphQLClusterHelperService,
    private graphQlStudentMapGrowthHelperService: GraphQLStudentMapGrowthHelperService,
    private graphQlStudentAcadienceHelperService: GraphQLStudentAcadienceHelperService,
    private graphQlProgramChangesHelperService: GraphQLProgramChangesHelperService,
    private graphQlGapPlansHelperService: GraphQLGapPlansHelperService,
    private graphQLStudentDessaHelperService: GraphQLStudentDessaHelperService,
    private graphQLStudentIReadyHelperService: GraphQLStudentIReadyHelperService,
    private graphQLSELSupportHelperService : GraphQLSELSupportHelperService,
    private graphQlStudentPathsHelperService: GraphQlStudentPathsHelperService,
    private graphQlStudentDocLogsHelperService: GraphQLStudentDocLogsHelperService,
    private graphQLCsvDownloadsService: GraphQLCsvDownloadsService,
    private graphQLShelterSuccessMentorHelperService: GraphQlShelterSuccessMentorHelperService,
    private graphQLNvConfigsHelper: GraphQLNvConfigsHelperService,
    private graphQlGridViewHelperService: GraphQLGridViewHelperService,
    private graphQLExperiencesHelperService: GraphQLExperiencesHelperService,
    private graphQLTasksHelperService: GraphQLTasksHelperService,
    private graphQLCollegeNowHelperService: GraphQLCollegeNowHelperService,
    private mixpanelService: MixpanelService,
    private graphQLActionsHelperService: GraphQLActionsHelperService,
    private imCachedObject: ImCachedObject,
    private graphQlNetworkSideNavHelperService: GraphQlNetworkSideNavHelperService,
    private sanitizer: DomSanitizer,
  ) {
    // Configs
    this.publicConfig = this.portalConfig.publicConfig;
    this.NV_API_ORIGIN = this.publicConfig.NV_API_ORIGIN;

    if (this.NV_API_ORIGIN && !this.isAPIOriginValid(this.NV_API_ORIGIN)) {
      throw new Error('Invalid API Origin');
    }

    // Endpoints
    this.COURSE_DIFFS_URL = `${this.NV_API_ORIGIN}/v1/courseDiffs/`;
    this.BULK_CREATE_COURSE_DIFFS_URL = `${this.NV_API_ORIGIN}/v1/courseDiffs/bulk/create`;
    this.SINGLE_STUDENT_URL = this.NV_API_ORIGIN + '/v1/students/';
    this.GAP_PLANS_URL = `${this.NV_API_ORIGIN}/v1/gapPlans`;
    this.BULK_CREATE_GAP_PLANS_URL = `${this.NV_API_ORIGIN}/v1/gapPlans/bulk/create`;
    this.SINGLE_STUDENT_FROM_OTHER_SCHOOL_URL = `${this.NV_API_ORIGIN}/v1/students/otherSchools/`;
    this.STUDENT_LIST_URL = this.NV_API_ORIGIN + '/v1/students/search';
    this.GRAPHQL_URL = this.NV_API_ORIGIN + '/graphql';
    this.SCHOOL_URL = this.NV_API_ORIGIN + '/v1/schools/';
    this.FLAGS_URL = `${this.NV_API_ORIGIN}/v1/flags/`;
    this.DOC_LOGS_LIST_URL = this.NV_API_ORIGIN + '/v1/docLogs/';
    this.DOC_LOGS_LIST_URL_SHELTER = this.NV_API_ORIGIN + '/v1/shelterDocLogs/';
    this.NOTIFICATION_URL = `${this.NV_API_ORIGIN}/v1/notifications/`;
    this.SUPPORTS_URL = this.NV_API_ORIGIN + '/v1/supports/';
    this.SUPPORTS_CREATE_URL = this.NV_API_ORIGIN + '/v1/supports';
    this.USERS_URL = `${this.NV_API_ORIGIN}/v1/users/`;
    this.STUDENT_SUPPORTS_URL = this.NV_API_ORIGIN + '/v1/studentSupports/';
    this.STUDENT_SUPPORTS_LIST_URL = `${this.NV_API_ORIGIN}/v1/studentSupports/`;
    this.STUDENT_SUPPORTS_CREATE_URL = `${this.NV_API_ORIGIN}/v1/studentSupports`;
    // inconsistencies in the paths to the bulk create/update studentSupports handlers
    // is due to inconsistencies in the corresponding handlers path
    // values in the backend's plugins/routes.js file. (JYR)
    this.REPORTS_URL = `${this.NV_API_ORIGIN}/v1/reports/`;
    this.STUDENT_SUPPORTS_BULK_CREATE_URL = `${this.NV_API_ORIGIN}/v1/studentSupports/bulk/create`;
    this.STUDENT_SUPPORTS_BULK_UPDATE_URL = `${this.NV_API_ORIGIN}/v1/studentSupports/update/bulk`;
    this.ATTENDANCE_RECORDS_URL = `${this.NV_API_ORIGIN}/v1/attendanceRecords/`;
    this.ATTENDANCE_RECORDS_CREATE_URL = `${this.NV_API_ORIGIN}/v1/attendanceRecords`;
    this.ATTENDANCE_RECORDS_BULK_CREATE_URL = `${this.NV_API_ORIGIN}/v1/attendanceRecords/bulk/create`;
    this.ATTENDANCE_RECORDS_BULK_UPDATE_URL = `${this.NV_API_ORIGIN}/v1/attendanceRecords/update/bulk`;
    this.DASHBOARD_URL = `${this.NV_API_ORIGIN}/v1/dashboards/`;
    this.STUDENTS_BULK_URL = `${this.NV_API_ORIGIN}/v1/students/bulk`;
    this.STUDENT_PATH_URL = `${this.NV_API_ORIGIN}/v1/studentPaths/`;
    this.PATHS_URL = `${this.NV_API_ORIGIN}/v1/paths/`;
    this.TOOLS_URL = `${this.NV_API_ORIGIN}/v1/tools/`;
    this.SYNC_URL = `${this.NV_API_ORIGIN}/v1/listSyncer`;
    this.API_STATUS_URL = `${this.NV_API_ORIGIN}/v1/status`;
    this.CACHED_OBJECT_URL = `${this.NV_API_ORIGIN}/v1/cachedObjects/`;
    this.NOTES_URL = `${this.NV_API_ORIGIN}/v1/notes`;
    this.NOTES_BULK_CREATE_URL = `${this.NV_API_ORIGIN}/v1/notes/bulk/create`;
    this.GRIDVIEW_CREATE_URL = `${this.NV_API_ORIGIN}/v1/gridViews/create`;
    this.GRIDVIEW_UPDATE_URL = `${this.NV_API_ORIGIN}/v1/gridViews/update`;
    this.GRIDVIEW_DELETE_URL = `${this.NV_API_ORIGIN}/v1/gridViews/delete`;
  }

  // checks if the origin of the API is allowed based on the env configuration file
  private isAPIOriginValid (origin: string): boolean {
    const allowedApiOrigins = Object.keys(appEnv).map(key => appEnv[key].PublicConfig.NV_API_ORIGIN);
    const isAPIOriginValid = allowedApiOrigins.includes(origin);

    return isAPIOriginValid;
  }

  private isValidId (id: string): boolean {
    const idPattern = /^[a-zA-Z0-9_-]+$/;
    return idPattern.test(id);
  }

  private isValidPartnerType (partnerType: TValidPartnerTypes): boolean {
    return ALL_PARTNER_TYPES.includes(partnerType);
  }

  private sanitizeUrl (url: string): string {
    return this.sanitizer.sanitize(SecurityContext.URL, url) as string;
  }

  private isValidUrl (url: string): boolean {
    const MAX_URL_LENGTH = 500;

    if (!url || !url.length || url.length > MAX_URL_LENGTH) {
      return false;
    }

    return true;
  }

  getNote (_id: string, partnerType: TValidPartnerTypes) {
    // Validate and sanitize the inputs
    if (!this.isValidId(_id)) {
      throw new Error('Invalid ID');
    }

    let url = `${this.NOTES_URL}/${_id}`;
    if (partnerType && this.isValidPartnerType(partnerType)) {
      url += '?partnerType=' + partnerType;
    }

    if (!this.isValidUrl(url)) {
      throw new Error('Invalid URL');
    }

    // Sanitize the final URL
    const sanitizedUrl = this.sanitizeUrl(url);

    return this.http.get<{ data: Array<ISchoolNote> }>(sanitizedUrl).pipe(
      catchError(err => throwError(err)),
    );
  }

  createNote (note: ISchoolNote | IShelterNote, mixpanelEvent: TMixpanelEvent<INoteMetadata>): Observable<ISchoolNote> {
    return this.http.post<any>(this.NOTES_URL, note).pipe(
      catchError(err => throwError(err)),
      tap(() => this.mixpanelService.trackEvents([mixpanelEvent])),
    );
  }

  patchNote (_id: string, patch: Partial<ISchoolNote | IShelterNote>, partnerType: TValidPartnerTypes, mixpanelEvent: TMixpanelEvent<INoteMetadata>): Observable<Partial<ISchoolNote>> {
    let url = `${this.NOTES_URL}/${_id}`;
    if (partnerType) url += '?partnerType=' + partnerType;
    return this.http.patch<object>(url, patch).pipe(
      catchError(err => throwError(err)),
      tap(() => this.mixpanelService.trackEvents([mixpanelEvent])),
    );
  }

  bulkCreateNote (ids: Array<string>, note: ISchoolNote | IShelterNote, partnerType: TValidPartnerTypes, mixpanelEvents: TMixpanelEvent<INoteMetadata | IBatchActionMetadata>[]) {
    const idsForStudentType = (partnerType === PartnerTypes.SCHOOL) ? { studentIds: ids } : { caresIds: ids };
    return this.http.post<any>(this.NOTES_BULK_CREATE_URL, Object.assign(note, idsForStudentType), { observe: 'response' }).pipe(
      catchError(err => throwError(err)),
      tap(() => this.mixpanelService.trackEvents(mixpanelEvents)),
    );
  }

  createReport (payload) {
    return this.http.post<any[]>(this.REPORTS_URL, payload, { observe: 'response' }).pipe(catchError(err => throwError(err)));
  }

  getReport (reportId: string, mixpanelEvents?: TMixpanelEvent<IGenerateReportMetadata | IBatchActionMetadata>[]) {
    const url = `${this.REPORTS_URL}${reportId}`;
    return this.http.get<object>(url).pipe(
      catchError(err => throwError(err)),
      tap(() => {
        if (mixpanelEvents) this.mixpanelService.trackEvents(mixpanelEvents);
      }),
    );
  }

  getGapPlans (queries = {}) {
    const url = `${this.GAP_PLANS_URL}`;
    let urlWithParams = this.utilitiesService.addQueriesToUrl(url, queries);
    urlWithParams = this.utilitiesService.replaceStudentIdWithHash(urlWithParams);
    return this.http.get(urlWithParams).pipe(rxMap(({ data }: { data: Array<IGapPlan> }) => data));
  }

  createGapPlans (gapPlan: IGapPlan): Observable<IGapPlan> {
    return this.http.post<IGapPlan>(this.GAP_PLANS_URL, gapPlan);
  }

  bulkCreateGapPlans (gapPlan: IGapPlan): Observable<HttpResponse<any>> {
    return this.http.post<IGapPlan>(this.BULK_CREATE_GAP_PLANS_URL, gapPlan, { observe: 'response' });
  }

  patchGapPlans (id, patch): Observable<IGapPlan> {
    const url = `${this.GAP_PLANS_URL}/${id}`;
    return this.http.patch<IGapPlan>(url, patch).pipe(catchError(err => throwError(err)));
  }

  getCourseDiffs (queries = {}) {
    const url = `${this.COURSE_DIFFS_URL}`;
    let urlWithParams = this.utilitiesService.addQueriesToUrl(url, queries);
    urlWithParams = this.utilitiesService.replaceStudentIdWithHash(urlWithParams);
    return this.http.get(urlWithParams).pipe(rxMap(({ data }: { data: Array<ICourseDiff> }) => data));
  }

  createCourseDiff (diff: ICourseDiff): Observable<ICourseDiff> {
    return this.http.post<ICourseDiff>(this.COURSE_DIFFS_URL, diff);
  }

  bulkCreateCourseDiff (diff: ICourseDiff): Observable<HttpResponse<any>> {
    return this.http.post<ICourseDiff>(this.BULK_CREATE_COURSE_DIFFS_URL, diff, { observe: 'response' });
  }

  patchCourseDiff (_id, patch): Observable<ICourseDiff> {
    const url = `${this.COURSE_DIFFS_URL}${_id}`;
    return this.http.patch<ICourseDiff>(url, patch).pipe(catchError(err => throwError(err)));
  }

  getAcademicListConstantsGraphQL (data: any): Observable<object> {
    return this.http.post<any[]>(this.GRAPHQL_URL, data).pipe(catchError(err => throwError(err)));
  }

  getSchool (schoolId: string): Observable<object> {
    const url = `${this.SCHOOL_URL}${schoolId}`;
    return this.http.get<object>(url).pipe(catchError(err => throwError(err)));
  }

  public updateSchool (payload): Observable<object> {
    const { schoolId, patch } = payload;
    const url = `${this.SCHOOL_URL}${schoolId}`;
    return this.http.patch<object>(url, patch).pipe(catchError(err => throwError(err)));
  }

  updateSchoolWithHeaders (payload): Observable<HttpResponse<object>> {
    const { schoolId, patch } = payload;
    const url = `${this.SCHOOL_URL}${schoolId}`;
    return this.http.patch<object>(url, patch, { observe: 'response' }).pipe(catchError(err => throwError(err)));
  }

  getNotifications (options?: { params: { [key: string]: string } }): Observable<{ data: Array<INotification> }> {
    const { params } = options;
    const url = `${this.NOTIFICATION_URL}`;
    return this.http.get<{ data: Array<INotification> }>(url, { params }).pipe(catchError(err => throwError(err)));
  }

  createNotification (payload: INotification): Observable<{ data: INotification }> {
    const url = `${this.NOTIFICATION_URL}`;
    return this.http.post<{ data: INotification }>(url, payload).pipe(catchError(err => throwError(err)));
  }

  patchNotification ({ _id, patch }): Observable<INotification> {
    const url = `${this.NOTIFICATION_URL}${_id}`;
    return this.http.patch<INotification>(url, patch);
  }

  getSingleStudent (studentId: string, isSummerSchoolTerm: boolean): Observable<object> {
    const hashedId = this.imCachedObject.createHash({ _id: studentId });
    const url = `${this.SINGLE_STUDENT_URL}${hashedId}?isSummerSchoolTerm=${isSummerSchoolTerm}`;
    return this.http.get<object>(url).pipe(catchError(err => throwError(err)));
  }

  getSingleStudentFromOtherSchool (studentId: string, otherSchoolType: TSingleStudentViewOtherSchoolTypes, isSummerSchoolTerm: boolean): Observable<object> {
    const hashedId = this.imCachedObject.createHash({ _id: studentId });
    const url = `${this.SINGLE_STUDENT_FROM_OTHER_SCHOOL_URL}${otherSchoolType}/${hashedId}?isSummerSchoolTerm=${isSummerSchoolTerm}`;
    return this.http.get<object>(url).pipe(catchError(err => throwError(err)));
  }

  getStudents (data: IStudentListData): Observable<object> {
    return this.http.post<any[]>(this.STUDENT_LIST_URL, data).pipe(
      rxMap((res: any) => this.utilitiesService.unzipData(res.data, data.projection)),
      catchError(err => throwError(err)),
    );
  }

  // change this name
  getStudentsGraphQL (data: any): Observable<any> {
    return this.http.post<any[]>(this.GRAPHQL_URL, data).pipe(catchError(err => throwError(err)));
  }

  getSchoolsForRole (currentUser: IUser) {
    const clusterSchoolIds = currentUser._role_clusterSchoolIds;
    const chunkedSchoolIds = chunk(clusterSchoolIds, 300);
    const projection = JSON.stringify({ _id: 1, nickName: 1, fullName: 1 });
    const urls = chunkedSchoolIds.map(schoolIds => {
      const where = JSON.stringify({ _id: { $in: schoolIds } });
      return `${this.NV_API_ORIGIN}/v1/schools?limit=0&page=1&projection=${projection}&where=${where}`;
    });
    const observable$ = urls.map(url => this.http.get(url).pipe(rxMap(({ data }: { data: Array<ISchool> }) => data)));
    return forkJoin(observable$);
  }

  getPartnerOrgs ({ schoolId, where }): Observable<object> {
    const query = this.graphQlActivitiesHelperService.getPartnerOrgsQuery(schoolId, where);
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post<any[]>(this.GRAPHQL_URL, data).pipe(catchError(err => throwError(err)));
  }

  patchPartnerOrg ({ partnerOrgId, patch }): Observable<object> {
    const query = this.graphQlActivitiesHelperService.updatePartnerOrgQuery(partnerOrgId, patch);
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post<any[]>(this.GRAPHQL_URL, data).pipe(catchError(err => throwError(err)));
  }

  createPartnerOrg (schoolId: string, partnerOrgParams: { name: string }): Observable<object> {
    const query = this.graphQlActivitiesHelperService.createPartnerOrgQuery(schoolId, partnerOrgParams);
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post<any[]>(this.GRAPHQL_URL, data).pipe(catchError(err => throwError(err)));
  }

  public createGridView (gridViewParams: any, contextPartnerType: TValidPartnerTypes): Observable<object> {
    const query = this.graphQlGridViewHelperService.createGridViewQuery(gridViewParams, contextPartnerType);
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post<any[]>(this.GRAPHQL_URL, data).pipe(catchError(err => throwError(err)));
  }

  updateGridView (gridViewParams: any, contextPartnerType: TValidPartnerTypes): Observable<object> {
    const query = this.graphQlGridViewHelperService.updateGridViewQuery(gridViewParams, contextPartnerType);
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post<any[]>(this.GRAPHQL_URL, data).pipe(catchError(err => throwError(err)));
  }

  deleteGridView (gridViewParams: any, contextPartnerType: TValidPartnerTypes): Observable<object> {
    const query = this.graphQlGridViewHelperService.deleteGridViewQuery(gridViewParams, contextPartnerType);
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post<any[]>(this.GRAPHQL_URL, data).pipe(catchError(err => throwError(err)));
  }

  createCsvDownload (options: { fileName: string, schoolId: string, columns: string[], rowData: string[], csvType: CsvType, url: string }, mixPanelEvent: TMixpanelEvent<IExportedCsvMetadata>): Observable<object> {
    const { query, variables } = this.graphQLCsvDownloadsService.getCsvDownloadQuery(options);
    const data = {
      query,
      variables,
      fetchPolicy: 'no-cache',
    };
    return this.http.post<any[]>(this.GRAPHQL_URL, data).pipe(
      catchError(err => throwError(err)),
      tap(() => this.mixpanelService.trackEvents([mixPanelEvent])),
    );
  }

  /** paths is a comma-separated list of student fields. This method returns
   * an object with student fields as keys and an array of distinct values
   * for those keys from the current user's caseload of students
   * example - paths: 'studentDetails.classOf,studentDetails.officialClass'
   * could return { id: null, classOf: ['2018', '2019'], officialClass: ['999', '201'] } (JE)
   */
  getMadlibOptions (schoolId: string, paths: string): Observable<object> {
    const payload = {
      distinct: paths,
      where: {
        schoolStatus: { $in: ['A'] },
        schoolId,
      },
    };
    return this.http.post<any[]>(this.STUDENT_LIST_URL, payload).pipe(catchError(err => throwError(err)));
  }

  getFlags (schoolId: string): Observable<object> {
    const url = `${this.FLAGS_URL}${schoolId}`;
    return this.http.get<object>(url).pipe(catchError(err => throwError(err)));
  }

  getUser (userId: string): Observable<object> {
    const url = `${this.USERS_URL}${userId}`;
    return this.http.get<object>(url).pipe(catchError(err => throwError(err)));
  }

  getUsers (schoolId: string, queries?): Observable<Array<IUser>> {
    const url = `${this.USERS_URL}?schoolId=${schoolId}`;
    const urlWithParams = this.utilitiesService.addQueriesToUrl(url, queries);
    return this.http.get<any>(urlWithParams).pipe(rxMap(({ data }) => data), catchError(err => throwError(err)));
  }

  getSupport (supportId: string): Observable<ISchoolSupport> {
    const url = `${this.SUPPORTS_URL}${supportId}`;
    return this.http.get<object>(url).pipe(catchError(err => throwError(err))) as Observable<ISchoolSupport>;
  }

  getSupports (schoolId: string, queries?): Observable<object> {
    let url = `${this.SUPPORTS_URL}?schoolId=${schoolId}`;
    if (size(queries)) {
      url = this.utilitiesService.addQueriesToUrl(url, queries);
    }
    return this.http.get<object>(url).pipe(catchError(err => throwError(err)));
  }

  patchFlag (id: string, patch: Partial<IFlag>): Observable<IFlag> {
    const url = `${this.FLAGS_URL}${id}`;
    return this.http.patch(url, patch).pipe(catchError(err => throwError(err))) as Observable<IFlag>;
  }

  patchSingleStudent ({ id, patch }): Observable<any> {
    const hashedId = this.imCachedObject.createHash({ _id: id });
    const url = `${this.SINGLE_STUDENT_URL}${hashedId}`;
    return this.http.patch(url, patch).pipe(catchError(err => throwError(err)));
  }

  patchSdcStudents (patches: Array<{ studentIds: Array<string>, patch: any }>) {
    const url = `${this.STUDENTS_BULK_URL}`;
    return this.http
      .patch<any[]>(url, { patches }, { observe: 'response' })
      .pipe(catchError((err: any) => throwError(err)));
  }

  patchStudents (patchData, mixpanelEvent?: TMixpanelEvent<IBatchActionMetadata>): Observable<HttpResponse<any>> {
    const url = `${this.STUDENTS_BULK_URL}`;

    let patches;
    const { _ids, path, newValue } = patchData;
    /**
     * this first condition only applies in cases where patchData.newValue
     * is an array of student(s), where each student contains:
     * studentId
     * dataType (may remove)
     * value: array of items that should be applied to the patchData.path. Each student
     * could have a different value. Dependent on what the existing value at the path
     * for each student contained.
     * At the time of this comment, this only applied to `pointPeople` path, where
     * a SINGLE point person is being updated. - JYR
     */
    if (newValue && newValue[0] && newValue[0].dataType === SorterColumnDataType.ARRAY) {
      patches = map(newValue, (updatedStudent: { _id: string; dataType: string; value: any[] }) => {
        const changes = set({}, path, updatedStudent.value);
        return {
          studentIds: [updatedStudent._id],
          patch: changes,
        };
      });
    } else {
      const changes = set({}, path, newValue);
      patches = [
        {
          studentIds: _ids,
          patch: changes,
        },
      ];
    }
    return this.http
      .patch<any[]>(url, { patches }, { observe: 'response' })
      .pipe(
        catchError((err: any) => throwError(err)),
        tap(() => {
          if (mixpanelEvent) this.mixpanelService.trackEvents([mixpanelEvent]);
        }),
      );
  }

  createSupport (schoolId: string, data: ISupportOptPayload, mixpanelEvent: TMixpanelEvent<ISupportsMetadata>): Observable<any> {
    const obj = Object.assign(data, { schoolId });
    const url = `${this.SUPPORTS_CREATE_URL}?schoolId=${schoolId}`;
    return this.http.post<any>(url, obj).pipe(
      catchError(err => throwError(err)),
      tap(() => this.mixpanelService.trackEvents([mixpanelEvent])),
    );
  }

  updateSupport (supportId: string, data: ISchoolSupport): Observable<any> {
    const url = this.SUPPORTS_URL + supportId;
    const payload = data;
    return this.http.patch<object>(url, payload).pipe(catchError(err => throwError(err)));
  }

  getStudentSupports (schoolId: string, queries?: { where?: any }, projection?): Observable<Array<IStudentSupport>> {
    const queryParamsExist = !!size(queries);
    let url = `${this.STUDENT_SUPPORTS_LIST_URL}?schoolId=${schoolId}`;

    if (queryParamsExist) url = this.utilitiesService.addQueriesToUrl(url, queries);

    if (projection) {
      const projectionQuery = JSON.stringify(projection);
      url += `&projection=${projectionQuery}`;
    }

    return this.http.get<Array<IStudentSupport>>(url).pipe(catchError(err => throwError(err)));
  }

  createStudentSupport (data: IApi['CreateStudentSupportPayload'], schoolId: string, mixpanelEvent: TMixpanelEvent<ISupportsMetadata>): Observable<IStudentSupport> {
    const url = `${this.STUDENT_SUPPORTS_CREATE_URL}?schoolId=${schoolId}`;
    return this.http.post<IStudentSupport>(url, data).pipe(
      catchError(err => throwError(err)),
      tap(() => this.mixpanelService.trackEvents([mixpanelEvent])),
    );
  }

  createStudentSupports (data: IApi['CreateStudentSupportsPayload'], mixpanelEvents: TMixpanelEvent<ISupportsMetadata | IBatchActionMetadata>[]): Observable<HttpResponse<any>> {
    const url = this.STUDENT_SUPPORTS_BULK_CREATE_URL;
    return this.http.post<object>(url, data, { observe: 'response' }).pipe(
      catchError(err => throwError(err)),
      tap(() => this.mixpanelService.trackEvents(mixpanelEvents)),
    );
  }

  patchStudentSupport (id: string, patch: Partial<IStudentSupport>): Observable<IStudentSupport> {
    const url = `${this.STUDENT_SUPPORTS_URL}${id}`;
    return this.http.patch(url, patch).pipe(catchError(err => throwError(err))) as Observable<IStudentSupport>;
  }

  patchStudentSupports (data: IApi['PatchStudentSupportsPayload']): Observable<HttpResponse<any>> {
    const { studentSupportIds, status, startsOn, endsOn, extendStudentSupports } = data;
    const payload = {
      patches: [
        {
          studentSupportIds,
          patch: { status, startsOn, endsOn, extendStudentSupports },
        },
      ],
    };
    const url = this.STUDENT_SUPPORTS_BULK_UPDATE_URL;
    return this.http.patch(url, payload, { observe: 'response' }).pipe(catchError(err => throwError(err)));
  }

  getStudentSelSupports({ schoolId, studentId }): Observable<Array<IStudentSelSupport>> {
    const query = this.graphQLStudentDessaHelperService.getStudentSelSupportsQuery({ schoolId, studentId });
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http
      .post<{
        data: {
          ApertureStudentSupport: Array<IStudentSelSupport>;
        };
      }>(this.GRAPHQL_URL, data)
      .pipe(
        rxMap((res: { data: { ApertureStudentSupport: any } }) => {
          return res.data.ApertureStudentSupport;
        }),
        catchError(err => throwError(err)),
      );
  }

  createAttendanceRecord (data: IAttendanceRecord, schoolId: string): Observable<IAttendanceRecord> {
    const url = `${this.ATTENDANCE_RECORDS_CREATE_URL}?schoolId=${schoolId}`;
    return this.http.post<IAttendanceRecord>(url, data).pipe(catchError(err => throwError(err)));
  }

  patchAttendanceRecord (id: string, patch: { status: IAttendanceRecord['status'] }): Observable<IAttendanceRecord> {
    const url = `${this.ATTENDANCE_RECORDS_URL}${id}`;
    return this.http.patch(url, patch).pipe(catchError(err => throwError(err))) as Observable<IAttendanceRecord>;
  }

  bulkCreateAttendanceRecords (data: IApi['CreateAttendanceRecordsPayload'][]): Observable<HttpResponse<any>> {
    const payload = { patches: data };
    const url = this.ATTENDANCE_RECORDS_BULK_CREATE_URL;
    return this.http.post<object>(url, payload, { observe: 'response' }).pipe(catchError(err => throwError(err)));
  }

  bulkUpdateAttendanceRecords (data: IApi['PatchAttendanceRecordsPayload'][]): Observable<HttpResponse<any>> {
    const patches = data.reduce((acc, { attendanceRecordIds, status }) => {
      acc.push({
        attendanceRecordIds,
        patch: { status },
      });
      return acc;
    }, []);
    const payload = { patches };
    const url = `${this.ATTENDANCE_RECORDS_BULK_UPDATE_URL}`;
    return this.http.patch(url, payload, { observe: 'response' }).pipe(catchError(err => throwError(err)));
  }

  getGraph (schoolId: string, graphName: string, graphQuery): Observable<object> {
    let url;
    if (graphQuery) url = this.DASHBOARD_URL + schoolId + '/' + graphName + '/?' + graphQuery;
    else url = this.DASHBOARD_URL + schoolId + '/' + graphName;
    return this.http.get<object>(url).pipe(catchError(err => throwError(err)));
  }

  public getPaginatedDocLogs (
    partnerType: TValidPartnerTypes,
    partnerId: string,
    queries?: {
      page?: number; // api default is 1
      limit?: number; // api default is 50
      sort?: string; // api default is '-createdAt'
      docId?: string;
      collection?: string;
      loggedBefore?: string;
      loggedAfter?: string;
      where?: string;
      displayHistory?: boolean;
      noLimit?: boolean;
    },
  ) {
    const queryParamsExist = !!size(queries);
    let url;
    if (partnerType === PartnerTypes.SCHOOL) {
      url = `${this.DOC_LOGS_LIST_URL}${partnerId}`;
    }
    if (partnerType === PartnerTypes.SHELTER) {
      url = `${this.DOC_LOGS_LIST_URL_SHELTER}${partnerId}`;
    }

    if (queryParamsExist) {
      url = this.utilitiesService.addQueriesToUrl(url, queries);
      url = this.utilitiesService.replaceStudentIdWithHash(url);
    }

    return this.http.get(url).pipe(catchError(err => throwError(err)));
  }

  public getPaginatedSupports (
    schoolId: string,
    queries?: {
      projection?: string;
      where: string;
    },
  ): Observable<IPaginatedResult<ISchoolSupport>> {
    const queryParamsExist = !!size(queries);
    let url = this.SUPPORTS_URL + `?schoolId=${schoolId}`;

    if (queryParamsExist) if (queryParamsExist) url = this.utilitiesService.addQueriesToUrl(url, queries);

    return this.http.get<IPaginatedResult<ISchoolSupport>>(url).pipe(catchError(err => throwError(err)));
  }

  getStudentPaths (payload: {
    schoolId: string;
    projection?: any;
    queries?: any;
    studentId?: string;
  }): Observable<Array<object>> {
    const { schoolId, projection, queries, studentId } = payload;
    let url = `${this.STUDENT_PATH_URL}${schoolId}`;

    if (studentId) {
      url += '?studentId=' + studentId;
    } else {
      const queryParamsExist = !!size(queries);
      if (queryParamsExist) url = this.utilitiesService.addQueriesToUrl(url, queries);
      if (projection) {
        const projectionQuery = JSON.stringify(projection);
        url += `?projection=${projectionQuery}`;
      }
    }

    url = this.utilitiesService.replaceStudentIdWithHash(url);

    return this.http.get<Array<object>>(url).pipe(catchError(err => throwError(err)));
  }

  createStudentPath (schoolId: string, studentId: string, pathData, mixpanelEvent: TMixpanelEvent<IStudentPathMetadata>): Observable<object> {
    const hashedId = this.imCachedObject.createHash({ _id: studentId });
    const url = this.STUDENT_PATH_URL + schoolId + '/' + hashedId;
    const payload = pathData;
    return this.http.post<object>(url, payload).pipe(
      catchError(err => throwError(err)),
      tap(() => this.mixpanelService.trackEvents([mixpanelEvent])),
    );
  }

  bulkCreateStudentPath (payload: {
    schoolId: string;
    studentIds: string[];
    college: string;
  }, mixpanelEvents: TMixpanelEvent<IExperienceMetadata | IBatchActionMetadata>[]): Observable<HttpResponse<any>> {
    const url = this.STUDENT_PATH_URL + 'create/bulk';
    return this.http.post<object>(url, payload, { observe: 'response' }).pipe(
      catchError(err => throwError(err)),
      tap(() => this.mixpanelService.trackEvents(mixpanelEvents)),
    );
  }

  patchStudentPaths (data: IApi['PatchStudentPathsPayload']): Observable<HttpResponse<any>> {
    const { studentPathIds, status, studentIds, college, schoolId, downgradeMap } = data;
    // if downgradeMap exists, these studentPaths need to be downgraded BEFORE the new status is patched (JE)

    const payload = {
      downgradeMap,
      patches: [
        {
          studentPathIds,
          patch: { status },
          studentIds,
          college,
          schoolId,
        },
      ],
    };
    const url = `${this.STUDENT_PATH_URL}update/bulk`;
    return this.http.patch(url, payload, { observe: 'response' }).pipe(catchError(err => throwError(err)));
  }

  updateStudentPath (studentPathId: string, status: string): Observable<object> {
    const url = this.STUDENT_PATH_URL + studentPathId;
    const payload = {
      status,
    };

    return this.http.patch<object>(url, payload).pipe(catchError(err => throwError(err)));
  }

  createPath (schoolId: string, pathType: PATH_TYPE, payload): Observable<object> {
    const url = this.PATHS_URL + schoolId + '/' + pathType;

    return this.http.post<object>(url, payload).pipe(catchError(err => throwError(err)));
  }

  getPaths (schoolId: string, pathType: PATH_TYPE): Observable<Array<object>> {
    const url = this.PATHS_URL + schoolId + '/' + pathType;

    return this.http.get<Array<object>>(url).pipe(catchError(err => throwError(err)));
  }

  getTools (schoolId: string): Observable<Array<object>> {
    const url = `${this.TOOLS_URL}?schoolId=${schoolId}`;
    return this.http.get<ITool[]>(url).pipe(catchError(err => throwError(err)));
  }

  createSyncTool (payload: ICreateSyncToolPayload): Observable<any> {
    const url = `${this.SYNC_URL}/create`;
    return this.http.post<ITool>(url, payload).pipe(catchError(err => throwError(err)));
  }

  updateSyncTool (payload: IUpdateSyncToolPayload): Observable<any> {
    const url = `${this.SYNC_URL}/update`;
    return this.http.patch<ITool>(url, payload).pipe(catchError(err => throwError(err)));
  }

  deleteSyncTool (payload: IDeleteSyncToolPayload): Observable<any> {
    const url = `${this.SYNC_URL}/delete`;
    return this.http.post(url, payload).pipe(catchError(err => throwError(err)));
  }

  getSchoolAssessments (payload: ILoadSchoolAssessmentsPayload): Observable<any> {
    const query = this.graphQlSchoolAssessmentsHelperService.getSchoolAssessmentsQuery(payload);
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post<any[]>(this.GRAPHQL_URL, data).pipe(catchError(err => throwError(err)));
  }

  createSchoolAssessments (params: ICreateSchoolAssessmentPayload): Observable<any> {
    const query = this.graphQlSchoolAssessmentsHelperService.bulkCreateSchoolAssessmentsQuery(params);
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post<any[]>(this.GRAPHQL_URL, data).pipe(catchError(err => throwError(err)));
  }

  patchSchoolAssessments (params: IBulkUpdateSchoolAssessmentsPayload): Observable<any> {
    const query = this.graphQlSchoolAssessmentsHelperService.bulkUpdateSchoolAssessmentsQuery(params);
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post<any[]>(this.GRAPHQL_URL, data).pipe(catchError(err => throwError(err)));
  }

  getStudentAssessments (params: ILoadStudentAssessmentsPayload): Observable<any> {
    const query = this.graphQlStudentAssessmentsHelperService.getStudentAssessmentsQuery(params);
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post<any[]>(this.GRAPHQL_URL, data).pipe(catchError(err => throwError(err)));
  }

  patchStudentAssessments (params: IBulkUpdateStudentAssessmentsPayload): Observable<any> {
    const query = this.graphQlStudentAssessmentsHelperService.bulkUpdateStudentAssessmentsQuery(params);
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post<any[]>(this.GRAPHQL_URL, data).pipe(catchError(err => throwError(err)));
  }

  getDataLoads (schoolId: string, where?: any): Observable<any> {
    const query = this.graphQLDataLoadsHelperService.getDataLoadsQuery(schoolId, where);
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post<any[]>(this.GRAPHQL_URL, data).pipe(catchError(err => throwError(err)));
  }

  createDataLoad (params: IDataLoadParams): Observable<any> {
    const query = this.graphQLDataLoadsHelperService.createDataLoad(params);
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post<any[]>(this.GRAPHQL_URL, data).pipe(catchError(err => throwError(err)));
  }

  // fetch a single projected or full partner doc
  getPartner ({ params, projections }: IApi['GetPartnerPayload']): Observable<IPartner> {
    const query = this.graphQLPartnerHelperService.getPartnerQuery({ params, projections });
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post<IApi['GetPartnerRes']>(this.GRAPHQL_URL, data).pipe(
      rxMap((res: IApi['GetPartnerRes']) => {
        return res.data.Partner;
      }),
      catchError(err => throwError(err)),
    );
  }

  // fetch a list of projected or full partner docs
  getPartners ({ params, projections }: IApi['GetPartnersPayload']): Observable<IPartner[]> {
    const query = this.graphQLPartnerHelperService.getPartnersQuery({ params, projections });
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post<IApi['GetPartnersRes']>(this.GRAPHQL_URL, data).pipe(
      rxMap((res: IApi['GetPartnersRes']) => {
        return res.data.Partners;
      }),
      catchError(err => throwError(err)),
    );
  }

  getClustersByClusterType ({ params, projections }: IApi['GetClustersByClusterTypePayload']): Observable<ICluster[]> {
    const query = this.graphQLClusterHelperService.getClustersByClusterTypeQuery({ params, projections });
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post<IApi['GetClustersByClusterTypeRes']>(this.GRAPHQL_URL, data).pipe(
      rxMap((res: IApi['GetClustersByClusterTypeRes']) => {
        return res.data.ClustersByClusterType;
      }),
      catchError(err => throwError(err)),
    );
  }

  getCluster ({ params, projections }: IApi['GetClusterPayload']): Observable<Partial<ICluster>> {
    const query = this.graphQLClusterHelperService.getClusterQuery({ params, projections });
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post<IApi['GetClusterRes']>(this.GRAPHQL_URL, data).pipe(
      rxMap((res: IApi['GetClusterRes']) => {
        return res.data.Cluster;
      }),
      catchError(err => throwError(err)),
    );
  }

  getApiStatus (): Observable<IApiStatus> {
    return this.http.get<IApiStatus>(this.API_STATUS_URL).pipe(catchError(err => throwError(err)));
  }

  // START OF USER RELATED:
  getClusterUserMadLib ({ clusterId, projection }) {
    const query = this.graphQlSchoolClusterUserHelperService.getClusterUserMadLibQuery({ clusterId, projection });
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post<any>(this.GRAPHQL_URL, data).pipe(catchError(err => throwError(err)));
  }

  // fetch list of shelter cluster users
  getClusterUsers ({ clusterId, grouping, projection }): Observable<object> {
    const query = this.graphQlSchoolClusterUserHelperService.getClusterUsersQuery({ clusterId, grouping, projection });
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post<any>(this.GRAPHQL_URL, data).pipe(catchError(err => throwError(err)));
  }

  // populate the add/edit user modal
  getClusterUserDetail ({ userId }): Observable<object> {
    const query = this.graphQlSchoolClusterUserHelperService.getClusterUserDetailQuery({ userId });
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post<any>(this.GRAPHQL_URL, data).pipe(catchError(err => throwError(err)));
  }

  // populate shelter cluster users portfolio modal
  getClusterUserSchools ({ userId, clusterId }): Observable<object> {
    const query = this.graphQlSchoolClusterUserHelperService.getClusterUserSchoolsQuery({ userId, clusterId });
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post<any>(this.GRAPHQL_URL, data).pipe(catchError(err => throwError(err)));
  }

  mutateClusterUser ({ userPayload, columns }, mode: TClusterUserModalViewMode): Observable<object> {
    const query = this.graphQlSchoolClusterUserHelperService.getClusterUserMutationQuery({ userPayload, columns }, mode);
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post<any[]>(this.GRAPHQL_URL, data).pipe(
      catchError(err => throwError(err)),
    );
  }

  // fetch list of shelter cluster users
  getShelterClusterUsers ({ clusterId, projection }): Observable<any> {
    const query = this.graphQlShelterClusterUserHelperService.getShelterClusterUsersQuery({ clusterId, projection });
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post<IApi['GetShelterClusterUsersRes']>(this.GRAPHQL_URL, data).pipe(
      rxMap((res: IApi['GetShelterClusterUsersRes']) => {
        return res.data.ShelterClusterUserGrouping;
      }),
      catchError(err => throwError(err)),
    );
  }

  // populate the add/edit user modal
  getShelterClusterUserDetail ({ userId }): Observable<object> {
    const query = this.graphQlShelterClusterUserHelperService.getShelterClusterUserDetailQuery({ userId });
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post<any>(this.GRAPHQL_URL, data).pipe(catchError(err => throwError(err)));
  }

  // populate shelter cluster users portfolio modal
  getShelterClusterUserShelters ({ userId, clusterId }): Observable<object> {
    const query = this.graphQlShelterClusterUserHelperService.getShelterClusterUserSheltersQuery({ userId, clusterId });
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post<any>(this.GRAPHQL_URL, data).pipe(catchError(err => throwError(err)));
  }

  mutateShelterClusterUser ({ userPayload, columns }, mode: TClusterUserModalViewMode): Observable<object> {
    const query = this.graphQlShelterClusterUserHelperService.getShelterClusterUserMutationQuery(
      { userPayload, columns },
      mode,
    );
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post<any[]>(this.GRAPHQL_URL, data).pipe(catchError(err => throwError(err)),
    );
  }

  // School User Management

  getSchoolUsers ({ schoolId, grouping, projection }): Observable<object> {
    const query = this.graphQlSchoolUserHelperService.getSchoollUsersQuery({ schoolId, grouping, projection });
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post<any>(this.GRAPHQL_URL, data).pipe(catchError(err => throwError(err)));
  }

  getSchoolUserMadLib ({ schoolId, projection }) {
    const query = this.graphQlSchoolUserHelperService.getSchoolUserMadLibQuery({ schoolId, projection });
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post<any>(this.GRAPHQL_URL, data).pipe(catchError(err => throwError(err)));
  }

  mutateSchoolUser ({ userPayload, columns }, mode: TSchoolUserModalViewMode, schoolId): Observable<object> {
    const query = this.graphQlSchoolUserHelperService.getSchoolUserMutationQuery({ userPayload, columns }, mode, schoolId);
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post<any[]>(this.GRAPHQL_URL, data).pipe(catchError(err => throwError(err)));
  }

  bulkUpdateUserPermissions ({ userPayload, columns }, accessLevel, schoolId): Observable<object> {
    const query = this.graphQlSchoolUserHelperService.getbulkUpdateUserPermissionsQuery({ userPayload, columns }, accessLevel, schoolId);
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post<any[]>(this.GRAPHQL_URL, data).pipe(catchError(err => throwError(err)));
  }

  checkDupesEmails ({ userPayload }, mode: TSchoolUserModalViewMode, schoolId): Observable<object> {
    const query = this.graphQlSchoolUserHelperService.getSchoolUserEmailDupesQuery({ userPayload }, mode, schoolId);
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post<any[]>(this.GRAPHQL_URL, data).pipe(catchError(err => throwError(err)));
  }

  getSchoolUserDetail ({ userId, schoolId }): Observable<object> {
    const query = this.graphQlSchoolUserHelperService.getSchoolUserDetailQuery({ userId, schoolId });
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post<any>(this.GRAPHQL_URL, data).pipe(catchError(err => throwError(err)));
  }

  getCachedObject (id): Observable<{ _id: string, json: string }> {
    const url = `${this.CACHED_OBJECT_URL}${id}`;
    return this.http.get<{ _id: string, json: string }>(url).pipe(catchError(err => throwError(err)));
  }

  createCachedObject (cachedObject: { _id: string, json: string }): Observable<object> {
    const url = `${this.CACHED_OBJECT_URL}`;
    return this.http.post<any>(url, cachedObject).pipe(catchError(err => throwError(err)));
  }

  getStudentMapGrowth ({ schoolId, studentId, calcConfig }): Observable<IStudentMapGrowthEntity> {
    const query = this.graphQlStudentMapGrowthHelperService.getStudentMapGrowthQuery({ schoolId, studentId });
    const data = {
      query,
      fetchPolicy: 'no-cache',
      variables: { calcConfig },
    };
    return this.http
      .post<{
        data: {
          StudentMapGrowth: IStudentMapGrowthEntity;
        };
      }>(this.GRAPHQL_URL, data)
      .pipe(
        rxMap((res: { data: { StudentMapGrowth: IStudentMapGrowthEntity } }) => {
          return res.data.StudentMapGrowth;
        }),
        catchError(err => throwError(err)),
      );
  }

  getStudentDessa ({ schoolId, studentId, calcConfig }): Observable<IStudentMapGrowthEntity> {
    const query = this.graphQLStudentDessaHelperService.getStudentDessaQuery({ schoolId, studentId });
    const data = {
      query,
      fetchPolicy: 'no-cache',
      variables: { calcConfig },
    };
    return this.http
      .post<{
        data: {
          StudentDessa: any;
        };
      }>(this.GRAPHQL_URL, data)
      .pipe(
        rxMap((res: { data: { StudentDessa: any } }) => {
          return res.data.StudentDessa;
        }),
        catchError(err => throwError(err)),
      );
  }

  getStudentIReady ({ schoolId, studentId, calcConfig }): Observable<object> {
    const query = this.graphQLStudentIReadyHelperService.getStudentIReadyQuery({ schoolId, studentId });
    const data = {
      query,
      fetchPolicy: 'no-cache',
      variables: { calcConfig },
    };
    return this.http
      .post<{
        data: {
          StudentIReady: any;
        };
      }>(this.GRAPHQL_URL, data)
      .pipe(
        rxMap((res: { data: { StudentIReady: any } }) => {
          return res.data.StudentIReady;
        }),
        catchError(err => throwError(err)),
      );
  }

  getStudentAcadience ({ schoolId, studentId, calcConfig }): Observable<IStudentAcadienceEntity> {
    const query = this.graphQlStudentAcadienceHelperService.getStudentAcadienceQuery({ schoolId, studentId });
    const data = {
      query,
      fetchPolicy: 'no-cache',
      variables: { calcConfig },
    };
    return this.http
      .post<{
        data: {
          StudentAcadience: IStudentAcadienceEntity;
        };
      }>(this.GRAPHQL_URL, data)
      .pipe(
        rxMap((res: { data: { StudentAcadience: IStudentAcadienceEntity } }) => {
          return res.data.StudentAcadience;
        }),
        catchError(err => throwError(err)),
      );
  }

  public getIntercomUser (data: { query: string; fetchPolicy: string }): Observable<IIntercomUser> {
    return this.http
      .post<{
        data: { IntercomUser: IIntercomUser };
      }>(this.GRAPHQL_URL, data)
      .pipe(
        rxMap((res: { data: { IntercomUser: IIntercomUser } }) => res.data.IntercomUser),
        catchError(err => throwError(err)),
      );
  }

  // Program Changes

  getProgramChangesData ({ schoolId, fociOption, groupingOption, projection }): Observable<object> {
    const query = this.graphQlProgramChangesHelperService.getProgramChangesQuery({ schoolId, fociOption, groupingOption, projection });
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post<any>(this.GRAPHQL_URL, data).pipe(catchError(err => throwError(err)));
  }

  // Next Term Planning
  getGapPlansGraphQl ({ schoolId, grouping, projection }): Observable<object> {
    const query = this.graphQlGapPlansHelperService.getGapPlansQuery({ schoolId, grouping, projection });
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post<any>(this.GRAPHQL_URL, data).pipe(catchError(err => throwError(err)));
  }

  getSELTypeandTier (params: ISELParams) : Observable<any> {
    // withFilter adds the madlib filter at the end of the filter string
    const queryParams = {
      schoolId: params.schoolId,
      grouping: params.grouping,
      columns: params.columns,
      filter: this.graphQLSELSupportHelperService.buildSELFilterString(params),
    };
    const query = this.graphQLSELSupportHelperService.getSELInterventionTypeTierQuery(queryParams);
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post<any>(this.GRAPHQL_URL, data).pipe(catchError(err => throwError(err)));
  }
  // Postsecondary path field options
  getPostsecPathFieldOptions
    ({ schoolId, pathCategory }: IPostSecondaryPathFieldOptionParams): Observable<IFieldOptionsDict> {
      const query = this.graphQlStudentPathsHelperService.getPostSecPathFieldOptionQuery({ schoolId, pathCategory });

      const data = {
        query,
        fetchPolicy: 'no-cache',
      };
    return this.http
      .post<any>(this.GRAPHQL_URL, data)
      .pipe(
        rxMap((res: { data: { PostsecondaryPathFieldOptions: IFieldOptionsDict } }) => {
          return res.data.PostsecondaryPathFieldOptions;
        }),
        catchError(err => throwError(err)),
      );
  }

  // DocLogs filter options
  getDocLogsFilterOptionsQuery ({ studentId = '', schoolId = '', caresId = '', shelterId = '', partnerType = 'school' }): Observable<object> {
    let query;
    if (partnerType === 'shelter') query = this.graphQlStudentDocLogsHelperService.getShelterDocLogsFilterOptionsQuery({ caresId, shelterId });
    else query = this.graphQlStudentDocLogsHelperService.getStudentDocLogsFilterOptionsQuery({ studentId, schoolId });
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post<any>(this.GRAPHQL_URL, data).pipe(catchError(err => throwError(err)));
  }

  //Shelter Success Mentor
  checkInProgressShelterSuccessMentor (payload: IAssignSuccessMentorPayload | IUpdateSuccessMentorPayload): Observable<any> {
    const query = this.graphQLShelterSuccessMentorHelperService.getInProgressShelterSuccessMentorQuery(payload);
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http
      .post<{
        data: {
          ShelterInProgressSuccessMentor: any;
        };
      }>(this.GRAPHQL_URL, data)
      .pipe(
        rxMap((res: { data: { ShelterInProgressSuccessMentor: any } }) => {
          return res.data.ShelterInProgressSuccessMentor;
        }),
        catchError(err => throwError(err)),
      );
  }

  mutateShelterSuccessMentor (payload: any, mode: TValidShelterSuccessMentorModes, origin: TBatchActionsOrigin): Observable<any> {
    let query;
    if (origin === BatchActionOriginOptions.PROFILE_PANEL_LIST) {
      query = this.graphQLShelterSuccessMentorHelperService.getMutateShelterSuccessMentorQueryForProfile(payload, mode);
    } else {
      query = this.graphQLShelterSuccessMentorHelperService.getMutateShelterSuccessMentorQuery(payload, mode);
    }
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http
      .post<{
        data: {
          bulkCreateShelterSuccessMentor: any;
        };
      }>(this.GRAPHQL_URL, data)
      .pipe(catchError(err => throwError(err)));
  }

  public getAttProgMonDataGraphQL (data: { query: string, fetchPolicy: string }): Observable<{ data: Record<string, any> }> {
    return this.http.post<{ data: Record<string, any>}>(this.GRAPHQL_URL, data).pipe(catchError(err => throwError(err)));
  }

  getConfigGraphQL ({ schoolId, domainKey, tabKey }): Observable<object> {
    const query = this.graphQLNvConfigsHelper.getNvConfigsQuery({ schoolId, domainKey, tabKey });
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post<any>(this.GRAPHQL_URL, data).pipe(catchError(err => throwError(err)));
  }

  createExperience$ (payload, mixpanelEvent: TMixpanelEvent<IExperienceMetadata>): Observable<object> {
    const query = this.graphQLExperiencesHelperService.getCreateExperienceQuery(payload);

    const data = {
      query,
      fetchPolicy: 'no-cache',
    };

    return this.http.post<any>(this.GRAPHQL_URL, data).pipe(
      catchError(err => throwError(err)),
      tap(() => this.mixpanelService.trackEvents([mixpanelEvent])),
    );
  }

  bulkCreateExperiences$ (payload): Observable<any> {
    const query = this.graphQLExperiencesHelperService.getBulkCreateExperienceQuery(payload);

    const data = {
      query,
      fetchPolicy: 'no-cache',
    };

    return this.http.post<any>(this.GRAPHQL_URL, data).pipe(
      catchError(err => throwError(err)),
    );
  }

  public editExperience$ (payload): Observable<object> {
    const query = this.graphQLExperiencesHelperService.getEditExperienceMutation(payload);

    const data = {
      query,
      fetchPolicy: 'no-cache',
    };

    return this.http.post<any>(this.GRAPHQL_URL, data).pipe(catchError(err => throwError(err)));
  }

  public deleteExperience$ (experienceId: string): Observable<object> {
    const query = this.graphQLExperiencesHelperService.getDeleteExperienceMutation(experienceId);
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };

    return this.http.post<any>(this.GRAPHQL_URL, data).pipe(catchError(err => throwError(err)));
  }

  public sendInitialCollegeNowEmail$ (studentId: string): Observable<object> {
    const query = this.graphQLCollegeNowHelperService.getSendInitialEmailQuery(studentId);
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };

    return this.http.post<any>(this.GRAPHQL_URL, data).pipe(catchError(err => throwError(err)));
  }

  public getStudentExperience$ ({ schoolId, studentId }): Observable<any> {
    const query = this.graphQLExperiencesHelperService.getStudentExperienceQuery(schoolId, studentId);
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };

    return this.http.post<any>(this.GRAPHQL_URL, data).pipe(catchError(err => throwError(err)));
  }

  public getMonitorConfig (data: any): Observable<any> {
    return this.http.post<any[]>(this.GRAPHQL_URL, data).pipe(catchError(err => throwError(err)));
  }

  public getMonitorData (data: any): Observable<any> {
    return this.http.post<any[]>(this.GRAPHQL_URL, data).pipe(catchError(err => throwError(err)));
  }

  public getNetworkSideNavConfig (clusterId: string, contextPartnerType: TValidPartnerTypes): Observable<any> {
    const query = this.graphQlNetworkSideNavHelperService.getNetworkSideNavConfigQuery(clusterId, contextPartnerType);
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post(this.GRAPHQL_URL, data).pipe(
      rxMap((res: any) => {
        return res.data.NetworkSideNavConfig;
      }),
      catchError(err => throwError(err)),
    );
  }

  public getStudentsRespondGroupings (data: any): Observable<IRespondGraduationSingleStudent[]> {
    return this.http
      .post<any>(this.GRAPHQL_URL, data)
      .pipe(
        rxMap((res: { data: { RespondGroupings: IRespondGraduationSingleStudent[] } }) => {
          return res.data.RespondGroupings;
        }),
        catchError(err => throwError(err)),
      );
  }

  public getRegentsSupportsTableData (payload) : Observable<any> {
    return this.http.post<any[]>(this.GRAPHQL_URL, payload)
      .pipe(
        rxMap((res) => {
          return res;
        }),
        catchError(err => throwError(err)),
      );
  }

  public findIsContactRestricted (payload): Observable<any> {
    return this.http.post<any[]>(this.GRAPHQL_URL, payload).pipe(catchError(err => throwError(err)));
  }

  public bulkCreateTasks$ (studentIds: string[], payload: any, partnerType: TValidPartnerTypes, mixpanelEvents: TMixpanelEvent<ITaskMetadata | IBatchActionMetadata>[]): Observable<any> {
    const query = this.graphQLTasksHelperService.getBulkCreateTaskQuery(studentIds, payload, partnerType);
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };

    return this.http.post<any>(this.GRAPHQL_URL, data).pipe(
      rxMap((res) => {
        return res;
      }),
      catchError(err => throwError(err)),
      tap(() => this.mixpanelService.trackEvents(mixpanelEvents)),
    );
  }

  public createSingleTask$ (studentData: any, payload: any, partnerType: TValidPartnerTypes, mixpanelEvent: TMixpanelEvent<ITaskMetadata>): Observable<any> {
    const query = this.graphQLTasksHelperService.getCreateSingleTaskQuery(studentData, payload, partnerType);
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };

    return this.http.post<any>(this.GRAPHQL_URL, data).pipe(
      rxMap((res) => {
        if (res.errors) throw res.errors;
        return res.data.createSingleTask;
      }),
      catchError(err => throwError(err)),
      tap(() => this.mixpanelService.trackEvents([mixpanelEvent])),
    );
  }

  public editTask$ (payload: any, mixpanelEvent: TMixpanelEvent<ITaskMetadata>): Observable<any> {
    const query = this.graphQLTasksHelperService.getEditTaskQuery(payload);
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };

    return this.http.post<any>(this.GRAPHQL_URL, data).pipe(
      rxMap(res => {
        if (res.errors) throw res.errors;
        return res.data.updateTask;
      }),
      catchError(err => throwError(err)),
      tap(() => this.mixpanelService.trackEvents([mixpanelEvent])),
    );
  }

  public getPaginatedDataForStudent (payload): Observable<any> {
    return this.http.post<any[]>(this.GRAPHQL_URL, payload).pipe(catchError(err => throwError(err)));
  }

  public getActions$ (payload: IApi['GetActionsPayload']): Observable<IAction[]> {
    const query = this.graphQLActionsHelperService.getPortalActionsConfigs(payload);

    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http
      .post<IApi['GetActionsDataRes']>(this.GRAPHQL_URL, data).pipe(
        rxMap((res: IApi['GetActionsDataRes']) => {
          return res.data.ActionsConfig;
        }),
        catchError(err => throwError(err)),
      );
  }

  getFamilyConversationId$ (familyId: string, studentId: string, schoolId: string): Observable<any> {
    const query = `{
      FamilyConversationByStudent(contextPartnerType: "school", contextPartnerId: "${schoolId}", familyId: "${familyId}", studentId: "${studentId}") {
        conversationId
        isReadOnly
        portalUserId
        entityId
      }
    }`;

    const data = {
      query,
      fetchPolicy: 'no-cache',
    };

    return this.http.post<any>(this.GRAPHQL_URL, data).pipe(
      rxMap((res) => {
        if (res.errors) throw res.errors;
        return res.data.FamilyConversationByStudent;
      }),
      catchError(err => throwError(err)),
    );
  };

  getUnreadMessageCount$ (familyId: string, studentId: string, schoolId: string): Observable<any> {
    const query = `{
      FamilyConversationByStudent(contextPartnerType: "school", contextPartnerId: "${schoolId}", familyId: "${familyId}", studentId: "${studentId}") {
        unreadMessageCount
      }
    }`;

    const data = {
      query,
      fetchPolicy: 'no-cache',
    };

    return this.http.post<any>(this.GRAPHQL_URL, data).pipe(
      rxMap((res) => {
        if (res.errors) throw res.errors;
        return res.data.FamilyConversationByStudent;
      }),
      catchError(err => throwError(err)),
    );
  };

  getConversationHeader$ (conversationId: string, participantId: string, requestParams: IConvoDetailsRequestParams): Observable<any> {
    const { schoolId, familyId } = requestParams;
    const query = `{
      ConversationDetails(contextPartnerType: "school", contextPartnerId: "${schoolId}", entityId: "${familyId}", entityType: "family", contactPortalUserId: "${participantId}", conversationId: "${conversationId}") {
        conversationId
        header
        subheader
        messagesCount
      }
    }`;

    const data = {
      query,
      fetchPolicy: 'no-cache',
    };

    return this.http.post<any>(this.GRAPHQL_URL, data).pipe(
      rxMap((res) => {
        if (res.errors) throw res.errors;
        return res;
      }),
      catchError(err => throwError(err)),
    );
  };

  getConversationMessages$ (conversationId: string, participantId: string, requestParams: IConvoDetailsRequestParams, page: number, pageSize: number): Observable<any> {
    const { schoolId, familyId } = requestParams;
    const query = `{
      ConversationDetails(contextPartnerType: "school", contextPartnerId: "${schoolId}", entityId: "${familyId}", entityType: "family", contactPortalUserId: "${participantId}", conversationId: "${conversationId}", pageNumber: ${page}, pageSize: ${pageSize}) {
        conversationId
        isReadOnly
        messages {
          id
          content
          sentBy
          firstName
          lastName
          metadata
          timestamp
        }
      }
    }`;

    const data = {
      query,
      fetchPolicy: 'no-cache',
    };

    return this.http.post<any>(this.GRAPHQL_URL, data).pipe(
      rxMap((res) => {
        if (res.errors) throw res.errors;
        return res;
      }),
      catchError(err => throwError(err)),
    );
  };

  sendFamilyConversationMessage$ ({
    familyId,
    schoolId,
    participantId,
    conversationId,
    messageContent,
    messageMetadata,
  }): Observable<any> {
    const query = `mutation sendFamilyConversationMessage($patch: ConversationDetailInput!) {
      sendFamilyConversationMessage(patch: $patch) {
        id
        conversationId
        sentBy
        content
        metadata
        timestamp
      }
    }`;

    const conversation = {
      conversationId,
      entityId: familyId,
      entityType: 'family',
      partnerId: schoolId,
      partnerType: 'school',
      isPrivate: true,
      isActive: true,
      createdAt: new Date().toISOString(),
    };

    const message = {
      conversationId: conversation.conversationId,
      sentBy: participantId,
      content: messageContent,
      metadata: messageMetadata,
    };

    const participants = [{
      conversationId: conversation.conversationId,
      participantId,
      participantType: 'CARING_ADULT',
      isPrimary: true,
    }];

    const conversationDetailInput = {
      conversation,
      message,
      participants,
    };

    const payload = {
      query,
      variables: { patch: conversationDetailInput },
      fetchPolicy: 'no-cache',
    };

    return this.http.post<any>(this.GRAPHQL_URL, payload).pipe(
      rxMap((res) => {
        if (res.errors) throw res.errors;
        return res;
      }),
      catchError(err => throwError(err)),
    );
  };

  getConversationTags$ (): any {
    const query = `{
      ConversationTags {
        tagName
        tagType
      }
    }`;

    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post<any>(this.GRAPHQL_URL, data).pipe(
      rxMap(({ data: { ConversationTags } }: { data: { ConversationTags: { tagName: string }[] }}) => ConversationTags),
      catchError(() => of([])),
    );
  };
}
