import { each, isArray, reduce } from 'lodash';
import { IFlag } from '../../shared/typings/interfaces/flags.interface';
import * as flagActions from '../actions/flag-actions';

export interface IFlagsState {
  loading: boolean;
  loaded: boolean;
  flagEntities: {
    [id: string]: IFlag;
  };
}

export const initialState: IFlagsState = {
  loading: false,
  loaded: false,
  flagEntities: {},
};

export function reducer(state: IFlagsState = initialState, action: flagActions.FlagAction): IFlagsState {
  switch (action.type) {
    case flagActions.LOAD_FLAGS:
      return {
        ...state,
        loading: true,
      };
    case flagActions.LOAD_FLAGS_SUCCESS:
      const flags = action.payload;
      const flagEntities: IFlagsState['flagEntities'] = reduce(
        flags,
        (acc, flag) => {
          acc[flag._id] = flag;

          return acc;
        },
        {},
      );

      return {
        ...state,
        loading: false,
        loaded: true,
        flagEntities,
      };
    case flagActions.UPDATE_FLAG_SUCCESS: {
      let flagEntities: IFlagsState['flagEntities'] = Object.assign({}, state.flagEntities);

      if (isArray(action.payload)) {
        each(action.payload, updatedFlag => {
          const updatedFlagId = updatedFlag.itemId;
          flagEntities[updatedFlagId] = updatedFlag.patch;
        });
      } else {
        const updatedFlag = action.payload;
        const updatedFlagId = action.payload._id;
        flagEntities[updatedFlagId] = updatedFlag;
      }
      return {
        ...state,
        flagEntities,
      };
    }
    case flagActions.UPDATE_FLAG_FAIL: {
      return {
        ...state,
      };
    }
    default:
      return state;
  }
}
