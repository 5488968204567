import { ApiService } from 'Src/ng2/shared/services/api-service/api-service';
import { ImUser } from './../../services/im-models/im-user';
import { SupportStatuses } from './../../constants/support-statuses.constant';
import { IStudentSupport } from 'Src/ng2/shared/typings/interfaces/student-support.interface';
import { LoadStudentSupports } from './../../../store/actions/student-supports-actions';
import { getStudentSupportsLoadedStatus, getStudentSupportsEntitiesList } from './../../../store/selectors/student-supports-selectors';
import { LoadSupports } from './../../../store/actions/supports-actions';
import { getSupportsEntitiesList, getSupportsLoadedStatus } from './../../../store/selectors/supports-selectors';
import { map as rxMap } from 'rxjs/operators';
import { getSchool } from './../../../store/selectors/school-selectors';
import { Observable } from 'rxjs';
import { getUsersEntities, getUsersLoadedStatus } from './../../../store/selectors/users-selectors';
import { StoreDataService } from './../../services/store-data-service/store-data.service';
import { SupportCategories } from './../../constants/support-categories.constant';
import { IPickerOption } from 'projects/shared/nvps-libraries/design/nv-multi-picker/nv-multi-picker.interface';
import { subjectsForRegentsAndAcademicCatPatches } from './support-modal.config';
import { RegentsSubject } from './../../constants/regents.constant';
import { sortBy, uniq, map, reduce, flatten, size, values, compact } from 'lodash';
import { supportSubjectOptionsBySchool } from './support-subject-options-by-school.constant';
import { Injectable, Inject } from '@angular/core';
import { ISchool, isElementaryOnly, TValidSchoolTypes } from '../../typings/interfaces/school.interface';
import { IUserMini } from '../../typings/interfaces/user.interface';
import { LoadUsers } from 'Src/ng2/store/actions';

const supportCats = {
  REGENTS_PREP: 'REGENTS_PREP',
  ATTENDANCE: 'ATTENDANCE',
  ACADEMIC: 'ACADEMIC',
  PHYS_HEALTH_SERVICES: 'PHYS_HEALTH_SERVICES',
};

const catsWithMeta = [
  supportCats.REGENTS_PREP,
  supportCats.ACADEMIC,
  supportCats.PHYS_HEALTH_SERVICES,
  supportCats.ATTENDANCE,
];

@Injectable()
export class SupportModalService {
  constructor (
    private storeDataService: StoreDataService,
    private imUser: ImUser,
    private apiService: ApiService,
  ) {}

  public getFormVariants (mode: string): { title: string; actionButtonLabel: string } {
    switch (mode) {
      case 'CREATE':
        return {
          title: 'Create new support',
          actionButtonLabel: 'Create',
        };
      case 'EDIT':
        return {
          title: 'Edit support',
          actionButtonLabel: 'Update',
        };
      case 'DUPLICATE':
        return {
          title: 'Duplicate support',
          actionButtonLabel: 'Duplicate',
        };
    }
  }

  //
  private getSubjectBuckets (schoolType): any {
    // in the case of hybrid schools, the subjects constant will
    // return two keys for ELA -  'ELA_CORE' and 'ELA_CORE_MS'. They share the same
    // human readble value, 'ELA', which is what the schema accepts anwyays. So
    // just dumping one from the menu.
    const academicSubjects = sortBy(uniq(map(supportSubjectOptionsBySchool[schoolType], sub => sub.humanShort)));
    const regentsSubjects = sortBy(
      reduce(
        RegentsSubject,
        (res, subj) => {
          res.push(subj.longName);
          return res;
        },
        [],
      ),
    );

    // if BOTH regents and acad cats are chosen, the available subject options
    // need to be limited to those that fulfil REGENTS_PREP cat validation requirements first
    // and then also fulfill cat ACADEMIC validation requirements.
    const mergedSubjects = sortBy(
      reduce(
        subjectsForRegentsAndAcademicCatPatches,
        (res, v, k) => {
          res.push(v.display);
          return res;
        },
        [],
      ),
    );
    return {
      regentsSubjects,
      academicSubjects,
      mergedSubjects,
    };
  }

  subjectBuckets: {
    regentsSubjects: any[];
    academicSubjects: any[];
    mergedSubjects: any[];
  };

  public getDynamicSubjects (selectedCats, schoolType) {
    const buckets = this.getSubjectBuckets(schoolType);
    const academicSelected = selectedCats.includes(supportCats.ACADEMIC);
    const regentsSelected = selectedCats.includes(supportCats.REGENTS_PREP);
    if (academicSelected && !regentsSelected) {
      return buckets.academicSubjects;
    } else if (!academicSelected && regentsSelected) {
      return buckets.regentsSubjects;
    } else if (academicSelected && regentsSelected) {
      return buckets.mergedSubjects;
    }
  }

  public getDynamicSubcategories (selectedCats, subcatBuckets): IPickerOption[] {
    const mergedSubCats = reduce(
      subcatBuckets,
      (res, bucket, key) => {
        const cond = selectedCats.includes(key);
        if (cond) res.push(bucket);
        return res;
      },
      [],
    );
    const flatCats = flatten(mergedSubCats);
    return values(flatCats);
  }

  private getGroupedSubcats (cat): IPickerOption[] {
    if (cat.subOptions) {
      return map(cat.subOptions, subOpt => {
        return {
          key: subOpt.value,
          human: subOpt.humanName,
        };
      });
    }
  }

  public getCategoryBasedOptions (schoolType : TValidSchoolTypes): any {
    const res = { categories: [], groupedSubcats: {} };
    const mappedData = reduce(
      SupportCategories,
      (res, cat) => {
        //Leave out regents prep for elementary students.
        if(cat.key === supportCats.REGENTS_PREP && isElementaryOnly(schoolType)) {
          return res;
        }

        if (!cat.key || cat.key === 'COLLEGE_CAREER') return res;
        if (catsWithMeta.includes(cat.key) && cat.key !== supportCats.REGENTS_PREP) {
          res.categories.push({ key: cat.key, human: cat.humanName, metaData: {} });
          res.groupedSubcats[cat.key] = this.getGroupedSubcats(cat);
        } else {
          res.categories.push({ key: cat.key, human: cat.humanName, metaData: {} });
        }
        return res;
      },
      res,
    );
    mappedData.categories = sortBy(compact(mappedData.categories), 'human');
    return mappedData;
  }

  public getTermBasedDateRange (term, schoolTermInfo) {
    return reduce(
      schoolTermInfo,
      (res: any, value) => {
        if (term === value.termOption) {
          res.currentStart = value.termStartDate;
          res.currentEnd = value.termEndDate;
        }
        return res;
      },
      {},
    );
  }

  public getValidActivityLeadsPayload (leads, users) {
    const fullMinis = map(leads, (leadId: string) => {
      return reduce(
        users,
        (res, userDoc) => {
          if (userDoc.key === leadId) {
            res.push(userDoc.user);
          }
          return res;
        },
        [],
      );
    });
    return flatten(fullMinis);
  }

  public getValidCategoriesPayload (categories) {
    return map(categories.value, cat => {
      return {
        category: cat.key,
        metaData: size(cat.metaData) ? cat.metaData : null,
      };
    });
  }

  public getUsers$ (schoolId: string): Observable<IUserMini[]> {
    return this.storeDataService.loadDataAndGetStream$({ schoolId }, getUsersLoadedStatus, LoadUsers, getUsersEntities)
      .pipe(
        rxMap(users => {
          let userMinis = users
            .filter(({ authorizationStatus }) => authorizationStatus === 'FULL_ACCESS')
            .map((user) => this.imUser.toMiniUser(user));

          userMinis = sortBy(userMinis, 
            [user => user.firstName?.toLowerCase(), user => user.lastName?.toLowerCase()]);
          return userMinis
            .map(user => {
              const { firstName, lastName, userId } = user;
              const nameString = `${firstName} ${lastName}`;
              return {
                key: nameString,
                human: nameString,
                user,
                id: userId,
              };
            });
          
        }),
      );
  }

  public getSchool$ (): Observable<ISchool> {
    return this.storeDataService.getDataFromStore$(getSchool);
  }

  public getSupportNames$ (schoolId: string): Observable<string[]> {
    const payload = { schoolId };
    return this.storeDataService
      .loadDataToStore$(payload, getSupportsLoadedStatus, LoadSupports, getSupportsEntitiesList)
      .pipe(rxMap(supports => supports.map(({ name }) => name)));
  }

  public getStudentIdsForSupport$ (schoolId: string, supportId: string) {
    const payload = { schoolId };
    const studentIdHash = {};

    return this.storeDataService
      .loadDataToStore$(payload, getStudentSupportsLoadedStatus, LoadStudentSupports, getStudentSupportsEntitiesList)
      .pipe(
        rxMap((studentSupports: IStudentSupport[]) => {
          return studentSupports.reduce((acc, { status, support: { supportId: supportMiniId }, student: { studentId, schoolStatus } }) => {
            if (supportMiniId === supportId && status !== SupportStatuses.backend.DELETED && schoolStatus === 'A') {
              if (!studentIdHash[studentId]) studentIdHash[studentId] = true;
              acc.push(studentId);
            }
            return acc;
          }, []);
        }),
      );
  }
}
