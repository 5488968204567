import { MatButtonModule } from '@angular/material/button';
import { WizardFilterActionsModule } from '../../../../wizard-filter-actions/wizard-filter-actions.module';
import { CommonModule } from '@angular/common';
import { SchedulingCheckStep } from './scheduling-check.component';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [SchedulingCheckStep],
  imports: [CommonModule, WizardFilterActionsModule, MatButtonModule],
  exports: [SchedulingCheckStep],
})
export class SchedulingCheckStepModule {
  //
}
