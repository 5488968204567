<div id="container">
  <span class="toolbar" *ngIf="isContentLoaded">
    <nv-button
      *ngFor="let button of toolbarButtons"
      icon="{{ button.icon }}"
      size="{{ button.size }}"
      type="{{ button.type }}"
      (click)="button.action()"
    >
      {{ button.name }}
    </nv-button>
  </span>

  <ng-template [ngIf]="!isContentLoaded">
    <span class="toolbar-skeleton">
      <ngx-skeleton-loader
        *ngFor="let button of toolbarButtons"
        [theme]="skeletonHeaderLineTheme"
      ></ngx-skeleton-loader>
    </span>
  </ng-template>
</div>
