import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from 'Src/ng2/shared/services/api-service/api-service';
import { ICluster } from 'Src/ng2/shared/typings/interfaces/cluster.interface';

@Injectable()
export class ClusterIdResolver implements Resolve<any> {
  constructor (private apiService: ApiService) {}
  resolve (route: ActivatedRouteSnapshot): Observable<Partial<ICluster>> | Observable<never> {
    const clusterId = route.paramMap.get('clusterId');
    const type = route.data.clusterType;
    const params = {
      type,
      clusterId, // for school cluster and shetler cluster
    };
    const projections = ['clusterName'];
    return this.apiService.getCluster({ params, projections });
  }
}
