import * as moment from 'moment';
import { UtilitiesService } from './../utilities/utilities.service';
import { ApiService } from 'Src/ng2/shared/services/api-service/api-service';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { INotification } from '../../typings/interfaces/notification.interface';

@Injectable()
export class ImNotification {
  constructor (private apiService: ApiService, private utilitiesService: UtilitiesService) {}

  filterForActive (notifications: INotification[]): INotification[] {
    const filtered = _.filter(notifications, (notification: INotification) => {
      return notification.status === 'ACTIVE';
    });
    return filtered;
  }

  // filter for only those methods that are “in progress”,
  // uses instance method notificationIsInProgress" method below
  filterForInProgress (notifications: INotification[], user): INotification[] {
    const filtered = _.filter(notifications, (notification: any) => {
      if (this.isInProgressForUser(notification, user)) {
        return notification;
      }
    });
    return filtered;
  }

  // determine whether a given notification is “in progress”, all of these must be true
  isInProgressForUser (notification: INotification, userId: string): boolean {
    const current = moment().toISOString();
    const c1 = current > notification.startsOn || notification.startsOn === null;
    const c2 = current < notification.endsOn || notification.endsOn === null;
    const c3 = !notification.dismissals[userId];

    if (c2 !== true) {
      const path = 'dismissals';
      const newVal = { [userId]: true };
      this._patchNotification(notification._id, path, newVal);
      return false;
    } else if (c1 && c2 && c3) {
      // if today is within start and end , and notification is NOT dismissed
      return true;
    }
  }

  _patchNotification (_id, path, newVal) {
    const patch = this.utilitiesService.setFieldByPath({}, path, newVal);
    this.apiService.patchNotification({ _id, patch });
  }
}
