import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { getBatchActionsSelectedStudentIds } from 'Src/ng2/store';
import { Store } from '@ngrx/store';
import { Subject, SubscriptionLike, takeUntil } from 'rxjs';
import { unsubscribeComponent } from '../../helpers/unsubscribe-decorator/unsubscribe-decorators.helper';

export interface IErrorModalComponentData {
  title: string;
  message: string;
  confirmText?: string;
}

@Component({
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
@unsubscribeComponent
export class ErrorModalComponent implements OnInit {
  title: string;
  message: string;
  confirmText: string;
  public countStudents: number;
  public destroy$: Subject<boolean> = new Subject<boolean>();
  public batchActionSelectedStudentIdsSubscription: SubscriptionLike;
  public studentNoun: string = 'student';

  constructor (
    public dialogRef: MatDialogRef<ErrorModalComponent>,
    private store: Store<any>,
    @Inject(MAT_DIALOG_DATA) public data: IErrorModalComponentData,
  ) {}

  ngOnInit (): void {
    this.title = this.data.title;
    this.message =
      this.data.message || 'The batch action could not be applied to all of the selected students. Please try again.';
    this.confirmText = this.data.confirmText || 'Okay';
    this._setStudentsSelected();
  }

  private _setStudentsSelected () : void {
    const selectedStudentIdsSlice$ = this.store.select(getBatchActionsSelectedStudentIds);
    this.batchActionSelectedStudentIdsSubscription = selectedStudentIdsSlice$
      .pipe(takeUntil(this.destroy$))
      .subscribe((studentIds: string[]) => {
        this.countStudents = studentIds.length;
        if (this.countStudents > 1) this.studentNoun = 'students';
      });
  }

  onConfirm (): void {
    this.dialogRef.close();
  }
}
