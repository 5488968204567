import { IUser } from 'Src/ng2/shared/typings/interfaces/user.interface';
import { WindowRef } from 'projects/shared/services/window-ref/windowRef';
import { ImSupport } from 'Src/ng2/shared/services/im-models/im-support/im-support.service';
import { UtilitiesService } from 'Src/ng2/shared/services/utilities/utilities.service';
import { SupportCategories } from '../../../../../../shared/constants/support-categories.constant';
import { getCurrentUser } from 'Src/ng2/store/selectors/current-user-selectors';
import { tap, take, filter as rxFilter, switchMap } from 'rxjs/operators';
import { ImUser } from '../../../../../../shared/services/im-models/im-user';
import { getUsersEntities, getUsersLoadedStatus } from '../../../../../../store/selectors/users-selectors';
import { Store } from '@ngrx/store';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { pickBy, keys, filter, each, reduce, identity } from 'lodash';
import { LoadUsers } from 'Src/ng2/store';
import { UrlPathService } from 'Src/ng2/shared/services/url-path-service/url-path.service';

@Component({
  selector: 'track-attendance-step',
  templateUrl: './track-attendance-step.component.html',
  styleUrls: ['../shared-step-styles.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TrackAttendanceStep {
  @Input() private schoolSupports;
  public stepLabel: string = 'Invite activity leads to take attendance in support activities';
  public currentSchool;
  public mySupportsUrl;
  public supportsByActivityLead;
  public supportsByActivatedLead;
  public activityLeadsWithoutAccess: number;
  public currentUserSubscription;
  public hasGafeEmail = false;
  private noAccessUsers: Array<{ [key: string]: boolean }>;
  public schoolId: string;

  constructor (
    private store: Store<any>,
    private imUser: ImUser,
    private router: Router,
    private utilitiesService: UtilitiesService,
    private activatedRoute: ActivatedRoute,
    private imSupport: ImSupport,
    private windowRef: WindowRef,
    private urlPathService: UrlPathService,
  ) { }

  ngOnInit () {
    this.schoolId = this.activatedRoute.snapshot.params.schoolId;
    const usersSlice$ = this.store.select(getUsersLoadedStatus).pipe(
      tap((loaded: boolean) => {
        if (!loaded) this.store.dispatch(new LoadUsers({ schoolId: this.schoolId }));
      }),
      rxFilter(identity),
      switchMap(() => this.store.select(getUsersEntities)),
    );
    usersSlice$
      .pipe(
        tap(users => {
          this.noAccessUsers = users.reduce((users, user) => {
            const email = this.imUser.getEffectiveEmail(user);
            const noAccess = this.imUser.isNoAccessUser(user, { partnerId: this.schoolId });
            if (noAccess) users[email] = noAccess;
            return users;
          }, {});
        }),
        take(1),
      )
      .subscribe();
    const currentUserSlice$ = this.store.select(getCurrentUser);
    this.currentUserSubscription = currentUserSlice$.pipe(take(1)).subscribe((user: IUser) => {
      if (user.gafeEmail) this.hasGafeEmail = true;
    });

    this.mySupportsUrl = 'portal.newvisions.org/school/' + this.schoolId + '/lists/supports';
    this.supportsByActivityLead = this.getSupportsByActivityLead(this.schoolSupports);
    this.supportsByActivatedLead = this.getSupportsByActivatedLead(this.supportsByActivityLead);
    this.activityLeadsWithoutAccess = filter(
      this.supportsByActivityLead,
      (v, email: string) => email.split('_')[0] === 'noAccessUser',
    ).length;
  }

  getSupportsByActivityLead (schoolSupports) {
    const inProgressAndUnstartedSupports = this.imSupport.getInProgressAndNotStartedSupports(schoolSupports);
    const uniqueActivityLeadDetails = reduce(
      inProgressAndUnstartedSupports,
      (result: { [key: string]: { supportNames: string[]; supports: any } }, support: any, i) => {
        const supportDetails = {
          supports: [],
          supportNames: [],
        };
        if (support.category === SupportCategories.REGENTS_PREP.key) {
          const activityLeadEmail = this.imUser.getEffectiveEmail(support.activityLead);
          // if activityLead does not have access denoted in the this.noAccessUsers object assign default to filter on,
          // rather than null for all
          const key = this.noAccessUsers[activityLeadEmail] ? `noAccessUser_${i}` : activityLeadEmail;
          result[key] = result[key] || supportDetails;
          result[key].supports.push(support);
          result[key].supportNames.push(support.name);
        }
        return result;
      },
      {},
    );
    return uniqueActivityLeadDetails;
  }

  getSupportsByActivatedLead (supportsByActivityLead) {
    return pickBy(supportsByActivityLead, (val: object, email: string) => {
      return email.split('_')[0] !== 'noAccessUser';
    });
  }

  countOfUniqueActivityLeads (supportsByActivityLead) {
    return keys(supportsByActivityLead).length;
  }

  goToSupportSettings (): void {
    const url = this.urlPathService.computeDistrictUrlPath(`school/${this.schoolId}/settings/supports`);
    this.router.navigate([url]);
  }

  joinSupportNames (supportNames) {
    return supportNames.join(', ');
  }

  openEmail (supportsByActivityLead) {
    const base = 'https://mail.google.com/mail/?view=cm';
    const to = '&to=' + encodeURIComponent(keys(supportsByActivityLead).join(','));
    const sub = '&su=' + encodeURIComponent('Upcoming Regents Prep Activities');
    const body = '&body=' + encodeURIComponent(this.getBody(supportsByActivityLead));
    const url = `${base}${to}${sub}${body}`;
    this.windowRef.nativeWindow.open(url, '_blank');
  }

  getBody (supportsByActivityLead) {
    let body =
      'Hi team,\n\n' +
      'For this round of Regents Prep we will be using a new tool to help us manage and monitor ' +
      'our Regents Prep Program.  Because you are leading one or more Regents prep ' +
      'activities you will be taking attendance and monitoring student participation ' +
      'using the New Visions Data Portal.\n\n' +
      'The activities that you are leading are listed below along with when they occur.\n';
    each(supportsByActivityLead, (value: any, key) => {
      const email = key;
      const supports = value.supports;
      body = body.concat('\n' + email + '\n');
      each(supports, (support: any) => {
        const repeatsOnString = this.imSupport.getMeetsOnScheduleString(support);
        const startHuman = this.utilitiesService.convertISODateToHumanDate(support.schedule.startsOn);
        const fullScheduleString = '  -' + support.name + ': ' + repeatsOnString + ' starting on ' + startHuman + '\n';
        body = body.concat(fullScheduleString);
      });
    });
    body =
      body +
      '\nYou can view your rosters and take attendance at: ' +
      this.mySupportsUrl +
      '.  ' +
      'Please bookmark this URL as it is the one you will use to take attendance for all ' +
      'your Regents prep activities as a part of this pilot.  On [ENTER DATE], we will be hosting ' +
      'a staff training focused on taking attendance.\n\n' +
      'Thanks in advance for helping to make this round of Regents prep a success!\n\n\n' +
      'PS. The attendance taking features are mobile friendly.  Try logging in on your phone!';
    return body;
  }
}
