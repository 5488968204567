<p class="mat-body-2">
    Of the
    <wizard-filter-actions [data]="filteredStudentsIds" [options]="options"></wizard-filter-actions>
    active
    <ng-container *ngIf="!selectedGradDate" [ngPlural]="plannedInPastOrIncomplete.length">
        <ng-template ngPluralCase="0"> students </ng-template>
        <ng-template ngPluralCase="1"> student </ng-template>
        <ng-template ngPluralCase="other"> students </ng-template>
    </ng-container>

    <ng-container *ngIf="selectedGradDate" [ngPlural]="plannedInPastOrIncomplete.length">
        <ng-template ngPluralCase="0"> planned {{ selectedGradDate }} graduates </ng-template>
        <ng-template ngPluralCase="1"> planned {{ selectedGradDate }} graduate </ng-template>
        <ng-template ngPluralCase="other"> planned {{ selectedGradDate }} graduates </ng-template>
    </ng-container>

    in your school
    <wizard-filter-actions [data]="plannedInPastOrIncomplete" [options]="options"></wizard-filter-actions>

    are missing a planned diploma type and/or planned graduation date, or have a planned graduation date in the
    past.
</p>

<button class="step-action-button" mat-raised-button (click)="navigateToGrid()"
    [disabled]="!plannedInPastOrIncomplete.length">
    Review {{ plannedInPastOrIncomplete.length }}

    <ng-container [ngPlural]="plannedInPastOrIncomplete.length">
        <ng-template ngPluralCase="0">students</ng-template>
        <ng-template ngPluralCase="1">student</ng-template>
        <ng-template ngPluralCase="other">students</ng-template>
    </ng-container>
</button>