import { Router } from '@angular/router';
import { ImStudent } from './../../../../shared/services/im-models/im-student.service';
import { TSortDirections } from './../../../../shared/services/list-services/sort-and-filter.service';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { IRowData } from 'Src/ng2/shared/models/list-models';
import { ObjectCache } from 'Src/ng2/shared/services/object-cache/object-cache.service';
import { UrlPathService } from 'Src/ng2/shared/services/url-path-service/url-path.service';

@Injectable()
export class ListNavigationService {
  constructor (
    public objectCache: ObjectCache,
    private imStudent: ImStudent,
    private router: Router,
    private urlPathService: UrlPathService,
  ) {}

  updateUrl (
    sortKey$: BehaviorSubject<string>,
    sortDirection$: BehaviorSubject<TSortDirections>,
    filterFormControl: FormControl,
    madlibModel?: FormGroup | undefined,
    subLocationId: string = null,
  ): void {
    const sortKey = sortKey$.value;
    const sortDirection = sortDirection$.value;
    const search = filterFormControl.value;

    const paramsWithoutMadlib = { sortKey, sortDirection, search, subLocationId };

    madlibModel
      ? this.router.navigate([], { queryParams: { ...paramsWithoutMadlib, ...this.getMadlibQueryParams(madlibModel) }, replaceUrl: true })
      : this.router.navigate([], { queryParams: paramsWithoutMadlib, replaceUrl: true });
  }

  goToStudentProfile (schoolId: string, studentTableRowData: IRowData[]): void {
    let isSummerOnly;
    const [studentCol] = studentTableRowData;
    const studentMetaData = JSON.parse(studentCol.meta);
    const objectToCache: { [key: string]: [string] } = { studentId: [studentMetaData.data] };

    // the way that this function is being used in some context/lists makes it lose the `this` context
    // the check for `this.imStudent` is to avoid calling methods on that service if the `this` context has been lost
    if (schoolId && this.imStudent) {
      const student = { _id: studentMetaData.data };
      isSummerOnly = this.imStudent.isSummerOnly(student, schoolId);

      if (isSummerOnly) {
        // _id is needed for summer only students in the student route `filteredStudents` resolver
        objectToCache._id = [studentMetaData.data];
      }
    }

    const cachedStudent = this.objectCache.cacheObject(objectToCache);
    const url = this.urlPathService.computeDistrictUrlPath(`/school/${schoolId}/student`);
    this.router.navigate([url], { queryParams: { filter: cachedStudent } });
  }

  // TODO - consolidate with goToStudentProfile
  goToShelterStudentProfile (shelterId: string, subLocationId: string, studentTableRowData: IRowData[]): void {
    const [studentCol] = studentTableRowData;
    const studentMetaData = JSON.parse(studentCol.meta);
    const { caresId } = studentMetaData;
    const hashedCaresIds = this.objectCache.cacheObject({ _id: [caresId] });
    const url = this.urlPathService.computeDistrictUrlPath(`/shelter/${shelterId}/student`);
    this.router.navigate([url], { queryParams: { filter: hashedCaresIds, subLocationId } });
  }
  
  goToSupportRoster (schoolId: string, supportTableRowData: IRowData[]): void {
    const [supportCol] = supportTableRowData;
    const { data: supportId } = JSON.parse(supportCol.meta);
    const url = this.urlPathService.computeDistrictUrlPath(`/school/${schoolId}/lists/supports/${supportId}`);
    this.router.navigate([url]);
  }

  private getMadlibQueryParams (madlibModel: FormGroup) {
    const { focus, filter, grouping } = madlibModel.value;
    const formattedGrouping = grouping.wildcardKey ? `${grouping.graphQlKey}=${grouping.wildcardKey}` : grouping.graphQlKey;

    return {
      ...{
        focus: focus.graphQlKey,
        filter: filter.graphQlKey,
        grouping: formattedGrouping, // if wildcard include
      },
    };
  }
}
