import { RollbarService } from './../../../shared/services/rollbar/rollbar.service';
import { EventBus } from './../../../shared/services/event-bus/event-bus';
import { InitializePusher } from './../../../store/actions/pusher-actions';
import { Auth } from './../../../shared/auth/auth.service';
import { PusherService } from './../../../shared/services/pusher/pusher-service';
import { Injectable, Inject } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import * as Rollbar from 'rollbar';

@Injectable()
export class PusherResolver implements Resolve<any> {
  constructor (
    private pusherService: PusherService,
    private auth: Auth,
    private eventBus: EventBus,
    @Inject(RollbarService) private rollbar: Rollbar,
  ) {}

  resolve (route: ActivatedRouteSnapshot): Observable<any> {
    try {
      const idToken = this.auth.getIdToken();
      this.pusherService.pusher.config.auth.headers.Authorization = idToken;
    } catch (err) {
      this.rollbar.debug('PusherService Authorization Header: ', err);
    }

    // This pusher-channel needs to be shared between the frameworks while in a hybrid state
    // Having the same channel/id allows us to block messages to the event initiator(JJ)
    // Update - we want to unsubscribe from any school that is not the current school (JE)
    const currentChannels = this.pusherService.pusher.allChannels();
    const pusherChannelName = this.pusherService.getChannelName(route.params.schoolId);
    currentChannels.forEach(channel => {
      if (channel.name !== pusherChannelName) this.pusherService.pusher.unsubscribe(channel.name);
    });

    const channel = this.pusherService.pusher.channels[pusherChannelName]
      ? this.pusherService.pusher.channels[pusherChannelName]
      : this.pusherService.pusher.subscribe(pusherChannelName);

    const action = new InitializePusher(channel);
    this.eventBus.emitAction(action);

    return of(channel);
  }
}
