import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvDropdownModule } from 'Src/nvps-libraries/design/nv-dropdown/nv-dropdown.module';
import { NvDropdownItemModule } from 'Src/nvps-libraries/design/nv-dropdown-item/nv-dropdown-item.module';
import { NvButtonModule } from 'projects/shared/nvps-libraries/design/nv-button/nv-button.module';
import { MatMenuModule } from '@angular/material/menu';
import { PortfolioGroupingService } from './portfolio-grouping.service';
import { ClusterUserPortfolioListComponent } from './cluster-user-portfolio-list.component';
import { PageHeaderModule } from './../../header/page-header/page-header.module';
import { ListContainerModule } from './../../list/list-container/list-container.module';
import { PortfolioPermissionDropdownModule } from './../portfolio-permission-dropdown/portfolio-permission-dropdown.module';

@NgModule({
  imports: [
    CommonModule,
    MatMenuModule,
    NvDropdownModule,
    NvDropdownItemModule,
    NvButtonModule,
    PageHeaderModule,
    ListContainerModule,
    PortfolioPermissionDropdownModule,
  ],
  declarations: [ClusterUserPortfolioListComponent],
  exports: [ClusterUserPortfolioListComponent],
  providers: [PortfolioGroupingService],
})
export class ClusterUserPortfolioListModule {}
