import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PATH_CATEGORY_KEYS } from '../../../../constants/student-paths.constant';
import { BaseStudentPathModalForm } from '../base-student-path-modal-form.component';
import { ApiService } from '../../../../../shared/services/api-service/api-service';
import { formToStudentPathSubdoc } from '../path-category-form-utils';
import { IPathSubdoc } from 'Src/ng2/shared/typings/interfaces/studentPaths.interface';

@Component({
  selector: 'public-service-form',
  templateUrl: './public-service-form.component.html',
  encapsulation: ViewEncapsulation.None,
})

export class PublicServiceForm extends BaseStudentPathModalForm implements OnInit {
  public formGroup: FormGroup;

  // empty state text
  public readonly emptyStateProgram = 'No programs found';
  public readonly emptyStateTransitionSupportOrg = 'No transition support organizations found';

  constructor (
    apiService: ApiService,
    private formBuilder: FormBuilder,
  ) {
    super(apiService);
  };

  ngOnInit (): void {
    this.formGroup = this.formBuilder.group({
      program: [null, Validators.required],
      typeOfPlacement: [null],
      transitionSupportOrg: [null],
    });

    super.initForm(PATH_CATEGORY_KEYS.PUBLIC_SERVICE, this.schoolId);
  }

  getStudentPath (): IPathSubdoc {
    const studentPath = formToStudentPathSubdoc(this.formGroup);
    return studentPath;
  }

  handleClick (): void {
    this.onEmitStudentPath();
  }
}
