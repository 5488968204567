/* tslint:disable */

import { Action } from '@ngrx/store';
export const LOAD_SCHOOL = '[Portal] Load School';
export const LOAD_SCHOOL_FAIL = '[Portal] Load School Fail';
export const LOAD_SCHOOL_SUCCESS = '[Portal] Load School Success';

export const UPDATE_SCHOOL = '[Portal] Update School';
export const UPDATE_SCHOOL_SUCCESS = '[Portal] Update School Success';
export const UPDATE_SCHOOL_FAIL = '[Portal] Update School Fail';

export class LoadSchool implements Action {
  readonly type = LOAD_SCHOOL;
  constructor (public payload: any) {}
}

export class LoadSchoolFail implements Action {
  readonly type = LOAD_SCHOOL_FAIL;
  constructor (public payload: any) {}
}

export class LoadSchoolSuccess implements Action {
  readonly type = LOAD_SCHOOL_SUCCESS;
  constructor (public payload: object) {}
}

export class UpdateSchool implements Action {
  readonly type = UPDATE_SCHOOL;
  constructor (public payload: any) {}
}

export class UpdateSchoolSuccess implements Action {
  readonly type = UPDATE_SCHOOL_SUCCESS;
  constructor (public payload: any) {}
}

export class UpdateSchoolFail implements Action {
  readonly type = UPDATE_SCHOOL_FAIL;
  constructor (public payload: any) {}
}

// action types
export type SchoolAction = LoadSchool | LoadSchoolFail | LoadSchoolSuccess | UpdateSchool | UpdateSchoolSuccess | UpdateSchoolFail;
