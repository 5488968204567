import { IGroupData } from './../../models/list-models';
import { AfterViewInit, Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { BaseModalComponent } from '../base-modal.component';

export interface IListSummaryModalEventObj {
  sectionIndex: number;
}

@Component({
  selector: 'list-summary-modal',
  templateUrl: './list-summary-modal.component.html',
  styleUrls: ['./list-summary-modal.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ListSummaryModalComponent extends BaseModalComponent implements OnInit, AfterViewInit {
  // Modal Configurations
  title: string = 'Summary';
  // this boolean hides selected student count that is only applicable to batch actions related modals - jchu
  isProfileMode: boolean = true;

  tableColumns: string[] = ['name', 'count'];
  summaryData = new MatTableDataSource<IGroupData>(this.data.data);
  itemsPerPage: number = 10;
  groupHumanName: string;
  pageEvent: PageEvent;
  countLabel: string;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  constructor (
    dialogRef: MatDialogRef<ListSummaryModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { data: IGroupData[], groupHumanName: string, countLabel: string },
  ) {
    super(dialogRef);
    this.countLabel = data.countLabel;
  }

  public ngOnInit (): void {
    this.groupHumanName = this.data.groupHumanName;
  }

  public ngAfterViewInit (): void {
    this.summaryData.paginator = this.paginator;
  }

  public onCancel (): void {
    super.close();
  }

  public onRowClick ($rowIndex: number): void {
    const pageIndex = this.pageEvent ? this.pageEvent.pageIndex : 0;
    const sectionIndex = $rowIndex + pageIndex * this.itemsPerPage;
    const $event: IListSummaryModalEventObj = { sectionIndex };
    super.close($event);
  }
}
