<!-- part I - basic info -->
<ng-container *ngIf="data.mode !== 'PORTFOLIO'">
  <ng-container *ngIf="(clusterDetail$ | async) as clusterDetail">
    <div class="base-modal-shell shelter-cluster-user-modal-shell">
      <ng-container *ngIf="v4ModeIsOn; else oldSkin">
        <div class="dialog-title">
          <h1>{{ title }}</h1>
          <nv-icon-button [name]="iconName" (clickButton)="onCancel()"></nv-icon-button>
        </div>
      </ng-container>
      <!--TODO: v4 cleanup: remove oldSkin-->
      <ng-template #oldSkin>
        <div class="dialog-title">
          <nv-icon-button [name]="iconName" (clickButton)="onCancel()"></nv-icon-button>
          <h1>{{ title }}</h1>
          <div></div>
        </div>
      </ng-template>
      <ng-container *ngIf="userBasicForm">
        <mat-dialog-content>
          <!-- 1. first name, last name input -->
          <div class="people-name-fields">
            <nv-form-field
              label="First name"
              [isInvalid]="userBasicForm.controls['firstName'].invalid"
              [showInvalid]="
                userBasicForm.controls['firstName'].invalid && !userBasicForm.controls['firstName'].pristine
              "
            >
              <nv-textbox
                placeholder="First name"
                icon="person-large-black"
                [hasClearBtn]="true"
                [showInvalid]="true"
                [showInvalidOnPristine]="false"
                [textControl]="userBasicForm.controls['firstName']"
                (clearValue)="onClearInput('firstName')"
              ></nv-textbox>
            </nv-form-field>
            <nv-form-field
              label="Last name"
              [isInvalid]="userBasicForm.controls['lastName'].invalid"
              [showInvalid]="userBasicForm.controls['lastName'].invalid && !userBasicForm.controls['lastName'].pristine"
            >
              <nv-textbox
                placeholder="Last name"
                icon="person-large-black"
                [hasClearBtn]="true"
                [showInvalid]="true"
                [showInvalidOnPristine]="false"
                [textControl]="userBasicForm.controls['lastName']"
                (clearValue)="onClearInput('lastName')"
              ></nv-textbox>
            </nv-form-field>
          </div>
          <!-- 2. required email input -->
          <nv-form-field
            [label]="clusterDetail.loginMethod.required + ' email'"
            [isInvalid]="userBasicForm.controls[reqEmailType].invalid"
            [showInvalid]="
              userBasicForm.controls[reqEmailType].invalid && !userBasicForm.controls[reqEmailType].pristine
            "
          >
            <nv-textbox
              [placeholder]="'e.g. username@' + reqEmailPlaceholder"
              icon="email-large-black"
              [hasClearBtn]="true"
              [showInvalid]="true"
              [showInvalidOnPristine]="false"
              [textControl]="userBasicForm.controls[reqEmailType]"
              (clearValue)="onClearInput(reqEmailType)"
            ></nv-textbox>
            <ng-container *ngIf="userBasicForm.controls[reqEmailType].errors as reqEmailErrs">
              <ng-container *ngIf="reqEmailErrs.domain || reqEmailErrs.email">
                <nv-note class="email-hint" size="small" priority="danger"
                  >Email must end in {{ reqEmailPlaceholder }}</nv-note
                >
              </ng-container>
            </ng-container>
          </nv-form-field>
          <!-- 3. optional email input -->
          <ng-container *ngIf="clusterDetail.loginMethod.optional">
            <nv-form-field
              [label]="clusterDetail.loginMethod.optional + ' email (optional)'"
              [isInvalid]="userBasicForm.controls[optEmailType].invalid"
              [showInvalid]="
                userBasicForm.controls[optEmailType].invalid && !userBasicForm.controls[optEmailType].pristine
              "
            >
              <nv-textbox
                [placeholder]="'e.g. username@' + optEmailPlaceholder"
                icon="email-large-black"
                [hasClearBtn]="true"
                [showInvalid]="true"
                [showInvalidOnPristine]="false"
                [textControl]="userBasicForm.controls[optEmailType]"
                (clearValue)="onClearInput(optEmailType)"
              ></nv-textbox>
              <ng-container *ngIf="userBasicForm.controls[optEmailType].errors as optEmailErrs">
                <ng-container *ngIf="optEmailErrs.domain || optEmailErrs.email">
                  <nv-note class="email-hint" size="small" priority="danger"
                    >Email must end in {{ optEmailPlaceholder }}</nv-note
                  >
                </ng-container>
              </ng-container>
            </nv-form-field>
          </ng-container>
          <!-- 4. optional title input -->
          <nv-form-field label="Job title (optional)">
            <nv-textbox
              placeholder="Job title"
              icon="briefcase-large-blue"
              [hasClearBtn]="true"
              [textControl]="userBasicForm.controls['title']"
              (clearValue)="onClearInput('title')"
            ></nv-textbox>
          </nv-form-field>
          <!-- 5. access level dropdown -->
          <nv-form-field label="Has access">
            <nv-dropdown
              [options]="accessLevels"
              [selected]="selectedAccessLevelKey"
              (selectOption)="onSelectAccessLevel($event)"
            ></nv-dropdown>
          </nv-form-field>
          <!-- 6. conditional checkbox for `can manage users` -->
          <!-- NOT applicable to shelter cluster user management -->
          <!-- 7. conditional alert msg for when setting hybrid cluster user to no access -->
          <hybrid-cluster-user-alert [warningContent]="warningContent"></hybrid-cluster-user-alert>
          <!-- 8. error msg for when a dupe record found in db based on email -->
          <ng-container *ngIf="emailDupesErrMsg">
            <ng-container *ngIf="needsHelpLink; else shortTemplate">
              <nv-note size="small" priority="danger">
                <span> {{ emailDupesErrMsg }} Please <a href="javascript:void(0);" (click)="getHelp()">contact support</a>. </span>
              </nv-note>
            </ng-container>
            <ng-template #shortTemplate>
              <nv-note size="small" priority="danger">
                <span> {{ emailDupesErrMsg }} </span>
              </nv-note>
            </ng-template>
          </ng-container>
          <!-- 9. error msg for all other errors -->
          <ng-container *ngIf="generalErrMsg">
            <nv-note size="small" priority="danger">
              <span> {{ generalErrMsg }} </span>
            </nv-note>
          </ng-container>
        </mat-dialog-content>
        <div class="dialog-actions">
          <nv-button type="secondary" (clickButton)="onCancel()">Cancel</nv-button>
          <nv-button (clickButton)="onClickPrimaryBtn()" [disabled]="userBasicForm.pristine || userBasicForm.invalid">{{
            primaryBtnName
          }}</nv-button>
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
<!-- part II - portfolio -->
<ng-container *ngIf="data.mode === 'PORTFOLIO'">
  <ng-container *ngIf="(portfolioGroupings$ | async) as groupings">
    <cluster-user-portfolio-list
      [iconName]="portfolioSeconIcon"
      [btnName]="portfolioPrimaryBtn"
      [groupingData$]="portfolioGroupings$"
      [user]="user"
      [portfolioListComponentConfig]="portfolioListComponentConfig"
      (clickedPrimaryBtn)="onPortfolioPrimaryBtn($event)"
      (clickedSeconBtn)="onPortfolioSecondaryBtn($event)"
    >
  </cluster-user-portfolio-list>
  </ng-container>
</ng-container>
