import { switchMap, take } from 'rxjs/operators';
import { getSchool } from '../../../../ng2/store';
import { Observable, of } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { DeprecatedRouteService } from '../../../shared/services/route-services/deprecated-route.service';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { UrlPathService } from 'Src/ng2/shared/services/url-path-service/url-path.service';

@Injectable()
export class ListDeprecationGuard implements CanActivate {
  constructor (
    private store: Store<any>,
    private router: Router,
    private deprecatedRouteService: DeprecatedRouteService,
    private urlPathService: UrlPathService,
  ) {}

  canActivate (snapshot: ActivatedRouteSnapshot): Observable<UrlTree| boolean> {
    const { queryParams, routeConfig } = snapshot;
    const listType = routeConfig.path;

    const isSortParamDeprecated = this.deprecatedRouteService.isParamDeprecated({
      stateOrUrl: `/lists/${listType}`,
      routeParams: queryParams,
      param: 'sortKey',
    });

    if (isSortParamDeprecated) {
      let supportId;
      if (listType === 'supports') {
        const supportIdPieces = window.location.pathname.split('/');
        const hasSupportId = supportIdPieces.length && supportIdPieces[supportIdPieces.length - 1] !== 'supports';
        if (hasSupportId) supportId = supportIdPieces[supportIdPieces.length - 1];
      }

      let targetUrlLocation = `${listType}`;
      if (supportId) targetUrlLocation = `${listType}/${supportId}`;

      return this.store.pipe(
        select(getSchool),
        take(1),
        switchMap(({ _id: schoolId }) => {
          const paramsForRedirect = this.deprecatedRouteService.getNewParamsForRedirect({
            stateOrUrl: `/lists/${listType}`,
            routeParams: queryParams,
            param: 'sortKey',
          });
          const url = this.urlPathService.computeDistrictUrlPath(`/school/${schoolId}/lists/${targetUrlLocation}`);
          return of(this.router.createUrlTree([url], { queryParams: paramsForRedirect }));
        }),
      );
    } else {
      return of(true);
    }
  }
}
