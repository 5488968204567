import { Action } from '@ngrx/store';
import { MetricsActionsTypes } from '../actions/metrics-actions';

export interface IMetricsState {
  changeMade: boolean;
}

export const initialState: IMetricsState = {
  changeMade: false,
};

export function reducer(state: IMetricsState = initialState, action: Action): IMetricsState {
  switch (action.type) {
    case MetricsActionsTypes.CHANGE_MADE_IN_METRICS:
      return {
        ...state,
        changeMade: true,
      };

    case MetricsActionsTypes.CHANGE_SAVED_IN_METRICS: {
      return {
        ...state,
        changeMade: false,
      };
    }
    default:
      return state;
  }
}
