import { Injectable } from '@angular/core';

@Injectable()
export class ImFlag {
  constructor() {
    // empty block
  }

  isActive(flag) {
    return flag.status === 'ACTIVE';
  }
}
