import { PortalSwitcherModule } from '../../shared/components/portal-switcher/portal-switcher.module';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { SharedServicesModule } from '../../shared/services/shared.services.module';
import { LogOutModule } from '../log-out/log-out.module';
import { SideNavNgComponent } from './side-nav.component';
import { NvLogoModule } from 'Src/nvps-libraries/design/nv-logo/nv-logo.module';
import { RouterModule } from '@angular/router';
import { NvSidebarListModule } from './../../../nvps-libraries/design/nv-sidebar-list/nv-sidebar-list.module';
import { SideNavConfigService } from './side-nav.config.service';

@NgModule({
  declarations: [SideNavNgComponent],
  imports: [CommonModule, RouterModule, PortalSwitcherModule, SharedServicesModule, LogOutModule, NvLogoModule, NvSidebarListModule],
  exports: [SideNavNgComponent],
  providers: [SideNavConfigService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SideNavNgModule { }
