import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StudentSelSupportsHistoricalDataModalComponent } from './student-sel-supports-historical-data-modal.component';
import { BaseModalModule } from './../base-modal.module';
import { StudentSelSupportsPanelListModule } from './../../../school/lists/student-sel-supports-panel-list/student-sel-supports-panel-list.module';

@NgModule({
  declarations: [StudentSelSupportsHistoricalDataModalComponent],
  imports: [StudentSelSupportsPanelListModule, CommonModule, BaseModalModule],
  exports: [StudentSelSupportsHistoricalDataModalComponent],
})

export class StudentSelSupportsHistoricalDataModule {}