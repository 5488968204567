<ng-container *ngIf="v4ModeIsOn; else oldSkin">
<div class="all-history-modal_v4">
  <!-- Header -->
  <div class="history-modal-banner">
    <div class="icon close clickable" (click)="onCancel()"></div>
    <h2 class="modal-title">{{ modalTitle }}</h2>
    <nv-button (click)="onAddNote()">
      Add Note
    </nv-button>
  </div>
  <!-- Toggle -->
  <div [ngClass]="{deactivated: (hasLogs===false && !hasAppliedFilters)}" class="segmented-control-holder">
    <nv-segmented-control 
    [index]="listIndex" 
    [options]="options"
    (clicked)="onListToggle($event)"
    >
    </nv-segmented-control>
    <filter-logs-options
      [filterOptions]="filterOptions$ | async"
      [userSelectedFilters]="userSelectedFilters"
      (selectedFilters)="onMadlibFilter($event)"
    ></filter-logs-options>
  </div>

  <!-- History logs / Notes -->
  <ag-grid-angular
    class="ag-theme-alpine"
    [modules]="modules"
    [columnDefs]="columnDefs"
    [gridOptions]="gridOptions"
    (gridReady)="onGridReady($event)"
  ></ag-grid-angular>
</div>
</ng-container>
<!---TODO: v4 cleanup: remove oldSkin-->
<ng-template #oldSkin>
  <div class="all-history-modal">
    <!-- Header -->
    <div class="history-modal-banner">
      <div class="icon close clickable" (click)="onCancel()"></div>
      <h2 class="modal-title">{{ modalTitle }}</h2>
      <nv-button (click)="onAddNote()">
        Add note
      </nv-button>
    </div>
    <!-- Toggle -->
    <div [ngClass]="{deactivated: (hasLogs===false && !hasAppliedFilters)}" class="segmented-control-holder">
      <nv-segmented-control 
      [index]="listIndex" 
      [options]="options"
      (clicked)="onListToggle($event)"
      >
      </nv-segmented-control>
      <filter-logs-options
        [filterOptions]="filterOptions$ | async"
        [userSelectedFilters]="userSelectedFilters"
        (selectedFilters)="onMadlibFilter($event)"
      ></filter-logs-options>
    </div>
  
    <!-- History logs / Notes -->
    <ag-grid-angular
      class="ag-theme-alpine"
      [modules]="modules"
      [columnDefs]="columnDefs"
      [gridOptions]="gridOptions"
      (gridReady)="onGridReady($event)"
    ></ag-grid-angular>
  </div>
</ng-template>
