import { ModalsService } from './../../../../../../shared/modals/modals.service';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PrintRegentsStep } from './print-regents-step.component';

@NgModule({
  imports: [CommonModule, MatButtonModule],
  providers: [ModalsService],
  declarations: [PrintRegentsStep],
  exports: [PrintRegentsStep],
})
export class PrintRegentsStepModule {}
