<span *ngIf="showCheckBox">
  <mat-checkbox id="selectAllChk" class="select-all-check nv-mat-checkbox" 
  [checked]="params?.context.parentComponent.isSelectAll"  
  [indeterminate]="params?.context.parentComponent.isInDeterminateSelectAll"
  (change)="toggleSelect()"></mat-checkbox>
</span> 
<span *ngIf="!params?.enableSorting" [class.with-checkbox]="showCheckBox">
  {{ headerName }}
</span> 

<div
  [ngClass]="{ 'ag-header-cell-sorted-asc': params?.column.sort === 'asc', 'ag-header-cell-sorted-desc': params?.column.sort === 'desc' }"
  *ngIf="params?.enableSorting"
  class="ag-cell-label-container"
  role="presentation"
>
  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" aria-hidden="true">
    <span class="ag-icon ag-icon-menu" #menuButton (click)="onMenuClicked($event)" role="presentation"></span></span>
    <div ref="eLabel" class="ag-header-cell-label" role="presentation" unselectable="on" (click)="onSortRequested($event)" >
    <span ref="eText" class="ag-header-cell-text" unselectable="on" role="columnheader" [class.with-checkbox]="showCheckBox" >
      {{ headerName }}
    </span>
   
    <span ref="eSortOrder" class="ag-header-icon ag-header-label-icon ag-sort-order"
     *ngIf="(params?.column.sort === 'asc' || params?.column.sort === 'desc') 
     && params?.column.sortIndex > 0">{{ this.params?.column?.sortIndex+1 }}
    </span>
    <span *ngIf="(params?.column.sort === 'asc' || params?.column.sort === 'desc')" ref="'eSort' + sortSubIcon"  
    [ngClass]="'ag-sort-' + sortIcon + '-icon'" class="ag-header-icon ag-header-label-icon">
      <span class="ag-icon" [ngClass]="'ag-icon-' + sortSubIcon" role="presentation"></span>
    </span>
    <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon"
    [ngClass]="filteredBySelectAllColumn === true ? null : 'ag-hidden' " > 
      <span class="ag-icon ag-icon-filter" unselectable="on" role="presentation"></span></span>
  </div>
</div>
