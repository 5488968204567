import { StudentListPreviewV2Module } from '../../../shared/modals/student-list-preview-v2/student-list-preview-v2.module';
import { WizardFilterActions } from './wizard-filter-actions.component';
import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

@NgModule({
  declarations: [WizardFilterActions],
  exports: [WizardFilterActions],
  imports: [CommonModule, StudentListPreviewV2Module],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class WizardFilterActionsModule { }
