<div class="confirm-dialog confirm-csv-upload-modal">
  <div class="dialog-title">
    <div class="dialog-title-left">
      <nv-icon-button name="close-large-blue" (clickButton)="onCancel()"></nv-icon-button>
      <span>{{ title }}</span>
    </div>
  </div>
  <div *ngIf="recordCount">
    <div class="dialog-body">
      <mat-dialog-content *ngIf="data.title === 'College list upload'">
        <p>
          You are about to make changes to the college list for <span class="bold">{{ recordCount }}</span>
          {{ studentText }}, including assigning colleges.
        </p>
        <p>This action cannot be undone.</p>
      </mat-dialog-content>
      <mat-dialog-content *ngIf="data.title === 'College list upload' && recordCount === 0">
        <p>The Data Portal already contains all the verified information outlined in the selected CSV file.</p>
      </mat-dialog-content>
    </div>
    <div class="dialog-actions">
      <nv-button type="secondary" (clickButton)="onCancel()">Cancel</nv-button>
      <nv-button (clickButton)="onConfirm()" *ngIf="recordCount > 0" [disabled]="recordCount === 0">Upload</nv-button>
      <nv-button (clickButton)="onConfirm()" *ngIf="recordCount === 0">Ok</nv-button>
    </div>
  </div>
  <div *ngIf="recordCount === 0" class="dialog-body">
    <div class="dialog-body">
      <mat-dialog-content>
        <p>{{ emptyMessage }}</p>
      </mat-dialog-content>
    </div>
    <div class="dialog-actions"><nv-button type="primary" (clickButton)="onCancel()">Ok</nv-button></div>
  </div>
</div>
