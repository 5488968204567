<div class="menu-cell-renderer-wrapper">
  <div *ngIf="disabled; else notDisabled">
    <nv-icon
      class="disabled-menu"
      [name]="icon"
      color="blue"
      nvTooltip
      [tooltipData]="tooltip">
    </nv-icon>
  </div>
  <ng-template #notDisabled>
    <nv-icon-button
      [matMenuTriggerFor]="menu" 
      [name]="icon"
      color="blue"> </nv-icon-button>
    <mat-menu #menu="matMenu" panelClass="nv-mat-menu">
      <nv-dropdown-item *ngFor="let option of options" [option]="option" (selectItem)="onSelect($event, data)">
      </nv-dropdown-item>
    </mat-menu>
  </ng-template>

</div>
