import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'instruction-step',
  templateUrl: './instruction-step.component.html',
  encapsulation: ViewEncapsulation.None,
})

export class InstructionStep {
  @Input() public stepLabel: string='Purpose & Instructions';
  @Input() public description: string = '';
}
