import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';

// modules
import { WizardFilterActionsModule } from 'Src/ng2/school/sdc/wizard-filter-actions/wizard-filter-actions.module';

// services
import { ImSchool } from 'Src/ng2/shared/services/im-models/im-school';
import { UtilitiesService } from 'Src/ng2/shared/services/utilities/utilities.service';
import { CreditGapsPriorityGroupingsTransferService } from '../../../services/credit-gaps-priority-groupings/credit-gaps-priority-groupings-transfer.service';

// components
import { CreditGapsWizardTransferTable } from './credit-gaps-transfer-table.component';

@NgModule({
  imports: [CommonModule, WizardFilterActionsModule, MatButtonModule, MatRadioModule, FormsModule],
  exports: [CreditGapsWizardTransferTable],
  declarations: [CreditGapsWizardTransferTable],
  providers: [ImSchool, UtilitiesService, CreditGapsPriorityGroupingsTransferService],
})
export class CreditGapsWizardTransferTableModule { }
