import { IPartnerOrg } from './../../shared/typings/interfaces/partner-org.interface';
/* tslint:disable */

import { Action } from '@ngrx/store';
export const LOAD_PARTNER_ORGS = '[Portal] Load Partner Org';
export const LOAD_PARTNER_ORGS_SUCCESS = '[Portal] Load Partner Org Success';
export const LOAD_PARTNER_ORGS_FAIL = '[Portal] Load Partner Org Failure';
export const CREATE_PARTNER_ORG = '[Portal] Create Partner Org';
export const CREATE_PARTNER_ORG_SUCCESS = '[Portal] Create Partner Org Success';
export const CREATE_PARTNER_ORG_FAIL = '[Portal] Create Partner Org Fail';
export const UPDATE_PARTNER_ORG = '[Portal] Update Partner Org';
export const UPDATE_PARTNER_ORG_SUCCESS = '[Portal] Update Partner Org Success';
export const UPDATE_PARTNER_ORG_FAIL = '[Portal] Update Partner Org Fail';

// register store actions
export class LoadPartnerOrgs implements Action {
  readonly type = LOAD_PARTNER_ORGS;
  constructor(public payload: { schoolId: string }) {}
}

export class LoadPartnerOrgsSuccess implements Action {
  readonly type = LOAD_PARTNER_ORGS_SUCCESS;
  constructor(public payload: { data: { PartnerOrgs: IPartnerOrg[] } }) {}
}

export class LoadPartnerOrgsFail implements Action {
  readonly type = LOAD_PARTNER_ORGS_FAIL;
  constructor(public payload: any) {}
}

export class CreatePartnerOrg implements Action {
  readonly type = CREATE_PARTNER_ORG;
  constructor(public payload: any) {}
}

export class CreatePartnerOrgSuccess implements Action {
  readonly type = CREATE_PARTNER_ORG_SUCCESS;
  constructor(public payload: any) {}
}

export class CreatePartnerOrgFail implements Action {
  readonly type = CREATE_PARTNER_ORG_FAIL;
  constructor(public payload: any) {}
}

export class UpdatePartnerOrg implements Action {
  readonly type = UPDATE_PARTNER_ORG;
  constructor(public payload: { partnerOrgId: string; patch: any }) {}
}

export class UpdatePartnerOrgSuccess implements Action {
  readonly type = UPDATE_PARTNER_ORG_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdatePartnerOrgFail implements Action {
  readonly type = UPDATE_PARTNER_ORG_FAIL;
  constructor(public payload: any) {}
}

// action types
export type PartnerOrgsActions =
  | LoadPartnerOrgs
  | LoadPartnerOrgsSuccess
  | LoadPartnerOrgsFail
  | CreatePartnerOrg
  | CreatePartnerOrgSuccess
  | CreatePartnerOrgFail
  | UpdatePartnerOrg
  | UpdatePartnerOrgSuccess
  | UpdatePartnerOrgFail;
