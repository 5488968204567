import { CUNYGuard } from 'Src/ng2/routing/guards/cuny-guard/cuny-guard';
import { CurrentUserGuard } from '../../../guards/current-user-guard/current-user-guard';
import { SyncCombinatorGuard } from '../../../guards/sync-combinator-guard/sync-combinator-guard';
import { TogglesGuard } from '../../../guards/toggles-guard/toggles-guards';
import { ssvMatcher } from 'Src/ng2/routing/url-matchers/ssv-matcher/ssv-matcher';
import { SchoolSingleColumnLayoutComponent } from 'Src/ng2/school/layouts/school-single-column-layout/school-single-column-layout.component';
import { SchoolSsvFilterResolver } from 'Src/ng2/routing/resolvers/school-ssv/school-ssv-filter/school-ssv-filter.resolver';
import { SchoolSsvSortResolver } from 'Src/ng2/routing/resolvers/school-ssv/school-ssv-sort/school-ssv-sort.resolver';
import { PartnerTypes } from 'Src/ng2/shared/typings/interfaces/partner.interface';

export const profileRoute = {
  path: 'cuny',
  url: '/cuny',
  authenticationRequired: true,
  canActivate: [SyncCombinatorGuard],
  data: {
    guards: [CUNYGuard, CurrentUserGuard, TogglesGuard],
  },
  children: [
    {
      matcher: ssvMatcher, // could be '' or :studentId based on existing logic
      component: SchoolSingleColumnLayoutComponent,
      resolve: {
        filter: SchoolSsvFilterResolver,
        sort: SchoolSsvSortResolver,
      },
      data: {
        contextPartnerType: PartnerTypes.CUNY,
      },
      loadChildren: () =>
        import('../../../../student/student.module').then(m => m.StudentModule),
    },
  ],
};
