import { ColDef } from '@ag-grid-community/core';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, filter, map as rxMap, switchMap, take, tap } from 'rxjs/operators';
import { ApiService } from '../../../shared/services/api-service/api-service';
import { IApi } from '../../../shared/services/api-service/api-service.interface';
import { getGridDataLoaded, getGridDataSlice, getRefreshGridDataInProgress } from '../../../store';
import { LoadGridData } from '../../../store/actions/grid-actions';
import { RefreshGridData, SetRefreshGridDataInProgress } from './../../../store/actions/grid-actions';
import { GridConfigService } from './grid-config.service';

export interface IDataGridColDef extends ColDef {
  graphQlKey: string;
  field: string;
  wildcardKey: string | null;
}

export interface IDataGridColumnRequest {
  columnKey: string;
  field: string;
}

@Injectable()
export class GridService {
  constructor (
    private apiService: ApiService,
    private gridConfigService: GridConfigService,
    private store: Store<any>,
  ) {}

  public getGridConfig$ ({ schoolId, gridType }): Observable<any> {
    const query = `{
      GridConfig(schoolId: "${schoolId}", gridType: "${gridType}") {
        category
        categoryOrder
        cellRenderer
        checkboxSelection
        field
        filter
        filterParams {
          valueFormatter
          values
        }
        graphQlKey
        headerCheckboxSelection
        headerName
        headerTooltip
        hide
        lockPinned
        pinned
        resizable
        sortable
        tags
        tooltipComponentParams {
          tooltipTemplate
          tooltipHeader
        }
        tooltipField
        valueFormatter
        valueGetter
        width
        wildcardKey
      }
    }`;
    const payload = { query, fetchPolicy: 'no-cache' };
    return this.apiService.getStudentsGraphQL(payload).pipe(
      rxMap((res: IApi['GetGridConfigRes']) => {
        const columnDefs = res.data.GridConfig;
        return this.gridConfigService.addCustomFieldsTo(columnDefs);
      }),
      catchError(err => {
        if (err) {
          return throwError(EMPTY);
        }
      }),
    );
  }

  public fetchGridData$ (opts: { schoolId: string; columnDefs: IDataGridColDef[]; studentIds?: string[] }): Observable<any> {
    const { schoolId, columnDefs, studentIds } = opts;
    const columnRequest = this.getColumnRequest(columnDefs);
    const columnFields = this.getColumnFields(columnDefs);
    const query = studentIds
      ? `
      query ($columnRequest: [GridColumnRequest!]) {
        GridData(
          schoolId: "${schoolId}",
          columns: $columnRequest,
          studentIds: [${this.getStudentIdKeys(studentIds)}]
        ) {
          ${columnFields}
        }
      }
    `
      : `
      query ($columnRequest: [GridColumnRequest!]) {
        GridData(
          schoolId: "${schoolId}",
          columns: $columnRequest
        ) {
          ${columnFields}
        }
      }
    `;
    const payload = { query, fetchPolicy: 'no-cache', variables: { columnRequest } };
    return this.apiService.getStudentsGraphQL(payload).pipe(
      rxMap((res: IApi['GetGridDataRes']) => res.data.GridData),
      catchError(err => {
        if (err) {
          return throwError(EMPTY);
        }
      }),
    );
  }

  public getAllGridData$ ({ schoolId, gridType, urlPassedColumnState }): Observable<any> {
    return this.store.pipe(
      select(getGridDataLoaded),
      tap(loaded => {
        if (!loaded) this.store.dispatch(new LoadGridData({ schoolId, gridType, urlPassedColumnState }));
      }),
      filter(loaded => loaded),
      switchMap(() => {
        return this.getDataFromStore$();
      }),
      take(1),
    );
  }

  public refreshGridData$ (opts: { studentIds?: string[]; selectedColumnDefs?: ColDef[] }): Observable<any> {
    const { studentIds, selectedColumnDefs } = opts;
    this.store.dispatch(new SetRefreshGridDataInProgress({ refreshGridDataRunning: true }));
    return this.store.pipe(
      select(getRefreshGridDataInProgress),
      tap(refreshGridDataRunning => {
        if (refreshGridDataRunning) this.store.dispatch(new RefreshGridData({ studentIds, selectedColumnDefs }));
      }),
      filter(refreshGridDataRunning => !refreshGridDataRunning),
      switchMap(() => this.getDataFromStore$()),
      take(1),
    );
  }

  private getDataFromStore$ (): Observable<any> {
    return this.store.pipe(
      select(getGridDataSlice),
      rxMap(({ columnDefs, rowData }) => {
        return { columnDefs, rowData };
      }),
    );
  }

  private getColumnRequest (columnDefs: IDataGridColDef[]): IDataGridColumnRequest[] {
    return columnDefs.map((columnDef: IDataGridColDef) => {
      return {
        columnKey: columnDef.wildcardKey ? `${columnDef.graphQlKey}=${columnDef.wildcardKey}` : columnDef.graphQlKey,
        field: columnDef.field,
      };
    });
  }

  private getColumnFields (columnDefs: ColDef[]): string[] {
    return columnDefs.map((columnDef: ColDef) => {
      return columnDef.field;
    });
  }

  private getStudentIdKeys (studentIds: string[]): string[] {
    return studentIds.map((studentId: string) => {
      return `"${studentId}"`;
    });
  }

  public getColumnDefsForInitialFetch (opts: { urlPassedColumnState?: any[]; columnDefs: IDataGridColDef[] }): IDataGridColDef[] {
    const { urlPassedColumnState, columnDefs } = opts;
    let columnDefsForInitialFetch;
    if (urlPassedColumnState) {
      const urlPassedColumnKeys = urlPassedColumnState.reduce((acc, columnState) => {
        if (!columnState.hide) {
          acc.push(columnState.colId || columnState.field);
        }
        return acc;
      }, []);
      columnDefsForInitialFetch = columnDefs.filter(columnDef => {
        return urlPassedColumnKeys.includes(columnDef.field);
      });
    } else {
      columnDefsForInitialFetch = columnDefs.filter((columnDef: IDataGridColDef) => {
        return !columnDef.hide;
      });
    }
    return columnDefsForInitialFetch;
  }
}
