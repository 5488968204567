import { Component, Input, OnInit } from '@angular/core';
import { TRUNCATED_FIN_AID_STATUSES, orderedHescOptionsHuman } from '../../../../student/common-panels/student-postsec/student-postsec.config';
import { IUserMini } from '../../../typings/interfaces/user.interface';

const selectableHescOptionsHuman = [
  TRUNCATED_FIN_AID_STATUSES.PROCESSED,
  TRUNCATED_FIN_AID_STATUSES.WAIVED
];

@Component({
  selector: 'financial-aid-modal-dropdown',
  styleUrls: ['./financial-aid-modal-dropdown.component.scss'],
  templateUrl: './financial-aid-modal-dropdown.component.html'
})

export class FinancialAidModalDropdown implements OnInit {
    @Input() label: string;
    @Input() tooltip: string;
    @Input() displayStatus: string;
    @Input() realStatus: string;
    @Input() currentUser: IUserMini;

    public options;
    public selected;
    public isDisabled;
    public isHescButtonHidden;
    public disabledDropdownTooltip = "Student financial aid application has been processed and is no longer editable.";
    public resetButtonTooltip = "Reset to the latest value from the Higher Education Services Corps (HESC) report."

    constructor() { }

    ngOnInit() { 
      const { options, selected } = this.initOptions(this.displayStatus);
      
      this.options = options;
      this.selected = selected;
      this.isHescButtonHidden = this.displayStatus === this.realStatus;
      this.isDisabled = this.displayStatus === TRUNCATED_FIN_AID_STATUSES.PROCESSED;
    }

    private initOptions (status) {    
      const dropdownOptions = orderedHescOptionsHuman.map(
        humanStatus => ({
          key: humanStatus,
          human: humanStatus
        })
      );

      const filteredOptions = dropdownOptions.filter(
        option => (selectableHescOptionsHuman.includes(option.key as any) || option.key === status)
      )

      const selectedOption = orderedHescOptionsHuman.find(human => human === status);
      
      return {
        options: filteredOptions,
        selected: selectedOption
      }
    }

    public onSelectOption (key) {
      this.selected = key;
    }

    public resetToRealStatus () {
      const { options, selected } = this.initOptions(this.realStatus);
      
      this.options = options;
      this.selected = selected;
      this.isHescButtonHidden = true;
      this.isDisabled = this.realStatus === TRUNCATED_FIN_AID_STATUSES.PROCESSED;
    }

    // Bind this method to the context of this component since it will be called in the parent
    public getPatchData = () => {
      const hasUserMadeEdit = this.selected !== (this.displayStatus);

      if (hasUserMadeEdit) {
        const isResetting = this.selected === (this.realStatus);

        return {
          dateLastUpdatedAt: new Date().toISOString(),
          lastUpdatedBy: this.currentUser,
          status: isResetting ? null : orderedHescOptionsHuman.indexOf(this.selected)
        }
      }

      return null;
    }
}