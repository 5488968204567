import { NvDropdownMenuModule } from './../nv-dropdown-menu/nv-dropdown-menu.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { NvPillButtonModule } from '../nv-pill-button/nv-pill-button.module';
import { NvDropdownComponent } from './nv-dropdown.component';
import { NvIconModule } from '../nv-icon/nv-icon.module';

@NgModule({
  imports: [CommonModule, NvPillButtonModule, MatMenuModule, MatInputModule, NvIconModule, NvDropdownMenuModule],
  declarations: [NvDropdownComponent],
  exports: [NvDropdownComponent],
})
export class NvDropdownModule {}
