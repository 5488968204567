import { RadioButtonRendererComponent } from './radio-button-renderer.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';

@NgModule({
  imports: [CommonModule, MatButtonModule, MatRadioModule, FormsModule],
  exports: [RadioButtonRendererComponent],
  declarations: [RadioButtonRendererComponent],
  providers: [],
})
export class RadioButtonRendererModule { }
