import { EventFormatterService } from './../../shared/services/mixpanel/event-formatter.service';
import { BACKGROUND_JOB_TIMEOUT_THRESHOLDS, BACKGROUND_JOB_STATUS_TYPES } from './../../shared/services/background-job/background-job.service';
import { identity } from 'lodash';
import { ImStudentSupport } from './../../shared/services/im-models/im-student-support/im-student-support.service';
import { ModalsService } from './../../shared/modals/modals.service';
import { HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Injector, forwardRef } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap, switchMap, takeLast, tap, withLatestFrom, map, timeout } from 'rxjs/operators';
import { BackgroundJob } from 'Src/ng2/shared/services/background-job/background-job.service';
import { ISnackBarData, SnackBarService } from 'Src/ng2/shared/services/snackbar/snackbar.service';
import { UtilitiesService } from 'Src/ng2/shared/services/utilities/utilities.service';
import { IStudentMini } from 'Src/ng2/shared/typings/interfaces/student.interface';
import {
  IStudentSupport,
  TValidStudentSupportBackendStatuses,
} from '../../shared/typings/interfaces/student-support.interface';
import { LoadDocLogs } from '../actions/doc-logs-actions';
import { LoadFlags } from '../actions/flag-actions';
import * as studentSupportsActions from '../actions/student-supports-actions';
import { IBulkCreateStudentSupports, LoadStudentSupports } from '../actions/student-supports-actions';
import * as moment from 'moment';
import { ApiService } from './../../shared/services/api-service/api-service';
import { BackgroundJobNotificationService } from './../../shared/services/background-job-notification-service/background-job-notification-service';
import { IUpdatePayload } from './../actions/flag-actions';
import { BatchActionsEffectsUtilities } from '../utilities/batch-actions-effects-utilities';
import { TBatchActionsOrigin } from 'Src/ng2/shared/components/nv-actions/nv-actions.interface';

interface IAction {
  type: string;
  payload: {
    schoolId: string;
  };
}

interface IUpdateAction {
  type: string;
  payload: IUpdatePayload<IStudentSupport>;
}

export interface IStudentSupportUpdatePayload {
  studentSupportIds: string[];
  status: TValidStudentSupportBackendStatuses;
  startsOn?: string;
  endsOn?: string;
  extendStudentSupports?: boolean,
  origin?: TBatchActionsOrigin;
}

@Injectable()
export class StudentSupportsEffects {
  batchSuccessMessage: string;
  batchFailedMessage: string;
  snackBarData: ISnackBarData = { message: '', duration: 3000 };

  constructor (
    private actions$: Actions,
    private apiService: ApiService,
    private injector: Injector,
    private backgroundJob: BackgroundJob,
    private snackBarService: SnackBarService,
    private store: Store<any>,
    private utilitiesService: UtilitiesService,
    private backgroundJobNotificationService: BackgroundJobNotificationService,
    private imStudentSupport: ImStudentSupport,
    private eventFormatterService: EventFormatterService,
    @Inject(forwardRef(() => ModalsService)) private modalsService: ModalsService,
  ) {
    this.batchSuccessMessage = 'Batch action applied to the selected students.';
    this.batchFailedMessage =
      'The batch action could not be applied to all of the selected students. Please try again.';
  }

  loadStudentSupports$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(studentSupportsActions.LOAD_STUDENT_SUPPORTS),
      switchMap((action: IAction) => {
        const { schoolId } = action.payload;
        return this.apiService.getStudentSupports(schoolId).pipe(
          mergeMap((res: any) => {
            return [new studentSupportsActions.LoadStudentSupportsSuccess(res.data)];
          }),
          catchError(error => {
            return of(new studentSupportsActions.LoadStudentSupportsFail(error));
          }),
        );
      }),
    );
  });

  // additional refactoring to dry up repeated switchMap code across each effect is being handled in
  // https://www.pivotaltracker.com/story/show/160791378 - JYR
  createStudentSupport$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(studentSupportsActions.CREATE_STUDENT_SUPPORT),
      switchMap((action: any) => {
        const { support, studentId, schoolId, startsOn, endsOn } = action.payload;
        const studentSupportFromTemplate = this.imStudentSupport.createTemplateFromSupport(support, startsOn, endsOn);
        (studentSupportFromTemplate as any).student = { studentId };
        (studentSupportFromTemplate as any)._id = this.utilitiesService.createV4Uuid();
        const mixpanelEvent = this.eventFormatterService.getAssignSupportEvent({
          view: 'STUDENT-PROFILE',
          portal: 'SCHOOL',
          categories: support.categories.map(({ category }) => category),
        });
        return this.apiService.createStudentSupport(studentSupportFromTemplate as any, schoolId, mixpanelEvent).pipe(
          switchMap((studentSupport: IStudentSupport) => {
            return [new studentSupportsActions.CreateStudentSupportSuccess(studentSupport)];
          }),
          catchError(error => {
            return of(new studentSupportsActions.CreateStudentSupportFailure(error));
          }),
        );
      }),
    );
  });

  updateStudentSupports$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(studentSupportsActions.UPDATE_STUDENT_SUPPORT),
      switchMap((action: IUpdateAction) => {
        const { id, patch } = action.payload;
        if (patch.startsOn) patch.startsOn = moment(patch.startsOn).format('YYYY-MM-DD');
        if (patch.endsOn) patch.endsOn = moment(patch.endsOn).format('YYYY-MM-DD');
        return this.apiService.patchStudentSupport(id, patch).pipe(
          switchMap((studentSupport: IStudentSupport) => {
            return [new studentSupportsActions.UpdateStudentSupportSuccess(studentSupport)];
          }),
          catchError(error => {
            return of(new studentSupportsActions.UpdateStudentSupportFail(error));
          }),
        );
      }),
    );
  });

  bulkCreateStudentSupports$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(studentSupportsActions.BULK_CREATE_STUDENT_SUPPORTS),
      switchMap((action: any) => {
        const payload: IBulkCreateStudentSupports = action.payload;
        const { studentIds, support, startsOn, endsOn, origin } = payload;
        const studentSupportIds = this.imStudentSupport.createStudentSupportIdsFromStudentIds(studentIds);
        const studentSupport = this.imStudentSupport.createTemplateFromSupport(support, startsOn, endsOn);
        const apiPayload = { studentSupportIds, studentSupport };
        const studentMini: IStudentMini = { studentId: null, lastFirst: null };
        (studentSupport as any).student = studentMini;
        const mixpanelEvents = [
          this.eventFormatterService.getAssignSupportEvent({
            view: 'BATCH-ACTION',
            portal: 'SCHOOL',
            categories: support.categories.map(({ category }) => category),
          }),
        ];
        const { schoolId } = studentSupport;
        const httpResponseObsv$: Observable<HttpResponse<object>> = this.apiService.createStudentSupports(apiPayload as any, mixpanelEvents);
        return httpResponseObsv$.pipe(
          takeLast(1),
          map(response => BatchActionsEffectsUtilities.getJobSubject(this.backgroundJob, { response })),
          tap(subject => {
            this.modalsService.openBackgroundJobSpinnerModal({ backgroundJobSubject: subject, title: 'Assigning student supports' });
          }),
          switchMap(identity),
          timeout(BACKGROUND_JOB_TIMEOUT_THRESHOLDS.BATCH_ACTIONS),
          tap(({ type }) => {
            if (type === BACKGROUND_JOB_STATUS_TYPES.RESOLVE) {
              this.store.dispatch(new LoadStudentSupports({ schoolId }));
              this.store.dispatch(new LoadFlags({ schoolId }));
              this.store.dispatch(new LoadDocLogs({ studentId: studentIds, schoolId }));
              BatchActionsEffectsUtilities.sendSnack(this.snackBarService, { success: true, origin });
              this.backgroundJobNotificationService.sendMessage({ backgroundJob: 'BulkStudentSupportCreate' });
            }
          }),
          catchError(error => {
            BatchActionsEffectsUtilities.sendSnack(this.snackBarService, { success: false, origin });
            return of(new studentSupportsActions.BulkCreateStudentSupportsFail(error));
          }),
        );
      }),
    );
  }, { dispatch: false });

  bulkUpdateStudentSupports$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<any>(studentSupportsActions.BULK_UPDATE_STUDENT_SUPPORTS),
      withLatestFrom(this.store),
      switchMap(([action]) => {
        const payload: IStudentSupportUpdatePayload = action.payload;
        const { origin } = payload;

        if (payload.startsOn) payload.startsOn = new Date(payload.startsOn).toISOString();
        if (payload.endsOn) payload.endsOn = new Date(payload.endsOn).toISOString();

        const httpResponseObsv$: Observable<HttpResponse<object>> = this.apiService.patchStudentSupports(payload);
        return httpResponseObsv$.pipe(
          takeLast(1),
          map(response => BatchActionsEffectsUtilities.getJobSubject(this.backgroundJob, { response })),
          tap(subject => {
            this.modalsService.openBackgroundJobSpinnerModal({ backgroundJobSubject: subject, title: 'Updating student supports' });
          }),
          switchMap(identity),
          timeout(BACKGROUND_JOB_TIMEOUT_THRESHOLDS.BATCH_ACTIONS),
          tap(({ type }) => {
            if (type === BACKGROUND_JOB_STATUS_TYPES.RESOLVE) {
              BatchActionsEffectsUtilities.sendSnack(this.snackBarService, { success: true, origin });
              this.backgroundJobNotificationService.sendMessage({ backgroundJob: 'BulkStudentSupportUpdate' });
              this.store.dispatch(new studentSupportsActions.BulkUpdateStudentSupportsSuccess(payload));
            }
          }),
          catchError(error => {
            BatchActionsEffectsUtilities.sendSnack(this.snackBarService, { success: false, origin });
            return of(new studentSupportsActions.BulkUpdateStudentSupportsFail(error));
          }),
        );
      }),
    );
  }, { dispatch: false });
}
