import { ActivatedRoute, Router } from '@angular/router';
import { ObjectCache } from './../../../../../../shared/services/object-cache/object-cache.service';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { each } from 'lodash';
import { UrlPathService } from 'Src/ng2/shared/services/url-path-service/url-path.service';

@Component({
  selector: 'final-prep-check-step',
  templateUrl: './final-prep-check-step.component.html',
  styleUrls: ['../shared-step-styles.scss', './final-prep-check-step.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FinalPrepCheckStep {
  @Input() filteredStudents;

  public tableRows;
  public options;
  public stepLabel: string = 'Review potential conflicts';
  private schoolId: string;

  constructor (
    private objectCache: ObjectCache,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private urlPathService: UrlPathService,
  ) {}

  ngOnInit (): void {
    this.schoolId = this.activatedRoute.snapshot.params.schoolId;
    this.tableRows = this.populateTableRows(this.filteredStudents);
    this.options = {
      columnKeys: ['cohort', 'gradPlan', 'regentsSchedForNextAdmin', 'currentlyActiveSupports'],
    };
  }

  ngOnChanges ({ filteredStudents }) {
    if (filteredStudents && !filteredStudents.isFirstChange()) {
      this.ngOnInit();
    }
  }

  goToProfile (studentIds: Array<string>) {
    const url = this.urlPathService.computeDistrictUrlPath(`/school/${this.schoolId}/student`);
    const cachedStudent = this.objectCache.cacheObject({ _id: studentIds.map((id) => `${id}${this.schoolId}`) });
    this.router.navigate([url], { queryParams: { filter: cachedStudent } });
  }

  private populateTableRows = students => {
    const tableRows = {
      1: [],
      2: [],
      3: [],
      4: [],
      5: [],
    };
    each(students, (student: any) => {
      const { studentId, countOfCurrentlyActiveSupports } = student;
      if (countOfCurrentlyActiveSupports === 1) {
        tableRows[1].push(studentId);
      } else if (countOfCurrentlyActiveSupports === 2) {
        tableRows[2].push(studentId);
      } else if (countOfCurrentlyActiveSupports === 3) {
        tableRows[3].push(studentId);
      } else if (countOfCurrentlyActiveSupports === 4) {
        tableRows[4].push(studentId);
      } else if (countOfCurrentlyActiveSupports >= 5) {
        tableRows[5].push(studentId);
      }
    });
    return tableRows;
  };
}
