import { Injectable } from '@angular/core';
import { each, flatten, includes, isArray, map, pull, uniq } from 'lodash';

import { UtilitiesService } from '../utilities/utilities.service';
import { IStudent } from '../../typings/interfaces/student.interface';
import { IStudentDessa } from '../../typings/interfaces/dessa.interface';
import { IMethodDependency } from './im-student.service';
import { STUDENT_DESSA_CURR_SCHOOL_YEAR } from '../../constants/current-school-year.constant';
import * as moment from 'moment';

/**
 * `@depends` decorator
 *
 * Use this decorator to state that an ImStudent method depends on certain paths, joins or other methods:
 *    @depends({ paths: ['studentDetails.name'], joins: ['courseDiffs'], methods: ['_getSomething']})
 *    static fullName(student) {  }
 *
 * @param depends Object an object with three optional keys: `paths`, `joins` and `methods`
 * @return {dependencyDecorator}
 */
function depends (depends: IMethodDependency) {
  return function dependencyDecorator (target: any, key: string, descriptor: PropertyDescriptor) {
    descriptor.value.depends = depends;
    return descriptor;
  };
}

@Injectable()
export class ImStudentDessa {
  constructor (
    private utilitiesService: UtilitiesService,
  ) { }

  /**
   * Removes fields from `student` that are not included in the dependent paths or joins
   *
   * @param method
   * @returns {Student} masked student
   */
  maskedStudentForMethod (student: IStudent, method) {
    const paths = this.pathsFor(method);
    const joins = this.joinsFor(method);
    each(joins, join => {
      paths.push(`join_${join}`);
    });
    return this.utilitiesService.generatePatch(student, paths);
  }

  _dependentMethods (method) {
    const resolvedMethods = [];
    const unresolvedMethods = [];

    // See here for the dependency resolution algorithm used:
    // https://www.electricmonk.nl/log/2008/08/07/dependency-resolving-algorithm/
    const resolveMethods = (method, resolvedMethods, unresolvedMethods) => {
      unresolvedMethods.push(method);
      const methods = (this[method].depends || {}).methods || [];
      each(methods, m => {
        if (!includes(resolvedMethods, m)) {
          if (includes(unresolvedMethods, m)) {
            throw new Error(`Circular method dependency detected: ${method} -> ${m}`);
          }
          resolveMethods(m, resolvedMethods, unresolvedMethods);
        }
      });
      resolvedMethods.push(method);
      pull(unresolvedMethods, method);
    };

    resolveMethods(method, resolvedMethods, unresolvedMethods);
    return resolvedMethods;
  }

  /**
   * Traverses the method dependency graph and returns all of the joins that `method` depends on
   *
   * @param method a ImStudent method
   * @return {Array} and array of joins
   */
  joinsFor (method) {
    if (!this[method]) throw new Error(`method '${method}' does not exist`);

    if (!(this[method].depends || {}).methods) return (this[method].depends || {}).joins;
    const dependentMethods = this._dependentMethods(method);
    const dependentJoins = uniq(
      flatten(
        map(dependentMethods, m => {
          const depends = this[m].depends;
          if (!depends) {
            console.warn(`\`${m}\` was detected as a dependent method, but it didn't register any dependencies itself`);
            return [];
          }
          return depends.joins || [];
        }),
      ),
    );
    return dependentJoins;
  }

  pathsFor (method) {
    if (!this[method]) throw new Error(`method '${method}' does not exist`);

    if (!(this[method].depends || {}).methods) {
      const paths = (this[method].depends || {}).paths;
      if (!isArray(paths)) {
        console.warn(
          `this.pathsFor('${method}') was called, but ${method} didn't declare any dependent paths. ` +
          `If ${method} doesn't depend on any paths, annotate it with \`@depends({ paths: [] })\``,
        );
      }
      return paths;
    }
    const dependentMethods = this._dependentMethods(method);
    const dependentPaths = uniq(
      flatten(
        map(dependentMethods, m => {
          const depends = this[m].depends;
          if (!depends) {
            console.warn(`\`${m}\` was detected as a dependent method, but it didn't register any dependencies itself`);
            return [];
          }
          return depends.paths || [];
        }),
      ),
    );
    return dependentPaths;
  }

  @depends({
    paths: ['dessa.history'],
    methods: [],
  })
  getDessaGeneralData (
    doc: IStudentDessa,
    ratingPeriodName: string,
    desiredField: string,
  ) {
    const assessment = this.findDessaMostRecentInTerm(doc, ratingPeriodName);
    return assessment?.general[desiredField];
  }

  @depends({
    paths: ['dessa.history'],
    methods: [],
  })
  findDessaMostRecentInTerm (
    doc: IStudentDessa,
    ratingPeriodName: string,
  ) {
    const assessment = doc.dessa.history.find(({ general }) => (
      general.ratingPeriodName === ratingPeriodName &&
      general.schoolYear === STUDENT_DESSA_CURR_SCHOOL_YEAR &&
      general.isMostRecentInRatingPeriod));
    return assessment;
  }

  @depends({
    paths: ['dessa.history'],
    methods: [],
  })
  getDessaDomainData (
    doc: IStudentDessa,
    ratingPeriodName: string,
    domainName: string,
    desiredField: string,
  ) {
    const asssessment = this.findDessaMostRecentInTerm(doc, ratingPeriodName);
    return asssessment?.domains.find(domain => domain.name === domainName)[desiredField];
  };

  static formatDessaDate (date: string): string {
    const isValidDate = moment(date).isValid();

    return isValidDate ? moment(date).format('YYYY-MM-DD') : date;
  };

  static formatDescription (description: string): string {
    if (description === 'Strong') return 'Strength';
    return description;
  }
};
