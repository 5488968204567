<div *ngIf="(caringAdultOptions$ | async) as caringAdultOptions">
  <nv-form-field label="Caring Adult"></nv-form-field>
  <nv-textbox 
    placeholder="Search for school staff..."
    [autocompleteOptions]="caringAdultOptions"
    (selectOption)="onOptionSelect($event)"
    [textControl]="form.controls.caringAdult"
    returnObject="true"
    hasClearBtn="true"
    (clearValue)="clearTextBoxValue()"
    [optionsPredicateCb]="optionsPredicateCb"
  ></nv-textbox>
</div>