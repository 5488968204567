import { NgModule } from '@angular/core';
import { NvButtonModule } from 'projects/shared/nvps-libraries/design/nv-button/nv-button.module';
import { RegentsNextAdminConflictModalComponent } from './regents-next-admin-conflict.component';

@NgModule({
  declarations: [RegentsNextAdminConflictModalComponent],
  imports: [
    NvButtonModule,
  ],
  exports: [RegentsNextAdminConflictModalComponent],
  providers: [],
})
export class RegentsNextAdminConflictModalModule {}
