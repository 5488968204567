import { Injectable } from '@angular/core';
import { IApi } from '../../api-service/api-service.interface';

@Injectable()
export class GraphQLPartnerHelperService {
  private PROJECTION_MAP_TP_SUBFIELDS = {
    subLocations: '{ addressId address }',
  };

  getPartnerQuery({ params, projections }: IApi['GetPartnerPayload']): string {
    const validType = params && params.type ? `"${params.type}"` : 'null';
    const validId = params && params._id ? `"${params._id}"` : 'null';
    const fields = this.getFields(projections);
    return `{
      Partner(
        type: ${validType}, 
        _id: ${validId}
      ) {
          ${fields}
        }
    }`;
  }

  getPartnersQuery({ params, projections }: IApi['GetPartnersPayload']): string {
    const validType = params && params.type ? `"${params.type}"` : 'null';
    const hasIds = params && params.ids && this.getValidArrArg(params.ids);
    const validIds = hasIds ? `[${hasIds}]` : 'null';
    const validClusterId = params && params.clusterId ? `"${params.clusterId}"` : 'null';
    const fields = this.getFields(projections);
    return `{
      Partners(
        type: ${validType}, 
        ids: ${validIds},
        clusterId: ${validClusterId}
      ) {
          ${fields}
        }
    }`;
  }

  private getValidArrArg(array: string[]): string[] | '' {
    return array.length
      ? array.map(el => {
          return `"${el}"`;
        })
      : '';
  }

  private getFields (projections: string[]): string {
    const _validProjections = projections.length ? projections : ['_id'];
    const fields = _validProjections.reduce((acc, projection) => {
      const subs = this.PROJECTION_MAP_TP_SUBFIELDS[projection];
      if (subs) projection = `${projection} ${subs}`;
      acc += `${projection} \n`;
      return acc;
    }, '');
    return fields;
  }
}
