import { FuturePlanModalComponent } from './future-plan-modal.component';
import { ExperienceModalService } from '../experience-modal-service/experience-modal-service';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { NvFormFieldModule } from 'Src/nvps-libraries/design/nv-form-field/nv-form-field.module';
import { BaseModalModule } from '../../../base-modal.module';
import { NvDropdownModule } from 'Src/nvps-libraries/design/nv-dropdown/nv-dropdown.module';
import { NvButtonModule } from 'projects/shared/nvps-libraries/design/nv-button/nv-button.module';
import { CommonModule } from '@angular/common';
import { NvTextboxModule } from 'Src/nvps-libraries/design/nv-textbox/nv-textbox.module';
import { NvTextareaModule } from 'Src/nvps-libraries/design/nv-textarea/nv-textarea.module';
import { NvDateRangePickerModule } from 'Src/nvps-libraries/design/nv-date-range-picker/nv-date-range-picker.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NvNumboxModule } from 'Src/nvps-libraries/design/nv-numbox/nv-numbox.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ExperiencesModalModule } from '../experiences-modal.module';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatCheckboxModule,
    NvFormFieldModule,
    NvTextboxModule,
    NvTextareaModule,
    NvDateRangePickerModule,
    BaseModalModule,
    NvDropdownModule,
    NvButtonModule,
    NvNumboxModule,
    FormsModule,
    ReactiveFormsModule,
    ExperiencesModalModule,
  ],
  exports: [],
  declarations: [FuturePlanModalComponent],
  providers: [ExperienceModalService],
})
export class FuturePlanModalModule { }
