import { createFeatureSelector, createSelector } from '@ngrx/store';
import { map } from 'lodash';
import { ICollegePathState } from '../reducers/college-path-reducers';

const getPortalState = createFeatureSelector('PORTAL_STORE');

export const getCollegePathState = createSelector(
  getPortalState,
  (state: any) => {
    return state.collegePath;
  },
);

export const getCollegePathEntities = createSelector(
  getCollegePathState,
  (state: ICollegePathState) => {
    return state.collegePathEntities;
  },
);

export const getCollegePathsEntitiesList = createSelector(
  getCollegePathState,
  (state: ICollegePathState) => {
    const collegePathEntities = state.collegePathEntities;
    return map(collegePathEntities, (v, k) => v);
  },
);

export const getCollegePathLoadingStatus = createSelector(
  getCollegePathState,
  (state: ICollegePathState) => {
    return state.loading;
  },
);

export const getCollegePathLoadedStatus = createSelector(
  getCollegePathState,
  (state: ICollegePathState) => {
    return state.loaded;
  },
);
