import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { ITool } from '../../shared/typings/interfaces/tool.interface';
import * as toolsActions from '../actions/tools-actions';
import { ApiService } from './../../shared/services/api-service/api-service';

interface ILoadAction {
  type: string;
  payload: toolsActions.ILoadToolsPayload;
}

interface ICreateAction {
  type: string;
  payload: toolsActions.ICreateSyncToolPayload;
}

interface IUpdateAction {
  type: string;
  payload: toolsActions.IUpdateSyncToolPayload;
}

interface IDeleteAction {
  type: string;
  payload: toolsActions.IDeleteSyncToolPayload;
}

@Injectable()
export class ToolsEffects {
  constructor(private actions$: Actions, private apiService: ApiService) {}

  loadTools$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(toolsActions.LOAD_TOOLS),
      switchMap((action: ILoadAction) => {
        const { schoolId } = action.payload;
        return this.apiService.getTools(schoolId).pipe(
          mergeMap((tools: ITool[]) => {
            return [new toolsActions.LoadToolsSuccess(tools)];
          }),
          catchError(error => {
            return of(new toolsActions.LoadToolsFail(error));
          }),
        );
      }),
    );
  });

  createSyncTool$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(toolsActions.CREATE_SYNC_TOOL),
      switchMap((action: ICreateAction) => {
        return this.apiService.createSyncTool(action.payload).pipe(
          switchMap((syncTool: ITool) => {
            return [new toolsActions.QueueCreateSyncToolSuccess(syncTool)];
          }),
          catchError(error => {
            return of(new toolsActions.QueueCreateSyncToolFail(error));
          }),
        );
      }),
    );
  });

  updateSyncTool$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(toolsActions.UPDATE_SYNC_TOOL),
      switchMap((action: IUpdateAction) => {
        return this.apiService.updateSyncTool(action.payload).pipe(
          switchMap((payload: {syncTool: ITool, duplicateColumns: string[]}) => {
            const { syncTool, duplicateColumns } = payload;
            if (duplicateColumns) {
              return of(new toolsActions.QueueUpdateSyncToolFail(payload));
            } else {
              return [new toolsActions.QueueUpdateSyncToolSuccess(payload)];
            }
          }),
          catchError(error => {
            return of(new toolsActions.QueueUpdateSyncToolFail(error));
          }),
        );
      }),
    );
  });

  deleteSyncTool$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(toolsActions.DELETE_SYNC_TOOL),
      switchMap((action: IDeleteAction) => {
        return this.apiService.deleteSyncTool(action.payload).pipe(
          switchMap(() => {
            return [new toolsActions.QueueDeleteSyncToolSuccess()];
          }),
          catchError(error => {
            return of(new toolsActions.QueueDeleteSyncToolFail(error));
          }),
        );
      }),
    );
  });
}
