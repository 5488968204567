<p class="mat-body-2">
    Of the
    <wizard-filter-actions [data]="activeStudentsIds" [options]="options">
    </wizard-filter-actions>
    active students in
    <span *ngIf="currentFilter !== 'All'">
        the class of {{ currentFilter }}
        <span *ngIf="countActiveSuperSeniors > 0">
            and <wizard-filter-actions [data]="activeSuperSeniorsIds" [options]="options"></wizard-filter-actions>
            older
        </span>
    </span>
    <span *ngIf="currentFilter === 'All'">
        all cohorts
    </span>
</p>

<ul class="mat-body-2">
    <li>
        <b>
            <wizard-filter-actions [data]="activeWithUnaddressedGapsIds" [options]="options"></wizard-filter-actions>
            have unaddressed
            <ng-container [ngPlural]="countActiveWithUnaddressedGaps">
                <ng-template ngPluralCase="1"> credit gap </ng-template>
                <ng-template ngPluralCase="few"> credit gaps </ng-template>
                <ng-template ngPluralCase="other"> credit gaps </ng-template>
            </ng-container>
        </b>
        - meaning, students need a course change this term or a plan to address their gaps in a future term.
    </li>

    <li>
        <wizard-filter-actions [data]="activeWithAddressedGapsIds" [options]="options"></wizard-filter-actions>
        have
        <ng-container [ngPlural]="countActiveWithUnaddressedGaps">
            <ng-template ngPluralCase="1"> credit gap </ng-template>
            <ng-template ngPluralCase="few"> credit gaps </ng-template>
            <ng-template ngPluralCase="other"> credit gaps </ng-template>
        </ng-container>
        that are planned to be addressed by a pending course change or gap plan in this term and/or a future
        term.
    </li>

    <li>
        <wizard-filter-actions [data]="activeWithNoGapsIds" [options]="options"></wizard-filter-actions>
        have no
        <ng-container [ngPlural]="countActiveWithNoGaps">
            <ng-template ngPluralCase="1"> credit gap </ng-template>
            <ng-template ngPluralCase="few"> credit gaps </ng-template>
            <ng-template ngPluralCase="other"> credit gaps </ng-template>
        </ng-container>
    </li>
</ul>

<p class="mat-body-2">
    Use the Credit Gaps panel in the Student Profile to indicate program changes that can be made this term to address
    credit gaps. Program changes can then be exported in Step 5 to upload into your source system. From the Student
    Profile, you can also indicate a plan for gaps to be addressed in future terms.
</p>

<!-- PRI GROUPS -->
<div *ngIf="activeWithUnaddressedGapsIds.length > 0">
    <div *ngIf="priorityGroups.length > 1">
        <p class="mat-body-2">
            The
            <wizard-filter-actions [data]="activeWithUnaddressedGapsIds" [options]="options"></wizard-filter-actions>
            who have

            <ng-container [ngPlural]="countActiveWithUnaddressedGaps">
                <ng-template ngPluralCase="1"> credit gap is </ng-template>
                <ng-template ngPluralCase="few"> credit gaps are </ng-template>
                <ng-template ngPluralCase="other"> credit gaps are </ng-template>
            </ng-container>
            prioritized as follows
        </p>

        <table class="mat-body-2 priority-table">
            <tr *ngFor="let data of priorityGroups">
                <td><span class="description">Priority {{ data.priorityVal }}: </span> <span>{{ data.priorityLabel
                        }}</span></td>
                <td>
                    <div>
                        <button class="step-action-button" mat-raised-button [disabled]="!data.studentIds.length"
                            (click)="navigateToStudents(data.studentIds)">
                            Review {{ data.studentIds.length}}
                        </button>
                    </div>
                </td>
            </tr>
        </table>
    </div>

    <!-- younger cohorts -->
    <div *ngIf="priorityGroups.length === 1">
        <button class="step-action-button" mat-raised-button [disabled]="!priorityGroups[0].studentIds.length"
            (click)="navigateToStudents(priorityGroups[0].studentIds)">
            Review {{ priorityGroups[0].studentIds.length }}
            <ng-container [ngPlural]="priorityGroups[0].studentIds">
                <ng-template ngPluralCase="1"> student </ng-template>
                <ng-template ngPluralCase="few"> students </ng-template>
                <ng-template ngPluralCase="other"> students </ng-template>
            </ng-container>
        </button>
    </div>
</div>