import * as toggleActions from '../actions/toggle-actions';

interface IToggleState {
  loading: boolean;
  loaded: boolean;
  toggleEntities: object;
}

export const initialState = {
  loading: false,
  loaded: false,
  toggleEntities: {},
};

export function reducer (state: IToggleState = initialState, action: toggleActions.ToggleAction): IToggleState {
  switch (action.type) {
    case toggleActions.LOAD_TOGGLES: {
      return { ...state, loading: true };
    }

    case toggleActions.LOAD_TOGGLES_SUCCESS: {
      const toggleEntities = action.payload;
      return {
        ...state,
        loading: false,
        loaded: true,
        toggleEntities,
      };
    }

    case toggleActions.LOAD_TOGGLES_FAIL: {
      return { ...state, loading: false };
    }

    default:
      return state;
  }
}
