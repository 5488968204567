import { StudentSet } from 'Src/ng2/shared/services/student-set/student-set.service';
import { NgModule } from '@angular/core';
import { StudentFetchService } from './student-fetch.service';

@NgModule({
  providers: [StudentFetchService, StudentSet],
})
export class StudentFetchServicesModule {
  //
}
