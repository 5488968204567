<div class="category-tags-renderer-wrapper">
    <div 
        class="history-log"
        [ngClass]="{ 'normal-line-height' : isNormalLineHeight, 'base-grey-text': isBaseGreyColor }"
        [innerHtml]="historyLogDisplay"
        nvTooltip [tooltipData]="bodyTooltip">
    </div>
    <div *ngIf="displayCatsInBody" class="category-tags-wrapper">
        <nv-pill *ngFor="let categoryTag of categoryTagsArray" class="category-tag" color="blue" isInverted="true">
            {{ categoryTag }}
        </nv-pill>
    </div>
</div>