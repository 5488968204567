import { SnackBarService } from './../../../shared/services/snackbar/snackbar.service';
import { IListSummaryGrouping } from '../../../shared/components/list-summary/list-summary.component';
import { MatDialogRef } from '@angular/material/dialog';
import { SpinnerService } from '../../../shared/components/spinner/spinner-modal.service';
import { getBatchActionsMode } from '../../../store/selectors/batch-actions-selectors';
import { ActivatedRoute } from '@angular/router';
import { getSchool } from '../../../store/selectors/school-selectors';
import { unsubscribeComponent } from 'Src/ng2/shared/helpers/unsubscribe-decorator/unsubscribe-decorators.helper';
import {
  SortAndFilterService,
  TSortDirections,
  Direction,
} from '../../../shared/services/list-services/sort-and-filter.service';
import { Store, select } from '@ngrx/store';
import { IListConfig } from 'Src/ng2/shared/models/list-models';
import { IGroupData } from '../../../shared/models/list-models';
import {
  BackgroundJobNotificationService,
  TValidBackgroundJob,
} from '../../../shared/services/background-job-notification-service/background-job-notification-service';
import { IListData } from '../../../shell/content-tools/content-tools.component';
import { ToggleBatchActions, UpdateSelectedStudentIds } from '../../../store/actions/batch-actions-actions';
import { ListHeaderService } from '../services/list-headers/list-headers.service';
import { ListNavigationService } from '../services/list-navigation/list-navigation.service';

import {
  Component,
  ComponentFactoryResolver,
  ElementRef,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, Unsubscribable, of } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { FixedToInfiniteViewComponent } from 'Src/ng2/shared/components/list/fixed-to-infinite-view-container/fixed-to-infinite-view.component';
import { ObjectCache } from 'Src/ng2/shared/services/object-cache/object-cache.service';
import { ListSummaryGroupings } from '../../../shared/services/list-summary-groupings/list-summary-groupings.service';
import { BatchActionsService } from '../services/batch-actions/batch-actions.service';
import { MadlibService } from '../services/madlib/madlib.service';
import { SpinnerModalComponent } from 'Src/ng2/shared/components/spinner/spinner-modal.component';
import { FormativeAssessmentsDataService, IFocus } from './formative-assessments-list-data/formative-assessments-list-data.service';
import { SharedEventTrackers } from 'Src/ng2/shared/services/mixpanel/event-trackers/shared-tracking.service';
import { MISC_PAGE_ORIGINS } from 'Src/ng2/shared/services/mixpanel/event-interfaces/misc-page';
import { PORTAL_TYPES } from 'Src/ng2/shared/typings/interfaces/portal.interface';

@Component({
  selector: 'formative-assessments-list-v2',
  templateUrl: './formative-assessments-list-v2.component.html',
  styleUrls: ['./formative-assessments-list-v2.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
@unsubscribeComponent
// @ts-ignore
export class FormativeAssessmentsListContainer extends FixedToInfiniteViewComponent implements OnInit {
  public schoolId: string;
  public batchActionsMode$: Observable<any>;
  public isRefactoredList: boolean = true;
  public fociData$: Observable<any>;
  public madlibModel: FormGroup;
  public dataSource$: Observable<any>;
  public foci: IFocus[];
  public fociByCategory: Array<{ categoryLabel: string; foci: IFocus[] }>;
  public activeList: 'INFINITE' | 'FIXED';
  public batchActionsHash;

  public uiRowData;
  public visualGroupingData: IGroupData;
  public onUiRowDataUpdate: Function;

  // The background jobs does this view need to refetch on?
  public backgroundJobDependencies: TValidBackgroundJob[] = [
    'BulkStudentUpdate',
    'BulkStudentSupportCreate',
    'BulkStudentSupportUpdate',
    'BulkStudentNoteCreate',
  ];

  // MetaData
  public listData: IListData;

  public pageHeaderMeta = {
    title: 'Formative Assessments',
    subTitle: null,
    icon: {
      tooltip: null,
    },
  };

  public listSummaryGroupData: IListSummaryGrouping[];

  public filterFormControl: FormControl;
  public sortKey$: BehaviorSubject<string>;
  public sortDirection$: BehaviorSubject<TSortDirections>;
  public updateSort: Function;

  // SuperProps
  public onFixed: Function;
  public onInfinite: Function;
  public onBatchAction: Function;
  public onRowClick: Function;
  public onDynamicComponentClick: Function = () => null;
  public columns;
  public dynamicColumns = new BehaviorSubject(null);
  public dynamicColumnIndexMap = new BehaviorSubject(null);
  public columnIndexMap: { [columnKey: string]: number };
  public groupingData$ = new BehaviorSubject(null);
  public listConfig: IListConfig = {
    listType: 'STUDENT',
    noDataMessage: 'No students to display',
    sortableColumns: true,
  };

  private spinner: MatDialogRef<SpinnerModalComponent>

  public dynamicComponentInputData: any;

  // SubSinks
  public notificationServiceSub: Unsubscribable;
  public batchActionsModeSubscription: Unsubscribable;
  public filterSub: Unsubscribable;

  // Refs
  @ViewChild('entry', { read: ViewContainerRef, static: true }) entry: ViewContainerRef;
  @ViewChild('listContent', { static: true }) listContent: ElementRef;

  public noDataMessage: any;

  // DI
  constructor (
    public resolver: ComponentFactoryResolver,
    public listSummaryGroupings: ListSummaryGroupings,
    public madlibService: MadlibService,
    public objectCache: ObjectCache,
    public notificationService: BackgroundJobNotificationService,
    public formativeAssmntsDataService: FormativeAssessmentsDataService,
    private store: Store<any>,
    private batchActionsService: BatchActionsService,
    private navigationService: ListNavigationService,
    private listHeaderService: ListHeaderService,
    private route: ActivatedRoute,
    private spinnerService: SpinnerService,
    private snackBarService: SnackBarService,
    private sharedEventTrackers: SharedEventTrackers,
  ) {
    // We are extending the fixed-to-infinite functionality
    super(resolver, batchActionsService, store);
  }

  ngOnInit (): void {
    this.store
      .pipe(
        select(getSchool),
        take(1),
        tap(({ _id }) => (this.schoolId = _id)),
      )
      .subscribe();

    this.batchActionsMode$ = this.getBatchActionsMode$();
    // Clear the cache on initialization. User *may* have changed schools.
    this.formativeAssmntsDataService.clearFociCache();
    // Set initial state based on url params
    this.filterFormControl = new FormControl(this.route.snapshot.queryParams.search || null);
    this.sortKey$ = new BehaviorSubject(this.route.snapshot.queryParams.sortKey || 'STUDENT_NAME_WITH_LOGS');
    this.stubReplacement = 'STUDENT_NAME_WITH_LOGS';
    this.sortDirection$ = new BehaviorSubject<TSortDirections>(this.route.snapshot.queryParams.sortDirection || Direction.asc);
    // Super Props
    this.updateSort = (sortKey: string) => {
      SortAndFilterService.updateSortCol(sortKey, this.sortKey$, this.sortDirection$);
      this.navigationService.updateUrl(this.sortKey$, this.sortDirection$, this.filterFormControl, this.madlibModel);

      const studentIds = this.batchActionsHash.getStudentIds({ sortKey: this.sortKey$.value, sortDirection: this.sortDirection$.value });
      this.store.dispatch(new UpdateSelectedStudentIds(studentIds));
    };
    this.onBatchAction = this.formatAndFireBatchActionEvt;
    this.onRowClick = this.navigationService.goToStudentProfile.bind(this.navigationService, this.schoolId);
    this.onFixed = this.updateCssForListType('FIXED');
    this.onInfinite = this.updateCssForListType('INFINITE');
    this.onUiRowDataUpdate = ($event: IGroupData[]) => {
      const groupings = Object.values($event);
      this.uiRowData = groupings;
      this.updateContentToolsData(groupings);
    };
    // Update URL on filter change
    this.filterSub = this.filterFormControl.valueChanges
      .pipe(
        tap(() =>
          this.navigationService.updateUrl(
            this.sortKey$,
            this.sortDirection$,
            this.filterFormControl,
            this.madlibModel,
          ),
        ),
      )
      .subscribe();
    // Listen for completed background jobs and refetch data as needed
    this.notificationServiceSub = this.notificationService
      .getMessage()
      .pipe(
        filter(({ backgroundJob }) => {
          this.snackBarService.showBatchActionNoteSuccessToast(backgroundJob);
          return this.backgroundJobDependencies.includes(backgroundJob);
        }),
        tap(() => this.initializeFormativeAssessmentsList(this.schoolId, this.route.snapshot.queryParams, false)),
      )
      .subscribe();

    // Initially set batch actions to false
    this.store.dispatch(new ToggleBatchActions(false));

    // Initialize batchAction data structure with helper methods. Used to keep track of selected students
    this.batchActionsHash = this.batchActionsService.initializeStudentBatchActionsHash();

    // Reset when batchActions is closed. BatchActions will be closed initially, so resetBatchActions will be called on init.
    this.batchActionsModeSubscription = this.batchActionsMode$
      .pipe(tap((mode: boolean) => { if (!mode) this.resetBatchActions(); }))
      .subscribe();

    // Kick off data pipeline `getFoci$---generateMadlibModel---getGrouping$---createListContainer--|`
    this.initializeFormativeAssessmentsList(this.schoolId, this.route.snapshot.queryParams, true);
    this.trackMiscPageView();
  }

  // getFocusData---createMadlibModel---getGroupingData---createListContainer--|
  initializeFormativeAssessmentsList (schoolId: string, $stateParams, loadContainer: boolean): void {
    this.spinner = this.spinnerService.openSpinner({ message: 'Loading...' });
    // Get fociPartials to populate foci dropdown
    this.getFociData$({ contextPartnerId: schoolId })
      .pipe(
        // Format and set the partials to fit the nv-dropdown
        tap(fociPartials => (this.fociByCategory = fociPartials)),
        // Once we know the default focus, fetch complete data to populate corresponding filter and grouping options
        switchMap(fociPartials =>
          this.getFociData$({ contextPartnerId: schoolId, focusKey: this.madlibService.getListDefaultFocus(fociPartials, $stateParams) }),
        ),
        switchMap(([completeFocusData]) =>
          of(this.madlibService.setMadlibModel(completeFocusData, $stateParams)),
        ),
        // Set madlib model with focus containing all data
        tap(madlibModel => (this.madlibModel = madlibModel)),
        switchMap(madlibModel => this.getGroupingWithTooltips$(madlibModel)),
        tap(() =>
          this.navigationService.updateUrl(
            this.sortKey$,
            this.sortDirection$,
            this.filterFormControl,
            this.madlibModel,
          ),
        ),
        // Set side-bar summary/csv-export etc.
        tap(groupingData => {
          this.updateGroupingDependentProps(groupingData);
          if (loadContainer) this.createListContainer();
        }),
      )
      .subscribe({ complete: () => this.spinner.close() });
  }

  appendGroupTooltips (groupings, groupingData): Observable<IGroupData> {
    const groupingsWithTooltips = groupings.reduce((accum: Array<IGroupData>, group: IGroupData) => {
      const subgroupData = groupingData.groupingSubgroups.find(subgroup => subgroup.label === group.key);
      const appendedGroup = { ...group, ...{ tooltip: subgroupData ? subgroupData.headerTooltip : null } };
      accum.push(appendedGroup);
      return accum;
    }, []);
    return of(groupingsWithTooltips);
  }

  private getGroupingWithTooltips$ (madlibModel: FormGroup) {
    return this.formativeAssmntsDataService
      .getFormativeAssessmentsGroupingData$(this.madlibService.getGroupingDataPayload(this.schoolId, madlibModel.value) as any)
      .pipe(
        switchMap(({ data: { StudentGroupings } }) =>
          this.appendGroupTooltips(StudentGroupings, this.madlibModel.value.grouping),
        ),
      );
  }

  onMadlibChange (evt: { dimension: string; data: any }, schoolId: string, madlibModel: FormGroup): void {
    this.spinner = this.spinnerService.openSpinner({ message: 'Loading...' });
    const stream$ = evt.dimension === 'focus'
      ? this.madlibService.getListModelForFocusChange({ contextPartnerId: schoolId, madlibModel, evt, getFociData$: this.getFociData$.bind(this) })
      : this.madlibService.getModelForNonFocusChange(madlibModel, evt);
    stream$.pipe(
      tap(() =>
        this.navigationService.updateUrl(
          this.sortKey$,
          this.sortDirection$,
          this.filterFormControl,
          this.madlibModel,
        ),
      ),
      switchMap(madlibModel => this.getGroupingWithTooltips$(madlibModel)),
      tap(groupingData => this.updateGroupingDependentProps(groupingData)),
    )
      .subscribe({ complete: () => this.spinner.close() });
  }

  // This is far from optimal and should be addressed in a HTMl/CSS List cleanup
  updateCssForListType (listMode: 'INFINITE' | 'FIXED'): Function {
    return (instance?: ElementRef): void => {
      this.activeList = listMode;
      const addClassForListMode = listMode === 'INFINITE' ? 'infinite' : 'fixed';
      this.listContent.nativeElement.classList.add(addClassForListMode);
      const removeClassForListMode = listMode === 'INFINITE' ? 'fixed' : 'infinite';
      this.listContent.nativeElement.classList.remove(removeClassForListMode);
      this.pageHeaderMeta =
        listMode === 'INFINITE'
          ? { ...this.pageHeaderMeta, ...{ icon: this.listHeaderService.getInfiniteHeaderIcon(instance) } }
          : { ...this.pageHeaderMeta, ...{ icon: this.listHeaderService.getFixedHeaderIcon() } };
    };
  }

  getFociData$ ({ contextPartnerId, focusKey }: { contextPartnerId: string, focusKey?: string }): Observable<any> {
    return this.formativeAssmntsDataService
      .getFormativeAssessmentsFocusData$(contextPartnerId, focusKey)
      .pipe(switchMap(({ data: { FormativeAssessmentsFocus } }) => of(FormativeAssessmentsFocus)));
  }

  getBatchActionsMode$ (): Observable<boolean> {
    return this.store.pipe(select(getBatchActionsMode));
  }

  resetBatchActions (): void {
    this.batchActionsHash.reset();
    this.store.dispatch(new UpdateSelectedStudentIds([]));
    this.store.dispatch(new ToggleBatchActions(false));
  }

  updateContentToolsData (groupData: IGroupData[], visibleGroupings?: string[]): void {
    const groupings = visibleGroupings ? groupData.filter(group => visibleGroupings.includes(group.key)) : groupData;
    this.listData = this.listSummaryGroupings.getExportListData({
      groupings,
      columns: this.madlibService.getColumnDataBasedOnMadlibModel(this.madlibModel).columns,
      madlibModel: this.madlibModel,
      listType: 'Formative Assessments',
    });
    this.listSummaryGroupData = [this.listSummaryGroupings.getGroupings(this.madlibModel.value, groupings)];
  }

  updateGroupingDependentProps (groupingData): void {
    const { columns, columnIndexMap } = this.madlibService.getColumnDataBasedOnMadlibModel(this.madlibModel);
    this.dynamicColumns.next(columns);
    this.dynamicColumnIndexMap.next(columnIndexMap);
    this.groupingData$.next(groupingData);
    this.updateContentToolsData(groupingData);
  }

  private trackMiscPageView () {
    const pageName = 'Formative Assessments List';
    const origin = MISC_PAGE_ORIGINS.OTHER_TOOLS;
    const portal = PORTAL_TYPES.SCHOOL;
    this.sharedEventTrackers.trackMiscPageView({ pageName, origin, portal });
  }
}
