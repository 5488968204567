import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'assign-supports-step',
  templateUrl: './assign-supports-step.component.html',
  styleUrls: ['./assign-supports-step.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AssignSupportsStep {
  public stepLabel: string = 'Create additional Regents prep supports';
}
