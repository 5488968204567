import * as tileActions from '../../actions/tile-actions/tile-actions';

export const initialState = {
  loading: false,
  loaded: false,
  tileList: [],
};

export function reducer(state = initialState, action: tileActions.TileAction) {
  switch (action.type) {
    case tileActions.LOAD_TILE:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case tileActions.LOAD_TILE_SUCCESS:
      const tileList = action.payload;
      return {
        ...state,
        loading: false,
        loaded: true,
        tileList,
      };
    case tileActions.RESET_TILES:
      return {
        ...state,
        loading: false,
        loaded: false,
        tileList: state.tileList,
      };
    default:
      return state;
  }
}
