import {
  Component,
  ViewChild,
  ElementRef,
  Renderer2,
  ChangeDetectorRef,
  Input,
  OnChanges,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  SecurityContext,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'hybrid-cluster-user-alert',
  templateUrl: './hybrid-cluster-user-alert.component.html',
  styleUrls: ['./hybrid-cluster-user-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class HybridClusterUserAlertComponent implements OnChanges {
  @ViewChild('warningContent', { static: false }) warningContentEl: ElementRef;
  @Input() warningContent: string;

  constructor (
    private renderer: Renderer2,
    private cd: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
  ) {
    // ..
  }

  ngOnChanges (changes) {
    this.cd.detectChanges();
    if (this.warningContentEl) {
      // Sanitize content before assinging to innerHTML property
      const sanitizedContent = this.sanitizer.sanitize(SecurityContext.HTML, this.warningContent);
      this.renderer.setProperty(this.warningContentEl.nativeElement, 'innerHTML', sanitizedContent);
    }
  }
}
