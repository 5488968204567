import { Injectable } from '@angular/core';
import { getCurrentUser, getSchoolAssessmentsLoadedStatus, LoadSchoolAssessments, getSchoolAssessmentsEntitiesList, getUsersLoadedStatus, LoadUsers, getFullAccessUsers } from 'Src/ng2/store';
import { StoreDataService } from 'Src/ng2/shared/services/store-data-service/store-data.service';
import { Observable } from 'rxjs';
import { ISchoolAssessment } from 'Src/ng2/shared/typings/interfaces/school-assessment.interface';

@Injectable()
export class SettingsPageData {
  constructor (
    private storeDataService: StoreDataService,
  ) { }

  getCurrentUser$ () {
    return this.storeDataService.getDataFromStore$(getCurrentUser);
  }

  getSchoolAssessments$ (payload: {schoolId: string, schoolYear: string}): Observable<Array<ISchoolAssessment>> {
    const statusSelection = getSchoolAssessmentsLoadedStatus;
    const loadAction = LoadSchoolAssessments;
    const itemSelection = getSchoolAssessmentsEntitiesList;
    return this.storeDataService.loadDataToStore$(payload, statusSelection, loadAction, itemSelection);
  }

  getSchoolUsers$ (payload: {schoolId: string }) {
    const { schoolId } = payload;
    return this.storeDataService.loadDataAndGetStream$({ schoolId }, getUsersLoadedStatus, LoadUsers, getFullAccessUsers);
  }
}
