import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvIconModule } from '../../../../../projects/shared/nvps-libraries/design/nv-icon/nv-icon.module';
import { NvSharedTooltipModule } from '../../../../../projects/shared/nvps-libraries/design/nv-tooltip/nv-shared-tooltip.module';
import { NvSidebarListHeaderComponent } from './nv-sidebar-list-header.component';

@NgModule({
  declarations: [NvSidebarListHeaderComponent],
  imports: [CommonModule, NvIconModule, NvSharedTooltipModule],
  exports: [NvSidebarListHeaderComponent],
})
export class NvSidebarListHeaderModule {}
