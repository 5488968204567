export enum DataTypes {
  WEEKLY_ATTENDANCE_RATE = 'attendance_progress_monitoring__ada',
  YEAR_TO_DATE = 'attendance_progress_monitoring__ytd',
  CHRONIC_ABSENTEEISM = 'attendance_progress_monitoring__ca',
}

export enum VizHeader {
  WEEKLY_ATTENDANCE_RATE = 'Attendance',
  YEAR_TO_DATE = 'Year to Date Attendance',
  CHRONIC_ABSENTEEISM = 'Chronic Absenteeism',
}

export enum DataInputType {
  FOCUS = 'FOCUS',
  TILE = 'TILE',
}

export type AttendanceTrendsFocusOptions = {
  timePeriodKey: 'attd_time_period';
  timePeriodValue: string;
  timePeriodHuman: string;
  optionsOrder: string;
  isDefault: '0' | '1';
}

// Tile data
export type TileData = {
    timePeriodKey: string;
    timePeriodValue: string;
    breakdownCalcKey: 'attendance_breakdown';
    category: 'attendance';
    focusKey: string;
    isDefault: '0' | '1';
    optionsOrder: string;
    tileCalcKey: 'attendance_change';
    tileChangeLabel: string;
    tileFormat: 'PERCENT';
    tileLabel: string;
    tileTooltip: string;
    vizCalcKey: 'attendance_viz';
    vizHeader: string;
    // `current_period_vars` is a JSON string
    currentPeriodVars: string;
    dbnOrIdLabel: string;
  }

export type TileCalcs = {
  description: string;
  data_point: string;
  value: number;
  calc_final: number;
}

// Viz Data
export type AttendanceTrendsVizData = {
  description: string;
  rnk: number;
  subset: string;
  value: number;
}

export const staticMonths = [
  'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
];

// Table data
export type AttendanceTrendsBreakdownTableData = {
  dbn: string;
  calcAfter: number;
  calcFinal: number;
}

export type AttendanceTrendsTableHeaderAndTooltips = {
  columnLabel: string;
  columnOrder: number;
  columnTooltip: string;
  cellFormat: 'STRING' | 'PERCENT';
}

export type AttendanceTrendsTableData = {
  dbn: string;
  dataPoint: string;
  calcBefore: number;
  calcAfter: number;
  calcFinal: number;
  schoolName: string;
}
