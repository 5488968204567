import { filter, reduce } from 'lodash';
import { ISchoolAssessment } from '../../../shared/typings/interfaces/school-assessment.interface';
import * as schoolAssessmentsActions from './../../actions/assessments-actions/school-assessments-actions';

export interface ISchoolAssessmentsState {
  loading: boolean;
  loaded: boolean;
  schoolAssessmentsEntities: {
    [id: string]: ISchoolAssessment;
  };
}

export const initialState: ISchoolAssessmentsState = {
  loading: false,
  loaded: false,
  schoolAssessmentsEntities: {},
};

export function reducer(
  state: ISchoolAssessmentsState = initialState,
  action: schoolAssessmentsActions.SchoolAssessmentsAction,
): ISchoolAssessmentsState {
  let schoolAssessment;
  let schoolAssessmentsEntities;
  switch (action.type) {
    case schoolAssessmentsActions.LOAD_SCHOOL_ASSESSMENTS:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case schoolAssessmentsActions.LOAD_SCHOOL_ASSESSMENTS_SUCCESS:
      const schoolAssessments = action.payload.data.SchoolAssessments;
      const filteredSchoolAssessments = filter(schoolAssessments, asmnt => asmnt.status !== 'DELETED');
      const loadedSchoolAssessments: ISchoolAssessmentsState['schoolAssessmentsEntities'] = reduce(
        filteredSchoolAssessments,
        (acc, schoolAssessment: ISchoolAssessment) => {
          acc[schoolAssessment._id] = schoolAssessment;

          return acc;
        },
        {},
      );
      const mergedSchoolAssessments = { ...state.schoolAssessmentsEntities, ...loadedSchoolAssessments };
      return {
        ...state,
        loading: false,
        loaded: true,
        schoolAssessmentsEntities: mergedSchoolAssessments,
      };
    case schoolAssessmentsActions.LOAD_SCHOOL_ASSESSMENTS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case schoolAssessmentsActions.BULK_UPDATE_SCHOOL_ASSESSMENTS_SUCCESS:
      const update = action.payload.updateSchoolAssessment[0];
      schoolAssessmentsEntities = Object.assign({}, state.schoolAssessmentsEntities);
      const { itemId, patch } = update;
      if (schoolAssessmentsEntities[itemId]) {
        schoolAssessment = Object.assign(schoolAssessmentsEntities[itemId], patch);
        schoolAssessmentsEntities[itemId] = schoolAssessment;
        // remove schoolAssessmentEntity if patched to status 'DELETED'
        if (patch.status === 'DELETED') delete schoolAssessmentsEntities[itemId];
      }
      return {
        ...state,
        schoolAssessmentsEntities,
      };
    case schoolAssessmentsActions.BULK_CREATE_SCHOOL_ASSESSMENTS_SUCCESS:
      schoolAssessment = action.payload.createSchoolAssessment;
      schoolAssessmentsEntities = Object.assign({}, state.schoolAssessmentsEntities);
      schoolAssessmentsEntities[schoolAssessment._id] = schoolAssessment;

      return {
        ...state,
        schoolAssessmentsEntities,
      };
    default:
      return state;
  }
}
