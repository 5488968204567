import { createFeatureSelector, createSelector } from '@ngrx/store';
import { map } from 'lodash';
import { IFlagsState } from './../reducers/flags-reducer';

const getPortalState = createFeatureSelector('PORTAL_STORE');

export const getFlagsState = createSelector(
  getPortalState,
  (state: any) => {
    return state.flags;
  },
);

export const getFlagEntities = createSelector(
  getFlagsState,
  (state: IFlagsState) => {
    return state.flagEntities;
  },
);

export const getFlagEntitiesList = createSelector(
  getFlagsState,
  (state: IFlagsState) => {
    const flagEntities = state.flagEntities;
    return map(flagEntities, (v, k) => v);
  },
);

export const getFlagsLoadingStatus = createSelector(
  getFlagsState,
  (state: IFlagsState) => {
    return state.loading;
  },
);

export const getFlagsLoadedStatus = createSelector(
  getFlagsState,
  (state: IFlagsState) => {
    return state.loaded;
  },
);
