import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { districtsConfig } from 'Src/ng2/shared/constants/districts-config.constant';
import { SessionStorageService } from 'Src/ng2/shared/services/web-storage/session-storage/session-storage.service';

@Injectable()
export class NoDistrictRouteGuard implements CanActivate {
  constructor (
    private sessionStorageService: SessionStorageService,
  ) {}

  canActivate (): Observable<UrlTree|boolean> {
    this.sessionStorageService.setItem('currentDistrict', districtsConfig.NYC_DISTRICT);
    return of(true);
  }
}
