import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

// services
import { WizardHelpers } from 'Src/ng2/school/sdc/services/wizard-helpers.service';
import { CsvExporterService } from 'Src/ng2/shared/services/csv-exporter/csv-exporter.service';
import { SpinnerService } from './../../../../../../../shared/components/spinner/spinner-modal.service';
import { ModalsService } from 'Src/ng2/shared/modals/modals.service';

// components
import { DoFinalCheckStep } from './do-final-check.component';
import { ImSchool } from 'Src/ng2/shared/services/im-models/im-school';
import { WizardFilterActionsModule } from 'Src/ng2/school/sdc/wizard-filter-actions/wizard-filter-actions.module';

@NgModule({
  imports: [CommonModule, MatButtonModule, WizardFilterActionsModule],
  exports: [DoFinalCheckStep],
  declarations: [DoFinalCheckStep],
  providers: [WizardHelpers, CsvExporterService, SpinnerService, ImSchool, ModalsService],
})
export class DoFinalCheckStepModule { }
