import { NvBasicInfoTileModule } from '../nv-category-container-items/nv-basic-info-tile/nv-basic-info-tile.module';
import { NvCategoryContainerComponent } from './nv-category-container.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvActionTileModule } from '../nv-category-container-items/nv-action-tile/nv-action-tile.module';
import { NvSecondaryFocusTileModule } from '../nv-category-container-items/nv-secondary-focus-tile/nv-secondary-focus-tile.module';
import { NvPrimaryFocusTileModule } from '../nv-category-container-items/nv-primary-focus-tile/nv-primary-focus-tile.module';
import { NvVizTileModule } from '../nv-category-container-items/nv-viz-tile/nv-viz-tile.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NvSharedTooltipModule } from '../../../../projects/shared/nvps-libraries/design/nv-tooltip/nv-shared-tooltip.module';
import { NvIconModule } from '../../../../projects/shared/nvps-libraries/design/nv-icon/nv-icon.module';
import { NvNoteSummerModule } from '../nv-note-summer/nv-note-summer.module';

@NgModule({
  imports: [
    CommonModule,
    NvActionTileModule,
    NvSecondaryFocusTileModule,
    NvPrimaryFocusTileModule,
    NvBasicInfoTileModule,
    NvVizTileModule,
    NgxSkeletonLoaderModule,
    NvSharedTooltipModule,
    NvIconModule,
    NvNoteSummerModule,
  ],
  declarations: [NvCategoryContainerComponent],
  exports: [NvCategoryContainerComponent],
})
export class NvCategoryContainerModule {}
