import { CommonModule } from '@angular/common';
import { ExamLoadStep } from './exam-load-step.component';
import { NgModule } from '@angular/core';
import { WizardFilterActionsModule } from 'Src/ng2/school/sdc/wizard-filter-actions/wizard-filter-actions.module';

@NgModule({
  imports: [
    CommonModule,
    WizardFilterActionsModule,
  ],
  declarations: [ExamLoadStep],
  exports: [ExamLoadStep],
  providers: [],
})
export class ExamLoadStepModule { }
