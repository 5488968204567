import { RegentsPrepPriorityGroupingsTransferService } from 'Src/ng2/school/sdc/services/regents-prep-priority-groupings/regents-prep-priority-groupings-transfer.service';
import { CurrentSchoolYear } from 'Src/ng2/shared/constants/current-school-year.constant';
import { ObjectCache } from 'Src/ng2/shared/services/object-cache/object-cache.service';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { reduce, each } from 'lodash';
import { UrlPathService } from 'Src/ng2/shared/services/url-path-service/url-path.service';

@Component({
  selector: 'transfer-student-review-step',
  templateUrl: './student-review-step.component.html',
  styleUrls: ['../../shared-steps/shared-step-styles.scss', './student-review-step.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TransferStudentReviewStep implements OnInit {
  @Input() filteredStudents;
  public stepLabel: string = 'Assign students to Regents prep supports';
  public organizedPriorityData;
  public totalStudents;
  public totalExams;
  public graduatingCohort;
  public options;

  constructor (
    private objectCache: ObjectCache,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private regentsPrepPriorityGroupingsTransferService: RegentsPrepPriorityGroupingsTransferService,
    private urlPathService: UrlPathService,
  ) { }

  ngOnInit () {
    this.organizedPriorityData = this.organizeStudentsByPriority(this.filteredStudents);
    this.totalStudents = this.getTotalStudents(this.organizedPriorityData);
    this.totalExams = this.getTotalExams(this.organizedPriorityData);
    this.graduatingCohort = Number(CurrentSchoolYear.ENDFULL);
    this.options = {
      columnKeys: ['cohort', 'gradPlan', 'regentsSchedNextAdminWithNoPrep'],
    };
  }

  ngOnChanges ({ filteredStudents }) {
    if (filteredStudents && !filteredStudents.isFirstChange()) {
      this.ngOnInit();
    }
  }

  goToStudentProfile (studentIds) {
    const filter = this.objectCache.cacheObject({ studentId: studentIds });
    const url = this.urlPathService.computeDistrictUrlPath(`/school/${this.activatedRoute.snapshot.params.schoolId}/student`);
    this.router.navigate([url], { queryParams: { filter }, state: { sdcKey: this.activatedRoute.snapshot.data.sdcKey } });
  };

  private getTotalStudents (priorityGroups) {
    return reduce(
      priorityGroups,
      (result, priorityGroup: any) => {
        result = result.concat(priorityGroup.studentIds);
        return result;
      },
      [],
    );
  };

  private getTotalExams (priorityGroups) {
    return reduce(
      priorityGroups,
      (result, priorityGroup: any) => {
        result += priorityGroup.examCount;
        return result;
      },
      0,
    );
  };

  private organizeStudentsByPriority = students => {
    const regentsPrepPriorityGroups = this.regentsPrepPriorityGroupingsTransferService.getPriorityGroupsByPlannedGradDateFilter().map(
      x => x.studentPriorityGroupingValue,
    );

    const priorityGroups = reduce(
      regentsPrepPriorityGroups,
      (priorityGroups: any, _, i:number) => {
        if (i >= 7) return priorityGroups;
        priorityGroups[++i] = { studentIds: [], examCount: 0, text: regentsPrepPriorityGroups[--i] };
        return priorityGroups;
      },
      {},
    );

    each(students, (student: any) => {
      const { studentId, regentsPrepPriorityGrouping, countOfRegentsSchedNextAdminWithNoPrep } = student;
      if (regentsPrepPriorityGrouping === regentsPrepPriorityGroups[0]) {
        priorityGroups[1].studentIds.push(studentId);
        priorityGroups[1].examCount += countOfRegentsSchedNextAdminWithNoPrep;
      } else if (regentsPrepPriorityGrouping === regentsPrepPriorityGroups[1]) {
        priorityGroups[2].studentIds.push(studentId);
        priorityGroups[2].examCount += countOfRegentsSchedNextAdminWithNoPrep;
      } else if (regentsPrepPriorityGrouping === regentsPrepPriorityGroups[2]) {
        priorityGroups[3].studentIds.push(studentId);
        priorityGroups[3].examCount += countOfRegentsSchedNextAdminWithNoPrep;
      } else if (regentsPrepPriorityGrouping === regentsPrepPriorityGroups[3]) {
        priorityGroups[4].studentIds.push(studentId);
        priorityGroups[4].examCount += countOfRegentsSchedNextAdminWithNoPrep;
      } else if (regentsPrepPriorityGrouping === regentsPrepPriorityGroups[4]) {
        priorityGroups[5].studentIds.push(studentId);
        priorityGroups[5].examCount += countOfRegentsSchedNextAdminWithNoPrep;
      } else if (regentsPrepPriorityGrouping === regentsPrepPriorityGroups[5]) {
        priorityGroups[6].studentIds.push(studentId);
        priorityGroups[6].examCount += countOfRegentsSchedNextAdminWithNoPrep;
      } else if (regentsPrepPriorityGrouping === regentsPrepPriorityGroups[6]) {
        priorityGroups[7].studentIds.push(studentId);
        priorityGroups[7].examCount += countOfRegentsSchedNextAdminWithNoPrep;
      }
    });
    return priorityGroups;
  };
}
