<p class="mat-body-2">
  Your school's On-Track Credit metric should reflect the number of credits that you expect students to earn by the
  end of each term in high school. A credit gap is the difference between what you expect and the number a student
  is programmed to earn by the end of the term.
</p>

<p class="mat-body-2 rachel">
  For example, Rachel is a rising senior who has earned 5 core ELA credits and is scheduled for her 6th in the fall.
  Your On-Track Credit metric indicates that seniors should have 7 core ELA credits by the end of the fall term in
  their senior year. Based on that metric, Rachel will be short 1 credit from being considered “on-track” in ELA.
  This means that she will need to be programmed for an additional core ELA credit in either the fall or a future
  term. Until that plan has been entered, Rachel will be counted as a student with a credit gap in ELA.
</p>

<div>
  <button class="step-action-button" mat-raised-button (click)="navigateToCreditSettings()">
    Review Metric
  </button>
</div>