import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { IHistoryModalData, THistoryLogsComponent } from '../../../modals/history-all/history-all-modal.component';
import { HistoryAllHelperService, IHistoryRecord } from 'Src/ng2/shared/modals/history-all/history-all-helpers.service';
import { IDocLog, TDocLogCategory } from 'Src/ng2/shared/typings/interfaces/docLogs.interface';
import { TasksNotesPanelCollections } from '../../tasks-notes-activity-table/tasks-notes-activity-table.component';

@Component({
  selector: 'category-tags-renderer',
  templateUrl: './category-tags-renderer.component.html',
  styleUrls: ['./category-tags-renderer.component.scss'],
})

export class CategoryTagsComponent implements ICellRendererAngularComp {
  protected params: ICellRendererParams;
  protected data: IHistoryModalData;
  public docLog: IDocLog;
  public historyLogDisplay: any;
  public categoryTagsArray: string[] = [];
  public displayCatsInBody: boolean;
  public isNormalLineHeight: boolean = false;
  public isBaseGreyColor: boolean = false;
  public bodyTooltip: string;

  constructor (
    private historyAllHelperService: HistoryAllHelperService,
  ) {}

  public agInit (params: ICellRendererParams): void {
    this.params = params;
    this.docLog = params.node.data;
    this.setLogDisplay(this.docLog);
    this.displayCatsInBody = this.params.colDef?.cellRendererParams?.displayCatsInBody;
    if (this.historyLogDisplay) this.setCategoryTags(this.docLog?.tags);
  }

  public refresh (params: ICellRendererParams): boolean {
    return false;
  }

  public setLogDisplay (docLog: any): void {
    if (!docLog) return;
    let historyRecord: IHistoryRecord;
    const listType: THistoryLogsComponent = this.params.colDef?.cellRendererParams.listType;
    if (listType === 'STUDENT' || listType === 'SCHOOL_DATA_GRID' || listType === 'SCHOOL_LIST' || listType === 'SHELTER_STUDENT' || listType === 'SHELTER_ATT_LIST' || listType === 'SHELTER_DATA_GRID') {
      this._setLogsDisplayForStudentPanel(docLog, listType);
    } else {
      historyRecord = this.historyAllHelperService.constructHistoryRecord(docLog);
      this.historyLogDisplay = historyRecord?.modal ? historyRecord.modal : historyRecord?.expanded;
    }
  }

  public setCategoryTags (tagArray: TDocLogCategory[] = []): void {
    if (!tagArray.length) return;
    const filteredTags: string[] = tagArray.filter((category) => category !== 'Notes')
      .sort();
    if (filteredTags.length) {
      const namedTags = filteredTags.slice(0, 3);
      if (filteredTags.length > 3) {
        const numUnnamed = filteredTags.length - 3;
        namedTags.push(`+${numUnnamed}`);
      }
      this.categoryTagsArray = namedTags;
    }
  }

  private _setLogsDisplayForStudentPanel (docLog: any, listType: THistoryLogsComponent): void {
    // Student Profile Panel: this column displays the text, truncated to enforce a standard row height
    this.isNormalLineHeight = true;
    const STUDENT_PANEL_CELL = 150;
    const STUDENT_GRID_MODAL_CELL = 55;
    const substringLength = (listType === 'SHELTER_STUDENT' || listType === 'STUDENT') ? STUDENT_PANEL_CELL : STUDENT_GRID_MODAL_CELL; // Calculate the substring length for different cell's width
    const collection: string = this.params.colDef?.cellRendererParams.collection;
    if (collection === TasksNotesPanelCollections.NOTES) {
      const truncatedNotebody = this._truncateTextNote(docLog?.body, substringLength);
      this.historyLogDisplay = `<span class="history-value-note">${truncatedNotebody}</span>`;
    } else if (collection === TasksNotesPanelCollections.TASKS) {
      this.isBaseGreyColor = docLog?.status !== 'ACTIVE'; // Overwrite default log text color
      const truncatedDescription = this._truncateTextNote(docLog?.description, substringLength);
      this.historyLogDisplay = `<span class="history-value-note">${truncatedDescription}</span>`;
    } else {
      const historyRecord = this.historyAllHelperService.constructHistoryRecord(docLog);
      this.historyLogDisplay = historyRecord?.expanded;
    }
  }

  private _truncateTextNote (value: string, truncateLimit: number): string {
    if (!value?.length) return '';
    if (value?.length > truncateLimit) {
      this.bodyTooltip = value;
      return value.substring(0, truncateLimit + 10) + '...';
    } else {
      return value;
    }
  }
}
