<div class="button-container">
  <nv-button type="secondary" (clickButton)="onBack()">Back</nv-button>
  <nv-button (clickButton)="onNext()">{{nextButtonText}}</nv-button>
</div>
<nv-form-field label="Automatic updates">
  <mat-radio-group class="nv-mat-radio-group" (change)="onFrequencySelected($event)" [value]="form.controls.frequency.value">
    <mat-radio-button value="Daily">Daily</mat-radio-button>
    <div>
      <mat-radio-button value="Weekly">Weekly</mat-radio-button>
      <ng-container *ngIf="form.controls.frequency.value === 'Weekly'; then enabledDropdown; else disabledDropdown">
      </ng-container>
      <ng-template #enabledDropdown>
        <nv-dropdown
          [options]="weekDayAvailableOptions"
          [selected]="form.controls.weekDay.value"
          (selectOption)="onWeekDaySelected($event)"
        >
        </nv-dropdown>
      </ng-template>
      <ng-template #disabledDropdown>
        <nv-pill-button
          [text]="weekDayAvailableOptions[form.controls.weekDay.value].human"
          isDisabled="true"
        ></nv-pill-button>
      </ng-template>
    </div>
    <mat-radio-button value="No automatic updates">No automatic updates</mat-radio-button>
  </mat-radio-group>
</nv-form-field>