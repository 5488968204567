import { createFeatureSelector, createSelector } from '@ngrx/store';

const getPortalState = createFeatureSelector('PORTAL_STORE');

export const getShelterSlice = createSelector(
  getPortalState,
  (state: any) => state.shelter,
);

export const getShelterId = createSelector(
  getShelterSlice,
  (state: any) => state.shelterId,
);
