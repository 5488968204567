/* tslint:disable */

import { Action } from '@ngrx/store';
export const LOAD_DOC_LOGS = '[Portal] Load DocLogs';
export const LOAD_DOC_LOGS_SUCCESS = '[Portal] Load DocLogs Success';
export const LOAD_DOC_LOGS_FAIL = '[Portal] Load DocLogs Failure';

export interface ILoadDocLogsPayload {
  studentId: string | string[];
  schoolId: string;
}

// register store actions
export class LoadDocLogs implements Action {
  readonly type = LOAD_DOC_LOGS;
  constructor (public payload: ILoadDocLogsPayload) {}
}

export class LoadDocLogsSuccess implements Action {
  readonly type = LOAD_DOC_LOGS_SUCCESS;
  constructor (public payload) {}
}

export class LoadDocLogsFail implements Action {
  readonly type = LOAD_DOC_LOGS_FAIL;
  constructor (public payload: any) {}
}

// action types
export type DocLogsAction = LoadDocLogs | LoadDocLogsSuccess | LoadDocLogsFail;
