import { DataMaskingService } from './../data-masking/data-masking.service';
import { getSchool } from './../../../store/selectors/school-selectors';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { PortalConfig } from '../portal-config';
import { IUser } from '../../../shared/typings/interfaces/user.interface';
import { getCurrentUser } from './../../../store/selectors/current-user-selectors';
import { StoreDataService } from './../../../shared/services/store-data-service/store-data.service';
import * as Mixpanel from 'mixpanel-browser';
import { take } from 'rxjs/operators';
import { SessionStorageService } from '../web-storage/session-storage/session-storage.service';
import { TEventName } from '../../typings/interfaces/mixpanel.interface';
import { ISchool } from '../../typings/interfaces/school.interface';
import { combineLatest } from 'rxjs';

const ignoreDefaultPropertyList = [
  '$current_url',
  '$initial_referrer',
  '$initial_referring_domain',
  '$referring_domain',
  '$referrer',
  '$os',
];

type userProps = 'Last Login' | 'District' | 'School Type' | 'School Name' | 'DBN' | 'Cluster';
// eslint-disable-next-line no-unused-vars
type userPropsMap = { [key in userProps]?: string | number };

export const MixpanelClient = new InjectionToken<Mixpanel>('mixpanelClient', { factory: () => Mixpanel });

export type TMixpanelEvent<TMetadata> = { event: TEventName; metaData: TMetadata };

@Injectable()
export class MixpanelService {
  private district;
  private inDevMode: boolean;
  private userPropsMap: Map<userProps, string | number> = new Map();

  constructor (
    private storeDataService: StoreDataService,
    private portalConfig: PortalConfig,
    private sessionStorageService: SessionStorageService,
    private dataMaskingService: DataMaskingService,
     @Inject(MixpanelClient) private mixpanelClient: Mixpanel,
  ) {
    this.inDevMode = this.portalConfig.publicConfig.DEV_MODE;

    if (!this.inDevMode) {
      this.mixpanelClient.init(this.portalConfig.publicConfig.MIXPANEL_PROJECT_TOKEN, {
        property_blacklist: ignoreDefaultPropertyList,
        ip: false,
      });
    }
  }

  trackEvents <T> (events: TMixpanelEvent<T>[]): void {
    const maskedEvents = this.dataMaskingService.maskData(events) as any;

    if (!this.district) { // set district first time
      this.district = this.sessionStorageService.getItem('currentDistrict')?.toUpperCase();
    }

    if (!this.inDevMode) {
      combineLatest([this.getCurrentUser$(), this.getCurrentSchool$()]).subscribe(([user, school]) => {
        const { userAnalyticsHash, nvRole, authenticationEmail } = user;
        const newProps = {
          district: school.district,
          'School Type': school.schoolType,
          'School Name': school.fullName,
          DBN: school._id,
          Cluster: nvRole.clusterId,
          $email: authenticationEmail, // $email = reserved Mixpanel email field
        };
        if (userAnalyticsHash && this.shouldUpdateUser(newProps)) {
          // Update mixpanel user
          this.updateUser(userAnalyticsHash, newProps);
        }

        // Events
        maskedEvents.forEach(
          ({ event, metaData }) => {
            this.mixpanelClient.track(event, {
              analyticLevel: 'Client',
              district: this.district,
              distinct_id: userAnalyticsHash,
              ...metaData,
            });
          },
        );
      });
    }
  }

  updateUser (userAnalyticsHash: string, newProps: userPropsMap): void {
    if (!this.inDevMode) {
      this.setUserPropsMap(newProps);
      this.mixpanelClient.identify(userAnalyticsHash);
      this.mixpanelClient.people.set(newProps);
    }
  }

  private getCurrentUser$ (): Observable<IUser> {
    return this.storeDataService.getDataFromStore$(getCurrentUser).pipe(
      take(1),
    );
  }

  private getCurrentSchool$ (): Observable<ISchool> {
    return this.storeDataService.getDataFromStore$(getSchool).pipe(
      take(1),
    );
  }

  private setUserPropsMap (userProps: userPropsMap): void {
    Object.entries(userProps).forEach(([key, val]: [userProps, string | number]) => {
      this.userPropsMap.set(key, val);
    });
  }

  private shouldUpdateUser (newProps: userPropsMap): boolean {
    if (this.userPropsMap.size === 0) return true;
    return !Object.entries(newProps).every(([key, val]: [userProps, string | number]) => {
      return this.userPropsMap.get(key) === val;
    });
  }
}
