import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';

import { NvDesignLibraryModule } from '../../../../../nvps-libraries/design/nv-design-library.module';
import { BaseModalModule } from '../../base-modal.module';
import { NoteDetailsModalComponent } from './note-details-modal.component';

@NgModule({
  declarations: [NoteDetailsModalComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    BaseModalModule,
    NvDesignLibraryModule,
  ],
  exports: [NoteDetailsModalComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class NoteDetailsModalModule {}
