<base-modal class="base-modal" [title]="title" [isProfileMode]="isProfileMode">
  <nv-form-field label="EOP financial eligibility">
    <nv-dropdown
      [options]="eopFinancialEligibilityOptions"
      [selected]="selectedEop"
      (selectOption)="onEopOptionChange($event)"
    >
    </nv-dropdown>
  </nv-form-field>
  <financial-aid-modal-dropdown
    #fafsaDropdown
    label="FAFSA status"
    [tooltip]="fafsaLabelTooltip"
    [displayStatus]="truncatedFafsaStatus"
    [realStatus]="realTruncatedFafsaStatus"
    [currentUser]="currentUser"
  ></financial-aid-modal-dropdown>
  
  <financial-aid-modal-dropdown
    #tapDropdown
    label="TAP status"
    [tooltip]="tapLabelTooltip"
    [displayStatus]="truncatedTapStatus"
    [realStatus]="realTruncatedTapStatus"
    [currentUser]="currentUser"
  ></financial-aid-modal-dropdown>

  <mat-dialog-actions class="dialog-actions">
    <nv-button type="secondary" (clickButton)="cancel()">
      Cancel
    </nv-button>
    <nv-button htmlType="submit" (clickButton)="save(fafsaDropdown.getPatchData, tapDropdown.getPatchData)">
      Save
    </nv-button>
</mat-dialog-actions>
</base-modal>
