export enum SettingsRoute {
  Metrics = 'Metrics',
  MasterProgram = 'Master Program',
  Supports = 'Supports',
  UserManagement = 'User Management',
  Assessments = 'Assessments',
  DistanceToGraduation = 'Distance to Graduation'
}

export enum MetricsRoute {
  Credits = 'Credits',
  GradPlanning = 'Grad Planning',
}

export const SettingsRoutesRelativeUrls = {
  Supports: 'supports',
  'Master Program': 'master-program',
  Assessments: 'assessments',
  'User Management': 'user-management',
  Metrics: 'metrics/credits',
};
