import { identity } from 'lodash';
import { getToggleLoadedStatus, getToggleEntities } from '../../../store/selectors/toggles-selectors';
import { LoadToggles } from '../../../store/actions/toggle-actions';
import { tap, switchMap, filter, take, map } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable()
export class MaintenanceModeResolver implements Resolve<any> {
  constructor (private store: Store<any>) {}

  resolve (route: ActivatedRouteSnapshot):Observable<boolean> {
    const { maintenanceToggles = [] } = route.data;
    if (!maintenanceToggles.length) return of(false);
    return this.getTogglesFromStoreOrAPI().pipe(
      map(toggles => maintenanceToggles.some((toggle: string) => toggles[toggle])),
    );
  }

  getTogglesFromStoreOrAPI (): Observable<any> {
    return this.store.pipe(
      select(getToggleLoadedStatus),
      tap(loaded => {
        if (!loaded) this.store.dispatch(new LoadToggles({ }));
      }),
      filter(identity),
      switchMap(() => this.store.pipe(select(getToggleEntities))),
      take(1),
    );
  }
}
