import { getSchool } from '../../../../store/selectors/school-selectors';
import { map as rxMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UrlPathService } from 'Src/ng2/shared/services/url-path-service/url-path.service';

@Injectable()
export class SdcActivityGuardForProfile implements CanActivate {
  constructor (
    private store: Store<any>,
    private router: Router,
    private urlPathService: UrlPathService,
  ) { }

  canActivate (snapshot: ActivatedRouteSnapshot): Observable<UrlTree | boolean> {
    const { filter } = snapshot.queryParams;
    return this.store.select(getSchool).pipe(
      rxMap(({ _id }) => {
        const url = this.urlPathService.computeDistrictUrlPath(`/school/${_id}/student`);
        return this.router.createUrlTree([url], { queryParams: { filter } });
      }),
    );
  }

  canActivateChild (snapshot: ActivatedRouteSnapshot): Observable<UrlTree | boolean> {
    return this.canActivate(snapshot);
  }
}
