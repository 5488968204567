import { Injectable } from '@angular/core';
import { reduce } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IListViewData } from '../../../../typings/interfaces/list-view.interface';
import { COLUMN_DATA_TYPE } from './../../../../constants/list-view/cell-type.constant';

interface ITeacherGroupingOptions {
  columns: Array<object>;
  listType: string;
  navDataType: string;
  navRoute: string;
  navData: { index: number; key: string };
}

interface ITeacherGroupingStudent {
  studentId: string;
  currProgram: {
    grades: Array<{
      mostRecent: boolean;
      pf: 'P' | 'F';
      teacher: { nickName: string };
    }>;
  };
}

// takes students and an options object
@Injectable()
export class TeacherRollupGrouping {
  public getGrouping(filteredStudents$: Observable<any>, options: ITeacherGroupingOptions): Observable<any> {
    const { columns } = options;
    return filteredStudents$.pipe(
      map(students => {
        const teacherData = this.getFailingStudentsByTeacher(students);
        // empty list
        const formattedList: IListViewData = this.getFormattedListShell(options);
        const groupingData: object = { students, teacherData, columns, formattedList };
        // populated list
        const grouping: IListViewData = this.generateGrouping(groupingData);
        return grouping;
      }),
    );
  }

  private getFormattedListShell(args) {
    const { columns, listType, navDataType, navRoute, navData } = args;
    return {
      listType,
      columns: this.formatColumnsForList(columns),
      sections: [
        {
          name: 'Teacher',
          count: 0,
          data: [],
          defaultDisplayCount: 10,
          dataType: 'String',
          key: 'teacher',
        },
      ],
      navDataType,
      navData,
      navRoute,
    };
  }

  private formatColumnsForList(columns) {
    const formattedColumns = columns.reduce((formattedColumns, col) => {
      if (col.cellType !== COLUMN_DATA_TYPE.SECTION_HEADER) {
        formattedColumns[col.key] = {
          name: col.human,
          cellType: col.cellType,
          cellConfig: col.cellConfig,
          dataType: col.dataType,
          orderBy: col.orderBy,
        };
      }
      return formattedColumns;
    }, {});
    return formattedColumns;
  }

  private getFailingStudentsByTeacher(
    students: ITeacherGroupingStudent[],
  ): { [key: string]: ITeacherGroupingStudent[] } {
    // since a student can have multiple courses for the same teacher, we only want to add each student once
    const addedStudents = {};
    return students.reduce((teacherData, student) => {
      const {
        currProgram: { grades },
      } = student;
      grades.forEach((grade: { mostRecent: boolean; pf: 'P' | 'F'; teacher: { nickName: string } }) => {
        const {
          mostRecent,
          teacher: { nickName },
        } = grade;
        const isMostRecentGrade = mostRecent;

        if (isMostRecentGrade) {
          const isAdded = addedStudents[nickName] && addedStudents[nickName][student.studentId];
          // if this is the first time we come across this teacher, create a empty list for aligned students
          if (!teacherData[nickName]) {
            teacherData[nickName] = [];
            addedStudents[nickName] = {};
          }

          if (!isAdded) {
            teacherData[nickName].push(student);
            addedStudents[nickName][student.studentId] = true;
          }
        }
      });
      return teacherData;
    }, {});
  }

  private generateGrouping(args) {
    const { teacherData, columns, formattedList } = args;
    const grouping = reduce(
      teacherData,
      (formattedList, students: any, teacherNickName) => {
        const config = { grouping: 'TEACHER', teacher: teacherNickName };

        const teacherColumn = {
          data: teacherNickName,
          dependencies: {
            filterValue: teacherNickName,
            studentIds: students.map(({ studentId }) => studentId),
          },
        };

        const dataObj = columns.reduce(
          (result, { key, getValueAtPath }) => {
            result[key] = getValueAtPath(students, config);
            return result;
          },
          { teacher: teacherColumn },
        );

        formattedList.sections[0].count++;
        formattedList.sections[0].data.push(dataObj);
        return formattedList;
      },
      formattedList,
    );

    return grouping;
  }
}
