import { ModalsService } from 'Src/ng2/shared/modals/modals.service';
import { Component, Input, OnDestroy, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';

@Component({
  selector: 'base-modal',
  templateUrl: './base-modal.component.html',
  styleUrls: ['modals.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BaseModalComponent implements OnDestroy {
  @Input() title: any;
  @Input() itemCount: number;
  @Input() itemType: string; // TValid patchable collections
  @Input() isProfileMode?: boolean;
  @Input() subtitle? : string;
  @Input() isMultiMode?: boolean;
  @Input() buttonMode: string = 'close';
  @Input() backButtonPayload?: any;
  @Input() titleTooltipInfo?: string;
  @Input() childHasSecondPage: boolean;
  @Output() page1 = new EventEmitter();
  destroy$: Subject<boolean> = new Subject<boolean>();
  public isDisabled = true;

  // dependencies marked with ? indicate that the derived class optionally will pass that through constructor
  constructor (protected dialogRef: MatDialogRef<any>) {}

  ngOnDestroy (this): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  close (payload?): void {
    this.dialogRef.close(payload);
  }

  goBack (): void {
    this.childHasSecondPage = false;
    this.page1.emit(true);
    if (this.buttonMode) this.dialogRef.close({ goBack: true });
  }
}
