import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import * as _ from 'lodash';
import { ImSchool } from 'Src/ng2/shared/services/im-models/im-school';
import { CsvExporterService, CsvType } from '../../../../../../../../ng2/shared/services/csv-exporter/csv-exporter.service';
import { SpinnerService } from 'Src/ng2/shared/components/spinner/spinner-modal.service';
import { ModalsService } from 'Src/ng2/shared/modals/modals.service';
import * as moment from 'moment';
import { ARTICLE_URLS } from 'Src/ng2/shared/constants/article-urls.constant';

export interface IStudent {
  studentId: string;
  gradPlan: string;
  cohort: string;
  status: string;
  isSuperSenior: boolean;
  hasPotentialCreditIssuesOnCurrProgram: boolean,
  hasGapPlansPastPlannedGradDate: boolean,
  pendingCourseAdditions: string[],
  pendingCourseDrops: string[],
  pendingCourseAdditionsWithoutSection: string[],
}
@Component({
  selector: 'do-final-check',
  templateUrl: './do-final-check.component.html',
  encapsulation: ViewEncapsulation.None,
})

export class DoFinalCheckStep implements OnInit {
  @Input() filteredStudents: any;
  @Input() currentFilter: any;
  @Input() school: any;

  private currentTermYear;
  public starsInstructions;

  public filteredFlattenedStudents;
  public stepLabel: string = 'Do a final check';
  public buckets;
  public options;
  public plurals;
  public spinner;
  public studentsWithPendingCourseDiffs;
  public studentsWithPendingGapPlans;
  public studentsWithCredIssues;
  public studentsWithPlansBeyonGradDate;

  constructor (
    private imSchool: ImSchool,
    private spinnerService: SpinnerService,
    private csvExporter: CsvExporterService,
    private modalsService: ModalsService,
  ) { }

  ngOnInit (): void {
    this.setUi();
  }

  ngOnChanges ({ currentFilter }): void {
    if (!currentFilter.firstChange) {
      this.setUi();
    }
  }

  setUi (): void {
    const columnKeyMapper = {
      studentsWithCredIssues: ['cohort', 'hasPotentialCreditIssuesOnCurrProgram'],
      studentsWithPlansBeyonGradDate: ['cohort', 'hasGapPlansPastPlannedGradDate', 'gapPlansSummary'],
      studentsWithPendingCourseDiffs: ['cohort', 'pendingCourseAdditions', 'pendingCourseDrops'],
      studentsWithPendingGapPlans: ['cohort', 'pendingCourseAdditionsWithoutSection'],
    };

    this.currentTermYear = this.imSchool.getCurrentTermYear(this.school);
    this.buckets = this.generateFilteredStudentIdBuckets();
    this.starsInstructions = ARTICLE_URLS.uploadCoursePlanSTARS;

    _.each(this.buckets, (value, key) => {
      this.buckets[key].count = value.length;
      this.buckets[key].options = { columnKeys: columnKeyMapper[key] };
    });
    this.studentsWithPendingCourseDiffs = this.buckets.studentsWithPendingCourseDiffs;
    this.studentsWithPendingGapPlans = this.buckets.studentsWithPendingGapPlans;
    this.studentsWithCredIssues = this.buckets.studentsWithCredIssues;
    this.studentsWithPlansBeyonGradDate = this.buckets.studentsWithPlansBeyonGradDate;

    this.plurals = {
      s: {
        0: "s'",
        1: "'s ",
        other: "s'",
      },
      thisCap: {
        0: ' These students',
        1: ' This student',
        other: 'These students',
      },
      this: {
        0: ' these students',
        1: ' this student',
        other: 'these students',
      },
      has: {
        0: 'students have',
        1: 'student has',
        other: 'students have',
      },
    };
  }

  private generateFilteredStudentIdBuckets () {
    const studentsWithCredIssues = [];
    const studentsWithPlansBeyonGradDate = [];
    const studentsWithPendingCourseDiffs = [];
    const studentsWithPendingGapPlans = [];
    _.each(this.filteredStudents, (student: any) => {
      const {
        studentId,
        hasPotentialCreditIssuesOnCurrProgram,
        hasGapPlansPastPlannedGradDate,
        pendingCourseAdditions,
        pendingCourseDrops,
        pendingCourseAdditionsWithoutSection,
      } = student;
      // If `All` is selected from dropdown, all students fall `inCohort`
      if (hasPotentialCreditIssuesOnCurrProgram) studentsWithCredIssues.push(studentId);
      if (hasGapPlansPastPlannedGradDate) studentsWithPlansBeyonGradDate.push(studentId);
      if (pendingCourseAdditions.length > 0 || pendingCourseDrops.length > 0) {
        studentsWithPendingCourseDiffs.push(studentId);
      }
      if (pendingCourseAdditionsWithoutSection.length > 0) studentsWithPendingGapPlans.push(studentId);
    });

    return {
      studentsWithCredIssues,
      studentsWithPlansBeyonGradDate,
      studentsWithPendingCourseDiffs,
      studentsWithPendingGapPlans,
    };
  }

  public generateCourseDiffCsv (): void {
    this.spinner = this.spinnerService.openSpinner({ message: 'Loading...' });
    this.csvExporter
      .createCourseDiffCsv(this.school)
      .then((csvString: string) => {
        const currentDateAndTime = moment().format('MM-DD-YYYY hmma');
        const fileName = `STARS Course Upload ${this.currentTermYear} ${currentDateAndTime}.csv`;
        const docType = 'text/csv;charset=utf-8';
        this.csvExporter.exportCsv(csvString, fileName, docType, CsvType.Student);
        this.spinner.close();
      })
      .catch(err => {
        this.modalsService.openErrorModal(err);
        this.spinner.close();
      });
  }
}
