import { Directive, ElementRef, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { unsubscribeComponent } from 'Src/ng2/shared/helpers/unsubscribe-decorator/unsubscribe-decorators.helper';

@Directive({
  selector: '[stickyScroll]',
})
@unsubscribeComponent
export class StickyScrollDirective implements OnInit {
  private scrollSubject = new Subject<ElementRef['nativeElement']>();
  private scrollObservable = this.scrollSubject.asObservable().pipe();
  private scrollSubs: Subscription;

  @HostListener('scroll', ['$event.target'])
  onScroll(el: ElementRef['nativeElement']) {
    this.scrollSubject.next(el);
  }

  @Output() onStickyScrolled = new EventEmitter<number>();

  ngOnInit(): void {
    this.scrollSubs = this.scrollObservable.subscribe(el => {
      this.onStickyScrolled.emit(el.scrollTop);
    });
  }
}
