import { RollbarService } from './../../services/rollbar/rollbar.service';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { cloneDeep, set } from 'lodash';
import { IFlag } from './../../typings/interfaces/flags.interface';
import { FLAG_IGNORE_MODAL_CONFIG, FlagIgnoreModalRadioButtonChoices } from './flag-ignore-modal.config';
import * as Rollbar from 'rollbar';

export interface IFlagIgnoreModalResult {
  otherActions?: IFlag['otherActions'];
  isIgnore?: IFlag['isIgnored'];
  note?: IFlag['note'];
}

@Component({
  selector: 'flag-ignore-modal',
  templateUrl: './flag-ignore-modal.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class FlagIgnoreModalComponent implements OnInit {
  public form: FormGroup;

  // Modal Configurations
  readonly radiobuttons = FLAG_IGNORE_MODAL_CONFIG.radiobuttons;
  readonly textarea = FLAG_IGNORE_MODAL_CONFIG.textarea;
  readonly buttons = FLAG_IGNORE_MODAL_CONFIG.buttons;

  constructor (
    public dialogRef: MatDialogRef<FlagIgnoreModalComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: IFlag,
    @Inject(RollbarService) private rollbar: Rollbar,
  ) {}

  public ngOnInit (): void {
    // clone data to avoid mutating the passed in data. (CM)
    this.data = cloneDeep(this.data);
    this.form = this.buildForm();
  }

  public onCancel (): void {
    this.dialogRef.close();
  }

  public onSubmit (form: FormGroup): void {
    const patch = this.processForm(form);

    this.dialogRef.close(patch);
  }

  private buildForm (): FormGroup {
    const textAreaInitialVal = undefined;
    const textAreaValidator = Validators.required;
    const radioChoiceInitialVal = this.radiobuttons.defaultVal;
    const radioChoiceValidator = Validators.required;

    const form = this.formBuilder.group({
      textarea: [textAreaInitialVal, textAreaValidator],
      radioChoice: [radioChoiceInitialVal, radioChoiceValidator],
    });

    return form;
  }

  private processForm (form: FormGroup): IFlagIgnoreModalResult {
    const value: { textarea: string; radioChoice: string } = form.value;
    const { textarea, radioChoice } = value;
    const patch: IFlagIgnoreModalResult = {};

    if (textarea) {
      const patchPath = 'note';

      set(patch, patchPath, textarea);
    }

    if (radioChoice) {
      switch (radioChoice) {
        case FlagIgnoreModalRadioButtonChoices.Ignore: {
          const patchPath = 'isIgnored';

          set(patch, patchPath, true);
          break;
        }
        case FlagIgnoreModalRadioButtonChoices.OtherAction: {
          const patchPathOtherActions = 'otherActions';

          set(patch, patchPathOtherActions, [radioChoice]);
          break;
        }
        default: {
          this.rollbar.warning('Invalid radio choice value exists in flags ignore modal: ', radioChoice);
        }
      }
    }

    return patch;
  }
}
