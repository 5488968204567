import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvIconModule } from 'projects/shared/nvps-libraries/design/nv-icon/nv-icon.module';
import { NvActionTileComponent } from './nv-action-tile.component';
import { NvSharedTooltipModule } from 'projects/shared/nvps-libraries/design/nv-tooltip/nv-shared-tooltip.module';

@NgModule({
  imports: [CommonModule, NvIconModule, NvSharedTooltipModule],
  declarations: [NvActionTileComponent],
  exports: [NvActionTileComponent],
})
export class NvActionTileModule {}
