import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { PartnerTypes } from 'Src/ng2/shared/typings/interfaces/partner.interface';
import * as docLogsActions from '../actions/doc-logs-actions';
import { ApiService } from './../../shared/services/api-service/api-service';

interface IAction {
  type: string;
  payload: {
    schoolId: string;
    studentId: string;
  };
}

@Injectable()
export class DocLogsEffects {
  constructor (private actions$: Actions, private apiService: ApiService, private store$: Store<any>) {}

  loadDocLogs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(docLogsActions.LOAD_DOC_LOGS),
      switchMap((action: IAction) => {
        const { schoolId, studentId } = action.payload;
        const options = {
          page: 1,
          limit: 30,
          where: JSON.stringify({
            studentId,
            historyType: { $ne: null },
          }),
        };
        return this.apiService.getPaginatedDocLogs(PartnerTypes.SCHOOL, schoolId, options).pipe(
          mergeMap((res: any) => {
            return [new docLogsActions.LoadDocLogsSuccess(res.data)];
          }),
          catchError(error => {
            return of(new docLogsActions.LoadDocLogsFail(error));
          }),
        );
      }),
    );
  });
}
