import { ISingleStudent } from 'Src/ng2/student/student-data-service/single-student-data.interface';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { cloneDeep, filter, map, set, sortBy } from 'lodash';
import { BaseModalComponent } from '../base-modal.component';
import { IBaseModalData, ModalsService } from '../modals.service';
import { getCurrentUser, BulkUpdateStudents, IBulkUpdateStudentsPayload, getSchool, UpdateSingleStudent } from 'Src/ng2/store';
import { modalOptions } from './grad-panel-modal.config';
import { DateHelpers } from '../../../../../projects/shared/services/date-helpers/date-helpers.service';
import {
  GraduationDate,
  IGraduationDate,
  NextGraduationDate,
} from './../../../shared/constants/graduation-date.constant';
import { IDropdownOption } from '../../../../../projects/shared/nvps-libraries/design/interfaces/design-library.interface';
import { IUser } from '../../typings/interfaces/user.interface';
import { ImUser } from '../../services/im-models/im-user';
import { ISchool } from '../../typings/interfaces/school.interface';
import { ApiService } from 'Src/ng2/shared/services/api-service/api-service';
import { Observable, Subscription } from 'rxjs';
import { catchError, switchMap, take, tap } from 'rxjs/operators';
import { EM_DASH } from '../../constants/em-dash.constant';
import { TBatchActionsOrigin } from '../../components/nv-actions/nv-actions.interface';

const STUDENTS_PROJECTION = {
  _id: true,
  studentId: true,
  'gradPlanningDetails.plannedGraduationDate': true,
  'gradPlanningDetails.plannedDiplomaType': true,
  'gradPlanningDetails.noteMetaData': true,
  'spedDetails.isSped': true,
  'gradPlanningDetails.schoolVerifiedSafetyNetEligibility': true,
  IS_SPED: true,
  SAFETY_NET_ELIGIBLE: true,
};

interface IGradPlanModalStudent {
  _id: string;
  studentId: string;
  IS_SPED: boolean;
  SAFETY_NET_ELIGIBLE: string;
  gradPlanningDetails: {
    plannedGraduationDate: any;
    plannedDiplomaType: string;
    noteMetaData: string,
  };
}

export interface IGradPanelModalComponentData extends IBaseModalData {
  school: { _id: string; district: string };
  isProfileMode?: boolean;
  studentIds: string[];
  origin?: TBatchActionsOrigin;
  isRestricted: boolean,
}

export const PLANNED_DIPLOMA_TYPES = [
  { key: 'Regents', human: 'Regents' },
  { key: 'Advanced Regents', human: 'Advanced Regents' },
  { key: 'Local', human: 'Local' },
  { key: 'Non-Graduate', human: 'Non-Graduate' },
  { key: EM_DASH, human: EM_DASH },
];

const LOCAL = 'Local';

@Component({
  selector: 'grad-panel-modal',
  templateUrl: './grad-panel-modal.component.html',
  styleUrls: ['./grad-panel-modal.component.scss'],
})

export class GradPanelModalComponent extends BaseModalComponent implements OnInit, OnDestroy {
  public iconName: string = 'close-large-blue';
  public currentUser: IUser;
  public saveButtonDisabled = true;

  // props for base modal
  public itemCount: number;
  public itemType: string;

  // Modal Configurations
  public modalOptions;
  public modalOptionsConfig = modalOptions;
  public schoolId: string;
  public school: ISchool;
  public district: string;
  public studentIds: string[];

  // subscriptions
  openAlertModalSubscription: Subscription;

  public gradPanelForm : FormGroup;

  public title = 'Assign Graduation Plan';
  private origin: TBatchActionsOrigin;
  public students: IGradPlanModalStudent[];

  constructor (
    @Inject(MAT_DIALOG_DATA) public data: IGradPanelModalComponentData,
    dialogRef: MatDialogRef<GradPanelModalComponent>,
    private cdr: ChangeDetectorRef,
    private store: Store<any>,
    private apiService: ApiService,
    private dateHelpers: DateHelpers,
    public formBuilder: FormBuilder,
    private modalService: ModalsService,
    private imUser: ImUser,
  ) {
    super(dialogRef);
  }

  public ngOnInit (): void {
    const { isProfileMode, school: { _id: schoolId, district }, studentIds, isRestricted, origin } = cloneDeep(this.data);

    this.titleTooltipInfo = 'Assign the diploma type and graduation date that represents the best-case outcome for each student. Once plans are set, the Portal will display each students\' progress against their plan.';
    this.itemCount = studentIds.length;
    this.itemType = 'student';
    this.isProfileMode = isProfileMode;
    this.schoolId = schoolId;
    this.district = district;
    this.origin = origin;
    this.studentIds = studentIds;

    this.renderOptions(isRestricted);
    this.store.select(getCurrentUser).subscribe(user => {
      this.currentUser = user;
    });
    this.gradPanelForm = this.formBuilder.group({
      note: ['', Validators.required],
    });
  }

  ngOnDestroy (this: any): void {
    if (this.openAlertModalSubscription) {
      this.openAlertModalSubscription.unsubscribe();
    }
  }

  ngAfterViewChecked (): void {
    this.cdr.detectChanges();
  }

  filterGradDates (currentDiplomaType = ''): IDropdownOption[] {
    currentDiplomaType = currentDiplomaType || (this.modalOptions ? this.modalOptions[0]?.data : null);
    if (currentDiplomaType === 'Non-Graduate' || currentDiplomaType === EM_DASH || !currentDiplomaType) {
      return [{ key: EM_DASH, human: EM_DASH }];
    }
    const gradDateHumanNames = map(GraduationDate, (date: IGraduationDate) => {
      return date.humanName;
    });

    const sortedDates = sortBy(gradDateHumanNames, (date: string) => {
      return new Date(date);
    });
    const filteredDates = filter(sortedDates, date => {
      const now = this.dateHelpers.getMomentObjForMonthYearDate(NextGraduationDate.humanName).startOf('month');
      const _date = this.dateHelpers.getMomentObjForMonthYearDate(date).startOf('month');
      return _date.isSameOrAfter(now);
    });

    const datesAsValidOptions = filteredDates.map(date => {
      return {
        key: date,
        human: date,
      };
    });

    return [...datesAsValidOptions, { key: EM_DASH, human: EM_DASH }];
  }

  renderOptions (isRestricted) {
    const gradDateOptions = this.filterGradDates();
    const optionsMap = {
      'Planned Diploma Type': PLANNED_DIPLOMA_TYPES,
      'Planned Graduation Date': gradDateOptions,
    };

    this.modalOptions = this.modalOptionsConfig.map(({ human, formattedData, editable }) => {
      return {
        human,
        data: EM_DASH,
        options: optionsMap[human] || [{ key: EM_DASH, human: EM_DASH }],
        editable: editable(isRestricted),
      };
    });

    this.getStudents$()
      .pipe(
        tap(students => {
          this.students = map(students, (student: any) => {
            return {
              ...student,
              IS_SPED: student.spedDetails?.isSped || false,
              SAFETY_NET_ELIGIBLE: student.gradPlanningDetails?.schoolVerifiedSafetyNetEligibility === true ? 'Yes' : 'No' || '',
              gradPlanningDetails: {
                plannedGraduationDate: student.gradPlanningDetails?.plannedGraduationDate || '',
                plannedDiplomaType: student.gradPlanningDetails?.plannedDiplomaType || '',
                noteMetaData: student.gradPlanningDetails?.noteMetaData || '',
              },
            } as IGradPlanModalStudent;
          });

          const student : ISingleStudent = this.students[0];
          if (this.isProfileMode) {
            student.PLANNED_DIPLOMA_TYPE = this.students[0].gradPlanningDetails?.plannedDiplomaType;
            student.PLANNED_GRAD_DATE = this.students[0].gradPlanningDetails?.plannedGraduationDate;
            this.modalOptions[0].data = student.PLANNED_DIPLOMA_TYPE || EM_DASH;
            this.modalOptions[1].data = student.PLANNED_GRAD_DATE || EM_DASH;
            this.modalOptions[1].options = optionsMap['Planned Graduation Date'] = this.filterGradDates(student.PLANNED_DIPLOMA_TYPE);
          }
        }),
        take(1),
      )
      .subscribe();
  }

  public onCancel (): void {
    this.dialogRef.close();
  }

  isValidDiplomaUpdate (patch, student) {
    const { IS_SPED, SAFETY_NET_ELIGIBLE } = student;
    const isLocal = patch === LOCAL;
    if (isLocal) return !!IS_SPED || !!(SAFETY_NET_ELIGIBLE === 'Yes');
    else return true;
  }

  createLastEditedObj () {
    const date = new Date();
    return {
      user: this.imUser.toMiniUser(this.currentUser),
      date,
    };
  }

  onChangeHandler (field: string, option: IDropdownOption) {
    const { key } = option;
    const diplomaOption = this.modalOptions[0];
    const gradPlanOption = this.modalOptions[1];
    switch (field) {
      case 'Planned Diploma Type': {
        diplomaOption.data = key;
        diplomaOption.dirtied = true;
        if (diplomaOption.data === 'Non-Graduate' || diplomaOption.data === EM_DASH || !diplomaOption.data) {
          gradPlanOption.disabled = true;
          gradPlanOption.options = [{ key: EM_DASH, human: EM_DASH }];
          gradPlanOption.data = EM_DASH;
        } else {
          gradPlanOption.disabled = false;
          gradPlanOption.options = this.filterGradDates(diplomaOption.data);
        }
        break;
      }
      case 'Planned Graduation Date': {
        if (diplomaOption.data !== 'Non-Graduate' && diplomaOption.data) {
          gradPlanOption.data = key;
          gradPlanOption.dirtied = true;
          gradPlanOption.options = this.filterGradDates(diplomaOption.data);
        } else {
          gradPlanOption.disabled = true;
          gradPlanOption.options = [{ key: EM_DASH, human: EM_DASH }];
          gradPlanOption.data = EM_DASH;
        }
        break;
      }
      default:
        break;
    }

    if ((diplomaOption.data === EM_DASH || diplomaOption.data === null) && gradPlanOption.data !== EM_DASH) {
      this.saveButtonDisabled = true;
    } else if ((diplomaOption.data === 'Non-Graduate' || !diplomaOption.data) &&
    (gradPlanOption.data === EM_DASH || !gradPlanOption.data)) {
      this.saveButtonDisabled = false;
    } else if ((diplomaOption.data !== 'Non-Graduate' && diplomaOption.data) &&
    (gradPlanOption.data !== EM_DASH && gradPlanOption.data)) {
      this.saveButtonDisabled = false;
    } else if (diplomaOption.data === EM_DASH && gradPlanOption.data === EM_DASH) {
      this.saveButtonDisabled = false;
    } else {
      this.saveButtonDisabled = true;
    }
  }

  _showInvalidPlannedDiplomaTypeErrModal (plannedDiplomaType, invalidForPatchStudents) {
    const title = 'Invalid Diploma Type';

    const message = this.isProfileMode
      ? `Invalid diploma type "${plannedDiplomaType}". Student must be "SWD" or have ` +
    '"School Verified Safety Net Eligibility" to be eligible for a "Local" diploma type.'
      : `The update to "${plannedDiplomaType}" Diploma Type failed for ${invalidForPatchStudents.length} student${invalidForPatchStudents.length > 1 ? 's' : ''} ` +
    'Student(s) must be "SWD" or have ' +
    '"School Verified Safety Net Eligibility" ' +
    'to be eligible for a "Local" diploma type.';

    this.openAlertModalSubscription = this.modalService
      .openAlertModal({
        title,
        message,
      })
      .afterClosed()
      .subscribe(() => {
        // reset dropdown to original value (CM).
        this.modalOptions[0].data = this.students[0].gradPlanningDetails?.plannedDiplomaType || '';
      });
  }

  public onSave (): void {
    const patch = {};
    const diplomaOption = this.modalOptions[0];
    const gradPlanOption = this.modalOptions[1];
    const note = this.gradPanelForm.controls.note.value;
    const lastEdited = this.createLastEditedObj();

    const parentPath = 'gradPlanningDetails';
    const pathGradPlan = this.isProfileMode ? `${parentPath}.plannedGraduationDate` : 'plannedGraduationDate';
    const pathDiplomaType = this.isProfileMode ? `${parentPath}.plannedDiplomaType` : 'plannedDiplomaType';
    const pathNoteMetaData = this.isProfileMode ? `${parentPath}.noteMetaData` : 'noteMetaData';
    const pathLastEdited = this.isProfileMode ? `${parentPath}.lastEdited` : 'lastEdited';

    const validForPatchStudentIds = [];
    const invalidForPatchStudentIds = [];

    if (gradPlanOption.dirtied) {
      const parsedValue = gradPlanOption.data === EM_DASH ? null : gradPlanOption.data;
      set(patch, pathGradPlan, parsedValue);
    }

    this.students.forEach(student => {
      if (diplomaOption.dirtied || this.isProfileMode) {
        const isValidDiplomaUpdate = this.isValidDiplomaUpdate(diplomaOption.data, student);

        if (isValidDiplomaUpdate) {
          validForPatchStudentIds.push(student._id);
          const parsedValue = diplomaOption.data === EM_DASH ? null : diplomaOption.data;
          set(patch, pathDiplomaType, parsedValue);

          // reset grad plan if diploma type is non-graduate or null
          if (parsedValue === 'Non-Graduate' || !parsedValue) {
            set(patch, pathGradPlan, null);
          }
        } else {
          if (this.isProfileMode) {
            this._showInvalidPlannedDiplomaTypeErrModal(diplomaOption.data, [student]);
            return null;
          } else {
            invalidForPatchStudentIds.push(student);
          }
        }
      }
    });

    set(patch, pathLastEdited, lastEdited);
    if (note && note !== '') {
      set(patch, pathNoteMetaData, note);
    }

    if (this.isProfileMode) {
      this.store.dispatch(new UpdateSingleStudent({ patch, id: validForPatchStudentIds[0] }));
    } else {
      const payload: IBulkUpdateStudentsPayload = {
        patches: {
          _ids: validForPatchStudentIds,
          path: 'gradPlanningDetails',
          newValue: patch,
        },
        origin: this.origin,
      };
      if (validForPatchStudentIds.length > 0) {
        this.store.dispatch(new BulkUpdateStudents(payload));
      }

      if (invalidForPatchStudentIds.length > 0) {
        this._showInvalidPlannedDiplomaTypeErrModal(diplomaOption.data, invalidForPatchStudentIds);
      }
    }

    this.dialogRef?.close('saved');
  }

  private getStudents$ () {
    return this.store.select(getSchool).pipe(
      switchMap(({ _id: schoolId }) => {
        const projection = STUDENTS_PROJECTION;
        const joins = [];
        const where = { _id: { $in: this.studentIds } };
        const payload = { schoolId, projection, joins, where };
        return this.apiService.getStudents(payload) as Observable<any>;
      }),
      catchError(() => []),
    );
  }
}
