<div>
  <page-header [pageHeaderMeta]="pageHeaderMeta" [filterFormControl]="searchFilter">
    <div title>
      <h1 class="title">Edit Portfolio</h1>
      <h2 class="subtitle">{{ user.name }}</h2>
    </div>
    <div top-right>
      <nv-button (clickButton)="onClickPrimaryBtn()">{{ btnName }}</nv-button>
    </div>

    <div class="portfolio-madlib-wrapper">
      <!-- BATCH EDIT TOOL BAR -->
      <!-- TODO - come up with a pattern for how to make this menu reusable -->
      <div class="batch-edit-tool-bar" *ngIf="(batchActionsSelectedIds$ | async).length !== 0">
        <div class="selected-text">{{ (batchActionsSelectedIds$ | async).length }} SELECTED</div>
        <div class="edit-permissions">
          <nv-button size="small" type="secondary" icon="pencil-small-blue" [matMenuTriggerFor]="dropdownMenu"
            >Edit permissions</nv-button
          >
          <mat-menu #dropdownMenu="matMenu" panelClass="nv-mat-menu">
            <nv-dropdown-item
              *ngFor="let option of batchEditOptions"
              [option]="option"
              (selectItem)="onBatchEditAccessLevel($event)"
            >
            </nv-dropdown-item>
          </mat-menu>
        </div>
        <div class="uncheck-selections">
          <nv-button size="small" type="secondary" icon="uncheck" (clickButton)="onUncheckAll()"
            >Uncheck selections</nv-button
          >
        </div>
      </div>
    </div>
  </page-header>

  <list-container
    [groupingData$]="groupingData$"
    [sortKey$]="portfolioListComponentConfig.sortKey$"
    [sortDirection$]="portfolioListComponentConfig.sortDirection$"
    [filterFormControl]="searchFilter"
    [batchActionsMode$]="batchActionsMode$"
    [batchActionsSelectedIds$]="batchActionsSelectedIds$"
    [columns]="portfolioListComponentConfig.columns"
    [columnIndexMap]="portfolioListComponentConfig.columnIndexMap"
    [listConfig]="portfolioListComponentConfig.listConfig"
    [dynamicComponentTrigger]="dynamicComponentTrigger"
    (batchActionData)="onBatchAction($event)"
    (focusedGroup)="onFocusedGroup($event)"
    (clickedDynamicComponent)="onSingleAccessLevel($event)"
  >
  </list-container>
</div>
