export const AG_GRID_NUMBER_FILTER_TYPES = {
  EQUALS: 1,
  NOT_EQUALS: 2,
  LESS_THAN: 3,
  LESS_THAN_OR_EQUAL: 4,
  GREATER_THAN: 5,
  GREATER_THAN_OR_EQUAL: 6,
  RANGE: 7,
};

export const AG_GRID_TEXT_FILTER_TYPES = {
  CONTAINS: 1,
  EQUALS: 2,
  NOT_EQUALS: 3,
  STARTS_WITH: 4,
  ENDS_WITH: 5,
};
