<network-left-side-nav
  [sideNavOptions]="sideNavOptions"
></network-left-side-nav>


<div class="outlet-wrapper">
  <ng-container *ngTemplateOutlet="isInMaintenanceMode ? maintenanceOn : maintenanceOff"></ng-container>
</div>

<ng-template #maintenanceOff>
  <router-outlet></router-outlet>
</ng-template>

<ng-template #maintenanceOn>
  <maintenance-mode></maintenance-mode>
</ng-template>
