import { TValidSchoolTypes } from 'Src/ng2/shared/typings/interfaces/school.interface';
import { IUser } from 'Src/ng2/shared/typings/interfaces/user.interface';

export type TSchoolUserModalViewMode = 'CREATE' | 'EDIT' | 'REMOVE';

export const SCHOOL_USER_BTN_CONFIG = {
  CREATE: 'Create',
  EDIT: 'Edit',
  NEXT: 'Continue',
  CREATE_LONG: 'Create user',
  EDIT_LONG: 'Save changes',
};

export interface ISchoolUserIdentifier {
  name: string;
  id: string;
}

export interface ISchoolUserModalShellData {
  projectionColumns?: string[];
  user?: ISchoolUserIdentifier;
  mode: TSchoolUserModalViewMode;
  currentUser?: IUser;
  schoolId?: string;
  schoolType: TValidSchoolTypes;
}
