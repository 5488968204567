import { IRegentsCategory, RegentsCategory } from '../../../ng2/shared/constants/regents.constant';

interface IRegentsForByCat5Grouping {
  first?: IRegentsCategory;
  second?: IRegentsCategory;
  third?: IRegentsCategory;
}

// MATH, SCIENCE, and SCOCIAL STUDIES CAN MEET MULTIPLE REQUIREMENTS FOR BY CAT5
export const RegentsCategoryGroupingForByCat5 = {
  MATH: {
    first: RegentsCategory.MATH,
    second: RegentsCategory.SECOND_MATH,
    third: RegentsCategory.THIRD_MATH,
  } as IRegentsForByCat5Grouping,
  SCIENCE: {
    first: RegentsCategory.SCIENCE,
    second: RegentsCategory.SECOND_SCI,
  } as IRegentsForByCat5Grouping,
  SOCIAL_STUDIES: {
    first: RegentsCategory.SOCIAL_STUDIES,
    second: RegentsCategory.SECOND_SS,
  } as IRegentsForByCat5Grouping,
};
