import { IHistoryAllModalMoreButtonEmitData } from './../../../modals/history-all/history-all-modal.component';
import { Subscription } from 'rxjs';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { IDropdownOption } from '../../../../../../projects/shared/nvps-libraries/design/interfaces/design-library.interface';
import { ImUser } from 'Src/ng2/shared/services/im-models/im-user';
import { IUser } from 'Src/ng2/shared/typings/interfaces/user.interface';
import { TValidPartnerTypes } from 'Src/ng2/shared/typings/interfaces/partner.interface';
import { THistoryLogsComponent } from 'Src/ng2/shared/modals/history-all/history-all-modal.component';

const EDIT_NOTE = 'edit-note';
const DELETE_NOTE = 'delete-note';

@Component({
  selector: 'history-all-modal-more-button',
  templateUrl: './history-all-modal-more-button.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class HistoryAllModalMoreButtonComponent implements ICellRendererAngularComp {
  @Input() editNoteButton: (listType: any, docId: any, data: any) => void;
  @Input() deleteNoteButton: (docId: any) => void;
  public isNoteCreator: boolean;
  public options: IDropdownOption[];
  public editAndDeleteOptions: IDropdownOption[] = [
    { key: 'Edit note', human: 'Edit note', customClass: EDIT_NOTE },
    { key: 'Delete note', human: 'Delete note', customClass: DELETE_NOTE },
  ];

  public deleteOption: IDropdownOption[] = [
    { key: 'Delete', human: 'Delete note', customClass: 'delete-note' },
  ];

  protected params: ICellRendererParams;

  public currentUserSubscription: Subscription;
  public userCanEdit: boolean;
  public currentUser: IUser;
  public noteAuthor: string;
  public moreButtonDisabled: boolean = false;
  public disabledTooltipMsg: string;
  public isAdmin: boolean = false;
  public partnerType: TValidPartnerTypes;
  public docId: string;
  public listType: THistoryLogsComponent;
  public isDeleted: boolean;

  public agInit (params): void {
    this.params = params;
    const { parentComponent, data } = params;
    this.noteAuthor = data.createdBy.userId;
    this.currentUser = parentComponent.currentUser;
    this.partnerType = parentComponent.partnerType;
    this.isAdmin = this.imUser.isPartnerClusterOrDelegatedAdmin(this.currentUser, { partnerType: this.partnerType });
    if (this.isAdmin && this.noteAuthor !== this.currentUser._id) {
      this.options = this.deleteOption;
    } else {
      this.options = this.editAndDeleteOptions;
    }
    if (data.collection === 'notes' && data.diffs && data.diffs[0].newVal === 'DELETED') {
      this.isDeleted = true;
    }

    const collection = data.collection || this.params.colDef.cellRendererParams.collection;
    const { moreButtonDisabled, disabledTooltipMsg } = this._getDisabledMoreBtnAndMsg(this.noteAuthor, this.currentUser._id, collection);
    this.moreButtonDisabled = moreButtonDisabled;
    this.disabledTooltipMsg = disabledTooltipMsg;
    this.partnerType = parentComponent.partnerType;
    if (params.data.newDoc) {
      this.docId = data.newDoc?._id;
    } else if (data.docId) {
      this.docId = data.docId;
    } else {
      this.docId = data._id;
    }
    this.listType = parentComponent.listType;
    this.editNoteButton = parentComponent.editNoteButton.bind(parentComponent);
    this.deleteNoteButton = parentComponent.deleteNoteButton.bind(parentComponent);
  }

  constructor (
    private imUser: ImUser,
  ) {}

  public handleMoreButtonClick (e, action): void {
    if (action === EDIT_NOTE) {
      this.editNoteButton(this.listType, this.docId, this.params.data);
    }
    if (action === DELETE_NOTE) {
      this.deleteNoteButton(this.docId);
    }
  }

  public refresh (params: ICellRendererParams): boolean {
    return false;
  }

  public _getDisabledMoreBtnAndMsg (noteAuthor, currentUser, collection): {
    moreButtonDisabled: boolean;
    disabledTooltipMsg?: string;
  } {
    if (collection !== 'notes') {
      return {
        moreButtonDisabled: true,
        disabledTooltipMsg: '',
      };
    }
    if (this.isDeleted) {
      return {
        moreButtonDisabled: true,
        disabledTooltipMsg: '',
      };
    }
    if (this.isAdmin) {
      return {
        moreButtonDisabled: false,
      };
    }
    if (noteAuthor !== currentUser) {
      return {
        moreButtonDisabled: true,
        disabledTooltipMsg: 'Cannot edit notes created by another user',
      };
    }
    return {
      moreButtonDisabled: false,
    };
  }
}
