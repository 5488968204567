import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { unsubscribeComponent } from 'Src/ng2/shared/helpers/unsubscribe-decorator/unsubscribe-decorators.helper';

@Component({
  selector: 'master-program-settings-preps-pill',
  templateUrl: './master-program-settings-preps-pill.component.html',
  styleUrls: ['./master-program-settings-preps-pill.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
@unsubscribeComponent
export class MasterProgramSettingsPrepsPill implements OnInit {
  @Input() input: any;
  @Output() output: any = new EventEmitter();

  public preps: string[] = [];

  ngOnInit (): void {
    if (this.input?.column?.data && (this.input.column.data !== '—')) {
      this.preps = this.input.column.data;
    }
  }
}
