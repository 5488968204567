import { Component, Input, OnInit } from '@angular/core';
import { ICaringAdultInfo } from '../program-point-modal/program-point-modal.component';
@Component({
  selector: 'remove-caring-adult',
  templateUrl: 'remove-caring-adult.component.html',
})
export class RemoveCaringAdultComponent implements OnInit {
  @Input() caringAdultInfo: ICaringAdultInfo;
  @Input() schoolId: string;

  public confirmationMessage: string;
  constructor () {}

  ngOnInit () {
    this.confirmationMessage = this.getConfirmationMessage(this.caringAdultInfo);
  }

  private getConfirmationMessage ({ firstName, lastName }): string {
    const message = `Are you sure you want to remove ${firstName} ${lastName} as a Caring Adult? Their students will become unassigned and will need to be reassigned to a new Caring Adult.`;
    return message;
  }

  public getPayload () {
    const payload = {
      caringAdultId: this.caringAdultInfo.id,
      schoolId: this.schoolId,
      studentIds: [],
    };
    return payload;
  }
}
