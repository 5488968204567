import { NgModule } from '@angular/core';
import { NvSharedTooltipModule } from '../../../../projects/shared/nvps-libraries/design/nv-tooltip/nv-shared-tooltip.module';
import { TOOLTIP_SERVICE_TOKEN } from '../../../../projects/shared/nvps-libraries/design/nv-tooltip/nv-tooltip.directive';
import { NvTooltipDataService } from './nv-tooltip.service';
@NgModule({
  imports: [NvSharedTooltipModule],
  providers: [
    { provide: TOOLTIP_SERVICE_TOKEN, useClass: NvTooltipDataService },
  ],
  exports: [NvSharedTooltipModule],
})
export class NvTooltipModule {}
