import { Component, EventEmitter, Input, Output, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IFocus } from 'Src/ng2/school/tiles/interfaces/dashboard.interfaces';

@Component({
  selector: 'madlib',
  templateUrl: './mad-lib.component.html',
  styleUrls: ['./mad-lib.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class Madlib implements OnInit {
  @Input() madlibModel: FormGroup;
  @Input() fociByCategory: Array<{ foci: IFocus[]; categoryLabel: string; options: IFocus[] }>;
  @Output() madlibChange = new EventEmitter();

  public hasMultipleFoci = true;

  ngOnInit (): void {
    this.hasMultipleFoci =
      this.fociByCategory.length > 1 ||
      (this.fociByCategory[0] && this.fociByCategory[0].options && this.fociByCategory[0].options.length > 1);
  }

  emitMadlibChange (evt, dimension) {
    this.madlibChange.emit({ data: evt, dimension });
  }
}
