export interface ITier {
  key: string;
  humanName: string;
  humanShort: string;
}

export interface ITiers {
  UNSPECIFIED: ITier;
  TIER_3: ITier;
  TIER_2: ITier;
  TIER_1: ITier;
}

export const Tiers: ITiers = {
  UNSPECIFIED: {
    key: '-',
    humanName: 'Select tier',
    humanShort: 'Select tier',
  },
  TIER_3: {
    key: '3',
    humanName: 'Tier 3 - Intensive intervention',
    humanShort: 'Tier 3',
  },
  TIER_2: {
    key: '2',
    humanName: 'Tier 2 - Strategic, targeted intervention',
    humanShort: 'Tier 2',
  },
  TIER_1: {
    key: '1',
    humanName: 'Tier 1 - Universal or large group initiative',
    humanShort: 'Tier 1',
  },
};