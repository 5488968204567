<ng-container *ngIf="dateRangeForm">
  <nv-date-picker
    [dateControl]="dateRangeForm.controls.startDate"
    [startLimit]="startLimit"
    [endLimit]="startMaxLimit || startMax"
    [isInverted]="isInverted"
    [isDisabled]="isDisabled"
    [placeholder]="startPlaceholder"
  ></nv-date-picker>
  <nv-icon
    [name]="isOneValid ? 'arrow-right-default' : 'arrow-right-red'"
    class="arrow-icon"
    [ngClass]="isDisabled ? 'isDisabled' : null"
  ></nv-icon>
  <nv-date-picker
    [dateControl]="dateRangeForm.controls.endDate"
    [startLimit]="endMinLimit || endMin"
    [endLimit]="endLimit"
    [isInverted]="isInverted"
    [isDisabled]="isDisabled"
    [placeholder]="endPlaceholder"
  ></nv-date-picker>
</ng-container>
