import { StudentSupportsPanelListModule } from './../../../school/lists/student-supports-panel-list/student-supports-panel-list.module';
import { StudentSupportsHistoricalDataModalComponent } from './student-supports-historical-data-modal.component';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { NvDesignLibraryModule } from '../../../../nvps-libraries/design/nv-design-library.module';
import { NvMatTooltipModule } from '../../components/nv-mat-tooltip/nv-mat-tooltip.module';
import { BaseModalModule } from '../base-modal.module';

@NgModule({
  declarations: [StudentSupportsHistoricalDataModalComponent],
  imports: [StudentSupportsPanelListModule, CommonModule, BaseModalModule, NvDesignLibraryModule, NvMatTooltipModule],
  exports: [StudentSupportsHistoricalDataModalComponent],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class StudentSupportsHistoricalDataModule {}
