import { ActivatedRoute } from '@angular/router';
import { Component, ViewEncapsulation } from '@angular/core';
import { INetworkSideNavConfigOptions } from 'Src/nvps-libraries/design/nv-sidebar-list/nv-sidebar.interface';

@Component({
  selector: 'network-standard-layout',
  templateUrl: './network-standard-layout.component.html',
  styleUrls: ['./network-standard-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NetworkStandardLayoutComponent {
  public isInMaintenanceMode: boolean;
  public sideNavOptions: INetworkSideNavConfigOptions;

  constructor (private route: ActivatedRoute) { }

  ngOnInit () {
    this.isInMaintenanceMode = this.route.snapshot.data.maintenanceMode;
    this.sideNavOptions = this.route.snapshot.data.networkSideNavConfig;
  }
}
