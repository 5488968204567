import { Injectable } from '@angular/core';

export interface TStudentIReadyCalcConfig {
  field: string;
  graphQlKey: string;
  wildcardKey: string;
}

@Injectable()
export class GraphQLStudentIReadyHelperService {
  getStudentIReadyQuery ({ schoolId, studentId }): string {
    return `
    query ($calcConfig: [StudentIReadyCalcConfig!]) {
      StudentIReady(schoolId: "${schoolId}", studentId: "${studentId}", calcConfig: $calcConfig){
        IREADY_PLACEMENT_LEVEL_ELA_CURR_TERM
        IREADY_PLACEMENT_LEVEL_MATH_CURR_TERM
        IREADY_SCREENER_DATA_FOR_PANEL {
          performanceEachAdmin {
            year
            termName
            subject
            date
            rushFlag
            score
            absLvl
            relLvl
            scaleScoreSinceInitial
            typicalGrowth
            stretchGrowth
            domains {
              domainName
              score
              placementLevel
              relativePlacement
            }
          }
          progressBetweenAdmin {
            subject
            year
            termName
            date
            typicalGrowth
            stretchGrowth
            initialRelative
            finalRelative
            changeInRelativePlacement
          }
        }
      }
    }`;
  }
};
