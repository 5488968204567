import { WizardFilterResolver } from '../../../../routing/resolvers/sdc/wizard-filter/wizard-filter.resolver';
import { RegentsPrepWizardContainer } from './regent-prep-wizard-container.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RouteGuard } from '../../../../routing/guards/route-guard/route-guard';
import { USER_ROLE_PERMISSIONS_FOR_GUARDS } from '../../../../routing/guards/user-role-permissions-for-route-guards.constant';

const routes = [
  {
    path: '',
    partnerType: 'school',
    authenticationRequired: true,
    component: RegentsPrepWizardContainer,
    rolePermissions: USER_ROLE_PERMISSIONS_FOR_GUARDS['school/:schoolId/other-tools/regents-prep/wizard'],
    canActivate: [RouteGuard],
    data: {
      sdcKey: 'regents-prep',
    },
    resolve: {
      filter: WizardFilterResolver,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RegentsPrepWizardRoutingModule { }
