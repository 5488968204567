import { OtherToolsResourcesComponent } from './other-tools-resources.component';
import { IRouteConfigsOpts } from '../../../../routing/route.config';
import { USER_ROLE_PERMISSIONS_FOR_GUARDS } from '../../../../routing/guards/user-role-permissions-for-route-guards.constant';
import { RouteGuard } from '../../../../routing/guards/route-guard/route-guard';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SchoolTypeGuard } from 'Src/ng2/routing/guards/school-type-guard/school-type-guard';
import { DistrictGuard } from 'Src/ng2/routing/guards/district-guard/district-guard';
import { OtherToolsSideNavConfig } from '../other-tools.constant';

export const otherToolsResourcesRoutes: IRouteConfigsOpts[] = [
  {
    path: '',
    partnerType: 'school',
    authenticationRequired: true,
    component: OtherToolsResourcesComponent,
    rolePermissions: USER_ROLE_PERMISSIONS_FOR_GUARDS['school/:schoolId/other-tools/resources'],
    canActivate: [RouteGuard, SchoolTypeGuard, DistrictGuard],
    data: {
      // schoolTypes: OtherToolsSideNavConfig.tools.RESOURCES.schoolTypes,
      // districts: OtherToolsSideNavConfig.tools.RESOURCES.districts,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(otherToolsResourcesRoutes)],
  exports: [RouterModule],
})
export class OtherToolsResourcesRoutingModule {}
