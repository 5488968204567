<div class="dropdown-container">
  <p class="consent-status">Consent status</p>
  <nv-dropdown
    id="consentStatusSelect"
    [selected]="selectedConsentStatus.value"
    [options]="consentStatusOptions"
    (selectOption)="onConsentStatusSelect($event)"
  ></nv-dropdown> 
</div>
<p class="description">By clicking save, you affirm that you have received a valid paper consent form for this family and will email a copy of the form to {{ ecfikEmail }}</p>
