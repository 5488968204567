<ng-container *ngIf="(autoCompleteOptions$ | async) as autoCompleteOptions">
  <nv-form-field label="Program">
    <nv-textbox
      [textControl]="formGroup.controls.program"
      icon="search-large-black"
      placeholder="Enter a program..."
      [restrictToOptions]="true"
      [autocompleteOptions]="autoCompleteOptions.program"
      [emptyStateText]="emptyStateProgram"
      [hasClearBtn]="true"
      (clearValue)="onClearFieldSelection('program')"
      [returnObject]="true"
      (selectOption)="onFieldSelect($event, 'program')"
      [optionsPredicateCb]="optionsPredicateCb"
      [optionsLimit]="optionsLimit"
    >
      <nv-autocomplete-action [icon]="emptyStateIcon" (click)="onCreateOption('program')">
        <span class="autocomplete-text"> Add new program </span>
      </nv-autocomplete-action>
    </nv-textbox>
  </nv-form-field>

  <nv-form-field label="Type of placement (optional)">
    <nv-dropdown
      placeholder="Select a type of placement"
      [options]="autoCompleteOptions.typeOfPlacement"
      (selectOption)="onFieldSelect($event, 'typeOfPlacement')"
      [returnObject]="true"
    ></nv-dropdown>
  </nv-form-field>

  <nv-form-field label="Transition support organization (optional)">
    <nv-textbox
      [textControl]="formGroup.controls.transitionSupportOrg"
      icon="search-large-black"
      placeholder="Select a transition support organization..."
      [restrictToOptions]="true"
      [autocompleteOptions]="autoCompleteOptions.transitionSupportOrg"
      [emptyStateText]="emptyStateTransitionSupportOrg"
      [hasClearBtn]="true"
      (clearValue)="onClearFieldSelection('transitionSupportOrg')"
      [returnObject]="true"
      (selectOption)="onFieldSelect($event, 'transitionSupportOrg')"
      [optionsPredicateCb]="optionsPredicateCb"
      [optionsLimit]="optionsLimit"
    >
      <nv-autocomplete-action [icon]="emptyStateIcon" (click)="onCreateOption('transitionSupportOrg')">
        <span class="autocomplete-text"> Add new transition support organization </span>
      </nv-autocomplete-action>
    </nv-textbox>
  </nv-form-field>

  <status-dropdown
    pathCategory="publicService"
    [studentPaths]="studentPaths"
    (selectStatus)="onSelectStatus($event)"
    [selectedStatusOption]="selectedStatusOption"
  ></status-dropdown>

  <submit-button
    [isDisabled]="!this.formGroup.valid"
    [confirmationButtonLabel]="action"
    (handleClick)="handleClick()"
    (handleClose)="onEmitCloseModal()"
  ></submit-button>
</ng-container>
