import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as Rollbar from 'rollbar';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ApiService } from 'Src/ng2/shared/services/api-service/api-service';
import { UtilitiesService } from 'Src/ng2/shared/services/utilities/utilities.service';
import { ImCachedObject } from '../../shared/services/im-models/im-cached-object.service';
import { RollbarService } from '../../shared/services/rollbar/rollbar.service';
import * as objectCacheActions from '../actions/object-cache-actions';

@Injectable()
export class ObjectCacheEffects {
  constructor(
    @Inject(RollbarService) private rollbar: Rollbar,
    private actions$: Actions,
    private apiService: ApiService,
    private imCachedObject: ImCachedObject,
    private utilitiesService: UtilitiesService,
  ) {}

  FetchObjectCache = createEffect(() => {
    return this.actions$.pipe(
      ofType<any>(objectCacheActions.FETCH_OBJECT_CACHE),
      mergeMap(({ payload }) => {
        return this.apiService.getCachedObject(payload).pipe(
          map(({ _id, json }) => {
            const newObject = { [_id]: JSON.parse(json) };
            return new objectCacheActions.FetchObjectCacheSuccess(newObject);
          }),
          catchError(error => {
            this.rollbar.warning(`Error fetching objectCache from DB `, error);
            return of(new objectCacheActions.FetchObjectCacheFail(payload));
          }),
        );
      }),
    );
  });

  createObjectCache$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<any>(objectCacheActions.CREATE_OBJECT_CACHE),
      mergeMap(({ payload }) => {
        const { key, object } = payload;
        const backendCachedObject = this.imCachedObject.createFromObject(object);
        return this.apiService.createCachedObject(backendCachedObject).pipe(
          map((response: any) => {
            const key = response._id;
            const object = JSON.parse(response.json);
            const newObject = { [key]: this.utilitiesService.toPOJO(object) };
            return new objectCacheActions.CreateObjectCacheSuccess({ key, object: newObject });
          }),
          catchError(error => {
            this.rollbar.warning(`Error pushing objectCache to DB `, error);
            return of(new objectCacheActions.CreateObjectCacheFail(key));
          }),
        );
      }),
    );
  });
}
