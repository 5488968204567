import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModalsModule } from '../../modals/modals.module';
import { ExitViewComponent } from './exit-view.component';
import { NvIconButtonModule } from 'Src/nvps-libraries/design/nv-icon-button/nv-icon-button.module';

@NgModule({
  declarations: [ExitViewComponent],
  imports: [CommonModule, ModalsModule, NvIconButtonModule],
  exports: [ExitViewComponent],
})
export class ExitViewModule {}
