import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ActionsSubject, Store } from '@ngrx/store';
import { ofType } from '@ngrx/effects';
import { Observable, Subject, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { IStudent } from '../../../typings/interfaces/student.interface';
import { ISchoolAssessment } from '../../../typings/interfaces/school-assessment.interface';
import { QUEUE_CREATE_SYNC_TOOL_FAIL, QUEUE_CREATE_SYNC_TOOL_SUCCESS, QueueCreateSyncToolSuccess, QueueCreateSyncToolFail, QUEUE_UPDATE_SYNC_TOOL_SUCCESS, QUEUE_UPDATE_SYNC_TOOL_FAIL, CreateSyncTool, QueueUpdateSyncToolFail, QueueUpdateSyncToolSuccess, UpdateSyncTool, ISyncToolFilters } from '../../../../store';
import { IStudentSelectorInput, IStudentSelectorOutput } from './student-selector/student-selector.component';
import { IColumnSelectorInput, IColumnSelectorOutput } from './column-selector/column-selector.component';
import { IUpdateSelectorInput, IUpdateSelectorOutput } from './update-selector/update-selector.component';

export type SheetDataType = {
  spreadsheetId: string;
  autoUpdateSchedule?: null | {
    weekDay: '0' | '1' | '2' | '3' | '4' | '5' | '6' | '*',
  },
  columnKeys: string[];
  spreadsheetName: string;
  schoolId: string;
  filters: ISyncToolFilters;
};

export interface ISyncedSheetModalPayload {
  sheetData?: SheetDataType;
  schoolId: string;
  schoolAssessments: ISchoolAssessment[];
}

type IInitSelectionInput = (
  IStudentSelectorInput &
  IColumnSelectorInput &
  IUpdateSelectorInput
);

export { IStudentSelectorOutput, IColumnSelectorOutput, IUpdateSelectorOutput };

export type ISelectionOutput = (
  IStudentSelectorOutput |
  IColumnSelectorOutput |
  IUpdateSelectorOutput
);

type IFullSelectionOutput = (
  IStudentSelectorOutput &
  IColumnSelectorOutput &
  IUpdateSelectorOutput
);

type QueueUpdateSyncToolStatus = 'Loading' | 'Success' | 'Fail';

@Component({
  templateUrl: './synced-sheet-modal.component.html',
  styleUrls: ['./synced-sheet-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SyncedSheetModalComponent implements OnInit {
  @ViewChild(MatStepper) stepperComponent: MatStepper;

  schoolId: string;
  schoolAssessments: ISchoolAssessment[];
  isEditMode: boolean;
  title: string;
  initSelectionInput: IInitSelectionInput;
  queueUpdateSyncToolStatus$: Observable<QueueUpdateSyncToolStatus>;

  private selectionOutput: Partial<IFullSelectionOutput> = {};
  private queueUpdateSyncTool: Subject<'Loading'>;

  constructor (
    private actionsSubject: ActionsSubject,
    private store: Store<any>,
    public dialogRef: MatDialogRef<SyncedSheetModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ISyncedSheetModalPayload,
  ) {}

  ngOnInit (): void {
    const { sheetData, schoolId, schoolAssessments } = this.data;
    this.schoolId = schoolId;
    this.schoolAssessments = schoolAssessments;
    this.isEditMode = !!sheetData;
    this.title = this.isEditMode ? 'Edit synced sheet' : 'Create new synced sheet';
    this.initSelectionInput = sheetData || {};

    type QueueSyncToolActionResults = (
      QueueUpdateSyncToolSuccess |
      QueueUpdateSyncToolFail |
      QueueCreateSyncToolSuccess |
      QueueCreateSyncToolFail
    );
    const queueUpdateSyncToolFinalStatus$: Observable<'Success' | 'Fail'> = this.actionsSubject
      .pipe(
        ofType<QueueSyncToolActionResults>(
          QUEUE_UPDATE_SYNC_TOOL_SUCCESS,
          QUEUE_UPDATE_SYNC_TOOL_FAIL,
          QUEUE_CREATE_SYNC_TOOL_SUCCESS,
          QUEUE_CREATE_SYNC_TOOL_FAIL,
        ),
        map(({ type }) => {
          switch (type) {
            case QUEUE_UPDATE_SYNC_TOOL_SUCCESS:
            case QUEUE_CREATE_SYNC_TOOL_SUCCESS:
              return 'Success';
            case QUEUE_UPDATE_SYNC_TOOL_FAIL:
            case QUEUE_CREATE_SYNC_TOOL_FAIL:
            default:
              return 'Fail';
          }
        }),
      );
    this.queueUpdateSyncTool = new Subject<'Loading'>();

    this.queueUpdateSyncToolStatus$ = merge(queueUpdateSyncToolFinalStatus$, this.queueUpdateSyncTool);
  }

  onSectionCompleted ($event: ISelectionOutput) {
    this.selectionOutput = { ...this.selectionOutput, ...$event };
    this.stepperComponent.next();
  }

  onAllSectionsCompleted ($event: ISelectionOutput) {
    this.onSectionCompleted($event);
    this.queueUpdateSyncTool.next('Loading');
    const fullSelectionOutput = this.selectionOutput as IFullSelectionOutput;
    const sharedPayloadFields = {
      ...fullSelectionOutput,
      schoolId: this.schoolId,
    };
    if (this.isEditMode) {
      const updatePayload = {
        ...sharedPayloadFields,
        spreadsheetId: this.data.sheetData.spreadsheetId,
      };
      this.store.dispatch(new UpdateSyncTool(updatePayload));
    } else {
      const createPayload = {
        ...sharedPayloadFields,
      };
      this.store.dispatch(new CreateSyncTool(createPayload));
    }
  }

  onSectionAbandoned () {
    this.stepperComponent.previous();
  }

  close () {
    this.dialogRef.close();
  }
}
