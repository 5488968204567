import { ColDef } from '@ag-grid-community/core';
import { Injectable } from '@angular/core';
import { DateHelpers } from 'projects/shared/services/date-helpers/date-helpers.service';

@Injectable()
export class GridConfigService {
  constructor (private dateHelpers: DateHelpers) {}

  public addCustomFieldsTo (columnDefs: ColDef[]): ColDef[] {
    return columnDefs.map((columnDef: ColDef) => {
      const fun = this[columnDef.field];
      const addDateComparators = columnDef.filter === 'agDateColumnFilter' && !columnDef.valueGetter;

      if (fun) {
        return fun(columnDef);
      } else if (addDateComparators) {
        return this.addDateComparators(columnDef);
      } else {
        return columnDef;
      }
    });
  }

  private GRADE (columnDef: ColDef) {
    const sort = {
      PK: -2,
      '0K': -1,
    };
    columnDef.comparator = (a: any, b: any) => {
      if (sort[a]) a = sort[a];
      if (sort[b]) b = sort[b];
      return a < b ? -1 : 1;
    };
    return columnDef;
  }

  private DOE_RISK_GROUP_ATT (columnDef: ColDef) {
    columnDef.comparator = (a: any, b: any) => {
      const sort = {
        'Severely chronically absent': 1,
        'Chronically absent': 2,
        'At risk': 3,
        'Not at risk': 4,
        'No DOE risk group': 5,
      };
      if (sort[a]) a = sort[a];
      if (sort[b]) b = sort[b];
      return a < b ? -1 : 1;
    };
    return columnDef;
  }

  private NV_ATTENDANCE_RISK_GROUP (columnDef: ColDef) {
    columnDef.comparator = (a: any, b: any) => {
      const sort = {
        '95.01-100%': 1,
        '90.01-95%': 2,
        '80.01-90%': 3,
        '60.01-80%': 4,
        '60% or less': 5,
        'No Data': 6,
        null: 7,
      };
      if (sort[a]) a = sort[a];
      if (sort[b]) b = sort[b];
      return a < b ? -1 : 1;
    };
    return columnDef;
  }

  private NV_TRANSFER_ATTENDANCE_RISK_GROUP (columnDef: ColDef) {
    columnDef.comparator = (a: any, b: any) => {
      const sort = {
        '90.01-100%': 1,
        '80.01-90%': 2,
        '60.01-80%': 3,
        '33.01-60%': 4,
        '33% or less': 5,
        'No Data': 6,
        null: 7,
      };
      if (sort[a]) a = sort[a];
      if (sort[b]) b = sort[b];
      return a < b ? -1 : 1;
    };
    return columnDef;
  }

  private addDateComparators (columnDef: ColDef) {
    // used for sorting
    columnDef.comparator = this.getSortDateComparator();

    // used for filtering
    if (columnDef.filterParams) {
      columnDef.filterParams.comparator = this.getFilterDateComparator();
    } else {
      columnDef.filterParams = { comparator: this.getFilterDateComparator() };
    }

    return columnDef;
  }

  private getSortDateComparator () {
    return (date1, date2) => {
      if (!date1 && !date2) {
        return 0;
      } else if (!date1) {
        return -1;
      } else if (!date2) {
        return 1;
      } else {
        const date1Number = this.getDateNumberToCompare(date1);
        const date2Number = this.getDateNumberToCompare(date2);

        return date1Number - date2Number;
      }
    };
  }

  private getFilterDateComparator () {
    return (filterLocalDateAtMidnight, cellValue) => {
      if (!cellValue) return -1;

      const cellDateNumber = this.getDateNumberToCompare(cellValue);
      const filterLocalDateAtMidnightNumber = filterLocalDateAtMidnight.getTime();

      if (filterLocalDateAtMidnightNumber === cellDateNumber) {
        return 0;
      }

      if (cellDateNumber < filterLocalDateAtMidnightNumber) {
        return -1;
      }

      if (cellDateNumber > filterLocalDateAtMidnightNumber) {
        return 1;
      }
    };
  }

  private getDateNumberToCompare (date: string): number {
    if (!date) return null;
    // standardize date
    const dateFormat = 'DD/MM/YYYY';
    const dateParts = this.dateHelpers.getFormattedMomentFromDate(date, dateFormat).split('/');
    // convert standardized date to number
    const dateNumber = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0])).getTime();

    return dateNumber;
  }
}
