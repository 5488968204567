/* tslint:disable */

import { Action } from '@ngrx/store';
import { IFlag } from '../../shared/typings/interfaces/flags.interface';
export const LOAD_FLAGS = '[Portal] Load Flags';
export const LOAD_FLAGS_SUCCESS = '[Portal] Load Flags Success';
export const LOAD_FLAGS_FAIL = '[Portal] Load Flags Failure';
export const UPDATE_FLAG = '[Portal] Update Flag';
export const UPDATE_FLAG_SUCCESS = '[Portal] Update Flag Success';
export const UPDATE_FLAG_FAIL = '[Portal] Update Flag Fail';

export interface IUpdatePayload<T> {
  id: string;
  patch: Partial<T>;
}
// register store actions
export class LoadFlags implements Action {
  readonly type = LOAD_FLAGS;
  constructor (public payload: any) {}
}

export class LoadFlagsSuccess implements Action {
  readonly type = LOAD_FLAGS_SUCCESS;
  constructor (public payload: IFlag[]) {}
}

export class LoadFlagsFail implements Action {
  readonly type = LOAD_FLAGS_FAIL;
  constructor (public payload: any) {}
}

export class UpdateFlag implements Action {
  readonly type = UPDATE_FLAG;
  constructor (public payload: IUpdatePayload<IFlag>) {}
}

export class UpdateFlagSuccess implements Action {
  readonly type = UPDATE_FLAG_SUCCESS;
  constructor (public payload: any) {}
}

export class UpdateFlagFail implements Action {
  readonly type = UPDATE_FLAG_FAIL;
  constructor (public payload) {}
}

// action types
export type FlagAction = LoadFlags | LoadFlagsFail | LoadFlagsSuccess | UpdateFlag | UpdateFlagSuccess | UpdateFlagFail;
