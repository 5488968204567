<base-modal
  class="results-modal"
  [title]="title"
  [itemType]="itemType"
  [isProfileMode]="true"
>
  <div class="actions-container">
    <nv-note class="success-note" [size]="'small'" [priority]="'success'">
      {{ validMessage }}
    </nv-note>

    <nv-note class="error-note" [size]="'small'" [priority]="'error'">
      {{ invalidMessage }}
    </nv-note>
  </div>

  <p class="error-message"> {{ errorMessage }} </p>

  <!-- Buttons -->
  <mat-dialog-actions *ngIf="showButtons" class="dialog-actions">
    <nv-button type="secondary" (clickButton)="close()"> Stay on page </nv-button>
    <nv-button type="primary" (clickButton)="viewStudents()"> {{ actionBtnText }} </nv-button>
  </mat-dialog-actions>
</base-modal>
