import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';
import { Observable, from, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Auth } from '../../../shared/auth/auth.service';
import { SessionStorageService } from 'Src/ng2/shared/services/web-storage/session-storage/session-storage.service';

@Injectable()
export class NotificationsGuard implements CanActivate {
  constructor (
    private sessionStorageService: SessionStorageService,
    private authService: Auth,
    private router: Router,
  ) {}

  canActivate (): Observable<UrlTree|boolean> {
    const userDistricts$ = from(this.authService.getUserDistricts());
    return userDistricts$.pipe(
      switchMap(districts => {
        const currentDistrict = this.sessionStorageService.getItem('currentDistrict');
        const canManageCurrentDistrictNotifications = currentDistrict && districts.find(({ _id }) => _id === currentDistrict).permissions?.canManageNotifications;
        const otherDistrictNotificationsAdmin = districts.find((district) => district.permissions?.canManageNotifications)?._id;
        if (canManageCurrentDistrictNotifications) return of(true);
        else if (otherDistrictNotificationsAdmin) {
          this.sessionStorageService.setItem('currentDistrict', otherDistrictNotificationsAdmin);
          return of(true);
        }
        return of(this.router.createUrlTree(['not-found']));
      }),
    );
  }
}
