import { ISubLocation } from './shelter.interface';

export type TValidClusterTypes = 'school' | 'shelter';

export enum CLUSTER_TYPES {
  // eslint-disable-next-line no-unused-vars
  SHELTER = 'shelter',
  // eslint-disable-next-line no-unused-vars
  SCHOOL = 'school',
}

export type TValidClusterStatus = 'ACTIVE' | 'DE_PROVISIONED';

interface IClusterEditable {
  lastETLedAt: string;
}

interface IClusterStatic {
  _id: string;
  clusterName: string;
  type: TValidClusterTypes;
  schoolIds: string[];
  shelterIds: string[];
  tableauGroupId: string;
  approvedDomains: string[];
  status: TValidClusterStatus;
  subLocations?: ISubLocation[];
}

export interface ICluster extends IClusterEditable, IClusterStatic {}
