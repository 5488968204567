import { reduce } from 'lodash';
import * as docLogsActions from '../actions/doc-logs-actions';

export interface IDocLogsState {
  loading: boolean;
  loaded: boolean;
  docLogsEntities: {
    [id: string]: any;
  };
}

export const initialState: IDocLogsState = {
  loading: false,
  loaded: false,
  docLogsEntities: {},
};

export function reducer (state: IDocLogsState = initialState, action: docLogsActions.DocLogsAction): IDocLogsState {
  switch (action.type) {
    case docLogsActions.LOAD_DOC_LOGS:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case docLogsActions.LOAD_DOC_LOGS_SUCCESS:
      const docLogs = action.payload;
      const loadedDocLogs: IDocLogsState['docLogsEntities'] = reduce(
        docLogs,
        (acc, docLog) => {
          acc[docLog._id] = docLog;

          return acc;
        },
        {},
      );
      // persist doclogs in the store. Use case: user has two tabs open to two different students,
      // without persistence, a patch to one student would wipe out the doclogs for another in the store (JE)
      const mergedDocLogs = { ...state.docLogsEntities, ...loadedDocLogs };
      return {
        ...state,
        loading: false,
        loaded: true,
        docLogsEntities: mergedDocLogs,
      };
    default:
      return state;
  }
}
