import { getCurrentUser } from 'Src/ng2/store/selectors/current-user-selectors';
import { ApiService } from 'Src/ng2/shared/services/api-service/api-service';
import { ImUser } from 'Src/ng2/shared/services/im-models/im-user';
import { Store, select } from '@ngrx/store';
import { switchMap, take } from 'rxjs/operators';
import { Observable, forkJoin, of } from 'rxjs';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { getSchool } from 'Src/ng2/store/selectors/school-selectors';
import { PartnerTypes } from 'Src/ng2/shared/typings/interfaces/partner.interface';

@Injectable()
export class SupportLeadGuard implements CanActivate {
  constructor (private store: Store<any>, private imUser: ImUser, private apiService: ApiService) {}

  canActivate (snapshot: ActivatedRouteSnapshot): Observable<boolean> {
    // TODO: Improve this function. There is no reason to fetch the support unless userCanOnlyAccessSupportsTheyLead
    const { supportId } = snapshot.params;

    const USERS_THAT_CAN_ONLY_ACCESS_SUPPORTS_THEY_LEAD = ['edit_caseload', 'view_caseload', 'view_all'];
    return forkJoin([
      this.apiService.getSupport(supportId),
      this.store.pipe(select(getCurrentUser), take(1)),
      this.store.pipe(select(getSchool), take(1)),
    ])
      .pipe(
        switchMap((res) => {
          const [support, user, { _id: schoolId }] = res;
          const userRoleType = this.imUser.getRoleTypeOnAllLevels(user, { partnerType: PartnerTypes.SCHOOL, partnerId: schoolId });
          const userCanOnlyAccessSupportsTheyLead = USERS_THAT_CAN_ONLY_ACCESS_SUPPORTS_THEY_LEAD.includes(userRoleType);
          if (support && userCanOnlyAccessSupportsTheyLead) {
            const userIsActivityLead = support.activityLeads.some(({ userId }) => userId === user._id);
            if (!userIsActivityLead) return of(false);
            else return of(true);
          } else {
            return of(true);
          }
        }),
      );
  }
}
