import { ActivatedRoute } from '@angular/router';
import { Component } from '@angular/core';

@Component({
  selector: 'network-full-screen-layout',
  templateUrl: './network-full-screen-layout.component.html',
})
export class NetworkFullScreenLayoutComponent {
  public isInMaintenanceMode: boolean;

  constructor (private route: ActivatedRoute) { }

  ngOnInit () {
    this.isInMaintenanceMode = this.route.snapshot.data.maintenanceMode;
  }
}
