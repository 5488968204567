import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { districtsConfig } from '../../../../ng2/shared/constants/districts-config.constant';

@Injectable()
export class RedirectGuard implements CanActivate {
  constructor (private router: Router) {}
  canActivate (routeSnapshot: ActivatedRouteSnapshot): Observable<UrlTree> {
    const { queryParams, fragment, url } = routeSnapshot;
    const paths = [districtsConfig.NYC_DISTRICT_NAME, ...url.map(({ path }) => path)];
    return of(this.router.createUrlTree(paths, { queryParams, fragment }));
  }
}
