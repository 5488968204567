<div class="student-list-preview-v2" aria-label="Student List Preview">
  
  <mat-toolbar>
    <h2>Preview {{ students.length }} students</h2>
  </mat-toolbar>

  <mat-dialog-content *ngIf="data.description">
    <div class="mat-body-2">{{ data.description }}</div>
  </mat-dialog-content>

  <mat-divider *ngIf="data.description"></mat-divider>

  <mat-dialog-content class="mat-dialog-content preview-table">
    <table *ngIf="students.length" class="nv-table nv-table-dense">
      <thead>
        <tr>
          <th class="col-header-button" *ngFor="let columnObj of humanColumnNames">
            <span
              [ngClass]="{ 'active-sort': sortType === columnObj.path }"
              (click)="sortType = columnObj.path; sortReverse = !sortReverse; sortTable(columnObj.index, table, sortReverse)"
            >
              {{ columnObj.human }}
            </span>

            <span
              class="sort-direction"
              [ngClass]="{'reverse-sort': !sortReverse}"
              *ngIf="sortType === columnObj.path"
            >
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of table">
          <td *ngFor="let data of row">{{ data }}</td>
        </tr>
      </tbody>
    </table>

    <p class="mat-body-2" *ngIf="!students.length">No students to preview.</p>
  </mat-dialog-content>

  <mat-dialog-actions class="action-buttons" align="end">
    <button mat-button [mat-dialog-close]="true">Close</button>
  </mat-dialog-actions>

</div>
