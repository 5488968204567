<ng-container *ngIf="moreButtonDisabled; else threeDotMenu">
  <nv-icon-button name="more-large-blue" [disabled]="moreButtonDisabled"> </nv-icon-button>
</ng-container>
<ng-template #threeDotMenu>
  <nv-icon-button [matMenuTriggerFor]="menu" name="more-large-blue"> </nv-icon-button>
  <mat-menu #menu="matMenu" panelClass="nv-mat-menu">
    <nv-dropdown-item *ngFor="let option of options" [option]="option" (selectItem)="onSelect($event)">
    </nv-dropdown-item>
  </mat-menu>
</ng-template>
