
<base-modal
  class="base-modal grad-plan-modal"
  [title]="title"
  [itemCount]="itemCount"
  [itemType]="itemType"
  [isProfileMode]="isProfileMode"
  [buttonMode]="null"
  [titleTooltipInfo]="titleTooltipInfo"
>
  
<form [formGroup]="gradPanelForm" autocomplete="off">
 <div class="content">
  <ng-container *ngFor="let element of modalOptions">
        <nv-form-field [label]="element.human" class="option-field">
            <nv-dropdown 
              [options]="element.options"
              [selected]="element.data"
              [returnObject]="true"
              (selectOption)="onChangeHandler(element.human, $event)"
            >
            </nv-dropdown>
        </nv-form-field>

    </ng-container>
    <div class="cell-label">
      <nv-form-field label="Add Note (Optional)">
        <nv-textarea 
        [formControl]="gradPanelForm.controls.note"
        [maxCharacters]="150"
        [placeholder]="'Add note explaining why graduation plan will be updated'"
        >
        </nv-textarea>
      </nv-form-field>
    </div>  
  </div>
  <!-- Cancel / Save buttons -->
  <mat-dialog-actions class="dialog-actions">
    <nv-button type="secondary" (clickButton)="onCancel()">
      Cancel
    </nv-button>
    <nv-button (clickButton)="onSave()" [disabled]="saveButtonDisabled">
      Assign
    </nv-button>
  </mat-dialog-actions>
</form>
</base-modal>