import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvButtonModule } from 'projects/shared/nvps-libraries/design/nv-button/nv-button.module';
import { BaseModalModule } from '../base-modal.module';
import { AddMockRegentsExamsComponent } from './add-mock-regents-exams.component';
import { NvIconModule } from 'projects/shared/nvps-libraries/design/nv-icon/nv-icon.module';
import { NvPillModule } from 'Src/nvps-libraries/design/nv-pill/nv-pill.module';

@NgModule({
  declarations: [AddMockRegentsExamsComponent],
  imports: [
    BaseModalModule,
    CommonModule,
    NvButtonModule,
    NvIconModule,
    NvButtonModule,
    NvPillModule,
  ],
  exports: [AddMockRegentsExamsComponent],
})
export class AddMockRegentsExamsModule {}
