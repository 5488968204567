import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DynamicComponentModule } from 'projects/shared/directives/dynamic-component/dynamic-component.module';
import { NvTooltipModule } from 'Src/ng2/shared/nv-tooltip/nv-tooltip.module';
import { NvDesignLibraryModule } from '../../../../../nvps-libraries/design/nv-design-library.module';
import { NvMatTooltipModule } from '../../nv-mat-tooltip/nv-mat-tooltip.module';
import { FixedTableComponent } from './fixed-table.component';

@NgModule({
  imports: [
    CommonModule,
    NvDesignLibraryModule,
    NvMatTooltipModule,
    DynamicComponentModule,
    MatCheckboxModule,
    NvTooltipModule,
  ],
  declarations: [FixedTableComponent],
  exports: [FixedTableComponent],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class FixedTableModule {
  /** */
}
