import { UrlMatchResult, UrlSegment } from '@angular/router';

export function ssvMatcher (segments: UrlSegment[]): UrlMatchResult {
  if (segments.length > 0 && segments[0].path === 'student') {
    if (segments.length === 1) return { consumed: segments, posParams: {} };
    if (segments.length === 2) {
      const [, studentNumber] = segments;
      return { consumed: segments, posParams: { studentNumber } };
    }
    return <UrlMatchResult>(null as any);
  }
  return <UrlMatchResult>(null as any);
}
