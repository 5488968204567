import { Store } from '@ngrx/store';
import { Component, Input, OnInit } from '@angular/core';
import { ImSchool } from '../../../../../../services/im-models/im-school';
import { getSchool } from '../../../../../../../store';
import { map } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { CurrentSchoolYear } from 'Src/ng2/shared/constants/current-school-year.constant';

@Component({
  selector: 'form-item-date-picker',
  templateUrl: 'form-item-date-picker.component.html',
})

export class FormItemDatePickerComponent implements OnInit {
  @Input() dateRangeForm: FormGroup;

  public dateLimitConfig$: Observable<{ startLimit: string; endLimit: string }>;

  constructor (
    private store: Store<any>,
    private imSchool: ImSchool,
  ) {}

  ngOnInit (): void {
    this.dateLimitConfig$ = this.store.select(getSchool).pipe(
      map(school => {
        const orderedTermInfoArray = this.imSchool.getOrderedTermInfoArray(school);
        const startLimit = orderedTermInfoArray?.length ? orderedTermInfoArray[orderedTermInfoArray.length - 1].termStartDate : null;
        const endLimit = orderedTermInfoArray?.length ? orderedTermInfoArray[0].termEndDate : CurrentSchoolYear.LAST_DAY;
        return {
          startLimit,
          endLimit,
        };
      }),
    );
  }
}
