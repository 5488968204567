import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { RouteActivationService } from './../../../../routing/services/route-activation.service';
import { NvIconButtonModule } from 'Src/nvps-libraries/design/nv-icon-button/nv-icon-button.module';
import { NvSidebarListModule } from 'Src/nvps-libraries/design/nv-sidebar-list/nv-sidebar-list.module';
import { SettingsLeftNavComponent } from './settings-left-nav.component';
import { SettingsPageData } from './../settings-data-service/settings-data.service';

@NgModule({
  declarations: [SettingsLeftNavComponent],
  imports: [CommonModule, NvIconButtonModule, NvSidebarListModule],
  exports: [SettingsLeftNavComponent],
  providers: [RouteActivationService, SettingsPageData],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})

export class SettingsLeftNavModule {}
