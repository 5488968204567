import { IMapGrowthTableRow } from 'Src/ng2/student/common-panels/student-screener-panel/map-growth-table/map-growth-table.component';
import {
  LOAD_STUDENT_MAP_GROWTH,
  LOAD_STUDENT_MAP_GROWTH_FAIL,
  LOAD_STUDENT_MAP_GROWTH_SUCCESS,
  StudentMapGrowthAction,
} from '../../actions/map-growth-actions/student-map-growth-actions';

export interface IStudentMapGrowthEntity {
  [graphQlKey: string]: string | IMapGrowthTableRow[]; // data type of backend constant calculation result
}

export interface IStudentMapGrowthState {
  loading: boolean;
  loaded: boolean;
  studentMapGrowthEntities: {
    [id: string]: IStudentMapGrowthEntity;
  };
}

export const initialState = {
  loading: false,
  loaded: false,
  studentMapGrowthEntities: {},
};

export function reducer(state = initialState, action: StudentMapGrowthAction) {
  let payload: any;
  switch (action.type) {
    case LOAD_STUDENT_MAP_GROWTH:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case LOAD_STUDENT_MAP_GROWTH_SUCCESS:
      const studentMapGrowthData = action.payload;
      const studentId = action.studentId;
      const mergedStudentMapGrowthData: IStudentMapGrowthState['studentMapGrowthEntities'] = {
        ...state.studentMapGrowthEntities[studentId],
        ...studentMapGrowthData,
      };
      const mergedStudentMapGrowthEntities = {
        ...state.studentMapGrowthEntities,
        ...{ [studentId]: mergedStudentMapGrowthData },
      };
      return {
        ...state,
        loading: false,
        loaded: true,
        studentMapGrowthEntities: mergedStudentMapGrowthEntities,
      };
    case LOAD_STUDENT_MAP_GROWTH_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    default:
      return state;
  }
}
