import { LoteSchedulerModalService } from './lote-scheduler-modal.services';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LoteSchedulerModalComponent } from './lote-scheduler-modal.component';
import { MatRadioModule } from '@angular/material/radio';
import { NvButtonModule } from 'projects/shared/nvps-libraries/design/nv-button/nv-button.module';

@NgModule({
  declarations: [LoteSchedulerModalComponent],
  imports: [
    CommonModule,
    MatRadioModule,
    NvButtonModule,
    FormsModule,
  ],
  exports: [LoteSchedulerModalComponent],
  providers: [LoteSchedulerModalService],
})
export class LoteSchedulerModalModule {}
