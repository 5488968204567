import { USER_ROLE_PERMISSIONS_FOR_GUARDS } from '../../../routing/guards/user-role-permissions-for-route-guards.constant';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { RouteGuard } from '../../../routing/guards/route-guard/route-guard';
import { IRouteConfigsOpts } from '../../../routing/route.config';
import { FormativeAssessmentsListContainer } from './formative-assessments-list-v2.component';
import { SchoolTypeGuard } from 'Src/ng2/routing/guards/school-type-guard/school-type-guard';
import { OtherToolsSideNavConfig } from '../../school-tools/other-tools/other-tools.constant';
export const routes: IRouteConfigsOpts[] = [
  {
    path: '',
    partnerType: 'school',
    authenticationRequired: true,
    component: FormativeAssessmentsListContainer,
    rolePermissions: USER_ROLE_PERMISSIONS_FOR_GUARDS['school/:schoolId/other-tools/formative-assessments-list'],
    canActivate: [RouteGuard, SchoolTypeGuard],
    data: {
      schoolTypes: OtherToolsSideNavConfig.tools.FORMATIVE_ASSESSMENTS_LIST.schoolTypes,
      districts: OtherToolsSideNavConfig.tools.FORMATIVE_ASSESSMENTS_LIST.districts,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FormativeAssessmentsListRoutingModule {}
