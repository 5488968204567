import { SupportCategories } from '../../constants/support-categories.constant';
import { IModalsConfig } from './../modals.config.interface';

export const SUPPORT_MODAL_CONFIG: IModalsConfig = {
  buttons: {
    confirm: {
      text: 'Create',
      disableButtonRipple: true,
    },
    cancel: {
      text: 'Cancel',
      disableButtonRipple: true,
    },
    next: {
      text: 'Next',
      disableButtonRipple: true,
    },
  },
};

export const ACADEMIC_SUBCATS = 
  SupportCategories.ACADEMIC.subOptions.map( entry => entry.value);

export const PHYS_HEALTH_SUBCATS = 
  SupportCategories.PHYS_HEALTH_SERVICES.subOptions.map( entry => entry.value);

export const SUPPORT_SCHEDULE_REPEAT_FREQUENCY_FRONTEND = {
  ONE_TIME: 'One time',
  EVERY_DAY: 'Every day',
  EVERY_WEEK_MTWTHF: 'Every week on M-F',
  EVERY_WEEK_MWF: 'Every week on M/W/F',
  EVERY_WEEK_TTH: 'Every week on T/Th',
  CUSTOM: 'Custom',
};

export const SUPPORT_SCHEDULE_WEEK_OBJ = {
  monday: false,
  tuesday: false,
  wednesday: false,
  thursday: false,
  friday: false,
  saturday: false,
  sunday: false,
};

// selection of a subject is dependent on category selections
// If both regents AND academic cats are picked, requirement is to
// be able to select a value that is then applicable to the metaData for BOTH
// categories. The cat schema requirements for each field varies slightly in what the acceptable
// value is.
export const subjectsForRegentsAndAcademicCatPatches = {
  ELA: {
    display: 'ELA',
    regentsSubjectKey: 'ela',
    supportSubjectKey: 'ELA',
  },
  ALG: {
    display: 'Algebra',
    regentsSubjectKey: 'alg',
    supportSubjectKey: 'Math/Alg',
  },
  GEOM: {
    display: 'Geometry',
    regentsSubjectKey: 'geom',
    supportSubjectKey: 'Math/Geom',
  },
  TRIG: {
    display: 'Trigonometry',
    regentsSubjectKey: 'trig',
    supportSubjectKey: 'Math/Trig',
  },
  CHEM: {
    display: 'Chemistry',
    regentsSubjectKey: 'chem',
    supportSubjectKey: 'Phys Sci/Chem',
  },
  PHYSICS: {
    display: 'Physics',
    regentsSubjectKey: 'physics',
    supportSubjectKey: 'Phys Sci/Phys',
  },
  GLOBAL: {
    display: 'Global',
    regentsSubjectKey: 'global',
    supportSubjectKey: 'Global',
  },
  US: {
    display: 'US',
    regentsSubjectKey: 'us',
    supportSubjectKey: 'US',
  },
  LOTE: {
    display: 'LOTE',
    regentsSubjectKey: 'lote',
    supportSubjectKey: 'LOTE',
  },
  LIVING_ENG: {
    display: 'Life Sci/Liv',
    regentsSubjectKey: 'livingEnv',
    supportSubjectKey: 'Life Sci/Liv',
  },
  EARTH: {
    display: 'Phys Sci/Earth',
    regentsSubjectKey: 'earth',
    supportSubjectKey: 'Phys Sci/Earth',
  },
};
