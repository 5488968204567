import { RegentsAdminHelper } from './../../constants/regents-plans.constant';
import { Component, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControlName, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Store } from '@ngrx/store';
import { cloneDeep, set } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';
import { getCurrentUser, getSchool } from '../../../store';
import { IStudentEditableField, studentEditableFieldMap } from '../../constants/paths/student-editable-map.constant';
import { SorterColumnDataType } from '../../constants/sorter-column-data-type.constant';
import {
  BatchEditService,
  IBatchEditStudentParams,
  IBatchEditProcessPayload,
  TBatchEditSaveValue,
} from '../../services/batch-edit-service/batch-edit-service';
import { ISchool, TValidSchoolTypes } from '../../typings/interfaces/school.interface';
import { IUser, IUserMini } from '../../typings/interfaces/user.interface';
import { BaseModalComponent } from '../base-modal.component';
import { IBaseModalData } from '../modals.service';
import { BATCH_EDIT_MODAL_CONFIG } from './batch-edit-modal.config';
import { IACOption } from '../../../../../projects/shared/nvps-libraries/design/interfaces/design-library.interface';
import { ImUser } from '../../services/im-models/im-user';

export interface IBatchEditorProjection {
  schoolId: string;
  projection: {
    _id: boolean;
    studentId: boolean;
    pointPeople: boolean;
    'gradPlanningDetails.plannedEndorsements': boolean;
    'gradPlanningDetails.plannedCreds': boolean;
  };
  joins: any[];
}

export interface IBatchEditStudent {
  _id: string;
  studentId: string;
  pointPeople: IUserMini[];
  gradPlanningDetails: {
    plannedEndorsements: string[];
    plannedCreds: string[];
  };
}

export interface IEditableFields {
  humanName: string;
  category?: string;
  schoolLevels?: string[];
}

const needsLastEditedPaths = {
  'Planned Diploma Type': true,
  'Planned Graduation Date': true,
};

@Component({
  selector: 'batch-edit-modal',
  templateUrl: './batch-edit-modal.component.html',
  styleUrls: ['./batch-edit-modal.component.scss', './batch-edit-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BatchEditModalComponent extends BaseModalComponent implements OnInit, OnDestroy {
  @ViewChild(MatAutocompleteTrigger, { static: false }) trigger: MatAutocompleteTrigger;

  editableFields: IEditableFields[];
  filteredEditableFields$: Observable<IEditableFields[]>;
  currentUser: IUser;
  currentSchool: ISchool;
  selection: any;
  field: IStudentEditableField;
  isFieldSelected: boolean;
  currentStudents: IBatchEditStudent[];
  editLabel: string;
  schoolType: TValidSchoolTypes;
  selectedStudentIds: string[];

  form: FormGroup;
  searchField: FormControlName;
  dynamicFieldValue: TBatchEditSaveValue;
  isValueSelected: boolean; // allows for null value in dropdown and button enable/disable (JE)

  // constants
  types;
  studentEditableFields;

  searchFieldInput = BATCH_EDIT_MODAL_CONFIG.input;
  destroy$: Subject<boolean> = new Subject<boolean>();
  readonly buttons = BATCH_EDIT_MODAL_CONFIG.buttons;

  title: string;
  itemCount: number;
  itemType: string;

  constructor (
    dialogRef: MatDialogRef<BatchEditModalComponent>,
    private formBuilder: FormBuilder,
    private batchEditService: BatchEditService,
    private store: Store<any>,
    private imUser: ImUser,
    @Inject(MAT_DIALOG_DATA) public data: IBaseModalData,
  ) {
    super(dialogRef);
    this.types = SorterColumnDataType;
    this.studentEditableFields = studentEditableFieldMap;
  }

  ngOnInit (): void {
    this.data = cloneDeep(this.data);
    this.title = 'Batch edit field';
    this.selectedStudentIds = this.data.studentIds;
    this.itemCount = this.selectedStudentIds.length;
    this.itemType = 'student';
    this.form = this._buildForm();

    this.store.select(getCurrentUser).subscribe(user => {
      this.currentUser = user;
    });

    this.store
      .select(getSchool)
      .pipe(take(1))
      .subscribe(school => {
        const params: IBatchEditorProjection = {
          schoolId: school._id,
          projection: {
            _id: true,
            studentId: true,
            pointPeople: true,
            'gradPlanningDetails.plannedEndorsements': true,
            'gradPlanningDetails.plannedCreds': true,
          },
          joins: null,
        };
        this.schoolType = school.schoolType;
        this._getProjectedStudents(params);
      });

    this.editableFields = this.batchEditService
      .getEditableFields(this.currentUser, this.schoolType)
      .map(field => {
        return {
        // Apply IACOption properties
          key: field.humanName.replace(/( )/g, '-').toLowerCase(),
          human: field.humanName,
          tags: fieldCatToTags(field.category),
          ...field,
        };
      });
    this.filteredEditableFields$ = this._filterEditableFields();
    this.isFieldSelected = false;
    this.isValueSelected = false;
  }

  ngOnDestroy (): void {
    super.ngOnDestroy();
  }

  _getProjectedStudents (params) {
    this.batchEditService.getStudentsPointPeople(params)
      .subscribe(students => {
        this.currentStudents = students;
      });
  }

  // dependent ng5 services. don't move to body batchEditService
  _buildForm (): FormGroup {
    const form = this.formBuilder.group({
      searchField: [null, Validators.required],
      /**
       * placeholder and validation are null due to not knowing ahead of time what form control will be rendered.
       * Also, Validators.required just checks to make the field has a value. That can be accomplished
       * via check on whether field has been selected and value chosen. So may not be necessary but leaving for now.
       * - JYR
       */
      dynamicField: [null, null],
    });
    return form;
  }

  _filterEditableFields () {
    return this.form.controls.searchField.valueChanges.pipe(
      startWith(''),
      map(val => {
        const filtered = this._filterInput(val);
        return filtered;
      }),
    );
  }

  _filterInput (val: any) {
    if (typeof val === 'string') { // sometimes val could be the full object
      return this.batchEditService.filterInput(val, this.editableFields);
    } else {
      return this.batchEditService.filterInput(val.human, this.editableFields);
    }
  }

  public onSelectField (field: IACOption): void {
    const { human } = field;
    this.form.controls.searchField.setValue(human);
    this.field = this.batchEditService.getField(human);
    this.isFieldSelected = true;
    this.editLabel = this.getEditLabel(human);

    // unselects the value and prevents previous selection from persisting when switching fields
    this.isValueSelected = false;
    this.dynamicFieldValue = null;
  }

  public getEditLabel (searchFieldValue: string): string {
    return 'TO';
  }

  public onNewValueChange (newValue: TBatchEditSaveValue) {
    this.dynamicFieldValue = newValue;
    this.isValueSelected = true;
  }

  public onClearFieldSelection (): void {
    this.field = null;
    this.dynamicFieldValue = null;
    this.isValueSelected = false;
    this.isFieldSelected = false;
    this.form.controls.searchField.setValue('');
  }

  createLastEditedObj () {
    const date = new Date();
    return {
      user: this.imUser.toMiniUser(this.currentUser),
      date,
    };
  }

  processForm (form: FormGroup): IBatchEditStudentParams['patch'] {
    const { path, dataType, humanName, dataTypeOptions } = this.field;
    const {
      value: { searchField },
    } = form;
    const patch = {
      path: null,
      newValue: null,
      dataType,
      key: null,
      meta: null,
    };
    const isSinglePointPersonUpdate =
      dataType === SorterColumnDataType.USER_MINI &&
      (searchField === this.studentEditableFields.academicPointPerson.humanName ||
        searchField === this.studentEditableFields.attendancePointPerson.humanName ||
        searchField === this.studentEditableFields.postSecPointPerson.humanName ||
        searchField === this.studentEditableFields.creditsPointPerson.humanName ||
        searchField === this.studentEditableFields.regentsPointPerson.humanName ||
        searchField === this.studentEditableFields.advisor.humanName ||
        searchField === this.studentEditableFields.guidanceCounselor.humanName);
    const isMultiPointPersonUpdate = searchField === this.studentEditableFields.primaryPointPerson.humanName;
    const isEopFinancialEligibilityUpdate = searchField === this.studentEditableFields.eopFinancialEligibility.humanName;
    const saveValue = this.dynamicFieldValue;
    const payload: IBatchEditProcessPayload = {
      saveValue,
      selectedStudentIds: this.selectedStudentIds,
      currentStudents: this.currentStudents,
    };
    const needsLastEditedField = needsLastEditedPaths[humanName] ?? false;

    // preprocessing patch value based on data types
    switch (dataType) {
      case SorterColumnDataType.USER_MINI:
        if (isMultiPointPersonUpdate) {
          patch.newValue = this.batchEditService._processMultiPointPersonUpdate(payload);
        } else if (isSinglePointPersonUpdate) {
          payload.form = form;
          patch.newValue = this.batchEditService._processSinglePointPersonUpdate(payload);
        } else {
          // standard user mini update (guidance counselor, advisor)
          patch.newValue = saveValue;
        }
        break;
      case SorterColumnDataType.BOOLEAN_YES_NO:
        patch.newValue = this.batchEditService.setBooleanPatch(saveValue as string);
        break;
      case SorterColumnDataType.RADIO: {
        // @ts-ignore - It's unclear how `meta` can be set, since it doesn't appear on the interface. Nevertheless, it's used below - AT
        const { subType, meta } = dataTypeOptions;
        patch.meta = {
          subType,
        };
        if (subType === 'ENDORSEMENT' || subType === 'CREDENTIAL') {
          const endorsement = meta ? meta.endorsement : null;
          const credential = meta ? meta.credential : null;
          payload.meta = { endorsement, credential };
          patch.newValue = this.batchEditService._processEndorsementOrCredentialUpdate(payload, subType);
        }
      }
        break;
      case SorterColumnDataType.REGENTS_ADMIN:
        patch.newValue = RegentsAdminHelper.getAdminDatePatch(saveValue as string);
        break;
      case SorterColumnDataType.ENUM:
        if (isEopFinancialEligibilityUpdate) {
          patch.newValue = this.batchEditService._processEopFinancialEligibilityUpdate(payload, this.currentUser as IUser);
        } else {
          patch.newValue = saveValue;
        }
        break;
      default:
        patch.newValue = saveValue;
    }

    // adjusting path and patch if we need a last edited object
    if (needsLastEditedField) {
      const lastEditedObj = this.createLastEditedObj();
      const pathArray = path.split('.');
      const parentPath = pathArray[0];
      const childPath = pathArray[1];
      patch.path = parentPath;
      const dataToSend = patch.newValue;
      const newPatch = {};
      set(newPatch, childPath, dataToSend);
      set(newPatch, 'lastEdited', lastEditedObj);
      set(patch, 'newValue', newPatch);
    } else {
      // no preprocessing of path string
      patch.path = path;
    }

    return patch;
  }

  cancel (): void {
    // Ensure the field is not set so the dialog doesn't submit anything
    this.field = null;
    super.close();
  }

  submit (form: FormGroup): void {
    const patch = this.processForm(form);
    super.close(patch);
  }
}

export function fieldCatToTags (category: string): Array<{key: string, human: string}> {
  return category.split(',')
    .map(cat => ({
      key: cat.trim().toLowerCase(),
      human: cat.trim(),
    }));
}
