import { Action } from '@ngrx/store';
export const CLEAR_STATE = '[Portal] Clear State';

// register store actions
export class ClearState implements Action {
  readonly type = CLEAR_STATE;
  constructor () {}
}

// action types
export type ClearStateAction = ClearState;
