import { DeclineConsentStatusComponent } from './decline-consent-status/decline-consent-status.component';
import { RevokeConsentStatusComponent } from './revoke-consent-status/revoke-consent-status.component';
import { Component, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { BaseModalComponent } from '../base-modal.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EditConsentStatusComponent } from './edit-consent-status/edit-consent-status.component';
import { RequestConsentComponent } from './request-consent/request-consent.component';

export enum ConsentStatusModalControl {
  // eslint-disable-next-line no-unused-vars
  EDIT = 'EDIT_CONSENT_STATUS',
  // eslint-disable-next-line no-unused-vars
  REVOKE = 'REVOKE_CONSENT_STATUS',
  // eslint-disable-next-line no-unused-vars
  REQUEST = 'REQUEST_CONSENT',
  // eslint-disable-next-line no-unused-vars
  DECLINE = 'DECLINE_CONSENT_STATUS',
}

export type TModalControl = ConsentStatusModalControl.EDIT
  | ConsentStatusModalControl.REQUEST
  | ConsentStatusModalControl.REVOKE
  | ConsentStatusModalControl.DECLINE;

export interface IConsentStatusModalData {
  modalControl: TModalControl;
  consentStatus?: string;
  initialConsentStatus?: string;
}

@Component({
  selector: 'consent-status-modal',
  templateUrl: 'consent-status-modal.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class ConsentStatusModalComponent extends BaseModalComponent {
  public modalControl: TModalControl;
  public initialConsentStatus: string;
  public title: string;
  public submitButtonText: string;

  @ViewChild(EditConsentStatusComponent) editConsentStatus: EditConsentStatusComponent;
  @ViewChild(RevokeConsentStatusComponent) revokeConsentStatus: RevokeConsentStatusComponent;
  @ViewChild(RequestConsentComponent) requestConsent: RequestConsentComponent;
  @ViewChild(DeclineConsentStatusComponent) declineConsentStatus: DeclineConsentStatusComponent;


  constructor (
    dialogRef: MatDialogRef<ConsentStatusModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IConsentStatusModalData,
  ) {
    super(dialogRef);
  }

  ngOnInit () {
    this.modalControl = this.data.modalControl;
    this.initialConsentStatus = this.data.initialConsentStatus;
    this.title = this.getTitle(this.modalControl);
    this.submitButtonText = this.getSubmitButtonText(this.modalControl);
  }

  private getTitle (modalControl: TModalControl) {
    const mapModalControlToTitle = {
      EDIT_CONSENT_STATUS: 'Edit family consent status',
      REVOKE_CONSENT_STATUS: 'Revoke consent',
      REQUEST_CONSENT: 'Request consent',
      DECLINE_CONSENT_STATUS: 'Decline consent',
    };
    const title = mapModalControlToTitle[modalControl];
    return title;
  }

  private getSubmitButtonText (modalControl: TModalControl) {
    const mapModalControlToButtonText = {
      EDIT_CONSENT_STATUS: 'Save',
      REVOKE_CONSENT_STATUS: 'Revoke',
      REQUEST_CONSENT: 'Request',
      DECLINE_CONSENT_STATUS: 'Decline',
    };
    const buttonText = mapModalControlToButtonText[modalControl];
    return buttonText;
  }

  public onSave () {
    const mapModalControlToChildForm = {
      EDIT_CONSENT_STATUS: 'editConsentStatus',
      REVOKE_CONSENT_STATUS: 'revokeConsentStatus',
      REQUEST_CONSENT: 'requestConsent',
      DECLINE_CONSENT_STATUS: 'declineConsentStatus',
    };
    const childForm = mapModalControlToChildForm[this.modalControl];
    const payload = this[childForm].getPayload();
    super.close(payload);
  }

  public onCancel () {
    super.close();
  }
}
