import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from } from 'rxjs';
import { catchError, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { ApiService } from 'Src/ng2/shared/services/api-service/api-service';
import * as focusActions from '../../actions/focus-actions/focus-actions';
import { generateQuery } from '../projections/focus';

@Injectable()
export class FocusEffects {
  constructor(private actions$: Actions, private apiService: ApiService, private store$: Store<any>) {}

  loadFocus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<any>(focusActions.LOAD_FOCUS),
      withLatestFrom(this.store$),
      switchMap(([payload]) => {
        const query = generateQuery(payload);
        const queryPayload = {
          query,
          fetchPolicy: 'no-cache',
        };
        return this.apiService.getStudentsGraphQL(queryPayload).pipe(
          mergeMap(({ data: { Focus } }) => [new focusActions.LoadFocusSuccess(Focus)]),
          catchError(error => from([new focusActions.LoadFocusFail(error)])),
        );
      }),
    );
  });
}
