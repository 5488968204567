import { switchMap, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { OtherToolsDataService } from 'Src/ng2/school/school-tools/other-tools/other-tools-data/other-tools-data.service';
import { HUMAN_TO_ROUTE, OtherToolsSideNavConfig } from 'Src/ng2/school/school-tools/other-tools/other-tools.constant';
import { ITool } from 'Src/ng2/shared/typings/interfaces/tool.interface';
import { UrlPathService } from 'Src/ng2/shared/services/url-path-service/url-path.service';

@Injectable()
export class OtherToolsFirstDefaultRouteGuard implements CanActivate {
  constructor (
    private router: Router,
    private otherToolsDataService: OtherToolsDataService,
    private urlPathService: UrlPathService,
  ) {}

  canActivate (routeSnapshot: ActivatedRouteSnapshot): Observable<UrlTree | boolean> {
    const {
      params: { schoolId },
    } = routeSnapshot;
    const tools$ = this.otherToolsDataService.getTools$(schoolId);
    return tools$.pipe(
      switchMap((tools: ITool[]) =>
        this.otherToolsDataService.getSideNav({ OtherToolsSideNavConfig, tools, activatedRoute: routeSnapshot }),
      ),
      switchMap(({ sheetsMenu, toolsMenu }) => {
        const allMenus = [...sheetsMenu, ...toolsMenu];
        const [firstMenu] = allMenus;
        const path = HUMAN_TO_ROUTE[firstMenu?.human];
        const url = path ? this.urlPathService.computeDistrictUrlPath(`/school/${schoolId}/other-tools/${path}`) : 'home';
        const redirect = this.router.createUrlTree([url]);
        return of(redirect);
      }),
      take(1),
    );
  }
}
