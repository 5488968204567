import { EM_DASH } from '../../constants/em-dash.constant';

export const modalOptions = [
  {
    human: 'Regents Exam',
    formattedData: EM_DASH,
    editable: (isRestricted) => !isRestricted,
  },
  {
    human: 'Next Admin Plan',
    formattedData: EM_DASH,
    editable: (isRestricted) => !isRestricted,
  },
];

export const loteLanguageOptionConfig = {
  human: 'Language',
  formattedData: EM_DASH,
  editable: (isRestricted) => !isRestricted,
};
