import { ApiService } from '../../../shared/services/api-service/api-service';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { TValidPartnerTypes } from 'Src/ng2/shared/typings/interfaces/partner.interface';

@Injectable()
export class NetworkSideNavResolver implements Resolve<any> {
  constructor (
    private apiService: ApiService,
  ) {}

  resolve (route: ActivatedRouteSnapshot) {
    const { clusterId } = route.params;
    const contextPartnerType = route.data.partnerType as TValidPartnerTypes;
    return this.apiService.getNetworkSideNavConfig(clusterId, contextPartnerType);
  }
}
