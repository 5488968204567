import { NvPillModule } from './../../../../nvps-libraries/design/nv-pill/nv-pill.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RowgGroupChildCellRendererModule } from './../../components/server-side-grid/row-group-child-cell-renderer/row-group-child-cell-renderer.module';
import { RowgGroupCellRendererModule } from './../../components/server-side-grid/row-group-cell-renderer/row-group-cell-renderer.module';
import { AgGridModule } from '@ag-grid-community/angular';
import { NvMatTooltipModule } from './../../components/nv-mat-tooltip/nv-mat-tooltip.module';
import { ListContainerModule } from '../../components/list/list-container/list-container.module';
import { EditGridColumnsListComponent } from './edit-grid-columns-list/edit-grid-columns-list.component';
import { EditGridColumnsMadlibComponent } from './edit-grid-columns-madlib/edit-grid-columns-madlib.component';
import { NvMultipickerModule } from './../../../../nvps-libraries/design/nv-multi-picker/nv-multi-picker.module';
import { NvDropdownModule } from './../../../../nvps-libraries/design/nv-dropdown/nv-dropdown.module';
import { NvPillButtonModule } from './../../../../nvps-libraries/design/nv-pill-button/nv-pill-button.module';
import { PageHeaderModule } from './../../components/header/page-header/page-header.module';
import { NvButtonModule } from 'projects/shared/nvps-libraries/design/nv-button/nv-button.module';
import { EditGridColumnsModalComponent } from './edit-grid-columns-modal.component';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  declarations: [EditGridColumnsModalComponent, EditGridColumnsMadlibComponent, EditGridColumnsListComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    NvButtonModule,
    PageHeaderModule,
    NvPillButtonModule,
    NvPillModule,
    NvDropdownModule,
    NvMultipickerModule,
    MatMenuModule,
    MatCheckboxModule,
    ListContainerModule,
    NvMatTooltipModule,
    RowgGroupCellRendererModule,
    RowgGroupChildCellRendererModule,
    AgGridModule,
  ],
  exports: [EditGridColumnsModalComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class EditGridColumnsModalModule {}
