<nav-wrapper>
  <header>
    <div class="other-tools-nav-title">
      <nv-icon-button
        name="arrow-left-selected"
        nvMatTooltip="Back"
        nvMatTooltipClass="tooltip"
        (clickButton)="goToMyStudents()"
      ></nv-icon-button>
      <h1>Other Tools</h1>
    </div>
  </header>
  <div class="side-content-wrap">
    <div class="menus">
      <nv-sidebar-list
        *ngIf="internalViewsSheetsMenu.length"
        [title]="sidebarTitleGS"
        [listData]="internalViewsSheetsMenu"
        [selectedKey]="selectedSidebarItem"
        (sidebarListItemSelect)="updateViewTools($event)"
      ></nv-sidebar-list>
      <nv-sidebar-list
        [title]="sidebarTitleTools"
        [listData]="internalViewsToolsMenu"
        [selectedKey]="selectedSidebarItem"
        (sidebarListItemSelect)="updateViewTools($event)"
      ></nv-sidebar-list>
      <nav-content class="nv-sidebar-nav-menu-small">
        <item-list class="nv-sidebar-list-item nv-sidebar-list-item-small" *ngFor="let option of userMenuOptions">
          <label for="goToMenuSelection" (click)="goToState(option)"> {{ option.humanName }} </label>
        </item-list>
        <item-list class="nv-sidebar-list-item nv-sidebar-list-item-small"><nvps-log-out portalType="SCHOOL"></nvps-log-out></item-list>
      </nav-content>
    </div>
  </div>
</nav-wrapper>
