import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { BaseModalModule } from '../base-modal.module';
import { ListSummaryModalComponent } from './list-summary-modal.component';

@NgModule({
  declarations: [ListSummaryModalComponent],
  imports: [CommonModule, MatDialogModule, BaseModalModule, MatPaginatorModule, MatTableModule],
  exports: [ListSummaryModalComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ListSummaryModalModule {}
