import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { BaseModalComponent } from '../base-modal.component';
import { IBaseModalData } from '../modals.service';
import { getCurrentUser, BulkUpdateStudents, IBulkUpdateStudentsPayload } from 'Src/ng2/store';
import { IDropdownOption } from '../../../../../projects/shared/nvps-libraries/design/interfaces/design-library.interface';
import { loteLanguageOptionConfig, modalOptions } from './regents-plan-modal.config';
import { IUser } from '../../typings/interfaces/user.interface';
import { ImUser } from '../../services/im-models/im-user';
import { ISchool } from '../../typings/interfaces/school.interface';
import { RegentsAdminHelper, RegentsPathMap, RegentsPlans } from '../../constants/regents-plans.constant';
import { EM_DASH } from '../../constants/em-dash.constant';
import { TBatchActionsOrigin } from '../../components/nv-actions/nv-actions.interface';
import { LoteExamNames } from '../../constants/lote-exam-names.constant';

export interface IRegentsPanelModalComponentData extends IBaseModalData {
  school: { _id: string; district: string };
  isProfileMode?: boolean;
  studentIds: string[];
  origin?: TBatchActionsOrigin;
  isRestricted: boolean,
}

@Component({
  selector: 'regents-plan-modal',
  templateUrl: './regents-plan-modal.component.html',
  styleUrls: ['./regents-plan-modal.component.scss'],
})

export class RegentsPlanModalComponent extends BaseModalComponent implements OnInit, OnDestroy {
  public iconName: string = 'close-large-blue';
  public currentUser: IUser;
  public saveButtonDisabled = true;

  // props for base modal
  public itemCount: number;
  public itemType: string;

  // Modal Configurations
  public modalOptions;
  public modalOptionsConfig = modalOptions;
  public schoolId: string;
  public school: ISchool;
  public district: string;
  public studentIds: string[];
  public isRestricted: boolean;

  public regentsPlanForm : FormGroup;

  public title = 'Assign Regents Plan';
  private origin: TBatchActionsOrigin;
  private regentsExamOption: string;
  private adminPlanOption: string;
  private loteLanguageOption: string;
  constructor (
    @Inject(MAT_DIALOG_DATA) public data: IRegentsPanelModalComponentData,
    dialogRef: MatDialogRef<RegentsPlanModalComponent>,
    private cdr: ChangeDetectorRef,
    private store: Store<any>,
    public formBuilder: FormBuilder,
    private imUser: ImUser,
  ) {
    super(dialogRef);
  }

  public ngOnInit (): void {
    const { isProfileMode, school: { _id: schoolId, district }, studentIds, isRestricted, origin } = cloneDeep(this.data);

    this.titleTooltipInfo = 'The Portal highlights students with Regents scheduling needs who will end the upcoming Regents administration behind in Regents if their schedules are not adjusted (See Regents Scheduling Status column). Use this modal to add Regents plans to address students\' Regents scheduling needs.';
    this.itemCount = studentIds.length;
    this.itemType = 'student';
    this.isProfileMode = isProfileMode;
    this.schoolId = schoolId;
    this.district = district;
    this.origin = origin;
    this.studentIds = studentIds;
    this.isRestricted = isRestricted;

    this.regentsPlanForm = new FormGroup({});

    this.renderOptions();
    this.store.select(getCurrentUser).subscribe(user => {
      this.currentUser = user;
    });
  }

  ngAfterViewChecked (): void {
    this.cdr.detectChanges();
  }

  renderOptions () {
    const regentsPathOptions = Object.entries(RegentsPathMap).map((regentsExam: any[]) => ({ human: regentsExam[1][0], key: regentsExam[1][1] }));
    const validRegentsPlansOptions = [
      { key: null, human: EM_DASH },
      ...Object.entries(RegentsPlans).map(([key, human]) => ({ key, human })),
    ];
    const languageLoteOptions = LoteExamNames.map((language) => ({ human: language, key: language }));

    const optionsMap = {
      'Regents Exam': regentsPathOptions,
      Language: languageLoteOptions,
      'Next Admin Plan': validRegentsPlansOptions,
    };

    this.modalOptions = this.modalOptionsConfig.map(({ human, formattedData, editable }) => {
      return {
        human,
        formattedData,
        options: optionsMap[human],
        editable: editable(this.isRestricted),
      };
    });
  }

  public onCancel (): void {
    this.dialogRef.close();
  }

  onChangeHandler (field: string, option: IDropdownOption) {
    if (field === 'Regents Exam') {
      this.regentsExamOption = option?.key;
      this._updateModalOptions();
    } else if (field === 'Next Admin Plan') {
      this.adminPlanOption = option?.human;
    } else if (field === 'Language') {
      this.loteLanguageOption = option.key;
    }

    this.saveButtonDisabled = this._isSaveButtonDisabled();
  }

  public onSave (): void {
    const patch = {
      path: null,
      newValue: null,
      dataType: 'REGENTS_ADMIN',
    };

    patch.path = this.regentsExamOption;
    const dateValue = this.adminPlanOption === EM_DASH ? null : this.adminPlanOption;
    patch.newValue = RegentsAdminHelper.getAdminDatePatch(dateValue);

    // Add language option if available
    if (this.loteLanguageOption) {
      patch.newValue = {
        ...patch.newValue,
        examLanguage: this.loteLanguageOption,
      };
    }

    const payload: IBulkUpdateStudentsPayload = {
      patches: {
        _ids: this.studentIds,
        path: patch.path,
        newValue: patch.newValue,
      },
      origin: this.origin,
    };

    if (this.studentIds.length > 0) {
      this.store.dispatch(new BulkUpdateStudents(payload));
    }
    this.dialogRef?.close('saved');
  }

  private _updateModalOptions () {
    if (this.regentsExamOption === 'nextScheduledRegents.lote') {
      const { human, formattedData, editable } = loteLanguageOptionConfig;
      const languageOption = {
        human,
        formattedData,
        options: LoteExamNames.map((language) => ({ human: language, key: language })),
        editable: editable(this.isRestricted),
      };
      // Ensure 'Language' option is added as the second option
      this.modalOptions.splice(1, 0, languageOption);
    } else {
      // Find the index of the 'Language' option in modalOptions
      const languageOptionIndex = this.modalOptions.findIndex(option => option.human === 'Language');
      // If 'Language' option exists, remove it from modalOptions
      if (languageOptionIndex !== -1) this.modalOptions.splice(languageOptionIndex, 1);
    }
  }

  private _isSaveButtonDisabled (): boolean {
    if (this.modalOptions.some(option => option.human === 'Language')) {
      // If 'Language' option is available, disable saveButtonDisabled if a language is not selected
      return !this.regentsExamOption || !this.adminPlanOption || !this.loteLanguageOption;
    } else {
      // Only check for Regents Exam and Next Admin Plan fields
      return !this.regentsExamOption || !this.adminPlanOption;
    }
  }
}
