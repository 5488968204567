import {
  Component,
  ComponentFactoryResolver,
  Input,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import * as __moment from 'moment';
import { NvDatePickerCalendarComponent } from './nv-date-picker-calendar/nv-date-picker-calendar.component';
import { NvDatePickerHeaderComponent } from './nv-date-picker-header/nv-date-picker-header.component';
const moment = __moment;

export const PICKER_DATE_FORMATS = {
  parse: {
    dateInput: { month: 'short', day: 'numeric' },
  },
  display: {
    dateInput: { month: 'short', day: 'numeric' },
    monthYearLabel: { month: 'short', year: 'numeric' },
  },
};

/**
 *
 * The date picker component is a trigger for a Material Datepicker,
 * styled like other inputs in the app.
 *
 * Use the date picker to allow the user to input dates in a form.
 *
 * Restrict start and end dates with the `startLimit` and `endLimit` props.
 *
 */
@Component({
  selector: 'nv-date-picker',
  templateUrl: './nv-date-picker.component.html',
  styleUrls: ['./nv-date-picker.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MAT_DATE_FORMATS,
      useValue: PICKER_DATE_FORMATS,
    },
  ],
})
export class NvDatePickerComponent {
  // TODO: consider replacing ngOnChanges with start and end limit to make use of observables (JE)
  // TODO: Add `hasClearButton` prop (AT)

  /**
   *
   * Use a FormControl to keep track of the selected date. Use Validators to control the input values, or mark a value as `required`
   * @required
   */
  @Input() dateControl: FormControl;

  /**
   *
   * The earliest date selectable. `YYYY-MM-DD` format
   */
  @Input() startLimit: string; // YYYY-MM-DD

  /**
   *
   * The latest date selectable. `YYYY-MM-DD` format
   */
  @Input() endLimit: string; // YYYY-MM-DD

  /**
   *
   * The placeholder text when the picker is empty
   */
  @Input() placeholder: string = 'Date';

  /**
   *
   * Determines whether the picker should be solid blue, or light blue.
   * Recommended to use `isInverted` if using a `Validator.required` on the form control
   */
  @Input() isInverted: boolean = true;

  /**
   *
   * disables the mat-calendar
   */
  @Input() isDisabled: boolean = false;

  @ViewChild('calendarTemplate', { read: ViewContainerRef, static: true }) calendarTemplate;

  public isValid: boolean = true;
  public nvDatePickerHeader = NvDatePickerHeaderComponent;
  public hasBeenOpened: boolean = false;
  public calendarComponentRef;

  constructor (private resolver: ComponentFactoryResolver) {}

  get dateLabel () {
    return !!this.dateControl && !!this.dateControl.value
      ? moment(this.dateControl.value).format('MMM D')
      : this.placeholder;
  }

  ngOnChanges (changes) {
    const { startLimit, endLimit, dateControl } = changes;
    const start = startLimit ? startLimit.currentValue : null;
    const end = endLimit ? endLimit.currentValue : null;
    if (startLimit) this.startLimit = startLimit.currentValue;
    if (endLimit) this.endLimit = endLimit.currentValue;
    // update limits and dateControl in existing component if it has already been instantiated
    if (this.hasBeenOpened) {
      if (dateControl) {
        this.calendarComponentRef.instance.dateControl = dateControl.currentValue;
      }
      this.calendarComponentRef.instance.setMinMax(start, end);
    }
  }

  // The first time we try to open the calendar,
  // we dynamically generate the calendar component, which opens itself AfterViewInit.
  // Subsequently, we open it directly with openCalendar
  openCalendar () {
    if (!this.isDisabled) {
      if (this.hasBeenOpened) {
        this.calendarComponentRef.instance.openCalendar();
      } else {
        this.hasBeenOpened = true;
        this.calendarTemplate.clear();
        const factory = this.resolver.resolveComponentFactory(NvDatePickerCalendarComponent);
        this.calendarComponentRef = this.calendarTemplate.createComponent(factory);
        this.calendarComponentRef.instance.dateControl = this.dateControl;
        this.calendarComponentRef.instance.startLimit = this.startLimit;
        this.calendarComponentRef.instance.endLimit = this.endLimit;
      }
    }
  }
}
