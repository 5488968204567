import { FormGroup } from '@angular/forms';
import { IPathSubdoc } from './../../../typings/interfaces/studentPaths.interface';

/**
 * This function walks a form and creates an object of key/value pairs where each form field
 * maps to the value it holds on the state of the form. This function is meant to be used
 * by children of BaseStudentPathModalForm, so as to prevent the parent from dictating the
 * shape of - and thereby taking responsibility for - the studentPath subdoc that is bubbled up
 * to StudentPathModalComponent.
 * @param form
 * @returns an object of type IPathSubdoc derived from the form that is passed in.
 */
export const formToStudentPathSubdoc = (form: FormGroup): IPathSubdoc => {
  return Object.entries(form.controls)
    .reduce(
      (acc, [fieldKey, fieldControl]) => {
        if (fieldControl.value) {
          acc[fieldKey] = fieldControl.value;
        }
        return acc;
      }, {} as IPathSubdoc,
    );
};
