import { IListViewData } from '../../shared/typings/interfaces/list-view.interface';
import * as csvDataActions from '../actions/csv-data-actions';

export interface ICsvDataState {
  loading: boolean;
  loaded: boolean;
  listData: any;
  madLibSelections: {
    Dimension1: string;
    Dimension2: string;
    Groupings: string;
  };
}

export const initialState: ICsvDataState = {
  loading: false,
  loaded: false,
  listData: {} as IListViewData,
  madLibSelections: {
    Dimension1: null,
    Dimension2: null,
    Groupings: null,
  },
};

export function reducer (state: ICsvDataState = initialState, action: csvDataActions.CsvDataAction): ICsvDataState {
  switch (action.type) {
    case csvDataActions.UPDATE_CSV_DATA: {
      const { data, madLibSelections } = action.payload;
      return {
        loaded: true,
        loading: false,
        listData: data,
        madLibSelections,
      };
    }

    default:
      return state;
  }
}
