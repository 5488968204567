<ng-template #calendarTemplate></ng-template>
<nv-pill-button
  [icon]="isInverted ? 'calendar-small-blue' : 'calendar-small-white'"
  [text]="dateLabel"
  [isInverted]="isInverted"
  [isDisabled]="isDisabled"
  [color]="dateControl.valid ? 'blue' : 'red'"
  [textIsPlaceholder]="!!!dateControl.value"
  (clickButton)="openCalendar()"
></nv-pill-button>
