import { RollbarService } from './../rollbar/rollbar.service';
import { Inject, Injectable } from '@angular/core';
import { get, reduce } from 'lodash';
import { IStudent } from '../../typings/interfaces/student.interface';
import * as Rollbar from 'rollbar';

export interface ICollectionToMerge {
  collection: any[];
  keyToAddToStudent: string;
  pathToStudentId: string;
}
export interface ICollectionHash {
  keyToAddToStudent: string;
  studentIdsWithItems: {
    [_id: string]: any[];
  };
}

@Injectable()
export class StudentCollectionsMergeService {
  constructor (@Inject(RollbarService) private rollbar: Rollbar) {
    /***/
  }

  private generateCollectionHashes (collections: ICollectionToMerge[]): ICollectionHash[] {
    return reduce(
      collections,
      (hashes: ICollectionHash[], collectionObj: ICollectionToMerge) => {
        const { collection, keyToAddToStudent, pathToStudentId } = collectionObj;
        const studentIdsWithItems = reduce(
          collection,
          (result, item) => {
            const studentId = get<any, string>(item, pathToStudentId);
            result[studentId] ? result[studentId].push(item) : (result[studentId] = [item]);
            return result;
          },
          {},
        );
        const hashObj = { keyToAddToStudent, studentIdsWithItems };
        hashes.push(hashObj);
        return hashes;
      },
      [],
    );
  }

  private getStudentsWithMergedCollections (
    hashes: ICollectionHash[],
    students: IStudent[],
    omitStudentIfMissing: String[],
  ) {
    const updatedStudents = [];
    return reduce(
      hashes,
      (updatedStudents, hash: ICollectionHash) => {
        const { keyToAddToStudent, studentIdsWithItems } = hash;
        if (updatedStudents.length > 0) students = updatedStudents;
        return reduce(
          students,
          (result, student: IStudent) => {
            const studentId = student._id;
            const items = studentIdsWithItems[studentId] || [];

            // We omit the student from the list if we explicitely require docs from another collection for that student are missing
            // This will prevent breaking the the post sec list if there is bad data (Jack)
            if (omitStudentIfMissing.includes(keyToAddToStudent) && !items.length) {
              this.rollbar.error(
                `mergeCollectionsToStudents: missing ${keyToAddToStudent} for some students}`,
              );
              return result;
            } else {
              student[keyToAddToStudent] = items;
              result.push(student);
              return result;
            }
          },
          [],
        );
      },
      updatedStudents,
    );
  }

  mergeCollectionsToStudents ({
    students,
    collections,
    omitStudentIfMissing = [],
  }: {
    students: IStudent[];
    collections: ICollectionToMerge[];
    omitStudentIfMissing?: String[];
  }): any[] {
    const hashes: ICollectionHash[] = this.generateCollectionHashes(collections);
    const studentsWithMergedCollections = this.getStudentsWithMergedCollections(hashes, students, omitStudentIfMissing);
    return studentsWithMergedCollections;
  }
}
