import { reduce } from 'lodash';
import { IDataLoad } from '../../shared/typings/interfaces/data-load.interface';
import * as dataLoadsActions from '../actions/data-loads-actions';

export interface IDataLoadsState {
  loading: boolean;
  loaded: boolean;
  dataLoadsEntities: {
    [id: string]: IDataLoad;
  };
}

export const initialState: IDataLoadsState = {
  loading: false,
  loaded: false,
  dataLoadsEntities: {},
};

export function reducer(
  state: IDataLoadsState = initialState,
  action: dataLoadsActions.DataLoadsAction,
): IDataLoadsState {
  let dataLoadsEntities;
  switch (action.type) {
    case dataLoadsActions.LOAD_DATA_LOADS:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case dataLoadsActions.LOAD_DATA_LOADS_SUCCESS:
      const { DataLoads } = action.payload.data;
      const loadedDataLoads: IDataLoadsState['dataLoadsEntities'] = reduce(
        DataLoads,
        (acc, dataLoad: IDataLoad) => {
          acc[dataLoad._id] = dataLoad;
          return acc;
        },
        {},
      );
      const mergedDataLoads = { ...state.dataLoadsEntities, ...loadedDataLoads };
      return {
        ...state,
        loading: false,
        loaded: true,
        dataLoadsEntities: mergedDataLoads,
      };
    case dataLoadsActions.LOAD_DATA_LOADS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case dataLoadsActions.CREATE_DATA_LOAD_SUCCESS:
      const created = action.payload.dataLoad;
      dataLoadsEntities = Object.assign({}, state.dataLoadsEntities);
      dataLoadsEntities[created._id] = created;
      return {
        ...state,
        dataLoadsEntities,
      };
    default:
      return state;
  }
}
