import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { uniq, map, reduce, isArray } from 'lodash';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { StudentSet } from 'Src/ng2/shared/services/student-set/student-set.service';

@Component({
  selector: 'student-list-preview-v2',
  templateUrl: './student-list-preview-v2.component.html',
  styleUrls: ['./student-list-preview-v2.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StudentListPreviewV2 {
  public students;
  public columnDefs;
  public humanColumnNames;
  public sortType;
  public sortReverse;
  public table;

  constructor (
    private StudentSet: StudentSet,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {}

  ngOnInit () {
    const sortedColumn = 'studentName';
    const sortedColumnIndex = 1;
    this.students = this.data.students;
    this.columnDefs = this.data.columnDefs;
    this.sortType = sortedColumn;
    this.sortReverse = true;
    this.humanColumnNames = this.getHumanReadableColumns(this.columnDefs);
    this.sortTable(sortedColumnIndex, this.buildPreviewTable(), this.sortReverse);
  }

  /**
   * @param columnKeys an array of sorterColumn keys
   * @returns an array of object containing the sorterColumn name: humanReadable column name
   */
  getHumanReadableColumns (columnKeys: string[]): any[] {
    const keysPlusDefaults = uniq(['studentId', 'studentName', ...columnKeys]);
    const columnDefinitions = this.StudentSet.getColumnDefs(keysPlusDefaults);
    const humanColumnNames = map(columnDefinitions, (col: any, index: number) => {
      return {
        path: keysPlusDefaults[index],
        human: col.headerName,
        cellRenderer: col.cellRenderer,
        index,
      };
    });
    return humanColumnNames;
  }

  buildPreviewTable () {
    return reduce(
      this.students,
      (table, student) => {
        const { coursesPotentialCreditIssues } = student;
        const row = this.humanColumnNames.map((col: any) => {
          let cellValue = student[col.path];
          if (isArray(cellValue)) cellValue = cellValue.join(', ');
          return this.renderCellVal(col, cellValue, { coursesPotentialCreditIssues });
        });
        table.push(row);
        return table;
      },
      [],
    );
  }

  sortTable (index, table, sortReverse) {
    const sortedTable = table.sort((a, b) => {
      if (sortReverse) {
        if (a[index] < b[index]) return -1;
        if (a[index] > b[index]) return 1;
        return 0;
      } else {
        if (a[index] > b[index]) return -1;
        if (a[index] < b[index]) return 1;
      }
    });
    this.table = sortedTable;
  }

  // Add more rules down the road if any bool value needs to be reformed into a more readable name (JChu)
  // To get some info on cellVal datatype, please refer to sorter-column-data-type.constant.ts
  renderCellVal ({ key, cellRenderer }: { key: string, cellRenderer: Function }, cellVal: any, args?: any) {
    switch (key) {
      case 'hasPotentialCreditIssuesOnCurrProgram': {
        const { coursesPotentialCreditIssues } = args;
        return cellVal === true && coursesPotentialCreditIssues
          ? map(coursesPotentialCreditIssues, 'courseName').join()
          : '';
      }
      case 'schoolVerifiedSafetyNetEligibility':
      case 'isSafetyNetEligible':
        return cellVal === true ? 'Eligible' : cellVal === false ? 'Not Eligible' : '-';
      case 'iep':
        return cellVal === true ? 'Yes' : cellVal === false ? 'No' : '-';
      case 'potentiallyEligibleForSafetyNet':
        return !cellVal ? '-' : cellVal;
      default: {
        // When cellVal is a string, its accompanying cellRenderer expects cellVal to be an array
        const isString = typeof cellVal === 'string';
        const formatStringToArray = (cellVal) => cellVal ? [cellVal] : [];
        if (isString) cellVal = formatStringToArray(cellVal);
        return cellRenderer ? cellRenderer({ value: cellVal }) : cellVal;
      }
    }
  }
}
