import { difference } from 'lodash';
import { TValidSdcColumns } from './../../sdc-columns.type';
import { DEFAULT_SORT } from './../default-sort.constant';
import { sdcStateConfigConstant } from './../sdc-state-config.constant';

export class DefaultSdcConfigService {
  get humanName () {
    return sdcStateConfigConstant.DEFAULT_SDC.humanName;
  }

  get state () {
    return sdcStateConfigConstant.DEFAULT_SDC.state;
  }

  get gridColumns () {
    return difference(this.columns, this.hiddenColumns);
  }

  get columns (): TValidSdcColumns[] {
    return [
      'studentId',
      'studentName',
      'cohort',
      'status',
      'grade',
      // Hidden as a part of PFD-11066, left as a comment in the case we need to unhide
      // 'learningPreference',
      'officialClass',

      // google classroom
      'classroomRemoteEngagementLast5',
      'classroomRemoteEngagement',
      'classroomTotalAssignments',
      'classroomTotalEngagedAssignments',
      'classroomTotalEnrolled',
      'classroomStudentEmail',
      'daysEngagedWithGoogleMeetOverLast5Days',
      'percentOfDaysEngagedWithGoogleMeet',
      'meetDevicesUsed',

      'dob',
      'sendingSchool',
      'admitDate',
      'dischargeCode',
      'dischargeDate',
      'gender',
      'ethnicity',
      'citywideLowestThird',

      'seniorFlag',
      'ageOn1231OfCurrentSy',
      'yearOfEntry',
      'countOfAdmitsToCurrentSchool',
      'overageUnderCredited',
      'mostAtRisk',

      'iep',
      'spedProgram',
      'potentiallyEligibleForSafetyNet',
      'schoolVerifiedSafetyNetEligibility',
      'isSafetyNetEligible',
      'sife',
      'ell',
      'homeLanguage',
      'lepStatus',

      'plannedDiplomaType',
      'plannedGraduationDate',
      'gradPlan',
      'creditsBehind',
      'regentsBehind',
      'distanceFromGraduation',

      'elaPr8thGrade',
      'mathPr8thGrade',

      'transitDistanceMiles',
      'transitTimeMinutes',
      'attendancePriorSy',
      'attendancePreviousSchoolDay',
      'attendanceYtdFilter',
      'attendanceYtd',
      'inPersonYTDAttendance',
      'remoteYTDAttendance',
      'attendanceYtdLastYear',
      'ytdAttendanceChange',
      'attendanceLast20',
      'inPersonAttendanceLast20Days',
      'remoteAttendanceLast20Days',
      'attendanceLast20MinusYtdPct',
      'numberOfDaysAbsentYtd',
      'countOfInPersonDaysAbsentYTD',
      'countOfInRemoteDaysAbsentYTD',
      'numberOfDaysAbsentLast5Days',
      'countOfInPersonDaysAbsentLast5Days',
      'countOfRemoteDaysAbsentLast5Days',
      'countOfDaysAbsentLast10Days',
      'countOfInPersonDaysAbsentLast10Days',
      'countOfRemoteDaysAbsentLast10Days',
      'numberOfDaysAbsentLast20Days',
      'countOfInPersonDaysAbsentLast20Days',
      'countOfRemoteDaysAbsentLast20Days',
      'countOfDaysAbsentThisTerm',
      'countOfInPersonDaysAbsentThisTerm',
      'countOfRemoteDaysAbsentThisTerm',
      'percentDaysLateYtd',
      'percentDaysLatePriorSy',
      'sepAttendance',
      'octAttendance',
      'novAttendance',
      'decAttendance',
      'janAttendance',
      'febAttendance',
      'marAttendance',
      'aprAttendance',
      'mayAttendance',
      'junAttendance',
      'countOfCurrentlyActiveAttendanceSupports',
      'currentlyActiveAttendanceSupports',

      'currentCourses',
      'currentCourseCodes',
      'countOfCoursesFailingMostRecentMp',
      'countOfHighPriorityCoursesFailingMostRecentMp',

      'totalCreditsAttempted',
      'totalCreditsEarned',
      'totalCreditsSched',
      'avg8thGradeGpa',
      'transcriptGradeAverage',
      'creditsEarnedPriorToCurrentSy',
      'totalCreditsEarnedToDateInCurrentSy',
      'countOfFailedCoursesInCurrentSy',
      'countOfCurrentlyActiveAcademicSupports',
      'currentlyActiveAcademicSupports',

      'countOfRegentsPassedForDiploma',
      'regentsPassedForDiploma',
      'countOfRegentsNeededForOnTrackDiploma',
      'regentsNeededForOnTrackDiploma',
      'regentsNeededForCollegeReadiness',
      'countOfRegentsPassedForGrad',
      'countOfRegentsPassedAt65Of5',
      'countOfRegentsPassedAt55Of5SpedOnly',
      'countOfRegentsPassedAt65Of9',
      'countOfRegentsPassedOf5PriorToCurrentSy',
      'countOfCurrentlyActiveRegentsPrepSupports',
      'currentlyActiveRegentsPrepSupports',

      'collegeReadyOnMathRegentsOrSatMath',
      'collegeReadyOnElaRegentsOrSatCr',

      'countOfCurrentlyActiveSupports',
      'currentlyActiveSupports',

      'atsCounselor',
      'atsCounselorEmail',
      'guidanceCounselor',
      'advisor',

      // added for community schools on 3/22
      'schoolPriorityFlag',

      // MAP Growth Language Use
      'mapGrowthLanguageUseScoreDateFall',
      'mapGrowthLanguageUseRitScoreFall',
      'mapGrowthLanguageUsePercentileFall',
      'mapGrowthLanguageUseAchievementQuintileFall',
      'mapGrowthLanguageUseScoreDateWinter',
      'mapGrowthLanguageUseRitScoreWinter',
      'mapGrowthLanguageUsePercentileWinter',
      'mapGrowthLanguageUseAchievementQuintileWinter',
      'mapGrowthLanguageUseScoreDateSpring',
      'mapGrowthLanguageUseRitScoreSpring',
      'mapGrowthLanguageUsePercentileSpring',
      'mapGrowthLanguageUseAchievementQuintileSpring',
      // MAP Growth Reading
      'mapGrowthReadingScoreDateFall',
      'mapGrowthReadingRitScoreFall',
      'mapGrowthReadingPercentileFall',
      'mapGrowthReadingAchievementQuintileFall',
      'mapGrowthReadingScoreDateWinter',
      'mapGrowthReadingRitScoreWinter',
      'mapGrowthReadingPercentileWinter',
      'mapGrowthReadingAchievementQuintileWinter',
      'mapGrowthReadingScoreDateSpring',
      'mapGrowthReadingRitScoreSpring',
      'mapGrowthReadingPercentileSpring',
      'mapGrowthReadingAchievementQuintileSpring',
      // MAP Growth Math
      'mapGrowthMathScoreDateFall',
      'mapGrowthMathRitScoreFall',
      'mapGrowthMathPercentileFall',
      'mapGrowthMathAchievementQuintileFall',
      'mapGrowthMathScoreDateWinter',
      'mapGrowthMathRitScoreWinter',
      'mapGrowthMathPercentileWinter',
      'mapGrowthMathAchievementQuintileWinter',
      'mapGrowthMathScoreDateSpring',
      'mapGrowthMathRitScoreSpring',
      'mapGrowthMathPercentileSpring',
      'mapGrowthMathAchievementQuintileSpring',
      // MAP Growth Algebra 1
      'mapGrowthAlgebra1ScoreDateFall',
      'mapGrowthAlgebra1RitScoreFall',
      'mapGrowthAlgebra1PercentileFall',
      'mapGrowthAlgebra1AchievementQuintileFall',
      'mapGrowthAlgebra1ScoreDateWinter',
      'mapGrowthAlgebra1RitScoreWinter',
      'mapGrowthAlgebra1PercentileWinter',
      'mapGrowthAlgebra1AchievementQuintileWinter',
      'mapGrowthAlgebra1ScoreDateSpring',
      'mapGrowthAlgebra1RitScoreSpring',
      'mapGrowthAlgebra1PercentileSpring',
      'mapGrowthAlgebra1AchievementQuintileSpring',
      // MAP Growth Algebra 2
      'mapGrowthAlgebra2ScoreDateFall',
      'mapGrowthAlgebra2RitScoreFall',
      'mapGrowthAlgebra2PercentileFall',
      'mapGrowthAlgebra2AchievementQuintileFall',
      'mapGrowthAlgebra2ScoreDateWinter',
      'mapGrowthAlgebra2RitScoreWinter',
      'mapGrowthAlgebra2PercentileWinter',
      'mapGrowthAlgebra2AchievementQuintileWinter',
      'mapGrowthAlgebra2ScoreDateSpring',
      'mapGrowthAlgebra2RitScoreSpring',
      'mapGrowthAlgebra2PercentileSpring',
      'mapGrowthAlgebra2AchievementQuintileSpring',
      // MAP Growth Geometry
      'mapGrowthGeometryScoreDateFall',
      'mapGrowthGeometryRitScoreFall',
      'mapGrowthGeometryPercentileFall',
      'mapGrowthGeometryAchievementQuintileFall',
      'mapGrowthGeometryScoreDateWinter',
      'mapGrowthGeometryRitScoreWinter',
      'mapGrowthGeometryPercentileWinter',
      'mapGrowthGeometryAchievementQuintileWinter',
      'mapGrowthGeometryScoreDateSpring',
      'mapGrowthGeometryRitScoreSpring',
      'mapGrowthGeometryPercentileSpring',
      'mapGrowthGeometryAchievementQuintileSpring',
      // MAP Growth Lexile
      'mapGrowthMaxScoreLexileFall',
      'mapGrowthMaxDateLexileFall',
      'mapGrowthMaxScoreLexileWinter',
      'mapGrowthMaxDateLexileWinter',
      'mapGrowthMaxScoreLexileSpring',
      'mapGrowthMaxDateLexileSpring',
      // DESSA
      'dessaPreAssessmentRatingDate',
      'dessaPostAssessmentRatingDate',
      'dessaPreAssessmentDescriptiveRange',
      'dessaPostAssessmentDescriptiveRange',
      'dessaPreAssessmentSecTScore',
      'dessaPostAssessmentSecTScore',
    ];
  }

  get hiddenColumns (): TValidSdcColumns[] {
    return [];
  }

  get gridViews () {
    return {};
  }

  get filter () {
    return {
      status: { values: ['A'] },
    };
  }

  get sort () {
    return DEFAULT_SORT;
  }

  get availableSdcViews () {
    return sdcStateConfigConstant.DEFAULT_SDC.availableSdcViews;
  }
}
