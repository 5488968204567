import { RegentsExam, IRegentsExam } from '../../../../../../shared/constants/regents.constant';
import { IPatchEvent, WizardHelpers } from '../../../../services/wizard-helpers.service';
import { Component, Input, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { each, reduce } from 'lodash';

interface IPastDueExamsByExam {
  [key: string]: {
    _ids: string[];
    studentIds: string[];
  };
}

@Component({
  selector: 'plan-past-due-exams-step',
  templateUrl: './plan-past-due-exams-step.component.html',
  styleUrls: ['../shared-step-styles.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class PlanPastDueExamsStep implements OnInit {
  @Input() filteredStudents;
  @Output() studentPatch: EventEmitter<any> = new EventEmitter();
  public stepLabel: string = 'Plan Regents exams for students who have past due exams';
  public pastDueStudentExamsByExamName: IPastDueExamsByExam;
  public totalPastDueExams: number;
  public patches
  public languageHelper;
  public options;

  constructor (
    private wizardHelpers: WizardHelpers,
  ) { }

  ngOnInit (): void {
    this.pastDueStudentExamsByExamName = this.getStudentsPastExamsByExamName();
    this.totalPastDueExams = this.wizardHelpers.getCountOfStudentsToBePatched(this.pastDueStudentExamsByExamName);
    this.patches = this.wizardHelpers.getRegentsExamBulkPatch(this.pastDueStudentExamsByExamName, 'shortName');
    this.languageHelper = this.wizardHelpers.getRegentsWizardLanguageHelper('key');
    this.options = {
      columnKeys: ['cohort', 'pastDueRegentsExams', 'regentsSchedForNextAdmin'],
    };
  }

  ngOnChanges ({ filteredStudents }) {
    if (filteredStudents && !filteredStudents.isFirstChange()) {
      this.ngOnInit();
    }
  }

  private getStudentsPastExamsByExamName (): IPastDueExamsByExam {
    // generate baseObj
    const baseObj = reduce(
      RegentsExam,
      (result: any, { shortName }: IRegentsExam) => {
        result[shortName] = { _ids: [], studentIds: [] };
        return result;
      },
      {},
    );
    // populate
    return reduce(
      this.filteredStudents,
      (pastDueExamsByExamName, { _id, studentId, pastDueUnplannedRegentsExams }) => {
        each(pastDueUnplannedRegentsExams, examShortName => {
          baseObj[examShortName]._ids.push(_id);
          baseObj[examShortName].studentIds.push(studentId);
        });
        return baseObj;
      },
      baseObj,
    );
  }

  patchStudents (patches, count) {
    const patchEvent: IPatchEvent = {
      message: `This will bulk schedule ${count} exams`,
      patches,
    };
    this.studentPatch.emit(patchEvent);
  }
}
