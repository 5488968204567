/* tslint:disable */
import { Action } from '@ngrx/store';
export const SET_NETWORK_FOCI_GRID_STATE = '[Portal] Set Network Foci Grid State';

export class SetNetworkFociGridState implements Action {
  readonly type = SET_NETWORK_FOCI_GRID_STATE;
  constructor (public payload: any) {}
}

export type NetworkFociGridAction = SetNetworkFociGridState;
