export class RegularExpressionsUtility {
  // matches the following in a string: (CM)
  // 12345
  // .12345
  // 12345.12345
  // 0.12345
  // but not 1.1.1
  static matchDecimalChars(string: string) {
    return string.match(/\d*\.*\d+/);
  }

  // matches the following in a string: (CM)
  // '<='
  // '>='
  // '<'
  // '>'
  static matchComparisonOperatorChars(string: string) {
    return string.match(/<=|>=|<|>/);
  }

  // Matches normal people first name and last name
  // no special characters like @#$%^&*+=!?[]{}()\/_<>...
  // no number
  // can contain - and '
  // begin with a word, or -, or '
  // at least one character length
  // get more explanation on https://regexr.com/
  static peopleNameRegexCtr() {
    return new RegExp(/^[\w'\-][^0-9_!¡?÷?¿/\\+=@"#$%ˆ&*(){}|~<>;:[\]]{0,}$/, 'gi');
  }

  // Matches both real and demo school id in single school route e.g. /school/13W320/, etc
  static schoolIdRouteRegexCtr() {
    return new RegExp(/\/school\/[A-Z0-9]{6}\//, 'g');
  }

  // Matches both real and demo shelter id in single shelter route e.g. /shelter/CQ01/, /shelter/shelter26/, /shelter/demoShelterSmall/, etc
  static shelterIdRouteRegexCtr() {
    return new RegExp(
      /\/shelter\/([A-Z]{2}[0-9]{2}|shelter[0-9]{1,2}|demoShelterSmall|demoShelterMedium|demoShelterLarge)\//,
      'g',
    );
  }

   // Matches email id with domain
  static emailIdWithDomainRegexCtr() {
    return new RegExp(this.emailIdWithDomainRegex(), 'g');
  }

   // Matches email id with domain
   // pattern expected <chars>@<chars>.<chars> 
  static emailIdWithDomainRegex() {
    return /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(\.(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?){2,}){1,2}$/;
  }

   // Matches phone number 
   // pattern: (000) 000-0000, 000-000-0000, (000)000-0000,000 000-0000 0000000000
  static phoneNumberRegexCtr() {
    return new RegExp(/^[(]*[0-9]{3}[)-]*[ ]*[0-9]{3}[- ]*[0-9]{4}$/,'g');
  }

  // Grid view name validation
  // no special characters like #$%^&*+=!?[]{}\/_<>...
  // can contain - @ ( ) _ . /
  // can contain letters and numbers and spaces
  // get more explanation on https://regexr.com/
  static gridViewNameRegexCtr () {
    // eslint-disable-next-line
    return new RegExp(/^[0-9A-Za-z_ .()@/-]+$/, 'gi');
  }
}
