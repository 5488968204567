<base-modal
  class="base-modal-shell notes-modal"
  [title]="title"
  [itemCount]="itemCount"
  [itemType]="itemType"
  [isProfileMode]="isProfileMode"
>
  <form [formGroup]="noteForm" autocomplete="off" *ngIf="noteForm">
    <!-- categories input -->
    <nv-form-field label="Categories">
      <nv-multi-picker
        [options]="notesCategoriesOptions"
        [hasClearButton]="true"
        [hasAllButton]="false"
        [placeholder]="'Select a category'"
        [selectedOptions]="noteForm.controls.selectedCategories.value"
        (selectedOptionsChange)="handleSelectedCategories($event)"
      ></nv-multi-picker>
    </nv-form-field>

    <!-- note input -->
    <nv-form-field label="Note">
      <nv-textarea
        [formControl]="noteForm.controls.note"
        [maxCharacters]="600"
        [placeholder]="'Enter a note'"
      ></nv-textarea>
    </nv-form-field>

    <!-- ECFIK -->
    <ng-container *ngIf="ecfikFormFieldsSet">
      <nv-form-field label="Date">
        <nv-date-picker
          [dateControl]="noteForm.controls.logDate"
          startDate="startDate"
          [endLimit]="today"
        ></nv-date-picker>
      </nv-form-field>

      <nv-form-field label="Type">
        <nv-dropdown
          [placeholder]="'Select student or family'"
          [options]="ecfikFormOptionsMap.type.options"
          [selected]="noteForm.controls.type.value"
          (selectOption)="handleDropdownSelect($event, 'type')"
        ></nv-dropdown>
      </nv-form-field>

      <nv-form-field label="Status">
        <nv-dropdown
          [placeholder]="'Select status'"
          [options]="ecfikFormOptionsMap.status.options"
          [selected]="noteForm.controls.status.value"
          (selectOption)="handleDropdownSelect($event, 'status')"
        ></nv-dropdown>
      </nv-form-field>

      <nv-form-field label="Needs (optional)">
        <nv-multi-picker
          [placeholder]="'Select need(s)'"
          [options]="ecfikFormOptionsMap.needs.options"
          [selected]="noteForm.controls.needs.value"
          [selectedOptions]="noteForm.controls.needs.value"
          (selectedOptionsChange)="handleSelectedNeeds($event)"
        ></nv-multi-picker>
      </nv-form-field>

      <div class="service-escalation-field">
        <mat-checkbox
          [formControl]="noteForm.controls.serviceReferral"
          class="nv-mat-checkbox"
        >
        Service referral
        </mat-checkbox>
        <nv-icon name="info-small-hover" nvTooltip [tooltipData]="ecfikFormOptionsMap.serviceReferral.description"></nv-icon>
      </div>
    </ng-container>

  
    <!-- Buttons -->
    <mat-dialog-actions class="dialog-actions">
      <nv-button type="secondary" (clickButton)="close()">
        Cancel
      </nv-button>
      <nv-button
        htmlType="submit"
        [disabled]="noteForm.pristine || noteForm.invalid || editIsRestricted"
        (clickButton)="onClickPrimaryBtn()"
      >
        {{ primaryBtnName }}
      </nv-button>
    </mat-dialog-actions>

  </form>
</base-modal>