import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatStepperModule } from '@angular/material/stepper';

// Modules
import { SdcDropdownModule } from '../../../sdc-dropdown/sdc-dropdown.module';
import { InstructionStepModule } from '../../../shared/components/wizard-steps/instruction-step/instruction-step.module';
import { GradPlanningStepModule } from './steps/grad-planning/grad-planning-step.module';
import { ReviewGapsStepModule } from './steps/review-gaps/review-gaps-component.module';
import { DoFinalCheckStepModule } from '../non-transfer/steps/do-final-check/do-final-check.module';
import { CreditGapsWizardSubjectTableModule } from '../tables/credit-gaps-subject-table.module';
import { CreditGapsWizardTransferTableModule } from '../tables/credit-gaps-transfer-table.module';

// Services
import { WizardHelpers } from '../../../services/wizard-helpers.service';
import { SpinnerService } from 'Src/ng2/shared/components/spinner/spinner-modal.service';
import { ImSchool } from 'Src/ng2/shared/services/im-models/im-school';

// Wizard
import { CreditGapsTransferWizard } from './credit-gaps-transfer-wizard.component';

@NgModule({
  declarations: [
    CreditGapsTransferWizard,
  ],
  exports: [CreditGapsTransferWizard],
  imports: [
    MatStepperModule,
    SdcDropdownModule,
    MatButtonModule,
    CommonModule,
    InstructionStepModule,
    GradPlanningStepModule,
    ReviewGapsStepModule,
    DoFinalCheckStepModule,
    CreditGapsWizardSubjectTableModule,
    CreditGapsWizardTransferTableModule,
  ],
  providers: [
    WizardHelpers,
    SpinnerService,
    ImSchool,
  ],
})
export class CreditGapsTransferWizardModule { }
