import { IPostSecondaryPathFieldOptionParams } from './../../../typings/interfaces/studentPaths.interface';
import { Injectable } from '@angular/core';
import { PATH_CATEGORY_KEYS } from 'Src/ng2/shared/constants/student-paths.constant';

const careerTrainingProgramQuery = `
  areaOfStudy {
    key
    human
  }
  organization {
    key
    human
  }
  specialtyArea {
    key
    human
  }
  transitionSupportOrg {
    key
    human
  }
`;

const certificateProgramQuery = `
  areaOfStudy {
    key
    human
  }
  organization {
    key
    human
    tags {
      key
      human
    }
  }
  program {
    key
    human
  }
  transitionSupportOrg {
    key
    human
  }
`;

const collegeQuery = `
  college {
    key
    human
    tags {
      key
      human
    }
  }
  transitionSupportOrg {
    key
    human
  }
`;

const employmentQuery = `
  jobType {
    key
    human
  }
  employerIndustry {
    key
    human
  }
  employer {
    key
    human
  }
  jobStatus {
    key
    human
  }
  transitionSupportOrg {
    key
    human
  }
`;

const militaryQuery = `
  jobType {
    key
    human
  }
  militaryBranch {
    key
    human
  }
`;

const otherQuery = `
  other {
    key
    human
  }
  transitionSupportOrg {
    key
    human
  }
`;

const publicServiceQuery = `
  program {
    key
    human
  }
  typeOfPlacement {
    key
    human
  }
  transitionSupportOrg {
    key
    human
  }
`;

const pathCategoryToFieldsQueryString = {
  [PATH_CATEGORY_KEYS.CAREER_TRAINING_PROGRAM]: careerTrainingProgramQuery,
  [PATH_CATEGORY_KEYS.CERTIFICATE_PROGRAM]: certificateProgramQuery,
  [PATH_CATEGORY_KEYS.COLLEGE]: collegeQuery,
  [PATH_CATEGORY_KEYS.EMPLOYMENT]: employmentQuery,
  [PATH_CATEGORY_KEYS.MILITARY]: militaryQuery,
  [PATH_CATEGORY_KEYS.OTHER]: otherQuery,
  [PATH_CATEGORY_KEYS.PUBLIC_SERVICE]: publicServiceQuery,
};

@Injectable()
export class GraphQlStudentPathsHelperService {
  public getPostSecPathFieldOptionQuery ({ schoolId, pathCategory }: IPostSecondaryPathFieldOptionParams): string {
    const resolverPrefix = `PostsecondaryPathFieldOptions(schoolId: "${schoolId}", pathCategory: "${pathCategory}")`;
    const fieldsQueryString = pathCategoryToFieldsQueryString[pathCategory];
    const query = `{
      ${resolverPrefix} {
        ${fieldsQueryString}
      }
    }`;
    return query;
  }
};
