<base-modal 
[buttonMode]="'close'"
[isProfileMode]="true"
[title]="modalTitle"

>
  @for (option of modalOptions; track option.id) {
    @if(option.optionType !== 'range') {
      <nv-multi-select-filter-section
      [sectionTitle]="option.title"
      [hasSearch]="false"
      [optionType]="option.optionType"
      [options]="option.options"
      [totalOptions]="option.length"
      [optionId]="option.id"
      [currentlySelected]="currentlySelected"
      [hasSearch]="option.hasSearch"
      [searchPlaceholder]="option.searchPlaceholder"
      [rangeFloor]="option.rangeFloor"
      [rangeCeiling]="option.rangeCeiling"
      (optionChosen)="onOptionChosen($event)"
      #multiSelectChildren
    ></nv-multi-select-filter-section>
    }
  }
  <div class="button-container">
    <nv-button
    [disabled]="buttonDisabled()"
    [type]="'primary'"
    [htmlType]="'button'"
    (click)="emitFiltersConfirmation()"
    >
      Apply filters
    </nv-button>
  </div>
</base-modal>