<div class="button-container">
  <nv-button 
    *ngIf="queueUpdateSyncToolStatus === 'Fail'"
    type="secondary" 
    (clickButton)="onBack()"
  >
    Back
  </nv-button>
  <nv-button 
    [disabled]="queueUpdateSyncToolStatus === 'Loading'"
    (clickButton)="onOK()"
  >
    OK
  </nv-button>
</div>
<ng-container [ngSwitch]="queueUpdateSyncToolStatus">
  <nv-note *ngSwitchCase="'Fail'" size="large" priority="danger">
    Your synced sheet cannot be {{actionWordPast}} at this time. Please try again later.
  </nv-note>
  <div *ngSwitchCase="'Success'">
    We've received your request to {{actionWordPresent}} the synced sheet. We'll notify you by email when it's ready.
  </div>
  <div *ngSwitchDefault>
    Submitting your request...
  </div>
</ng-container>