import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { ApiService } from 'Src/ng2/shared/services/api-service/api-service';
import * as tileActions from '../../actions/tile-actions/tile-actions';
import { generateQuery } from '../projections/tile';

@Injectable()
export class TileEffects {
  constructor(private actions$: Actions, private apiService: ApiService) {}

  loadTiles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<any>(tileActions.LOAD_TILE),
      switchMap(({ payload }) => {
        const { schoolId } = payload;
        const query = generateQuery(schoolId);
        const queryPayload = {
          query,
          fetchPolicy: 'no-cache',
        };
        return this.apiService.getStudentsGraphQL(queryPayload).pipe(
          mergeMap(({ data: { Tile } }) => [new tileActions.LoadTileSuccess(Tile)]),
          catchError(error => from([new tileActions.LoadTileFail(error)])),
        );
      }),
    );
  });
}
