import { PortalLanguages } from './../../../constants/portal-languages.constant';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ECFIKManagementService } from 'Src/ng2/school/ecfik-management/ecfik-management.service';
import { unsubscribeComponent } from 'Src/ng2/shared/helpers/unsubscribe-decorator/unsubscribe-decorators.helper';
import { IACOption } from 'projects/shared/nvps-libraries/design/nv-textbox/nv-textbox.interface';
import { BehaviorSubject, Observable, Unsubscribable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ICaringAdultInfo } from '../program-point-modal/program-point-modal.component';

// This component is used for both the "Assign caring adult" and "Manage caring adult" cases since their functionality is very similar.

export interface ICaringAdult {
  _id: string;
  firstName: string;
  lastName: string;
  studentCaseload: { _id: string; firstName: string, lastName: string }[];
  languages?: PortalLanguages[];
}

@unsubscribeComponent
@Component({
  selector: 'manage-caring-adult',
  templateUrl: 'manage-caring-adult.component.html',
  styleUrls: ['./manage-caring-adult.component.scss'],
})

export class ManageCaringAdultComponent implements OnInit {
  @Input() studentId: string;
  @Input() schoolId: string;
  @Input() caringAdultInfo: ICaringAdultInfo;

  public caringAdultOptions$: Observable<IACOption[]>;
  public selectOptionSub: Unsubscribable;
  public form: FormGroup;

  private selectedOption: BehaviorSubject<IACOption> = new BehaviorSubject(null);
  private mapCaringAdultIdCaringAdult: Record<string, ICaringAdult>;

  constructor (private ecfikService: ECFIKManagementService) { }

  ngOnInit () {
    this.form = this.getForm();

    const caringAdults$: Observable<ICaringAdult[]> = this.ecfikService.getProgramPointCaseload(this.schoolId);

    this.selectOptionSub = this.selectedOption.pipe(
      tap((option) => {
        this.form.controls.caringAdult.setValue(option?.human || '');
      }),
    ).subscribe();

    this.caringAdultOptions$ = caringAdults$.pipe(
      tap(caringAdults => {
        this.mapCaringAdultIdCaringAdult = this.getMapCaringAdultIdCaringAdult(caringAdults);
      }),
      map(caringAdults => this.getCaringAdultOptions(caringAdults)),
      tap(options => {
        const initOption = options.find(({ key }) => key === this.caringAdultInfo?.id) || null;
        this.selectedOption.next(initOption);
      }),
    );
  };

  private getMapCaringAdultIdCaringAdult (caringAdults: ICaringAdult[]): Record<string, ICaringAdult> {
    const map = caringAdults.reduce(
      (acc, caringAdult) => {
        acc[caringAdult._id] = caringAdult;
        return acc;
      }, {},
    );
    return map;
  }

  private getCaseloadWithAddition (caringAdult: ICaringAdult, studentId: string): string[] {
    const updatedCaseload = [...caringAdult.studentCaseload.map(caseload => caseload._id), studentId];
    return updatedCaseload;
  }

  private getCaseloadWithRemoval (caringAdult: ICaringAdult, studentId: string): string[] {
    const updatedCaseload = caringAdult.studentCaseload.map(caseload => caseload._id).filter(id => id !== studentId);
    return updatedCaseload;
  }

  private getForm (): FormGroup {
    const form = new FormGroup({
      caringAdult: new FormControl('', []),
    });
    return form;
  };

  private getCaringAdultOptions (caringAdults: ICaringAdult[]): IACOption[] {
    const options = caringAdults.map(
      ({ _id, firstName, lastName, studentCaseload = [] }) => {
        const caseloadMetaString = `${studentCaseload.length} out of 3 students`;
        const option = {
          key: _id,
          human: `${firstName} ${lastName}`,
          tags: [
            {
              key: caseloadMetaString,
              human: caseloadMetaString,
            },
          ],
        };
        return option;
      },
    );

    return options;
  }

  public optionsPredicateCb (option: IACOption, searchValue: string): boolean {
    const sanitizedSearchWordList = searchValue
      ?.trim()
      .toLowerCase()
      .replace(/[^\w]|_/g, ' ')
      .split(/[\s,]+/) || [];

    const { human, tags } = option;
    const tagsAsConcatString = tags?.reduce(
      (acc, { human }) => {
        acc += ` ${human}`;
        return acc;
      }, '',
    );

    const fullPathString = (
      tags
        ? `${human}${tagsAsConcatString}`
        : `${human}`
    ).toLowerCase();

    return sanitizedSearchWordList.every(val => fullPathString.includes(val));
  }

  public onOptionSelect ($event): void {
    this.selectedOption.next($event);
  }

  public clearTextBoxValue (): void {
    this.selectedOption.next(null);
  }

  public getPayload () {
    const caringAdultId = this.selectedOption.value?.key || this.caringAdultInfo.id;
    const caringAdult = this.mapCaringAdultIdCaringAdult[caringAdultId];
    const studentIds = this.selectedOption.value ? this.getCaseloadWithAddition(caringAdult, this.studentId) : this.getCaseloadWithRemoval(caringAdult, this.studentId);

    const payload = {
      caringAdultId,
      schoolId: this.schoolId,
      studentIds,
    };

    return payload;
  }
}
