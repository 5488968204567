import { WizardHelpers } from './../../../services/wizard-helpers.service';
import { RegentsPlanningTableModule } from './../shared-tables/regents-planning-table/regents-planning-table.module';
import { StarsMismatchStepModule } from './../shared-steps/stars-mismatch-step/stars-mismatch-step.module';
import { RegentsExportStepModule } from './../shared-steps/regents-export-step/regents-export-step.module';
import { ReadinessMathStepModule } from './../shared-steps/readiness-math-step/readiness-math-step.module';
import { PriorityGroupStepModule } from './priority-group-step/priority-group-step.module';
import { PlanPastDueExamsStepModule } from '../shared-steps/plan-past-due-exams-step/plan-past-due-exams-step.module';
import { OnTrackRegentsStepModule } from './../shared-steps/on-track-regents-step/on-track-regents-step.module';
import { GradPlansStepModule } from './../shared-steps/grad-plans-step/grad-plans-step.module';
import { ExamLoadStepModule } from './../shared-steps/exam-load-step/exam-load-step.module';
import { CulminatingCourseStepModule } from './../shared-steps/culminating-course-step/culminating-course-step.module';
import { RegentsPlanningNonTransferWizard } from './regents-planning-non-transfer-wizard.component';
import { SdcDropdownModule } from './../../../sdc-dropdown/sdc-dropdown.module';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatStepperModule } from '@angular/material/stepper';
import { ReadinessElaStepModule } from '../shared-steps/readiness-ela-step/readiness-ela-step.module';
import { InstructionStepModule } from '../../../shared/components/wizard-steps/instruction-step/instruction-step.module';

@NgModule({
  declarations: [RegentsPlanningNonTransferWizard],
  exports: [RegentsPlanningNonTransferWizard],
  imports: [
    MatStepperModule,
    CommonModule,
    SdcDropdownModule,
    MatButtonModule,
    CulminatingCourseStepModule,
    ExamLoadStepModule,
    GradPlansStepModule,
    InstructionStepModule,
    OnTrackRegentsStepModule,
    PlanPastDueExamsStepModule,
    PriorityGroupStepModule,
    ReadinessElaStepModule,
    ReadinessMathStepModule,
    RegentsExportStepModule,
    StarsMismatchStepModule,
    RegentsPlanningTableModule,
  ],
  providers: [WizardHelpers],
})
export class RegentsPlanningNonTransferWizardModule { }
