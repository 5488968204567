/* tslint:disable */

import { Action } from '@ngrx/store';

export const LOAD_PATCH_PROCESSING = '[Portal] Load Patch Processing';
export const UPDATE_PATCH_PROCESSING = '[Portal] Update Patch Processing';

export class LoadPatchProcessing implements Action {
  readonly type = LOAD_PATCH_PROCESSING;
  constructor() {}
}

export class UpdatePatchProcessing implements Action {
  readonly type = UPDATE_PATCH_PROCESSING;
  constructor() {}
}
// action types
export type PatchProcessingAction = LoadPatchProcessing | UpdatePatchProcessing;
