<div *ngIf="filterOptions">
  <!---TODO: v4 cleanup: remove newSkinModeIsOn--->
  <div [matMenuTriggerFor]="menu" class="newSkinModeIsOn ? icon-label-container_v4 : icon-label-container">
    <nv-icon [name]="'filter-small-blue'" [color]="iconTextColor" [isInverted]="false"></nv-icon>
    <span [ngClass]="{ 'blue-label': iconTextColor === 'blue', 'purple-label': iconTextColor === 'purple'}">{{ filterText }}</span>
  </div>
  
  <mat-menu #menu="matMenu" >
    <!---TODO: v4 cleanup: remove newSkinModeIsOn--->
    <div class="newSkinModeIsOn ? menu-filter-panel_v4 : menu-filter-panel">
      <div class="header-text">Apply filter</div>
      <!-- TODO - important! refactor to nv-form-field to avoid special handling-->
      <div class="filter">
        <label for="category">Categories</label>
        <nv-multi-picker
          id="category"
          (click)="$event.stopPropagation()"
          [options]="filterOptions.categories"
          [(selectedOptions)]="selectedCategories"
          [hasSearchBox]="true"
          [placeholder]="'Select a category'"
          [hasAllButton]="false"
          (optionClicked)="onCategoriesOptionClicked($event)"
        ></nv-multi-picker>
      </div>
    
      <div class="filter">
        <label for="users">Users</label>
        <nv-multi-picker
          id="users"
          (click)="$event.stopPropagation()"
          [options]="filterOptions.users"
          [(selectedOptions)]="selectedUsers"
          [hasSearchBox]="true"
          [placeholder]="'Select a user'"
          (optionClicked)="onUsersOptionClicked($event)"
        ></nv-multi-picker>
      </div>
    
      <div class="filter">
        <label for="date">Date</label>
        <nv-date-range-picker
          [isInverted]="newSkinModeIsOn ? true : false"
          (click)="$event.stopPropagation()"
          [dateRangeForm]="dateRangeForm"
          [startPlaceholder]="startPlaceholder"
          [endPlaceholder]="endPlaceHolder"
          >
        </nv-date-range-picker>
      </div>
  
      <nv-button (clickButton)="resetSelectedFilters()" class="cancel-button" type="secondary" [disabled]="disabledClearButton">Clear</nv-button>
      <div class="buttons-container">
        <nv-button class="cancel-button" type="secondary" (click)="onCancel()">Cancel</nv-button>
        <nv-button (clickButton)="onFilter()" [disabled]="disabledSaveButton">Save</nv-button>
      </div>
    </div>
  </mat-menu>
</div>