<span class="wizard-filter-actions">
  <span
    class="action underlined"
    *ngIf="data.length"
    (mouseover)="displayBody=true"
    (mouseleave)="displayBody=false"
  >
    {{data.length}}
  </span>

  <span
    [ngClass]="{'hidden-tip-body': !displayBody}"
    (mouseover)="displayBody=true"
    (mouseleave)="displayBody=false"
  >
    <span class="tooltip">
      <ng-container *ngFor="let action of filterActions | keyvalue">
        <div class="internal-body label action" (click)="action.value.open(data, options)">{{action.value.label}}</div>
      </ng-container>
    </span>
  </span>

  <!-- no data, no link. -->
  <span *ngIf="!data.length">0</span>
</span>
