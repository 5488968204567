import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { NvBoxButtonModule } from '../nv-box-button/nv-box-button.module';
import { NvDropdownMenuModule } from '../nv-dropdown-menu/nv-dropdown-menu.module';
import { NvDropdownBoxComponent } from './nv-dropdown-box.component';

@NgModule({
  declarations: [NvDropdownBoxComponent],
  exports: [NvDropdownBoxComponent],
  imports: [CommonModule, MatMenuModule, NvDropdownMenuModule, NvBoxButtonModule],
})
export class NvDropdownBoxModule {}
