<form [formGroup]="taskForm" autocomplete="off" *ngIf="taskForm">
  <nv-form-field label="Student name">
    <p>{{ taskForm.controls.studentName.value }}</p>
  </nv-form-field>
  <nv-form-field label="Description">
    <p>{{ taskForm.controls.description.value }}</p>
  </nv-form-field>
  <nv-form-field label="Categories">
    <p>{{ taskForm.controls.categories.value }}</p>
  </nv-form-field>
  <div class="task-modal-two-column-display">
    <nv-form-field label="Created by">
      <p>{{ taskForm.controls.assignedBy.value }}</p>
    </nv-form-field>
    <nv-form-field label="Assigned to">
      <p>{{ taskForm.controls.assignedTo.value }}</p>
    </nv-form-field>
  </div>
  <div class="task-modal-two-column-display">
    <nv-form-field label="Completed by">
      <p>{{ taskForm.controls.resolvedBy.value }}</p>
    </nv-form-field>
    <nv-form-field label="Status">
      <nv-pill [color]="taskForm.controls.status.value.color">{{ taskForm.controls.status.value.status }}</nv-pill>
    </nv-form-field>
  </div>

  <nv-form-field *ngIf="editCommentMode; else viewCommentMode" label="Comments (Optional)" id="edit-task-comment">
    <nv-textarea
      [formControl]="taskForm.controls.comment"
      [maxCharacters]="150"
      placeholder="Enter a comment..."
    ></nv-textarea>
    <nv-note size="small" priority="info"
      >Please be aware that your comments are visible to other school staff.
    </nv-note>
  </nv-form-field>

  <ng-template #viewCommentMode>
    <div id="view-task-comment">
      <nv-form-field label="Comments (Optional)">
        <p>{{ taskForm.controls.comment.value || '—' }}</p>
      </nv-form-field>
      <nv-icon-button
      *ngIf="canEditComment"
      id="task-comment-edit-icon"
      name="edit-large-blue"
      (clickButton)="onEditCommentButtonClick()"
      ></nv-icon-button>
    </div>
  </ng-template>
</form>
