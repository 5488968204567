import { TInfoBoxConfigRow } from './../../shared-components/info-box-table/info-box-table-helpers';
import { ISchool } from 'Src/ng2/shared/typings/interfaces/school.interface';
import { IInfoBoxConfig } from '../../shared-components/info-box-table/info-box-table-helpers';
import StudentPanelsConfig from '../../student-container/student-panels.config';

const rowValueFormatter = (val: string | number): string => (val ? `${val}` : '—');
const showCollegeNowRowButton = (val: string): boolean => (val.toUpperCase() === 'NOT ELIGIBLE');

const collegeNowViewOnlyConfig = [
  [
    {
      value: 'CUNY College Now',
      isGraphQlKey: false,
    },
    {
      value: 'COLLEGE_NOW_ELIGIBLE_STRING',
      isGraphQlKey: true,
      tooltipPath: 'CUNY_COLLEGE_NOW_ELIGIBLITY_TOOLTIP',
      rowButtonConfig: {
        rowButtonIcon: 'edit-small-blue',
      },
      getFormattedValue: rowValueFormatter,
    },
  ],
];

const futureReadyConfig = [
  [
    {
      value: 'FutureReadyNYC',
      isGraphQlKey: false,
    },
    {
      value: 'IS_FUTURE_READY',
      isGraphQlKey: true,
      tooltipPath: 'IS_FUTURE_READY_TOOLTIP',
      getFormattedValue: rowValueFormatter,
    },
  ],
];

const collegeNowCanEditConfig = [
  [
    {
      value: 'CUNY College Now',
      isGraphQlKey: false,
    },
    {
      value: 'COLLEGE_NOW_ELIGIBLE_STRING',
      isGraphQlKey: true,
      tooltipPath: 'CUNY_COLLEGE_NOW_ELIGIBLITY_TOOLTIP',
      showRowButton: showCollegeNowRowButton,
      rowButtonConfig: {
        rowButtonIcon: 'edit-small-blue',
      },
      getFormattedValue: rowValueFormatter,
    },
  ],
];

const getCollegeNowRowDataConfig = (isCollegeNowPilotStudent: boolean, isFutureReadyStudent, isViewOnlyUser: boolean, isWithinCollegeNowRecruitmentWindow: boolean) => {
  if (isCollegeNowPilotStudent) {
    const canEdit = !(isViewOnlyUser || !isWithinCollegeNowRecruitmentWindow);
    return getCollegeNowPilotConfigs(isFutureReadyStudent, canEdit);
  } else {
    return [];
  }
};

const getCollegeNowPilotConfigs = (isFutureReadyStudent, canEdit) => {
  if (isFutureReadyStudent) {
    // if a student is future ready, we don't show college now eligibility
    return [];
  } else {
    return canEdit ? collegeNowCanEditConfig : collegeNowViewOnlyConfig;
  }
};

// These strings are coupled with the calculated values that are derived from
// POST259 in the student level logic. They're used to determine conditional
// text color in getFinAidStyleClass
export enum TRUNCATED_FIN_AID_STATUSES {
  MISSING_SIGNATURE = '1 – missing signature',
  PROCESSED = '2 – processed',
  ERROR = '3 – processed w errors',
  STUB = '—',
  WAIVED = 'Waived'
}

export const orderedHescOptionsHuman = [
  TRUNCATED_FIN_AID_STATUSES.STUB,
  TRUNCATED_FIN_AID_STATUSES.MISSING_SIGNATURE,
  TRUNCATED_FIN_AID_STATUSES.PROCESSED,
  TRUNCATED_FIN_AID_STATUSES.ERROR,
  TRUNCATED_FIN_AID_STATUSES.WAIVED,
];

/**
 * Determines conditional text color for strings that appear in the financial aid summary
 * box tooltips.
 * @param finAidStatus 
 * @returns a style class string found in student-postsec.scss
 */
const getFinAidStyleClass = (finAidStatus: string): string => {
  let styleClass;

  if (
    finAidStatus === TRUNCATED_FIN_AID_STATUSES.MISSING_SIGNATURE || 
    finAidStatus === TRUNCATED_FIN_AID_STATUSES.ERROR
  ) {
    styleClass = 'error-text';
  } else {
    styleClass = 'processed-text';
  }
    
  return styleClass;
}

const getSharedAISummaryBoxRows = (isCollegeNowPilotStudent: boolean, isFutureReadyStudent: boolean, isViewOnlyUser: boolean, isWithinCollegeNowRecruitmentWindow: boolean) : TInfoBoxConfigRow[] => {
  const sharedAISummaryBoxRows: TInfoBoxConfigRow[] = [
    [
      {
        value: 'GPA',
        isGraphQlKey: false,
      },
      {
        value: 'CURRENT_GPA',
        isGraphQlKey: true,
        tooltipPath: '',
        getFormattedValue: rowValueFormatter,
      },
    ],
    [
      {
        value: 'CUNY college readiness',
        isGraphQlKey: false,
      },
      {
        value: 'CUNY_COLLEGE_READINESS',
        isGraphQlKey: true,
        tooltipPath: 'CUNY_COLLEGE_READINESS_TOOLTIP',
        getFormattedValue: rowValueFormatter,
      },
    ],
    ...(getCollegeNowRowDataConfig(isCollegeNowPilotStudent, isFutureReadyStudent, isViewOnlyUser, isWithinCollegeNowRecruitmentWindow)),
    ...futureReadyConfig,
    [
      {
        value: 'CDOS work-based experience hours completed',
        isGraphQlKey: false,
      },
      {
        value: 'EXPERIENCE_CDOS_HOURS_EARNED',
        isGraphQlKey: true,
        tooltipPath: 'EXPERIENCE_CDOS_HOURS_EARNED_TOOLTIP',
        getFormattedValue: rowValueFormatter,
      },
    ],
    [
      {
        value: 'CDOS course hours completed',
        isGraphQlKey: false,
      },
      {
        value: 'CDOS_COURSE_HOURS_EARNED',
        isGraphQlKey: true,
        tooltipPath: 'CDOS_COURSE_HOURS_EARNED_TOOLTIP',
        getFormattedValue: rowValueFormatter,
      },
    ],
  ];

  return sharedAISummaryBoxRows;
};

const charterSpecificAISummaryBoxRows: TInfoBoxConfigRow[] = [
  [
    {
      value: 'Max PSAT',
      isGraphQlKey: false,
    },
    { 
      value: 'PSAT_MAX_SCORE_TOTAL',
      isGraphQlKey: true,
      tooltipPath: 'PSAT_MAX_SCORE_TOTAL_TOOLTIP', 
      getFormattedValue: rowValueFormatter 
    },
  ],
  [
    {
      value: 'SAT Superscore',
      isGraphQlKey: false,
    },
    { 
      value: 'SAT_SUPERSCORE',
      isGraphQlKey: true,
      tooltipPath: 'SAT_SUPERSCORE_TOOLTIP', 
      getFormattedValue: rowValueFormatter 
    },
  ],
  [
    {
      value: 'Max ACT',
      isGraphQlKey: false,
    },
    { 
      value: 'ACT_COMPOSITE_MAX',
      isGraphQlKey: true,
      tooltipPath: 'ACT_COMPOSITE_MAX_TOOLTIP',
      getFormattedValue: rowValueFormatter 
    },
  ]
];

const makeAIInfoBoxConfig = (school: ISchool, isCollegeNowPilotStudent: boolean, isFutureReadyStudent: boolean, isViewOnlyUser: boolean, isWithinCollegeNowRecruitmentWindow: boolean): IInfoBoxConfig => {
  const sharedAISummaryBoxRows = getSharedAISummaryBoxRows(isCollegeNowPilotStudent, isFutureReadyStudent, isViewOnlyUser, isWithinCollegeNowRecruitmentWindow);
  const config = {
    title: 'Academic indicators',
    rows: [
      ...sharedAISummaryBoxRows,
      ...(school.isNVCMO ? charterSpecificAISummaryBoxRows : [])
    ],
  };
  return config;
};

const postsecDataSourcesBoxConfig: IInfoBoxConfig = {
  title: 'Postsec data sources',
  headerButtonConfig: {
    headerButtonText: 'Expand',
    headerButtonIcon: 'expand-small-blue',
  },
  rows: [
    [
      {
        value: 'CUNY apps',
        isGraphQlKey: false,
      },
      { 
        value: 'CUNY_REPORT_TIMESTAMP',
        isGraphQlKey: true,
        tooltipPath: '',
        getFormattedValue: rowValueFormatter 
      },
    ],
    [
      {
        value: 'HESC fin aid',
        isGraphQlKey: false,
      },
      { 
        value: 'HESC_REPORT_TIMESTAMP',
        isGraphQlKey: true,
        tooltipPath: '',
        getFormattedValue: rowValueFormatter 
      },
    ]
  ],
};

const PostsecConfig = {
  title: StudentPanelsConfig.POSTSECONDARY.human,
  /**
   * Allows the shape of IInfoBoxConfigs to be conditionally different
   * based on the passed in school.
   * @param school
   * @returns a list of IInfoBoxConfig
  */  
  makeInfoBoxConfigs: (school: ISchool, isUftDoeAdvisingStudent: boolean, isCollegeNowPilotStudent: boolean, isFutureReadyStudent: boolean, isViewOnlyUser: boolean, isWithinCollegeNowRecruitmentWindow: boolean): IInfoBoxConfig[] => [
    makeAIInfoBoxConfig(school, isCollegeNowPilotStudent, isFutureReadyStudent, isViewOnlyUser, isWithinCollegeNowRecruitmentWindow),
    {
      title: 'Financial aid status',
      ...(!isViewOnlyUser && {
        headerButtonConfig: {
          headerButtonText: 'Edit',
          headerButtonIcon: 'edit-small-blue',
        }
      }),
      rows: [
        [
          {
            value: 'EOP eligibility',
            isGraphQlKey: false,
          },
          {
            value: 'EOP_ELIGIBILITY_STATUS',
            isGraphQlKey: true,
            getFormattedValue: rowValueFormatter,
          },
          {
            value: 'EOP_DATA_SOURCE',
            isGraphQlKey: true,
            tooltipPath: 'EOP_DATA_SOURCE_TOOLTIP',
            getFormattedValue: rowValueFormatter,
          }
        ],
        [
          {
            value: 'FAFSA',
            isGraphQlKey: false,
          },
          {
            value: 'FAFSA_TRUNCATED_STATUS',
            isGraphQlKey: true,
            tooltipPath: 'FAFSA_TRUNCATED_STATUS_TOOLTIP',
            getBackgroundStyle: (finAidStatus) => getFinAidStyleClass(finAidStatus),
            getFormattedValue: rowValueFormatter,
          },
          {
            value: 'FAFSA_DATA_SOURCE',
            isGraphQlKey: true,
            tooltipPath: 'FAFSA_DATA_SOURCE_TOOLTIP',
            getFormattedValue: rowValueFormatter,
          }
        ],
        [
          {
            value: 'TAP',
            isGraphQlKey: false,
          },
          {
            value: 'TAP_TRUNCATED_STATUS',
            isGraphQlKey: true,
            tooltipPath: 'TAP_TRUNCATED_STATUS_TOOLTIP',
            getBackgroundStyle: (finAidStatus) => getFinAidStyleClass(finAidStatus),
            getFormattedValue: rowValueFormatter,
          },
          {
            value: 'TAP_DATA_SOURCE',
            isGraphQlKey: true,
            tooltipPath: 'TAP_DATA_SOURCE_TOOLTIP',
            getFormattedValue: rowValueFormatter,
          }
        ]
      ]
    },
    postsecDataSourcesBoxConfig,
  ],
};

export default PostsecConfig;
