import { ILoadStudentAssessmentsPayload } from './../../../../store/actions/assessments-actions/student-assessments-actions';
import { Injectable } from '@angular/core';
import { each } from 'lodash';
import { IBulkUpdateStudentAssessmentsPayload } from '../../../../../ng2/store';

@Injectable()
export class GraphQLStudentAssessmentsHelperService {
  constructor() {
    //
  }
  getStudentAssessmentsQuery({ schoolId, schoolYear, where = '{}' }: ILoadStudentAssessmentsPayload): string {
    return `{
      StudentAssessments(schoolId: "${schoolId}", schoolYear: "${schoolYear}", where: "${where}"){
        _id
        student {
          studentId
          firstName
          lastName
          grade
          officialClass
        }
        schoolAssessment {
          adminNumber
          schoolAssessmentId
          assessmentNickName
        }
        items {
          section
          value
        }
        lastEditedOn
        lastEditedBy {
          firstName
          lastName
        }
        assessedOn
        status
        relativeScore
        expectedScore
      }
    }`;
  }

  bulkUpdateStudentAssessmentsQuery(studentAssessmentParams: IBulkUpdateStudentAssessmentsPayload): string {
    const { payload } = studentAssessmentParams;
    let payloadString = '';
    each(payload, studentAssessmentDetail => {
      const { studentAssessmentId, schoolAssessmentId, assessedOn, status, items } = studentAssessmentDetail;
      payloadString += `{schoolAssessmentId: "${schoolAssessmentId}", studentAssessmentId: "${studentAssessmentId}", `;
      if (assessedOn) payloadString += `assessedOn: "${assessedOn}", `;
      if (status) payloadString += `status: "${status}", `;
      if (items) {
        // required to stringify array of strings into shape that graphql will accept (JE)
        let itemsString = '[';
        each(items, ({ section, value }) => (itemsString += `{section: "${section}", value: "${value}"}, `));
        itemsString = itemsString.substring(0, itemsString.length - 2);
        itemsString = `items: ${itemsString}], `;
        payloadString += itemsString;
      }
      payloadString = payloadString.substring(0, payloadString.length - 2);
      payloadString += '}';
    });
    return `mutation{
      bulkUpdateStudentAssessment(patch: { payload: [${payloadString}]}){
        backgroundJobId
      }
    }`;
  }
}
