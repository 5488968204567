import { Pipe, PipeTransform } from '@angular/core';
import { TMatDisplayOption } from '../transform-to-mat-display-options/transform-to-mat-display-options.pipe';
/**
 * This pipe implements transform as a pure function that appends an un-selectable option to the list
 * of display options with a message denoting the remaining number of options after limiting.
 */
@Pipe({ name: 'limitMatDisplayOptions' })
export class LimitMatDisplayOptions implements PipeTransform {
  transform (options: TMatDisplayOption[], optionsLimit: number, moreOptionsText: string): TMatDisplayOption[] {
    const slicedOptions = options.slice(0, optionsLimit || options.length);
    const remainingOptionsAfterSlice = options.length - slicedOptions.length;

    if (remainingOptionsAfterSlice > 0) {
      const human = moreOptionsText || `${remainingOptionsAfterSlice} more options. Refine your search.`;
      slicedOptions.push({ selectable: false, human });
    } else if (!optionsLimit && moreOptionsText) {
      slicedOptions.push({ selectable: false, human: moreOptionsText });
    }

    return slicedOptions;
  }
};
