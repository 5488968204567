import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'grid-sort-summary',
  templateUrl: './grid-sort-summary.component.html',
  styleUrls: ['./grid-sort-summary.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GridSortSummaryComponent {
  @Input() sortData: { colId: string; headerName: string; sort: string; sortIndex: number }[];
  @Output() clearAllClick = new EventEmitter<string>();

  public onClearAll (): void {
    this.clearAllClick.emit('clear');
  }
}
