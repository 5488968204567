<section>
    <p class="mat-caption">Please review the following students:</p>
    <p class="mat-body-2">
        <wizard-filter-actions [data]="studentsWithPendingCourseDiffs"
            [options]="studentsWithPendingCourseDiffs.options"></wizard-filter-actions>
        <ng-container [ngPlural]="studentsWithPendingCourseDiffs.count">
            <ng-template ngPluralCase="0"> students have </ng-template>
            <ng-template ngPluralCase="1"> student has </ng-template>
            <ng-template ngPluralCase="other"> students have </ng-template>
        </ng-container>
        course changes that are <b>still pending for the current term</b> and need to be added to STARS.
        <a href="javascript:void(0);" (click)="generateCourseDiffCsv()">Click here</a> to export a CSV that can easily be uploaded
        into STARS. *The export will contain students across all cohorts. See
        <a href="{{starsInstructions}}" target="_blank">this document</a> for more details.
    </p>
    <p class="mat-body-2">
        <wizard-filter-actions [data]="studentsWithPendingGapPlans" [options]="studentsWithPendingGapPlans.options">
        </wizard-filter-actions>
        <ng-container [ngPlural]="studentsWithPendingGapPlans.count">
            <ng-template ngPluralCase="0"> students have </ng-template>
            <ng-template ngPluralCase="1"> student has </ng-template>
            <ng-template ngPluralCase="other"> students have </ng-template>
        </ng-container>
        course changes that are <b>still pending for the current term but do not have a section number</b>.
        <ng-container [ngPlural]="studentsWithPendingGapPlans.count">
            <ng-template ngPluralCase="0"> These students </ng-template>
            <ng-template ngPluralCase="1"> This student </ng-template>
            <ng-template ngPluralCase="other"> These students </ng-template>
        </ng-container>
        will NOT be included in the STARS export, and will require manual changes in STARS.
    </p>
    <div class="mat-body-2">
        <wizard-filter-actions [data]="studentsWithCredIssues" [options]="studentsWithCredIssues.options">
        </wizard-filter-actions>
        <ng-container [ngPlural]="studentsWithCredIssues.count">
            <ng-template ngPluralCase="0"> students have </ng-template>
            <ng-template ngPluralCase="1"> student has </ng-template>
            <ng-template ngPluralCase="other"> students have </ng-template>
        </ng-container>
        potential credit issues on their current program. Please review
        <ng-container [ngPlural]="studentsWithCredIssues.count">
            <ng-template ngPluralCase="0"> these students </ng-template>
            <ng-template ngPluralCase="1"> this student </ng-template>
            <ng-template ngPluralCase="other"> these students </ng-template>
        </ng-container>
        the Student Profile and make course changes as needed. Criteria for inclusion:
        <ul class="mat-caption">
            <li>
                A student programmed in a course that may result in the student earning greater than the maximum
                allowable
                credits in a graduation requirement.
            </li>
            <li>
                A student programmed in a course where the code matches a course code for which the student has already
                earned credit on their transcript.
            </li>
        </ul>
        <!-- prettier-ignore -->
    </div>
    <p class="mat-body-2">
        <wizard-filter-actions [data]="studentsWithPlansBeyonGradDate"
            [options]="studentsWithPlansBeyonGradDate.options"></wizard-filter-actions>
        <ng-container [ngPlural]="studentsWithPlansBeyonGradDate.count">
            <ng-template ngPluralCase="0"> students have </ng-template>
            <ng-template ngPluralCase="1"> student has </ng-template>
            <ng-template ngPluralCase="other"> students have </ng-template>
        </ng-container>
        course plans that would occur after their planned graduation date. Please review
        <ng-container [ngPlural]="studentsWithPlansBeyonGradDate.count">
            <ng-template ngPluralCase="0"> these students </ng-template>
            <ng-template ngPluralCase="1"> this student </ng-template>
            <ng-template ngPluralCase="other"> these students </ng-template>
        </ng-container>
        in the Student Profile and either update the course plans or the student’s planned graduation date.
    </p>
    <br />
    <br />
</section>
