import * as usersActions from '../actions/users-actions';

interface IUsersState {
  loaded: boolean;
  loading: boolean;
  userEntities: object;
}

export const initialState = {
  loaded: false,
  loading: false,
  userEntities: {},
};

// TODO: these reducers need unit tests (CM)
/* istanbul ignore next */
export function reducer(state: IUsersState = initialState, action: usersActions.UsersAction): IUsersState {
  switch (action.type) {
    case usersActions.LOAD_USERS: {
      return { ...state, loaded: false, loading: true };
    }

    case usersActions.LOAD_USERS_SUCCESS: {
      const userEntities = action.payload;
      return {
        ...state,
        loaded: true,
        loading: false,
        userEntities,
      };
    }

    case usersActions.UPDATE_USER_SUCCESS: {
      const user = action.payload;
      const userEntities = Object.assign({}, state.userEntities) as any;

      userEntities.push(user);

      return {
        ...state,
        userEntities,
      };
    }

    default:
      return state;
  }
}
