import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'row-group-cell-renderer',
  styleUrls: ['./row-group-cell-renderer.component.scss'],
  templateUrl: './row-group-cell-renderer.component.html',
  encapsulation: ViewEncapsulation.Emulated,
})
export class RowGroupCellRenderer {
  public name: string;
  public selected: number;
  public total: number;

  agInit (params): void {
    const { value, categoryMeta } = params;
    const { currentSelected, currentTotal } = categoryMeta[value];
    this.name = value;
    this.selected = currentSelected;
    this.total = currentTotal;
  }
}
