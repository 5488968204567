import { MatButtonModule } from '@angular/material/button';
import { CsvExporterService } from 'Src/ng2/shared/services/csv-exporter/csv-exporter.service';
import { WizardFilterActionsModule } from './../../../../wizard-filter-actions/wizard-filter-actions.module';
import { CommonModule } from '@angular/common';
import { RegentsExportStep } from './regents-export.component';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [CommonModule, WizardFilterActionsModule, MatButtonModule],
  exports: [RegentsExportStep],
  declarations: [RegentsExportStep],
  providers: [CsvExporterService],
})
export class RegentsExportStepModule { }
