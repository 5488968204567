import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { VizD3Service } from '../../../shared/services/viz-services/viz-d3-service';
import { VizDataService } from '../../../shared/services/viz-services/viz-data-service';
import { VizComponent } from './viz.component';

@NgModule({
  imports: [CommonModule],
  declarations: [VizComponent],
  exports: [VizComponent],
  providers: [VizDataService, VizD3Service],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class VizModule {}
