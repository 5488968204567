import { ImStudentCurrentProgramHelpers } from './im-student-credit-gaps-helpers/im-student-current-program-helpers';
import { ImStudentSupport } from './im-student-support/im-student-support.service';
import { ImTool } from './im-tool/im-tool';
import { ImSupport } from './im-support/im-support.service';
import { ImStudentCurrentProgram } from './im-student-current-program/im-student-current-program';
import { ImStudentGradPlanning } from './im-student-grad-planning/im-student-grad-planning';
import { ImStudentRegentsExemptions } from './im-student-regents-exemptions/im-student-regents-exemptions';
import { CreditGapsPriorityGroupingsTransferService } from './../../../school/sdc/services/credit-gaps-priority-groupings/credit-gaps-priority-groupings-transfer.service';
import { CreditGapsPriorityGroupingsService } from './../../../school/sdc/services/credit-gaps-priority-groupings/credit-gaps-priority-groupings.service';
import { RegentsPrepPriorityGroupingsTransferService } from './../../../school/sdc/services/regents-prep-priority-groupings/regents-prep-priority-groupings-transfer.service';
import { RegentsPrepPriorityGroupingsService } from './../../../school/sdc/services/regents-prep-priority-groupings/regents-prep-priority-groupings.service';
import { RegentsPlanningPriorityGroupingsTransferService } from 'Src/ng2/school/sdc/services/regents-planning-priority-groupings/regents-planning-priority-groupings-transfer.service';
import { RegentsPlanningPriorityGroupingsService } from 'Src/ng2/school/sdc/services/regents-planning-priority-groupings/regents-planning-priority-groupings.service';
import { ImStudentRegents } from './im-student-regents/im-student-regents';
import { NgModule } from '@angular/core';
import { ImCachedObject } from './im-cached-object.service';
import { ImCourseDiff } from './im-course-diff';
import { ImFlag } from './im-flag';
import { ImGapPlan } from './im-gap-plan';
import { ImNote } from './im-note.service';
import { ImNotification } from './im-notification.service';
import { ImSchool } from './im-school';
import { ImStudent } from './im-student.service';
import { ImStudentMapGrowth } from './im-student-map-growth';
import { ImStudentIReady } from './im-student-iready';
import { ImUser } from './im-user';
import { ImStudentCreditGaps } from './im-student-credit-gaps/im-student-credit-gaps';
import { ImStudentDessa } from './im-student-dessa';

@NgModule({
  providers: [
    ImNote,
    ImUser,
    ImSchool,
    ImStudent,
    ImStudentMapGrowth,
    ImStudentIReady,
    ImStudentDessa,
    ImCachedObject,
    ImNotification,
    ImFlag,
    ImCourseDiff,
    ImGapPlan,
    ImStudentSupport,
    ImTool,
    ImSupport,
    ImStudentCurrentProgram,
    ImStudentCurrentProgramHelpers,
    ImStudentGradPlanning,
    ImStudentRegentsExemptions,
    ImStudentCreditGaps,
    CreditGapsPriorityGroupingsService,
    CreditGapsPriorityGroupingsTransferService,
    ImStudentRegents,
    RegentsPlanningPriorityGroupingsService,
    RegentsPlanningPriorityGroupingsTransferService,
    RegentsPrepPriorityGroupingsService,
    RegentsPrepPriorityGroupingsTransferService,
  ],
})
export class ImModelsModule {}
