export const CUNY_APPS_COLUMNS = [
  {
    columnName: 'PATH_NAME',
    columnOrder: 0,
    graphQlKey: 'PATH_NAME',
    cellTooltip: 'SIMPLE_OR_TABLE_CONTENT',
  },
  {
    columnName: 'Status',
    columnDataType: 'STUDENT_PATH_STATUS',
    columnOrder: 1,
    graphQlKey: 'STUDENT_PATH_STATUS',
  },
];

export const HESC_FIN_AID_COLUMNS = [
  {
    columnName: 'DATA_SOURCE',
    columnOrder: 0,
    graphQlKey: 'DATA_SOURCE',
    cellTooltip: 'SIMPLE_OR_TABLE_CONTENT',
  },
  {
    columnName: 'Status',
    columnDataType: 'HESC_REPORT_STATUS',
    columnOrder: 1,
    graphQlKey: 'HESC_REPORT_STATUS',
  },
  {
    columnName: 'Submitted',
    columnDataType: 'HESC_SUBMISSTION_DATE',
    columnOrder: 1,
    graphQlKey: 'HESC_SUBMISSTION_DATE',
  },
];
