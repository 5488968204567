import { MatCheckboxModule } from '@angular/material/checkbox';
import { SupportModalService } from './support-modal.service';
import { CommonModule } from '@angular/common';
import { NvTimeRangePickerModule } from './../../../../nvps-libraries/design/nv-time-range-picker/nv-time-range-picker.module';
import { NvDateRangePickerModule } from './../../../../nvps-libraries/design/nv-date-range-picker/nv-date-range-picker.module';
import { NvDatePickerModule } from './../../../../nvps-libraries/design/nv-date-picker/nv-date-picker.module';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { BaseModalModule } from '../base-modal.module';
import { NvDesignLibraryModule } from './../../../../nvps-libraries/design/nv-design-library.module';
import { NvMultipickerModule } from './../../../../nvps-libraries/design/nv-multi-picker/nv-multi-picker.module';
import { NvTextareaModule } from './../../../../nvps-libraries/design/nv-textarea/nv-textarea.module';
import { NvTextboxModule } from './../../../../nvps-libraries/design/nv-textbox/nv-textbox.module';
import { NvMatTooltipModule } from './../../components/nv-mat-tooltip/nv-mat-tooltip.module';
import { SupportModalComponent } from './support-modal.component';
import { NvTooltipModule } from 'Src/ng2/shared/nv-tooltip/nv-tooltip.module';

@NgModule({
  declarations: [SupportModalComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatInputModule,
    MatFormFieldModule,
    MatOptionModule,
    NvTooltipModule,
    FormsModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatDialogModule,
    BaseModalModule,
    MatMenuModule,
    NvDesignLibraryModule,
    NvTextboxModule,
    NvTextareaModule,
    NvMatTooltipModule,
    NvMultipickerModule,
    NvDatePickerModule,
    NvDateRangePickerModule,
    NvTimeRangePickerModule,
    MatCheckboxModule,
  ],
  exports: [SupportModalComponent],
  providers: [SupportModalService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SupportModalModule {}
