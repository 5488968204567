/* tslint:disable */
import { Action } from '@ngrx/store';
export const LOAD_ROLLUP_GROUPING = '[Portal] Load Rollup Grouping';
export const RESET_ROLLUP_GROUPING = '[Portal] Reset Rollup Grouping';
export const LOAD_ROLLUP_GROUPING_SUCCESS = '[Portal] Load Rollup Grouping Success';
export const LOAD_ROLLUP_GROUPING_FAIL = '[Portal] Load Rollup Grouping Fail';

export interface IRollupGroupingPayload {
  schoolId: string;
  filterKeys: string[];
  groupingKey: string;
  columns: string[];
  focusKey: string;
}

export interface IRollupGroupingResponse {
  // TODO
}

// LoadFocus hits a graphQL endpoint if you pass a FocusName you get the single Focus, no FocusName... get all the Focus
export class LoadRollupGrouping implements Action {
  readonly type = LOAD_ROLLUP_GROUPING;
  constructor(public payload: IRollupGroupingPayload) {}
}

export class ResetRollupGrouping implements Action {
  readonly type = RESET_ROLLUP_GROUPING;
  constructor() {}
}

// todo: add interface Array<IFocus>
export class LoadRollupGroupingSuccess implements Action {
  readonly type = LOAD_ROLLUP_GROUPING_SUCCESS;
  constructor(public payload: IRollupGroupingResponse) {}
}

// interface is error$?
export class LoadRollupGroupingFail implements Action {
  readonly type = LOAD_ROLLUP_GROUPING_FAIL;
  constructor(public payload: any) {}
}

// action types
export type RollupGroupingAction =
  | LoadRollupGrouping
  | ResetRollupGrouping
  | LoadRollupGroupingSuccess
  | LoadRollupGroupingFail;
