import { ValidCourseCodeRegex } from './../constants/valid-course-code-regex.constant';
import { AbstractControl, ValidatorFn } from '@angular/forms';

export function restrictInputTo (allowedRegEx: RegExp): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const allowed = allowedRegEx.test(control.value);
    return allowed ? null : { restricInputTo: { value: control.value } };
  };
}

export function forbiddenInput (forbiddenRegEx: RegExp): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const forbidden = forbiddenRegEx.test(control.value);
    return forbidden ? { forbiddenInput: { value: control.value } } : null;
  };
}

export function restrictInputToNumber (): ValidatorFn {
  const numberRegex: RegExp = /(?:([0-9]*[.])?[0-9])+/g;
  return restrictInputTo(numberRegex);
}

export function validateCourseCode (district): ValidatorFn {
  return (control: AbstractControl): {invalidCourseCode: true} | null => {
    if (district !== 'NYC') return null;
    const isValid = ValidCourseCodeRegex.test(control.value);
    return isValid ? null : { invalidCourseCode: true };
  };
}
