export const NOTES_CATEGORIES_OPTIONS = {
  HS: [
    { key: 'Academics', human: 'Academics' },
    { key: 'Attendance', human: 'Attendance' },
    { key: 'Credits', human: 'Credits' },
    { key: 'General', human: 'General' },
    { key: 'Graduation', human: 'Graduation' },
    { key: 'Point People', human: 'Point People' },
    { key: 'Postsecondary', human: 'Postsecondary' },
    { key: 'Regents', human: 'Regents' },
    { key: 'Remote Learning', human: 'Remote Learning' },
    { key: 'Screeners / Assessments', human: 'Screeners / Assessments' },
    { key: 'Supports', human: 'Supports' },
  ],
  MS: [
    { key: 'Academics', human: 'Academics' },
    { key: 'Attendance', human: 'Attendance' },
    { key: 'General', human: 'General' },
    { key: 'Point People', human: 'Point People' },
    { key: 'Remote Learning', human: 'Remote Learning' },
    { key: 'Screeners / Assessments', human: 'Screeners / Assessments' },
    { key: 'Supports', human: 'Supports' },
  ],
  ES: [
    { key: 'Academics', human: 'Academics' },
    { key: 'Attendance', human: 'Attendance' },
    { key: 'General', human: 'General' },
    { key: 'Point People', human: 'Point People' },
    { key: 'Remote Learning', human: 'Remote Learning' },
    { key: 'Screeners / Assessments', human: 'Screeners / Assessments' },
    { key: 'Supports', human: 'Supports' },
  ],
  SHELTER: [
    { key: 'General', human: 'General' },
    { key: 'Success Mentoring', human: 'Success Mentoring'},
  ]
};

export const NOTE_TEMPLATE = {
  _id: null,
  schoolId: null,
  body: '',
  categories: [],
  attachments: null,
};

// SHELTER
export const NOTE_SHELTER_TEMPLATE = {
  _id: null,
  shelterId: null,
  body: null,
  categories: [],
  attachments: null,
};
