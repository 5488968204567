import { Injectable } from '@angular/core';
import { CsvType } from '../../csv-exporter/csv-exporter.service';

@Injectable()
export class GraphQLCsvDownloadsService {
  private wrapElementsInQuotes(arr: string[]) {
    let output: string[] = [];
    for (let item of arr) {
      // some items might already be wrapped in quotes
      item[0] === '"' && item[item.length - 1] === '"' ? 
        output.push(`${item}`) :
        output.push(`"${item}"`);
    }
    return output;
  }

  public getCsvDownloadQuery (options: { fileName: string, schoolId: string, columns: string[], rowData: string[], csvType: CsvType, url: string }) : { query: string, variables: { [key: string]: string } } {
    const columns = this.wrapElementsInQuotes(options.columns);
    const query = `mutation ($metadata: String!){
      createCsvDownload(
        options: { 
          fileName: "${options.fileName}",
          csvType: "${options.csvType}",
          url: "${options.url}",
          columns: [${columns}],
          metadata: $metadata
        })
      {
        _id
        fileName
      }
    }`;

    let variables: { [key: string]: string };
    switch(options.csvType){
      case CsvType.Student:
        variables = { metadata: JSON.stringify({ schoolId: options.schoolId, studentIds: options.rowData }) };
        break;
      case CsvType.Support:
        variables = { metadata: JSON.stringify({ schoolId: options.schoolId, supportNames: options.rowData }) };
        break;
      case CsvType.Network:
        variables = { metadata: JSON.stringify({ schoolIds: options.rowData }) };
        break;
      case CsvType.MockRegents:
        variables = { metadata: JSON.stringify({ schoolId: options.schoolId }) };
        break;
      default:
        throw new Error(`CsvType must be Student, Support or Network. CsvType is ${options.csvType}. See PI-3108 for more information.`);
    }
    return { query, variables };
  }
}
