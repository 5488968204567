<nv-pill-button
  #pillButton
  [ngClass]="{ pulse: pulse }"
  [text]="parsedPillLabel"
  icon="dropdown-blue"
  [color]="color"
  [isInverted]="true"
  [textIsPlaceholder]="placeholderSelected"
  (click)="toggleMenu()"
  [isDisabled]="isDisabled"
></nv-pill-button>
