import { HttpErrorResponse } from '@angular/common/http';
import { IUser } from '../../shared/typings/interfaces/user.interface';
import * as currentUserActions from '../actions/current-user-actions';

export interface ICurrentUserState {
  loading: boolean;
  loaded: boolean;
  userEntity: IUser;
  error: HttpErrorResponse;
}

export const initialState: ICurrentUserState = {
  loading: false,
  loaded: false,
  userEntity: null,
  error: null,
};

export function reducer(
  state: ICurrentUserState = initialState,
  action: currentUserActions.CurrentUserAction,
): ICurrentUserState {
  switch (action.type) {
    case currentUserActions.LOAD_CURRENT_USER:
      return {
        ...state,
        loading: true,
      };
    case currentUserActions.LOAD_CURRENT_USER_SUCCESS:
      const userEntity = action.payload;
      return {
        ...state,
        loading: false,
        loaded: true,
        userEntity,
        error: null,
      };
    case currentUserActions.LOAD_CURRENT_USER_FAIL: {
      const error = action.payload;
      return {
        ...state,
        loading: false,
        loaded: false,
        error,
      };
    }
    default:
      return state;
  }
}
