<div class="scheduled-course-plan">
  <div class="term-container">
    <ng-container class="label"> Select Term </ng-container>
    <nv-dropdown
      id="termYear"
      [placeholder]="initialDropdownLabel.termYear"
      [selected]="termYear"
      [options]="allTermYears"
      (selectOption)="setTermType($event)"
    >
    </nv-dropdown>
  </div>
  <div class="grid-container">
    <div class="filters-container">
      <div class="dropdowns">
        <nv-dropdown
          id="subjectArea"
          [placeholder]="initialDropdownLabel.subject"
          selected="userFilter.subjectArea"
          [options]="subjectsMenu"
          (selectOption)="changeDropdownEventToUserFilter('subjectArea', $event)"
        >
        </nv-dropdown>
        <nv-dropdown
          id="period"
          [placeholder]="initialDropdownLabel.period"
          selected="userFilter.period"
          [options]="periodsMenu"
          (selectOption)="changeDropdownEventToUserFilter('period', $event)"
        >
        </nv-dropdown>
      </div>
      <nv-textbox
        [textControl]="courseForm"
        placeholder="Search course code"
        icon="search-large-black"
        [hasClearBtn]="true"
        (clearValue)="onClear()"
      ></nv-textbox>
    </div>
    <ag-grid-angular
      class="ag-theme-alpine"
      [gridOptions]="gridOptions"
      [modules]="modules"
      (gridReady)="onGridReady($event)"
    ></ag-grid-angular>
  </div>

  <mat-dialog-actions class="course-plans-actions">
    <nv-button type="secondary" (clickButton)="close()">
      Cancel
    </nv-button>
    <nv-button
      [disabled]="isButtonDisabled"
      (clickButton)="onApply()"
    >
      Assign
    </nv-button>
  </mat-dialog-actions>
</div>