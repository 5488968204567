import { CodeDeckUrl } from './../../../constants/valid-course-code-regex.constant';
import { Component, EventEmitter, Inject, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { filter, cloneDeep } from 'lodash';
import { ImSchool } from '../../../services/im-models/im-school';
import { ISchool } from '../../../typings/interfaces/school.interface';
import { UtilitiesService } from '../../../services/utilities/utilities.service';
import { CreditRequirements, ICreditRequirement } from '../../../constants/credit-requirements.constant';
import { validateCourseCode } from 'Src/ng2/shared/directives/input-validator.directive';
import { BaseModalComponent } from 'Src/ng2/shared/modals/base-modal.component';
import { ICoursePlansModalComponentData } from 'Src/ng2/shared/modals/course-plans/course-plans-modal-shell.component';
import { DistrictHelpers } from 'Src/ng2/shared/services/district/district-helpers.service';
import { TDistricts } from 'Src/ng2/shared/typings/interfaces/district.interface';

interface ITermYear {
  termYear: string;
  human: string;
  key: string;
}
@Component({
  selector: 'manual-course-plan',
  templateUrl: './manual-course-plan.component.html',
  styleUrls: ['./manual-course-plan.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ManualCoursePlanComponent extends BaseModalComponent implements OnInit {
  public manualCoursePlanForm: FormGroup;
  public schoolId: string;
  public school: ISchool;
  public confirmationButtonLabel: string = 'Assign';
  public allTermYears: ITermYear[];
  public creditReqs: ICreditRequirement[];
  public district: string;
  public isButtonDisabled: boolean = true;
  public codeDeckUrl = CodeDeckUrl;
  public isNYC: boolean;
  public hideErrorMessage: boolean = true;

  @Output() applyPlan: EventEmitter<any> = new EventEmitter<any>();

  constructor (
    dialogRef: MatDialogRef<ManualCoursePlanComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: ICoursePlansModalComponentData,
    private imSchool: ImSchool,
    private utils: UtilitiesService,
    private districtHelpers: DistrictHelpers,
  ) {
    super(dialogRef);
  }

  public ngOnInit (): void {
    const { school: { district, _id } } = cloneDeep(this.data);
    this.schoolId = _id;
    this.district = district;
    this.isNYC = (this.district === 'NYC');
    const schoolDistrict = this.districtHelpers.districtLookup(this.schoolId, this.district as TDistricts);
    this.allTermYears = this.buildTermYears(this.data.school, 12);
    this.creditReqs = filter(CreditRequirements, (creditReq: any) => {
      return creditReq.orders.creditGapModal;
    }).map((creditReq: any) => {
      const newCreditReq = {
        ...creditReq,
      };
      newCreditReq.human = creditReq.human[schoolDistrict];
      newCreditReq.key = creditReq.camelCase;
      return newCreditReq;
    });

    /*
      The regex below accounts for the following conditions:
        1. number between 0-5 (inclusive)
        2. 2 decimal places
        3. a decimal that starts with '.'
    */
    const validCreditValueNumRegEx = /^((0(\.\d{1,2})?)|([1-5](\.\d{1,2})?)|(\.\d{1,2}))$/;

    this.manualCoursePlanForm = this.formBuilder.group(
      {
        termYear: new FormControl(null, [Validators.required]),
        gradReq: new FormControl(this.data?.gapPlan?.gradReq || null, [Validators.required]),
        creditValue: new FormControl(this.data?.gapPlan?.creditValue || null, [Validators.required, Validators.pattern(validCreditValueNumRegEx), Validators.max(5)]),
        courseCode: new FormControl(this.data?.gapPlan?.plan || null, [validateCourseCode(this.district)]),
        note: new FormControl(null),
      });

    this.manualCoursePlanForm.valueChanges.subscribe((val) => {
      this.manualCoursePlanForm.markAsDirty();
      this.hideErrorMessage = this.manualCoursePlanForm.controls.courseCode.valid ||
        this.manualCoursePlanForm.controls.courseCode.pristine ||
        (this.manualCoursePlanForm.controls.courseCode.value === '' && this.manualCoursePlanForm.controls.courseCode.touched);
      this.validateManualCoursePlanForm();
    });
  }

  public markAsTouched (control: AbstractControl) {
    control.markAsTouched();
  }

  public close (): void {
    super.close();
  }

  public onApply (): void {
    if (!this.isButtonDisabled) {
      const { termYear, gradReq, creditValue, courseCode, note } = this.manualCoursePlanForm.value;
      const gapPlan = {
        termYear: parseFloat(termYear),
        gradReq,
        creditValue: parseFloat(creditValue),
        plan: courseCode || null, // while this is the courseCode, on the BE, it is called the "plan"
        note: note || null,
        schoolId: this.schoolId,
      };
      this.applyPlan.emit(gapPlan);
      super.close({ gapPlan });
    }
  }

  // Build an array of term years, starting with the nextTermYear
  public buildTermYears (school, numTermYears: number) {
    const currentTermYear = this.imSchool.getCurrentTermYear(this.data.school);
    let nextTermYear = currentTermYear;
    let counter = 0;

    const allTermYears = [
      {
        human: this.utils.getHumanReadableTerm(currentTermYear),
        termYear: currentTermYear.toString(),
        key: currentTermYear.toString(),
      },
    ];

    // -1 because we already loaded the first item above
    const quitCount = numTermYears - 1;

    while (counter < quitCount) {
      nextTermYear = this.imSchool.getNextTermYear(school, nextTermYear);
      const human = this.utils.getHumanReadableTerm(nextTermYear);
      allTermYears.push({
        human,
        termYear: nextTermYear.toString(),
        key: nextTermYear.toString(),
      });
      counter++;
    }
    return allTermYears;
  }

  public clearSelection (fieldName: string): void {
    setTimeout(() => {
      this.manualCoursePlanForm.controls[fieldName].setValue('');
    }, 0);
  }

  public validateManualCoursePlanForm (): void {
    const {
      controls: {
        creditValue: { status: creditValueStatus },
        courseCode: { value: courseCodeValue, status: courseCodeStatus },
      },
      value: { termYear, gradReq, note },
    } = this.manualCoursePlanForm;

    let optionalFieldsValid: boolean;
    if (this.isNYC) {
      optionalFieldsValid = courseCodeStatus === 'VALID' || (note && courseCodeValue === null) || (note && courseCodeValue === '');
    } else {
      optionalFieldsValid = courseCodeValue || note;
    }

    const creditValueStatusValid = creditValueStatus === 'VALID';
    const requiredFieldsValid = (!!termYear && !!gradReq && creditValueStatusValid);
    const coursePlanValid = requiredFieldsValid && optionalFieldsValid;

    if (!this.manualCoursePlanForm.pristine) {
      if (coursePlanValid) {
        this.isButtonDisabled = false;
      } else {
        this.isButtonDisabled = true;
      }
    }
  }

  public setTermYear ($event: string) {
    const { termYear } = this.manualCoursePlanForm.controls;
    termYear.setValue($event);
  }

  public setGradReq ($event: string) {
    const { gradReq } = this.manualCoursePlanForm.controls;
    gradReq.setValue($event);
  }
}
