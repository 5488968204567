<div>
  <p class="mat-body-2">
    
    <wizard-filter-actions
      [data]="studentIdsNotScheduledInStars"
      [options]="options"
    ></wizard-filter-actions>

    <ng-container [ngPlural]="studentIdsNotScheduledInStars.length">
      <ng-template ngPluralCase="0"> students have </ng-template>
      <ng-template ngPluralCase="1"> student has </ng-template>
      <ng-template ngPluralCase="few"> students have </ng-template>
      <ng-template ngPluralCase="other"> students have </ng-template>
    </ng-container>

    been planned for {{countExamAdds}} {{ nextRegentsAdminDate}} Regents exams that are not
    currently scheduled in STARS.
  </p>

  <p class="mat-body-2">
    <wizard-filter-actions
      [data]="studentIdsWithExamsToBeDropped"
      [options]="options"
    ></wizard-filter-actions>

    <ng-container [ngPlural]="studentIdsWithExamsToBeDropped.length">
      <ng-template ngPluralCase="0"> students have </ng-template>
      <ng-template ngPluralCase="1"> student has </ng-template>
      <ng-template ngPluralCase="few"> students have </ng-template>
      <ng-template ngPluralCase="other"> students have </ng-template>
    </ng-container>

    been planned to drop {{countExamDrops}} {{ nextRegentsAdminDate}} Regents exams already
    scheduled in STARS.
  </p>

  <p class="mat-body-2">You can generate a data export formatted for STARS upload by clicking below.</p>

  <button class="step-action-button" mat-raised-button (click)="exportStarsDataAsCsv()"
    >Generate Stars export*</button
  >
  <p class="mat-caption">
    *The export will contain any student, in <i><b>ANY</b></i> cohort, who is planned to sit for or drop a Regents
    exam in the upcoming Regents administration.
  </p>
</div>