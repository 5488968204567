import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvPillModule } from '../../../../../nvps-libraries/design/nv-pill/nv-pill.module';
import { PillCellRenderer } from './pill-cell-renderer.component';

@NgModule({
  declarations: [PillCellRenderer],
  exports: [PillCellRenderer],
  imports: [CommonModule, NvPillModule],
})
export class PillCellRendererModule {}
