import { NgModule } from '@angular/core';

import { EditECFIKGuardianModalComponent } from './edit-ecfik-guardian-modal.component';
import { BaseModalModule } from '../base-modal.module';
import { NvFormFieldModule } from 'Src/nvps-libraries/design/nv-form-field/nv-form-field.module';
import { NvTextboxModule } from 'Src/nvps-libraries/design/nv-textbox/nv-textbox.module';
import { NvButtonModule } from 'projects/shared/nvps-libraries/design/nv-button/nv-button.module';
import { MatDialogModule } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NvNoteModule } from 'projects/shared/nvps-libraries/design/nv-note/nv-note.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NvTooltipModule } from '../../nv-tooltip/nv-tooltip.module';
import { NvIconModule } from 'projects/shared/nvps-libraries/design/nv-icon/nv-icon.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    BaseModalModule,
    NvFormFieldModule,
    NvTextboxModule,
    NvButtonModule,
    NvNoteModule,
    MatDialogModule,
    MatCheckboxModule,
    NvIconModule,
    NvTooltipModule,
  ],
  exports: [],
  declarations: [EditECFIKGuardianModalComponent],
  providers: [],
})
export class EditECFIKGuardianModule { }
