import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ApiService } from '../../shared/services/api-service/api-service';
import * as collegePathActions from '../actions/college-path-actions';

@Injectable()
export class CollegePathEffects {
  constructor(private actions$: Actions, private apiService: ApiService) {}

  loadCollegePath$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(collegePathActions.LOAD_COLLEGE_PATH),
      switchMap((action: { type: string; payload: any }) => {
        const { schoolId, pathType } = action.payload;
        return this.apiService.getPaths(schoolId, pathType).pipe(
          switchMap(paths => [new collegePathActions.LoadCollegePathSuccess(paths)]),
          catchError(error => of(new collegePathActions.LoadCollegePathFail(error))),
        );
      }),
    );
  });
}
