import {
  CLEAR_GRID_DATA,
  GridDataActions,
  LOAD_GRID_DATA,
  LOAD_GRID_DATA_SUCCESS,
  REFRESH_GRID_DATA,
  REFRESH_GRID_DATA_SUCCESS,
  SAVE_GRID_DATA,
  SET_REFRESH_GRID_DATA_IN_PROGRESS,
} from '../actions/grid-actions';

export const initialState = {
  loading: false,
  loaded: false,
  schoolId: null,
  gridType: null,
  columnDefs: [],
  loadedColumnDefs: [],
  rowData: [],
  hasInitialLoad: false,
  backgroundJobRunning: false,
  refreshGridDataRunning: false,
  filterHash: null,
  stateHash: null,
  scrollLeft: null,
  scrollTop: null,
  nextTermPlanningGridRunning: false,
};

export function reducer (state = initialState, action: GridDataActions) {
  let payload: any;
  switch (action.type) {
    case CLEAR_GRID_DATA:
      return {
        loading: false,
        loaded: false,
        schoolId: null,
        gridType: null,
        hasInitialLoad: false,
        backgroundJobRunning: false,
        refreshGridDataRunning: false,
        columnDefs: [],
        loadedColumnDefs: [],
        rowData: [],
        filterHash: null,
        stateHash: null,
        scrollLeft: null,
        scrollTop: null,
        nextTermPlanningGridRunning: false,
      };

    case LOAD_GRID_DATA:
      payload = action.payload;
      return {
        ...state,
        loaded: false,
        loading: true,
        ...payload,
      };

    case LOAD_GRID_DATA_SUCCESS:
      payload = action.payload;
      return {
        ...state,
        loaded: true,
        loading: false,
        hasInitialLoad: true,
        backgroundJobRunning: false,
        ...payload,
      };

    case SET_REFRESH_GRID_DATA_IN_PROGRESS:
      payload = action.payload;
      return {
        ...state,
        ...payload,
      };

    case REFRESH_GRID_DATA:
      payload = action.payload;
      return {
        ...state,
        loaded: false,
        loading: true,
        backgroundJobRunning: true,
        ...payload,
      };

    case REFRESH_GRID_DATA_SUCCESS: {
      const currentRowData = state.rowData;
      const fetchedData = action.payload.rowData;
      const fetchedDataEntities = fetchedData.reduce((acc, data) => {
        acc[data.OSIS_NUMBER] = data;
        return acc;
      }, {});
      const mergedRowData = currentRowData.map(data => {
        const id = data.OSIS_NUMBER;
        const oldData = Object.assign({}, data);
        const newData = fetchedDataEntities[id];
        return newData ? Object.assign(oldData, newData) : Object.assign({}, oldData);
      });
      return {
        ...state,
        loaded: true,
        loading: false,
        backgroundJobRunning: false,
        refreshGridDataRunning: false,
        rowData: mergedRowData,
      };
    }

    case SAVE_GRID_DATA:
      payload = action.payload;
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
}
