import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ApiService } from 'Src/ng2/shared/services/api-service/api-service';
import {
  ILoadStudentMapGrowthPayload,
  LoadStudentMapGrowthFail,
  LoadStudentMapGrowthSuccess,
  LOAD_STUDENT_MAP_GROWTH,
} from '../../actions/map-growth-actions/student-map-growth-actions';
import { IStudentMapGrowthEntity } from '../../reducers/map-growth-reducer/student-map-growth-reducer';

@Injectable()
export class StudentMapGrowthEffects {
  constructor(private actions$: Actions, private apiService: ApiService) {}

  loadStudentMapGrowth$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LOAD_STUDENT_MAP_GROWTH),
      switchMap((action: { type: string; payload: ILoadStudentMapGrowthPayload }) => {
        const { schoolId, studentId, calcConfig } = action.payload;
        return this.apiService.getStudentMapGrowth({ schoolId, studentId, calcConfig }).pipe(
          switchMap((studentMapGrowth: IStudentMapGrowthEntity) => {
            return [new LoadStudentMapGrowthSuccess(studentId, studentMapGrowth)];
          }),
          catchError(error => of(new LoadStudentMapGrowthFail(error))),
        );
      }),
    );
  });
}
