import { GridService } from 'Src/ng2/school/grid/services/grid.service';
import { EcfikStudentCaseload, IUser } from '../../../typings/interfaces/user.interface';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ECFIKStatusOptions, NeedsOptions } from '../../../modals/check-in-logs/check-in-log-modal.component';
import { ECFIKFormOptionsMap } from '../../../modals/check-in-logs/add-check-in-log/add-check-in-log.component';
import { IPickerOption } from 'projects/shared/nvps-libraries/design/nv-multi-picker/nv-multi-picker.interface';
import { ImUserCore } from './im-user-core';

export class ImUserEcfik extends ImUserCore {
  public isCaringAdult (user: IUser, schoolId: string): boolean {
    const isCaringAdult = user._ecfikPermissions?.studentCaseload?.find(
      caseload => caseload.role === 'CARING_ADULT' && caseload.schoolId === schoolId && !!caseload.studentIds.length,
    );
    return !!isCaringAdult;
  }

  public isProgramPoint (user: IUser, schoolId: string): boolean {
    const isProgramPoint = user._ecfikPermissions?.programPointDbns?.includes(schoolId);
    return !!isProgramPoint;
  }

  public isEcfikClusterUser (user: IUser): boolean {
    const isNetworkAndSchoolUser = user.delegatedRole === 'network_and_school';
    const isEcfikProgramClusterUser = user.nvRole.type === 'cluster' && (user.nvRole.clusterId === 'ECFIK_PROGRAM' || user.nvRole.clusterId === 'DEMO_ECFIK_PROGRAM');
    return isNetworkAndSchoolUser && isEcfikProgramClusterUser;
  }

  public isInEcfikCaseload (studentIds: string[], studentCaseload: EcfikStudentCaseload[]): boolean {
    if (!studentIds?.length || !studentCaseload?.length) return false;
    return studentIds.every(studentId => !!studentCaseload.find(caseload => caseload.studentIds.includes(studentId)));
  }

  public areAllStudentsEcfikEligible (gridService: GridService, studentIds: string[], schoolId: string, isAuthorized: boolean): Observable<boolean> {
    if (studentIds?.length && isAuthorized) {
      return gridService.fetchGridData$({
        schoolId,
        columnDefs: [{ graphQlKey: 'IS_ECFIK_ELIGIBLE', field: 'IS_ECFIK_ELIGIBLE', wildcardKey: null }],
        studentIds,
      })
        .pipe(
          take(1),
          map((res: { IS_ECFIK_ELIGIBLE: boolean }[]) => {
            const allStudentsAreEcfikEligible = res.every(({ IS_ECFIK_ELIGIBLE }) => IS_ECFIK_ELIGIBLE === true);
            return allStudentsAreEcfikEligible;
          }),
        );
    } else {
      return of(false);
    }
  }

  public categoriesIncludesEcfik (categories: string[]): boolean {
    return categories.includes('ECFIK');
  }

  public shapeOptionsByPermissions (notesCategoriesOptions: IPickerOption[], isInEcfikCaseload: boolean): IPickerOption[] {
    let options = notesCategoriesOptions;
    if (!notesCategoriesOptions?.find(option => option.key === 'ECFIK')) {
      if (isInEcfikCaseload) notesCategoriesOptions.push({ key: 'ECFIK', human: 'ECFIK' });
    } else {
      if (!isInEcfikCaseload) options = notesCategoriesOptions?.filter(option => option.key !== 'ECFIK');
    }
    return options;
  }

  public getEcfikFormOptionsmap (): ECFIKFormOptionsMap {
    return {
      type: {
        options: [
          { key: 'Student', human: 'Student' },
          { key: 'Family', human: 'Family' },
        ],
      },
      status: {
        options: Object.values(ECFIKStatusOptions).map((val) => {
          return { key: val, human: val };
        }),
      },
      needs: {
        options: Object.values(NeedsOptions).map((val) => {
          return { key: val, human: val };
        }),
      },
      serviceReferral: {
        options: null,
        description: 'Select this option if there was a service referral for this student',
      },
    };
  }
}
