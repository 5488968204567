<p class="mat-body-2">
  Your school’s On-Track Regents metrics should reflect the year-end by which you expect all students in your
  school to have passed each Regents exam in order to be on-track for graduation. Accurate metrics will customize
  for your school the recommendations made in Step 6 of the Wizard.
</p>

<button 
  mat-raised-button 
  class="step-action-button"
  (click)="navigateToRegentsSettings()"
>
Review Metrics
</button>