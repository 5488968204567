
      <p class="mat-body-2">
        {{ studentIdsWithRecRationalCourse.length }} students enrolled in Regents-culminating courses have not
        passed the corresponding exam at 65 and are not planned to sit for it during the upcoming administration.
        
        <a (click)="wizardHelpers.goToStateInNewTab('settings/master-program')">Click here</a> to
        
        see which of your courses are identified as Regents-culminating.
      </p>

      <ul class="mat-body-2" *ngFor="let data of regentsRecRationalsByExamName | keyvalue">
        <li *ngIf="data.value.studentIds.length > 0">
          <wizard-filter-actions [data]="data.value.studentIds" [options]="options"></wizard-filter-actions>
          <ng-container [ngPlural]="data.value.studentIds.length">
            <ng-template ngPluralCase="0"> student </ng-template>
            <ng-template ngPluralCase="1"> student </ng-template>
            <ng-template ngPluralCase="few"> students </ng-template>
            <ng-template ngPluralCase="other"> students </ng-template>
          </ng-container>

          in a Regents-culminating {{languageHelper[data.key].humanName}} course

          <ng-container [ngPlural]="data.value.studentIds.length">
            <ng-template ngPluralCase="0"> are </ng-template>
            <ng-template ngPluralCase="1"> is </ng-template>
            <ng-template ngPluralCase="few"> are </ng-template>
            <ng-template ngPluralCase="other"> are </ng-template>
          </ng-container>

          
          not planned for the {{ languageHelper[data.key].subject.humanName }} exam
        </li>
      </ul>

      <button
        class="step-action-button"
        mat-raised-button
        (click)="patchStudents(patches, totalExamsWithRecRationalCourse)"
        [disabled]="!totalExamsWithRecRationalCourse"
      >
        Plan {{totalExamsWithRecRationalCourse}}
  
        <ng-container [ngPlural]="totalExamsWithRecRationalCourse">
          <ng-template ngPluralCase="0"> exam </ng-template>
          <ng-template ngPluralCase="1"> exam </ng-template>
          <ng-template ngPluralCase="few"> exams </ng-template>
          <ng-template ngPluralCase="other"> exams </ng-template>
        </ng-container>
      </button>

