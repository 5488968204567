import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvIconModule } from 'projects/shared/nvps-libraries/design/nv-icon/nv-icon.module';
import { NvDashboardTileComponent } from './nv-dashboard-tile.component';

@NgModule({
  imports: [CommonModule, NvIconModule],
  declarations: [NvDashboardTileComponent],
  exports: [NvDashboardTileComponent],
})
export class NvDashboardTileModule {}
