import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { identity, startCase, map } from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { filter, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { getCurrentUser, getCurrentUserLoadedStatus } from '../../../store';
import { unsubscribeComponent } from '../../helpers/unsubscribe-decorator/unsubscribe-decorators.helper';
import { IUser, IUserEditable, IUserStatic } from '../../typings/interfaces/user.interface';
import { ApiService } from '../api-service/api-service';
import { ImUser } from '../im-models/im-user';
import { PortalConfig } from '../portal-config';
import { CookieService } from '../web-storage/cookie/cookie.service';
import { LocalStorageService } from '../web-storage/local-storage/local-storage.service';
import { WindowRef } from '../../../../../projects/shared/services/window-ref/windowRef';
import { PartnerTypes } from '../../typings/interfaces/partner.interface';

/* eslint-disable camelcase */
export interface IIntercomUserInfo {
  clusterId: string | null;
  effectiveRole: string;
  email: string | null;
  jobRole: string | null;
  name: string;
  schoolId: string | null;
  schoolType: string | null;
  shelterClusterId: string | null;
  shelterId: string | null;
  title: string | null;
  user_id: string;
}

export interface IIntercomUser {
  clusterName: string;
  schoolName: string;
  schoolType: string;
  shelterClusterName: string;
  shelterName: string;
  user_hash: string;
}

interface IIntercomUpdate extends IIntercomUserInfo, IIntercomUser {}

const CONNECTION_TYPES = {
  'NYC-DSS': 'dhsEmail',
  'google-oauth2': 'gafeEmail',
  NYCDOE: 'doeEmail',
};

@Injectable({
  providedIn: 'root',
})
@unsubscribeComponent
export class HelpDeskService {
  protected currentUserSubscription: Subscription;

  constructor (
    private apiService: ApiService,
    private cookieService: CookieService,
    private imUser: ImUser,
    private localStorageService: LocalStorageService,
    private portalConfig: PortalConfig,
    private store: Store<any>,
    private windowRef: WindowRef,
  ) {}

  public startHelpDeskService (): void {
    // delete all intercom cookies before booting
    this.deleteIntercomCookies();
    this.boot();
    this.currentUserSubscription = this.getCurrentUser$()
      .pipe(
        filter(identity), // this filter is needed because when a new district is selected, getCurrentUser emits null as the store is reset
        mergeMap(user => this.getIntercomUser(user._id)),
        withLatestFrom(this.getCurrentUser$()),
        tap(([intercomUser, user]) => {
          const userInfo = {
            ...this.getIntercomUserInfo(user),
            ...intercomUser,
          };
          this.updateIntercom(userInfo);
        }),
      )
      .subscribe();
  }

  private getCurrentUser$ (): Observable<IUser> {
    return this.store.select(getCurrentUserLoadedStatus).pipe(
      filter(identity),
      switchMap(() => this.store.select(getCurrentUser)),
    );
  }

  public getIntercomUserInfo (user: IUser): IIntercomUserInfo {
    const { firstName, lastName } = user.name;
    const userInfo = {
      clusterId: null,
      effectiveRole: this.imUser.getRoleType(user, PartnerTypes.SCHOOL) || this.imUser.getRoleType(user, PartnerTypes.SHELTER),
      email: this.getUserEmail(),
      jobRole: this.getJobRolesString(user.positions),
      name: `${firstName} ${lastName}`,
      schoolId: null,
      schoolType: null,
      shelterClusterId: null,
      shelterId: null,
      title: user.title,
      user_id: user._id,
    };

    if (this.imUser.isSchoolLevelUser(user)) {
      userInfo.schoolId = this.getSchoolIdsString(user.nvRole);
    }

    if (this.imUser.isClusterUser(user, PartnerTypes.SCHOOL)) {
      userInfo.clusterId = user.nvRole.clusterId;
    }

    if (this.imUser.isShelterUser(user)) {
      userInfo.shelterId = user.nvRoleShelter.shelterId;
    }

    if (this.imUser.isClusterUser(user, PartnerTypes.SHELTER)) {
      userInfo.shelterClusterId = user.nvRoleShelter.shelterClusterId;
    }

    return userInfo;
  }

  private getUserEmail (): string {
    const loginEmail = this.localStorageService.getItem('loginEmail');
    return loginEmail;
  }

  private getJobRolesString (positions: IUserEditable['positions']): string | null {
    const parsedPositions = positions || {};
    const jobRolesArr = map(parsedPositions, ({ jobRole }, contextOrgId) => `${jobRole}(${contextOrgId})`);
    let str = null;
    const hasJobRoles = jobRolesArr.length;
    if (hasJobRoles) {
      str = jobRolesArr.join(', ');
    }
    return str;
  }

  private getSchoolIdsString (nvRole: IUserStatic['nvRole']): string {
    const { schoolId, schoolIds } = nvRole;
    const parsedSchoolIds = schoolId ? [schoolId] : schoolIds;
    const str = parsedSchoolIds.join(', ');
    return str;
  }

  public updateIntercom (update: IIntercomUpdate): void {
    const window = this.windowRef.nativeWindow;
    const formattedPayload = this.formatPayload(update);
    window.Intercom('update', {
      ...formattedPayload,
    });
  }

  public getIntercomUser (userId: string): Observable<any> {
    const query = `{
      IntercomUser(userId: "${userId}") {
        clusterName
        schoolName
        schoolType
        shelterClusterName
        shelterName
        user_hash
        district
      }
    }`;
    const data = { query, fetchPolicy: 'no-cache' };
    return this.apiService.getIntercomUser(data);
  }

  public showHelp (): void {
    const window = this.windowRef.nativeWindow;
    window.Intercom('show');
  }

  public showMessages (): void {
    const window = this.windowRef.nativeWindow;
    window.Intercom('showMessages');
  }

  public showNewMessage (message?: string): void {
    const window = this.windowRef.nativeWindow;
    window.Intercom('showNewMessage', message);
  }

  public deleteIntercomCookies (): void {
    this.cookieService
      .getCookieArray()
      .filter(c => c.includes('intercom'))
      .forEach(c => {
        const splitCookie = c.split('=');
        const cookie = splitCookie[0].trim() + '=;';
        this.cookieService.expireCookie(cookie);
      });
  }

  public boot (): void {
    const appId = this.portalConfig.publicConfig.INTERCOM_APP_ID;
    const window = this.windowRef.nativeWindow;
    window.Intercom('boot', {
      app_id: appId,
    });
  }

  private formatPayload (update: IIntercomUpdate): any {
    const doNotFormat = ['email', 'name', 'user_hash', 'user_id'];
    return Object.keys(update).reduce((acc, key) => {
      if (doNotFormat.includes(key)) {
        acc[key] = update[key];
      } else {
        const formattedKey = startCase(key);
        acc[formattedKey] = update[key];
      }
      return acc;
    }, {});
  }
}
