<div class="multi-picker-menu-content-wrapper" nvFocusTrap>
  <div class="multi-picker-menu-searchbox-wrapper" *ngIf="hasSearchBox">
    <nv-textbox
      class="searchbox"
      icon="search-large-black"
      [hasClearBtn]="true"
      (clearValue)="clearSearchbox()"
      [textControl]="searchboxForm"
    ></nv-textbox>
  </div>
  <div class="option-pills-container" [ngClass]="{'one-column': oneColumnLayout}" #pillsContainer>
    <nv-pill-button *ngIf="hasAllButton" [isSmall]="true" [isInverted]="true" color="purple" (click)="clickPill('all')">
      All
    </nv-pill-button>
    <nv-pill-button
      *ngIf="hasClearButton"
      [isSmall]="true"
      [isInverted]="true"
      color="purple"
      (click)="clickPill('clear')"
    >
      Clear
    </nv-pill-button>
    <ng-container *ngFor="let opt of shownOptions">
      <nv-pill-button [isSmall]="true" [isInverted]="!isKeySelected(opt.key)" (click)="clickPill(opt.key)">
        {{ opt.human }}
      </nv-pill-button>
    </ng-container>
  </div>
</div>
