import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { reduce } from 'lodash';

interface IFinalCheck {
  data: string[];
  options: any;
}

@Component({
  selector: 'exam-load-step',
  templateUrl: './exam-load-step.component.html',
  styleUrls: ['./exam-load-step.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class ExamLoadStep implements OnInit {
  @Input() filteredStudents;

  public stepLabel: string = 'Do a final check';
  public studentsWithConflictingExams: IFinalCheck;
  public studentsPlannedForThreeOrMore: IFinalCheck;
  public studentsPlannedForExamsAttemptedThreeOrMoreTimes: IFinalCheck;
  public studentsNotPlannedForNeededExamWithNoExamsPlannedInNextAdmin: IFinalCheck;
  public studentsPlannedForRegentsExamsBeyondGradDate: IFinalCheck;

  ngOnInit (): void {
    const buckets = this.getStudentBuckets(this.filteredStudents);
    this.studentsWithConflictingExams = {
      data: buckets.studentsWithConflictingExams,
      options: {
        columnKeys: ['countOfRegentsExamsWithConclicts', 'regentsSchedForNextAdmin'],
      },
    };

    this.studentsPlannedForThreeOrMore = {
      data: buckets.studentsPlannedForThreeOrMore,
      options: {
        columnKeys: ['countOfRegentsSchedForNextAdmin', 'regentsSchedForNextAdmin', 'regentsNeededForOnTrackDiploma'],
      },
    };

    this.studentsPlannedForExamsAttemptedThreeOrMoreTimes = {
      data: buckets.studentsPlannedForExamsAttemptedThreeOrMoreTimes,
      options: {
        columnKeys: [
          'regentsSchedForNextAdmin',
          'countOfRegentsSchedNextAdminAttemptedThreeOrMoreTimes',
          'regentsSchedNextAdminAttemptedThreeOrMoreTimes',
        ],
      },
    };

    this.studentsNotPlannedForNeededExamWithNoExamsPlannedInNextAdmin = {
      data: buckets.studentsNotPlannedForNeededExamWithNoExamsPlannedInNextAdmin,
      options: {
        columnKeys: ['regentsNeededForOnTrackDiploma', 'regentsSchedForNextAdmin'],
      },
    };

    this.studentsPlannedForRegentsExamsBeyondGradDate = {
      data: buckets.studentsPlannedForRegentsExamsBeyondGradDate,
      options: {
        columnKeys: [
          'plannedGraduationDate',
          'countOfRegentsExamsPlannedBeyondStudentsGradDate',
          'regentsExamsPlannedBeyondStudentsGradDate',
        ],
      },
    };
  }

  ngOnChanges ({ filteredStudents }) {
    if (filteredStudents && !filteredStudents.isFirstChange()) {
      this.ngOnInit();
    }
  }

  private getStudentBuckets (students) {
    const baseObj = {
      studentsWithConflictingExams: [],
      studentsPlannedForThreeOrMore: [],
      studentsPlannedForExamsAttemptedThreeOrMoreTimes: [],
      studentsNotPlannedForNeededExamWithNoExamsPlannedInNextAdmin: [],
      studentsPlannedForRegentsExamsBeyondGradDate: [],
    };
    return reduce(
      students,
      (
        result,
        {
          studentId,
          countOfRegentsExamsWithConclicts: examConflicts,
          countOfRegentsSchedForNextAdmin: examLoad,
          countOfRegentsSchedNextAdminAttemptedThreeOrMoreTimes: examsPlannedWith3Attempts,
          countOfRegentsNeededForOnTrackDiploma: examsNeeded,
          countOfRegentsExamsPlannedBeyondStudentsGradDate: examsBeyond,
        },
      ) => {
        if (examConflicts > 0) result.studentsWithConflictingExams.push(studentId);
        if (examLoad >= 3) result.studentsPlannedForThreeOrMore.push(studentId);
        if (examsPlannedWith3Attempts > 0) result.studentsPlannedForExamsAttemptedThreeOrMoreTimes.push(studentId);
        if (examLoad === 0 && examsNeeded > 0) {
          result.studentsNotPlannedForNeededExamWithNoExamsPlannedInNextAdmin.push(studentId);
        }
        if (examsBeyond > 0) {
          result.studentsPlannedForRegentsExamsBeyondGradDate.push(studentId);
        }
        return result;
      },
      baseObj,
    );
  }
}
