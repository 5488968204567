
<div class="app-headline">
    <h3>Planned 
      <span>{{nvNextRegentsAdminDate}}</span> 
      Regents Exams By Prep Status</h3>
</div>

<div class="nv-table-container">
  <table class="nv-table nv-table-dense">
    <thead>
      <tr>
        <th></th>
        <th class="th-row-1" *ngFor="let exam of tableCols">{{exam.monthDay}} {{exam.amPm}}</th>
      </tr>
      <tr>
        <th></th>
        <th class="th-row-2" *ngFor="let exam of tableCols">{{exam.shortName}}</th>
      </tr>
    </thead>

    <tbody>
      <tr class="row-no-prep">
        <td class="mat-subtitle-2">No Support</td>
        <td
          *ngFor="let col of tableCols"
          [ngClass]="{isHovered: hover && tableRows.rowNoPrep[col.key].students.length > 0, noStudents: tableRows.rowNoPrep[col.key].students.length === 0}"
          (mouseenter)="hover = true"
          (mouseleave)="hover = false"
        >
          <wizard-filter-actions
            [data]="tableRows.rowNoPrep[col.key].students"
            [options]="tableRows.rowNoPrep[col.key].options"
            [additionalActions]="studentSupportAction"
          >
          </wizard-filter-actions>
        </td>
      </tr>

      <tr class="row-course-failing">
        <td class="mat-subtitle-2">Course Only: Curr. Failing/Borderline</td>
        <td
          *ngFor="let col of tableCols"
          [ngClass]="{isHovered: hover && tableRows.rowFailing[col.key].students.length > 0, noStudents: tableRows.rowFailing[col.key].students.length === 0}"
          (mouseenter)="hover = true"
          (mouseleave)="hover = false"
        >
          <wizard-filter-actions
            [data]="tableRows.rowFailing[col.key].students"
            [options]="tableRows.rowFailing[col.key].options"
            [additionalActions]="studentSupportAction"
          >
          </wizard-filter-actions>
        </td>
      </tr>

      <tr class="row-course-passing">
        <td class="mat-subtitle-2">Course Only: Curr. Passing</td>
        <td
          *ngFor="let col of tableCols"
          [ngClass]="{isHovered: hover && tableRows.rowPassing[col.key].students.length > 0, noStudents: tableRows.rowPassing[col.key].students.length === 0}"
          (mouseenter)="hover = true"
          (mouseleave)="hover = false"
        >
          <wizard-filter-actions
            [data]="tableRows.rowPassing[col.key].students"
            [options]="tableRows.rowPassing[col.key].options"
            [additionalActions]="studentSupportAction"
          >
          </wizard-filter-actions>
        </td>
      </tr>

      <tr class="row-additional-prep">
        <td class="mat-subtitle-2">Additional Prep Only</td>
        <td
          *ngFor="let col of tableCols"
          [ngClass]="{isHovered: hover && tableRows.rowAdditionalPrep[col.key].students.length > 0, noStudents: tableRows.rowAdditionalPrep[col.key].students.length === 0}"
          (mouseenter)="hover = true"
          (mouseleave)="hover = false"
        >
          <wizard-filter-actions
            [data]="tableRows.rowAdditionalPrep[col.key].students"
            [options]="tableRows.rowAdditionalPrep[col.key].options"
            [additionalActions]="studentSupportAction"
          >
          </wizard-filter-actions>
        </td>
      </tr>

      <tr class="row-course-additional-prep">
        <td class="mat-subtitle-2">Course & Additional Prep</td>
        <td
          *ngFor="let col of tableCols"
          [ngClass]="{isHovered: hover && tableRows.rowCourseAdditionalPrep[col.key].students.length > 0, noStudents: tableRows.rowCourseAdditionalPrep[col.key].students.length === 0}"
          (mouseenter)="hover = true"
          (mouseleave)="hover = false"
        >
          <wizard-filter-actions
            [data]="tableRows.rowCourseAdditionalPrep[col.key].students"
            [options]="tableRows.rowCourseAdditionalPrep[col.key].options"
            [additionalActions]="studentSupportAction"
          >
          </wizard-filter-actions>
        </td>
      </tr>

      <tr>
        <td class="mat-subtitle-2">Total Planned</td>
        <td *ngFor="let col of tableCols">{{tableColTotals[col.key]}}</td>
      </tr>
    </tbody>
  </table>
</div>

