<div *ngIf="studentsWithoutGradPlanOrDate.length" flex>
  <p class="mat-body-2">
    Of {{ filteredStudents.length }}

    <ng-container [ngPlural]="studentsWithoutGradPlanOrDate.length">
      <ng-template ngPluralCase="0"> active students </ng-template>
      <ng-template ngPluralCase="1"> active student </ng-template>
      <ng-template ngPluralCase="other"> active students </ng-template>
    </ng-container>
    
    <wizard-filter-actions [data]="studentsWithoutGradPlanOrDate" [options]="options"></wizard-filter-actions>
    
    are missing a planned diploma type and/or planned graduation date, or have a planned graduation date in the past.
  </p>

  <button mat-raised-button class="step-action-button" (click)="goToGradPlanningWizard(schoolId)">Go to Grad Planning Wizard</button>
</div>