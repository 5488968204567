<div class="regents-exams-supports-table">
  <div class="app-headline">
    <h3>Regents Preps Supports</h3>
  </div>

  <div class="regents-support-filters" layout="row">

    <mat-form-field>
      <mat-label>Preps For</mat-label>
      <mat-select [formControl]="supportExamSubjFilter">
        <mat-select-trigger>
          {{supportExamSubjFilter.value}}
        </mat-select-trigger>
        <mat-option *ngFor="let exam of examSubjectsList" [value]="exam.k">{{exam.human}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Lead</mat-label>
      <mat-select [formControl]="supportLeadFilter">
        <mat-select-trigger>
          {{ supportLeadFilter.value }}
        </mat-select-trigger>
        <mat-option *ngFor="let lead of supportLeadsList" [value]="lead.email">{{lead.email}}</mat-option>
      </mat-select>
    </mat-form-field>

    <button (click)="goToSupportSettings()" mat-raised-button class="add-support-button step-action-button">Create support</button>

  </div>

  <div class="nv-table-container">
    <table class="nv-table nv-table-dense">
      <thead>
        <tr>
          <th class="col-name-lead col-header-button" (click)="sortBy('name')">
            <span [ngClass]="{'active-sort': activeSort('name')}">Name</span>
            <span
              class="sort-direction"
              [ngClass]="{'reverse-sort': sortDirection === 'asc'}"
              *ngIf="activeSort('name')"
            ></span>
          </th>

          <th class="col-dates col-header-button" (click)="sortBy('_startDate')">
            <span [ngClass]="{'active-sort': activeSort('_startDate')}">Date</span>
            <span
              class="sort-direction"
              [ngClass]="{'reverse-sort': sortDirection === 'asc'}"
              *ngIf="activeSort('_startDate')"
            ></span>
          </th>

          <th class="col-meets-at">Meets On</th>

          <th class="col-preps-for col-header-button" (click)="sortBy('metaData.examSubject')">
            <span [ngClass]="{'active-sort': activeSort('metaData.examSubject')}">Preps For</span>
            <span
              class="sort-direction"
              [ngClass]="{'reverse-sort': sortDirection === 'asc'}"
              *ngIf="activeSort('metaData.examSubject')"
            ></span>
          </th>

          <th class="col-enrolled col-header-button" (click)="sortBy('_enrollments')">
            <span [ngClass]="{'active-sort': activeSort('_enrollments')}">Enrollment</span>
            <span
              class="sort-direction"
              [ngClass]="{'reverse-sort': sortDirection === 'asc'}"
              *ngIf="activeSort('_enrollments')"
            ></span>
          </th>

          <th class="col-status col-header-button" (click)="sortBy('_statusObj.order')">
            <span [ngClass]="{'active-sort': activeSort('_statusObj.order')}">Status</span>
            <span
              class="sort-direction"
              [ngClass]="{'reverse-sort': sortDirection === 'asc'}"
              *ngIf="activeSort('_statusObj.order')"
            ></span>
          </th>

          <th class="col-actions" colspan="2">Options</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngIf="schoolSupports.length === 0">
          <td colspan="7">No supports found.</td>
        </tr>

        <tr *ngFor="let s of data " [ngClass]="{'hidden-item': !s._matchesFilter}">
          <td class="col-name-lead">
            <div  nvTooltip [tooltipData]="s._leadHumanName">{{s.name}}</div>
          </td>

          <td class="col-dates">{{s._startAndEndDateString}}</td>

          <td class="col-meets-at">
            <div nvTooltip [tooltipData]="s.location">
              <div>{{s._meetsOnScheduleString}}</div>
              <div>{{s._startAndEndTimeString}}</div>
            </div>
          </td>

          <td class="col-preps-for"><span>{{s._examSubjectPrepsFor}}</span></td>

          <td class="col-enrolled"><span>{{s._enrollments}}</span></td>

          <td class="col-status"><span>{{s._statusObj.currentState}}</span></td>

          <td class="col-actions">

            <button aria-label="Open table options menu" mat-icon-button  [matMenuTriggerFor]="menu"><mat-icon class="material-icons">more_vert</mat-icon></button>
            <!-- #enddocregion mat-menu-trigger-for -->
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="goToSupportSettings()">
                <mat-icon class="material-icons mat-18">content_copy</mat-icon>
                Duplicate
              </button>

              <button *ngIf="s._canBeEdited" mat-menu-item (click)="goToSupportSettings()">
                <mat-icon class="material-icons mat-18">create</mat-icon>
                Edit
              </button>

              <button *ngIf="s._canBeEdited" mat-menu-item  (click)="goToSupportSettings()">
                <mat-icon class="material-icons mat-18">delete</mat-icon>
                Delete
              </button>

            </mat-menu>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
