import { PaginatorControlsComponent } from './paginator-controls.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvIconButtonModule } from 'Src/nvps-libraries/design/nv-icon-button/nv-icon-button.module';

@NgModule({
  imports: [CommonModule, NvIconButtonModule],
  declarations: [PaginatorControlsComponent],
  exports: [PaginatorControlsComponent],
})
export class PaginatorControlsModule { }
