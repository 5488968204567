import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import * as Rollbar from 'rollbar';
import * as _ from 'lodash';
import { ImSchool } from 'Src/ng2/shared/services/im-models/im-school';
import { RollbarService } from 'Src/ng2/shared/services/rollbar/rollbar.service';

interface ICohortBucket {
  column: string;
  bucket: string[];
}

export interface ITable {
  [key: string]: {
    cohortBuckets: ICohortBucket[];
    className?: string;
  };
}

interface ISummary {
  studentIdsCreditMaxGaps: string[];
  studentIdsCreditMaxGapsNoPlan: string[];
}

@Component({
  selector: 'credit-gaps-class-table',
  templateUrl: './credit-gaps-class-table.component.html',
  styleUrls: ['./styles/credit-gaps-generic-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class CreditGapsWizardClassTable implements OnInit {
  @Input() currentFilter: string;
  @Input() filteredStudents: any; // flattenedSdcStudents

  public uiReady: boolean;
  public nextFourGraduatingCohorts: string[];
  public tableHeaders: string[];
  public tableBase: ITable;
  public populatedTable: ITable;
  public selectedToggle: string;
  public tableSummary: any;

  public filterOptions: { columnKeys: string[] } = {
    columnKeys: ['gradPlan', 'creditMaxGaps', 'creditMaxGapsNoPlan'],
  };

  public toggleMap = {
    creditMaxGaps: 'Students with Credit Gaps',
    creditMaxGapsNoPlan: 'Students with Unaddressed Credit Gaps',
  }

  constructor (
    @Inject(RollbarService) private rollbar: Rollbar,
    private imSchool: ImSchool,
  ) {}

  ngOnInit () {
    this.setUi();
  }

  setUi (): void {
    this.uiReady = true;
    this.selectedToggle = 'creditMaxGaps';
    this.nextFourGraduatingCohorts = this.imSchool.getCurrentCohorts();
    this.tableHeaders = this.generateTableHeaders();
    this.tableBase = this.generateBaseObjectForCohorts();
    this.populatedTable = this.generateTable();
    this.tableSummary = this.generateTableSummary();
  }

  public orderTableRows = (a, b) => a.key;

  // fired on toggle change
  public rebuildTable (): void {
    this.populatedTable = this.generateTable();
  }

  // later populated by threshold logic in #generateTable
  private generateBaseObjectForCohorts (): ITable {
    const creditGapThresholdsKeys = ['5.01+', '2.01-5.0', '1.01-2.0', '0.01-1.0', '0.0', 'Total'];
    const classNames = ['red', 'pink', 'orange', 'yellow', 'green', 'white'];
    const cohortsPlusTotal = ['Total'].concat(this.nextFourGraduatingCohorts);
    return _.reduce(
      creditGapThresholdsKeys,
      (result, threshold: string, index) => {
        result[threshold] = { cohortBuckets: [], className: classNames[index] };
        _.each(cohortsPlusTotal, cohort => {
          result[threshold].cohortBuckets.push({ column: cohort, bucket: [] });
        });
        return result;
      },
      {},
    );
  }

  // cohorts of students freshman-to-senior
  private generateTableHeaders (): string[] {
    const classOfHeaders = _.map(this.nextFourGraduatingCohorts, (cohort, i) => {
      if (!i) return `Class of ${cohort} and Older`;
      else return `Class of ${cohort}`;
    });
    const headersWithTotal = ['Total'].concat(classOfHeaders);
    return headersWithTotal;
  }

  private generateTable (): ITable {
    const tableBase = _.cloneDeep(this.tableBase);
    const table = _.reduce(
      this.filteredStudents,
      (table, student: any) => {
        const { studentId, cohort } = student;
        const superSeniorThreshold = +_.first(this.nextFourGraduatingCohorts);
        let cohortIndex = this.nextFourGraduatingCohorts.indexOf(cohort);

        // if student is super-senior we want to include with threshold year
        if (+cohort < superSeniorThreshold) cohortIndex = 0;
        // if student is beyond 4 years out -- unlikely, but guarding due to term flip timing
        if (cohortIndex === -1) return table;

        // index-0 is total, add aligns index with proper cohort
        cohortIndex += 1;

        // dynamic based on selected toggle value
        const creditGaps = student[this.selectedToggle];

        // bucket out studentIds based on creditGaps thresholds
        if (creditGaps <= -5.01) {
          // push to threshold row total, total is the first column
          table['5.01+'].cohortBuckets[0].bucket.push(studentId);
          // push to corresponding cohort for threshold, could be any column
          table['5.01+'].cohortBuckets[cohortIndex].bucket.push(studentId);
          // push to total row for corresponding cohort
          table.Total.cohortBuckets[cohortIndex].bucket.push(studentId);
          // push to total of total column
          table.Total.cohortBuckets[0].bucket.push(studentId);
        } else if (creditGaps <= -2.01 && creditGaps >= -5) {
          table['2.01-5.0'].cohortBuckets[0].bucket.push(studentId);
          table['2.01-5.0'].cohortBuckets[cohortIndex].bucket.push(studentId);
          table.Total.cohortBuckets[cohortIndex].bucket.push(studentId);
          table.Total.cohortBuckets[0].bucket.push(studentId);
        } else if (creditGaps <= -1.01 && creditGaps >= -2) {
          table['1.01-2.0'].cohortBuckets[0].bucket.push(studentId);
          table['1.01-2.0'].cohortBuckets[cohortIndex].bucket.push(studentId);
          table.Total.cohortBuckets[cohortIndex].bucket.push(studentId);
          table.Total.cohortBuckets[0].bucket.push(studentId);
        } else if (creditGaps <= -0.01 && creditGaps >= -1.0) {
          table['0.01-1.0'].cohortBuckets[0].bucket.push(studentId);
          table['0.01-1.0'].cohortBuckets[cohortIndex].bucket.push(studentId);
          table.Total.cohortBuckets[cohortIndex].bucket.push(studentId);
          table.Total.cohortBuckets[0].bucket.push(studentId);
        } else if (creditGaps === 0) {
          table['0.0'].cohortBuckets[0].bucket.push(studentId);
          table['0.0'].cohortBuckets[cohortIndex].bucket.push(studentId);
          table.Total.cohortBuckets[cohortIndex].bucket.push(studentId);
          table.Total.cohortBuckets[0].bucket.push(studentId);
        } else this.rollbar.error('Warning: Student fell thru Credit-Gaps thresholds');
        return table;
      },
      tableBase,
    );
    return table;
  }

  private generateTableSummary (): ISummary {
    return _.reduce(
      this.filteredStudents,
      (summary, { studentId, creditMaxGaps, creditMaxGapsNoPlan }) => {
        if (creditMaxGapsNoPlan < 0) summary.studentIdsCreditMaxGapsNoPlan.push(studentId);
        if (creditMaxGaps < 0) summary.studentIdsCreditMaxGaps.push(studentId);
        return summary;
      },
      {
        studentIdsCreditMaxGaps: [],
        studentIdsCreditMaxGapsNoPlan: [],
      },
    );
  }
}
