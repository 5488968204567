import { each, includes, pull, flatten, uniq, map, isArray } from 'lodash';
import { ImStudent, IMethodDependency } from './im-student.service';
import { MAP_GROWTH_CURR_GROWTH_TERM, MAP_GROWTH_CURR_SCHOOL_YEAR, MAP_GROWTH_CURR_TERM } from '../../constants/current-school-year.constant';
import { Injectable } from '@angular/core';
import { IStudent } from '../../../shared/typings/interfaces/student.interface';
import { UtilitiesService } from '../utilities/utilities.service';
import { IGoalProperties, IMapGrowth } from '../../typings/interfaces/map-growth.interface';

/**
 * `@depends` decorator
 *
 * Use this decorator to state that an ImStudent method depends on certain paths, joins or other methods:
 *    @depends({ paths: ['studentDetails.name'], joins: ['courseDiffs'], methods: ['_getSomething']})
 *    static fullName(student) {  }
 *
 * @param depends Object an object with three optional keys: `paths`, `joins` and `methods`
 * @return {dependencyDecorator}
 */
function depends (depends: IMethodDependency) {
  return function dependencyDecorator (target: any, key: string, descriptor: PropertyDescriptor) {
    descriptor.value.depends = depends;
    return descriptor;
  };
}

@Injectable()
export class ImStudentMapGrowth {
  constructor (
      private ImStudent: ImStudent,
      private utilitiesService: UtilitiesService,
  ) {}

  /**
   * Removes fields from `student` that are not included in the dependent paths or joins
   *
   * @param method
   * @returns {Student} masked student
   */
  maskedStudentForMethod (student: IStudent, method) {
    const paths = this.pathsFor(method);
    const joins = this.joinsFor(method);
    each(joins, join => {
      paths.push(`join_${join}`);
    });
    return this.utilitiesService.generatePatch(student, paths);
  }

  _dependentMethods (method) {
    const resolvedMethods = [];
    const unresolvedMethods = [];

    // See here for the dependency resolution algorithm used:
    // https://www.electricmonk.nl/log/2008/08/07/dependency-resolving-algorithm/
    const resolveMethods = (method, resolvedMethods, unresolvedMethods) => {
      unresolvedMethods.push(method);
      const methods = (this[method].depends || {}).methods || [];
      each(methods, m => {
        if (!includes(resolvedMethods, m)) {
          if (includes(unresolvedMethods, m)) {
            throw new Error(`Circular method dependency detected: ${method} -> ${m}`);
          }
          resolveMethods(m, resolvedMethods, unresolvedMethods);
        }
      });
      resolvedMethods.push(method);
      pull(unresolvedMethods, method);
    };

    resolveMethods(method, resolvedMethods, unresolvedMethods);
    return resolvedMethods;
  }

  /**
   * Traverses the method dependency graph and returns all of the joins that `method` depends on
   *
   * @param method a ImStudent method
   * @return {Array} and array of joins
   */
  joinsFor (method) {
    if (!this[method]) throw new Error(`method '${method}' does not exist`);

    if (!(this[method].depends || {}).methods) return (this[method].depends || {}).joins;
    const dependentMethods = this._dependentMethods(method);
    const dependentJoins = uniq(
      flatten(
        map(dependentMethods, m => {
          const depends = this[m].depends;
          if (!depends) {
            console.warn(`\`${m}\` was detected as a dependent method, but it didn't register any dependencies itself`);
            return [];
          }
          return depends.joins || [];
        }),
      ),
    );
    return dependentJoins;
  }

  pathsFor (method) {
    if (!this[method]) throw new Error(`method '${method}' does not exist`);

    if (!(this[method].depends || {}).methods) {
      const paths = (this[method].depends || {}).paths;
      if (!isArray(paths)) {
        console.warn(
              `this.pathsFor('${method}') was called, but ${method} didn't declare any dependent paths. ` +
                `If ${method} doesn't depend on any paths, annotate it with \`@depends({ paths: [] })\``,
        );
      }
      return paths;
    }
    const dependentMethods = this._dependentMethods(method);
    const dependentPaths = uniq(
      flatten(
        map(dependentMethods, m => {
          const depends = this[m].depends;
          if (!depends) {
            console.warn(`\`${m}\` was detected as a dependent method, but it didn't register any dependencies itself`);
            return [];
          }
          return depends.paths || [];
        }),
      ),
    );
    return dependentPaths;
  }

    @depends({
      paths: ['mapGrowth.history'],
      methods: [],
    })
  getMapGrowthData (
    doc: IMapGrowth,
    desiredField: string,
    course?: string,
    term?: string,
  ) {
    const assessment = this.findUsedForGrowthAssessment(doc, term, course);
    const result = desiredField === 'testStartDate' ? assessment?.general[desiredField] : assessment?.scores[desiredField];
    return result;
  }

  @depends({
    paths: ['mapGrowth.history'],
    methods: [],
  })
    getMapGrowthScores (
      doc: IMapGrowth,
      desiredField: string,
      course?: string,
      season?: string,
    ) {
      const assessment = this.findUsedForGrowthAssessment(doc, season, course);
      return assessment?.scores[desiredField];
    }

  @depends({
    paths: ['mapGrowth.history'],
    methods: [],
  })
  getMapGrowthGrowth (
    doc: IMapGrowth,
    desiredField: string,
    course?: string,
    season?: string,
    growthTerm?: string,
  ) {
    const assessment = this.findUsedForGrowthAssessment(doc, season, course);
    return assessment?.growth[growthTerm][desiredField];
  }

  @depends({
    paths: ['mapGrowth.history'],
    methods: [],
  })
  getMapGrowthGoals (
    doc: IMapGrowth,
    course?: string,
    domain?: string,
    desiredField?: string,
  ) {
    const assessment = this.findUsedForGrowthAssessment(doc, MAP_GROWTH_CURR_TERM, course);
    if (!assessment) {
      return null; // no assessment data for the specific year/term/course combo, returns null
    } else {
      const goal = assessment.goals?.find((goal) => domain === goal.goalName);
      const goalData = goal && goal[desiredField];
      return goalData;
    }
  }

  @depends({
    paths: ['mapGrowth.history'],
    methods: [],
  })
  getMapGrowthMaxLexileAssessmentInTermData (
    doc: IMapGrowth,
    desiredField: string,
    course?: string,
    term?: string,
  ) {
    const assessment = doc.mapGrowth.history.find(({ general, scores }) => {
      const mostRecentLexileCourseForCurrSchYear =
        (general.termNameSeason === term) &&
          (general.course === course) &&
          (general.termNameYear === MAP_GROWTH_CURR_SCHOOL_YEAR) &&
          scores.isHighestLexileScoreInItsYearAndTerm;

      return mostRecentLexileCourseForCurrSchYear;
    });

    const value = (desiredField === 'testStartDate') ? assessment?.general[desiredField] : assessment?.scores[desiredField];
    return value;
  }

  @depends({
    paths: ['mapGrowth.history'],
    methods: [],
  })
  findUsedForGrowthAssessment (
    doc: IMapGrowth,
    season: string,
    course: string,
  ) {
    const assmnt = doc.mapGrowth.history.find(
      ({ general }) =>
        general.termNameSeason === season &&
        general.course === course &&
        general.termNameYear === MAP_GROWTH_CURR_SCHOOL_YEAR &&
        general.isUsedForGrowth,
    );
    return assmnt;
  }
}
