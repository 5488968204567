<base-modal
  class="base-modal postsec-data-sources-modal"
  [title]="title"
  [isProfileMode]="isProfileMode"
>
  <div class="segmented-control-holder">
    <nv-segmented-control
      [options]="controlOptions"
      [index]="controlIndex"
      (clicked)="onSegmentToggle($event)"
    ></nv-segmented-control>
  </div>

  <nv-note size="large" [priority]="infoIconName"> {{ infoText }} </nv-note>

  <ng-container *ngIf="controlIndex === 0">
    <list-container
      [groupingData$]="groupingData$"
      [sortKey$]="sortKey$"
      [sortDirection$]="sortDirection$"
      [filterFormControl]="filterFormControl"
      [batchActionsMode$]="batchActionsMode$"
      [columns]="columns"
      [columnIndexMap]="columnIndexMap"
      [listConfig]="listConfig"
    >
    </list-container>
  </ng-container>

  <div *ngIf="controlIndex === 1" class="hesc-list">
    <list-container
      [groupingData$]="groupingData$"
      [sortKey$]="sortKey$"
      [sortDirection$]="sortDirection$"
      [filterFormControl]="filterFormControl"
      [batchActionsMode$]="batchActionsMode$"
      [columns]="columns"
      [columnIndexMap]="columnIndexMap"
      [listConfig]="listConfig"
    >
    </list-container>
  </div>

  <!-- Button -->
  <div id="button-holder">
    <nv-button (clickButton)="onConfirm()"> {{ confirmationButtonLabel }} </nv-button>
  </div>
</base-modal>
