import { ObjectCache } from 'Src/ng2/shared/services/object-cache/object-cache.service';
import { Router, ActivatedRoute } from '@angular/router';
import { IPriorityGroupDefinition } from '../../../../services/regents-prep-priority-groupings/regents-prep-priority-groupings-transfer.service';
import { each, map, find } from 'lodash';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { RegentsPlanningPriorityGroupingsService } from 'Src/ng2/school/sdc/services/regents-planning-priority-groupings/regents-planning-priority-groupings.service';
import { UrlPathService } from 'Src/ng2/shared/services/url-path-service/url-path.service';

interface IPriorityGroupTable extends IPriorityGroupDefinition {
  studentIds: string[];
}

@Component({
  selector: 'priority-group-step',
  templateUrl: './priority-group-step.component.html',
  styleUrls: ['../../shared-steps/shared-step-styles.scss', './priority-group-step.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class PriorityGroupStep implements OnInit {
  @Input() filteredStudents
  public stepLabel: string = 'Review students individually by priority group';
  public priorityGroupsTable: IPriorityGroupTable[];
  public options;
  public currentFilter;
  private schoolId: string;

  constructor (
    private regentsPlanningPriorityGroupingsService: RegentsPlanningPriorityGroupingsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private objectCache: ObjectCache,
    private urlPathService: UrlPathService,
  ) {}

  ngOnInit (): void {
    this.schoolId = this.activatedRoute.snapshot.params.schoolId;
    this.priorityGroupsTable = this.buildPriorityGroupTable();
    this.options = {
      columnKeys: [
        'cohort',
        'regentsNeededForOnTrackDiploma',
        'regentsNeededForOnTrackDiplomaNotSched',
        'regentsSchedForNextAdmin',
      ],
    };
  }

  ngOnChanges ({ filteredStudents }) {
    if (filteredStudents && !filteredStudents.isFirstChange()) {
      this.ngOnInit();
    }
  }

  private buildPriorityGroupTable (): IPriorityGroupTable[] {
    const priorityGrouping = this.regentsPlanningPriorityGroupingsService.getPriorityGroupsByStudentYearFilter();
    const priorityGroupsWithAppendedField = map(priorityGrouping, (group: IPriorityGroupTable) => {
      group.studentIds = [];
      return group;
    });
    each(this.filteredStudents, ({ regentsPlanningPriorityGrouping, studentId }: any) => {
      // sdc student
      const matchedPriorityGroup: any = find(priorityGroupsWithAppendedField, {
        studentPriorityGroupingValue: regentsPlanningPriorityGrouping,
      });
      if (!matchedPriorityGroup) return;
      matchedPriorityGroup.studentIds.push(studentId);
    });
    return priorityGroupsWithAppendedField.slice(0, -1) as any; // remove `none`
  }

  goToProfile (studentIds, schoolId) {
    const filter = this.objectCache.cacheObject({ studentId: studentIds });
    const url = this.urlPathService.computeDistrictUrlPath(`/school/${schoolId}/student`);
    this.router.navigate([url], { queryParams: { filter }, state: { sdcKey: this.activatedRoute.snapshot.data.sdcKey } });
  }
}
