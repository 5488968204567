import { ISchoolSupport } from 'Src/ng2/shared/typings/interfaces/support.interface';
import { UtilitiesService } from 'Src/ng2/shared/services/utilities/utilities.service';

import { IStudentSupport, TValidStudentSupportBackendStatuses } from 'Src/ng2/shared/typings/interfaces/student-support.interface';
import { each, reduce } from 'lodash';
import { ImSupport } from 'Src/ng2/shared/services/im-models/im-support/im-support.service';
import { StudentSupportStatuses } from 'Src/ng2/shared/constants/student-support-statuses.constant';
import { Injectable } from '@angular/core';
import * as moment from 'moment';

const displayFormat = 'MM/DD/YYYY';
const comparisonFormat = 'YYYY-MM-DD';

@Injectable()
export class ImStudentSupport {
  constructor (private imSupport: ImSupport, private utils: UtilitiesService) {}

  getStudentIdFrom_Id (studentSupport: IStudentSupport): string {
    const studentId = studentSupport.student.studentId;
    return studentId && studentId.replace(studentSupport.schoolId, '');
  }

  // returns string OR bool
  hasStarted (studentSupport: IStudentSupport): boolean {
    const now = moment();
    const nowFormatted = moment(now, comparisonFormat);
    const startsOnFormatted = moment(studentSupport.startsOn, comparisonFormat);
    const check1 = nowFormatted.isSame(startsOnFormatted, 'day');
    const diff = nowFormatted.diff(startsOnFormatted);
    const check2 = diff > 0;
    const check = check1 || check2;
    return check;
  }

  getCategoryHumanName (studentSupport: IStudentSupport): string {
    return this.imSupport.getCategoryHumanName(studentSupport.support);
  }

  getFrontendStatus (studentSupport: IStudentSupport, optBackendStatus?: TValidStudentSupportBackendStatuses): string {
    const isDeleted = this.isDeleted(studentSupport, optBackendStatus);
    if (isDeleted) return StudentSupportStatuses.frontend.REMOVED;

    const isCompleted = this.isCompleted(studentSupport, optBackendStatus);
    if (isCompleted) return StudentSupportStatuses.backend.COMPLETED;

    const hasStarted = this.hasStarted(studentSupport);
    if (hasStarted) {
      return StudentSupportStatuses.frontend.IN_PROGRESS;
    } else {
      return StudentSupportStatuses.frontend.NOT_STARTED;
    }
  }

  isDeleted (studentSupport: IStudentSupport, optBackendStatus: TValidStudentSupportBackendStatuses): boolean {
    const backendStatus = optBackendStatus || studentSupport.status;
    return backendStatus === StudentSupportStatuses.backend.DELETED;
  }

  isCompleted (studentSupport: IStudentSupport, optBackendStatus: TValidStudentSupportBackendStatuses): boolean {
    const backendStatus = optBackendStatus || studentSupport.status;
    return backendStatus === StudentSupportStatuses.backend.COMPLETED;
  }

  isActive (studentSupport: IStudentSupport, optBackendStatus: TValidStudentSupportBackendStatuses): boolean {
    const backendStatus = optBackendStatus || studentSupport.status;
    return backendStatus === StudentSupportStatuses.backend.ACTIVE;
  }

  getStartsOnString (studentSupport: IStudentSupport): string {
    return moment(studentSupport.startsOn).format(displayFormat);
  }

  getCompletedAtString (studentSupport: IStudentSupport): string {
    return studentSupport.completedAt && moment(studentSupport.completedAt).format(displayFormat);
  }

  getAssignedOnString (studentSupport: IStudentSupport): string {
    return moment(studentSupport.assignedOn).format(displayFormat);
  }

  getReviewOnString (studentSupport: IStudentSupport): string {
    const lastReview = this.getLastReview(studentSupport);
    return moment(lastReview).format(displayFormat);
  }

  getLastReview (studentSupport: IStudentSupport): string {
    const lastReviewDateIndex = studentSupport.reviewOn.length - 1;
    return studentSupport.reviewOn.length ? studentSupport.reviewOn[lastReviewDateIndex] : undefined;
  }

  needsReview (studentSupport: IStudentSupport): boolean {
    const lastReview = this.getLastReview(studentSupport);
    let needsReview = false;

    if (lastReview) {
      needsReview = moment().isAfter(lastReview);
    }

    return needsReview;
  }

  createStudentSupportIdsFromStudentIds (_ids: Array<string>) {
    return _ids.reduce((acc, id: string) => {
      // bc we use createUuid this is not actually a pure function
      acc[id] = this.utils.createV4Uuid();
      return acc;
    }, {});
  };

  createTemplateFromSupport (
    support: ISchoolSupport,
    startsOn: string = support.schedule.startsOn,
    endsOn: string = support.schedule.endsOn,
  ) {
    const template = {
      schoolId: support.schoolId,
      status: 'ACTIVE',
      // bc we create start and end dates this function is not pure
      startsOn: moment(startsOn).format('YYYY-MM-DD'),
      endsOn: moment(endsOn).format('YYYY-MM-DD'),
      support: {
        supportId: support._id,
        name: support.name,
        category: support.category,
        metaData: support.metaData,
      },
    };
    return template;
  }

  public getStudentSupportsGroupedByFrontEndStatus (studentSupports: IStudentSupport[]) {
    const acc = {
      [StudentSupportStatuses.frontend.IN_PROGRESS]: [],
      [StudentSupportStatuses.frontend.NOT_STARTED]: [],
      [StudentSupportStatuses.frontend.COMPLETED]: [],
    };

    reduce(
      studentSupports,
      (acc, studentSupport) => {
        const frontendStatus = this.getFrontendStatus(studentSupport);

        if (acc[frontendStatus]) acc[frontendStatus].push(studentSupport);

        return acc;
      },
      acc,
    );

    each(acc, (value, key) => {
      if (!value.length) delete acc[key];
    });

    return acc;
  }
}
