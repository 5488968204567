import { NgZone } from '@angular/core';
import { Route, Router } from '@angular/router';
import { ROUTE_CONFIGS, IRouteConfigsOpts } from './route.config';
class RouteHelpers {
  readonly ROUTE_CONFIGS = ROUTE_CONFIGS;

  get (): Route[] {
    const routes: Route[] = [];
    this.ROUTE_CONFIGS.forEach((routeConfig: IRouteConfigsOpts) => routes.push(routeConfig));
    return routes;
  }

  getRoutesUrls (): string[] {
    const routesUrls = [];
    this.ROUTE_CONFIGS.forEach(({ url }) => routesUrls.push(url));
    return routesUrls;
  }

  getRolePermissions (routeConfig: IRouteConfigsOpts) {
    const { rolePermissions } = routeConfig;
    return rolePermissions;
  }

  /**
   * Wrapper of Angular router (only for testing purpose)
   * Ensures all router operations are run within Angular zone
   * Keep change detection enabled
   * But, avoids flooding the console with warnings
   * https://github.com//issues/25837
   */
  wrapRouterInNgZone (router: Router, ngZone: NgZone): Router {
    return new Proxy(router, {
      get (target: Router, prop: PropertyKey): unknown {
        const invokedProperty = target[prop];
        if (typeof invokedProperty !== 'function') return invokedProperty;
        return function (...args: Array<unknown>): unknown {
          return ngZone.run(() => invokedProperty.apply(target, args));
        };
      },
    });
  }
}

export const routeHelpers = new RouteHelpers();
