import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { each, pull } from 'lodash';

import { CreditGapsPriorityGroupingsService } from 'Src/ng2/school/sdc/services/credit-gaps-priority-groupings/credit-gaps-priority-groupings.service';
import { IPriorityGroupDefinition } from 'Src/ng2/school/sdc/services/regents-prep-priority-groupings/regents-prep-priority-groupings.service';
import { WizardHelpers } from 'Src/ng2/school/sdc/services/wizard-helpers.service';
import { ImSchool } from 'Src/ng2/shared/services/im-models/im-school';
import { UrlPathService } from 'Src/ng2/shared/services/url-path-service/url-path.service';
import { IStudent } from 'Src/ng2/shared/typings/interfaces/student.interface';

@Component({
  selector: 'review-gaps-step',
  templateUrl: './review-gaps-component.html',
  encapsulation: ViewEncapsulation.None,
})

export class ReviewGapsStep implements OnInit {
    @Input() filteredStudents: any;
    @Input() currentFilter: any;

    private buckets;

    public stepLabel: string = 'Plan for credit gaps to be addressed this term or in the future';
    public currentStudentYear;
    public priorityGroups: IPriorityGroupDefinition[];
    public sort: any;

    public activeStudentsIds: string[];
    public activeSuperSeniorsIds: string[];
    public activeWithUnaddressedGapsIds: string[];
    public activeWithUnaddressedGapsFull: IStudent[];
    public activeWithAddressedGapsIds: string[];
    public activeWithNoGapsIds: string[];

    public countActiveStudents: number;
    public countActiveSuperSeniors: number;
    public countActiveWithUnaddressedGaps: number;
    public countActiveWithAddressedGaps: number;
    public countActiveWithNoGaps: number;
    public options;

    constructor (
        private imSchool: ImSchool,
        private wizardHelpers: WizardHelpers,
        private activatedRoute: ActivatedRoute,
        private creditGapsPriorityGroupingsService: CreditGapsPriorityGroupingsService,
        private urlPathService: UrlPathService,
    ) {
      this.options = {
        columnKeys: ['gradPlan', 'creditMaxGaps', 'creditMaxGapsNoPlan'],
      };
    }

    ngOnInit () {
      this.setUi();
    }

    ngOnChanges ({ filteredStudents }): void {
      if (!filteredStudents.firstChange) {
        this.setUi();
      }
    }

    setUi (): void {
      this.currentStudentYear = this.getCurrentStudentYear();
      this.buckets = this.generateFilteredStudentIdBuckets();
      this.activeStudentsIds = this.buckets.activeStudentsIds;
      this.activeSuperSeniorsIds = this.buckets.activeSuperSeniorsIds;
      this.activeWithUnaddressedGapsIds = this.buckets.activeWithUnaddressedGapsIds;
      this.activeWithUnaddressedGapsFull = this.buckets.activeWithUnaddressedGapsFull;
      this.activeWithAddressedGapsIds = this.buckets.activeWithAddressedGapsIds;
      this.activeWithNoGapsIds = this.buckets.activeWithNoGapsIds;
      this.countActiveSuperSeniors = this.activeSuperSeniorsIds.length;
      this.countActiveWithUnaddressedGaps = this.activeWithUnaddressedGapsIds.length;
      this.countActiveWithAddressedGaps = this.activeWithAddressedGapsIds.length;
      this.countActiveWithNoGaps = this.activeWithNoGapsIds.length;
      this.priorityGroups = this.generatePriorityGroups(this.activeWithUnaddressedGapsFull);
      this.sort = { sort: { colId: 'studentName', sort: 'asc' } };
    }

    public navigateToStudents (studentIds: string[]): void {
      const { schoolId } = this.activatedRoute.snapshot.params;
      const url = this.urlPathService.computeDistrictUrlPath(`/school/${schoolId}/student`);
      this.wizardHelpers.navigateToView({ url, cacheData: { studentId: studentIds }, sort: this.sort });
    }

    private getCurrentStudentYear (): number {
      return this.imSchool.getStudentYearForCohort(this.currentFilter);
    }

    private generateFilteredStudentIdBuckets (): object {
      const activeStudentsIds = [];
      const activeSuperSeniorsIds = [];
      const activeWithUnaddressedGapsIds = [];
      const activeWithUnaddressedGapsFull = [];
      const activeWithAddressedGapsIds = [];
      const activeWithNoGapsIds = [];

      each(this.filteredStudents, (student: any) => {
        const { studentId, cohort, status, creditMaxGaps, creditMaxGapsNoPlan, isSuperSenior } = student;
        const isActive = status === 'A';
        // if `All` is selected in the dropdown, all students are `inCohort`
        let inCohort = true;
        if (isSuperSenior) inCohort = Number(cohort) <= Number(this.currentFilter);
        inCohort = this.currentFilter === 'All' || cohort === this.currentFilter;

        const hasUnaddressedGaps = creditMaxGapsNoPlan < 0;
        const hasAddressedGaps = creditMaxGaps < 0 && creditMaxGapsNoPlan >= 0;
        const hasNoGaps = creditMaxGaps === 0;

        if (isActive && inCohort) activeStudentsIds.push(studentId);
        if (isActive && isSuperSenior) activeSuperSeniorsIds.push(studentId);
        if (isActive && hasUnaddressedGaps) {
          pull(activeWithAddressedGapsIds, studentId);
          activeWithUnaddressedGapsIds.push(studentId);
          activeWithUnaddressedGapsFull.push(student);
        }
        if (isActive && hasAddressedGaps) {
          pull(activeWithUnaddressedGapsIds, studentId);
          pull(activeWithUnaddressedGapsFull, student);
          activeWithAddressedGapsIds.push(studentId);
        }
        if (isActive && hasNoGaps) activeWithNoGapsIds.push(studentId);
      });

      return {
        activeStudentsIds,
        activeSuperSeniorsIds,
        activeWithUnaddressedGapsIds,
        activeWithUnaddressedGapsFull,
        activeWithAddressedGapsIds,
        activeWithNoGapsIds,
      };
    }

    private generatePriorityGroups (students): IPriorityGroupDefinition[] {
      const priorityGroups = this.creditGapsPriorityGroupingsService.getPriorityGroupsByStudentYearFilter(
        this.currentStudentYear,
      );
      each(priorityGroups, (group: any) => (group.studentIds = []));
      each(students, (student: any) => {
        const { studentId, creditGapPriorityGrouping } = student;
        each(priorityGroups, (group: any) => {
          if (group.studentPriorityGroupingValue === creditGapPriorityGrouping) {
            group.studentIds.push(studentId);
          }
        });
      });
      return priorityGroups;
    }
}
