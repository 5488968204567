import * as _ from 'lodash';

import {
  RegentsCategory,
  RegentsExam,
  RegentsSubject,
  IRegentsCategory,
} from './../../constants/regents.constant';

export class StudentStatic {
  /**
   * @param {string} regentsCategory.key - a value in RegentsCategory[CONSTANT].key
   * @returns {Array} of regentsExams for a category
   */
  static getRegentsExamsForRegentsCategory (regentsCategoryKey) {
    const regentsCategory: IRegentsCategory = _.find(RegentsCategory, { key: regentsCategoryKey });
    const exams = _.flatten(
      _.map(regentsCategory.subjects, function (subject: any) {
        return subject.exams;
      }),
    ); // lodash 4.0 has _.flatMap

    return _.filter(exams, 'isOffered');
  }

  /**
   * @param {string} regentsExam.key - a value in RegentsExam[CONSTANT].key
   * @returns {Object} which is the regentsSubject for a regentsExam
   */
  static getRegentsSubjectForRegentsExam (regentsExamKey) {
    const regentsExam = _.find(RegentsExam, { key: regentsExamKey });

    return _.find(RegentsSubject, function (regentsSubject: any) {
      return _.includes(regentsSubject.exams, regentsExam);
    });
  }

  /**
   * @param {number} gradeMark must be a value (studentProgramDetails.currSy[course].marks[mark].num)
   * @returns {string} status
   */
  static getGradeMarkStatus (gradeMark) {
    let status;
    if (gradeMark) {
      if (gradeMark < 65) {
        status = 'Failing';
      } else if (gradeMark >= 75) {
        status = 'Passing';
      } else {
        status = 'Borderline';
      }
    }
    return status;
  }

  /**
   * @param {Object} course (studentProgramDetails.currSy[course])
   * @returns {Number} grade value
   */
  static getCurrentCourseGradeMark (course) {
    const courseMarksSortedByMp = _.sortBy(course.marks, 'mp');
    const numMps = courseMarksSortedByMp.length;
    const currentMp: any = courseMarksSortedByMp[numMps - 1];
    const currentGrade = currentMp ? currentMp.num : null;
    return currentGrade;
  }

  static createIdsFromDistrictIds (schoolId, districtIds: string[]) {
    return _.map(districtIds, id => `${id}${schoolId}`);
  }

  // at the time of writing, it's been assured that district Id will always be 6 digits. (Devin)
  static getDistrictIdFromStudentId (studentId) {
    return studentId.substring(0, studentId.length - 6);
  }
}
