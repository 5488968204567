import { IEditableRecord } from '../../services/batch-edit-service/batch-edit-service';
import { SorterColumnDataType } from '../sorter-column-data-type.constant';

export const studentEditableMap = {
  _id: 'id',
  gradPlanningDetails: 'Graduation planning details',
  'gradPlanningDetails.plannedCreds': 'Planned Credentials',
  'gradPlanningDetails.plannedDiplomaType': 'Diploma Type',
  'gradPlanningDetails.plannedEndorsements': ' Planned Endorsements',
  'gradPlanningDetails.plannedGraduationDate': 'Graduation Date',
  'gradPlanningDetails.schoolVerifiedSafetyNetEligibility': 'Safety Net Eligibility',
  schoolPriorityGrouping: 'School Priority Grouping',
  schoolRiskGroupELA: 'School Risk Group (ELA)',
  schoolRiskGroupMath: 'School Risk Group (Math)',
  activeStudentSupports: 'Active Supports',
  nextScheduledRegents: 'Next scheduled regents',
  'nextScheduledRegents.global': 'Next scheduled Global History exam', // global is deprecated, keeping it here for doc logs logic
  'nextScheduledRegents.global.adminDate': 'Next scheduled Global History date', // global is deprecated, keeping it here for doc logs logic
  'nextScheduledRegents.global.examLanguage': 'Next scheduled Global History exam language', // global is deprecated, keeping it here for doc logs logic
  'nextScheduledRegents.globalTwo': 'Next scheduled Global II History exam',
  'nextScheduledRegents.globalTwo.adminDate': 'Next scheduled Global II History date',
  'nextScheduledRegents.globalTwo.examLanguage': 'Next scheduled Global II History exam language',
  'nextScheduledRegents.us': 'Next scheduled US History exam', // us is deprecated, keeping it here for doc logs logic
  'nextScheduledRegents.us.adminDate': 'Next scheduled US History date', // us is deprecated, keeping it here for doc logs logic
  'nextScheduledRegents.us.examLanguage': 'Next scheduled US History exam language', // us is deprecated, keeping it here for doc logs logic
  'nextScheduledRegents.usFramework': 'Next scheduled US Framework History exam',
  'nextScheduledRegents.usFramework.adminDate': 'Next scheduled US Framework History date',
  'nextScheduledRegents.usFramework.examLanguage': 'Next scheduled US Framework History exam language',
  'nextScheduledRegents.compEla': 'Next scheduled Comprehensive English Regents exam',
  'nextScheduledRegents.compEla.adminDate': 'Next scheduled Comprehensive English Regents date',
  'nextScheduledRegents.compEla.examLanguage': 'Next scheduled Comprehensive English Regents exam language',
  'nextScheduledRegents.ccEla': 'Next scheduled Common Core English Regents exam',
  'nextScheduledRegents.ccEla.adminDate': 'Next scheduled Common Core English Regents date',
  'nextScheduledRegents.ccEla.examLanguage': 'Next scheduled Common Core English Regents exam language',
  'nextScheduledRegents.intAlg': 'Next scheduled Integrated Algebra Regents exam',
  'nextScheduledRegents.intAlg.adminDate': 'Next scheduled Integrated Algebra Regents date',
  'nextScheduledRegents.intAlg.examLanguage': 'Next scheduled Integrated Algebra Regents exam language',
  'nextScheduledRegents.ccAlg': 'Next scheduled Common Core Algebra Regents exam',
  'nextScheduledRegents.ccAlg.adminDate': 'Next scheduled Common Core Algebra Regents date',
  'nextScheduledRegents.ccAlg.examLanguage': 'Next scheduled Common Core Algebra Regents exam language',
  'nextScheduledRegents.oldGeom': 'Next scheduled Geometry Regents exam',
  'nextScheduledRegents.oldGeom.adminDate': 'Next scheduled Geometry Regents date',
  'nextScheduledRegents.oldGeom.examLanguage': 'Next scheduled Geometry Regents exam language',
  'nextScheduledRegents.ccGeom': 'Next scheduled Common Core Geometry Regents exam',
  'nextScheduledRegents.ccGeom.adminDate': 'Next scheduled Common Core Geometry Regents date',
  'nextScheduledRegents.ccGeom.examLanguage': 'Next scheduled Common Core Geometry Regents exam language',
  'nextScheduledRegents.oldTrig': 'Next scheduled Trigonometry Regents exam',
  'nextScheduledRegents.oldTrig.adminDate': 'Next scheduled Trigonometry Regents date',
  'nextScheduledRegents.oldTrig.examLanguage': 'Next scheduled Trigonometry Regents exam language',
  'nextScheduledRegents.ccTrig': 'Next scheduled Common Core Trigonometry Regents exam',
  'nextScheduledRegents.ccTrig.adminDate': 'Next scheduled Common Core Trigonometry Regents date',
  'nextScheduledRegents.ccTrig.examLanguage': 'Next scheduled Common Core Trigonometry Regents exam language',
  'nextScheduledRegents.livingEnv': 'Next scheduled Living Environment Regents exam',
  'nextScheduledRegents.livingEnv.adminDate': 'Next scheduled Living Environment Regents date',
  'nextScheduledRegents.livingEnv.examLanguage': 'Next scheduled Living Environment Regents exam language',
  'nextScheduledRegents.chem': 'Next scheduled Chemistry Regents exam',
  'nextScheduledRegents.chem.adminDate': 'Next scheduled Chemistry Regents date',
  'nextScheduledRegents.chem.examLanguage': 'Next scheduled Chemistry Regents exam language',
  'nextScheduledRegents.physics': 'Next scheduled Physics Regents exam',
  'nextScheduledRegents.physics.adminDate': 'Next scheduled Physics Regents date',
  'nextScheduledRegents.physics.examLanguage': 'Next scheduled Physics Regents exam language',
  'nextScheduledRegents.earth': 'Next scheduled Earth Science Regents exam',
  'nextScheduledRegents.earth.adminDate': 'Next scheduled Earth Science Regents date',
  'nextScheduledRegents.earth.examLanguage': 'Next scheduled Earth Science Regents exam language',
  'nextScheduledRegents.lote': 'Next scheduled LOTE Regents exam',
  'nextScheduledRegents.lote.adminDate': 'Next scheduled LOTE Regents date',
  'nextScheduledRegents.lote.examLanguage': 'Next scheduled LOTE Regents exam language',
  studentContactDetails: 'Student contact details',
  'studentContactDetails.email': 'Student email',
  'studentContactDetails.mobile': 'Student mobile',
  'studentContactDetails.parentEmail': 'Parent email',
  'eopFinancialEligibility.status': 'EOP financial eligibility',
  'hescEditable.fafsaStatus': 'FAFSA status',
  'hescEditable.tapStatus': 'TAP status',
  'postsecondaryFuturePlan.advisor': 'Advisor',
  'postsecondaryFuturePlan.careerInterest': 'Career interest',
  'postsecondaryFuturePlan.futureGoals': 'Future goals',
};

const validRiskGroupOptions = ['Pushable 2', 'Pushable 3', 'Slippable 2', 'Slippable 3'];

const validEopEligibilityOptions = ['Eligible', 'Not eligible'];

export interface IStudentEditableField extends IEditableRecord {
  path: string;
  type?: string;
}

// TODO - create a more accurate interface for this map - AT
export const studentEditableFieldMap = {
  primaryPointPerson: {
    humanName: 'Point person for all areas',
    path: 'pointPeople',
    category: 'POINT PEOPLE',
    schoolLevels: ['ES', 'MS', 'HS'],
    editable: true,
    dataType: SorterColumnDataType.USER_MINI,
    dataTypeOptions: {
      canBeRemoved: true,
    },
  } as IStudentEditableField,
  attendancePointPerson: {
    humanName: 'Attendance point person',
    path: 'pointPeople',
    type: 'ATTENDANCE',
    category: 'POINT PEOPLE, ATTENDANCE',
    schoolLevels: ['ES', 'MS', 'HS'],
    editable: true,
    dataType: SorterColumnDataType.USER_MINI,
    dataTypeOptions: {
      canBeRemoved: true,
    },
  } as IStudentEditableField,
  academicPointPerson: {
    humanName: 'Academic point person',
    path: 'pointPeople',
    type: 'ACADEMIC',
    category: 'ACADEMICS, POINT PEOPLE',
    schoolLevels: ['ES', 'MS', 'HS'],
    editable: true,
    dataType: SorterColumnDataType.USER_MINI,
    dataTypeOptions: {
      canBeRemoved: true,
    },
  } as IStudentEditableField,
  postSecPointPerson: {
    humanName: 'Postsecondary point person',
    path: 'pointPeople',
    type: 'POSTSECONDARY',
    category: 'POINT PEOPLE, POSTSECONDARY',
    schoolLevels: ['HS'],
    editable: true,
    dataType: SorterColumnDataType.USER_MINI,
    dataTypeOptions: {
      canBeRemoved: true,
    },
  } as IStudentEditableField,
  creditsPointPerson: {
    humanName: 'Credits point person',
    path: 'pointPeople',
    type: 'CREDITS',
    category: 'POINT PEOPLE, CREDITS',
    schoolLevels: ['HS'],
    editable: true,
    dataType: SorterColumnDataType.USER_MINI,
    dataTypeOptions: {
      canBeRemoved: true,
    },
  } as IStudentEditableField,
  regentsPointPerson: {
    humanName: 'Regents point person',
    path: 'pointPeople',
    type: 'REGENTS',
    category: 'POINT PEOPLE, REGENTS',
    schoolLevels: ['HS'],
    editable: true,
    dataType: SorterColumnDataType.USER_MINI,
    dataTypeOptions: {
      canBeRemoved: true,
    },
  } as IStudentEditableField,
  advisor: {
    humanName: 'Advisor',
    path: 'pointPeople',
    type: 'ADVISOR',
    category: 'POINT PEOPLE, BASIC INFO',
    schoolLevels: ['ES', 'MS', 'HS'],
    editable: true,
    dataType: SorterColumnDataType.USER_MINI,
    dataTypeOptions: {
      canBeRemoved: true,
    },
  } as IStudentEditableField,
  guidanceCounselor: {
    humanName: 'Guidance Counselor',
    path: 'pointPeople',
    type: 'GUIDANCE_COUNSELOR',
    category: 'POINT PEOPLE, BASIC INFO',
    schoolLevels: ['ES', 'MS', 'HS'],
    editable: true,
    dataType: SorterColumnDataType.USER_MINI,
    dataTypeOptions: {
      canBeRemoved: true,
    },
  } as IStudentEditableField,
  schoolRiskGroupELA: {
    humanName: 'School Risk Group (ELA)',
    path: 'schoolRiskGroupELA',
    category: 'ACADEMICS',
    schoolLevels: ['ES', 'MS'],
    editable: true,
    dataType: SorterColumnDataType.ENUM,
    dataTypeOptions: {
      canBeNull: true,
      values: validRiskGroupOptions,
    },
  } as IStudentEditableField,
  schoolRiskGroupMath: {
    humanName: 'School Risk Group (Math)',
    path: 'schoolRiskGroupMath',
    category: 'ACADEMICS',
    schoolLevels: ['ES', 'MS'],
    editable: true,
    dataType: SorterColumnDataType.ENUM,
    dataTypeOptions: {
      canBeNull: true,
      values: validRiskGroupOptions,
    },
  } as IStudentEditableField,
  eopFinancialEligibility: {
    humanName: 'EOP financial eligibility',
    path: 'eopFinancialEligibility',
    editable: true,
    category: 'POSTSECONDARY',
    schoolLevels: ['HS'],
    dataType: SorterColumnDataType.ENUM,
    dataTypeOptions: {
      canBeNull: true,
      values: validEopEligibilityOptions,
    },
  } as IStudentEditableField,
  postsecondaryCollegeAndCareerAdvisor: {
    humanName: 'College and Career Advisor',
    path: 'postsecondaryFuturePlan.advisor',
    editable: true,
    category: 'FUTURE PLAN, POSTSECONDARY',
    schoolLevels: ['HS'],
    dataType: SorterColumnDataType.STRING,
  } as IStudentEditableField,
  postsecondaryCareerInterest: {
    humanName: 'Career Interest',
    path: 'postsecondaryFuturePlan.careerInterest',
    editable: true,
    category: 'FUTURE PLAN, POSTSECONDARY',
    schoolLevels: ['HS'],
    dataType: SorterColumnDataType.STRING,
    dataTypeOptions: {
      values: [
        'Agriculture, Food & Natural Resources',
        'Architecture & Construction',
        'Arts, A/V Technology & Communications',
        'Business Management & Administration',
        'Education & Training',
        'Finance',
        'Government & Public Administration',
        'Health Science',
        'Hospitality & Tourism',
        'Human Services',
        'Information Technology',
        'Law, Public Safety, Corrections & Security',
        'Manufacturing',
        'Science, Technology, Engineering & Mathematics',
        'Transportation, Distribution & Logistics',
        'Undecided',
      ].map(val => ({ key: val, human: val })),
    },
  } as IStudentEditableField,
  postsecondaryFutre: {
    humanName: 'Future Goals',
    path: 'postsecondaryFuturePlan.futureGoals',
    editable: true,
    category: 'FUTURE PLAN, POSTSECONDARY',
    schoolLevels: ['HS'],
    dataType: SorterColumnDataType.STRING,
  } as IStudentEditableField,
  endorsementsCTE: {
    humanName: 'CTE (Planned Endorsement)',
    path: 'gradPlanningDetails.plannedEndorsements',
    editable: true,
    category: 'ENDORSEMENTS',
    schoolLevels: ['HS'],
    dataType: SorterColumnDataType.RADIO,
    dataTypeOptions: {
      canBeRemoved: true,
      values: ['Planned', 'Not planned'],
      subType: 'ENDORSEMENT',
      meta: {
        endorsement: 'CTE',
      },
    },
  },
  endorsementsArts: {
    humanName: 'Arts (Planned Endorsement)',
    path: 'gradPlanningDetails.plannedEndorsements',
    editable: true,
    category: 'ENDORSEMENTS',
    schoolLevels: ['HS'],
    dataType: SorterColumnDataType.RADIO,
    dataTypeOptions: {
      canBeRemoved: true,
      values: ['Planned', 'Not planned'],
      subType: 'ENDORSEMENT',
      meta: {
        endorsement: 'Arts',
      },
    },
  },
  endorsementsCDOS: {
    humanName: 'CDOS (Planned Endorsement)',
    path: 'gradPlanningDetails.plannedEndorsements',
    editable: true,
    category: 'ENDORSEMENTS',
    schoolLevels: ['HS'],
    dataType: SorterColumnDataType.RADIO,
    dataTypeOptions: {
      canBeRemoved: true,
      values: ['Planned', 'Not planned'],
      subType: 'ENDORSEMENT',
      meta: {
        endorsement: 'CDOS',
      },
    },
  },
  endorsementsHonors: {
    humanName: 'Honors (Planned Endorsement)',
    path: 'gradPlanningDetails.plannedEndorsements',
    editable: true,
    category: 'ENDORSEMENTS',
    schoolLevels: ['HS'],
    dataType: SorterColumnDataType.RADIO,
    dataTypeOptions: {
      canBeRemoved: true,
      values: ['Planned', 'Not planned'],
      subType: 'ENDORSEMENT',
      meta: {
        endorsement: 'Honors',
      },
    },
  },
  endorsementsMasteryInMath: {
    humanName: 'Mastery in Math (Planned Endorsement)',
    path: 'gradPlanningDetails.plannedEndorsements',
    editable: true,
    category: 'ENDORSEMENTS',
    schoolLevels: ['HS'],
    dataType: SorterColumnDataType.RADIO,
    dataTypeOptions: {
      canBeRemoved: true,
      values: ['Planned', 'Not planned'],
      subType: 'ENDORSEMENT',
      meta: {
        endorsement: 'Mastery in Math',
      },
    },
  },
  endorsementsMasteryInScience: {
    humanName: 'Mastery in Science (Planned Endorsement)',
    path: 'gradPlanningDetails.plannedEndorsements',
    editable: true,
    category: 'ENDORSEMENTS',
    schoolLevels: ['HS'],
    dataType: SorterColumnDataType.RADIO,
    dataTypeOptions: {
      canBeRemoved: true,
      values: ['Planned', 'Not planned'],
      subType: 'ENDORSEMENT',
      meta: {
        endorsement: 'Mastery in Science',
      },
    },
  },
  credentialsTASC: {
    humanName: 'TASC (Planned Credential)',
    path: 'gradPlanningDetails.plannedCreds',
    editable: true,
    category: 'CREDENTIALS',
    schoolLevels: ['HS'],
    dataType: SorterColumnDataType.RADIO,
    dataTypeOptions: {
      canBeRemoved: true,
      values: ['Planned', 'Not planned'],
      subType: 'CREDENTIAL',
      meta: {
        credential: 'TASC',
      },
    },
  },
  credentialsCDOS: {
    humanName: 'CDOS (Planned Credential)',
    path: 'gradPlanningDetails.plannedCreds',
    editable: true,
    category: 'CREDENTIALS',
    schoolLevels: ['HS'],
    dataType: SorterColumnDataType.RADIO,
    dataTypeOptions: {
      canBeRemoved: true,
      values: ['Planned', 'Not planned'],
      subType: 'CREDENTIAL',
      meta: {
        credential: 'CDOS',
      },
    },
  },
  credentialsSACC: {
    humanName: 'SACC (Planned Credential)',
    path: 'gradPlanningDetails.plannedCreds',
    editable: true,
    category: 'CREDENTIALS',
    schoolLevels: ['HS'],
    dataType: SorterColumnDataType.RADIO,
    dataTypeOptions: {
      canBeRemoved: true,
      values: ['Planned', 'Not planned'],
      subType: 'CREDENTIAL',
      meta: {
        credential: 'SACC',
      },
    },
  },
};
