import { identity } from 'lodash';
import { LoadToggles } from './../../../store/actions/toggle-actions';
import { tap, filter, switchMap, take, catchError, mapTo } from 'rxjs/operators';
import { getToggleLoadedStatus, getToggleEntities } from './../../../store/selectors/toggles-selectors';
import { Store, select } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, CanLoad, Route, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Auth } from 'Src/ng2/shared/auth/auth.service';

@Injectable()
export class TogglesGuard implements CanActivate, CanLoad {
  constructor (
    private store: Store<any>,
    private router: Router,
    private auth: Auth,
  ) {}

  getFromStoreOrAPI (currentSchoolId: string): Observable<any> {
    return this.store.pipe(
      select(getToggleLoadedStatus),
      tap(loaded => {
        if (!loaded) this.store.dispatch(new LoadToggles({ currentSchoolId }));
      }),
      filter(identity),
      switchMap(() => this.store.pipe(select(getToggleEntities))),
      take(1),
    );
  }

  canActivate (route: ActivatedRouteSnapshot | Route): Observable<boolean> {
    const schoolId = (route as ActivatedRouteSnapshot).params?.schoolId;
      return this.getFromStoreOrAPI(schoolId).pipe(
        mapTo(true),
        catchError(() => of(false)),
      );
  }

  canActivateChild (route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.canActivate(route);
  }

  canLoad (route: Route): Observable<boolean | UrlTree> {
    const isAuthenticated = this.auth.isAuthenticated();
    if (!isAuthenticated) return of(this.router.createUrlTree(['/login']));
    return this.canActivate(route);
  }
}
