import { ApiService } from 'Src/ng2/shared/services/api-service/api-service';
import { switchMap, mergeMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import * as userActions from '../../actions/users-actions';

@Injectable()
export class UsersEffects {
  constructor (
    private actions$: Actions,
    private apiService: ApiService,
  ) {}

  loadUsers = createEffect(() => {
    return this.actions$.pipe(
      ofType<any>(userActions.LOAD_USERS),
      switchMap(({ payload: { schoolId } }) => {
        return this.apiService.getUsers(schoolId);
      }),
      mergeMap(users => [new userActions.LoadUsersSuccess(users)]),
      // TODO: create users error action and update accordingly
      // catchError(error => from([new userActions.LoadUser(error)])),
    );
  });
}
