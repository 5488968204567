<base-modal
  class="base-modal-shell"
  [title]="title"
  [itemType]="itemType"
  [isProfileMode]="true"
>
    <div class="detail-section" *ngFor="let section of sections;">
        <div class="detail-header-container">{{ section.sectionTitle }}</div>
        <div class="detail-text-container">{{ section.sectionText }}</div>
    </div>
</base-modal>
