import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';

@Component({
  selector: 'pill-cell-renderer',
  templateUrl: './pill-cell-renderer.component.html',
  styleUrls: ['./pill-cell-renderer.component.scss'],
})
export class PillCellRenderer implements ICellRendererAngularComp {
  public pillLabel: string;
  public color: string = 'blue';

  agInit (params: ICellRendererParams): void {
    const {
      value,
      data,
      colDef: {
        cellRendererParams: { getPillColor, getFormattedStatus },
      },
    } = params;

    this.pillLabel = value;
    if (getPillColor) this.color = getPillColor(data);
    if (getFormattedStatus) this.pillLabel = getFormattedStatus(data);
  }

  public refresh (_: ICellRendererParams): boolean {
    return false;
  }
}
