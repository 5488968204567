export class RollbarMock {
  configure(config) {
    //
  }

  error(msg, err) {
    //
  }

  debug(msg, err) {
    //
  }

  info(msg, err) {
    //
  }

  warn(msg, err) {
    //
  }

  warning(msg, err) {
    //
  }
}
