<div class="flag-modal">
  <form [formGroup]="form" novalidate class="solution">
    <!-- HEADER -->
    <h2>
      <span>{{ data.type }}</span>
    </h2>

    <!-- CHECKBOXES -->
    <div class="solutions">
      <div class="choices" formGroupName="checkboxes">
        <div class="choice" *ngFor="let choice of checkboxes.choices">
          <div class="label">
            <mat-checkbox [formControlName]="choice.value" [checked]="choice.checked" class="nv-mat-checkbox">
            </mat-checkbox>
            <span>{{ choice.text }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- TEXT AREA -->
    <textarea [placeholder]="textarea.placeholder" formControlName="textarea"></textarea>

    <!-- ACTION BUTTONS -->
    <div class="actions">
      <nv-button *ngIf="form.valid" (click)="onSubmit(form)"> {{ buttons.confirm.text }} </nv-button>
      <nv-button type="secondary" (click)="onCancel()"> {{ buttons.cancel.text }} </nv-button>
    </div>
  </form>
</div>
