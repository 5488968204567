<div
  class="segmented-control-pill"
  *ngFor="let option of options; let i = index"
  [class.selected]="isSelected(i)"
  (click)="handleClick(i)"
>
  <div class="segmented-control-label">
    {{ option.label || option }}
    <span
      *ngIf="option.badge"
      class="option-badge"
      nvTooltip [tooltipData]="option.badgeTooltip"
    > {{ option.badge }}</span> 
  </div>
</div>
