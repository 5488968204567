import { createFeatureSelector, createSelector } from '@ngrx/store';

const getPortalState = createFeatureSelector('PORTAL_STORE');

export const getRegentsResultsGridSlice = createSelector(
  getPortalState,
  (state: any) => state.regentsResultsGrid,
);

export const getRegentsResultsGridLoadedState = createSelector(
  getRegentsResultsGridSlice,
  (state: any) => state.loaded,
);

export const getRegentsResultsGridState = createSelector(
  getRegentsResultsGridSlice,
  (state: any) => state.gridState,
);
