import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { LogOutComponent } from './log-out.component';
import { SharedEventTrackers } from 'Src/ng2/shared/services/mixpanel/event-trackers/shared-tracking.service';
import { NvButtonModule } from 'projects/shared/nvps-libraries/design/nv-button/nv-button.module';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [LogOutComponent],
  imports: [CommonModule, NvButtonModule, MatListModule],
  exports: [LogOutComponent],
  providers: [SharedEventTrackers],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class LogOutModule {}
