import { ComponentType } from '@angular/cdk/portal';
import { Component, Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarRef } from '@angular/material/snack-bar';
import { NvToastComponent } from '../../../../nvps-libraries/design/nv-toast/nv-toast.component';
import { SNACKBAR_CONFIG } from './snackbar.config';
import { TValidBackgroundJob } from '../background-job-notification-service/background-job-notification-service';
import { ModalsService } from '../../modals/modals.service';
import { IResultModalEventData, IResultsModalData } from '../../modals/results/results-modal.component';
import { Subject } from 'rxjs';

export interface ISnackBarData {
  message: string;
  action?: string;
  duration?: number;
}
@Injectable()
export class SnackBarService {
  readonly SNACKBAR_CONFIG = SNACKBAR_CONFIG;
  public actionEvent$ = new Subject<any>();

  constructor (public snackBar: MatSnackBar, private modalService: ModalsService) {}

  public showSnackBar ({ message, action, duration }: ISnackBarData): void {
    const config = duration ? { ...this.SNACKBAR_CONFIG, ...{ duration } } : this.SNACKBAR_CONFIG;
    this.snackBar.open(message, action || null, config);
  }

  public openSnackBarFromComponent (component: ComponentType<any>, data): MatSnackBarRef<Component> {
    return this.snackBar.openFromComponent(component, data);
  }

  public showToast ({ toastText, duration = 3000 }): MatSnackBarRef<NvToastComponent> {
    return this.snackBar.openFromComponent(NvToastComponent, {
      data: {
        toastText,
        hasClearButton: false,
        actionText: null,
      },
      duration,
    });
  }

  public showToastWithCloseButton ({ toastText }): MatSnackBarRef<NvToastComponent> {
    return this.snackBar.openFromComponent(NvToastComponent, {
      data: {
        toastText,
        hasClearButton: true,
        actionText: null,
      },
    });
  }

  public showBatchActionNoteSuccessToast (backgroundJob: TValidBackgroundJob): any {
    const message = (backgroundJob === 'BulkStudentNoteCreate')
      ? 'Note was added for selected students.'
      : 'Batch action applied to the selected students.';
    this.showToast({ toastText: message });
  }

  public showToastLowerLeftCorner ({ toastText, duration = 3000 }): MatSnackBarRef<NvToastComponent> {
    return this.snackBar.openFromComponent(NvToastComponent, {
      data: {
        toastText,
        hasClearButton: false,
        actionText: null,
      },
      duration,
      ...this.SNACKBAR_CONFIG,
    });
  }

  public showDangerToastWithCloseButton ({ toastText, isDanger = true, horizontalPosition = 'left' }: { toastText: string, isDanger?: boolean, horizontalPosition?: MatSnackBarHorizontalPosition }): MatSnackBarRef<NvToastComponent> {
    return this.snackBar.openFromComponent(NvToastComponent, {
      data: {
        toastText,
        hasClearButton: true,
        actionText: null,
        isDanger,
      },
      panelClass: 'nv-mat-snackbar',
      horizontalPosition,
      verticalPosition: 'bottom',
    });
  }

  public showBatchActionToast ({
    success,
    duration,
  }: {
    success: boolean;
    duration?: number;
  }): MatSnackBarRef<NvToastComponent> {
    const batchSuccessMessage = 'Batch action applied to the selected students.';
    const batchFailedMessage =
      'The batch action could not be applied to all of the selected students. Please try again.';
    const toastText = success ? batchSuccessMessage : batchFailedMessage;
    return this.showToast({ toastText, duration });
  }

  public showBatchActionResult (backgroundJob: TValidBackgroundJob, metaData?: any): void {
    switch (backgroundJob) {
      case 'BulkStudentNoteCreate':
        this.showToast({ toastText: 'Note was added for selected students.' });
        break;
      case 'BulkStudentTaskCreate':
        this.showToast({ toastText: 'Task has been created' });
        break;
      case 'BulkStudentTaskPartialCreate': {
        const modalData: IResultsModalData = {
          metaData,
          title: 'Tasks Action Results',
        };
        this.modalService.openResultsModal(modalData).afterClosed().subscribe((eventData: IResultModalEventData) => this.sendMessage(eventData));
      }
        break;
      default:
        this.showToast({ toastText: 'Batch action applied to the selected students.' });
        break;
    }
  }

  public sendMessage (eventData: IResultModalEventData) {
    this.actionEvent$.next(eventData);
  }
}
