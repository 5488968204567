<input
  #inputTime
  type="text"
  #autocompleteTrigger="matAutocompleteTrigger"
  [formControl]="control"
  [matAutocomplete]="menu"
  class="{{ icon !== null ? 'left-icon' : '' }} {{ hasClearBtn ? 'right-icon' : '' }}"
  [ngClass]="{ 'is-invalid': getValidity() }"
  [placeholder]="placeholderText"
  required
  (blur)="validateAndEmit()"
  autocomplete="off"
  data-lpignore="true"
/>
<nv-icon *ngIf="icon !== null" class="left" [name]="icon" [color]="getValidity() ? 'pink' : 'blue'"></nv-icon>

<nv-icon-button
  *ngIf="hasClearBtn === true && showClearBtn === true"
  class="close-button"
  name="close-large-button"
  [color]="getValidity() ? 'pink' : 'blue'"
  (click)="clearSearch($event)"
></nv-icon-button>

<mat-autocomplete
  #menu="matAutocomplete"
  class="nv-mat-autocomplete"
  [disableRipple]="false"
  [autoActiveFirstOption]="false"
  (optionSelected)="emitOption($event.option)"
  (opened)="emitPanelState()"
>
  <mat-option *ngFor="let option of (filteredOptions | async)" [value]="option" class="nv-mat-option">
    <div class="nv-mat-option-label">{{ option }}</div>
  </mat-option>
</mat-autocomplete>
