<ng-container *ngIf="(batchActionsSelectedIds$ | async).length === 0">
  <nv-dropdown
    [isInverted]="isInverted"
    [selected]="status"
    [options]="batchEditOptions"
    (selectOption)="onSelectAttendanceStatus($event)"
  >
  </nv-dropdown>
</ng-container>

<ng-container *ngIf="(batchActionsSelectedIds$ | async).length > 0">
  <span *ngIf="status === 'DELETED'">–</span> <span *ngIf="status !== 'DELETED'">{{ status | titlecase }}</span>
</ng-container>
