import { IColumnStuLvl } from 'Src/ng2/shared/models/list-models';
import { District, TDistricts } from 'Src/ng2/shared/typings/interfaces/district.interface';
import { TValidSchoolTypeCats } from 'Src/ng2/shared/typings/interfaces/school.interface';

export const COLUMNS: IColumnStuLvl[] = [
  {
    columnName: 'FILE_NAME',
    columnDataType: 'string',
    columnOrder: 0,
    graphQlKey: 'FILE_NAME',
  },
  {
    columnName: 'AUTOMATED UPDATES',
    columnDataType: 'string',
    columnOrder: 1,
    // Following the pattern of matching the graphQlKey to the columnName -
    // is there a reason we need this? I can't find evidence that the columns
    // are dependent on an API.
    graphQlKey: 'AUTOMATED_UPDATES',
  },
  {
    columnName: 'CREATED BY',
    columnDataType: 'string',
    columnOrder: 2,
    graphQlKey: 'CREATED_BY',
  },
  {
    columnName: 'LAST EDITED',
    columnDataType: 'Date',
    columnOrder: 3,
    graphQlKey: 'LAST_EDITED',
  },
  {
    columnName: null,
    columnDataType: 'OTHER_TOOLS_MORE',
    columnOrder: 4,
    graphQlKey: 'OTHER_TOOLS_MORE',
  },
];

export const SHEET_COLUMNS: IColumnStuLvl[] = [
  {
    columnName: 'FILE_NAME',
    columnDataFormat: 'string',
    columnOrder: 0,
    graphQlKey: 'FILE_NAME',
  },
  {
    columnName: 'CREATED ON',
    columnDataFormat: 'Date',
    columnOrder: 1,
    graphQlKey: 'CREATED_ON',
  },
];

export const SHEET_TYPE_HUMAN_MAP = {
  'Attendance Heat Map': 'ATTENDANCE',
  'Marking Period': 'MARKING PERIOD',
  'Mock Regents': 'MOCK REGENTS',
  'Regents Outreach': 'REGENTS OUTREACH',
  'Regents Results': 'REGENTS RESULTS',
  'Regents Appeals': 'REGENTS APPEALS',
  'Student Sorter': 'SORTER',
  'SPELL Management': 'SPELL MANAGEMENT',
  'Synced Sheets': 'SYNCED_LIST',
  'Remote Learning': 'REMOTE LEARNING',
};

export const SHEET_TYPE_TO_ROUTE = {
  'Attendance Heat Map': 'attendance-heat-map',
  'Marking Period': 'marking-period',
  'Mock Regents': 'mock-regents',
  'Regents Outreach': 'regents-outreach',
  'Regents Results': 'regents-results',
  'Regents Appeals': 'regents-appeals',
  'Student Sorter': 'student-sorter',
  'SPELL Management': 'spell-management',
  'Synced Sheets': 'synced-sheets',
  'Remote Learning': 'remote-learning',
};

export const SHEET_TYPE_MAPPINGS = {
  Attendance: 'Attendance Heat Map',
  'Mock Regents': 'Mock Regents',
  'Regents Outreach': 'Regents Outreach',
  'Regents Results': 'Regents Results',
  'Regents Appeals': 'Regents Appeals',
  'Marking Period': 'Marking Period',
  'Spell Management': 'SPELL Management',
  Sorter: 'Student Sorter',
  'Remote Learning': 'Remote Learning',
  Sync: 'Synced Sheets',
};

export const ROUTE_TO_HUMAN = {
  'synced-sheets': 'Synced Sheets',
  'attendance-heat-map': 'Attendance Heat Map',
  'mock-regents': 'Mock Regents',
  'regents-outreach': 'Regents Outreach',
  'regents-results': 'Regents Results',
  'regents-appeals': 'Regents Appeals',
  'marking-period': 'Marking Period',
  'spell-management': 'SPELL Management',
  'student-sorter': 'Student Sorter',
  'remote-learning': 'Remote Learning',
  'grad-planning': 'Grad Plans',
  'next-term-planning': 'Next Term Planning',
  'program-changes-and-gap-plans': 'Program Changes',
  resources: 'Resources',
  'regents-prep': 'Regents Prep',
  'regents-planning': 'Regents Planning',
  'credit-gaps': 'Credit Gaps',
  'formative-assessments': 'Formative Assessments',
};

export const HUMAN_TO_ROUTE = {
  'Synced Sheets': 'synced-sheets',
  'Attendance Heat Map': 'attendance-heat-map',
  'Mock Regents': 'mock-regents',
  'Regents Outreach': 'regents-outreach',
  'Regents Results': 'regents-results',
  'Regents Appeals': 'regents-appeals',
  'Marking Period': 'marking-period',
  'Grad Plans': 'grad-planning/wizard',
  'Next Term Planning': 'next-term-planning',
  'Program Changes': 'program-changes-and-gap-plans',
  'SPELL Management': 'spell-management',
  'Student Sorter': 'student-sorter',
  'Remote Learning': 'remote-learning',
  Resources: 'resources',
  'Regents Prep': 'regents-prep',
  'Regents Planning': 'regents-planning',
  'Credit Gaps': 'credit-gaps',
  'Formative Assessments': 'formative-assessments',
};

export interface IOtherToolsNavItem {
  humanName: string;
  schoolTypes: TValidSchoolTypeCats[];
  districts: TDistricts[];
  order: number;
  isActive: boolean;
}

export interface IOtherToolsNavGroup {
  [propName: string]: IOtherToolsNavItem
}

export interface IOtherToolsSideNav {
  [section: string]: IOtherToolsNavGroup
}

export const OtherToolsSideNavConfig: IOtherToolsSideNav = {
  sheets: {
    SYNCED_SHEETS: {
      humanName: 'Synced Sheets',
      schoolTypes: ['all'],
      districts: [District.NYC],
      order: 1,
      isActive: true,
    },
    ATTENDANCE_HEAT_MAP: {
      humanName: 'Attendance Heat Map',
      schoolTypes: ['all'],
      districts: [District.NYC],
      order: 2,
      isActive: true,
    },
    MOCK_REGENTS: {
      humanName: 'Mock Regents',
      schoolTypes: ['hs'],
      districts: [District.NYC],
      order: 3,
      isActive: true,
    },
    REGENTS_RESULTS: {
      humanName: 'Regents Results',
      schoolTypes: ['hs'],
      districts: [District.NYC],
      order: 5,
      isActive: true,
    },
    REGENTS_APPEALS: {
      humanName: 'Regents Appeals',
      schoolTypes: ['hs'],
      districts: [District.NYC],
      order: 6,
      isActive: true,
    },
    MARKING_PERIOD: {
      humanName: 'Marking Period',
      schoolTypes: ['all'],
      districts: [District.NYC],
      order: 7,
      isActive: true,
    },
    SPELL_MANAGEMENT: {
      humanName: 'SPELL Management',
      schoolTypes: ['all'],
      districts: [District.NYC],
      order: 8,
      isActive: true,
    },
    REMOTE_LEARNING: {
      humanName: 'Remote Learning',
      schoolTypes: ['all'],
      districts: [District.NYC],
      order: 10,
      isActive: true,
    },
  },
  tools: { // now known as "Legacy Tools"
    CREDIT_GAPS: {
      humanName: 'Credit Gaps',
      schoolTypes: ['hs'],
      districts: [District.NYC],
      order: 11,
      isActive: true,
    },
    FORMATIVE_ASSESSMENTS_LIST: {
      humanName: 'Formative Assessments',
      schoolTypes: ['ems'],
      districts: [District.NYC],
      order: 12,
      isActive: true,
    },
    GRAD_PLANS: {
      humanName: 'Grad Plans',
      schoolTypes: ['hs'],
      districts: [District.NYC],
      order: 14,
      isActive: true,
    },
    NEXT_TERM_PLANNING: {
      humanName: 'Next Term Planning',
      schoolTypes: ['hs'],
      districts: ['all'],
      order: 16,
      isActive: true,
    },
    REGENTS_PLANNING: {
      humanName: 'Regents Planning',
      schoolTypes: ['hs'],
      districts: [District.NYC],
      order: 17,
      isActive: true,
    },
    REGENTS_PREP: {
      humanName: 'Regents Prep',
      schoolTypes: ['hs'],
      districts: [District.NYC],
      order: 18,
      isActive: true,
    },
    REGENTS_RESULTS: {
      humanName: 'Regents Results',
      schoolTypes: ['hs', 'ms'],
      districts: [District.NYC, District.UNIONDALE, District.SCHENECTADY], // TODO: Confirm this works out for FLACS, NV CMO, NYC ICS, District.BROOME, District.GO
      order: 5,
      isActive: true,
    },
  },
};

export const autoUpdateNumberToHumanStatus = new Map<string, string>([
  ['*', 'Daily'],
  ['0', 'Weekly on Sunday'],
  ['1', 'Weekly on Monday'],
  ['2', 'Weekly on Tuesday'],
  ['3', 'Weekly on Wednesday'],
  ['4', 'Weekly on Thursday'],
  ['5', 'Weekly on Friday'],
  ['6', 'Weekly on Saturday'],
]);

export type TOtherToolsMoreButtonActionMode = 'EDIT' | 'DELETE' | 'UPDATE';
