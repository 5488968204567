import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { NvIconButtonModule } from '../../../nvps-libraries/design/nv-icon-button/nv-icon-button.module';
import { NvIconModule } from 'projects/shared/nvps-libraries/design/nv-icon/nv-icon.module';
import { NvMatTooltipModule } from '../components/nv-mat-tooltip/nv-mat-tooltip.module';
import { MaterialModule } from '../modules/material.module';
import { BaseModalComponent } from './base-modal.component';
import { NvTooltipModule } from '../nv-tooltip/nv-tooltip.module';

@NgModule({
  declarations: [BaseModalComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    MaterialModule,
    MatDialogModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NvMatTooltipModule,
    NvIconButtonModule,
    NvIconModule,
    NvTooltipModule,
  ],
  exports: [BaseModalComponent],
  providers: [],
})
export class BaseModalModule {}
