import { IGuardian } from 'projects/shared/typings/interfaces/family.interface';
import { ContactInfoService } from './../../services/contact-info/contact-info.service';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseModalComponent } from '../base-modal.component';
import { UtilitiesService } from '../../services/utilities/utilities.service';
import { debounceTime, map, switchMap, take, tap } from 'rxjs/operators';
import { combineLatest, Observable, of } from 'rxjs';
import { ECFIKManagementService } from 'Src/ng2/school/ecfik-management/ecfik-management.service';

export interface IEcfikModalData {
  guardians: IGuardian[];
  state: 'EDIT' | 'ADD';
  checkboxDisabled?: boolean;
}

@Component({
  selector: 'edit-ecfik-guardian-modal',
  templateUrl: 'edit-ecfik-guardian-modal.component.html',
  styleUrls: ['edit-ecfik-guardian-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

// Can support both add new and edit existing guardian cases
export class EditECFIKGuardianModalComponent extends BaseModalComponent implements OnInit {
  public guardiansFormList: FormArray;
  public title: string;
  public modalState: 'ADD' | 'EDIT';
  public submitText: string = '';
  public restrictedContactNameLookup: boolean[] = [];
  public restrictedContactMobileLookup: boolean[] = [];
  public isInvalidEmail: boolean[] = [];
  public checkboxDisabled: boolean = false;
  public primaryGuardianDescription = 'One primary guardian must be designated for a family. The primary guardian is responsible for program consent, which allows all guardians in the household to log in to the Family Portal';
  public primaryGuardianIndex = null;
  // Track if email has changed from initial value for editing purposes. If we do not track, then isUniqueGuardianEmail validator will flag email as in use.
  private initialEmailsMap: Map<string, string> = new Map();

  constructor (
    dialogRef: MatDialogRef<EditECFIKGuardianModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IEcfikModalData,
    private fb: FormBuilder,
    private utils: UtilitiesService,
    private contactInfoService: ContactInfoService,
    private ecfikManagementService: ECFIKManagementService,
  ) {
    super(dialogRef);
  }

  ngOnInit () {
    this.guardiansFormList = this.fb.array([]);
    this.modalState = this.data.state;
    const modalStates = {
      EDIT: { title: 'Edit guardian', subtitle: 'Updating guardian email changes their Family Portal login and sends an email invitation to the updated email address', initForm: this.initEditGuardiansForm.bind(this) },
      ADD: { title: 'Add guardian', subtitle: 'This will send the guardian an email invitation to the New Visions Family Portal', initForm: this.initAddGuardianForm.bind(this) },
    };

    const modalState = modalStates[this.data.state];
    this.title = modalState.title;
    this.subtitle = modalState.subtitle;
    this.checkboxDisabled = this.data?.checkboxDisabled;
    this.primaryGuardianIndex = this.data.guardians?.findIndex((guardian: IGuardian) => guardian.isPrimary);
    this.submitText = this.modalState === 'ADD' ? 'Add' : 'Save';
    modalState.initForm();
  }

  private initAddGuardianForm (): void {
    const formIndex = 0;
    const [guardian] = this.data.guardians;
    const fg = this.getGuardianFormGroup(guardian);
    this.guardiansFormList.push(fg);
    this.restrictedContactNameLookup[formIndex] = false;
    this.restrictedContactMobileLookup[formIndex] = false;
    this.isInvalidEmail[formIndex] = false;
  }

  private initEditGuardiansForm (): void {
    this.data.guardians.forEach(
      (guardian, index) => {
        const fg = this.getGuardianFormGroup(guardian);
        this.guardiansFormList.push(fg);
        this.restrictedContactNameLookup[index] = false;
        this.restrictedContactMobileLookup[index] = false;
        this.isInvalidEmail[index] = false;
        this.initialEmailsMap.set(guardian.guardianId, guardian.email);
      },
    );
  }

  private getGuardianFormGroup ({ firstName, lastName, email, mobile, isPrimary, source, guardianId, latestLogin }: IGuardian = {} as IGuardian): FormGroup {
    return new FormGroup({
      firstName: new FormControl(firstName || '', [Validators.required]),
      lastName: new FormControl(lastName || '', [Validators.required]),
      email: new FormControl(email || '', []),
      mobile: new FormControl(mobile || '', []),
      isPrimary: new FormControl({ value: isPrimary, disabled: this.checkboxDisabled }),
      source: new FormControl(source || 'userEntered'),
      guardianId: new FormControl(guardianId || this.utils.createV4Uuid()),
      latestLogin: new FormControl(latestLogin || null),
    }, [], this.validateGuardianForm.bind(this));
  }

  private validateGuardianForm (fg: FormGroup): Observable<any> {
    if (fg.pristine) return of(null);

    const fgIndex = this.guardiansFormList.controls.indexOf(fg);
    const payload = {
      firstName: fg.value.firstName,
      lastName: fg.value.lastName,
      phoneNumber: fg.value.mobile,
    };

    // Validate mobile number
    const mobileValidation$ = fg.value.mobile
      ? this.contactInfoService.findIsContactRestricted(payload).pipe(
        tap(res => {
          this.restrictedContactNameLookup[fgIndex] = res.fullName;
          this.restrictedContactMobileLookup[fgIndex] = res.phoneNumber;
        }),
        map(res => (!res.phoneNumber && !res.fullName ? null : res)),
      )
      : of(null);
    // Validate email
    const emailValidation$ = fg.value.email
      ? this.ecfikManagementService.isUniqueGuardianEmail(fg.value.email).pipe(
        tap(res => {
          const initialEmail = this.initialEmailsMap.get(fg.value.guardianId);
          const isSameAsInitial = initialEmail === fg.value.email;
          const allEmailsAreUnique = this.emailIsUniqueWithinForm(fg.value.email);
          this.isInvalidEmail[fgIndex] = !isSameAsInitial && (!res || !allEmailsAreUnique);
        }),
        map(isValidEmail => {
          const initialEmail = this.initialEmailsMap.get(fg.value.guardianId);
          const isSameAsInitial = initialEmail === fg.value.email;
          const allEmailsAreUnique = this.emailIsUniqueWithinForm(fg.value.email);
          const isInvalid = !isSameAsInitial && (!isValidEmail || !allEmailsAreUnique);
          return isInvalid ? { invalidEmail: true } : null;
        }),
      )
      : of(null);

    return fg.valueChanges.pipe(
      take(1),
      debounceTime(150), // Debounce to prevent excessive API calls
      switchMap(() =>
        combineLatest([emailValidation$, mobileValidation$]).pipe(
          map(([emailRes, mobileRes]) => {
            if (mobileRes && emailRes) {
              return { ...mobileRes, ...emailRes };
            }
            return mobileRes || emailRes;
          }),
        ),
      ),
    );
  }

  private emailIsUniqueWithinForm (updatedEmail: string): boolean {
    const emailsArr = this.guardiansFormList.value.map(form => form.email);
    const emailCount = emailsArr.reduce((acc, formEmail) => {
      if (updatedEmail === formEmail) acc++;
      return acc;
    }, 0);
    return emailCount === 1;
  }

  public onClearMobile (index): void {
    const targetFg = this.guardiansFormList.at(index);
    (targetFg as FormGroup).controls.mobile.setValue('');
    this.restrictedContactMobileLookup[index] = false;
  }

  public onClearName (index: number, field: 'firstName' | 'lastName'): void {
    const targetFg = this.guardiansFormList.at(index);
    (targetFg as FormGroup).controls[field].setValue('');
    this.restrictedContactNameLookup[index] = false;
    this.restrictedContactMobileLookup[index] = false;
  }

  public deleteGuardian (index: number): void {
    this.guardiansFormList.removeAt(index);

    // disable button if there is only one guardian i.e 1 primary guardian is required per family
    if (this.guardiansFormList.length === 1) {
      const newPrimaryGuardianFg = this.guardiansFormList.at(0);
      // required for disabling the checkbox when there is only 1 guardian in the edit modal. This removes the `isPrimary` property from `this.guardiansFormList.value()` so we must use this.guardiansFormList.getRawValue() when sending the payload
      newPrimaryGuardianFg.get('isPrimary').disable();
      (newPrimaryGuardianFg as FormGroup).controls.isPrimary.setValue(true);
      this.primaryGuardianIndex = 0;
      this.checkboxDisabled = true;
    }
    if (index === this.primaryGuardianIndex) {
      // if deselecting the current primary guardian, set next guardian to be primary guardian
      const maxIndex = this.guardiansFormList.length - 1;
      const nextIndex = index >= maxIndex ? 0 : index + 1;
      const newPrimaryGuardianFg = this.guardiansFormList.at(nextIndex);
      (newPrimaryGuardianFg as FormGroup).controls.isPrimary.setValue(true);
      this.primaryGuardianIndex = nextIndex;
    } else {
      this.primaryGuardianIndex = this.guardiansFormList.value.findIndex(val => val.isPrimary === true);
    }
    const restrictedContactNameCopy = [...this.restrictedContactNameLookup];
    const restrictedContactMobileCopy = [...this.restrictedContactMobileLookup];
    const isInvalidEmailCopy = [...this.isInvalidEmail];
    this.restrictedContactNameLookup = this.getRefreshedLookup(restrictedContactNameCopy, index);
    this.restrictedContactMobileLookup = this.getRefreshedLookup(restrictedContactMobileCopy, index);
    this.isInvalidEmail = this.getRefreshedLookup(isInvalidEmailCopy, index);
  }

  private getRefreshedLookup (lookup: boolean[], index: number): boolean[] {
    lookup.splice(index, 1);
    return lookup;
  }

  public handleDeselectedPrimaryGuardian (index: number): void {
    // if deselecting the current primary guardian, set next guardian to be primary guardian
    const maxIndex = this.guardiansFormList.length - 1;
    const nextIndex = index >= maxIndex ? 0 : index + 1;
    this.setPrimaryGuardian(nextIndex, this.primaryGuardianIndex);
  }

  public onClickCheckbox (index: number): void {
    if (this.data.state === 'EDIT') {
      if (index === this.primaryGuardianIndex) {
        // if deselecting the current primary guardian, set next guardian to be primary guardian
        this.handleDeselectedPrimaryGuardian(index);
      } else if (index !== this.primaryGuardianIndex) {
        // unset other primary guardian and set to newly clicked one
        this.setPrimaryGuardian(index, this.primaryGuardianIndex);
      }
    }
  }

  private setPrimaryGuardian (newIndex: number, oldIndex: number): void {
    const oldPrimaryGuardianFg = this.guardiansFormList.at(oldIndex);
    const newPrimaryGuardianFg = this.guardiansFormList.at(newIndex);

    (oldPrimaryGuardianFg as FormGroup).controls.isPrimary.setValue(false);
    (newPrimaryGuardianFg as FormGroup).controls.isPrimary.setValue(true);
    this.primaryGuardianIndex = newIndex;
  }

  public onCancel (): void {
    super.close();
  }

  public onSave (): void {
    // when `isPrimary` is disabled, `isPrimary is not included in the payload. .getRawValue() will allow us to include the disabled `isPrimary` field.
    const rawFormVal = this.guardiansFormList.getRawValue();
    super.close(rawFormVal);
  }
}
