import { IGuardian } from './family.interface';

export enum CONSENT_STATUS {
  // eslint-disable-next-line no-unused-vars
  NOT_REQUESTED = 'Not requested',
  // eslint-disable-next-line no-unused-vars
  PENDING = 'Pending',
  // eslint-disable-next-line no-unused-vars
  YES = 'Yes',
  // eslint-disable-next-line no-unused-vars
  NO = 'No',
  // eslint-disable-next-line no-unused-vars
  REVOKED = 'Revoked',
  // eslint-disable-next-line no-unused-vars
  NEEDS_UPDATE = 'Needs update',
  // eslint-disable-next-line no-unused-vars
}

export type TConsentStatus = CONSENT_STATUS.NOT_REQUESTED
  | CONSENT_STATUS.PENDING
  | CONSENT_STATUS.REVOKED
  | CONSENT_STATUS.NEEDS_UPDATE
  | CONSENT_STATUS.YES
  | CONSENT_STATUS.NO;

export type TConsentEvent = 'completed' | 'declined'| 'emailSent' | 'envelopeOpened' | 'childAddedToFamily' | 'consentRevoked' | 'consentNotRequested';
export type TConsentSource = 'admin' | 'guardian' | 'schoolUser' | 'ETL' | 'docusign' | 'sourceSheet';

export interface IConsentHistory {
  event: TConsentEvent;
  source: TConsentSource;
  user: IGuardian | {
    userId: string;
    firstName: string;
    lastName: string;
    gafeEmail: string;
    doeEmail: string;
    dhsEmail?: string | null;
  };
  date: string; // ISO string
  metaData?: {
    envelopeId: string;
    envelopeType: 'email' | 'embedded';
  }
}

export type TConsentStatusData = {
  lastUpdated: string;
  lastEditor: string;
};
