import { ToggleService } from './../../../services/toggle/toggle.service';
import { Component, Inject, OnInit, ViewEncapsulation, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { flatten } from 'lodash';
import { Store } from '@ngrx/store';

import { UpdateSchool } from './../../../../store/actions/school-actions';
import { unsubscribeComponent } from 'Src/ng2/shared/helpers/unsubscribe-decorator/unsubscribe-decorators.helper';
import { IPickerOption } from 'projects/shared/nvps-libraries/design/nv-multi-picker/nv-multi-picker.interface';
import { RegentsSubject } from 'Src/ng2/shared/constants/regents.constant';
import { Toggles } from 'Src/ng2/shared/constants/toggles.constant';

@Component({
  selector: 'assign-prep-modal',
  templateUrl: './assign-prep-modal.component.html',
  styleUrls: ['./assign-prep-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

@unsubscribeComponent
export class AssignPrepModalComponent implements OnInit {
  public iconName = 'close-large-blue';
  public title = 'Assign prep';
  public subtitle: string;
  public numOfCoursesSelected: number;
  public selectedSubjects: string[] = [];
  public subjectOptions: IPickerOption[];
  public v4ModeIsOn: boolean;

  constructor (
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<AssignPrepModalComponent>,
    private store: Store<any>,
    private toggleService: ToggleService,
    private elementRef: ElementRef,
  ) {
    // ..
  }

  ngOnInit (): void {
    this.toggleV4NewSkinMode();
    this.numOfCoursesSelected = this.data.coursesId.length;
    this.subtitle = (this.numOfCoursesSelected > 1) ? `${this.numOfCoursesSelected} courses selected` : `${this.numOfCoursesSelected} course selected`;
    this.subjectOptions = this.getSubjectOptions();
  }

  private toggleV4NewSkinMode () : void {
    this.v4ModeIsOn = this.toggleService.getToggleState(Toggles.TOGGLE_V4_NEW_SKIN_MODE);
    if (this.v4ModeIsOn) {
      this.elementRef.nativeElement.classList.add('v4');
    }
  }

  public onCancel (): void {
    this.dialogRef.close();
  }

  public onConfirm (): void {
    this._prepPayloadForApi();
  }

  private getSubjectOptions (): IPickerOption[] {
    const subjectOptions = [];
    for (const subj in RegentsSubject) {
      subjectOptions.push(
        {
          key: RegentsSubject[subj].key,
          human: RegentsSubject[subj].longName,
        },
      );
    }
    return subjectOptions;
  }

  private _prepPayloadForApi (): void {
    const currentUserEnteredPrepCourses = this.data.allCurrentUserPrepCourses;
    const selectedCourses = this.data.coursesId;
    const selectedPrepSubjects = this.selectedSubjects;

    // For each course, create obj with selected prep
    const coursesWithNewPreps = selectedCourses.reduce((acc, course) => {
      const courseWithPrep = selectedPrepSubjects.map(prep => {
        return {
          courseId: course,
          coursePrepsFor: prep,
        };
      });

      return acc.concat(courseWithPrep);
    }, []);

    // Merge currentUserEnteredPrepCourses and courses with new preps
    const flattenCourses = flatten(coursesWithNewPreps); // Flatten coursesWithNewPreps
    const allCourses = [...currentUserEnteredPrepCourses, ...flattenCourses];

    // Construct payload needed to updated school
    const payload = {
      schoolId: this.data.schoolId,
      patch: {
        userEnteredPrepCourses: allCourses,
      },
    };
    this._sendToApi(payload);
  }

  private _sendToApi (payload): void {
    this.store.dispatch(new UpdateSchool(payload));
    this.dialogRef.close(true);
  }
}
