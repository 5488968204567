import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { IUser } from '../../shared/typings/interfaces/user.interface';
import * as currentUserActions from '../actions/current-user-actions';
import { ApiService } from './../../shared/services/api-service/api-service';

interface IAction {
  type: string;
  payload: { email: string };
}

@Injectable()
export class CurrentUserEffects {
  constructor (private actions$: Actions, private apiService: ApiService) {}

  loadCurrentUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(currentUserActions.LOAD_CURRENT_USER),
      switchMap((action: IAction) => {
        const { email } = action.payload;
        return this.apiService.getUser(email).pipe(
          mergeMap((user: IUser) => {
            return [new currentUserActions.LoadCurrentUserSuccess(user)];
          }),
          catchError(error => {
            return of(new currentUserActions.LoadCurrentUserFail(error));
          }),
        );
      }),
    );
  });
}
