import { LicenseManager } from '@ag-grid-enterprise/core';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from './environments/environment';
import { AppModule } from './ng2/app.module';

import 'zone.js';

(window as any).agGrid = require('@ag-grid-community/core');
(window as any).moment = require('moment-recur');
window._ = require('lodash');

if (environment?.production) {
  enableProdMode();
}

// tslint:disable-next-line:max-line-length
LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-061536}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{New_Visions_For_Public_Schools,_Inc.}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{New_Visions_Data_Tools_Portal}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{New_Visions_Data_Tools_Portal}_need_to_be_licensed___{New_Visions_Data_Tools_Portal}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{12_August_2025}____[v3]_[01]_MTc1NDk1MzIwMDAwMA==22d229b4a9d305146fb0d103600f689c',
);

// bootstrap NG2 App (CM).
platformBrowserDynamic().bootstrapModule(AppModule);
