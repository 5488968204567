import { ModalsService } from 'Src/ng2/shared/modals/modals.service';
import { Inject, Injectable, forwardRef } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, switchMap, map, tap } from 'rxjs/operators';
import { IStudentAssessment } from '../../../shared/typings/interfaces/student-assessment.interface';
import * as studentAssessmentsActions from '../../actions/assessments-actions/student-assessments-actions';
import { ApiService } from './../../../shared/services/api-service/api-service';
import { BackgroundJob } from './../../../shared/services/background-job/background-job.service';

@Injectable()
export class StudentAssessmentsEffects {
  constructor (
    private actions$: Actions,
    private apiService: ApiService,
    private backgroundJob: BackgroundJob,
    @Inject(forwardRef(() => ModalsService)) public modalsService: ModalsService,
  ) {}

  loadStudentAssessments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(studentAssessmentsActions.LOAD_STUDENT_ASSESSMENTS),
      switchMap((action: { type: string; payload: any }) => {
        const { schoolId, schoolYear } = action.payload;
        // This filter could also be moved to the route, but it seems unlikely there will be
        // need to show discharged students studentAssessment records anywhere in the portal at this time
        // so keeping the filter here for now.
        const where = '{\\"status\\": {\\"$ne\\": \\"DELETED\\"}}';
        return this.apiService.getStudentAssessments({ schoolId, schoolYear, where }).pipe(
          switchMap((response: { data: { StudentAssessments: IStudentAssessment[] } }) => {
            return [new studentAssessmentsActions.LoadStudentAssessmentsSuccess(response)];
          }),
          catchError(error => of(new studentAssessmentsActions.LoadStudentAssessmentsFail(error))),
        );
      }),
    );
  });

  /* istanbul ignore next */
  updateStudentAssessments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(studentAssessmentsActions.BULK_UPDATE_STUDENT_ASSESSMENTS),
      switchMap((action: { type: string; payload: studentAssessmentsActions.IBulkUpdateStudentAssessmentsPayload }) => {
        return this.apiService.patchStudentAssessments(action.payload).pipe(
          map((response: { data: { bulkUpdateStudentAssessment: { backgroundJobId: string } } }) => {
            const {
              data: {
                bulkUpdateStudentAssessment: { backgroundJobId },
              },
            } = response;
            const subject = (this.backgroundJob as any)(backgroundJobId).subject();
            return subject;
          }),
          tap((backgroundJobSubject) => this.modalsService.openBackgroundJobSpinnerModal({ backgroundJobSubject, title: 'Updating Students' })),
          catchError(error => {
            return of(new studentAssessmentsActions.BulkUpdateStudentAssessmentsFail(error));
          }),
        );
      }),
    );
  }, { dispatch: false });
}
