import * as _ from 'lodash';

const F_AND_P_PORTAL = {
  fPEnteredInPortalLatestRelativeLevel: {
    tag: 'F&P (entered in Portal)',
    tags: ['Fountas & Pinnell', 'Fountas and Pinnell', 'F&P', 'F and P', 'Formative Assessments'],
    humanName: 'F&P (entered in Portal): Latest Relative Level',
  },
  fPEnteredInPortalPreviousDateOfAdministration: {
    tag: 'F&P (entered in Portal)',
    tags: ['Fountas & Pinnell', 'Fountas and Pinnell', 'F&P', 'F and P', 'Formative Assessments'],
    humanName: 'F&P (entered in Portal): Previous Date of Administration',
  },
  fPEnteredInPortalPreviousInstructionalLevel: {
    tag: 'F&P (entered in Portal)',
    tags: ['Fountas & Pinnell', 'Fountas and Pinnell', 'F&P', 'F and P', 'Formative Assessments'],
    humanName: 'F&P (entered in Portal): Previous Instructional Level',
  },
  fPEnteredInPortalPreviousIndependentLevel: {
    tag: 'F&P (entered in Portal)',
    tags: ['Fountas & Pinnell', 'Fountas and Pinnell', 'F&P', 'F and P', 'Formative Assessments'],
    humanName: 'F&P (entered in Portal): Previous Independent Level',
  },
  fPEnteredInPortalPreviousRelativeLevel: {
    tag: 'F&P (entered in Portal)',
    tags: ['Fountas & Pinnell', 'Fountas and Pinnell', 'F&P', 'F and P', 'Formative Assessments'],
    humanName: 'F&P (entered in Portal): Previous Relative Level',
  },
};

const F_AND_P_STARS = {
  fPFromStarsMostRecentDateForIndependent: {
    tag: 'F&P (from STARS)',
    tags: ['Fountas & Pinnell', 'Fountas and Pinnell', 'F&P', 'F and P', 'Formative Assessments'],
    humanName: 'F&P (from STARS): Most Recent Date for Independent',
  },
  fPFromStarsMostRecentDateForInstructional: {
    tag: 'F&P (from STARS)',
    tags: ['Fountas & Pinnell', 'Fountas and Pinnell', 'F&P', 'F and P', 'Formative Assessments'],
    humanName: 'F&P (from STARS): Most Recent Date for Instructional',
  },
  fPFromStarsMostRecentIndependentScore: {
    tag: 'F&P (from STARS)',
    tags: ['Fountas & Pinnell', 'Fountas and Pinnell', 'F&P', 'F and P', 'Formative Assessments'],
    humanName: 'F&P (from STARS): Most Recent Independent Score',
  },
  fPFromStarsMostRecentInstructionalScore: {
    tag: 'F&P (from STARS)',
    tags: ['Fountas & Pinnell', 'Fountas and Pinnell', 'F&P', 'F and P', 'Formative Assessments'],
    humanName: 'F&P (from STARS): Most Recent Instructional Score',
  },
  fPFromStarsSecondMostRecentDateForIndependent: {
    tag: 'F&P (from STARS)',
    tags: ['Fountas & Pinnell', 'Fountas and Pinnell', 'F&P', 'F and P', 'Formative Assessments'],
    humanName: 'F&P (from STARS): Second Most Recent Date for Independent',
  },
  fPFromStarsSecondMostRecentDateForInstructional: {
    tag: 'F&P (from STARS)',
    tags: ['Fountas & Pinnell', 'Fountas and Pinnell', 'F&P', 'F and P', 'Formative Assessments'],
    humanName: 'F&P (from STARS): Second Most Recent Date for Instructional',
  },
  fPFromStarsSecondMostRecentIndependentScore: {
    tag: 'F&P (from STARS)',
    tags: ['Fountas & Pinnell', 'Fountas and Pinnell', 'F&P', 'F and P', 'Formative Assessments'],
    humanName: 'F&P (from STARS): Second Most Recent Independent Score',
  },
  fPFromStarsSecondMostRecentInstructionalScore: {
    tag: 'F&P (from STARS)',
    tags: ['Fountas & Pinnell', 'Fountas and Pinnell', 'F&P', 'F and P', 'Formative Assessments'],
    humanName: 'F&P (from STARS): Second Most Recent Instructional Score',
  },
};

// col templates - used during dynamic sync col generation
const fAndP = {
  tag: 'Fountas & Pinnell, Fountas and Pinnell, F&P, F and P, Formative Assessments',
  tags: ['Fountas & Pinnell', 'Fountas and Pinnell', 'F&P', 'F and P', 'Formative Assessments'],
  humanName: 'F and P',
  path: 'join_student_assessments',
  calculation (student) {
    return _.get(student, this.path);
  },
};

// Adds student assessment columns to F_AND_P_FLATTENED_STUDENT_MAP
export const addStudentAssessmentColumns = schoolAssessments => {
  const stopIndex = schoolAssessments.length - 1;
  schoolAssessments
    .sort((a, b) => a.adminNumber - b.adminNumber)
    .map((schoolAssessment, index) => {
      const {
        assessment: { assessmentTitle },
        adminNumber,
        status,
      } = schoolAssessment;
      // TODO: Remove outer condition if graphQL where query (passed in route) is working
      // TODO: Also, if backend string 'Fountas and Pinnell' changes? Need to align.
      if (status === 'LOCKED') {
        if (assessmentTitle === 'Fountas and Pinnell') {
          // F&P Independent Score
          createFlattenedStudentMapCols({
            key: `fAndPAdmin${adminNumber}IndependentScore`,
            col: fAndP,
            subType: `Admin ${adminNumber} - Independent Score`,
          });
          // F&P Instructional Score
          createFlattenedStudentMapCols({
            key: `fAndPAdmin${adminNumber}InstructionalScore`,
            col: fAndP,
            subType: `Admin ${adminNumber} - Instructional Score`,
          });
          // F&P Admin Date
          createFlattenedStudentMapCols({
            key: `fAndPAdmin${adminNumber}Date`,
            col: fAndP,
            subType: `Admin ${adminNumber} Date`,
          });
          // F&P Expected Admin
          createFlattenedStudentMapCols({
            key: `fAndPAdmin${adminNumber}ExpectedLevel`,
            col: fAndP,
            subType: `Admin ${adminNumber} Expected Level`,
          });
          // F&P Growth
          if (index < stopIndex) {
            const nextAdmin = adminNumber + 1;
            createFlattenedStudentMapCols({
              key: `fAndPGrowthAdmin${adminNumber}Admin${nextAdmin}`,
              col: fAndP,
              subType: `Growth Admin ${adminNumber} - Admin ${nextAdmin}`,
            });
          }
        }
      }
    });
};

// Creates and adds columns to F_AND_P_FLATTENED_STUDENT_MAP
const createFlattenedStudentMapCols = ({ key, col, subType }) => {
  F_AND_P_FLATTENED_STUDENT_MAP[key] = Object.assign({}, col);
  F_AND_P_FLATTENED_STUDENT_MAP[key].humanName = `${col.humanName} ${subType}`;
};

export const F_AND_P_FLATTENED_STUDENT_MAP = {
  ...F_AND_P_PORTAL,
  ...F_AND_P_STARS,
};
