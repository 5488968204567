<div class="program-changes-content-body" #listContent >
  <page-header [pageHeaderMeta]="pageHeaderMeta" [showSearchBox]="true" [filterFormControl]="filterFormControl" *ngIf="fociOptions">
    Planned program changes for <nv-dropdown [options]="fociOptions" [selected]="fociOptionSelected" (selectOption)="onFociChange($event)"></nv-dropdown> grouped by
    <nv-dropdown [options]="groupingOptions" [selected]="groupingOptionSelected" (selectOption)="onGroupingChange($event)"></nv-dropdown> 
  </page-header> 
  <list-container *ngIf="fociOptions && groupingData$.value"
    [groupingData$]="groupingData$"
    [sortKey$]="sortKey$"
    [sortDirection$]="sortDirection$"
    [filterFormControl]="filterFormControl"
    [batchActionsMode$]="batchActionsMode$"
    [columns]="columns"
    [columnIndexMap]="columnIndexMap"
    [listConfig]="listConfig"
    [dynamicComponentTrigger]="dynamicComponentTrigger"
    [dynamicComponentInputData]="dynamicComponentInputData"
    (clickedRow)="onRowClick($event)"
    (focusedGroup)="onFocusedGroup($event)"
  >
  </list-container>
</div>

<div class="program-changes-sidebar">
  <h4>TOOLS</h4>
  <div class="tool-option" (click)="generateCsv()">
      <nv-icon name="download-small-blue"></nv-icon>
      <label for="exportToCSV">Export to CSV</label> <span id="exportToCSV"></span>
  </div>
</div> 