import { ObjectCache } from 'Src/ng2/shared/services/object-cache/object-cache.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { from, Observable } from 'rxjs';

interface ICacheObject {
  id: string;
  json: string;
}

@Injectable()
export class SchoolSsvFilterResolver implements Resolve<any> {
  constructor (private objectCache: ObjectCache) {}

  resolve (route: ActivatedRouteSnapshot): Observable<ICacheObject> {
    const { filter } = route.queryParams;
    const filterPromise: Promise<ICacheObject> = this.objectCache.getObject(filter);
    return from(filterPromise);
  }
}
