import { Action } from './../../shared/services/event-bus/event-bus';

export enum SideNavAction {
  HIDE_SIDE_NAV = 'HIDE_SIDE_NAV',
  SHOW_SIDE_NAV = 'SHOW_SIDE_NAV',
}

export class HideSideNavAction implements Action {
  readonly type = SideNavAction.HIDE_SIDE_NAV;

  constructor(public payload: null) {}
}

// tslint:disable:max-classes-per-file
export class ShowSideNavAction implements Action {
  readonly type = SideNavAction.SHOW_SIDE_NAV;

  constructor(public payload: null) {}
}
