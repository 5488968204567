import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { NvTextareaModule } from 'Src/nvps-libraries/design/nv-textarea/nv-textarea.module';
import { NvDesignLibraryModule } from '../../../../nvps-libraries/design/nv-design-library.module';
import { BaseModalModule } from '../base-modal.module';
import { NotificationsModalShellComponent } from './notifications-modal-shell.component';
import { NvIconModule } from 'projects/shared/nvps-libraries/design/nv-icon/nv-icon.module';

@NgModule({
  declarations: [NotificationsModalShellComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule,
    BaseModalModule,
    NvDesignLibraryModule,
    NvTextareaModule,
    NvIconModule,
  ],
  exports: [NotificationsModalShellComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class NotificationsModalShellModule {}
