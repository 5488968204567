import { IFocusPayload } from '../../actions/focus-actions/focus-actions';

export const generateQuery = ({ payload }: { payload: IFocusPayload }) => {
  const { schoolId, focusKey } = payload;

  return `{
    Focus(schoolId: "${schoolId}", focusKey: "${focusKey}"){
      graphQlKeyVizGrouping
      graphQlKeyPopulationFilter
      graphType
      graphQlKeyVizStackedGrouping
      focusName
      focusOptions {
        graphQlKey
        parent
        parentOrder
        children {
          graphQlKey
          child
          childOrder
          children {
            graphQlKey
            child
            childLabel
            childOrder
          }
        }
      }
      filterOptions {
        graphQlKey
        parent
        parentOrder
        children {
          graphQlKey
          child
          childOrder
          filterOptions {
            graphQlKey
            child
            childOrder
            filterOptions {
              graphQlKey
              child
              childOrder
            }
          }
        }
      }
      midLevelGroupingOptions {
        graphQlKey
        parent
        parentOrder
        children {
          graphQlKey
          child
          childOrder
        }
      }
      studentLevelGroupingOptions {
        graphQlKey
        parent
        midLevelParentKey
        wildcardKey
      }
      defaultStudentLevelGrouping {
        graphQlKey
      }
      defaultFilter {
        graphQlKey
        human
      }
      defaultMidLevelGrouping {
        graphQlKey
      }
      midLevelColumns {
        graphQlKey
        columnName
        columnDataType
        columnOrder
        isFooterSetter
        footerLabel
        columnDataType
        headerTooltip
        wildcardKey
      }
      studentLevelColumns {
        graphQlKey
        columnName
        columnOrder
        columnDataFormat
        headerTooltip
        cellConfig
        cellTooltip
        wildcardKey
        cellTooltipWildcardKey
      }
      vizDateStamp
    } 
  }
  `;
};

export const FOCUS_JOINS = [];
