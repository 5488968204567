import { Injectable } from '@angular/core';
import { EventFormatterService } from '../event-formatter.service';
import { MixpanelService } from '../mixpanel.service';
import { PORTAL_TYPES, TPortalLocation } from '../../../typings/interfaces/portal.interface';
import { EVENT_TYPES } from '../../../typings/interfaces/mixpanel.interface';
import { TMiscOrigins, TMiscPageActions } from '../event-interfaces/misc-page';

@Injectable()
export class SharedEventTrackers {
  constructor (
        private eventFormatterService: EventFormatterService,
        private mixpanelService: MixpanelService,
  ) {}

  public trackLefthandNavEvent ({ navItem, portal }:{navItem: string, portal: TPortalLocation}): void {
    const event = this.eventFormatterService.getLefthandNavEvent({
      navItem,
      portal,
    });
    this.mixpanelService.trackEvents([event]);
  }

  public trackMiscPageView ({ pageName, origin, portal }: { pageName: string, origin: TMiscOrigins, portal: keyof typeof PORTAL_TYPES}): void {
    const metadata = {
      portal,
      action: EVENT_TYPES.VIEWED,
      origin,
      pageName,
    };
    const event = this.eventFormatterService.getMiscPageViewEvent(metadata);
    this.mixpanelService.trackEvents([event]);
  }

  public trackMiscPageAction ({ action, pageName, origin, portal }: { action: TMiscPageActions, pageName: string, origin: TMiscOrigins, portal: keyof typeof PORTAL_TYPES}) {
    const metadata = {
      portal,
      action,
      origin,
      pageName,
    };
    const event = this.eventFormatterService.getMiscPageActionEvent(metadata);
    this.mixpanelService.trackEvents([event]);
  }
}
