/* tslint:disable */
import { Action } from '@ngrx/store';
import { ITile } from '../../../school/tiles/interfaces/dashboard.interfaces';
export const LOAD_TILE = '[Portal] Load Tile';
export const LOAD_TILE_SUCCESS = '[Portal] Load Tile Success';
export const LOAD_TILE_FAIL = '[Portal] Load Tile Fail';
export const RESET_TILES = '[Portal] Reset Tiles';

export interface ITilePayload {
  schoolId: string;
  tileName?: string;
}

// LoadTiles hits a graphQL endpoint if you pass a tileName you get the single tile, no tileName... get all the tiles
export class LoadTile implements Action {
  readonly type = LOAD_TILE;
  constructor(public payload: ITilePayload) {}
}

// todo: add interface Array<ITile>
export class LoadTileSuccess implements Action {
  readonly type = LOAD_TILE_SUCCESS;
  constructor(public payload: ITile[]) {}
}

export class LoadTileFail implements Action {
  readonly type = LOAD_TILE_FAIL;
  constructor(public payload: any) {}
}

export class ResetTiles implements Action {
  readonly type = RESET_TILES;
  constructor() {}
}

export type TileAction = LoadTile | LoadTileSuccess | LoadTileFail | ResetTiles;
