<div class="fix-errors-modal-wrapper">
  <div class="dialog-title">
    <nv-icon-button name="close-large-blue" (clickButton)="onCancel()"></nv-icon-button>
    <div class="dialog-title-left">
      <h1>{{ modalTitle }}</h1>
      <div></div>
      <p *ngIf="showStudentIdTable">{{ calculateUnverifiedIdCount }} unverified errors</p>
      <p *ngIf="showCollegeNameTable">{{ calculateUnverifiedCollegeCount }} unverified errors</p>
    </div>
    <div class="actions-wrapper">
      <nv-button type="secondary" (clickButton)="onCancel()">Cancel</nv-button>
      <nv-button
        *ngIf="showStudentIdTable"
        (clickButton)="submitStudentIdForm()"
        [ngClass]="{ disabled: !studentIdFormValid }"
      >
        {{ verifyStudentActionButtonText }}
      </nv-button>
      <nv-button
        *ngIf="showCollegeNameTable"
        (clickButton)="submitCollegeNameForm()"
        [ngClass]="{ disabled: !collegeNameFormValid }"
      >
        {{ verifyCollegeActionButtonText }}
      </nv-button>
    </div>
  </div>
  <div class="dialog-body">
    <div *ngIf="showStudentIdTable">
      <mat-dialog-content>
        <p>{{ studentModalVerbiage }}</p>
      </mat-dialog-content>
      <div class="table-holder" *ngIf="students">
        <div class="table-row table-row-students header" [ngClass]="{ shadow: showShadow }">
          <div class="col">STUDENT NAME PROVIDED</div>
          <div class="col">STUDENT ID PROVIDED</div>
          <div class="col">EQUIVALENT STUDENT IN DATA PORTAL</div>
        </div>
        <div class="row-holder" (scroll)="checkShadowAndStickyHeader($event)">
          <div class="table-row table-row-students" *ngFor="let row of studentIdTableData">
            <div class="col">{{ row.name }}</div>
            <div class="col error">{{ row.providedId }}</div>
            <div class="col">
              <nv-textbox
                [textControl]="studentIdSelectionsForm.controls[row.key]"
                (clearValue)="clearStudentInput(row.key)"
                (selectOption)="selectStudent(row.key, $event)"
                [placeholder]="findStudentPlaceholder"
                [autocompleteOptions]="filteredStudents"
                [moreOptionsText]="moreOptionsText"
                icon="search-large-black"
                [hasClearBtn]="true"
                [returnObject]="true"
                [emptyStateText]="emptyStateText"
                [restrictToOptions]="true"
              ></nv-textbox>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showCollegeNameTable">
      <mat-dialog-content>
        <p>{{ collegeModalVerbiage }}</p>
      </mat-dialog-content>
      <div class="table-holder">
        <div class="table-row table-row-colleges header" [ngClass]="{ shadow: showShadow }">
          <div class="col">COLLEGE NAME PROVIDED</div>
          <div class="col">EQUIVALENT COLLEGE IN DATA PORTAL</div>
        </div>
        <div class="row-holder" (scroll)="checkShadowAndStickyHeader($event)">
          <div class="table-row table-row-colleges" *ngFor="let row of collegeNameTableData">
            <div class="col error">{{ row.collegeName }}</div>
            <div class="col">
              <nv-textbox
                [textControl]="collegeNameSelectionsForm.controls[row.collegeName]"
                (clearValue)="clearCollegeNameInput(row.collegeName)"
                (selectOption)="selectCollegeName(row.collegeName, $event)"
                [placeholder]="findCollegeNamePlaceholder"
                [autocompleteOptions]="filteredColleges"
                [moreOptionsText]="moreOptionsText"
                icon="search-large-black"
                [hasClearBtn]="true"
                [returnObject]="true"
                [emptyStateText]="emptyStateText"
                [restrictToOptions]="true"
              ></nv-textbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
