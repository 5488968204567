<base-modal
  class="base-modal regents-plan-modal"
  [title]="title"
  [itemCount]="itemCount"
  [itemType]="itemType"
  [isProfileMode]="isProfileMode"
  [buttonMode]="null"
  [titleTooltipInfo]="titleTooltipInfo"
> 
<form [formGroup]="regentsPlanForm" autocomplete="off">
      <ng-container *ngFor="let element of modalOptions">
          <div class="row">
            <nv-form-field [label]="element.human" class="option-field">
              <ng-container>
                <nv-dropdown
                  [options]="element.options"
                  [selected]="element.data"
                  [returnObject]="true"
                  [customClass]="'scrollable'"
                  placeholder="—"
                  (selectOption)="onChangeHandler(element.human, $event)"
                >
                </nv-dropdown>
              </ng-container>
            </nv-form-field>
          </div>
        </ng-container>
  <mat-dialog-actions class="dialog-actions">
  <!-- Cancel / Save buttons -->
    <nv-button type="secondary" (clickButton)="onCancel()">
      Cancel
    </nv-button>
    <nv-button (clickButton)="onSave()" [disabled]="saveButtonDisabled">
      Assign
    </nv-button>
  </mat-dialog-actions>
</form>
</base-modal>