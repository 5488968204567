import { CUNYGuard } from './../../guards/cuny-guard/cuny-guard';
import { CurrentUserGuard } from '../../guards/current-user-guard/current-user-guard';
import { RouteGuard } from '../../guards/route-guard/route-guard';
import { TogglesGuard } from '../../guards/toggles-guard/toggles-guards';

export const nycCUNYRoute = {
  path: 'cuny-grid',
  url: '/cuny-grid',
  canLoad: [CurrentUserGuard],
  data: {
    guards: [RouteGuard, TogglesGuard],
  },
  loadChildren: () =>
    import('../../../school/server-side-grid/server-side-grid.module').then(m => m.ServerSideGridModule),
  canActivate: [TogglesGuard, CUNYGuard],
};
