import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GraphQLActionsHelperService {
  constructor () { }
  public getPortalActionsConfigs ({ contextPartnerId, contextPartnerType, origin }): string {
    const query = `{
      ActionsConfig(contextPartnerId: "${contextPartnerId}", origin: "${origin}", contextPartnerType: "${contextPartnerType}") {
        human
        key
        iconStyle
        type
        tooltip {
          default
          unique
          style
        }
        menuTypes {
          sideNav {
            isVisible
            sortOrder
          }
          actions {
            isVisible
            sortOrder
          }
        }
        options {
          human
          key
          customClass
          tooltip {
            default
            unique
          }
        }
      }
    }`;

    return query;
  }
}
