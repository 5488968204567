import { Observable } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';

@Injectable()
export class SyncCombinatorGuard implements CanActivate {
  constructor (private injector: Injector) { }

  async canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const guards = route.data.guards || [];
    for (const guard of guards) {
      const instance: CanActivate = this.injector.get(guard);
      const result = await (instance.canActivate(route, state) as Observable<boolean|UrlTree>).toPromise().catch(() => false);
      if (result === false || result instanceof UrlTree) return result;
    }
    return true;
  }
}
