import { reverse, sortBy, startCase } from 'lodash';
import moment from 'moment';

export function parseDateList (dates: string[] = []): string {
  dates = reverse(
    sortBy(dates, value => {
      return new Date(value);
    }),
  );
  const shortenList = dates.length > 10;
  const extra = shortenList ? `, +${dates.length - 10} more` : '';
  dates = shortenList ? dates.slice(0, 10) : dates;

  const today = moment().format('YYYY-MM-DD');
  const parsedDates = dates.reduce((sum: string, date: string, index: number) => {
    let dateStr: string;
    if (today === date) {
      dateStr = 'Today';
    } else {
      dateStr = moment(date).format('MMM D');
      if (index !== dates.length - 1) dateStr += ',';
    }
    return `${sum} ${dateStr}`;
  }, '');

  const trimmedDates = parsedDates.trim();
  return trimmedDates ? `${trimmedDates}${extra}` : '';
};

export function percentFromDecimalValueFormatter (val: number) {
  // @ts-ignore
  return val !== null && val !== undefined ? `${+(Math.round(val + 'e+4') + 'e-2')}%` : null;
};

export function wholePercentFromDecValueFormatter (val: number) {
  // @ts-ignore
  return val !== null && val !== undefined ? `${+(Math.round(val + 'e+2'))}%` : null;
};

export function titleCase (val: string) {
  return startCase(val);
};

export function checkForPercent (str: string | number) {
  // Assumption is Percent is at end
  // Regex Expression evaluates to any number of digits with a percent at end
  const re = /\d+%/;
  if (typeof (str) === 'string') {
    if (re.test(str)) {
      return true;
    } else {
      return false;
    }
  }
};

/**
* Removes the End Percent and adds it back in as its own span
*/
export function adjustPercent (str: string | number) {
  if ((typeof (str) === 'string') && checkForPercent(str)) {
    const strLen = str.length;
    return str.slice(0, strLen - 1);
  } else {
    return str;
  }
};

export function convertAddressToTitleCase (str : string) : string {
  const alphaRegEx = /^([A-Z])$/;
  let res = '';
  let prev = '';
  for (let i = 0; i < str.length; i++) {
    const c = str[i];
    // We only want to lower case if the previous character is A-Z and the current one is A-Z
    const previousIsAlpha = alphaRegEx.test(prev);
    const currentIsAlpha = alphaRegEx.test(c);
    if (previousIsAlpha && currentIsAlpha) {
      res += c.toLowerCase();
    } else {
      res += c;
      prev = c;
    }
  }
  return res;
}
