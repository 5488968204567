import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { NvDropdownMenuModule } from '../nv-dropdown-menu/nv-dropdown-menu.module';
import { NvPillButtonV4Module } from '../nv-pill-button-v4/nv-pill-button-v4.module';
import { NvDropdownPillComponent } from './nv-dropdown-pill.component';
import { NvAriaLabelModule } from '../directives/nv-aria-label/nv-aria-label.module';

@NgModule({
  declarations: [NvDropdownPillComponent],
  exports: [NvDropdownPillComponent],
  imports: [CommonModule, MatMenuModule, NvDropdownMenuModule, NvPillButtonV4Module, NvAriaLabelModule],
})
export class NvDropdownPillModule {}
