import { createFeatureSelector, createSelector } from '@ngrx/store';

const getPortalState = createFeatureSelector('PORTAL_STORE');

export const getMetricsState = createSelector(
  getPortalState,
  (state: any) => {
    return state.metrics;
  },
);
