<div class="outlet-wrapper">
  <ng-container *ngTemplateOutlet="isInMaintenanceMode ? maintenanceOn : maintenanceOff"></ng-container>
</div>

<ng-template #maintenanceOff>
  <router-outlet></router-outlet>
</ng-template>

<ng-template #maintenanceOn>
  <div class="maintenance-mode-layout">
    <exit-view id="close-button" [exitState]="exitState"></exit-view>
    <maintenance-mode></maintenance-mode>
  </div>
</ng-template>