import { IFlattenedStudentMap } from './../../../../services/sorter-column/flattened-student-map.constant';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { clone, filter, includes, map } from 'lodash';
import { Toggles } from '../../../../constants/toggles.constant';
import { addDynamicColumns } from '../../../../../shared/services/sorter-column/flattened-student-map.constant';
import { ToggleService } from '../../../../services/toggle/toggle.service';
import { ISchoolAssessment } from '../../../../typings/interfaces/school-assessment.interface';
import { IACOption } from '../../../../../../../projects/shared/nvps-libraries/design/interfaces/design-library.interface';
import { Subscription } from 'rxjs';

export interface IColOption {
  humanName: string;
  tag: string;
  keyName: string;
}

export interface ISelectedCol {
  human: string;
  key: string;
  canDelete: boolean;
}

export interface IColumnSelectorOutput {
  columnKeys: string[];
}

export type IColumnSelectorInput = Partial<IColumnSelectorOutput>;

const DEFAULT_KEYS = ['studentId', 'studentName'];

/**
 * This function ensures that deprecated columns are included in the column options, 
 * should tool documents contain a stale reference to them in the list of columnKeys. 
 * This results in a preserved UI.
 * @param columns 
 * @param columnKeys 
 * @returns a list of IColOption
 */
export const initColsOptions = 
  (columns: IFlattenedStudentMap, columnKeys: string[]): IColOption[] => {
  return Object.entries(columns).reduce(
    (acc, [key, column]) => {
      // If a column is deprecated, only expose it if the tool doc
      // is still persisting the deprecated columnKey
      if (!column.isDeprecated || columnKeys?.includes(key)) {
        const colOption = {
          keyName: key,
          tag: column.tag || 'general',
          humanName: column.humanName,
        }
        acc.push(colOption);
      }
      return acc;
    }, []
  )
};

@Component({
  selector: 'column-selector',
  templateUrl: './column-selector.component.html',
  styleUrls: ['./column-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ColumnSelectorComponent implements OnInit, OnDestroy {
  @Input() initialFormData: IColumnSelectorInput;
  @Input() schoolAssessments: ISchoolAssessment[];
  @ViewChild('rowContainer', { static: false }) rowContainer: ElementRef;
  @Output() completed: EventEmitter<IColumnSelectorOutput> = new EventEmitter<IColumnSelectorOutput>();
  @Output() abandoned: EventEmitter<void> = new EventEmitter<void>();

  form: FormGroup;
  selectedCols: ISelectedCol[];
  colsOptions: IColOption[];
  ACOptions: IACOption[] = [];
  ACOptionsSource: IACOption[] = [];
  availableACOptions: IACOption[] = [];
  ACOptionsMap: Map<string, IACOption>;
  tableShadow: boolean = false;

  private colSearchSubscription: Subscription;

  constructor (
    private formBuilder: FormBuilder,
    private toggleService: ToggleService,
  ) {
  }

  ngOnInit () {
    const { columnKeys } = this.initialFormData;
    this.form = this.formBuilder.group({
      colSearch: [null, Validators.required],
    });

    const allCols = this.getAllCols();
    this.colsOptions = initColsOptions(allCols, columnKeys);
    this.ACOptions = this.shapeColsForAutocomplete(this.colsOptions);
    this.ACOptionsSource = clone(this.ACOptions);
    this.ACOptionsMap = new Map(this.ACOptionsSource.map(option => [option.human, option]));

    this.selectedCols = [
      ...DEFAULT_KEYS.map(
        (key) => ({
          ...this.ACOptionsSource.find(option => option.key === key),
          canDelete: false,
        }),
      ),
      ...(columnKeys || [])
        .filter((key) => !DEFAULT_KEYS.includes(key))
        .map(
          (key) => ({
            ...this.ACOptionsSource.find(option => option.key === key),
            canDelete: true,
          }),
        ),
    ].reverse();
    this._updateACOptions();
    this.colSearchSubscription = this.form.valueChanges.subscribe(changes => {
      const { colSearch } = changes;
      // following prevents native select from causing an error due to conflict with storybook method also called select
      const selectSearchCondition = !!colSearch && !colSearch.bubbles;
      if (colSearch === '' || selectSearchCondition) {
        const searchTerm = colSearch.human ? colSearch.human : colSearch;
        if (!searchTerm || searchTerm === '') {
          this.ACOptions = this.availableACOptions;
        } else {
          this.ACOptions = filter(this.availableACOptions, (el: IACOption) => {
            return includes(el.human.toLowerCase(), searchTerm.toLowerCase());
          });
        }
      }
    });
  }

  ngOnDestroy () {
    this.colSearchSubscription.unsubscribe();
  }

  _updateACOptions (): void {
    // updates ACOptions to excluded the options in selectedColumns array
    const selectedKeys = map(this.selectedCols, col => col.key);
    this.availableACOptions = filter(this.ACOptionsSource, option => !includes(selectedKeys, option.key));
    this.ACOptions = this.availableACOptions;
  }

  columnSelected ($event?): void {
    this.form.controls.colSearch.setValue(null);
    const selectedColumn = this.ACOptionsMap.get($event);
    this.selectedCols.unshift({ ...selectedColumn, canDelete: true });
    this._updateACOptions();
  }

  columnDeleted (colHuman): void {
    this.selectedCols = filter(this.selectedCols, col => {
      return col.human !== colHuman;
    });
    this._updateACOptions();
  }

  _toggleNycTMvp (toggleNycTMvp): IFlattenedStudentMap {
    let availableCols;
    if (toggleNycTMvp) {
      availableCols = addDynamicColumns(this.schoolAssessments, toggleNycTMvp);
    } else {
      availableCols = addDynamicColumns();
    }
    return availableCols;
  }

  getAllCols () {
    const toggleNycTMvp = this.toggleService.getToggleState(Toggles.TOGGLE_NYCT_MVP);
    const allCols = this._toggleNycTMvp(toggleNycTMvp);
    return allCols;
  }

  shapeColsForAutocomplete (colOptions): IACOption[] {
    return map(colOptions, option => {
      return {
        human: option.humanName,
        key: option.keyName,
      };
    });
  }

  onTableScroll (): void {
    const scrollTop = this.rowContainer.nativeElement.scrollTop;
    this.tableShadow = scrollTop > 10;
  }

  onClear (): void {
    this.form.controls.colSearch.setValue('');
  }

  onNext () {
    const output: IColumnSelectorOutput = {
      columnKeys: this.selectedCols.map(({ key }) => key).reverse(),
    };
    this.completed.emit(output);
  }

  onBack () {
    this.abandoned.emit();
  }
}
