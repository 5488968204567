import { InstructionStep } from './instruction-step.component';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [],
  exports: [InstructionStep],
  declarations: [InstructionStep],
  providers: [],
})
export class InstructionStepModule { }
