import { createFeatureSelector, createSelector } from '@ngrx/store';
import { map } from 'lodash';
import { ISchoolAssessmentsState } from '../../reducers/assessments-reducer/school-assessments-reducer';

const getPortalState = createFeatureSelector('PORTAL_STORE');

export const getSchoolAssessmentsState = createSelector(
  getPortalState,
  (state: any) => {
    return state.schoolAssessments;
  },
);

export const getSchoolAssessmentsEntities = createSelector(
  getSchoolAssessmentsState,
  (state: ISchoolAssessmentsState) => {
    return state.schoolAssessmentsEntities;
  },
);

export const getSchoolAssessmentsEntitiesList = createSelector(
  getSchoolAssessmentsState,
  (state: ISchoolAssessmentsState) => {
    const schoolAssessmentsEntities = state.schoolAssessmentsEntities;
    return map(schoolAssessmentsEntities, (v, k) => v);
  },
);

export const getSchoolAssessmentsLoadingStatus = createSelector(
  getSchoolAssessmentsState,
  (state: ISchoolAssessmentsState) => {
    return state.loading;
  },
);

export const getSchoolAssessmentsLoadedStatus = createSelector(
  getSchoolAssessmentsState,
  (state: ISchoolAssessmentsState) => {
    return state.loaded;
  },
);
