/* tslint:disable */
import { Action } from '@ngrx/store';
export const LOAD_DASHBOARD = '[Portal] Load Dashboard';
export const SET_CALCULATED_GRAPH = '[Portal] Set Calculated Graph';
export const LOAD_DASHBOARD_FAIL = '[Portal] Load Dashboard Fail';
export const LOAD_DASHBOARD_SUCCESS = '[Portal] Load Dashboard Success';
// with min projection
export const LOAD_DASHBOARD_STUDENTS = '[Portal] Load Dashabord Students';
export const LOAD_DASHBOARD_STUDENTS_SUCCESS = '[Portal] Load Dashabord Students Success';
export const LOAD_DASHBOARD_STUDENTS_FAIL = '[Portal] Load Dashabord Students Fail';
// with min projection
export const LOAD_DASHBOARD_STUDENT_SUPPORTS = '[Portal] Load Dashabord Student Supports';
export const LOAD_DASHBOARD_STUDENT_SUPPORTS_SUCCESS = '[Portal] Load Dashabord Student Supports Success';
export const LOAD_DASHBOARD_STUDENT_SUPPORTS_FAIL = '[Portal] Load Dashabord Student Supports Fail';
export const UPDATE_DASHBOARD_STUDENTS_SUCCESS = '[Portal] Update Dashboard Students Success';
export const UPDATE_MADLIB = '[Portal] Update Madlib Selection';
export const RESET_MADLIB = '[Portal] Reset Madlib Selection';

interface IGraphState {
  loading: boolean;
  loaded: boolean;
  data: number;
}

// TODO: Change this name to `fetchAndSetGraph`
export class LoadDashboard implements Action {
  readonly type = LOAD_DASHBOARD;
  constructor(public payload: { schoolId: string; graphName: string }) {}
}

export class SetCalculatedGraph implements Action {
  readonly type = SET_CALCULATED_GRAPH;
  constructor(public payload: { graphState: IGraphState; graphName: string }) {}
}

// TODO: Change this name to `fetchAndSetGraphFail`
export class LoadDashboardFail implements Action {
  readonly type = LOAD_DASHBOARD_FAIL;
  constructor(public payload: any) {}
}

// TODO: Change this name to `fetchAndSetGraphSuccess`
export class LoadDashboardSuccess implements Action {
  readonly type = LOAD_DASHBOARD_SUCCESS;
  constructor(public payload: object) {}
}

// load dashboard students
export class LoadDashboardStudents implements Action {
  readonly type = LOAD_DASHBOARD_STUDENTS;
  constructor(public payload: object) {}
}

export class LoadDashboardStudentsSuccess implements Action {
  readonly type = LOAD_DASHBOARD_STUDENTS_SUCCESS;
  constructor(public payload: any) {}
}

export class LoadDashboardStudentsFail implements Action {
  readonly type = LOAD_DASHBOARD_STUDENTS_FAIL;
  constructor(public payload: any) {}
}

// update dashboard students
export class UpdateDashboardStudentsSuccess implements Action {
  readonly type = UPDATE_DASHBOARD_STUDENTS_SUCCESS;
  constructor(public payload: any) {}
}

// load dashboard student supports
export class LoadDashboardStudentSupports implements Action {
  readonly type = LOAD_DASHBOARD_STUDENT_SUPPORTS;
  constructor(public payload: object) {}
}

export class LoadDashboardStudentSupportsSuccess implements Action {
  readonly type = LOAD_DASHBOARD_STUDENT_SUPPORTS_SUCCESS;
  constructor(public payload: any) {}
}

export class LoadDashboardStudentSupportsFail implements Action {
  readonly type = LOAD_DASHBOARD_STUDENT_SUPPORTS_FAIL;
  constructor(public payload: any) {}
}
// Dashboard mid-level madlib selected options
export class UpdateMadlib implements Action {
  readonly type = UPDATE_MADLIB;
  constructor(public payload: any) {} // createa an interface for this
}

export class ResetMadlib implements Action {
  readonly type = RESET_MADLIB;
}

// action types
export type DashboardAction =
  | LoadDashboard
  | SetCalculatedGraph
  | LoadDashboardFail
  | LoadDashboardSuccess
  | LoadDashboardStudents
  | LoadDashboardStudentsSuccess
  | LoadDashboardStudentsFail
  | LoadDashboardStudentSupports
  | LoadDashboardStudentSupportsSuccess
  | LoadDashboardStudentSupportsFail
  | UpdateDashboardStudentsSuccess
  | UpdateMadlib
  | ResetMadlib;
