import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, Subscription } from 'rxjs';
import { debounceTime, map, startWith, filter, tap, take } from 'rxjs/operators';
import { getStudentPathsEntities } from '../../../store/selectors/student-paths-selector';
import { ICareerPath } from '../../typings/interfaces/paths.interface';
import { BaseModalComponent } from '../base-modal.component';
import { ADD_CAREER_MODAL_CONFIG } from './add-career.config';

export interface ICareerPathEntity {
  id: string;
  name: string;
}

export interface IUniqueCareerPath {
  _id: string;
  name: string;
  searchName: string;
}

export interface IAddCareerData {
  careerPath: Observable<ICareerPath[]>;
  studentId: string;
}

@Component({
  templateUrl: './add-career.component.html',
  selector: 'add-career-modal',
  encapsulation: ViewEncapsulation.None,
})
export class AddCareerModalComponent extends BaseModalComponent implements OnInit {
  // Modal Configurations
  title: string = 'Add Career';
  isProfileMode: boolean = true;
  readonly placeholder = ADD_CAREER_MODAL_CONFIG.input.placeholder;
  readonly buttons = ADD_CAREER_MODAL_CONFIG.buttons;

  isNewCareer: boolean = false;
  addBtnEnabled: boolean = false;
  selectedOption: ICareerPathEntity;
  selectedCareerType: string;
  form = new FormControl();
  filteredOptions$: Observable<IUniqueCareerPath[]>;
  pathsSubscription: Subscription;
  studentId: string;
  uniquePaths: IUniqueCareerPath[];

  careerTypeBtns = [
    { selected: true, humanName: 'Job training', key: 'Job training' },
    { selected: false, humanName: 'Apprenticeship', key: 'Apprenticeship' },
    { selected: false, humanName: 'Certification program', key: 'Certification program' },
    { selected: false, humanName: 'Public service', key: 'Public service' },
    { selected: false, humanName: 'Military', key: 'Military' },
    { selected: false, humanName: 'Other', key: 'Other' },
  ];

  constructor (
    dialogRef: MatDialogRef<AddCareerModalComponent>,
    private store: Store<any>,
    @Inject(MAT_DIALOG_DATA) public data: IAddCareerData,
  ) {
    super(dialogRef);
  }

  ngOnInit (): void {
    this.selectedCareerType = this.careerTypeBtns[0].key;
    this.studentId = this.data.studentId;

    this.pathsSubscription = combineLatest([
      this.data.careerPath,
      this.store.select(getStudentPathsEntities),
    ]).pipe(
      take(1),
      filter(responses => !responses.includes(null)),
      tap(([careerPaths, storePaths]) => {
        this.getUniqueStudentCareerPaths(careerPaths, storePaths);
      }),
    ).subscribe();

    this.filteredOptions$ = this.form.valueChanges.pipe(
      debounceTime(100),
      startWith<string>(''),
      map(val => (val.length >= 1 ? this._filter(val, this.uniquePaths) : [])),
    );
  }

  private _filter (searchText: string, uniquePaths: IUniqueCareerPath[]): IUniqueCareerPath[] {
    const filterValue = searchText.toLowerCase();

    return uniquePaths.filter(option => option.searchName.includes(filterValue)).slice(0, 100);
  }

  // exclude the paths student already has.
  getUniqueStudentCareerPaths (careerPaths: ICareerPath[], storePaths: IUniqueCareerPath[] | any): void {
    const studentPathIds = [];

    Object.keys(storePaths).forEach(studentPathId => {
      if (storePaths[studentPathId].studentId === this.studentId) {
        const pathId = storePaths[studentPathId].path.pathId;
        studentPathIds.push(pathId);
      }
    });

    const uniquePaths: IUniqueCareerPath[] = [];
    careerPaths.forEach(path => {
      if (studentPathIds.indexOf(path._id) === -1) {
        const pathObj: IUniqueCareerPath = {
          _id: path._id,
          name: path.name,
          searchName: path.name.toLowerCase(),
        };
        uniquePaths.push(pathObj);
      }
    });

    this.uniquePaths = uniquePaths;
  }

  displayFn (value): string | undefined {
    return value ? value.name : undefined;
  }

  onOptionSelection (event): void {
    this.selectedOption = event.option.value;
    this.addBtnEnabled = true;
    this.isNewCareer = false;
  }

  onChange (): void {
    this.isNewCareer = true;
    if (this.form.value.trim() !== '') {
      this.addBtnEnabled = true;
    } else {
      this.addBtnEnabled = false;
    }
  }

  onConfirm (): void {
    if (!this.addBtnEnabled) {
      return;
    }
    const option = {
      isNewCareer: this.isNewCareer, // whether a career has been selected or newly entered
      career: this.form.value, // newly creatred career name
      careerType: this.selectedCareerType,
      selectedOption: this.selectedOption,
    };
    super.close(option);
  }

  onCancel (): void {
    super.close();
  }

  onCareerTypeChange (key: string): void {
    this.careerTypeBtns.forEach(type => {
      if (type.key === key) {
        type.selected = true;
        this.selectedCareerType = type.key;
      } else {
        type.selected = false;
      }
    });
  }
}
