const MAP_GROWTH_LANGUAGE_USE = {
  mapGrowthLanguageUseScoreDateFall: {
    tag: 'MAP Growth Language Use',
    tags: ['MAP Growth Language Use', 'Language Use'],
    humanName: 'MAP Growth Language Use: Score Date (Fall)',
  },
  mapGrowthLanguageUseRitScoreFall: {
    tag: 'MAP Growth Language Use',
    tags: ['MAP Growth Language Use', 'Language Use'],
    humanName: 'MAP Growth Language Use: RIT Score (Fall)',
  },
  mapGrowthLanguageUsePercentileFall: {
    tag: 'MAP Growth Language Use',
    tags: ['MAP Growth Language Use', 'Language Use'],
    humanName: 'MAP Growth Language Use: Percentile (Fall)',
  },
  mapGrowthLanguageUseAchievementQuintileFall: {
    tag: 'MAP Growth Language Use',
    tags: ['MAP Growth Language Use', 'Language Use'],
    humanName: 'MAP Growth Language Use: Achievement Quintile (Fall)',
  },
  mapGrowthLanguageUseScoreDateWinter: {
    tag: 'MAP Growth Language Use',
    tags: ['MAP Growth Language Use', 'Language Use'],
    humanName: 'MAP Growth Language Use: Score Date (Winter)',
  },
  mapGrowthLanguageUseRitScoreWinter: {
    tag: 'MAP Growth Language Use',
    tags: ['MAP Growth Language Use', 'Language Use'],
    humanName: 'MAP Growth Language Use: RIT Score (Winter)',
  },
  mapGrowthLanguageUsePercentileWinter: {
    tag: 'MAP Growth Language Use',
    tags: ['MAP Growth Language Use', 'Language Use'],
    humanName: 'MAP Growth Language Use: Percentile (Winter)',
  },
  mapGrowthLanguageUseAchievementQuintileWinter: {
    tag: 'MAP Growth Language Use',
    tags: ['MAP Growth Language Use', 'Language Use'],
    humanName: 'MAP Growth Language Use: Achievement Quintile (Winter)',
  },
  mapGrowthLanguageUseScoreDateSpring: {
    tag: 'MAP Growth Language Use',
    tags: ['MAP Growth Language Use', 'Language Use'],
    humanName: 'MAP Growth Language Use: Score Date (Spring)',
  },
  mapGrowthLanguageUseRitScoreSpring: {
    tag: 'MAP Growth Language Use',
    tags: ['MAP Growth Language Use', 'Language Use'],
    humanName: 'MAP Growth Language Use: RIT Score (Spring)',
  },
  mapGrowthLanguageUsePercentileSpring: {
    tag: 'MAP Growth Language Use',
    tags: ['MAP Growth Language Use', 'Language Use'],
    humanName: 'MAP Growth Language Use: Percentile (Spring)',
  },
  mapGrowthLanguageUseAchievementQuintileSpring: {
    tag: 'MAP Growth Language Use',
    tags: ['MAP Growth Language Use', 'Language Use'],
    humanName: 'MAP Growth Language Use: Achievement Quintile (Spring)',
  },
};

const MAP_GROWTH_LANGUAGE_USE_PROGRESS = {
  mapGrowthLanguageUseMetProjectedGrowthFallToWinterProgress: {
    tag: 'MAP Growth Language Use',
    tags: ['MAP Growth Language Use', 'Language Use'],
    humanName: 'MAP Growth Language Use: Met Projected Growth (Fall to Winter Progress)',
  },
  mapGrowthLanguageUseConditionalGrowthPercentileFallToWinterProgress: {
    tag: 'MAP Growth Language Use',
    tags: ['MAP Growth Language Use', 'Language Use'],
    humanName: 'MAP Growth Language Use: Conditional Growth Percentile (Fall to Winter Progress)',
  },
  mapGrowthLanguageUseGrowthQuintileFallToWinterProgress: {
    tag: 'MAP Growth Language Use',
    tags: ['MAP Growth Language Use', 'Language Use'],
    humanName: 'MAP Growth Language Use: Growth Quintile (Fall to Winter Progress)',
  },
  mapGrowthLanguageUseMetProjectedGrowthWinterToSpringProgress: {
    tag: 'MAP Growth Language Use',
    tags: ['MAP Growth Language Use', 'Language Use'],
    humanName: 'MAP Growth Language Use: Met Projected Growth (Winter to Spring Progress)',
  },
  mapGrowthLanguageUseConditionalGrowthPercentileWinterToSpringProgress: {
    tag: 'MAP Growth Language Use',
    tags: ['MAP Growth Language Use', 'Language Use'],
    humanName: 'MAP Growth Language Use: Conditional Growth Percentile (Winter to Spring Progress)',
  },
  mapGrowthLanguageUseGrowthQuintileWinterToSpringProgress: {
    tag: 'MAP Growth Language Use',
    tags: ['MAP Growth Language Use', 'Language Use'],
    humanName: 'MAP Growth Language Use: Growth Quintile (Winter to Spring Progress)',
  },
  mapGrowthLanguageUseMetProjectedGrowthFallToSpringProgress: {
    tag: 'MAP Growth Language Use',
    tags: ['MAP Growth Language Use', 'Language Use'],
    humanName: 'MAP Growth Language Use: Met Projected Growth (Fall to Spring Progress)',
  },
  mapGrowthLanguageUseConditionalGrowthPercentileFallToSpringProgress: {
    tag: 'MAP Growth Language Use',
    tags: ['MAP Growth Language Use', 'Language Use'],
    humanName: 'MAP Growth Language Use: Conditional Growth Percentile (Fall to Spring Progress)',
  },
  mapGrowthLanguageUseGrowthQuintileFallToSpringProgress: {
    tag: 'MAP Growth Language Use',
    tags: ['MAP Growth Language Use', 'Language Use'],
    humanName: 'MAP Growth Language Use: Growth Quintile (Fall to Spring Progress)',
  },
};

const MAP_GROWTH_READING = {
  mapGrowthReadingScoreDateFall: {
    tag: 'MAP Growth Reading',
    tags: ['MAP Growth Reading', 'Reading'],
    humanName: 'MAP Growth Reading: Score Date (Fall)',
  },
  mapGrowthReadingRitScoreFall: {
    tag: 'MAP Growth Reading',
    tags: ['MAP Growth Reading', 'Reading'],
    humanName: 'MAP Growth Reading: RIT Score (Fall)',
  },
  mapGrowthReadingPercentileFall: {
    tag: 'MAP Growth Reading',
    tags: ['MAP Growth Reading', 'Reading'],
    humanName: 'MAP Growth Reading: Percentile (Fall)',
  },
  mapGrowthReadingAchievementQuintileFall: {
    tag: 'MAP Growth Reading',
    tags: ['MAP Growth Reading', 'Reading'],
    humanName: 'MAP Growth Reading: Achievement Quintile (Fall)',
  },
  mapGrowthReadingScoreDateWinter: {
    tag: 'MAP Growth Reading',
    tags: ['MAP Growth Reading', 'Reading'],
    humanName: 'MAP Growth Reading: Score Date (Winter)',
  },
  mapGrowthReadingRitScoreWinter: {
    tag: 'MAP Growth Reading',
    tags: ['MAP Growth Reading', 'Reading'],
    humanName: 'MAP Growth Reading: RIT Score (Winter)',
  },
  mapGrowthReadingPercentileWinter: {
    tag: 'MAP Growth Reading',
    tags: ['MAP Growth Reading', 'Reading'],
    humanName: 'MAP Growth Reading: Percentile (Winter)',
  },
  mapGrowthReadingAchievementQuintileWinter: {
    tag: 'MAP Growth Reading',
    tags: ['MAP Growth Reading', 'Reading'],
    humanName: 'MAP Growth Reading: Achievement Quintile (Winter)',
  },
  mapGrowthReadingScoreDateSpring: {
    tag: 'MAP Growth Reading',
    tags: ['MAP Growth Reading', 'Reading'],
    humanName: 'MAP Growth Reading: Score Date (Spring)',
  },
  mapGrowthReadingRitScoreSpring: {
    tag: 'MAP Growth Reading',
    tags: ['MAP Growth Reading', 'Reading'],
    humanName: 'MAP Growth Reading: RIT Score (Spring)',
  },
  mapGrowthReadingPercentileSpring: {
    tag: 'MAP Growth Reading',
    tags: ['MAP Growth Reading', 'Reading'],
    humanName: 'MAP Growth Reading: Percentile (Spring)',
  },
  mapGrowthReadingAchievementQuintileSpring: {
    tag: 'MAP Growth Reading',
    tags: ['MAP Growth Reading', 'Reading'],
    humanName: 'MAP Growth Reading: Achievement Quintile (Spring)',
  },
};

const MAP_GROWTH_READING_PROGRESS = {
  mapGrowthReadingMetProjectedGrowthFallToWinterProgress: {
    tag: 'MAP Growth Reading',
    tags: ['MAP Growth Reading', 'Reading'],
    humanName: 'MAP Growth Reading: Met Projected Growth (Fall to Winter Progress)',
  },
  mapGrowthReadingConditionalGrowthPercentileFallToWinterProgress: {
    tag: 'MAP Growth Reading',
    tags: ['MAP Growth Reading', 'Reading'],
    humanName: 'MAP Growth Reading: Conditional Growth Percentile (Fall to Winter Progress)',
  },
  mapGrowthReadingGrowthQuintileFallToWinterProgress: {
    tag: 'MAP Growth Reading',
    tags: ['MAP Growth Reading', 'Reading'],
    humanName: 'MAP Growth Reading: Growth Quintile (Fall to Winter Progress)',
  },
  mapGrowthReadingMetProjectedGrowthWinterToSpringProgress: {
    tag: 'MAP Growth Reading',
    tags: ['MAP Growth Reading', 'Reading'],
    humanName: 'MAP Growth Reading: Met Projected Growth (Winter to Spring Progress)',
  },
  mapGrowthReadingConditionalGrowthPercentileWinterToSpringProgress: {
    tag: 'MAP Growth Reading',
    tags: ['MAP Growth Reading', 'Reading'],
    humanName: 'MAP Growth Reading: Conditional Growth Percentile (Winter to Spring Progress)',
  },
  mapGrowthReadingGrowthQuintileWinterToSpringProgress: {
    tag: 'MAP Growth Reading',
    tags: ['MAP Growth Reading', 'Reading'],
    humanName: 'MAP Growth Reading: Growth Quintile (Winter to Spring Progress)',
  },
  mapGrowthReadingMetProjectedGrowthFallToSpringProgress: {
    tag: 'MAP Growth Reading',
    tags: ['MAP Growth Reading', 'Reading'],
    humanName: 'MAP Growth Reading: Met Projected Growth (Fall to Spring Progress)',
  },
  mapGrowthReadingConditionalGrowthPercentileFallToSpringProgress: {
    tag: 'MAP Growth Reading',
    tags: ['MAP Growth Reading', 'Reading'],
    humanName: 'MAP Growth Reading: Conditional Growth Percentile (Fall to Spring Progress)',
  },
  mapGrowthReadingGrowthQuintileFallToSpringProgress: {
    tag: 'MAP Growth Reading',
    tags: ['MAP Growth Reading', 'Reading'],
    humanName: 'MAP Growth Reading: Growth Quintile (Fall to Spring Progress)',
  },
};

const MAP_GROWTH_READING_HISTORICAL = {
  oneYearPriorMapGrowthReadingScoreDateSpring: {
    tag: 'MAP Growth Reading',
    tags: ['MAP Growth Reading', 'Reading', 'MAP Growth Reading'],
    humanName: 'One Year Prior - MAP Growth Reading: Score Date (Spring)',
    calculation(doc) {
      return doc;
    },
  },
  oneYearPriorMapGrowthReadingRitScoreSpring: {
    tag: 'MAP Growth Reading',
    tags: ['MAP Growth Reading', 'Reading', 'One Year Prior'],
    humanName: 'One Year Prior - MAP Growth Reading: RIT Score (Spring)',
    calculation(doc) {
      return doc;
    },
  },
  oneYearPriorMapGrowthReadingPercentileSpring: {
    tag: 'MAP Growth Reading',
    tags: ['MAP Growth Reading', 'Reading', 'One Year Prior'],
    humanName: 'One Year Prior - MAP Growth Reading: Percentile (Spring)',
    calculation(doc) {
      return doc;
    },
  },
  oneYearPriorMapGrowthReadingAchievementQuintileSpring: {
    tag: 'MAP Growth Reading',
    tags: ['MAP Growth Reading', 'Reading', 'One Year Prior'],
    humanName: 'One Year Prior - MAP Growth Reading: Achievement Quintile (Spring)',
    calculation(doc) {
      return doc;
    },
  },
  oneYearPriorMapGrowthReadingLexileMaxScoreSpring: {
    tag: 'MAP Growth Lexile',
    tags: ['MAP Growth Reading', 'MAP Growth Lexile', 'Lexile', 'One Year Prior'],
    humanName: 'One Year Prior - MAP Growth Reading: Lexile Max Score (Spring)',
    calculation(doc) {
      return doc;
    },
  },oneYearPriorMapGrowthReadingConditionalGrowthPercentileWinterToSpring: {
    tag: 'MAP Growth Reading',
    tags: ['MAP Growth Reading', 'Reading', 'One Year Prior'],
    humanName: 'One Year Prior - MAP Growth Reading: Conditional Growth Percentile (Winter to Spring)',
    calculation(doc) {
      return doc;
    },
  },
  oneYearPriorMapGrowthReadingGrowthQuintileWinterToSpring: {
    tag: 'MAP Growth Reading',
    tags: ['MAP Growth Reading', 'Reading', 'One Year Prior'],
    humanName: 'One Year Prior - MAP Growth Reading: Growth Quintile (Winter to Spring)',
    calculation(doc) {
      return doc;
    },
  },
  oneYearPriorMapGrowthReadingMetProjectedGrowthWinterToSpring: {
    tag: 'MAP Growth Reading',
    tags: ['MAP Growth Reading', 'Reading', 'One Year Prior'],
    humanName: 'One Year Prior - MAP Growth Reading: Met Projected Growth (Winter to Spring)',
    calculation(doc) {
      return doc;
    },
  },
  oneYearPriorMapGrowthReadingConditionalGrowthPercentileFallToSpring: {
    tag: 'MAP Growth Reading',
    tags: ['MAP Growth Reading', 'Reading', 'One Year Prior'],
    humanName: 'One Year Prior - MAP Growth Reading: Conditional Growth Percentile (Fall to Spring)',
    calculation(doc) {
      return doc;
    },
  },
  oneYearPriorMapGrowthReadingGrowthQuintileFallToSpring: {
    tag: 'MAP Growth Reading',
    tags: ['MAP Growth Reading', 'Reading', 'One Year Prior'],
    humanName: 'One Year Prior - MAP Growth Reading: Growth Quintile (Fall to Spring)',
    calculation(doc) {
      return doc;
    },
  },
  oneYearPriorMapGrowthReadingMetProjectedGrowthFallToSpring: {
    tag: 'MAP Growth Reading',
    tags: ['MAP Growth Reading', 'Reading', 'One Year Prior'],
    humanName: 'One Year Prior - MAP Growth Reading: Met Projected Growth (Fall to Spring)',
    calculation(doc) {
      return doc;
    },
  },
  oneYearPriorMapGrowthReadingScoreDateWinter: {
    tag: 'MAP Growth Reading',
    tags: ['MAP Growth Reading', 'Reading', 'One Year Prior'],
    humanName: 'One Year Prior - MAP Growth Reading: Score Date (Winter)',
    calculation(doc) {
      return doc;
    },
  },
  oneYearPriorMapGrowthReadingRitScoreWinter: {
    tag: 'MAP Growth Reading',
    tags: ['MAP Growth Reading', 'Reading', 'One Year Prior'],
    humanName: 'One Year Prior - MAP Growth Reading: RIT Score (Winter)',
    calculation(doc) {
      return doc;
    },
  },
  oneYearPriorMapGrowthReadingPercentileWinter: {
    tag: 'MAP Growth Reading',
    tags: ['MAP Growth Reading', 'Reading', 'One Year Prior'],
    humanName: 'One Year Prior - MAP Growth Reading: Percentile (Winter)',
    calculation(doc) {
      return doc;
    },
  },
  oneYearPriorMapGrowthReadingAchievementQuintileWinter: {
    tag: 'MAP Growth Reading',
    tags: ['MAP Growth Reading', 'Reading', 'One Year Prior'],
    humanName: 'One Year Prior - MAP Growth Reading: Achievement Quintile (Winter)',
    calculation(doc) {
      return doc;
    },
  },
  oneYearPriorMapGrowthReadingLexileMaxScoreWinter: {
    tag: 'MAP Growth Lexile',
    tags: ['MAP Growth Reading', 'MAP Growth Lexile', 'Lexile', 'One Year Prior'],
    humanName: 'One Year Prior - MAP Growth Reading: Lexile Max Score (Winter)',
    calculation(doc) {
      return doc;
    },
  },
  oneYearPriorMapGrowthReadingConditionalGrowthPercentileFallToWinter: {
    tag: 'MAP Growth Reading',
    tags: ['MAP Growth Reading', 'Reading'],
    humanName: 'One Year Prior - MAP Growth Reading: Conditional Growth Percentile (Fall to Winter)',
    calculation(doc) {
      return doc;
    },
  },
  oneYearPriorMapGrowthReadingGrowthQuintileFallToWinter: {
    tag: 'MAP Growth Reading',
    tags: ['MAP Growth Reading', 'Reading'],
    humanName: 'One Year Prior - MAP Growth Reading: Growth Quintile (Fall to Winter)',
    calculation(doc) {
      return doc;
    },
  },
  oneYearPriorMapGrowthReadingMetProjectedGrowthFallToWinter: {
    tag: 'MAP Growth Reading',
    tags: ['MAP Growth Reading', 'Reading'],
    humanName: 'One Year Prior - MAP Growth Reading: Met Projected Growth (Fall to Winter)',
    calculation(doc) {
      return doc;
    },
  },
  oneYearPriorMapGrowthReadingScoreDateFall: {
    tag: 'MAP Growth Reading',
    tags: ['MAP Growth Reading', 'Reading', 'One Year Prior'],
    humanName: 'One Year Prior - MAP Growth Reading: Score Date (Fall)',
    calculation(doc) {
      return doc;
    },
  },
  oneYearPriorMapGrowthReadingRitScoreFall: {
    tag: 'MAP Growth Reading',
    tags: ['MAP Growth Reading', 'Reading', 'One Year Prior'],
    humanName: 'One Year Prior - MAP Growth Reading: RIT Score (Fall)',
    calculation(doc) {
      return doc;
    },
  },
  oneYearPriorMapGrowthReadingPercentileFall: {
    tag: 'MAP Growth Reading',
    tags: ['MAP Growth Reading', 'Reading', 'One Year Prior'],
    humanName: 'One Year Prior - MAP Growth Reading: Percentile (Fall)',
    calculation(doc) {
      return doc;
    },
  },
  oneYearPriorMapGrowthReadingAchievementQuintileFall: {
    tag: 'MAP Growth Reading',
    tags: ['MAP Growth Reading', 'Reading', 'One Year Prior'],
    humanName: 'One Year Prior - MAP Growth Reading: Achievement Quintile (Fall)',
    calculation(doc) {
      return doc;
    },
  }, 
  oneYearPriorMapGrowthReadingLexileMaxScoreFall: {
    tag: 'MAP Growth Lexile',
    tags: ['MAP Growth Reading', 'MAP Growth Lexile', 'Lexile', 'One Year Prior'],
    humanName: 'One Year Prior - MAP Growth Reading: Lexile Max Score (Fall)',
    calculation(doc) {
      return doc;
    },
  },
};

const MAP_GROWTH_READING_DOMAIN = {
  mapGrowthReadingDomainLiteraryAndInformationalTextRitScoreFall: {
    tag: 'MAP Growth Reading Domain',
    tags: ['MAP Growth Reading Domain', 'Reading Domain'],
    humanName: 'MAP Growth Reading Domain: Literary and Informational Text RIT Score (Fall)',
  },
  mapGrowthReadingDomainLiteraryAndInformationalTextAchievementLevelFall: {
    tag: 'MAP Growth Reading Domain',
    tags: ['MAP Growth Reading Domain', 'Reading Domain'],
    humanName: 'MAP Growth Reading Domain: Literary and Informational Text Achievement Level (Fall)',
  },
  mapGrowthReadingDomainLiteraryAndInformationalTextRitScoreWinter: {
    tag: 'MAP Growth Reading Domain',
    tags: ['MAP Growth Reading Domain', 'Reading Domain'],
    humanName: 'MAP Growth Reading Domain: Literary and Informational Text RIT Score (Winter)',
  },
  mapGrowthReadingDomainLiteraryAndInformationalTextAchievementLevelWinter: {
    tag: 'MAP Growth Reading Domain',
    tags: ['MAP Growth Reading Domain', 'Reading Domain'],
    humanName: 'MAP Growth Reading Domain: Literary and Informational Text Achievement Level (Winter)',
  },
  mapGrowthReadingDomainLiteraryAndInformationalTextRitScoreSpring: {
    tag: 'MAP Growth Reading Domain',
    tags: ['MAP Growth Reading Domain', 'Reading Domain'],
    humanName: 'MAP Growth Reading Domain: Literary and Informational Text RIT Score (Spring)',
  },
  mapGrowthReadingDomainLiteraryAndInformationalTextAchievementLevelSpring: {
    tag: 'MAP Growth Reading Domain',
    tags: ['MAP Growth Reading Domain', 'Reading Domain'],
    humanName: 'MAP Growth Reading Domain: Literary and Informational Text Achievement Level (Spring)',
  },
  mapGrowthReadingDomainFoundationalSkillsRitScoreFall: {
    tag: 'MAP Growth Reading Domain',
    tags: ['MAP Growth Reading Domain', 'Reading Domain'],
    humanName: 'MAP Growth Reading Domain: Foundational Skills RIT Score (Fall)',
  },
  mapGrowthReadingDomainFoundationalSkillsAchievementLevelFall: {
    tag: 'MAP Growth Reading Domain',
    tags: ['MAP Growth Reading Domain', 'Reading Domain'],
    humanName: 'MAP Growth Reading Domain: Foundational Skills Achievement Level (Fall)',
  },
  mapGrowthReadingDomainFoundationalSkillsRitScoreWinter: {
    tag: 'MAP Growth Reading Domain',
    tags: ['MAP Growth Reading Domain', 'Reading Domain'],
    humanName: 'MAP Growth Reading Domain: Foundational Skills RIT Score (Winter)',
  },
  mapGrowthReadingDomainFoundationalSkillsAchievementLevelWinter: {
    tag: 'MAP Growth Reading Domain',
    tags: ['MAP Growth Reading Domain', 'Reading Domain'],
    humanName: 'MAP Growth Reading Domain: Foundational Skills Achievement Level (Winter)',
  },
  mapGrowthReadingDomainFoundationalSkillsRitScoreSpring: {
    tag: 'MAP Growth Reading Domain',
    tags: ['MAP Growth Reading Domain', 'Reading Domain'],
    humanName: 'MAP Growth Reading Domain: Foundational Skills RIT Score (Spring)',
  },
  mapGrowthReadingDomainFoundationalSkillsAchievementLevelSpring: {
    tag: 'MAP Growth Reading Domain',
    tags: ['MAP Growth Reading Domain', 'Reading Domain'],
    humanName: 'MAP Growth Reading Domain: Foundational Skills Achievement Level (Spring)',
  },
  mapGrowthReadingDomainWritingAndConventionsOfAcademicEnglishRitScoreFall: {
    tag: 'MAP Growth Reading Domain',
    tags: ['MAP Growth Reading Domain', 'Reading Domain'],
    humanName: 'MAP Growth Reading Domain: Writing and Conventions of Academic English RIT Score (Fall)',
  },
  mapGrowthReadingDomainWritingAndConventionsOfAcademicEnglishAchievementLevelFall: {
    tag: 'MAP Growth Reading Domain',
    tags: ['MAP Growth Reading Domain', 'Reading Domain'],
    humanName: 'MAP Growth Reading Domain: Writing and Conventions of Academic English Achievement Level (Fall)',
  },
  mapGrowthReadingDomainWritingAndConventionsOfAcademicEnglishRitScoreWinter: {
    tag: 'MAP Growth Reading Domain',
    tags: ['MAP Growth Reading Domain', 'Reading Domain'],
    humanName: 'MAP Growth Reading Domain: Writing and Conventions of Academic English RIT Score (Winter)',
  },
  mapGrowthReadingDomainWritingAndConventionsOfAcademicEnglishAchievementLevelWinter: {
    tag: 'MAP Growth Reading Domain',
    tags: ['MAP Growth Reading Domain', 'Reading Domain'],
    humanName: 'MAP Growth Reading Domain: Writing and Conventions of Academic English Achievement Level (Winter)',
  },
  mapGrowthReadingDomainWritingAndConventionsOfAcademicEnglishRitScoreSpring: {
    tag: 'MAP Growth Reading Domain',
    tags: ['MAP Growth Reading Domain', 'Reading Domain'],
    humanName: 'MAP Growth Reading Domain: Writing and Conventions of Academic English RIT Score (Spring)',
  },
  mapGrowthReadingDomainWritingAndConventionsOfAcademicEnglishAchievementLevelSpring: {
    tag: 'MAP Growth Reading Domain',
    tags: ['MAP Growth Reading Domain', 'Reading Domain'],
    humanName: 'MAP Growth Reading Domain: Writing and Conventions of Academic English Achievement Level (Spring)',
  },
  mapGrowthReadingDomainVocabularyAcquisitionAndUseRitScoreFall: {
    tag: 'MAP Growth Reading Domain',
    tags: ['MAP Growth Reading Domain', 'Reading Domain'],
    humanName: 'MAP Growth Reading Domain: Vocabulary Acquisition and Use RIT Score (Fall)',
  },
  mapGrowthReadingDomainVocabularyAcquisitionAndUseAchievementLevelFall: {
    tag: 'MAP Growth Reading Domain',
    tags: ['MAP Growth Reading Domain', 'Reading Domain'],
    humanName: 'MAP Growth Reading Domain: Vocabulary Acquisition and Use Achievement Level (Fall)',
  },
  mapGrowthReadingDomainVocabularyAcquisitionAndUseRitScoreWinter: {
    tag: 'MAP Growth Reading Domain',
    tags: ['MAP Growth Reading Domain', 'Reading Domain'],
    humanName: 'MAP Growth Reading Domain: Vocabulary Acquisition and Use RIT Score (Winter)',
  },
  mapGrowthReadingDomainVocabularyAcquisitionAndUseAchievementLevelWinter: {
    tag: 'MAP Growth Reading Domain',
    tags: ['MAP Growth Reading Domain', 'Reading Domain'],
    humanName: 'MAP Growth Reading Domain: Vocabulary Acquisition and Use Achievement Level (Winter)',
  },
  mapGrowthReadingDomainVocabularyAcquisitionAndUseRitScoreSpring: {
    tag: 'MAP Growth Reading Domain',
    tags: ['MAP Growth Reading Domain', 'Reading Domain'],
    humanName: 'MAP Growth Reading Domain: Vocabulary Acquisition and Use RIT Score (Spring)',
  },
  mapGrowthReadingDomainVocabularyAcquisitionAndUseAchievementLevelSpring: {
    tag: 'MAP Growth Reading Domain',
    tags: ['MAP Growth Reading Domain', 'Reading Domain'],
    humanName: 'MAP Growth Reading Domain: Vocabulary Acquisition and Use Achievement Level (Spring)',
  },
  mapGrowthReadingDomainKeyIdeasDetailsAndConnectionsRitScoreFall: {
    tag: 'MAP Growth Reading Domain',
    tags: ['MAP Growth Reading Domain', 'Reading Domain'],
    humanName: 'MAP Growth Reading Domain: Key Ideas, Details, and Connections RIT Score (Fall)',
  },
  mapGrowthReadingDomainKeyIdeasDetailsAndConnectionsAchievementLevelFall: {
    tag: 'MAP Growth Reading Domain',
    tags: ['MAP Growth Reading Domain', 'Reading Domain'],
    humanName: 'MAP Growth Reading Domain: Key Ideas, Details, and Connections Achievement Level (Fall)',
  },
  mapGrowthReadingDomainKeyIdeasDetailsAndConnectionsRitScoreWinter: {
    tag: 'MAP Growth Reading Domain',
    tags: ['MAP Growth Reading Domain', 'Reading Domain'],
    humanName: 'MAP Growth Reading Domain: Key Ideas, Details, and Connections RIT Score (Winter)',
  },
  mapGrowthReadingDomainKeyIdeasDetailsAndConnectionsAchievementLevelWinter: {
    tag: 'MAP Growth Reading Domain',
    tags: ['MAP Growth Reading Domain', 'Reading Domain'],
    humanName: 'MAP Growth Reading Domain: Key Ideas, Details, and Connections Achievement Level (Winter)',
  },
  mapGrowthReadingDomainKeyIdeasDetailsAndConnectionsRitScoreSpring: {
    tag: 'MAP Growth Reading Domain',
    tags: ['MAP Growth Reading Domain', 'Reading Domain'],
    humanName: 'MAP Growth Reading Domain: Key Ideas, Details, and Connections RIT Score (Spring)',
  },
  mapGrowthReadingDomainKeyIdeasDetailsAndConnectionsAchievementLevelSpring: {
    tag: 'MAP Growth Reading Domain',
    tags: ['MAP Growth Reading Domain', 'Reading Domain'],
    humanName: 'MAP Growth Reading Domain: Key Ideas, Details, and Connections Achievement Level (Spring)',
  },
  mapGrowthReadingDomainUnderstandLanguageCraftAndStructureRitScoreFall: {
    tag: 'MAP Growth Reading Domain',
    tags: ['MAP Growth Reading Domain', 'Reading Domain'],
    humanName: 'MAP Growth Reading Domain: Understand Language, Craft, and Structure RIT Score (Fall)',
  },
  mapGrowthReadingDomainUnderstandLanguageCraftAndStructureAchievementLevelFall: {
    tag: 'MAP Growth Reading Domain',
    tags: ['MAP Growth Reading Domain', 'Reading Domain'],
    humanName: 'MAP Growth Reading Domain: Understand Language, Craft, and Structure Achievement Level (Fall)',
  },
  mapGrowthReadingDomainUnderstandLanguageCraftAndStructureRitScoreWinter: {
    tag: 'MAP Growth Reading Domain',
    tags: ['MAP Growth Reading Domain', 'Reading Domain'],
    humanName: 'MAP Growth Reading Domain: Understand Language, Craft, and Structure RIT Score (Winter)',
  },
  mapGrowthReadingDomainUnderstandLanguageCraftAndStructureAchievementLevelWinter: {
    tag: 'MAP Growth Reading Domain',
    tags: ['MAP Growth Reading Domain', 'Reading Domain'],
    humanName: 'MAP Growth Reading Domain: Understand Language, Craft, and Structure Achievement Level (Winter)',
  },
  mapGrowthReadingDomainUnderstandLanguageCraftAndStructureRitScoreSpring: {
    tag: 'MAP Growth Reading Domain',
    tags: ['MAP Growth Reading Domain', 'Reading Domain'],
    humanName: 'MAP Growth Reading Domain: Understand Language, Craft, and Structure RIT Score (Spring)',
  },
  mapGrowthReadingDomainUnderstandLanguageCraftAndStructureAchievementLevelSpring: {
    tag: 'MAP Growth Reading Domain',
    tags: ['MAP Growth Reading Domain', 'Reading Domain'],
    humanName: 'MAP Growth Reading Domain: Understand Language, Craft, and Structure Achievement Level (Spring)',
  },
};

const MAP_GROWTH_MATH = {
  mapGrowthMathK12ScoreDateFall: {
    tag: 'MAP Growth Math K-12',
    tags: ['MAP Growth Math K-12', 'Math K-12'],
    humanName: 'MAP Growth Math K-12: Score Date (Fall)',
  },
  mapGrowthMathK12RitScoreFall: {
    tag: 'MAP Growth Math K-12',
    tags: ['MAP Growth Math K-12', 'Math K-12'],
    humanName: 'MAP Growth Math K-12: RIT Score (Fall)',
  },
  mapGrowthMathK12PercentileFall: {
    tag: 'MAP Growth Math K-12',
    tags: ['MAP Growth Math K-12', 'Math K-12'],
    humanName: 'MAP Growth Math K-12: Percentile (Fall)',
  },
  mapGrowthMathK12AchievementQuintileFall: {
    tag: 'MAP Growth Math K-12',
    tags: ['MAP Growth Math K-12', 'Math K-12'],
    humanName: 'MAP Growth Math K-12: Achievement Quintile (Fall)',
  },
  mapGrowthMathK12ScoreDateWinter: {
    tag: 'MAP Growth Math K-12',
    tags: ['MAP Growth Math K-12', 'Math K-12'],
    humanName: 'MAP Growth Math K-12: Score Date (Winter)',
  },
  mapGrowthMathK12RitScoreWinter: {
    tag: 'MAP Growth Math K-12',
    tags: ['MAP Growth Math K-12', 'Math K-12'],
    humanName: 'MAP Growth Math K-12: RIT Score (Winter)',
  },
  mapGrowthMathK12PercentileWinter: {
    tag: 'MAP Growth Math K-12',
    tags: ['MAP Growth Math K-12', 'Math K-12'],
    humanName: 'MAP Growth Math K-12: Percentile (Winter)',
  },
  mapGrowthMathK12AchievementQuintileWinter: {
    tag: 'MAP Growth Math K-12',
    tags: ['MAP Growth Math K-12', 'Math K-12'],
    humanName: 'MAP Growth Math K-12: Achievement Quintile (Winter)',
  },
  mapGrowthMathK12ScoreDateSpring: {
    tag: 'MAP Growth Math K-12',
    tags: ['MAP Growth Math K-12', 'Math K-12'],
    humanName: 'MAP Growth Math K-12: Score Date (Spring)',
  },
  mapGrowthMathK12RitScoreSpring: {
    tag: 'MAP Growth Math K-12',
    tags: ['MAP Growth Math K-12', 'Math K-12'],
    humanName: 'MAP Growth Math K-12: RIT Score (Spring)',
  },
  mapGrowthMathK12PercentileSpring: {
    tag: 'MAP Growth Math K-12',
    tags: ['MAP Growth Math K-12', 'Math K-12'],
    humanName: 'MAP Growth Math K-12: Percentile (Spring)',
  },
  mapGrowthMathK12AchievementQuintileSpring: {
    tag: 'MAP Growth Math K-12',
    tags: ['MAP Growth Math K-12', 'Math K-12'],
    humanName: 'MAP Growth Math K-12: Achievement Quintile (Spring)',
  },
};

const MAP_GROWTH_MATH_K12_PROGRESS = {
  mapGrowthMathK12MetProjectedGrowthFallToWinterProgress: {
    tag: 'MAP Growth Math K-12',
    tags: ['MAP Growth Math K-12', 'Math K-12'],
    humanName: 'MAP Growth Math K-12: Met Projected Growth (Fall to Winter Progress)',
  },
  mapGrowthMathK12ConditionalGrowthPercentileFallToWinterProgress: {
    tag: 'MAP Growth Math K-12',
    tags: ['MAP Growth Math K-12', 'Math K-12'],
    humanName: 'MAP Growth Math K-12: Conditional Growth Percentile (Fall to Winter Progress)',
  },
  mapGrowthMathK12GrowthQuintileFallToWinterProgress: {
    tag: 'MAP Growth Math K-12',
    tags: ['MAP Growth Math K-12', 'Math K-12'],
    humanName: 'MAP Growth Math K-12: Growth Quintile (Fall to Winter Progress)',
  },
  mapGrowthMathK12MetProjectedGrowthWinterToSpringProgress: {
    tag: 'MAP Growth Math K-12',
    tags: ['MAP Growth Math K-12', 'Math K-12'],
    humanName: 'MAP Growth Math K-12: Met Projected Growth (Winter to Spring Progress)',
  },
  mapGrowthMathK12ConditionalGrowthPercentileWinterToSpringProgress: {
    tag: 'MAP Growth Math K-12',
    tags: ['MAP Growth Math K-12', 'Math K-12'],
    humanName: 'MAP Growth Math K-12: Conditional Growth Percentile (Winter to Spring Progress)',
  },
  mapGrowthMathK12GrowthQuintileWinterToSpringProgress: {
    tag: 'MAP Growth Math K-12',
    tags: ['MAP Growth Math K-12', 'Math K-12'],
    humanName: 'MAP Growth Math K-12: Growth Quintile (Winter to Spring Progress)',
  },
  mapGrowthMathK12MetProjectedGrowthFallToSpringProgress: {
    tag: 'MAP Growth Math K-12',
    tags: ['MAP Growth Math K-12', 'Math K-12'],
    humanName: 'MAP Growth Math K-12: Met Projected Growth (Fall to Spring Progress)',
  },
  mapGrowthMathK12ConditionalGrowthPercentileFallToSpringProgress: {
    tag: 'MAP Growth Math K-12',
    tags: ['MAP Growth Math K-12', 'Math K-12'],
    humanName: 'MAP Growth Math K-12: Conditional Growth Percentile (Fall to Spring Progress)',
  },
  mapGrowthMathK12GrowthQuintileFallToSpringProgress: {
    tag: 'MAP Growth Math K-12',
    tags: ['MAP Growth Math K-12', 'Math K-12'],
    humanName: 'MAP Growth Math K-12: Growth Quintile (Fall to Spring Progress)',
  },
};

const MAP_GROWTH_MATH_K12_DOMAIN = {
  mapGrowthMathK12DomainOperationsAndAlgebraicThinkingRitScoreFall: {
    tag: 'MAP Growth Math K-12 Domain',
    tags: ['MAP Growth Math K-12 Domain', 'Math K-12 Domain'],
    humanName: 'MAP Growth Math K-12 Domain: Operations and Algebraic Thinking RIT Score (Fall)',
  },
  mapGrowthMathK12DomainOperationsAndAlgebraicThinkingAchievementLevelFall: {
    tag: 'MAP Growth Math K-12 Domain',
    tags: ['MAP Growth Math K-12 Domain', 'Math K-12 Domain'],
    humanName: 'MAP Growth Math K-12 Domain: Operations and Algebraic Thinking Achievement Level (Fall)',
  },
  mapGrowthMathK12DomainOperationsAndAlgebraicThinkingRitScoreWinter: {
    tag: 'MAP Growth Math K-12 Domain',
    tags: ['MAP Growth Math K-12 Domain', 'Math K-12 Domain'],
    humanName: 'MAP Growth Math K-12 Domain: Operations and Algebraic Thinking RIT Score (Winter)',
  },
  mapGrowthMathK12DomainOperationsAndAlgebraicThinkingAchievementLevelWinter: {
    tag: 'MAP Growth Math K-12 Domain',
    tags: ['MAP Growth Math K-12 Domain', 'Math K-12 Domain'],
    humanName: 'MAP Growth Math K-12 Domain: Operations and Algebraic Thinking Achievement Level (Winter)',
  },
  mapGrowthMathK12DomainOperationsAndAlgebraicThinkingRitScoreSpring: {
    tag: 'MAP Growth Math K-12 Domain',
    tags: ['MAP Growth Math K-12 Domain', 'Math K-12 Domain'],
    humanName: 'MAP Growth Math K-12 Domain: Operations and Algebraic Thinking RIT Score (Spring)',
  },
  mapGrowthMathK12DomainOperationsAndAlgebraicThinkingAchievementLevelSpring: {
    tag: 'MAP Growth Math K-12 Domain',
    tags: ['MAP Growth Math K-12 Domain', 'Math K-12 Domain'],
    humanName: 'MAP Growth Math K-12 Domain: Operations and Algebraic Thinking Achievement Level (Spring)',
  },
  mapGrowthMathK12DomainNumberAndOperationsRitScoreFall: {
    tag: 'MAP Growth Math K-12 Domain',
    tags: ['MAP Growth Math K-12 Domain', 'Math K-12 Domain'],
    humanName: 'MAP Growth Math K-12 Domain: Number and Operations RIT Score (Fall)',
  },
  mapGrowthMathK12DomainNumberAndOperationsAchievementLevelFall: {
    tag: 'MAP Growth Math K-12 Domain',
    tags: ['MAP Growth Math K-12 Domain', 'Math K-12 Domain'],
    humanName: 'MAP Growth Math K-12 Domain: Number and Operations Achievement Level (Fall)',
  },
  mapGrowthMathK12DomainNumberAndOperationsRitScoreWinter: {
    tag: 'MAP Growth Math K-12 Domain',
    tags: ['MAP Growth Math K-12 Domain', 'Math K-12 Domain'],
    humanName: 'MAP Growth Math K-12 Domain: Number and Operations RIT Score (Winter)',
  },
  mapGrowthMathK12DomainNumberAndOperationsAchievementLevelWinter: {
    tag: 'MAP Growth Math K-12 Domain',
    tags: ['MAP Growth Math K-12 Domain', 'Math K-12 Domain'],
    humanName: 'MAP Growth Math K-12 Domain: Number and Operations Achievement Level (Winter)',
  },
  mapGrowthMathK12DomainNumberAndOperationsRitScoreSpring: {
    tag: 'MAP Growth Math K-12 Domain',
    tags: ['MAP Growth Math K-12 Domain', 'Math K-12 Domain'],
    humanName: 'MAP Growth Math K-12 Domain: Number and Operations RIT Score (Spring)',
  },
  mapGrowthMathK12DomainNumberAndOperationsAchievementLevelSpring: {
    tag: 'MAP Growth Math K-12 Domain',
    tags: ['MAP Growth Math K-12 Domain', 'Math K-12 Domain'],
    humanName: 'MAP Growth Math K-12 Domain: Number and Operations Achievement Level (Spring)',
  },
  mapGrowthMathK12DomainMeasurementAndDataRitScoreFall: {
    tag: 'MAP Growth Math K-12 Domain',
    tags: ['MAP Growth Math K-12 Domain', 'Math K-12 Domain'],
    humanName: 'MAP Growth Math K-12 Domain: Measurement and Data RIT Score (Fall)',
  },
  mapGrowthMathK12DomainMeasurementAndDataAchievementLevelFall: {
    tag: 'MAP Growth Math K-12 Domain',
    tags: ['MAP Growth Math K-12 Domain', 'Math K-12 Domain'],
    humanName: 'MAP Growth Math K-12 Domain: Measurement and Data Achievement Level (Fall)',
  },
  mapGrowthMathK12DomainMeasurementAndDataRitScoreWinter: {
    tag: 'MAP Growth Math K-12 Domain',
    tags: ['MAP Growth Math K-12 Domain', 'Math K-12 Domain'],
    humanName: 'MAP Growth Math K-12 Domain: Measurement and Data RIT Score (Winter)',
  },
  mapGrowthMathK12DomainMeasurementAndDataAchievementLevelWinter: {
    tag: 'MAP Growth Math K-12 Domain',
    tags: ['MAP Growth Math K-12 Domain', 'Math K-12 Domain'],
    humanName: 'MAP Growth Math K-12 Domain: Measurement and Data Achievement Level (Winter)',
  },
  mapGrowthMathK12DomainMeasurementAndDataRitScoreSpring: {
    tag: 'MAP Growth Math K-12 Domain',
    tags: ['MAP Growth Math K-12 Domain', 'Math K-12 Domain'],
    humanName: 'MAP Growth Math K-12 Domain: Measurement and Data RIT Score (Spring)',
  },
  mapGrowthMathK12DomainMeasurementAndDataAchievementLevelSpring: {
    tag: 'MAP Growth Math K-12 Domain',
    tags: ['MAP Growth Math K-12 Domain', 'Math K-12 Domain'],
    humanName: 'MAP Growth Math K-12 Domain: Measurement and Data Achievement Level (Spring)',
  },
  mapGrowthMathK12DomainGeometryRitScoreFall: {
    tag: 'MAP Growth Math K-12 Domain',
    tags: ['MAP Growth Math K-12 Domain', 'Math K-12 Domain'],
    humanName: 'MAP Growth Math K-12 Domain: Geometry RIT Score (Fall)',
  },
  mapGrowthMathK12DomainGeometryAchievementLevelFall: {
    tag: 'MAP Growth Math K-12 Domain',
    tags: ['MAP Growth Math K-12 Domain', 'Math K-12 Domain'],
    humanName: 'MAP Growth Math K-12 Domain: Geometry Achievement Level (Fall)',
  },
  mapGrowthMathK12DomainGeometryRitScoreWinter: {
    tag: 'MAP Growth Math K-12 Domain',
    tags: ['MAP Growth Math K-12 Domain', 'Math K-12 Domain'],
    humanName: 'MAP Growth Math K-12 Domain: Geometry RIT Score (Winter)',
  },
  mapGrowthMathK12DomainGeometryAchievementLevelWinter: {
    tag: 'MAP Growth Math K-12 Domain',
    tags: ['MAP Growth Math K-12 Domain', 'Math K-12 Domain'],
    humanName: 'MAP Growth Math K-12 Domain: Geometry Achievement Level (Winter)',
  },
  mapGrowthMathK12DomainGeometryRitScoreSpring: {
    tag: 'MAP Growth Math K-12 Domain',
    tags: ['MAP Growth Math K-12 Domain', 'Math K-12 Domain'],
    humanName: 'MAP Growth Math K-12 Domain: Geometry RIT Score (Spring)',
  },
  mapGrowthMathK12DomainGeometryAchievementLevelSpring: {
    tag: 'MAP Growth Math K-12 Domain',
    tags: ['MAP Growth Math K-12 Domain', 'Math K-12 Domain'],
    humanName: 'MAP Growth Math K-12 Domain: Geometry Achievement Level (Spring)',
  },
  mapGrowthMathK12DomainTheRealAndComplexNumberSystemsRitScoreFall: {
    tag: 'MAP Growth Math K-12 Domain',
    tags: ['MAP Growth Math K-12 Domain', 'Math K-12 Domain'],
    humanName: 'MAP Growth Math K-12 Domain: The Real and Complex Number Systems RIT Score (Fall)',
  },
  mapGrowthMathK12DomainTheRealAndComplexNumberSystemsAchievementLevelFall: {
    tag: 'MAP Growth Math K-12 Domain',
    tags: ['MAP Growth Math K-12 Domain', 'Math K-12 Domain'],
    humanName: 'MAP Growth Math K-12 Domain: The Real and Complex Number Systems Achievement Level (Fall)',
  },
  mapGrowthMathK12DomainTheRealAndComplexNumberSystemsRitScoreWinter: {
    tag: 'MAP Growth Math K-12 Domain',
    tags: ['MAP Growth Math K-12 Domain', 'Math K-12 Domain'],
    humanName: 'MAP Growth Math K-12 Domain: The Real and Complex Number Systems RIT Score (Winter)',
  },
  mapGrowthMathK12DomainTheRealAndComplexNumberSystemsAchievementLevelWinter: {
    tag: 'MAP Growth Math K-12 Domain',
    tags: ['MAP Growth Math K-12 Domain', 'Math K-12 Domain'],
    humanName: 'MAP Growth Math K-12 Domain: The Real and Complex Number Systems Achievement Level (Winter)',
  },
  mapGrowthMathK12DomainTheRealAndComplexNumberSystemsRitScoreSpring: {
    tag: 'MAP Growth Math K-12 Domain',
    tags: ['MAP Growth Math K-12 Domain', 'Math K-12 Domain'],
    humanName: 'MAP Growth Math K-12 Domain: The Real and Complex Number Systems RIT Score (Spring)',
  },
  mapGrowthMathK12DomainTheRealAndComplexNumberSystemsAchievementLevelSpring: {
    tag: 'MAP Growth Math K-12 Domain',
    tags: ['MAP Growth Math K-12 Domain', 'Math K-12 Domain'],
    humanName: 'MAP Growth Math K-12 Domain: The Real and Complex Number Systems Achievement Level (Spring)',
  },
  mapGrowthMathK12DomainStatisticsAndProbabilityRitScoreFall: {
    tag: 'MAP Growth Math K-12 Domain',
    tags: ['MAP Growth Math K-12 Domain', 'Math K-12 Domain'],
    humanName: 'MAP Growth Math K-12 Domain: Statistics and Probability RIT Score (Fall)',
  },
  mapGrowthMathK12DomainStatisticsAndProbabilityAchievementLevelFall: {
    tag: 'MAP Growth Math K-12 Domain',
    tags: ['MAP Growth Math K-12 Domain', 'Math K-12 Domain'],
    humanName: 'MAP Growth Math K-12 Domain: Statistics and Probability Achievement Level (Fall)',
  },
  mapGrowthMathK12DomainStatisticsAndProbabilityRitScoreWinter: {
    tag: 'MAP Growth Math K-12 Domain',
    tags: ['MAP Growth Math K-12 Domain', 'Math K-12 Domain'],
    humanName: 'MAP Growth Math K-12 Domain: Statistics and Probability RIT Score (Winter)',
  },
  mapGrowthMathK12DomainStatisticsAndProbabilityAchievementLevelWinter: {
    tag: 'MAP Growth Math K-12 Domain',
    tags: ['MAP Growth Math K-12 Domain', 'Math K-12 Domain'],
    humanName: 'MAP Growth Math K-12 Domain: Statistics and Probability Achievement Level (Winter)',
  },
  mapGrowthMathK12DomainStatisticsAndProbabilityRitScoreSpring: {
    tag: 'MAP Growth Math K-12 Domain',
    tags: ['MAP Growth Math K-12 Domain', 'Math K-12 Domain'],
    humanName: 'MAP Growth Math K-12 Domain: Statistics and Probability RIT Score (Spring)',
  },
  mapGrowthMathK12DomainStatisticsAndProbabilityAchievementLevelSpring: {
    tag: 'MAP Growth Math K-12 Domain',
    tags: ['MAP Growth Math K-12 Domain', 'Math K-12 Domain'],
    humanName: 'MAP Growth Math K-12 Domain: Statistics and Probability Achievement Level (Spring)',
  },
};

const MAP_GROWTH_MATH_K12_HISTORICAL = {
  oneYearPriorMapGrowthMathK12ScoreDateSpring: {
    tag: 'MAP Growth Math K-12',
    tags: ['MAP Growth Math K-12', 'Math K-12', 'One Year Prior'],
    humanName: 'One Year Prior - MAP Growth Math K-12: Score Date (Spring)',
    calculation(doc) {
      return doc;
    },
  },
  oneYearPriorMapGrowthMathK12RitScoreSpring: {
    tag: 'MAP Growth Math K-12',
    tags: ['MAP Growth Math K-12', 'Math K-12', 'One Year Prior'],
    humanName: 'One Year Prior - MAP Growth Math K-12: RIT Score (Spring)',
    calculation(doc) {
      return doc;
    },
  },
  oneYearPriorMapGrowthMathK12PercentileSpring: {
    tag: 'MAP Growth Math K-12',
    tags: ['MAP Growth Math K-12', 'Math K-12', 'One Year Prior'],
    humanName: 'One Year Prior - MAP Growth Math K-12: Percentile (Spring)',
    calculation(doc) {
      return doc;
    },
  },
  oneYearPriorMapGrowthMathK12AchievementQuintileSpring: {
    tag: 'MAP Growth Math K-12',
    tags: ['MAP Growth Math K-12', 'Math K-12', 'One Year Prior'],
    humanName: 'One Year Prior - MAP Growth Math K-12: Achievement Quintile (Spring)',
    calculation(doc) {
      return doc;
    },
  },
  oneYearPriorMapGrowthMathK12ConditionalGrowthPercentileWinterToSpring: {
    tag: 'MAP Growth Math K-12',
    tags: ['MAP Growth Math K-12', 'Math K-12', 'One Year Prior'],
    humanName: 'One Year Prior: MAP Growth Math K-12: Conditional Growth Percentile (Winter to Spring)',
    calculation(doc) {
      return doc;
    },
  },
  oneYearPriorMapGrowthMathK12GrowthQuintileWinterToSpring: {
    tag: 'MAP Growth Math K-12',
    tags: ['MAP Growth Math K-12', 'Math K-12', 'One Year Prior'],
    humanName: 'One Year Prior: MAP Growth Math K-12: Growth Quintile (Winter to Spring)',
    calculation(doc) {
      return doc;
    },
  },
  oneYearPriorMapGrowthMathK12MetProjectedGrowthWinterToSpring: {
    tag: 'MAP Growth Math K-12',
    tags: ['MAP Growth Math K-12', 'Math K-12', 'One Year Prior'],
    humanName: 'One Year Prior: MAP Growth Math K-12: Met Projected Growth (Winter to Spring)',
    calculation(doc) {
      return doc;
    },
  },
  oneYearPriorMapGrowthMathK12ConditionalGrowthPercentileFallToSpring: {
    tag: 'MAP Growth Math K-12',
    tags: ['MAP Growth Math K-12', 'Math K-12', 'One Year Prior'],
    humanName: 'One Year Prior - MAP Growth Math K-12: Conditional Growth Percentile (Fall to Spring)',
    calculation(doc) {
      return doc;
    },
  },
  oneYearPriorMapGrowthMathK12GrowthQuintileFallToSpring: {
    tag: 'MAP Growth Math K-12',
    tags: ['MAP Growth Math K-12', 'Math K-12', 'One Year Prior'],
    humanName: 'One Year Prior - MAP Growth Math K-12: Growth Quintile (Fall to Spring)',
    calculation(doc) {
      return doc;
    },
  },
  oneYearPriorMapGrowthMathK12MetProjectedGrowthFallToSpring: {
    tag: 'MAP Growth Math K-12',
    tags: ['MAP Growth Math K-12', 'Math K-12', 'One Year Prior'],
    humanName: 'One Year Prior - MAP Growth Math K-12: Met Projected Growth (Fall to Spring)',
    calculation(doc) {
      return doc;
    },
  },
  oneYearPriorMapGrowthMathK12ScoreDateWinter: {
    tag: 'MAP Growth Math K-12',
    tags: ['MAP Growth Math K-12', 'Math K-12', 'One Year Prior'],
    humanName: 'One Year Prior - MAP Growth Math K-12: Score Date (Winter)',
    calculation(doc) {
      return doc;
    },
  },
  oneYearPriorMapGrowthMathK12RitScoreWinter: {
    tag: 'MAP Growth Math K-12',
    tags: ['MAP Growth Math K-12', 'Math K-12', 'One Year Prior'],
    humanName: 'One Year Prior - MAP Growth Math K-12: RIT Score (Winter)',
    calculation(doc) {
      return doc;
    },
  },
  oneYearPriorMapGrowthMathK12PercentileWinter: {
    tag: 'MAP Growth Math K-12',
    tags: ['MAP Growth Math K-12', 'Math K-12', 'One Year Prior'],
    humanName: 'One Year Prior - MAP Growth Math K-12: Percentile (Winter)',
    calculation(doc) {
      return doc;
    },
  },
  oneYearPriorMapGrowthMathK12AchievementQuintileWinter: {
    tag: 'MAP Growth Math K-12',
    tags: ['MAP Growth Math K-12', 'Math K-12', 'One Year Prior'],
    humanName: 'One Year Prior - MAP Growth Math K-12: Achievement Quintile (Winter)',
    calculation(doc) {
      return doc;
    },
  },
  oneYearPriorMapGrowthMathK12ConditionalGrowthPercentileFallToWinter: {
    tag: 'MAP Growth Math K-12',
    tags: ['MAP Growth Math K-12', 'Math K-12', 'One Year Prior'],
    humanName: 'One Year Prior - MAP Growth Math K-12: Conditional Growth Percentile (Fall to Winter)',
    calculation(doc) {
      return doc;
    },
  },
  oneYearPriorMapGrowthMathK12GrowthQuintileFallToWinter: {
    tag: 'MAP Growth Math K-12',
    tags: ['MAP Growth Math K-12', 'Math K-12', 'One Year Prior'],
    humanName: 'One Year Prior - MAP Growth Math K-12: Growth Quintile (Fall to Winter)',
    calculation(doc) {
      return doc;
    },
  },
  oneYearPriorMapGrowthMathK12MetProjectedGrowthFallToWinter: {
    tag: 'MAP Growth Math K-12',
    tags: ['MAP Growth Math K-12', 'Math K-12', 'One Year Prior'],
    humanName: 'One Year Prior - MAP Growth Math K-12: Met Projected Growth (Fall to Winter)',
    calculation(doc) {
      return doc;
    },
  },
  oneYearPriorMapGrowthMathK12ScoreDateFall: {
    tag: 'MAP Growth Math K-12',
    tags: ['MAP Growth Math K-12', 'Math K-12', 'One Year Prior'],
    humanName: 'One Year Prior - MAP Growth Math K-12: Score Date (Fall)',
    calculation(doc) {
      return doc;
    },
  },
  oneYearPriorMapGrowthMathK12RitScoreFall: {
    tag: 'MAP Growth Math K-12',
    tags: ['MAP Growth Math K-12', 'Math K-12', 'One Year Prior'],
    humanName: 'One Year Prior - MAP Growth Math K-12: RIT Score (Fall)',
    calculation(doc) {
      return doc;
    },
  },
  oneYearPriorMapGrowthMathK12PercentileFall: {
    tag: 'MAP Growth Math K-12',
    tags: ['MAP Growth Math K-12', 'Math K-12', 'One Year Prior'],
    humanName: 'One Year Prior - MAP Growth Math K-12: Percentile (Fall)',
    calculation(doc) {
      return doc;
    },
  },
  oneYearPriorMapGrowthMathK12AchievementQuintileFall: {
    tag: 'MAP Growth Math K-12',
    tags: ['MAP Growth Math K-12', 'Math K-12', 'One Year Prior'],
    humanName: 'One Year Prior - MAP Growth Math K-12: Achievement Quintile (Fall)',
    calculation(doc) {
      return doc;
    },
  },
};

const MAP_GROWTH_ALGEBRA_1 = {
  mapGrowthAlgebra1ScoreDateFall: {
    tag: 'MAP Growth Algebra 1',
    tags: ['MAP Growth Algebra 1', 'Algebra 1'],
    humanName: 'MAP Growth Algebra 1: Score Date (Fall)',
  },
  mapGrowthAlgebra1RitScoreFall: {
    tag: 'MAP Growth Algebra 1',
    tags: ['MAP Growth Algebra 1', 'Algebra 1'],
    humanName: 'MAP Growth Algebra 1: RIT Score (Fall)',
  },
  mapGrowthAlgebra1PercentileFall: {
    tag: 'MAP Growth Algebra 1',
    tags: ['MAP Growth Algebra 1', 'Algebra 1'],
    humanName: 'MAP Growth Algebra 1: Percentile (Fall)',
  },
  mapGrowthAlgebra1AchievementQuintileFall: {
    tag: 'MAP Growth Algebra 1',
    tags: ['MAP Growth Algebra 1', 'Algebra 1'],
    humanName: 'MAP Growth Algebra 1: Achievement Quintile (Fall)',
  },
  mapGrowthAlgebra1ScoreDateWinter: {
    tag: 'MAP Growth Algebra 1',
    tags: ['MAP Growth Algebra 1', 'Algebra 1'],
    humanName: 'MAP Growth Algebra 1: Score Date (Winter)',
  },
  mapGrowthAlgebra1RitScoreWinter: {
    tag: 'MAP Growth Algebra 1',
    tags: ['MAP Growth Algebra 1', 'Algebra 1'],
    humanName: 'MAP Growth Algebra 1: RIT Score (Winter)',
  },
  mapGrowthAlgebra1PercentileWinter: {
    tag: 'MAP Growth Algebra 1',
    tags: ['MAP Growth Algebra 1', 'Algebra 1'],
    humanName: 'MAP Growth Algebra 1: Percentile (Winter)',
  },
  mapGrowthAlgebra1AchievementQuintileWinter: {
    tag: 'MAP Growth Algebra 1',
    tags: ['MAP Growth Algebra 1', 'Algebra 1'],
    humanName: 'MAP Growth Algebra 1: Achievement Quintile (Winter)',
  },
  mapGrowthAlgebra1ScoreDateSpring: {
    tag: 'MAP Growth Algebra 1',
    tags: ['MAP Growth Algebra 1', 'Algebra 1'],
    humanName: 'MAP Growth Algebra 1: Score Date (Spring)',
  },
  mapGrowthAlgebra1RitScoreSpring: {
    tag: 'MAP Growth Algebra 1',
    tags: ['MAP Growth Algebra 1', 'Algebra 1'],
    humanName: 'MAP Growth Algebra 1: RIT Score (Spring)',
  },
  mapGrowthAlgebra1PercentileSpring: {
    tag: 'MAP Growth Algebra 1',
    tags: ['MAP Growth Algebra 1', 'Algebra 1'],
    humanName: 'MAP Growth Algebra 1: Percentile (Spring)',
  },
  mapGrowthAlgebra1AchievementQuintileSpring: {
    tag: 'MAP Growth Algebra 1',
    tags: ['MAP Growth Algebra 1', 'Algebra 1'],
    humanName: 'MAP Growth Algebra 1: Achievement Quintile (Spring)',
  },
};

const MAP_GROWTH_ALGEBRA_1_PROGRESS = {
  mapGrowthAlgebra1MetProjectedGrowthFallToSpringProgress: {
    tag: 'MAP Growth Algebra 1',
    tags: ['MAP Growth Algebra 1', 'Algebra 1'],
    humanName: 'MAP Growth Algebra 1: Met Projected Growth (Fall to Spring Progress)',
  },
  mapGrowthAlgebra1ConditionalGrowthPercentileFallToSpringProgress: {
    tag: 'MAP Growth Algebra 1',
    tags: ['MAP Growth Algebra 1', 'Algebra 1'],
    humanName: 'MAP Growth Algebra 1: Conditional Growth Percentile (Fall to Spring Progress)',
  },
  mapGrowthAlgebra1GrowthQuintileFallToSpringProgress: {
    tag: 'MAP Growth Algebra 1',
    tags: ['MAP Growth Algebra 1', 'Algebra 1'],
    humanName: 'MAP Growth Algebra 1: Growth Quintile (Fall to Spring Progress)',
  },
};

const MAP_GROWTH_ALGEBRA_1_DOMAIN = {
  mapGrowthAlgebra1DomainNumberQuantityRitScoreFall: {
    tag: 'MAP Growth Algebra 1 Domain',
    tags: ['MAP Growth Algebra 1 Domain', 'Algebra 1 Domain'],
    humanName: 'MAP Growth Algebra 1 Domain: Number & Quantity RIT Score (Fall)',
  },
  mapGrowthAlgebra1DomainNumberQuantityAchievementLevelFall: {
    tag: 'MAP Growth Algebra 1 Domain',
    tags: ['MAP Growth Algebra 1 Domain', 'Algebra 1 Domain'],
    humanName: 'MAP Growth Algebra 1 Domain: Number & Quantity Achievement Level (Fall)',
  },
  mapGrowthAlgebra1DomainNumberQuantityRitScoreWinter: {
    tag: 'MAP Growth Algebra 1 Domain',
    tags: ['MAP Growth Algebra 1 Domain', 'Algebra 1 Domain'],
    humanName: 'MAP Growth Algebra 1 Domain: Number & Quantity RIT Score (Winter)',
  },
  mapGrowthAlgebra1DomainNumberQuantityAchievementLevelWinter: {
    tag: 'MAP Growth Algebra 1 Domain',
    tags: ['MAP Growth Algebra 1 Domain', 'Algebra 1 Domain'],
    humanName: 'MAP Growth Algebra 1 Domain: Number & Quantity Achievement Level (Winter)',
  },
  mapGrowthAlgebra1DomainNumberQuantityRitScoreSpring: {
    tag: 'MAP Growth Algebra 1 Domain',
    tags: ['MAP Growth Algebra 1 Domain', 'Algebra 1 Domain'],
    humanName: 'MAP Growth Algebra 1 Domain: Number & Quantity RIT Score (Spring)',
  },
  mapGrowthAlgebra1DomainNumberQuantityAchievementLevelSpring: {
    tag: 'MAP Growth Algebra 1 Domain',
    tags: ['MAP Growth Algebra 1 Domain', 'Algebra 1 Domain'],
    humanName: 'MAP Growth Algebra 1 Domain: Number & Quantity Achievement Level (Spring)',
  },
  mapGrowthAlgebra1DomainAlgebraRitScoreFall: {
    tag: 'MAP Growth Algebra 1 Domain',
    tags: ['MAP Growth Algebra 1 Domain', 'Algebra 1 Domain'],
    humanName: 'MAP Growth Algebra 1 Domain: Algebra RIT Score (Fall)',
  },
  mapGrowthAlgebra1DomainAlgebraAchievementLevelFall: {
    tag: 'MAP Growth Algebra 1 Domain',
    tags: ['MAP Growth Algebra 1 Domain', 'Algebra 1 Domain'],
    humanName: 'MAP Growth Algebra 1 Domain: Algebra Achievement Level (Fall)',
  },
  mapGrowthAlgebra1DomainAlgebraRitScoreWinter: {
    tag: 'MAP Growth Algebra 1 Domain',
    tags: ['MAP Growth Algebra 1 Domain', 'Algebra 1 Domain'],
    humanName: 'MAP Growth Algebra 1 Domain: Algebra RIT Score (Winter)',
  },
  mapGrowthAlgebra1DomainAlgebraAchievementLevelWinter: {
    tag: 'MAP Growth Algebra 1 Domain',
    tags: ['MAP Growth Algebra 1 Domain', 'Algebra 1 Domain'],
    humanName: 'MAP Growth Algebra 1 Domain: Algebra Achievement Level (Winter)',
  },
  mapGrowthAlgebra1DomainAlgebraRitScoreSpring: {
    tag: 'MAP Growth Algebra 1 Domain',
    tags: ['MAP Growth Algebra 1 Domain', 'Algebra 1 Domain'],
    humanName: 'MAP Growth Algebra 1 Domain: Algebra RIT Score (Spring)',
  },
  mapGrowthAlgebra1DomainAlgebraAchievementLevelSpring: {
    tag: 'MAP Growth Algebra 1 Domain',
    tags: ['MAP Growth Algebra 1 Domain', 'Algebra 1 Domain'],
    humanName: 'MAP Growth Algebra 1 Domain: Algebra Achievement Level (Spring)',
  },
  mapGrowthAlgebra1DomainFunctionsRitScoreFall: {
    tag: 'MAP Growth Algebra 1 Domain',
    tags: ['MAP Growth Algebra 1 Domain', 'Algebra 1 Domain'],
    humanName: 'MAP Growth Algebra 1 Domain: Functions RIT Score (Fall)',
  },
  mapGrowthAlgebra1DomainFunctionsAchievementLevelFall: {
    tag: 'MAP Growth Algebra 1 Domain',
    tags: ['MAP Growth Algebra 1 Domain', 'Algebra 1 Domain'],
    humanName: 'MAP Growth Algebra 1 Domain: Functions Achievement Level (Fall)',
  },
  mapGrowthAlgebra1DomainFunctionsRitScoreWinter: {
    tag: 'MAP Growth Algebra 1 Domain',
    tags: ['MAP Growth Algebra 1 Domain', 'Algebra 1 Domain'],
    humanName: 'MAP Growth Algebra 1 Domain: Functions RIT Score (Winter)',
  },
  mapGrowthAlgebra1DomainFunctionsAchievementLevelWinter: {
    tag: 'MAP Growth Algebra 1 Domain',
    tags: ['MAP Growth Algebra 1 Domain', 'Algebra 1 Domain'],
    humanName: 'MAP Growth Algebra 1 Domain: Functions Achievement Level (Winter)',
  },
  mapGrowthAlgebra1DomainFunctionsRitScoreSpring: {
    tag: 'MAP Growth Algebra 1 Domain',
    tags: ['MAP Growth Algebra 1 Domain', 'Algebra 1 Domain'],
    humanName: 'MAP Growth Algebra 1 Domain: Functions RIT Score (Spring)',
  },
  mapGrowthAlgebra1DomainFunctionsAchievementLevelSpring: {
    tag: 'MAP Growth Algebra 1 Domain',
    tags: ['MAP Growth Algebra 1 Domain', 'Algebra 1 Domain'],
    humanName: 'MAP Growth Algebra 1 Domain: Functions Achievement Level (Spring)',
  },
};

const MAP_GROWTH_ALGEBRA_2 = {
  mapGrowthAlgebra2ScoreDateFall: {
    tag: 'MAP Growth Algebra 2',
    tags: ['MAP Growth Algebra 2', 'Algebra 2'],
    humanName: 'MAP Growth Algebra 2: Score Date (Fall)',
  },
  mapGrowthAlgebra2RitScoreFall: {
    tag: 'MAP Growth Algebra 2',
    tags: ['MAP Growth Algebra 2', 'Algebra 2'],
    humanName: 'MAP Growth Algebra 2: RIT Score (Fall)',
  },
  mapGrowthAlgebra2PercentileFall: {
    tag: 'MAP Growth Algebra 2',
    tags: ['MAP Growth Algebra 2', 'Algebra 2'],
    humanName: 'MAP Growth Algebra 2: Percentile (Fall)',
  },
  mapGrowthAlgebra2AchievementQuintileFall: {
    tag: 'MAP Growth Algebra 2',
    tags: ['MAP Growth Algebra 2', 'Algebra 2'],
    humanName: 'MAP Growth Algebra 2: Achievement Quintile (Fall)',
  },
  mapGrowthAlgebra2ScoreDateWinter: {
    tag: 'MAP Growth Algebra 2',
    tags: ['MAP Growth Algebra 2', 'Algebra 2'],
    humanName: 'MAP Growth Algebra 2: Score Date (Winter)',
  },
  mapGrowthAlgebra2RitScoreWinter: {
    tag: 'MAP Growth Algebra 2',
    tags: ['MAP Growth Algebra 2', 'Algebra 2'],
    humanName: 'MAP Growth Algebra 2: RIT Score (Winter)',
  },
  mapGrowthAlgebra2PercentileWinter: {
    tag: 'MAP Growth Algebra 2',
    tags: ['MAP Growth Algebra 2', 'Algebra 2'],
    humanName: 'MAP Growth Algebra 2: Percentile (Winter)',
  },
  mapGrowthAlgebra2AchievementQuintileWinter: {
    tag: 'MAP Growth Algebra 2',
    tags: ['MAP Growth Algebra 2', 'Algebra 2'],
    humanName: 'MAP Growth Algebra 2: Achievement Quintile (Winter)',
  },
  mapGrowthAlgebra2ScoreDateSpring: {
    tag: 'MAP Growth Algebra 2',
    tags: ['MAP Growth Algebra 2', 'Algebra 2'],
    humanName: 'MAP Growth Algebra 2: Score Date (Spring)',
  },
  mapGrowthAlgebra2RitScoreSpring: {
    tag: 'MAP Growth Algebra 2',
    tags: ['MAP Growth Algebra 2', 'Algebra 2'],
    humanName: 'MAP Growth Algebra 2: RIT Score (Spring)',
  },
  mapGrowthAlgebra2PercentileSpring: {
    tag: 'MAP Growth Algebra 2',
    tags: ['MAP Growth Algebra 2', 'Algebra 2'],
    humanName: 'MAP Growth Algebra 2: Percentile (Spring)',
  },
  mapGrowthAlgebra2AchievementQuintileSpring: {
    tag: 'MAP Growth Algebra 2',
    tags: ['MAP Growth Algebra 2', 'Algebra 2'],
    humanName: 'MAP Growth Algebra 2: Achievement Quintile (Spring)',
  },
};

const MAP_GROWTH_ALGEBRA_2_PROGRESS = {
  mapGrowthAlgebra2MetProjectedGrowthFallToSpringProgress: {
    tag: 'MAP Growth Algebra 2',
    tags: ['MAP Growth Algebra 2', 'Algebra 2'],
    humanName: 'MAP Growth Algebra 2: Met Projected Growth (Fall to Spring Progress)',
  },
  mapGrowthAlgebra2ConditionalGrowthPercentileFallToSpringProgress: {
    tag: 'MAP Growth Algebra 2',
    tags: ['MAP Growth Algebra 2', 'Algebra 2'],
    humanName: 'MAP Growth Algebra 2: Conditional Growth Percentile (Fall to Spring Progress)',
  },
  mapGrowthAlgebra2GrowthQuintileFallToSpringProgress: {
    tag: 'MAP Growth Algebra 2',
    tags: ['MAP Growth Algebra 2', 'Algebra 2'],
    humanName: 'MAP Growth Algebra 2: Growth Quintile (Fall to Spring Progress)',
  },
};

const MAP_GROWTH_ALGEBRA_2_DOMAIN = {
  mapGrowthAlgebra2DomainStatisticsProbabilityRitScoreFall: {
    tag: 'MAP Growth Algebra 2 Domain',
    tags: ['MAP Growth Algebra 2 Domain', 'Algebra 2 Domain'],
    humanName: 'MAP Growth Algebra 2 Domain: Statistics & Probability RIT Score (Fall)',
  },
  mapGrowthAlgebra2DomainStatisticsProbabilityAchievementLevelFall: {
    tag: 'MAP Growth Algebra 2 Domain',
    tags: ['MAP Growth Algebra 2 Domain', 'Algebra 2 Domain'],
    humanName: 'MAP Growth Algebra 2 Domain: Statistics & Probability Achievement Level (Fall)',
  },
  mapGrowthAlgebra2DomainStatisticsProbabilityRitScoreWinter: {
    tag: 'MAP Growth Algebra 2 Domain',
    tags: ['MAP Growth Algebra 2 Domain', 'Algebra 2 Domain'],
    humanName: 'MAP Growth Algebra 2 Domain: Statistics & Probability RIT Score (Winter)',
  },
  mapGrowthAlgebra2DomainStatisticsProbabilityAchievementLevelWinter: {
    tag: 'MAP Growth Algebra 2 Domain',
    tags: ['MAP Growth Algebra 2 Domain', 'Algebra 2 Domain'],
    humanName: 'MAP Growth Algebra 2 Domain: Statistics & Probability Achievement Level (Winter)',
  },
  mapGrowthAlgebra2DomainStatisticsProbabilityRitScoreSpring: {
    tag: 'MAP Growth Algebra 2 Domain',
    tags: ['MAP Growth Algebra 2 Domain', 'Algebra 2 Domain'],
    humanName: 'MAP Growth Algebra 2 Domain: Statistics & Probability RIT Score (Spring)',
  },
  mapGrowthAlgebra2DomainStatisticsProbabilityAchievementLevelSpring: {
    tag: 'MAP Growth Algebra 2 Domain',
    tags: ['MAP Growth Algebra 2 Domain', 'Algebra 2 Domain'],
    humanName: 'MAP Growth Algebra 2 Domain: Statistics & Probability Achievement Level (Spring)',
  },
  mapGrowthAlgebra2DomainNumberQuantityAndAlgebraRitScoreFall: {
    tag: 'MAP Growth Algebra 2 Domain',
    tags: ['MAP Growth Algebra 2 Domain', 'Algebra 2 Domain'],
    humanName: 'MAP Growth Algebra 2 Domain: Number & Quantity and Algebra RIT Score (Fall)',
  },
  mapGrowthAlgebra2DomainNumberQuantityAndAlgebraAchievementLevelFall: {
    tag: 'MAP Growth Algebra 2 Domain',
    tags: ['MAP Growth Algebra 2 Domain', 'Algebra 2 Domain'],
    humanName: 'MAP Growth Algebra 2 Domain: Number & Quantity and Algebra Achievement Level (Fall)',
  },
  mapGrowthAlgebra2DomainNumberQuantityAndAlgebraRitScoreWinter: {
    tag: 'MAP Growth Algebra 2 Domain',
    tags: ['MAP Growth Algebra 2 Domain', 'Algebra 2 Domain'],
    humanName: 'MAP Growth Algebra 2 Domain: Number & Quantity and Algebra RIT Score (Winter)',
  },
  mapGrowthAlgebra2DomainNumberQuantityAndAlgebraAchievementLevelWinter: {
    tag: 'MAP Growth Algebra 2 Domain',
    tags: ['MAP Growth Algebra 2 Domain', 'Algebra 2 Domain'],
    humanName: 'MAP Growth Algebra 2 Domain: Number & Quantity and Algebra Achievement Level (Winter)',
  },
  mapGrowthAlgebra2DomainNumberQuantityAndAlgebraRitScoreSpring: {
    tag: 'MAP Growth Algebra 2 Domain',
    tags: ['MAP Growth Algebra 2 Domain', 'Algebra 2 Domain'],
    humanName: 'MAP Growth Algebra 2 Domain: Number & Quantity and Algebra RIT Score (Spring)',
  },
  mapGrowthAlgebra2DomainNumberQuantityAndAlgebraAchievementLevelSpring: {
    tag: 'MAP Growth Algebra 2 Domain',
    tags: ['MAP Growth Algebra 2 Domain', 'Algebra 2 Domain'],
    humanName: 'MAP Growth Algebra 2 Domain: Number & Quantity and Algebra Achievement Level (Spring)',
  },
  mapGrowthAlgebra2DomainFunctionsRitScoreFall: {
    tag: 'MAP Growth Algebra 2 Domain',
    tags: ['MAP Growth Algebra 2 Domain', 'Algebra 2 Domain'],
    humanName: 'MAP Growth Algebra 2 Domain: Functions RIT Score (Fall)',
  },
  mapGrowthAlgebra2DomainFunctionsAchievementLevelFall: {
    tag: 'MAP Growth Algebra 2 Domain',
    tags: ['MAP Growth Algebra 2 Domain', 'Algebra 2 Domain'],
    humanName: 'MAP Growth Algebra 2 Domain: Functions Achievement Level (Fall)',
  },
  mapGrowthAlgebra2DomainFunctionsRitScoreWinter: {
    tag: 'MAP Growth Algebra 2 Domain',
    tags: ['MAP Growth Algebra 2 Domain', 'Algebra 2 Domain'],
    humanName: 'MAP Growth Algebra 2 Domain: Functions RIT Score (Winter)',
  },
  mapGrowthAlgebra2DomainFunctionsAchievementLevelWinter: {
    tag: 'MAP Growth Algebra 2 Domain',
    tags: ['MAP Growth Algebra 2 Domain', 'Algebra 2 Domain'],
    humanName: 'MAP Growth Algebra 2 Domain: Functions Achievement Level (Winter)',
  },
  mapGrowthAlgebra2DomainFunctionsRitScoreSpring: {
    tag: 'MAP Growth Algebra 2 Domain',
    tags: ['MAP Growth Algebra 2 Domain', 'Algebra 2 Domain'],
    humanName: 'MAP Growth Algebra 2 Domain: Functions RIT Score (Spring)',
  },
  mapGrowthAlgebra2DomainFunctionsAchievementLevelSpring: {
    tag: 'MAP Growth Algebra 2 Domain',
    tags: ['MAP Growth Algebra 2 Domain', 'Algebra 2 Domain'],
    humanName: 'MAP Growth Algebra 2 Domain: Functions Achievement Level (Spring)',
  },
};

const MAP_GROWTH_GEOMETRY = {
  mapGrowthGeometryScoreDateFall: {
    tag: 'MAP Growth Geometry',
    tags: ['MAP Growth Geometry', 'Geometry'],
    humanName: 'MAP Growth Geometry: Score Date (Fall)',
  },
  mapGrowthGeometryRitScoreFall: {
    tag: 'MAP Growth Geometry',
    tags: ['MAP Growth Geometry', 'Geometry'],
    humanName: 'MAP Growth Geometry: RIT Score (Fall)',
  },
  mapGrowthGeometryPercentileFall: {
    tag: 'MAP Growth Geometry',
    tags: ['MAP Growth Geometry', 'Geometry'],
    humanName: 'MAP Growth Geometry: Percentile (Fall)',
  },
  mapGrowthGeometryAchievementQuintileFall: {
    tag: 'MAP Growth Geometry',
    tags: ['MAP Growth Geometry', 'Geometry'],
    humanName: 'MAP Growth Geometry: Achievement Quintile (Fall)',
  },
  mapGrowthGeometryScoreDateWinter: {
    tag: 'MAP Growth Geometry',
    tags: ['MAP Growth Geometry', 'Geometry'],
    humanName: 'MAP Growth Geometry: Score Date (Winter)',
  },
  mapGrowthGeometryRitScoreWinter: {
    tag: 'MAP Growth Geometry',
    tags: ['MAP Growth Geometry', 'Geometry'],
    humanName: 'MAP Growth Geometry: RIT Score (Winter)',
  },
  mapGrowthGeometryPercentileWinter: {
    tag: 'MAP Growth Geometry',
    tags: ['MAP Growth Geometry', 'Geometry'],
    humanName: 'MAP Growth Geometry: Percentile (Winter)',
  },
  mapGrowthGeometryAchievementQuintileWinter: {
    tag: 'MAP Growth Geometry',
    tags: ['MAP Growth Geometry', 'Geometry'],
    humanName: 'MAP Growth Geometry: Achievement Quintile (Winter)',
  },
  mapGrowthGeometryScoreDateSpring: {
    tag: 'MAP Growth Geometry',
    tags: ['MAP Growth Geometry', 'Geometry'],
    humanName: 'MAP Growth Geometry: Score Date (Spring)',
  },
  mapGrowthGeometryRitScoreSpring: {
    tag: 'MAP Growth Geometry',
    tags: ['MAP Growth Geometry', 'Geometry'],
    humanName: 'MAP Growth Geometry: RIT Score (Spring)',
  },
  mapGrowthGeometryPercentileSpring: {
    tag: 'MAP Growth Geometry',
    tags: ['MAP Growth Geometry', 'Geometry'],
    humanName: 'MAP Growth Geometry: Percentile (Spring)',
  },
  mapGrowthGeometryAchievementQuintileSpring: {
    tag: 'MAP Growth Geometry',
    tags: ['MAP Growth Geometry', 'Geometry'],
    humanName: 'MAP Growth Geometry: Achievement Quintile (Spring)',
  },
};

const MAP_GROWTH_GEOMETRY_PROGRESS = {
  mapGrowthGeometryMetProjectedGrowthFallToSpringProgress: {
    tag: 'MAP Growth Geometry',
    tags: ['MAP Growth Geometry', 'Geometry'],
    humanName: 'MAP Growth Geometry: Met Projected Growth (Fall to Spring Progress)',
  },
  mapGrowthGeometryConditionalGrowthPercentileFallToSpringProgress: {
    tag: 'MAP Growth Geometry',
    tags: ['MAP Growth Geometry', 'Geometry'],
    humanName: 'MAP Growth Geometry: Conditional Growth Percentile (Fall to Spring Progress)',
  },
  mapGrowthGeometryGrowthQuintileFallToSpringProgress: {
    tag: 'MAP Growth Geometry',
    tags: ['MAP Growth Geometry', 'Geometry'],
    humanName: 'MAP Growth Geometry: Growth Quintile (Fall to Spring Progress)',
  },
};

const MAP_GROWTH_GEOMETRY_DOMAIN = {
  mapGrowthGeometryDomainCongruenceRitScoreFall: {
    tag: 'MAP Growth Geometry Domain',
    tags: ['MAP Growth Geometry Domain', 'Geometry Domain'],
    humanName: 'MAP Growth Geometry Domain: Congruence RIT Score (Fall)',
  },
  mapGrowthGeometryDomainCongruenceAchievementLevelFall: {
    tag: 'MAP Growth Geometry Domain',
    tags: ['MAP Growth Geometry Domain', 'Geometry Domain'],
    humanName: 'MAP Growth Geometry Domain: Congruence Achievement Level (Fall)',
  },
  mapGrowthGeometryDomainCongruenceRitScoreWinter: {
    tag: 'MAP Growth Geometry Domain',
    tags: ['MAP Growth Geometry Domain', 'Geometry Domain'],
    humanName: 'MAP Growth Geometry Domain: Congruence RIT Score (Winter)',
  },
  mapGrowthGeometryDomainCongruenceAchievementLevelWinter: {
    tag: 'MAP Growth Geometry Domain',
    tags: ['MAP Growth Geometry Domain', 'Geometry Domain'],
    humanName: 'MAP Growth Geometry Domain: Congruence Achievement Level (Winter)',
  },
  mapGrowthGeometryDomainCongruenceRitScoreSpring: {
    tag: 'MAP Growth Geometry Domain',
    tags: ['MAP Growth Geometry Domain', 'Geometry Domain'],
    humanName: 'MAP Growth Geometry Domain: Congruence RIT Score (Spring)',
  },
  mapGrowthGeometryDomainCongruenceAchievementLevelSpring: {
    tag: 'MAP Growth Geometry Domain',
    tags: ['MAP Growth Geometry Domain', 'Geometry Domain'],
    humanName: 'MAP Growth Geometry Domain: Congruence Achievement Level (Spring)',
  },
  mapGrowthGeometryDomainSimilarityRightTrianglesAndTrigonometryRitScoreFall: {
    tag: 'MAP Growth Geometry Domain',
    tags: ['MAP Growth Geometry Domain', 'Geometry Domain'],
    humanName: 'MAP Growth Geometry Domain: Similarity, Right Triangles, and Trigonometry RIT Score (Fall)',
  },
  mapGrowthGeometryDomainSimilarityRightTrianglesAndTrigonometryAchievementLevelFall: {
    tag: 'MAP Growth Geometry Domain',
    tags: ['MAP Growth Geometry Domain', 'Geometry Domain'],
    humanName: 'MAP Growth Geometry Domain: Similarity, Right Triangles, and Trigonometry Achievement Level (Fall)',
  },
  mapGrowthGeometryDomainSimilarityRightTrianglesAndTrigonometryRitScoreWinter: {
    tag: 'MAP Growth Geometry Domain',
    tags: ['MAP Growth Geometry Domain', 'Geometry Domain'],
    humanName: 'MAP Growth Geometry Domain: Similarity, Right Triangles, and Trigonometry RIT Score (Winter)',
  },
  mapGrowthGeometryDomainSimilarityRightTrianglesAndTrigonometryAchievementLevelWinter: {
    tag: 'MAP Growth Geometry Domain',
    tags: ['MAP Growth Geometry Domain', 'Geometry Domain'],
    humanName: 'MAP Growth Geometry Domain: Similarity, Right Triangles, and Trigonometry Achievement Level (Winter)',
  },
  mapGrowthGeometryDomainSimilarityRightTrianglesAndTrigonometryRitScoreSpring: {
    tag: 'MAP Growth Geometry Domain',
    tags: ['MAP Growth Geometry Domain', 'Geometry Domain'],
    humanName: 'MAP Growth Geometry Domain: Similarity, Right Triangles, and Trigonometry RIT Score (Spring)',
  },
  mapGrowthGeometryDomainSimilarityRightTrianglesAndTrigonometryAchievementLevelSpring: {
    tag: 'MAP Growth Geometry Domain',
    tags: ['MAP Growth Geometry Domain', 'Geometry Domain'],
    humanName: 'MAP Growth Geometry Domain: Similarity, Right Triangles, and Trigonometry Achievement Level (Spring)',
  },
  mapGrowthGeometryDomainCirclesAndGeometricPropertiesWithEquationsRitScoreFall: {
    tag: 'MAP Growth Geometry Domain',
    tags: ['MAP Growth Geometry Domain', 'Geometry Domain'],
    humanName: 'MAP Growth Geometry Domain: Circles and Geometric Properties with Equations RIT Score (Fall)',
  },
  mapGrowthGeometryDomainCirclesAndGeometricPropertiesWithEquationsAchievementLevelFall: {
    tag: 'MAP Growth Geometry Domain',
    tags: ['MAP Growth Geometry Domain', 'Geometry Domain'],
    humanName: 'MAP Growth Geometry Domain: Circles and Geometric Properties with Equations Achievement Level (Fall)',
  },
  mapGrowthGeometryDomainCirclesAndGeometricPropertiesWithEquationsRitScoreWinter: {
    tag: 'MAP Growth Geometry Domain',
    tags: ['MAP Growth Geometry Domain', 'Geometry Domain'],
    humanName: 'MAP Growth Geometry Domain: Circles and Geometric Properties with Equations RIT Score (Winter)',
  },
  mapGrowthGeometryDomainCirclesAndGeometricPropertiesWithEquationsAchievementLevelWinter: {
    tag: 'MAP Growth Geometry Domain',
    tags: ['MAP Growth Geometry Domain', 'Geometry Domain'],
    humanName: 'MAP Growth Geometry Domain: Circles and Geometric Properties with Equations Achievement Level (Winter)',
  },
  mapGrowthGeometryDomainCirclesAndGeometricPropertiesWithEquationsRitScoreSpring: {
    tag: 'MAP Growth Geometry Domain',
    tags: ['MAP Growth Geometry Domain', 'Geometry Domain'],
    humanName: 'MAP Growth Geometry Domain: Circles and Geometric Properties with Equations RIT Score (Spring)',
  },
  mapGrowthGeometryDomainCirclesAndGeometricPropertiesWithEquationsAchievementLevelSpring: {
    tag: 'MAP Growth Geometry Domain',
    tags: ['MAP Growth Geometry Domain', 'Geometry Domain'],
    humanName: 'MAP Growth Geometry Domain: Circles and Geometric Properties with Equations Achievement Level (Spring)',
  },
  mapGrowthGeometryDomainGeometricMeasurementAndModelingRitScoreFall: {
    tag: 'MAP Growth Geometry Domain',
    tags: ['MAP Growth Geometry Domain', 'Geometry Domain'],
    humanName: 'MAP Growth Geometry Domain: Geometric Measurement and Modeling RIT Score (Fall)',
  },
  mapGrowthGeometryDomainGeometricMeasurementAndModelingAchievementLevelFall: {
    tag: 'MAP Growth Geometry Domain',
    tags: ['MAP Growth Geometry Domain', 'Geometry Domain'],
    humanName: 'MAP Growth Geometry Domain: Geometric Measurement and Modeling Achievement Level (Fall)',
  },
  mapGrowthGeometryDomainGeometricMeasurementAndModelingRitScoreWinter: {
    tag: 'MAP Growth Geometry Domain',
    tags: ['MAP Growth Geometry Domain', 'Geometry Domain'],
    humanName: 'MAP Growth Geometry Domain: Geometric Measurement and Modeling RIT Score (Winter)',
  },
  mapGrowthGeometryDomainGeometricMeasurementAndModelingAchievementLevelWinter: {
    tag: 'MAP Growth Geometry Domain',
    tags: ['MAP Growth Geometry Domain', 'Geometry Domain'],
    humanName: 'MAP Growth Geometry Domain: Geometric Measurement and Modeling Achievement Level (Winter)',
  },
  mapGrowthGeometryDomainGeometricMeasurementAndModelingRitScoreSpring: {
    tag: 'MAP Growth Geometry Domain',
    tags: ['MAP Growth Geometry Domain', 'Geometry Domain'],
    humanName: 'MAP Growth Geometry Domain: Geometric Measurement and Modeling RIT Score (Spring)',
  },
  mapGrowthGeometryDomainGeometricMeasurementAndModelingAchievementLevelSpring: {
    tag: 'MAP Growth Geometry Domain',
    tags: ['MAP Growth Geometry Domain', 'Geometry Domain'],
    humanName: 'MAP Growth Geometry Domain: Geometric Measurement and Modeling Achievement Level (Spring)',
  },
};

const MAP_GROWTH_LEXILE = {
  mapGrowthLexileMaxScoreFall: {
    tag: 'MAP Growth Lexile',
    tags: ['MAP Growth Lexile', 'Lexile'],
    humanName: 'MAP Growth Lexile: Max Score (Fall)',
  },
  mapGrowthLexileMaxScoreDateFall: {
    tag: 'MAP Growth Lexile',
    tags: ['MAP Growth Lexile', 'Lexile'],
    humanName: 'MAP Growth Lexile: Max Score Date (Fall)',
  },
  mapGrowthLexileMaxScoreWinter: {
    tag: 'MAP Growth Lexile',
    tags: ['MAP Growth Lexile', 'Lexile'],
    humanName: 'MAP Growth Lexile: Max Score (Winter)',
  },
  mapGrowthLexileMaxScoreDateWinter: {
    tag: 'MAP Growth Lexile',
    tags: ['MAP Growth Lexile', 'Lexile'],
    humanName: 'MAP Growth Lexile: Max Score Date (Winter)',
  },
  mapGrowthLexileMaxScoreSpring: {
    tag: 'MAP Growth Lexile',
    tags: ['MAP Growth Lexile', 'Lexile'],
    humanName: 'MAP Growth Lexile: Max Score (Spring)',
  },
  mapGrowthLexileMaxScoreDateSpring: {
    tag: 'MAP Growth Lexile',
    tags: ['MAP Growth Lexile', 'Lexile'],
    humanName: 'MAP Growth Lexile: Max Score Date (Spring)',
  },
};

export const FLATTENED_STUDENT_MAP_FOR_MAP_GROWTH = {
  ...MAP_GROWTH_LANGUAGE_USE,
  ...MAP_GROWTH_LANGUAGE_USE_PROGRESS,
  ...MAP_GROWTH_READING,
  ...MAP_GROWTH_READING_PROGRESS,
  ...MAP_GROWTH_READING_DOMAIN,
  ...MAP_GROWTH_READING_HISTORICAL,
  ...MAP_GROWTH_MATH,
  ...MAP_GROWTH_MATH_K12_PROGRESS,
  ...MAP_GROWTH_MATH_K12_DOMAIN,
  ...MAP_GROWTH_MATH_K12_HISTORICAL,
  ...MAP_GROWTH_ALGEBRA_1,
  ...MAP_GROWTH_ALGEBRA_1_PROGRESS,
  ...MAP_GROWTH_ALGEBRA_1_DOMAIN,
  ...MAP_GROWTH_ALGEBRA_2,
  ...MAP_GROWTH_ALGEBRA_2_PROGRESS,
  ...MAP_GROWTH_ALGEBRA_2_DOMAIN,
  ...MAP_GROWTH_GEOMETRY,
  ...MAP_GROWTH_GEOMETRY_PROGRESS,
  ...MAP_GROWTH_GEOMETRY_DOMAIN,
  ...MAP_GROWTH_LEXILE,
};
