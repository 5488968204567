import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IListViewData } from '../../../../typings/interfaces/list-view.interface';
import { COLUMN_DATA_TYPE } from './../../../../constants/list-view/cell-type.constant';

@Injectable()
export class CoursePriorityGrouping {
  public getGrouping(filteredStudents$: Observable<any>, options): Observable<any> {
    return filteredStudents$.pipe(
      map(students => {
        const formattedList: IListViewData = this.getFormattedListShell(options);
        const args = { students, formattedList, ...options };
        const grouping = this.generateGrouping(args);
        return grouping;
      }),
    );
  }

  // args - students, columns, subject, formattedList
  private getFormattedListShell(args): IListViewData {
    const { columns, listType } = args;
    return {
      listType,
      columns: this.formatColumnsForList(columns),
      sections: [
        {
          name: 'High',
          count: 0,
          data: [],
          defaultDisplayCount: 10,
          tooltip:
            'Courses in which students will need to earn more than one credit per term in the subj in future term(s) in order to graduate on time if they fail',
        },
        {
          name: 'Medium',
          count: 0,
          data: [],
          tooltip: 'Courses in which students have not yet met graduation reqs in the aligned subj area',
          defaultDisplayCount: 10,
        },
        {
          name: 'Low',
          count: 0,
          data: [],
          tooltip:
            'Courses in which students have already met graduation reqs or that do not correspond to a grad req category',
          defaultDisplayCount: 10,
        },
      ],
    };
  }

  private formatColumnsForList(columns) {
    const formattedColumns = columns.reduce((formattedList, col) => {
      if (col.cellType !== COLUMN_DATA_TYPE.SECTION_HEADER) {
        formattedList[col.key] = {
          name: col.human,
          cellType: col.cellType,
          cellConfig: col.cellConfig,
          dataType: col.dataType,
          orderBy: col.orderBy,
          tooltip: col.tooltip,
        };
      }
      return formattedList;
    }, {});
    return formattedColumns;
  }

  private generateGrouping(args) {
    const { students, columns, formattedList, madLibModels, listType } = args;
    return students.reduce((formattedList, student) => {
      const {
        currProgram: { grades },
      } = student;

      grades.forEach((course: any) => {
        let { pf, subj, priority, code, mostRecent } = course;
        const isFailedCourse = pf === 'F' && mostRecent;
        const isSelectedSubject = madLibModels.Dimension2.key === subj;
        const studentFailingSelectedSubject: boolean = isFailedCourse && isSelectedSubject;

        const studentRow = columns.reduce((result, { key, getValueAtPath }) => {
          result[key] = getValueAtPath(student, madLibModels, listType, code);
          return result;
        }, {});

        if (studentFailingSelectedSubject) {
          switch (priority) {
            case 'high':
              formattedList.sections[0].data.push(studentRow);
              formattedList.sections[0].count++;
              break;
            case 'medium':
              formattedList.sections[1].data.push(studentRow);
              formattedList.sections[1].count++;
              break;
            case 'low':
              formattedList.sections[2].data.push(studentRow);
              formattedList.sections[2].count++;
              break;
            default:
              return;
          }
        }
      });
      return formattedList;
    }, formattedList);
  }
}
