/* tslint:disable */
import { Action } from '@ngrx/store';
export const LOAD_FOCUS = '[Portal] Load Focus';
export const RESET_FOCUS = '[Portal] Reset Focus';
export const LOAD_FOCUS_SUCCESS = '[Portal] Load Focus Success';
export const LOAD_FOCUS_FAIL = '[Portal] Load Focus Fail';

export interface IFocusPayload {
  schoolId: string;
  focusKey: string;
}

interface IFocusResponse {}

// LoadFocus hits a graphQL endpoint if you pass a FocusName you get the single Focus, no FocusName... get all the Focus
export class LoadFocus implements Action {
  readonly type = LOAD_FOCUS;
  constructor(public payload: IFocusPayload) {}
}

export class ResetFocus implements Action {
  readonly type = RESET_FOCUS;
}

// TODO: add interface Array<IFocus>
export class LoadFocusSuccess implements Action {
  readonly type = LOAD_FOCUS_SUCCESS;
  constructor(public payload: IFocusResponse) {}
}

export class LoadFocusFail implements Action {
  readonly type = LOAD_FOCUS_FAIL;
  constructor(public payload: any) {}
}

// action types
export type FocusAction = LoadFocus | LoadFocusSuccess | LoadFocusFail | ResetFocus;
