import { RegentsPlanningTransferWizardModule } from './transfer/regents-planning-transfer-wizard.module';
import { RegentsPlanningWizardContainer } from './regents-planning-wizard-container.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RegentsPlanningWizardRoutingModule } from './regents-planning-wizard-container-routing.module';
import { RegentsPlanningNonTransferWizardModule } from './non-transfer/regents-planning-non-transfer-wizard.module';

@NgModule({
  declarations: [RegentsPlanningWizardContainer],
  imports: [
    CommonModule,
    RegentsPlanningWizardRoutingModule,
    RegentsPlanningNonTransferWizardModule,
    RegentsPlanningTransferWizardModule,
  ],
  exports: [RegentsPlanningWizardContainer],
})
export class RegentsPlanningWizardModule { }
