<ng-template *ngIf="tooltipData.content.template; then customTemplate; else autoTemplate"></ng-template>
<ng-template #autoTemplate>
    <ng-template *ngIf="contentIsString; then stringTemplate; else arrayTemplate"></ng-template>
  
  <!-- If the content is a basic string, then we just print it -->
  <ng-template #stringTemplate>
    <span class="tooltip-content">{{ this.tooltipData.content }}</span>
  </ng-template>
  
  <!-- Otherwise we put all the data into a grid -->
  <ng-template #arrayTemplate>
    <!-- Header Row -->
    <ng-template ngFor let-header [ngForOf]="tooltipData.content.headers" let-i="index">
      <!-- The first header is styled differently -->
      <h1 *ngIf="i === 0; else h2">{{ header }}</h1>
      <ng-template #h2
        ><h2>{{ header }}</h2></ng-template
      >
    </ng-template>

    <!-- Data Rows -->
    <ng-template ngFor let-row [ngForOf]="tooltipData.content.rowData" let-i="index">
      <ng-template ngFor let-data [ngForOf]="row" let-j="index">
        <!-- The first column is styled differently -->
        <span *ngIf="j === 0; else value" class="tooltip-list-item-label">{{ data }}</span>
        <ng-template #value>
          <!-- Normal data -->
          <span *ngIf="!data?.dynamic" class="tooltip-list-item-value">{{ data }}</span>

          <!-- Dynamic component -->
          <span
            *ngIf="data?.dynamic"
            class="tooltip-list-item-value"
            dynamicComponent
            [dynamicComponentInput]="{ row: row, column: data }"
          ><span>{{ data }}</span></span>
        </ng-template>
      </ng-template>
    </ng-template>
  </ng-template>
</ng-template>

<ng-template #customTemplate>
  <div *ngIf="tooltipData.content?.template === 'groupedTables'">
    <nv-grouped-tables-tooltip [tooltipData]="tooltipData.content"></nv-grouped-tables-tooltip>
  </div>
</ng-template>
