import { UtilitiesService } from 'Src/ng2/shared/services/utilities/utilities.service';
import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IAttendanceLogsMeta } from './../../../school/lists/support-attendance-logs-list-v2/support-attendance-logs-kebab/support-attendance-logs-kebab.component';
import { ToggleBatchActions, UpdateSelectedStudentIds } from './../../../store/actions/batch-actions-actions';
import {
  getBatchActionsMode,
  getBatchActionsSelectedStudentIds,
} from './../../../store/selectors/batch-actions-selectors';
import { BaseModalComponent } from './../base-modal.component';
import { IBaseModalData } from './../modals.service';

export interface IAttendanceLogsComponentData extends IBaseModalData {
  supportId: string;
  schoolId: string;
}

@Component({
  selector: 'support-attendance-logs-modal',
  templateUrl: './support-attendance-logs-modal.component.html',
  styleUrls: ['./support-attendance-logs-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SupportAttendanceLogsModalComponent extends BaseModalComponent implements OnInit, OnDestroy {
  // Determines which view to show
  public mode: 'attendanceLogs' | 'takeAttendance' = 'attendanceLogs';
  public showConfirm: boolean = false;

  // Props needed for BaseModal
  public title: string = 'Support Attendance Logs';
  public confirmTitle: string = 'Unsaved changes';
  public isProfileMode: boolean = true;

  // Props for attendance-logs-list.component
  public schoolId: string;
  public supportId: string;
  public batchActionsMode$: Observable<any>;
  public batchActionsSelectedIds$: Observable<string[]> = null;

  // Additional prop for attendance-logs-form-list.component
  public eventDate: string;
  public formattedEventDate: string;

  constructor (
    private store: Store<any>,
    private utilitiesService: UtilitiesService,
    dialogRef: MatDialogRef<SupportAttendanceLogsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IAttendanceLogsComponentData,
  ) {
    super(dialogRef);
  }

  public ngOnInit (): void {
    this.batchActionsMode$ = this.store.select(getBatchActionsMode);
    this.batchActionsSelectedIds$ = this.store.select(getBatchActionsSelectedStudentIds);

    const { schoolId, supportId } = this.data;
    this.schoolId = schoolId;
    this.supportId = supportId;

    // TODO: In order for dialogRef on the base class to be accessible, had to
    // make the declaration protected there, and remove the private declaration here
    // Ideally we dont change the base, but have to do that for now until figuring out
    // some other way of accomplishing the request to toggleBatchActions
    this.dialogRef
      .afterClosed()
      .pipe(tap(() => this.toggleBatchActions(false)))
      .subscribe();
  }

  public ngOnDestroy (): void {
    super.ngOnDestroy();
  }

  private openConfirmModal () {
    this.showConfirm = true;
  }

  discardChanges (shouldDiscardChanges: boolean): void {
    shouldDiscardChanges ? this.goBackToSessionList(false) : (this.showConfirm = false);
  }

  openTakeAttendanceList (meta: IAttendanceLogsMeta): void {
    this.mode = 'takeAttendance';
    this.eventDate = meta.eventDate;
    this.formattedEventDate = this.utilitiesService.getDateWithoutWeekday(this.eventDate);
    this.toggleBatchActions(true);
  }

  goBackToSessionList (isFormDirty: boolean): void {
    if (isFormDirty) {
      this.openConfirmModal();
    } else {
      this.mode = 'attendanceLogs';
      this.toggleBatchActions(false);
    }
  }

  private toggleBatchActions (mode: boolean): void {
    if (!mode) this.store.dispatch(new UpdateSelectedStudentIds([]));
    this.store.dispatch(new ToggleBatchActions(mode));
  }
}
