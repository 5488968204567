import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { IRowData } from '../../../shared/models/list-models';
import { IDropdownOption } from '../../../../../projects/shared/nvps-libraries/design/interfaces/design-library.interface';
import { IClusterUserIdentifier } from 'Src/ng2/shared/modals/user-management/cluster-user/school-cluster-user/cluster-user-modals.config';

// Mimic the structure of what backend returns per column constant calc
export interface ISchoolClusterUserListMoreColmnData {
  isSelf: boolean;
  isAdmin: boolean; // in this context, it is only referring to school cluster admin
  canBeRemoved: boolean;
  shelterClusterAdmins: Array<{
    gafeEmail: string | null;
    doeEmail: string | null;
    dhsEmail: string | null;
    authenticationEmail: string;
  }>;
}

type TSchoolClusterUserListMoreButtonActionMode = 'DETAILS' | 'PORTFOLIO' | 'REMOVE';

export interface ISchoolClusterUserListMoreButtonEmitData {
  action: TSchoolClusterUserListMoreButtonActionMode;
  user: IClusterUserIdentifier;
  hybridClusterUserContext: {
    // only needs the other side's admins info, so it is shelterClusterAdmins
    [adminsType: string]: ISchoolClusterUserListMoreColmnData['shelterClusterAdmins'];
  };
}

@Component({
  selector: 'network-settings-more-button',
  templateUrl: './network-settings-more-button.component.html',
  styleUrls: ['./network-settings-more-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NetworkSettingsMoreButtonComponent implements OnInit {
  @Input() input: { row: IRowData[]; column: IRowData };
  @Output() output = new EventEmitter();
  options: IDropdownOption[];
  moreButtonDisabled: boolean = false;
  user: {
    id: string;
    name: string;
  };

  hybridClusterUserContext: {
    [adminsType: string]: ISchoolClusterUserListMoreColmnData['shelterClusterAdmins'];
  };

  public ngOnInit () {
    const { row } = this.input;
    const accessLevel = this._getColumnFromKey({ row, columnKey: 'ACCESS_LEVEL' }).data;
    const userColumn = this._getColumnFromKey({ row, columnKey: 'STUB' });
    const moreColumn = this._getColumnFromKey({ row, columnKey: 'MORE' });
    const moreColumnData: ISchoolClusterUserListMoreColmnData = JSON.parse(moreColumn.meta);
    const id = JSON.parse(userColumn.meta).data;
    const name = userColumn.data;
    this.user = { id, name };
    this.hybridClusterUserContext = {
      shelterClusterAdmins: moreColumnData.shelterClusterAdmins,
    };
    this.options = this._initDropdownOptions({ accessLevel, moreColumnData });
    this.moreButtonDisabled = this._disableMoreBtn({ accessLevel, moreColumnData });
  }

  // if the user is not "Network & School", disable 'Edit portfolio' option
  // if the user can not be removed, disable 'Remove user' option
  private _initDropdownOptions ({ accessLevel, moreColumnData }): IDropdownOption[] {
    const { canBeRemoved } = moreColumnData;
    const editDetailOption = { key: 'Edit details', human: 'Edit details' };
    const editPortfolioOption =
      accessLevel === 'Network and school level'
        ? { key: 'Edit portfolio', human: 'Edit portfolio' }
        : { key: 'Edit portfolio', human: 'Edit portfolio', disabled: true };
    const removeUserOption = { key: 'Remove user', human: 'Remove user' };
    const options = canBeRemoved
      ? [editDetailOption, editPortfolioOption, removeUserOption]
      : [editDetailOption, editPortfolioOption];
    return options;
  }

  // if the user to be accessed is 'Network admin'(school cluster admin), or self
  // disable three dot menu(i.e. more button)
  private _disableMoreBtn ({ accessLevel, moreColumnData }): boolean {
    const { isSelf } = moreColumnData;
    if (accessLevel === 'Network admin' || isSelf) {
      return true;
    }
    return false;
  }

  public onSelect (e): void {
    let action: TSchoolClusterUserListMoreButtonActionMode;
    switch (e) {
      case 'Edit details': {
        action = 'DETAILS';
        break;
      }
      case 'Edit portfolio': {
        action = 'PORTFOLIO';
        break;
      }
      case 'Remove user': {
        action = 'REMOVE';
        break;
      }
    }
    const emitData: ISchoolClusterUserListMoreButtonEmitData = {
      action,
      user: this.user,
      hybridClusterUserContext: this.hybridClusterUserContext,
    };
    this.output.emit(emitData);
  }

  private _getColumnFromKey ({ row, columnKey }: { row: IRowData[]; columnKey: string }): IRowData {
    return row.find(v => v.columnKey === columnKey);
  }
}
