import { IJobStatusSubject } from './../../services/background-job/background-job.service';
import { finalize, tap, takeUntil } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, ElementRef, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';

export enum SpinnerMode {
  /* eslint-disable-next-line */
  DETERMINATE = 'determinate',
  /* eslint-disable-next-line */
  INDETERMINATE = 'indeterminate'
}

@Component({
  selector: 'background-job-spinner-modal',
  templateUrl: './background-job-spinner-modal.component.html',
  styleUrls: ['./background-job-spinner-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BackgroundJobSpinnerModal implements OnInit {
  public mode: SpinnerMode.DETERMINATE | SpinnerMode.INDETERMINATE;
  public progress: number = 0;
  private currentBackgroundJobListener = null;

  private ngOnDestroyEmitter: Subject<boolean> = new Subject();

  constructor (
    public dialogRef: MatDialogRef<BackgroundJobSpinnerModal>,
    @Inject(MAT_DIALOG_DATA) public data: { backgroundJobSubject: Subject<IJobStatusSubject>, title: string },
  ) { }

  ngOnInit () {
    this.mode = this.data.backgroundJobSubject ? SpinnerMode.DETERMINATE : SpinnerMode.INDETERMINATE;
    if (this.data.backgroundJobSubject) this.listenToBackgroundJob();
  }

  ngOnDestroy () {
    this.ngOnDestroyEmitter.next(true);
    this.ngOnDestroyEmitter.complete();
  }

  setProgress (progress: number) {
    this.progress = Math.round(progress);
  }

  backgroundJobListener (backgroundJobSubject: Subject<{ data: { progress: number } }>) {
    backgroundJobSubject.pipe(
      tap(({ data }) => {
        // the initial emission will not contain progress
        if (data?.progress) this.setProgress(data.progress);
      }),
      takeUntil(this.ngOnDestroyEmitter),
      // complete animation before closing spinner - run on error/complete
      finalize(() => {
        this.setProgress(100);
        this.dialogRef.close();
      }),
    ).subscribe();
  }

  listenToBackgroundJob () {
    if (this.currentBackgroundJobListener) {
      this.currentBackgroundJobListener.stopListening = true;
    }
    if (this.data.backgroundJobSubject) {
      this.currentBackgroundJobListener = this.backgroundJobListener(this.data.backgroundJobSubject);
    }
  }
}
