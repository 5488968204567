import { ICodeDeck } from './../constants/code-deck-district.constant';

interface IStringTMap<T> {
  [key: string]: T;
}

// (DS) Use this spreadsheet to update codes
// https://docs.google.com/spreadsheets/d/1cwReHlTV8XX-Dad22ya112JYYQ8tAeqSSvr8IihIYUk/edit#gid=1197221804

export const CodeDeckCharter: IStringTMap<ICodeDeck> = {
  ANNUALIZED: {},
  SEMESTER: {
    art: [
      {
        courseCode: 'ANSA1',
        gradReq: 'art',
        creditValue: 0.5,
        subjectArea: 'art',
      },
      {
        courseCode: 'CGS11',
        gradReq: 'art',
        creditValue: 0.5,
        subjectArea: 'art',
      },
    ],
    elaCore: [
      {
        courseCode: 'EES81',
        gradReq: 'elaCore',
        creditValue: 1,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EES82',
        gradReq: 'elaCore',
        creditValue: 1,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EES83',
        gradReq: 'elaCore',
        creditValue: 1,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EES84',
        gradReq: 'elaCore',
        creditValue: 1,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EES85',
        gradReq: 'elaCore',
        creditValue: 1,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EES86',
        gradReq: 'elaCore',
        creditValue: 1,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EES87',
        gradReq: 'elaCore',
        creditValue: 1,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EES88',
        gradReq: 'elaCore',
        creditValue: 1,
        subjectArea: 'elaCore',
      },
    ],
    elaElect: [
      {
        courseCode: 'EQS11',
        gradReq: 'misc',
        creditValue: 0.5,
        subjectArea: 'elaElect',
      },
      {
        courseCode: 'EQS41',
        gradReq: 'misc',
        creditValue: 0.5,
        subjectArea: 'elaElect',
      },
      {
        courseCode: 'EQS42',
        gradReq: 'misc',
        creditValue: 0.5,
        subjectArea: 'elaElect',
      },
      {
        courseCode: 'EQS43',
        gradReq: 'misc',
        creditValue: 0.5,
        subjectArea: 'elaElect',
      },
      {
        courseCode: 'EQS44',
        gradReq: 'misc',
        creditValue: 0.5,
        subjectArea: 'elaElect',
      },
      {
        courseCode: 'EQS81',
        gradReq: 'misc',
        creditValue: 1,
        subjectArea: 'elaElect',
      },
    ],
    advisory: [
      {
        courseCode: 'GAS81',
        gradReq: 'misc',
        creditValue: 0.5,
        subjectArea: 'advisory',
      },
      {
        courseCode: 'GAS82',
        gradReq: 'misc',
        creditValue: 0.5,
        subjectArea: 'advisory',
      },
      {
        courseCode: 'GAS83',
        gradReq: 'misc',
        creditValue: 0.5,
        subjectArea: 'advisory',
      },
      {
        courseCode: 'GAS84',
        gradReq: 'misc',
        creditValue: 0.5,
        subjectArea: 'advisory',
      },
      {
        courseCode: 'GAS85',
        gradReq: 'misc',
        creditValue: 0.5,
        subjectArea: 'advisory',
      },
      {
        courseCode: 'GAS86',
        gradReq: 'misc',
        creditValue: 0.5,
        subjectArea: 'advisory',
      },
      {
        courseCode: 'GAS87',
        gradReq: 'misc',
        creditValue: 0.5,
        subjectArea: 'advisory',
      },
      {
        courseCode: 'GAS88',
        gradReq: 'misc',
        creditValue: 0.5,
        subjectArea: 'advisory',
      },
    ],
    ssElective: [
      {
        courseCode: 'HBS11',
        gradReq: 'misc',
        creditValue: 0.5,
        subjectArea: 'ssElective',
      },
    ],
    mathAlg: [
      {
        courseCode: 'MES21',
        gradReq: 'math',
        creditValue: 1,
        subjectArea: 'mathAlg',
      },
      {
        courseCode: 'MES22',
        gradReq: 'math',
        creditValue: 1,
        subjectArea: 'mathAlg',
      },
    ],
    mathGeom: [
      {
        courseCode: 'MGS21',
        gradReq: 'math',
        creditValue: 1,
        subjectArea: 'mathGeom',
      },
      {
        courseCode: 'MGS22',
        gradReq: 'math',
        creditValue: 1,
        subjectArea: 'mathGeom',
      },
    ],
    pe: [
      {
        courseCode: 'PDS81',
        gradReq: 'pe',
        creditValue: 0.5,
        subjectArea: 'pe',
      },
      {
        courseCode: 'PDS82',
        gradReq: 'pe',
        creditValue: 0.5,
        subjectArea: 'pe',
      },
      {
        courseCode: 'PDS83',
        gradReq: 'pe',
        creditValue: 0.5,
        subjectArea: 'pe',
      },
      {
        courseCode: 'PDS84',
        gradReq: 'pe',
        creditValue: 0.5,
        subjectArea: 'pe',
      },
      {
        courseCode: 'PDS85',
        gradReq: 'pe',
        creditValue: 0.5,
        subjectArea: 'pe',
      },
      {
        courseCode: 'PDS86',
        gradReq: 'pe',
        creditValue: 0.5,
        subjectArea: 'pe',
      },
      {
        courseCode: 'PDS87',
        gradReq: 'pe',
        creditValue: 0.5,
        subjectArea: 'pe',
      },
      {
        courseCode: 'PDS88',
        gradReq: 'pe',
        creditValue: 0.5,
        subjectArea: 'pe',
      },
    ],
    sciLiving: [
      {
        courseCode: 'SLS21',
        gradReq: 'sciLife',
        creditValue: 1,
        subjectArea: 'sciLiving',
      },
      {
        courseCode: 'SLS22',
        gradReq: 'sciLife',
        creditValue: 1,
        subjectArea: 'sciLiving',
      },
    ],
    sciPhysical: [
      {
        courseCode: 'SRS21',
        gradReq: 'sciPhysical',
        creditValue: 1,
        subjectArea: 'sciPhysical',
      },
      {
        courseCode: 'SRS22',
        gradReq: 'sciPhysical',
        creditValue: 1,
        subjectArea: 'sciPhysical',
      },
    ],
  },
  TRIMESTER: {
    art: [
      {
        courseCode: 'AGT61',
        gradReq: 'art',
        creditValue: 1,
        subjectArea: 'art',
      },
      {
        courseCode: 'AGT62',
        gradReq: 'art',
        creditValue: 1,
        subjectArea: 'art',
      },
      {
        courseCode: 'AGT63',
        gradReq: 'art',
        creditValue: 1,
        subjectArea: 'art',
      },
      {
        courseCode: 'AGT64',
        gradReq: 'art',
        creditValue: 1,
        subjectArea: 'art',
      },
      {
        courseCode: 'AGT65',
        gradReq: 'art',
        creditValue: 1,
        subjectArea: 'art',
      },
      {
        courseCode: 'AGT66',
        gradReq: 'art',
        creditValue: 1,
        subjectArea: 'art',
      },
      {
        courseCode: 'AHT21',
        gradReq: 'art',
        creditValue: 1,
        subjectArea: 'art',
      },
      {
        courseCode: 'AHT22',
        gradReq: 'art',
        creditValue: 1,
        subjectArea: 'art',
      },
      {
        courseCode: 'AHT31',
        gradReq: 'art',
        creditValue: 0.4,
        subjectArea: 'art',
      },
      {
        courseCode: 'AHT32',
        gradReq: 'art',
        creditValue: 0.4,
        subjectArea: 'art',
      },
      {
        courseCode: 'AHT33',
        gradReq: 'art',
        creditValue: 0.4,
        subjectArea: 'art',
      },
      {
        courseCode: 'AJT31',
        gradReq: 'art',
        creditValue: 1,
        subjectArea: 'art',
      },
      {
        courseCode: 'AJT32',
        gradReq: 'art',
        creditValue: 1,
        subjectArea: 'art',
      },
      {
        courseCode: 'AJT33',
        gradReq: 'art',
        creditValue: 1,
        subjectArea: 'art',
      },
      {
        courseCode: 'AKT11',
        gradReq: 'art',
        creditValue: 0.5,
        subjectArea: 'art',
      },
      {
        courseCode: 'AKT31',
        gradReq: 'art',
        creditValue: 0.4,
        subjectArea: 'art',
      },
      {
        courseCode: 'AKT32',
        gradReq: 'art',
        creditValue: 0.4,
        subjectArea: 'art',
      },
      {
        courseCode: 'AKT33',
        gradReq: 'art',
        creditValue: 0.4,
        subjectArea: 'art',
      },
      {
        courseCode: 'AKT61',
        gradReq: 'art',
        creditValue: 1,
        subjectArea: 'art',
      },
      {
        courseCode: 'AKT62',
        gradReq: 'art',
        creditValue: 1,
        subjectArea: 'art',
      },
      {
        courseCode: 'AKT63',
        gradReq: 'art',
        creditValue: 1,
        subjectArea: 'art',
      },
      {
        courseCode: 'AKT64',
        gradReq: 'art',
        creditValue: 1,
        subjectArea: 'art',
      },
      {
        courseCode: 'AKT65',
        gradReq: 'art',
        creditValue: 1,
        subjectArea: 'art',
      },
      {
        courseCode: 'AKT66',
        gradReq: 'art',
        creditValue: 1,
        subjectArea: 'art',
      },
      {
        courseCode: 'ALT31',
        gradReq: 'art',
        creditValue: 1,
        subjectArea: 'art',
      },
      {
        courseCode: 'ALT32',
        gradReq: 'art',
        creditValue: 1,
        subjectArea: 'art',
      },
      {
        courseCode: 'ALT33',
        gradReq: 'art',
        creditValue: 1,
        subjectArea: 'art',
      },
      {
        courseCode: 'AMT31',
        gradReq: 'art',
        creditValue: 0.4,
        subjectArea: 'art',
      },
      {
        courseCode: 'AMT32',
        gradReq: 'art',
        creditValue: 0.4,
        subjectArea: 'art',
      },
      {
        courseCode: 'AMT33',
        gradReq: 'art',
        creditValue: 0.4,
        subjectArea: 'art',
      },
      {
        courseCode: 'ANT11',
        gradReq: 'art',
        creditValue: 0.5,
        subjectArea: 'art',
      },
      {
        courseCode: 'ANT61',
        gradReq: 'art',
        creditValue: 0.4,
        subjectArea: 'art',
      },
      {
        courseCode: 'ANT62',
        gradReq: 'art',
        creditValue: 0.4,
        subjectArea: 'art',
      },
      {
        courseCode: 'ANT63',
        gradReq: 'art',
        creditValue: 0.4,
        subjectArea: 'art',
      },
      {
        courseCode: 'ANT64',
        gradReq: 'art',
        creditValue: 0.4,
        subjectArea: 'art',
      },
      {
        courseCode: 'ANT65',
        gradReq: 'art',
        creditValue: 0.4,
        subjectArea: 'art',
      },
      {
        courseCode: 'ANT66',
        gradReq: 'art',
        creditValue: 0.4,
        subjectArea: 'art',
      },
      {
        courseCode: 'AQT31',
        gradReq: 'art',
        creditValue: 0.4,
        subjectArea: 'art',
      },
      {
        courseCode: 'AQT32',
        gradReq: 'art',
        creditValue: 0.4,
        subjectArea: 'art',
      },
      {
        courseCode: 'AQT33',
        gradReq: 'art',
        creditValue: 0.4,
        subjectArea: 'art',
      },
      {
        courseCode: 'AUT11',
        gradReq: 'art',
        creditValue: 1,
        subjectArea: 'art',
      },
      {
        courseCode: 'AYT31',
        gradReq: 'art',
        creditValue: 0.4,
        subjectArea: 'art',
      },
      {
        courseCode: 'AYT32',
        gradReq: 'art',
        creditValue: 0.4,
        subjectArea: 'art',
      },
      {
        courseCode: 'AYT33',
        gradReq: 'art',
        creditValue: 0.4,
        subjectArea: 'art',
      },
      {
        courseCode: 'CGT11',
        gradReq: 'art',
        creditValue: 0.5,
        subjectArea: 'art',
      },
      {
        courseCode: 'CGT31',
        gradReq: 'art',
        creditValue: 0.4,
        subjectArea: 'art',
      },
      {
        courseCode: 'CGT32',
        gradReq: 'art',
        creditValue: 0.4,
        subjectArea: 'art',
      },
      {
        courseCode: 'CGT33',
        gradReq: 'art',
        creditValue: 0.4,
        subjectArea: 'art',
      },
      {
        courseCode: 'CMT31',
        gradReq: 'art',
        creditValue: 0.4,
        subjectArea: 'art',
      },
      {
        courseCode: 'CMT32',
        gradReq: 'art',
        creditValue: 0.4,
        subjectArea: 'art',
      },
      {
        courseCode: 'CMT33',
        gradReq: 'art',
        creditValue: 0.4,
        subjectArea: 'art',
      },
      {
        courseCode: 'CRT11',
        gradReq: 'art',
        creditValue: 1,
        subjectArea: 'art',
      },
      {
        courseCode: 'CRT31',
        gradReq: 'art',
        creditValue: 0.4,
        subjectArea: 'art',
      },
      {
        courseCode: 'CRT32',
        gradReq: 'art',
        creditValue: 0.4,
        subjectArea: 'art',
      },
      {
        courseCode: 'CRT33',
        gradReq: 'art',
        creditValue: 0.4,
        subjectArea: 'art',
      },
      {
        courseCode: 'DRT11',
        gradReq: 'art',
        creditValue: 1,
        subjectArea: 'art',
      },
      {
        courseCode: 'UAT31',
        gradReq: 'art',
        creditValue: 1,
        subjectArea: 'art',
      },
      {
        courseCode: 'UAT32',
        gradReq: 'art',
        creditValue: 1,
        subjectArea: 'art',
      },
      {
        courseCode: 'UAT61',
        gradReq: 'art',
        creditValue: 0.4,
        subjectArea: 'art',
      },
      {
        courseCode: 'UAT62',
        gradReq: 'art',
        creditValue: 0.4,
        subjectArea: 'art',
      },
      {
        courseCode: 'UAT63',
        gradReq: 'art',
        creditValue: 0.4,
        subjectArea: 'art',
      },
      {
        courseCode: 'UAT64',
        gradReq: 'art',
        creditValue: 0.4,
        subjectArea: 'art',
      },
      {
        courseCode: 'UAT65',
        gradReq: 'art',
        creditValue: 0.4,
        subjectArea: 'art',
      },
      {
        courseCode: 'UAT66',
        gradReq: 'art',
        creditValue: 0.4,
        subjectArea: 'art',
      },
      {
        courseCode: 'UDT31',
        gradReq: 'art',
        creditValue: 0.4,
        subjectArea: 'art',
      },
      {
        courseCode: 'UDT32',
        gradReq: 'art',
        creditValue: 0.4,
        subjectArea: 'art',
      },
      {
        courseCode: 'UDT33',
        gradReq: 'art',
        creditValue: 0.4,
        subjectArea: 'art',
      },
      {
        courseCode: 'UET61',
        gradReq: 'art',
        creditValue: 1,
        subjectArea: 'art',
      },
      {
        courseCode: 'UET62',
        gradReq: 'art',
        creditValue: 1,
        subjectArea: 'art',
      },
      {
        courseCode: 'UET63',
        gradReq: 'art',
        creditValue: 1,
        subjectArea: 'art',
      },
      {
        courseCode: 'UET64',
        gradReq: 'art',
        creditValue: 1,
        subjectArea: 'art',
      },
      {
        courseCode: 'UET65',
        gradReq: 'art',
        creditValue: 1,
        subjectArea: 'art',
      },
      {
        courseCode: 'UET66',
        gradReq: 'art',
        creditValue: 1,
        subjectArea: 'art',
      },
      {
        courseCode: 'UHT31',
        gradReq: 'art',
        creditValue: 1,
        subjectArea: 'art',
      },
      {
        courseCode: 'UHT32',
        gradReq: 'art',
        creditValue: 1,
        subjectArea: 'art',
      },
      {
        courseCode: 'UHT33',
        gradReq: 'art',
        creditValue: 1,
        subjectArea: 'art',
      },
      {
        courseCode: 'ULT11',
        gradReq: 'art',
        creditValue: 1,
        subjectArea: 'art',
      },
      {
        courseCode: 'UNT11',
        gradReq: 'art',
        creditValue: 0.5,
        subjectArea: 'art',
      },
      {
        courseCode: 'UPT31',
        gradReq: 'art',
        creditValue: 0.4,
        subjectArea: 'art',
      },
      {
        courseCode: 'UPT32',
        gradReq: 'art',
        creditValue: 0.4,
        subjectArea: 'art',
      },
      {
        courseCode: 'UPT33',
        gradReq: 'art',
        creditValue: 0.4,
        subjectArea: 'art',
      },
      {
        courseCode: 'UUT31',
        gradReq: 'art',
        creditValue: 0.4,
        subjectArea: 'art',
      },
      {
        courseCode: 'UUT32',
        gradReq: 'art',
        creditValue: 0.4,
        subjectArea: 'art',
      },
      {
        courseCode: 'UUT33',
        gradReq: 'art',
        creditValue: 0.4,
        subjectArea: 'art',
      },
      {
        courseCode: 'UVT11',
        gradReq: 'art',
        creditValue: 0.5,
        subjectArea: 'art',
      },
      {
        courseCode: 'UVT31',
        gradReq: 'art',
        creditValue: 1,
        subjectArea: 'art',
      },
      {
        courseCode: 'UVT32',
        gradReq: 'art',
        creditValue: 1,
        subjectArea: 'art',
      },
      {
        courseCode: 'UVT33',
        gradReq: 'art',
        creditValue: 1,
        subjectArea: 'art',
      },
    ],
    cte: [
      {
        courseCode: 'BGT31',
        gradReq: 'cte',
        creditValue: 1,
        subjectArea: 'cte',
      },
      {
        courseCode: 'BGT32',
        gradReq: 'cte',
        creditValue: 1,
        subjectArea: 'cte',
      },
      {
        courseCode: 'BGT33',
        gradReq: 'cte',
        creditValue: 1,
        subjectArea: 'cte',
      },
      {
        courseCode: 'BJT31',
        gradReq: 'cte',
        creditValue: 0.4,
        subjectArea: 'cte',
      },
      {
        courseCode: 'BJT32',
        gradReq: 'cte',
        creditValue: 0.4,
        subjectArea: 'cte',
      },
      {
        courseCode: 'BJT33',
        gradReq: 'cte',
        creditValue: 0.4,
        subjectArea: 'cte',
      },
      {
        courseCode: 'BNT31',
        gradReq: 'cte',
        creditValue: 1,
        subjectArea: 'cte',
      },
      {
        courseCode: 'BNT32',
        gradReq: 'cte',
        creditValue: 1,
        subjectArea: 'cte',
      },
      {
        courseCode: 'BNT33',
        gradReq: 'cte',
        creditValue: 1,
        subjectArea: 'cte',
      },
      {
        courseCode: 'BQT11',
        gradReq: 'cte',
        creditValue: 0.4,
        subjectArea: 'cte',
      },
      {
        courseCode: 'KCT11',
        gradReq: 'cte',
        creditValue: 0.5,
        subjectArea: 'cte',
      },
      {
        courseCode: 'KCT31',
        gradReq: 'cte',
        creditValue: 1,
        subjectArea: 'cte',
      },
      {
        courseCode: 'KCT32',
        gradReq: 'cte',
        creditValue: 1,
        subjectArea: 'cte',
      },
      {
        courseCode: 'KCT33',
        gradReq: 'cte',
        creditValue: 1,
        subjectArea: 'cte',
      },
      {
        courseCode: 'KET31',
        gradReq: 'cte',
        creditValue: 1,
        subjectArea: 'cte',
      },
      {
        courseCode: 'KET32',
        gradReq: 'cte',
        creditValue: 1,
        subjectArea: 'cte',
      },
      {
        courseCode: 'KET33',
        gradReq: 'cte',
        creditValue: 1,
        subjectArea: 'cte',
      },
      {
        courseCode: 'KLT11',
        gradReq: 'cte',
        creditValue: 1,
        subjectArea: 'cte',
      },
      {
        courseCode: 'RQT11',
        gradReq: 'cte',
        creditValue: 1,
        subjectArea: 'cte',
      },
      {
        courseCode: 'RVT61',
        gradReq: 'cte',
        creditValue: 1,
        subjectArea: 'cte',
      },
      {
        courseCode: 'RVT62',
        gradReq: 'cte',
        creditValue: 1,
        subjectArea: 'cte',
      },
      {
        courseCode: 'RVT63',
        gradReq: 'cte',
        creditValue: 1,
        subjectArea: 'cte',
      },
      {
        courseCode: 'RVT64',
        gradReq: 'cte',
        creditValue: 1,
        subjectArea: 'cte',
      },
      {
        courseCode: 'RVT65',
        gradReq: 'cte',
        creditValue: 1,
        subjectArea: 'cte',
      },
      {
        courseCode: 'RVT66',
        gradReq: 'cte',
        creditValue: 1,
        subjectArea: 'cte',
      },
      {
        courseCode: 'TAT94',
        gradReq: 'cte',
        creditValue: 1,
        subjectArea: 'cte',
      },
      {
        courseCode: 'TAT95',
        gradReq: 'cte',
        creditValue: 1,
        subjectArea: 'cte',
      },
      {
        courseCode: 'TAT97',
        gradReq: 'cte',
        creditValue: 1,
        subjectArea: 'cte',
      },
      {
        courseCode: 'TGT31',
        gradReq: 'cte',
        creditValue: 1,
        subjectArea: 'cte',
      },
      {
        courseCode: 'TGT32',
        gradReq: 'cte',
        creditValue: 1,
        subjectArea: 'cte',
      },
      {
        courseCode: 'TGT33',
        gradReq: 'cte',
        creditValue: 1,
        subjectArea: 'cte',
      },
      {
        courseCode: 'TQT91',
        gradReq: 'cte',
        creditValue: 1,
        subjectArea: 'cte',
      },
      {
        courseCode: 'TQT92',
        gradReq: 'cte',
        creditValue: 1,
        subjectArea: 'cte',
      },
      {
        courseCode: 'TST31',
        gradReq: 'cte',
        creditValue: 1,
        subjectArea: 'cte',
      },
      {
        courseCode: 'TST32',
        gradReq: 'cte',
        creditValue: 1,
        subjectArea: 'cte',
      },
      {
        courseCode: 'TST33',
        gradReq: 'cte',
        creditValue: 1,
        subjectArea: 'cte',
      },
      {
        courseCode: 'TTT11',
        gradReq: 'cte',
        creditValue: 0.5,
        subjectArea: 'cte',
      },
      {
        courseCode: 'TTT31',
        gradReq: 'cte',
        creditValue: 1,
        subjectArea: 'cte',
      },
      {
        courseCode: 'TTT32',
        gradReq: 'cte',
        creditValue: 1,
        subjectArea: 'cte',
      },
      {
        courseCode: 'TTT33',
        gradReq: 'cte',
        creditValue: 1,
        subjectArea: 'cte',
      },
      {
        courseCode: 'TZT91',
        gradReq: 'cte',
        creditValue: 1,
        subjectArea: 'cte',
      },
      {
        courseCode: 'TZT92',
        gradReq: 'cte',
        creditValue: 1,
        subjectArea: 'cte',
      },
      {
        courseCode: 'TZT97',
        gradReq: 'cte',
        creditValue: 1,
        subjectArea: 'cte',
      },
    ],
    elaCore: [
      {
        courseCode: 'EET31',
        gradReq: 'elaCore',
        creditValue: 1,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EET32',
        gradReq: 'elaCore',
        creditValue: 1,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EET33',
        gradReq: 'elaCore',
        creditValue: 1,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EET81',
        gradReq: 'elaCore',
        creditValue: 1,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EET82',
        gradReq: 'elaCore',
        creditValue: 1,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EET83',
        gradReq: 'elaCore',
        creditValue: 1,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EET84',
        gradReq: 'elaCore',
        creditValue: 1,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EET85',
        gradReq: 'elaCore',
        creditValue: 1,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EET86',
        gradReq: 'elaCore',
        creditValue: 1,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EET87',
        gradReq: 'elaCore',
        creditValue: 1,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EET88',
        gradReq: 'elaCore',
        creditValue: 1,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EETC1',
        gradReq: 'elaCore',
        creditValue: 1,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EETC2',
        gradReq: 'elaCore',
        creditValue: 1,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EETC3',
        gradReq: 'elaCore',
        creditValue: 1,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EETC4',
        gradReq: 'elaCore',
        creditValue: 1,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EETC5',
        gradReq: 'elaCore',
        creditValue: 1,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EETC6',
        gradReq: 'elaCore',
        creditValue: 1,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EETC7',
        gradReq: 'elaCore',
        creditValue: 1,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EETC8',
        gradReq: 'elaCore',
        creditValue: 1,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EETC9',
        gradReq: 'elaCore',
        creditValue: 1,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EETCA',
        gradReq: 'elaCore',
        creditValue: 1,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EETCB',
        gradReq: 'elaCore',
        creditValue: 1,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EETCC',
        gradReq: 'elaCore',
        creditValue: 1,
        subjectArea: 'elaCore',
      },
    ],
    elaElect: [
      {
        courseCode: 'ELT31',
        gradReq: 'misc',
        creditValue: 0.4,
        subjectArea: 'elaElect',
      },
      {
        courseCode: 'ELT32',
        gradReq: 'misc',
        creditValue: 0.4,
        subjectArea: 'elaElect',
      },
      {
        courseCode: 'ELT33',
        gradReq: 'misc',
        creditValue: 0.4,
        subjectArea: 'elaElect',
      },
      {
        courseCode: 'EQT11',
        gradReq: 'misc',
        creditValue: 0.4,
        subjectArea: 'elaElect',
      },
      {
        courseCode: 'EQT31',
        gradReq: 'misc',
        creditValue: 0.4,
        subjectArea: 'elaElect',
      },
      {
        courseCode: 'EQT32',
        gradReq: 'misc',
        creditValue: 0.4,
        subjectArea: 'elaElect',
      },
      {
        courseCode: 'EQT33',
        gradReq: 'misc',
        creditValue: 0.4,
        subjectArea: 'elaElect',
      },
      {
        courseCode: 'EQTC1',
        gradReq: 'misc',
        creditValue: 0.4,
        subjectArea: 'elaElect',
      },
      {
        courseCode: 'EQTC2',
        gradReq: 'misc',
        creditValue: 0.4,
        subjectArea: 'elaElect',
      },
      {
        courseCode: 'EQTC3',
        gradReq: 'misc',
        creditValue: 0.4,
        subjectArea: 'elaElect',
      },
      {
        courseCode: 'EQTC4',
        gradReq: 'misc',
        creditValue: 0.4,
        subjectArea: 'elaElect',
      },
      {
        courseCode: 'EQTC5',
        gradReq: 'misc',
        creditValue: 0.4,
        subjectArea: 'elaElect',
      },
      {
        courseCode: 'EQTC6',
        gradReq: 'misc',
        creditValue: 0.4,
        subjectArea: 'elaElect',
      },
      {
        courseCode: 'EQTC7',
        gradReq: 'misc',
        creditValue: 0.4,
        subjectArea: 'elaElect',
      },
      {
        courseCode: 'EQTC8',
        gradReq: 'misc',
        creditValue: 0.4,
        subjectArea: 'elaElect',
      },
      {
        courseCode: 'EQTC9',
        gradReq: 'misc',
        creditValue: 0.4,
        subjectArea: 'elaElect',
      },
      {
        courseCode: 'EQTCA',
        gradReq: 'misc',
        creditValue: 0.4,
        subjectArea: 'elaElect',
      },
      {
        courseCode: 'EQTCB',
        gradReq: 'misc',
        creditValue: 0.4,
        subjectArea: 'elaElect',
      },
      {
        courseCode: 'EQTCC',
        gradReq: 'misc',
        creditValue: 0.4,
        subjectArea: 'elaElect',
      },
      {
        courseCode: 'EWT11',
        gradReq: 'misc',
        creditValue: 0.4,
        subjectArea: 'elaElect',
      },
      {
        courseCode: 'EWT31',
        gradReq: 'misc',
        creditValue: 1,
        subjectArea: 'elaElect',
      },
      {
        courseCode: 'EWT32',
        gradReq: 'misc',
        creditValue: 1,
        subjectArea: 'elaElect',
      },
      {
        courseCode: 'EWT33',
        gradReq: 'misc',
        creditValue: 1,
        subjectArea: 'elaElect',
      },
    ],
    elaEsl: [
      {
        courseCode: 'EST31',
        gradReq: 'misc',
        creditValue: 1,
        subjectArea: 'elaEsl',
      },
      {
        courseCode: 'EST32',
        gradReq: 'misc',
        creditValue: 1,
        subjectArea: 'elaEsl',
      },
      {
        courseCode: 'EST33',
        gradReq: 'misc',
        creditValue: 1,
        subjectArea: 'elaEsl',
      },
      {
        courseCode: 'ESTC1',
        gradReq: 'misc',
        creditValue: 1,
        subjectArea: 'elaEsl',
      },
      {
        courseCode: 'ESTC2',
        gradReq: 'misc',
        creditValue: 1,
        subjectArea: 'elaEsl',
      },
      {
        courseCode: 'ESTC3',
        gradReq: 'misc',
        creditValue: 1,
        subjectArea: 'elaEsl',
      },
      {
        courseCode: 'ESTC4',
        gradReq: 'misc',
        creditValue: 1,
        subjectArea: 'elaEsl',
      },
      {
        courseCode: 'ESTC5',
        gradReq: 'misc',
        creditValue: 1,
        subjectArea: 'elaEsl',
      },
      {
        courseCode: 'ESTC6',
        gradReq: 'misc',
        creditValue: 1,
        subjectArea: 'elaEsl',
      },
      {
        courseCode: 'ESTC7',
        gradReq: 'misc',
        creditValue: 1,
        subjectArea: 'elaEsl',
      },
      {
        courseCode: 'ESTC8',
        gradReq: 'misc',
        creditValue: 1,
        subjectArea: 'elaEsl',
      },
      {
        courseCode: 'ESTC9',
        gradReq: 'misc',
        creditValue: 1,
        subjectArea: 'elaEsl',
      },
      {
        courseCode: 'ESTCA',
        gradReq: 'misc',
        creditValue: 1,
        subjectArea: 'elaEsl',
      },
      {
        courseCode: 'ESTCB',
        gradReq: 'misc',
        creditValue: 1,
        subjectArea: 'elaEsl',
      },
      {
        courseCode: 'ESTCC',
        gradReq: 'misc',
        creditValue: 1,
        subjectArea: 'elaEsl',
      },
    ],
    lote: [
      {
        courseCode: 'FFT31',
        gradReq: 'lote',
        creditValue: 1,
        subjectArea: 'lote',
      },
      {
        courseCode: 'FFT32',
        gradReq: 'lote',
        creditValue: 1,
        subjectArea: 'lote',
      },
      {
        courseCode: 'FFT33',
        gradReq: 'lote',
        creditValue: 1,
        subjectArea: 'lote',
      },
      {
        courseCode: 'FMT61',
        gradReq: 'lote',
        creditValue: 1,
        subjectArea: 'lote',
      },
      {
        courseCode: 'FMT62',
        gradReq: 'lote',
        creditValue: 1,
        subjectArea: 'lote',
      },
      {
        courseCode: 'FMT63',
        gradReq: 'lote',
        creditValue: 1,
        subjectArea: 'lote',
      },
      {
        courseCode: 'FMT64',
        gradReq: 'lote',
        creditValue: 1,
        subjectArea: 'lote',
      },
      {
        courseCode: 'FMT65',
        gradReq: 'lote',
        creditValue: 1,
        subjectArea: 'lote',
      },
      {
        courseCode: 'FMT66',
        gradReq: 'lote',
        creditValue: 1,
        subjectArea: 'lote',
      },
      {
        courseCode: 'FST31',
        gradReq: 'lote',
        creditValue: 1,
        subjectArea: 'lote',
      },
      {
        courseCode: 'FST32',
        gradReq: 'lote',
        creditValue: 1,
        subjectArea: 'lote',
      },
      {
        courseCode: 'FST33',
        gradReq: 'lote',
        creditValue: 1,
        subjectArea: 'lote',
      },
      {
        courseCode: 'FST61',
        gradReq: 'lote',
        creditValue: 1,
        subjectArea: 'lote',
      },
      {
        courseCode: 'FST62',
        gradReq: 'lote',
        creditValue: 1,
        subjectArea: 'lote',
      },
      {
        courseCode: 'FST63',
        gradReq: 'lote',
        creditValue: 1,
        subjectArea: 'lote',
      },
      {
        courseCode: 'FST64',
        gradReq: 'lote',
        creditValue: 1,
        subjectArea: 'lote',
      },
      {
        courseCode: 'FST65',
        gradReq: 'lote',
        creditValue: 1,
        subjectArea: 'lote',
      },
      {
        courseCode: 'FST66',
        gradReq: 'lote',
        creditValue: 1,
        subjectArea: 'lote',
      },
      {
        courseCode: 'FST91',
        gradReq: 'lote',
        creditValue: 0.4,
        subjectArea: 'lote',
      },
      {
        courseCode: 'FST92',
        gradReq: 'lote',
        creditValue: 0.4,
        subjectArea: 'lote',
      },
      {
        courseCode: 'FST93',
        gradReq: 'lote',
        creditValue: 0.4,
        subjectArea: 'lote',
      },
      {
        courseCode: 'FST94',
        gradReq: 'lote',
        creditValue: 0.4,
        subjectArea: 'lote',
      },
      {
        courseCode: 'FST95',
        gradReq: 'lote',
        creditValue: 0.4,
        subjectArea: 'lote',
      },
      {
        courseCode: 'FST96',
        gradReq: 'lote',
        creditValue: 0.4,
        subjectArea: 'lote',
      },
      {
        courseCode: 'FST97',
        gradReq: 'lote',
        creditValue: 0.4,
        subjectArea: 'lote',
      },
      {
        courseCode: 'FST98',
        gradReq: 'lote',
        creditValue: 0.4,
        subjectArea: 'lote',
      },
      {
        courseCode: 'FYT31',
        gradReq: 'lote',
        creditValue: 1,
        subjectArea: 'lote',
      },
      {
        courseCode: 'FYT32',
        gradReq: 'lote',
        creditValue: 1,
        subjectArea: 'lote',
      },
      {
        courseCode: 'FYT33',
        gradReq: 'lote',
        creditValue: 1,
        subjectArea: 'lote',
      },
    ],
    advisory: [
      {
        courseCode: 'GAT11',
        gradReq: 'misc',
        creditValue: 0.4,
        subjectArea: 'advisory',
      },
      {
        courseCode: 'GAT21',
        gradReq: 'misc',
        creditValue: 0.4,
        subjectArea: 'advisory',
      },
      {
        courseCode: 'GAT22',
        gradReq: 'misc',
        creditValue: 0.4,
        subjectArea: 'advisory',
      },
      {
        courseCode: 'GAT31',
        gradReq: 'misc',
        creditValue: 0.4,
        subjectArea: 'advisory',
      },
      {
        courseCode: 'GAT32',
        gradReq: 'misc',
        creditValue: 0.4,
        subjectArea: 'advisory',
      },
      {
        courseCode: 'GAT33',
        gradReq: 'misc',
        creditValue: 0.4,
        subjectArea: 'advisory',
      },
    ],
    guidance: [
      {
        courseCode: 'GLT61',
        gradReq: 'misc',
        creditValue: 0.4,
        subjectArea: 'guidance',
      },
      {
        courseCode: 'GLT62',
        gradReq: 'misc',
        creditValue: 0.4,
        subjectArea: 'guidance',
      },
      {
        courseCode: 'GLT63',
        gradReq: 'misc',
        creditValue: 0.4,
        subjectArea: 'guidance',
      },
      {
        courseCode: 'GLT64',
        gradReq: 'misc',
        creditValue: 0.4,
        subjectArea: 'guidance',
      },
      {
        courseCode: 'GLT65',
        gradReq: 'misc',
        creditValue: 0.4,
        subjectArea: 'guidance',
      },
      {
        courseCode: 'GLT66',
        gradReq: 'misc',
        creditValue: 0.4,
        subjectArea: 'guidance',
      },
      {
        courseCode: 'GMT31',
        gradReq: 'misc',
        creditValue: 0.4,
        subjectArea: 'guidance',
      },
      {
        courseCode: 'GMT32',
        gradReq: 'misc',
        creditValue: 0.4,
        subjectArea: 'guidance',
      },
      {
        courseCode: 'GMT33',
        gradReq: 'misc',
        creditValue: 0.4,
        subjectArea: 'guidance',
      },
      {
        courseCode: 'GST31',
        gradReq: 'misc',
        creditValue: 0.4,
        subjectArea: 'guidance',
      },
      {
        courseCode: 'GST32',
        gradReq: 'misc',
        creditValue: 0.4,
        subjectArea: 'guidance',
      },
      {
        courseCode: 'GST33',
        gradReq: 'misc',
        creditValue: 0.4,
        subjectArea: 'guidance',
      },
    ],
    ssElective: [
      {
        courseCode: 'HBT11',
        gradReq: 'misc',
        creditValue: 1,
        subjectArea: 'ssElective',
      },
      {
        courseCode: 'HLT11',
        gradReq: 'misc',
        creditValue: 1,
        subjectArea: 'ssElective',
      },
      {
        courseCode: 'HQT11',
        gradReq: 'misc',
        creditValue: 0.5,
        subjectArea: 'ssElective',
      },
      {
        courseCode: 'HQT31',
        gradReq: 'misc',
        creditValue: 1,
        subjectArea: 'ssElective',
      },
      {
        courseCode: 'HQT32',
        gradReq: 'misc',
        creditValue: 1,
        subjectArea: 'ssElective',
      },
      {
        courseCode: 'HQT33',
        gradReq: 'misc',
        creditValue: 1,
        subjectArea: 'ssElective',
      },
      {
        courseCode: 'HST11',
        gradReq: 'misc',
        creditValue: 1,
        subjectArea: 'ssElective',
      },
    ],
    ssEcon: [
      {
        courseCode: 'HET11',
        gradReq: 'ssEcon',
        creditValue: 1,
        subjectArea: 'ssEcon',
      },
    ],
    ssGlobal: [
      {
        courseCode: 'HGT31',
        gradReq: 'ssGlobal',
        creditValue: 1,
        subjectArea: 'ssGlobal',
      },
      {
        courseCode: 'HGT32',
        gradReq: 'ssGlobal',
        creditValue: 1,
        subjectArea: 'ssGlobal',
      },
      {
        courseCode: 'HGT33',
        gradReq: 'ssGlobal',
        creditValue: 1,
        subjectArea: 'ssGlobal',
      },
      {
        courseCode: 'HGT41',
        gradReq: 'ssGlobal',
        creditValue: 1,
        subjectArea: 'ssGlobal',
      },
      {
        courseCode: 'HGT42',
        gradReq: 'ssGlobal',
        creditValue: 1,
        subjectArea: 'ssGlobal',
      },
      {
        courseCode: 'HGT43',
        gradReq: 'ssGlobal',
        creditValue: 1,
        subjectArea: 'ssGlobal',
      },
      {
        courseCode: 'HGT44',
        gradReq: 'ssGlobal',
        creditValue: 1,
        subjectArea: 'ssGlobal',
      },
      {
        courseCode: 'HGT61',
        gradReq: 'ssGlobal',
        creditValue: 1,
        subjectArea: 'ssGlobal',
      },
      {
        courseCode: 'HGT62',
        gradReq: 'ssGlobal',
        creditValue: 1,
        subjectArea: 'ssGlobal',
      },
      {
        courseCode: 'HGT63',
        gradReq: 'ssGlobal',
        creditValue: 1,
        subjectArea: 'ssGlobal',
      },
      {
        courseCode: 'HGT64',
        gradReq: 'ssGlobal',
        creditValue: 1,
        subjectArea: 'ssGlobal',
      },
      {
        courseCode: 'HGT65',
        gradReq: 'ssGlobal',
        creditValue: 1,
        subjectArea: 'ssGlobal',
      },
      {
        courseCode: 'HGT66',
        gradReq: 'ssGlobal',
        creditValue: 1,
        subjectArea: 'ssGlobal',
      },
    ],
    ssUs: [
      {
        courseCode: 'HUT21',
        gradReq: 'ssUs',
        creditValue: 1,
        subjectArea: 'ssUs',
      },
      {
        courseCode: 'HUT22',
        gradReq: 'ssUs',
        creditValue: 1,
        subjectArea: 'ssUs',
      },
      {
        courseCode: 'HUT31',
        gradReq: 'ssUs',
        creditValue: 1,
        subjectArea: 'ssUs',
      },
      {
        courseCode: 'HUT32',
        gradReq: 'ssUs',
        creditValue: 1,
        subjectArea: 'ssUs',
      },
      {
        courseCode: 'HUT33',
        gradReq: 'ssUs',
        creditValue: 1,
        subjectArea: 'ssUs',
      },
    ],
    ssGovt: [
      {
        courseCode: 'HVT11',
        gradReq: 'ssGovt',
        creditValue: 1,
        subjectArea: 'ssGovt',
      },
      {
        courseCode: 'HVT31',
        gradReq: 'ssGovt',
        creditValue: 1,
        subjectArea: 'ssGovt',
      },
      {
        courseCode: 'HVT32',
        gradReq: 'ssGovt',
        creditValue: 1,
        subjectArea: 'ssGovt',
      },
      {
        courseCode: 'HVT33',
        gradReq: 'ssGovt',
        creditValue: 1,
        subjectArea: 'ssGovt',
      },
    ],
    mathCalc: [
      {
        courseCode: 'MCT31',
        gradReq: 'math',
        creditValue: 1,
        subjectArea: 'mathCalc',
      },
      {
        courseCode: 'MCT32',
        gradReq: 'math',
        creditValue: 1,
        subjectArea: 'mathCalc',
      },
      {
        courseCode: 'MCT33',
        gradReq: 'math',
        creditValue: 1,
        subjectArea: 'mathCalc',
      },
    ],
    mathAlg: [
      {
        courseCode: 'MET11',
        gradReq: 'math',
        creditValue: 1,
        subjectArea: 'mathAlg',
      },
      {
        courseCode: 'MET31',
        gradReq: 'math',
        creditValue: 1,
        subjectArea: 'mathAlg',
      },
      {
        courseCode: 'MET32',
        gradReq: 'math',
        creditValue: 1,
        subjectArea: 'mathAlg',
      },
      {
        courseCode: 'MET33',
        gradReq: 'math',
        creditValue: 1,
        subjectArea: 'mathAlg',
      },
      {
        courseCode: 'MET41',
        gradReq: 'math',
        creditValue: 1,
        subjectArea: 'mathAlg',
      },
      {
        courseCode: 'MET42',
        gradReq: 'math',
        creditValue: 1,
        subjectArea: 'mathAlg',
      },
      {
        courseCode: 'MET43',
        gradReq: 'math',
        creditValue: 1,
        subjectArea: 'mathAlg',
      },
    ],
    mathGeom: [
      {
        courseCode: 'MGT11',
        gradReq: 'math',
        creditValue: 1,
        subjectArea: 'mathGeom',
      },
      {
        courseCode: 'MGT21',
        gradReq: 'math',
        creditValue: 1,
        subjectArea: 'mathGeom',
      },
      {
        courseCode: 'MGT22',
        gradReq: 'math',
        creditValue: 1,
        subjectArea: 'mathGeom',
      },
      {
        courseCode: 'MGT31',
        gradReq: 'math',
        creditValue: 1,
        subjectArea: 'mathGeom',
      },
      {
        courseCode: 'MGT32',
        gradReq: 'math',
        creditValue: 1,
        subjectArea: 'mathGeom',
      },
      {
        courseCode: 'MGT33',
        gradReq: 'math',
        creditValue: 1,
        subjectArea: 'mathGeom',
      },
    ],
    mathPreCalc: [
      {
        courseCode: 'MPT31',
        gradReq: 'math',
        creditValue: 1,
        subjectArea: 'mathPreCalc',
      },
      {
        courseCode: 'MPT32',
        gradReq: 'math',
        creditValue: 1,
        subjectArea: 'mathPreCalc',
      },
      {
        courseCode: 'MPT33',
        gradReq: 'math',
        creditValue: 1,
        subjectArea: 'mathPreCalc',
      },
    ],
    mathTrig: [
      {
        courseCode: 'MRT11',
        gradReq: 'math',
        creditValue: 1,
        subjectArea: 'mathTrig',
      },
      {
        courseCode: 'MRT31',
        gradReq: 'math',
        creditValue: 1,
        subjectArea: 'mathTrig',
      },
      {
        courseCode: 'MRT32',
        gradReq: 'math',
        creditValue: 1,
        subjectArea: 'mathTrig',
      },
      {
        courseCode: 'MRT33',
        gradReq: 'math',
        creditValue: 1,
        subjectArea: 'mathTrig',
      },
    ],
    mathStats: [
      {
        courseCode: 'MST11',
        gradReq: 'math',
        creditValue: 1,
        subjectArea: 'mathStats',
      },
      {
        courseCode: 'MST31',
        gradReq: 'math',
        creditValue: 1,
        subjectArea: 'mathStats',
      },
      {
        courseCode: 'MST32',
        gradReq: 'math',
        creditValue: 1,
        subjectArea: 'mathStats',
      },
      {
        courseCode: 'MST33',
        gradReq: 'math',
        creditValue: 1,
        subjectArea: 'mathStats',
      },
    ],
    pe: [
      {
        courseCode: 'PAT31',
        gradReq: 'pe',
        creditValue: 1,
        subjectArea: 'pe',
      },
      {
        courseCode: 'PAT32',
        gradReq: 'pe',
        creditValue: 1,
        subjectArea: 'pe',
      },
      {
        courseCode: 'PAT33',
        gradReq: 'pe',
        creditValue: 1,
        subjectArea: 'pe',
      },
      {
        courseCode: 'PDT11',
        gradReq: 'pe',
        creditValue: 0.5,
        subjectArea: 'pe',
      },
      {
        courseCode: 'PFT11',
        gradReq: 'pe',
        creditValue: 0.5,
        subjectArea: 'pe',
      },
      {
        courseCode: 'PFT22',
        gradReq: 'pe',
        creditValue: 0.5,
        subjectArea: 'pe',
      },
      {
        courseCode: 'PFT31',
        gradReq: 'pe',
        creditValue: 0.4,
        subjectArea: 'pe',
      },
      {
        courseCode: 'PFT32',
        gradReq: 'pe',
        creditValue: 0.4,
        subjectArea: 'pe',
      },
      {
        courseCode: 'PFT33',
        gradReq: 'pe',
        creditValue: 0.4,
        subjectArea: 'pe',
      },
      {
        courseCode: 'PGT31',
        gradReq: 'pe',
        creditValue: 1,
        subjectArea: 'pe',
      },
      {
        courseCode: 'PGT32',
        gradReq: 'pe',
        creditValue: 1,
        subjectArea: 'pe',
      },
      {
        courseCode: 'PGT33',
        gradReq: 'pe',
        creditValue: 1,
        subjectArea: 'pe',
      },
      {
        courseCode: 'PPT11',
        gradReq: 'pe',
        creditValue: 0.5,
        subjectArea: 'pe',
      },
      {
        courseCode: 'PPT31',
        gradReq: 'pe',
        creditValue: 0.4,
        subjectArea: 'pe',
      },
      {
        courseCode: 'PPT32',
        gradReq: 'pe',
        creditValue: 0.4,
        subjectArea: 'pe',
      },
      {
        courseCode: 'PPT33',
        gradReq: 'pe',
        creditValue: 0.4,
        subjectArea: 'pe',
      },
      {
        courseCode: 'PPTC1',
        gradReq: 'pe',
        creditValue: 0.4,
        subjectArea: 'pe',
      },
      {
        courseCode: 'PPTC2',
        gradReq: 'pe',
        creditValue: 0.4,
        subjectArea: 'pe',
      },
      {
        courseCode: 'PPTC3',
        gradReq: 'pe',
        creditValue: 0.4,
        subjectArea: 'pe',
      },
      {
        courseCode: 'PPTC4',
        gradReq: 'pe',
        creditValue: 0.4,
        subjectArea: 'pe',
      },
      {
        courseCode: 'PPTC5',
        gradReq: 'pe',
        creditValue: 0.4,
        subjectArea: 'pe',
      },
      {
        courseCode: 'PPTC6',
        gradReq: 'pe',
        creditValue: 0.4,
        subjectArea: 'pe',
      },
      {
        courseCode: 'PPTC7',
        gradReq: 'pe',
        creditValue: 0.4,
        subjectArea: 'pe',
      },
      {
        courseCode: 'PPTC8',
        gradReq: 'pe',
        creditValue: 0.4,
        subjectArea: 'pe',
      },
      {
        courseCode: 'PTT31',
        gradReq: 'pe',
        creditValue: 0.4,
        subjectArea: 'pe',
      },
      {
        courseCode: 'PTT32',
        gradReq: 'pe',
        creditValue: 0.4,
        subjectArea: 'pe',
      },
      {
        courseCode: 'PTT33',
        gradReq: 'pe',
        creditValue: 0.4,
        subjectArea: 'pe',
      },
      {
        courseCode: 'PYT31',
        gradReq: 'pe',
        creditValue: 0.4,
        subjectArea: 'pe',
      },
      {
        courseCode: 'PYT32',
        gradReq: 'pe',
        creditValue: 0.4,
        subjectArea: 'pe',
      },
      {
        courseCode: 'PYT33',
        gradReq: 'pe',
        creditValue: 0.4,
        subjectArea: 'pe',
      },
    ],
    health: [
      {
        courseCode: 'PHT11',
        gradReq: 'health',
        creditValue: 1,
        subjectArea: 'health',
      },
      {
        courseCode: 'PHT21',
        gradReq: 'health',
        creditValue: 0.5,
        subjectArea: 'health',
      },
      {
        courseCode: 'PHT22',
        gradReq: 'health',
        creditValue: 0.5,
        subjectArea: 'health',
      },
      {
        courseCode: 'PHT31',
        gradReq: 'health',
        creditValue: 0.4,
        subjectArea: 'health',
      },
      {
        courseCode: 'PHT32',
        gradReq: 'health',
        creditValue: 0.4,
        subjectArea: 'health',
      },
      {
        courseCode: 'PHT33',
        gradReq: 'health',
        creditValue: 0.4,
        subjectArea: 'health',
      },
    ],
    sciBio: [
      {
        courseCode: 'SBT11',
        gradReq: 'sciLife',
        creditValue: 1,
        subjectArea: 'sciBio',
      },
      {
        courseCode: 'SBT31',
        gradReq: 'sciLife',
        creditValue: 1,
        subjectArea: 'sciBio',
      },
      {
        courseCode: 'SBT32',
        gradReq: 'sciLife',
        creditValue: 1,
        subjectArea: 'sciBio',
      },
      {
        courseCode: 'SBT33',
        gradReq: 'sciLife',
        creditValue: 1,
        subjectArea: 'sciBio',
      },
    ],
    sciChem: [
      {
        courseCode: 'SCT11',
        gradReq: 'sciPhysical',
        creditValue: 1,
        subjectArea: 'sciChem',
      },
      {
        courseCode: 'SCT31',
        gradReq: 'sciPhysical',
        creditValue: 1,
        subjectArea: 'sciChem',
      },
      {
        courseCode: 'SCT32',
        gradReq: 'sciPhysical',
        creditValue: 1,
        subjectArea: 'sciChem',
      },
      {
        courseCode: 'SCT33',
        gradReq: 'sciPhysical',
        creditValue: 1,
        subjectArea: 'sciChem',
      },
    ],
    sciPhysical: [
      {
        courseCode: 'SDT11',
        gradReq: 'sciPhysical',
        creditValue: 1,
        subjectArea: 'sciPhysical',
      },
      {
        courseCode: 'SDT31',
        gradReq: 'sciPhysical',
        creditValue: 1,
        subjectArea: 'sciPhysical',
      },
      {
        courseCode: 'SDT32',
        gradReq: 'sciPhysical',
        creditValue: 1,
        subjectArea: 'sciPhysical',
      },
      {
        courseCode: 'SDT33',
        gradReq: 'sciPhysical',
        creditValue: 1,
        subjectArea: 'sciPhysical',
      },
    ],
    sciEarth: [
      {
        courseCode: 'SET11',
        gradReq: 'sciPhysical',
        creditValue: 1,
        subjectArea: 'sciEarth',
      },
      {
        courseCode: 'SET21',
        gradReq: 'sciPhysical',
        creditValue: 1,
        subjectArea: 'sciEarth',
      },
      {
        courseCode: 'SET22',
        gradReq: 'sciPhysical',
        creditValue: 1,
        subjectArea: 'sciEarth',
      },
      {
        courseCode: 'SET31',
        gradReq: 'sciPhysical',
        creditValue: 0.67,
        subjectArea: 'sciEarth',
      },
      {
        courseCode: 'SET32',
        gradReq: 'sciPhysical',
        creditValue: 0.67,
        subjectArea: 'sciEarth',
      },
      {
        courseCode: 'SET33',
        gradReq: 'sciPhysical',
        creditValue: 1,
        subjectArea: 'sciEarth',
      },
      {
        courseCode: 'SET41',
        gradReq: 'sciPhysical',
        creditValue: 1,
        subjectArea: 'sciEarth',
      },
      {
        courseCode: 'SET42',
        gradReq: 'sciPhysical',
        creditValue: 1,
        subjectArea: 'sciEarth',
      },
      {
        courseCode: 'SET43',
        gradReq: 'sciPhysical',
        creditValue: 1,
        subjectArea: 'sciEarth',
      },
    ],
    sciForensics: [
      {
        courseCode: 'SFT31',
        gradReq: 'sciOther',
        creditValue: 1,
        subjectArea: 'sciForensics',
      },
      {
        courseCode: 'SFT32',
        gradReq: 'sciOther',
        creditValue: 1,
        subjectArea: 'sciForensics',
      },
      {
        courseCode: 'SFT33',
        gradReq: 'sciOther',
        creditValue: 1,
        subjectArea: 'sciForensics',
      },
    ],
    sciLiving: [
      {
        courseCode: 'SLT11',
        gradReq: 'sciLife',
        creditValue: 1,
        subjectArea: 'sciLiving',
      },
      {
        courseCode: 'SLT21',
        gradReq: 'sciLife',
        creditValue: 1,
        subjectArea: 'sciLiving',
      },
      {
        courseCode: 'SLT22',
        gradReq: 'sciLife',
        creditValue: 1,
        subjectArea: 'sciLiving',
      },
      {
        courseCode: 'SLT31',
        gradReq: 'sciLife',
        creditValue: 0.67,
        subjectArea: 'sciLiving',
      },
      {
        courseCode: 'SLT32',
        gradReq: 'sciLife',
        creditValue: 0.67,
        subjectArea: 'sciLiving',
      },
      {
        courseCode: 'SLT33',
        gradReq: 'sciLife',
        creditValue: 0.67,
        subjectArea: 'sciLiving',
      },
      {
        courseCode: 'SLT41',
        gradReq: 'sciLife',
        creditValue: 1,
        subjectArea: 'sciLiving',
      },
      {
        courseCode: 'SLT42',
        gradReq: 'sciLife',
        creditValue: 1,
        subjectArea: 'sciLiving',
      },
      {
        courseCode: 'SLT43',
        gradReq: 'sciLife',
        creditValue: 1,
        subjectArea: 'sciLiving',
      },
    ],
    sciPhysics: [
      {
        courseCode: 'SPT31',
        gradReq: 'sciPhysical',
        creditValue: 1,
        subjectArea: 'sciPhysics',
      },
      {
        courseCode: 'SPT32',
        gradReq: 'sciPhysical',
        creditValue: 1,
        subjectArea: 'sciPhysics',
      },
    ],
    sciOther: [
      {
        courseCode: 'SQT31',
        gradReq: 'sciTotal',
        creditValue: 1,
        subjectArea: 'sciOther',
      },
      {
        courseCode: 'SQT32',
        gradReq: 'sciTotal',
        creditValue: 1,
        subjectArea: 'sciOther',
      },
      {
        courseCode: 'SQT33',
        gradReq: 'sciTotal',
        creditValue: 1,
        subjectArea: 'sciOther',
      },
    ],
    sciLife: [
      {
        courseCode: 'SWT11',
        gradReq: 'sciLife',
        creditValue: 1,
        subjectArea: 'sciLife',
      },
      {
        courseCode: 'SWT31',
        gradReq: 'sciLife',
        creditValue: 1,
        subjectArea: 'sciLife',
      },
      {
        courseCode: 'SWT32',
        gradReq: 'sciLife',
        creditValue: 1,
        subjectArea: 'sciLife',
      },
      {
        courseCode: 'SWT33',
        gradReq: 'sciLife',
        creditValue: 1,
        subjectArea: 'sciLife',
      },
    ],
    functionalId: [
      {
        courseCode: 'ZJT11',
        gradReq: 'misc',
        creditValue: 0.4,
        subjectArea: 'functionalId',
      },
    ],
  },
  QUARTERLY: {
    art: [
      {
        courseCode: 'AMF41',
        gradReq: 'art',
        creditValue: 0.25,
        subjectArea: 'art',
      },
      {
        courseCode: 'AMF42',
        gradReq: 'art',
        creditValue: 0.25,
        subjectArea: 'art',
      },
      {
        courseCode: 'AMF43',
        gradReq: 'art',
        creditValue: 0.5,
        subjectArea: 'art',
      },
      {
        courseCode: 'AMF44',
        gradReq: 'art',
        creditValue: 0.5,
        subjectArea: 'art',
      },
      {
        courseCode: 'ANF81',
        gradReq: 'art',
        creditValue: 0.25,
        subjectArea: 'art',
      },
      {
        courseCode: 'ANF82',
        gradReq: 'art',
        creditValue: 0.25,
        subjectArea: 'art',
      },
      {
        courseCode: 'ANF83',
        gradReq: 'art',
        creditValue: 0.25,
        subjectArea: 'art',
      },
      {
        courseCode: 'ANF84',
        gradReq: 'art',
        creditValue: 0.25,
        subjectArea: 'art',
      },
      {
        courseCode: 'ANF85',
        gradReq: 'art',
        creditValue: 0.25,
        subjectArea: 'art',
      },
      {
        courseCode: 'ANF86',
        gradReq: 'art',
        creditValue: 0.25,
        subjectArea: 'art',
      },
      {
        courseCode: 'ANF87',
        gradReq: 'art',
        creditValue: 0.25,
        subjectArea: 'art',
      },
      {
        courseCode: 'ANF88',
        gradReq: 'art',
        creditValue: 0.25,
        subjectArea: 'art',
      },
      {
        courseCode: 'UAF41',
        gradReq: 'art',
        creditValue: 0.25,
        subjectArea: 'art',
      },
      {
        courseCode: 'UAF42',
        gradReq: 'art',
        creditValue: 0.25,
        subjectArea: 'art',
      },
      {
        courseCode: 'UAF43',
        gradReq: 'art',
        creditValue: 0.25,
        subjectArea: 'art',
      },
      {
        courseCode: 'UAF44',
        gradReq: 'art',
        creditValue: 0.25,
        subjectArea: 'art',
      },
    ],
    elaCore: [
      {
        courseCode: 'EEF41',
        gradReq: 'elaCore',
        creditValue: 0.5,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EEF42',
        gradReq: 'elaCore',
        creditValue: 0.5,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EEF43',
        gradReq: 'elaCore',
        creditValue: 0.5,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EEF44',
        gradReq: 'elaCore',
        creditValue: 0.5,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EEFG1',
        gradReq: 'elaCore',
        creditValue: 0.5,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EEFG2',
        gradReq: 'elaCore',
        creditValue: 0.5,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EEFG3',
        gradReq: 'elaCore',
        creditValue: 0.5,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EEFG4',
        gradReq: 'elaCore',
        creditValue: 0.5,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EEFG5',
        gradReq: 'elaCore',
        creditValue: 0.5,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EEFG6',
        gradReq: 'elaCore',
        creditValue: 0.5,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EEFG7',
        gradReq: 'elaCore',
        creditValue: 0.5,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EEFG8',
        gradReq: 'elaCore',
        creditValue: 0.5,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EEFG9',
        gradReq: 'elaCore',
        creditValue: 0.5,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EEFGA',
        gradReq: 'elaCore',
        creditValue: 0.5,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EEFGB',
        gradReq: 'elaCore',
        creditValue: 0.5,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EEFGC',
        gradReq: 'elaCore',
        creditValue: 0.5,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EEFGD',
        gradReq: 'elaCore',
        creditValue: 0.5,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EEFGE',
        gradReq: 'elaCore',
        creditValue: 0.5,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EEFGF',
        gradReq: 'elaCore',
        creditValue: 0.5,
        subjectArea: 'elaCore',
      },
      {
        courseCode: 'EEFGG',
        gradReq: 'elaCore',
        creditValue: 0.5,
        subjectArea: 'elaCore',
      },
    ],
    elaElect: [
      {
        courseCode: 'EQF41',
        gradReq: 'misc',
        creditValue: 0.25,
        subjectArea: 'elaElect',
      },
      {
        courseCode: 'EQF42',
        gradReq: 'misc',
        creditValue: 0.25,
        subjectArea: 'elaElect',
      },
      {
        courseCode: 'EQF43',
        gradReq: 'misc',
        creditValue: 0.25,
        subjectArea: 'elaElect',
      },
      {
        courseCode: 'EQF44',
        gradReq: 'misc',
        creditValue: 0.25,
        subjectArea: 'elaElect',
      },
    ],
    elaEsl: [
      {
        courseCode: 'ESFG1',
        gradReq: 'misc',
        creditValue: 0.5,
        subjectArea: 'elaEsl',
      },
      {
        courseCode: 'ESFG2',
        gradReq: 'misc',
        creditValue: 0.5,
        subjectArea: 'elaEsl',
      },
      {
        courseCode: 'ESFG3',
        gradReq: 'misc',
        creditValue: 0.5,
        subjectArea: 'elaEsl',
      },
      {
        courseCode: 'ESFG4',
        gradReq: 'misc',
        creditValue: 0.5,
        subjectArea: 'elaEsl',
      },
      {
        courseCode: 'ESFG5',
        gradReq: 'misc',
        creditValue: 0.5,
        subjectArea: 'elaEsl',
      },
      {
        courseCode: 'ESFG6',
        gradReq: 'misc',
        creditValue: 0.5,
        subjectArea: 'elaEsl',
      },
      {
        courseCode: 'ESFG7',
        gradReq: 'misc',
        creditValue: 0.5,
        subjectArea: 'elaEsl',
      },
      {
        courseCode: 'ESFG8',
        gradReq: 'misc',
        creditValue: 0.5,
        subjectArea: 'elaEsl',
      },
      {
        courseCode: 'ESFG9',
        gradReq: 'misc',
        creditValue: 0.5,
        subjectArea: 'elaEsl',
      },
      {
        courseCode: 'ESFGA',
        gradReq: 'misc',
        creditValue: 0.5,
        subjectArea: 'elaEsl',
      },
      {
        courseCode: 'ESFGB',
        gradReq: 'misc',
        creditValue: 0.5,
        subjectArea: 'elaEsl',
      },
      {
        courseCode: 'ESFGC',
        gradReq: 'misc',
        creditValue: 0.5,
        subjectArea: 'elaEsl',
      },
      {
        courseCode: 'ESFGD',
        gradReq: 'misc',
        creditValue: 0.5,
        subjectArea: 'elaEsl',
      },
      {
        courseCode: 'ESFGE',
        gradReq: 'misc',
        creditValue: 0.5,
        subjectArea: 'elaEsl',
      },
      {
        courseCode: 'ESFGF',
        gradReq: 'misc',
        creditValue: 0.5,
        subjectArea: 'elaEsl',
      },
      {
        courseCode: 'ESFGG',
        gradReq: 'misc',
        creditValue: 0.5,
        subjectArea: 'elaEsl',
      },
    ],
    lote: [
      {
        courseCode: 'FSFC1',
        gradReq: 'lote',
        creditValue: 0.5,
        subjectArea: 'lote',
      },
      {
        courseCode: 'FSFC2',
        gradReq: 'lote',
        creditValue: 0.5,
        subjectArea: 'lote',
      },
      {
        courseCode: 'FSFC3',
        gradReq: 'lote',
        creditValue: 0.5,
        subjectArea: 'lote',
      },
      {
        courseCode: 'FSFC4',
        gradReq: 'lote',
        creditValue: 0.5,
        subjectArea: 'lote',
      },
      {
        courseCode: 'FSFC5',
        gradReq: 'lote',
        creditValue: 0.5,
        subjectArea: 'lote',
      },
      {
        courseCode: 'FSFC6',
        gradReq: 'lote',
        creditValue: 0.5,
        subjectArea: 'lote',
      },
      {
        courseCode: 'FSFC7',
        gradReq: 'lote',
        creditValue: 0.5,
        subjectArea: 'lote',
      },
      {
        courseCode: 'FSFC8',
        gradReq: 'lote',
        creditValue: 0.5,
        subjectArea: 'lote',
      },
      {
        courseCode: 'FSFC9',
        gradReq: 'lote',
        creditValue: 0.5,
        subjectArea: 'lote',
      },
      {
        courseCode: 'FSFCA',
        gradReq: 'lote',
        creditValue: 0.5,
        subjectArea: 'lote',
      },
      {
        courseCode: 'FSFCB',
        gradReq: 'lote',
        creditValue: 0.5,
        subjectArea: 'lote',
      },
      {
        courseCode: 'FSFCC',
        gradReq: 'lote',
        creditValue: 0.5,
        subjectArea: 'lote',
      },
    ],
    advisory: [
      {
        courseCode: 'GAF81',
        gradReq: 'misc',
        creditValue: 0.5,
        subjectArea: 'advisory',
      },
      {
        courseCode: 'GAF82',
        gradReq: 'misc',
        creditValue: 0.5,
        subjectArea: 'advisory',
      },
      {
        courseCode: 'GAF83',
        gradReq: 'misc',
        creditValue: 0.5,
        subjectArea: 'advisory',
      },
      {
        courseCode: 'GAF84',
        gradReq: 'misc',
        creditValue: 0.5,
        subjectArea: 'advisory',
      },
      {
        courseCode: 'GAF85',
        gradReq: 'misc',
        creditValue: 0.5,
        subjectArea: 'advisory',
      },
      {
        courseCode: 'GAF86',
        gradReq: 'misc',
        creditValue: 0.5,
        subjectArea: 'advisory',
      },
      {
        courseCode: 'GAF87',
        gradReq: 'misc',
        creditValue: 0.5,
        subjectArea: 'advisory',
      },
      {
        courseCode: 'GAF88',
        gradReq: 'misc',
        creditValue: 0.5,
        subjectArea: 'advisory',
      },
      {
        courseCode: 'GAFG1',
        gradReq: 'misc',
        creditValue: 0.25,
        subjectArea: 'advisory',
      },
      {
        courseCode: 'GAFG2',
        gradReq: 'misc',
        creditValue: 0.25,
        subjectArea: 'advisory',
      },
      {
        courseCode: 'GAFG3',
        gradReq: 'misc',
        creditValue: 0.25,
        subjectArea: 'advisory',
      },
      {
        courseCode: 'GAFG4',
        gradReq: 'misc',
        creditValue: 0.25,
        subjectArea: 'advisory',
      },
      {
        courseCode: 'GAFG5',
        gradReq: 'misc',
        creditValue: 0.25,
        subjectArea: 'advisory',
      },
      {
        courseCode: 'GAFG6',
        gradReq: 'misc',
        creditValue: 0.25,
        subjectArea: 'advisory',
      },
      {
        courseCode: 'GAFG7',
        gradReq: 'misc',
        creditValue: 0.25,
        subjectArea: 'advisory',
      },
      {
        courseCode: 'GAFG8',
        gradReq: 'misc',
        creditValue: 0.25,
        subjectArea: 'advisory',
      },
      {
        courseCode: 'GAFG9',
        gradReq: 'misc',
        creditValue: 0.25,
        subjectArea: 'advisory',
      },
      {
        courseCode: 'GAFGA',
        gradReq: 'misc',
        creditValue: 0.25,
        subjectArea: 'advisory',
      },
      {
        courseCode: 'GAFGB',
        gradReq: 'misc',
        creditValue: 0.25,
        subjectArea: 'advisory',
      },
      {
        courseCode: 'GAFGC',
        gradReq: 'misc',
        creditValue: 0.25,
        subjectArea: 'advisory',
      },
      {
        courseCode: 'GAFGD',
        gradReq: 'misc',
        creditValue: 0.25,
        subjectArea: 'advisory',
      },
      {
        courseCode: 'GAFGE',
        gradReq: 'misc',
        creditValue: 0.25,
        subjectArea: 'advisory',
      },
    ],
    ssEcon: [
      {
        courseCode: 'HEF21',
        gradReq: 'ssEcon',
        creditValue: 0.5,
        subjectArea: 'ssEcon',
      },
      {
        courseCode: 'HEF22',
        gradReq: 'ssEcon',
        creditValue: 0.5,
        subjectArea: 'ssEcon',
      },
    ],
    ssGlobal: [
      {
        courseCode: 'HGF41',
        gradReq: 'ssGlobal',
        creditValue: 0.5,
        subjectArea: 'ssGlobal',
      },
      {
        courseCode: 'HGF42',
        gradReq: 'ssGlobal',
        creditValue: 0.5,
        subjectArea: 'ssGlobal',
      },
      {
        courseCode: 'HGF43',
        gradReq: 'ssGlobal',
        creditValue: 0.5,
        subjectArea: 'ssGlobal',
      },
      {
        courseCode: 'HGF44',
        gradReq: 'ssGlobal',
        creditValue: 0.5,
        subjectArea: 'ssGlobal',
      },
      {
        courseCode: 'HGF81',
        gradReq: 'ssGlobal',
        creditValue: 0.5,
        subjectArea: 'ssGlobal',
      },
      {
        courseCode: 'HGF82',
        gradReq: 'ssGlobal',
        creditValue: 0.5,
        subjectArea: 'ssGlobal',
      },
      {
        courseCode: 'HGF83',
        gradReq: 'ssGlobal',
        creditValue: 0.5,
        subjectArea: 'ssGlobal',
      },
      {
        courseCode: 'HGF84',
        gradReq: 'ssGlobal',
        creditValue: 0.5,
        subjectArea: 'ssGlobal',
      },
      {
        courseCode: 'HGF85',
        gradReq: 'ssGlobal',
        creditValue: 0.5,
        subjectArea: 'ssGlobal',
      },
      {
        courseCode: 'HGF86',
        gradReq: 'ssGlobal',
        creditValue: 0.5,
        subjectArea: 'ssGlobal',
      },
      {
        courseCode: 'HGF87',
        gradReq: 'ssGlobal',
        creditValue: 0.5,
        subjectArea: 'ssGlobal',
      },
      {
        courseCode: 'HGF88',
        gradReq: 'ssGlobal',
        creditValue: 0.5,
        subjectArea: 'ssGlobal',
      },
    ],
    ssUs: [
      {
        courseCode: 'HUF41',
        gradReq: 'ssUs',
        creditValue: 0.5,
        subjectArea: 'ssUs',
      },
      {
        courseCode: 'HUF42',
        gradReq: 'ssUs',
        creditValue: 0.5,
        subjectArea: 'ssUs',
      },
      {
        courseCode: 'HUF43',
        gradReq: 'ssUs',
        creditValue: 0.5,
        subjectArea: 'ssUs',
      },
      {
        courseCode: 'HUF44',
        gradReq: 'ssUs',
        creditValue: 0.5,
        subjectArea: 'ssUs',
      },
    ],
    ssGovt: [
      {
        courseCode: 'HVF21',
        gradReq: 'ssGovt',
        creditValue: 0.5,
        subjectArea: 'ssGovt',
      },
      {
        courseCode: 'HVF22',
        gradReq: 'ssGovt',
        creditValue: 0.5,
        subjectArea: 'ssGovt',
      },
      {
        courseCode: 'HVF41',
        gradReq: 'ssGovt',
        creditValue: 0.5,
        subjectArea: 'ssGovt',
      },
      {
        courseCode: 'HVF42',
        gradReq: 'ssGovt',
        creditValue: 0.5,
        subjectArea: 'ssGovt',
      },
      {
        courseCode: 'HVF43',
        gradReq: 'ssGovt',
        creditValue: 0.5,
        subjectArea: 'ssGovt',
      },
      {
        courseCode: 'HVF44',
        gradReq: 'ssGovt',
        creditValue: 0.5,
        subjectArea: 'ssGovt',
      },
    ],
    mathCalc: [
      {
        courseCode: 'MCF41',
        gradReq: 'math',
        creditValue: 0.5,
        subjectArea: 'mathCalc',
      },
      {
        courseCode: 'MCF42',
        gradReq: 'math',
        creditValue: 0.5,
        subjectArea: 'mathCalc',
      },
      {
        courseCode: 'MCF43',
        gradReq: 'math',
        creditValue: 0.5,
        subjectArea: 'mathCalc',
      },
      {
        courseCode: 'MCF44',
        gradReq: 'math',
        creditValue: 0.5,
        subjectArea: 'mathCalc',
      },
    ],
    mathAlg: [
      {
        courseCode: 'MEF41',
        gradReq: 'math',
        creditValue: 0.5,
        subjectArea: 'mathAlg',
      },
      {
        courseCode: 'MEF42',
        gradReq: 'math',
        creditValue: 0.5,
        subjectArea: 'mathAlg',
      },
      {
        courseCode: 'MEF43',
        gradReq: 'math',
        creditValue: 0.5,
        subjectArea: 'mathAlg',
      },
      {
        courseCode: 'MEF44',
        gradReq: 'math',
        creditValue: 0.5,
        subjectArea: 'mathAlg',
      },
    ],
    mathGeom: [
      {
        courseCode: 'MGF41',
        gradReq: 'math',
        creditValue: 0.5,
        subjectArea: 'mathGeom',
      },
      {
        courseCode: 'MGF42',
        gradReq: 'math',
        creditValue: 0.5,
        subjectArea: 'mathGeom',
      },
      {
        courseCode: 'MGF43',
        gradReq: 'math',
        creditValue: 0.5,
        subjectArea: 'mathGeom',
      },
      {
        courseCode: 'MGF44',
        gradReq: 'math',
        creditValue: 0.5,
        subjectArea: 'mathGeom',
      },
    ],
    mathPreCalc: [
      {
        courseCode: 'MPF41',
        gradReq: 'math',
        creditValue: 0.5,
        subjectArea: 'mathPreCalc',
      },
      {
        courseCode: 'MPF42',
        gradReq: 'math',
        creditValue: 0.5,
        subjectArea: 'mathPreCalc',
      },
      {
        courseCode: 'MPF43',
        gradReq: 'math',
        creditValue: 0.5,
        subjectArea: 'mathPreCalc',
      },
      {
        courseCode: 'MPF44',
        gradReq: 'math',
        creditValue: 0.5,
        subjectArea: 'mathPreCalc',
      },
    ],
    mathTrig: [
      {
        courseCode: 'MRF41',
        gradReq: 'math',
        creditValue: 0.5,
        subjectArea: 'mathTrig',
      },
      {
        courseCode: 'MRF42',
        gradReq: 'math',
        creditValue: 0.5,
        subjectArea: 'mathTrig',
      },
      {
        courseCode: 'MRF43',
        gradReq: 'math',
        creditValue: 0.5,
        subjectArea: 'mathTrig',
      },
      {
        courseCode: 'MRF44',
        gradReq: 'math',
        creditValue: 0.5,
        subjectArea: 'mathTrig',
      },
    ],
    mathStats: [
      {
        courseCode: 'MSF41',
        gradReq: 'math',
        creditValue: 0.5,
        subjectArea: 'mathStats',
      },
      {
        courseCode: 'MSF42',
        gradReq: 'math',
        creditValue: 0.5,
        subjectArea: 'mathStats',
      },
      {
        courseCode: 'MSF43',
        gradReq: 'math',
        creditValue: 0.5,
        subjectArea: 'mathStats',
      },
      {
        courseCode: 'MSF44',
        gradReq: 'math',
        creditValue: 0.5,
        subjectArea: 'mathStats',
      },
    ],
    health: [
      {
        courseCode: 'PHF21',
        gradReq: 'health',
        creditValue: 0.5,
        subjectArea: 'health',
      },
      {
        courseCode: 'PHF22',
        gradReq: 'health',
        creditValue: 0.5,
        subjectArea: 'health',
      },
    ],
    pe: [
      {
        courseCode: 'PPF41',
        gradReq: 'pe',
        creditValue: 0.25,
        subjectArea: 'pe',
      },
      {
        courseCode: 'PPF42',
        gradReq: 'pe',
        creditValue: 0.25,
        subjectArea: 'pe',
      },
      {
        courseCode: 'PPF43',
        gradReq: 'pe',
        creditValue: 0.25,
        subjectArea: 'pe',
      },
      {
        courseCode: 'PPF44',
        gradReq: 'pe',
        creditValue: 0.25,
        subjectArea: 'pe',
      },
    ],
    cte: [
      {
        courseCode: 'RQF11',
        gradReq: 'cte',
        creditValue: 0.5,
        subjectArea: 'cte',
      },
    ],
    sciChem: [
      {
        courseCode: 'SCF41',
        gradReq: 'sciPhysical',
        creditValue: 0.5,
        subjectArea: 'sciChem',
      },
      {
        courseCode: 'SCF42',
        gradReq: 'sciPhysical',
        creditValue: 0.5,
        subjectArea: 'sciChem',
      },
      {
        courseCode: 'SCF43',
        gradReq: 'sciPhysical',
        creditValue: 0.5,
        subjectArea: 'sciChem',
      },
      {
        courseCode: 'SCF44',
        gradReq: 'sciPhysical',
        creditValue: 0.5,
        subjectArea: 'sciChem',
      },
    ],
    sciEarth: [
      {
        courseCode: 'SEF41',
        gradReq: 'sciPhysical',
        creditValue: 0.5,
        subjectArea: 'sciEarth',
      },
      {
        courseCode: 'SEF42',
        gradReq: 'sciPhysical',
        creditValue: 0.5,
        subjectArea: 'sciEarth',
      },
      {
        courseCode: 'SEF43',
        gradReq: 'sciPhysical',
        creditValue: 0.5,
        subjectArea: 'sciEarth',
      },
      {
        courseCode: 'SEF44',
        gradReq: 'sciPhysical',
        creditValue: 0.5,
        subjectArea: 'sciEarth',
      },
    ],
    sciLiving: [
      {
        courseCode: 'SLF41',
        gradReq: 'sciLife',
        creditValue: 0.5,
        subjectArea: 'sciLiving',
      },
      {
        courseCode: 'SLF42',
        gradReq: 'sciLife',
        creditValue: 0.5,
        subjectArea: 'sciLiving',
      },
      {
        courseCode: 'SLF43',
        gradReq: 'sciLife',
        creditValue: 0.5,
        subjectArea: 'sciLiving',
      },
      {
        courseCode: 'SLF44',
        gradReq: 'sciLife',
        creditValue: 0.5,
        subjectArea: 'sciLiving',
      },
    ],
    sciPhysics: [
      {
        courseCode: 'SPF41',
        gradReq: 'sciPhysical',
        creditValue: 0.5,
        subjectArea: 'sciPhysics',
      },
      {
        courseCode: 'SPF42',
        gradReq: 'sciPhysical',
        creditValue: 0.5,
        subjectArea: 'sciPhysics',
      },
      {
        courseCode: 'SPF43',
        gradReq: 'sciPhysical',
        creditValue: 0.5,
        subjectArea: 'sciPhysics',
      },
      {
        courseCode: 'SPF44',
        gradReq: 'sciPhysical',
        creditValue: 0.5,
        subjectArea: 'sciPhysics',
      },
    ],
    sciLife: [
      {
        courseCode: 'SWF41',
        gradReq: 'sciLife',
        creditValue: 0.5,
        subjectArea: 'sciLife',
      },
      {
        courseCode: 'SWF42',
        gradReq: 'sciLife',
        creditValue: 0.5,
        subjectArea: 'sciLife',
      },
      {
        courseCode: 'SWF43',
        gradReq: 'sciLife',
        creditValue: 0.5,
        subjectArea: 'sciLife',
      },
      {
        courseCode: 'SWF44',
        gradReq: 'sciLife',
        creditValue: 0.5,
        subjectArea: 'sciLife',
      },
    ],
  },
};
