import { forwardRef, Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { identity } from 'lodash';
import { catchError, map, switchMap, takeLast, tap, timeout } from 'rxjs/operators';
import * as courseDiffsActions from '../actions/course-diff-actions';
import { ApiService } from './../../shared/services/api-service/api-service';
import { ModalsService } from '../../shared/modals/modals.service';
import { BACKGROUND_JOB_STATUS_TYPES, BACKGROUND_JOB_TIMEOUT_THRESHOLDS, BackgroundJob } from '../../shared/services/background-job/background-job.service';
import { BackgroundJobNotificationService } from '../../shared/services/background-job-notification-service/background-job-notification-service';
import { BatchActionsEffectsUtilities } from '../utilities/batch-actions-effects-utilities';
import { SnackBarService } from '../../shared/services/snackbar/snackbar.service';
import { HttpResponse } from '@angular/common/http';

@Injectable()
export class CourseDiffEffects {
  constructor (
    private actions$: Actions,
    private apiService: ApiService,
    @Inject(forwardRef(() => ModalsService)) private modalsService: ModalsService,
    private backgroundJobNotificationService: BackgroundJobNotificationService,
    private snackBarService: SnackBarService,
    private backgroundJob: BackgroundJob,
  ) { }

  bulkCreateCourseDiffs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(courseDiffsActions.BULK_CREATE_COURSE_DIFF),
      switchMap((action: any) => {
        const payload = action.payload;
        const { origin } = payload;
        const httpResponseObsv$: Observable<HttpResponse<object>> = this.apiService.bulkCreateCourseDiff(payload as any);
        return httpResponseObsv$.pipe(
          takeLast(1),
          map(response => BatchActionsEffectsUtilities.getJobSubject(this.backgroundJob, { response })),
          tap(subject => {
            this.modalsService.openBackgroundJobSpinnerModal({ backgroundJobSubject: subject, title: 'Creating course diff' });
          }),
          switchMap(identity),
          timeout(BACKGROUND_JOB_TIMEOUT_THRESHOLDS.BATCH_ACTIONS),
          tap(({ type }) => {
            if (type === BACKGROUND_JOB_STATUS_TYPES.RESOLVE) {
              BatchActionsEffectsUtilities.sendSnack(this.snackBarService, { success: true, origin });
              this.backgroundJobNotificationService.sendMessage({ backgroundJob: 'BulkCreateCourseDiffs' });
              return [new courseDiffsActions.BulkCreateCourseDiffSuccess(payload)];
            }
          }),
          catchError(error => {
            // open modal with error
            const payload = {
              title: 'Unable to Complete Batch Action',
              message: 'The batch action could not be applied to all of the selected students. Please try again.',
              confirmText: 'Ok',
            };
            this.modalsService.openErrorModal(payload);
            return of(new courseDiffsActions.BulkCreateCourseDiffFail(error));
          }),
        );
      }),
    );
  }, { dispatch: false });
}
