import { ElementRef, Injectable, QueryList } from '@angular/core';
import { IGroupData } from '../../models/list-models';
import { isEqual } from 'lodash';
import { WildcardService } from './wildcard.service';

export interface IDisplayedHeader {
  key: string;
  human: string;
  total?: number;
  tooltip?: string;
  cellTooltip?: string;
  cellConfig?: string;
}

@Injectable()
export class HeaderService {
  static checkHeaderShadow (scrollTop: number): boolean {
    return scrollTop !== 0;
  }

  static checkHeaderPos (elRefs: QueryList<ElementRef>, listStickyHeader: ElementRef): number {
    const listStickyHeaderRect: DOMRect = listStickyHeader.nativeElement.getBoundingClientRect();
    const listStickyHeaderBottom: number = listStickyHeaderRect.y + listStickyHeaderRect.height;
    const list: Array<ElementRef> = elRefs.toArray();
    let headerPosition: number = 0;
    let i: number;
    for (i = list.length - 1; i > -1; i--) {
      const listHead = list[i].nativeElement.querySelector('listhead');
      if (listHead) {
        const cell = listHead.querySelector('cell');
        const cellRect = cell.getBoundingClientRect();
        const cellYPlusHalfHeight = cellRect.y + cellRect.height / 2;
        if (cellYPlusHalfHeight && cellYPlusHalfHeight < listStickyHeaderBottom) {
          headerPosition = i;
          break;
        }
      }
    }
    return headerPosition;
  }

  static getNewDisplayedHeaders (
    displayedHeaders: IDisplayedHeader[],
    headerInfo: { human: string; total: number; tooltip: string },
  ): IDisplayedHeader[] {
    const newDisplayedHeaders = Array.from(displayedHeaders);
    newDisplayedHeaders[0].human = headerInfo.human;
    newDisplayedHeaders[0].tooltip = headerInfo.tooltip;
    newDisplayedHeaders[0].total = headerInfo.total;
    return newDisplayedHeaders;
  }

  static getDisplayedHeaders (
    columns: Array<{ graphQlKey: string; columnName: string }>,
    groupData: IGroupData,
    madlibModel: any,
    showFirstColumnHeader?: boolean,
  ): IDisplayedHeader[] {
    const displayedHeaders = columns.map((col: any, indx: number) => {
      const displayedHeader: IDisplayedHeader =
        indx === 0
          ? {
              key: col.colKey || col.graphQlKey,
              human: showFirstColumnHeader ? col.columnName || col.label : groupData.human,
              tooltip: col.headerTooltip,
              total: groupData.rowData.length,
              // preserve the exiting logic and extend the logic to append wildcardKey
              cellTooltip: WildcardService.getCellTooltipCalcKey({ madlibModel, column: col }),
              cellConfig: col.cellConfig,
            }
          : {
              key: col.colKey || col.graphQlKey,
              // update backend config to align field names
              human: col.columnName || col.label,
            };
      if (col.headerTooltip) displayedHeader.tooltip = col.headerTooltip;
      if (col.cellTooltip) displayedHeader.cellTooltip = WildcardService.getCellTooltipCalcKey({ madlibModel, column: col });
      if (col.cellConfig) displayedHeader.cellConfig = col.cellConfig;
      return displayedHeader;
    });
    return displayedHeaders;
  }

  static getHeaderOverflow (className: string): boolean {
    const element = document.getElementsByClassName(className)[0] as HTMLElement;
    return element.scrollHeight > element.clientHeight;
  }

  static haveHeadersChanged (oldHeaders: IDisplayedHeader[], newHeaders: IDisplayedHeader[]): boolean {
    const newHeaderKeys = newHeaders.map((header: IDisplayedHeader) => ({ key: header.key, human: header.human }));
    const oldHeaderKeys = oldHeaders.map((header: IDisplayedHeader) => ({ key: header.key, human: header.human }));
    return !isEqual(newHeaderKeys, oldHeaderKeys);
  }
}
