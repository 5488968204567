import { Injectable } from '@angular/core';
import { TSchoolUserModalViewMode } from 'Src/ng2/shared/modals/user-management/school-user/school-user-modals.config';

@Injectable()
export class GraphQLSchoolUserHelperService {
  public getSchoollUsersQuery ({ schoolId, grouping, projection }): string {
    const columnKeys = this.getColumnKeys(projection);
    let query;
    if (!grouping) {
      query = `{
        SchoolUserGrouping(
          schoolId: "${schoolId}",
          columns: [${columnKeys}],
        ) {
            groupData {
              human
              rowData {
                data
                meta
                columnKey
              }
            }
            groupingOptions{
              human
              key
            }
          }
      }`;
    } else {
      query = `{
        SchoolUserGrouping(
          schoolId: "${schoolId}",
          groupingKey: "${grouping}",
          columns: [${columnKeys}]
        ) {
            groupData {
              human
              rowData {
                data
                meta
                columnKey
              }
            }
          }
      }`;
    }
    return query;
  }

  public getSchoolUserMadLibQuery ({ schoolId, projection }): string {
    const columnKeys = this.getColumnKeys(projection);
    const query = `{
      SchoolUserGrouping(
        schoolId: "${schoolId}",
        columns: [${columnKeys}]
      ) {
          groupingOptions{
            human
            key
            options {
              human 
              key
            }
          }
        }
      }`;
    return query;
  }

  public getSchoolUserDetailQuery ({ userId, schoolId }): string {
    const validatedUserId = userId === null ? `${userId}` : `"${userId}"`;
    const query = `{
      SchoolUserDetail(
        userId: ${validatedUserId},
        schoolId: "${schoolId}"
      ){ 
        firstName
        lastName
        gafeEmail
        doeEmail
        jobRole
        mappedSchoolLevelRole
        isTrustedAtSchoolLevel   
      }
    }`;
    return query;
  }

  public getSchoolUserEmailDupesQuery ({ userPayload: payload }, mode: TSchoolUserModalViewMode, schoolId): string {
    const { userId } = payload;
    let options = '';

    options += this._getBasicInfoOptions(payload);

    if (userId) {
      options += `userId: "${userId}"`;
    }

    return `{
      SchoolUserDupesEmails(
        options: {${options}},
        schoolId: "${schoolId}",
        mode: "${mode}"
      ) {
        firstName
        lastName
        gafeEmail
        doeEmail
      }
    }`;
  }

  public getSchoolUserMutationQuery ({ userPayload: payload, columns }, mode: TSchoolUserModalViewMode, schoolId): string {
    const { userId, isDOEemployee, schoolType } = payload;
    let mutationName;
    let options = '';

    switch (mode) {
      case 'CREATE':
        mutationName = 'createSchoolUser';
        break;
      case 'EDIT':
        mutationName = 'updateSchoolUser';
        break;
      case 'REMOVE':
        mutationName = 'deleteSchoolUser';
        break;
    }

    options += this._getBasicInfoOptions(payload);

    if (userId) {
      options += `userId: "${userId}",`;
    }

    if (schoolType) {
      options += `schoolType: "${schoolType}",`;
    }

    const columnKeys = this.getColumnKeys(columns);

    return `
    mutation {
      ${mutationName}(
        options: {${options}},
        columns: [${columnKeys}],
        schoolId: "${schoolId}",
        isDOEemployee: "${isDOEemployee}",
      ) {
        groupData {
          human
          rowData {
            data
            meta
            columnKey
          }
        }
        groupingOptions{
          human
          key
          options {
            human 
            key
          }
        }
      }
    }`;
  }

  public getbulkUpdateUserPermissionsQuery ({ userPayload, columns }, accessLevel, schoolId) {
    const userIds = userPayload.users.map(id => `"${id}"`);
    const columnKeys = this.getColumnKeys(columns);

    return `mutation{
      bulkUpdateSchoolUserPermissions(
        schoolId: "${schoolId}",
        accessLevel: "${accessLevel}",
        userIds: [${userIds}],
        columns: [${columnKeys}],
      ){
        backgroundJobId
      }
    }`;

    // return `mutation{
    //   bulkUpdateSchoolUserPermissions(
    //     schoolId: "${schoolId}",
    //     accessLevel: "${accessLevel}",
    //     userIds: [${userIds}],
    //     columns: [${columnKeys}],
    //   ){
    //     groupData {
    //       human
    //       rowData {
    //         data
    //         meta
    //         columnKey
    //       }
    //     }
    //     groupingOptions{
    //       human
    //       key
    //     }
    //   }
    // }`;
  }

  private getColumnKeys (projection: string[]): string[] {
    return projection.map(key => {
      return `"${key}"`;
    });
  }

  private _getBasicInfoOptions (payload): string {
    const { delegatedRole, doeEmail, firstName, gafeEmail, lastName, jobRole, nvRole, userType } = payload;
    let options = '';
    if (firstName) {
      const validatedDelegatedRole = delegatedRole === 'no_access' ? 'null' : `"${delegatedRole}"`;
      options = options += `
          title: "${jobRole}",
          name: {
            firstName: "${firstName}",
            lastName: "${lastName}",
          },
          delegatedRole: ${validatedDelegatedRole},
          userType: "${userType}",
          nvRole: {
            type: "${nvRole?.type}",
            schoolId: "${nvRole?.schoolId}",
          },
          jobRole: "${jobRole}",
        `;

      if (gafeEmail || doeEmail) {
        // when it is undefined?
        // 1. no optional login method
        // 2. formControls associated with them are disabled
        // when it is empty string?
        // 1. email type is optional
        const validatedGafeEmail = gafeEmail ? `"${gafeEmail}"` : 'null';
        const validatedDoeEmail = doeEmail ? `"${doeEmail}"` : 'null';
        options += `
          gafeEmail: ${validatedGafeEmail},
          doeEmail: ${validatedDoeEmail},
          `;
      }
    }
    return options;
  }
}
