import { MatMenuModule } from '@angular/material/menu';
import { GridApi } from '@ag-grid-community/core';
import { HistoryAllModalMoreButtonModule } from './../../components/server-side-grid/history-all-modal-more-button/history-all-modal-more-button.module';
import { SuccessMentoringPanelService } from 'Src/ng2/shelter/profile/profile-panels/success-mentoring-panel/success-mentoring-panel.service';
import { NvSegmentedControlModule } from './../../../../nvps-libraries/design/nv-segmented-control/nv-segmented-control.module';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AgGridModule } from '@ag-grid-community/angular';
import { HistoryAllModalComponent } from './history-all-modal.component';
import { HistoryAllHelperServiceShelter } from './history-all-helper-shelter.service';
import { CommonModule } from '@angular/common';
import { NoRowsComponentModule } from '../../components/server-side-grid/no-rows/no-rows.module';
import { NvButtonModule } from 'projects/shared/nvps-libraries/design/nv-button/nv-button.module';
import { NvMatTooltipModule } from '../../components/nv-mat-tooltip/nv-mat-tooltip.module';

import { CategoryTagsRendererModule } from '../../components/server-side-grid/category-tags-renderer/category-tags-renderer.module';
import { FiltersLogsOptionsModule } from 'Src/ng2/student/sidenav/ssv-side-nav-primary/filter-logs-options/filter-logs-options.module';

@NgModule({
  declarations: [HistoryAllModalComponent],
  imports: [
    NvSegmentedControlModule,
    AgGridModule,
    CommonModule,
    NoRowsComponentModule,
    NvButtonModule,
    NvMatTooltipModule,
    CategoryTagsRendererModule,
    HistoryAllModalMoreButtonModule,
    MatMenuModule,
    AgGridModule,
    FiltersLogsOptionsModule,
  ],
  exports: [HistoryAllModalComponent],
  providers: [HistoryAllHelperServiceShelter, SuccessMentoringPanelService, GridApi],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HistoryAllModalModule {}
