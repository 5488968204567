<div flex>
  <p class="mat-body-2">
    Review students who have been assigned to multiple support activities to ensure that the support meeting dates
    and times do not conflict. The best place to check is in the
    <span class="mat-subtitle-2">Assigned Supports panel</span> in the <span class="mat-subtitle-2">Student Profile</span>.
  </p>
</div>

<table class="mat-body-2 priority-table">
  <tr *ngFor="let row of tableRows | keyvalue">
    <td class="description">
      <wizard-filter-actions [data]="row.value" [options]="options"></wizard-filter-actions>

      <ng-container [ngPlural]="row.value.length">
        <ng-template ngPluralCase="0"> student </ng-template>
        <ng-template ngPluralCase="1"> student </ng-template>
        <ng-template ngPluralCase="few"> students </ng-template>
        <ng-template ngPluralCase="other"> students </ng-template>
      </ng-container>
      
      <span [ngSwitch]="row.key">
        <span *ngSwitchCase="'1'"> is in 1 support. </span> 
        <span *ngSwitchCase="'5'"> are in 5+ supports. </span>
        <span *ngSwitchDefault> are in {{row.key}} supports. </span>
      </span>
    </td>
    <td>
      <button mat-raised-button [disabled]="!row.value.length" class="step-action-button"
        (click)="goToProfile(row.value)">
        Review {{row.value.length}}
      </button>
    </td>
  </tr>
</table>