import { Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';

@Component({
  selector: 'radio-button-renderer',
  templateUrl: './radio-button-renderer.component.html',
  styleUrls: ['./radio-button-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class RadioButtonRendererComponent implements ICellRendererAngularComp {
  protected params: ICellRendererParams;
  public icon: String;
  @Output() rowSelected = new EventEmitter<any>();

  public agInit (params: any): void {
    this.params = params;
  }

  public refresh (params: ICellRendererParams): boolean {
    return true;
  }

  public onRadioClick (event: Event): void {
    // Update the row's selection status based on the radio button click
    this.params.node.setSelected(!this.params.node.isSelected());
    event.stopPropagation();

    // Accessing a method from the parent component through the context
    this.params.context.parentComponent.handleRowSelected(this.params.data);
  }
}
