import { ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { cloneDeep, isEqual } from 'lodash';
import moment from 'moment';

import { ApiService } from 'Src/ng2/shared/services/api-service/api-service';
import { BaseModalComponent } from '../base-modal.component';
import { INotification } from '../../typings/interfaces/notification.interface';
import { PortalConfig } from '../../services/portal-config';

interface INotificationFormData {
  title: string;
  message: string;
  status: string;
  priority: number;
  target: string;
  type: string;
  isDismissable: boolean;
  dateRange: {
    startDate: string;
    endDate: string;
  };
  timeRange: {
    startTime: string;
    endTime: string;
  };
}

interface INotificationForm {
  title: FormControl;
  message: FormControl;
  status: FormControl;
  priority: FormControl;
  target: FormControl;
  type: FormControl;
  isDismissable: FormControl;
  dateRange: FormGroup;
  timeRange: FormGroup;
}

interface INotificationDropdownOptions {
  status: {
    key: string;
    human: string;
    value: string;
  }[];
  priority: {
    key: string;
    human: string;
    value: number;
  }[];
  target: {
    key: string;
    human: string;
    value: string;
  }[];
  type: {
    key: string;
    human: string;
    value: string;
  }[];
  isDismissable: {
    key: string;
    human: string;
    value: boolean;
  }[];
}

interface ISelectedDropdownOption {
  status: {
    key: string;
    value: string;
  };
  priority: {
    key: string;
    value: number;
  };
  target: {
    key: string;
    value: string;
  };
  type: {
    key: string;
    value: string;
  };
  isDismissable: {
    key: string;
    value: boolean;
  };
};

@Component({
  selector: 'notifications-shell-modal',
  templateUrl: './notifications-modal-shell.component.html',
  styleUrls: ['./notifications-modal-shell.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NotificationsModalShellComponent extends BaseModalComponent implements OnInit {
  public disabled: { [key: string]: string | boolean } = {};
  public mode: 'Add' | 'Edit';
  public title: string;
  public notificationForm: FormGroup<INotificationForm>;
  public notificationToEdit: INotification;
  public dropDownOptions: { options: INotificationDropdownOptions };
  public selectedDropDownOptions: ISelectedDropdownOption;
  public disabledDropDownOptions: { [key: string]: boolean } = {
    target: true,
    type: true,
  };

  public errorMessage: string;

  // Date and time range
  public rangeStartTime: string = '6:00 AM';
  public rangeEndTime: string = '9:00 PM';
  public interval = 30;

  constructor (
    dialogRef: MatDialogRef<NotificationsModalShellComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { mode: 'Add' | 'Edit'; notification: INotification },
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private portalConfig: PortalConfig,
  ) {
    super(dialogRef);
  }

  ngOnInit (): void {
    // clone data to avoid mutating the passed in data
    const { mode, notification } = cloneDeep(this.data);
    this.notificationToEdit = notification;
    this.title = mode + ' notification';
    this.mode = mode;
    this.dropDownOptions = this.setDropdownOptions();
    if (this.mode === 'Add') {
      this.selectedDropDownOptions = this.setSelectedDropDownOptions(this.mode);
      this.initFormControlsToCreateNotification();
    } else {
      this.selectedDropDownOptions = this.setSelectedDropDownOptions(this.mode, this.notificationToEdit);
      this.initFormControlsFromNotification(this.notificationToEdit);
    }
    const initialForm = { ...this.notificationForm.value };
    this.notificationForm.valueChanges.pipe(
      tap((value) => {
        this.cdr.detectChanges();
        if (isEqual(value, initialForm)) {
          this.notificationForm.markAsPristine();
        } else {
          this.notificationForm.markAsDirty();
        }
      }),
      takeUntil(this.destroy$),
    )
      .subscribe();

    if (this.portalConfig.publicConfig.DISPLAY_ENV_PATH === 'prod') {
      this.disabled = this.getDisabledFields(this.mode);
    }
  }

  ngOnDestroy (): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngAfterViewChecked (): void {
    this.cdr.detectChanges();
  }

  public onDropDownOptionChange (optionName: string, optionValue: string): void {
    const value = this.dropDownOptions.options[optionName].find((option) => option.key === optionValue).value;
    this.selectedDropDownOptions[optionName] = { key: optionValue, value };
    this.notificationForm.controls[optionName].setValue(value);
  }

  public onClickPrimaryBtn (): void {
    const payload = this.prepPayloadForApi();
    if (this.mode === 'Add') {
      this.apiService.createNotification(payload).pipe(
        tap((createdNotification) => {
          this.dialogRef.close(createdNotification);
        }),
        catchError((error) => {
          return throwError(error);
        }),
        takeUntil(this.destroy$),
      ).subscribe();
    } else {
      // when editing, only changed fields should be sent to the api
      const patch = this.getUpdatePatch(this.notificationToEdit, payload);
      this.apiService.patchNotification({ _id: this.notificationToEdit._id, patch }).pipe(
        tap((updatedNotification) => {
          this.dialogRef.close(updatedNotification);
        }),
        catchError((error) => {
          return throwError(error);
        }),
        takeUntil(this.destroy$),
      ).subscribe();
    }
  }

  public async onPasteNotification () {
    const fromClipboard = await navigator.clipboard.readText();
    let notification: INotification;
    try {
      notification = JSON.parse(fromClipboard);
    } catch (e) {
      this.errorMessage = 'Pasted notification is not valid';
    }
    if (notification && notification._id) {
      this.selectedDropDownOptions = this.setSelectedDropDownOptions('copy', notification);
      this.updateNotificationForm(notification);
    }
  }

  public onCancel (): void {
    this.dialogRef.close();
  }

  private getDisabledFields (mode: string) {
    const disabledFields = {
      info: 'User input disabled on prod. Use paste button',
      title: true,
      message: true,
      dateRange: true,
      timeRange: true,
      status: true,
      priority: true,
      target: true,
      type: true,
      isDismissable: true,
    };
    if (mode === 'Edit') {
      disabledFields.info = 'Only status can be edited';
      disabledFields.status = false;
    }
    return disabledFields;
  }

  private buildForm (form: INotificationFormData): FormGroup<INotificationForm> {
    const notificationForm = this.formBuilder.group({
      title: [form.title, Validators.required],
      message: [form.message, Validators.required],
      status: [form.status, Validators.required],
      priority: [form.priority, Validators.compose([Validators.min(1), Validators.max(2)])],
      target: [form.target, Validators.required],
      type: [form.type, Validators.required],
      isDismissable: [form.isDismissable, Validators.required],
      dateRange: this.formBuilder.group({
        startDate: [form.dateRange.startDate, Validators.required],
        endDate: [form.dateRange.endDate, Validators.required],
      }),
      timeRange: this.formBuilder.group({
        startTime: [form.timeRange.startTime, Validators.required],
        endTime: [form.timeRange.endTime, Validators.required],
      }),
    });
    return notificationForm;
  }

  private initFormControlsToCreateNotification (): void {
    const initialForm = {
      title: '',
      message: '',
      status: this.selectedDropDownOptions.status.value,
      priority: this.selectedDropDownOptions.priority.value,
      target: this.selectedDropDownOptions.target.value,
      type: this.selectedDropDownOptions.type.value,
      isDismissable: this.selectedDropDownOptions.isDismissable.value,
      dateRange: {
        startDate: '',
        endDate: '',
      },
      timeRange: {
        startTime: '',
        endTime: '',
      },
    };
    this.notificationForm = this.buildForm(initialForm);
  }

  private initFormControlsFromNotification (notification: INotification): void {
    const { date: startDate, time: startTime } = this.getFormattedDateFromISOString(notification.startsOn);
    const { date: endDate, time: endTime } = this.getFormattedDateFromISOString(notification.endsOn);
    const form = {
      title: notification.title,
      message: notification.message,
      status: notification.status,
      priority: notification.priority,
      target: notification.target,
      type: notification.type,
      isDismissable: notification.isDismissable,
      dateRange: {
        startDate,
        endDate,
      },
      timeRange: {
        startTime,
        endTime,
      },
    };
    this.notificationForm = this.buildForm(form);
  }

  private getDropDownKeyByValue (optionName: string, optionValue: string | number | boolean): string {
    return this.dropDownOptions.options[optionName].find(option => option.value === optionValue).key;
  }

  private getFormattedDateRange (dateRange: { startDate: string; endDate: string }, timeRange: { startTime: string; endTime: string }): { startsOn: string, endsOn: string } {
    const { startDate, endDate } = dateRange;
    const { startTime, endTime } = timeRange;

    // convert date and time to ISO string
    const fortmat = 'YYYY-MM-DD h:mm A';
    const startsOn = moment(`${startDate} ${startTime}`, fortmat).toISOString();
    const endsOn = moment(`${endDate} ${endTime}`, fortmat).toISOString();

    return { startsOn, endsOn };
  }

  private getFormattedDateFromISOString (IsoDate: string): { date: string; time: string } {
    const fortmat = 'YYYY-MM-DDTh:mm A';
    const dateAndTime = moment(IsoDate).format(fortmat);
    const [date, time] = dateAndTime.split('T');
    return { date, time };
  }

  private getUpdatePatch (originalNotification, editedNotification) {
    const patch = {};
    Object.keys(editedNotification).forEach(key => {
      if (originalNotification[key] !== editedNotification[key]) {
        patch[key] = editedNotification[key];
      }
    });
    return patch;
  }

  private setDropdownOptions () : { options: INotificationDropdownOptions } {
    const options: INotificationDropdownOptions = {
      status: [
        { key: 'ACTIVE', value: 'ACTIVE', human: 'Active' },
        { key: 'NOT_ACTIVE', value: 'NOT_ACTIVE', human: 'Not Active' },
      ],
      priority: [
        { key: '1', value: 1, human: '1' },
        { key: '2', value: 2, human: '2' },
      ],
      target: [
        { key: 'ALL', value: 'ALL', human: 'All' },
        { key: 'ALL_SCHOOL', value: 'ALL_SCHOOL', human: 'All School' },
        { key: 'ALL_ADMIN', value: 'ALL_ADMIN', human: 'All Admin' },
      ],
      type: [
        { key: 'ALERT', value: 'ALERT', human: 'Alert' },
        { key: 'NOTIFICATION', value: 'NOTIFICATION', human: 'Notification' },
      ],
      isDismissable: [
        { key: 'true', value: true, human: 'Yes' },
        { key: 'false', value: false, human: 'No' },
      ],
    };
    return { options };
  }

  private setSelectedDropDownOptions (mode: string, notification?: INotification) {
    let selectedOptions: ISelectedDropdownOption;
    if (mode === 'Add') {
      const { options } = this.dropDownOptions;
      selectedOptions = {
        status: { key: options.status[0].key, value: options.status[0].value },
        priority: { key: options.priority[0].key, value: options.priority[0].value },
        target: { key: options.target[0].key, value: options.target[0].value },
        type: { key: options.type[0].key, value: options.type[0].value },
        isDismissable: { key: options.isDismissable[0].key, value: options.isDismissable[0].value },
      };
    } else {
      const {
        status: statusValue,
        priority: priorityValue,
        target: targetValue,
        type: typeValue,
        isDismissable: isDismissableValue,
      } = notification;
      const statusKey = this.getDropDownKeyByValue('status', statusValue);
      const targetKey = this.getDropDownKeyByValue('target', targetValue);
      const priorityKey = this.getDropDownKeyByValue('priority', priorityValue);
      const typeKey = this.getDropDownKeyByValue('type', typeValue);
      const isDismissableKey = this.getDropDownKeyByValue('isDismissable', isDismissableValue);

      selectedOptions = {
        status: { key: statusKey, value: statusValue },
        priority: { key: priorityKey, value: priorityValue },
        target: { key: targetKey, value: targetValue },
        type: { key: typeKey, value: typeValue },
        isDismissable: { key: isDismissableKey, value: isDismissableValue },
      };
    }
    return selectedOptions;
  }

  private prepPayloadForApi (): INotification {
    const { title, message, status, priority, target, type, isDismissable, dateRange, timeRange } = this.notificationForm.value;
    const { startsOn, endsOn } = this.getFormattedDateRange(dateRange, timeRange);

    const payload = {
      title,
      message,
      status,
      priority,
      target,
      type,
      isDismissable,
      startsOn,
      endsOn,
    };
    return payload;
  }

  private updateNotificationForm (notification: INotification): void {
    // update title and message
    this.notificationForm.controls.title.patchValue(notification.title);
    this.notificationForm.controls.message.patchValue(notification.message);

    // update date and time range
    const { date: startDate, time: startTime } = this.getFormattedDateFromISOString(notification.startsOn);
    const { date: endDate, time: endTime } = this.getFormattedDateFromISOString(notification.endsOn);
    this.notificationForm.controls.dateRange.controls.startDate.patchValue(startDate);
    this.notificationForm.controls.dateRange.controls.endDate.patchValue(endDate);
    this.notificationForm.controls.timeRange.controls.startTime.patchValue(startTime);
    this.notificationForm.controls.timeRange.controls.endTime.patchValue(endTime);

    // update dropdown values
    this.notificationForm.controls.status.patchValue(notification.status);
    this.notificationForm.controls.priority.patchValue(notification.priority);
    this.notificationForm.controls.target.patchValue(notification.target);
    this.notificationForm.controls.type.patchValue(notification.type);
    this.notificationForm.controls.isDismissable.patchValue(notification.isDismissable);
  }
}
