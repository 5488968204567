<div class="nv-wizard-table credit-gaps-generic-table">
    <div class="app-headline">
        <h3>
            Students with <span *ngIf="tableToggle === 'creditMaxGapsNoPlan'">Unaddressed</span> Credit Gaps by Planned
            Graduation Date
        </h3>
    </div>

    <div class="row">
        <div class="table-container">
            <table class="nv-table nv-table-dense">
                <thead>
                    <tr>
                        <th></th>
                        <th *ngFor="let header of tableHeaders">{{ header }}</th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let row of table | keyvalue: orderTableRows" [ngClass]="row.value.color">
                        <td class="mat-subtitle-2">
                            <p>{{row.value.human}}</p>
                        </td>
                        <td *ngFor="let header of tableHeaders"
                            [ngClass]="{ 'is-hovered': row.value.buckets[header].hover && row.value.buckets[header].length > 0 }"
                            (mouseenter)="row.value.buckets[header].hover=true"
                            (mouseleave)="row.value.buckets[header].hover=false">
                            <wizard-filter-actions [data]="row.value.buckets[header]" [options]="options">
                            </wizard-filter-actions>
                        </td>
                    </tr>

                    <tr>
                        <td class="mat-subtitle-2">
                            Total
                        </td>
                        <td *ngFor="let header of tableHeaders"
                            [ngClass]="{ 'is-hovered': totalsData[header].hover && totalsData[header].length > 0 }"
                            (mouseenter)="totalsData[header].hover=true" (mouseleave)="totalsData[header].hover=false">
                            <wizard-filter-actions [data]="totalsData[header]" [options]="options">
                            </wizard-filter-actions>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="summary-container summary-layout">
            <div class="wizard-table-summary">
                <div class="summary">
                    <span class="count linked">
                        <wizard-filter-actions
                            [data]="tableToggle === 'creditMaxGapsNoPlan' ? studentWithUnaddressedGaps : studentWithGaps"
                            [options]="options"></wizard-filter-actions>
                    </span>
                    <div class="description">
                        <ng-container
                            [ngPlural]="tableToggle === 'creditMaxGapsNoPlan' ? studentWithUnaddressedGaps.length : studentWithGaps.length">
                            <ng-template ngPluralCase="1"> Student </ng-template>
                            <ng-template ngPluralCase="few"> Students </ng-template>
                            <ng-template ngPluralCase="other"> Students </ng-template>
                        </ng-container>
                        with <span *ngIf="tableToggle === 'creditMaxGapsNoPlan'">Unaddressed</span> Credit Gaps
                    </div>
                </div>
            </div>

            <mat-radio-group class="nv-mat-radio-group" [(ngModel)]="tableToggle" (change)="updateTable()">
                <mat-radio-button value="creditMaxGaps">
                    <span class="mat-caption">View by students with credit gaps</span>
                </mat-radio-button>

                <mat-radio-button value="creditMaxGapsNoPlan">
                    <span class="mat-caption">View by students with unaddressed credit gaps</span>
                </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
</div>