import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RouteGuard } from 'Src/ng2/routing/guards/route-guard/route-guard';
import { USER_ROLE_PERMISSIONS_FOR_GUARDS } from 'Src/ng2/routing/guards/user-role-permissions-for-route-guards.constant';
import { WizardFilterResolver } from 'Src/ng2/routing/resolvers/sdc/wizard-filter/wizard-filter.resolver';
import { CreditGapsWizardContainer } from './credit-gaps-wizard-container.component';

const routes = [
  {
    path: '',
    partnerType: 'school',
    authenticationRequired: true,
    component: CreditGapsWizardContainer,
    rolePermissions: USER_ROLE_PERMISSIONS_FOR_GUARDS['school/:schoolId/other-tools/credit-gaps/wizard'],
    canActivate: [RouteGuard],
    data: {
      sdcKey: 'credit-gaps',
    },
    resolve: {
      filter: WizardFilterResolver,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CreditGapsWizardContainerRoutingModule {}
