import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { each, union } from 'lodash';
import { GraduationPlan } from 'Src/ng2/shared/constants/graduation-plan.constant';
import { CurrentSchoolYear } from 'Src/ng2/shared/constants/current-school-year.constant';
import { ActivatedRoute } from '@angular/router';
import { WizardHelpers } from 'Src/ng2/school/sdc/services/wizard-helpers.service';
import { UrlPathService } from 'Src/ng2/shared/services/url-path-service/url-path.service';

export interface IStudent {
  studentId: string;
  gradPlan: string;
  cohort: string;
  status: string;
  isSuperSenior: boolean;
}
@Component({
  selector: 'grad-plans-step',
  templateUrl: './grad-planning-step-component.html',
  encapsulation: ViewEncapsulation.None,
})

export class GradPlanningStep implements OnInit {
  @Input() filteredStudents: IStudent[];
  @Input() currentFilter: any;

  public currentLabel: string;
  public sort: object;
  public buckets: any;
  public activeStudentsIds: string[];
  public activeStudentsWithIncompleteGradPlansIds: string[];
  public activeSuperSeniorsIds: string[];
  public activeSuperSeniorsWithIncompleteGradPlansIds: string[];
  public countActiveStudents: number;
  public countActiveStudentsWithIncompleteGradPlans: number;
  public countActiveSuperSeniors: number;
  public countActiveSuperSeniorsWithIncompleteGradPlans: number;
  public studentsToReview: string[];
  public countStudentsToReview: number;
  public columnKeys: string[];

  public options = {
    columnKeys: ['gradPlan', 'creditMaxGaps', 'creditMaxGapsNoPlan'],
  };

  public stepLabel: string = 'Complete graduation planning for all students';

  constructor (
    private wizardHelpers: WizardHelpers,
    private activatedRoute: ActivatedRoute,
    private urlPathService: UrlPathService,
  ) { }

  ngOnInit (): void {
    this.setUi();
  }

  ngOnChanges ({ currentFilter, filteredStudents }): void {
    if (!currentFilter.firstChange || !filteredStudents.firstChange) {
      this.setUi();
    }
  }

  setUi (): void {
    this.currentLabel = this.getLabel();

    this.buckets = this.generateFilteredStudentIdBuckets();

    this.activeStudentsIds = this.buckets.activeStudentsIds;
    this.activeStudentsWithIncompleteGradPlansIds = this.buckets.activeStudentsWithIncompleteGradPlansIds;

    this.activeSuperSeniorsIds = this.buckets.activeSuperSeniorsIds;
    this.activeSuperSeniorsWithIncompleteGradPlansIds = this.buckets.activeSuperSeniorsWithIncompleteGradPlansIds;

    this.countActiveStudents = this.activeStudentsIds.length;
    this.countActiveStudentsWithIncompleteGradPlans = this.activeStudentsWithIncompleteGradPlansIds.length;
    this.countActiveSuperSeniors = this.activeSuperSeniorsIds.length;
    this.countActiveSuperSeniorsWithIncompleteGradPlans = this.activeSuperSeniorsWithIncompleteGradPlansIds.length;

    this.studentsToReview = union(
      this.activeStudentsWithIncompleteGradPlansIds,
      this.activeSuperSeniorsWithIncompleteGradPlansIds,
    );
    this.countStudentsToReview = this.studentsToReview.length;

    this.sort = { sort: { colId: 'studentName', sort: 'asc' } };
  }

  public hasSuperSeniors (): boolean {
    return this.currentFilter && this.currentFilter === CurrentSchoolYear.ENDFULL;
  }

  public navigateToGrid (): void {
    const { schoolId } = this.activatedRoute.snapshot.params;
    const url = this.urlPathService.computeDistrictUrlPath(`/school/${schoolId}/${this.activatedRoute.snapshot.data.sdcKey}/grid`);
    this.wizardHelpers.navigateToView({ url, cacheData: { studentId: this.studentsToReview } });
  }

  private getLabel (): string {
    const status = this.hasSuperSeniors();
    return status === true ? `${this.currentFilter} and older` : this.currentFilter;
  }

  private generateFilteredStudentIdBuckets (): object {
    const activeStudentsIds = [];
    const activeStudentsWithIncompleteGradPlansIds = [];
    const activeSuperSeniorsIds = [];
    const activeSuperSeniorsWithIncompleteGradPlansIds = [];

    each(this.filteredStudents, student => {
      const { isSuperSenior, studentId, gradPlan, cohort, status } = student;
      // If `All` is selected from dropdown, all students fall `inCohort`
      const inCohort = this.currentFilter === 'All' || cohort === this.currentFilter;
      const isActive = status === 'A';
      const gradPlanIsIncomplete = gradPlan === GraduationPlan.PLAN_INCOMPLETE.humanName;
      const gradPlanIsInPast = gradPlan === GraduationPlan.PLAN_IN_PAST.humanName;

      if (isActive && inCohort) activeStudentsIds.push(studentId);
      if (isActive && inCohort && (gradPlanIsIncomplete || gradPlanIsInPast)) {
        activeStudentsWithIncompleteGradPlansIds.push(studentId);
      }
      if (isActive && isSuperSenior) activeSuperSeniorsIds.push(studentId);
      if (isActive && isSuperSenior && (gradPlanIsIncomplete || gradPlanIsInPast)) {
        activeSuperSeniorsWithIncompleteGradPlansIds.push(studentId);
      }
    });

    return {
      activeStudentsIds,
      activeStudentsWithIncompleteGradPlansIds,
      activeSuperSeniorsIds,
      activeSuperSeniorsWithIncompleteGradPlansIds,
    };
  }
}
