import { LoadTools } from './../../../../store/actions/tools-actions';
import { IRouteConfigsOpts } from './../../../../routing/route.config';
import { SHEET_TYPE_TO_ROUTE, ROUTE_TO_HUMAN, HUMAN_TO_ROUTE, IOtherToolsNavItem, SHEET_TYPE_HUMAN_MAP, IOtherToolsNavGroup, IOtherToolsSideNav } from './../other-tools.constant';
import { RouteActivationService } from './../../../../routing/services/route-activation.service';
import { ActivatedRouteSnapshot } from '@angular/router';
import { getSchool } from './../../../../store/selectors/school-selectors';
import { LoadDashboardStudents } from './../../../../store/actions/dashboard-actions';
import { getDashboardStudentsLoadedStatus, getDashboardStudentsList } from './../../../../store/selectors/dashboard-selectors';
import { IStudent } from '../../../../shared/typings/interfaces/student.interface';
import { ISchool } from '../../../../shared/typings/interfaces/school.interface';
import { IUser } from '../../../../shared/typings/interfaces/user.interface';
import { ITool } from '../../../../shared/typings/interfaces/tool.interface';
import { ISchoolAssessment } from '../../../../shared/typings/interfaces/school-assessment.interface';
import { StoreDataService } from './../../../../shared/services/store-data-service/store-data.service';
import { LoadSchoolAssessments } from './../../../../store/actions/assessments-actions/school-assessments-actions';
import { getSchoolAssessmentsEntitiesList, getSchoolAssessmentsLoadedStatus } from './../../../../store/selectors/assessments-selectors/school-assessments-selectors';
import { getToolsEntitiesList, getToolsLoadedStatus } from './../../../../store/selectors/tools-selectors';
import { getCurrentUser } from './../../../../store/selectors/current-user-selectors';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { groupBy, sortBy, valuesIn } from 'lodash';
import { ISidebarItem } from 'Src/nvps-libraries/design/nv-sidebar-list/nv-sidebar.interface';
import { otherToolsResourcesRoutes } from '../other-tools-resources/other-tools-resources-routing.module';
import { nextTermPlanningRoutes } from 'Src/ng2/school/sdc/next-term-planning/next-term-planning-routing.module';
import { courseDiffsAndGapPlansRoutes } from 'Src/ng2/school/sdc/course-diffs-and-gap-plans/course-diffs-and-gap-plans-routing.module';
import { otherToolsRoutes as otherToolsSheetsRoutes } from './../other-tools-routing.module';
import { routes as gradPlansRoutes } from 'Src/ng2/school/sdc/grad-planning/grad-planning-container-routing.module';
import { routes as regentsPrepRoutes } from '../../../../school/sdc/regents-prep/regents-prep-container-routing.module';
import { routes as regentsPlanningRoutes } from '../../../../school/sdc/regents-planning/regents-planning-container-routing.module';
import { routes as creditGapsRoutes } from '../../../../school/sdc/credit-gaps/credit-gaps-container-routing.module';
import { routes as formativeAssessmentRoutes } from '../../../../school/lists/formative-assessments-list-v2/formative-assessments-list-v2-routing.module';

export interface IOtherToolsRoutesConfigs {
  otherToolsSheetsRoutes: IRouteConfigsOpts[];
  otherToolsResourcesRoutes: IRouteConfigsOpts[];
  gradPlansRoutes: IRouteConfigsOpts[];
  nextTermPlanningRoutes: IRouteConfigsOpts[];
  courseDiffsAndGapPlansRoutes: IRouteConfigsOpts[];
  regentsPrepRoutes: IRouteConfigsOpts[];
  regentsPlanningRoutes: IRouteConfigsOpts[];
  creditGapsRoutes: IRouteConfigsOpts[];
  formativeAssessmentRoutes: IRouteConfigsOpts[];
};

@Injectable()
export class OtherToolsDataService {
  constructor (
    private storeDataService: StoreDataService,
    private routeActivationService: RouteActivationService,
  ) {}

  protected OtherToolsRoutesConfigs: IOtherToolsRoutesConfigs = {
    otherToolsSheetsRoutes,
    otherToolsResourcesRoutes,
    gradPlansRoutes,
    nextTermPlanningRoutes,
    courseDiffsAndGapPlansRoutes,
    regentsPrepRoutes,
    regentsPlanningRoutes,
    creditGapsRoutes,
    formativeAssessmentRoutes,
  };

  getSchoolAssessments$ (schoolId: string): Observable<ISchoolAssessment[]> {
    const payload = { schoolId };
    return this.storeDataService.loadDataToStore$(payload, getSchoolAssessmentsLoadedStatus, LoadSchoolAssessments, getSchoolAssessmentsEntitiesList);
  }

  getTools$ (schoolId: string): Observable<ITool[]> {
    const payload = { schoolId };
    return this.storeDataService.loadDataAndGetStream$(payload, getToolsLoadedStatus, LoadTools, getToolsEntitiesList);
  }

  getCurrentUser$ (): Observable<IUser> {
    return this.storeDataService.getDataFromStore$(getCurrentUser);
  }

  getSchool$ (): Observable<ISchool> {
    return this.storeDataService.getDataFromStore$(getSchool);
  }

  getStudents$ (schoolId: string): Observable<IStudent[]> {
    const payload = { schoolId };
    return this.storeDataService.loadDataToStore$(payload, getDashboardStudentsLoadedStatus, LoadDashboardStudents, getDashboardStudentsList);
  }

  async canNavigate (routes: IOtherToolsRoutesConfigs, view: string, activatedRoute: ActivatedRouteSnapshot): Promise<boolean> {
    const routeConfig: IRouteConfigsOpts = this.getRouteConfig(routes, view);
    routeConfig.path = routeConfig.path || HUMAN_TO_ROUTE[view];
    return !routeConfig ? false : await this.routeActivationService.canActivateRouteConfig(routeConfig, activatedRoute);
  }

  private getRouteConfig (routes: IOtherToolsRoutesConfigs, view: string): IRouteConfigsOpts {
    let routeConfig: IRouteConfigsOpts;

    if (SHEET_TYPE_TO_ROUTE[view]) {
      routeConfig = routes.otherToolsSheetsRoutes.find(({ path }) => path.includes(SHEET_TYPE_TO_ROUTE[view]));
    } else {
      switch (view) {
        case ROUTE_TO_HUMAN['grad-planning']:
          [routeConfig] = routes.gradPlansRoutes;
          break;
        case ROUTE_TO_HUMAN['next-term-planning']:
          [routeConfig] = routes.nextTermPlanningRoutes;
          break;
        case ROUTE_TO_HUMAN['program-changes-and-gap-plans']:
          [routeConfig] = routes.courseDiffsAndGapPlansRoutes;
          break;
        case ROUTE_TO_HUMAN.resources:
          [routeConfig] = routes.otherToolsResourcesRoutes;
          break;
        case ROUTE_TO_HUMAN['regents-prep']:
          [routeConfig] = routes.regentsPrepRoutes;
          break;
        case ROUTE_TO_HUMAN['regents-planning']:
          [routeConfig] = routes.regentsPlanningRoutes;
          break;
        case ROUTE_TO_HUMAN['credit-gaps']:
          [routeConfig] = routes.creditGapsRoutes;
          break;
        case ROUTE_TO_HUMAN['formative-assessments']:
          [routeConfig] = routes.formativeAssessmentRoutes;
          break;
        default:
          break;
      }
    }
    return routeConfig ? { ...routeConfig } : null;
  }

  public async getSideNav (opts: {
    OtherToolsSideNavConfig: IOtherToolsSideNav,
    tools: ITool[],
    activatedRoute: ActivatedRouteSnapshot,
  }): Promise<{ [key: string]: ISidebarItem[] }> {
    const { OtherToolsSideNavConfig, tools, activatedRoute } = opts;
    const sheetsMenu = await this.validateAndGenerateMenu({
      navGroup: OtherToolsSideNavConfig.sheets,
      OtherToolsRoutesConfigs: this.OtherToolsRoutesConfigs,
      activatedRoute,
    });
    const availableSheetsMenu = this.filterAvailableSheetsMenu(tools, sheetsMenu);
    const toolsMenu = await this.validateAndGenerateMenu({
      navGroup: OtherToolsSideNavConfig.tools,
      OtherToolsRoutesConfigs: this.OtherToolsRoutesConfigs,
      activatedRoute,
    });
    return { sheetsMenu: availableSheetsMenu, toolsMenu };
  }

  private async validateAndGenerateMenu (opts: {
    navGroup: IOtherToolsNavGroup,
    OtherToolsRoutesConfigs: IOtherToolsRoutesConfigs,
    activatedRoute: ActivatedRouteSnapshot,
  }): Promise<ISidebarItem[]> {
    const { navGroup, OtherToolsRoutesConfigs, activatedRoute } = opts;
    const validViews = [];
    const items: IOtherToolsNavItem[] = sortBy(valuesIn(navGroup), 'order');
    for (const item of items) {
      const humanName = item.humanName;
      const isActive = item.isActive;
      const canNavigate = await this.canNavigate(OtherToolsRoutesConfigs, humanName, activatedRoute);
      if (isActive && canNavigate) validViews.push(item);
    }
    const menus = validViews.map(({ humanName }) => this.convertInternalViewToISidebarItem(humanName));
    return menus;
  }

  private filterAvailableSheetsMenu (sheets: ITool[], sheetsMenu: ISidebarItem[]): ISidebarItem[] {
    const groupedSheets = groupBy(sheets, (tool: ITool) => tool.metaData.sheetToolType);
    const filtered = sheetsMenu.filter(({ human }) => {
      const matchedSheetType = SHEET_TYPE_HUMAN_MAP[human];
      const sheetsExist = groupedSheets[matchedSheetType];
      return sheetsExist;
    });
    return filtered;
  }

  private convertInternalViewToISidebarItem (label: string): ISidebarItem {
    return {
      human: label,
      key: label,
    };
  }
}
