import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { StudentListPreviewV2 } from './student-list-preview-v2.component';
import { MatDialogModule } from '@angular/material/dialog';
import { NgModule } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';

@NgModule({
  imports: [
    MatDialogModule,
    MatToolbarModule,
    MatDividerModule,
    CommonModule,
    MatButtonModule,
  ],
  declarations: [
    StudentListPreviewV2,
  ],
  exports: [
    StudentListPreviewV2,
  ],
})
export class StudentListPreviewV2Module { }
