import { Component, ElementRef, forwardRef, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Toggles } from 'Src/ng2/shared/constants/toggles.constant';
import { SnackBarService } from 'Src/ng2/shared/services/snackbar/snackbar.service';
import { ToggleService } from 'Src/ng2/shared/services/toggle/toggle.service';
import { UserManagementSharedService } from 'Src/ng2/shared/services/user-management/user-management.shared.service';
import { IClusterUserPermissions } from 'Src/ng2/shared/typings/interfaces/user.interface';
import { unsubscribeComponent } from '../../../../../helpers/unsubscribe-decorator/unsubscribe-decorators.helper';
import { IGroupData, IRowData } from '../../../../../models/list-models';
import { ApiService } from '../../../../../services/api-service/api-service';
import { HelpDeskService } from '../../../../../services/help-desk/help-desk.service';
import { IDropdownOption } from '../../../../../../../../projects/shared/nvps-libraries/design/interfaces/design-library.interface';
import { MODALS_CONFIG_COMMON_MARKUP, MODALS_CONFIG_WIDE_NO_PADDING } from '../../../../modals.config';
import { ShelterClusterUserModalShellService } from '../shelter-cluster-user-modal-shell.service';
import { ShelterClusterUserModalsDataService } from '../shelter-cluster-user-modal.data.service';
import {
  IShelterClusterUserModalShellData,
  SHELTER_CLUSTER_PORTFOLIO_LIST_COMPONENT_CONFIG,
  SHELTER_CLUSTER_USER_BTN_CONFIG,
  TShelterClusterUserModalViewMode,
} from '../shelter-cluster-user-modals.config';

@Component({
  templateUrl: './shelter-cluster-user-modal-shell.component.html',
  styleUrls: ['./shelter-cluster-user-modal-shell.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
@unsubscribeComponent
export class ShelterClusterUserModalShellComponent implements OnInit {
  @ViewChild('warningContent', { static: false }) warningContentEl: ElementRef;

  // FOR TEMPLATE RENDERING AND INTERPOLATION
  public clusterDetail$: BehaviorSubject<any> = new BehaviorSubject(null);
  public portfolioGroupings$: BehaviorSubject<null | IGroupData[]> = new BehaviorSubject(null);
  public needsHelpLink: boolean;
  public emailDupesErrMsg: string;
  public generalErrMsg: string;
  public warningContent: string;
  public title: 'Create User' | 'Edit User';
  public iconName: string;
  public primaryBtnName: string;
  accessLevels: IDropdownOption[] = [
    {
      key: 'shelter_only',
      human: 'Yes',
    },
    {
      key: 'no_access',
      human: 'No',
    },
  ];

  public selectedAccessLevelKey = 'shelter_only';
  public originalUserAccessLevelKey: string;
  public userBasicForm: FormGroup;
  public reqEmailPlaceholder: string;
  public reqEmailType: string;
  public optEmailPlaceholder: string | null = null;
  public optEmailType: string | null = null;
  public v4ModeIsOn: boolean;

  // PASSDOWN TO CHILD
  user: {
    name: string;
    id: string | null;
  };

  public portfolioSeconIcon: 'close-large-blue' | 'arrow-left-default';
  portfolioPrimaryBtn: string;

  // NOT FOR VIEW - LOCAL STATE MAINTAINING AND MANAGEMENT
  private prevViewMode: TShelterClusterUserModalViewMode;
  private emailDupes: string[] = [];
  private currentGrouping: IGroupData[]; // keep track of cluster user schools groupings, api related, shape of list row data
  private permissions: IClusterUserPermissions; // keep track of shelter portfolio permissions, api related, shape of portfolio schema
  private apiMode: TShelterClusterUserModalViewMode;

  // CONSTANTS
  readonly CUSTOM_ERRS = {
    'User already exists in this shelter network.': { needsSupport: false },
    'User already exists in another shelter network.': { needsSupport: true },
    'User already exists as a shelter user.': { needsSupport: true },
  };

  readonly commonMarkupClass = MODALS_CONFIG_COMMON_MARKUP.panelClass;
  readonly noPaddingWideClass = MODALS_CONFIG_WIDE_NO_PADDING.panelClass;
  private helpDeskService: HelpDeskService;
  readonly portfolioListComponentConfig = SHELTER_CLUSTER_PORTFOLIO_LIST_COMPONENT_CONFIG;

  constructor (
    @Inject(MAT_DIALOG_DATA) public data: IShelterClusterUserModalShellData,
    @Inject(forwardRef(() => HelpDeskService)) helpDeskService: HelpDeskService,
    private apiService: ApiService,
    private shellService: ShelterClusterUserModalShellService,
    private snackBarService: SnackBarService,
    private userMgmtSharedService: UserManagementSharedService,
    public dataService: ShelterClusterUserModalsDataService,
    public dialogRef: MatDialogRef<ShelterClusterUserModalShellComponent>,
    private toggleService: ToggleService,
    private elementRef: ElementRef,
  ) {
    this.helpDeskService = helpDeskService;
  }

  ngOnInit (): void {
    this.toggleV4NewSkinMode();
    switch (this.data.mode) {
      case 'CREATE':
        this.getShelterClusterDetail();
        break;
      case 'EDIT':
        this.getShelterClusterUserDetail();
        break;
      case 'PORTFOLIO':
        this.getShelterPortfolioGroupings();
        break;
      default:
        break;
    }
  }

  private toggleV4NewSkinMode () : void {
    this.v4ModeIsOn = this.toggleService.getToggleState(Toggles.TOGGLE_V4_NEW_SKIN_MODE);
    if (this.v4ModeIsOn) {
      this.elementRef.nativeElement.classList.add('v4');
    }
  }

  private getShelterClusterDetail (): void {
    const params = {
      type: 'shelter',
      clusterId: this.data.clusterId,
    };
    const projections = ['approvedDomains', 'loginMethod'];
    this.apiService
      .getCluster({ params, projections })
      .pipe(
        tap((res: any) => {
          const { approvedDomains, loginMethod } = res;
          const clusterDetail = {
            loginMethod,
            approvedDomains,
          };
          this.initViewForBasicModal({ clusterDetail });
          this.clusterDetail$.next(clusterDetail);
        }),
      )
      .subscribe();
  }

  private getShelterClusterUserDetail (): void {
    const userId = this.data.user ? this.data.user.id : null;
    this.dataService
      .getShelterClusterUserDetail({ userId })
      .pipe(
        tap((res: any) => {
          const {
            data: {
              ShelterClusterUserDetail: { userBasic, approvedDomains, loginMethod },
            },
          } = res;
          const clusterDetail = {
            loginMethod,
            approvedDomains,
          };
          this.initViewForBasicModal({ clusterDetail, userBasic });
          this.clusterDetail$.next(clusterDetail);
        }),
      )
      .subscribe();
  }

  private initViewForBasicModal ({ clusterDetail, userBasic = null }): void {
    switch (this.data.mode) {
      case 'CREATE':
        this.title = 'Create User';
        this.primaryBtnName = SHELTER_CLUSTER_USER_BTN_CONFIG.CREATE;
        this.iconName = 'close-large-blue';
        this.apiMode = 'CREATE';
        break;
      case 'EDIT':
        this.title = 'Edit User';
        this.primaryBtnName = SHELTER_CLUSTER_USER_BTN_CONFIG.EDIT;
        this.iconName = 'close-large-blue';
        this.apiMode = 'EDIT';
        break;
      default:
        break;
    }
    this.initFormControls({ clusterDetail, userBasic });
    this.selectedAccessLevelKey = this._getAccessLevelDropdown({ userBasic });
    this.originalUserAccessLevelKey = this.selectedAccessLevelKey;
  }

  private getShelterPortfolioGroupings (): void {
    const userId = this.data.user ? this.data.user.id : null;
    const clusterId = this.isMultiMode() && this.fromCreateMode() ? this.data.clusterId : null;
    this.dataService
      .getShelterPortfolioGroupings({ userId, clusterId })
      .pipe(
        tap((res: any) => {
          const {
            data: { ClusterUserShelters: groupings },
          } = res;
          this.initViewForPortfolioModal();
          this.portfolioGroupings$.next(groupings);
        }),
      )
      .subscribe();
  }

  private isMultiMode (): boolean {
    return this.prevViewMode && this.prevViewMode !== this.data.mode;
  }

  private fromCreateMode (): boolean {
    return this.prevViewMode && this.prevViewMode === 'CREATE';
  }

  private initViewForPortfolioModal (): void {
    if (this.isMultiMode()) {
      if (this.fromCreateMode()) {
        this.portfolioPrimaryBtn = SHELTER_CLUSTER_USER_BTN_CONFIG.CREATE_LONG;
        const firstNameFormControl = this.userBasicForm.controls.firstName;
        const lastNameFormControl = this.userBasicForm.controls.lastName;
        this.user = {
          name: this.shellService.getUserFullName({ firstNameFormControl, lastNameFormControl }),
          id: null,
        };
      } else {
        this.portfolioPrimaryBtn = SHELTER_CLUSTER_USER_BTN_CONFIG.EDIT_LONG;
        this.user = this.data.user;
      }
      this.portfolioSeconIcon = 'arrow-left-default';
    } else {
      this.portfolioSeconIcon = 'close-large-blue';
      this.portfolioPrimaryBtn = SHELTER_CLUSTER_USER_BTN_CONFIG.EDIT_LONG;
      this.user = this.data.user;
      this.apiMode = 'EDIT';
    }
  }

  private initFormControls ({ clusterDetail, userBasic }): void {
    this.userBasicForm = new FormGroup({});
    const { firstNameControl, lastNameControl, titleControl } = this.shellService.getNonEmailFormControls({
      userBasic,
    });
    const emailDupes = this.emailDupes;
    this.userBasicForm.registerControl('firstName', firstNameControl);
    this.userBasicForm.registerControl('lastName', lastNameControl);
    this.userBasicForm.registerControl('title', titleControl);
    const {
      requireEmailControl: { emailType: reqEmailType, placeholder: reqEmailPlaceholder, formControl: reqEmailControl },
      optionalEmailControl,
    } = this.shellService.getEmailFormControls({ userBasic, clusterDetail, emailDupes });
    this.reqEmailType = reqEmailType;
    this.reqEmailPlaceholder = reqEmailPlaceholder;
    this.userBasicForm.registerControl(reqEmailType, reqEmailControl);
    if (optionalEmailControl) {
      const {
        emailType: optEmailType,
        placeholder: optEmailPlaceholder,
        formControl: optEmailControl,
      } = optionalEmailControl;
      this.optEmailType = optEmailType;
      this.optEmailPlaceholder = optEmailPlaceholder;
      this.userBasicForm.registerControl(optEmailType, optEmailControl);
    }
  }

  private _getAccessLevelDropdown ({ userBasic }): string {
    // if it is create mode, userBasic is not defined. set it to 'no_access' by default.
    // if it is edit mode, userBasic is defined, if delegatedRoleShelter is 'null', set to 'no_access'
    return (userBasic && userBasic.delegatedRoleShelter) || 'no_access';
  }

  // EVENT HANDLERS
  public onSelectAccessLevel ($key: string): void {
    if (this.userBasicForm.pristine) {
      this.userBasicForm.markAsDirty();
    }
    this.selectedAccessLevelKey = $key;
    this._setWarningContent();
    if ($key === 'shelter_only') {
      this.primaryBtnName = SHELTER_CLUSTER_USER_BTN_CONFIG.NEXT;
    } else {
      this.primaryBtnName =
        this.data.mode === 'CREATE' ? SHELTER_CLUSTER_USER_BTN_CONFIG.CREATE : SHELTER_CLUSTER_USER_BTN_CONFIG.EDIT;
    }
  }

  public onClickPrimaryBtn (): void {
    switch (this.primaryBtnName) {
      case SHELTER_CLUSTER_USER_BTN_CONFIG.CREATE:
      case SHELTER_CLUSTER_USER_BTN_CONFIG.EDIT:
        this._prepPayloadForApi();
        break;
      case SHELTER_CLUSTER_USER_BTN_CONFIG.NEXT:
        this.prevViewMode = this.data.mode;
        this.data.mode = 'PORTFOLIO';
        this.dialogRef.removePanelClass(this.commonMarkupClass);
        this.dialogRef.addPanelClass(this.noPaddingWideClass);
        if (this.currentGrouping) {
          this.portfolioGroupings$.next(this.currentGrouping);
        } else {
          this.getShelterPortfolioGroupings();
        }
        break;
      default:
        break;
    }
  }

  public onPortfolioPrimaryBtn ({ permissions }): void {
    this.permissions = permissions;
    this._prepPayloadForApi();
  }

  public onPortfolioSecondaryBtn ({ currentGrouping }): void {
    if (this.isMultiMode()) {
      this._goToDetailsView();
      this.currentGrouping = currentGrouping;
    } else {
      this.dialogRef.close();
    }
  }

  private _goToDetailsView (): void {
    this.data.mode = this.prevViewMode;
    this.dialogRef.removePanelClass(this.noPaddingWideClass);
    this.dialogRef.addPanelClass(this.commonMarkupClass);
  }

  public onCancel (): void {
    this.dialogRef.close();
  }

  public onClearInput (controlName: string): void {
    this.userBasicForm.controls[controlName].setValue('');
    this.userBasicForm.controls[controlName].markAsDirty();
  }

  public getHelp (): void {
    this.helpDeskService.showHelp();
  }

  // API CALLS
  private _prepPayloadForApi (): void {
    let userPayload = {};
    if (this.userBasicForm) {
      userPayload = {
        ...this.userBasicForm.value,
        delegatedRoleShelter: this.selectedAccessLevelKey,
      };
    }
    userPayload = { ...userPayload, permissions: this.permissions };
    if (this.data.user) {
      const userId = this.data.user.id;
      userPayload = { ...userPayload, userId };
    }
    if (
      this.primaryBtnName === SHELTER_CLUSTER_USER_BTN_CONFIG.CREATE ||
      this.portfolioPrimaryBtn === SHELTER_CLUSTER_USER_BTN_CONFIG.CREATE_LONG
    ) {
      userPayload = { ...userPayload, clusterId: this.data.clusterId };
    }
    this._sendToApi({ userPayload });
  }

  private _sendToApi ({ userPayload }): void {
    this.apiService
      .mutateShelterClusterUser({ userPayload, columns: this.data.shelterClusterUserGroupingColumns }, this.apiMode)
      .pipe(
        catchError(error => {
          this.snackBarService.showDangerToastWithCloseButton({
            toastText: 'Unable to update the user’s portfolio. Please try again.',
            isDanger: true,
          });
          return throwError(error);
        }),
        tap((res: { data: { [mutationName: string]: IRowData[][] }; errors: any[] }) => {
          this._onApiRes(res);
        }),
      )
      .subscribe();
  }

  private _onApiRes (res: { data: { [mutationName: string]: IRowData[][] }; errors: any[] }): void {
    const { data, errors } = res;
    if (errors && errors[0]) {
      const {
        extensions: {
          exception: { data },
        },
        message: errMessage,
      } = errors[0];
      this._resetErrMessages();
      const isDupesErr = !!this.CUSTOM_ERRS[errMessage];
      if (isDupesErr) {
        this._setDupesErr(errMessage, data.devMessage);
      } else {
        this.generalErrMsg = errMessage;
      }
      if (this.isMultiMode()) {
        this._goToDetailsView();
      }
    } else if (data && Object.keys(data).length) {
      const groupData: IRowData[][] = data.createShelterClusterUser || data.updateShelterClusterUser; // CM UPDATE
      this.dialogRef.close(groupData);
    }
  }

  private _resetErrMessages (): void {
    this.generalErrMsg = '';
    this.emailDupesErrMsg = '';
  }

  private _setDupesErr (errMessage, devMessage): void {
    this.needsHelpLink = this.CUSTOM_ERRS[errMessage].needsSupport;
    this.emailDupesErrMsg = errMessage;
    const emailType = this._getEmailTypeForDupesErr(devMessage);
    this.userBasicForm.controls[emailType].setErrors({ dupes: true });
    this.emailDupes.push(this.userBasicForm.controls[emailType].value.trim().toLowerCase());
  }

  private _getEmailTypeForDupesErr (devMessage): string {
    let emailType = '';
    if (devMessage.match('dhsEmail')) {
      emailType = 'dhsEmail';
    }
    if (devMessage.match('gafeEmail')) {
      emailType = 'gafeEmail';
    }
    if (devMessage.match('doeEmail')) {
      emailType = 'doeEmail';
    }
    return emailType;
  }

  private _setWarningContent (): void {
    const settingUserToNoAccess =
      this.originalUserAccessLevelKey !== this.selectedAccessLevelKey && this.selectedAccessLevelKey === 'no_access';
    const warningContent = settingUserToNoAccess
      ? this.userMgmtSharedService.getHybridClusterUserWarningContent({
        // .schoolClusterAdmins will be null if the target user is not hybrid cluster type
        admins: this.data.hybridClusterUserContext.schoolClusterAdmins,
        contextPartnerType: 'shelter',
        mode: 'EDIT',
      })
      : '';
    this.warningContent = warningContent;
  }
}
