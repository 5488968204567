import { IPatchEvent, WizardHelpers } from '../../../../services/wizard-helpers.service';
import { Component, Input, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { reduce } from 'lodash';

@Component({
  selector: 'readiness-ela-step',
  templateUrl: './readiness-ela-step.component.html',
  styleUrls: ['../shared-step-styles.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReadinessElaStep implements OnInit {
  @Input() filteredStudents; // modified students
  @Output() studentPatch: EventEmitter<any> = new EventEmitter();
  public stepLabel: string = 'Plan Regents exams for students who could resit for CR in ELA';

  public collegeReadinessElaStudentData;
  public totalStudentsWithReadinessMismatch: number;
  public options;
  public patches;

  constructor (private wizardHelpers: WizardHelpers) {}

  ngOnInit (): void {
    this.collegeReadinessElaStudentData = this.getCollegeReadinessElaStudentData();
    this.totalStudentsWithReadinessMismatch = this.wizardHelpers.getCountOfStudentsToBePatched(
      this.collegeReadinessElaStudentData,
    );
    this.patches = this.wizardHelpers.getRegentsExamBulkPatch(this.collegeReadinessElaStudentData, 'humanName');
    this.options = {
      columnKeys: [
        'regentsNeededForCollegeReadiness',
        'maxScoreEnglishExams',
        'collegeReadyOnElaRegentsOrSatCr',
        'regentsNeededForOnTrackDiploma',
        'regentsSchedForNextAdmin',
      ],
    };
  }

  ngOnChanges ({ filteredStudents }) {
    if (filteredStudents && !filteredStudents.isFirstChange()) {
      this.ngOnInit();
    }
  }

  private getCollegeReadinessElaStudentData () {
    const baseObj = { 'CC ELA': { _ids: [], studentIds: [] } };
    return reduce(
      this.filteredStudents,
      (studentRecRatCollection, { _id, studentId, isRegentsRecRatCollegeReadinessMismatchedEla }) => {
        if (isRegentsRecRatCollegeReadinessMismatchedEla) {
          studentRecRatCollection['CC ELA']._ids.push(_id);
          studentRecRatCollection['CC ELA'].studentIds.push(studentId);
        }
        return studentRecRatCollection;
      },
      baseObj,
    );
  }

  patchStudents (patches, count) {
    const patchEvent: IPatchEvent = {
      message: `This will bulk schedule ${count} exams`,
      patches,
    };
    this.studentPatch.emit(patchEvent);
  }
}
