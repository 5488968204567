import { NvIconModule } from 'projects/shared/nvps-libraries/design/nv-icon/nv-icon.module';
import { NvLogoModule } from 'Src/nvps-libraries/design/nv-logo/nv-logo.module';
import { NvDropdownItemModule } from 'Src/nvps-libraries/design/nv-dropdown-item/nv-dropdown-item.module';
import { MatMenuModule } from '@angular/material/menu';
import { PortalSwitcherComponent } from './portal-switcher.component';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

@NgModule({
  declarations: [PortalSwitcherComponent],
  imports: [CommonModule, NvDropdownItemModule, MatMenuModule, NvLogoModule, NvIconModule],
  exports: [PortalSwitcherComponent],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PortalSwitcherModule {}
