import { getSchool } from 'Src/ng2/store';
import { Store, select } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import {
  MAP_GROWTH_CURR_TERM,
  LANSING_MAP_GROWTH_CURR_TERM,
  STUDENT_ACADIENCE_CURR_TERM,
  CURRENT_DESSA_RATING_PERIOD,
  STUDENT_IREADY_CURR_TERM,
} from 'Src/ng2/shared/constants/current-school-year.constant';
import { DESSA_POST_RATING_PERIOD_NAME, DESSA_PRE_RATING_PERIOD_NAME } from 'Src/ng2/shared/constants/dessa.constant';
import { switchMap } from 'rxjs/operators';
import { UrlPathService } from 'Src/ng2/shared/services/url-path-service/url-path.service';

export const TERM_BASED_SCREENER_FOCUS = {
  MAP_GROWTH: {
    Fall: [
      'MAP_GROWTH_ELA',
      'MAP_GROWTH_MATH',
      'MAP_GROWTH_READING_DOMAINS_FALL',
      'MAP_GROWTH_MATH_DOMAINS_FALL',
      'MAP_GROWTH_ALGEBRA_1_DOMAINS_CURR_TERM',
      'MAP_GROWTH_ALGEBRA_2_DOMAINS_CURR_TERM',
      'MAP_GROWTH_GEOMETRY_DOMAINS_CURR_TERM',
    ],
    Winter: [
      'MAP_GROWTH_ELA_WINTER',
      'MAP_GROWTH_MATH_WINTER',
      'MAP_GROWTH_READING_DOMAINS_WINTER',
      'MAP_GROWTH_MATH_DOMAINS_WINTER',
      'MAP_GROWTH_ALGEBRA_1_DOMAINS_WINTER',
      'MAP_GROWTH_ALGEBRA_2_DOMAINS_WINTER',
      'MAP_GROWTH_GEOMETRY_DOMAINS_WINTER',
    ],
    Spring: [
      'MAP_GROWTH_ELA_SPRING',
      'MAP_GROWTH_MATH_SPRING',
      'MAP_GROWTH_READING_DOMAINS_SPRING',
      'MAP_GROWTH_MATH_DOMAINS_SPRING',
      'MAP_GROWTH_ALGEBRA_1_DOMAINS_SPRING',
      'MAP_GROWTH_ALGEBRA_2_DOMAINS_SPRING',
      'MAP_GROWTH_GEOMETRY_DOMAINS_SPRING',
      'MAP_GROWTH_READING_PROGRESS_WINTER_TO_SPRING',
    ],
    FallToWinter: ['MAP_GROWTH_READING_PROGRESS_FALL_TO_WINTER', 'MAP_GROWTH_MATHK12_PROGRESS_FALL_TO_WINTER'],
    FallToSpring: ['MAP_GROWTH_READING_PROGRESS_FALL_TO_SPRING', 'MAP_GROWTH_MATHK12_PROGRESS_FALL_TO_SPRING'],
    WinterToSpring: ['MAP_GROWTH_READING_PROGRESS_WINTER_TO_SPRING', 'MAP_GROWTH_MATHK12_PROGRESS_WINTER_TO_SPRING'],
  },
  ACADIENCE: {
    Fall: ['ACADIENCE_READING', 'ACADIENCE_MATH_FALL', 'ACADIENCE_READING_MEASURES_FALL', 'ACADIENCE_MATH_MEASURES_FALL', 'ACADIENCE_READING_COMPOSITE_FALL', 'ACADIENCE_MATH_COMPOSITE_FALL'],
    Winter: ['ACADIENCE_READING_WINTER', 'ACADIENCE_MATH_WINTER', 'ACADIENCE_READING_MEASURES_WINTER', 'ACADIENCE_MATH_MEASURES_WINTER', 'ACADIENCE_READING_COMPOSITE_WINTER', 'ACADIENCE_MATH_COMPOSITE_WINTER'],
    Spring: ['ACADIENCE_READING_SPRING', 'ACADIENCE_MATH_SPRING', 'ACADIENCE_READING_MEASURES_SPRING', 'ACADIENCE_MATH_MEASURES_SPRING', 'ACADIENCE_READING_COMPOSITE_SPRING', 'ACADIENCE_MATH_COMPOSITE_SPRING'],
    FallToWinter: ['ACADIENCE_READING_PROGRESS_WINTER', 'ACADIENCE_MATH_PROGRESS_WINTER'],
    FallToSpring: ['ACADIENCE_READING_PROGRESS_FALL_SPRING', 'ACADIENCE_MATH_PROGRESS_FALL_SPRING'],
  },
  IREADY: {
    Fall: ['ELA_IREADY_FALL', 'MATH_IREADY_FALL'],
    Winter: ['ELA_IREADY_WINTER', 'MATH_IREADY_WINTER'],
    Spring: ['ELA_IREADY_SPRING', 'MATH_IREADY_SPRING'],
    FallToWinter: ['IREADY_READING_GROWTH_WINTER', 'IREADY_MATH_GROWTH_WINTER'],
    FallToSpring: ['IREADY_READING_GROWTH_SPRING', 'IREADY_MATH_GROWTH_SPRING'],
  },
  DESSA: {
    [DESSA_PRE_RATING_PERIOD_NAME]: ['DESSA_RATING_PRE_ASSESSMENT', 'DESSA_COMPETENCIES_PRE_ASSESSMENT'],
    [DESSA_POST_RATING_PERIOD_NAME]: ['DESSA_RATING_POST_ASSESSMENT', 'DESSA_COMPETENCIES_POST_ASSESSMENT'],
    PRE_TO_POST_ASSESSMENT: ['DESSA_PROGRESS_PRE_TO_POST_ASSESSMENT'],
  },
};

@Injectable()
export class DashboardScreenerFociRotationGuard implements CanActivate {
  constructor (
    private router: Router,
    private store: Store<any>,
    private urlPathService: UrlPathService,
  ) { }

  canActivate(snapshot: ActivatedRouteSnapshot): Observable<UrlTree | boolean> {
    const { queryParams } = snapshot;
    const { focus } = queryParams;
    const { schoolId } = snapshot.params;

    return this.store.pipe(
      select(getSchool),
      switchMap(({ district }) => {
        const terms = this.getScreenerTerms(focus, district);
        const fociKeys = this.getScreenerFociKeys(focus, TERM_BASED_SCREENER_FOCUS);
        const valid = this.validateScreenerFociBasedOnTerm(focus, { ...terms, fociKeys });
        if (valid) {
          return of(true);
        } else {
          const url = this.urlPathService.computeDistrictUrlPath(`/school/${schoolId}/lists/tiles`);
          return of(this.router.createUrlTree([url]));
        }
      })
    )
  }

  getScreenerTerms(fociKey: string, district) {
    let currTerm = null;
    let currRatingPeriod = null;
    const nyDistricts = ['NYC', 'Schenectady', 'Uniondale'];
    if (fociKey.match('MAP_GROWTH')) {
      currTerm = nyDistricts.includes(district) ? MAP_GROWTH_CURR_TERM : LANSING_MAP_GROWTH_CURR_TERM;
    }
    if (fociKey.match('ACADIENCE')) {
      currTerm = STUDENT_ACADIENCE_CURR_TERM;
    }
    if (fociKey.match('IREADY')) {
      currTerm = STUDENT_IREADY_CURR_TERM;
    }
    if (fociKey.match('DESSA')) {
      currRatingPeriod = CURRENT_DESSA_RATING_PERIOD;
    }
    const terms = {
      currTerm,
      fallTerm: 'Fall',
      winterTerm: 'Winter',
      springTerm: 'Spring',
      fallToWinter: 'FallToWinter',
      fallToSpring: 'FallToSpring',
      winterToSpring: 'WinterToSpring',
      currRatingPeriod,
      pre_assessment: DESSA_PRE_RATING_PERIOD_NAME,
      post_assessment: DESSA_POST_RATING_PERIOD_NAME,
    };
    return terms;
  }

  getScreenerFociKeys(fociKey, TERM_BASED_SCREENER_FOCUS) {
    let screenerKey: null | string = null;
    if (fociKey.match('MAP_GROWTH')) {
      screenerKey = 'MAP_GROWTH';
    }
    if (fociKey.match('ACADIENCE')) {
      screenerKey = 'ACADIENCE';
    }
    if (fociKey.match('IREADY')) {
      screenerKey = 'IREADY';
    }
    if (fociKey.match('DESSA')) {
      screenerKey = 'DESSA';
    }
    const fociKeys = TERM_BASED_SCREENER_FOCUS[screenerKey];
    return fociKeys;
  }

  validateScreenerFociBasedOnTerm(
    fociKey: string,
    {
      currTerm,
      fallTerm,
      winterTerm,
      springTerm,
      fociKeys,
      fallToWinter,
      fallToSpring,
      winterToSpring,
      currRatingPeriod,
      pre_assessment,
      post_assessment,
    },
  ): boolean {
    let valid = true;
    if (fociKeys) {
      if (currTerm) {
        valid = this.valiateNonDessaFociBasedOnTerm(fociKey, {
          currTerm,
          fallTerm,
          winterTerm,
          springTerm,
          fociKeys,
          fallToWinter,
          fallToSpring,
          winterToSpring,
        });
      } else if (currRatingPeriod) {
        valid = this.validateDessaFociBasedOnTerm(fociKey, {
          fociKeys,
          currRatingPeriod,
          pre_assessment,
          post_assessment,
        });
      }
    }
    return valid;
  }

  valiateNonDessaFociBasedOnTerm(
    fociKey: string,
    { currTerm, fallTerm, winterTerm, springTerm, fallToWinter, fallToSpring, winterToSpring, fociKeys },
  ): boolean {
    let valid = true;
    const validKeysForFall = [...(fociKeys[fallTerm] || [])];
    const validKeysForWinter = [
      ...validKeysForFall,
      ...(fociKeys[winterTerm] || []),
      ...(fociKeys[fallToWinter] || []),
    ];
    const validKeysForSpring = [
      ...validKeysForWinter,
      ...(fociKeys[springTerm] || []),
      ...(fociKeys[fallToSpring] || []),
      ...(fociKeys[winterToSpring] || []),
    ];
    switch (currTerm) {
      case fallTerm:
        valid = validKeysForFall.includes(fociKey);
        break;
      case winterTerm:
        valid = validKeysForWinter.includes(fociKey);
        break;
      case springTerm:
      default:
        valid = validKeysForSpring.includes(fociKey);
        break;
    }
    return valid;
  }

  validateDessaFociBasedOnTerm(
    fociKey: string,
    { fociKeys, currRatingPeriod, pre_assessment, post_assessment },
  ): boolean {
    let valid = true;
    const validKeysForPreAssessment = [
      ...(fociKeys[pre_assessment] || []),
      ...(fociKeys['PRE_ASSESSMENT_COMPETENCIES'] || []),
    ];
    const validKeysForPostAssessment = [
      ...validKeysForPreAssessment,
      ...(fociKeys[post_assessment] || []),
      ...(fociKeys['POST_ASSESSMENT_COMPETENCIES'] || []),
      ...(fociKeys['PRE_TO_POST_ASSESSMENT'] || []),
    ];
    switch (currRatingPeriod) {
      case pre_assessment:
        valid = validKeysForPreAssessment.includes(fociKey);
        break;
      case post_assessment:
      default:
        valid = validKeysForPostAssessment.includes(fociKey);
        break;
    }
    return valid;
  }
}
