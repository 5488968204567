import { IPatchEvent, WizardHelpers } from '../../../../services/wizard-helpers.service';
import { Component, Input, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { reduce } from 'lodash';

@Component({
  selector: 'readiness-math-step',
  templateUrl: 'readiness-math-step.component.html',
  styleUrls: ['../shared-step-styles.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReadinessMathStep implements OnInit {
  @Input() filteredStudents;
  @Output() studentPatch: EventEmitter<any> = new EventEmitter();

  public stepLabel: string = 'Plan Regents exams for students who could resit for CR in Math';
  public collegeReadinessAlgStudentData;
  public totalStudentsWithReadinessMismatch: number;
  public options;
  public patches;

  constructor (private wizardHelpers: WizardHelpers) {}

  ngOnInit (): void {
    this.collegeReadinessAlgStudentData = this.getCollegeReadinessAlgStudentData();
    this.totalStudentsWithReadinessMismatch = this.wizardHelpers.getCountOfStudentsToBePatched(
      this.collegeReadinessAlgStudentData,
    );
    this.patches = this.wizardHelpers.getRegentsExamBulkPatch(this.collegeReadinessAlgStudentData, 'humanName');
    this.options = {
      columnKeys: [
        'regentsNeededForCollegeReadiness',
        'maxScoreMath',
        'collegeReadyOnMathRegentsOrSatMath',
        'regentsNeededForOnTrackDiploma',
        'regentsSchedForNextAdmin',
      ],
    };
  }

  ngOnChanges ({ filteredStudents }) {
    if (filteredStudents && !filteredStudents.isFirstChange()) {
      this.ngOnInit();
    }
  }

  private getCollegeReadinessAlgStudentData () {
    const baseObj = { 'CC Algebra': { _ids: [], studentIds: [] } };
    return reduce(
      this.filteredStudents,
      (studentRecRatCollection, { _id, studentId, isRegentsRecRatCollegeReadinessMismatchedAlg }) => {
        if (isRegentsRecRatCollegeReadinessMismatchedAlg) {
          studentRecRatCollection['CC Algebra']._ids.push(_id);
          studentRecRatCollection['CC Algebra'].studentIds.push(studentId);
        }
        return studentRecRatCollection;
      },
      baseObj,
    );
  }

  patchStudents (patches, count) {
    const patchEvent: IPatchEvent = {
      message: `This will bulk schedule ${count} exams`,
      patches,
    };
    this.studentPatch.emit(patchEvent);
  }
}
