import { createFeatureSelector, createSelector } from '@ngrx/store';
import { map } from 'lodash';
import { ISupportsState } from './../reducers/supports-reducer';

const getPortalState = createFeatureSelector('PORTAL_STORE');

export const getSupportsState = createSelector(
  getPortalState,
  (state: any) => {
    return state.supports;
  },
);

export const getSupportsEntities = createSelector(
  getSupportsState,
  (state: ISupportsState) => {
    return state.supportsEntities;
  },
);

export const getSupportsEntitiesList = createSelector(
  getSupportsState,
  (state: ISupportsState) => {
    const supportsEntities = state.supportsEntities;
    return map(supportsEntities, (v, k) => v);
  },
);

export const getSupportsLoadingStatus = createSelector(
  getSupportsState,
  (state: ISupportsState) => {
    return state.loading;
  },
);

export const getSupportsLoadedStatus = createSelector(
  getSupportsState,
  (state: ISupportsState) => {
    return state.loaded;
  },
);
