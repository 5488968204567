import { Injectable, Injector } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import * as Diacritic from 'diacritic';
import { CreditRequirements } from '../../constants/credit-requirements.constant';
import { DateHelpers } from '../../../../../projects/shared/services/date-helpers/date-helpers.service';
import { IProjection } from '../student-fetch/student-fetch.service';
import { ImCachedObject } from '../im-models/im-cached-object.service';
import { SharedUtilitiesService } from 'projects/shared/services/utilities-service/utilities.service';

interface IMergeArrayObjsByIdResponse<T> {
  mergedObjs: T[];
  unmatchedIdsForArr1: string[];
  unmatchedIdsForArr2: string[];
}

export type TValidTypes =
  | 'undefined'
  | 'null'
  | 'number'
  | 'boolean'
  | 'string'
  | 'object'
  | 'function'
  | 'regexp'
  | 'array'
  | 'date'
  | 'error';

const suffixMap: Record<string, string> = {
  jr: 'Jr',
  sr: 'Sr',
  i: 'I',
  ii: 'II',
  iii: 'III',
  iv: 'IV',
  v: 'V',
};
@Injectable()
export class UtilitiesService extends SharedUtilitiesService {
  constructor (
    private dateHelpers: DateHelpers,
    private injector: Injector,
  ) {
    super();
  }

  patchObject (obj, patch): any {
    for (const key in patch) {
      if (_.size(patch[key]) && obj[key] && _.isPlainObject(obj[key])) {
        this.patchObject(obj[key], patch[key]);
      } else if (typeof obj[key] === 'function') {
        throw new Error('Invalid patch attempt - ' + key + ' is a function and cannot be overwritten');
      } else {
        obj[key] = patch[key];
      }
    }
  }

  copyPOJO (obj) {
    return this.toPOJO(obj);
  }

  /* istanbul ignore next */
  parseDate (str) {
    return moment(str, 'MMMM-DD-YYYY HH:mm A Z');
  }

  toPOJO (obj) {
    return JSON.parse(
      JSON.stringify(obj, (key, value) => {
        let val = value;
        /* istanbul ignore if */
        if (key !== '_id' && typeof key === 'string' && key.charAt(0) === '_') {
          val = undefined;
        }
        return val;
      }),
    );
  }

  // Determines if `hash` is indeed a SHA1 or MD5 hash
  // TODO: Make sure this change did not break existing functionality in the App
  isHash (hash) {
    if (typeof hash === 'string' && /[0-9a-f]{40}/.exec(hash)) return true;
    return false;
  }

  createReadableDate (date) {
    if (date) {
      return moment(date, 'MMM d, y h:mm a');
    } else {
      return false;
    }
  }

  // Takes a string as parameter and capitalizes the first character after every whitespace that is not
  // a special character such as '!@#$%^&*()_+:?><' etc
  toTitleCase (string) {
    return string.replace(/\w\S*|[A-Za-zÀ-ÖØ-öø-ÿ]*/g, txt => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  /**
   * Inherit the prototype methods from one constructor into another.
   *
   * @note Taken from https://google.github.io/closure-library/api/goog.html#inherits
   *
   * @example
   * function ParentClass(a, b) { }
   * ParentClass.prototype.foo = function(a) { };
   *
   * function ChildClass(a, b, c) {
   *  ChildClass.base(this, 'constructor', a, b);
   * }
   * UtilitiesService.inherits(ChildClass, ParentClass);
   *
   * var child = new ChildClass('a', 'b', 'see');
   * child.foo(); // This works.
   *
   * @param childCtor {Function} Child class.
   * @param parentCtor {Function} Parent class.
   */
  inherits (childCtor, parentCtor) {
    function TempCtor () {
      //
    }
    TempCtor.prototype = parentCtor.prototype;
    childCtor._super = parentCtor.prototype;

    childCtor.prototype = new TempCtor();
    childCtor.prototype.constructor = childCtor;
  }

  // fields is an array of "dot notation" paths of the fields that should be patched
  generatePatch (obj, fields) {
    if (!_.isArray(fields)) {
      fields = [fields];
    }

    return _.reduce(
      fields,
      (result, field) => {
        this.setFieldByPath(result, field, this.getFieldByPath(obj, field));
        return result;
      },
      {},
    );
  }

  getFieldByPath (obj, path) {
    let ret = obj;
    const paths = path.split('.');
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < paths.length; ++i) {
      if (_.isUndefined(ret[paths[i]])) {
        return undefined;
      } else {
        ret = ret[paths[i]];
      }
    }
    return ret;
  }

  setFieldByPath (obj, path, value) {
    const paths = path.split('.');

    let setOn = obj; // object where the last key is replaced
    for (let i = 0; i < paths.length - 1; i++) {
      if (!_.isUndefined(setOn[paths[i]])) {
        if (_.isPlainObject(setOn[paths[i]])) {
          setOn = setOn[paths[i]];
        } else {
          throw new Error(
            'Cannot locate `' +
            path +
            '`\n' +
            'At path ' +
            _.slice(paths, 0, i + 1).join('.') +
            ', value is not an object',
          );
        }
      } else {
        setOn[paths[i]] = {};
        setOn = setOn[paths[i]];
      }
    }

    if (!_.isFunction(setOn[paths[paths.length - 1]])) {
      setOn[paths[paths.length - 1]] = value;
    } else {
      throw new Error('Cannot set value at `' + path + '` since it would overwrite a function');
    }

    return obj;
  }

  /**
   * This method is used to parse the student list.search endpoint response
   * @param paths {Array} of dot notation paths
   * @param values values to be assign to the unzipped paths
   * @param projection paths {Array} to get (reduces paths)
   */
  unzipData (data = { paths: [], values: [] }, projection?): Array<any> {
    const paths = data.paths;
    const values = data.values;
    const pathsToGet = projection && _.keys(projection);
    const unzippedData = _.map(values, (value) => this.unzipDataHelper({ paths, pathsToGet, values: value }));
    return unzippedData;
  }

  private unzipDataHelper ({ paths, pathsToGet, values } : {paths: string[], pathsToGet?: string[], values: string[]}) {
    const ret = {};
    const pathAndIndex: { paths: string[]; idxs: number[] } = { paths: [], idxs: [] };
    if (pathsToGet) {
      _.each(pathsToGet, pToReturn => {
        _.each(paths, (p, idx) => {
          if (_.startsWith(p, pToReturn)) {
            pathAndIndex.paths.push(p);
            pathAndIndex.idxs.push(idx);
          }
        });
      });
    } else pathAndIndex.paths = paths;

    _.each(pathAndIndex.paths, p => _.set(ret, p, undefined));

    if (pathAndIndex.idxs.length) {
      _.each(pathAndIndex.paths, (p: any, idx: number) => {
        _.set(ret, p, values[pathAndIndex.idxs[idx]]);
      });
    } else {
      _.each(paths, (p, idx) => {
        _.set(ret, p, values[idx]);
      });
    }
    return ret;
  }

  /**
   * This method takes a projection, and iterates through each of the paths.
   * If
   *
   * @param projection
   * @returns {*}
   */
  reduceProjection (projection) {
    const keys = _.keys(projection).sort();
    if (keys.length <= 1) {
      return projection;
    }

    const ret = this.copyPOJO(projection);

    // set the initial value of 'prevKey'.
    let prevKey = keys[0];
    for (let i = 1; i < keys.length; i++) {
      const key = keys[i];
      if (_.startsWith(key, prevKey) && key.charAt(prevKey.length) === '.') {
        delete ret[key];
      } else {
        prevKey = key;
      }
    }

    return ret;
  }

  /**
   * Finds the difference between two projections
   *
   * This will return the projection that represents the fields that are to be added to `baseProjection` to get
   * `extendedProjection`. For example,
   *
   * @param baseProjection projection in MongoDB format
   * @param extendedProjection projection in MongoDB format
   */
  diffProjection (baseProjection, extendedProjection) {
    const nestedProjection = {};
    _.each(baseProjection, (value, key: string) => {
      const path = key.split('.');
      let obj = nestedProjection;
      for (let i = 0; i < path.length; i++) {
        const p = path[i];
        if (i !== path.length - 1) {
          if (typeof obj[p] !== 'object') {
            obj[p] = {};
          }
          obj = obj[p];
        } else if (i === path.length - 1) {
          if (!obj[p]) obj[p] = true;
        }
      }
    });

    const diffProjection = {};
    _.each(extendedProjection, (value, key: string) => {
      const path = key.split('.');
      let obj = nestedProjection;
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < path.length; i++) {
        const p = path[i];
        if (obj[p] === true) {
          return;
        } else if (typeof obj[p] === 'undefined') {
          diffProjection[key] = true;
          return;
        } else {
          obj = obj[p];
        }
      }
      diffProjection[key] = true;
    });

    return diffProjection;
  }

  /**
   * Combines baseProjection with additionalProjection and returns a reduced projection
   *
   * @note Pure function that does not mutate baseProjection or additionalProjection
   *
   * @param baseProjection
   * @param additionalProjection
   */
  mergeProjection (baseProjection, additionalProjection) {
    const base = this.copyPOJO(baseProjection);
    _.each(additionalProjection, (value, key) => {
      base[key] = true;
    });

    return this.reduceProjection(base);
  }

  // Returns today as YYYY-MM-DD
  todayISODate () {
    const d = new Date();
    return [d.getFullYear(), ('0' + (d.getMonth() + 1)).slice(-2), ('0' + d.getDate()).slice(-2)].join('-');
  }

  // strip the time from passed in date and return it's UTC equivalent.
  // At the time of writing, this functionality is needed to set correct date in md-picker
  dateToUTCWithoutTime (date) {
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth();
    const day = date.getUTCDate();

    return new Date(year, month, day);
  }

  getAbbrDayOfWeek (day) {
    if (day === 'monday') {
      return 'Mon';
    } else if (day === 'tuesday') {
      return 'Tue';
    } else if (day === 'wednesday') {
      return 'Wed';
    } else if (day === 'thursday') {
      return 'Thur';
    } else if (day === 'friday') {
      return 'Fri';
    } else if (day === 'saturday') {
      return 'Sat';
    } else if (day === 'sunday') {
      return 'Sun';
    }
  }

  /* istanbul ignore next */
  getWeeklyScheduleString (scheduleObj) {
    let week: any = _.map(scheduleObj, (dayValue, dayKey) => {
      if (dayValue === true) {
        return dayKey;
      }
    });
    week = _.map(week, day => {
      return this.getAbbrDayOfWeek(day);
    });
    week = _.compact(week);
    return week.toString();
  }

  getHumanReadableTerm (termYear: number): string {
    const termCode = termYear.toString();
    const term = termCode.slice(2) === '7' ? 'Summer' : `Term ${Number(termCode.slice(2))}`;
    const startSchoolYear = Number(termCode.slice(0, 2));
    const endSchoolYear = startSchoolYear + 1;
    return `${term} SY${startSchoolYear}-${endSchoolYear}`;
  }

  // DS TODO: this is imperfect - not sure about how to factor in March
  getMonthYearDateFromTermYear (termYear: number): string {
    const termYearString = termYear.toString();
    const termNumber = termYearString.slice(2);
    const month = termNumber === '1' ? 'Jan' : termNumber === '7' ? 'Aug' : 'June';
    const twoDigitYear = Number(termYearString.slice(0, 2));
    const fourDigitYear = '20' + (twoDigitYear + 1);
    const monthYear = month + ' ' + fourDigitYear;
    return monthYear;
  }

  getHumanReadableGradReq (gradReq: string): string {
    const course: any = _.find(CreditRequirements, { camelCase: gradReq });
    if (course) return course.human;
  }

  convertISODateToHumanDate (isoString) {
    if (!isoString) return;
    const dateString = isoString.split('T')[0];
    const split = dateString.split('-');

    // If string is not proper ISO format of YYYY-MM-DD, return
    if (split.length !== 3) return;

    return `${split[1]}/${split[2]}/${split[0]}`;
  }

  // return a timestamp in this format: Wednesday, 11/22 10:52 am
  convertTimestampToLocalTime (timestamp: string): string {
    if (timestamp === null) {
      return;
    }
    const utc = moment.utc(timestamp);
    const localDate = moment(utc).local();
    return localDate.format('dddd, M/DD/YYYY hh:mm a');
  }

  /**
   * @summary given an array and an integer, it creates a 2 dimensional array with columns split evenly.
   * @example splitArrIntoCols([1,2,3,4,5], 3) will return [[1,2], [3,4], [5]]
   * @param arr {Array}
   * @param numOfCols {Number}
   * @returns {Array}
   */
  chunkArrIntoCols (arr, numOfCols) {
    const lengthOfArr = arr.length;
    const columns = [];

    for (let i = 0; i < numOfCols; i++) {
      const startSliceIndex = Math.ceil((lengthOfArr / numOfCols) * i);
      const endSliceIndex = Math.ceil(lengthOfArr * ((i + 1) / numOfCols));
      const column = arr.slice(startSliceIndex, endSliceIndex);

      columns.push(column);
    }
    return columns;
  }

  /**
   * @example mergeArrayObjsById([{ _id: 'a', a: 1 }, {_id: 'z', a: 1 }], [{ _id: 'a', b: 2 }]) will return
   *    { mergedObjs: [{_id: 'a', a: 1, b: 2}], unmatchedObjsIds: ['z'] }
   * @param arr1 {Array} of {Objects}
   * @param arr2 {Array} of {Objects}
   * @returns {Object}
   */
  mergeArrayObjsById<T> (arr1: T[], arr2: T[]): IMergeArrayObjsByIdResponse<T> {
    const cache = {};
    const mergedObjs = [];
    const unmatchedIdsForArr1 = [];
    const unmatchedIdsForArr2 = [];

    _.each(arr2, (v: any) => {
      cache[v._id] = v;
    });

    _.each(arr1, (v: any) => {
      if (cache[v._id]) {
        const mergedObj = this.deepMergeObjs(v, cache[v._id]);
        mergedObjs.push(mergedObj);
        delete cache[v._id];
      } else {
        unmatchedIdsForArr1.push(v._id);
      }
    });

    if (_.size(cache)) {
      _.each(cache, (v, k) => {
        unmatchedIdsForArr2.push(k);
      });
    }

    return { mergedObjs, unmatchedIdsForArr1, unmatchedIdsForArr2 };
  }

  /**
   * @summary it deep merges any number of objects, returning a new object with all
   *  of the properties and vaules of the merged objects. It does not mutate
   *  original objects.
   * @param {Object} -> any number of objects
   * @returns {Object} -> merged object
   */
  deepMergeObjs (...args): object {
    const toString = {}.toString;
    const result = {};
    let src;
    let p;

    while (args.length > 0) {
      src = args.splice(0, 1)[0];
      if (toString.call(src) === '[object Object]') {
        for (p in src) {
          if (src.hasOwnProperty(p)) {
            if (toString.call(src[p]) === '[object Object]') {
              result[p] = this.deepMergeObjs(result[p] || {}, src[p]);
            } else {
              result[p] = src[p];
            }
          }
        }
      }
    }

    return result;
  }

  /**
   * Returns error object for any data type (CM).
   * Use to handle errors in async function.
   * example:
   *  try {
   *    await getData();
   *  } catch (err) {
   *    throw utilitiesService.handleAwaitErr(err);
   *  }
   */
  handleAwaitErr (err) {
    const typeOf = this.typeOf(err);
    if (typeOf === 'error') return err; // simply return error object (CM)
    if (typeOf === 'object') return new Error(JSON.stringify(err)); // to avoid `[object Object]` (CM)

    return new Error(err);
  }

  /**
   * Returns the dataType for a given input (CM).
   *  dataType  | using js native `typeof` | using custom `typeOf`
   *  undefined | 'undefined'              | 'undefined'
   *  null      | 'object'                 | 'null'
   *  number    | 'number'                 | 'number'
   *  boolean   | 'boolean'                | 'boolean'
   *  string    | 'string'                 | 'string'
   *  function  | 'function'               | 'function'
   *  object    | 'object'                 | 'object'
   *  regex     | 'object'                 | 'regexp'
   *  array     | 'object'                 | 'array'
   *  date      | 'object'                 | 'date'
   *  error     | 'object'                 | 'error'
   */
  typeOf (dataType): TValidTypes {
    const type = {}.toString
      .call(dataType)
      .split(' ')[1]
      .slice(0, -1)
      .toLowerCase();

    return type;
  }

  /* istanbul ignore next */
  static toUpperSnakeCase (str: string): string {
    return _.snakeCase(str).toUpperCase();
  }

  // takes an object of key/value pair and returns a string in the following format:
  // e.g. `https://portal.com?schoolId=13W320&studentId=1234&status=ACTIVE
  addQueriesToUrl (url: string, queries: { [key: string]: any }): string {
    let initialQueryAdded = url.includes('?');

    if (url.endsWith('/')) url = url.substring(0, url.length - 1);

    url = _.reduce(
      queries,
      (url, value, key) => {
        if (value !== undefined) {
          if (!initialQueryAdded) {
            url += `?${key}=${value}`;
            initialQueryAdded = true;
          } else {
            url += `&${key}=${value}`;
          }
        }

        return url;
      },
      url,
    );

    return url;
  }

  replaceStudentIdWithHash (url: string) {
    const studentIdMatch = url.match(/studentId=([^&]+)/);

    if (studentIdMatch) {
      // avoid circular dependency by using injector class to get the ImCachedObject service
      const imCachedObject = this.injector.get(ImCachedObject);
      const studentId = studentIdMatch[1];
      const hashedId = imCachedObject.createHash({ _id: studentId });
      return url.replace(/studentId=[^&]+/, `hashedId=${hashedId}`);
    }

    return url;
  }

  // TODO: move to a new array sort service? (CM)
  // sorts ascending by default (reverseSort = false)
  sortStrings ({ a, b, reverseSort }: { a: string; b: string; reverseSort?: boolean }) {
    const _a = a || '';
    const _b = b || '';

    if (reverseSort) return _a.trim().toLowerCase() > _b.trim().toLowerCase() ? -1 : 1;
    return _a.trim().toLowerCase() < _b.trim().toLowerCase() ? -1 : 1;
  }

  // TODO: move to a new array sort service? (CM)
  // sorts ascending by default (reverseSort = false)
  sortDates ({ a, b, reverseSort, dateFormat }: { a: string; b: string; reverseSort?: boolean; dateFormat?: string }) {
    const _a = this.dateHelpers.getMomentObj(a, dateFormat);
    const _b = this.dateHelpers.getMomentObj(b, dateFormat);

    if (reverseSort) return _a.isSameOrAfter(_b) ? -1 : 1;
    return _a.isSameOrBefore(_b) ? -1 : 1;
  }

  getBooleanOrDash (str: boolean | any): string {
    let result = '—';
    if (str === true) {
      result = 'Yes';
    } else if (str === false) {
      result = 'No';
    }

    return result;
  }

  showListPanelShadow (scrollYPosition: number): boolean {
    // 10 refers to the y offset in pixels. if you want some other offset,
    // try modifying the method and pass in the offset.
    return scrollYPosition > 10;
  }

  getDateWithoutWeekday (date, formatOptions: { formatMonth?: 'short' | 'long' } = { formatMonth: 'short' }) {
    const { formatMonth } = formatOptions;
    const options = {
      year: 'numeric',
      month: formatMonth,
      day: 'numeric',
      timeZone: 'UTC',
    };
    return new Date(date).toLocaleDateString('en-US', options as any);
  }

  getShortenedSelectedSchoolYear (selectedSchoolYear: string): string {
    if (selectedSchoolYear) {
      // regex matches SY20XX-XX out of a string
      // capture group preserves XX-XX and replaces SY20XX-XX with SYXX-XX
      // any remaining part of the string is preserved unchanged
      const regex = /SY20(\d{2}-\d{2})/g;
      const replaceText = 'SY$1';
      return selectedSchoolYear.slice().replace(regex, replaceText);
    }
    return selectedSchoolYear;
  }

  getWelcomeMessage (name: string): string {
    const stageOfDay = this.dateHelpers.getStageOfDayString();
    return name ? `Good ${stageOfDay}, ${name}!` : `Good ${stageOfDay}!`;
  }

  public checkForHyphensDiacriticsAndSpaces (name: string): string {
    if (name.length > 0) {
      const originalSeparator = name.includes('-') ? '-' : ' ';
      const words = name.split(originalSeparator);
      const capitalizedWords = words.map((word) => {
        const hasDiacritics = Diacritic.clean(word) !== word;
        if (hasDiacritics) {
          const lowercaseWords = word === word.toLocaleUpperCase() ? word.toLocaleLowerCase() : word;
          return lowercaseWords.charAt(0).toUpperCase() + lowercaseWords.slice(1);
        } else {
          // Split the string into words using non-alphabetic characters as separators
          const partialName: string[] = word.split(/[^a-zA-Z'-]+/).filter(Boolean);
          // Capitalize the initial character and the first letter of each word after spaces and hyphens (excluding apostrophes)
          return partialName.map(word => this.capitalizeWords(this.checkForSuffix(word)));
        }
      });
      return capitalizedWords.join(originalSeparator);
    } else {
      return '';
    }
  }

  private capitalizeWords (words: string): string {
    // Convert to lowercase if the input is all uppercase
    const lowercaseWords = words === words.toLocaleUpperCase() ? words.toLocaleLowerCase() : words;

    // Capitalize the initial character and the first letter of each word
    const capitalizedWords = lowercaseWords.replace(/(^|\b|[-'])\w/g, (match) => {
      // Capitalize the first letter, except for the second part onwards after an apostrophe
      return match.charAt(0).toUpperCase() + (match.charAt(0) === "'" ? match.slice(1).toLocaleLowerCase() : match.slice(1));
    });
    // Check for all-caps suffix and capitalize accordingly
    const wordsArray = capitalizedWords.split(/\b/);
    const lastWord = wordsArray[wordsArray.length - 1];
    const suffix = this.checkForSuffix(lastWord);

    if (suffix) {
      wordsArray[wordsArray.length - 1] = suffix;
    }
    // Add a period after a single-letter word EXCEPT when it one of the suffixes
    const isSingleLetterSuffix = capitalizedWords.length === 1 && ((capitalizedWords.toLocaleUpperCase() === suffixMap.v) || (capitalizedWords.toLocaleUpperCase() === suffixMap.i));
    return (capitalizedWords.length === 1 && !isSingleLetterSuffix) ? capitalizedWords + '.' : wordsArray.join('');
  }

  private checkForSuffix (word: string): string {
    const lowerCaseWord = word.toLocaleLowerCase();
    if (Object.prototype.hasOwnProperty.call(suffixMap, lowerCaseWord)) {
      return suffixMap[lowerCaseWord];
    }
    return word;
  }

  public formatFullName (fullName: string) {
    const names = fullName.split(', ');
    const formattedFirstName = this.checkForHyphensDiacriticsAndSpaces(names[1]);
    const formattedLastName = this.checkForHyphensDiacriticsAndSpaces(names[0]);
    return `${formattedFirstName} ${formattedLastName}`;
  }
}
