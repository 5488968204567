import { Component, EventEmitter, HostBinding, Input, Output, ViewEncapsulation } from '@angular/core';
import { TNvSegmentedControlOptions } from './nv-segmented-control.interface';

/**
 *
 * The Segmented Control enables the user to select between a limited set of options.
 *
 * Typically the Segmented Control should be used like a tab bar.
 *
 * Interacting with the Segmented Control should only change subviews, but should not remove it from view.
 * The Segmented Control is not a button—they make it easy to explore and switch between different views.
 *
 * Add the class `slim` to reduce the height of the `segmented-control`
 *
 */
@Component({
  selector: 'nv-segmented-control',
  templateUrl: './nv-segmented-control.component.html',
  styleUrls: ['./nv-segmented-control.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NvSegmentedControlComponent {
  /**
   * @deprecated
   */
  @Input() selected: string;

  /**
   *
   */
  @Input() index: number;

  /**
   *
   *
   */
  @Input() size: 'regular' | 'small';

  /**
   * A INvSegmentedControlOption[] or String[] representing the
   * options to be shown in the dropdown
   */
  @Input() options: TNvSegmentedControlOptions;

  /**
   *
   */
  @Output() clicked: EventEmitter<number> = new EventEmitter<number>();

  @HostBinding('class.slim') get slimClass () {
    return this.size === 'small';
  }

  ngOnInit () {
    if (this.selected !== undefined) {
      // tslint:disable-next-line:no-console
      console.warn('nv-segmented-control: Using `selected` is deprecated. Use `index` instead');
    }
  }

  // tslint:disable-next-line:no-unused-variable
  isSelected (opt: number): boolean {
    return opt === this.index;
  }

  handleClick (index: number) {
    this.index = index;
    this.clicked.emit(index);
  }
}
