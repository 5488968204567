import { IListSummaryGrouping } from './../../components/list-summary/list-summary.component';
import { IGroupData } from './../../models/list-models';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { SetGrouping } from '../../../store/actions/previous-states-actions';
import { IListSummaryModalEventObj } from '../../modals/list-summary/list-summary-modal.component';
import { IListViewData } from '../../typings/interfaces/list-view.interface';
import { ICurrentGrouping } from '../prev-state/prev-state.service';
import { IListData } from 'Src/ng2/shell/content-tools/content-tools.component';

@Injectable()
export class ListSummaryGroupings {
  // tslint:disable-next-line:contextual-decorator
  @Output() groupingNavEvent$: EventEmitter<IListSummaryModalEventObj> = new EventEmitter<IListSummaryModalEventObj>();

  constructor(private store: Store<any>) {
    //
  }

  public setGroupings(madLibModel, groupings$: Observable<Partial<IListViewData>>) {
    if (groupings$) {
      const groupHumanName = madLibModel.Groupings ? madLibModel.Groupings.human : madLibModel.Dimension2.human;
      groupings$.pipe(take(1)).subscribe(grouping => {
        this.store.dispatch(new SetGrouping({ grouping, groupHumanName } as ICurrentGrouping));
      });
    }
  }

  public setGroupingsV2(madLibModel, grouping) {
    if (grouping) {
      const groupHumanName = madLibModel.grouping ? madLibModel.grouping.human : madLibModel.focus.human;
      this.store.dispatch(new SetGrouping({ grouping, groupHumanName } as ICurrentGrouping));
    }
  }

  public getGroupings(madLibModel, data: IGroupData[]): IListSummaryGrouping {
    if (data) {
      return {
        title: 'Summary',
        data: data.filter(group => group.rowData.length),
        groupHumanName: madLibModel.grouping ? madLibModel.grouping.human : madLibModel.focus.human,
        countLabel: madLibModel.grouping.countLabel || 'Students',
        filterOptions: [],
      };
    }
  }

  public getExportListData ({ groupings, columns, madlibModel, listType, rowType = 'students', entityName }: { groupings, columns, madlibModel, listType: string, rowType?: string, entityName?: string }): IListData {
    return {
      listType,
      groupings,
      madlibSelections: madlibModel.value,
      columns,
      rowType,
      entityName,
    };
  }
}
