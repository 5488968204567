<div *ngIf="displayedNotifications.length">
  <div>
    <banner>
      <notification-wrapper>
        <notification *ngFor="let item of displayedNotifications">
          <icon *ngIf="item.isDismissable" (click)="dismissNotification(item)"></icon>
          <span [innerHTML]="notificationMessages[item._id]"></span>
        </notification>
      </notification-wrapper>
    </banner>
  </div>
</div>
