import { IUser, IUserMini, IUserMiniWithRole } from 'Src/ng2/shared/typings/interfaces/user.interface';
import { PartnerTypes, TValidPartnerTypes } from 'Src/ng2/shared/typings/interfaces/partner.interface';

const defaultHybridHandler = (user: any) => user;
const defaultSchoolHandler = (user: any) => user;
const defaultShelterHandler = (user: any) => user;
const defaultSchoolClusterHandler = (user: any) => user;
const defaultShelterClusterHandler = (user: any) => user;
const defaultExpandEdHandler = (user: any) => 'expand_ed';
const defaultCUNYHandler = (user: any) => 'cuny';

export function userCate (
  user: IUser | IUserMini | IUserMiniWithRole,
  partnerType: TValidPartnerTypes = PartnerTypes.SCHOOL,
) {
  return ({
    school: schoolHandler = defaultSchoolHandler,
    school_cluster: schoolClusterHandler = defaultSchoolClusterHandler,
    shelter: shelterHandler = defaultShelterHandler,
    shelter_cluster: shelterClusterHandler = defaultShelterClusterHandler,
    hybrid: hybridHandler = defaultHybridHandler,
    expandEd: expandEdHandler = defaultExpandEdHandler,
    expandEd: cunyHandler = defaultCUNYHandler,
  }) => {
    let res = null;
    switch (partnerType) {
      case PartnerTypes.HYBRID:
        res = hybridHandler(user);
        break;
      case PartnerTypes.SCHOOL:
        res = schoolHandler(user);
        break;
      case PartnerTypes.SCHOOL_NETWORK:
        res = schoolClusterHandler(user);
        break;
      case PartnerTypes.SHELTER:
        res = shelterHandler(user);
        break;
      case PartnerTypes.SHELTER_NETWORK:
        res = shelterClusterHandler(user);
        break;
      case PartnerTypes.EXPANDED:
        res = expandEdHandler(user);
        break;
      case PartnerTypes.CUNY:
        res = cunyHandler(user);
        break;
      default:
        break;
    }
    return res;
  };
}
