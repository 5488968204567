import { SyncCombinatorGuard } from './../../guards/sync-combinator-guard/sync-combinator-guard';
import { RouteGuard } from 'Src/ng2/routing/guards/route-guard/route-guard';
import { CurrentDistrictGuard } from './../../guards/current-district-guard/current-district-guard';
import { TogglesGuard } from 'Src/ng2/routing/guards/toggles-guard/toggles-guards';
import { CurrentUserGuard } from './../../guards/current-user-guard/current-user-guard';
import { schoolPickerRoute } from '../shared/school-picker/school-picker.route';
import { exploreRoute } from '../shared/explore/explore.route';
import { networkRoute } from '../shared/network/network.route';
import { schoolRoute } from '../shared/school/school.route';
import { nycExpandEdRoute } from '../nyc-expand-ed/nyc-expand-ed-route';
import { nycCUNYRoute } from '../nyc-cuny/nyc-cuny-route';
import { profileRoute } from '../shared/cuny/cuny.route';

export const districtRoute = {
  path: ':district',
  url: ':district',
  authenticationRequired: true,
  rolePermissions: ['all'],
  canActivate: [SyncCombinatorGuard],
  data: {
    guards: [CurrentDistrictGuard, RouteGuard, CurrentUserGuard, TogglesGuard],
  },
  children: [
    {
      path: '',
      pathMatch: 'full',
      redirectTo: '/not-found',
    },
    {
      ...schoolPickerRoute,
    },
    {
      ...exploreRoute,
    },
    {
      ...networkRoute,
    },
    {
      ...schoolRoute,
    },
    {
      ...nycExpandEdRoute,
    },
    {
      ...nycCUNYRoute,
    },
    {
      ...profileRoute,
    },
  ],
};
