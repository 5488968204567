<div flex>
  <p class="mat-body-2">
    Use the <span class="mat-subtitle-2">Planned Regents Exams By Prep Status table</span> to determine how many
    additional Regents Prep supports you need for each exam.
  </p>
  <p class="mat-body-2">
    You'll need to accommodate all the students with no support, who are not currently in a course that is prepping
    them for the exam (red row), as well as students who are struggling with the course they are in now (orange
    row).
  </p>
  <p class="mat-body-2">
    Use the <span class="md-body-2">Regents Prep Supports table</span> to view existing supports and create
    supports.
  </p>
</div>