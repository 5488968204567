import { TValidPlannedDiplomaTypes } from '../../../ng2/shared/typings/interfaces/student.interface';
import { EM_DASH } from './em-dash.constant';

export interface IPlannedDiplomaType {
  humanName: TValidPlannedDiplomaTypes;
  type: string;
  byCategory: '5' | '9';
  keyForNumberPassed: string;
  numberPassedString?: string;
}

export const PlannedDiplomaType = {
  ADVANCED_REGENTS: {
    humanName: 'Advanced Regents',
    type: 'advanced',
    byCategory: '9',
    keyForNumberPassed: 'of9Advanced',
    numberPassedString: 'of 9 Regents @ 65',
  } as IPlannedDiplomaType,
  REGENTS: {
    humanName: 'Regents',
    type: 'regents',
    byCategory: '5',
    keyForNumberPassed: 'of5Regents',
    numberPassedString: 'of 5 Regents @ 65',
  } as IPlannedDiplomaType,
  LOCAL: {
    humanName: 'Local',
    type: 'local',
    byCategory: '5',
    keyForNumberPassed: 'of5Local',
    numberPassedString: 'of 5 Regents @ 55',
  } as IPlannedDiplomaType,
  NON_GRADUATE: {
    humanName: 'Non-Graduate',
    type: 'grad',
    byCategory: '5',
    keyForNumberPassed: 'of5Grad',
  } as IPlannedDiplomaType,
  NO_PLAN: {
    humanName: EM_DASH,
    type: 'grad',
    byCategory: '5',
    keyForNumberPassed: 'of5Grad',
  } as IPlannedDiplomaType,
};
