<div class="content-tools" *ngIf="hasListActions">
  <info-panel [infoPanel]="infoPanel"></info-panel>
  <ng-container *ngIf="hasListSummary">
    <list-summary
      *ngFor="let summaryTable of listSummaryGroupData"
      [summaryTable]="summaryTable"
      (selectOption)="onFilterChange($event)"
    ></list-summary>
  </ng-container>
  <tools-wrapper *ngIf="!hideTools">
    <tools *ngIf="!batchActionsMode">
      <div class="side-header"><div class="side-header-text">Tools</div></div>
      <div class="tool-option" *ngIf="hasBatchActions">
        <div class="tool-option-inner" (click)="enableBatchActionsMode()">
          <div class="icon-container"><nv-icon name='select-small-blue'></nv-icon></div>
          <label for="batchActions">Batch actions</label> <span id="batchActions"></span>
        </div>
      </div>
      <div *ngFor="let tool of additionalTools">
        <div class="tool-option" [ngClass]="{ disabled: !tool.isActive }">
          <div class="tool-option-inner" (click)="tool.onClick()">
            <div class="icon-container"><nv-icon name="{{ tool.icon }}"></nv-icon></div>
            <label for="{{ tool.label }}">{{ tool.label }}</label> 
            <span id="{{ tool.label }}"></span>
            <ng-container *ngIf="tool?.subLabel">
              <span></span>
              <div class="additional-tools-sub-label">{{ tool.subLabel }}</div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="tool-option">
        <div class="tool-option-inner" (click)="onExportCsvClick()">
          <div class="icon-container"><nv-icon name='download-small-blue'></nv-icon></div>
          <label for="exportToCSV">Export to CSV</label> <span id="exportToCSV"></span>
        </div>
      </div>
    </tools>
    <tools *ngIf="batchActionsMode">
      <div class="side-header">
        <div class="side-header-text">Batch Actions</div>
        <div class="side-header-meta">
          <p>
            {{ countStudents }}
            <span [ngPlural]="countStudents">
              <ng-template ngPluralCase="=1">student</ng-template>
              <ng-template ngPluralCase="other">students</ng-template>
            </span>
            selected
          </p>
        </div>
      </div>
      <batch-actions-menu
        [school]="school"
        [userCanEdit]="userCanEdit"
        [currentUser]="currentUser"
        [batchActionsToShow]="batchActionsToShow"
        [contextPartnerType]="contextPartnerType"
        [contextPartnerId]="contextPartnerId"
        [subLocationId]="subLocationId"
        [origin]="origin"
      ></batch-actions-menu>
    </tools>
  </tools-wrapper>
</div>
