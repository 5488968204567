<div class="nv-wizard-table credit-gaps-generic-table">
    <div class="app-headline">
        <h3>{{ toggleMap[selectedToggle] }} By Class</h3>
    </div>

    <div class="row">
        <div class="table-container">
            <table class="nv-table nv-table-dense">
                <thead>
                    <tr>
                        <th></th>
                        <th *ngFor="let header of tableHeaders">{{ header }}</th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let data of populatedTable | keyvalue: orderTableRows" [ngClass]="data.value.className">
                        <td>{{ data.key }}</td>
                        <td 
                        *ngFor="let rowData of data.value.cohortBuckets"
                        [ngClass]="{ 'is-hovered': rowData.hover && rowData.bucket.length > 0 }" 
                        (mouseenter)="rowData.hover=true"
                        (mouseleave)="rowData.hover=false"
                        >
                            <wizard-filter-actions [data]="rowData.bucket" [options]="filterOptions">
                            </wizard-filter-actions>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="summary-container summary-layout">
            <div class="wizard-table-summary">
                <div class="summary">
                    <span class="count linked">
                        <wizard-filter-actions [data]="tableSummary.studentIdsCreditMaxGapsNoPlan"
                            [options]="filterOptions">
                        </wizard-filter-actions>
                    </span>
                    <div class="description">{{ toggleMap[selectedToggle] }}</div>
                </div>
            </div>

            <mat-radio-group class="nv-mat-radio-group" [(ngModel)]="selectedToggle" (change)="rebuildTable()">
                <mat-radio-button value="creditMaxGaps">
                    <span class="mat-caption">View by students with credit gaps</span>
                </mat-radio-button>

                <mat-radio-button value="creditMaxGapsNoPlan">
                    <span class="mat-caption">View by students with unaddressed credit gaps</span>
                </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
</div>