import { merge } from 'lodash';
import * as sdcActions from '../../actions/sdc-actions/sdc-actions';

export const initialState = {
  loaded: false,
  loading: false,
  projection: {},
  joins: [],
  columnKeys: [],
  students: null,
  flattenedStudents: null,
  gridStates: {},
} as any;

export function reducer (state = initialState, action: sdcActions.SdcAction) {
  switch (action.type) {
    case sdcActions.LOAD_SDC_STUDENTS:
      return { ...state, loading: true };

    case sdcActions.LOAD_SDC_STUDENTS_SUCCESS: {
      const { students, flattenedStudents, joins, projection, columnKeys } = action.payload;
      return {
        ...state,
        loaded: true,
        loading: false,
        students: merge(state.students, students),
        flattenedStudents: merge(state.flattenedStudents, flattenedStudents),
        joins: merge([...state.joins], joins),
        projection: { ...state.projection, ...projection },
        columnKeys: [...state.columnKeys, ...columnKeys],
      };
    }

    case sdcActions.UPDATE_SDC_STUDENTS_SUCCESS: {
      const { students, flattenedStudents } = action.payload;
      const { students: storeStudents, flattenedStudents: storeFlattenedStudents } = state;
      return {
        ...state,
        loaded: true,
        flattenedStudents: merge({ ...storeFlattenedStudents }, flattenedStudents),
        students: merge({ ...storeStudents }, students),
      };
    }

    case sdcActions.UPDATE_SDC_STUDENT_SUCCESS: {
      const { student, flattenedStudent } = action.payload;
      const { students, flattenedStudents } = state;
      return {
        ...state,
        flattenedStudents: { ...flattenedStudents, flattenedStudent },
        students: { ...students, student },
      };
    }

    case sdcActions.SET_SDC_GRID_STATE: {
      const { sdcKey, state: gridState } = action.payload;
      return {
        ...state,
        gridStates: {
          ...state.gridStates,
          [sdcKey]: gridState,
        },
      };
    }

    default:
      return state;
  }
}
