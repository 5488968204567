<base-modal class="base-modal program-point-modal" [title]="title" isProfileMode="false">
 <!-- Forms -->
  <ng-container [ngSwitch]="modalControl">
    <add-caring-adult
      *ngSwitchCase="'ADD_CARING_ADULT'"
      [schoolId]="schoolId"
      ></add-caring-adult>
    <remove-caring-adult 
      *ngSwitchCase="'REMOVE_CARING_ADULT'"
      [caringAdultInfo]="caringAdultInfo" 
      [schoolId]="schoolId"></remove-caring-adult>
    <manage-caseload
      *ngSwitchCase="'MANAGE_CASELOAD'"
      [caringAdultInfo]="caringAdultInfo" 
      [schoolId]="schoolId"
    ></manage-caseload>
    <manage-caring-adult
      *ngSwitchCase="'ASSIGN_CARING_ADULT'"
      [studentId]="studentId"
      [schoolId]="schoolId">
    </manage-caring-adult>
    <manage-caring-adult
      *ngSwitchCase="'MANAGE_CARING_ADULT'"
      [studentId]="studentId"
      [schoolId]="schoolId"
      [caringAdultInfo]="caringAdultInfo">
  </manage-caring-adult>
  </ng-container>

  <!-- Buttons -->
  <mat-dialog-actions class="dialog-actions button-group">
    <nv-button type="secondary" (clickButton)="onCancel()">
      Cancel
    </nv-button>
    <nv-button htmlType="submit" (clickButton)="onSave()">
      {{ submitButtonText }}
    </nv-button>
  </mat-dialog-actions>
</base-modal>