import { getSchool } from '../../../../store/selectors/school-selectors';
import { catchError, mapTo, take } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UtilitiesService } from 'Src/ng2/shared/services/utilities/utilities.service';

@Injectable()
export class CurrentSdcSchoolGuard implements CanActivate {
  constructor (
    private store: Store<any>,
  ) {}

  canActivate (): Observable<boolean> {
    return this.store.pipe(
      select(getSchool),
      take(1),
      mapTo(true),
      catchError(() => of(false)),
    );
  }

  canActivateChild (): Observable<boolean> {
    return this.canActivate();
  }
}
