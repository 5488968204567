// tslint:disable: jsdoc-format
import { Component, HostBinding, Inject, ElementRef, ViewEncapsulation } from '@angular/core';
import { TOOLTIP_DATA } from './nv-tooltip.directive';
import { isTooltipContent, ITooltipContent, ITooltipData } from './nv-shared-tooltip.interface';
/**
 *
 * # Using the `nvTooltip` Directive
 * Use the `nvTooltip` directive on any element to create a tooltip.
 * Pass in a `tooltipData` prop to populate the tooltip. `tooltipData` must meet the `ITooltipData` interface
 *
```
interface ITooltipData {
  type?: 'sync' | 'async';
  calc?: string;
  content?: string | ITooltipContent;
}

interface ITooltipContent {
  headers?: Array<string>;
  rowData: Array<Array<string>>;
}
```
 *
 * Example:
 * `<div nvTooltip [tooltipData]={...}></div>`
 *
 * # Tooltip content
 * Content is populated in the tooltip using the `content` property on the `tooltipData` object.
 *
 * ## Synchronous content
 *
 * The most common way of implementing a tooltip, synchronous content is content passed in directly into the `tooltipData` object.
 * There are two ways of passing in synchronous data: Strings and `ITooltipContent` Objects.
 *
 * ### Strings
 * If the content of your tooltip a basic string, just pass in the string as `tooltipData.content`.
 *
 * ### Tables & Lists
 * If your content is more complex, pass in an `ITooltipContent` shaped object as `tooltip.content`.
 *
 * Notice there is no `style` option on `ITooltipContent`. The tooltip will automatically apply the appropriate styles based on the shape of the data passed in.
 *
 * #### `rowData`
 * A 2-dimensional array that defines the content. The shape of this array defines how the list/table will render.
 * For example, a [list-style](#simple-list) tooltip is rendered with single-element arrays:
 *
```js
simpleList: {
  rowData: [
    ['Needs Social studies support'],
    ['Needs ELA support']
  ],
}
```
 *
 * You can pass as many elements to the row-arrays as you need.
 * A special case is a 2-column table, which can be rendered either [inline](#two-column-list),
 * or [stacked](#stacked-list-long-label) depending on the length of the data in the first column.
 * The threshold for _inline_ vs _stacked_ list is `24 characters` in the first column.
 *
 * Alternatively, a [table-style] tooltip could be rendered by passing multi-element arrays as `rowData`:
 *
```js
table: {
  rowData: [
    ['Admin 1', 'A', 'B', 'Sept 23'],
    ['Admin 2', '—', 'C', 'Nov 28'],
    ['Admin 3', 'C', 'D', 'Mar 16']
  ],
}
```
 *
 * #### `headers`
 * An optional array that defines the column headers of a list or table.
 * Header arrays do not necessarily need to be the same length (number of columns) as the `rowData`
 * Examples:
 *
 *  * [Simple list with header](#simple-list-with-header)
 *  * [Two Column With Header](#two-column-with-header)
 *  * [Table With Headers](#table-with-headers)
 *  * [Table With Long Label](#table-with-long-label)
 *
 * More examples can be found in `shared/nv-tooltip/nv-tooltip.data.ts`.
 *
 * ### Caveats
 * Currently grouped tables (tables with multiple headers) are unsupported.
 * The philosophy is that if you need to present this much data, there's likely a better design solution.
 *
 * The tooltip does not yet support adding non-text elements (like pills or icons) in the tooltip.
 *
 * The tooltip also does not yet support formatting text (i.e. bold, underline, line breaks).
 *
 */
@Component({
  selector: 'nv-tooltip',
  templateUrl: './nv-tooltip.component.html',
  styleUrls: ['./nv-tooltip.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NvTooltipComponent {
  constructor (@Inject(TOOLTIP_DATA) public tooltipData: ITooltipData, private tooltipElementRef: ElementRef) {}

  private MAX_LABEL_LENGTH = 24;

  get contentIsString (): boolean {
    return typeof this.tooltipData.content === 'string';
  }

  get contentIsValid (): boolean {
    if (typeof this.tooltipData.content === 'string') return true;
    else return isTooltipContent(this.tooltipData.content);
  }

  @HostBinding('class.nv-tooltip-component') isTooltipClass = true;

  // The default styling assumes a plain string
  @HostBinding('class.nv-tooltip-is_string') isStringClass = this.contentIsString;

  // Style the first column differently if it's the only column vs first of many
  @HostBinding('class.nv-tooltip-is_one_column') isOneColClass = this.isSingleColumn;

  // Style each 'row' as a stacked list if the label is too long unless preserveTwoColumnLayout is specified
  @HostBinding('class.nv-tooltip-is_stacked') isStackedClass = this.isStacked;

  // Style things differently if there's more than two columns
  @HostBinding('class.nv-tooltip-is_table') isTableClass = this.isTable;

  ngAfterContentInit (): void {
    this.setTableColumnCustomProperty();
  }

  setTableColumnCustomProperty () {
    // Need to explicitly state the number of columns for CSS grid to work
    // See scss file for more details
    this.tooltipElementRef.nativeElement.style.setProperty('--table-column-count', this.countColumns);
  }

  get isSingleColumn (): boolean {
    if (typeof this.tooltipData.content === 'string' || !this.contentIsValid) {
      return false;
    } else {
      return (this.tooltipData.content.rowData as any).every(row => row.length === 1);
    }
  }

  // If the label of a 2 column list is too long (MAX_LABEL_LENGTH),
  // the `value` (column 2) should appear below the label.
  // unless preserveTwoColumnLayout is specified as true
  get isStacked (): boolean {
    if (
      !this.contentIsValid ||
      typeof this.tooltipData.content === 'string' ||
      this.isSingleColumn ||
      this.isTable ||
      this.tooltipData.content?.options?.preserveTwoColumnLayout
    ) {
      return false;
    } else {
      const { rowData, typeOfTable } = (this.tooltipData.content as ITooltipContent);
      // if some have a label & it's long
      const rowLabels = rowData.map(([label]) => label);
      const longestLabelLength = Math.max(...rowLabels.map(item => item.length));

      // if we need a stacked table regardless of the length of the label
      const isTypeOfTableStacked: boolean = typeOfTable === 'stackedTable';

      return longestLabelLength >= this.MAX_LABEL_LENGTH || isTypeOfTableStacked;
    }
  }

  get isTable (): boolean {
    if (typeof this.tooltipData.content === 'string' || !this.contentIsValid) {
      return false;
    } else {
      return (
        this.tooltipData.content.template === 'groupedTables' ||
        this.tooltipData.content.rowData.some(row => row.length > 2) ||
        (this.tooltipData.content.rowData.some(row => row.length === 2) && this.tooltipData.content.headers?.length === 2)
      );
    }
  }

  get countColumns (): number {
    if (this.isTable) {
      const content = this.tooltipData.content as ITooltipContent;
      const isGroupedTables = content.template === 'groupedTables';
      return isGroupedTables ? 1 : content.rowData[0].length;
    } else {
      return 0;
    }
  }
}
