import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';

import { BaseModalComponent } from '../base-modal.component';

export interface IResultsModalData {
  metaData: any;
  title: string;
}

export interface IResultModalEventData {
  type: 'PARTIAL_SUCCESS';
  failedStudentIds: string[];
}

@Component({
  selector: 'results-modal',
  templateUrl: './results-modal.component.html',
  styleUrls: ['./results-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ResultsModalComponent extends BaseModalComponent implements OnInit {
  public itemCount: number;
  public itemType: string = 'student';
  public title: string;
  public validMessage: string;
  public invalidMessage: string;
  public errorMessage: string;
  public actionBtnText: string;
  public invalidStudentIds: string[];
  public showButtons: boolean = true;

  constructor (
    dialogRef: MatDialogRef<ResultsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super(dialogRef);
  }

  ngOnInit (): void {
    const { metaData, title } = cloneDeep(this.data);
    this.title = title || 'Action results';
    this.invalidStudentIds = metaData.invalidStudentIds;
    this._createTaskActionResults(metaData);
    this.showButtons = !metaData.hideButtons;
  }

  public onCancel (): void {
    this.dialogRef.close();
  }

  public viewStudents (): void {
    const eventData: IResultModalEventData = {
      type: 'PARTIAL_SUCCESS',
      failedStudentIds: this.invalidStudentIds,
    };
    this.dialogRef.close(eventData);
  }

  private _createTaskActionResults (data: any): void {
    const { validStudentIds, invalidStudentIds } = data;
    this.itemCount = validStudentIds.length + invalidStudentIds.length;
    const successText = validStudentIds.length > 1 ? 'students' : 'student';
    const errorText = invalidStudentIds.length > 1 ? 'students' : 'student';
    const studentPronoun = invalidStudentIds.length > 1 ? 'These' : 'This';
    const studentVerb = invalidStudentIds.length > 1 ? 'are' : 'is';
    this.actionBtnText = `View ${invalidStudentIds.length} ${errorText}`;
    this.validMessage = `Task created for ${validStudentIds.length} ${successText}`;
    this.invalidMessage = `Task failed for ${invalidStudentIds.length} ${errorText}`;
    this.errorMessage = `An error occurred while creating tasks for ${invalidStudentIds.length} ${errorText}. ${studentPronoun} ${errorText} ${studentVerb} not in the recipient's caseload.`;
  }
}
