import { Pipe, PipeTransform } from '@angular/core';
import { TMatDisplayOption } from '../transform-to-mat-display-options/transform-to-mat-display-options.pipe';

@Pipe({ name: 'appendEmptyStateOption' })
export class AppendEmptyStateOption implements PipeTransform {
  transform (options: TMatDisplayOption[], emptyStateText: string): TMatDisplayOption[] {
    const updatedOptions = [...options];
    if (emptyStateText && !options.length) {
      updatedOptions.push({ selectable: false, human: emptyStateText });
    }
    return updatedOptions;
  }
};
