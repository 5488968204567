import { Injectable } from '@angular/core';
import { SnackBarService } from 'Src/ng2/shared/services/snackbar/snackbar.service';
import { MixpanelService } from 'Src/ng2/shared/services/mixpanel/mixpanel.service';
import { IGridShareUrlMetadata, getGridShareUrlEvent } from 'Src/ng2/shared/services/mixpanel/event-trackers/server-side-grid';
import { TValidPartnerTypes } from 'Src/ng2/shared/typings/interfaces/partner.interface';
import { EVENT_TYPES } from 'Src/ng2/shared/typings/interfaces/mixpanel.interface';

@Injectable({
  providedIn: 'root',
})
export class ClipboardService {
  constructor (
    private snackBarService: SnackBarService,
    private mixpanelService: MixpanelService,
  ) {}

  async copyToClipboard (url: string, contextPartnerType: TValidPartnerTypes): Promise<void> {
    try {
      await navigator.clipboard.writeText(url);
      const toastText = 'Link copied to clipboard';
      this.snackBarService.showToast({ toastText });
      const metaData: IGridShareUrlMetadata = {
        eventType: EVENT_TYPES.CREATED,
        contextPartnerType,
        url,
      };
      const event = getGridShareUrlEvent(metaData);
      this.mixpanelService.trackEvents([event]);
    } catch (err) {
      const errText = `Failed to copy text to clipboard: ${err}`;
      this.snackBarService.showToast({ toastText: errText });
    }
  }
}
