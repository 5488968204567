import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ToggleService } from '../../shared/services/toggle/toggle.service';
import * as toggleActions from '../actions/toggle-actions';

@Injectable()
export class ToggleEffects {
  constructor(private actions$: Actions, private toggleService: ToggleService) {}

  loadToggles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(toggleActions.LOAD_TOGGLES),
      switchMap((action: { type: string; payload: object }) => {
        const configOptions = action.payload;
        return this.toggleService.getAllComputedToggles(configOptions).pipe(
          switchMap(toggle => [new toggleActions.LoadTogglesSuccess(toggle)]),
          catchError(error => of(new toggleActions.LoadTogglesFail(error))),
        );
      }),
    );
  })
}
