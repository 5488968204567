export class MergeUtilities {
  constructor() {
    //
  }

  static deepMergeObjs(...args: Array<object>): object {
    const toString = {}.toString;
    const result = {};
    let src;
    let p;

    while (args.length > 0) {
      src = args.splice(0, 1)[0];
      if (toString.call(src) === '[object Object]') {
        for (p in src) {
          // todo: add coverage
          /* istanbul ignore next */
          if (src.hasOwnProperty(p)) {
            if (toString.call(src[p]) === '[object Object]') result[p] = this.deepMergeObjs(result[p] || {}, src[p]);
            else result[p] = src[p];
          }
        }
      }
    }
    return result;
  }
}
