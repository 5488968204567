import { FormGroup } from '@angular/forms';
import { AddCheckInLogComponent } from './add-check-in-log/add-check-in-log.component';
import { BaseModalComponent } from './../base-modal.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';

/* eslint-disable no-unused-vars */
export enum ECFIKStatusOptions {
  COMPLETED = 'Completed',
  NO_RESPONSE = 'No response',
  INACTIVE_PHONE = 'Inactive phone',
}

export enum NeedsOptions {
  ACADEMIC_SUPPORT = 'Academic support',
  AFTER_SCHOOL_ACTIVITIES = 'After-school activities',
  APPLYING_FOR_BENEFITS = 'Applying for benefits',
  BUSING = 'Busing',
  CASH_ASSISTANCE = 'Cash assistance',
  CHILDCARE_CARETAKER = 'Childcare/caretaker',
  CLOTHING_LAUNDRY = 'Clothing/laundry',
  DOCUMENTATION = 'Documentation',
  DOMESTIC_VIOLENCE_HELP = 'Domestic violence help',
  EDUCATION_FOR_ADULTS = 'Education for adults',
  FOOD = 'Food',
  GRATITUDE_FROM_FAMILY = 'Gratitude from family',
  HOUSING = 'Housing',
  IMMIGRATION = 'Immigration',
  LEGAL_SERVICES = 'Legal services',
  MENTAL_EMOTIONAL_HEALTH = 'Mental/emotional health',
  PHYSICAL_HEALTH_ASTHMA = 'Physical health/asthma',
  SCHOOL_SUPPLIES = 'School supplies',
  SPECIAL_ED = 'Special education',
  STUDENT_IN_GOOD_SPIRITS = 'Student in good spirits',
  STUDENT_POST_GRAD_CAREER_GOALS = 'Student postgrad/career goals',
  TOO_FAR_FROM_SCHOOL = 'Too far from school',
}

export enum ECFIKFormOptionsFields {
  Date = 'logDate',
  Type = 'type',
  Status = 'status',
  Needs = 'needs',
  ServiceReferral = 'serviceReferral',
};

export interface ICheckInLog {
  notes: string;
  date: string;
  type: string;
  status: ECFIKStatusOptions;
  needs?: NeedsOptions[];
  serviceReferral?: 'Open' | 'Resolved';
}

export type TModalControl = 'ADD_CHECKIN_LOG';

export interface ICheckInLogData {
  modalControl: 'ADD_CHECKIN_LOG';
  schoolId: string;
  studentId: string;
  studentName: string;
  guardianName: string;
}

@Component({
  selector: 'check-in-log-modal',
  templateUrl: 'check-in-log-modal.component.html',
  styleUrls: ['./check-in-log-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CheckInLogModalComponent extends BaseModalComponent implements OnInit {
  public modalControl: TModalControl;
  public tite: string;
  public schoolId: string;
  public studentId: string;
  public studentName: string;
  public guardianName: string;
  public noteForm: FormGroup;
  public canSave: boolean = false;
  readonly DATE_FORMAT: string = 'YYYY-MM-DD';

  public modalControlToTitleAndChildFormMap: {[key: string]: { title: string; childForm: string} } = {
    ADD_CHECKIN_LOG: {
      title: 'Add note',
      childForm: 'addCheckInLogComponent',
    },
  };

  @ViewChild(AddCheckInLogComponent) addCheckInLogComponent: AddCheckInLogComponent;

  constructor (
    dialogRef: MatDialogRef<CheckInLogModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ICheckInLogData,
  ) {
    super(dialogRef);
  }

  ngOnInit (): void {
    this.setData();
    this.title = this.getTitle(this.modalControl);
  }

  onOptionChange (status): void {
    if (status === 'VALID') {
      this.canSave = true;
    } else {
      this.canSave = false;
    }
  }

  private setData () {
    this.modalControl = this.data.modalControl;
    this.studentId = this.data.studentId;
    this.schoolId = this.data.schoolId;
    this.studentName = this.data.studentName;
    this.guardianName = this.data.guardianName;
  }

  private getTitle (modalControl: TModalControl): string {
    return this.modalControlToTitleAndChildFormMap[modalControl].title;
  }

  public onSave (): void {
    const childForm = this.modalControlToTitleAndChildFormMap[this.modalControl].childForm;
    const payload = this[childForm].getPayload();
    super.close(payload);
  }

  public onCancel () {
    super.close();
  }
}
