import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { LoadShelterSuccess, LOAD_SHELTER } from '../../actions/shelter-actions/shelter-actions';

@Injectable()
export class ShelterEffects {
  constructor (private actions$: Actions) {}

  loadShelter$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<any>(LOAD_SHELTER),
      map(data => {
        return new LoadShelterSuccess(data.payload);
      }),
    );
  });
}
