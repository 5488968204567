/** HOW TO USE THIS FILE?  */

/* This file holds a set of constants (eg. targing params, urls, fragments, or etc...) for mapping deprecated one to new one */

// This constant only targets the params
// 1. first level, key by state name, in the future, could be key by url name
// 2. second level, key by param name
// 3. third level, key-value pair: key is the deprecated param value, value is the new param value
export const DEPRECATED_ROUTE_PARAMS_BY_SCHOOL_ROUTE_STATE = {
  '/lists/mid-level': {
    focus: {
      CHRONIC_ABSENTEEISM: '[dash_foci_att_risk_group_based_on_school_type]',
    },
  },
  '/lists/supports': {
    sortKey: {
      STUDENT_SUPPORT_STUDENT_NAME: 'STUDENT_SUPPORT_STUDENT_NAME_WITH_LOGS',
    },
  },
  '/lists/attendance-list': {
    sortKey: {
      STUDENT_NAME: 'STUDENT_NAME_WITH_LOGS',
    },
  },
  '/lists/academic-list': {
    sortKey: {
      STUDENT_NAME: 'STUDENT_NAME_WITH_LOGS',
    },
  },
  '/lists/screeners-list': {
    sortKey: {
      STUDENT_NAME: 'STUDENT_NAME_WITH_LOGS',
    },
  },
  '/lists/postsecondary-list': {
    sortKey: {
      STUDENT_NAME: 'STUDENT_NAME_WITH_LOGS',
    },
  },
  '/lists/formative-assessments-list': {
    sortKey: {
      STUDENT_NAME: 'STUDENT_NAME_WITH_LOGS',
    },
  },
};
