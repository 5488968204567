<div class="nv-wizard-table regents-planning-table">
  <div class="app-headline mat-padding no-pad-left">
    <h3>Regents Plans by Regents Needed to be On-Track</h3>
    <h5>
      This table contains
      <span *ngIf="tableToggleValue === 'CAT_5'">active students planned for a Regents/Local diploma.</span>
      <span *ngIf="tableToggleValue === 'CAT_9'">active students planned for an Advanced Regents diploma.</span>
      <span *ngIf="tableToggleValue === 'MIN_REQ'">all active students.</span>
    </h5>
  </div>

  <div class="table-layout">
    <div class="nv-table-container">
      <table class="nv-table nv-table-dense">
        <thead>
          <tr>
            <th></th>
            <th *ngFor="let columnHeader of tableHeaders">{{columnHeader.humanName}}</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let row of table | keyvalue">
            <td>{{helper[row.key].language}}</td>
            <td [ngClass]="helper[row.key].style" *ngFor="let cell of row.value| keyvalue: orderTableRows">
              <wizard-filter-actions [data]="cell.value" [options]="tableOptions"></wizard-filter-actions>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="summary-layout">
      <div class="wizard-table-summary">
        <div class="side-calc-container summary">
          <div class="calc-count count">
            <wizard-filter-actions [data]="studentsWithUnadressedNeeds" [options]="unaddressedOptions">
            </wizard-filter-actions>
          </div>
          <div class="calc-text description mat-body-2">
            of
            <wizard-filter-actions [data]="studentIdsByGradPlan" [options]="unaddressedOptions"></wizard-filter-actions>
            active students <span *ngIf="tableToggleValue === 'CAT_5'">planned for a Regents/Local diploma</span>
            <span *ngIf="tableToggleValue === 'CAT_9'">planned for an Advanced Regents diploma</span> have
            unaddressed Regents needs
          </div>
        </div>
      </div>

      <mat-radio-group class="nv-mat-radio-group" [(ngModel)]="tableToggleValue" (change)="buildTable()">
        <mat-radio-button value="CAT_5">
          <span class="mat-caption">Regents/Local Diploma only</span>
        </mat-radio-button>

        <mat-radio-button value="CAT_9">
          <span class="mat-caption">Advanced Regents Diploma only</span>
        </mat-radio-button>

        <mat-radio-button value="MIN_REQ">
          <span class="mat-caption">Minimum Required for Graduation</span>
        </mat-radio-button>
      </mat-radio-group>

    </div>
  </div>

  <div class="wizard-table-summary">
    <div class="bottom-calc-container">
      <div class="app-headline mat-padding">
        <h3 class="mat-headline-6 summary-title">Progress to College Readiness</h3>
        <h5>Active students only.</h5>
      </div>


      <div class="calc-layout">
        <div class="summary mat-padding">
          <span class="count">
            <wizard-filter-actions [data]="studentCollegeReadinessData.ELA.notReady" [options]="readinessOptionsEla">
            </wizard-filter-actions>
          </span>
  
          <div class="description mat-body-2">
            of the
            <wizard-filter-actions [data]="studentCollegeReadinessData.ELA.fulfilled" [options]="readinessOptionsEla">
            </wizard-filter-actions>
            who have fulfilled ELA with a 65+ score have not reached CR in ELA.
            <wizard-filter-actions [data]="studentCollegeReadinessData.ELA.notScheduled" [options]="readinessOptionsEla">
            </wizard-filter-actions>
            of the
            <wizard-filter-actions [data]="studentCollegeReadinessData.ELA.notReady" [options]="readinessOptionsEla">
            </wizard-filter-actions>
            are not currently planned to resit for CC ELA in {{nextAdminDate}}
          </div>
        </div>
        
        <div class="summary mat-padding">
          <span class="count">
            <wizard-filter-actions [data]="studentCollegeReadinessData.Math.notReady" [options]="readinessOptionsMath">
            </wizard-filter-actions>
          </span>
          <div class="description mat-body-2">
            of the
            <wizard-filter-actions [data]="studentCollegeReadinessData.Math.fulfilled" [options]="readinessOptionsMath">
            </wizard-filter-actions>
            who have fulfilled Math with a 65+ score have not reached CR in Math.
            <wizard-filter-actions [data]="studentCollegeReadinessData.Math.notScheduled"
              [options]="readinessOptionsMath"></wizard-filter-actions>
            of the
            <wizard-filter-actions [data]="studentCollegeReadinessData.Math.notReady" [options]="readinessOptionsMath">
            </wizard-filter-actions>
            are not currently planned to resit for CC Alg in {{nextAdminDate}}
          </div>
        </div>
      </div>
    </div>

    <div class="bottom-calc-container">
      <div class="app-headline mat-padding">
        <h3 class="mat-headline-6 summary-title">Portal Plans vs STARS Scheduling</h3>
        <h5>Active students only.</h5>
      </div>

      <div class="calc-layout">
        <div class="summary mat-padding">
          <span class="count">
            <wizard-filter-actions [data]="portalMismatchData.mismatchedStudents" [options]="portalToStarsOptions">
            </wizard-filter-actions>
          </span>
          <div class="description mat-body-2">
            students are scheduled for {{portalMismatchData.totalExams}} exams in STARS, but are not planned in
            the Portal
          </div>
        </div>

        <div class="summary mat-padding">
          <span class="count">
            <wizard-filter-actions [data]="starsMismatchData.mismatchedStudents" [options]="portalToStarsOptions">
            </wizard-filter-actions>
          </span>
          <div class="description mat-body-2">
            students are scheduled for {{starsMismatchData.totalExams}} exams in the Portal, but are not planned
            in STARS
          </div>
        </div>
      </div>


    </div>
  </div>
</div>