import { IUser } from 'Src/ng2/shared/typings/interfaces/user.interface';
import { ISchoolClusterUserListMoreColmnData } from 'Src/ng2/network/network-settings/network-settings-more-button/network-settings-more-button.component';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Direction, TSortDirections } from 'Src/ng2/shared/services/list-services/sort-and-filter.service';

export type TClusterUserModalViewMode = 'CREATE' | 'EDIT' | 'PORTFOLIO' | 'REMOVE';

export const CLUSTER_USER_BTN_CONFIG = {
  CREATE: 'Create',
  EDIT: 'Save',
  NEXT: 'Continue',
  CREATE_LONG: 'Create user',
  EDIT_LONG: 'Save changes',
};

export const SCHOOL_CLUSTER_PORTFOLIO_COLUMNS = [
  {
    columnName: 'Name',
    columnOrder: 0,
    graphQlKey: 'SCHOOL_NAME',
  },
  {
    columnName: 'dbn',
    columnOrder: 1,
    graphQlKey: 'SCHOOL_DBN',
  },
  {
    columnName: 'permissions',
    columnDataFormat: 'EDITABLE_DROPDOWN',
    columnOrder: 2,
    graphQlKey: 'SCHOOL_PERMISSION',
  },
];

export const SCHOOL_CLUSTER_PORTFOLIO_LIST_COMPONENT_CONFIG = {
  searchboxPlaceholder: 'Find a school...',
  sortKey$: new BehaviorSubject('SCHOOL_NAME'),
  groupingColumnKey: 'SCHOOL_PERMISSION',
  sortDirection$: new BehaviorSubject<TSortDirections>(Direction.asc),
  columns: SCHOOL_CLUSTER_PORTFOLIO_COLUMNS,
  columnIndexMap: SCHOOL_CLUSTER_PORTFOLIO_COLUMNS.reduce((mapping, col: any, i: number) => {
      mapping[col.graphQlKey] = i;
      return mapping;
    }, {}),
  listConfig: {
    emptyTableMessage: 'No schools at this permission level',
    listType: 'USER_SCHOOL_PORTFOLIO',
    noDataMessage: 'No schools to display',
    sortableColumns: false,
    maximumVisibleRowsPerGroup: 5,
  },
};

export interface IClusterUserIdentifier {
  name: string;
  id: string;
}

export interface IClusterUserModalShellData {
  schoolClusterUserGroupingColumns?: string[];
  clusterId?: string;
  user?: IClusterUserIdentifier;
  mode: TClusterUserModalViewMode;
  currentUser?: IUser;
  hybridClusterUserContext?: { [adminsType: string]: ISchoolClusterUserListMoreColmnData['shelterClusterAdmins'] };
}
