import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { Notifications } from './notifications.component';

@NgModule({
  declarations: [Notifications],
  imports: [CommonModule],
  exports: [Notifications],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class NotificationsModule {}
