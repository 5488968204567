export type TValidGapPlanBackendStatuses = 'APPLIED' | 'ACTIVE' | 'DELETED';

export type TValidGapPlanFrontendStatuses = 'APPLIED' | 'PENDING' | 'PAST' | 'FUTURE' | 'REMOVED' | 'INVALID';

export interface IGapPlanStatuses {
  backend: {
    APPLIED: TValidGapPlanBackendStatuses;
    ACTIVE: TValidGapPlanBackendStatuses;
    DELETED: TValidGapPlanBackendStatuses;
  };
  frontend: {
    APPLIED: TValidGapPlanFrontendStatuses;
    PENDING: TValidGapPlanFrontendStatuses;
    PAST: TValidGapPlanFrontendStatuses;
    FUTURE: TValidGapPlanFrontendStatuses;
    REMOVED: TValidGapPlanFrontendStatuses;
    INVALID: TValidGapPlanFrontendStatuses;
  };
}

export const GapPlanStatuses: IGapPlanStatuses = {
  backend: {
    APPLIED: 'APPLIED',
    ACTIVE: 'ACTIVE',
    DELETED: 'DELETED',
  },
  frontend: {
    APPLIED: 'APPLIED', // applied current term -- these should be ignored in credit gap cal
    PENDING: 'PENDING', // active current term -- these should NOT be ignored in credit gap cal
    PAST: 'PAST', // active past term -- these should be ignored in credit gap cal
    FUTURE: 'FUTURE', // active future term -- these should NOT be ignored in credit gap cal
    REMOVED: 'REMOVED', // deleted -- these should be ignored in credit gap cal
    INVALID: 'INVALID', // active but not valid -- these should be ignored in credit gap cal
  },
};
