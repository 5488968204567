import { Injectable } from '@angular/core';
import { isFinite, reduce, round } from 'lodash';
import { IFlag } from '../../../shared/typings/interfaces/flags.interface';
import { IListColumnData } from '../../../shared/typings/interfaces/list-view.interface';
import * as moment from 'moment';

@Injectable()
export class ListCellFormattingService {

  genericFormatter (columnData: any): any {
    const formattedData: IListColumnData = {
      data: columnData,
      style: 'center',
    };

    if (columnData === null || columnData === 0 || columnData === '0') {
      formattedData.data = '—';
      formattedData.style = 'disabled center';
    }
    return formattedData;
  }

  ireadyFormatter (columnData: { latestScore: number; tooltipMsg: string }): any {
    const latestScore = columnData.latestScore;
    const formattedData: IListColumnData = {
      data: latestScore,
      tooltipMsg: columnData.tooltipMsg,
      style: 'center',
    };
    if (latestScore === null) {
      formattedData.data = '—';
      formattedData.style = 'disabled center';
    }
    return formattedData;
  }

  stateExamFormatter (columnData: { latestScore: number; tooltipMsg: string }): any {
    const latestScore = columnData.latestScore;
    const formattedData: IListColumnData = {
      data: latestScore,
      tooltipMsg: columnData.tooltipMsg,
      style: 'center',
    };
    if (latestScore === null) {
      formattedData.data = '—';
      formattedData.style = 'disabled center';
    }
    return formattedData;
  }

  pointPersonFormatter (columnData: any): any {
    const pointPersonExists = columnData && columnData !== '—';
    const names = pointPersonExists ? columnData.split(' ') : '';
    const initials = pointPersonExists ? (names[0].substring(0, 1) + names[1].substring(0, 1)).toUpperCase() : null;
    const style = 'center';

    const formattedData: IListColumnData = {
      data: initials ? `<div class="person-initials">${initials}</div>` : '—',
      style,
      tooltipMsg: pointPersonExists ? columnData : '',
      tooltipClass: 'point-person-tooltip',
    };
    return formattedData;
  }

  otherStaffFormatter (columnData: any): any {
    let tooltip = '';
    if (columnData.length > 0) {
      tooltip = reduce(
        columnData,
        (str, person: any) => {
          const { firstName, lastName } = person.user;
          return str + `${firstName} ${lastName}&#13;`;
        },
        '',
      );
    }

    const data = columnData.length ? columnData.length.toString() : '—';

    const formattedData: IListColumnData = {
      data,
      style: 'center',
      tooltipMsg: tooltip,
      tooltipClass: 'point-person-tooltip',
    };

    return formattedData;
  }

  percentageFormatter (columnData: any): any {
    const formattedData: IListColumnData = {
      data: '—',
      style: 'disabled center',
    };

    if (columnData === null) {
      formattedData.data = '—';
    } else if (columnData === '0' || !isFinite(columnData)) {
      return formattedData;
    } else {
      formattedData.data = round(columnData * 100) + '%';
      formattedData.style = 'center';
    }
    return formattedData;
  }

  flagFormatter (columnData: any): any {
    const formattedData: IListColumnData = {
      data: '—',
      style: 'disabled center',
      tooltipMsg: '',
    };

    if (Array.isArray(columnData) && columnData.length > 0) {
      // set data. since we'll be displaying flag icons in the cell, there's no need
      // for data value
      formattedData.data = '';

      // set styles.
      const commonStyle = 'flags center';
      if (columnData.length === 1) formattedData.style = commonStyle + ' one-flag';
      else if (columnData.length === 2) formattedData.style = commonStyle + ' two-flags';
      else if (columnData.length > 2) formattedData.style = commonStyle + ' three-plus-flags';

      // set tooltip
      const toolTip = columnData.map((flag: IFlag) => {
        return flag.type;
      });

      formattedData.tooltipMsg = toolTip.join('<br/><br/>');
    }
    return formattedData;
  }

  dateFormatter (columnData: string): IListColumnData {
    const formattedData: IListColumnData = {
      data: '—',
      style: 'center',
    };

    if (!moment(columnData).isValid()) {
      formattedData.style = 'disabled center';
      return formattedData;
    }

    formattedData.data = moment(columnData).format('MMM D, YYYY');
    return formattedData;
  }

  arrayFormatter (columnData: Array<object>): IListColumnData {
    const formattedData: IListColumnData = {
      data: '—',
      style: 'center',
    };
    formattedData.data = columnData.length.toString();
    return formattedData;
  }

  readingGrowthFormatter (columnData: { data: string; tooltipMsg: string }): IListColumnData {
    const { data, tooltipMsg } = columnData;
    const formattedData: IListColumnData = {
      data,
      tooltipMsg,
      tooltipClass: 'fp-table-growth',
      style: 'center',
    };

    if (data === 'Declined' || data === 'No growth') {
      formattedData.style = 'center warning';
    }
    return formattedData;
  }

  moreInfoFormatter (columnData): IListColumnData {
    const hasMoreInfo = columnData !== '-';
    const tooltipMsg = hasMoreInfo ? columnData : '';
    const formattedData: IListColumnData = {
      data: hasMoreInfo ? '' : '-',
      tooltipMsg,
      style: 'center',
    };

    if (columnData !== '-') {
      formattedData.style = 'center more-info';
    }
    if (columnData.length > 100) {
      formattedData.tooltipClass = 'more-info-wide';
    }
    return formattedData;
  }

  fpScoreFormatter (columnData: { data: string; tooltipMsg: string }): IListColumnData {
    const { data, tooltipMsg } = columnData;
    const formattedData: IListColumnData = {
      data,
      tooltipMsg,
      tooltipClass: 'fp-table',
      style: 'center',
    };
    return formattedData;
  }
}
