  <div class="grad-plans-step">
    <p class="mat-body-2">
      Of {{ filteredStudents.length }}
  
      <ng-container [ngPlural]="studentsWithoutGradPlanOrDate.length">
        <ng-template ngPluralCase="0"> active students </ng-template>
        <ng-template ngPluralCase="1"> active student </ng-template>
        <ng-template ngPluralCase="few"> active students </ng-template>
        <ng-template ngPluralCase="other"> active students </ng-template>
      </ng-container>

      <!-- <span *ngIf="currentFilter">in the class of {{ currentFilter }}</span> -->
      
      <wizard-filter-actions
        [data]="studentsWithoutGradPlanOrDate"
        [options]="options"
      >
      </wizard-filter-actions>

      are missing a planned diploma type and/or planned graduation date, or have a planned graduation date in the
      past.
    </p>

    <button
      mat-raised-button
      class="step-action-button"
      (click)="navigateToGradPlanningWizard(schoolId)"
    >
      Go to Grad Planning Wizard
    </button>
  </div>

