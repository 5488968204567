<!-- ASSIGN SUCCESS MENTOR -->
<base-modal
  *ngIf="mode === 'ASSIGN'"
  class="base-modal shelter-success-mentor-shell-modal"
  [title]="modalConfig.title"
  [itemCount]="itemCount"
  [itemType]="itemType"
  [isProfileMode]="modalConfig.profile"
>
  <form [formGroup]="shelterSuccessMentorForm" autocomplete="off" *ngIf="!needsToConfirm">
    <!-- success mentor name input -->
    <nv-form-field 
      label="Mentor"
      [isInvalid]="shelterSuccessMentorForm.controls['mentorName'].invalid"
      [showInvalid]="shelterSuccessMentorForm.controls['mentorName'].invalid && !shelterSuccessMentorForm.controls['mentorName'].pristine"
    >
      <nv-textbox 
        icon="person-large-black"
        [textControl]="shelterSuccessMentorForm.controls['mentorName']"
        placeholder="Name"
        [hasClearBtn]="true"
        [showInvalid]="true"
        [showInvalidOnPristine]="false"
        (clearValue)="onClear()"
      >
      </nv-textbox>
      <!-- ERROR MESSAGES: Success Mentor Name input -->
      <ng-container *ngIf="shelterSuccessMentorForm.controls['mentorName'].errors as invalidMentorNameErr">
        <ng-container *ngIf="invalidMentorNameErr.invalid && !shelterSuccessMentorForm.controls['mentorName'].pristine">
          <nv-note class="mentor-name-hint" size="small" priority="danger"
            >Enter a valid name. No numbers or symbols are allowed.</nv-note>
        </ng-container>
      </ng-container>
    </nv-form-field>

    <!-- Frequency dropdown -->
    <nv-form-field label="Frequency">
      <nv-dropdown 
        [options]="frequencyOptions" 
        placeholder="Select a frequency"
        (selectOption)="onFrequencySelection($event)"
        [selected]="currentFrequency"
      >
      </nv-dropdown>
    </nv-form-field>

    <!-- start date picker -->
    <nv-form-field label="Start date">
      <nv-date-picker
        [dateControl]="shelterSuccessMentorForm.controls['startDate']"
        placeholder="Start date"
      >
      </nv-date-picker>
    </nv-form-field>

    <mat-dialog-actions class="dialog-actions">
      <nv-button type="secondary" (click)="onCancel()"> Cancel </nv-button>
      <nv-button (click)="onAssign()" [disabled]="isAssignButtonDisabled"> {{ modalConfig.primaryBtnText }} </nv-button>
    </mat-dialog-actions>
  </form>

  <!-- WARNING OF EXISTING IN_PROGRESS SUCCESS MENTORS CONTAINER -->
  <div *ngIf="needsToConfirm">
    {{ modalConfig.modalText.confirmText }}

    <mat-dialog-actions class="dialog-actions">
      <nv-button type="secondary" (click)="onCancel()"> Cancel </nv-button>
      <nv-button (click)="onConfirmAssign()" [disabled]="isAssignButtonDisabled"> Okay </nv-button>
    </mat-dialog-actions>
  </div>
  <!-- ------ -->

</base-modal>

<!-- BATCH ACTIONS - MARK COMPLETE or DELETE SUCCESS MENTOR -->
<ng-container *ngIf="batchActionsUpdate; else shelterProfile">
  <base-modal
  *ngIf="data.mode === 'MARK_COMPLETE' || data.mode === 'DELETE'"
  class="base-modal shelter-success-mentor-shell-modal"
  [title]="modalConfig.title"
  [itemCount]="itemCount"
  [itemType]="itemType"
  [isProfileMode]="modalConfig.profile"
  >
  <div *ngIf="!needsToConfirm">
    {{ modalConfig.modalText.batchActionsText }}
  
    <p class="undone-message">This action can't be undone.</p>

    <!-- note date input only for marking COMPLETE Success Mentoring -->
    <nv-form-field label="completion end date" *ngIf="data.mode === 'MARK_COMPLETE'">
      <nv-date-picker
        [dateControl]="endDateFormControl"
        [startLimit]="startLimit"
      ></nv-date-picker>
    </nv-form-field>

    <mat-dialog-actions class="dialog-actions">
      <nv-button type="secondary" (click)="onCancel()"> Cancel </nv-button>
      <nv-button (click)="onEdit()" [disabled]="modalConfig.buttonDisabled"> {{ modalConfig.primaryBtnText }} </nv-button>
    </mat-dialog-actions>
  </div>

    <!-- WARNING OF EXISTING IN_PROGRESS SUCCESS MENTORS CONTAINER -->
    <div *ngIf="needsToConfirm">
      {{ modalConfig.modalText.confirmText }}

      <mat-dialog-actions class="dialog-actions">
        <nv-button type="secondary" (click)="onCancel()"> Cancel </nv-button>
        <nv-button (click)="onConfirmEdit()" [disabled]="modalConfig.buttonDisabled"> {{ modalConfig.primaryBtnText }} </nv-button>
      </mat-dialog-actions>
    </div>
  </base-modal>
</ng-container>

<ng-template #shelterProfile>
  <!-- PROFILE - EDIT SUCCESS MENTOR RECORD MODAL-->
  <base-modal
    *ngIf="data.mode === 'EDIT'"
    class="base-modal shelter-success-mentor-shell-modal"
    [title]="modalConfig.title"
    [itemCount]="itemCount"
    [itemType]="itemType"
    [isProfileMode]="modalConfig.profile"
  >
  <nv-form-field label="Mentor">
    {{ successMentorName }}
  </nv-form-field>
  <nv-form-field label="Edit start date" *ngIf="data.mode === 'EDIT'">
    <nv-date-picker
      [dateControl]="startDateFormControl"
      [placeholder]="editModalStartDatePlaceHolder"
    >
    </nv-date-picker>
  </nv-form-field>

  <mat-dialog-actions class="dialog-actions">
    <nv-button type="secondary" (click)="onCancel()"> Cancel </nv-button>
    <nv-button (click)="onEdit()" [disabled]="modalConfig.buttonDisabled"> {{ modalConfig.primaryBtnText }} </nv-button>
  </mat-dialog-actions>
  </base-modal>

  <!-- PROFILE - MARK COMPLETE MODALS -->
  <base-modal
    *ngIf="data.mode === 'MARK_COMPLETE'"
    class="base-modal shelter-success-mentor-shell-modal"
    [title]="modalConfig.title"
    [itemCount]="itemCount"
    [itemType]="itemType"
    [isProfileMode]="modalConfig.profile"
  >
  <div *ngIf="!needsToConfirm">
    <nv-form-field label="Mentor">
      {{ successMentorName }}
    </nv-form-field>
    
    <nv-form-field label="end date" *ngIf="data.mode === 'MARK_COMPLETE'">
      <nv-date-picker
        [dateControl]="endDateFormControl"
        [startLimit]="startLimit"
      ></nv-date-picker>
    </nv-form-field>

    <mat-dialog-actions class="dialog-actions">
      <nv-button type="secondary" (click)="onCancel()"> Cancel </nv-button>
      <nv-button (click)="onConfirmFirstMarkCompleteModal()" [disabled]="modalConfig.buttonDisabled"> {{ modalConfig.primaryBtnText }} </nv-button>
    </mat-dialog-actions>
  </div>

    <div *ngIf="needsToConfirm">
      Are you sure you would like to {{ modalConfig.modalText.profileText }} <b>{{ successMentorName }}</b> {{ modalConfig.modalText.followUpProfileText }} for <b>{{ studentName }}</b>?

    <p class="undone-message">This action can't be undone.</p>

    <mat-dialog-actions class="dialog-actions">
      <nv-button type="secondary" (click)="onCancel()"> Cancel </nv-button>
      <nv-button (click)="onEdit()" [disabled]="modalConfig.buttonDisabled"> {{ modalConfig.primaryBtnText }} </nv-button>
    </mat-dialog-actions>
    </div>
  </base-modal>

  <!-- PROFILE - DELETE MODAL -->
  <base-modal
    *ngIf="data.mode === 'DELETE'"
    class="base-modal shelter-success-mentor-shell-modal"
    [title]="modalConfig.title"
    [itemCount]="itemCount"
    [itemType]="itemType"
    [isProfileMode]="modalConfig.profile"
  >
  <div>
    Are you sure you would like to {{ modalConfig.modalText.profileText }} <b>{{ successMentorName }}</b> {{ modalConfig.modalText.followUpProfileText }} for <b>{{ studentName }}</b>?

    <p class="undone-message">This action can't be undone.</p>

    <mat-dialog-actions class="dialog-actions">
      <nv-button type="secondary" (click)="onCancel()"> Cancel </nv-button>
      <nv-button (click)="onEdit()" [disabled]="modalConfig.buttonDisabled"> {{ modalConfig.primaryBtnText }} </nv-button>
    </mat-dialog-actions>
  </div>
  </base-modal>
</ng-template>
