export const primaryOutcomeMetrics = {
  PRIMARY_OUTCOME_METRCIS_ATT: [
    'att.ytd', 'att.currSyYtdPercentLate', 'att.last10.abs', 'att.last10.late'
  ],
  PRIMARY_OUTCOME_METRICS_REGENTS: [
    'regentsDetails.byCategory9.ela.maxScore',
    'regentsDetails.byCategory9.global.maxScore',
    'regentsDetails.byCategory9.us.maxScore',
    'regentsDetails.byCategory9.alg.maxScore',
    'regentsDetails.byCategory9.geom.maxScore',
    'regentsDetails.byCategory9.trig.maxScore',
    'regentsDetails.byCategory9.lifeSci.maxScore',
    'regentsDetails.byCategory9.lote.maxScore',
    'regentsDetails.byExam.chem.maxScore',
    'regentsDetails.byExam.physics.maxScore',
    'regentsDetails.byExam.earth.maxScore',
    'regentsDetails.byExam.livingEnv.maxScore',
  ],
};
