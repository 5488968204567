// tslint:disable:max-classes-per-file
import { Action } from '@ngrx/store';
export const LOAD_SHELTER = '[Portal] Load Shelter';
export const LOAD_SHELTER_SUCCESS = '[Portal] Load Shelter Success';

export class LoadShelter implements Action {
  readonly type = LOAD_SHELTER;
  constructor(public payload: any) {}
}

export class LoadShelterSuccess implements Action {
  readonly type = LOAD_SHELTER_SUCCESS;
  constructor(public payload: any) {}
}

// action types
export type ShelterActions = LoadShelter | LoadShelterSuccess;
