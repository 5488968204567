<div class="no-rows">
  <div
    *ngIf="backgroundImage !== 'no-rows-no-backgroundImage'"
    class="empty-state-image"
    [ngClass]="backgroundImage"
  ></div>
  <div [ngClass]="messageDivClass">
    <p class="no-result-header">{{ primaryEmptyStateHeader }}</p>
    <p [ngClass]="secondaryMessageClass">
      {{ secondaryEmptyStateMessage }} <a class="button-activator" href="{{ linkUrl }}" target="_blank">{{ linkLabel }}</a><br />
      <span *ngIf="additionalTextMessage" [ngClass]="additionalMessageClass"> {{ additionalTextMessage }} <a class="button-activator" href="{{ secondaryLinkUrl }}" target="_blank">{{ secondaryLinkLabel }}</a>
      </span>
    </p>
    <p *ngIf="showButton" class="button-container">
      <nv-button
        class="button-activator"
        type="primary"
        [disabled]="disabledButton"
        (clickButton)="onButtonClick()">{{buttonText}}
      </nv-button>
    </p>
  </div>
</div>
