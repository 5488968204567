import { IModalsConfig } from './../modals.config.interface';

export const ASSIGN_SUPPORT_MODAL_CONFIG: IModalsConfig = {
  title: 'Assign to support',
  dropdown: {
    placeholder: 'Find a support...',
  },
  buttons: {
    confirm: {
      text: 'Assign',
      disableButtonRipple: true,
    },
    cancel: {
      text: 'Cancel',
      disableButtonRipple: true,
    },
  },
};
