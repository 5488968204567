/* tslint:disable */
import { Action } from '@ngrx/store';
import { ITool } from '../../shared/typings/interfaces/tool.interface';

export const LOAD_TOOLS = '[Portal] Load Tools';
export const LOAD_TOOLS_SUCCESS = '[Portal] Load Tools Success';
export const LOAD_TOOLS_FAIL = '[Portal] Load Tools Failure';
export const CREATE_SYNC_TOOL = '[Portal] Create Sync Tool';
export const CREATE_SYNC_TOOL_SUCCESS = '[Portal] Create Sync Tool Success';
export const CREATE_SYNC_TOOL_FAIL = '[Portal] Create Sync Tool Failure';
export const QUEUE_CREATE_SYNC_TOOL_SUCCESS = '[Portal] Queue Create Sync Tool Success';
export const QUEUE_CREATE_SYNC_TOOL_FAIL = '[Portal] Queue Create Sync Tool Failure';
export const UPDATE_SYNC_TOOL = '[Portal] Update Sync Tool';
export const UPDATE_SYNC_TOOL_SUCCESS = '[Portal] Update Sync Tool Success';
export const UPDATE_SYNC_TOOL_FAIL = '[Portal] Update Sync Tool Failure';
export const QUEUE_UPDATE_SYNC_TOOL_SUCCESS = '[Portal] Queue Update Sync Tool Success';
export const QUEUE_UPDATE_SYNC_TOOL_FAIL = '[Portal] Queue Update Sync Tool Failure';
export const DELETE_SYNC_TOOL = '[Portal] Delete Sync Tool';
export const DELETE_SYNC_TOOL_SUCCESS = '[Portal] Delete Sync Tool Success';
export const DELETE_SYNC_TOOL_FAIL = '[Portal] Delete Sync Tool Failure';
export const QUEUE_DELETE_SYNC_TOOL_SUCCESS = '[Portal] Queue Delete Sync Tool Success';
export const QUEUE_DELETE_SYNC_TOOL_FAIL = '[Portal] Queue Delete Sync Tool Failure';

export interface ILoadToolsPayload {
  schoolId: string;
}
export interface ICreateSyncToolQueryField {
  [fieldName: string]: string;
}

export interface ISyncToolFilters {
  // filter is dynamic, depending on what is selected
  [filter: string]: string[];
  status: string[];
}

export interface ICreateSyncToolPayload {
  schoolId: string;
  spreadsheetName: string;
  filters: ISyncToolFilters;
  columnKeys: string[];
  autoUpdateSchedule: null | { weekDay: '0' | '1' | '2' | '3' | '4' | '5' | '6' | '*' };
}

export interface IDeleteSyncToolPayload {
  schoolId: string;
  spreadsheetId: string;
}

export interface IUpdateSyncToolPayload {
  schoolId: string;
  spreadsheetName?: string;
  filters: ISyncToolFilters;
  spreadsheetId: string;
  columnKeys?: string[];
  autoUpdateSchedule?: null | { weekDay: '0' | '1' | '2' | '3' | '4' | '5' | '6' | '*' };
  refreshData?: boolean;
}

export class LoadTools implements Action {
  readonly type = LOAD_TOOLS;
  constructor (public payload: ILoadToolsPayload) {}
}

export class LoadToolsSuccess implements Action {
  readonly type = LOAD_TOOLS_SUCCESS;
  constructor (public payload: ITool[]) {}
}

export class LoadToolsFail implements Action {
  readonly type = LOAD_TOOLS_FAIL;
  constructor (public payload: any) {}
}

export class CreateSyncTool implements Action {
  readonly type = CREATE_SYNC_TOOL;
  constructor (public payload: ICreateSyncToolPayload) {}
}

export class CreateSyncToolSuccess implements Action {
  readonly type = CREATE_SYNC_TOOL_SUCCESS;
  constructor (public payload: ITool) {}
}

export class CreateSyncToolFail implements Action {
  readonly type = CREATE_SYNC_TOOL_FAIL;
  constructor (public payload: any) {}
}

export class QueueCreateSyncToolSuccess implements Action {
  readonly type = QUEUE_CREATE_SYNC_TOOL_SUCCESS;
  constructor (public payload: ITool) {}
}

export class QueueCreateSyncToolFail implements Action {
  readonly type = QUEUE_CREATE_SYNC_TOOL_FAIL;
  constructor (public payload: any) {}
}

export class UpdateSyncTool implements Action {
  readonly type = UPDATE_SYNC_TOOL;
  constructor (public payload: IUpdateSyncToolPayload) {}
}

export class UpdateSyncToolSuccess implements Action {
  readonly type = UPDATE_SYNC_TOOL_SUCCESS;
  constructor (public payload: ITool) {}
}

export class UpdateSyncToolFail implements Action {
  readonly type = UPDATE_SYNC_TOOL_FAIL;
  constructor (public payload: any) {}
}

export class QueueUpdateSyncToolSuccess implements Action {
  readonly type = QUEUE_UPDATE_SYNC_TOOL_SUCCESS;
  constructor (public payload: any) {}
}

export class QueueUpdateSyncToolFail implements Action {
  readonly type = QUEUE_UPDATE_SYNC_TOOL_FAIL;
  constructor (public payload: any) {}
}

export class DeleteSyncTool implements Action {
  readonly type = DELETE_SYNC_TOOL;
  constructor (public payload: IDeleteSyncToolPayload) {}
}

export class DeleteSyncToolSuccess implements Action {
  readonly type = DELETE_SYNC_TOOL_SUCCESS;
  constructor (public payload: ITool) {}
}

export class DeleteSyncToolFail implements Action {
  readonly type = DELETE_SYNC_TOOL_FAIL;
  constructor (public payload: any) {}
}

export class QueueDeleteSyncToolSuccess implements Action {
  readonly type = QUEUE_DELETE_SYNC_TOOL_SUCCESS;
  constructor () {}
}

export class QueueDeleteSyncToolFail implements Action {
  readonly type = QUEUE_DELETE_SYNC_TOOL_FAIL;
  constructor (public payload: any) {}
}

export type ToolsAction =
  | LoadTools
  | LoadToolsSuccess
  | LoadToolsFail
  | CreateSyncTool
  | CreateSyncToolSuccess
  | CreateSyncToolFail
  | QueueCreateSyncToolSuccess
  | QueueCreateSyncToolFail
  | UpdateSyncTool
  | UpdateSyncToolSuccess
  | UpdateSyncToolFail
  | QueueUpdateSyncToolSuccess
  | QueueUpdateSyncToolFail
  | DeleteSyncTool
  | DeleteSyncToolSuccess
  | DeleteSyncToolFail
  | QueueDeleteSyncToolSuccess
  | QueueDeleteSyncToolFail;
