import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NvTooltipModule } from 'Src/ng2/shared/nv-tooltip/nv-tooltip.module';
import { NvDesignLibraryModule } from 'Src/nvps-libraries/design/nv-design-library.module';
import { NvMatTooltipModule } from '../../nv-mat-tooltip/nv-mat-tooltip.module';
import { PageHeaderComponent } from './page-header.component';

@NgModule({
  imports: [NvMatTooltipModule, CommonModule, NvDesignLibraryModule, FormsModule, ReactiveFormsModule, NvTooltipModule],
  declarations: [PageHeaderComponent],
  exports: [PageHeaderComponent],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class PageHeaderModule {
  /** */
}
