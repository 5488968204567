import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListContainerModule } from '../../../shared/components/list/list-container/list-container.module';
import { StudentSelSupportsPanelListDataService } from './student-sel-supports-panel-list-data/student-sel-supports-panel-list-data.service';
import { StudentSelSupportsContainer } from './student-sel-supports-panel-list.component';

@NgModule({
  declarations: [StudentSelSupportsContainer],
  imports: [ListContainerModule, CommonModule],
  exports: [StudentSelSupportsContainer],
  providers: [StudentSelSupportsPanelListDataService],
})
export class StudentSelSupportsPanelListModule {}