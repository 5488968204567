import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { cloneDeep } from 'lodash';

import { BaseModalComponent } from '../base-modal.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface IBatchEditGroupModal {
  studentGroups: any;
  title: string;
  infoText: string;
}

@Component({
  selector: 'batch-edit-group',
  templateUrl: './batch-edit-group-modal.component.html',
  styleUrls: ['./batch-edit-group-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BatchEditGroupModalComponent extends BaseModalComponent implements OnInit, OnDestroy {
  // Base modal inputs
  public title: string;
  public itemCount: number;
  public itemType: string;
  public infoText: string;

  public modalData: any;
  public groupData: any;
  constructor (
    @Inject(MAT_DIALOG_DATA) public data: any,
    dialogRef: MatDialogRef<BatchEditGroupModalComponent>,
  ) {
    super(dialogRef);
  }

  ngOnInit (): void {
    this.modalData = cloneDeep(this.data);
    this.groupData = this.modalData.studentGroups;
    this.infoText = this.modalData.infoText;
    this.title = this.modalData.title;
    this.itemCount = Object.keys(this.groupData).length;
    this.itemType = 'group';
  }

  ngOnDestroy (): void {
    super.ngOnDestroy();
  }

  public onClickPrimaryBtn (): void {
    super.close(this.groupData);
  }

  public onCancel (): void {
    this.dialogRef.close();
  }

  public getFormattedRowText (group: {key: string; value: any[]}): string {
    const arrKey: string[] = group.key.split(' ');
    const formattedKey = arrKey.join(' ');
    return `${group.value.length} students will be assigned ${formattedKey}`;
  }
}
