import { createFeatureSelector, createSelector } from '@ngrx/store';

const getPortalState = createFeatureSelector('PORTAL_STORE');

export const getGridDataSlice = createSelector(
  getPortalState,
  (state: any) => state.gridData,
);

export const getGridDataLoaded = createSelector(
  getGridDataSlice,
  (state: any) => state.loaded,
);

export const getRefreshGridDataInProgress = createSelector(
  getGridDataSlice,
  (state: any) => state.refreshGridDataRunning,
);

export const getGridDataLoading = createSelector(
  getGridDataSlice,
  (state: any) => state.loading,
);

export const getGridDataSchoolId = createSelector(
  getGridDataSlice,
  (state: any) => state.schoolId,
);

export const getGridDataGridType = createSelector(
  getGridDataSlice,
  (state: any) => state.gridType,
);

export const getGridDataColumnDefs = createSelector(
  getGridDataSlice,
  (state: any) => state.columnDefs,
);

export const getGridDataRowData = createSelector(
  getGridDataSlice,
  (state: any) => state.rowData,
);

export const getGridDataFilterHash = createSelector(
  getGridDataSlice,
  (state: any) => state.filterHash,
);

export const getGridDataStateHash = createSelector(
  getGridDataSlice,
  (state: any) => state.stateHash,
);

export const getGridDataScrollLeft = createSelector(
  getGridDataSlice,
  (state: any) => state.scrollLeft,
);

export const getGridDataScrollTop = createSelector(
  getGridDataSlice,
  (state: any) => state.scrollTop,
);

export const getGridDataNextTermPlanningRunning = createSelector(
  getGridDataSlice,
  (state: any) => state.nextTermPlanningGridRunning,
);

export const getGridDataGridViewIdHash = createSelector(
  getGridDataSlice,
  (state: any) => state.gridViewIdHash,
);
