import { PrevStateService } from 'Src/ng2/shared/services/prev-state/prev-state.service';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UrlPathService } from 'Src/ng2/shared/services/url-path-service/url-path.service';

@Component(
  {
    selector: 'navigation-header',
    templateUrl: './navigation-header.component.html',
    styleUrls: ['./navigation-header.component.scss'],
    encapsulation: ViewEncapsulation.None,
  },
)
export class NavigationHeader {
  @Input() isFullScreen: boolean;
  @Input() header: string;
  @Input() navItems: Array<{human: string, url: string, disabled: boolean}>;
  @Input() sdcKey: string;
  @Input() sdcView: string;

  private schoolId: string;
  public exitState;

  constructor (
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private prevStateService: PrevStateService,
    private urlPathService: UrlPathService,
  ) {}

  ngOnInit () {
    this.schoolId = this.activatedRoute.snapshot.params.schoolId;
    this.exitState = this.getExitUrlNavigateOpts();
  }

  navigateToUrl (urlSegment: string) {
    const url = this.urlPathService.computeDistrictUrlPath(`/school/${this.schoolId}${urlSegment}`);
    const returningToPreviousFeature = this.exitState.url === url;
    this.router.navigate([url], { state: { sdcKey: this.sdcKey }, queryParams: returningToPreviousFeature ? this.exitState.queryParams : {} });
  }

  getExitUrlNavigateOpts () {
    if (this.activatedRoute.snapshot.data.sdcView === 'grid') {
      if (this.sdcKey === 'default-sdc') {
        // For old data grid (/default-sdc/grid):
        // Makes sure it goes back to prev urls that are in current school only
        return this.prevStateService.getPrevUrlNavigateOpts({
          designatedPrevUrls: [
            `${this.schoolId}/lists`,
            `${this.schoolId}/regents-planning`,
            `${this.schoolId}/regents-prep`,
            `${this.schoolId}/credit-gaps`,
            `${this.schoolId}/grad-planning`,
            `${this.schoolId}/other-tools`,
            `${this.schoolId}/settings`,
            `${this.schoolId}/data-uploads`,
          ],
          fallbackUrl: this.urlPathService.computeDistrictUrlPath(`/school/${this.schoolId}/lists/tiles`),
        });
      } else {
        // For regular sdc grid:
        // Makes sure we can break out of the loop between sdc grid and profile
        return this.prevStateService.getPrevUrlNavigateOpts({
          designatedPrevUrls: ['wizard'],
          fallbackUrl: this.urlPathService.computeDistrictUrlPath(`/school/${this.schoolId}/lists/tiles`),
        });
      }
    } else {
      return this.prevStateService.getPrevUrlNavigateOpts({ fallbackUrl: this.urlPathService.computeDistrictUrlPath(`/school/${this.schoolId}/lists/tiles`) });
    }
  }
}
