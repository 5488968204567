import { Injectable } from '@angular/core';
import { TClusterUserModalViewMode } from 'Src/ng2/shared/modals/user-management/cluster-user/school-cluster-user/cluster-user-modals.config';

@Injectable()
export class GraphQLSchoolClusterUserHelperService {
  public getClusterUserMadLibQuery ({ clusterId, projection }): string {
    const validClusterId = clusterId ? `"${clusterId}"` : 'null';
    const columnKeys = this.getColumnKeys(projection);
    const query = `{
      SchoolClusterUserGrouping(
        clusterId: ${validClusterId}
        columns: [${columnKeys}]
      ) {
          groupingOptions{
            human
            key
          }
        }
      }`;
    return query;
  }

  public getClusterUsersQuery ({ clusterId, grouping, projection }): string {
    const validClusterId = clusterId ? `"${clusterId}"` : 'null';
    const columnKeys = this.getColumnKeys(projection);
    let query;
    if (!grouping) {
      query = `{
        SchoolClusterUserGrouping(
          clusterId: ${validClusterId}
          columns: [${columnKeys}]
        ) {
            groupData {
              human
              rowData {
                data
                meta
                columnKey
              }
            }
            groupingOptions{
              human
              key
            }
          }
      }`;
    } else {
      query = `{
        SchoolClusterUserGrouping(
          clusterId: ${validClusterId}
          groupingKey: "CLUSTER_ACCESS_LEVEL=${grouping}",
          columns: [${columnKeys}]
        ) {
            groupData {
              human
              rowData {
                data
                meta
                columnKey
              }
            }
          }
      }`;
    }
    return query;
  }

  public getClusterUserDetailQuery ({ userId }): string {
    const validatedUserId = userId === null ? `${userId}` : `"${userId}"`;
    return `{
      SchoolClusterUserDetail(
        userId: ${validatedUserId}
      ) {
          clusterId
          schoolIds
          approvedDomains
          loginMethod {
            required
            optional
          }
          userBasic {
            firstName
            lastName
            gafeEmail
            doeEmail
            title
            delegatedRole
            canManageUsers
            isClusterTrustedUser
          }
        }
    }`;
  }

  public getClusterUserSchoolsQuery ({ userId, clusterId }): string {
    const validatedUserId = userId === null ? `${userId}` : `"${userId}"`;
    const validatedClusterId = clusterId === null ? `${clusterId}` : `"${clusterId}"`;
    return `{
      ClusterUserSchools(
        userId: ${validatedUserId},
        clusterId: ${validatedClusterId}
      ) {
        key
        human
        showAll
        rowData {
          data
          meta
          columnKey
        }
      }
    }`;
  }

  public getClusterUserMutationQuery ({ userPayload: payload, columns }, mode: TClusterUserModalViewMode): string {
    const { permissions, userId, clusterId } = payload;
    let mutationName;
    let options = '';

    switch (mode) {
      case 'CREATE':
        mutationName = 'createSchoolClusterUser';
        break;
      case 'EDIT':
        mutationName = 'updateSchoolClusterUser';
        break;
      case 'REMOVE':
        mutationName = 'deleteSchoolClusterUser';
        break;
    }

    options += this._getBasicInfoOptions(payload);
    options += this._getPermissionOptions(permissions, payload.delegatedRole);

    if (userId) {
      options += `userId: "${userId}"`;
    }

    if (clusterId) {
      options += `clusterId: "${clusterId}"`;
    }

    const columnKeys = this.getColumnKeys(columns);

    return `
    mutation {
      ${mutationName}(
        options: {${options}},
        columns: [${columnKeys}]
      ) {
        groupData {
          human
          rowData {
            data
            meta
            columnKey
          }
        }
        groupingOptions{
          human
          key
        }
      }
    }`;
  }

  private getColumnKeys (projection: string[]): string[] {
    return projection.map(key => {
      return `"${key}"`;
    });
  }

  private _getBasicInfoOptions (payload): string {
    const { canManageUsers, delegatedRole, doeEmail, firstName, gafeEmail, lastName, title } = payload;
    let options = '';
    if (firstName) {
      // if firstName does not exists assume no other userBasic information exists and we are editing a portfolio not
      // userDetails
      const validatedDelegatedRole = delegatedRole === 'no_access' ? 'null' : `"${delegatedRole}"`;

      options = options += `
        title: "${title}",
        name: {
          firstName: "${firstName}",
          lastName: "${lastName}",
        },
        delegatedRole: ${validatedDelegatedRole},
        canManageUsers: ${canManageUsers},
      `;

      if (gafeEmail || doeEmail) {
        // when it is undefined?
        // 1. no optional login method
        // 2. formControls associated with them are disabled
        // when it is empty string?
        // 1. email type is optional
        const validatedGafeEmail = gafeEmail ? `"${gafeEmail}"` : 'null';
        const validatedDoeEmail = doeEmail ? `"${doeEmail}"` : 'null';
        options += `
        gafeEmail: ${validatedGafeEmail},
        doeEmail: ${validatedDoeEmail},
        `;
      }
    }
    return options;
  }

  private _getPermissionOptions (permissions, delegatedRole): string {
    let options = '';
    if (permissions) {
      const permissionsString = Object.keys(permissions)
        .map(key => {
          return `
          ${key}: ${JSON.stringify(permissions[key])}`;
        })
        .join(', ');
      options += `
        portfolio: {
          permissions: { ${permissionsString}
          }
        }
      `;
    } else if (['no_access', 'network_only'].includes(delegatedRole)) {
      // only when validatedDelegatedRole is null or network_only, then porfolio should be null - new or update mode
      options += `
        portfolio: null
      `;
    }
    return options;
  }
}
