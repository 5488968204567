
  <mat-toolbar>
    <h2>{{title}}</h2>
  </mat-toolbar>

  <ng-container *ngIf="(schoolCourseDiffs$ | async) as schoolCourseDiffs">
    <ng-container *ngIf="(schoolGapPlans$ | async) as schoolGapPlans">
      <div class="course-table">
        <find-a-course-table
          [courses]="data.allCourses"
          [termYear]="data.termDetails.value"
          [studentGapPlans]="data.studentGapPlans"
          [studentCourseDiffs]="data.studentCourseDiffs"
          [schoolGapPlans]="schoolGapPlans"
          [schoolCourseDiffs]="schoolCourseDiffs"
          [student]="data.student"
          [school]="data.school"
          [termType]="data.termDetails.termType"
          [initialFilter]="data.initialFilter"
          (newCourseDiff)="handleCourseDiff($event)"
          (newGapPlan)="handleGapPlan($event)"
        >
        </find-a-course-table>
      </div>
    </ng-container>
  </ng-container>



  <div class="action-buttons">
    <nv-button type="secondary" (click)="close()">Close</nv-button>
  </div>

