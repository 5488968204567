import { FormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { WizardFilterActionsModule } from 'Src/ng2/school/sdc/wizard-filter-actions/wizard-filter-actions.module';
import { CommonModule } from '@angular/common';
import { RegentsPlanningTable } from './regents-planning-table.component';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [CommonModule, WizardFilterActionsModule, MatRadioModule, FormsModule],
  exports: [RegentsPlanningTable],
  declarations: [RegentsPlanningTable],
  providers: [],
})
export class RegentsPlanningTableModule { }
