import { NvDropdownMenuComponent } from './nv-dropdown-menu.component';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { NvIconModule } from '../nv-icon/nv-icon.module';
import { NvIconButtonModule } from '../nv-icon-button/nv-icon-button.module';

@NgModule({
  imports: [CommonModule, MatMenuModule, MatInputModule, NvIconModule, NvIconButtonModule],
  declarations: [NvDropdownMenuComponent],
  exports: [NvDropdownMenuComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class NvDropdownMenuModule {}
