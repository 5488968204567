import { Store } from '@ngrx/store';
import { BACKGROUND_JOB_STATUS_TYPES, BACKGROUND_JOB_TIMEOUT_THRESHOLDS } from './../../shared/services/background-job/background-job.service';
import { BatchActionsEffectsUtilities } from './../utilities/batch-actions-effects-utilities';
import { IAttendanceRecord } from 'Src/ng2/shared/typings/interfaces/attendance-record.interface';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap, switchMap, takeLast, tap, map, timeout } from 'rxjs/operators';
import { ApiService } from 'Src/ng2/shared/services/api-service/api-service';
import { BackgroundJobNotificationService } from 'Src/ng2/shared/services/background-job-notification-service/background-job-notification-service';
import { BackgroundJob } from 'Src/ng2/shared/services/background-job/background-job.service';
import * as attendanceRecordsActions from '../actions/attendance-records-actions';
import { identity } from 'lodash';

@Injectable()
export class AttendanceRecordsEffects {
  batchSuccessMessage: string;
  batchFailedMessage: string;

  constructor (
    private actions$: Actions,
    private apiService: ApiService,
    private backgroundJob: BackgroundJob,
    private backgroundJobNotificationService: BackgroundJobNotificationService,
    private store: Store<any>,
  ) {
    this.batchSuccessMessage = 'Attendance records logged for selected students.';
    this.batchFailedMessage = 'Logs for attendance records failed to be recorded. Please try again.';
  }

  createAttendanceRecord$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(attendanceRecordsActions.CREATE_ATTENDANCE_RECORD),
      switchMap((action: any) => {
        const { attendanceRecord, schoolId } = action.payload;
        return this.apiService.createAttendanceRecord(attendanceRecord, schoolId).pipe(
          switchMap((attendanceRecord: IAttendanceRecord) => {
            return [new attendanceRecordsActions.CreateAttendanceRecordSuccess(attendanceRecord)];
          }),
          catchError(error => {
            return of(new attendanceRecordsActions.CreateAttendanceRecordFail(error));
          }),
        );
      }),
    );
  });

  updateAttendanceRecord$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(attendanceRecordsActions.UPDATE_ATTENDANCE_RECORD),
      switchMap((action: any) => {
        const { id, patch } = action.payload;
        return this.apiService.patchAttendanceRecord(id, patch).pipe(
          switchMap((attendanceRecord: IAttendanceRecord) => {
            return [new attendanceRecordsActions.UpdateAttendanceRecordSuccess(attendanceRecord)];
          }),
          catchError(error => {
            return of(new attendanceRecordsActions.UpdateAttendanceRecordFailure(error));
          }),
        );
      }),
    );
  });

  bulkCreateAttendanceRecords$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(attendanceRecordsActions.BULK_CREATE_ATTENDANCE_RECORDS),
      switchMap((action: any) => {
        const { payload } = action;
        const httpResponseObsv$: Observable<HttpResponse<object>> = this.apiService.bulkCreateAttendanceRecords(payload);
        return httpResponseObsv$.pipe(
          takeLast(1),
          switchMap(response => BatchActionsEffectsUtilities.getJobSubject(this.backgroundJob, { response })),
          timeout(BACKGROUND_JOB_TIMEOUT_THRESHOLDS.BATCH_ACTIONS),
          tap(({ type }) => {
            if (type === BACKGROUND_JOB_STATUS_TYPES.RESOLVE) {
              this.backgroundJobNotificationService.sendMessage({ backgroundJob: 'BulkAttendanceRecordsCreate' });
            }
          }),
          catchError(error => {
            return of(new attendanceRecordsActions.BulkCreateAttendanceRecordsFail(error));
          }),
        );
      }),
    );
  }, { dispatch: false });

  bulkUpdateAttendanceRecords$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<any>(attendanceRecordsActions.BULK_UPDATE_ATTENDANCE_RECORDS),
      switchMap(action => {
        const { payload } = action;
        const httpResponseObsv$: Observable<HttpResponse<object>> = this.apiService.bulkUpdateAttendanceRecords(payload);
        return httpResponseObsv$.pipe(
          takeLast(1),
          switchMap(identity),
          timeout(BACKGROUND_JOB_TIMEOUT_THRESHOLDS.BATCH_ACTIONS),
          tap(({ type }) => {
            if (type === BACKGROUND_JOB_STATUS_TYPES.RESOLVE) {
              this.backgroundJobNotificationService.sendMessage({ backgroundJob: 'BulkAttendanceRecordsUpdate' });
              this.store.dispatch(new attendanceRecordsActions.BulkUpdateAttendanceRecordsSuccess(payload));
            }
          }),
          catchError(error => {
            return of(new attendanceRecordsActions.BulkUpdateAttendanceRecordsFail(error));
          }),
        );
      }),
    );
  }, { dispatch: false });
}
