import { ExitViewModule } from './../../../shared/components/exit-view/exit-view.component.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MaintenanceModeModule } from 'Src/ng2/shared/components/maintenance-mode/maintenance-mode.module';
import { SchoolSingleColumnLayoutComponent } from './school-single-column-layout.component';

@NgModule({
  declarations: [SchoolSingleColumnLayoutComponent],
  imports: [CommonModule, RouterModule, MaintenanceModeModule, ExitViewModule],
  exports: [SchoolSingleColumnLayoutComponent],
})
export class SchoolSingleColumnLayoutModule {}
