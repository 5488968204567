<p class="mat-body-2">
  
  <wizard-filter-actions
    [data]="collegeReadinessElaStudentData['CC ELA'].studentIds"
    [options]="options"
  ></wizard-filter-actions>
  
  <ng-container [ngPlural]="collegeReadinessElaStudentData['CC ELA'].studentIds">
    <ng-template ngPluralCase="0"> students </ng-template>
    <ng-template ngPluralCase="1"> student </ng-template>
    <ng-template ngPluralCase="few"> students </ng-template>
    <ng-template ngPluralCase="other"> students </ng-template>
  </ng-container>
  
  have passed an ELA Regents exam with a score of 65+, but have not yet achieved a College Ready score (75+) in
  ELA, and <b>are not</b> planned to resit for CC ELA in the next administration.
</p>

<button
  mat-raised-button
  class="step-action-button"
  [disabled]="!totalStudentsWithReadinessMismatch"
  (click)="patchStudents(patches, totalStudentsWithReadinessMismatch)"
>
  Plan {{totalStudentsWithReadinessMismatch}}

  <ng-container [ngPlural]="totalStudentsWithReadinessMismatch">
    <ng-template ngPluralCase="0"> students </ng-template>
    <ng-template ngPluralCase="1"> student </ng-template>
    <ng-template ngPluralCase="few"> students </ng-template>
    <ng-template ngPluralCase="other"> students </ng-template>
  </ng-container>

</button>
