import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvNoteComponent } from './nv-note.component';
import { NvButtonModule } from '../nv-button/nv-button.module';
import { NvIconModule } from '../nv-icon/nv-icon.module';

@NgModule({
  imports: [CommonModule, NvIconModule, NvButtonModule],
  declarations: [NvNoteComponent],
  exports: [NvNoteComponent],
})
export class NvNoteModule {}
