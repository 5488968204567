import { IPathSubdoc, IStudentPath } from 'Src/ng2/shared/typings/interfaces/studentPaths.interface';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PATH_CATEGORY_KEYS } from '../../../../constants/student-paths.constant';
import { BaseStudentPathModalForm } from '../base-student-path-modal-form.component';
import { ApiService } from '../../../../../shared/services/api-service/api-service';
import { formToStudentPathSubdoc } from '../path-category-form-utils';

@Component({
  selector: 'college-form',
  templateUrl: './college-form.component.html',
  encapsulation: ViewEncapsulation.None,
})

export class CollegeForm extends BaseStudentPathModalForm implements OnInit {
  @Input() readonly isProfileMode: boolean;
  @Input() readonly isEditMode: boolean;

  public formGroup: FormGroup;
  public showStatusDropdown = true;

  // empty state text
  public readonly emptyStateCollege = 'No colleges found';
  public readonly emptyStateTransitionSupportOrg = 'No transition support organizations found';

  constructor (
    apiService: ApiService,
    private formBuilder: FormBuilder,
  ) {
    super(apiService);
  };

  ngOnInit (): void {
    this.formGroup = this.formBuilder.group({
      college: [null, Validators.required],
      transitionSupportOrg: [null],
    });

    if (!this.isProfileMode) {
      // in batch action view, only show the status dropdown if it is permissible
      // to edit the status, which is done in isEditMode
      this.showStatusDropdown = this.isEditMode;
    }

    super.initForm(PATH_CATEGORY_KEYS.COLLEGE, this.schoolId);
  }

  getStudentPath (): IPathSubdoc {
    const studentPath = formToStudentPathSubdoc(this.formGroup);
    return studentPath;
  }

  handleClick (): void {
    this.onEmitStudentPath();
  }
}
