import { omit } from 'lodash';
import * as studentPathsAction from '../actions/student-paths-actions';

export interface IStudentPathsState {
  loading: boolean;
  loaded: boolean;
  allLoaded: boolean;
  loadedEntities: object;
  studentPathsEntities: {
    [id: string]: any;
  };
}

export const initialState: IStudentPathsState = {
  loading: false,
  loaded: false,
  allLoaded: false,
  loadedEntities: {},
  studentPathsEntities: {},
};

export function reducer(
  state: IStudentPathsState = initialState,
  action: studentPathsAction.StudentPathAction,
): IStudentPathsState {
  switch (action.type) {
    case studentPathsAction.LOAD_ALL_STUDENT_PATHS: {
      return {
        ...state,
        loaded: false,
        loading: true,
        allLoaded: false,
      };
    }

    case studentPathsAction.LOAD_ALL_STUDENT_PATHS_SUCCESS:
      if (!action.payload.length) {
        return {
          ...state,
          loaded: true,
          loading: false,
          allLoaded: true,
        };
      }
      const studentPathsEntities = action.payload.reduce((accum, path) => {
        accum[path._id] = path;
        return accum;
      }, {});

      const entities = state.loadedEntities;

      const loadedEntities = action.payload.reduce((acc, path) => {
        const { studentId } = path;
        if (!state.loadedEntities[path.studentId]) entities[studentId] = path;
        return entities;
      }, entities);

      return {
        ...state,
        loaded: true,
        loading: false,
        allLoaded: true,
        loadedEntities,
        studentPathsEntities,
      };

    case studentPathsAction.LOAD_ALL_STUDENT_PATHS_FAIL: {
      return {
        ...state,
        loaded: false,
        loading: false,
      };
    }

    case studentPathsAction.LOAD_STUDENT_PATH:
      return {
        ...state,
        loading: true,
      };

    case studentPathsAction.LOAD_STUDENT_PATH_SUCCESS: {
      let { payload } = action;
      const studentId = payload.studentId;
      const loadedEntities = state.loadedEntities;
      // set to true even if payload is empty, otherwise ssv breaks (JE)
      loadedEntities[studentId] = true;

      const studentPathsEntities = payload.paths.reduce((acc, path) => {
        acc[path._id] = path;
        return acc;
      }, {});

      return {
        ...state,
        loaded: true,
        loading: false,
        loadedEntities: { ...loadedEntities },
        studentPathsEntities,
      };
    }

    case studentPathsAction.LOAD_STUDENT_PATH_FAIL:
      return { ...state, loaded: false, loading: false };

    case studentPathsAction.CREATE_STUDENT_PATH:
      return {
        ...state,
        loading: true,
      };

    case studentPathsAction.CREATE_STUDENT_PATH_SUCCESS: {
      const path = action.payload;
      const studentPathsEntities = Object.assign({}, state.studentPathsEntities);
      studentPathsEntities[path._id] = path;

      return {
        ...state,
        studentPathsEntities,
      };
    }

    case studentPathsAction.CREATE_STUDENT_PATH_FAIL:
      return { ...state, loaded: false, loading: false };

    case studentPathsAction.UPDATE_STUDENT_PATH:
      return {
        ...state,
        loading: true,
      };

    case studentPathsAction.UPDATE_STUDENT_PATH_SUCCESS: {
      const path = action.payload;
      let studentPathsEntities;

      // removes studentPaths with updated to have status 'DELETED' (JE)
      if (path.status !== 'DELETED') {
        studentPathsEntities = { ...state.studentPathsEntities, [path._id]: path };
      } else {
        // removes studentPaths with updated to have status 'DELETED' (JE)
        const entities = Object.assign({}, state.studentPathsEntities);
        studentPathsEntities = omit(entities, path._id);
      }

      return {
        ...state,
        studentPathsEntities,
      };
    }

    case studentPathsAction.UPDATE_STUDENT_PATH_FAIL:
      return { ...state, loaded: false, loading: false };

    case studentPathsAction.BULK_CREATE_STUDENT_PATH_FAIL: {
      return { ...state, loaded: false, loading: false };
    }

    case studentPathsAction.BULK_UPDATE_STUDENT_PATH_FAIL: {
      return { ...state, loaded: false, loading: false };
    }

    default:
      return state;
  }
}
