
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatStepperModule } from '@angular/material/stepper';
import { NvIconButtonModule } from '../../../../../nvps-libraries/design/nv-icon-button/nv-icon-button.module';
import { SyncedSheetModalComponent } from './synced-sheet-modal.component';
import { StudentSelectorModule } from './student-selector/student-selector.module';
import { ColumnSelectorModule } from './column-selector/column-selector.module';
import { UpdateSelectorModule } from './update-selector/update-selector.module';
import { SyncSummaryModule } from './sync-summary/sync-summary.module';

@NgModule({
  declarations: [SyncedSheetModalComponent],
  imports: [
    CommonModule,
    MatStepperModule,
    NvIconButtonModule,
    StudentSelectorModule,
    ColumnSelectorModule,
    UpdateSelectorModule,
    SyncSummaryModule,
  ],
  exports: [SyncedSheetModalComponent],
})
export class SyncedSheetModalModule {}
