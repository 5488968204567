import { IRegentsCategory } from './../../../../../../shared/constants/regents.constant';
import { ImSchool } from 'Src/ng2/shared/services/im-models/im-school';
import { RegentsCategoryByCategory9 } from './../../../../../../shared/constants/regents-category-by-category-9.constant';
import { RegentsCategoryByCategory5 } from './../../../../../../shared/constants/regents-category-by-category-5.constant';
import { GraduationPlan } from './../../../../../../shared/constants/graduation-plan.constant';
import { GraduationPlanTransfer } from './../../../../../../shared/constants/graduation-plan-transfer.constant';
import { each, includes, reduce, some, forOwn, map, filter, find } from 'lodash';
import { NextRegentsAdminDate } from './../../../../../../shared/constants/next-regents-admin-date.constant';
import { Component, Input, ViewEncapsulation } from '@angular/core';

type TValidToggles = 'CAT_5' | 'CAT_9' | 'MIN_REQ';

interface ITableHeader {
  humanName: string;
  neededOnTrackValue: string;
}

interface IBaseObject {
  [TValidBaseObjectKeys: string]: {
    [key: string]: string[];
  };
}

interface IHelper {
  [TValidBaseOBjectKey: string]: {
    language: string;
    style: string;
  };
}

interface ICollegeReadinessData {
  [TValidCollegeReadinessKeys: string]: { fulfilled: string[]; notReady: string[]; notScheduled: string[] };
}

interface IMisMatchData {
  mismatchedStudents: string[];
  totalExams: number;
}

@Component({
  selector: 'regents-planning-table',
  templateUrl: 'regents-planning-table.component.html',
  styleUrls: ['./regents-planning-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RegentsPlanningTable {
  @Input() filteredStudents;
  @Input() school;

  public nextAdminDate;
  public studentsByGradPlan; // sdc students
  public studentIdsByGradPlan: string[];
  public studentsWithUnadressedNeeds: string[];
  public portalMismatchData: IMisMatchData;
  public starsMismatchData: IMisMatchData;
  public studentCollegeReadinessData: ICollegeReadinessData;
  public tableHeaders: ITableHeader[];
  public tableToggleValue: TValidToggles = 'CAT_5';
  public activeCategories: IRegentsCategory[];
  public helper: IHelper;
  public table: IBaseObject;
  public tableOptions;
  /* eslint-disable-next-line camelcase */
  public tableOptions_diploma;
  /* eslint-disable-next-line camelcase */
  public tableOptions_grad;
  public readinessOptionsEla;
  public readinessOptionsMath;
  public portalToStarsOptions;
  public unaddressedOptions;

  constructor (private imSchool: ImSchool) {}

  ngOnInit (): void {
    this.nextAdminDate = NextRegentsAdminDate;

    this.helper = {
      NOT_PLANNED: { language: 'Needed / Not Planned', style: 'red' },
      PLANNED_THIS_ADMIN: { language: `Needed / Planned ${NextRegentsAdminDate}`, style: 'green' },
      PLANNED_FUTURE: { language: 'Needed / Planned Future Admin', style: 'yellow' },
      FULFILLED: { language: 'Fulfilled', style: 'green' },
      NOT_YET_NEEDED: { language: 'Not Yet Needed', style: 'grey' },
    };

    this.tableOptions_diploma = {
      columnKeys: [
        'regentsPassedForDiploma',
        'regentsNotYetNeededForDiploma',
        'regentsNeededForOnTrackDiplomaNotSched',
        'regentsNeededForOnTrackDiplomaSchedNextAdmin',
        'regentsNeededForOnTrackDiplomaSchedFutureAdmin',
      ],
    };

    this.tableOptions_grad = {
      columnKeys: [
        'regentsPassedForGrad',
        'regentsNotYetNeededForGrad',
        'regentsNeededForOnTrackGradNotSched',
        'regentsNeededForOnTrackGradSchedNextAdmin',
        'regentsNeededForOnTrackGradSchedFutureAdmin',
      ],
    };

    this.readinessOptionsMath = {
      columnKeys: ['maxScoreMath', 'collegeReadyOnMathRegentsOrSatMath', 'regentsSchedForNextAdmin'],
    };

    this.readinessOptionsEla = {
      columnKeys: ['maxScoreEnglishExams', 'collegeReadyOnElaRegentsOrSatCr', 'regentsSchedForNextAdmin'],
    };

    this.portalToStarsOptions = {
      columnKeys: ['examsSchedInPortalNotPlannedInStars', 'examsSchedInStarsNotPlannedInPortal'],
    };

    this.unaddressedOptions = {
      columnKeys: ['regentsNeededForOnTrackDiplomaNotSched'],
    };

    this.buildTable();
  }

  ngOnChanges ({ filteredStudents }) {
    if (filteredStudents && !filteredStudents.isFirstChange()) {
      this.ngOnInit();
    }
  }

  // wraps all table functionality for toggle/store updates
  buildTable (): void {
    this.activeCategories =
      this.tableToggleValue === 'CAT_5' || this.tableToggleValue === 'MIN_REQ' ? RegentsCategoryByCategory5.required : RegentsCategoryByCategory9.required;
    this.studentsByGradPlan = this.filterStudentsByGradPlan();
    this.studentIdsByGradPlan = map(this.studentsByGradPlan, ({ studentId }) => studentId);
    this.tableHeaders = this.getTableHeaders();
    this.tableOptions = this.tableToggleValue === 'MIN_REQ' ? this.tableOptions_grad : this.tableOptions_diploma;
    this.table = this.getTableRows();
    this.buildPublicCalculations();
  }

  // wraps all calc functionality for toggle/store updates
  private buildPublicCalculations (): void {
    this.studentsWithUnadressedNeeds = this.getStudentsWithUnadressedNeeds();
    this.portalMismatchData = this.getMismatchedPortalStudentsData();
    this.starsMismatchData = this.getMismatchedStarsStudentsData();
    this.studentCollegeReadinessData = this.getStudentCollegeReadinessData();
  }

  private getTableHeaders (): ITableHeader[] {
    return map(this.activeCategories, ({ humanName, neededOnTrackValue }) => ({ humanName, neededOnTrackValue }));
  }

  private getBaseObject (): IBaseObject {
    // used to populate tableRows
    const base = { NOT_PLANNED: {}, PLANNED_FUTURE: {}, PLANNED_THIS_ADMIN: {}, FULFILLED: {}, NOT_YET_NEEDED: {} };
    return forOwn(base, (tableColumns, tableRow) => {
      each(this.tableHeaders, ({ neededOnTrackValue }) => {
        tableColumns[neededOnTrackValue] = [];
      });
    });
  }

  // populates baseObject with studentIds
  private getTableRows (): IBaseObject {
    const columns = this.getTableHeaders();
    const baseObj = this.getBaseObject();
    const tableRows = reduce(
      this.studentsByGradPlan,
      (tableRow, student: any) => {
        const toggleIsMinReqForGrad = this.tableToggleValue === 'MIN_REQ';
        const NOT_PLANNED = toggleIsMinReqForGrad
          ? student.regentsNeededForOnTrackGradNotSched
          : student.regentsNeededForOnTrackDiplomaNotSched;
        const PLANNED_THIS_ADMIN = toggleIsMinReqForGrad
          ? student.regentsNeededForOnTrackGradSchedNextAdmin
          : student.regentsNeededForOnTrackDiplomaSchedNextAdmin;
        const PLANNED_FUTURE = toggleIsMinReqForGrad
          ? student.regentsNeededForOnTrackGradSchedFutureAdmin
          : student.regentsNeededForOnTrackDiplomaSchedFutureAdmin;
        const FULFILLED = toggleIsMinReqForGrad ? student.regentsPassedForGrad : student.regentsPassedForDiploma;
        const NOT_YET_NEEDED = toggleIsMinReqForGrad
          ? student.regentsNotYetNeededForGrad
          : student.regentsNotYetNeededForDiploma;
        const studentData = {
          NOT_PLANNED,
          PLANNED_THIS_ADMIN,
          PLANNED_FUTURE,
          FULFILLED,
          NOT_YET_NEEDED,
        };
        each(columns, (examCat: any) => {
          const neededOnTrackValue = examCat.neededOnTrackValue;
          // We need to loop through these statuses in an ordered way because we only
          // want each student to show up in one bucket for each exam
          // Meaning, is a student is FULFILLED for ELA, we want to push them to the fulfilled bucket
          // and not any other bucket, even if they are also planned for the exam in the next or future admin
          const orderedStatuses = [
            'FULFILLED',
            'NOT_YET_NEEDED',
            'PLANNED_THIS_ADMIN',
            'PLANNED_FUTURE',
            'NOT_PLANNED',
          ];
          some(orderedStatuses, status => {
            const examCatHasThisStatus = includes(studentData[status], neededOnTrackValue);
            if (examCatHasThisStatus && tableRow[status][neededOnTrackValue]) {
              tableRow[status][neededOnTrackValue].push(student.studentId);
              return true; // break out of the loop
            }
          });
        });
        return tableRow;
      },
      baseObj,
    );
    return tableRows;
  }

  public orderTableRows = (a, b) => a.key;

  // returns sdc flattenedStudents
  private filterStudentsByGradPlan () {
    const tableToggle = this.tableToggleValue;
    // maps flattendStudent `gradPlan` to toggleValue and filters down students
    const toggleMappingObj = {
      CAT_5: ['Local', 'Regents'],
      CAT_9: ['Advanced Regents'],
    };
    if (tableToggle === 'MIN_REQ') return this.filteredStudents;
    const studentsForToggle = filter(this.filteredStudents, (student: any) => {
      // sdc student
      const { gradPlan: studentGradPlan } = student;
      const filter = toggleMappingObj[tableToggle];
      let diplomaType;
      const schoolIsTransfer = this.imSchool.isTransferSchool(this.school);
      if (schoolIsTransfer) {
        diplomaType = find(GraduationPlanTransfer, { humanName: studentGradPlan }).diploma;
      } else diplomaType = find(GraduationPlan, { humanName: studentGradPlan }).diploma;
      if (includes(filter, diplomaType)) return student;
    });
    return studentsForToggle;
  }

  private getStudentsWithUnadressedNeeds () {
    return reduce(
      this.studentsByGradPlan,
      (studentIdsArray, { studentId, regentsNeededForOnTrackDiplomaNotSched: NOT_PLANNED }) => {
        if (NOT_PLANNED.length > 0) studentIdsArray.push(studentId);
        return studentIdsArray;
      },
      [],
    );
  }

  private getMismatchedPortalStudentsData (): IMisMatchData {
    return reduce(
      this.filteredStudents,
      (result, { studentId, examsSchedInStarsNotPlannedInPortal: mismatchedExams }) => {
        if (mismatchedExams.length) {
          result.mismatchedStudents.push(studentId);
          result.totalExams += mismatchedExams.length;
        }
        return result;
      },
      { mismatchedStudents: [], totalExams: 0 },
    );
  }

  private getMismatchedStarsStudentsData (): IMisMatchData {
    return reduce(
      this.filteredStudents,
      (result, { studentId, examsSchedInPortalNotPlannedInStars: mismatchedExams }) => {
        if (mismatchedExams.length) {
          result.mismatchedStudents.push(studentId);
          result.totalExams += mismatchedExams.length;
        }
        return result;
      },
      { mismatchedStudents: [], totalExams: 0 },
    );
  }

  private getStudentCollegeReadinessData (): ICollegeReadinessData {
    const baseObj = {
      ELA: { fulfilled: [], notReady: [], notScheduled: [] },
      Math: { fulfilled: [], notReady: [], notScheduled: [] },
    };
    return reduce(
      this.filteredStudents,
      (
        result,
        {
          studentId,
          maxScoreEnglishExams,
          collegeReadyOnElaRegentsOrSatCr,
          maxScoreMath,
          collegeReadyOnMathRegentsOrSatMath,
          regentsSchedForNextAdmin,
        },
      ) => {
        if (+maxScoreEnglishExams >= 65) {
          result.ELA.fulfilled.push(studentId);
          if (collegeReadyOnElaRegentsOrSatCr === false) {
            result.ELA.notReady.push(studentId);
            if (!includes(regentsSchedForNextAdmin, 'CC ELA')) result.ELA.notScheduled.push(studentId);
          }
        }
        if (+maxScoreMath >= 65) {
          result.Math.fulfilled.push(studentId);
          if (collegeReadyOnMathRegentsOrSatMath === false) {
            result.Math.notReady.push(studentId);
            if (!includes(regentsSchedForNextAdmin, 'CC Alg')) result.Math.notScheduled.push(studentId);
          }
        }
        return result;
      },
      baseObj,
    );
  }
}
