import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { GRADIENT_TYPE } from '../../../shared/constants/list-view/cell-gradient.constant';
import { COLUMN_DATA_TYPE } from '../../../shared/constants/list-view/cell-type.constant';
import { IListColumnData } from '../../../shared/typings/interfaces/list-view.interface';
import { ListCellFormattingService } from '../list-display/list-cell-formatting.service';

@Injectable()
export class AcademicService {
  listCellFormattingService: ListCellFormattingService;

  constructor() {
    this.listCellFormattingService = new ListCellFormattingService();
  }

  formatColumnData(columnType, columnData, cellConfig) {
    columnData = columnData ? columnData.data : columnData;
    switch (columnType) {
      case COLUMN_DATA_TYPE.FLAG:
        return this.listCellFormattingService.flagFormatter(columnData);

      case COLUMN_DATA_TYPE.GRADIENT_CUSTOM:
        return this.gradientCustomFormatter(columnData, cellConfig);

      case COLUMN_DATA_TYPE.GENERIC:
        return this.listCellFormattingService.genericFormatter(columnData);

      case COLUMN_DATA_TYPE.IREADY:
        return this.listCellFormattingService.ireadyFormatter(columnData);

      case COLUMN_DATA_TYPE.STATE_EXAM:
        return this.listCellFormattingService.stateExamFormatter(columnData);

      case COLUMN_DATA_TYPE.DATE:
        return this.listCellFormattingService.dateFormatter(columnData);

      case COLUMN_DATA_TYPE.PERCENTAGE:
        return this.listCellFormattingService.percentageFormatter(columnData);

      case COLUMN_DATA_TYPE.READING_GROWTH:
        return this.listCellFormattingService.readingGrowthFormatter(columnData);

      case COLUMN_DATA_TYPE.MORE_INFO:
        return this.listCellFormattingService.moreInfoFormatter(columnData);

      case COLUMN_DATA_TYPE.F_P_SCORE:
        return this.listCellFormattingService.fpScoreFormatter(columnData);

      default:
        let formattedData: IListColumnData = {
          data: columnData,
          style: 'center',
        };
        return formattedData;
    }
  }

  private gradientCustomFormatter(columnData, colConfig): IListColumnData {
    const gradientType = colConfig.gradientRule.type;

    switch (gradientType) {
      case GRADIENT_TYPE.ACADEMIC_IREADY:
        return this.formatIReady(columnData);

      case GRADIENT_TYPE.ACADEMIC_GROWTH:
        return this.formatGrowth(columnData);

      case GRADIENT_TYPE.ACADEMIC_FAILING:
        return this.formatFailing(columnData);

      default:
        return this.listCellFormattingService.genericFormatter(columnData);
    }
  }

  private formatFailing(columnData): IListColumnData {
    let formattedData: IListColumnData = {
      data: columnData,
      style: 'center',
    };

    if (columnData === null || columnData === 0 || !_.isFinite(columnData)) {
      formattedData.data = '—';
      formattedData.style = 'disabled center';
      return formattedData;
    }

    switch (columnData) {
      case 1:
        formattedData.style = 'center gradient-low';
        break;

      case 2:
      case 3:
      case 4:
        formattedData.style = 'center gradient-medium';
        break;

      default:
        formattedData.style = 'center gradient-high';
        break;
    }

    return formattedData;
  }

  private formatGrowth(columnData): IListColumnData {
    let formattedData: IListColumnData = {
      data: columnData,
      style: 'center',
    };

    if (_.isNil(columnData)) {
      formattedData.data = '—';
      formattedData.style = 'disabled center';
      return formattedData;
    }

    if (columnData === 0) {
      formattedData.data = '0%';
      formattedData.style = 'center gradient-low';
    } else {
      formattedData.data = _.round(columnData * 100, 0) + '%';
      if (columnData > 0) {
        formattedData.data = '+' + formattedData.data;
      }
      formattedData.style = 'center';
    }

    return formattedData;
  }

  private formatIReady(columnData): IListColumnData {
    let formattedData: IListColumnData = {
      data: columnData.latestRelLvl,
      tooltipMsg: columnData.tooltipMsg,
      style: 'center',
    };

    let latestRelLvl = columnData.latestRelLvl;

    if (latestRelLvl === null || latestRelLvl === 0) {
      formattedData.data = '—';
      formattedData.style = 'disabled center';
      return formattedData;
    }

    latestRelLvl = latestRelLvl.toLowerCase();
    switch (latestRelLvl) {
      case '1 level below':
        formattedData.style = 'center gradient-low';
        break;

      case '2 levels below':
        formattedData.style = 'center gradient-medium';
        break;

      case '2 or more levels below':
        formattedData.style = 'center gradient-high';
        break;

      default:
        formattedData.style = 'center';
        break;
    }

    return formattedData;
  }
}
