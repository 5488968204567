<div id="synced-sheet-modal-wrapper">
  <div class="dialog-header">
    <nv-icon-button
      name="close-large-blue"
      (clickButton)="close()"
    ></nv-icon-button>
    <h2 class="dialog-title">
      {{ title }}
    </h2>
  </div>
  <mat-horizontal-stepper linear #stepper>
    <mat-step>
      <student-selector
        (completed)="onSectionCompleted($event)"
        (abandoned)="onSectionAbandoned()"
        [schoolId]="schoolId"
        [isEditMode]="isEditMode"
        [initialFormData]="initSelectionInput"
      >
      </student-selector>
    </mat-step>
    <mat-step>
      <column-selector
        (completed)="onSectionCompleted($event)"
        (abandoned)="onSectionAbandoned()"
        [schoolAssessments]="schoolAssessments"
        [initialFormData]="initSelectionInput"
      >
    </column-selector>
    </mat-step>
    <mat-step>
      <update-selector
        (completed)="onAllSectionsCompleted($event)"  
        (abandoned)="onSectionAbandoned()"
        [isEditMode]="isEditMode"
        [initialFormData]="initSelectionInput"
      >
      </update-selector>
    </mat-step>
    <mat-step>
      <sync-summary
        (completed)="close()"
        (abandoned)="onSectionAbandoned()"
        [isEditMode]="isEditMode"
        [queueUpdateSyncToolStatus]="(queueUpdateSyncToolStatus$ | async)"
      >
      </sync-summary>
    </mat-step>
  </mat-horizontal-stepper>
</div>
