import { RollbarService } from './../rollbar/rollbar.service';
import { NvPusherSocketId } from './nv-pusher-interceptor';
import { Inject, Injectable } from '@angular/core';
import { PortalConfig } from '../portal-config';
import { WindowRef } from '../../../../../projects/shared/services/window-ref/windowRef';
import { Auth } from '../../auth/auth.service';
import * as Pusher from 'pusher-js/with-encryption';
import Channels from 'pusher-js/types/src/core/channels/channels';
import { each } from 'lodash';
import * as Rollbar from 'rollbar';
import { SessionStorageService } from '../web-storage/session-storage/session-storage.service';
import { Observable, of } from 'rxjs';

/* istanbul ignore next */
@Injectable()
export class PusherService {
  // Pusher as any is used to prevent the following compilation error:
  // https://stackoverflow.com/questions/48115003/how-to-use-pusher-js-within-vs-codes-angular-workflow (Carlos)
  private _Pusher = Pusher as any;
  private _pusher: Pusher.default;

  constructor (
    private auth: Auth,
    private windowRef: WindowRef,
    private portalConfig: PortalConfig,
    private sessionStorageService: SessionStorageService,
    @Inject(RollbarService) private rollbar: Rollbar,
  ) {}

  get pusher () {
    const currentDistrict = this.sessionStorageService.getItem('currentDistrict');
    if (!this._pusher || this._pusher.config.auth.headers['nv-district-id'] !== currentDistrict) {
      this._pusher = this.initializeClient();
    }
    return this._pusher;
  }

  private get channelNamePrefix () {
    const { publicConfig } = this.portalConfig;
    // Encrypted channels must be prefixed with 'private-encrypted-' (Carlos)
    // More info: https://pusher.com/docs/channels/using_channels/encrypted-channels
    if (publicConfig.PUSHER_ENCRYPTION_ENABLED) return 'private-encrypted-school';
    // enable encryption
    else return 'private-school'; // disable encryption
  }

  getPusherInstance$ (): Observable<Pusher.default> {
    return of(this.pusher);
  }

  getChannelName (schoolId) {
    const currentDistrict = this.sessionStorageService.getItem('currentDistrict');
    const channelNamePrefix = this.channelNamePrefix;
    const pusherChannelName = `${channelNamePrefix}-${currentDistrict}-${schoolId}`;

    return pusherChannelName;
  }

  private initializeClient () {
    const { publicConfig } = this.portalConfig;
    const karmaIsRunning = this.windowRef.nativeWindow._KARMA_IS_RUNNING;
    if (publicConfig.DEV_MODE && !karmaIsRunning) this._Pusher.logToConsole = true;

    if (karmaIsRunning) {
      throw new Error(
        'Some service tried to initialize PusherService while tests are running. This will cause us to bump up ' +
          'against our Pusher connection limit.\nInstead of doing that, import PusherServiceMock into your test.',
      );
    }

    const pusher = new this._Pusher(publicConfig.PUSHER_KEY, {
      authEndpoint: publicConfig.NV_API_ORIGIN + '/v1/pusher/auth',
      encrypted: true,
      auth: {
        headers: {
          Authorization: this.auth.getIdToken(),
          'nv-district-id': this.sessionStorageService.getItem('currentDistrict'),
        },

      },
    });
    // How should we handle this error if pusher fails to connect?
    // Do we alert the client or proceed without realtime updates?
    pusher.connection.bind('connected', () => {
      NvPusherSocketId.setSocketId(pusher.connection.socket_id);
    });

    return pusher;
  }

  // pulled from original school.route.ts
  getCurrentSchoolChannel (currSchoolId): Channels {
    const currSchlPusherChannelName = this.getChannelName(currSchoolId);
    const channel = this.pusher.channels[currSchlPusherChannelName]
      ? this.pusher.channels[currSchlPusherChannelName]
      : this.pusher.subscribe(currSchlPusherChannelName);
    return channel;
  }

  // pulled from original school.route.ts
  ensurePusherToken (): void {
    // ensure that we always have the latest token for pusher when switching schools
    // this is to address: https://newvisions.atlassian.net/browse/PI-1012 (Carlos)
    try {
      const idToken = this.auth.getIdToken();
      this.pusher.config.auth.headers.Authorization = idToken;
    } catch (err) {
      this.rollbar.debug('PusherService Authorization Header: ', err);
    }
  }

  // pulled from original school.route.ts
  ensureUnsubscribeFromPrevSchools (currSchoolId: string): void {
    // This pusher-channel needs to be shared between the frameworks while in a hybrid state
    // Having the same channel/id allows us to block messages to the event initiator(JJ)
    // Update - we want to unsubscribe from any school that is not the current school (JE)
    const currSchlPusherChannelName = this.getChannelName(currSchoolId);
    const currentChannels = this.pusher.allChannels();
    each(currentChannels, channel => {
      if (channel.name !== currSchlPusherChannelName) this.pusher.unsubscribe(channel.name);
    });
  }
}
