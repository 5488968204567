import { ImUser } from 'Src/ng2/shared/services/im-models/im-user';
import { AssignSupportModalService } from './../../../../../../shared/modals/assign-support/assign-support-modal.service';
import { ActivatedRoute } from '@angular/router';
import { ModalsService } from 'Src/ng2/shared/modals/modals.service';
import { map, sortBy, filter, sum, isUndefined, each, cloneDeep } from 'lodash';
import { UtilitiesService } from 'Src/ng2/shared/services/utilities/utilities.service';
import { RegentsExam } from './../../../../../../shared/constants/regents.constant';
import { RegentsExamPrepStatuses } from './../../../../../../shared/constants/regents-exam-prep-statuses.constant';
import { NextRegentsAdminDate } from './../../../../../../shared/constants/next-regents-admin-date.constant';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { PartnerTypes, TValidPartnerTypes } from 'Src/ng2/shared/typings/interfaces/partner.interface';
import { PortalActionsService } from 'Src/ng2/shared/components/nv-actions/nv-actions.service';

interface IExamValue {
  readonly key: string;
  readonly nextAdminDates: string[];
  readonly humanName: string;
  readonly shortName: string;
}

@Component({
  selector: 'regents-scheduled-table',
  templateUrl: './regents-scheduled-table.component.html',
  styleUrls: ['./regents-scheduled-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RegentsScheduledTable {
  @Input() private filteredStudents;
  @Input() private currentUser;
  private canSeeSdcStepper: boolean

  tableCols;
  tableRows: any;
  tableColTotals: any;
  studentSupportAction;

  private _offeredExams: any;
  private _allScheduledExamsByStatus: any;
  public nvNextRegentsAdminDate: any;
  private nvRegentsExamPrepStatuses: any;
  private nvRegentsExam: any;
  private schoolId: string;
  private contextPartnerType: TValidPartnerTypes = PartnerTypes.SCHOOL;

  constructor (
    private utils: UtilitiesService,
    private modalsService: ModalsService,
    private activatedRoute: ActivatedRoute,
    private portalActionsService: PortalActionsService,
    private imUser: ImUser,
  ) {}

  ngOnInit () {
    this.schoolId = this.activatedRoute.snapshot.params.schoolId;
    this.canSeeSdcStepper = this.imUser.canSeeSdcStepper(this.currentUser);
    // the regents exam dates are not in a known ISO format.
    // ordinarily moment would be able to handle this by falling back to using js
    // Date to parse it.However, the fallback method is being deprecated.
    // Moment recommends explicitly defining a format for the full string.
    // http://momentjs.com/guides/#/warnings/js-date/
    this.studentSupportAction = this.generateAdditionalWizardFilterAction();
    this.nvNextRegentsAdminDate = NextRegentsAdminDate;
    this.nvRegentsExamPrepStatuses = RegentsExamPrepStatuses;
    this.nvRegentsExam = RegentsExam;
    this._offeredExams = filter(this.nvRegentsExam, (examValue: any) => examValue.isOffered);

    this.tableRows = {
      rowNoPrep: {},
      rowFailing: {},
      rowPassing: {},
      rowAdditionalPrep: {},
      rowCourseAdditionalPrep: {},
    };

    // Table headers
    this.tableCols = map(this._offeredExams, examValue => {
      const col: any = cloneDeep(examValue);
      const date = col.nextAdminDates[0];
      // figure out if the offered exam is actually offered this regents admin!
      let monthDay = 'Not Offered';
      let amPm;
      if (date) {
        if (date === 'Schedule Unknown') {
          monthDay = '';
          amPm = 'Offered';
        } else {
          const parsedDate = this.utils.parseDate(date);
          monthDay = parsedDate.format('M/DD');
          amPm = parsedDate.format('A');
        }
      }
      col.monthDay = monthDay;
      col.amPm = amPm;
      return col;
    });
    // Sorted Table headers
    this.tableCols = sortBy(this.tableCols, ['monthDay', 'amPm', 'order']);

    this.fetchStudents(this.filteredStudents);
  }

  ngOnChanges ({ filteredStudents }) {
    if (!filteredStudents.isFirstChange()) this.ngOnInit();
  }

  private populateTableRows (student, examKey, prepStatusForExam) {
    const { studentId, _id } = student;
    switch (prepStatusForExam) {
      case this.nvRegentsExamPrepStatuses.NO_PREP:
        this.tableRows.rowNoPrep[examKey].students.push(studentId);
        this.tableRows.rowNoPrep[examKey].studentsToPatch.push(_id);
        break;
      case this.nvRegentsExamPrepStatuses.COURSE_FAILING:
        this.tableRows.rowFailing[examKey].students.push(studentId);
        this.tableRows.rowFailing[examKey].studentsToPatch.push(_id);
        break;
      case this.nvRegentsExamPrepStatuses.COURSE_PASSING:
        this.tableRows.rowPassing[examKey].students.push(studentId);
        this.tableRows.rowPassing[examKey].studentsToPatch.push(_id);
        break;
      case this.nvRegentsExamPrepStatuses.ADD_PREP:
        this.tableRows.rowAdditionalPrep[examKey].students.push(studentId);
        this.tableRows.rowAdditionalPrep[examKey].studentsToPatch.push(_id);
        break;
      case this.nvRegentsExamPrepStatuses.COURSE_ADD_PREP:
        this.tableRows.rowCourseAdditionalPrep[examKey].students.push(studentId);
        this.tableRows.rowCourseAdditionalPrep[examKey].studentsToPatch.push(_id);
        break;
      default:
        break;
    }
    return this.tableRows;
  }

  private createTableRows (examValue: IExamValue) {
    return each(this.tableRows, row => {
      row[examValue.key] = {};
      row[examValue.key].students = [];
      row[examValue.key].studentsToPatch = [];
      row[examValue.key].examValue = examValue;
      row[examValue.key].options = {
        columnKeys: [
          'cohort',
          examValue.key + 'PlannedNextAdmin',
          examValue.key + 'PrepStatus',
          examValue.key + 'AlignedCourses',
          examValue.key + 'AlignedSupports',
        ],
        examKey: examValue,
      };
    });
  }

  private fetchStudents (students) {
    this.tableColTotals = {};
    each(this.tableCols, (examValue: IExamValue) => {
      const filteredStudents = filter(students, student => {
        if (!isUndefined(student[`${examValue.key}PrepStatus`])) return student;
      });

      this.createTableRows(examValue);
      each(filteredStudents, filteredStudent => {
        const prepStatusForExam = filteredStudent[`${examValue.key}PrepStatus`];
        if (prepStatusForExam) this.populateTableRows(filteredStudent, examValue.key, prepStatusForExam);
      });

      // get total scheduled for each exam across all prep statuses
      this._allScheduledExamsByStatus = map(this.tableRows, row => row[examValue.key].students.length);
      const colTotal: number = sum(this._allScheduledExamsByStatus);
      this.tableColTotals[examValue.key] = colTotal;
    });
  }

  private generateAdditionalWizardFilterAction () {
    return this.canSeeSdcStepper
      ? {
        assignRegents: {
          updateCount: null,
          open: (studentIds): void => {
            const _ids = map(studentIds, id => `${id}${this.schoolId}`);
            this.modalsService
              .openAssignSupportModal({ schoolId: this.schoolId, studentIds: _ids })
              .afterClosed()
              .subscribe(partialPayload => {
                if (partialPayload) {
                    this.portalActionsService.trackBatchAssignEvent({ item: 'Support', contextPartnerType: this.contextPartnerType, origin: 'MOCK-REGENTS-GRID' });
                }
              });
          },
          label: 'Assign Student Support',
        },
      }
      : {};
  }
}
