import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PATH_CATEGORY_KEYS } from '../../../../constants/student-paths.constant';
import { BaseStudentPathModalForm } from '../base-student-path-modal-form.component';
import { ApiService } from '../../../../../shared/services/api-service/api-service';
import { formToStudentPathSubdoc } from '../path-category-form-utils';
import { IPathSubdoc } from 'Src/ng2/shared/typings/interfaces/studentPaths.interface';

@Component({
  selector: 'military-form',
  templateUrl: './military-form.component.html',
  encapsulation: ViewEncapsulation.None,
})

export class MilitaryForm extends BaseStudentPathModalForm implements OnInit {
  public formGroup: FormGroup;

  // empty state text
  public readonly emptyStateJobType = 'No job types found';

  constructor (
    apiService: ApiService,
    private formBuilder: FormBuilder,
  ) {
    super(apiService);
  };

  ngOnInit (): void {
    this.formGroup = this.formBuilder.group({
      militaryBranch: [null, Validators.required],
      jobType: [null],
    });

    super.initForm(PATH_CATEGORY_KEYS.MILITARY, this.schoolId);
  }

  getStudentPath (): IPathSubdoc {
    const studentPath = formToStudentPathSubdoc(this.formGroup);
    return studentPath;
  }

  handleClick (): void {
    this.onEmitStudentPath();
  }
}
