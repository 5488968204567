import { animate, style, transition, trigger } from '@angular/animations';

export function expandInAni() {
  return trigger('expandInAni', [
    transition(':enter', [
      style({
        opacity: 0,
        transform: 'scaleY(0.1)',
      }),
      animate(200),
    ]),
  ]);
}
