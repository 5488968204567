import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { CreditGapsPriorityGroupingsTransferService, IPriorityGroupDefinition } from 'Src/ng2/school/sdc/services/credit-gaps-priority-groupings/credit-gaps-priority-groupings-transfer.service';
import { WizardHelpers } from 'Src/ng2/school/sdc/services/wizard-helpers.service';
import { ObjectCache } from 'Src/ng2/shared/services/object-cache/object-cache.service';
import { UrlPathService } from 'Src/ng2/shared/services/url-path-service/url-path.service';
// tslint:disable-next-line:max-line-length

interface IStudentIdsByGapsCollection {
    unaddressedGaps: { students: any[]; studentIds: string[] };
    pendingGapsResolutions: { students: any[]; studentIds: string[] };
    noGaps: { students: any[]; studentIds: string[] };
}

interface IExtendedPriorityGroupDef extends IPriorityGroupDefinition {
    studentIds: string[];
}

@Component({
  selector: 'review-gaps-step',
  templateUrl: './review-gaps-component.html',
  encapsulation: ViewEncapsulation.None,
})

export class ReviewGapsStep implements OnInit {
    @Input() filteredStudents: any;
    @Input() currentFilter: any;

    private sort: string;
    private studentsWithUnaddressedGaps: any[]; // sdc students

    public studentsByCreditGaps: IStudentIdsByGapsCollection;
    public priorityGroupFilter: string;
    public priorityGroups: IExtendedPriorityGroupDef[];
    public options = { columnKeys: ['gradPlan', 'creditMaxGaps', 'creditMaxGapsNoPlan'] };
    public selectedGradDate: string | null;
    public courseDiffs;
    public stepLabel = 'Plan for credit gaps to be addressed this term or in the future';

    constructor (
        private objectCache: ObjectCache,
        private wizardHelpers: WizardHelpers,
        private activatedRoute: ActivatedRoute,
        private creditGapsPriorityGroupingsTransferService: CreditGapsPriorityGroupingsTransferService,
        private urlPathService: UrlPathService,
    ) {
    }

    ngOnInit () {
      this.setUi();
    }

    ngOnChanges ({ filteredStudents }): void {
      if (!filteredStudents.firstChange) {
        this.setUi();
      }
    }

    setUi (): void {
      this.studentsByCreditGaps = this.getStudentsByCreditGaps(this.filteredStudents);
      this.studentsWithUnaddressedGaps = this.studentsByCreditGaps.unaddressedGaps.students;
      this.sort = this.objectCache.cacheObject([{ colId: 'creditMaxGapsNoPlan', sort: 'desc' }]);
      this.priorityGroups = this.buildPriorityGroupTable(this.studentsWithUnaddressedGaps);
    }

    public navigateToStudents (studentIds: string[]): void {
      const { schoolId } = this.activatedRoute.snapshot.params;
      const url = this.urlPathService.computeDistrictUrlPath(`/school/${schoolId}/student`);
      this.wizardHelpers.navigateToView({ url, cacheData: { studentId: studentIds }, sort: this.sort });
    }

    private getStudentsByCreditGaps (students): IStudentIdsByGapsCollection {
      const baseObj = {
        unaddressedGaps: { students: [], studentIds: [] },
        pendingGapsResolutions: { students: [], studentIds: [] },
        noGaps: { students: [], studentIds: [] },
      };
      return _.reduce(
        students,
        (result, student: any) => {
          const { studentId, creditMaxGapsNoPlan, creditMaxGaps } = student;
          const unaddressedGaps = creditMaxGapsNoPlan < 0;
          const pendingGapsResolutions = creditMaxGaps < 0 && creditMaxGapsNoPlan >= 0;
          const noGaps = creditMaxGaps === 0;
          if (unaddressedGaps) {
            result.unaddressedGaps.students.push(student);
            result.unaddressedGaps.studentIds.push(studentId);
          } else if (pendingGapsResolutions) {
            result.pendingGapsResolutions.students.push(student);
            result.pendingGapsResolutions.studentIds.push(studentId);
          } else if (noGaps) {
            result.noGaps.students.push(student);
            result.noGaps.studentIds.push(studentId);
          }
          return result;
        },
        baseObj,
      );
    }

    private getPriorityGroupByGradDate (priorityGroupFilter) {
      return this.creditGapsPriorityGroupingsTransferService.getPriorityGroupsByPlannedGradDateFilter(
        priorityGroupFilter,
      );
    }

    private buildPriorityGroupTable (students): IExtendedPriorityGroupDef[] {
      const priorityGroupings = this.getPriorityGroupByGradDate(this.priorityGroupFilter);
      const priorityGroupingsWithAppendedField = _.map(priorityGroupings, (group: any) => {
        group.studentIds = [];
        return group;
      });
      _.each(students, (student: any) => {
        const { creditGapPriorityGrouping, studentId } = student;
        const matchedPriorityGroup = _.find(priorityGroupingsWithAppendedField, {
          studentPriorityGroupingValue: creditGapPriorityGrouping,
        });
        matchedPriorityGroup.studentIds.push(studentId);
      });
      return priorityGroupingsWithAppendedField;
    }
}
