import { ModalsService } from 'Src/ng2/shared/modals/modals.service';
import { ILoadSchoolAssessmentsPayload } from './../../actions/assessments-actions/school-assessments-actions';
import { Inject, Injectable, forwardRef } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, switchMap, map, tap } from 'rxjs/operators';
import { BackgroundJob } from '../../../shared/services/background-job/background-job.service';
import { ISchoolAssessment } from '../../../shared/typings/interfaces/school-assessment.interface';
import * as schoolAssessmentsActions from '../../actions/assessments-actions/school-assessments-actions';
import { ApiService } from './../../../shared/services/api-service/api-service';

@Injectable()
export class SchoolAssessmentsEffects {
  constructor (
    private actions$: Actions,
    private apiService: ApiService,
    private backgroundJob: BackgroundJob,
    @Inject(forwardRef(() => ModalsService)) public modalsService: ModalsService,
  ) {}

  loadSchoolAssessments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(schoolAssessmentsActions.LOAD_SCHOOL_ASSESSMENTS),
      switchMap((action: { type: string; payload: ILoadSchoolAssessmentsPayload }) => {
        return this.apiService.getSchoolAssessments(action.payload).pipe(
          switchMap((response: { data: { SchoolAssessments: ISchoolAssessment[] } }) => {
            return [new schoolAssessmentsActions.LoadSchoolAssessmentsSuccess(response)];
          }),
          catchError(error => of(new schoolAssessmentsActions.LoadSchoolAssessmentsFail(error))),
        );
      }),
    );
  });

  createSchoolAssessment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(schoolAssessmentsActions.BULK_CREATE_SCHOOL_ASSESSMENTS),
      switchMap((action: { type: string; payload: schoolAssessmentsActions.ICreateSchoolAssessmentPayload }) => {
        return this.apiService.createSchoolAssessments(action.payload).pipe(
          map((response: { data: { bulkCreateSchoolAssessment: { backgroundJobId: string } } }) => {
            const {
              data: {
                bulkCreateSchoolAssessment: { backgroundJobId },
              },
            } = response;
            const subject = (this.backgroundJob as any)(backgroundJobId).subject();
            return subject;
          }),
          tap((backgroundJobSubject) => this.modalsService.openBackgroundJobSpinnerModal({ backgroundJobSubject, title: 'Updating assessments' })),
          catchError(error => {
            return of(new schoolAssessmentsActions.BulkCreateSchoolAssessmentsFail(error));
          }),
        );
      }),
    );
  }, { dispatch: false });

  updateSchoolAssessment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(schoolAssessmentsActions.BULK_UPDATE_SCHOOL_ASSESSMENTS),
      switchMap((action: { type: string; payload: schoolAssessmentsActions.IBulkUpdateSchoolAssessmentsPayload }) => {
        return this.apiService.patchSchoolAssessments(action.payload).pipe(
          map((response: { data: { bulkUpdateSchoolAssessment: { backgroundJobId: string } } }) => {
            const {
              data: {
                bulkUpdateSchoolAssessment: { backgroundJobId },
              },
            } = response;
            const subject = (this.backgroundJob as any)(backgroundJobId).subject();
            return subject;
          }),
          tap((backgroundJobSubject) => this.modalsService.openBackgroundJobSpinnerModal({ backgroundJobSubject, title: 'Updating Assessments' })),
          catchError(error => {
            return of(new schoolAssessmentsActions.BulkUpdateSchoolAssessmentsFail(error));
          }),
        );
      }),
    );
  }, { dispatch: false });
}
