import { AfterViewInit, Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import * as __moment from 'moment';
import { Subscription } from 'rxjs';
import { NvDatePickerHeaderComponent } from '../nv-date-picker-header/nv-date-picker-header.component';
const moment = __moment;

@Component({
  selector: 'nv-date-picker-calendar',
  templateUrl: './nv-date-picker-calendar.component.html',
  styleUrls: ['./nv-date-picker-calendar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NvDatePickerCalendarComponent implements OnInit, AfterViewInit {
  public nvDatePickerHeader = NvDatePickerHeaderComponent;

  @Input() startLimit: string; // YYYY-MM-DD
  @Input() endLimit: string; // YYYY-MM-DD
  @Input() dateControl: FormControl;

  @ViewChild('picker', { static: false }) picker: MatDatepicker<Date>;

  public min: Date;
  public max: Date;
  public calendarDate: Date;
  public formSubscription: Subscription;

  // the default mat calendar displays the wrong date
  // see https://github.com/angular/components/issues/7167
  setUtcDate (value: string) {
    const date = value ? new Date(value) : null;
    const utcDate = date ? new Date(date.setDate(date.getDate() + 1)) : null;
    return utcDate;
  }

  ngOnInit () {
    this.setMinMax(this.startLimit, this.endLimit);
    this.calendarDate = this.setUtcDate(this.dateControl.value);
  }

  ngAfterViewInit (): void {
    // Force block to run asynchronously
    setTimeout(() => {
      this.openCalendar();
    });
  }

  openCalendar () {
    this.picker.open();
  }

  setMinMax (startLimit, endLimit): void {
    if (startLimit) this.min = moment(startLimit).toDate();
    if (endLimit) this.max = moment(endLimit).toDate();
  }

  selectDate ($event): void {
    const isoDate = moment($event.value).format('YYYY-MM-DD');
    this.calendarDate = this.setUtcDate(isoDate);
    this.dateControl.setValue(isoDate);
    this.dateControl.markAsDirty();
  }
}
