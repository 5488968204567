import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NvButtonModule } from 'projects/shared/nvps-libraries/design/nv-button/nv-button.module';
import { NvTextboxModule } from '../../../../../../nvps-libraries/design/nv-textbox/nv-textbox.module';
import { NvFormFieldModule } from '../../../../../../nvps-libraries/design/nv-form-field/nv-form-field.module';
import { ColumnSelectorComponent } from './column-selector.component';
import { NvIconButtonModule } from './../../../../../../nvps-libraries/design/nv-icon-button/nv-icon-button.module';

@NgModule({
  declarations: [ColumnSelectorComponent],
  imports: [
    CommonModule,
    NvButtonModule,
    NvTextboxModule,
    NvFormFieldModule,
    NvIconButtonModule,
  ],
  exports: [ColumnSelectorComponent],
  providers: [],
})
export class ColumnSelectorModule {}
