import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'paginator-controls',
  templateUrl: './paginator-controls.component.html',
  styleUrls: ['./paginator-controls.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class PaginatorControlsComponent {
    @Input() currentPage: number;
    @Input() totalPages: number;
    @Input() totalRows: number;
    @Input() pageSize: number;
    @Output() onBtNext = new EventEmitter<number>();
    @Output() onBtPrevious = new EventEmitter<number>();

    public isFirstPage: boolean;
    public isLastPage: boolean;

    ngOnChanges () {
      this.isFirstPage = this.currentPage === 1;
      this.isLastPage = this.currentPage === this.totalPages;
    }

    onPrevPage () {
      this.onBtPrevious.emit(this.currentPage - 1);
    }

    onNextPage () {
      this.onBtNext.emit(this.currentPage + 1);
    }

    getCurrentEntriesRange (): string {
      const startIndex = (this.currentPage - 1) * this.pageSize + 1;
      const endIndex = Math.min(this.currentPage * this.pageSize, this.totalRows);
      return `Showing ${startIndex} to ${endIndex} of ${this.totalRows} entries`;
    }
}
