// tslint:disable:max-classes-per-file
import { Action } from '@ngrx/store';

export enum MetricsActionsTypes {
  CHANGE_MADE_IN_METRICS = '[Portal] Change Made In Metrics',
  CHANGE_SAVED_IN_METRICS = '[Portal] Change Saved In Metrics',
}

export class ChangeMadeInMetrics implements Action {
  readonly type = MetricsActionsTypes.CHANGE_MADE_IN_METRICS;
}

export class ChangeSavedInMetrics implements Action {
  readonly type = MetricsActionsTypes.CHANGE_SAVED_IN_METRICS;
}
