/* tslint:disable */

import { Action } from '@ngrx/store';
export const LOAD_TOGGLES = '[Portal] Load Toggle';
export const LOAD_TOGGLES_FAIL = '[Portal] Load Toggle Fail';
export const LOAD_TOGGLES_SUCCESS = '[Portal] Load Toggle Success';

export class LoadToggles implements Action {
  readonly type = LOAD_TOGGLES;
  constructor (public payload: { schoolId?: string, currentSchoolId?: string, currentSchoolType?: string}) {}
}

export class LoadTogglesFail implements Action {
  readonly type = LOAD_TOGGLES_FAIL;
  constructor (public payload: any) {}
}

export class LoadTogglesSuccess implements Action {
  readonly type = LOAD_TOGGLES_SUCCESS;
  constructor (public payload: any) {}
}

// action types
export type ToggleAction = LoadToggles | LoadTogglesFail | LoadTogglesSuccess;
