import { NvSidebarListModule } from 'Src/nvps-libraries/design/nv-sidebar-list/nv-sidebar-list.module';
import { RouterModule } from '@angular/router';
import { NvLogoModule } from 'Src/nvps-libraries/design/nv-logo/nv-logo.module';
import { PortalSwitcherModule } from './../../shared/components/portal-switcher/portal-switcher.module';
import { NetworkLeftSideNavComponent } from './network-left-side-nav.component';
import { CommonModule } from '@angular/common';
import { LogOutModule } from 'Src/ng2/shell/log-out/log-out.module';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

@NgModule({
  declarations: [NetworkLeftSideNavComponent],
  imports: [CommonModule, RouterModule, LogOutModule, PortalSwitcherModule, NvLogoModule, RouterModule, NvSidebarListModule],
  exports: [NetworkLeftSideNavComponent],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class NetworkLeftSideNavModule {}
