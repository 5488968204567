import { Pipe, PipeTransform } from '@angular/core';
import { IACOption } from '../../nv-textbox.interface';

export type TMatDisplayOption = ({ selectable: true; } & IACOption) | ({selectable: false, human: string });

@Pipe({ name: 'transformToMatDisplayOptions' })
export class TransformToMatDisplayOptions implements PipeTransform {
  // Note that pure pipes only fire when there is a change to a primitive input value, or a
  // changed object reference. In this case, to ensure this pipe fires when there's been a
  // change to the options, ensure that the changed list of options has been replaced
  // and not mutated. More here: https://angular.io/guide/pipes#detecting-pure-changes-to-primitives-and-object-references
  transform (options: IACOption[]): TMatDisplayOption[] {
    return options?.map(
      (option) => ({ ...option, selectable: true }),
    ) || [];
  }
};
