import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

// services
import { WizardHelpers } from 'Src/ng2/school/sdc/services/wizard-helpers.service';

// components
import { OnTrackCreditStep } from './on-track-credit-step.component';

@NgModule({
  imports: [CommonModule, MatButtonModule],
  exports: [OnTrackCreditStep],
  declarations: [OnTrackCreditStep],
  providers: [WizardHelpers],
})
export class OnTrackCreditStepModule { }
