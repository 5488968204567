import { NetworkFociGridAction } from './../../actions/network-foci-grid-actions/network-foci-grid-actions';
import * as networkFociGridActions from '../../actions/network-foci-grid-actions/network-foci-grid-actions';

export const initialState = {
  loaded: false,
  gridState: null,
};

export function reducer (state = initialState, action: NetworkFociGridAction) {
  switch (action.type) {
    case networkFociGridActions.SET_NETWORK_FOCI_GRID_STATE:
      return {
        gridState: { ...action.payload },
        loaded: true,
      };
    default:
      return state;
  }
}
