import { createFeatureSelector, createSelector } from '@ngrx/store';

const getPortalState = createFeatureSelector('PORTAL_STORE');

export const getMockRegentsGridSlice = createSelector(
  getPortalState,
  (state: any) => state.mockRegentsGrid,
);

export const getMockRegentsGridLoadedState = createSelector(
  getMockRegentsGridSlice,
  (state: any) => state.loaded,
);

export const getMockRegentsGridState = createSelector(
  getMockRegentsGridSlice,
  (state: any) => state.gridState,
);
