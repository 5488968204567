<div class="regents-prep-non-transfer-wizard">
  <ng-container *ngIf="flattenedStudents$|async">
    <sdc-dropdown 
      [label]="'Class of'"
      [options]="classOfDropdownOptions"
      [selection]="dropdownSelection.value"
      (dropdownChange)="handleDropdownChange($event)"
    >
    </sdc-dropdown>
  </ng-container>

  <ng-container *ngIf="(flattenedStudents$|async) as flattenedStudents">
    <regents-scheduled-table [filteredStudents]="flattenedStudents" [currentUser]="currentUser$ | async"></regents-scheduled-table>
  </ng-container>

  <mat-vertical-stepper [linear]="isLinear" #stepper (selectionChange)="onStepChange($event)" [selectedIndex]="selectedStepIndex">
    <ng-container *ngIf="(flattenedStudents$|async) as flattenedStudents">
      <mat-step label={{instructionStep?.stepLabel}}>
        <instruction-step [description]="instructionDescription"></instruction-step>
        <ng-container [ngTemplateOutlet]="navigation"></ng-container>
      </mat-step>
    
      <mat-step label={{completeGradPlanningStep?.stepLabel}}>
        <complete-grad-planning-step [filteredStudents]="flattenedStudents"></complete-grad-planning-step>
        <ng-container [ngTemplateOutlet]="navigation"></ng-container>
      </mat-step>
    
      <mat-step label={{schedulingCheckStep?.stepLabel}}>
        <scheduling-check-step [students]="flattenedStudents"></scheduling-check-step>
        <ng-container [ngTemplateOutlet]="navigation"></ng-container>
      </mat-step>
    
      <mat-step label={{prepReviewStep?.stepLabel}}>
        <prep-review-step></prep-review-step>
        <ng-container [ngTemplateOutlet]="navigation"></ng-container>
      </mat-step>
    
      <mat-step label={{assignSupportStep?.stepLabel}}>
        <assign-supports-step></assign-supports-step>
        <ng-container [ngTemplateOutlet]="navigation"></ng-container>
      </mat-step>
    
      <mat-step label={{studentReviewStep?.stepLabel}}>
        <student-review-step [filteredStudents]="flattenedStudents"></student-review-step>
        <ng-container [ngTemplateOutlet]="navigation"></ng-container>
      </mat-step>
    
      <mat-step label={{finalPrepCheckStep?.stepLabel}}>
        <final-prep-check-step [filteredStudents]="flattenedStudents"></final-prep-check-step>
        <ng-container [ngTemplateOutlet]="navigation"></ng-container>
      </mat-step>
    
      <mat-step label={{printRegentsStep?.stepLabel}}>
        <print-regents-step [filteredStudents]="flattenedStudents"></print-regents-step>
        <ng-container [ngTemplateOutlet]="navigation"></ng-container>
      </mat-step>
    
      <ng-container *ngIf="(schoolSupports$|async) as schoolSupports"  >
        <mat-step label={{trackAttendanceStep?.stepLabel}}>
          <track-attendance-step [schoolSupports]="schoolSupports"></track-attendance-step>
          <ng-container [ngTemplateOutlet]="navigation"></ng-container>
        </mat-step>
      </ng-container>

      <mat-step label={{monitorAttendanceStep?.stepLabel}}>
        <monitor-attendance-step></monitor-attendance-step>
        <ng-container [ngTemplateOutlet]="navigation"></ng-container>
      </mat-step>
    
    </ng-container>
  
    <!-- reusable step navigation buttons-->
    <ng-template #navigation>
      <div>
        <button *ngIf="stepper?.selectedIndex !== 0" mat-button  matStepperPrevious>BACK</button>
        <button *ngIf="stepper?.selectedIndex !== stepper?.steps?.length-1"mat-button  matStepperNext>CONTINUE</button>
      </div>
    </ng-template>
  </mat-vertical-stepper>

  <ng-container *ngIf="flattenedStudents$|async">
    <ng-container *ngIf="(studentSupports$| async) as studentSupports">
      <ng-container *ngIf="(schoolSupports$ | async) as schoolSupports">
        <regents-exam-supports-table [studentSupports]="studentSupports" [schoolSupports]="schoolSupports"></regents-exam-supports-table>
      </ng-container>
    </ng-container>
  </ng-container>
  
</div>
