import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';

import { TasksNotesActivityModalComponent } from './tasks-notes-activity-modal.component';
import { BaseModalModule } from '../base-modal.module';
import { TasksNotesActivityTableModule } from '../../components/tasks-notes-activity-table/tasks-notes-activity-table.module';

@NgModule({
  declarations: [TasksNotesActivityModalComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    BaseModalModule,
    TasksNotesActivityTableModule,
  ],
  exports: [TasksNotesActivityModalComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TasksNotesActivityModalModule {}
