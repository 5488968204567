import { Store } from '@ngrx/store';
import { map, tap, take } from 'rxjs/operators';
import { ImSchool } from '../../../../shared/services/im-models/im-school';
import { Component, ViewEncapsulation } from '@angular/core';
import { getSchool } from 'Src/ng2/store';
import { SharedEventTrackers } from 'Src/ng2/shared/services/mixpanel/event-trackers/shared-tracking.service';
import { MISC_PAGE_ORIGINS } from 'Src/ng2/shared/services/mixpanel/event-interfaces/misc-page';
import { PORTAL_TYPES } from 'Src/ng2/shared/typings/interfaces/portal.interface';
@Component({
  selector: 'regents-prep-wizard-container',
  templateUrl: './regents-prep-wizard-container.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class RegentsPrepWizardContainer {
  public isTransfer: boolean;

  constructor (
    private imSchool: ImSchool,
    private store: Store<any>,
    private sharedEventTrackers: SharedEventTrackers,
  ) {}

  ngOnInit () {
    this.store.select(getSchool).pipe(
      map(this.imSchool.isTransferSchool.bind(this)),
      tap((isTransfer: boolean) => (this.isTransfer = isTransfer)),
      take(1),
    ).subscribe();
    this.trackMiscPageView();
  }

  private trackMiscPageView () {
    const pageName = 'Regents Prep Wizard';
    const origin = MISC_PAGE_ORIGINS.OTHER_TOOLS;
    const portal = PORTAL_TYPES.SCHOOL;
    this.sharedEventTrackers.trackMiscPageView({ pageName, origin, portal });
  }
}
