import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ApiService } from 'Src/ng2/shared/services/api-service/api-service';
import {
  ILoadStudentAcadiencePayload,
  LoadStudentAcadienceFail,
  LoadStudentAcadienceSuccess,
  LOAD_STUDENT_ACADIENCE,
} from '../../actions/student-acadience-actions/student-acadience-actions';
import { IStudentAcadienceEntity } from '../../reducers/student-acadience-reducer/student-acadience-reducer';

@Injectable()
export class StudentAcadienceEffects {
  constructor (private actions$: Actions, private apiService: ApiService) {}

  loadStudentAcadience$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LOAD_STUDENT_ACADIENCE),
      switchMap((action: { type: string; payload: ILoadStudentAcadiencePayload }) => {
        const { schoolId, studentId, calcConfig } = action.payload;
        return this.apiService.getStudentAcadience({ schoolId, studentId, calcConfig }).pipe(
          switchMap((studentAcadience: IStudentAcadienceEntity) => {
            return [new LoadStudentAcadienceSuccess(studentId, studentAcadience)];
          }),
          catchError(error => of(new LoadStudentAcadienceFail(error))),
        );
      }),
    );
  });
}
