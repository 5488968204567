import { Injectable } from '@angular/core';
import * as moment from 'moment';

import { DateHelpers } from '../../../../../projects/shared/services/date-helpers/date-helpers.service';
import { HistoryAllHelperService, IHistoryRecord, IHistoryRecordText } from './history-all-helpers.service';
import { IDocLog } from '../../typings/interfaces/docLogs.interface';

const SHELTER_SUCCESSMENTOR_FREQUENCY_HUMAN_MAP = {
  'DAILY': 'Daily',
  'WEEKLY': 'Once a week',
  'BIWEEKLY': 'Once every two weeks',
  'TRIWEEKLY': 'Once every three weeks',
  'MONTHLY': 'Once a month'
};

@Injectable()
export class HistoryAllHelperServiceShelter {
  constructor (
    private dateHelpers: DateHelpers,
    private historyAllHelpers: HistoryAllHelperService,
  ) {}

  // creates history card data based on docLog path (JE)
  public constructHistoryRecord (docLog: IDocLog, truncateLimit: number = 20, truncateLength: number = 39): IHistoryRecord {
    if (!docLog) return;
    const { createdAt, historyType, collection } = docLog;
    const time = this.dateHelpers.getMomentSinceVariableUnit(createdAt);
    const age = `${time.count}${time.unit}`;
    const panelOpen = false;
    let historyRecord = null;
    // create condensed and expanded text based on recordType (JE)
    switch (historyType) {
      case 'SHELTER_SUCCESS_MENTOR_ADDED':
        historyRecord = this._createSuccessMentorRecord(docLog);
        break;
      case 'SHELTER_SUCCESS_MENTOR_UPDATED':
      case 'SHELTER_SUCCESS_MENTOR_DELETED':
        historyRecord = this._updatedSuccessMentorRecord(docLog);
        break;
      case 'SHELTER_NOTE_ADDED':
        // Requirements are the same, we are re-using the School notes logic
        historyRecord = this.historyAllHelpers.createNoteAddedHistoryRecord(docLog, truncateLimit, truncateLength);
        break;
      case 'SHELTER_NOTE_UPDATED':
      case 'SHELTER_NOTE_DELETED':
        historyRecord = this.historyAllHelpers.createNoteUpdatedHistoryRecord(docLog, null, truncateLimit, truncateLength);
        break;
      default:
        break;
    }

    let expanded;
    let condensed;
    let modal;
    if (historyRecord) {
      condensed = historyRecord.condensed;
      expanded = historyRecord.expanded;
      modal = historyRecord.modal;
      return {
        id: docLog._id,
        condensed,
        expanded,
        age,
        panelOpen,
        createdAt,
        collection,
        modal,
      };
    } else {
      return null;
    }
  }

  private _createSuccessMentorRecord (docLog: IDocLog): IHistoryRecordText {
    const { newDoc: { mentor, startDate, frequency} } = docLog;
    const startDateFormatted = moment(startDate).format('MMM D, YYYY');
    const frequencyFormatted = SHELTER_SUCCESSMENTOR_FREQUENCY_HUMAN_MAP[frequency];

    const condensed = `Assigned to Success Mentor <span class="history-value">${mentor}</span>`;
    const expanded = `Assigned to Success Mentor <span class="history-value">${mentor}</span> starting <span class="history-value">${startDateFormatted}</span> 
      with <span class="history-value">${frequencyFormatted}</span> frequency`;

    return { condensed, expanded };
  }

  private _updatedSuccessMentorRecord (docLog: IDocLog): IHistoryRecordText {
    const { diffs, metaData: { mentor }} = docLog;
    const status = diffs.find(diff => diff.path === 'status');
    const condensed = this._constructSuccessMentorEditText('condensed', status?.newVal, mentor).condensedText;
    const expanded = this._constructSuccessMentorEditText('expanded', status?.newVal, mentor).expandedText;

    return { condensed, expanded };
  }

  private _constructSuccessMentorEditText(type: string, status: string, mentor: string): { condensedText: string; expandedText: string } {
    const mentorFormatted = (type === 'condensed') ? this.historyAllHelpers.truncate(mentor) : mentor;

    let condensedText;
    let expandedText;
    switch (status) {
      case 'COMPLETE':
        condensedText = `Marked Success Mentor <span class="history-value">${mentorFormatted}</span> complete`
        expandedText = `Marked Success Mentoring with <span class="history-value">${mentorFormatted}</span> complete`
        break;
      case 'DELETED':
        condensedText = `Success Mentoring record <span class="history-value">${mentorFormatted}</span> deleted`
        expandedText = `Success Mentoring record with <span class="history-value">${mentorFormatted}</span> deleted`
        break;
      default:
        condensedText = `Success Mentoring record <span class="history-value">${mentorFormatted}</span> edited`
        expandedText = `Success Mentoring record with <span class="history-value">${mentorFormatted}</span> edited`
        break;
    }

    return { condensedText, expandedText };
  }
}
