<ng-container *ngIf="(reqdOptions$ | async) as reqdOptions">
  <div class="button-container">
    <nv-button [disabled]="!form.valid" (clickButton)="onNext()">Next</nv-button>
  </div>
  <nv-form-field label="Sheet name">
    <nv-textbox placeholder="Enter a sheet name..." [textControl]="form.controls.spreadsheetName">
    </nv-textbox>
  </nv-form-field>

  <div class="pickers" *ngIf="reqdOptions.statusOptions">
    <nv-form-field label="Student status" labelIcon="info-small-hover"
      [labelIconTooltip]="isEditMode ? 'Modifying a filter will alter the data in your existing synced sheet. ' + statusTooltip : statusTooltip">
      <nv-multi-picker [options]="reqdOptions.statusOptions" [selectedOptions]="form.controls.statusOptions.value"
        (selectedOptionsChange)="onStatusOptionsChange($event)" [hasClearButton]="true" [hasAllButton]="false">
      </nv-multi-picker>
    </nv-form-field>
  </div>

  <div class="pickers">
    <nv-form-field label="Students to include">
      <nv-dropdown [options]="reqdOptions.studentFilterCategories"
        (selectOption)="onStudentFilterCategoryChange($event)" [selected]="form.controls.studentFilterCategory.value">
      </nv-dropdown>
    </nv-form-field>

    <ng-container *ngIf="(studentFilterOptions$ | async) as studentFilterOptions">
      <nv-form-field *ngIf="showStudentFilterOptions && studentFilterOptions.length > 0"
        [label]="studentFilterCategoryLabel" class="student-category-options-container">
        <nv-multi-picker [options]="studentFilterOptions" [selectedOptions]="form.controls.studentFilterOptions.value"
          (selectedOptionsChange)="onStudentFilterOptionsChange($event)" [hasClearButton]="true" [hasAllButton]="false">
        </nv-multi-picker>
      </nv-form-field>
    </ng-container>
  </div>

  <nv-note size="small" priority="info">
    {{ (studentCount$ | async) }} students selected
  </nv-note>
</ng-container>