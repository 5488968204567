import { WindowRef } from 'projects/shared/services/window-ref/windowRef';
import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UrlPathService } from 'Src/ng2/shared/services/url-path-service/url-path.service';

@Component({
  selector: 'prep-review-step',
  templateUrl: './prep-review-step.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class PrepReviewStep {
  public stepLabel: string = 'Review your prep courses';

  constructor (
    private activatedRoute: ActivatedRoute,
    private windowRef: WindowRef,
    private urlPathService: UrlPathService,
  ) {}

  goToMasterProgram () {
    const { schoolId } = this.activatedRoute.snapshot.params;
    const url = this.urlPathService.computeDistrictUrlPath(`/school/${schoolId}/settings/master-program`);
    this.windowRef.nativeWindow.open(url, '_blank');
  }
}
