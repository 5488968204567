import { MatButtonModule } from '@angular/material/button';
import { WizardFilterActionsModule } from 'Src/ng2/school/sdc/wizard-filter-actions/wizard-filter-actions.module';
import { CommonModule } from '@angular/common';
import { PlanPastDueExamsStep } from './plan-past-due-exams-step.component';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [CommonModule, WizardFilterActionsModule, MatButtonModule],
  exports: [PlanPastDueExamsStep],
  declarations: [PlanPastDueExamsStep],
  providers: [],
})
export class PlanPastDueExamsStepModule { }
