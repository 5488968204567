import { NvVizTileComponent } from './nv-viz-tile.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { VizModule } from 'Src/ng2/school/mid-level-dashboard/viz/viz.module';
import { TrendsVizModule } from 'Src/ng2/shared/components/trends-viz/trends-viz.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@NgModule({
  imports: [
    CommonModule,
    VizModule,
    TrendsVizModule,
    NgxSkeletonLoaderModule,
  ],
  declarations: [NvVizTileComponent],
  exports: [NvVizTileComponent],
})
export class NvVizTileModule {}
