import { WizardHelpers } from './../../../../services/wizard-helpers.service';
import { MatButtonModule } from '@angular/material/button';
import { WizardFilterActionsModule } from 'Src/ng2/school/sdc/wizard-filter-actions/wizard-filter-actions.module';
import { CommonModule } from '@angular/common';
import { GradPlansStep } from './grad-plans-step.component';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [CommonModule, WizardFilterActionsModule, MatButtonModule],
  exports: [GradPlansStep],
  declarations: [GradPlansStep],
  providers: [WizardHelpers],
})
export class GradPlansStepModule { }
