import { Injectable } from '@angular/core';
import { each, filter, find, map } from 'lodash';
import { Observable } from 'rxjs';
import { map as rxMap } from 'rxjs/operators';
import { IAcademicListStudent } from '../../../../../store/effects/projections';
import { FORMATTED_GROUPING_LISTS, IFormattedList } from '../../mad-lib-groupings.constant';

export const F_AND_P_LEVELS = {
  'Pre-A': 0,
  A: 1,
  B: 2,
  C: 3,
  D: 4,
  E: 5,
  F: 6,
  G: 7,
  H: 8,
  I: 9,
  J: 10,
  K: 11,
  L: 12,
  M: 13,
  N: 14,
  O: 15,
  P: 16,
  Q: 17,
  R: 18,
  S: 19,
  T: 20,
  U: 21,
  V: 22,
  W: 23,
  X: 24,
  Y: 25,
  Z: 26,
};

@Injectable()
export class AssessmentsGrouping {
  constructor() {
    //
  }

  getFandPRelativeScoreGrouping(
    filteredStudents$: Observable<IAcademicListStudent[]>,
    { columns, listType, admin },
  ): Observable<any> {
    return filteredStudents$.pipe(
      rxMap(filteredStudents => {
        const formattedList: IFormattedList = FORMATTED_GROUPING_LISTS.f_and_p_relative_score.getFormattedList({
          columns,
          listType,
        });

        // filter out students who are not scheduled to take the assessment
        const relevantStudents = filter(filteredStudents, student => {
          return student.studentAssessments.length > 0;
        });

        // iterate over students, find studentAssessment with matching admin number
        return relevantStudents.reduce((formattedList, student) => {
          const { studentAssessments } = student;
          const filteredAssmnts = filter(studentAssessments, asmnt => {
            const {
              schoolAssessment: { assessmentNickName },
            } = asmnt;
            return assessmentNickName === 'F & P';
          });

          const latestAssmnt = find(filteredAssmnts, asmnt => asmnt.schoolAssessment.adminNumber === admin);

          const studentRow = columns.reduce((result, column) => {
            const { key, getValueAtPath } = column;
            result[key] = getValueAtPath(student, admin);
            return result;
          }, {});

          // grouping data
          let sectionIndex;
          const relativeScore = latestAssmnt ? latestAssmnt.relativeScore : null;

          // needs assessment
          if (relativeScore === null) sectionIndex = 4;
          // 3 plus below
          else if (relativeScore <= -3) sectionIndex = 0;
          // 2 below
          else if (relativeScore === -2) sectionIndex = 1;
          // 1 below
          else if (relativeScore === -1) sectionIndex = 2;
          // at or above
          else if (relativeScore >= 0) sectionIndex = 3;
          // needs assessments
          else sectionIndex = 4;

          formattedList.sections[sectionIndex].count++;
          formattedList.sections[sectionIndex].data.push(studentRow);

          return formattedList;
        }, formattedList);
      }),
    );
  }

  getFandPInstScoreGrouping(
    filteredStudents$: Observable<IAcademicListStudent[]>,
    { columns, listType, admin },
  ): Observable<any> {
    return filteredStudents$.pipe(
      rxMap(filteredStudents => {
        const formattedList: IFormattedList = FORMATTED_GROUPING_LISTS.f_and_p_instruc_score.getFormattedList({
          columns,
          listType,
        });

        // filter out students who are not scheduled to take the assessment
        const relevantStudents = filter(filteredStudents, student => {
          return student.studentAssessments.length > 0;
        });

        // iterate over students, find studentAssessment with matching admin number
        return relevantStudents.reduce((formattedList, student) => {
          const { studentAssessments } = student;
          const filteredAssmnts = filter(studentAssessments, asmnt => {
            const {
              schoolAssessment: { assessmentNickName },
            } = asmnt;
            return assessmentNickName === 'F & P';
          });

          const latestAssmnt = find(filteredAssmnts, asmnt => asmnt.schoolAssessment.adminNumber === admin);

          const studentRow = columns.reduce((result, column) => {
            const { key, getValueAtPath } = column;
            result[key] = getValueAtPath(student, admin);
            return result;
          }, {});

          // grouping data
          const instructionalScore = latestAssmnt ? latestAssmnt.items[1].value : null;
          let sectionIndex =
            instructionalScore && studentRow.relScore.data.data !== 'Needs assessment'
              ? F_AND_P_LEVELS[instructionalScore]
              : 27;

          formattedList.sections[sectionIndex].count++;
          formattedList.sections[sectionIndex].data.push(studentRow);

          return formattedList;
        }, formattedList);
      }),
    );
  }

  getFandPSupportGrouping(
    filteredStudents$: Observable<IAcademicListStudent[]>,
    { columns, listType, admin },
  ): Observable<any> {
    return filteredStudents$.pipe(
      rxMap(filteredStudents => {
        const formattedList: IFormattedList = FORMATTED_GROUPING_LISTS.f_and_p_support.getFormattedList({
          columns,
          listType,
        });

        // filter out students who are not scheduled to take the assessment
        const relevantStudents = filter(filteredStudents, student => {
          return student.studentAssessments.length > 0;
        });

        // iterate over students, find studentAssessment with matching admin number
        const supportNameMap = {};
        each(relevantStudents, student => {
          const { activeStudentSupports } = student;
          const academicSupports = filter(
            activeStudentSupports,
            studSupport => studSupport.support.category === 'ACADEMIC',
          );

          const studentRow = columns.reduce((result, column) => {
            const { key, getValueAtPath } = column;
            result[key] = getValueAtPath(student, admin);
            return result;
          }, {});

          if (academicSupports.length === 0) {
            formattedList.sections[0].count++;
            formattedList.sections[0].data.push(studentRow);
          } else {
            each(academicSupports, studSupport => {
              const {
                support: { name },
              } = studSupport;
              if (!supportNameMap[name]) {
                supportNameMap[name] = {
                  name,
                  count: 0,
                  data: [],
                  defaultDisplayCount: 10,
                  tooltip: name,
                };
              }
              supportNameMap[name].count++;
              supportNameMap[name].data.push(studentRow);
            });
          }
        });
        const supportSections = map(Object.keys(supportNameMap), name => supportNameMap[name]);
        const sections = [...supportSections, formattedList.sections[0]];
        formattedList.sections = sections;
        return formattedList;
      }),
    );
  }
}
