import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { some } from 'lodash';
import { RegularExpressionsUtility } from '../../utilities/regular-expressions/regular-expressions.utility';

interface IFormValidatorsService {
  // Method in this service that are used for form validation
  // should implement `ValidatorFn Interface` '@angular/forms' (CM).
  formControlHasAValueValidator: ValidatorFn;
  atLeastOneFormControlIsTrueValidator: ValidatorFn;
}
@Injectable()
export class FormValidatorsService implements IFormValidatorsService {
  /**
   * Use for validating that a `FormControl` has a value (CM).
   *
   * Given a form controll:
   * - it returns `null` if a `formControl.value` exists.
   * - it returns { valid: false } if a `formControl.value` does NOT exist.
   *
   * Example Usage:
   * `formBuilder.group(
   *    {
   *      note: 'hello world'
   *    },
   *    {
   *      validator: formControlHasAValueValidator
   *    }
   *  )`
   * @class FormValidatorsService;
   * @method formControlHasAValueValidator
   * @return {null|Object} Null if valid.
   */
  public formControlHasAValueValidator (formControl: FormControl): ValidationErrors | null {
    const { value } = formControl;

    // no validation error
    if (value) return null;

    // validation error
    return {
      valid: false,
    };
  }

  /**
   * Use for validating a boolean `FormGroup` (CM).
   *
   * Given a form group:
   * - it returns `null` if at least one `formControl.value` is `true`.
   * - it returns { valid: false } if all `formControl.value` are `false`.
   *
   * Example Usage:
   * `formBuilder.group(
   *    {
   *      opt1: true,
   *      opt2: false
   *    },
   *    {
   *      validator: isAtLeastOneFormControlTrue
   *    }
   *  )`
   * @class FormValidatorsService;
   * @method atLeastOneFormControlIsTrueValidator
   * @return {null|Object} Null if valid.
   */
  public atLeastOneFormControlIsTrueValidator (formGroup: FormGroup): ValidationErrors | null {
    const controls = formGroup.controls;

    const atLeastOneCheckboxSelected = some(controls, (formControl: FormControl, key: string) => {
      if (formGroup.controls.hasOwnProperty(key)) {
        return formControl.value;
      }

      return false;
    });

    // no validation error
    if (atLeastOneCheckboxSelected) return null;

    // validation error
    return {
      valid: false,
    };
  }

  /**
   * Use for validating a email domain by providing a list of approved domains (jchu).
   *
   * It returns a validator func which further:
   * - validates on the email form control's value
   * - returns `null` if value's domain is included in the list.
   * - returns { domain: true } if value's domain is NOT included in the list.
   *
   * { domain: true } will be placed into the form contorl's errors obj that could be further used to provide a styled hint message
   *
   * Example Usage:
   *
   * ts:
   *  const emailDomainValidator = emailDomainValidatorCtr({ domains })
   *  const formGroup = new FormGroup({});
   *  const formControl = new FormContorl('', {
   *    validators: [Validators.email, emailDomainValidator, ...],
   *    updateOn: 'blur'
   *  });
   *  fromGroup.registerControl('email', formControl);
   *
   * html:
   *  <ng-container *ngIf="formGroup.controls['email'].errors as errs">
   *    <ng-container *ngIf="errs.domain || errs.email">
   *      <nv-note priority="danger">Email address is invalid.</nv-note>
   *    </ng-container>
   *  </ng-container>
   *
   * @class FormValidatorsService;
   * @method emailDomainValidatorCtr
   * @return {fuction} validator function.
   */
  public emailDomainValidatorCtr ({ domains }) {
    return (control: AbstractControl) => {
      const trimed = control.value.trim();
      const val = trimed ? trimed.toLowerCase() : '';
      let domain = null;
      if (val) {
        domain = val.split('@')[1];
      }
      if (domain) {
        const valid = domains.includes(domain);
        if (!valid) {
          return { domain: true };
        }
        return null;
      }
      return null;
    };
  }

  /**
   * Use for validating a email existence by providing a list of already existed emails (jchu).
   * This is a sync validator, NOT a async validator
   *
   * It returns a validator func which further:
   * - validates on the email form control's value
   * - returns `null` if value is NOT included in the list.
   * - returns { dupes: true } if value is included in the list.
   *
   * { dupes: true } will be placed into the form contorl's errors obj that could be further used to provide a styled hint message
   *
   * Example Usage:
   *
   * ts:
   *  const emailDupesValidator = emailDupesValidatorCtr({ emailDupes })
   *  const formGroup = new FormGroup({});
   *  const formControl = new FormContorl('', {
   *    validators: [Validators.email, emailDupesValidator, ...],
   *    updateOn: 'blur'
   *  });
   *  fromGroup.registerControl('email', formControl);
   *
   * html:
   *  <ng-container *ngIf="formGroup.controls['email'].errors as errs">
   *    <ng-container *ngIf="errs.dupes">
   *      <nv-note priority="danger">Email address already exists.</nv-note>
   *    </ng-container>
   *  </ng-container>
   *
   * @class FormValidatorsService;
   * @method emailDupesValidatorCtr
   * @return {fuction} validator function.
   */
  public emailDupesValidatorCtr ({ emailDupes }) {
    return (control: AbstractControl) => {
      const trimed = control.value.trim();
      const val = trimed ? trimed.toLowerCase() : '';
      if (val) {
        const valid = !emailDupes.includes(val);
        if (!valid) {
          return { dupes: true };
        }
        return null;
      }
      return null;
    };
  }

  /**
   * Use for validating a email existence by providing a list of already existed emails (jchu).
   *
   * It returns a validator func which further:
   * - validates on the name form control's value
   * - returns `null` if value passes the regex test.
   * - returns { invalid: true } if value fails the regex test.
   *
   * { invalid: true } will be placed into the form contorl's errors obj that could be further used to provide a styled hint message
   *
   * Example Usage:
   *
   * ts:
   *  const peopleNameValidator = peopleNameValidatorCtr()
   *  const formGroup = new FormGroup({});
   *  const formControl = new FormContorl('', {
   *    validators: [peopleNameValidator, ...],
   *    updateOn: 'blur'
   *  });
   *  fromGroup.registerControl('name', formControl);
   *
   * html:
   *  <ng-container *ngIf="formGroup.controls['name'].errors as errs">
   *    <ng-container *ngIf="errs.invalid">
   *      <nv-note priority="danger">Name must not contain special characters.</nv-note>
   *    </ng-container>
   *  </ng-container>
   *
   * @class FormValidatorsService;
   * @method peopleNameValidatorCtr
   * @return {fuction} validator function.
   */
  readonly peopleNameRegex = RegularExpressionsUtility.peopleNameRegexCtr();
  public peopleNameValidatorCtr (): ValidatorFn {
    return (control: AbstractControl) => {
      const trimed = control.value.trim();
      const val = trimed ? trimed.toLowerCase() : '';
      if (val) {
        const valid = val.match(this.peopleNameRegex);
        if (!valid) {
          return { invalid: true };
        }
        return null;
      }
      return { invalid: true };
    };
  }

  /**
   * Use for validating a invalid email domain .
   *
   * It returns a validator func which further:
   * - validates on the email form control's value
   * - returns `null` if value is NOT the same as the argument.
   * - returns { [key]: true } if value is the same as the argument.
   * - the key is created by spliting the domain and using the first string (ex: schools.nyc.gov => schools)
   * { [key]: true } will be placed into the form contorl's errors obj that could be further used to provide a styled hint message
   *
   * Example Usage:
   *
   * ts:
   *  const emailFieldValidator = this.formValidatorService.emailDomainValidatorCtrForInvalidDomain({ domain: 'schools.nyc.gov' });
   *  email: ['', Validators.compose([emailFieldValidator])],
   *
   *
   * html:
   *  <ng-container *ngIf="formGroup.controls['email'].errors as errs">
   *    <ng-container *ngIf="errs.schools">
   *      <nv-note priority="danger">Enter a valid email that does NOT contain "@schools.nyc.gov"</nv-note>
   *    </ng-container>
   *  </ng-container>
   *
   * @class FormValidatorsService;
   * @method peopleNameValidatorCtr
   * @return {fuction} validator function.
   */
  public emailDomainValidatorCtrForInvalidDomain ({ domain }): ValidatorFn {
    return (control: AbstractControl) => {
      const trimed = control.value.trim();
      const val = trimed ? trimed.toLowerCase() : '';
      let domainName = null;
      if (val) {
        domainName = val.split('@')[1];
      }
      if (domainName) {
        const inValid = domain.includes(domainName);
        if (inValid) {
          const key = domainName.split('.')[0];
          return { [key]: true };
        }
        return null;
      }
      return null;
    };
  }
}
