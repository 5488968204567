export const GRADE_MAPPING = {
  PK: {
    key: 'PK',
    human: 'PK',
    filterValue: -1,
    sortValue: 'PK',
    filterKey: 'grade',
    order: 13,
  },
  '0K': {
    key: 'K',
    human: 'K',
    filterValue: 0,
    sortValue: '0K',
    filterKey: 'grade',
    order: 12,
  },
  '01': {
    key: 'Grade_1',
    human: 'Grade 1',
    filterValue: 1,
    sortValue: '01',
    filterKey: 'grade',
    order: 11,
  },
  '02': {
    key: 'Grade_2',
    human: 'Grade 2',
    filterValue: 2,
    sortValue: '02',
    filterKey: 'grade',
    order: 10,
  },
  '03': {
    key: 'Grade_3',
    human: 'Grade 3',
    filterValue: 3,
    sortValue: '03',
    filterKey: 'grade',
    order: 9,
  },
  '04': {
    key: 'Grade_4',
    human: 'Grade 4',
    filterValue: 4,
    sortValue: '04',
    filterKey: 'grade',
    order: 8,
  },
  '05': {
    key: 'Grade_5',
    human: 'Grade 5',
    filterValue: 5,
    sortValue: '05',
    filterKey: 'grade',
    order: 7,
  },
  '06': {
    key: 'Grade_6',
    human: 'Grade 6',
    filterValue: 6,
    sortValue: '06',
    filterKey: 'grade',
    order: 6,
  },
  '07': {
    key: 'Grade_7',
    human: 'Grade 7',
    filterValue: 7,
    sortValue: '07',
    filterKey: 'grade',
    order: 5,
  },
  '08': {
    key: 'Grade_8',
    human: 'Grade 8',
    filterValue: 8,
    sortValue: '08',
    filterKey: 'grade',
    order: 4,
  },
  '09': {
    key: 'Grade_9',
    human: 'Grade 9',
    filterValue: 9,
    sortValue: '09',
    filterKey: 'grade',
    order: 3,
  },
  '10': {
    key: 'Grade_10',
    human: 'Grade 10',
    filterValue: 10,
    sortValue: '10',
    filterKey: 'grade',
    order: 2,
  },
  '11': {
    key: 'Grade_11',
    human: 'Grade 11',
    filterValue: 11,
    sortValue: '11',
    filterKey: 'grade',
    order: 1,
  },
  '12': {
    key: 'Grade_12',
    human: 'Grade 12',
    filterValue: 12,
    sortValue: '12',
    filterKey: 'grade',
    order: 0,
  },
};
