import { RouterExitService } from './../../../routing/services/router-exit.service';
import { IACOption } from '../../../../../projects/shared/nvps-libraries/design/interfaces/design-library.interface';
import { IShelterPickerOption } from '../../../shell/shelter-picker/shelter-picker.interface';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { PickerDataService } from './picker.data.service';
import { combineLatest, Observable } from 'rxjs';
import { IPartner, PartnerTypes, TValidPartnerTypes } from '../../typings/interfaces/partner.interface';
import { map as rxMap } from 'rxjs/operators';
import { ICluster } from '../../typings/interfaces/cluster.interface';
import { IUser } from '../../typings/interfaces/user.interface';
import { UrlPathService } from '../url-path-service/url-path.service';
import { SessionStorageService } from '../web-storage/session-storage/session-storage.service';

interface IQueryParams {
  [key: string]: string;
}

interface IPreviousState {
  path: string;
  queryParams: IQueryParams;
}

interface IPreviousURL {
  url: string;
  queryParams: IQueryParams;
}

export interface IPickerBackIcon {
  name: 'arrow-left-selected' | 'close-large-blue';
  fn: Function;
}

export interface IPickerAdminIcon {
  name: 'person-small-blue';
  fn: Function;
}

@Injectable()
export class PickerService {
  constructor(
    private router: Router,
    private exitService: RouterExitService,
    private pickerDataService: PickerDataService,
    private urlPathService: UrlPathService,
    private sessionStorageService: SessionStorageService,
  ) {}

  filterAcOptions(acOptions: IACOption[], value: IACOption | string): IACOption[] {
    const onSearch = typeof value === 'string';
    if (onSearch) {
      const searchTerm = (value as string).toLowerCase().trim();
      const alternativeSearchTerm = searchTerm.replace('and', '&');

      return acOptions.filter(option => {
        if (!searchTerm) {
          return true;
        }
        const hasTagMatch: boolean =
          option.tags &&
          !!option.tags.filter(({ human, key }) => {
            return (
              human.toLowerCase().includes(searchTerm) ||
              key.toLowerCase().includes(searchTerm) ||
              human.toLowerCase().includes(alternativeSearchTerm) ||
              key.toLowerCase().includes(alternativeSearchTerm)
            );
          }).length;

        const hasKeyMatch: boolean =
          option.key.toLowerCase().includes(searchTerm) || option.key.toLowerCase().includes(alternativeSearchTerm);

        const hasHumanMatch: boolean =
          option.human.toLowerCase().includes(searchTerm) || option.human.toLowerCase().includes(alternativeSearchTerm);

        return hasKeyMatch || hasTagMatch || hasHumanMatch;
      });
    }
  }

  goBackToExploreUI(): void {
    const url = this.urlPathService.computeDistrictUrlPath('/explore');
    this.router.navigate([url]);
  }

  getBackIcon(): IPickerBackIcon {
    let backIcon: IPickerBackIcon = { name: null, fn: null };
    const { url: prevURL, queryParams: prevURLParams } = this.exitService.previousUrl$.value || {};

    const isValidPreviousURL = this._isValidPreviousURL(prevURL);

    if (prevURL?.match(/\/explore/)) {
      backIcon = { name: 'arrow-left-selected', fn: this.goBackToExploreUI.bind(this) };
    } else if (isValidPreviousURL) {
      backIcon.name = 'close-large-blue';
      const previousURL = { url: prevURL, queryParams: prevURLParams };
      backIcon.fn = this._goBackToPreviousURL(previousURL).bind(this);
    }
    return backIcon;
  }

  getAdminIcon (): IPickerAdminIcon {
    const currentDistrict = this.sessionStorageService.getItem('currentDistrict');
    const accessibleDistricts = this.sessionStorageService.getItem('districts');
    const canManageCurrentDistrictNotifications = currentDistrict && accessibleDistricts.find(({ _id }) => _id === currentDistrict).permissions?.canManageNotifications;
    const otherDistrictNotificationsAdmin = accessibleDistricts.find((district) => district.permissions?.canManageNotifications)?._id;
    const adminIcon: IPickerAdminIcon = canManageCurrentDistrictNotifications
      ? { name: 'person-small-blue', fn: this._goToNotificationsUi.bind(this, currentDistrict) }
      : otherDistrictNotificationsAdmin
        ? { name: 'person-small-blue', fn: this._goToNotificationsUi.bind(this, otherDistrictNotificationsAdmin) }
        : null;

    return adminIcon;
  }

  private _goBackToPreviousURL(previousURL: IPreviousURL): Function {
    return (): void => {
      const { url, queryParams } = previousURL;
      this.router.navigate([url], { queryParams });
    };
  }

  private _goToNotificationsUi (districtId: string): void {
    if (districtId) this.sessionStorageService.setItem('currentDistrict', districtId);
    const url = this.urlPathService.computeDistrictUrlPath('admin/notifications');
    this.router.navigate([url]);
  }

  private _isValidPreviousURL(url: string): boolean {
    const schoolPickerRegex = /\/school-picker/;
    return !!(url && url !== '/shelter-picker' && url !== '/home' && !schoolPickerRegex.test(url));
  }

  // Get a list of school clusters or shelter clusters based on partnerType
  public getClusterOptions (partnerType: TValidPartnerTypes): Observable<IShelterPickerOption[]> {
    return this.pickerDataService.getClusters(partnerType).pipe(
      rxMap((clusters: ICluster[]) =>
        clusters.map(cluster => ({
          key: cluster._id,
          human: `${cluster.clusterName} (${cluster._id})`,
          subLocations: cluster.subLocations,
        })),
      ),
    );
  }

  // Get a list of shelters
  public getPartnerOptions (partnerType: TValidPartnerTypes, partnerId?: string): Observable<IShelterPickerOption[]> {
    if (partnerId) {
      return this.pickerDataService.getPartner(partnerType, partnerId).pipe(
        rxMap((partner: IPartner) => [
          {
            key: partner._id,
            human: `${partner.partnerName} (${partner._id})`,
            subLocations: partner.subLocations,
          },
        ]),
      );
    } else {
      return this.pickerDataService.getPartners(partnerType).pipe(
        rxMap((partners: IPartner[]) =>
          partners.map(partner => ({
            key: partner._id,
            human: `${partner.partnerName} (${partner._id})`,
            subLocations: partner.subLocations,
          })),
        ),
      );
    }
  }

  // Get a list of filtered school clusters or shelter clusters based on partnerType and user input
  public getFilteredClusterOptions (partnerType, searchVal$: Observable<string>): Observable<IShelterPickerOption[]> {
    const clusters$ = this.getClusterOptions(partnerType);
    return combineLatest([clusters$, searchVal$]).pipe(
      rxMap(([clusters, searchVal]) => this.filterAcOptions(clusters, searchVal)),
    );
  }

  // Get a list of filtered schools or shelters based on partnerType and user input
  public getFilteredPartnerOptions (partnerType, searchVal$: Observable<string>, user: IUser): Observable<IShelterPickerOption[]> {
    switch (partnerType) {
      case PartnerTypes.SCHOOL:
      default:
        const { _role_clusterSchools = [] } = user;
        const schools = _role_clusterSchools.map(({ _id, fullName, nickName }) => ({
          key: _id,
          human: fullName,
          tags: [nickName].map(val => ({ key: val, human: val })),
        }));
        return searchVal$.pipe(rxMap(searchVal => this.filterAcOptions(schools, searchVal)));
      case PartnerTypes.SHELTER:
        // eslint-disable-next-line no-case-declarations
        const userPartnerId = user.nvRoleShelter?.shelterId ?? null;
        // If user is a single shelter user, only fetch that shelter, otherwise fetch all shelters associated with a cluster
        // eslint-disable-next-line no-case-declarations
        const shelters$ = this.getPartnerOptions(PartnerTypes.SHELTER, userPartnerId);
        return combineLatest([shelters$, searchVal$]).pipe(
          rxMap(([shelters, searchVal]) => this.filterAcOptions(shelters, searchVal)),
        );
    }
  }

  public getSubLocationOptions (subLocations: IACOption[], searchVal$: Observable<string>): Observable<IShelterPickerOption[]> {
    return combineLatest([searchVal$]).pipe(
      rxMap(([searchVal]) => this.filterAcOptions(subLocations, searchVal)),
    );
  }

  public getPartnerType(url: string): TValidPartnerTypes {
    // matches /school-picker or /shelter-picker in the passed url
    const matched = url.match(/\/(\w+)-\w+/);
    const partnerType = matched && matched[0].split(/\/|-picker/).join('');
    return partnerType as TValidPartnerTypes;
  }

  public navigateToPortal (partnerId: string, partnerType: TValidPartnerTypes, queryParams: object = {}) {
    const url = this.urlPathService.computeDistrictUrlPath(`school/${partnerId}/lists/tiles`);
    switch (partnerType) {
      case 'school':
      default:
        this.router.navigate([url]);
        break;
      case 'shelter': {
        const computedUrl = this.urlPathService.computeDistrictUrlPath(`/shelter/${partnerId}`);
        this.router.navigate([computedUrl], queryParams);
      }
        break;
    }
  }

  public navigateToNetDash(clusterId: string, partnerType: TValidPartnerTypes): void {
    let url: string;
    switch (partnerType) {
      case 'shelter':
        url = this.urlPathService.computeDistrictUrlPath(`/network/shelter/${clusterId}/dashboard`);
        this.router.navigate([url]);
        break;
      case 'school':
      default:
        url = this.urlPathService.computeDistrictUrlPath(`/network/school/${clusterId}`);
        this.router.navigate([url]);
        break;
    }
  }

  public navigateToShelterClusterUserSetting(clusterId: string): void {
    const url = this.urlPathService.computeDistrictUrlPath(`/network/shelter/${clusterId}/settings/user-permissions`);
    this.router.navigate([url]);
  }
}
