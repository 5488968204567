import { Injectable } from '@angular/core';

@Injectable()
export class UserManagementSharedService {
  private _getEmailHtmlString(admins): string {
    let emailString = '';
    switch (admins.length) {
      case 1:
        emailString = `${admins[0].authenticationEmail}`;
        break;
      case 2:
        emailString = `${admins[0].authenticationEmail} or ${admins[1].authenticationEmail}`;
        break;
      default:
        // more than 2
        emailString = admins.reduce((_emailString, admin, i) => {
          _emailString +=
            i === admins.length - 1 ? `, or ${admin.authenticationEmail}` : `${admin.authenticationEmail}, `;
          return _emailString;
        }, '');
        break;
    }
    return `<span class="email">${emailString}</span>`;
  }

  public getHybridClusterUserWarningContent({ admins, mode = 'REMOVE', contextPartnerType = 'school' }): string {
    const hasWarningContent = admins && admins.length;
    let htmlString = '';
    if (hasWarningContent) {
      const emailHtmlString = this._getEmailHtmlString(admins);
      const manageOrManages = admins.length === 1 ? 'manages' : 'manage';
      let action;
      switch (mode) {
        case 'EDIT':
          action = 'setting to no access';
          break;
        case 'REMOVE':
          action = 'removing';
          break;
      }
      let theOtherPortalDataSource;
      let theOtherPortalName;
      switch (contextPartnerType) {
        case 'school':
          theOtherPortalDataSource = 'DOE';
          theOtherPortalName = 'Shelter';
          break;
        case 'shelter':
          theOtherPortalDataSource = 'student';
          theOtherPortalName = 'School';
          break;
      }
      htmlString = `<div>The user you are ${action} will continue to have access to ${theOtherPortalDataSource} data through the ${theOtherPortalName} Portal. Please contact ${emailHtmlString}, who ${manageOrManages} this user’s ${theOtherPortalName} Portal access, for more information.</div>`;
    }
    return htmlString;
  }
}
