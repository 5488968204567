import { ListContainerModule } from 'Src/ng2/shared/components/list/list-container/list-container.module';
import { FixedTableModule } from 'Src/ng2/shared/components/list/fixed-table/fixed-table.module';
import { InfiniteTableModule } from 'Src/ng2/shared/components/list/infinite-table/infinite-table.module';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { NvDesignLibraryModule } from 'Src/nvps-libraries/design/nv-design-library.module';
import { NgRouterNavigationService } from 'Src/ng2/school/lists/services/list-navigation/ng-router-list-navigation.service';

@NgModule({
  imports: [CommonModule, NvDesignLibraryModule, InfiniteTableModule, FixedTableModule, ListContainerModule],
  providers: [NgRouterNavigationService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class FixedToInfiniteViewModule {
  /** */
}
