import { Injectable } from '@angular/core';
import { IClusterUserPermissions } from '../../../typings/interfaces/user.interface';
import { IGroupData, IRowData } from '../../../models/list-models';

@Injectable()
export class PortfolioGroupingService {
  public hashIdsToMove(idsToMove: string[]): { [key: string]: boolean } {
    return idsToMove.reduce((acc, id) => {
      acc[id] = true;
      return acc;
    }, {});
  }

  public getNewGroupings({
    groupData,
    idsToMove,
    destination,
    dropdownHuman,
    groupingColumnKey,
  }): IGroupData[] {
    const { groupData: newGroupData, rowsToMove } = groupData.reduce(
      (groupAcc, grouping: IGroupData) => {
        const newRowData = grouping.rowData.reduce((rowAcc: IRowData[][], row: IRowData[]) => {
          const meta = JSON.parse(row[0].meta);
          const id = meta.data;
          const found = idsToMove[id];

          if (found && grouping.key !== destination) {
            const newRow = row.map(column => {
              let newColumnData;
              if (column.columnKey === groupingColumnKey) {
                newColumnData = dropdownHuman[destination];
              }
              return { ...column, data: newColumnData || column.data };
            });

            groupAcc.rowsToMove.push([...newRow]);
          } else {
            rowAcc.push([...row]);
          }
          return rowAcc;
        }, []);
        groupAcc.groupData.push({ ...grouping, rowData: newRowData });
        return groupAcc;
      },
      { groupData: [], rowsToMove: [] },
    );
    const newGroupings = this.moveRows(newGroupData, rowsToMove, destination);
    return newGroupings;
  }

  public moveRows(groupData: IGroupData[], rowsToMove: IRowData[][], selectedKey: string): IGroupData[] {
    return groupData.map(group => {
      if (group.key === selectedKey) {
        const newRowData = [...group.rowData, ...rowsToMove];
        return { ...group, rowData: newRowData };
      } else {
        return group;
      }
    });
  }

  public getRowIdFromRow(row: IRowData[]): string {
    const stub = row[0];
    const metaData = JSON.parse(stub.meta);
    const id = metaData.data;
    return id;
  }

  public getNewGroupingsWithTargetGroupingExpanded(groupData: IGroupData[], groupIndx: number): IGroupData[] {
    return groupData.reduce((acc: IGroupData[], grouping: IGroupData, i) => {
      let newGrouping: IGroupData;
      if (i === groupIndx) {
        newGrouping = { ...grouping, showAll: true };
        acc.push(newGrouping);
      } else {
        acc.push({ ...grouping });
      }
      return acc;
    }, []);
  }

  public getNewGroupingsWithShowAllAsFalseForAll(groupData: IGroupData[]): IGroupData[] {
    return groupData.reduce((acc: IGroupData[], grouping: IGroupData) => {
      let newGrouping = { ...grouping, showAll: false };
      acc.push(newGrouping);
      return acc;
    }, []);
  }

  public getClusterPortfolioPermissionsChanges(groupData: IGroupData[]): IClusterUserPermissions {
    const _initialPermissions: IClusterUserPermissions = {
      cluster_edit_all: false,
      cluster_view_all: false,
      edit_all: [],
      view_all: [],
      no_access: [],
    };
    let permissionsChanges = groupData.reduce((acc, grouping: IGroupData) => {
      acc[grouping.key as any] = grouping.rowData.map(row => {
        const meta = JSON.parse(row[0].meta);
        const id = meta.data;
        return id;
      });
      return acc;
    }, _initialPermissions);

    if (this.isClusterWideEditor(permissionsChanges)) {
      permissionsChanges = {
        cluster_edit_all: true,
        cluster_view_all: false,
      };
    } else if (this.isClusterWideViewer(permissionsChanges)) {
      permissionsChanges = {
        cluster_view_all: true,
        cluster_edit_all: false,
      };
    }
    return permissionsChanges;
  }

  private isClusterWideEditor(permissions: IClusterUserPermissions): boolean {
    let isEditAll = false;
    const { view_all, edit_all, no_access } = permissions;
    if (!view_all?.length && !no_access?.length && !!edit_all?.length) {
      isEditAll = true;
    }
    return isEditAll;
  }

  private isClusterWideViewer(permissions: IClusterUserPermissions): boolean {
    let isViewAll = false;
    const { view_all, edit_all, no_access } = permissions;
    if (!edit_all?.length && !no_access?.length && !!view_all?.length) {
      isViewAll = true;
    }
    return isViewAll;
  }
}
