/* tslint:disable */

import { Action } from '@ngrx/store';
export const INITIALIZE_PUSHER = '[Portal] Initialize Pusher';

export class InitializePusher implements Action {
  readonly type = INITIALIZE_PUSHER;
  constructor(public payload: any) {}
}

// action types
export type PusherAction = InitializePusher;
