import * as _ from 'lodash';
import { primaryOutcomeMetrics } from '../../../ng2/shared/constants/primary-outcome-metrics.constant';
import { IRegentsExam, RegentsSubject } from '../../../ng2/shared/constants/regents.constant';

function filterSupports(supports: ISupportCategories): { [key: string]: ISupportCategory } {
  const filteredSupports = {};
  _.forEach(supports, (value: ISupportCategory, key) => {
    if (!value.isDeprecated) {
      filteredSupports[key] = value;
    } else {
      return;
    }
  });
  return filteredSupports;
}

export interface ISupportCategory {
  key: string;
  humanName: string;
  humanShort: string;
  isDeprecated: boolean;
  metaData?: string;
  primaryOutcomeMetrics: string[];
  subOptions?: Array<{ value: string; humanName: string; hasActiveSupports?: boolean }>;
  subOptionLabel?: string;
  hasActiveSupports?: boolean;
}

export interface ISupportCategories {
  REGENTS_PREP: ISupportCategory;
  ATTENDANCE: ISupportCategory;
  ACADEMIC: ISupportCategory;
  PHYS_HEALTH_SERVICES: ISupportCategory;
  EXTRACURRICULAR: ISupportCategory;
  MENTAL_HEALTH_SERVICES: ISupportCategory;
  COLLEGE_CAREER: ISupportCategory;
  OTHER: ISupportCategory;
}

export const SupportCategories: ISupportCategories = {
  REGENTS_PREP: {
    key: 'REGENTS_PREP',
    humanName: 'Regents Prep',
    humanShort: 'Regents',
    metaData: 'examSubject',
    isDeprecated: false,
    primaryOutcomeMetrics: primaryOutcomeMetrics.PRIMARY_OUTCOME_METRICS_REGENTS,
    subOptionLabel: 'Select a Regents exam*',
    subOptions: (_.map(RegentsSubject, (subj: IRegentsExam) => {
      return {
        value: subj.key,
        humanName: subj.humanName,
      };
    }) as unknown) as Array<{ value: string; humanName: string; hasActiveSupports?: boolean }>,
  },
  ATTENDANCE: {
    key: 'ATTENDANCE',
    humanName: 'Attendance',
    humanShort: 'Attendance',
    metaData: 'serviceName',
    isDeprecated: false,
    subOptionLabel: 'Select an intervention*',
    subOptions: [
      {
        value: 'Success Mentoring',
        humanName: 'Success Mentoring',
      },
    ],
    primaryOutcomeMetrics: primaryOutcomeMetrics.PRIMARY_OUTCOME_METRCIS_ATT,
  },
  ACADEMIC: {
    key: 'ACADEMIC',
    humanName: 'Academic',
    humanShort: 'Acad',
    metaData: 'serviceName',
    isDeprecated: false,
    primaryOutcomeMetrics: [],
    subOptionLabel: 'Select an intervention*',
    subOptions: [
      {
        value: 'Imagine Math (Renewal)',
        humanName: 'Imagine Math (Renewal)',
      },
      {
        value: 'Rewards(Renewal)',
        humanName: 'Rewards(Renewal)',
      },
      {
        value: 'Wilson Reading (NVCMO)',
        humanName: 'Wilson Reading (NVCMO)',
      },
      {
        value: 'Read 180 (NVCMO)',
        humanName: 'Read 180 (NVCMO)',
      },
      {value: 'ExpandEd - Amplify Education, Inc.', humanName: 'ExpandEd - Amplify Education, Inc.'},
      {value: 'ExpandEd - Boston University/ Catapult Learning', humanName: 'ExpandEd - Boston University/ Catapult Learning'},
      {value: 'ExpandEd - Braintrust Tutors', humanName: 'ExpandEd - Braintrust Tutors'},
      {value: 'ExpandEd - Child Mind Institute, School & Community Programs', humanName: 'ExpandEd - Child Mind Institute, School & Community Programs'},
      {value: 'ExpandEd - City University of New York / CUNY Reading Corps', humanName: 'ExpandEd - City University of New York / CUNY Reading Corps'},
      {value: 'ExpandEd - Helps Education Fund', humanName: 'ExpandEd - Helps Education Fund'},
      {value: 'ExpandEd - Ignite!', humanName: 'ExpandEd - Ignite!'},
      {value: 'ExpandEd - Literacy Trust', humanName: 'ExpandEd - Literacy Trust'},
      {value: 'ExpandEd - Read Alliance Inc.', humanName: 'ExpandEd - Read Alliance Inc.'},
      {value: 'ExpandEd - Reading Partners NYC', humanName: 'ExpandEd - Reading Partners NYC'},
      {value: 'ExpandEd - Springboard Collaborative', humanName: 'ExpandEd - Springboard Collaborative'},
      {value: 'ExpandEd - Success for All Foundation', humanName: 'ExpandEd - Success for All Foundation'},
      {value: 'ExpandEd - Targeted Reading Instruction', humanName: 'ExpandEd - Targeted Reading Instruction'},
      {value: 'ExpandEd - TinyIvy (VIPKID)', humanName: 'ExpandEd - TinyIvy (VIPKID)'},
      {value: 'ExpandEd - Air Tutors', humanName: 'ExpandEd - Air Tutors'},
      {value: 'ExpandEd - Axiom Learning', humanName: 'ExpandEd - Axiom Learning'},
      {value: 'ExpandEd - Brainfuse', humanName: 'ExpandEd - Brainfuse'},
      {value: 'ExpandEd - Catapult Learning LLC', humanName: 'ExpandEd - Catapult Learning LLC'},
      {value: 'ExpandEd - K-12 Connect', humanName: 'ExpandEd - K-12 Connect'},
      {value: 'ExpandEd - Lindamood-Bell Learning Processes', humanName: 'ExpandEd - Lindamood-Bell Learning Processes'},
      {value: 'ExpandEd - Littera', humanName: 'ExpandEd - Littera'},
      {value: 'ExpandEd - PRACTICE', humanName: 'ExpandEd - PRACTICE'},
      {value: 'ExpandEd - Reconstruction', humanName: 'ExpandEd - Reconstruction'},
      {value: 'ExpandEd - SmartStart', humanName: 'ExpandEd - SmartStart'},
      {value: 'ExpandEd - Teach For America NY', humanName: 'ExpandEd - Teach For America NY'},
      {value: 'ExpandEd - CUNY Tutor Corps', humanName: 'ExpandEd - CUNY Tutor Corps'},
      {value: 'ExpandEd - Follow Us to Success', humanName: 'ExpandEd - Follow Us to Success'},
      {value: 'ExpandEd - Intervene K-12', humanName: 'ExpandEd - Intervene K-12'},
      {value: 'ExpandEd - Matheka', humanName: 'ExpandEd - Matheka'},
      {value: 'ExpandEd - New Classrooms', humanName: 'ExpandEd - New Classrooms'},
      {value: 'ExpandEd - Saga', humanName: 'ExpandEd - Saga'},
      {value: 'ExpandEd - Zearn', humanName: 'ExpandEd - Zearn'},
      {value: 'ExpandEd - Other', humanName: 'ExpandEd - Other'},
      { value: 'ExpandEd - Club X Youthniversity', humanName: 'ExpandEd - Club X Youthniversity' },
      {
        value: 'Academic - Other',
        humanName: 'Academic - Other',
      },
    ],
  },
  PHYS_HEALTH_SERVICES: {
    key: 'PHYS_HEALTH_SERVICES',
    humanName: 'Physical Health Services',
    humanShort: 'Phys Health',
    metaData: 'serviceName',
    isDeprecated: false,
    primaryOutcomeMetrics: primaryOutcomeMetrics.PRIMARY_OUTCOME_METRCIS_ATT,
    subOptionLabel: 'Select a service*',
    subOptions: [
      {
        value: 'Vision',
        humanName: 'Vision',
      },
      { value: 'Dental', humanName: 'Dental' },
      { value: 'Asthma', humanName: 'Asthma' },
      { value: 'Health - Other', humanName: 'Health - Other' },
    ],
  },
  MENTAL_HEALTH_SERVICES: {
    key: 'MENTAL_HEALTH_SERVICES',
    humanName: 'Mental Health Services',
    humanShort: 'Mental Health',
    isDeprecated: false,
    primaryOutcomeMetrics: primaryOutcomeMetrics.PRIMARY_OUTCOME_METRCIS_ATT,
  },
  EXTRACURRICULAR: {
    key: 'EXTRACURRICULAR',
    humanName: 'Extracurricular Activities',
    humanShort: 'Extracurricular',
    isDeprecated: false,
    primaryOutcomeMetrics: [],
  },
  COLLEGE_CAREER: {
    key: 'COLLEGE_CAREER',
    isDeprecated: true,
    humanName: 'College and Career Activities',
    humanShort: 'Postsec',
    primaryOutcomeMetrics: [],
  },
  OTHER: {
    key: 'OTHER',
    isDeprecated: false,
    humanName: 'Other',
    humanShort: 'Other',
    primaryOutcomeMetrics: [],
  },
};

// Filter out supports that are not deprecated. (Jack)
export const ActiveSupportCategories = filterSupports(SupportCategories);
