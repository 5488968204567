import { ActivatedRoute } from '@angular/router';
import { WizardHelpers } from './../../../../services/wizard-helpers.service';
import { GraduationPlan } from './../../../../../../shared/constants/graduation-plan.constant';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { reduce } from 'lodash';
import { UrlPathService } from 'Src/ng2/shared/services/url-path-service/url-path.service';

@Component({
  selector: 'grad-plans-step',
  templateUrl: './grad-plans-step.component.html',
  styleUrls: ['../shared-step-styles.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class GradPlansStep implements OnInit {
  @Input() filteredStudents;

  public stepLabel: string = 'Complete graduation planning'
  public studentsWithoutGradPlanOrDate;
  public options;
  public currentFilter;
  public schoolId: string

  constructor (
    public wizardHelpers: WizardHelpers,
    private activatedRoute: ActivatedRoute,
    private urlPathService: UrlPathService,
  ) {}

  ngOnInit (): void {
    this.schoolId = this.activatedRoute.snapshot.params.schoolId;
    // this.currentFilter = _.last(this.WizardUtils.getCurrentCohortFilter());
    this.studentsWithoutGradPlanOrDate = this.getStudentsWithNoGradPlanOrDate();
    this.options = {
      columnKeys: ['gradPlan', 'plannedDiplomaType', 'plannedGraduationDate'],
    };
  }

  ngOnChanges ({ filteredStudents }) {
    if (filteredStudents && !filteredStudents.isFirstChange()) {
      this.ngOnInit();
    }
  }

  getStudentsWithNoGradPlanOrDate (): string[] {
    return reduce(
      this.filteredStudents,
      (studentIdCollection: string[], student: any) => {
        const { gradPlan, studentId } = student;
        if (
          !gradPlan ||
          gradPlan === GraduationPlan.PLAN_INCOMPLETE.humanName ||
          gradPlan === GraduationPlan.PLAN_IN_PAST.humanName
        ) {
          studentIdCollection.push(studentId);
        }
        return studentIdCollection;
      },
      [],
    );
  }

  navigateToGradPlanningWizard (schoolId) {
    const url = this.urlPathService.computeDistrictUrlPath(`/school/${schoolId}/grad-planning/wizard`);
    this.wizardHelpers.goToStateInNewTab(url);
  }
}
