import { MatButtonModule } from '@angular/material/button';
import { FinalPrepCheckStep } from './final-prep-check-step.component';
import { WizardFilterActionsModule } from '../../../../wizard-filter-actions/wizard-filter-actions.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [FinalPrepCheckStep],
  exports: [FinalPrepCheckStep],
  imports: [CommonModule, WizardFilterActionsModule, MatButtonModule],
})
export class FinalPrepCheckStepModule {
  //
}
