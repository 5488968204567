import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { isUndefined } from 'lodash';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { CreateObjectCache, FetchObjectCache } from 'Src/ng2/store/actions';
import { getObjectCache, getObjectCacheLoadedState } from 'Src/ng2/store/selectors';
import { ImCachedObject } from '../im-models/im-cached-object.service';
import { LocalStorageService } from '../web-storage/local-storage/local-storage.service';
import { RollbarService } from '../rollbar/rollbar.service';
import Rollbar from 'rollbar';

@Injectable()
export class ObjectCache {
  constructor(
    private imCachedObject: ImCachedObject,
    private localStorageService: LocalStorageService,
    private store: Store<any>,
    @Inject(RollbarService) private rollbar: Rollbar,
  ) {
    // Remove objectCache from user's local storage
    this.localStorageService.removeItem('ObjectCache');
  }

  public async getObject (key: string): Promise<any> {
    if (!key) return Promise.resolve(null);
    // Object Cache key is a SHA1 hash which is 40 characters long. If the key is not 40 characters long, it is likely invalid
    if (key.length !== 40) this.rollbar.warning('ObjectCache.getObject method called with possible invalid key', key);
    return this.store
      .select(getObjectCacheLoadedState(key))
      .pipe(
        tap((loaded: boolean) => {
          if (isUndefined(loaded)) {
            this.store.dispatch(new FetchObjectCache(key));
          }
        }),
        filter(loaded => loaded),
        switchMap(() => this.store.select(getObjectCache(key))),
        take(1),
      )
      .toPromise();
  }

  public cacheObject (object: any): string {
    const key: string = this.imCachedObject.createHash(object);
    this.store
      .select(getObjectCacheLoadedState(key))
      .pipe(
        filter(loaded => loaded !== false),
        switchMap(() => this.store.select(getObjectCache(key))),
        tap((objectCache: any) => {
          if (!objectCache) {
            this.store.dispatch(new CreateObjectCache({ key, object }));
          }
        }),
        take(1),
      )
      .subscribe();
    return key;
  }
}
