import { validateCourseCode } from './../../directives/input-validator.directive';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UtilitiesService } from 'Src/ng2/shared/services/utilities/utilities.service';
import { CreditRequirements, ICreditRequirement } from './../../constants/credit-requirements.constant';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';
import { ImSchool } from 'Src/ng2/shared/services/im-models/im-school';
import { CodeDeckUrl, ValidCourseCodeRegex } from '../../constants/valid-course-code-regex.constant';
import { ImGapPlan } from '../../services/im-models/im-gap-plan';
import { IGapPlan } from '../../typings/interfaces/gap-plan.interface';
import { Component, Inject, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { DistrictHelpers } from '../../services/district/district-helpers.service';
import { TDistricts } from '../../typings/interfaces/district.interface';

interface ITermYear {
  termYear: string;
  human: string;
}

@Component({
  selector: 'credit-gap-freeform-course-modal',
  templateUrl: './credit-gap-freeform-course-modal.component.html',
  styleUrls: ['./credit-gap-freeform-course-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CreditGapsFreeformCourseModal {
  public freeformModalForm;
  public creditReqs: ICreditRequirement[];
  public isValid = false;
  public freeformGapPlan;
  public allTermYears: ITermYear[];
  public mode: 'EDIT' | 'ADD';
  public selectedTermYear;
  public validCourseCodeRegex;
  public codeDeckUrl = CodeDeckUrl;
  public modalTitle: string;
  public courseForm: FormGroup;
  private district: string;

  @Output() gapPlanUpdate = new EventEmitter()
  @Output() newGapPlan = new EventEmitter()

  constructor (
    private imSchool: ImSchool,
    private utils: UtilitiesService,
    private imGapPlan: ImGapPlan,
    private dialogRef: MatDialogRef<CreditGapsFreeformCourseModal>,
    private districtHelpers: DistrictHelpers,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit () {
    this.modalTitle = this.getModalTitle(this.data.termDetails);
    this.district = this.data.district;
    const schoolDistrict = this.districtHelpers.districtLookup(this.data.school._id, this.district as TDistricts);
    this.creditReqs = _.filter(CreditRequirements, (creditReq: any) => {
      return creditReq.orders.creditGapModal;
    }).map((creditReq: any) => {
      const newCreditReq = {
        ...creditReq,
      };
      newCreditReq.human = creditReq.human[schoolDistrict];
      return newCreditReq;
    });

    // Update term dropdown with values in case its needed
    this.allTermYears = this.buildTermYears(this.data.school, 12);
    // Set selected term year to the passed in term year
    this.selectedTermYear = _.find(this.allTermYears, { termYear: this.data.gapPlan?.termYear.toString() || this.data.termDetails.value.toString() });
    this.validCourseCodeRegex = ValidCourseCodeRegex;

    // Set variables based on whether an existing gapPlan is being edited
    if (this.data.gapPlan) {
      this.mode = 'EDIT';
      this.freeformGapPlan = _.cloneDeep(this.data.gapPlan);
    } else {
      this.mode = 'ADD';
      this.freeformGapPlan = this.imGapPlan.createNew(null, this.data.school._id, this.data.student);
    }

    this.courseForm = new FormGroup({
      gradReq: new FormControl(this.data?.gapPlan?.gradReq || null),
      termYear: new FormControl(this.selectedTermYear.termYear),
      creditValue: new FormControl(this.data?.gapPlan?.creditValue || null, [
        Validators.min(0),
        Validators.max(5),
      ]),
      plan: new FormControl(this.data?.gapPlan?.plan || null, [validateCourseCode(this.data.district)]),
    });
  }

  getModalTitle (termDetails) {
    const { termType, value: termYear } = termDetails;
    switch (termType) {
      case 'FUTURE':
        return 'Add plan to future term';
      case 'CURRENT':
        return 'Add plan to this term';
      case 'NEXT': {
        const termYearHuman = this.utils.getHumanReadableTerm(termYear);
        return `Add plan to term ${termYearHuman}`;
      }
      default:
        throw new Error(`termDetails termType of ${termType} is not valid`);
    }
  }

  // Build an array of term years, starting with the nextTermYear
  buildTermYears (school, numTermYears: number): ITermYear[] {
    const currentTermYear = this.imSchool.getCurrentTermYear(this.data.school);
    let nextTermYear = currentTermYear;
    let counter = 0;

    const allTermYears = [
      {
        human: this.utils.getHumanReadableTerm(currentTermYear),
        termYear: currentTermYear.toString(),
      },
    ];

    while (counter < numTermYears) {
      nextTermYear = this.imSchool.getNextTermYear(school, nextTermYear);
      const human = this.utils.getHumanReadableTerm(nextTermYear);
      allTermYears.push({
        human,
        termYear: nextTermYear.toString(),
      });
      counter++;
    }

    return allTermYears;
  }

  makePatch (newPlan, oldPlan) {
    const patch: any = {};

    if (newPlan.termYear !== oldPlan.termYear) {
      patch.termYear = parseInt(newPlan.termYear);
    }

    if (newPlan.plan !== oldPlan.plan) {
      patch.plan = newPlan.plan;
    }

    if (newPlan.gradReq !== oldPlan.gradReq) {
      patch.gradReq = newPlan.gradReq;
    }

    if (newPlan.creditValue !== oldPlan.creditValue) {
      patch.creditValue = newPlan.creditValue;
    }

    if (_.size(patch)) {
      patch._id = newPlan._id;
      return patch;
    } else {
      return null;
    }
  }

  updateGapPlan (gapPlan) {
    const patch = this.makePatch(gapPlan, this.data.gapPlan);
    if (!patch) return this.closeModal();
    gapPlan.termYear = parseInt(gapPlan.termYear);
    this.gapPlanUpdate.emit({ _id: this.data.gapPlan._id, patch });
    this.closeModal();
  }

  createGapPlan (gapPlan): Promise<IGapPlan> | void {
    gapPlan = { ...this.imGapPlan.createNew(null, this.data.school._id, this.data.student), ...gapPlan };
    gapPlan.termYear = parseInt(gapPlan.termYear);
    this.data.studentGapPlans.push(gapPlan); // this feels unnecessary, check if we need this after functional
    this.newGapPlan.emit(gapPlan);
    this.closeModal();
  }

  closeModal () {
    this.dialogRef.close();
  }
}
