import { Injectable } from '@angular/core';
import { studentEditableMap } from './../../constants/paths/student-editable-map.constant';
import { studentSupportMap } from './../../constants/paths/student-support-map.constant';

@Injectable()
export class PathParserService {
  constructor() {
    //
  }

  studentEditableHumanPath(path: string): string {
    const splitPath = path.split('.');
    const pathPenultimate = splitPath[splitPath.length - 2];
    const pathFinal = splitPath[splitPath.length - 1];
    const humanReadable = studentEditableMap[path];
    // parse final two path branches if map doesn't contain the path (JE)
    return humanReadable ? humanReadable : `${pathPenultimate} ${pathFinal}`;
  }

  studentSupportHumanPath(path: string): string {
    const splitPath = path.split('.');
    const pathPenultimate = splitPath[splitPath.length - 2];
    const pathFinal = splitPath[splitPath.length - 1];
    const humanReadable = studentSupportMap[path];
    // parse final two path branches if map doesn't contain the path (JE)
    return humanReadable ? humanReadable : `${pathPenultimate} ${pathFinal}`;
  }
}
