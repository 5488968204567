import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { ApiService } from 'Src/ng2/shared/services/api-service/api-service';
import * as studentGroupingsActions from '../../actions/student-groupings-actions/student-groupings-actions';
import { generateQuery } from '../projections/student-groupings';

@Injectable()
export class StudentGroupingsEffects {
  constructor(private actions$: Actions, private apiService: ApiService) {}

  loadStudentGroupings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<any>(studentGroupingsActions.LOAD_STUDENT_GROUPINGS),
      switchMap(payload => {
        const query = generateQuery(payload);
        const queryPayload = {
          query,
          fetchPolicy: 'no-cache',
        };
        return this.apiService.getStudentsGraphQL(queryPayload).pipe(
          mergeMap(({ data: { StudentGroupings } }) => [
            new studentGroupingsActions.LoadStudentGroupingsSuccess(StudentGroupings),
          ]),
          catchError(error => from([new studentGroupingsActions.LoadStudentGroupingsFail(error)])),
        );
      }),
    );
  });
}
