import { IRegentsCategory, RegentsCategory } from '../../../ng2/shared/constants/regents.constant';

export const RegentsCategoryByCategory5 = {
  required: [
    RegentsCategory.ELA,
    RegentsCategory.MATH,
    RegentsCategory.SCIENCE,
    RegentsCategory.SOCIAL_STUDIES,
    RegentsCategory.PLUS_1,
  ] as IRegentsCategory[],
  optional: [
    RegentsCategory.LOTE,
    RegentsCategory.SECOND_SS,
    RegentsCategory.SECOND_SCI,
    RegentsCategory.SECOND_MATH,
    RegentsCategory.THIRD_MATH,
  ] as IRegentsCategory[],
};
