import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IObjectCache } from '../reducers/object-cache-reducer';

const getPortalState = createFeatureSelector('PORTAL_STORE');

export const getObjectCacheSlice = createSelector(
  getPortalState,
  (slice: IObjectCache) => {
    return slice.objectCache;
  },
);

export const getObjectCacheLoadedSlice = createSelector(
  getObjectCacheSlice,
  (slice: IObjectCache) => {
    return slice.loaded;
  },
);

export const getObjectCache = (key: string) =>
  createSelector(
    getObjectCacheSlice,
    (state: IObjectCache) => {
      return state.objectCache[key];
    },
  );

export const getObjectCacheLoadedState = (key: string) =>
  createSelector(
    getObjectCacheSlice,
    (state: IObjectCache) => {
      return state.loaded[key];
    },
  );
