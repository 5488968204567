import { Injectable } from '@angular/core';
import { reduce } from 'lodash';
import { IDataLoadParams } from '../../../typings/interfaces/data-load.interface';

interface ICollegeListToCreate {
  studentId: string;
  pathId: string;
}
@Injectable()
export class GraphQLDataLoadsHelperService {
  constructor () {
    //
  }

  getDataLoadsQuery (schoolId, where = '{}'): string {
    return `{
      DataLoads(schoolId: "${schoolId}", where: "${where}"){
        _id
        schoolId
        context
        status
        createdAt
        createdBy {
          firstName
          lastName
        }
        type
        metaData {
          changes {
            studentIds
          }
          term
          mp
        }
      }
    }`;
  }

  createDataLoad (dataLoadsParams: IDataLoadParams): string {
    const { errors, toCreate, toUpdate, schoolId, type, context, meta } = dataLoadsParams;
    let toCreateString;
    let metaDataString;
    let toUpdateString = '';
    if (context === 'COLLEGE_LIST') {
      toCreateString = this._collegeListToCreateString(toCreate);
      toUpdateString = reduce(
        toUpdate,
        (res, patch) => {
          const { studentId, studentPathId, status } = patch;
          res += `{studentId: "${studentId}", studentPathId: "${studentPathId}", status: "${status}"},`;
          return res;
        },
        '',
      );
      toUpdateString = toUpdateString.substring(0, toUpdateString.length - 1);
    }

    let errorsString = reduce(
      errors,
      (res, error) => {
        res += `"""${error}""",`; /// """ block quote allows for complex spacing in graphql payload (JE)
        return res;
      },
      '',
    );
    errorsString = errorsString.substring(0, errorsString.length - 1);

    let optionsString = `schoolId: "${schoolId}", type: "${type}", context: "${context}", toCreate: [${toCreateString}], toUpdate: [${toUpdateString}], errors: [${errorsString}]`;
    if (metaDataString) optionsString += `, metaData: ${metaDataString}`;
    return `mutation{
      createDataLoad(options: {${optionsString}}){
        backgroundJobId
      }
    }`;
  }

  _collegeListToCreateString (toCreate: ICollegeListToCreate[]): string {
    let toCreateString = reduce(
      toCreate,
      (res, patch) => {
        const { studentId, pathId } = patch;
        res += `{studentId: "${studentId}", pathId: "${pathId}"},`;
        return res;
      },
      '',
    );
    // remove trailing comma
    toCreateString = toCreateString.substring(0, toCreateString.length - 1);
    return toCreateString;
  }
}
