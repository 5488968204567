import { each, forEach, reduce } from 'lodash';
import { IStudentSupport } from '../../shared/typings/interfaces/student-support.interface';
import * as studentSupportsActions from '../actions/student-supports-actions';

export interface IStudentSupportsState {
  loading: boolean;
  loaded: boolean;
  studentSupportsEntities: {
    [id: string]: IStudentSupport;
  };
}

export const initialState: IStudentSupportsState = {
  loading: false,
  loaded: false,
  studentSupportsEntities: {},
};

export function reducer(
  state: IStudentSupportsState = initialState,
  action: studentSupportsActions.StudentSupportsAction,
): IStudentSupportsState {
  switch (action.type) {
    case studentSupportsActions.LOAD_STUDENT_SUPPORTS:
      return {
        ...state,
        loading: true,
      };
    case studentSupportsActions.LOAD_STUDENT_SUPPORTS_SUCCESS:
      const studentSupports = action.payload;
      const studentSupportsEntities: IStudentSupportsState['studentSupportsEntities'] = reduce(
        studentSupports,
        (acc, studentSupport: IStudentSupport) => {
          acc[studentSupport._id] = studentSupport;
          return acc;
        },
        {},
      );
      return {
        ...state,
        loading: false,
        loaded: true,
        studentSupportsEntities,
      };
    case studentSupportsActions.UPDATE_STUDENT_SUPPORT_SUCCESS: {
      const updatedStudentSupport = action.payload;
      const updatedStudentSupportId = updatedStudentSupport._id;
      const studentSupportsEntities: IStudentSupportsState['studentSupportsEntities'] = Object.assign(
        {},
        state.studentSupportsEntities,
      );

      studentSupportsEntities[updatedStudentSupportId] = updatedStudentSupport;

      return {
        ...state,
        studentSupportsEntities,
      };
    }
    case studentSupportsActions.UPDATE_STUDENT_SUPPORT_FAILURE: {
      return { ...state };
    }

    case studentSupportsActions.CREATE_STUDENT_SUPPORT_SUCCESS: {
      const createdStudentSupport = action.payload;
      const { _id } = createdStudentSupport;
      const studentSupportsEntities: IStudentSupportsState['studentSupportsEntities'] = Object.assign(
        {},
        state.studentSupportsEntities,
      );

      studentSupportsEntities[_id] = createdStudentSupport;

      return {
        ...state,
        studentSupportsEntities,
      };
    }

    case studentSupportsActions.BULK_CREATE_STUDENT_SUPPORTS_SUCCESS: {
      const studentSupports = action.payload;
      const studentSupportsEntities: IStudentSupportsState['studentSupportsEntities'] = Object.assign(
        {},
        state.studentSupportsEntities,
      );
      each(studentSupports, (ss: IStudentSupport) => {
        studentSupportsEntities[ss._id] = ss;
      });
      return {
        ...state,
        studentSupportsEntities,
      };
    }

    case studentSupportsActions.BULK_CREATE_STUDENT_SUPPORTS_FAILURE: {
      return { ...state, loaded: false, loading: false };
    }

    case studentSupportsActions.BULK_UPDATE_STUDENT_SUPPORTS: {
      return { ...state, loading: true };
    }

    case studentSupportsActions.BULK_UPDATE_STUDENT_SUPPORTS_SUCCESS: {
      const { studentSupportIds, status } = action.payload;
      const studentSupportsEntities = Object.assign({}, state.studentSupportsEntities);
      forEach(studentSupportIds, (studentSupportId: string) => {
        const updatedEntity = Object.assign({}, studentSupportsEntities[studentSupportId]);
        updatedEntity.status = status;
        studentSupportsEntities[studentSupportId] = updatedEntity;
      });
      return { ...state, studentSupportsEntities };
    }

    case studentSupportsActions.BULK_UPDATE_STUDENT_SUPPORTS_FAILURE: {
      return { ...state, loaded: false, loading: false };
    }

    case studentSupportsActions.STUDENT_SUPPORTS_BACKGROUND_JOB_STARTED: {
      return { ...state, loaded: false, loading: true };
    }
    case studentSupportsActions.STUDENT_SUPPORTS_BACKGROUND_JOB_COMPLETED: {
      return { ...state, loaded: true, loading: false };
    }

    default:
      return state;
  }
}
