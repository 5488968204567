<ul *ngIf="activeStudentsWithIncompleteGradPlansIds.length > 0">
    <li class="mat-body-2">
        Of the
        <wizard-filter-actions [data]="activeStudentsIds" [options]="options"></wizard-filter-actions>

        <ng-container [ngPlural]="activeStudentsWithIncompleteGradPlansIds.length">
            <ng-template ngPluralCase="1"> student </ng-template>
            <ng-template ngPluralCase="few"> students </ng-template>
            <ng-template ngPluralCase="other"> students </ng-template>
        </ng-container>

        <span class="mat-subtitle-2" *ngIf="currentFilter !== 'All'">in the class of {{ currentFilter }} </span>

        <wizard-filter-actions [data]="activeStudentsWithIncompleteGradPlansIds" [options]="options">
        </wizard-filter-actions>

        are missing a planned diploma type and/or planned graduation date,
        or have a planned graduation date in the past.
    </li>

    <!-- if current cohort has super seniors -->
    <li class="mat-body-2" *ngIf="hasSuperSeniors()">
        Of the
        <wizard-filter-actions [data]="activeSuperSeniorsIds" [options]="options"></wizard-filter-actions>
        
        <ng-container [ngPlural]="countActiveSuperSeniors">
            <ng-template ngPluralCase="1"> student </ng-template>
            <ng-template ngPluralCase="few"> students </ng-template>
            <ng-template ngPluralCase="other"> students </ng-template>
        </ng-container>
        from previous cohorts

        <wizard-filter-actions [data]="activeSuperSeniorsWithIncompleteGradPlansIds" [options]="options">
        </wizard-filter-actions>
        are missing a planned diploma type and/or planned graduation date, or have a planned graduation date
        in the past.
    </li>
</ul>

<button class="step-action-button" mat-raised-button (click)="navigateToGrid()">
    Review {{ countStudentsToReview }}

    <ng-container [ngPlural]="countStudentsToReview">
        <ng-template ngPluralCase="1"> student </ng-template>
        <ng-template ngPluralCase="few"> students </ng-template>
        <ng-template ngPluralCase="other"> students </ng-template>
    </ng-container>
</button>

<p *ngIf="countActiveStudentsWithIncompleteGradPlans === 0" class="mat-body-2">No students in Cohort {{
    currentFilter }} have incomplete graduation plans.</p>