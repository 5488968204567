import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseModalComponent } from '../base-modal.component';
import { IBaseModalData } from '../modals.service';

export interface IBehavioralIncident extends IBaseModalData {
  id: string;
  incidentDate: string;
  incidentDateForModal: string;
  description: string;
  studentRole: string;
  createdBy: string;
  consequenceAssigned: string;
  consequenceAction: string;
  consequenceStartAndEndDateForModal: string;
}
@Component({
  selector: 'behavioral-incident-detail',
  templateUrl: './behavioral-incident-detail.component.html',
  styleUrls: ['./behavioral-incident-detail.component.scss'],
})
export class BehavioralIncidentDetailModal extends BaseModalComponent implements OnInit {
  public itemCount: number;
  public itemType = 'student';
  public isProfileMode?: boolean = true;
  public title = 'Incident details';
  public incidentId: string;
  public incidentDate: string;
  public incidentDescription: string;
  public incidentStudentRole: string;
  public incidentCreatedBy: string;
  public incidentConsequence: string;
  public incidentConsequenceAction: string;
  public incidentConsequenceDates: string;

  constructor (
    public dialogRef: MatDialogRef<BehavioralIncidentDetailModal>,
    @Inject(MAT_DIALOG_DATA) public data: IBehavioralIncident,
  ) {
    super(dialogRef);
  }

  ngOnInit (): void {
    const { id, incidentDateForModal, description, studentRole, createdBy, consequenceAssigned, consequenceAction, consequenceStartAndEndDateForModal } = this.data;
    this.incidentId = id;
    this.incidentDate = incidentDateForModal;
    this.incidentDescription = description || '-';
    this.incidentStudentRole = studentRole || '-';
    this.incidentCreatedBy = createdBy || '-';
    this.incidentConsequence = consequenceAssigned || '-';
    this.incidentConsequenceAction = consequenceAction || '-';
    this.incidentConsequenceDates = consequenceStartAndEndDateForModal;
  }
}
