import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';
import { NvFormFieldModule } from '../../../../../../nvps-libraries/design/nv-form-field/nv-form-field.module';
import { NvDropdownModule } from '../../../../../../nvps-libraries/design/nv-dropdown/nv-dropdown.module';
import { NvPillButtonModule } from '../../../../../../nvps-libraries/design/nv-pill-button/nv-pill-button.module';
import { NvButtonModule } from 'projects/shared/nvps-libraries/design/nv-button/nv-button.module';
import { UpdateSelectorComponent } from './update-selector.component';

@NgModule({
  declarations: [UpdateSelectorComponent],
  imports: [
    CommonModule,
    MatRadioModule,
    NvDropdownModule,
    NvFormFieldModule,
    NvButtonModule,
    NvPillButtonModule,
  ],
  exports: [UpdateSelectorComponent],
  providers: [],
})
export class UpdateSelectorModule {}
