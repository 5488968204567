import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UtilitiesService } from './../utilities/utilities.service';
import { PortalConfig } from '../portal-config';

export interface IProjection {
  [path: string]: true | 1;
}

export interface IData {
  editablePaths?: string[];
  paths: string[];
  staticPaths?: string[];
  values: any[][];
}

interface IItems {
  begin: number;
  end: number;
  limit: number;
  total: number;
}

interface IPages {
  current: number;
  hasNext: boolean;
  hasPrev: boolean;
  next: number;
  prev: number;
  total: number;
}

interface IStudentDataRes {
  data: IData;
  items: IItems;
  pages: IPages;
}

export interface IFetchDataFromEndpointRes<T> {
  data: T[];
  paths: string[];
}


/* istanbul ignore next */
@Injectable()
export class StudentFetchService {
  readonly STUDENT_LIST_ENDPOINT_URL: string;

  private publicConfig: PortalConfig['publicConfig'];

  constructor (
    private $http: HttpClient,
    private utilitiesService: UtilitiesService,
    private portalConfig: PortalConfig,
  ) {
    this.publicConfig = this.portalConfig.publicConfig;

    const {
      NV_API_ORIGIN,
    } = this.publicConfig;
    this.STUDENT_LIST_ENDPOINT_URL = NV_API_ORIGIN + '/v1/students/search';
  }

  // Fetches student data.
  // Returns array of students with each obj containing the specified projection,
  // Using generic <T> instead of IStudent, since the shape of a student returned is dependent on the projection. (CM)
  async fetchStudentData<T> (opts: {
    joins: any;
    projection: IProjection;
    schoolId: string;
    includeStudentTypeFilter: boolean;
    isEms: boolean;
    whereFilter: object;
  }): Promise<T[]> {
    const { joins, projection, schoolId, includeStudentTypeFilter, isEms, whereFilter } = opts;
    const fetchDataFromEndpointOpts = { schoolId, projection, joins, includeStudentTypeFilter, isEms, whereFilter };
    const endpointData = this.fetchDataFromEndpoint<T>(fetchDataFromEndpointOpts);
    let endpointDataResolved: IFetchDataFromEndpointRes<T>;

    try {
      endpointDataResolved = await endpointData;
    } catch (err) {
      // We throw an error to get a stack trace (CM)
      throw this.utilitiesService.handleAwaitErr(err);
    }

    return endpointDataResolved.data;
  }

  private async fetchDataFromEndpoint<T> (opts: {
    joins: any;
    projection: IProjection;
    schoolId: string;
    includeStudentTypeFilter: boolean;
    isEms: boolean;
    whereFilter: object;
  }): Promise<IFetchDataFromEndpointRes<T>> {
    const { joins, projection, schoolId, includeStudentTypeFilter, isEms, whereFilter } = opts;
    const diffProjection: IProjection = projection;
    const diffJoins: string[] = joins;


    const url = this.STUDENT_LIST_ENDPOINT_URL;
    const data: {
      schoolId: string;
      projection: IProjection;
      joins: string[];
      where?: object;
    } = {
      schoolId,
      projection: diffProjection,
      joins: diffJoins,
      // `includePathTypes` makes response include `staticPaths` and `editablePaths`.
      // `staticPaths` includes the fields in the projection that are static.
      // `editablePaths` includes the fields in the projection that are editable (CM).
    };

    if (includeStudentTypeFilter) {
      let studentTypeFilter;

      if (isEms) {
        studentTypeFilter = {
          $or: [{ isES: true }, { isMS: true }],
        };
      } else {
        studentTypeFilter = { isHS: { $eq: true } };
      }
      data.where = { ...studentTypeFilter };
    }

    if (whereFilter) {
      data.where = { ...data.where, ...whereFilter };
    }

    const config = { params: { schoolId } };
    let req: Promise<IStudentDataRes>;
    let res: IStudentDataRes;
    try {
      req = this.$http.post<IStudentDataRes>(url, data, config).toPromise();
      res = await req;
    } catch (err) {
      // We throw an error to get a stack trace (CM)
      throw this.utilitiesService.handleAwaitErr(err);
    }

    const zippedData = res && res.data ? res.data : { paths: [], values: [], staticPaths: [], editablePaths: [] };
    const unzippedData = this.utilitiesService.unzipData(zippedData);
    const { paths } = zippedData;
    return { data: unzippedData, paths };
  }
}
