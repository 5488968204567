import { Injectable } from '@angular/core';
import { District, TDistricts } from '../../typings/interfaces/district.interface';

@Injectable()
export class DistrictHelpers {
  public districtLookup (schoolId: string, district: TDistricts): TDistricts {
    if (schoolId === '84M202') return District.GO;
    if (schoolId === '84M522') return District.BROOME;
    return district;
  };
}
