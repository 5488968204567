/* tslint:disable */
import { Action } from '@ngrx/store';

export const CREATE_ATTENDANCE_RECORD = '[Portal] Create Attendance Records';
export const CREATE_ATTENDANCE_RECORD_SUCCESS = '[Portal] Create Attendance Records Success';
export const CREATE_ATTENDANCE_RECORD_FAILURE = '[Portal] Create Attendance Records Failure';

export const UPDATE_ATTENDANCE_RECORD = '[Portal] Update Attendance Record';
export const UPDATE_ATTENDANCE_RECORD_SUCCESS = '[Portal] Update Attendance Record Success';
export const UPDATE_ATTENDANCE_RECORD_FAILURE = '[Portal] Update Attendance Record Failure';

export const BULK_UPDATE_ATTENDANCE_RECORDS = '[Portal] Bulk Update Attendance Records';
export const BULK_UPDATE_ATTENDANCE_RECORDS_SUCCESS = '[Portal] Bulk Update Attendance Records Success';
export const BULK_UPDATE_ATTENDANCE_RECORDS_FAILURE = '[Portal] Bulk Update Attendance Records Failure';

export const BULK_CREATE_ATTENDANCE_RECORDS = '[Portal] Bulk Create Attendance Records';
export const BULK_CREATE_ATTENDANCE_RECORDS_SUCCESS = '[Portal] Bulk Create Attendance Records Success';
export const BULK_CREATE_ATTENDANCE_RECORDS_FAILURE = '[Portal] Bulk Create Attendance Records Failure';

// register store actions
export class CreateAttendanceRecord implements Action {
  readonly type = CREATE_ATTENDANCE_RECORD;
  constructor(public payload: any) {}
}

export class CreateAttendanceRecordSuccess implements Action {
  readonly type = CREATE_ATTENDANCE_RECORD_SUCCESS;
  constructor(public payload: any) {}
}

export class CreateAttendanceRecordFail implements Action {
  readonly type = CREATE_ATTENDANCE_RECORD_FAILURE;
  constructor(public payload: any) {}
}

export class UpdateAttendanceRecord implements Action {
  readonly type = UPDATE_ATTENDANCE_RECORD;
  constructor(public payload: any) {}
}
export class UpdateAttendanceRecordSuccess implements Action {
  readonly type = UPDATE_ATTENDANCE_RECORD_SUCCESS;
  constructor(public payload: any) {}
}
export class UpdateAttendanceRecordFailure implements Action {
  readonly type = UPDATE_ATTENDANCE_RECORD_FAILURE;
  constructor(public payload: any) {}
}
export class BulkCreateAttendanceRecords implements Action {
  readonly type = BULK_CREATE_ATTENDANCE_RECORDS;
  constructor(public payload: any) {}
}

export class BulkCreateAttendanceRecordsSuccess implements Action {
  readonly type = BULK_CREATE_ATTENDANCE_RECORDS_SUCCESS;
  constructor(public payload: any) {}
}

export class BulkCreateAttendanceRecordsFail implements Action {
  readonly type = BULK_CREATE_ATTENDANCE_RECORDS_FAILURE;
  constructor(public payload: any) {}
}

export class BulkUpdateAttendanceRecords implements Action {
  readonly type = BULK_UPDATE_ATTENDANCE_RECORDS;
  constructor(public payload: any) {}
}

export class BulkUpdateAttendanceRecordsSuccess implements Action {
  readonly type = BULK_UPDATE_ATTENDANCE_RECORDS_SUCCESS;
  constructor(public payload: any) {}
}

export class BulkUpdateAttendanceRecordsFail implements Action {
  readonly type = BULK_UPDATE_ATTENDANCE_RECORDS_FAILURE;
  constructor(public payload: any) {}
}

// action types
export type AttendanceRecordsAction =
  | CreateAttendanceRecord
  | CreateAttendanceRecordSuccess
  | CreateAttendanceRecordFail
  | UpdateAttendanceRecord
  | UpdateAttendanceRecordSuccess
  | UpdateAttendanceRecordFailure
  | BulkCreateAttendanceRecords
  | BulkCreateAttendanceRecordsSuccess
  | BulkCreateAttendanceRecordsFail
  | BulkUpdateAttendanceRecords
  | BulkUpdateAttendanceRecordsSuccess
  | BulkUpdateAttendanceRecordsFail;
