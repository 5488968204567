// tslint:disable: jsdoc-format
import { Overlay } from '@angular/cdk/overlay';
import { Component, EventEmitter, Input, Output, ElementRef, Injector, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { IPickerOption } from './nv-multi-picker.interface';
import { NvMultipickerMenuService } from './nv-multi-picker.service';

export interface IOptionClickedEvent {
  selected: boolean;
  value: string;
}

/**
 *
 * A counterpart to the Dropdown, the Multi-picker allows users to select multiple values from a set of options.
 *
 * It's recommended that you disable the search box when there are fewer than 9 options.
 *
 */
@Component({
  selector: 'nv-multi-picker',
  templateUrl: './nv-multi-picker.component.html',
  styleUrls: ['./nv-multi-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NvMultiPickerComponent {
  /**
   *
   *
   * Array of `IPickerOptions` that defines what the user can select
```

interface IPickerOption {
  key: string,
  human: string
}

```
   *
   * @required
   */
  @Input() options: IPickerOption[];

  /**
   *
   * Array of keys that match with keys provided in `options`.
   *
   * Use 2-way binding to receive updates in the parent component
   *
   * e.g. `[(selectedOptions)]="myArrayOfSelectedOptions"`
   *
   * @required
   */
  @Input() selectedOptions: string[] = [];

  /**
   * Allows specifying some number of entries from input options to maintain their positions
   * at the start or the end, regardless of alphabetical re-sorting.
   * Only relevant if alphabetize is true
   */
  @Input() startCount: number = 0;
  //Unless overridden, assumes that most lists will have an "Other" option at the end,
  //which should maintain its position
  //Note that this is fairly safe since it is irrelevant unless someone intentionally
  //activates alphabetize. At which point, they should consider what to set for other props
  @Input() endCount: number = 1;

  /**
   * If true, options will be sorted alphabetically. See also startCount and endCount
   */
  @Input() alphabetize: boolean = false;


  @Output() selectedOptionsChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  /**
   *
   * The base color of the pill
   */
  @Input() color: string = 'blue';

  /**
   *
   * When `true`, this sets the text to `color`, and the background to a light version of `color`
   */
  @Input() isInverted: boolean = true;

  /**
   *
   * When `true`, this disables the pill component
   */
  @Input() isDisabled: boolean = false;

  /**
   *
   * Defines whether the option to **select all** options exists
   */
  @Input() hasAllButton: boolean = true;

  /**
   *
   * Defines whether the option to **clear all** selected options exists
   */
  @Input() hasClearButton: boolean = true;

  /**
   * Emits an IOptionClickedEvent when any option is clicked
   * selected field is true when the option is selected, false when deselected
   */
  @Output() optionClicked: EventEmitter<IOptionClickedEvent> = new EventEmitter<IOptionClickedEvent>();
  /**
   *
   * Defines the text displayed when no selections have been made
   */
  @Input() placeholder: string = 'None';

  /**
   *
   * Defines whether the search box appears at the top.
   * It's recommended to set this to `false` if there are fewer than 9 `options` provided
   */
  @Input() hasSearchBox: boolean = true;

  /**
   *
   * Passes an optional pillLabel from the parent, default to empty string
   */
  @Input() pillLabel: string = '';

  private _menuService: NvMultipickerMenuService;

  constructor (private overlay: Overlay, private injector: Injector, private elementRef: ElementRef) {
    this._menuService = new NvMultipickerMenuService(this.overlay, this.injector);
  }

  static alphabetizeOptions(options : IPickerOption[], startCount, endCount) : IPickerOption[]{
    const optionsCopy = options.slice();
    const startingEntries = optionsCopy.splice(0, startCount);
    const endingEntries = optionsCopy.splice(optionsCopy.length - endCount);
    //Now, alphabetize what remains by human
    optionsCopy.sort( (a : IPickerOption, b : IPickerOption) => {
      return a.human?.toLowerCase().localeCompare(b.human?.toLowerCase());
    });
    return startingEntries.concat(optionsCopy, endingEntries);
  }

  ngOnInit (): void {
    if(this.alphabetize) {
      this.options = NvMultiPickerComponent.alphabetizeOptions(
        this.options, this.startCount, this.endCount);
    }
  }

  /**
   *
   * What number does the `All but X` stop at
   */
  private reverseCount = 5;

  pulse = false;

  // TODO - turn selectedOptions array to Object (for performance)
  get parsedPillLabel (): string {
    if (this.pillLabel) {
      return this.pillLabel;
    }
    if (!this.selectedOptions || this.selectedOptions.length === 0) {
      return this.placeholder;
    } else if (this.selectedOptions.length === this.options.length) {
      return 'All';
    } else if (this.selectedOptions.length === 1) {
      const selectedOption = this.selectedOptions[0];
      return `${this.findOptionByKey(selectedOption).human}`;
    } else {
      // when SEVERAL, a string works, not an array of objects.
      return `${this.selectedOptions.length} selected`;
    }
  }

  get placeholderSelected (): boolean {
    return (
      this.selectedOptions.length === 0 ||
      (this.selectedOptions.length === 1 && this.selectedOptions[0] === this.placeholder)
    );
  }

  get _selectedOptionsObject (): object {
    const _obj = {};
    this.selectedOptions.forEach((opt: string) => {
      _obj[opt] = true;
    });
    return _obj;
  }

  ngOnDestroy (): void {
    if (this._menuService) {
      this._menuService.closeMenu();
    }
  }

  updateSelectedOptionsArray (value: string) {
    this.pulsePill();

    const optionClickedEvent: IOptionClickedEvent = {
      selected: !this.isKeySelected(value),
      value,
    };

    switch (value) {
      case 'all':
        this.options.forEach(opt => {
          if (!this.isKeySelected(opt.key)) {
            this.selectedOptions = [...this.selectedOptions, opt.key];
          }
        });
        break;
      case 'clear':
        this.selectedOptions = []; // empty the array
        break;
      default:
        if (this.isKeySelected(value)) {
          const removeIdx = this.indexOfKey(value);
          this.selectedOptions = [
            ...this.selectedOptions.slice(0, removeIdx),
            ...this.selectedOptions.slice(removeIdx + 1),
          ];
        } else {
          this.selectedOptions = [...this.selectedOptions, value];
        }
        break;
    }

    this.selectedOptionsChange.emit([...this.selectedOptions]);
    this.optionClicked.emit(optionClickedEvent);
  }

  private findOptionByKey (key: string) {
    return this.options.find(opt => opt.key === key);
  }

  private indexOfKey (key: string) {
    return this.selectedOptions.indexOf(key);
  }

  private isKeySelected (key: string): boolean {
    return this.selectedOptions.includes(key);
  }

  private pulsePill () {
    this.pulse = true;
    setTimeout(() => {
      this.pulse = false;
    }, 100);
  }

  toggleMenu () {
    if (!this.isDisabled) {
      // Create a new menu instance each time it's opened
      // This is more performant when multiple pickers are on page
      // It also allows us to more easily change the options inside the picker
      this._menuService.toggleMenu(this.elementRef.nativeElement, {
        options: this.options,
        hasAllButton: this.hasAllButton,
        hasClearButton: this.hasClearButton,
        hasSearchBox: this.hasSearchBox,
        initialSelection: this._selectedOptionsObject, // pass in separate from the obeservable
        selectedOption$: new Subject<string[]>(),
      });

      // Subscribe to changes emitted from the menu
      if (this._menuService.isOpen) {
        this._menuService.menuRef.updateSelection$.subscribe(optionKey => {
          this.updateSelectedOptionsArray(optionKey); // update the internal value
          const optionsObject = this._selectedOptionsObject;
          this._menuService.menuRef.setSelection(optionsObject); // send that value back down to the menu
        });
      }
    }
  }
}
