import { getCurrentUser } from 'Src/ng2/store/selectors/current-user-selectors';
import { Auth } from 'Src/ng2/shared/auth/auth.service';
import { LoadCurrentUser } from './../../../store/actions/current-user-actions';
import { getCurrentUserLoadedStatus } from './../../../store/selectors/current-user-selectors';
import { tap, filter, switchMap, take, catchError, mapTo } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable()
export class CurrentUserGuard implements CanActivate, CanLoad {
  constructor (private store: Store<any>, private auth: Auth, private router: Router) {}

  getFromStoreOrAPI (): Observable<any> {
    return this.store.pipe(
      select(getCurrentUserLoadedStatus),
      tap(async loaded => {
        if (!loaded) {
          const email = await this.auth.getCurrentUserEmail();
          this.store.dispatch(new LoadCurrentUser({ email }));
        }
      }),
      filter(loaded => loaded),
      switchMap(() => this.store.pipe(select(getCurrentUser))),
      take(1),
    );
  }

  canActivate (): Observable<boolean> {
    return this.getFromStoreOrAPI().pipe(
      mapTo(true),
      catchError(() => of(false)),
    );
  }

  canActivateChild (): Observable<boolean> {
    return this.canActivate();
  }

  canLoad (): Observable<boolean | UrlTree> {
    const isAuthenticated = this.auth.isAuthenticated();
    if (!isAuthenticated) return of(this.router.createUrlTree(['/login']));
    return this.canActivate();
  }
}
