const STAR_CHARTER_FSM_ENG_ELA = {
    starReadingLexileFall: {
        tag: 'Star Reading',
        tags: ['Star Reading', 'Star', 'screeners', 'assessments'],
        humanName: 'Star Reading Lexile (Fall)',
    },
    starReadingDateFall: {
        tag: 'Star Reading',
        tags: ['Star Reading', 'Star', 'screeners', 'assessments'],
        humanName: 'Star Reading Date (Fall)',
    },
    starReadingTierFall: {
        tag: 'Star Reading',
        tags: ['Star Reading', 'Star', 'screeners', 'assessments'],
        humanName: 'Star Reading Tier (Fall)',
    },
    starReadingLexileMostRecent: {
        tag: 'Star Reading',
        tags: ['Star Reading', 'Star', 'screeners', 'assessments'],
        humanName: 'Star Reading Lexile (Most Recent)',
    },
    starReadingDateMostRecent: {
        tag: 'Star Reading',
        tags: ['Star Reading', 'Star', 'screeners', 'assessments'],
        humanName: 'Star Reading Date (Most Recent)',
    },
    starReadingTierMostRecent: {
        tag: 'Star Reading',
        tags: ['Star Reading', 'Star', 'screeners', 'assessments'],
        humanName: 'Star Reading Tier (Most Recent)',
    },
    starReadingLexileWinter: {
        tag: 'Star Reading',
        tags: ['Star Reading', 'Star', 'screeners', 'assessments'],
        humanName: 'Star Reading Lexile (Winter)',
    },
    starReadingDateWinter: {
        tag: 'Star Reading',
        tags: ['Star Reading', 'Star', 'screeners', 'assessments'],
        humanName: 'Star Reading Date (Winter)',
    },
    starReadingTierWinter: {
        tag: 'Star Reading',
        tags: ['Star Reading', 'Star', 'screeners', 'assessments'],
        humanName: 'Star Reading Tier (Winter)',
    },
    starReadingLexileSpring: {
      tag: 'Star Reading',
      tags: ['Star Reading', 'Star', 'screeners', 'assessments'],
      humanName: 'Star Reading Lexile (Spring)',
    },
    starReadingDateSpring: {
      tag: 'Star Reading',
      tags: ['Star Reading', 'Star', 'screeners', 'assessments'],
      humanName: 'Star Reading Date (Spring)',
    },
    starReadingTierSpring: {
      tag: 'Star Reading',
      tags: ['Star Reading', 'Star', 'screeners', 'assessments'],
      humanName: 'Star Reading Tier (Spring)',
    },
    starReadingLexileSummer: {
      tag: 'Star Reading',
      tags: ['Star Reading', 'Star', 'screeners', 'assessments'],
      humanName: 'Star Reading Lexile (Summer)',
    },
    starReadingDateSummer: {
      tag: 'Star Reading',
      tags: ['Star Reading', 'Star', 'screeners', 'assessments'],
      humanName: 'Star Reading Date (Summer)',
    },
    starReadingTierSummer: {
      tag: 'Star Reading',
      tags: ['Star Reading', 'Star', 'screeners', 'assessments'],
      humanName: 'Star Reading Tier (Summer)',
    },
};

const STAR_CHARTER_FSM_SPAN_ELA = {
    starSpanishReadingLexileFall: {
        tag: 'Star Reading',
        tags: ['Star Reading', 'Star', 'screeners', 'assessments'],
        humanName: 'Star Spanish Reading Lexile (Fall)',
    },
    starSpanishReadingDateFall:  {
        tag: 'Star Reading',
        tags: ['Star Reading', 'Star', 'screeners', 'assessments'],
        humanName: 'Star Spanish Reading Date (Fall)',
    },
    starSpanishReadingLexileMostRecent: {
        tag: 'Star Reading',
        tags: ['Star Reading', 'Star', 'screeners', 'assessments'],
        humanName: 'Star Spanish Reading Lexile (Most Recent)',
    },
    starSpanishReadingDateMostRecent:  {
        tag: 'Star Reading',
        tags: ['Star Reading', 'Star', 'screeners', 'assessments'],
        humanName: 'Star Spanish Reading Date (Most Recent)',
    },
    starSpanishReadingLexileWinter: {
        tag: 'Star Reading',
        tags: ['Star Reading', 'Star', 'screeners', 'assessments'],
        humanName: 'Star Spanish Reading Lexile (Winter)',
    },
    starSpanishReadingDateWinter:  {
        tag: 'Star Reading',
        tags: ['Star Reading', 'Star', 'screeners', 'assessments'],
        humanName: 'Star Spanish Reading Date (Winter)',
    },
    starSpanishReadingLexileSpring: {
        tag: 'Star Reading',
        tags: ['Star Reading', 'Star', 'screeners', 'assessments'],
        humanName: 'Star Spanish Reading Lexile (Spring)',
    },
    starSpanishReadingDateSpring:  {
        tag: 'Star Reading',
        tags: ['Star Reading', 'Star', 'screeners', 'assessments'],
        humanName: 'Star Spanish Reading Date (Spring)',
    },
    starSpanishReadingLexileSummer: {
      tag: 'Star Reading',
      tags: ['Star Reading', 'Star', 'screeners', 'assessments'],
      humanName: 'Star Spanish Reading Lexile (Summer)',
    },
    starSpanishReadingDateSummer: {
      tag: 'Star Reading',
      tags: ['Star Reading', 'Star', 'screeners', 'assessments'],
      humanName: 'Star Spanish Reading Date (Summer)',
    },
};

const STAR_CHARTER_FSM_MATH = {
    starMathScaledScoreFall: {
        tag: 'Star Math',
        tags: ['Star Math', 'Star', 'screeners', 'assessments'],
        humanName: 'Star Math Scaled Score (Fall)',
    },
    starMathDateFall: {
        tag: 'Star Math',
        tags: ['Star Math', 'Star', 'screeners', 'assessments'],
        humanName: 'Star Math Date (Fall)',
    },
    starFallToWinterSgp: {
        tag: 'Star Math',
        tags: ['Star Math', 'Star', 'screeners', 'assessments'],
        humanName: 'Star Fall to Winter SGP',
    },
    starMathScaledScoreSpring: {
        tag: 'Star Math',
        tags: ['Star Math', 'Star', 'screeners', 'assessments'],
        humanName: 'Star Math Scaled Score (Spring)',
    },
    starMathDateSpring: {
        tag: 'Star Math',
        tags: ['Star Math', 'Star', 'screeners', 'assessments'],
        humanName: 'Star Math Date (Spring)',
    },
    starFallToSpringSgp: {
        tag: 'Star Math',
        tags: ['Star Math', 'Star', 'screeners', 'assessments'],
        humanName: 'Star Fall to Spring SGP',
    },
    starMathScaledScoreSummer: {
      tag: 'Star Math',
      tags: ['Star Math', 'Star', 'screeners', 'assessments'],
      humanName: 'Star Math Scaled Score (Summer)',
    },
    starMathDateSummer: {
      tag: 'Star Math',
      tags: ['Star Math', 'Star', 'screeners', 'assessments'],
      humanName: 'Star Math Date (Summer)',
    },
};

const STAR_CHARTER_FSM_SPANISH_MATH = {
    starSpanishMathScaledScoreMostRecent: {
        tag: 'Star Math',
        tags: ['Star Math', 'Star', 'screeners', 'assessments'],
        humanName: 'Star Spanish Math Scaled Score (Most Recent)',
    },
    starSpanishMathDateMostRecent:  {
        tag: 'Star Math',
        tags: ['Star Math', 'Star', 'screeners', 'assessments'],
        humanName: 'Star Spanish Math Date (Most Recent)',
    },
};

export const STAR_CHARTER_FLATTENED_STUDENT_MAP = {
    ...STAR_CHARTER_FSM_ENG_ELA,
    ...STAR_CHARTER_FSM_SPAN_ELA,
    ...STAR_CHARTER_FSM_MATH,
    ...STAR_CHARTER_FSM_SPANISH_MATH,
};