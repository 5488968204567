import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule } from '@angular/forms';

// modules
import { WizardFilterActionsModule } from 'Src/ng2/school/sdc/wizard-filter-actions/wizard-filter-actions.module';

// components
import { CreditGapsGradPlanTable } from './credit-gaps-grad-plan-table.component';

@NgModule({
  imports: [CommonModule, WizardFilterActionsModule, MatButtonModule, MatRadioModule, FormsModule],
  exports: [CreditGapsGradPlanTable],
  declarations: [CreditGapsGradPlanTable],
  providers: [],
})
export class CreditGapsGradPlanTableModule { }
