import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, UrlTree, RouterStateSnapshot } from '@angular/router';
import { Observable, from, of } from 'rxjs';
import { LocalStorageService } from '../../../shared/services/web-storage/local-storage/local-storage.service';
import { switchMap } from 'rxjs/operators';
import { Auth } from '../../../shared/auth/auth.service';
import { UrlPathService } from 'Src/ng2/shared/services/url-path-service/url-path.service';
import { SessionStorageService } from 'Src/ng2/shared/services/web-storage/session-storage/session-storage.service';

@Injectable()
export class CurrentDistrictGuard implements CanActivate {

  constructor (
    private auth: Auth,
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    private router: Router,
    private urlPathService: UrlPathService,
  ) {}

  canActivate (routeSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UrlTree | boolean> {
    const authenticated = this.auth.isAuthenticated();
    if (!authenticated) {
      this.setLastKnownUrl(state.url); // if not authenticated, set last known url and redirect to login
      return of(this.router.createUrlTree(['/login']));
    }
    const { params: { district: districtUrlDisplayName } } = routeSnapshot;
    return this.redirectToDistrictOrNotFound(districtUrlDisplayName);
  }

  private redirectToDistrictOrNotFound (districtUrlDisplayName: string) : Observable<UrlTree | boolean> {
    return from(this.auth.getUserDistricts()).pipe(
      switchMap(districts => {
        const districtId = this.urlPathService.getDistrictIdFromDistrictUrlDisplayName(districtUrlDisplayName, districts);
        if (districtId) {
          this.sessionStorageService.setItem('currentDistrict', districtId);
          return of(true);
        }
        return of(this.router.createUrlTree(['/not-found']));
      }),
    );
  }

  private setLastKnownUrl (url: string) {
    this.localStorageService.setItem('lastKnownUrl', url);
  }
}
