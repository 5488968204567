import { LogOutModule } from './../../../../shell/log-out/log-out.module';
import { RouteActivationService } from './../../../../routing/services/route-activation.service';
import { OtherToolsDataService } from './../other-tools-data/other-tools-data.service';
import { NvSidebarListModule } from 'Src/nvps-libraries/design/nv-sidebar-list/nv-sidebar-list.module';
import { NvIconButtonModule } from 'Src/nvps-libraries/design/nv-icon-button/nv-icon-button.module';
import { OtherToolsLeftNavComponent } from './other-tools-left-nav.component';
import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [OtherToolsLeftNavComponent],
  imports: [CommonModule, RouterModule, NvIconButtonModule, NvSidebarListModule, LogOutModule],
  exports: [OtherToolsLeftNavComponent],
  providers: [OtherToolsDataService, RouteActivationService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class OtherToolsLeftNavModule {}
