<base-modal 
  class="base-modal behavioral-incident-detail" 
  [itemCount]="itemCount"
  [itemType]="itemType"
  [isProfileMode]="isProfileMode"
  [title]="title"
>

  <div class="incident-section">
    <div class="incident-heading">Incident ID</div>
    <span class="incident-detail">{{ incidentId }}</span>
  </div>

  <div class="incident-section">
    <div class="incident-heading">Incident Date</div>
    <span class="incident-detail">{{ incidentDate }}</span>
  </div>

  <div class="incident-section">
    <div class="incident-row">
      <div class="column">
        <div class="incident-heading">Incident Created By</div>
        <span class="incident-detail">{{ incidentCreatedBy }}</span>
      </div>
      <div class="incident-column">
        <div class="incident-heading">Student Role in Incident</div>
        <span class="incident-detail">{{ incidentStudentRole }}</span>
      </div>
    </div>
    <div class="incident-row">
      <div class="column">
        <div class="incident-heading">Consequence Assigned</div>
        <span class="incident-detail">{{ incidentConsequence }}</span>
      </div>
      <div class="incident-column">
        <div class="incident-heading">Consequence Action</div>
        <span class="incident-detail">{{ incidentConsequenceAction }}</span>
      </div>
    </div>
  </div>

  <div class="incident-section">
    <div class="incident-heading">Consequence Dates</div>
    <span class="incident-detail">{{ incidentConsequenceDates }}</span>
  </div>

  <div class="incident-section last-row">
    <div class="incident-heading">Incident Description</div>
    <span class="incident-detail">{{ incidentDescription }}</span>
  </div>

</base-modal>
