import { EventFormatterService } from './../../shared/services/mixpanel/event-formatter.service';
import { BulkCreateStudentSupports, IBulkCreateStudentSupports } from './../actions/student-supports-actions';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import * as supportsActions from '../actions/supports-actions';
import { ApiService } from './../../shared/services/api-service/api-service';
import { ISchoolSupport } from './../../shared/typings/interfaces/support.interface';

@Injectable()
export class SupportsEffects {
  constructor (
    private actions$: Actions,
    private apiService: ApiService,
    private store: Store<any>,
    private eventFormatterService: EventFormatterService,
  ) {}

  createSupport$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(supportsActions.CREATE_SUPPORT),
      switchMap(
        (action: {
          type: string;
          payload: supportsActions.ICreateSupportPayload
        }) => {
          const { schoolId, supportParams, requiresMadlibRefresh, studentIds = [], view } = action.payload;
          const mixpanelEvent = this.eventFormatterService.getCreateSupportEvent({
            view,
            portal: 'SCHOOL',
            categories: supportParams.categories.map(({ category }) => category),
          });
          return this.apiService.createSupport(schoolId, supportParams, mixpanelEvent).pipe(
            switchMap((support: ISchoolSupport) => {
              // Reassign students from a duplicated support
              if (studentIds.length) {
                const { schedule: { startsOn, endsOn } } = support;
                const assignPayload: IBulkCreateStudentSupports = { support, startsOn, endsOn, studentIds };
                this.store.dispatch(new BulkCreateStudentSupports(assignPayload));
              }
              const payload = { support, requiresMadlibRefresh };
              return [new supportsActions.CreateSupportSuccess(payload)];
            }),
            catchError(error => {
              return of(new supportsActions.CreateSupportFail(error));
            }),
          );
        },
      ),
    );
  });

  updateSupport$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(supportsActions.UPDATE_SUPPORT),
      switchMap((action: { type: string; payload: { supportId; patch: any; requiresMadlibRefresh: boolean } }) => {
        const { supportId, patch, requiresMadlibRefresh } = action.payload;
        return this.apiService.updateSupport(supportId, patch).pipe(
          switchMap((support: any) => {
            const payload = { support, patch, requiresMadlibRefresh };
            return [new supportsActions.UpdateSupportSuccess(payload)];
          }),
          catchError(error => {
            return of(new supportsActions.UpdateSupportFail(error));
          }),
        );
      }),
    );
  });

  loadSupports$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(supportsActions.LOAD_SUPPORTS),
      switchMap((action: { type: string; payload: { schoolId: string } }) => {
        const { schoolId } = action.payload;
        return this.apiService.getSupports(schoolId).pipe(
          switchMap((payload: { data: ISchoolSupport[] }) => {
            return [new supportsActions.LoadSupportsSuccess(payload)];
          }),
          catchError(error => {
            return of(new supportsActions.LoadSupportsFail(error));
          }),
        );
      }),
    );
  });
}
