import { Injectable } from '@angular/core';
import { IColumn, IRowData } from '../../models/list-models';
import { CellDisplayService } from '../list-services/cell-display.service';

@Injectable()
export class RollupGroupingFooterService {
  generateFooterRow(
    dataByColumnKey: { [key: string]: number[] },
    colKeyFooterMapping: { [key: string]: { footerLabel: string; isFooterSetter: string; columnDataType: string } },
  ): Array<{ columnKey: string; data: string | number }> {
    const footerBase = [{ columnKey: 'STUB', data: null }];

    if (!dataByColumnKey || !colKeyFooterMapping) return footerBase;

    return Object.entries(dataByColumnKey).reduce((accum, [columnKey, allColValues]) => {
      const { footerLabel, isFooterSetter, columnDataType } = colKeyFooterMapping[columnKey];
      // set stub column [furthers left with no proper column header]
      if (isFooterSetter) accum[0].data = footerLabel;
      if (isFooterSetter && columnDataType === 'Percent')
        accum.push({ columnKey, data: this.getWeightedAverage(columnKey, dataByColumnKey) });
      else accum.push({ columnKey, data: this.total(allColValues) });
      return accum;
    }, footerBase);
  }

  getWeightedAverage(columnKey: string, dataByColumnKey): number {
    const data = dataByColumnKey[columnKey].reduce(
      (data: { primaryColData: number; total: number }, rowValForCol: number, i: number) => {
        data.primaryColData += (rowValForCol / 100) * dataByColumnKey.TOTAL[i];
        data.total += dataByColumnKey.TOTAL[i];
        return data;
      },
      { primaryColData: 0, total: 0 },
    );
    return data.total === 0 ? 0 : Number(((data.primaryColData / data.total) * 100).toFixed(2));
  }

  getDataByColumnKey(
    rowData: Array<Array<{ data: string | number; meta?: any; columnKey: string }>> = [],
  ): { [key: string]: number[] } {
    return rowData.reduce((accum, [, ...cols]) => {
      cols.forEach(({ columnKey, data }) => {
        const currentColValues = accum[columnKey] || [];
        accum[columnKey] = [...currentColValues, data];
      });
      return accum;
    }, {});
  }

  createColKeyFooterMapping(columnsData: Array<Partial<IColumn>> = []): { [key: string]: Partial<IColumn> } {
    return columnsData.reduce(
      (accum: { [key: string]: Partial<IColumn> }, { graphQlKey, footerLabel, isFooterSetter, columnDataType }) => {
        accum[graphQlKey] = { columnDataType, footerLabel, isFooterSetter };
        return accum;
      },
      {},
    );
  }

  total(columnCellValues: number[]): number {
    if (!columnCellValues || !columnCellValues.length) return 0;
    return columnCellValues.reduce((accum, data) => (accum += data), 0);
  }

  average(columnCellValues: number[]): number {
    if (!columnCellValues || !columnCellValues.length) return 0;
    return Number((this.total(columnCellValues) / columnCellValues.length).toFixed(2));
  }

  // format the display value of rollup table footer row
  // through CellDisplayService based on columnDataType
  getFormattedFooterRow ({ footerRow, columns, v4IsOn }: { footerRow: IRowData[]; columns: IColumn[]; v4IsOn?: boolean;}): IRowData[] {
    const dataTypeKey = CellDisplayService.getDataTypeKey('ROLLUP');
    return footerRow.map((rowCol, rowColIndx) => {
      const headerColIndx = CellDisplayService.getRelHeaderColIndx('ROLLUP', rowColIndx);
      const cellConfig = headerColIndx > -1 ? columns[headerColIndx].cellConfig : null;
      const colDataType = headerColIndx > -1 ? columns[headerColIndx][dataTypeKey] : '';
      const newRowCol = Object.assign({}, rowCol);
      newRowCol.style = CellDisplayService.addCellValueClass({ colVal: newRowCol.data, colDataType });
      newRowCol.backgroundStyle = CellDisplayService.addCellBackgroundClass({
        colVal: newRowCol.data,
        columnKey: rowCol.columnKey,
        cellConfig,
        data: newRowCol.meta && JSON.parse(newRowCol.meta),
        v4IsOn,
      });
      newRowCol.data = CellDisplayService.formatDisplayValue({ colVal: newRowCol.data, colDataType });
      return newRowCol;
    });
  }
}
