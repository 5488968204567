import { IPostsecondaryFuturePlan } from './../../../../typings/interfaces/student.interface';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseModalComponent } from '../../../base-modal.component';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { ExperienceModalService } from '../experience-modal-service/experience-modal-service';
import { map } from 'rxjs/operators';

@Component({
  templateUrl: 'future-plan-modal.component.html',
  styleUrls: ['../experiences-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class FuturePlanModalComponent extends BaseModalComponent implements OnInit {
  readonly title = 'Edit future plan';

  public formConfigKey$;
  public isProfileMode = true;
  public modalFocus = 'futurePlanModal';

  constructor (
    dialogRef: MatDialogRef<FuturePlanModalComponent>,
    @Inject(MAT_DIALOG_DATA) public editFuturePlan: IPostsecondaryFuturePlan,
    private experienceModalService: ExperienceModalService,
  ) {
    super(dialogRef);
  }

  ngOnInit () {
    this.formConfigKey$ = this.experienceModalService.getExperienceModalConfig$(this.modalFocus).pipe(
      map(payload => {
        const [formConfig] = payload;
        return formConfig.key;
      }),
    );
  }

  onCancel (): void {
    super.close();
  }

  onSave ($event) {
    super.close({
      postsecondaryFuturePlan: {
        ...$event,
      },
    });
  }
}
