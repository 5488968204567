import { IRowData } from '../../models/list-models';
import { Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'icon-with-text',
  templateUrl: './icon-with-text.component.html',
  styleUrls: ['./icon-with-text.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class IconWithTextComponent {
  @Input() input: {
    row: IRowData[];
    column: { data: { icon: string, data: any } }, // required shape
  };

  @Output() output = new EventEmitter();

  public icon: string;
  public data: any;

  ngOnInit (): void {
    this.icon = this.input.column?.data?.icon;
    this.data = this.input.column?.data?.data;
  }
}
