import { MaintenanceModeModule } from './../../../shared/components/maintenance-mode/maintenance-mode.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NetworkFullScreenLayoutComponent } from './network-full-screen-layout.component';

@NgModule({
  declarations: [NetworkFullScreenLayoutComponent],
  imports: [CommonModule, RouterModule, MaintenanceModeModule],
  exports: [NetworkFullScreenLayoutComponent],
  providers: [],
  schemas: [],
})
export class NetworkFullScreenLayoutModule {}
