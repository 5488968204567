import { select, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { getSchool } from 'Src/ng2/store/selectors/school-selectors';
import { catchError, mapTo, take, tap } from 'rxjs/operators';
import { PusherService } from 'Src/ng2/shared/services/pusher/pusher-service';
import { InitializePusher } from 'Src/ng2/store/actions/pusher-actions';
import { EventBus } from 'Src/ng2/shared/services/event-bus/event-bus';

@Injectable()
export class PusherGuard implements CanActivate {
  constructor(private store: Store<any>, private pusherService: PusherService, private eventBus: EventBus) {}

  canActivate(): Observable<boolean> {
    // the following code is pulled in from original school.route.ts channel resolver
    return this.store.pipe(
      select(getSchool),
      tap(currentSchool => {
        this.pusherService.ensurePusherToken();
        this.pusherService.ensureUnsubscribeFromPrevSchools(currentSchool._id);
        const channel = this.pusherService.getCurrentSchoolChannel(currentSchool._id);
        const action = new InitializePusher(channel);
        this.eventBus.emitAction(action);
      }),
      take(1),
      mapTo(true),
      catchError(() => of(false)),
    );
  }

  canActivateChild(): Observable<boolean> {
    return this.canActivate();
  }
}
