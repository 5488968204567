import { createFeatureSelector, createSelector } from '@ngrx/store';
import { map } from 'lodash';
import { IStudentSupport } from 'Src/ng2/shared/typings/interfaces/student-support.interface';
import { IStudentSupportsState } from './../reducers/student-supports-reducer';

const getPortalState = createFeatureSelector('PORTAL_STORE');

export const getStudentSupportsState = createSelector(
  getPortalState,
  (state: any) => {
    return state.studentSupports;
  },
);

export const getStudentSupportsEntities = createSelector(
  getStudentSupportsState,
  (state: IStudentSupportsState) => {
    return state.studentSupportsEntities;
  },
);

export const getStudentSupportsEntitiesList = createSelector(
  getStudentSupportsState,
  (state: IStudentSupportsState) => {
    const studentSupportsEntities = state.studentSupportsEntities;
    return map(studentSupportsEntities, (v, k) => v);
  },
);

export const getStudentSupportsForStudentId = (studentId: string) => createSelector(
  getStudentSupportsEntitiesList,
  (state: Array<IStudentSupport>) => {
    return state.filter(({ student: { studentId: ssStudentId } }) => ssStudentId === studentId);
  },
);

export const getStudentSupportsLoadingStatus = createSelector(
  getStudentSupportsState,
  (state: IStudentSupportsState) => {
    return state.loading;
  },
);

export const getStudentSupportsLoadedStatus = createSelector(
  getStudentSupportsState,
  (state: IStudentSupportsState) => {
    return state.loaded;
  },
);
