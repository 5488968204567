import { IFlag } from '../../../shared/typings/interfaces/flags.interface';
import { IStudentAssessment } from '../../../shared/typings/interfaces/student-assessment.interface';
import { IStudentSupport } from '../../../shared/typings/interfaces/student-support.interface';

export const ACADEMIC_PROJECTION = {
  _id: true,
  studentId: true,
  'studentDetails.name': true,
  'studentDetails.grade': true,
  'studentDetails.classOf': true,
  'studentDetails.officialClass': true,
  'gradPlanningDetails.plannedGraduationDate': true,
  'gradPlanningDetails.plannedDiplomaType': true,
  'spedDetails.isSped': true,
  'ellDetails.isEll': true,
  isMS: true,
  isHS: true,
  isES: true,
  pointPeople: true,
  otherStaff: true,
  schoolRiskGroupELA: true,
  schoolRiskGroupMath: true,
  'currProgram.grades': true,
  'currProgram.progress': true,
  'currProgram.progressHP': true,
  'currProgram.countBorderline': true,
  'currProgram.countFailing': true,
  'currProgram.borderline': true,
  'currProgram.failing': true,
  'currProgram.courses': true,
  'currProgram.countBorderlineHP': true,
  'currProgram.countFailingHP': true,
  'currProgram.borderlineHP': true,
  'currProgram.failingHP': true,
  isNewStudent: true,
  assmnts: true,
  'att.ytd': true,
  'att.riskGroup': true,
};

export interface IAcademicListStudent {
  _id: any;
  studentId: any;
  studentDetails: any;
  gradPlanningDetails: any;
  spedDetails: any;
  ellDetails: any;
  isMS: any;
  isHS: any;
  isES: any;
  pointPeople: any;
  otherStaff: any;
  schoolRiskGroupELA: any;
  schoolRiskGroupMath: any;
  currProgram: any;
  isNewStudent: any;
  assmnts: any;
  att: any;
  'att.ytd': any;
  'att.riskGroup': any;
  // merged onto student
  activeStudentSupports: IStudentSupport[];
  flags: IFlag[];
  studentAssessments?: IStudentAssessment[];
}

export const ACADEMIC_JOINS = [];
