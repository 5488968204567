import { BehaviorSubject } from 'rxjs';
import { RowGroupChildCellRenderer } from './../../../components/server-side-grid/row-group-child-cell-renderer/row-group-child-cell-renderer.component';
import { RowGroupCellRenderer } from './../../../components/server-side-grid/row-group-cell-renderer/row-group-cell-renderer.component';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { GridOptions, Module, GridApi } from '@ag-grid-community/core';
import { Component, Input, EventEmitter, Output, ChangeDetectionStrategy, ViewEncapsulation, OnChanges } from '@angular/core';
import { intersection } from 'lodash';

type CategoryMeta = { [key: string]: { total: number, selected: number, name: string, currentSelected: number, currentTotal: number  } };

const selectedRowColor = 'var(--color-blue-5)';
const groupRowHeight = 64;
const defaultChildRowHeight = 46;
const zeroHeight = 0;

const characterHeight = 24;
const headerNameCharactersPerLine = 22;
const headerTooltipCharactersPerLine = 55;

@Component({
  selector: 'edit-grid-columns-list',
  templateUrl: './edit-grid-columns-list.component.html',
  styleUrls: ['./edit-grid-columns-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditGridColumnsListComponent implements OnChanges {
  @Input() columns: any;
  @Input() categories: string[];
  @Input() tags: string[];
  @Input() searchString: string;
  @Output() selectColumn = new EventEmitter();
  @Output() setShowNullState = new EventEmitter();

  private gridApi: GridApi;
  private categoryMeta: CategoryMeta;
  private currentCategoryName: string;
  public stickyHeaderData: BehaviorSubject<{ name: string, currentSelected: number, currentTotal: number }>;
  private isFirstLoad: boolean;
  public listHasRows: BehaviorSubject<boolean>;;
  public sortedColumns: any;
  public rowCount = 0;

  private checkboxIsChecked: boolean;
  private checkboxIsIndeterminate: boolean;

  public modules: Module[] = [ClientSideRowModelModule, RowGroupingModule];

  // input
  public columnDefs = [
    {
      field: 'category',
      rowGroup: true,
      cellRenderer: 'rowGroupCellRenderer',
      hide: true,
      checkboxSelection: true,
      flex: 1,
      wrapText: true,
      cellClass: 'cell-wrap-text',
    },
    {
      headerName: '',
      field: 'headerTooltip',
      cellRenderer: 'rowGroupChildCellRenderer',
      cellRendererParams: {
        wrapText: true,
      },
      flex: 1,
      cellClass: 'cell-wrap-text',
    },
  ];

  public gridOptions: GridOptions;

  constructor (
  ) { }

  ngOnInit (): void {
    this.isFirstLoad = true;
    this.listHasRows = new BehaviorSubject(true);
    this.sortedColumns = this.columns.sort((a, b) => a.categoryOrder - b.categoryOrder);

    const [{ category: initialCategoryName }] = this.columns;
    this.categoryMeta = this.generateCategoryMeta(this.columns);
    this.setStickyHeader(this.categoryMeta[initialCategoryName]);
    this.setCheckboxStatus();


    this.gridOptions = {
      headerHeight: 0,
      rowBuffer: 0,
      groupSelectsChildren: true,
      rowSelection: 'multiple',
      rowMultiSelectWithClick: true,
      alwaysShowVerticalScroll: true, // causes text misalignment when there's no scrollbar if not set to true
      components: {
        rowGroupCellRenderer: RowGroupCellRenderer,
        rowGroupChildCellRenderer: RowGroupChildCellRenderer,
      },
      suppressContextMenu:true,
      groupDefaultExpanded: 1,
      getRowHeight: (params) => {
        const { node } = params;
        if (node.group) {
          // we want to hide the first group column (Basic Info)
          if (node.rowIndex === 0) return zeroHeight;
          else return groupRowHeight;
        }
        
        // we want to set the height according to whichever is larger, headerName or headerTooltip
        // they have different font sizes, so we will use whichever end up taking more space
        const { data: { headerName, headerTooltip } } = node;

        const headerNameLength = headerName ? headerName.length : 0;
        const headerNameHeight = Math.ceil(headerNameLength / headerNameCharactersPerLine) * characterHeight;

        const headerTooltipLength = headerTooltip ? headerTooltip.length : 0;
        const headerTooltipHeight = Math.ceil(headerTooltipLength / headerTooltipCharactersPerLine) * characterHeight;

        return Math.max(headerNameHeight, headerTooltipHeight, defaultChildRowHeight);
      },
      getRowStyle: params => {
        let background = 'white';
        if(!params.node.group) {
          const isHidden = params.node.data.hide;
          const selected = params.node.isSelected();
          // on the first load, the nodes aren't selected yet and cannot use isSelected
          // when it is the first load, we instead check the hide property 
          if(this.isFirstLoad && !isHidden) background = selectedRowColor;
          if(selected) background = selectedRowColor;
        }
        return { background };
      },
      autoGroupColumnDef: {
        headerName: '',
        hide: true,
        field: 'headerName',
        cellRendererParams: {
          checkbox: true,
          suppressCount: true,
          suppressEnterExpand: true,
          suppressDoubleClickExpand: true,
          categoryMeta: this.categoryMeta,
        },
        flex: 1,
        wrapText: true,
        cellClass: 'cell-wrap-text',
      },
      isExternalFilterPresent: () => true,
      doesExternalFilterPass: (node) => {
        // this filters according to tags, categories and search string
        // ag grid by default only filters by the cell value 
        const searchStrings = this.searchString.split(',').map((string) => string.trim().toUpperCase()).filter(string => !!string);
        if (!this.categories.length && !this.tags.length && !searchStrings.length) return true;
        const { data: { category, tags = [], headerTooltip, headerName } } = node;

        const matchCategory = this.categories.includes(category);
        const matchTags = intersection(tags, this.tags).length > 0;
        const matchString = searchStrings.some(filterTerm => {
          const headerNameMatch = headerName?.toUpperCase().includes(filterTerm);
          const headerTooltipMatch = headerTooltip?.toUpperCase().includes(filterTerm);
          const categoryMatch = category.toUpperCase().includes(filterTerm);
          return headerNameMatch || headerTooltipMatch || categoryMatch;
        });

        if(this.categories.length && this.tags.length && searchStrings.length) return matchCategory && matchTags && matchString;
        else if(this.categories.length && this.tags.length) return matchCategory && matchTags;
        else if(this.categories.length && this.searchString.length) return matchCategory && matchString;
        else if(this.tags.length && this.searchString.length) return matchTags && matchString;
        else if(this.categories.length) return matchCategory;
        else if(this.tags.length) return matchTags;
        else if(this.searchString.length) return matchString;
        else return false;
      },
    };
  }

  ngOnChanges (changes): void {
    // filter parameters come through here when we are applying filters with tags, categories or search
    // no need to run filter on first load
    if (changes?.columns?.firstChange){
      return;
    } 
    
    this.gridApi.onFilterChanged();
    this.regenerateCategoryMeta();

    const firstDisplayedRowIndex = this.gridApi.getFirstDisplayedRow();
    const firstDisplayedRowNode = this.gridApi.getDisplayedRowAtIndex(firstDisplayedRowIndex);
    if (!firstDisplayedRowNode) {
      this.stickyHeaderData.next(null);
      return;
    };

    // reset row height after filtering
    // there could be a new first grouping that should be hidden
    this.gridApi.resetRowHeights();

    const currentCategoryName = (firstDisplayedRowNode.groupData && firstDisplayedRowNode.groupData['ag-Grid-AutoColumn']) || firstDisplayedRowNode.data.category;
    this.setStickyHeader(this.categoryMeta[currentCategoryName]);
    this.setCheckboxStatus();
  }

  private setCheckboxStatus (): void {
    const { currentSelected, currentTotal } = this.categoryMeta[this.currentCategoryName];
    this.checkboxIsChecked = currentTotal === currentSelected;
    this.checkboxIsIndeterminate = currentSelected > 0 && currentSelected < currentTotal;
  }

  private setStickyHeader (categoryMeta): void {
    const { name, currentSelected, currentTotal } = categoryMeta;
    const data = { name, currentSelected, currentTotal };
    this.currentCategoryName = name;
    if (this.stickyHeaderData) {
      this.stickyHeaderData.next(data);
    } else {
      this.stickyHeaderData = new BehaviorSubject(data);
    }
  }

  private setStickyHeaderWithFirstCategory (): void {
    const firstDisplayedRowIndex = this.gridApi.getFirstDisplayedRow();
    const firstDisplayedRowNode = this.gridApi.getDisplayedRowAtIndex(firstDisplayedRowIndex);
    if (!firstDisplayedRowNode) return;

    const currentCategoryName = (firstDisplayedRowNode.groupData && firstDisplayedRowNode.groupData['ag-Grid-AutoColumn']) || firstDisplayedRowNode.data.category;

    this.setStickyHeader(this.categoryMeta[currentCategoryName]);
    this.setCheckboxStatus();
  }


  onGridReady ($event): void {
    this.gridApi = $event.api;
    this.isFirstLoad = false;
    this.gridApi.sizeColumnsToFit();

    this.columns.forEach((column, index) => {
      const node = this.gridApi.getRowNode(index.toString());
      if (!column.hide) {
        node.updateData({ ...node.data, defaultSelected: true });
        node.setSelected(true);
      };
    });

    this.gridOptions.onFirstDataRendered = () => {
      this.gridOptions.onViewportChanged = this.onViewPortChanged.bind(this);
      this.gridOptions.onRowSelected = this.onRowSelected.bind(this);
      this.gridOptions.onFilterChanged = this.onFilterChanged.bind(this);
    };
  }

  onViewPortChanged () {
    this.setStickyHeaderWithFirstCategory();
  }

  onRowSelected (params): void {
    const { node } = params;
    const isGroupRow = node.group;

    if (isGroupRow) return;

    const { category, defaultSelected } = node.data;
    if (defaultSelected) {
      node.updateData({ ...node.data, defaultSelected: false });
      return;
    }
    const selected = node.isSelected();
    const categoryState = this.categoryMeta[category];
    if (selected) {
      categoryState.selected++;
      categoryState.currentSelected++;
    } else {
      categoryState.selected--;
      // shouldn't go negative when we are clearing all
      if(categoryState.currentSelected > 0)categoryState.currentSelected--;
    }

    // update the sticky header if we are selecting rows in the same category
    if (category === this.currentCategoryName) {
      this.setStickyHeader(this.categoryMeta[category]);
      this.setCheckboxStatus();
    }
    // reset auto group column defs to update pill
    // redraw row to apply style change
    this.gridApi.setAutoGroupColumnDef({ ...this.gridOptions.autoGroupColumnDef });
    this.gridApi.redrawRows({ rowNodes: [node] });
    this.selectColumn.emit();
  }

  onFilterChanged() {
    let count = 0;
    this.gridApi.forEachNodeAfterFilter(() => count++);
    this.setShowNullState.emit(count);
    this.gridApi.setAutoGroupColumnDef({ ...this.gridOptions.autoGroupColumnDef });
  }

  private generateCategoryMeta(columns): CategoryMeta {
    return columns.reduce((acc, { category, hide }) => {
      if (!acc[category]) acc[category] = { name: category, total: 0, selected: 0, currentSelected: 0, currentTotal: 0 };
      acc[category].total++;
      acc[category].currentTotal++;
      if (!hide) {
        acc[category].selected++;
        acc[category].currentSelected++;
      }
      return acc;
    }, {});
  }

  private regenerateCategoryMeta (): void {
    for (const [_, value] of Object.entries(this.categoryMeta)) {
      value.currentSelected = 0;
      value.currentTotal = 0;
    }

    this.gridApi.forEachNodeAfterFilter((rowData) => {
      const { group, data } = rowData;
      if (group) return;
      const { category } = data;
      const selected = rowData.isSelected();
      this.categoryMeta[category].currentTotal++;
      if (selected) this.categoryMeta[category].currentSelected++;
    });

    this.gridOptions.autoGroupColumnDef.cellRendererParams.categoryMeta = this.categoryMeta;
  }

  public selectAllInCategory (event): void {
    const isChecked = event.checked;
    this.gridApi.forEachNodeAfterFilter((rowNode) => {
      const rowNodeCategory = rowNode.data?.category;
      const isGroupNode = rowNode.group;
      if (!isGroupNode && rowNodeCategory === this.currentCategoryName) {
        rowNode.setSelected(isChecked);
      }
    });
  }

  // ran by parent to get selected rows
  public getSelectedColumns (): any[] {
    return this.gridApi.getSelectedRows();
  }

  public uncheckAll () {
    this.gridApi.deselectAll();
  }
}
