
<div class="next-term-planning-content-body list-content-wrapper" #listContent >
  <page-header [pageHeaderMeta]="pageHeaderMeta" [showSearchBox]="false" *ngIf="options">
    Planned courses focused on <nv-dropdown [options]="options" [selected]="optionSelected" (selectOption)="onMadLibChange($event)"></nv-dropdown> grouped by department
  </page-header> 
  <list-container *ngIf="options && groupingData$.value"
    [groupingData$]="groupingData$"
    [sortKey$]="sortKey$"
    [sortDirection$]="sortDirection$"
    [filterFormControl]="filterFormControl"
    [batchActionsMode$]="batchActionsMode$"
    [columns]="columns"
    [columnIndexMap]="columnIndexMap"
    [listConfig]="listConfig"
    [dynamicComponentTrigger]="dynamicComponentTrigger"
    [dynamicComponentInputData]="dynamicComponentInputData"
    (clickedRow)="onRowClick($event)"
    (focusedGroup)="onFocusedGroup($event)"
  >
  </list-container>
</div>

<div class="next-term-planning-sidebar">
  <h4>TOOLS</h4>
  <div class="tool-option" (click)="generateCsv()">
      <nv-icon name="download-small-blue"></nv-icon>
      <label for="exportToCSV">Export to CSV</label> <span id="exportToCSV"></span>
  </div>
</div>