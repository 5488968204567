<base-modal class="base-modal edit-consent-status-modal" [title]="title" isProfileMode="false">
<!-- Forms -->
<ng-container [ngSwitch]="modalControl">
  <edit-consent-status
    *ngSwitchCase="'EDIT_CONSENT_STATUS'"
    [initialConsentStatus]="initialConsentStatus"
  ></edit-consent-status>
  <revoke-consent-status
    *ngSwitchCase="'REVOKE_CONSENT_STATUS'"
  ></revoke-consent-status>
  <request-consent
    *ngSwitchCase="'REQUEST_CONSENT'"
  ></request-consent>
  <decline-consent-status
    *ngSwitchCase="'DECLINE_CONSENT_STATUS'"
  ></decline-consent-status>
</ng-container>

<!-- Buttons -->
<mat-dialog-actions class="dialog-actions button-group">
  <nv-button type="secondary" (clickButton)="onCancel()">
    Cancel
  </nv-button>
  <nv-button htmlType="submit" (clickButton)="onSave()">
    {{ submitButtonText }}
  </nv-button>
</mat-dialog-actions>
</base-modal>