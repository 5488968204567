import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { ApiService } from 'Src/ng2/shared/services/api-service/api-service';
import * as rollupGroupingActions from '../../actions/rollup-grouping-actions/rollup-grouping-actions';
import { generateQuery } from '../projections/rollupGrouping';

@Injectable()
export class RollupGroupingEffects {
  constructor(private actions$: Actions, private apiService: ApiService) {}

  loadRollupGrouping$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<any>(rollupGroupingActions.LOAD_ROLLUP_GROUPING),
      switchMap(payload => {
        const query = generateQuery(payload);
        const queryPayload = {
          query,
          fetchPolicy: 'no-cache',
        };
        return this.apiService.getStudentsGraphQL(queryPayload).pipe(
          mergeMap(({ data: { RollupGrouping } }) => [
            new rollupGroupingActions.LoadRollupGroupingSuccess(RollupGrouping),
          ]),
          catchError(error => from([new rollupGroupingActions.LoadRollupGroupingFail(error)])),
        );
      }),
    );
  });
}
