import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';

import { TaskModalComponent } from './task-modal.component';
import { TaskDetailsComponent } from './task-details/task-details.component';
import { NvTextareaModule } from '../../../../nvps-libraries/design/nv-textarea/nv-textarea.module';
import { NvDesignLibraryModule } from '../../../../nvps-libraries/design/nv-design-library.module';
import { NvMatTooltipModule } from '../../components/nv-mat-tooltip/nv-mat-tooltip.module';
import { BaseModalModule } from '../base-modal.module';

@NgModule({
  declarations: [TaskModalComponent, TaskDetailsComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule,
    BaseModalModule,
    NvDesignLibraryModule,
    NvMatTooltipModule,
    NvTextareaModule,
  ],
  exports: [TaskModalComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TaskModalModule {}
