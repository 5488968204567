import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'college-now-status-pill',
  templateUrl: './college-now-status-pill.component.html',
  styleUrls: ['./college-now-status-pill.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CollegeNowStatusPillComponent implements OnInit {
  @Input() input: any;

  public status: string;

  ngOnInit (): void {
    this.status = this.input.column.data;
  }
}
