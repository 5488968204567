<div class="confirm-dialog">
  <div class="dialog-title">
    <ng-container *ngIf="v4ModeIsOn; else oldSkin">
      <span>{{ title }}</span>
      <nv-icon name="warning-large" *ngIf="hasWarningIcon" class="warning-icon"></nv-icon>
      <nv-icon-button name="close-large-blue" (click)="onCancel()" *ngIf="!hasWarningIcon"></nv-icon-button>
    </ng-container>
    <!--TODO: v4 cleanup: remove oldSkin-->
    <ng-template #oldSkin>
      <nv-icon name="warning-large" *ngIf="hasWarningIcon" class="warning-icon"></nv-icon>
      <span class="icon clickable close" (click)="onCancel()" *ngIf="!hasWarningIcon"></span> <span>{{ title }}</span>
    </ng-template>
  </div>
  <div *ngIf="subtitle" class="dialog-subtitle"><span>{{ subtitle}}</span></div>
  <div class="dialog-body">
    <mat-dialog-content *ngIf="noteText" class="noteText">
      <nv-note size="small" priority="info">{{ noteText }}</nv-note>
    </mat-dialog-content>
    <mat-dialog-content class="message-text"> <p [innerHTML]="message"></p> </mat-dialog-content>
    <mat-dialog-content *ngIf="subMessage">
      <p>{{ subMessage }}</p>
    </mat-dialog-content>
    <mat-dialog-content *ngIf="warningContent">
      <nv-note size="small" priority="warning"><span #warningContent></span></nv-note>
    </mat-dialog-content>
  </div>
  <div class="dialog-actions">
    <nv-button type="secondary" (click)="onCancel()">{{ cancelText }}</nv-button>
    <nv-button (click)="onConfirm()">{{ confirmText }}</nv-button>
  </div>
</div>
