import { OverlayRef } from '@angular/cdk/overlay';
import { Subject, Unsubscribable } from 'rxjs';
import { IMultipickerMenuProps } from '../nv-multi-picker.interface';

export class NvMultipickerMenuRef {
  private _updateSelection$ = new Subject<string>();
  public updateSelection$ = this._updateSelection$.asObservable();

  private _open: boolean = false;
  public isOpen (): boolean {
    return this._open;
  }

  constructor (public overlayRef: OverlayRef, public props: IMultipickerMenuProps) {
    this._open = true;
  }

  public initClickaway (): Unsubscribable {
    return this.overlayRef.backdropClick().subscribe(e => {
      this.closeMenu();
    });
  }

  public closeMenu () {
    this._open = false;
    this._updateSelection$.complete();
    this.overlayRef.dispose();
  }

  // Subscribed to by the parent menu
  // A stream of keys
  // updated as the user clicks new pills.
  // This is needed since the parent can't listen to an EventEmitter
  public emitUpdate (value: string) {
    this._updateSelection$.next(value); // add the new value to the observable
  }

  // Subscribed to by the child menu.
  // A stream of state arrays
  // updated by the parent in response to selection updates.
  // This is needed since the child can't receive props as @Input.
  // Triggering the change needs to be done manuallt
  public setSelection (selection: object) {
    this.props.selectedOption$.next(selection); // update the menu with a new selection array
  }
}
