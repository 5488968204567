import { Component, Input, EventEmitter, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'sdc-dropdown',
  templateUrl: './sdc-dropdown.component.html',
  styleUrls: ['./sdc-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SdcDropdown {
  @Input() selection: string;
  @Input() options: Array<string>;
  @Input() label: string;
  @Output() dropdownChange: EventEmitter<string|Array<string>> = new EventEmitter();

  onSelectionChange ({ value }): void{
    this.dropdownChange.emit(value);
  }
}
