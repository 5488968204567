
<div *ngIf="countOfUniqueActivityLeads(supportsByActivityLead)">
  <p class="mat-body-2">
    <span> {{countOfUniqueActivityLeads(supportsByActivityLead)}} staff </span>
    <span>
      
      <ng-container [ngPlural]="countOfUniqueActivityLeads(supportsByActivityLead)">
        <ng-template ngPluralCase="0"> member is </ng-template>
        <ng-template ngPluralCase="1"> member is </ng-template>
        <ng-template ngPluralCase="other"> members are </ng-template>
      </ng-container>
 
    </span>
    <span>assigned to lead Regents prep support activities.</span>
  </p>

  <p class="mat-body-2" *ngIf="activityLeadsWithoutAccess">
    <span> {{activityLeadsWithoutAccess}} staff </span>
    <span>
    
      <ng-container [ngPlural]="activityLeadsWithoutAccess">
        <ng-template ngPluralCase="0"> Regents prep leads </ng-template>
        <ng-template ngPluralCase="1"> Regents prep lead </ng-template>
        <ng-template ngPluralCase="other"> Regents prep lead </ng-template>
      </ng-container>

    </span>

    <span>
      cannot take attendance in the Portal because they have not been granted access. Your site administrator can
      update user settings for Portal access.
    </span>
  </p>

  <div *ngIf="countOfUniqueActivityLeads(supportsByActivatedLead) !== 0">
    <p class="mat-body-2">
      <span>Staff members can view their rosters and take attendance at </span>
      <span (click)="WizardUtils.goToStateInNewTab('lists/supports')">{{mySupportsUrl}}</span>
    </p>

    <div *ngIf="hasGafeEmail">
      <p class="mat-body-2">
        Clicking the button below will open a draft introductory email to activity leads in a new tab. Make
        modifications as needed. <b>Be sure to replace [ENTER DATE] with your actual staff training date.</b>
      </p>

      <button
        mat-raised-button
        class="step-action-button"
        (click)="openEmail(supportsByActivatedLead)"
      >
        <span *ngIf="activityLeadsWithoutAccess">
          INVITE {{countOfUniqueActivityLeads(supportsByActivatedLead)}} OF
          {{countOfUniqueActivityLeads(supportsByActivityLead)}} LEADS TO TAKE ATTENDANCE
        </span>
        <span *ngIf="!activityLeadsWithoutAccess"> INVITE LEADS TO TAKE ATTENDANCE </span>
      </button>
    </div>

    <div *ngIf="!hasGafeEmail">
      <p class="mat-body-2">
        Click the copy button below to add a draft introductory email for activity leads to your computer’s
        clipboard. Open your email application and paste the content in a new email window.
        <b>Be sure to replace [ENTER DATE] with your actual staff training date.</b>
      </p>

      <button
        class="mat-button mat-raised mat-primary"
        disabled="!countOfUniqueActivityLeads(supportsByActivatedLead)"
        (click)="copyToClipboard(supportsByActivatedLead)"
      >
        <span>COPY DRAFT INTRODUCTORY EMAIL</span>
      </button>
    </div>
  </div>
</div>

<p class="mat-body-2" *ngIf="!countOfUniqueActivityLeads(supportsByActivityLead)">
  There are no Regents support activities. Create and manage supports in
  <span id="school-supports-link" (click)="goToSupportSettings()">
    School Supports</span
  >.
</p>
