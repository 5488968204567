import { Injectable } from '@angular/core';
import { IDropdownOption } from '../../../../../projects/shared/nvps-libraries/design/interfaces/design-library.interface';

@Injectable()
export class PDFReportsService {
  constructor () {};

  public removeNonDefaultReports (reportOptions: Array<IDropdownOption>) : Array<IDropdownOption> {
    return reportOptions.filter((option) => {
      return option.default === true;
    });
  }
}
