import { EM_DASH } from '../../constants/em-dash.constant';
import { ISingleStudent } from './../../../student/student-data-service/single-student-data.interface';

export const modalOptions = [
  {
    human: 'Planned Diploma Type',
    formattedData: ({ PLANNED_DIPLOMA_TYPE }: ISingleStudent) => PLANNED_DIPLOMA_TYPE || EM_DASH,
    editable: (isRestricted) => !isRestricted,
  },
  {
    human: 'Planned Graduation Date',
    formattedData: ({ PLANNED_GRAD_DATE }: ISingleStudent) => PLANNED_GRAD_DATE || EM_DASH,
    editable: (isRestricted) => !isRestricted,
  },
];
