import { createFeatureSelector, createSelector } from '@ngrx/store';
import { map } from 'lodash';
import { IToolsState } from './../reducers/tools-reducer';

const getPortalState = createFeatureSelector('PORTAL_STORE');

export const getToolsState = createSelector(
  getPortalState,
  (state: any) => {
    return state.tools;
  },
);

export const getToolsLoadedStatus = createSelector(
  getToolsState,
  (state: IToolsState) => {
    return state.loaded;
  },
);

export const getToolsLoadingStatus = createSelector(
  getToolsState,
  (state: IToolsState) => {
    return state.loading;
  },
);

export const getToolsEntities = createSelector(
  getToolsState,
  (state: IToolsState) => {
    return state.toolsEntities;
  },
);

export const getToolsEntitiesList = createSelector(
  getToolsState,
  (state: IToolsState) => {
    const toolEntities = state.toolsEntities;
    return map(toolEntities, tool => tool);
  },
);
