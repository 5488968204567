import { Injectable } from '@angular/core';
import { find } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SubjectAreas } from 'Src/ng2/shared/constants/subject-areas.constant';
import { COLUMN_DATA_TYPE } from '../../../../constants/list-view/cell-type.constant';
import { IListViewData } from '../../../../typings/interfaces/list-view.interface';

@Injectable()
export class HasOrNeedsSupportForCourseGrouping {
  public getGrouping(filteredStudents$: Observable<any>, options): Observable<any> {
    return filteredStudents$.pipe(
      map(students => {
        const formattedList: IListViewData = this.getFormattedListShell(options);
        const args = { students, formattedList, ...options };
        const grouping = this.generateGrouping(args);
        return grouping;
      }),
    );
  }

  // args - students, columns, subject, formattedList
  private getFormattedListShell(args): IListViewData {
    const { columns, listType } = args;
    return {
      listType,
      columns: this.formatColumnsForList(columns),
      sections: [
        { name: 'Has support', count: 0, data: [], defaultDisplayCount: 10 },
        { name: 'Needs support', count: 0, data: [], defaultDisplayCount: 10 },
      ],
    };
  }

  private formatColumnsForList(columns) {
    const formattedColumns = columns.reduce((formattedColumns, col) => {
      if (col.cellType !== COLUMN_DATA_TYPE.SECTION_HEADER) {
        formattedColumns[col.key] = {
          name: col.human,
          cellType: col.cellType,
          cellConfig: col.cellConfig,
          dataType: col.dataType,
          orderBy: col.orderBy,
        };
      }
      return formattedColumns;
    }, {});
    return formattedColumns;
  }

  private generateGrouping(args) {
    const { students, columns, subject, course, formattedList } = args;
    const subjectHumanShort = SubjectAreas[subject].humanShort;

    return students.reduce((formattedList, student) => {
      const {
        activeStudentSupports,
        currProgram: { grades },
      } = student;
      const academicStudentSupports = activeStudentSupports.filter(
        ({ support }) => support.metaData && support.category === 'ACADEMIC',
      );
      // we want to create a row for each course, not each student
      // a student can be in a grouping twice with the same code but different sections
      grades.forEach(({ code, pf, mostRecent }) => {
        if (code === course && pf === 'F' && mostRecent) {
          const studentRow = columns.reduce((result, { key, getValueAtPath }) => {
            result[key] = getValueAtPath(student, course);
            return result;
          }, {});

          const hasSupportForSubject = find(academicStudentSupports, ({ support: { metaData } }) => {
            return metaData.subject === subjectHumanShort;
          });

          if (hasSupportForSubject) {
            formattedList.sections[0].data.push(studentRow);
            formattedList.sections[0].count++;
          } else {
            formattedList.sections[1].data.push(studentRow);
            formattedList.sections[1].count++;
          }
        }
      });
      return formattedList;
    }, formattedList);
  }
}
