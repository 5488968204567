import { ActivatedRoute } from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { get, includes, orderBy } from 'lodash';

@Injectable()
export class MadLibSorting {
  constructor (private activatedRoute: ActivatedRoute) {
    //
  }

  sortSections (sections, sortState, sortData, isInitialLoad) {
    const { dataType, key, sortOrder } = sortData;
    const columnSortData = { key, dataType, sortOrder };
    const sort = this.getSortOrderForColumnKey(columnSortData, sortState, isInitialLoad); // isInitialLoad
    switch (dataType) {
      case 'Number':
        return this.sortNumbers(sections, columnSortData, sort);
      case 'String':
        return this.sortStrings(sections, columnSortData, sort);
      case 'Array':
        return this.sortArrays(sections, columnSortData, sort);
      case 'Date':
        return this.sortDates(sections, columnSortData, sort);
      case 'Object':
        return this.sortObjects(sections, columnSortData, sort);
      default:
        return sections;
    }
  }

  updateSortState (sortedColumn, sortOrder, dataType) {
    return { sortedColumn, sortOrder, dataType };
  }

  // Arrays
  sortArrays (sections, sortData, sortOrder) {
    return sortOrder === 'asc'
      ? this.sortArraysAscending(sections, sortData)
      : this.sortArraysDescending(sections, sortData);
  }

  sortObjects (sections, sortData, sortOrder) {
    return sortOrder === 'asc'
      ? this.sortObjectKeys(sections, sortData, 'desc')
      : this.sortObjectKeys(sections, sortData, 'asc');
  }

  sortObjectKeys (sections, sortData, order) {
    const { key } = sortData;
    const ascending = sections.map(section => {
      const sortedSection = orderBy(
        section.data,
        (row: any) => {
          const { data } = row[key];
          if (data && typeof data === 'object' && data.calculatedStatus) {
            return data.calculatedStatus;
          }
        },
        order,
      );
      return { ...section, ...{ data: sortedSection } };
    });
    return ascending;
  }

  sortArraysAscending (sections, sortData) {
    const { key } = sortData;
    const ascending = sections.map(section => {
      const sortedSection = orderBy(
        section.data,
        (row: any) => {
          const { data } = row[key];
          // lists with users as cell values have objects as data (JE)
          if (data && typeof data === 'object' && data.user) return data.user.firstName;
          return data.length;
        },
        'asc',
      );
      return { ...section, ...{ data: sortedSection } };
    });
    return ascending;
  }

  sortArraysDescending (sections, sortData) {
    const { key } = sortData;
    const descending = sections.map(section => {
      const sortedSection = orderBy(
        section.data,
        (row: any) => {
          const { data } = row[key];
          // lists with users as cell values have objects as data (JE)
          if (data && typeof data === 'object' && data.user) return data.user.firstName;
          return data.length;
        },
        'desc',
      );
      return { ...section, ...{ data: sortedSection } };
    });
    return descending;
  }

  // Numbers
  sortNumbers (sections, sortData, sortOrder) {
    return sortOrder === 'asc'
      ? this.sortNumbersAscending(sections, sortData)
      : this.sortNumbersDescending(sections, sortData);
  }

  sortNumbersAscending (sections, sortData) {
    const { key } = sortData;
    const ascending = sections.map(section => {
      const sortedSection = orderBy(
        section.data,
        (row: any) => {
          const {
            dependencies: { filterValue },
            data,
          } = get(row, key);
          if (data && data.latestScore) {
            return data.latestScore;
          } else if (typeof filterValue === 'number') {
            return filterValue;
          } else if (data === '-' || data === '--' || data === null) {
            return -1;
          } else {
            return data;
          }
        },
        'asc',
      );
      return { ...section, ...{ data: sortedSection } };
    });
    return ascending;
  }

  sortNumbersDescending (sections, sortData) {
    const { key } = sortData;
    const descending = sections.map(section => {
      const sortedSection = orderBy(
        section.data,
        (row: any) => {
          const {
            dependencies: { filterValue },
            data,
          } = get(row, key);
          if (data && data.latestScore) {
            return data.latestScore;
          } else if (typeof filterValue === 'number') {
            return filterValue;
          } else if (data === '-' || data === '--' || data === null) {
            return -1;
          } else {
            return data;
          }
        },
        'desc',
      );
      return { ...section, ...{ data: sortedSection } };
    });
    return descending;
  }

  // Strings
  sortStrings (sections, sortData, sortOrder) {
    return sortOrder === 'asc'
      ? this.sortStringsAscending(sections, sortData)
      : this.sortStringsDescending(sections, sortData);
  }

  sortStringsAscending (sections, sortData) {
    const { key } = sortData;
    const ascending = sections.map(section => {
      const sortedSection = orderBy(
        section.data,
        (row: any) => {
          const {
            dependencies: { filterValue },
            data,
          } = get(row, key);
          if (data && data.latestRelLvl) {
            return data.latestRelLvl;
          } else {
            return filterValue || data;
          }
        },
        'asc',
      );
      return { ...section, ...{ data: sortedSection } };
    });
    return ascending;
  }

  sortStringsDescending (sections, sortData) {
    const { key } = sortData;
    const descending = sections.map(section => {
      const sortedSection = orderBy(
        section.data,
        (row: any) => {
          const {
            dependencies: { filterValue },
            data,
          } = get(row, key);
          if (data && data.latestRelLvl) {
            return data.latestRelLvl;
          } else {
            return filterValue || data;
          }
        },
        'desc',
      );
      return { ...section, ...{ data: sortedSection } };
    });
    return descending;
  }

  // Dates
  sortDates (sections, sortData, sortOrder) {
    return sortOrder === 'asc'
      ? this.sortDatesAscending(sections, sortData)
      : this.sortDatesDescending(sections, sortData);
  }

  sortDatesAscending (sections, sortData) {
    const { key } = sortData;
    const ascending = sections.map(section => {
      const sortedSection = orderBy(
        section.data,
        (row: any) => {
          const {
            dependencies: { filterValue },
            data,
          } = get(row, key);
          return filterValue || data;
        },
        'asc',
      );
      return { ...section, ...{ data: sortedSection } };
    });
    return ascending;
  }

  sortDatesDescending (sections, sortData) {
    const { key } = sortData;
    const descending = sections.map(section => {
      const sortedSection = orderBy(
        section.data,
        (row: any) => {
          const {
            dependencies: { filterValue },
            data,
          } = get(row, key);
          return filterValue || data;
        },
        'desc',
      );
      return { ...section, ...{ data: sortedSection } };
    });
    return descending;
  }

  getSortOrderForColumnKey (sortData, sortState, isInitialLoad) {
    let sortOrder;
    const { key, dataType, sortOrder: sort } = sortData;

    if (isInitialLoad) return (sortOrder = sort);

    // toggle-effect when resorting the same column consecutively
    const columnIsCurrentlySorted = sortState.sortedColumn === key;
    if (columnIsCurrentlySorted) return sortState.sortOrder;
    // otherwise sort is the default for dataType
    switch (dataType) {
      case 'String':
        return (sortOrder = 'asc');
      case 'Array':
        return (sortOrder = 'desc');
      case 'Number':
        return (sortOrder = 'desc');
      case 'Date':
        return (sortOrder = 'desc');
      case 'Object':
        return (sortOrder = 'desc');
      default:
        return (sortOrder = 'asc');
    }
  }

  getSort (newSort, newDimension, validSortableColumns) {
    let runType;
    const defaultSort = {
      sortKey: 'studentName',
      orderBy: 'asc',
      dataType: 'String',
    };

    const isInitialLoad = !newSort && !newDimension;
    const isDimensionChange = !newSort && newDimension;
    const isSortChange = newSort && !newDimension;

    if (isInitialLoad) runType = 'INITIAL_LOAD';
    if (isDimensionChange) runType = 'DIMENSION_CHANGE';
    if (isSortChange) runType = 'SORT_CHANGE';

    switch (runType) {
      case 'INITIAL_LOAD':
        const urlValues = this.activatedRoute.snapshot.queryParams;
        const urlContainsSortedColumn = urlValues.SortedColumn;
        const urlSortedColumnIsValid = includes(validSortableColumns, urlValues.SortedColumn);

        if (urlContainsSortedColumn && urlSortedColumnIsValid) {
          const { SortedColumn: sortKey, SortBy: orderBy, Type: dataType } = urlValues;
          return { sortKey, orderBy, dataType };
        } else {
          return defaultSort;
        }
      case 'DIMENSION_CHANGE':
        return defaultSort;
      case 'SORT_CHANGE':
        return {
          sortKey: newSort.key,
          orderBy: newSort.orderBy,
          dataType: newSort.dataType,
        };
      default:
        return defaultSort;
    }
  }
}
