import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import * as _ from 'lodash';
import { CreditRequirements } from 'Src/ng2/shared/constants/credit-requirements.constant';

@Component({
  selector: 'credit-gaps-subject-table',
  templateUrl: './credit-gaps-subject-table.component.html',
  styleUrls: ['./styles/credit-gaps-subject-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class CreditGapsWizardSubjectTable implements OnInit {
  @Input() currentFilter: string;
  @Input() filteredStudents: any;

  public creditReqs;
  public orderedCreditReqs;
  public columnKeys: string[];
  public table;
  public total;
  public studentFieldForTableRows: string;
  public uiReady = false;
  public filterOptions: { columnKeys: string[], sort: { colId: string; direction: string }} = {
    columnKeys: ['gradPlan', 'creditMaxGaps', 'creditMaxGapsNoPlan'],
    sort: {
      colId: 'gradPlan',
      direction: 'desc',
    },
  };

  public tableRows: { unaddressedGaps: object; addressedGaps: object; noGaps: object } = {
    unaddressedGaps: {},
    addressedGaps: {},
    noGaps: {},
  };

  constructor () {}

  ngOnInit () {
    this.setUi();
  }

  ngOnChanges ({ currentFilter, filteredStudents }): void {
    if (!currentFilter.firstChange || !filteredStudents.firstChange) {
      this.setUi();
    }
  }

  orderTableRows = (a, b) => a.key;

  private setUi () {
    // creates table shell
    this.orderedCreditReqs = this.getOrderedCreditRequirements();
    _.each(this.orderedCreditReqs, (requirement: any) => {
      this.tableRows.unaddressedGaps[requirement.camelCase] = { students: [] };
      this.tableRows.addressedGaps[requirement.camelCase] = { students: [] };
      this.tableRows.noGaps[requirement.camelCase] = { students: [] };
    });
    this.table = this.populateTable(this.orderedCreditReqs);
  }

  private populateTable (requirements): any {
    return _.reduce(
      this.filteredStudents,
      (acc: any, student: any) => {
        const { studentId } = student;
        let colKey;
        let creditGapsStartValue;
        let creditGapsValue;

        // for each student, check gap status for each requirement
        _.each(requirements, (req: any) => {
          colKey = req.camelCase;
          // find two columns on each student that map to the current credit requirement
          const creditReq = _.upperFirst(colKey).toString();
          creditGapsStartValue = student[`creditGapsStart${creditReq}`];
          creditGapsValue = student[`creditGaps${creditReq}`];

          const studentHasUnaddressedGaps = creditGapsValue < 0;
          const studentHasAddressedGaps = creditGapsValue >= 0 && creditGapsStartValue < 0;
          const studentHasNoGaps = creditGapsStartValue >= 0;

          if (studentHasUnaddressedGaps) {
            acc.unaddressedGaps[colKey].students.push(studentId);
          } else if (studentHasAddressedGaps) {
            acc.addressedGaps[colKey].students.push(studentId);
          } else if (studentHasNoGaps) {
            acc.noGaps[colKey].students.push(studentId);
          }
        });
        return acc;
      },
      this.tableRows,
    );
  }

  private getOrderedCreditRequirements () {
    return _.sortBy(this.filteredReqs, requirement => {
      return requirement.orders.creditGapsWizard;
    });
  }

  get filteredReqs () {
    const filter = _.filter(CreditRequirements, (req: any) => {
      if (req.orders.creditGapsWizard) return req;
    });
    return filter;
  }
}
