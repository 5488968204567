import { Injectable } from '@angular/core';
import { ApiService } from './../services/api-service/api-service';
import { PartnerTypes } from '../typings/interfaces/partner.interface';
import { EMPTY, Observable, of } from 'rxjs';
import { TTooltipElType, ITooltipContent } from 'projects/shared/nvps-libraries/design/nv-tooltip/nv-shared-tooltip.interface';
import { TNvTooltipData } from './nv-tooltip.interface';
import { IRowData } from '../models/list-models';
import { IconWithTextComponent } from './../components/icon-with-text/icon-with-text.component';

@Injectable()
export class NvTooltipDataService {
  constructor (private apiService: ApiService) {}

  getFormattedRowData (rowData: ITooltipContent['rowData']): ITooltipContent['rowData'] {
    return rowData.map((row: any) => {
      return row.map((col: IRowData) => {
        // col could be a string or a valid IRowData object
        if (col?.columnKey) {
          col.dynamic = this.getDynamicComponent(col.columnKey);
        }
        return col;
      });
    });
  }

  getAsyncTooltip (payload) {
    const { schoolId, contextPartnerId, docId, calc, caresId, tooltipFilter = {}, meta, contextPartnerType = PartnerTypes.SCHOOL } = payload;
    const metaObject = meta ? JSON.parse(meta) : null;
    const includeMeta = !!(meta && Object.keys(metaObject).length);

    // const validatedSchoolId = schoolId ? `schoolId: "${schoolId}", ` : '';
    let validatedPartnerId;
    if (contextPartnerType === PartnerTypes.SHELTER) {
      validatedPartnerId = contextPartnerId ? `"${contextPartnerId}"` : 'null';
    } else if (schoolId || contextPartnerType === PartnerTypes.SCHOOL) {
      validatedPartnerId = schoolId ? `"${schoolId}"` : contextPartnerId ? `"${contextPartnerId}"` : 'null';
    }
    const validatedCaresId = caresId ? `caresId: "${caresId}", ` : '';
    const tooltipFilterStr = this.cleanTooltipFilter(tooltipFilter);

    const formattedCalcKey = meta && includeMeta ? `${calc}=${metaObject.currentLevel},${metaObject.levelId},${metaObject._id}` : calc;
    let validatedDocId;

    if (this.canChangeDocId(calc)) {
      validatedDocId = schoolId; // we need the schoolId as the docId for these tooltips
    } else {
      validatedDocId = docId && docId.docId ? docId.docId : docId;
    }

    const query = `{
      Tooltip(
        contextPartnerType: "${contextPartnerType}",
        contextPartnerId: ${validatedPartnerId},
        id: "${validatedDocId}",
        calc: "${formattedCalcKey}",
        tooltipFilter: "${tooltipFilterStr}"
        ${validatedCaresId}
      ) {
          content {
            ...on SimpleTooltip {
              simpleContent
            }
            ... on TableTooltip{
              tableContent{
                template
                rowData
                headers
                typeOfTable
                options {
                  preserveTwoColumnLayout
                }
              }
            }
          }
        }
      }`;

    const queryPayload = {
      query,
      fetchPolicy: 'no-cache',
    };

    return this.apiService.getStudentsGraphQL(queryPayload);
  }

  getTooltipContent (tooltipData: TNvTooltipData): Observable<any> {
    let caresId;
    // guard against mistaken misuse of this directive
    if (!tooltipData) return EMPTY;
    // add a check to support old lists.
    if (typeof tooltipData === 'string') return of({ data: { Tooltip: { content: { simpleContent: tooltipData } } } });

    const { simpleContent, tableContent, schoolId, contextPartnerId, docId, calc, wildcard, connectedElType, meta, contextPartnerType } = tooltipData;
    if (simpleContent) {
      return of({ data: { Tooltip: { content: { simpleContent } } } });
    }

    if (tableContent) {
      return of({ data: { Tooltip: { content: { tableContent } } } });
    }

    // check against docId before parsing it - jchu
    if (!docId) return EMPTY;

    const { tooltipFilter } = JSON.parse(docId);
    const tooltipBaseDocId = this.getTooltipDocId(docId, connectedElType);

    let payload = { contextPartnerType, contextPartnerId, schoolId, docId: tooltipBaseDocId, tooltipFilter, calc, meta };

    const isShelter = contextPartnerType === PartnerTypes.SHELTER;
    if (isShelter && contextPartnerId) {
      caresId = tooltipData.caresId; // This is the case for shelter profile success mentoring panel
      if (!caresId) caresId = this.getCaresId(docId); // This is the case for shelter list and grid
      payload = Object.assign(payload, { caresId });
    }
    if (wildcard) payload = Object.assign(payload, { wildcard });

    return this.getAsyncTooltip(payload);
  }

  private cleanTooltipFilter (tooltipFilter: any): string {
    const tooltipFilterStr = JSON.stringify(tooltipFilter);
    const cleanedStr = tooltipFilterStr.replace(/"/g, '\\"');
    return cleanedStr;
  }

  private canChangeDocId (calc) {
    let isTooltip;
    switch (calc) {
      case 'REGENTS_PREP_CELL_TOOLTIP':
      case 'COURSE_PERIOD_CELL_TOOLTIP':
        isTooltip = true;
        break;
      default:
        isTooltip = false;
        break;
    }
    return isTooltip;
  }

  // re-calced tooltipBaseDocId (will be used to filter mongo doc through _id in the backend)
  // 1. for any student list(fixed table list, infinite table list), docId passed as a stringified obj wrapped inside .meta
  // 2. for student profile panel (e.g. basic info panel), docId is directly passed
  private getTooltipDocId (docId, connectedElType: TTooltipElType = 'FIXED_OR_INFINITE_LIST_CELL') {
    let tooltipBaseDocId;
    switch (connectedElType) {
      case 'PANEL_FIELD':
        tooltipBaseDocId = docId;
        break;
      case 'FIXED_OR_INFINITE_LIST_CELL':
      default:
        tooltipBaseDocId = JSON.parse(docId).data;
        break;
    }
    return tooltipBaseDocId;
  }

  private getCaresId (docId: string): string {
    return JSON.parse(docId).caresId;
  }

  private getDynamicComponent (columnKey: string): IRowData['dynamic'] {
    let component: IRowData['dynamic'];
    switch (columnKey) {
      case 'LATEST_MP_MARK':
        component = IconWithTextComponent;
        break;
      default:
        break;
    }
    return component;
  }
}
