import { ITool } from 'Src/ng2/shared/typings/interfaces/tool.interface';
import { Injectable } from '@angular/core';
import { ImUser } from 'Src/ng2/shared/services/im-models/im-user';

@Injectable()
export class ImTool {
  constructor (private imUser: ImUser) {
    // empty block
  }

  getHumanName (tool: ITool): string {
    let displayName;
    const isSyncSheet = tool.toolType === 'SYNCED_SHEET';
    if (isSyncSheet) {
      displayName = this.getSyncedSheetDisplayName(tool);
    } else {
      displayName = this.getSheetDisplayName(tool);
    }
    return displayName;
  }

  getSheetDisplayName (tool: ITool): string {
    let { displayName } = tool;
    const { cohort } = tool.metaData;
    // Tools created via the App Script/Spreadsheet Utility have "null" instead of null
    // TODO: fix Apps Script utility, migrate data
    if (cohort && cohort !== 'null') {
      displayName += ` - ${cohort}`;
    }
    return displayName;
  }

  getSyncedSheetDisplayName (tool: ITool): string {
    return tool.metaData.spreadsheetName;
  }

  getSheetUrl (tool: ITool): string {
    return `https://docs.google.com/spreadsheets/d/${tool.metaData.spreadsheetId}`;
  }

  // This func is initially designed for sync tool
  // yet, could be easily modified to fit other needs down the road (JChu)
  getGafeEmail (tool: ITool): string {
    return tool.createdBy ? tool.createdBy.gafeEmail : null;
  }

  getCreatedOn (tool: ITool): string {
    const { toolType } = tool;
    return toolType === 'SYNCED_SHEET' ? tool.lastModified : tool.createdAt;
  }

  getCreatedByEmail (tool: ITool): string {
    return tool.createdBy ? this.imUser.getEffectiveEmail(tool.createdBy) : null;
  }
}
