import { WizardHelpers } from './../../../services/wizard-helpers.service';
import { SdcDropdownModule } from './../../../sdc-dropdown/sdc-dropdown.module';
import { RegentsPrepTransferWizard } from './regents-prep-transfer-wizard.component';
import { RegentsPrepWizardRoutingModule } from '../regents-prep-wizard-container-routing.module';
import { PrintRegentsStepModule } from '../shared-steps/print-regents-step/print-regents-step.module';
import { RegentsScheduledTableModule } from '../shared-tables/regents-scheduled-table/regents-scheduled-table.module';
import { RegentsExamSupportsTableModule } from '../shared-tables/regents-exam-supports-table/regents-exam-supports-table.module';
import { SchedulingCheckStepModule } from '../shared-steps/scheduling-check/scheduling-check.module';
import { PrepReviewStepModule } from '../shared-steps/prep-review-step/prep-review-step.module';
import { MonitorAttendanceStepModule } from '../shared-steps/monitor-attendance-step/monitor-attendance-step.module';
import { FinalPrepCheckStepModule } from '../shared-steps/final-check-prep-step/final-prep-check-step.module';
import { CompleteGradPlanningStepModule } from '../shared-steps/complete-grad-planning-step/complete-grad-planning-step.module';
import { AssignSupportsStepModule } from '../shared-steps/assign-supports-step/assign-supports-step.module';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatStepperModule } from '@angular/material/stepper';
import { TrackAttendanceStepModule } from '../shared-steps/track-attendance-step/track-attendance-step.module';
import { TransferStudentReviewStepModule } from './student-review-step/student-review-step.module';
import { InstructionStepModule } from '../../../shared/components/wizard-steps/instruction-step/instruction-step.module';

@NgModule({
  declarations: [RegentsPrepTransferWizard],
  providers: [WizardHelpers],
  exports: [RegentsPrepTransferWizard],
  imports: [
    MatStepperModule,
    AssignSupportsStepModule,
    CompleteGradPlanningStepModule,
    FinalPrepCheckStepModule,
    InstructionStepModule,
    MonitorAttendanceStepModule,
    PrepReviewStepModule,
    SchedulingCheckStepModule,
    TransferStudentReviewStepModule,
    TrackAttendanceStepModule,
    RegentsExamSupportsTableModule,
    RegentsScheduledTableModule,
    PrintRegentsStepModule,
    CommonModule,
    RegentsPrepWizardRoutingModule,
    SdcDropdownModule,
    MatButtonModule,
  ],
})
export class RegentsPrepTransferWizardModule { }
