
<p class="mat-body-2">
  <wizard-filter-actions
    [data]="collegeReadinessAlgStudentData['CC Algebra'].studentIds"
    [options]="options"
  ></wizard-filter-actions>

  <ng-container [ngPlural]="collegeReadinessAlgStudentData['CC Algebra'].studentIds">
    <ng-template ngPluralCase="0"> students are</ng-template>
    <ng-template ngPluralCase="1"> student is</ng-template>
    <ng-template ngPluralCase="few"> students are</ng-template>
    <ng-template ngPluralCase="other"> students are</ng-template>
  </ng-container>

  have passed a Math Regents exam with a score of 65+ but have not yet achieved a College Ready score (70+ on CC
  Alg/Geom, 65+ on CC Alg II, 80+ on an older exam) in Math, and <b>are not</b> planned to resit for CC Alg in the
  next administration.
</p>

<p class="mat-body-2">
  Note: For students using non-CC exams for College Readiness, this recommendation accounts only for the exam
  component of college readiness, not credit requirements.
</p>

<p class="mat-body-2">Note: This action will plan students for the CC Alg exam.</p>

<button
  mat-raised-button
  class="step-action-button"
  [disabled]="!totalStudentsWithReadinessMismatch"
  (click)="patchStudents(patches, totalStudentsWithReadinessMismatch)"
>
  Plan {{totalStudentsWithReadinessMismatch}}
  
  <ng-container [ngPlural]="totalStudentsWithReadinessMismatch">
    <ng-template ngPluralCase="0"> students </ng-template>
    <ng-template ngPluralCase="1"> student </ng-template>
    <ng-template ngPluralCase="few"> students </ng-template>
    <ng-template ngPluralCase="other"> students </ng-template>
  </ng-container>
  
</button>

