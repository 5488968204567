import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { SortAndFilterService } from './../../../shared/services/list-services/sort-and-filter.service';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, of } from 'rxjs';
import { IGroupData, IListConfig } from 'Src/ng2/shared/models/list-models';
import { STUDENT_SEL_SUPPORTS_PANEL_COLUMNS } from './student-sel-supports-panel-list-data/student-sel-supports-panel-list-constants';
import { CurrentSchoolYear } from './../../../shared/constants/current-school-year.constant';
import { StudentSelSupportsPanelListDataService } from './student-sel-supports-panel-list-data/student-sel-supports-panel-list-data.service';
import { IStudentSelSupport } from 'Src/ng2/shared/typings/interfaces/student-sel-support.interface';

@Component({
  selector: 'student-sel-supports-panel-list',
  templateUrl: './student-sel-supports-panel-list.component.html',
  styleUrls: ['./student-sel-supports-panel-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StudentSelSupportsContainer {
  @Input() studentSelSupports: any;
  @Input() showHistoricalData?: boolean;

  // Outputs to panel
  @Output() updateRows: EventEmitter<number> = new EventEmitter();

  // list container props
  public groupingData$ = new BehaviorSubject(null);
  public sortKey$;
  public sortDirection$;
  public filterFormControl;
  public batchActionsMode$;
  public columns;
  public columnIndexMap: { [columnKey: string]: number };
  public listConfig: IListConfig;
  public dynamicComponentTrigger;
  public dynamicComponentInputData;

  constructor(
    public selSupportsDataService: StudentSelSupportsPanelListDataService,
  ) {}

  ngOnInit() : void {
    this.sortKey$ = new BehaviorSubject(null);
    this.sortDirection$ = new BehaviorSubject(null);
    this.batchActionsMode$ = of(false);
    this.filterFormControl = new FormControl();
    this.columns = STUDENT_SEL_SUPPORTS_PANEL_COLUMNS;

    this.columnIndexMap = this.columns.reduce((mapping, col: any, i: number) => {
      mapping[col.graphQlKey] = i;
      return mapping;
    }, {});

    this.listConfig = {
      emptyTableMessage: `No SEL supports assigned for SY${CurrentSchoolYear.START}-${CurrentSchoolYear.END}`,
      listType: 'STUDENT_SEL_SUPPORTS_PROFILE_PANEL',
      noDataMessage: `No SEL supports assigned for SY${CurrentSchoolYear.START}-${CurrentSchoolYear.END}`,
      sortableColumns: true,
      maximumVisibleRowsPerGroup: this.studentSelSupports.length,
      allowEmptyTable: true,
    };

    this.updateGroupingData(this.showHistoricalData);
  }

  onUpdateSort(sortKey: string) {
    SortAndFilterService.updateSortCol(sortKey, this.sortKey$, this.sortDirection$);
  }
  
  updateGroupingData(showHistoricalData?: boolean): void {
    const groupData = showHistoricalData
      ? this.formatHistoricalSupports(this.studentSelSupports)
      : this.formatCurrentSchoolYearSupports(this.studentSelSupports, CurrentSchoolYear.WITH_SY_PREFIX);
    this.groupingData$.next(groupData);
    this.emitRowsToParent(this.groupingData$.value[0].rowData.length);
  }

  formatHistoricalSupports(supports: IStudentSelSupport[]): IGroupData[] {
    const supportsBySchoolYear = this.selSupportsDataService.groupSupportData(supports);
    const groupData = this.selSupportsDataService.formatHistoricalGroupData(supportsBySchoolYear);
    return groupData;
  }

  formatCurrentSchoolYearSupports(supports: IStudentSelSupport[], currSY: string): IGroupData[] {
    const currentSupports = this.selSupportsDataService.filterForCurrentSchoolYearSupports(supports, currSY);
    const groupData = this.selSupportsDataService.formatCurrentSchoolYearData(currentSupports);
    return groupData;
  }

  emitRowsToParent (rows: number): void {
    this.updateRows.emit(rows);
  }
}
