<base-modal class="base-modal student-support-modal-v2" [title]="title" [isProfileMode]="isProfileMode">
  <nv-form-field label="Support"> {{ studentSupport.support.name }} </nv-form-field>

  <nv-form-field label="Student start and end date">
    <nv-date-range-picker
      [dateRangeForm]="dateRangeForm"
      [startLimit]="startLimit"
      [endLimit]="endLimit"
      [startPlaceholder]="startPlaceholder"
      [endPlaceholder]="endPlaceHolder"
      [isInverted]="v4ModeIsOn ? true : false"
    ></nv-date-range-picker>
  </nv-form-field>

  <div class="button-wrapper">
    <nv-button (click)="onCancel()" type="secondary">Cancel</nv-button>
    <nv-button (click)="updateStudentSupportDates()" [disabled]="!isFormDirty">Update</nv-button>
  </div>
</base-modal>
