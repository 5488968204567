<p class="mat-body-2 explainer">
    Of the {{ filteredStudents.length }}
    <span *ngIf="!selectedGradDate">active students in your school:</span>
    <span *ngIf="selectedGradDate">active planned {{ selectedGradDate }} graduates:</span>
</p>

<ul class="mat-body-2">
    <li>
        <wizard-filter-actions [data]="studentsByCreditGaps.unaddressedGaps.studentIds" [options]="options">
        </wizard-filter-actions>

        <ng-container [ngPlural]="studentsByCreditGaps.unaddressedGaps.studentIds.length">
            <ng-template ngPluralCase="0"> students have </ng-template>
            <ng-template ngPluralCase="1"> student has </ng-template>
            <ng-template ngPluralCase="other"> students have </ng-template>
        </ng-container>

        <b>unaddressed credit gaps</b> - meaning, students need a course change this term or a plan to address their
        gaps in a future term.
    </li>

    <li>
        <wizard-filter-actions [data]="studentsByCreditGaps.pendingGapsResolutions.studentIds" [options]="options">
        </wizard-filter-actions>

        <ng-container [ngPlural]="studentsByCreditGaps.pendingGapsResolutions.studentIds.length">
            <ng-template ngPluralCase="0"> students have </ng-template>
            <ng-template ngPluralCase="1"> student has </ng-template>
            <ng-template ngPluralCase="other"> students have </ng-template>
        </ng-container>
        credit gaps that are planned to be addressed by a pending course change or gap plan in this term and/or a future
        term.
    </li>

    <li>
        <wizard-filter-actions [data]="studentsByCreditGaps.noGaps.studentIds" [options]="options">
        </wizard-filter-actions>

        <ng-container [ngPlural]="studentsByCreditGaps.noGaps.studentIds.length">
            <ng-template ngPluralCase="0"> students have </ng-template>
            <ng-template ngPluralCase="1"> student has </ng-template>
            <ng-template ngPluralCase="other"> students have </ng-template>
        </ng-container>
        no credit gaps.
    </li>
</ul>

<p class="mat-body-2">
    Use the Credit Gaps panel in the Student Profile to indicate program changes that can be made this term to address
    credit gaps. Program changes can then be exported in Step 5 to upload into your source system. From the Student
    Profile, you can also indicate a plan for gaps to be addressed in future terms.
</p>

<!-- all students priority table -->
<div *ngIf="priorityGroups.length > 1">
    <p class="mat-body-2">
        The
        <wizard-filter-actions [data]="studentsByCreditGaps.unaddressedGaps.studentIds" [options]="options">
        </wizard-filter-actions>
        who have unaddressed gaps are prioritized as follows:
    </p>

    <table class="mat-body-2 priority-table">
        <tr *ngFor="let data of priorityGroups">
            <td><span class="description">Priority {{ data.priorityVal }}: </span> <span>{{ data.priorityLabel
                    }}</span></td>
            <td>
                <div>
                    <button class="step-action-button" mat-raised-button [disabled]="!data.studentIds.length"
                        (click)="navigateToStudents(data.studentIds)">
                        Review {{ data.studentIds.length}}
                    </button>
                </div>
            </td>
        </tr>
    </table>
</div>