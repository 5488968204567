/**
 * What does this component do ?
 * This component acts as the stateful container around the stateless student list tables
 * and allows for all of the table groupings to function as one.
 * It will also handles the job of implementing the sticky header logic(JJ)
 */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { BehaviorSubject, Unsubscribable, Observable } from 'rxjs';
import { debounceTime, delay, tap, startWith } from 'rxjs/operators';
import { IColumnStuLvl, IGroupData, IListConfig, IRowData } from '../../../models/list-models';
import { HeaderService, IDisplayedHeader } from '../../../services/list-services/header.service';
import { TSortDirections } from '../../../services/list-services/sort-and-filter.service';
import { FixedTableComponent, IBatchActionTableHeaderIsChecked } from '../fixed-table/fixed-table.component';
import { ListSummaryGroupings } from './../../../services/list-summary-groupings/list-summary-groupings.service';
import { TValidPartnerTypes } from 'Src/ng2/shared/typings/interfaces/partner.interface';
import { Toggles } from 'Src/ng2/shared/constants/toggles.constant';
import { ToggleService } from 'Src/ng2/shared/services/toggle/toggle.service';

interface IInitializeCheckBatchUpdateHeaders {
  setSelectedHeaders: (groupings: IGroupData[]) => void;
  updateCheckedTableHeader: ($event: IBatchActionTableHeaderIsChecked) => void;
  getCheckedTableHeaders: () => {};
  reset: () => void;
}

interface IGroupOrder {
  [key: string]: number;
}

/* istanbul ignore next */
@Component({
  selector: 'list-container',
  templateUrl: './list-container.component.html',
  styleUrls: ['./list-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { clickAction: 'noop' } }],
})
export class ListContainerComponent implements OnInit {
  // REQUIRED bindings if used along with FixedToInfiniteViewComponent
  @Input() groupingData$: BehaviorSubject<IGroupData[]>;

  @Input() sortKey$: BehaviorSubject<string>;
  @Input() sortDirection$: BehaviorSubject<TSortDirections>;

  @Input() schoolId: string;
  @Input() contextPartnerType: TValidPartnerTypes;
  @Input() contextPartnerId: string;
  @Input() madlibModel: any;
  @Input() columns: IColumnStuLvl[];
  @Input() columnIndexMap: { [key: string]: number };
  @Input() listConfig: IListConfig;
  @Input() dynamicComponentTrigger: null | boolean = null;
  @Input() filterFormControl: FormControl;
  @Input() batchActionsMode$: Observable<boolean>;
  @Output() focusedGroup = new EventEmitter<{
    groupData: IGroupData;
    sortKey: string;
    sortDirection: TSortDirections;
    groupIndx: number;
  }>();

  @Output() clickedRow = new EventEmitter<IRowData[]>();
  @Output() batchActionData = new EventEmitter<any>();
  @Output() uiRowDataUpdate = new EventEmitter<IGroupData[]>();
  @Output() sortEmitter = new EventEmitter<string>();
  // OPTIONAL bindings
  @Input() batchActionsSelectedIds$: Observable<string[]> = null;
  @Input() dynamicComponentInputData: any;
  @Input() showRowsSelectedOfTotal: boolean = false;
  @Input() showFirstColumnHeader: boolean;
  @Output() clickedDynamicComponent = new EventEmitter<any>();

  // available after view init
  @ViewChildren(FixedTableComponent, { read: ElementRef }) tableEls: QueryList<ElementRef>;
  @ViewChildren(FixedTableComponent) tables: QueryList<FixedTableComponent>;
  @ViewChild('listStickyHeader', { read: ElementRef, static: false }) listStickyHeader: ElementRef;
  @ViewChild('scrollWrapper', { static: false }) sectionContainer: ElementRef;
  @ViewChild('fixedHeader', { static: false }) fixedHeader: ElementRef;

  // methods
  getDisplayedHeaders: Function = HeaderService.getDisplayedHeaders;
  checkHeaderPos: Function = HeaderService.checkHeaderPos;
  getNewDisplayedHeaders: Function = HeaderService.getNewDisplayedHeaders;
  getHeaderOverflow: Function = HeaderService.getHeaderOverflow;
  checkShadow: Function = HeaderService.checkHeaderShadow;
  haveHeadersChanged: Function = HeaderService.haveHeadersChanged;

  // all other props
  groupings: IGroupData[];
  isBatchActionActivated: boolean;
  fixedHeaderBoundary: number;
  displayedHeaders: IDisplayedHeader[];
  maximumVisibleRowsPerGroup: number;
  displayGroupFooter: boolean;
  showShadow: boolean;
  currentFilterFormValue: string;
  uiRowDataModel;
  batchActionSub: Unsubscribable;
  nonEmptyTables: Array<{
    indx: number;
    human: string;
    total: number;
  }>;

  stickyHeaderIsChecked$: BehaviorSubject<boolean>;
  stickyHeaderIsInd$: BehaviorSubject<boolean>;
  checkedBatchUpdateHeaders: IInitializeCheckBatchUpdateHeaders;
  headerOverflow: boolean = false;
  groupings$: Observable<any>;
  filterControl$: Observable<any>;
  private prevHeaderPos: number = 0;
  sortableColumns: boolean = true;
  summarySub: Unsubscribable;
  headerSub: Unsubscribable;
  noDataMessage: string;
  numRowsSelectedOfTotal: number;
  isSummerSchoolToggleOn: boolean;

  constructor (private listSummaryGroupings: ListSummaryGroupings, private ref: ChangeDetectorRef, private toggleService: ToggleService) {}

  ngOnInit (): void {
    this.maximumVisibleRowsPerGroup = this.listConfig.maximumVisibleRowsPerGroup || 10;
    this.displayGroupFooter = this.listConfig.displayGroupFooter;
    this.sortableColumns = this.listConfig.sortableColumns;
    this.noDataMessage = this.listConfig.noDataMessage;
    this.currentFilterFormValue = this.filterFormControl.value || '';
    this.isBatchActionActivated = false;
    this._getGroupings$();

    this.checkedBatchUpdateHeaders = this.initializeCheckedBatchUpdateHeaders();

    // TODO: remove the need for this subscription
    this.batchActionSub = this.batchActionsMode$
      .pipe(
        tap((mode: boolean) => {
          this.isBatchActionActivated = mode;
          if (mode && this.groupings) this.checkedBatchUpdateHeaders.setSelectedHeaders(this.groupings);
          if (!mode) {
            this.checkedBatchUpdateHeaders.reset();
          }
        }),
      )
      .subscribe();

    this.summarySub = this.listSummaryGroupings.groupingNavEvent$
      .pipe(tap($event => this.scrollToSection($event.sectionIndex)))
      .subscribe();
  }

  public updateSort (sortKey: string): void {
    this.sortEmitter.emit(sortKey);
  }

  private _getGroupings$ (): void {
    this.groupings$ = this.groupingData$.pipe(
      tap(groupings => {
        if (groupings && groupings.length) {
          this.groupings = groupings;
          this.checkTableAndStickyHeader(groupings);
        } else {
          this.groupings = [];
          this.nonEmptyTables = [];
        }
        this.uiRowDataModel = this.createUiRowDataModel(this.groupings);
      }),
    );
  }

  ngAfterContentInit () {
    this.ref.detectChanges();
    // until groupings come back from the endpoint the nativeElement will not be available since it exists behind an ngIf
    if (this.fixedHeader) {
      const fixedHeaderEle = this.fixedHeader.nativeElement.getBoundingClientRect();
      this.fixedHeaderBoundary = fixedHeaderEle.top + fixedHeaderEle.height;
    }

    // Note: the subscription to tables.changes completes when the component is destroyed, so no need to unsubscribe manually
    this.tables.changes
      .pipe(
        debounceTime(150),
        delay(5),
        tap(() => {
          // makes sure no recursive calling on checkTableAndStickyHeader
          if (this.currentFilterFormValue) {
            this.checkTableAndStickyHeader(this.groupings);
          }
        }),
      )
      .subscribe();

    // set up an observable that will run every time the filter changes
    // template manages subscription to this.filterFormContorl.valueChanges
    this.filterControl$ = this.filterFormControl.valueChanges.pipe(
      debounceTime(150),
      delay(5),
      startWith(this.currentFilterFormValue),
      tap((filterTerm: string) => {
        this.currentFilterFormValue = filterTerm;
        this.checkTableAndStickyHeader(this.groupings, true);
      }),
    );
  }

  createUiRowDataModel (groupingData): IGroupOrder {
    return groupingData.reduce((accum: { [key: string]: IGroupData }, groupData: IGroupData, index) => {
      const { key } = groupData;
      accum[key] = index;
      return accum;
    }, {});
  }

  updateUiRowDataModel ($event: IGroupData, dataModel) {
    const clonedArr = [...this.groupings];
    clonedArr[dataModel[$event.key]] = $event;
    this.groupings = clonedArr;
    this.uiRowDataUpdate.emit(clonedArr);
  }

  scrollToSection (sectionIndex: number): void {
    const sectionEl = this.sectionContainer.nativeElement.getElementsByTagName('fixed-table')[sectionIndex];
    // protect against the event there are no groupings ie: no data / empty state
    if (sectionEl) {
      const sectionElRect = sectionEl.getBoundingClientRect();

      const top =
        this.sectionContainer.nativeElement.scrollTop +
        (sectionIndex === 0
          ? sectionElRect.y - this.fixedHeaderBoundary
          : sectionElRect.y - this.fixedHeaderBoundary + 80);

      this.sectionContainer.nativeElement.scrollTo({ top, behavior: 'smooth' });
    }
  }

  /**
   * Logic overview:
   * Construct nonEmptyTables: show empty state in view if its length is 0
   * Update displayedHeaders: push the first non-empty child to sticky header
   * (JCHU)
   */

  checkTableAndStickyHeader (groupings, filterChange?: boolean): void {
    this._setNonEmptyTables(groupings);
    this._setDisplayHeaders(groupings, filterChange);
    this._setNumRowsSelectedOfTotal(this.displayedHeaders);
    this.updateStickyHeaderCheckbox();
  }

  private _setNonEmptyTables (groupings) {
    if (this.tables) {
      if (this.currentFilterFormValue === '') {
        this.nonEmptyTables = this._getNonEmptyTablesFromGroupings(groupings);
      } else {
        this.nonEmptyTables = this._getNonEmptyTablesFromTables(this.tables);
      }
    } else {
      // initial load of a list, go to this block
      this.nonEmptyTables = this._getNonEmptyTablesFromGroupings(groupings);
    }
  }

  private _getNonEmptyTablesFromGroupings (groupings) {
    return groupings.map((groupData, groupIndx) => ({
      indx: groupIndx,
      human: groupData.human,
      total: groupData.rowData.length,
    }));
  }

  private _getNonEmptyTablesFromTables (tables) {
    return tables.reduce((nonEmptyTables, table, tableIndx) => {
      const notEmpty = !!table.dataSource$.value.length;
      return notEmpty
        ? nonEmptyTables.concat({
          indx: tableIndx,
          human: table.displayedHeaders[0].human,
          total: table.dataSource$.value.length,
        })
        : nonEmptyTables;
    }, []);
  }

  private _setDisplayHeaders (groupings, filterChange) {
    const hasTablesToDisplay = this.nonEmptyTables.length;
    if (hasTablesToDisplay) {
      if (this.tableEls) {
        // after initial load, when actions are search, batch actions, changing dropdown option, go to this block

        // headerPos: is the index of a tableEl that is srolled into a range where listStickyHeader should be re-rendered
        const headerPos = this.checkHeaderPos(this.tableEls, this.listStickyHeader);
        if (this.currentFilterFormValue === '') {
          const groupData = groupings[headerPos];
          if (groupData) {
            this.displayedHeaders = this.getDisplayedHeaders(this.columns, groupData, this.madlibModel?.value, this.showFirstColumnHeader);
          }
        } else {
          const nonEmptyTable = this.nonEmptyTables[headerPos];
          const newDisplayedHeaders = this.getDisplayedHeaders(this.columns, groupings[headerPos], this.madlibModel?.value, this.showFirstColumnHeader);
          // prevent circular call due to change to this.table
          const headersChanged = this.haveHeadersChanged(this.displayedHeaders, newDisplayedHeaders);
          if (nonEmptyTable && (filterChange || headersChanged)) {
            this.displayedHeaders = this.getNewDisplayedHeaders(newDisplayedHeaders, {
              human: nonEmptyTable.human, // new headers include new grouping human value
              total: nonEmptyTable.total,
            });
          }
        }
      } else {
        // initial load of a list, go to this block
        this.displayedHeaders = this.getDisplayedHeaders(this.columns, groupings[0], this.madlibModel?.value, this.showFirstColumnHeader);
      }
    }
  }

  private _setNumRowsSelectedOfTotal (displayedHeaders = []): void {
    if (displayedHeaders.length) {
      const { human } = displayedHeaders[0];
      const tableHeadersHash = this.checkedBatchUpdateHeaders.getCheckedTableHeaders();
      this.numRowsSelectedOfTotal = tableHeadersHash[human] && tableHeadersHash[human].numRowsSelectedOfTotal;
      this.ref.detectChanges();
    }
  }

  private updateStickyHeaderCheckbox (): void {
    const hasTablesToDisplay = this.nonEmptyTables.length;
    if (this.isBatchActionActivated && hasTablesToDisplay) {
      const tableHeaderHash = this.checkedBatchUpdateHeaders.getCheckedTableHeaders();
      if (this.displayedHeaders && this.displayedHeaders.length) {
        const table = tableHeaderHash[this.displayedHeaders[0].human];
        if (table) {
          const { isChecked, isIndeterminate } = table;
          this.stickyHeaderIsChecked$.next(isChecked);
          this.stickyHeaderIsInd$.next(isIndeterminate);
        }
      }
    }
  }

  loadFocusedGroup ($event: {
    groupData: IGroupData;
    sortKey: string;
    sortDirection: TSortDirections;
    groupIndx: number;
  }): void {
    this.focusedGroup.emit($event);
  }

  emitStateChange ($event: IRowData[]): void {
    this.isSummerSchoolToggleOn = this.toggleService.getToggleState(Toggles.TOGGLE_SUMMER_SCHOOL);
    if (this.isSummerSchoolToggleOn) {
      if ($event[1].columnKey === 'HOME_SCHOOL') {
        const homeSchoolId = $event[1].data;
        if (homeSchoolId === this.schoolId) {
          this.clickedRow.emit($event);
        }
      } else {
        this.clickedRow.emit($event);
      }
    } else {
      this.clickedRow.emit($event);
    }
  }

  emitBatchActionData ($event): void {
    this.batchActionData.emit($event);
  }

  initializeCheckedBatchUpdateHeaders (): IInitializeCheckBatchUpdateHeaders {
    let tableHeaderHash: {
      isChecked?: boolean;
      isIndeterminate?: boolean;
      numRowsSelectedOfTotal?: number;
    } = {};

    const batchActionHeaders = {
      setSelectedHeaders: (groupings: IGroupData[]) => {
        groupings.forEach((group: IGroupData) => {
          const count = group.rowData.reduce((count: number, row: IRowData[]) => {
            if ((row as any).isChecked) count++;
            return count;
          }, 0);
          const groupLength = group.rowData.length;
          const isChecked = !!(groupLength > 0 && groupLength === count);
          const isIndeterminate = !!(count && group.rowData.length > count);
          tableHeaderHash[group.human] = { isChecked, isIndeterminate };
        });
      },
      updateCheckedTableHeader: ($event: IBatchActionTableHeaderIsChecked) => {
        const { headerName, isChecked, isIndeterminate, visibleRowData, numRowsSelectedOfTotal } = $event;
        // set visibleRowData on groupings to account for filtered group data when using the search box.
        const index = this.groupings.findIndex(group => group.human === headerName);
        this.groupings[index].visibleRowData = visibleRowData;
        const currentHeader = this.displayedHeaders[0].human;
        tableHeaderHash[headerName] = { isChecked, isIndeterminate, numRowsSelectedOfTotal };

        // update sticky header when the table in the first position emits a row update.
        if (currentHeader === headerName) {
          this.stickyHeaderIsChecked$.next(isChecked);
          this.stickyHeaderIsInd$.next(isIndeterminate);
          this.numRowsSelectedOfTotal = numRowsSelectedOfTotal;
          this.ref.detectChanges();
        }
      },
      getCheckedTableHeaders: () => tableHeaderHash,
      reset: () => {
        tableHeaderHash = {};
        this.stickyHeaderIsChecked$.next(false);
        this.stickyHeaderIsInd$.next(false);
      },
    };
    this.stickyHeaderIsChecked$ = new BehaviorSubject<boolean>(false);
    this.stickyHeaderIsInd$ = new BehaviorSubject<boolean>(false);
    return batchActionHeaders;
  }

  emitBatchActionStickyHeaderIds (): void {
    const headerIsChecked = !this.stickyHeaderIsChecked$.value;
    this.stickyHeaderIsChecked$.next(headerIsChecked);
    const humanName = this.displayedHeaders[0].human;
    const { visibleRowData, rowData } = this.groupings.find(section => section.human === humanName);
    const sectionData = visibleRowData || rowData;
    const batchData = {
      updateAll: headerIsChecked,
      data: sectionData,
      level: 'SECTION',
      groupHuman: humanName,
      sortKey: this.sortKey$.value,
      sortDirection: this.sortDirection$.value,
      section: 0,
    };

    this.batchActionData.emit(batchData);
  }

  // Triggered by each scroll on the list:
  // 1. decide whether to show shadow, through ngClass
  // 2. update stickyHeader' displayedHeaders
  // 3. update stickyHeader's checkbox if batch actions mode is on
  checkShadowAndStickyHeader ($scrollTop: number): void {
    const fixedHeaderEle = this.fixedHeader.nativeElement.getBoundingClientRect();
    this.fixedHeaderBoundary = fixedHeaderEle.top + fixedHeaderEle.height;

    this.showShadow = this.checkShadow($scrollTop);
    const shouldWatchHeaderPos = !!this.nonEmptyTables.length;

    if (shouldWatchHeaderPos) {
      const headerPos = this.checkHeaderPos(this.tableEls, this.listStickyHeader);
      // headerPos: is the index of a tableEl that is srolled into a range where listStickyHeader should be re-rendered
      if (this.prevHeaderPos !== headerPos) {
        // Triggers re-rendering of listStickyHeader when:
        // 1. if scrolling from top to bottom, a prev tableEl bottom leaves listStickyHeader and curr tableEl top enters listStickyHeader
        // 2. if scrolling from bottom to top, a prev tableEl top leaves listStickyHeader and curr tableEl bottom enters listStickyHeader
        this.prevHeaderPos = headerPos;
        const groupData = this.groupings[headerPos];
        const nonEmptyTable = this.nonEmptyTables.filter(table => table.indx === headerPos)[0];
        if (groupData && nonEmptyTable) {
          this.displayedHeaders = this.getNewDisplayedHeaders(this.displayedHeaders, {
            human: groupData.human,
            total: nonEmptyTable.total,
          });
          this.updateStickyHeaderCheckbox();
          this._setNumRowsSelectedOfTotal(this.displayedHeaders);
        }
      }
    }
  }

  onClear (): void {
    this.filterFormControl.setValue('');
  }

  checkHeaderOverflow (className: string): void {
    this.headerOverflow = this.getHeaderOverflow(className);
  }

  resetHeaderOverflow (): void {
    this.headerOverflow = false;
  }

  emitDynamicComponentClicked (e): void {
    this.clickedDynamicComponent.emit(e);
  }

  ngOnDestroy (): void {
    this.batchActionSub.unsubscribe();
    this.summarySub.unsubscribe();
  }

  // List has staticGroupings set to true: grid edit column modal
  // List has staticGroupings undefined: attendance list, academic list, cluster user portfolio modal, etc
  trackByGroupFn (index: number, groupData: IGroupData): string {
    const staticGroupings = this.listConfig && this.listConfig.staticGroupings;
    const uniqueIdentifier = staticGroupings ? groupData.key : this.getIdentifierForDynamicGroupings(groupData);
    return `${index}-${uniqueIdentifier}-${this._getGroupUniqueIdentifier(groupData)}`;
  }

  getIdentifierForDynamicGroupings (groupData) {
    const { rowData, showAll } = groupData;
    const compoundIdentifier = `${JSON.stringify(rowData)} ${showAll}`;
    return compoundIdentifier;
  }

  // Generate a unique suffix based on the key property of groupData to differentiate when groupings change but columns stay the same
  private _getGroupUniqueIdentifier (groupData: IGroupData): string {
    return groupData.key;
  }
}
