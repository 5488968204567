import { CategoryTagsComponent } from './category-tags-renderer.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NvPillModule } from '../../../../../nvps-libraries/design/nv-pill/nv-pill.module';
import { NvTooltipModule } from 'Src/ng2/shared/nv-tooltip/nv-tooltip.module';

@NgModule({
  imports: [CommonModule, NvPillModule, NvTooltipModule],
  declarations: [CategoryTagsComponent],
  exports: [CategoryTagsComponent],
})
export class CategoryTagsRendererModule { }
