import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvDropdownModule } from 'Src/nvps-libraries/design/nv-dropdown/nv-dropdown.module';
import { PortfolioPermissionDropdownComponent } from './portfolio-permission-dropdown.component';

@NgModule({
  imports: [CommonModule, NvDropdownModule],
  declarations: [PortfolioPermissionDropdownComponent],
  exports: [PortfolioPermissionDropdownComponent],
  providers: [],
})
export class PortfolioPermissionDropdownModule {}
