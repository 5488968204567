import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseModalComponent } from '../base-modal.component';

@Component({
  selector: 'assign-plans',
  templateUrl: './assign-plans.component.html',
  styleUrls: ['./assign-plans.component.scss'],
})
export class AssignPlansComponent extends BaseModalComponent implements OnInit {
  public itemCount: number;
  public itemType = 'student';
  public nextRegentsAdminDate: string;
  public regentsExam: string;
  public title = 'Assign Regents Plans';

  constructor (
    public dialogRef: MatDialogRef<AssignPlansComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super(dialogRef);
  }

  ngOnInit (): void {
    const { itemCount, nextRegentsAdminDate, regentsExam } = this.data;
    this.itemCount = itemCount;
    this.nextRegentsAdminDate = nextRegentsAdminDate;
    this.regentsExam = regentsExam;
  }
}
