import { NvDesignLibraryModule } from './../../../../nvps-libraries/design/nv-design-library.module';
import { FormBuilder } from '@angular/forms';
import { StudentSupportModalV2Component } from './student-support-modal-v2.component';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BaseModalModule } from '../base-modal.module';

@NgModule({
  declarations: [StudentSupportModalV2Component],
  imports: [CommonModule, BaseModalModule, NvDesignLibraryModule],
  exports: [StudentSupportModalV2Component],
  providers: [FormBuilder],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class StudentSupportModalV2Module {}
