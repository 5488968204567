import { ApiService } from 'Src/ng2/shared/services/api-service/api-service';
import { FormBuilder } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvFormFieldModule } from '../../../../../../nvps-libraries/design/nv-form-field/nv-form-field.module';
import { NvPillButtonModule } from '../../../../../../nvps-libraries/design/nv-pill-button/nv-pill-button.module';
import { NvTextboxModule } from '../../../../../../nvps-libraries/design/nv-textbox/nv-textbox.module';
import { NvDropdownModule } from '../../../../../../nvps-libraries/design/nv-dropdown/nv-dropdown.module';
import { NvMultipickerModule } from '../../../../../../nvps-libraries/design/nv-multi-picker/nv-multi-picker.module';
import { NvNoteModule } from '../../../../../../../projects/shared/nvps-libraries/design/nv-note/nv-note.module';
import { NvButtonModule } from 'projects/shared/nvps-libraries/design/nv-button/nv-button.module';
import { StudentSelectorComponent } from './student-selector.component';
import { NvIconModule } from 'projects/shared/nvps-libraries/design/nv-icon/nv-icon.module';

@NgModule({
  declarations: [StudentSelectorComponent],
  imports: [
    CommonModule,
    NvTextboxModule,
    NvDropdownModule,
    NvMultipickerModule,
    NvNoteModule,
    NvButtonModule,
    NvPillButtonModule,
    NvFormFieldModule,
    NvIconModule,
  ],
  exports: [StudentSelectorComponent],
  providers: [FormBuilder, ApiService],
})
export class StudentSelectorModule {}
