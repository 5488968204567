/* tslint:disable */

import { Action } from '@ngrx/store';
export const UPDATE_CSV_DATA = '[Portal] Update CSV Data';

export class UpdateCsvData implements Action {
  readonly type = UPDATE_CSV_DATA;
  constructor (public payload: any) {}
}

// action types
export type CsvDataAction = UpdateCsvData;
