import { LoadToggles } from './../../../store/actions/toggle-actions';
import { tap, filter, switchMap, take } from 'rxjs/operators';
import { getToggleLoadedStatus, getToggleEntities } from './../../../store/selectors/toggles-selectors';
import { Store, select } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
@Injectable()
export class FeatureToggleGuard implements CanActivate {
  constructor (private store: Store<any>, private router: Router) {}
  getTogglesFromStoreOrAPI (currentSchoolId: string): Observable<any> {
    return this.store.pipe(
      select(getToggleLoadedStatus),
      tap(loaded => {
        if (!loaded) this.store.dispatch(new LoadToggles({ currentSchoolId }));
      }),
      filter(loaded => loaded),
      switchMap(() => this.store.pipe(select(getToggleEntities))),
      take(1),
    );
  }

  canActivate (snapshot: ActivatedRouteSnapshot): Observable<boolean|UrlTree> {
    const {
      params: {
        schoolId,
      },
      data: {
        onFailureRedirect,
        toggles: routeToggles = [],
      },
    } = snapshot;

    if (!routeToggles.length || !onFailureRedirect) return of(false);

    const formattedFailureRedirect = this.formatRedirect(onFailureRedirect, snapshot.params);
    const toggles$ = this.getTogglesFromStoreOrAPI(schoolId);
    return toggles$.pipe(
      switchMap(toggles => {
        const allTogglesPass = routeToggles.every((toggle: string) => toggles[toggle]);
        if (allTogglesPass) return of(true); // proceed to feature
        else return of(this.router.createUrlTree([formattedFailureRedirect])); // kick user back to intended redirect
      }));
  }

  private formatRedirect (url: string, params: ActivatedRouteSnapshot['params']): string {
    const { schoolId } = params;
    const urlContainsSchoolId = url.includes(':schoolId');
    if (urlContainsSchoolId && schoolId) return url.replace(':schoolId', schoolId);
    if (urlContainsSchoolId && !schoolId) return 'home';
    if (!urlContainsSchoolId) return url;
  }
}
