import { RegentsPrepPriorityGroupingsService } from './../../../../services/regents-prep-priority-groupings/regents-prep-priority-groupings.service';
import { CurrentSchoolYear } from 'Src/ng2/shared/constants/current-school-year.constant';
import { ObjectCache } from 'Src/ng2/shared/services/object-cache/object-cache.service';
import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { dropRight, each, reduce, cloneDeep, find, map } from 'lodash';
import { UrlPathService } from 'Src/ng2/shared/services/url-path-service/url-path.service';

@Component({
  selector: 'student-review-step',
  templateUrl: './student-review-step.component.html',
  styleUrls: ['../../shared-steps/shared-step-styles.scss', './student-review-step.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StudentReviewStep implements OnInit {
  @Input() filteredStudents;
  public stepLabel: string = 'Assign students to Regents prep supports';
  public organizedPriorityData;
  public totalStudents;
  public totalExams;
  public graduatingCohort;
  public options;

  constructor (
    private objectCache: ObjectCache,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private regentsPrepPriorityGroupingsService: RegentsPrepPriorityGroupingsService,
    private urlPathService: UrlPathService,
  ) { }

  ngOnInit () {
    this.organizedPriorityData = this.organizeStudentsByPriority(this.filteredStudents);
    this.totalStudents = this.getTotalStudents(this.organizedPriorityData);
    this.totalExams = this.getTotalExams(this.organizedPriorityData);
    this.graduatingCohort = Number(CurrentSchoolYear.ENDFULL);
    this.options = {
      columnKeys: ['cohort', 'gradPlan', 'regentsSchedNextAdminWithNoPrep'],
    };
  }

  ngOnChanges ({ filteredStudents }) {
    if (filteredStudents && !filteredStudents.isFirstChange()) {
      this.ngOnInit();
    }
  }

  goToStudentProfile (studentIds) {
    const filter = this.objectCache.cacheObject({ studentId: studentIds });
    const url = this.urlPathService.computeDistrictUrlPath(`/school/${this.activatedRoute.snapshot.params.schoolId}/student`);
    this.router.navigate([url], { queryParams: { filter }, state: { sdcKey: this.activatedRoute.snapshot.data.sdcKey } });
  };

  private getTotalStudents (priorityGroups) {
    return reduce(
      priorityGroups,
      (result, priorityGroup: any) => {
        result = result.concat(priorityGroup.studentIds);
        return result;
      },
      [],
    );
  };

  private getTotalExams (priorityGroups) {
    return reduce(
      priorityGroups,
      (result, priorityGroup: any) => {
        result += priorityGroup.examCount;
        return result;
      },
      0,
    );
  };

  private organizeStudentsByPriority (students) {
    const regentsPrepPriorityGroups = this.regentsPrepPriorityGroupingsService.getPriorityGroupsByStudentYearFilter();

    // copy and remove the last priority group because those are students who don't need any prep
    const priorityGroupCopy = dropRight(cloneDeep(regentsPrepPriorityGroups));

    const priorityGroups = map(priorityGroupCopy, (group: any) => {
      group.studentIds = [];
      group.examCount = 0;
      return group;
    });

    each(students, (student: any) => {
      const { studentId, regentsPrepPriorityGrouping, countOfRegentsSchedNextAdminWithNoPrep } = student;
      if (regentsPrepPriorityGrouping) {
        const group = find(priorityGroups, { studentPriorityGroupingValue: regentsPrepPriorityGrouping });
        if (group) {
          group.studentIds.push(studentId);
          group.examCount += countOfRegentsSchedNextAdminWithNoPrep;
        }
      }
    });
    return priorityGroups;
  };
}
