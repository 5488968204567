import { Injectable } from '@angular/core';
import { IApi } from '../../api-service/api-service.interface';

@Injectable()
export class GraphQLClusterHelperService {
  subFieldsProjectionMap = {
    loginMethod: `
      loginMethod {
        required optional
      }`,
  };

  getClustersByClusterTypeQuery({ params, projections }: IApi['GetClustersByClusterTypePayload']): string {
    const validType = params && params.type ? `"${params.type}"` : 'null';
    const validProjections = this.parseProjections(projections);
    const query = `{
      ClustersByClusterType(
        type: ${validType}
      ) {
         ${validProjections}
        }
      }`;
    return query;
  }

  getClusterQuery({ params, projections }: IApi['GetClusterPayload']): string {
    const validType = params && params.type ? `"${params.type}"` : 'null';
    const validClusterId = params && params.clusterId ? `"${params.clusterId}"` : 'null';
    const validProjections = this.parseProjections(projections);
    const query = `{
      Cluster(
        type: ${validType},
        clusterId: ${validClusterId}
      ) {
         ${validProjections}
        }
      }`;
    return query;
  }

  parseProjections(projections: string[]): string {
    const fieldsWithouSubFields = [];
    const fieldsHaveSubFields = [];
    projections.forEach(projection => {
      const fieldVal = this.subFieldsProjectionMap[projection];
      fieldVal ? fieldsHaveSubFields.push(fieldVal) : fieldsWithouSubFields.push(projection);
    });
    const reqFields_fieldsWithouSubFields = fieldsWithouSubFields.length ? fieldsWithouSubFields.join(' ').trim() : '';
    const reqFields_fieldsHaveSubFields = fieldsHaveSubFields.length ? fieldsHaveSubFields.join(' ').trim() : '';
    if (!reqFields_fieldsWithouSubFields && !reqFields_fieldsHaveSubFields) {
      return '_id';
    } else {
      return `${reqFields_fieldsHaveSubFields} ${reqFields_fieldsWithouSubFields}`;
    }
  }
}
