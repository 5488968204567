import { EventFormatterService } from 'Src/ng2/shared/services/mixpanel/event-formatter.service';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseModalComponent } from '../../base-modal.component';
import { cloneDeep } from 'lodash';
import { MixpanelService } from 'Src/ng2/shared/services/mixpanel/mixpanel.service';
import { THistoryLogsComponent } from '../../history-all/history-all-modal.component';

@Component({
  selector: 'note-details-modal',
  templateUrl: './note-details-modal.component.html',
  styleUrls: ['./note-details-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NoteDetailsModalComponent extends BaseModalComponent implements OnInit {
  public title: string = 'Note details';
  public itemType: string = 'student';
  public listType: THistoryLogsComponent;
  // template data
  public sections: any[];

  constructor (
    dialogRef: MatDialogRef<NoteDetailsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private eventFormatterService: EventFormatterService,
    private mixpanelService: MixpanelService,
  ) {
    super(dialogRef);
  }

  ngOnInit (): void {
    const { listType, rowData } = this.data;
    this.sections = cloneDeep(rowData);
    this.listType = listType;
    const viewNoteEvent = this.eventFormatterService.getViewNoteEvent({
      view: this.listType === 'STUDENT' ? 'STUDENT-PROFILE' : 'GRID-ROW',
      portal: 'SCHOOL',
      categories: [],
    });
    this.mixpanelService.trackEvents([viewNoteEvent]);
  }
}
