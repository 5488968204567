/* tslint:disable */

import { Action } from '@ngrx/store';
export const LOAD_USERS = '[Portal] Load Users';
export const LOAD_USERS_SUCCESS = '[Portal] Load Users Success';
export const UPDATE_USER_SUCCESS = '[Portal] Update User Success';

// LoadUsers payload would
// TODO: these actions need unit tests (CM).
/* istanbul ignore next */
export class LoadUsers implements Action {
  readonly type = LOAD_USERS;
  constructor (public payload: object) {}
}

export class LoadUsersSuccess implements Action {
  readonly type = LOAD_USERS_SUCCESS;
  constructor (public payload: any) {}
}

export class UpdateUserSuccess implements Action {
  readonly type = UPDATE_USER_SUCCESS;
  constructor (public payload: any) {}
}

// called with `store.dispatch(new LoadUsersSuccess(payload))`
export type UsersAction = LoadUsers | LoadUsersSuccess | UpdateUserSuccess;
