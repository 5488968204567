const ACADIENCE_READING = {
  acadienceReadingCompositeDateFall: {
    tag: 'Acadience Reading',
    tags: ['Acadience Reading', 'Fall'],
    humanName: 'Acadience Reading: Composite Date (Fall)',
  },
  acadienceReadingCompositeScoreFall: {
    tag: 'Acadience Reading',
    tags: ['Acadience Reading', 'Fall'],
    humanName: 'Acadience Reading: Composite Score (Fall)',
  },
  acadienceReadingCompositeStatusFall: {
    tag: 'Acadience Reading',
    tags: ['Acadience Reading', 'Fall'],
    humanName: 'Acadience Reading: Composite Status (Fall)',
  },
  acadienceReadingCompositeGoalFall: {
    tag: 'Acadience Reading',
    tags: ['Acadience Reading', 'Fall'],
    humanName: 'Acadience Reading: Composite Goal (Fall)',
  },
  acadienceReadingCompositeGoalStatusFall: {
    tag: 'Acadience Reading',
    tags: ['Acadience Reading', 'Fall'],
    humanName: 'Acadience Reading: Composite Goal Status (Fall)',
  },
  acadienceReadingCompositeDateWinter: {
    tag: 'Acadience Reading',
    tags: ['Acadience Reading', 'Winter'],
    humanName: 'Acadience Reading: Composite Date (Winter)',
  },
  acadienceReadingCompositeScoreWinter: {
    tag: 'Acadience Reading',
    tags: ['Acadience Reading', 'Winter'],
    humanName: 'Acadience Reading: Composite Score (Winter)',
  },
  acadienceReadingCompositeStatusWinter: {
    tag: 'Acadience Reading',
    tags: ['Acadience Reading', 'Winter'],
    humanName: 'Acadience Reading: Composite Status (Winter)',
  },
  acadienceReadingCompositeGoalWinter: {
    tag: 'Acadience Reading',
    tags: ['Acadience Reading', 'Winter'],
    humanName: 'Acadience Reading: Composite Goal (Winter)',
  },
  acadienceReadingCompositeGoalStatusWinter: {
    tag: 'Acadience Reading',
    tags: ['Acadience Reading', 'Winter'],
    humanName: 'Acadience Reading: Composite Goal Status (Winter)',
  },
  acadienceReadingCompositeDateSpring: {
    tag: 'Acadience Reading',
    tags: ['Acadience Reading', 'Spring'],
    humanName: 'Acadience Reading: Composite Date (Spring)',
  },
  acadienceReadingCompositeScoreSpring: {
    tag: 'Acadience Reading',
    tags: ['Acadience Reading', 'Spring'],
    humanName: 'Acadience Reading: Composite Score (Spring)',
  },
  acadienceReadingCompositeStatusSpring: {
    tag: 'Acadience Reading',
    tags: ['Acadience Reading', 'Spring'],
    humanName: 'Acadience Reading: Composite Status (Spring)',
  },
  acadienceReadingCompositeGoalSpring: {
    tag: 'Acadience Reading',
    tags: ['Acadience Reading', 'Spring'],
    humanName: 'Acadience Reading: Composite Goal (Spring)',
  },
  acadienceReadingCompositeGoalStatusSpring: {
    tag: 'Acadience Reading',
    tags: ['Acadience Reading', 'Spring'],
    humanName: 'Acadience Reading: Composite Goal Status (Spring)',
  },
  acadienceReadingCompositePathwayFallToWinterProgress: {
    tag: 'Acadience Reading',
    tags: ['Acadience Reading', 'Fall to Winter'],
    humanName: 'Acadience Reading: Composite Pathway (Fall to Winter Progress)',
  },
  acadienceReadingCompositePathwayFallToSpringProgress: {
    tag: 'Acadience Reading',
    tags: ['Acadience Reading', 'Fall to Spring'],
    humanName: 'Acadience Reading: Composite Pathway (Fall to Spring Progress)',
  },
};

const HISTORICAL_ACADIENCE_READING = {
  oneYearPriorSpringAcadienceReadingCompositeDate: {
    tag: 'Acadience Reading',
    tags: ['Acadience Reading', 'Spring', 'One Year Prior'],
    humanName: 'One Year Prior - Spring Acadience Reading: Composite Date',
  },
  oneYearPriorSpringAcadienceReadingCompositeScore: {
    tag: 'Acadience Reading',
    tags: ['Acadience Reading', 'Spring', 'One Year Prior'],
    humanName: 'One Year Prior - Spring Acadience Reading: Composite Score',
  },
  oneYearPriorSpringAcadienceReadingCompositeStatus: {
    tag: 'Acadience Reading',
    tags: ['Acadience Reading', 'Spring', 'One Year Prior'],
    humanName: 'One Year Prior - Spring Acadience Reading: Composite Status',
  },
  oneYearPriorSpringAcadienceReadingCompositePathwayFallToSpringProgress: {
    tag: 'Acadience Reading',
    tags: ['Acadience Reading', 'Fall to Spring', 'One Year Prior'],
    humanName: 'One Year Prior - Spring Acadience Reading: Composite Pathway (Fall to Spring Progress)',
  },
  oneYearPriorSpringAcadienceReadingCompositeGoal: {
    tag: 'Acadience Reading',
    tags: ['Acadience Reading', 'Spring', 'One Year Prior'],
    humanName: 'One Year Prior - Spring Acadience Reading: Composite Goal',
  },
  oneYearPriorSpringAcadienceReadingCompositeGoalStatus: {
    tag: 'Acadience Reading',
    tags: ['Acadience Reading', 'Spring', 'One Year Prior'],
    humanName: 'One Year Prior - Spring Acadience Reading: Composite Goal Status',
  },
  oneYearPriorWinterAcadienceReadingCompositeDate: {
    tag: 'Acadience Reading',
    tags: ['Acadience Reading', 'Winter', 'One Year Prior'],
    humanName: 'One Year Prior - Winter Acadience Reading: Composite Date',
  },
  oneYearPriorWinterAcadienceReadingCompositeScore: {
    tag: 'Acadience Reading',
    tags: ['Acadience Reading', 'Winter', 'One Year Prior'],
    humanName: 'One Year Prior - Winter Acadience Reading: Composite Score',
  },
  oneYearPriorWinterAcadienceReadingCompositeStatus: {
    tag: 'Acadience Reading',
    tags: ['Acadience Reading', 'Winter', 'One Year Prior'],
    humanName: 'One Year Prior - Winter Acadience Reading: Composite Status',
  },
  oneYearPriorWinterAcadienceReadingCompositePathwayFallToWinterProgress: {
    tag: 'Acadience Reading',
    tags: ['Acadience Reading', 'Fall to Winter', 'One Year Prior'],
    humanName: 'One Year Prior - Winter Acadience Reading: Composite Pathway (Fall to Winter Progress)',
  },
  oneYearPriorWinterAcadienceReadingCompositeGoal: {
    tag: 'Acadience Reading',
    tags: ['Acadience Reading', 'Winter', 'One Year Prior'],
    humanName: 'One Year Prior - Winter Acadience Reading: Composite Goal',
  },
  oneYearPriorWinterAcadienceReadingCompositeGoalStatus: {
    tag: 'Acadience Reading',
    tags: ['Acadience Reading', 'Winter', 'One Year Prior'],
    humanName: 'One Year Prior - Winter Acadience Reading: Composite Goal Status',
  },
  oneYearPriorFallAcadienceReadingCompositeDate: {
    tag: 'Acadience Reading',
    tags: ['Acadience Reading', 'Fall', 'One Year Prior'],
    humanName: 'One Year Prior - Fall Acadience Reading: Composite Date',
  },
  oneYearPriorFallAcadienceReadingCompositeScore: {
    tag: 'Acadience Reading',
    tags: ['Acadience Reading', 'Fall', 'One Year Prior'],
    humanName: 'One Year Prior - Fall Acadience Reading: Composite Score',
  },
  oneYearPriorFallAcadienceReadingCompositeStatus: {
    tag: 'Acadience Reading',
    tags: ['Acadience Reading', 'Fall', 'One Year Prior'],
    humanName: 'One Year Prior - Fall Acadience Reading: Composite Status',
  },
  oneYearPriorFallAcadienceReadingCompositeGoal: {
    tag: 'Acadience Reading',
    tags: ['Acadience Reading', 'Fall', 'One Year Prior'],
    humanName: 'One Year Prior - Fall Acadience Reading: Composite Goal',
  },
  oneYearPriorFallAcadienceReadingCompositeGoalStatus: {
    tag: 'Acadience Reading',
    tags: ['Acadience Reading', 'Fall', 'One Year Prior'],
    humanName: 'One Year Prior - Fall Acadience Reading: Composite Goal Status',
  },
};

const ACADIENCE_READING_DOMAINS = {
  acadienceReadingDomainFsfScoreFall: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'FSF'],
    humanName: 'Acadience Reading Domain: FSF Score (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainFsfStatusFall: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'FSF'],
    humanName: 'Acadience Reading Domain: FSF Status (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainFsfScoreWinter: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'FSF'],
    humanName: 'Acadience Reading Domain: FSF Score (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainFsfStatusWinter: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'FSF'],
    humanName: 'Acadience Reading Domain: FSF Status (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainFsfPathwayFallToWinterProgress: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'FSF'],
    humanName: 'Acadience Reading Domain: FSF Pathway (Fall to Winter Progress)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainFsfScoreSpring: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'FSF'],
    humanName: 'Acadience Reading Domain: FSF Score (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainFsfStatusSpring: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'FSF'],
    humanName: 'Acadience Reading Domain: FSF Status (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainFsfPathwayFallToSpringProgress: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'FSF'],
    humanName: 'Acadience Reading Domain: FSF Pathway (Fall to Spring Progress)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainPsfScoreFall: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'PSF'],
    humanName: 'Acadience Reading Domain: PSF Score (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainPsfStatusFall: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'PSF'],
    humanName: 'Acadience Reading Domain: PSF Status (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainPsfScoreWinter: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'PSF'],
    humanName: 'Acadience Reading Domain: PSF Score (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainPsfStatusWinter: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'PSF'],
    humanName: 'Acadience Reading Domain: PSF Status (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainPsfPathwayFallToWinterProgress: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'PSF'],
    humanName: 'Acadience Reading Domain: PSF Pathway (Fall to Winter Progress)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainPsfScoreSpring: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'PSF'],
    humanName: 'Acadience Reading Domain: PSF Score (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainPsfStatusSpring: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'PSF'],
    humanName: 'Acadience Reading Domain: PSF Status (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainPsfPathwayFallToSpringProgress: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'PSF'],
    humanName: 'Acadience Reading Domain: PSF Pathway (Fall to Spring Progress)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainLnfScoreFall: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'LNF'],
    humanName: 'Acadience Reading Domain: LNF Score (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainLnfStatusFall: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'LNF'],
    humanName: 'Acadience Reading Domain: LNF Status (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainLnfScoreWinter: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'LNF'],
    humanName: 'Acadience Reading Domain: LNF Score (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainLnfStatusWinter: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'LNF'],
    humanName: 'Acadience Reading Domain: LNF Status (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainLnfPathwayFallToWinterProgress: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'LNF'],
    humanName: 'Acadience Reading Domain: LNF Pathway (Fall to Winter Progress)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainLnfScoreSpring: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'LNF'],
    humanName: 'Acadience Reading Domain: LNF Score (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainLnfStatusSpring: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'LNF'],
    humanName: 'Acadience Reading Domain: LNF Status (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainLnfPathwayFallToSpringProgress: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'LNF'],
    humanName: 'Acadience Reading Domain: LNF Pathway (Fall to Spring Progress)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainNwfClsScoreFall: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'NWF CLS'],
    humanName: 'Acadience Reading Domain: NWF CLS Score (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainNwfClsStatusFall: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'NWF CLS'],
    humanName: 'Acadience Reading Domain: NWF CLS Status (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainNwfClsScoreWinter: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'NWF CLS'],
    humanName: 'Acadience Reading Domain: NWF CLS Score (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainNwfClsStatusWinter: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'NWF CLS'],
    humanName: 'Acadience Reading Domain: NWF CLS Status (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainNwfClsPathwayFallToWinterProgress: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'NWF CLS'],
    humanName: 'Acadience Reading Domain: NWF CLS Pathway (Fall to Winter Progress)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainNwfClsScoreSpring: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'NWF CLS'],
    humanName: 'Acadience Reading Domain: NWF CLS Score (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainNwfClsStatusSpring: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'NWF CLS'],
    humanName: 'Acadience Reading Domain: NWF CLS Status (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainNwfClsPathwayFallToSpringProgress: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'NWF CLS'],
    humanName: 'Acadience Reading Domain: NWF CLS Pathway (Fall to Spring Progress)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainNwfWwrScoreFall: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'NWF WWR'],
    humanName: 'Acadience Reading Domain: NWF WWR Score (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainNwfWwrStatusFall: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'NWF WWR'],
    humanName: 'Acadience Reading Domain: NWF WWR Status (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainNwfWwrScoreWinter: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'NWF WWR'],
    humanName: 'Acadience Reading Domain: NWF WWR Score (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainNwfWwrStatusWinter: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'NWF WWR'],
    humanName: 'Acadience Reading Domain: NWF WWR Status (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainNwfWwrPathwayFallToWinterProgress: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'NWF WWR'],
    humanName: 'Acadience Reading Domain: NWF WWR Pathway (Fall to Winter Progress)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainNwfWwrScoreSpring: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'NWF WWR'],
    humanName: 'Acadience Reading Domain: NWF WWR Score (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainNwfWwrStatusSpring: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'NWF WWR'],
    humanName: 'Acadience Reading Domain: NWF WWR Status (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainNwfWwrPathwayFallToSpringProgress: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'NWF WWR'],
    humanName: 'Acadience Reading Domain: NWF WWR Pathway (Fall to Spring Progress)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainOrfWcScoreFall: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'ORF WC'],
    humanName: 'Acadience Reading Domain: ORF WC Score (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainOrfWcStatusFall: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'ORF WC'],
    humanName: 'Acadience Reading Domain: ORF WC Status (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainOrfWcScoreWinter: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'ORF WC'],
    humanName: 'Acadience Reading Domain: ORF WC Score (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainOrfWcStatusWinter: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'ORF WC'],
    humanName: 'Acadience Reading Domain: ORF WC Status (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainOrfWcPathwayFallToWinterProgress: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'ORF WC'],
    humanName: 'Acadience Reading Domain: ORF WC Pathway (Fall to Winter Progress)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainOrfWcScoreSpring: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'ORF WC'],
    humanName: 'Acadience Reading Domain: ORF WC Score (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainOrfWcStatusSpring: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'ORF WC'],
    humanName: 'Acadience Reading Domain: ORF WC Status (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainOrfWcPathwayFallToSpringProgress: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'ORF WC'],
    humanName: 'Acadience Reading Domain: ORF WC Pathway (Fall to Spring Progress)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainOrfAccuracyScoreFall: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'ORF Accuracy'],
    humanName: 'Acadience Reading Domain: ORF Accuracy Score (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainOrfAccuracyStatusFall: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'ORF Accuracy'],
    humanName: 'Acadience Reading Domain: ORF Accuracy Status (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainOrfAccuracyScoreWinter: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'ORF Accuracy'],
    humanName: 'Acadience Reading Domain: ORF Accuracy Score (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainOrfAccuracyStatusWinter: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'ORF Accuracy'],
    humanName: 'Acadience Reading Domain: ORF Accuracy Status (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainOrfAccuracyPathwayFallToWinterProgress: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'ORF Accuracy'],
    humanName: 'Acadience Reading Domain: ORF Accuracy Pathway (Fall to Winter Progress)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainOrfAccuracyScoreSpring: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'ORF Accuracy'],
    humanName: 'Acadience Reading Domain: ORF Accuracy Score (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainOrfAccuracyStatusSpring: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'ORF Accuracy'],
    humanName: 'Acadience Reading Domain: ORF Accuracy Status (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainOrfAccuracyPathwayFallToSpringProgress: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'ORF Accuracy'],
    humanName: 'Acadience Reading Domain: ORF Accuracy Pathway (Fall to Spring Progress)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainRetellScoreFall: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'Retell'],
    humanName: 'Acadience Reading Domain: Retell Score (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainRetellStatusFall: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'Retell'],
    humanName: 'Acadience Reading Domain: Retell Status (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainRetellScoreWinter: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'Retell'],
    humanName: 'Acadience Reading Domain: Retell Score (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainRetellStatusWinter: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'Retell'],
    humanName: 'Acadience Reading Domain: Retell Status (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainRetellPathwayFallToWinterProgress: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'Retell'],
    humanName: 'Acadience Reading Domain: Retell Pathway (Fall to Winter Progress)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainRetellScoreSpring: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'Retell'],
    humanName: 'Acadience Reading Domain: Retell Score (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainRetellStatusSpring: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'Retell'],
    humanName: 'Acadience Reading Domain: Retell Status (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainRetellPathwayFallToSpringProgress: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'Retell'],
    humanName: 'Acadience Reading Domain: Retell Pathway (Fall to Spring Progress)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainRetellQualityScoreFall: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'Retell Quality'],
    humanName: 'Acadience Reading Domain: Retell Quality Score (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainRetellQualityStatusFall: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'Retell Quality'],
    humanName: 'Acadience Reading Domain: Retell Quality Status (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainRetellQualityScoreWinter: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'Retell Quality'],
    humanName: 'Acadience Reading Domain: Retell Quality Score (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainRetellQualityStatusWinter: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'Retell Quality'],
    humanName: 'Acadience Reading Domain: Retell Quality Status (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainRetellQualityPathwayFallToWinterProgress: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'Retell Quality'],
    humanName: 'Acadience Reading Domain: Retell Quality Pathway (Fall to Winter Progress)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainRetellQualityScoreSpring: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'Retell Quality'],
    humanName: 'Acadience Reading Domain: Retell Quality Score (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainRetellQualityStatusSpring: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'Retell Quality'],
    humanName: 'Acadience Reading Domain: Retell Quality Status (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainRetellQualityPathwayFallToSpringProgress: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'Retell Quality'],
    humanName: 'Acadience Reading Domain: Retell Quality Pathway (Fall to Spring Progress)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainMazeAdjustedScoreFall: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'Maze'],
    humanName: 'Acadience Reading Domain: Maze Adjusted Score (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainMazeStatusFall: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'Maze'],
    humanName: 'Acadience Reading Domain: Maze Status (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainMazeAdjustedScoreWinter: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'Maze'],
    humanName: 'Acadience Reading Domain: Maze Adjusted Score (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainMazeStatusWinter: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'Maze'],
    humanName: 'Acadience Reading Domain: Maze Status (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainMazePathwayFallToWinterProgress: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'Maze'],
    humanName: 'Acadience Reading Domain: Maze Pathway (Fall to Winter Progress)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainMazeAdjustedScoreSpring: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'Maze'],
    humanName: 'Acadience Reading Domain: Maze Adjusted Score (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainMazeStatusSpring: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'Maze'],
    humanName: 'Acadience Reading Domain: Maze Status (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceReadingDomainMazePathwayFallToSpringProgress: {
    tag: 'Acadience Reading Domain',
    tags: ['Acadience Reading Domain', 'Maze'],
    humanName: 'Acadience Reading Domain: Maze Pathway (Fall to Spring Progress)',
    calculation (doc) {
      return doc;
    },
  },
};

const ACADIENCE_MATH = {
  acadienceMathCompositeDateFall: {
    tag: 'Acadience Math',
    tags: ['Acadience Math', 'Fall'],
    humanName: 'Acadience Math: Composite Date (Fall)',
  },
  acadienceMathCompositeScoreFall: {
    tag: 'Acadience Math',
    tags: ['Acadience Math', 'Fall'],
    humanName: 'Acadience Math: Composite Score (Fall)',
  },
  acadienceMathCompositeStatusFall: {
    tag: 'Acadience Math',
    tags: ['Acadience Math', 'Fall'],
    humanName: 'Acadience Math: Composite Status (Fall)',
  },
  acadienceMathCompositeGoalFall: {
    tag: 'Acadience Math',
    tags: ['Acadience Math', 'Fall'],
    humanName: 'Acadience Math: Composite Goal (Fall)',
  },
  acadienceMathCompositeGoalStatusFall: {
    tag: 'Acadience Math',
    tags: ['Acadience Math', 'Fall'],
    humanName: 'Acadience Math: Composite Goal Status (Fall)',
  },
  acadienceMathCompositeDateWinter: {
    tag: 'Acadience Math',
    tags: ['Acadience Math', 'Winter'],
    humanName: 'Acadience Math: Composite Date (Winter)',
  },
  acadienceMathCompositeScoreWinter: {
    tag: 'Acadience Math',
    tags: ['Acadience Math', 'Winter'],
    humanName: 'Acadience Math: Composite Score (Winter)',
  },
  acadienceMathCompositeStatusWinter: {
    tag: 'Acadience Math',
    tags: ['Acadience Math', 'Winter'],
    humanName: 'Acadience Math: Composite Status (Winter)',
  },
  acadienceMathCompositeGoalWinter: {
    tag: 'Acadience Math',
    tags: ['Acadience Math', 'Winter'],
    humanName: 'Acadience Math: Composite Goal (Winter)',
  },
  acadienceMathCompositeGoalStatusWinter: {
    tag: 'Acadience Math',
    tags: ['Acadience Math', 'Winter'],
    humanName: 'Acadience Math: Composite Goal Status (Winter)',
  },
  acadienceMathCompositeDateSpring: {
    tag: 'Acadience Math',
    tags: ['Acadience Math', 'Spring'],
    humanName: 'Acadience Math: Composite Date (Spring)',
  },
  acadienceMathCompositeScoreSpring: {
    tag: 'Acadience Math',
    tags: ['Acadience Math', 'Spring'],
    humanName: 'Acadience Math: Composite Score (Spring)',
  },
  acadienceMathCompositeStatusSpring: {
    tag: 'Acadience Math',
    tags: ['Acadience Math', 'Spring'],
    humanName: 'Acadience Math: Composite Status (Spring)',
  },
  acadienceMathCompositeGoalSpring: {
    tag: 'Acadience Math',
    tags: ['Acadience Math', 'Spring'],
    humanName: 'Acadience Math: Composite Goal (Spring)',
  },
  acadienceMathCompositeGoalStatusSpring: {
    tag: 'Acadience Math',
    tags: ['Acadience Math', 'Spring'],
    humanName: 'Acadience Math: Composite Goal Status (Spring)',
  },
  acadienceMathCompositePathwayFallToWinterProgress: {
    tag: 'Acadience Math',
    tags: ['Acadience Math', 'Fall to Winter'],
    humanName: 'Acadience Math: Composite Pathway (Fall to Winter Progress)',
  },
  acadienceMathCompositePathwayFallToSpringProgress: {
    tag: 'Acadience Math',
    tags: ['Acadience Math', 'Fall to Spring'],
    humanName: 'Acadience Math: Composite Pathway (Fall to Spring Progress)',
  },
};

const HISTORICAL_ACADIENCE_MATH = {
  oneYearPriorSpringAcadienceMathCompositeDate: {
    tag: 'Acadience Math',
    tags: ['Acadience Math', 'Spring', 'One Year Prior'],
    humanName: 'One Year Prior - Spring Acadience Math: Composite Date',
  },
  oneYearPriorSpringAcadienceMathCompositeScore: {
    tag: 'Acadience Math',
    tags: ['Acadience Math', 'Spring', 'One Year Prior'],
    humanName: 'One Year Prior - Spring Acadience Math: Composite Score',
  },
  oneYearPriorSpringAcadienceMathCompositeStatus: {
    tag: 'Acadience Math',
    tags: ['Acadience Math', 'Spring', 'One Year Prior'],
    humanName: 'One Year Prior - Spring Acadience Math: Composite Status',
  },
  oneYearPriorSpringAcadienceMathCompositePathwayFallToSpringProgress: {
    tag: 'Acadience Math',
    tags: ['Acadience Math', 'Fall to Spring', 'One Year Prior'],
    humanName: 'One Year Prior - Spring Acadience Math: Composite Pathway (Fall to Spring Progress)',
  },
  oneYearPriorSpringAcadienceMathCompositeGoal: {
    tag: 'Acadience Math',
    tags: ['Acadience Math', 'Spring'],
    humanName: 'One Year Prior - Spring Acadience Math: Composite Goal',
  },
  oneYearPriorSpringAcadienceMathCompositeGoalStatus: {
    tag: 'Acadience Math',
    tags: ['Acadience Math', 'Spring'],
    humanName: 'One Year Prior - Spring Acadience Math: Composite Goal Status',
  },
  oneYearPriorWinterAcadienceMathCompositeDate: {
    tag: 'Acadience Math',
    tags: ['Acadience Math', 'Winter', 'One Year Prior'],
    humanName: 'One Year Prior - Winter Acadience Math: Composite Date',
  },
  oneYearPriorWinterAcadienceMathCompositeScore: {
    tag: 'Acadience Math',
    tags: ['Acadience Math', 'Winter', 'One Year Prior'],
    humanName: 'One Year Prior - Winter Acadience Math: Composite Score',
  },
  oneYearPriorWinterAcadienceMathCompositeStatus: {
    tag: 'Acadience Math',
    tags: ['Acadience Math', 'Winter', 'One Year Prior'],
    humanName: 'One Year Prior - Winter Acadience Math: Composite Status',
  },
  oneYearPriorWinterAcadienceMathCompositePathwayFallToWinterProgress: {
    tag: 'Acadience Math',
    tags: ['Acadience Math', 'Fall to Winter', 'One Year Prior'],
    humanName: 'One Year Prior - Winter Acadience Math: Composite Pathway (Fall to Winter Progress)',
  },
  oneYearPriorWinterAcadienceMathCompositeGoal: {
    tag: 'Acadience Math',
    tags: ['Acadience Math', 'Winter', 'One Year Prior'],
    humanName: 'One Year Prior - Winter Acadience Math: Composite Goal',
  },
  oneYearPriorWinterAcadienceMathCompositeGoalStatus: {
    tag: 'Acadience Math',
    tags: ['Acadience Math', 'Winter', 'One Year Prior'],
    humanName: 'One Year Prior - Winter Acadience Math: Composite Goal Status',
  },
  oneYearPriorFallAcadienceMathCompositeDate: {
    tag: 'Acadience Math',
    tags: ['Acadience Math', 'Fall', 'One Year Prior'],
    humanName: 'One Year Prior - Fall Acadience Math: Composite Date',
  },
  oneYearPriorFallAcadienceMathCompositeScore: {
    tag: 'Acadience Math',
    tags: ['Acadience Math', 'Fall', 'One Year Prior'],
    humanName: 'One Year Prior - Fall Acadience Math: Composite Score',
  },
  oneYearPriorFallAcadienceMathCompositeStatus: {
    tag: 'Acadience Math',
    tags: ['Acadience Math', 'Fall', 'One Year Prior'],
    humanName: 'One Year Prior - Fall Acadience Math: Composite Status',
  },
  oneYearPriorFallAcadienceMathCompositeGoal: {
    tag: 'Acadience Math',
    tags: ['Acadience Math', 'Fall', 'One Year Prior'],
    humanName: 'One Year Prior - Fall Acadience Math: Composite Goal',
  },
  oneYearPriorFallAcadienceMathCompositeGoalStatus: {
    tag: 'Acadience Math',
    tags: ['Acadience Math', 'Fall', 'One Year Prior'],
    humanName: 'One Year Prior - Fall Acadience Math: Composite Goal Status',
  },
};

const ACADIENCE_MATH_DOMAINS = {
  acadienceMathDomainBqdScoreFall: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'BQD'],
    humanName: 'Acadience Math Domain: BQD Score (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainBqdStatusFall: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'BQD'],
    humanName: 'Acadience Math Domain: BQD Status (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainBqdScoreWinter: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'BQD'],
    humanName: 'Acadience Math Domain: BQD Score (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainBqdStatusWinter: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'BQD'],
    humanName: 'Acadience Math Domain: BQD Status (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainBqdPathwayFallToWinterProgress: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'BQD'],
    humanName: 'Acadience Math Domain: BQD Pathway (Fall to Winter Progress)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainBqdScoreSpring: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'BQD'],
    humanName: 'Acadience Math Domain: BQD Score (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainBqdStatusSpring: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'BQD'],
    humanName: 'Acadience Math Domain: BQD Status (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainBqdPathwayFallToSpringProgress: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'BQD'],
    humanName: 'Acadience Math Domain: BQD Pathway (Fall to Spring Progress)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainNifScoreFall: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'NIF'],
    humanName: 'Acadience Math Domain: NIF Score (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainNifStatusFall: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'NIF'],
    humanName: 'Acadience Math Domain: NIF Status (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainNifScoreWinter: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'NIF'],
    humanName: 'Acadience Math Domain: NIF Score (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainNifStatusWinter: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'NIF'],
    humanName: 'Acadience Math Domain: NIF Status (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainNifPathwayFallToWinterProgress: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'NIF'],
    humanName: 'Acadience Math Domain: NIF Pathway (Fall to Winter Progress)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainNifScoreSpring: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'NIF'],
    humanName: 'Acadience Math Domain: NIF Score (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainNifStatusSpring: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'NIF'],
    humanName: 'Acadience Math Domain: NIF Status (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainNifPathwayFallToSpringProgress: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'NIF'],
    humanName: 'Acadience Math Domain: NIF Pathway (Fall to Spring Progress)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainNnfScoreFall: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'NNF'],
    humanName: 'Acadience Math Domain: NNF Score (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainNnfStatusFall: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'NNF'],
    humanName: 'Acadience Math Domain: NNF Status (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainNnfScoreWinter: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'NNF'],
    humanName: 'Acadience Math Domain: NNF Score (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainNnfStatusWinter: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'NNF'],
    humanName: 'Acadience Math Domain: NNF Status (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainNnfPathwayFallToWinterProgress: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'NNF'],
    humanName: 'Acadience Math Domain: NNF Pathway (Fall to Winter Progress)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainNnfScoreSpring: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'NNF'],
    humanName: 'Acadience Math Domain: NNF Score (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainNnfStatusSpring: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'NNF'],
    humanName: 'Acadience Math Domain: NNF Status (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainNnfPathwayFallToSpringProgress: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'NNF'],
    humanName: 'Acadience Math Domain: NNF Pathway (Fall to Spring Progress)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainAqdScoreFall: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'AQD'],
    humanName: 'Acadience Math Domain: AQD Score (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainAqdStatusFall: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'AQD'],
    humanName: 'Acadience Math Domain: AQD Status (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainAqdScoreWinter: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'AQD'],
    humanName: 'Acadience Math Domain: AQD Score (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainAqdStatusWinter: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'AQD'],
    humanName: 'Acadience Math Domain: AQD Status (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainAqdPathwayFallToWinterProgress: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'AQD'],
    humanName: 'Acadience Math Domain: AQD Pathway (Fall to Winter Progress)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainAqdScoreSpring: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'AQD'],
    humanName: 'Acadience Math Domain: AQD Score (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainAqdStatusSpring: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'AQD'],
    humanName: 'Acadience Math Domain: AQD Status (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainAqdPathwayFallToSpringProgress: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'AQD'],
    humanName: 'Acadience Math Domain: AQD Pathway (Fall to Spring Progress)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainMnfScoreFall: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'MNF'],
    humanName: 'Acadience Math Domain: MNF Score (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainMnfStatusFall: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'MNF'],
    humanName: 'Acadience Math Domain: MNF Status (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainMnfScoreWinter: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'MNF'],
    humanName: 'Acadience Math Domain: MNF Score (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainMnfStatusWinter: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'MNF'],
    humanName: 'Acadience Math Domain: MNF Status (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainMnfPathwayFallToWinterProgress: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'MNF'],
    humanName: 'Acadience Math Domain: MNF Pathway (Fall to Winter Progress)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainMnfScoreSpring: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'MNF'],
    humanName: 'Acadience Math Domain: MNF Score (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainMnfStatusSpring: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'MNF'],
    humanName: 'Acadience Math Domain: MNF Status (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainMnfPathwayFallToSpringProgress: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'MNF'],
    humanName: 'Acadience Math Domain: MNF Pathway (Fall to Spring Progress)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainCAScoreFall: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'C&A'],
    humanName: 'Acadience Math Domain: C&A Score (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainCAStatusFall: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'C&A'],
    humanName: 'Acadience Math Domain: C&A Status (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainCAScoreWinter: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'C&A'],
    humanName: 'Acadience Math Domain: C&A Score (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainCAStatusWinter: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'C&A'],
    humanName: 'Acadience Math Domain: C&A Status (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainCAPathwayFallToWinterProgress: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'C&A'],
    humanName: 'Acadience Math Domain: C&A Pathway (Fall to Winter Progress)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainCAScoreSpring: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'C&A'],
    humanName: 'Acadience Math Domain: C&A Score (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainCAStatusSpring: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'C&A'],
    humanName: 'Acadience Math Domain: C&A Status (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainCAPathwayFallToSpringProgress: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'C&A'],
    humanName: 'Acadience Math Domain: C&A Pathway (Fall to Spring Progress)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainCompScoreFall: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'COMP'],
    humanName: 'Acadience Math Domain: COMP Score (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainCompStatusFall: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'COMP'],
    humanName: 'Acadience Math Domain: COMP Status (Fall)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainCompScoreWinter: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'COMP'],
    humanName: 'Acadience Math Domain: COMP Score (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainCompStatusWinter: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'COMP'],
    humanName: 'Acadience Math Domain: COMP Status (Winter)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainCompPathwayFallToWinterProgress: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'COMP'],
    humanName: 'Acadience Math Domain: COMP Pathway (Fall to Winter Progress)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainCompScoreSpring: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'COMP'],
    humanName: 'Acadience Math Domain: COMP Score (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainCompStatusSpring: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'COMP'],
    humanName: 'Acadience Math Domain: COMP Status (Spring)',
    calculation (doc) {
      return doc;
    },
  },
  acadienceMathDomainCompPathwayFallToSpringProgress: {
    tag: 'Acadience Math Domain',
    tags: ['Acadience', 'COMP'],
    humanName: 'Acadience Math Domain: COMP Pathway (Fall to Spring Progress)',
    calculation (doc) {
      return doc;
    },
  },
};

export const ACADIENCE_FLATTENED_STUDENT_MAP = {
  ...ACADIENCE_READING,
  ...HISTORICAL_ACADIENCE_READING,
  ...ACADIENCE_READING_DOMAINS,
  ...ACADIENCE_MATH,
  ...HISTORICAL_ACADIENCE_MATH,
  ...ACADIENCE_MATH_DOMAINS,
};
