import { Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { share } from 'rxjs/operators';

@Injectable()
export class DocMouseMoveService {
  public event$: Observable<Event>;
  constructor () {
    this.event$ = fromEvent(document, 'mousemove').pipe(share());
  }
}
