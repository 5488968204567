import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';

import { ResultsModalComponent } from './results-modal.component';
import { NvDesignLibraryModule } from '../../../../nvps-libraries/design/nv-design-library.module';
import { BaseModalModule } from '../base-modal.module';
import { NvNoteModule } from 'projects/shared/nvps-libraries/design/nv-note/nv-note.module';

@NgModule({
  declarations: [ResultsModalComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    BaseModalModule,
    NvDesignLibraryModule,
    NvNoteModule,
  ],
  exports: [ResultsModalComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ResultsModalModule {}
