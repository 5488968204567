import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

/**
 *
 * A Numbox is a limited implementation of a Textbox, restricted to only numbers.
 *
 */
@Component({
  selector: 'nv-numbox',
  templateUrl: './nv-numbox.component.html',
  styleUrls: ['./nv-numbox.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NvNumboxComponent implements OnInit {
  /**
   *
   */
  @Input() numControl: FormControl;

  /**
   *
   */
  @Input() type: 'number' | 'integer' = 'number';

  /**
   *
   */
  @Input() placeholder: string = 'Enter a number...';

  /**
   *
   * Determines whether to highlight invalid inputs as red.
   *
   * Use [Validators](https://angular.io/guide/form-validation) to define what inputs are valid.
   */
  @Input() showInvalid: boolean = false;

  /**
   * Determines whether to show the clear button
   */
  @Input() hasClearBtn: boolean = false;

  /**
   *
   */
  @Input() icon: string = null;

  /**
   * Determines whether to allow the mouse wheel to change the value, defaults to true meaning the mouse wheel will not change the value by default.
   */
  @Input() disableWheelEvent: boolean = true;

  /**
   *
   */
  @Output() changeValue: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('input', { static: false }) inputElement: ElementRef;

  private debounce_time: number = 50; // millis
  private destroy$: Subject<boolean> = new Subject<boolean>();
  isTextValid: boolean = false;

  ngOnInit () {
    // Set up the subscription to Value Changes
    this.numControl.valueChanges
      .pipe(
        debounceTime(this.debounce_time),
        distinctUntilChanged(),
        takeUntil(this.destroy$),
      )
      .subscribe(query => this.onValueChange(query));

    this.isTextValid = this.getIsTextValid();
  }

  onValueChange (num_value: number): void {
    this.isTextValid = this.getIsTextValid();
    this.changeValue.emit(num_value);
  }

  getIsTextValid (): boolean {
    let isValid = true;
    if (this.showInvalid) {
      isValid = this.numControl.valid;
    }
    return isValid;
  }

  ngOnDestroy (): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onFocus ($event): void {
    if (this.disableWheelEvent) {
      $event.target.addEventListener('wheel', this.preventWheelEvent);
    }
  }

  onBlur ($event): void {
    if (this.disableWheelEvent) {
      $event.target.removeEventListener('wheel', this.preventWheelEvent);
    }
  }

  preventWheelEvent ($event): void {
    $event.preventDefault();
  }
}
