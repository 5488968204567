import * as _ from 'lodash';

import { AG_GRID_NUMBER_FILTER_TYPES } from './../ag-grid-filter.constant';

export class NumberFilterPassCases {
  static evaluate(filterType, valueToFilterOn, filterValue) {
    if (!filterType) {
      throw new Error('Invalid use of NumberFilterPassCases<evaluate>. Missing required parameter.');
    }

    switch (filterType) {
      case AG_GRID_NUMBER_FILTER_TYPES.EQUALS:
        return _.isEqual(valueToFilterOn, filterValue);
      case AG_GRID_NUMBER_FILTER_TYPES.NOT_EQUALS:
        return !_.isEqual(valueToFilterOn, filterValue);
      case AG_GRID_NUMBER_FILTER_TYPES.LESS_THAN:
        return _.lt(valueToFilterOn, filterValue);
      case AG_GRID_NUMBER_FILTER_TYPES.LESS_THAN_OR_EQUAL:
        return _.lte(valueToFilterOn, filterValue);
      case AG_GRID_NUMBER_FILTER_TYPES.GREATER_THAN:
        return _.gt(valueToFilterOn, filterValue);
      case AG_GRID_NUMBER_FILTER_TYPES.GREATER_THAN_OR_EQUAL:
        return _.gte(valueToFilterOn, filterValue);
      case AG_GRID_NUMBER_FILTER_TYPES.RANGE:
        let min = filterValue.min;
        let max = filterValue.max + 0.000001;
        // supports up to six decimal places
        return min < max && _.inRange(valueToFilterOn, min, max);
      default:
        throw new Error('Invalid number filter type');
    }
  }
}
