import { createFeatureSelector, createSelector } from '@ngrx/store';
import { sortBy } from 'lodash';
import { IBatchActionsState } from '../reducers/batch-actions-reducer';

const getPortalState = createFeatureSelector('PORTAL_STORE');

export const getBatchActionsState = createSelector(
  getPortalState,
  (state: any) => {
    return state.batchActions;
  },
);

export const getBatchActionsMode = createSelector(
  getBatchActionsState,
  (state: IBatchActionsState) => {
    return state.enabled;
  },
);

export const getBatchActionsSelectedStudentIds = createSelector(
  getBatchActionsState,
  (state: IBatchActionsState) => {
    const studentData = state.selectedStudentIds;
    const sortedStudentData = sortBy(studentData, ['sectionIndex', 'rowIndex']);
    const sortedStudentIds = sortedStudentData.map(({ studentId }) => studentId);
    return sortedStudentIds;
  },
);
