import { Component, ElementRef, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'row-group-child-cell-renderer',
  styleUrls: ['./row-group-child-cell-renderer.component.scss'],
  templateUrl: './row-group-child-cell-renderer.component.html',
  encapsulation: ViewEncapsulation.Emulated,
})
export class RowGroupChildCellRenderer {
  public params;

  constructor (private el: ElementRef) {}

  agInit (params): void {
    this.params = params;
  }
}
