import { Injectable } from '@angular/core';
import { has, round } from 'lodash';
import { GRADIENT_TYPE } from '../../../shared/constants/list-view/cell-gradient.constant';
import { IListColumnData } from '../../../shared/typings/interfaces/list-view.interface';
import { ListCellFormattingService } from '../list-display/list-cell-formatting.service';
import { COLUMN_DATA_TYPE } from './../../../shared/constants/list-view/cell-type.constant';

@Injectable()
export class AttendanceService {
  termStructure: string;
  listCellFormattingService: ListCellFormattingService;

  constructor() {
    this.listCellFormattingService = new ListCellFormattingService();
  }

  formatColumnData(columnType, columnDataObj, cellConfig) {
    const columnData = has(columnDataObj, 'data') ? columnDataObj.data : columnDataObj;

    switch (columnType) {
      case COLUMN_DATA_TYPE.FLAG:
        return this.listCellFormattingService.flagFormatter(columnData);

      case COLUMN_DATA_TYPE.GRADIENT:
        return this.gradientFormatter(columnDataObj);

      case COLUMN_DATA_TYPE.GRADIENT_CUSTOM:
        return this.gradientCustomFormatter(columnDataObj, cellConfig);

      case COLUMN_DATA_TYPE.GENERIC:
        return this.listCellFormattingService.genericFormatter(columnData);

      case COLUMN_DATA_TYPE.PERCENTAGE:
        return this.listCellFormattingService.percentageFormatter(columnData);

      case COLUMN_DATA_TYPE.DATE:
        return this.listCellFormattingService.dateFormatter(columnData);

      default:
        let formattedData: IListColumnData = {
          data: columnData,
          style: 'center',
        };
        return formattedData;
    }
  }

  private gradientCustomFormatter(columnDataObj, colConfig): IListColumnData {
    const columnData = has(columnDataObj, 'data') ? columnDataObj.data : columnDataObj;
    const gradientType = colConfig.gradientRule.type;

    let formattedData: IListColumnData = {
      data: columnData,
      style: 'center ',
    };

    if (columnData === null) {
      formattedData.data = '—';
      formattedData.style = 'disabled center';
      return formattedData;
    }

    switch (gradientType) {
      case GRADIENT_TYPE.ATTENDANCE_TODAY:
        if (columnData.toLowerCase() === 'absent') {
          formattedData.style += 'gradient-medium';
        }
        break;

      case GRADIENT_TYPE.ATTENDANCE_YEARLY_CHANGE:
        formattedData.data = round(columnData * 100, 1) + '%';
        if (columnData < 0) {
          formattedData.style += 'gradient-high';
        }
        break;

      default:
        break;
    }
    return formattedData;
  }

  private gradientFormatter(columnDataObj): IListColumnData {
    const columnData = has(columnDataObj, 'data') ? columnDataObj.data : null;
    let formattedData: IListColumnData = {
      data: columnData,
      style: 'center ',
    };

    if (columnData === null) {
      formattedData.data = '—';
      formattedData.style = 'disabled center';
    } else if (columnData === 0) {
      formattedData.data = 'Perfect';
    } else {
      const percentPresent = round(columnDataObj.dependencies.pctPres * 100, 2);
      formattedData.style += this.getGradientStyle(percentPresent);
      formattedData.data = 'Missed ' + columnData;
    }
    return formattedData;
  }

  private getGradientStyle(percentage: number): string {
    switch (true) {
      case percentage >= 95:
        return '';

      case percentage >= 91:
        return 'gradient-low';

      case percentage > 80:
        return 'gradient-medium';

      default:
        return 'gradient-high';
    }
  }
}
