import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IStudentAcadienceState } from '../../reducers/student-acadience-reducer/student-acadience-reducer';

const getPortalState = createFeatureSelector('PORTAL_STORE');

export const getStudentAcadienceSlice = createSelector(
  getPortalState,
  (state: any) => state.studentAcadience,
);

export const getStudentAcadienceLoadedStatus = createSelector(
  getStudentAcadienceSlice,
  (state: IStudentAcadienceState) => state.loaded,
);

export const getStudentAcadienceEntities = createSelector(
  getStudentAcadienceSlice,
  (state: IStudentAcadienceState) => {
    return state.studentAcadienceEntities;
  },
);
