import { IModalsConfig } from './../modals.config.interface';

export const BATCH_EDIT_MODAL_CONFIG: IModalsConfig = {
  input: {
    placeholder: 'Find a student field...',
  },
  buttons: {
    confirm: {
      text: 'Edit',
      disableButtonRipple: true,
    },
    cancel: {
      text: 'Cancel',
      disableButtonRipple: true,
    },
  },
};
