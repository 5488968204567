import { WizardFilterActionsModule } from 'Src/ng2/school/sdc/wizard-filter-actions/wizard-filter-actions.module';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { PriorityGroupStep } from './priority-group-step.component';
import { NgModule } from '@angular/core';
import { RegentsPlanningPriorityGroupingsService } from 'Src/ng2/school/sdc/services/regents-planning-priority-groupings/regents-planning-priority-groupings.service';

@NgModule({
  imports: [CommonModule, MatButtonModule, WizardFilterActionsModule],
  exports: [PriorityGroupStep],
  declarations: [PriorityGroupStep],
  providers: [RegentsPlanningPriorityGroupingsService],
})
export class PriorityGroupStepModule { }
