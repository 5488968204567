import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ECFIKManagementService } from 'Src/ng2/school/ecfik-management/ecfik-management.service';
import { IACOption } from 'projects/shared/nvps-libraries/design/nv-textbox/nv-textbox.interface';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ICaringAdultInfo } from '../program-point-modal/program-point-modal.component';

interface IEcfikStudent {
  _id: string;
  firstName: string;
  lastName: string;
  family: {
    languages: string[];
  };
}

@Component({
  selector: 'manage-caseload',
  templateUrl: 'manage-caseload.component.html',
  styleUrls: ['./manage-caseload.component.scss'],
})

export class ManageCaseloadComponent implements OnInit {
  @Input() schoolId: string;
  @Input() caringAdultInfo: ICaringAdultInfo;

  public caseloadForm: FormGroup;
  public studentOptions$: Observable<IACOption[]>;
  public readonly optionsLimit = 20;

  private selectedStudentIds = [];

  constructor (
    private formBuilder: FormBuilder,
    private ecfikService: ECFIKManagementService,
  ) { }

  ngOnInit () {
    // form
    this.caseloadForm = this.getCaseloadForm();
    this.initFormInputs();

    // data
    this.studentOptions$ = this.ecfikService.getEligibleEcfikStudents(this.schoolId, 'UNASSIGNED_OR_IN_CASELOAD', { caringAdultId: this.caringAdultInfo.id }).pipe(
      tap(students => this.setFormInputs(students)),
      map(students => this.getStudentOptions(students)),
    );
  }

  get students (): FormArray {
    return this.caseloadForm.get('students') as FormArray;
  }

  private initFormInputs () {
    let countInputs = 0;
    this.caringAdultInfo.caseload.studentIds?.forEach(
      studentId => {
        this.addFormInput();
        ++countInputs;
      },
    );

    for (let i = countInputs; i < 3; ++i) { // add up to 3 inputs
      this.addFormInput();
    }
  }

  private setFormInputs (students) {
    this.caringAdultInfo.caseload.studentIds?.forEach(
      (studentId, index) => {
        const student = students.find(({ _id }) => _id === studentId);
        const name = student ? `${student.firstName} ${student.lastName}` : '';
        this.students.at(index).setValue(name);
        this.selectedStudentIds[index] = studentId;
      },
    );
  }

  private getCaseloadForm (): FormGroup {
    const students = this.formBuilder.array([]);
    const form = this.formBuilder.group({ students });
    return form;
  }

  private getStudentFormControl (): FormControl {
    const formControl = this.formBuilder.control('', []);
    return formControl;
  }

  private getStudentOptions (students: IEcfikStudent[]): IACOption[] {
    const options = students.map(
      ({ _id, firstName, lastName, family }) => {
        const tags = [];

        if (family?.languages?.length) {
          const languagesSingleString = family.languages.join(', ');
          tags.push({
            key: languagesSingleString,
            human: languagesSingleString,
          });
        }

        const option = {
          key: _id,
          human: `${firstName} ${lastName}`,
          tags,
        };

        return option;
      },
    );

    return options;
  }

  public optionsPredicateCb (option: IACOption, searchValue: string): boolean {
    const sanitizedSearchWordList = searchValue
      ?.trim()
      .toLowerCase()
      .replace(/[^\w]|_/g, ' ')
      .split(/[\s,]+/) || [];

    const { human, tags } = option;
    const tagsAsConcatString = tags?.reduce(
      (acc, { human }) => {
        acc += ` ${human}`;
        return acc;
      }, '',
    );

    const fullPathString = (
      tags
        ? `${human}${tagsAsConcatString}`
        : `${human}`
    ).toLowerCase();

    return sanitizedSearchWordList.every(val => fullPathString.includes(val));
  }

  public addFormInput () {
    const newFormControl = this.getStudentFormControl();
    this.students.push(newFormControl);
    this.selectedStudentIds.push('');
  }

  public onStudentSelect (index, $event) {
    this.students.at(index).setValue($event.human);
    const copySelectedStudentIds = [...this.selectedStudentIds];
    copySelectedStudentIds[index] = $event.key;
    this.selectedStudentIds = copySelectedStudentIds;
  };

  public clearValue (index) {
    this.students.at(index).setValue('');
    const copySelectedStudentIds = [...this.selectedStudentIds];
    copySelectedStudentIds[index] = '';
    this.selectedStudentIds = copySelectedStudentIds;
  };

  public getPayload () {
    const payload = {
      caringAdultId: this.caringAdultInfo.id,
      schoolId: this.schoolId,
      studentIds: this.selectedStudentIds,
    };

    return payload;
  }
}
