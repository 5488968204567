import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import * as _ from 'lodash';

import { GraduationMonth } from 'Src/ng2/shared/constants/graduation-month.constant';
import { GraduationDate } from 'Src/ng2/shared/constants/graduation-date.constant';
import { GraduationPlan } from 'Src/ng2/shared/constants/graduation-plan.constant';

@Component({
  selector: 'credit-gaps-grad-plan-table',
  templateUrl: './credit-gaps-grad-plan-table.component.html',
  styleUrls: ['./styles/credit-gaps-generic-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class CreditGapsGradPlanTable implements OnInit {
  @Input() currentFilter: string;
  @Input() filteredStudents: any;

  public tableTitle: string;
  public table;
  public tableRows;
  public tableSummary;
  public total;
  public rowTotals;
  public colTotals;
  public studentsWithCreditMaxGapsIds: string[];
  public studentsWithCreditMaxGapsNoPlanIds: string[];
  public studentFieldForTableRows: string;
  public uiReady = false;
  public gapCols;
  public labels;
  public zeroHover: boolean;
  public oneHover: boolean;
  public twoHover: boolean;
  public fiveHover: boolean;
  public fivePlusHover: boolean;
  public filterOptions: { columnKeys: string[], sort: { colId: string; direction: string } } = {
    columnKeys: ['gradPlan', 'creditMaxGaps', 'creditMaxGapsNoPlan', 'hasPotentialCreditIssuesOnCurrProgram'],
    sort: {
      colId: 'gradPlan',
      direction: 'desc',
    },
  };

  private hashGraduationDateByMonth: any;

  constructor (

  ) {
    this.tableRows = {
      rowFivePlus: {
        'Jan / Mar': {
          students: [],
        },
        June: {
          students: [],
        },
        Aug: {
          students: [],
        },
        Beyond: {
          students: [],
        },
        'Plan Incomplete': {
          students: [],
        },
        'Non-Graduate': {
          students: [],
        },
      },
      rowTwoFive: {
        'Jan / Mar': {
          students: [],
        },
        June: {
          students: [],
        },
        Aug: {
          students: [],
        },
        Beyond: {
          students: [],
        },
        'Plan Incomplete': {
          students: [],
        },
        'Non-Graduate': {
          students: [],
        },
      },
      rowOneTwo: {
        'Jan / Mar': {
          students: [],
        },
        June: {
          students: [],
        },
        Aug: {
          students: [],
        },
        Beyond: {
          students: [],
        },
        'Plan Incomplete': {
          students: [],
        },
        'Non-Graduate': {
          students: [],
        },
      },
      rowZeroOne: {
        'Jan / Mar': {
          students: [],
        },
        June: {
          students: [],
        },
        Aug: {
          students: [],
        },
        Beyond: {
          students: [],
        },
        'Plan Incomplete': {
          students: [],
        },
        'Non-Graduate': {
          students: [],
        },
      },
      rowZero: {
        'Jan / Mar': {
          students: [],
        },
        June: {
          students: [],
        },
        Aug: {
          students: [],
        },
        Beyond: {
          students: [],
        },
        'Plan Incomplete': {
          students: [],
        },
        'Non-Graduate': {
          students: [],
        },
      },
    };

    this.gapCols = {
      creditMaxGapsNoPlan: 'creditMaxGapsNoPlan',
      creditMaxGaps: 'creditMaxGaps',
    };

    this.labels = {
      unaddressed: 'Students with Unaddressed Credit Gaps ',
      addressed: 'Students with Credit Gaps ',
    };

    this.studentFieldForTableRows = this.gapCols.creditMaxGaps;
  }

  ngOnInit (): void {
    this.hashGraduationDateByMonth = _.reduce(
      GraduationDate,
      (acc, v) => {
        acc[v.humanName] = {
          humanName: v.humanName,
          month: v.month,
          year: v.year,
        };
        return acc;
      },
      {},
    );
    this.setUi();
  }

  ngOnChanges ({ currentFilter, filteredStudents }): void {
    if (!currentFilter.firstChange || !filteredStudents.firstChange) {
      this.setUi();
    }
  }

  orderTableRows = (a, b) => a.key;

  get getLabels () {
    return this.labels;
  }

  get getTableRows () {
    return this.tableRows;
  }

  public setUi (): void {
    this.tableTitle = 'Students with Unaddressed Credit Gaps By Planned Graduation Date';
    this.table = this.generateTable();
    this.tableSummary = this.generateTableSummary();
    this.studentsWithCreditMaxGapsNoPlanIds = this.tableSummary.studentsWithCreditMaxGapsNoPlanIds;
    this.studentsWithCreditMaxGapsIds = this.tableSummary.studentsWithCreditMaxGapsIds;

    this.rowTotals = {
      fivePlus: this.getRowTotal(this.table.rowFivePlus),
      twoFive: this.getRowTotal(this.table.rowTwoFive),
      oneTwo: this.getRowTotal(this.table.rowOneTwo),
      zeroOne: this.getRowTotal(this.table.rowZeroOne),
      zero: this.getRowTotal(this.table.rowZero),
    };

    this.colTotals = {
      totals: this.getTotalOfTotals(),
      totalIsJanOrMar: this.getStudentsForCol('Jan / Mar'),
      totalIsJune: this.getStudentsForCol('June'),
      totalIsAug: this.getStudentsForCol('Aug'),
      totalIsBeyond: this.getStudentsForCol('Beyond'),
      totalIsPlanIncomplete: this.getStudentsForCol('Plan Incomplete'),
      totalIsNonGrad: this.getStudentsForCol('Non-Graduate'),
    };
  }

  private generateTableSummary () {
    const studentsWithCreditMaxGapsIds = [];
    const studentsWithCreditMaxGapsNoPlanIds = [];

    _.each(this.filteredStudents, (student: any) => {
      const { studentId, creditMaxGaps, creditMaxGapsNoPlan } = student;
      if (creditMaxGapsNoPlan < 0) studentsWithCreditMaxGapsNoPlanIds.push(studentId);
      if (creditMaxGaps < 0) studentsWithCreditMaxGapsIds.push(studentId);
    });

    return {
      studentsWithCreditMaxGapsIds,
      studentsWithCreditMaxGapsNoPlanIds,
    };
  }

  public generateTable (): any {
    return _.reduce(
      this.filteredStudents,
      (acc: any, student: any) => {
        let { studentId, creditMaxGapsNoPlan, creditMaxGaps, gradPlan, plannedGraduationDate } = student;

        // IMPORTANT - for column population purposes, plan in past should be overridden to read as Plan Incomplete,
        // as this is the col key available in our accumulator
        if (gradPlan === GraduationPlan.PLAN_IN_PAST.humanName) gradPlan = GraduationPlan.PLAN_INCOMPLETE.humanName;

        let colKey;

        const isIncomplete = gradPlan === GraduationPlan.PLAN_INCOMPLETE.humanName;
        const isNonGrad = gradPlan === GraduationPlan.NON_GRADUATE.humanName;

        if (isIncomplete || isNonGrad) {
          // use gradPlan to determine col which student will be added tp

          if (isIncomplete) colKey = GraduationPlan.PLAN_INCOMPLETE.humanName;
          if (isNonGrad) colKey = GraduationPlan.NON_GRADUATE.humanName;
        } else {
          // use plannedGraduationDate
          const gradDateCategory = this.hashGraduationDateByMonth[plannedGraduationDate];

          const isJanOrMar =
            gradDateCategory.month === GraduationMonth.JAN || gradDateCategory.month === GraduationMonth.MAR;
          const isJune = gradDateCategory.month === GraduationMonth.JUNE;
          const isAug = gradDateCategory.month === GraduationMonth.AUG;
          const isBeyond = gradDateCategory.year > parseInt(this.currentFilter);

          if (isJanOrMar) colKey = 'Jan / Mar'; // can't use constant. GraduationMonth.MAR currently equals `March`
          if (isJune) colKey = GraduationMonth.JUNE;
          if (isAug) colKey = GraduationMonth.AUG;
          if (isBeyond) colKey = 'Beyond';
        }

        // DEFAULTS
        let val = creditMaxGaps;

        /**
         * if a type was received (which would happen on radio button selection),
         * the following happens:
         *
         * 1. Sets value that table rows will use for their calcs.
         * 2. Updates studentFieldForTableRows model, which:
         *    a. sets radio button
         *    b. displays the appropriate counter
        */

        if (this.studentFieldForTableRows === this.gapCols.creditMaxGaps) {
          val = creditMaxGaps;
          this.tableTitle = this.getLabels.addressed + 'By Planned Graduation Date';
        } else {
          this.tableTitle = this.getLabels.unaddressed + 'By Planned Graduation Date';
          val = creditMaxGapsNoPlan;
        }

        // creditMaxGaps / creditMaxGapsNoPlans are saved as negatives.
        val = Math.abs(val);

        const moreThanFive = val >= 5.01;
        const twoToFive = val >= 2.01 && val <= 5;
        const oneToTwo = val >= 1.01 && val <= 2;
        const zeroToOne = val > 0.01 && val <= 1;
        const zero = val === 0;

        if (moreThanFive) {
          acc.rowFivePlus[colKey].students.push(studentId);
        } else if (twoToFive) {
          acc.rowTwoFive[colKey].students.push(studentId);
        } else if (oneToTwo) {
          acc.rowOneTwo[colKey].students.push(studentId);
        } else if (zeroToOne) {
          acc.rowZeroOne[colKey].students.push(studentId);
        } else if (zero) {
          acc.rowZero[colKey].students.push(studentId);
        }
        return acc;
      },
      _.cloneDeep(this.tableRows),
    );
  }

  public getCurrentCohort (currentSdcFilter) {
    return _.last(currentSdcFilter);
  }

  private getStudentsForCol (col) {
    const studentsForCol = this.table.rowFivePlus[col].students.concat(
      this.table.rowTwoFive[col].students,
      this.table.rowOneTwo[col].students,
      this.table.rowZeroOne[col].students,
      this.table.rowZero[col].students,
    );

    return studentsForCol;
  }

  private getRowTotal (row) {
    const total = [];
    _.each(row, (cell: any) => {
      if (cell.students) {
        _.each(cell.students, st => {
          total.push(st);
          return total;
        });
      }
    });
    return total;
  }

  private getTotalOfTotals () {
    const studentsForCol = this.rowTotals.fivePlus.concat(
      this.rowTotals.twoFive,
      this.rowTotals.oneTwo,
      this.rowTotals.zeroOne,
      this.rowTotals.zero,
    );

    return studentsForCol;
  }

  // toggle actions
  public toggleTable () {
    this.setUi();
    this.uiReady = true;
  }
}
