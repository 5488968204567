import { ITilePayload } from '../../actions/tile-actions/tile-actions';

export const generateQuery = (schoolId: ITilePayload) => {
  return `{
    Tile(schoolId: "${schoolId}"){
        tileName
        focusName
        graphQlKeyFocus
        graphQlKeyNumerator
        defaultGraphQlKeyFilter
        defaultGraphQlKeyGrouping
        data {
          top
          bottom
        }
        description
        category
        tileType
        schoolType
        studentTypeLabel
        dataType
        categoryOrder
        tileOrder
        graphQlKey
        tileKey
        goesToOldMidlevel
    }
  }
  `;
};

export const TILE_JOINS = [];
