import { ICoursePlansModalComponentData } from './../../../modals/course-plans/course-plans-modal-shell.component';
import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ImSchool } from '../../../services/im-models/im-school';
import { ICourse, ISchool, ICoursePartial, TCreditSubjAreas } from '../../../typings/interfaces/school.interface';
import { UtilitiesService } from '../../../services/utilities/utilities.service';
import { Observable } from 'rxjs';
import { RadioButtonRendererComponent } from '../../server-side-grid/radio-button-renderer/radio-button-renderer.component';
import { Module, GridOptions, ColDef, ColumnApi, GridApi, AgGridEvent } from '@ag-grid-community/core';
import { tap, map } from 'rxjs/operators';
import { cloneDeep, defaults, filter as _filter, find, includes, reduce, sortBy, uniq } from 'lodash';
import { ImCourseDiff } from 'Src/ng2/shared/services/im-models/im-course-diff';
import { ImGapPlan } from 'Src/ng2/shared/services/im-models/im-gap-plan';
import { ICourseDiff } from '../../../typings/interfaces/course-diff.interface';
import { IGapPlan } from '../../../typings/interfaces/gap-plan.interface';
import { IStudent } from '../../../typings/interfaces/student.interface';
import { ImStudentCreditGaps } from '../../../services/im-models/im-student-credit-gaps/im-student-credit-gaps';
import { ImStudentCurrentProgramHelpers } from 'Src/ng2/shared/services/im-models/im-student-credit-gaps-helpers/im-student-current-program-helpers';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ICourseForTable, ITermYear, IUserFilter } from '../../find-a-course-table-component/find-a-course-table.component';
import { ModalsService } from 'Src/ng2/shared/modals/modals.service';
import { ISubjectArea, SubjectAreas, TValidSubjHuman } from 'Src/ng2/shared/constants/subject-areas.constant';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NoRowsComponent } from '../../server-side-grid/no-rows/no-rows.component';
import { BaseModalComponent } from 'Src/ng2/shared/modals/base-modal.component';
import { ApiService } from 'Src/ng2/shared/services/api-service/api-service';
import { IRowData } from 'Src/ng2/shared/models/list-models';

type TRowType = 'ON_PROGRAM' | 'ON_CODEDECK';

export interface IDropdownLabel {
  subject: string;
  period: string;
  termYear: string;
}

interface INewPlan {
  courseDiff?: Partial<ICourseDiff>;
  gapPlan?: Partial<IGapPlan>;
}
@Component({
  selector: 'scheduled-course-plan',
  templateUrl: './scheduled-course-plan.component.html',
  styleUrls: ['./scheduled-course-plan.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ScheduledCoursePlanComponent extends BaseModalComponent implements OnInit {
  // Bindings
  public termYear: number;
  public termYearHuman: string;
  public termType: 'FUTURE' | 'CURRENT';
  @Input() termNumber: number;
  @Input() courses: ICoursePartial[];
  @Input() studentCourseDiffs: ICourseDiff[] = [];
  @Input() studentGapPlans: IGapPlan[] = [];
  @Input() schoolCourseDiffs: ICourseDiff[] = [];
  @Input() schoolGapPlans: IGapPlan[] = [];
  @Input() student: IStudent;
  @Input() initialFilter: IUserFilter;
  @Output() newCourseDiff: EventEmitter<any> = new EventEmitter();
  @Output() newGapPlan: EventEmitter<any> = new EventEmitter();
  @Output() sendSelectedRow: EventEmitter<any> = new EventEmitter();
  public school: ISchool;
  public studentIds: string[];
  public schoolId: string;

  // For view
  public userFilter: IUserFilter = {
    subjectArea: 'elaCore',
    period: '',
    courseCodeWithSection: '',
  };

  public courseForm: FormControl;
  public courseFilterOptions$: Observable<Array<Partial<ICourse>>>;
  public userInputCourseCodeWithSection: string;
  public isButtonDisabled: boolean = true;

  public filteredCourses: ICourseForTable[];
  public subjectsMenu: ISubjectArea[];
  public periodsMenu;
  public courseCodesMenu = [];
  public allTermYears: ITermYear[];

  private mappedCourses: ICourseForTable[];
  private district: string;
  public gridOptions: GridOptions;
  public columnDefs: ColDef[];
  public rowData: IRowData[];
  public selectedRow: ICourseForTable[];
  private gridApi: GridApi;
  public gridColumnApi: ColumnApi;
  public modules: Array<Module> = [ClientSideRowModelModule];
  public filterFormControl: FormControl;
  public initialDropdownLabel: IDropdownLabel;

  constructor (
    private utils: UtilitiesService,
    private ImSchool: ImSchool,
    public imGapPlan: ImGapPlan,
    private imCourseDiff: ImCourseDiff,
    public ImStudentCreditGaps: ImStudentCreditGaps,
    public modalsService: ModalsService,
    public apiService: ApiService,
    public dialogRef: MatDialogRef<ScheduledCoursePlanComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ICoursePlansModalComponentData,
  ) {
    super(dialogRef);
  }

  ngOnInit () {
    const { school, school: { district }, studentIds } = cloneDeep(this.data);
    this.studentIds = studentIds;
    this.school = school;
    this.district = district;
    this.termType = 'CURRENT';
    this.courseForm = new FormControl(this.initialFilter?.courseCodeWithSection || '');
    this.termYear = this.school.currentTermYear;
    this.allTermYears = this.buildTermYears(this.school, 12);
    this.termYearHuman = this.allTermYears[0].human;

    // Set default filters
    this.userFilter = this.assignFilter(this.initialFilter);

    this.initialDropdownLabel = {
      subject: 'ELA Core',
      period: 'Period',
      termYear: this.termYearHuman,
    };

    // Set courses;
    this.setCourses(this.termYear);

    this.courseForm.valueChanges.pipe(
      tap((courseCodeWithSection) => {
        this.userInputCourseCodeWithSection = courseCodeWithSection;
        this.setFilteredCourses({ ...this.userFilter, courseCodeWithSection });
      }),
      map((text) => this.filterCourseMenu(this.filteredCourses, text)),
    ).subscribe();

    this.gridOptions = {
      context: {
        parentComponent: this,
      },
      suppressContextMenu: true,
      defaultColDef: {
        width: 90,
        resizable: false,
      },
      columnDefs: [
        {
          headerName: 'Select Plan',
          field: 'selected',
          cellRenderer: 'radioButtonRenderer',
          cellRendererParams: {
            rowSelected: (rowData: any) => this.handleRowSelected(rowData),
          },
        },
        {
          headerName: 'Course',
          field: 'subject',
          cellClass: 'wrap-long-text-left-aligned',
          width: 120,
        },
        {
          headerName: 'Course Code',
          field: 'courseCodeWithSection',
          cellClass: 'wrap-long-text',
          width: 120,
        },
        { headerName: 'Period', field: 'period', width: 60 },
        { headerName: 'Cycle', field: 'cycleDay', width: 100 },
        { headerName: 'Enrollment', field: 'enrollment' },
        { headerName: 'Pending', field: 'pendingEnrollment', width: 60 },
        { headerName: 'Credits', field: 'creditValue', width: 60 },
      ],
      rowData: this.filteredCourses,
      rowClass: 'center-rows-under-column',
      frameworkComponents: {
        radioButtonRenderer: RadioButtonRendererComponent,
        noRowsComponent: NoRowsComponent,
      },
      noRowsOverlayComponent: 'noRowsComponent',
      noRowsOverlayComponentParams: {
        parentComponent: this,
        parentName: 'scheduled-course-plan',
      },
      isExternalFilterPresent: () => true,
      doesExternalFilterPass: (node) => {
        const { subjectArea, period, type, courseCodeWithSection } = node.data;

        // define the overall term filter
        const currentTermSelected = this.termType === 'CURRENT';
        const futureTermSelected = type === 'ON_CODEDECK';

        // define the three additional search filters
        const { subjectArea: userFilterSubjectArea, period: userFilterPeriod } = this.userFilter;
        const userFilterCourseCodeInput = this.userInputCourseCodeWithSection?.length;

        const isMatchingSubjectArea = subjectArea === userFilterSubjectArea;
        const isMatchingPeriod = period === userFilterPeriod;
        const isMatchingCourseCodeInput = courseCodeWithSection.includes(this.userInputCourseCodeWithSection?.toUpperCase());

        const searchingUsingAllFilters = userFilterSubjectArea && userFilterPeriod && userFilterCourseCodeInput;
        const searchingUsingMatchingSubjectAreaAndPeriod = userFilterSubjectArea && userFilterPeriod;
        const searchingUsingMatchingSubjectAreaAndCourseCodeInput = userFilterSubjectArea && userFilterCourseCodeInput;
        const searchingUsingMatchingPeriodAndCourseCodeInput = userFilterPeriod && userFilterCourseCodeInput;

        // logic
        if (searchingUsingAllFilters) return isMatchingSubjectArea && isMatchingPeriod && isMatchingCourseCodeInput;

        if (currentTermSelected) {
          if (searchingUsingMatchingSubjectAreaAndPeriod) return isMatchingSubjectArea && isMatchingPeriod;
          else if (searchingUsingMatchingSubjectAreaAndCourseCodeInput) return isMatchingSubjectArea && isMatchingCourseCodeInput;
          else if (searchingUsingMatchingPeriodAndCourseCodeInput) return isMatchingPeriod && isMatchingCourseCodeInput;
          else if (userFilterSubjectArea) return isMatchingSubjectArea;
          else if (userFilterPeriod) return isMatchingPeriod;
          else if (userFilterCourseCodeInput) return isMatchingCourseCodeInput;
        } else {
          if (futureTermSelected) {
            if (userFilterSubjectArea && userFilterPeriod) return isMatchingSubjectArea && isMatchingPeriod;
            else if (searchingUsingMatchingSubjectAreaAndCourseCodeInput) return isMatchingSubjectArea && isMatchingCourseCodeInput;
            else if (searchingUsingMatchingPeriodAndCourseCodeInput) return isMatchingPeriod && isMatchingCourseCodeInput;
            else if (userFilterSubjectArea) return isMatchingSubjectArea;
            else if (userFilterPeriod) return isMatchingPeriod;
            else if (userFilterCourseCodeInput) return isMatchingCourseCodeInput;
          }
        }
        return false;
      },
    };
  }

  public onGridReady (params: AgGridEvent): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  public handleRowSelected (rowData: any): void {
    this.isButtonDisabled = false;
    this.selectedRow = [rowData];
    this.sendSelectedRow.emit(this.selectedRow);
  }

  public onClear () {
    this.courseForm.setValue('');
  }

  public close (): void {
    super.close();
  }

  public onApply () {
    const [{ type }] = this.selectedRow;
    let newPlan: INewPlan;
    if (type === 'ON_PROGRAM') {
      newPlan = this.addCourseDiff(this.selectedRow[0]);
    } else {
      newPlan = this.addGapPlan(this.selectedRow[0]);
    }
    super.close(newPlan);
  }

  setCourses (termYear: number) {
    // Get termNumber of selected termYear
    const termYearAsString = termYear.toString();
    const termNumber = parseInt(termYearAsString.substring(termYearAsString.length - 1, termYearAsString.length));

    // Get courses for termNumber
    this.courses = this.ImSchool.getCombinedMasterProgramCodeDeck(this.school, termNumber);
    // Format courses for termNumber
    this.mappedCourses = this.getCoursesMappedToTable(this.courses);
    // Filter courses for termNumber
    this.filteredCourses = this.filterTableCourses(this.mappedCourses, this.userFilter, this.termType);
    // Build menus
    this.subjectsMenu = this.buildSubjectsMenu(this.courses);
    this.periodsMenu = this.buildPeriodsMenu(this.courses);
  }

  assignFilter (initialFilter: any) {
    if (!initialFilter) return this.userFilter;
    return defaults(initialFilter, this.userFilter);
  }

  // Build an array of term years
  buildTermYears (school, numTermYears: number): ITermYear[] {
    let counter = 0;

    const human = this.utils.getHumanReadableTerm(this.termYear);
    const termYear = this.termYear;
    const key = this.termYear;

    const allTermYears = [
      {
        key,
        human,
        termYear,
      },
    ];

    let nextTermYear = this.termYear;

    // -1 because we already loaded the first item above
    const quitCount = numTermYears - 1;
    while (counter < quitCount) {
      nextTermYear = this.ImSchool.getNextTermYear(school, nextTermYear);
      const human = this.utils.getHumanReadableTerm(nextTermYear);
      allTermYears.push({
        key: nextTermYear,
        human,
        termYear: nextTermYear,
      });
      counter++;
    }
    return allTermYears;
  }

  buildSubjectsMenu (courses: ICoursePartial[]): ISubjectArea[] {
    const allSubjects = courses?.map(course => course.subjectArea);
    const uniqed = uniq(allSubjects);
    const cleaned = reduce(
      uniqed,
      (result, uniqueSubject: TCreditSubjAreas) => {
        const subjectArea = find(SubjectAreas, { camelCase: uniqueSubject });
        if (subjectArea) result.push(subjectArea);
        return result;
      },
      [],
    );
    const sorted = sortBy(cleaned);
    return sorted;
  }

  buildPeriodsMenu (courses: ICoursePartial[]) {
    const allPeriods = courses?.map(course => course.period);
    const uniqed = uniq(allPeriods);
    const cleaned = _filter(uniqed, period => {
      return period !== null && period !== undefined;
    });
    // fix sort for double-periods or periods > 9 (JE)
    const sorted = sortBy(cleaned, (period: string) => {
      return parseInt(period);
    });
    return [{ key: '', human: 'All' }, ...sorted.map((item) => {
      return { key: item, human: item.toString() };
    })];
  }

  // Convert initial ICourse objects to ICourseForTable objs
  getCoursesMappedToTable (courses: ICoursePartial[]): ICourseForTable[] {
    const mappedCourses = courses?.map(course => {
      const type = this.getRowType(course);
      const isOnProgram = type === 'ON_PROGRAM';
      const subject = this.getSubjectNameForCourse(course);
      const pendingEnrollment = isOnProgram
        ? this.calculatePendingEnrollmentForCourse(course.courseId, course.enrollment, this.schoolCourseDiffs)
        : undefined;
      const courseCodeWithSection = this.getCourseCodeWithSection(course);

      const extender = {
        subject,
        pendingEnrollment,
        courseCodeWithSection,
        type,
        selected: false,
      };

      const courseForTable = Object.assign(course, extender);
      return courseForTable;
    });

    return mappedCourses;
  }

  getRowType (course: ICoursePartial): TRowType {
    const hasSection = !!course.section;
    return hasSection ? 'ON_PROGRAM' : 'ON_CODEDECK';
  }

  getCourseCodeWithSection (course: ICoursePartial): string {
    const { courseCode, section } = course;
    return section ? `${courseCode}-${section}` : courseCode;
  }

  getSubjectNameForCourse (course: ICoursePartial): TValidSubjHuman | null {
    return ImStudentCurrentProgramHelpers.getHumanReadableSubjectAreaForCourse(course, this.district);
  }

  // Set a default filter on subjectArea. We never show "All" because
  // it takes too long to render (JC)
  filterTableCourses (mappedCourses: ICourseForTable[], userFilter: IUserFilter, termType: string): ICourseForTable[] {
    const { subjectArea, period, courseCodeWithSection } = userFilter;

    // There will always be a subjectArea, other values may be empty ''
    const filter: any = {
      subjectArea,
    };

    // NEXT and FUTURE term types should only include official code deck
    if (termType === 'FUTURE') filter.type = 'ON_CODEDECK';

    // Old comment by JC said this needs to be an integer, but the course objects have period as a string (JE)
    if (period) filter.period = period;

    let filteredCourses = _filter(mappedCourses, filter);

    // Further filter for partial course code via course code auto-complete
    if (courseCodeWithSection) {
      filteredCourses = this.getCoursePickerFilter(filteredCourses, courseCodeWithSection);
    }

    return filteredCourses;
  }

  getCoursePickerFilter (filteredCourses: ICourseForTable[], courseCodeWithSection: string): ICourseForTable[] {
    return _filter(filteredCourses, course => {
      return includes(course.courseCodeWithSection.toLowerCase(), courseCodeWithSection.toLowerCase());
    });
  }

  public changeDropdownEventToUserFilter (dropdownOption: string, event: string) {
    let updatedEvent: IUserFilter;
    if (dropdownOption === 'subjectArea') {
      const subjectToUpdate: string = this.subjectsMenu.filter((menuItem) => menuItem.key === event)[0].camelCase;
      updatedEvent = { ...this.userFilter, subjectArea: subjectToUpdate };
    } else if (dropdownOption === 'period') {
      updatedEvent = { ...this.userFilter, period: event };
    }
    this.setFilteredCourses(updatedEvent);
  }

  public setFilteredCourses (updatedUserFilter: IUserFilter) {
    this.userFilter = updatedUserFilter;
    this.filteredCourses = this.filterTableCourses(this.mappedCourses, updatedUserFilter, this.termType);
    this.gridOptions.api.setRowData(this.filteredCourses);
    this.gridApi.onFilterChanged();
    if (this.filteredCourses.length === 0) {
      this.gridOptions.api.showNoRowsOverlay();
    } else {
      this.gridOptions.api.hideOverlay();
    }
  }

  public setTermType ($event: number) {
    if ($event === this.school.currentTermYear) {
      this.termType = 'CURRENT';
    } else {
      this.termType = 'FUTURE';
    }
    this.termYear = $event;
    const termYearAsString = $event.toString();
    const termNumber = parseInt(termYearAsString.substring(termYearAsString.length - 1, termYearAsString.length));
    // Get courses for termNumber
    this.courses = this.ImSchool.getCombinedMasterProgramCodeDeck(this.school, termNumber);
    // Format courses for termNumber
    this.mappedCourses = this.getCoursesMappedToTable(this.courses);
    this.setFilteredCourses({ ...this.userFilter });
  }

  // Used by the md-autocomplete component to further filter
  // course options prior to course selection
  filterCourseMenu (filteredCourses: ICourseForTable[], courseCodeWithSection): ICourseForTable[] {
    const filteredList = this.getCoursePickerFilter(filteredCourses, courseCodeWithSection);
    return filteredList;
  }

  calculatePendingEnrollmentForCourse (courseId, currentEnrollment: number, courseDiffs: ICourseDiff[] = []): number {
    // Count up the matching courseDiffs
    const courseDiffCount = courseDiffs.reduce((count, courseDiff) => {
      if (courseDiff.courseId === courseId) {
        if (courseDiff.action === 'ADD') count++;
        if (courseDiff.action === 'DROP') count--;
      }
      return count;
    }, 0);

    return currentEnrollment + courseDiffCount;
  }

  addGapPlan (course: ICourseForTable): INewPlan {
    const gapPlan: Partial<IGapPlan> = this.imGapPlan.createNew(null, this.school._id);
    gapPlan.termYear = this.termYear;
    gapPlan.plan = course.courseCode;
    gapPlan.gradReq = course.gradReq;
    gapPlan.creditValue = course.creditValue;

    this.createGapPlan(gapPlan);
    return { gapPlan };
  }

  addCourseDiff (course: ICourseForTable): INewPlan {
    const template = this.imCourseDiff.getTemplate();
    const diff: Partial<ICourseDiff> = template;
    diff._id = this.utils.createV4Uuid();
    diff.termYear = this.termYear;
    diff.schoolId = this.school._id;
    diff.courseId = course.courseId;

    this.createCourseDiff(diff);
    return { courseDiff: diff };
  }

  // Emits back to student component for student profile updates
  createGapPlan (gapPlan) {
    this.studentGapPlans.push(gapPlan);
    this.newGapPlan.emit(gapPlan);
  }

  // Emits back to student component for student profile updates
  createCourseDiff (diff): void {
    this.studentCourseDiffs.push(diff);
    this.schoolCourseDiffs.push(diff);
    this.newCourseDiff.emit(diff);
  }
}
