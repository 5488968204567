import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { reduce } from 'lodash';
import { WizardHelpers } from 'Src/ng2/school/sdc/services/wizard-helpers.service';
import { GraduationPlan } from 'Src/ng2/shared/constants/graduation-plan.constant';
import { ObjectCache } from 'Src/ng2/shared/services/object-cache/object-cache.service';
import { UrlPathService } from 'Src/ng2/shared/services/url-path-service/url-path.service';

export interface IStudent {
  studentId: string;
  gradPlan: string;
  cohort: string;
  status: string;
  isSuperSenior: boolean;
}
@Component({
  selector: 'grad-plans-step',
  templateUrl: './grad-planning-step-component.html',
  encapsulation: ViewEncapsulation.None,
})

export class GradPlanningStep implements OnInit {
    @Input() filteredStudents: IStudent[];

    public sort: string;
    public plannedInPastOrIncomplete: string[];
    public stepLabel: string = 'Complete graduation planning for all students';
    public selectedGradDate: string | null;
    public filteredStudentsIds: string[] = [];
    public options = {
      columnKeys: ['gradPlan', 'creditMaxGaps', 'creditMaxGapsNoPlan'],
    };
    
    constructor (
      private activatedRoute: ActivatedRoute,
      private objectCache: ObjectCache,
      private wizardHelpers: WizardHelpers,
      private urlPathService: UrlPathService,
    ) { }

    ngOnInit (): void {
      this.setUi();
    }

    ngOnChanges ({ filteredStudents }): void {
      if (!filteredStudents.firstChange) {
        this.setUi();
      }
    }

    setUi (): void {
      this.plannedInPastOrIncomplete = this.getStudentIdsByGradPlan();
      this.sort = this.objectCache.cacheObject([{ colId: 'studentName', sort: 'asc' }]);
      this.filteredStudentsIds = this.filteredStudents.map(student => student.studentId);
    }
 
    public navigateToGrid (): void {
      const { schoolId } = this.activatedRoute.snapshot.params;
      const url = this.urlPathService.computeDistrictUrlPath(`/school/${schoolId}/${this.activatedRoute.snapshot.data.sdcKey}/grid`);
      this.wizardHelpers.navigateToView({ url, cacheData: { studentId: this.plannedInPastOrIncomplete } });
    }

    private getStudentIdsByGradPlan (): string[] {
      const planIncompleteFilter: string = GraduationPlan.PLAN_INCOMPLETE.humanName;
      const planInPastFilter: string = GraduationPlan.PLAN_IN_PAST.humanName;
      return reduce(
        this.filteredStudents,
        (result, student: any) => {
          const { gradPlan, studentId } = student;
          const includeInFilter = gradPlan === planIncompleteFilter || gradPlan === planInPastFilter;
          if (includeInFilter) result.push(studentId);
          return result;
        },
        [],
      );
    }
}
