import { NvVizTileModule } from './nv-category-container-items/nv-viz-tile/nv-viz-tile.module';
import { NvTextboxVirtualScrollModule } from './nv-textbox-virtual-scroll/nv-textbox-virtual-scroll.module';
import { NvDropdownMenuModule } from './nv-dropdown-menu/nv-dropdown-menu.module';
import { NvTimeRangePickerModule } from './nv-time-range-picker/nv-time-range-picker.module';
import { NvTimePickerModule } from './nv-time-picker/nv-time-picker.module';
import { NgModule } from '@angular/core';
// NV Modules
import { NvButtonModule } from 'projects/shared/nvps-libraries/design/nv-button/nv-button.module';
import { NvDatePickerModule } from './nv-date-picker/nv-date-picker.module';
import { NvDateRangePickerModule } from './nv-date-range-picker/nv-date-range-picker.module';
import { NvDropdownItemModule } from './nv-dropdown-item/nv-dropdown-item.module';
import { NvDropdownModule } from './nv-dropdown/nv-dropdown.module';
import { NvFormFieldModule } from './nv-form-field/nv-form-field.module';
import { NvIconButtonModule } from './nv-icon-button/nv-icon-button.module';
import { NvIconModule } from 'projects/shared/nvps-libraries/design/nv-icon/nv-icon.module';
import { NvLogoModule } from './nv-logo/nv-logo.module';
import { NvMultipickerModule } from './nv-multi-picker/nv-multi-picker.module';
import { NvNoteModule } from '../../../projects/shared/nvps-libraries/design/nv-note/nv-note.module';
import { NvNumboxModule } from './nv-numbox/nv-numbox.module';
import { NvPillButtonModule } from './nv-pill-button/nv-pill-button.module';
import { NvPillModule } from './nv-pill/nv-pill.module';
import { NvSegmentedControlModule } from './nv-segmented-control/nv-segmented-control.module';
import { NvSidebarItemModule } from './nv-sidebar-item/nv-sidebar-item.module';
import { NvSidebarListModule } from './nv-sidebar-list/nv-sidebar-list.module';
import { NvSidebarListResponsiveModule } from './nv-sidebar-list-responsive/nv-sidebar-list-responsive.module';
import { NvTextboxModule } from './nv-textbox/nv-textbox.module';
import { NvToastModule } from './nv-toast/nv-toast.module';
import { NvDashboardTileModule } from './nv-dashboard-tile/nv-dashboard-tile.module';
import { NvPerformanceCardModule } from './nv-performance-card/nv-performance-card.module';
import { NvNavigationTabTileModule } from './nv-navigation-tab-tile/nv-navigation-tab-tile.module';
import { NvCardModule } from './nv-card/nv-card.module';
import { NvPillV4Module } from './nv-pill-v4/nv-pill-v4.module';
import { NvPillButtonV4Module } from './nv-pill-button-v4/nv-pill-button-v4.module';
import { NvDropdownPillModule } from './nv-dropdown-pill/nv-dropdown-pill.module';
import { NvBoxModule } from './nv-box/nv-box.module';
import { NvBoxButtonModule } from './nv-box-button/nv-box-button.module';
import { NvSquareButtonModule } from './nv-square-button/nv-square-button.module';
import { NvDropdownBoxModule } from './nv-dropdown-box/nv-dropdown-box.module';
import { NvCategoryContainerModule } from './nv-category-container/nv-category-container.module';
import { NvBasicInfoTileModule } from './nv-category-container-items/nv-basic-info-tile/nv-basic-info-tile.module';
import { NvPrimaryFocusTileModule } from './nv-category-container-items/nv-primary-focus-tile/nv-primary-focus-tile.module';
import { NvSecondaryFocusTileModule } from './nv-category-container-items/nv-secondary-focus-tile/nv-secondary-focus-tile.module';
import { NvActionTileModule } from './nv-category-container-items/nv-action-tile/nv-action-tile.module';
import { NvRadialGraphModule } from './nv-radial-graph/nv-radial-graph.module';
import { NvSnapshotCardModule } from './nv-snapshot-card/nv-snapshot-card.module';
import { NvMultiSelectFilterSectionModule } from './nv-multi-select-filter-section/nv-multi-select-filter-section.module';
import { NvProfilePanelContainerModule } from './nv-profile-panel-container/nv-profile-panel-container.module';

const modulesArray = [
  NvButtonModule,
  NvDatePickerModule,
  NvDateRangePickerModule,
  NvDropdownMenuModule,
  NvDropdownModule,
  NvFormFieldModule,
  NvDropdownItemModule,
  NvIconButtonModule,
  NvIconModule,
  NvLogoModule,
  NvMultipickerModule,
  NvNoteModule,
  NvNumboxModule,
  NvPillButtonModule,
  NvPillModule,
  NvSegmentedControlModule,
  NvSidebarItemModule,
  NvSidebarListModule,
  NvSidebarListResponsiveModule,
  NvTextboxModule,
  NvToastModule,
  NvTimePickerModule,
  NvTimeRangePickerModule,
  NvTextboxVirtualScrollModule,
  NvDashboardTileModule,
  NvPerformanceCardModule,
  NvNavigationTabTileModule,
  NvCardModule,
  NvPillV4Module,
  NvPillButtonV4Module,
  NvDropdownPillModule,
  NvBoxModule,
  NvBoxButtonModule,
  NvDropdownBoxModule,
  NvActionTileModule,
  NvSecondaryFocusTileModule,
  NvPrimaryFocusTileModule,
  NvBasicInfoTileModule,
  NvCategoryContainerModule,
  NvVizTileModule,
  NvRadialGraphModule,
  NvSnapshotCardModule,
  NvMultiSelectFilterSectionModule,
  NvSquareButtonModule,
  NvProfilePanelContainerModule,
];

@NgModule({
  imports: [...modulesArray],
  exports: [...modulesArray],
})
export class NvDesignLibraryModule {}
