import { ICellRendererParams } from '@ag-grid-community/core';
import { Component, ViewEncapsulation } from '@angular/core';
import { TASK_NOTES_ACTIVITY_TOGGLE_INDEX } from '../../tasks-notes-activity-table/tasks-notes-activity-table.component';
import { ModalsService } from 'Src/ng2/shared/modals/modals.service';
import { IHistoryModalData, THistoryLogsComponent } from 'Src/ng2/shared/modals/history-all/history-all-modal.component';
import { PartnerTypes } from 'Src/ng2/shared/typings/interfaces/partner.interface';

@Component({
  selector: 'status-cell-renderer',
  templateUrl: './status-cell-renderer.component.html',
  styleUrls: ['./status-cell-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class StatusCellRenderer {
  protected params: ICellRendererParams;
  public displayValue: string;
  public cellClass: string;

  constructor (
    private modalsService: ModalsService,
  ) {}

  agInit (params: any): void {
    this.params = params;
    if (this.params) {
      this._valueFormatter(params.value);
    }
  }

  public refresh (params: ICellRendererParams): boolean {
    return false;
  }

  public openModal (): void {
    // DATA-GRID data
    const { contextPartnerType, contextPartnerId, school, currentUser } = this.params.context.parentComponent;
    const columnKey = this.params.colDef.field;

    const columnModalData = columnKey === 'TASKS_TO_DO' ? this.getTaskColumnModalData() : this.getNoteColumnModalData(columnKey);

    if (contextPartnerType === PartnerTypes.SHELTER) {
      const historyModalData: IHistoryModalData = {
        studentData: this.params.data,
        shelterId: contextPartnerId,
        listType: 'SHELTER_DATA_GRID',
        ...columnModalData,
      };
      this.modalsService.openHistoryModal(historyModalData).afterClosed();
    } else {
      const listType: THistoryLogsComponent = 'SCHOOL_DATA_GRID';
      // Row Data for the student: OSIS_NUMBER, STUDENT_NAME
      const studentData = this.params.data;
      const tasksNotesActivityModalData = {
        partnerType: contextPartnerType,
        listType,
        currentSchool: school,
        studentData,
        currentUser,
        calcValueField: columnKey,
        ...columnModalData,
      };

      this.modalsService
        .openTasksNotesActivityModal(tasksNotesActivityModalData)
        .afterClosed();
    }
  }

  private getTaskColumnModalData () {
    return {
      segmentedControlIndex: TASK_NOTES_ACTIVITY_TOGGLE_INDEX.TASKS,
      pageSize: null,
    };
  }

  private getNoteColumnModalData (columnKey: string) {
    const noteColumnModalData = {
      segmentedControlIndex: TASK_NOTES_ACTIVITY_TOGGLE_INDEX.NOTES,
      defaultUserSelectedFilters: null,
    };

    if (this.isTimeBoundShelterNoteColumnKey(columnKey)) {
      noteColumnModalData.defaultUserSelectedFilters = this.getDateFilterByColumnKey(columnKey);
    }

    if (this.isShelterAttendanceColumnKey(columnKey)) {
      noteColumnModalData.defaultUserSelectedFilters = {
        ...noteColumnModalData.defaultUserSelectedFilters,
        categories: ['Attendance'],
      };
    }
    return noteColumnModalData;
  }

  private getDateFilterByColumnKey (columnKey: string) {
    const pastDate = new Date();
    const columnKeyToDays = {
      COUNT_OF_ACTIVE_NOTES_LAST_5_DAYS_SHELTER: 7,
      COUNT_OF_ACTIVE_NOTES_LAST_10_DAYS_SHELTER: 14,
      COUNT_OF_ACTIVE_NOTES_LAST_20_DAYS_SHELTER: 28,
      COUNT_OF_ACTIVE_ATTENDANCE_NOTES_LAST_5_DAYS_SHELTER: 7,
      COUNT_OF_ACTIVE_ATTENDANCE_NOTES_LAST_10_DAYS_SHELTER: 14,
      COUNT_OF_ACTIVE_ATTENDANCE_NOTES_LAST_20_DAYS_SHELTER: 28,
    };
    pastDate.setUTCDate(pastDate.getUTCDate() - columnKeyToDays[columnKey]);
    pastDate.setUTCHours(0, 0, 0, 0);
    return {
      startDate: pastDate.toISOString(),
      endDate: new Date().toISOString(),
    };
  }

  private isShelterAttendanceColumnKey (columnKey: string) {
    const columnKeys = [
      'COUNT_OF_ACTIVE_ATTENDANCE_NOTES_SHELTER',
      'COUNT_OF_ACTIVE_ATTENDANCE_NOTES_LAST_5_DAYS_SHELTER',
      'COUNT_OF_ACTIVE_ATTENDANCE_NOTES_LAST_10_DAYS_SHELTER',
      'COUNT_OF_ACTIVE_ATTENDANCE_NOTES_LAST_20_DAYS_SHELTER',
    ];
    return columnKeys.includes(columnKey);
  }

  private isTimeBoundShelterNoteColumnKey (columnKey: string) {
    const columnKeys = [
      'COUNT_OF_ACTIVE_NOTES_LAST_5_DAYS_SHELTER',
      'COUNT_OF_ACTIVE_NOTES_LAST_10_DAYS_SHELTER',
      'COUNT_OF_ACTIVE_NOTES_LAST_20_DAYS_SHELTER',
      'COUNT_OF_ACTIVE_ATTENDANCE_NOTES_LAST_5_DAYS_SHELTER',
      'COUNT_OF_ACTIVE_ATTENDANCE_NOTES_LAST_10_DAYS_SHELTER',
      'COUNT_OF_ACTIVE_ATTENDANCE_NOTES_LAST_20_DAYS_SHELTER',
    ];
    return columnKeys.includes(columnKey);
  }

  private _valueFormatter (value: number): void {
    if (value === 0) {
      this.displayValue = '0';
      this.cellClass = 'resolved';
    } else if (value > 99) {
      this.displayValue = '99+';
      this.cellClass = 'unresolved';
    } else {
      this.displayValue = (value)?.toString();
      this.cellClass = 'unresolved';
    }
  }
}
