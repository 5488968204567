import { createFeatureSelector, createSelector } from '@ngrx/store';
import { map } from 'lodash';
import { IStudentAssessmentsState } from '../../reducers/assessments-reducer/student-assessments-reducer';

const getPortalState = createFeatureSelector('PORTAL_STORE');

export const getStudentAssessmentsState = createSelector(
  getPortalState,
  (state: any) => {
    return state.studentAssessments;
  },
);

export const getStudentAssessmentsEntities = createSelector(
  getStudentAssessmentsState,
  (state: IStudentAssessmentsState) => {
    return state.studentAssessmentsEntities;
  },
);

export const getStudentAssessmentsEntitiesList = createSelector(
  getStudentAssessmentsState,
  (state: IStudentAssessmentsState) => {
    const studentAssessmentsEntities = state.studentAssessmentsEntities;
    return map(studentAssessmentsEntities, (v, k) => v);
  },
);

export const getStudentAssessmentsLoadingStatus = createSelector(
  getStudentAssessmentsState,
  (state: IStudentAssessmentsState) => {
    return state.loading;
  },
);

export const getStudentAssessmentsLoadedStatus = createSelector(
  getStudentAssessmentsState,
  (state: IStudentAssessmentsState) => {
    return state.loaded;
  },
);
