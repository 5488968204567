import { NvPillModule } from './../../../../../nvps-libraries/design/nv-pill/nv-pill.module';
import { CommonModule } from '@angular/common';
import { RowGroupCellRenderer } from './row-group-cell-renderer.component';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [CommonModule, NvPillModule],
  exports: [RowGroupCellRenderer],
  declarations: [RowGroupCellRenderer],
})
export class RowgGroupCellRendererModule { }
