import * as batchActions from '../actions/batch-actions-actions';
import { ISelectedStudentIdData } from './../actions/batch-actions-actions';
import { IAction } from 'Src/ng2/shared/components/nv-actions/nv-actions.interface';

export interface IBatchActionsState {
  enabled: boolean;
  selectedStudentIds: ISelectedStudentIdData[];
  selectedAction: IAction;
}

export const initialState = {
  enabled: false,
  selectedStudentIds: [],
  selectedAction: {} as IAction,
};

export function reducer(
  state: IBatchActionsState = initialState,
  action: batchActions.BatchActionsAction,
): IBatchActionsState {
  switch (action.type) {
    case batchActions.TOGGLE_BATCH_ACTIONS: {
      return {
        ...state,
        enabled: action.payload,
      };
    }

    case batchActions.UPDATE_SELECTED_STUDENT_IDS: {
      return {
        ...state,
        selectedStudentIds: action.payload,
      };
    }

    case batchActions.UPDATE_SELECTED_ACTION: {
      return {
        ...state,
        selectedAction: action.payload,
      };
    }

    default:
      return state;
  }
}
