import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { WildcardService } from 'Src/ng2/shared/services/list-services/wildcard.service';
import { NvDesignLibraryModule } from 'Src/nvps-libraries/design/nv-design-library.module';
import { PageHeaderModule } from '../../../shared/components/header/page-header/page-header.module';
import { FixedTableModule } from '../../../shared/components/list/fixed-table/fixed-table.module';
import { FixedToInfiniteViewModule } from '../../../shared/components/list/fixed-to-infinite-view-container/fixed-to-infinite-view.module';
import { ListContainerModule } from '../../../shared/components/list/list-container/list-container.module';
import { SharedServicesModule } from '../../../shared/services/shared.services.module';
import { BatchActionsService } from '../services/batch-actions/batch-actions.service';
import { MadlibService } from '../services/madlib/madlib.service';
import { SupportAttendanceFormDropdown } from './support-attendance-form-dropdown/support-attendance-form-dropdown.component';
import { SupportAttendanceFormDataService } from './support-attendance-form-list-v2-data/support-attendance-form-list-v2-data.service';
import { SupportAttendanceFormListV2Component } from './support-attendance-form-list-v2.component';

@NgModule({
  declarations: [SupportAttendanceFormListV2Component, SupportAttendanceFormDropdown],
  imports: [
    CommonModule,
    ListContainerModule,
    FixedToInfiniteViewModule,
    FixedTableModule,
    PageHeaderModule,
    SharedServicesModule,
    NvDesignLibraryModule,
    MatMenuModule,
  ],
  exports: [SupportAttendanceFormListV2Component],
  providers: [
    SupportAttendanceFormListV2Component,
    SupportAttendanceFormDataService,
    BatchActionsService,
    MadlibService,
    WildcardService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SupportAttendanceFormListV2Module {}
