import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Auth } from './../../auth/auth.service';

@Injectable()
/* istanbul ignore next */
export class TokenInterceptor implements HttpInterceptor {
  constructor(private auth: Auth) {}

  // we do not want to send Auth token to aws
  // otherwise, aws will return a 403 forbidden (CM).
  readonly REGION = 'us-east-1';
  // matches amazon aws URLs with or without region. Ex. "s3.amazonaws" and "s3.us-east-1.amazonaws"
  readonly AWS_URL_FRAGMENT_REGEX = new RegExp(`s3(\\.${this.REGION})?\\.amazonaws`);

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = request && request.url;
    if (url && !this.AWS_URL_FRAGMENT_REGEX.test(url)) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.auth.getIdToken()}`,
        },
      });
    }
    return next.handle(request);
  }
}
