import { ObjectCache } from 'Src/ng2/shared/services/object-cache/object-cache.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, from, of } from 'rxjs';

interface ICacheObject {
  id: string;
  json: string;
}

@Injectable()
export class GridStateResolver implements Resolve<any> {
  constructor (private objectCache: ObjectCache) {}

  resolve (route: ActivatedRouteSnapshot): Observable<ICacheObject> | null {
    const { gridState: gridStateHash } = route.queryParams;

    if (!gridStateHash) return of(null);

    const cacheObjectPromise: Promise<ICacheObject> = this.objectCache.getObject(gridStateHash);
    return from(cacheObjectPromise);
  }
}
