export const LoteExamNames = [
  'ARABIC',
  'HAITIAN',
  'CHINESE-SIMPLE',
  'HINDI',
  'GREEK',
  'FRENCH',
  'GERMAN',
  'HEBREW',
  'BENGALI',
  'JAPANESE',
  'KOREAN',
  'LATIN',
  'CHINESE-TRAD',
  'ALBANIAN',
  'RUSSIAN',
  'SPANISH',
  'ITALIAN',
  'URDU',
  'VIETNAMESE',
  'PUNJABI',
  'POLISH',
  'AMERICAN-SIGN-LANGUAGE',
] as string[];

export const LoteExamHumanName = {
  ARABIC: 'Arabic',
  HAITIAN: 'Haitian',
  'CHINESE-SIMPLE': 'Chinese-Simple',
  HINDI: 'Hindi',
  GREEK: 'Greek',
  FRENCH: 'French',
  GERMAN: 'German',
  HEBREW: 'Hebrew',
  BENGALI: 'Bengali',
  JAPANESE: 'Japanese',
  KOREAN: 'Korean',
  LATIN: 'Latin',
  'CHINESE-TRAD': 'Chinese-Trad',
  ALBANIAN: 'Albanian',
  RUSSIAN: 'Russian',
  SPANISH: 'Spanish',
  ITALIAN: 'Italian',
  URDU: 'Urdu',
  VIETNAMESE: 'Vietnamese',
  PUNJABI: 'Punjabi',
  POLISH: 'Polish',
  'AMERICAN-SIGN-LANGUAGE': 'American Sign Language',
};
