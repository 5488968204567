<base-modal
  class="base-modal"
  [title]="title"
  [itemCount]="itemCount"
  [itemType]="itemType"
  [isProfileMode]="isProfileMode"
>
  <label class="label" for="section">{{ sectionTitle }}</label> <span id="section"></span>
  <div class="checkbox-holder" *ngIf="form">
    <mat-checkbox
      *ngFor="let option of checkboxOptions"
      class="nv-mat-checkbox" 
      disableRipple
      (change)="onCheckboxClick(option)"
      [checked]="form.value[option]"
    >
      {{ option }}
    </mat-checkbox>
  </div>

  <mat-dialog-actions *ngIf="buttons" class="dialog-actions">
    <nv-button class="v3-btn secondary" *ngIf="buttons.cancel" type="button" (click)="close()" mat-button>
      {{ buttons.cancel.text }}
    </nv-button>
    <nv-button class="v3-btn primary" *ngIf="buttons.confirm" (click)="submit(form)" mat-button>
      {{ buttons.confirm.text }}
    </nv-button>
  </mat-dialog-actions>
</base-modal>
