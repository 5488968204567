import { MenuCellRenderer } from './menu-cell-renderer.component';
import { NvIconButtonModule } from '../../../../../nvps-libraries/design/nv-icon-button/nv-icon-button.module';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { NvDropdownItemModule } from 'Src/nvps-libraries/design/nv-dropdown-item/nv-dropdown-item.module';
import { NvTooltipModule } from 'Src/ng2/shared/nv-tooltip/nv-tooltip.module';
import { NvIconModule } from 'projects/shared/nvps-libraries/design/nv-icon/nv-icon.module';

@NgModule({
  imports: [
    CommonModule,
    NvIconButtonModule,
    MatMenuModule,
    NvDropdownItemModule,
    NvTooltipModule,
    NvIconModule,
  ],
  exports: [MenuCellRenderer],
  declarations: [MenuCellRenderer],
})
export class MenuCellRendererModule { }
