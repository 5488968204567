import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatStepperModule } from '@angular/material/stepper';

// Modules
import { SdcDropdownModule } from '../../../sdc-dropdown/sdc-dropdown.module';
import { InstructionStepModule } from '../../../shared/components/wizard-steps/instruction-step/instruction-step.module';
import { GradPlanningStepModule } from './steps/grad-planning/grad-planning-step-component.module';
import { ReviewGapsStepModule } from './steps/review-gaps/review-gaps-component.module';
import { OnTrackCreditStepModule } from './steps/on-track-credit-step/on-track-credit-step.module';
import { CreditGapsWizardSubjectTableModule } from '../tables/credit-gaps-subject-table.module';
import { CreditGapsWizardClassTableModule } from '../tables/credit-gaps-class-table.module';
import { DoFinalCheckStepModule } from './steps/do-final-check/do-final-check.module';
import { CreditGapsGradPlanTableModule } from '../tables/credit-gaps-grad-plan-table.module';

// Services
import { WizardHelpers } from './../../../services/wizard-helpers.service';
import { SpinnerService } from 'Src/ng2/shared/components/spinner/spinner-modal.service';
import { ImSchool } from 'Src/ng2/shared/services/im-models/im-school';
// import { SpinnerModal } from 'Src/app/shared/modals/status-spinner/status-spinner.service';

// Wizard
import { CreditGapsNonTransferWizard } from './credit-gaps-non-transfer-wizard.component';

@NgModule({
  declarations: [
    CreditGapsNonTransferWizard,
  ],
  exports: [CreditGapsNonTransferWizard],
  imports: [
    MatStepperModule,
    SdcDropdownModule,
    MatButtonModule,
    CommonModule,
    InstructionStepModule,
    GradPlanningStepModule,
    ReviewGapsStepModule,
    OnTrackCreditStepModule,
    CreditGapsWizardSubjectTableModule,
    CreditGapsWizardClassTableModule,
    DoFinalCheckStepModule,
    CreditGapsGradPlanTableModule,
  ],
  providers: [
    WizardHelpers,
    SpinnerService,
    // SpinnerModal,
    ImSchool,
  ],
})
export class CreditGapsNonTransferWizardModule { }
