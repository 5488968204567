import { ICurrentGrouping, IStateGoOpts } from '../../shared/services/prev-state/prev-state.service';
import * as previousStatesActions from '../actions/previous-states-actions';

export const PREVIOUS_STATES_SIZE_LIMIT = 50;
export interface IPreviousStates {
  previousStates: Array<{
    // all states a user has navigated to during session, except for current state (CM)
    [key: string]: IStateGoOpts['params'];
  }>;
  currentGrouping: ICurrentGrouping;
}

export const initialState: IPreviousStates = {
  previousStates: [],
  currentGrouping: null,
};

export function reducer(
  state: IPreviousStates = initialState,
  action: previousStatesActions.PreviousStatesAction,
): IPreviousStates {
  switch (action.type) {
    case previousStatesActions.UPDATE_PREVIOUS_STATES:
      const { name, params } = action.payload;
      const lastViewConfig = { [name]: params };
      const statePreviousLength = state.previousStates.length;
      const previousStates =
        statePreviousLength < PREVIOUS_STATES_SIZE_LIMIT // limit previous states array to 50 items (CM)
          ? state.previousStates
          : state.previousStates.slice(1);

      const newState = {
        previousStates: [...previousStates, lastViewConfig],
      };

      return {
        ...state,
        ...newState,
      };

    case previousStatesActions.SET_GROUPING:
      const { grouping, groupHumanName } = action.payload;
      const currentGrouping = {
        grouping,
        groupHumanName,
      };
      return {
        ...state,
        ...{ currentGrouping },
      };

    default:
      return state;
  }
}
