import { InitialGridStateResolver } from 'Src/ng2/routing/resolvers/sdc/initial-grid-state/initial-grid-state.resolver';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { USER_ROLE_PERMISSIONS_FOR_GUARDS } from 'Src/ng2/routing/guards/user-role-permissions-for-route-guards.constant';
import { SdcRootContainer } from 'Src/ng2/school/sdc/sdc-root/sdc-root-container.component';
import { SchoolStandardLayoutComponent } from '../../layouts/school-standard-layout/school-standard-layout.component';
import { sdcFullScreenResolver } from 'Src/ng2/routing/resolvers/sdc/sdc-full-screen/sdc-full-screen';
import { FullScreenLayoutComponent } from 'Src/ng2/shelter/layouts/full-screen-layout/full-screen-layout.component';
import { IRouteConfigsOpts } from 'Src/ng2/routing/route.config';
import { RouteGuard } from 'Src/ng2/routing/guards/route-guard/route-guard';
import { SchoolTypeGuard } from 'Src/ng2/routing/guards/school-type-guard/school-type-guard';
import { DistrictGuard } from 'Src/ng2/routing/guards/district-guard/district-guard';
import { OtherToolsSideNavConfig } from '../../school-tools/other-tools/other-tools.constant';
import { CreditGapsWizardContainerModule } from './wizard/credit-gaps-wizard-container.module';

export const routes: IRouteConfigsOpts[] = [
  {
    path: '',
    partnerType: 'school',
    canActivate: [RouteGuard, SchoolTypeGuard, DistrictGuard],
    rolePermissions: USER_ROLE_PERMISSIONS_FOR_GUARDS['school/:schoolId/other-tools/credit-gaps/wizard'],
    authenticationRequired: true,
    resolve: {
      isFullScreen: sdcFullScreenResolver,
    },
    component: SdcRootContainer,
    loadChildren: () => CreditGapsWizardContainerModule,
    data: {
      schoolTypes: OtherToolsSideNavConfig.tools.REGENTS_PLANNING.schoolTypes,
      districts: OtherToolsSideNavConfig.tools.REGENTS_PLANNING.districts,
      isFullScreen: false,
      sdcKey: 'credit-gaps',
      sdcView: 'wizard',
      header: 'Credit Gaps',
      navItems: [
        {
          human: 'Wizard',
          url: '/other-tools/credit-gaps/wizard',
          disabled: true,
        },
        {
          human: 'Grid',
          url: '/credit-gaps/grid',
          disabled: false,
        },
        {
          human: 'Profile',
          url: '/profile',
          disabled: false,
        },
      ],
    },
  },
  {
    path: 'grid',
    component: FullScreenLayoutComponent,
    rolePermissions: USER_ROLE_PERMISSIONS_FOR_GUARDS['school/:schoolId/other-tools/:sdcKey/grid'], // needed in SdcRootContainer -> SdcNavComponent
    authenticationRequired: true,
    children: [
      {
        path: '',
        component: SdcRootContainer,
        loadChildren: () => import('../sdc-grid/sdc-grid.module').then(m => m.SdcGridContainerModule),
        resolve: {
          gridState: InitialGridStateResolver,
        },
        data: {
          isFullScreen: true,
          sdcKey: 'credit-gaps', // needed in SdcActivityGuardForGrid and child route's InitialGridStateResolver
          sdcView: 'grid',
          header: 'Credit Gaps',
          navItems: [
            {
              human: 'Wizard',
              url: '/other-tools/credit-gaps/wizard',
              disabled: false,
            },
            {
              human: 'Grid',
              url: '/credit-gaps/grid',
              disabled: true,
            },
            {
              human: 'Profile',
              url: '/profile',
              disabled: false,
            },
          ],
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes as Routes)],
  exports: [RouterModule],
})
export class CreditGapsContainerRoutingModule {}
