import { IModalsConfig } from './../modals.config.interface';

export const FLAG_LOG_ACTION_MODAL_CONFIG: IModalsConfig = {
  checkboxes: {
    choices: [
      {
        text: 'Called home',
        value: 'Called home',
        checked: false,
      },
      {
        text: 'Conference',
        value: 'Conference',
        checked: false,
      },
      {
        text: 'Home visit',
        value: 'Home visit',
        checked: false,
      },
      {
        text: 'Talked to parent',
        value: 'Talked to parent',
        checked: false,
      },
      {
        text: 'Talked to student',
        value: 'Talked to student',
        checked: false,
      },
      {
        text: 'Other',
        value: 'Other',
        checked: false,
      },
    ],
  },
  textarea: {
    placeholder: 'Leave a note...',
  },
  buttons: {
    confirm: {
      text: 'Resolve',
      disableButtonRipple: true,
    },
    cancel: {
      text: 'Cancel',
      disableButtonRipple: true,
    },
  },
};
