import { createSelector } from '@ngrx/store';
import { createFeatureSelector } from '@ngrx/store';
import { map } from 'lodash';

const getPortalState = createFeatureSelector('PORTAL_STORE');
export const getDashboardState = createSelector(
  getPortalState,
  (state: any) => state.dashboard,
);

// STUDENTS
export const getDashboardStudents = createSelector(
  getDashboardState,
  (state: any) => state.students,
);

export const getDashboardStudentsLoadedStatus = createSelector(
  getDashboardStudents,
  (state: any) => state.loaded,
);
export const getDashboardStudentsLoadingStatus = createSelector(
  getDashboardStudents,
  (state: any) => state.loading,
);

// STUDENT_SUPPORTS
export const getDashboardStudentSupports = createSelector(
  getDashboardState,
  (state: any) => state.studentSupports,
);

export const getLoadedDashboardStudentSupportsStatus = createSelector(
  getDashboardStudentSupports,
  (state: any) => state.loaded,
);

export const getDashboardStudentEntities = createSelector(
  getDashboardStudents,
  (state: any) => state.studentEntities,
);

export const getDashboardStudentsList = createSelector(
  getDashboardStudents,
  (state: any) => {
    const { studentEntities } = state;
    return map(studentEntities, v => v);
  },
);

// GRAPHS
export const getGraphs = createSelector(
  getDashboardState,
  (state: any) => state.graphs,
);

/** MidLevel Madlib selectors */
// GRAPHS
export const getDashboardMadLibState = createSelector(
  getDashboardState,
  (state: any) => state.madlib,
);

export const dashboardSelector = (dashboardKey) => createSelector(
  getGraphs,
  (state: any) => state[dashboardKey],
);
