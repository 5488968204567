/* tslint:disable */

import { Action } from '@ngrx/store';

export const LOAD_CAREER_PATH = '[Portal] Load Career Path';
export const LOAD_CAREER_PATH_SUCCESS = '[Portal] Load Career Path Success';
export const LOAD_CAREER_PATH_FAIL = '[Portal] Load Career Path Fail';
export const CREATE_CAREER_PATH = '[Portal] Create Career Path';
export const CREATE_CAREER_PATH_SUCCESS = '[Portal] Create Career Path Success';
export const CREATE_CAREER_PATH_FAIL = '[Portal] Create Career Path Fail';

export class LoadCareerPath implements Action {
  readonly type = LOAD_CAREER_PATH;
  constructor(public payload: any) {}
}

export class LoadCareerPathSuccess implements Action {
  readonly type = LOAD_CAREER_PATH_SUCCESS;
  constructor(public payload: any) {}
}

export class LoadCareerPathFail implements Action {
  readonly type = LOAD_CAREER_PATH_FAIL;
  constructor(public payload) {}
}

export class CreateCareerPath implements Action {
  readonly type = CREATE_CAREER_PATH;
  constructor(public payload: any) {}
}

export class CreateCareerPathSuccess implements Action {
  readonly type = CREATE_CAREER_PATH_SUCCESS;
  constructor(public payload: any) {}
}

export class CreateCareerPathFail implements Action {
  readonly type = CREATE_CAREER_PATH_FAIL;
  constructor(public payload) {}
}

export type CareerPathAction =
  | LoadCareerPath
  | LoadCareerPathSuccess
  | LoadCareerPathFail
  | CreateCareerPath
  | CreateCareerPathSuccess
  | CreateCareerPathFail;
