import { Action } from '@ngrx/store';
import { TStudentMapGrowthCalcConfig } from 'Src/ng2/shared/services/graphql-helpers/student-map-growth/student-map-growth-queries.service';
import { IStudentMapGrowthEntity } from '../../reducers/map-growth-reducer/student-map-growth-reducer';
export const LOAD_STUDENT_MAP_GROWTH = '[Portal] Load StudentMapGrowth';
export const LOAD_STUDENT_MAP_GROWTH_SUCCESS = '[Portal] Load StudentMapGrowth Success';
export const LOAD_STUDENT_MAP_GROWTH_FAIL = '[Portal] Load StudentMapGrowth Failure';

export interface ILoadStudentMapGrowthPayload {
  schoolId: string;
  studentId: string;
  calcConfig: TStudentMapGrowthCalcConfig[];
}

export class LoadStudentMapGrowth implements Action {
  readonly type = LOAD_STUDENT_MAP_GROWTH;
  constructor(public payload: ILoadStudentMapGrowthPayload) {}
}

export class LoadStudentMapGrowthSuccess implements Action {
  readonly type = LOAD_STUDENT_MAP_GROWTH_SUCCESS;
  constructor(public studentId: string, public payload: IStudentMapGrowthEntity) {}
}

export class LoadStudentMapGrowthFail implements Action {
  readonly type = LOAD_STUDENT_MAP_GROWTH_FAIL;
  constructor(public payload: any) {}
}

// action types
export type StudentMapGrowthAction =
  | LoadStudentMapGrowth
  | LoadStudentMapGrowthSuccess
  | LoadStudentMapGrowthFail;
