import { GetRows, IGridEvents, TGridStateChange, IGridState, GetRowCount, GetColumnDefs, GetListFromRows } from './models';
import { ObjectCache } from 'Src/ng2/shared/services/object-cache/object-cache.service';
import { Router } from '@angular/router';
import { tap, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import {
  GridOptions,
  IServerSideGetRowsParams,
  ColDef,
} from '@ag-grid-community/core';

import { Subject, forkJoin } from 'rxjs';
import { CsvExporterService, CsvType } from '../csv-exporter/csv-exporter.service';
import { UrlPathService } from '../url-path-service/url-path.service';
import { TCsvExportView } from '../mixpanel/event-interfaces/export-csv';

@Injectable()
export class ServerSideGrid {
  constructor (
    private objectCache: ObjectCache,
    private router: Router,
    private csvExportService: CsvExporterService,
    private urlPathService: UrlPathService,
  ) {}

  private isEmptyRequest (hasNoData: boolean, isFirstReq: boolean): boolean {
    return hasNoData && isFirstReq;
  }

  private toggleOverlay (showNoRows: boolean, params: IServerSideGetRowsParams): void {
    showNoRows ? params.api.showNoRowsOverlay() : params.api.hideOverlay();
  }

  public getDataSource (
    getRows: GetRows,
    gridOptions: GridOptions,
  ) {
    return {
      getRows: (params: IServerSideGetRowsParams) => {
        getRows(params).pipe(
          tap((data: Array<{ [key:string]: any }>) => {
            if (!data) return params.failCallback();
            const isFirstReq = !params.request.startRow;
            const isEmptyRequest = this.isEmptyRequest(!data.length, isFirstReq);
            this.toggleOverlay(isEmptyRequest, params);

            params.successCallback(
              data,
              data.length === gridOptions.cacheBlockSize
                ? -1
                : (params.request.endRow - (gridOptions.cacheBlockSize - data.length)),
            );
          }),
        ).subscribe();
      },
    };
  }

  public setGridStateChangeListeners (
    gridEvents: IGridEvents,
    gridOptions: GridOptions,
  ): Subject<any> {
    const eventSubject = new Subject();
    for (const evt in gridEvents) {
      gridOptions[evt] = (e: TGridStateChange) => {
        eventSubject.next(e);
        gridEvents[evt] && gridEvents[evt].forEach((fn:Function) => fn(e));
      };
    }
    return eventSubject;
  }

  public createAgRequest (gridState: IGridState): { request: IGridState } {
    return { request: { ...gridState } };
  }

  public setGridState (
    gridState: IGridState,
    gridOptions: GridOptions,
  ): void {
    const { filterModel, sortModel, columnState } = gridState || {};
    if (filterModel) gridOptions.api.setFilterModel(filterModel);
    if (sortModel) gridOptions.columnApi.applyColumnState({state: sortModel});
    if (columnState) {
      columnState.forEach((col: {colId: string, width: number, pinned: 'left' | 'right' }, i: number) => {
        const { colId, width, pinned } = col;
        gridOptions.columnApi.setColumnWidth(colId, width);
        gridOptions.columnApi.setColumnPinned(colId, pinned);
        gridOptions.columnApi.moveColumn(colId, i);
      });
    }
  }

  public persistGridStateInUrl (gridState: {[key: string]: any}): void {
    const gridStateHash = this.objectCache.cacheObject(gridState);
    this.router.navigate([], { queryParams: { gridState: gridStateHash }, replaceUrl: true });
  }

  public persistStateInUrl (state: any): string {
    const stateHash = this.objectCache.cacheObject(state);
    this.router.navigate([], { queryParams: { state: stateHash }, queryParamsHandling: 'merge' }); // replaceUrl: true });
    return stateHash;
  }

  public setStatusBarTotalRowCount (
    statusComponentKey: string,
    getRowCount: GetRowCount,
    gridOptions: GridOptions,
  ): void {
    const gridState = { filterModel: {}, sortModel: {} };
    getRowCount(this.createAgRequest(gridState) as IServerSideGetRowsParams).pipe(
      tap(({ count }) => this.getAgComponentInstanceFromGridOptions(statusComponentKey, gridOptions)?.setTotalRows(count)),
    ).subscribe();
  }

  public setStatusBarFilteredRowCount (
    statusComponentKey: string,
    getRowCount: GetRowCount,
    gridOptions: GridOptions,
  ): void {
    const gridState = { filterModel: gridOptions.api.getFilterModel(), sortModel: this.getGridSortModel(gridOptions) };
    getRowCount(this.createAgRequest(gridState) as IServerSideGetRowsParams).pipe(
      tap(({ count }) => this.getAgComponentInstanceFromGridOptions(statusComponentKey, gridOptions)?.setFilteredRows(count)),
    ).subscribe();
  }

  public setStatusBarTotalAndFilteredRowCount (
    statusComponentKey: string,
    getRowCount: GetRowCount,
    gridOptions: GridOptions,
  ): void {
    const totalGridState = { filterModel: {}, sortModel: {} };
    const filteredGridState = { filterModel: gridOptions.api.getFilterModel(), sortModel: this.getGridSortModel(gridOptions) };
    forkJoin([
      getRowCount(this.createAgRequest(totalGridState) as IServerSideGetRowsParams),
      getRowCount(this.createAgRequest(filteredGridState) as IServerSideGetRowsParams),
    ]).pipe(
      tap(
        ([total, filtered]) => {
          this.getAgComponentInstanceFromGridOptions(statusComponentKey, gridOptions).setTotalRows(total.count);
          this.getAgComponentInstanceFromGridOptions(statusComponentKey, gridOptions).setFilteredRows(filtered.count);
        },
      ),
    ).subscribe();
  }

  public removePreviousRowGroupColumn (gridOptions:GridOptions): void {
    const [previousRowGroup] = gridOptions.columnApi.getRowGroupColumns();
    if (previousRowGroup) {
      const previousRowGroupId = previousRowGroup.getColId();
      gridOptions.columnApi.setColumnVisible(previousRowGroupId, true);
      gridOptions.columnApi.removeRowGroupColumn(previousRowGroupId);
    }
  }

  public setRowGroupColumn (
    colId: string,
    autoGroupColField: string,
    gridOptions: GridOptions,
  ): void{
    if (!colId) return;
    gridOptions.autoGroupColumnDef.field = autoGroupColField;
    gridOptions.columnApi.setColumnVisible(colId, false);
    gridOptions.columnApi.setRowGroupColumns([colId]);
  }

  public setGroupColumnInvisible (
    colId: string,
    gridOptions: GridOptions,
  ): void {
    if (!colId) return;
    gridOptions.columnApi.setColumnVisible(colId, false);
  }

  public viewProfiles (
    schoolId: string,
    gridOptions:GridOptions,
    mapFn: GetListFromRows,
  ): void {
    const selectedRows = gridOptions.api.getSelectedRows();
    if (!selectedRows.length) return;
    const filter = this.objectCache.cacheObject({ _id: mapFn(gridOptions.api.getSelectedRows()) });
    const url = this.urlPathService.computeDistrictUrlPath(`/school/${schoolId}/student`);
    this.router.navigate([url], { queryParams: { filter } });
  }

  // Handles csv exports from Data Grid
  public exportServerSideGridCsv (
    getCsvExportData: GetRows,
    getColumnDefs: GetColumnDefs,
    options: {fileName: string, currentExam?: string},
    gridOptions: GridOptions,
    view?: TCsvExportView,
  ): void {
    this.exportAnyGridCsv({
      getCsvExportData,
      getColumnDefs,
      options,
      gridOptions,
      csvType: CsvType.Network,
      view: view ?? 'Data grid',
    });
  }

  // handles any ag-grid implementation that allows csv export (such as Regents Results)
  public exportAnyGridCsv ({
    getCsvExportData,
    getColumnDefs,
    options,
    gridOptions,
    csvType,
    view,
  }:{
    getCsvExportData: GetRows,
    getColumnDefs: GetColumnDefs,
    options: {fileName: string, currentExam?: string},
    gridOptions: GridOptions,
    csvType: CsvType,
    view: TCsvExportView,
  }): void {
    const gridState = { filterModel: gridOptions.api.getFilterModel(), sortModel: this.getGridSortModel(gridOptions) };
    getCsvExportData(this.createAgRequest(gridState) as IServerSideGetRowsParams).pipe(
      withLatestFrom(getColumnDefs()),
      tap(([rowData, columnDefs]) => {
        const type = 'text/csv;charset=utf-8';
        const blob = this.csvExportService.createGridCsv(columnDefs, rowData);
        this.csvExportService.exportCsv(blob, options.fileName, type, csvType, view, options.currentExam ?? '');
      }),
    ).subscribe();
  }

  // handles any ag-grid implementation that allows csv export where columnDefs are derived from rowData - Used in Area/Std Mock Regents
  // download of data from base table instead of the grid
  public exportServersideDataAsCsv (
    getCsvExportData: GetRows,
    columnDefs: ColDef<any>[],
    options: {fileName: string, currentExam?: string},
    gridOptions: GridOptions,
    view?: TCsvExportView,
  ): void {
    const gridState = { filterModel: gridOptions.api.getFilterModel(), sortModel: this.getGridSortModel(gridOptions) };
    getCsvExportData(this.createAgRequest(gridState) as IServerSideGetRowsParams).pipe(
      tap((rowData) => {
        const type = 'text/csv;charset=utf-8';

        const blob = this.csvExportService.createGridCsv(columnDefs, rowData);
        this.csvExportService.exportCsv(blob, options.fileName, type, CsvType.MockRegents, view ?? 'Data grid', options.currentExam ?? '');
      }),
    ).subscribe();
  }

  private getAgComponentInstanceFromGridOptions (componentKey: string, gridOptions: GridOptions) {
    const statusBarComponent = gridOptions.api?.getStatusPanel(componentKey) as any;
    return statusBarComponent || null;
  }

  public getGridSortModel(gridOptions: GridOptions) {
    return gridOptions.columnApi.getColumnState()
      .filter(c => c.sort)
      .sort((a, b) => a.sortIndex - b.sortIndex)
      .map(({ sort, colId }) => ({ sort, colId }));
  }
}
