import { createFeatureSelector, createSelector } from '@ngrx/store';
import { map } from 'lodash';
import { ICareerPathState } from '../reducers/career-path-reducers';

const getPortalState = createFeatureSelector('PORTAL_STORE');

export const getCareerPathState = createSelector(
  getPortalState,
  (state: any) => {
    return state.careerPath;
  },
);

export const getCareerPathEntities = createSelector(
  getCareerPathState,
  (state: ICareerPathState) => {
    return state.careerPathEntities;
  },
);

export const getCareerPathEntitiesList = createSelector(
  getCareerPathState,
  state => {
    const entities = state.careerPathEntities;
    return map(entities, (v, k) => v);
  },
);

export const getCareerPathLoadingStatus = createSelector(
  getCareerPathState,
  (state: ICareerPathState) => {
    return state.loading;
  },
);

export const getCareerPathLoadedStatus = createSelector(
  getCareerPathState,
  (state: ICareerPathState) => {
    return state.loaded;
  },
);
