<div *ngIf="(studentOptions$ | async) as studentOptions" class="manage-caseload">
  <form [formGroup]="caseloadForm">
    <div formArrayName="students" *ngFor="let student of students.controls; let i = index">
      <nv-form-field label="Student"></nv-form-field>
      <nv-textbox 
        icon="search-large-black"
        placeholder="Search for eligible students"
        [autocompleteOptions]="studentOptions"
        (selectOption)="onStudentSelect(i, $event)"
        [textControl]="student"
        returnObject="true"
        hasClearBtn="true"
        (clearValue)="clearValue(i)"
        [optionsPredicateCb]="optionsPredicateCb"
        [optionsLimit]="optionsLimit"
      ></nv-textbox>
      
      <nv-button 
        *ngIf="i === students.length-1" 
        icon="add-small-blue" 
        type="secondary" 
        size="small" 
        (clickButton)="addFormInput()">Add additional student</nv-button>
    </div>
  </form>
</div>
