import { Injectable } from '@angular/core';

@Injectable()
export class GraphQLRegentsSupportsTableHelperService {
  getRegentsSupportsTableQuery (schoolId): string {
    return `
    {
        RegentsSupportsTable(schoolId:"${schoolId}"){
          columns {
            field
            graphQLKey
            headerName
            headerTooltip
            width
            valueGetter
            valueFormatter
            autoHeight
            suppressMovable
            wrapText
            headerClass
            cellClass
            cellRenderer
            cellRendererParams
            sortable
            resizable
            tooltipComponentParams {
              tooltipTemplate,
          },
            tooltipField
          }
          rowData {
            _id
            STUB
            SUPPORT_ACTIVITY_LEADS {
              firstName,
              lastName
            }
            SUPPORT_DATES
            ACTIVE_STUDENTS_IN_STUDENT_SUPPORT
            DOT_MENU
            REGENTS_SUPPORTS_SUBJECT
            REGENTS_SUPPORT_ADMIN
          }
        }
      }`;
  }
}
