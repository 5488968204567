<div class="sdc-nav-wrapper">
  <div class="nvps-sdc-header">
    <exit-view [exitState]="exitState" *ngIf="isFullScreen"> </exit-view>
    <h1>{{ header }}</h1>
  </div>
  <div class="nvps-sdc-nav-wrapper">
    <button
      mat-button
      *ngFor="let nav of navItems"
      class="sdc-nav-option v3-btn secondary"
      (click)="navigateToUrl(nav.url)"
      [class.isDisabled]="nav.disabled"
    >
      {{ nav.human }}
    </button>
  </div>
</div>
