<!-- FOCUS -->
<span class="madlib-element" id="madlib-focus">
  <nv-dropdown
    *ngIf="hasMultipleFoci; else noFociDropdown"
    [returnObject]="true"
    [options]="fociByCategory"
    (selectOption)="emitMadlibChange($event, 'focus')"
    [selected]="madlibModel.value.focus.key"
  ></nv-dropdown>
  <ng-template #noFociDropdown>
    <span class="madlib-no_options">{{ madlibModel.value.focus.label }}</span>
  </ng-template>
</span>

<!-- FILTER -->
<span class="madlib-element" id="madlib-filter">
  <span class="madlib-label">for</span>
  <nv-dropdown
    *ngIf="madlibModel.value.focus.filters.length > 1; else noFilterDropdown"
    [returnObject]="true"
    [options]="madlibModel.value.focus.filters"
    (selectOption)="emitMadlibChange($event, 'filter')"
    [selected]="madlibModel.value.filter.key"
  ></nv-dropdown>

  <ng-template #noFilterDropdown>
    <span class="madlib-no_options">{{ madlibModel.value.filter.label }}</span>
  </ng-template>
</span>

<!-- GROUPING -->
<span class="madlib-element" id="madlib-grouping">
  <span class="madlib-label">grouped by</span>
  <nv-dropdown
    *ngIf="madlibModel.value.focus.groupings.length > 1; else noDropdown"
    [returnObject]="true"
    [options]="madlibModel.value.focus.groupings"
    (selectOption)="emitMadlibChange($event, 'grouping')"
    [selected]="madlibModel.value.grouping.key"
  ></nv-dropdown>

  <ng-template #noDropdown>
    <span class="madlib-no_options">{{ madlibModel.value.grouping.label }}</span>
  </ng-template>
</span>
