import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { CurrentSchoolYear } from '../../../../shared/constants/current-school-year.constant';
import { NextGraduationDate } from '../../../../shared/constants/graduation-date.constant';

export type TValidPriorityGroupKeys =
  | 'PLANNED_JAN_MARCH'
  | 'PLANNED_JUNE'
  | 'PLANNED_AUG'
  | 'PLANNED_BEYOND_AUG'
  | 'PLANNED_NON_GRAD'
  | 'PLAN_INCOMPLETE'
  | 'JUNIORS'
  | 'SOPHOMORES'
  | 'FRESHMAN'
  | 'NONE';

export type TValidStudentYearFilters = 1 | 2 | 3 | 4;

export interface IPriorityGroupDefinition {
  key: TValidPriorityGroupKeys;
  priorityVal: number;
  priorityLabel: string;
  studentPriorityGroupingValue: string;
  studentYearFilter: TValidStudentYearFilters;
  studentIds?: string[];
}

@Injectable()
export class RegentsPlanningPriorityGroupingsService {
  private _priorityGroupings: IPriorityGroupDefinition[];

  private currentSchoolYearEnd = Number(CurrentSchoolYear.ENDFULL);
  private juniorCohort = this.currentSchoolYearEnd + 1;
  private sophomoreCohort = this.currentSchoolYearEnd + 2;
  private freshmanCohort = this.currentSchoolYearEnd + 3;

  private priorityGroupDefinitions = [
    {
      key: 'PLANNED_JAN_MARCH',
      priorityVal: 1,
      priorityLabel: `Planned January or March ${this.currentSchoolYearEnd} Grads with unaddressed Regents needs.`,
      studentPriorityGroupingValue:
        'Planned Jan/March ' + this.currentSchoolYearEnd + ' Grad w/ Unaddressed Regents Needs',
      studentYearFilter: 4,
    } as IPriorityGroupDefinition,
    {
      key: 'PLANNED_JUNE',
      priorityVal: 2,
      priorityLabel: `Planned June ${this.currentSchoolYearEnd} Grads with unaddressed Regents needs.`,
      studentPriorityGroupingValue:
        'Planned June/March ' + this.currentSchoolYearEnd + ' Grad w/ Unaddressed Regents Needs',
      studentYearFilter: 4,
    } as IPriorityGroupDefinition,
    {
      key: 'PLANNED_AUG',
      priorityVal: 3,
      priorityLabel: `Planned Aug ${this.currentSchoolYearEnd} Grads with unaddressed Regents needs.`,
      studentPriorityGroupingValue: 'Planned Aug ' + this.currentSchoolYearEnd + ' Grad w/ Unaddressed Regents Needs',
      studentYearFilter: 4,
    } as IPriorityGroupDefinition,
    {
      key: 'PLANNED_BEYOND_AUG',
      priorityVal: 4,
      priorityLabel: `Students planned to graduate beyond Aug ${
        this.currentSchoolYearEnd
      } with unaddressed Regents needs.`,
      studentPriorityGroupingValue:
        'Planned Beyond Aug ' + this.currentSchoolYearEnd + ' Grad w/ Unaddressed Regents Needs',
      studentYearFilter: 4,
    } as IPriorityGroupDefinition,
    {
      key: 'PLAN_INCOMPLETE',
      priorityVal: 5,
      priorityLabel: 'Seniors with incomplete grad plans with unaddressed Regents needs.',
      studentPriorityGroupingValue: 'No Grad Plan w/ Unaddressed Regents Needs',
      studentYearFilter: 4,
    } as IPriorityGroupDefinition,
    {
      key: 'JUNIORS',
      priorityVal: 6,
      priorityLabel: 'Active class of ' + this.juniorCohort + ' with unaddressed Regents needs.',
      studentPriorityGroupingValue: this.juniorCohort + ' w/ Unaddressed Regents Needs',
      studentYearFilter: 3,
      studentIds: [],
    } as IPriorityGroupDefinition,
    {
      key: 'SOPHOMORES',
      priorityVal: 7,
      priorityLabel: `Active class ${this.sophomoreCohort} students with unaddressed Regents needs.`,
      studentPriorityGroupingValue: this.sophomoreCohort + ' w/ Unaddressed Regents Needs',
      studentYearFilter: 2,
    } as IPriorityGroupDefinition,
    {
      key: 'FRESHMAN',
      priorityVal: 8,
      priorityLabel: `Active class of ${this.freshmanCohort} students with unaddressed Regents needs.`,
      studentPriorityGroupingValue: `${this.freshmanCohort} w/ Unaddressed Regents Needs`,
      studentYearFilter: 1,
    } as IPriorityGroupDefinition,
    {
      key: 'PLANNED_NON_GRAD',
      priorityVal: 9,
      priorityLabel: 'Planned Non-Graduates with unaddressed Regents needs.',
      studentPriorityGroupingValue: 'Planned Non-Grad w/ Unaddressed Regents Needs',
      studentYearFilter: 4,
    } as IPriorityGroupDefinition,
    {
      key: 'NONE',
      priorityVal: null,
      priorityLabel: 'Students with no unaddressed Regents needs',
      studentPriorityGroupingValue: 'No Unaddressed Regents Needs',
      studentYearFilter: null,
    } as IPriorityGroupDefinition,
  ];

  private setRegentsPlanningPriorityGroupingDetails () {
    this._priorityGroupings = [];
    // figure out priority numbers
    const nextGradDateMonthOrder = NextGraduationDate.monthOrder;
    const janIsNext = nextGradDateMonthOrder === 1;
    const marchIsNext = nextGradDateMonthOrder === 2;
    const juneIsNext = nextGradDateMonthOrder === 3;
    const augIsNext = nextGradDateMonthOrder === 4;

    let groupings;
    if (janIsNext || marchIsNext) groupings = new Array(10);
    if (juneIsNext) groupings = new Array(9);
    if (augIsNext) groupings = new Array(8);

    let startingIndex;
    if (janIsNext || marchIsNext) startingIndex = 0;
    if (juneIsNext) startingIndex = 1;
    if (augIsNext) startingIndex = 2;

    _.forEach(groupings, (group, i) => {
      const groupIndex = startingIndex + i;
      this._priorityGroupings.push(this.priorityGroupDefinitions[groupIndex]);
      // set priority val prop
      const priorityVal = i + 1;
      this._priorityGroupings[i].priorityVal = priorityVal;
      // overwrite studentPriorityGroupingValue to include priority val
      this._priorityGroupings[i].studentPriorityGroupingValue =
        priorityVal + '. ' + this._priorityGroupings[i].studentPriorityGroupingValue;
    });
  }

  get priorityGroupings (): IPriorityGroupDefinition[] {
    if (!this._priorityGroupings) this.setRegentsPlanningPriorityGroupingDetails();
    return this._priorityGroupings;
  }

  private _getPriorityGroupByKey (key: TValidPriorityGroupKeys): IPriorityGroupDefinition {
    const priorityGroupings = this.priorityGroupings;
    return _.find(priorityGroupings, { key });
  }

  public getStudentValueForPriorityGroupByKey (key: TValidPriorityGroupKeys): string {
    const prioirtyGroup = this._getPriorityGroupByKey(key);
    return prioirtyGroup.studentPriorityGroupingValue;
  }

  public getPriorityGroupsByStudentYearFilter (
    studentYearFilter?: TValidStudentYearFilters,
  ): IPriorityGroupDefinition[] {
    const priorityGroupings = this.priorityGroupings;
    if (studentYearFilter) {
      return _.filter(priorityGroupings, { studentYearFilter });
    } else {
      return priorityGroupings;
    }
  }
}
