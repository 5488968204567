import { ImStudentDessa } from './../services/im-models/im-student-dessa';
import { SorterColumnDataType } from './sorter-column-data-type.constant';
export const DESSA_PRE_RATING_PERIOD_NAME = 'Pre-Assessment';
export const DESSA_POST_RATING_PERIOD_NAME = 'Post-Assessment';
export const VALID_DESSA_RATING_PERIOD_NAMES = [DESSA_PRE_RATING_PERIOD_NAME, DESSA_POST_RATING_PERIOD_NAME];
export type TValidDessaRatingPeriodName = typeof VALID_DESSA_RATING_PERIOD_NAMES[number];
export const VALID_DESSA_RATING_PERIODS = [DESSA_PRE_RATING_PERIOD_NAME, DESSA_POST_RATING_PERIOD_NAME];

export const DESSA_OLD_GRID_COLUMNS = {
  domainDesiredFields: {
    // humanName and colNamen will be different depending on the domain and rating period
    tScore: {
      humanName: '',
      colName: '',
      desiredField: 'tScore',
      dataType: SorterColumnDataType.NUMERIC,
      dataTypeOptions: {
        numberType: 'NUMBER',
        decimalPlaces: 0,
      },
      headerTooltip: function (domainName: string, ratingPeriod: string): string {
        return `The ${domainName} T-Score for the most recent DESSA (SEL) ${ratingPeriod}`;
      },
    },
  },
  generalDataDesiredFields: {
    ratingDate: {
      humanName: '(SEL): Rating Date',
      colName: 'RatingDate',
      desiredField: 'ratingDate',
      dataType: SorterColumnDataType.STRING,
      headerTooltip: function (ratingPeriodName?: string): string {
        return `The date that the student completed the most recent DESSA (SEL) ${ratingPeriodName}. Data is provided by Aperture and updated nightly.`;
      },
      additionalCalculation: function (dateStr: string): string {
        return ImStudentDessa.formatDessaDate(dateStr);
      },
    },
    secDescription: {
      humanName: '(SEL): Descriptive Range',
      colName: 'DescriptiveRange',
      desiredField: 'secDescription',
      dataType: SorterColumnDataType.STRING,
      headerTooltip: function (ratingPeriodName?: string): string {
        return `Descriptive range for the most recent DESSA (SEL) ${ratingPeriodName}. High scores (T-scores of 60 and above) are referred to as strengths. T-scores between 41 and 59 inclusive are described as typical. Low scores (T-scores of 40 and below) are described as a need for instruction.`;
      },
      additionalCalculation: function (secDescription: string): string {
        return ImStudentDessa.formatDescription(secDescription);
      },
    },
    secTScore: {
      humanName: '(SEL): SEC (T-Score)',
      colName: 'SecTScore',
      desiredField: 'secTScore',
      dataType: SorterColumnDataType.NUMERIC,
      dataTypeOptions: {
        numberType: 'NUMBER',
        decimalPlaces: 0,
      },
      headerTooltip: function (ratingPeriodName?: string): string {
        return `Overal T-score for the most recent DESSA (SEL) ${ratingPeriodName}; SEC is the composite score- it stands for Social Emotional Composite`;
      },
    },
  },
};
