import { RegentsExam } from './../../../../../../shared/constants/regents.constant';
import { each, map, reduce } from 'lodash';
import { IPatchEvent, WizardHelpers } from './../../../../services/wizard-helpers.service';
import { Component, Input, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

interface IMismatchByStudent {
  _id: string;
  studentId: string;
  mismatchedExams: string[]; // student _ids
}

@Component({
  selector: 'stars-mismatch-step',
  templateUrl: 'stars-mismatch-step.component.html',
  styleUrls: ['../shared-step-styles.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class StarsMismatchStep implements OnInit {
  @Input() filteredStudents;
  @Output() studentPatch: EventEmitter<any> = new EventEmitter();

  public stepLabel: string = 'Update Portal to reflect exams currently scheduled in STARS';
  public studentsWithMismatches;
  public mismatchedExamsByStudent: IMismatchByStudent[];
  public mismatchedExamsByExamShortName;
  public totalMismatchedExams: number;
  public patches;
  public options;

  constructor (public wizardHelpers: WizardHelpers) {}

  ngOnInit (): void {
    this.mismatchedExamsByStudent = this.generateMismatchedExamData();
    this.studentsWithMismatches = map(this.mismatchedExamsByStudent, ({ studentId }) => studentId);
    this.mismatchedExamsByExamShortName = this.organizeMismatchedExamsByExamShortName();
    this.totalMismatchedExams = this.wizardHelpers.getCountOfStudentsToBePatched(this.mismatchedExamsByExamShortName);
    this.patches = this.wizardHelpers.getRegentsExamBulkPatch(this.mismatchedExamsByExamShortName, 'shortName');
    this.options = {
      columnKeys: ['gradPlan', 'plannedDiplomaType', 'plannedGraduationDate', 'examsSchedInStarsNotPlannedInPortal'],
    };
  }

  ngOnChanges ({ filteredStudents }) {
    if (filteredStudents && !filteredStudents.isFirstChange()) {
      this.ngOnInit();
    }
  }

  private generateMismatchedExamData (): IMismatchByStudent[] {
    return reduce(
      this.filteredStudents,
      (result, { _id, studentId, examsSchedInStarsNotPlannedInPortal: mismatchedExams }) => {
        if (mismatchedExams.length) result.push({ _id, studentId, mismatchedExams });
        return result;
      },
      [],
    );
  }

  private organizeMismatchedExamsByExamShortName () {
    const baseObj = reduce(
      RegentsExam,
      (result, exam) => {
        // obj key must be based on shortName: returned from sorterCol
        if (exam && exam.key) result[exam.shortName] = { _ids: [], studentIds: [] };
        return result;
      },
      {},
    );
    each(this.mismatchedExamsByStudent, ({ _id, studentId, mismatchedExams }) => {
      each(mismatchedExams, examShortName => {
        if (baseObj[examShortName]) {
          baseObj[examShortName]._ids.push(_id);
          baseObj[examShortName].studentIds.push(studentId);
        }
      });
    });
    return baseObj;
  }

  patchStudents (patches, count) {
    const patchEvent: IPatchEvent = {
      message: `This will bulk schedule ${count} exams`,
      patches,
    };
    this.studentPatch.emit(patchEvent);
  }
}
