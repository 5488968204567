import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TokenInterceptor } from './token.interceptor';
import { DistrictIdInterceptor } from './district-id.interceptor';

@NgModule({})
export class HttpInterceptorModule {
  static forRoot() {
    return {
      ngModule: HttpInterceptorModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: TokenInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: DistrictIdInterceptor,
          multi: true,
        },
      ],
    };
  }
}
