import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Auth } from './../../shared/auth/auth.service';
import { TPortalLocation } from 'Src/ng2/shared/typings/interfaces/portal.interface';
import { SharedEventTrackers } from 'Src/ng2/shared/services/mixpanel/event-trackers/shared-tracking.service';

export type TLogoutStyle = 'default' | 'BUTTON';

@Component({
  selector: 'nvps-log-out',
  templateUrl: './log-out.component.html',
  styleUrls: ['./log-out.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LogOutComponent {
  @Input() portalType: TPortalLocation;
  @Input() type: TLogoutStyle;

  constructor (
    private auth: Auth,
    private sharedEventTrackers: SharedEventTrackers,
  ) {}

  logOut (): void {
    this.trackManualLogout();
    this.auth.logout();
  }

  private trackManualLogout () {
    const portal = this.portalType;
    const navItem = 'Log out';
    this.sharedEventTrackers.trackLefthandNavEvent({ navItem, portal });
  }
}
