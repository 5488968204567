import { LastKnownUrlGuard } from '../../guards/last-known-url-guard/last-known-url-guard';

export const districtPickerRoute = {
  path: 'district-picker',
  url: '/district-picker',
  canActivate: [
    LastKnownUrlGuard,
  ],
  authenticationRequired: true,
  loadChildren: () => import('../../../shell/district-picker/district-picker.module').then(m => m.DistrictPickerModule),
};
