<p class="mat-body-2">
  If you have already scheduled students for Regents exams in STARS, you can update Regents scheduling in the
  Portal to reflect the work you have already done. You will have the opportunity in the following steps to review
  these plans and make adjustments as needed.
</p>

<p class="mat-body-2">
  If you choose to update Portal scheduling based on STARS, this will overwrite decisions captured in the Portal
  where there are discrepancies between the two systems (e.g. if a student is scheduled in STARS for the January
  administration and in the Portal for June, this will overwrite the June decision in the Portal).
</p>

<p class="mat-body-2" *ngIf="totalMismatchedExams">
  There are {{totalMismatchedExams}}
  
  <ng-container [ngPlural]="totalMismatchedExams">
    <ng-template ngPluralCase="0"> exams for </ng-template>
    <ng-template ngPluralCase="1"> exam for </ng-template>
    <ng-template ngPluralCase="few"> exams for </ng-template>
    <ng-template ngPluralCase="other"> exams for </ng-template>
  </ng-container>

  <wizard-filter-actions [data]="studentsWithMismatches" [options]="options"></wizard-filter-actions>

  <ng-container [ngPlural]="mismatchedExamsByStudent.length">
    <ng-template ngPluralCase="0"> students </ng-template>
    <ng-template ngPluralCase="1"> student </ng-template>
    <ng-template ngPluralCase="few"> students </ng-template>
    <ng-template ngPluralCase="other"> students </ng-template>
  </ng-container>

  that are scheduled in STARS that are not planned in the Portal.
</p>

<p class="mat-body-2" *ngIf="!totalMismatchedExams">
  There are no exams scheduled in STARS that are not planned in the Portal.
</p>

<button
  mat-raised-button
  class="step-action-button"
  [disabled]="!totalMismatchedExams"
  (click)="patchStudents(patches, totalMismatchedExams)"
>
  Update {{totalMismatchedExams}}
  
  <ng-container [ngPlural]="totalMismatchedExams">
    <ng-template ngPluralCase="0"> exams </ng-template>
    <ng-template ngPluralCase="1"> exam </ng-template>
    <ng-template ngPluralCase="few"> exams </ng-template>
    <ng-template ngPluralCase="other"> exams </ng-template>
  </ng-container>
</button>
