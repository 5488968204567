<base-modal
  class="base-modal support-modal"
  [title]="formVariant.title"
  [itemCount]="itemCount"
  [itemType]="itemType"
  [subtitle]="supportSubtitle"
  [isProfileMode]="isProfileMode"
  [isMultiMode]="isMultiMode"
  [childHasSecondPage]="childHasSecondPage"
  (page1)="showFieldSetOne()"
  [buttonMode]="buttonMode"
>
  <!-- TODO: ngIf filterOptions? -->
  <form *ngIf="supportForm" [formGroup]="supportForm" autocomplete="off">
    <!-- FIELD SET 1 ----------------------->
    <div class="field-set" [ngClass]="{ 'field-set-is-inactive': fieldSet2IsActive }" *ngIf="!childHasSecondPage">
      <!-- support name text -->
      <div class="field-wrapper">
        <label for="">Name</label> <span id="name"></span>
        <nv-textbox
          class="width-100"
          [textControl]="supportForm.controls.set1.controls.name"
          [placeholder]="placeholderName"
          [hasClearBtn]="hasClearBtn"
          (clearValue)="clearField('set1', 'name')"
          icon="people-large-black"
          [showInvalid]="supportForm.controls.set1.controls.name.invalid"
          [showInvalidOnPristine]="!supportForm.controls.set1.controls.name.invalid"
        ></nv-textbox>
        <div
          class="warning-message"
          *ngIf="
            !supportForm.controls.set1.controls.name.pristine &&
            supportForm.controls.set1.controls.name.invalid &&
            supportForm.value.set1.name.length > 150
          "
        >
          <div class="warning-icon"><nv-icon [name]="warningIcon"></nv-icon></div>
          <div class="warning-text">Name field must contain less than {{ maxCharacters }} characters</div>
        </div>
      </div>

      <!-- description textarea -->
      <div class="field-wrapper">
        <label for="description">Description (optional)</label> <span id="description"></span>
        <nv-textarea
          [formControl]="supportForm.controls.set1.controls.description"
          [placeholder]="placeholderDesc"
          [maxCharacters]="maxCharacters"
        ></nv-textarea>
      </div>

      <!-- Assigned students checkbox -->
      <div class="field-wrapper" *ngIf="isDuplicateMode">
        <label for="assignedStudents">Assigned students</label> <span id="assignedStudents"></span>
        <mat-checkbox
          class="nv-mat-checkbox"
          (change)="toggleReassignStudents()"
          [checked]="shouldReassignStudents"
        >Reassign students who are active in ATS</mat-checkbox>
      </div>

      <!-- support tier dropdown -->
      <div class="field-wrapper">
        <label for="tier">Tier (optional)</label>
        <nv-icon name="info-small-hover" nvTooltip [tooltipData]="tierTooltip"></nv-icon>
        <br><span id="tier"></span>
        <nv-dropdown
          [options]="tiers"
          (selectOption)="selectTier($event)"
          [selected]="selectedTier.key"
        ></nv-dropdown>
      </div>

      <!-- support cats mutli -->
      <div class="field-wrapper">
        <label for="category">Category</label> <span id="category"></span>
        <nv-multi-picker
          [placeholder]="categoryPlaceholder"
          [options]="categories"
          [(selectedOptions)]="selectedCats"
          [color]="color"
          [isInverted]="isInverted"
          [hasAllButton]="hasAllButton"
          [hasClearButton]="hasClearButton"
          (selectedOptionsChange)="handleSelectedCats($event)"
        ></nv-multi-picker>
      </div>

      <!-- based on cat, show subjects multi -->
      <div class="field-wrapper" *ngIf="showSubjectsField && subjects">
        <label for="category">Subject</label> <span id="subject"></span>
        <nv-pill-button
          class="support-form-single-dropdown"
          [ngClass]="{ pulse: pulse }"
          [text]="pillLabel"
          icon="dropdown-blue"
          [color]="color"
          [isInverted]="v4ModeIsOn ? 'true' : isInverted"
          [matMenuTriggerFor]="subjectsMenu"
        >
          {{ selectedSubject ? selectedSubject : 'Select subject' }}
        </nv-pill-button>
        <mat-menu #subjectsMenu Menu="matMenu" [overlapTrigger]="true" class="editable-option-panel">
          <button mat-menu-item *ngFor="let option of subjects" (click)="handleSelectedSubject(option)">
            {{ option }}
          </button>
        </mat-menu>
      </div>
      <!-- regents admin dropdown -->
      <div class="field-wrapper" *ngIf="showRegentsAdminField">
        <label for="category">Regents admin</label> <span id="regents-admin"></span>
        <nv-pill-button
          class="support-form-single-dropdown"
          [ngClass]="{ pulse: pulse }"
          [text]="pillLabel"
          icon="dropdown-blue"
          [color]="color"
          [isInverted]="v4ModeIsOn ? 'true' : isInverted"
          [matMenuTriggerFor]="regentsAdmin"
        >
          {{ regentsAdminDate ? regentsAdminDate : 'Select regents admin' }}
        </nv-pill-button>
        <mat-menu #regentsAdmin Menu="matMenu" [overlapTrigger]="true" class="editable-option-panel">
          <button mat-menu-item *ngFor="let option of adminDates" (click)="handleSelectedRegentsAdminDate(option)">
            {{ option }}
          </button>
        </mat-menu>
      </div>

      <div class="field-wrapper" *ngIf="showSubcategoriesField && subcats">
        <label for="subcategory">Subcategories (optional)</label> <span id="subcategory"></span>
        <nv-multi-picker
          [placeholder]="'Select subcategories'"
          [options]="subcats"
          [(selectedOptions)]="selectedSubcats"
          [color]="color"
          [isInverted]="isInverted"
          [hasAllButton]="hasAllButton"
          [hasClearButton]="hasClearButton"
          (optionClicked)="handleSubcatClicked($event)"
          [alphabetize]="true"
          [endCount]="1"
        ></nv-multi-picker>
        <!-- startCount = 1 is redundant but making it explicit incase we want to make the default 0 ----------------------->
      </div>
    </div>

    <!-- END FIELD SET 1 ----------------------->
    <div class="field-set" *ngIf="childHasSecondPage" [ngClass]="{ 'field-set-is-inactive': fieldSet1IsActive }">
      <!-- activity leads multi -->
      <div class="field-wrapper" *ngIf="users">
        <label for="activityLeads">Leads</label> <span id="activity-leads"></span>
        <nv-multi-picker
          [placeholder]="'Select a lead'"
          [options]="users"
          [(selectedOptions)]="selectedActivityLeads"
          [color]="color"
          [isInverted]="isInverted"
          [hasAllButton]="hasAllButton"
          [hasClearButton]="hasClearButton"
          (selectedOptionsChange)="handleSelectedActivityLeads($event)"
        ></nv-multi-picker>
      </div>

      <!-- support frequency dropdown -->
      <div class="field-wrapper">
        <label for="frequencies">Frequency</label> <span id="frequencies"></span>
        <nv-dropdown
          class="support-form-single-dropdown"
          [ngClass]="{ disabled: disableDateChange }"
          [options]="frequencies"
          (selectOption)="handleSelectedRepeats($event)"
          [selected]="selectedRepeats"
          placeholder="Select frequency"
          [isDisabled]="disableDateChange"
        ></nv-dropdown>
      </div>

      <!-- if FREQUENCY IS CUSTOM, SHOW TWO ADDITIONAL INPUTS. ALL OTHER FREQUENCIES 
      INDICATE A PRESET SCHEDULE -->
      <ng-container *ngIf="frequencyIsCustom">
        <div class="field-wrapper">
          <label for="repeat-every">Repeat every</label><span id="repeat-every"></span>
          <nv-dropdown
            class="support-form-single-dropdown"
            [ngClass]="{ disabled: disableDateChange }"
            [options]="repeatsEveryOpts"
            (selectOption)="handleSelectedRepeatsEvery($event)"
            [selected]="selectedRepeatsEvery"
            placeholder="Select repeats every"
            [isDisabled]="disableDateChange"
          ></nv-dropdown>
        </div>

        <div class="field-wrapper">
          <label for="repeat-on">Repeat on</label><span id="repeat-on"></span> <br />
          <div class="week-pills" *ngFor="let day of weekdays">
            <ng-container *ngIf="!disableDateChange; else completedPill">
              <span
                class="day-pill-wrapper"
                [ngClass]="{ 'day-is-active': day.isActive }"
                (click)="selectRepeatsOnDay(day)"
              >
                <input type="checkbox" class="day-pill" name="day-pill" checked />
                <label for="day-pill">{{ day.human }}</label>
              </span>
            </ng-container>
            
            <ng-template #completedPill>
              <span
                class="day-pill-wrapper disabled"
                [ngClass]="{ 'day-is-active': day.isActive }"
              >
                <input type="checkbox" class="day-pill" name="day-pill" checked disabled/>
                <label for="day-pill">{{ day.human }}</label>
              </span>
            </ng-template>
          </div>
        </div>
      </ng-container>

    <!-- term menu inputs -->
    <nv-form-field label="Start and end term" [ngClass]="{ disabled: disableDateChange }">
      <div class="term-range-picker">
        <nv-dropdown
          class="support-form-single-dropdown"
          [options]="termPickerStartOptions"
          [selected]="selectedTerms.startTerm.option.key"
          (selectOption)="onTermPickerSelect($event, 'start')"
        ></nv-dropdown>
        <nv-icon name="arrow-right-default" class="arrow-icon"></nv-icon>
        <nv-dropdown
          class="support-form-single-dropdown"
          [options]="termPickerEndOptions"
          [selected]="selectedTerms.endTerm.option.key"
          (selectOption)="onTermPickerSelect($event, 'end')"
        ></nv-dropdown>
      </div>
    </nv-form-field>

      <!-- DATE PICKERS - depends on Frequency chosen -->
      <div class="field-wrapper" *ngIf="selectedRepeats && frequencyIsOnce">
        <label for="date-picker">Date</label><span id="date-picker"></span>
        <nv-date-picker
          [dateControl]="supportForm.controls.set2.controls.date"
          [startLimit]="selectedTerms.startTerm.termStartDate"
          [endLimit]="selectedTerms.endTerm.termEndDate"
          [isDisabled]="disableDateChange"
        ></nv-date-picker>
      </div>

      <div class="field-wrapper" *ngIf="selectedRepeats && !frequencyIsOnce">
        <label for="date-range-picker">Start and end time</label><span id="date-range-picker"></span>
        <nv-date-range-picker
          [dateRangeForm]="supportForm.controls.set2.controls.dateRange"
          [startLimit]="selectedTerms.startTerm.termStartDate"
          [startMaxLimit]="selectedTerms.startTerm.termEndDate"
          [endMinLimit]="selectedTerms.endTerm.termStartDate"
          [endLimit]="selectedTerms.endTerm.termEndDate"
          [isDisabled]="disableDateChange"
        ></nv-date-range-picker>
      </div>

      <!--  time range picker -->
      <div class="field-wrapper">
        <label for="time-range-picker">Start and end time</label><span id="time-range-picker"></span>
        <nv-time-range-picker
          [ngClass]="{ disabled: disableDateChange }"
          [timeRangeForm]="supportForm.controls.set2.controls.timeRangeForm"
          [hasClearBtn]="true"
          [rangeStartTime]="rangeStartTime"
          [rangeEndTime]="rangeEndTime"
          [interval]="interval"
          [isDisabled]="disableDateChange"
        ></nv-time-range-picker>
      </div>

      <!-- location text -->
      <div class="field-wrapper">
        <label for="location">Location</label><span id="location"></span>
        <nv-textbox
          class="width-100"
          [textControl]="supportForm.controls.set2.controls.location"
          [placeholder]="'Enter a location'"
          [hasClearBtn]="hasClearBtn"
          (clearValue)="clearField('set2', 'location')"
          icon="location-large-black"
          [showInvalid]="supportForm.controls.set2.controls.location.invalid"
          [showInvalidOnPristine]="!supportForm.controls.set2.controls.location.invalid"
        ></nv-textbox>
        <div
          class="warning-message"
          *ngIf="
            !supportForm.controls.set2.controls.location.pristine &&
            supportForm.controls.set2.controls.location.invalid &&
            supportForm.value.set2.location.length > 150
          "
        >
          <div class="warning-icon"><nv-icon [name]="warningIcon"></nv-icon></div>
          <div class="warning-text">Location field must contain less than {{ maxCharacters }} characters</div>
        </div>
      </div>
    </div>

    <mat-dialog-actions *ngIf="buttons" class="dialog-actions">
      <nv-button (click)="close()" type="secondary">Cancel</nv-button>
      <nv-button
        [disabled]="supportForm.controls.set1.invalid"
        [ngClass]="{ disabled: supportForm.controls.set1.invalid }"
        *ngIf="fieldSet1IsActive"
        (click)="nextFieldSet(supportForm)"
      >
        Next
      </nv-button>

      <nv-button
        [ngClass]="{ disabled: supportForm.invalid || disableFormSubmit }"
        *ngIf="fieldSet2IsActive"
        (click)="submit(supportForm)"
      >
        {{ formVariant.actionButtonLabel }}
      </nv-button>
    </mat-dialog-actions>
  </form>
</base-modal>
