import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControlName, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';
import { ISchool } from '../../typings/interfaces/school.interface';
import { BaseModalComponent } from '../base-modal.component';
import { IBaseModalData } from '../modals.service';
import { IGapPlan } from '../../typings/interfaces/gap-plan.interface';
import { ICourseDiff } from '../../typings/interfaces/course-diff.interface';
import { Observable } from 'rxjs';
import { ApiService } from '../../services/api-service/api-service';

export enum ModalType {
  /* eslint-disable */
  Initial = 'INITIAL',
  CourseScheduled = 'COURSE_SCHEDULED',
  CourseManual = 'COURSE_MANUAL',
}

export interface ICoursePlansModalComponentData extends IBaseModalData {
  school: ISchool;
  isProfileMode?: boolean;
  studentIds: string[];
  gapPlan?: IGapPlan;
  courseDiff?: ICourseDiff;
}
@Component({
  selector: 'course-plans-modal-shell',
  templateUrl: './course-plans-modal-shell.component.html',
  styleUrls: ['./course-plans-modal-shell.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CoursePlansModalShellComponent extends BaseModalComponent implements OnInit {
  public schoolId: string;
  public school: ISchool;
  public district: string;
  public modalType: ModalType;

  // props for the radio buttons
  public form: FormGroup;
  public selectedOption: FormControlName;

  // props for base modal
  public itemCount: number;
  public itemType: string;
  public title: string;
  public titleTooltipInfo: string;
  public updateNavIcon: boolean = false;
  public schoolCourseDiffs$: Observable<Array<ICourseDiff>>;
  public schoolGapPlans$: Observable<Array<IGapPlan>>

  constructor (
    dialogRef: MatDialogRef<CoursePlansModalShellComponent>,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    @Inject(MAT_DIALOG_DATA) public data: ICoursePlansModalComponentData,
  ) {
    super(dialogRef);
  }

  public ngOnInit (): void {
    const { isProfileMode, school: { _id: schoolId, district }, studentIds } = cloneDeep(this.data);
    this.schoolCourseDiffs$ = this.apiService.getCourseDiffs({ where: JSON.stringify({ schoolId: schoolId, status: 'PENDING' }) });
    this.schoolGapPlans$ = this.apiService.getGapPlans({ where: JSON.stringify({ schoolId: schoolId, status: 'ACTIVE' }) });
    this.title = 'Assign Course Plan';
    this.titleTooltipInfo = 'The Portal highlights students with course scheduling needs who will end the term behind in credits if their current programs are not adjusted (see "Course Scheduling Status" column). Use this modal to assign course plans to address students\' scheduling needs.';
    this.modalType = (district === 'NYC') ? ModalType.Initial : ModalType.CourseManual;
    this.itemCount = studentIds.length;
    this.itemType = 'student';
    this.district = district;
    this.isProfileMode = isProfileMode;
    this.schoolId = schoolId;
    this.form = this.formBuilder.group({ selectedOption: [ModalType.CourseScheduled] });
  }

  public close (): void {
    super.close();
  }

  public resetModalType (event: boolean): void {
    this.updateNavIcon = !event;
    this.modalType = ModalType.Initial;
  }

  public submit (form: FormGroup) {
    this.modalType = form.value.selectedOption;
    if (this.modalType === ModalType.CourseScheduled || this.modalType === ModalType.CourseManual) {
      this.updateNavIcon = true;
    }
  }
}
