<div class="resources-wrapper">
  <div class="header">Resources</div>

  <div class="content">
    <p class="sub-head">External links</p>

    <div class="links">
      <div *ngFor="let config of configs" [ngClass]="config.style" (click)="goExternal(config.link)">{{config.human}}</div>
    </div>
  </div>
</div>
