import { Action } from '@ngrx/store';
export const FETCH_OBJECT_CACHE = "[Portal] Load Object Cache Data";
export const FETCH_OBJECT_CACHE_SUCCESS = "[Portal] Load Object Cache Data Success";
export const FETCH_OBJECT_CACHE_FAIL = "[Portal] Load Object Cache Data Fail";
export const CREATE_OBJECT_CACHE = "[Portal] Create Object Cache Data";
export const CREATE_OBJECT_CACHE_FAIL = "[Portal] Create Object Cache Data Fail";
export const CREATE_OBJECT_CACHE_SUCCESS = "[Portal] Create Object Cache Data Success";

export class FetchObjectCache implements Action {
    readonly type = FETCH_OBJECT_CACHE
    constructor (public payload: any) {}
};

export class FetchObjectCacheSuccess implements Action {
    readonly type = FETCH_OBJECT_CACHE_SUCCESS
    constructor (public payload: any) {}
};

export class FetchObjectCacheFail implements Action {
    readonly type = FETCH_OBJECT_CACHE_FAIL
    constructor (public payload: any) {}
};

export class CreateObjectCache implements Action {
    readonly type = CREATE_OBJECT_CACHE
    constructor (public payload: any) {}
};

export class CreateObjectCacheSuccess implements Action {
    readonly type = CREATE_OBJECT_CACHE_SUCCESS
    constructor (public payload: any) {}
};

export class CreateObjectCacheFail implements Action {
    readonly type = CREATE_OBJECT_CACHE_FAIL
    constructor (public payload: any) {}
};
// action types
export type ObjectCacheActions =
| FetchObjectCache
| FetchObjectCacheSuccess
| FetchObjectCacheFail
| CreateObjectCache
| CreateObjectCacheSuccess
| CreateObjectCacheFail;
