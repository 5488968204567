import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { IConfirmModalComponentData } from 'Src/ng2/shared/modals/confirm/confirm-modal.component';
import { ModalsService } from 'Src/ng2/shared/modals/modals.service';

export interface ComponentCanDeactivate {
  canDeactivate: (e: any) => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
  constructor (
    private modalsService: ModalsService,
  ) {};

  canDeactivate (component: any): boolean | Observable<boolean> {
    // if there are no pending changes, just allow deactivation; else confirm first
    const canDeactivate = component.canDeactivate();
    // if canDeactivate is true, navigate without confirmation
    // if canDeactivate is false, show the confirm modal before navigating away
    return canDeactivate ? true : this.openConfirmDialog(component.confirmModalData || {});
  }

  private openConfirmDialog (data: IConfirmModalComponentData) {
    return this.modalsService
      .openConfirmModal(data)
      .afterClosed()
      .pipe(
        catchError((error) => {
          return throwError(error);
        }),
      );
  }
}
