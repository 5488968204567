import { LoadSchool } from './../../../store/actions/school-actions';
import { switchMap } from 'rxjs/operators';
import { getSchoolLoadedStatus, getSchool } from './../../../store/selectors/school-selectors';
import { Observable, of } from 'rxjs';
import { CanActivate, Router, UrlTree, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { StoreDataService } from '../../../shared/services/store-data-service/store-data.service';
import { TDistricts } from '../../../shared/typings/interfaces/district.interface';
import { isEqual } from 'lodash';

@Injectable()
export class DistrictGuard implements CanActivate {
  constructor (public router: Router, public storeDataService: StoreDataService) {}

  canActivate (snapshot: ActivatedRouteSnapshot): Observable<UrlTree | boolean> {
    const { schoolId } = snapshot.params;
    const districts: TDistricts[] = snapshot.data.districts || [];
    return this.storeDataService.loadDataToStore$({ schoolId }, getSchoolLoadedStatus, LoadSchool, getSchool).pipe(
      switchMap(({ district }) => {
        const proceed =
          !districts.length || isEqual(districts, ['all']) || districts.includes(district);
        if (proceed) {
          return of(true);
        } else {
          return of(this.router.createUrlTree(['home']));
        }
      }),
    );
  }
}
