import { createFeatureSelector, createSelector } from '@ngrx/store';

const getPortalState = createFeatureSelector('PORTAL_STORE');

export const getNetworkFociGridSlice = createSelector(
  getPortalState,
  (state: any) => state.networkFociGrid,
);

export const getNetworkFociGridLoadedState = createSelector(
  getNetworkFociGridSlice,
  (state: any) => state.loaded,
);

export const getNetworkFociGridState = createSelector(
  getNetworkFociGridSlice,
  (state: any) => state.gridState,
);
