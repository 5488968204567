import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface IAlertModalcomponentData {
  title: string;
  message: string;
  confirmText?: string;
}

@Component({
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AlertModalcomponent implements OnInit {
  title: string;
  message: string;
  confirmText: string;

  constructor (public dialogRef: MatDialogRef<AlertModalcomponent>, @Inject(MAT_DIALOG_DATA) public data) {}

  ngOnInit (): void {
    this.title = this.data.title;
    this.message = this.data.message;
    this.confirmText = this.data.confirmText || 'OK';
  }

  onConfirm (): void {
    this.dialogRef.close();
  }
}
