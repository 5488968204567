export const sdcStateConfigConstant = {
  DEFAULT_SDC: {
    humanName: '',
    state: 'main.school.new-sdc.default-sdc',
    availableSdcViews: ['grid', 'profile'],
    url: '/school/:schoolId/default-sdc',
  },

  ATTENDANCE: {
    humanName: 'Attendance',
    state: 'main.school.new-sdc.attendance',
    availableSdcViews: ['grid', 'profile'],
  },

  MARKING_PERIOD: {
    humanName: 'Marking Period',
    state: 'main.school.new-sdc.marking-period',
    availableSdcViews: ['grid', 'profile'],
  },

  GRAD_PLANNING: {
    humanName: 'Graduation Planning',
    state: 'main.school.new-sdc.grad-planning',
    availableSdcViews: ['wizard', 'grid', 'profile'],
    url: '/school/:schoolId/grad-planning',
  },

  REGENTS_PLANNING: {
    humanName: 'Regents Planning',
    state: 'main.school.new-sdc.regents-planning',
    availableSdcViews: ['wizard', 'grid', 'profile'],
    url: '/school/:schoolId/regents-planning',
  },

  REGENTS_PREP: {
    humanName: 'Regents Prep',
    state: 'main.school.new-sdc.regents-prep',
    availableSdcViews: ['wizard', 'grid', 'profile'],
    url: '/school/:schoolId/regents-prep',
  },

  CREDIT_GAPS: {
    humanName: 'Credit Gaps',
    state: 'main.school.new-sdc.credit-gaps',
    availableSdcViews: ['wizard', 'grid', 'profile'],
    url: '/school/:schoolId/credit-gaps',
  },

  PENDING_COURSE_GAP_PLAN_TABLE: {
    humanName: 'Pending Course and Gap Plans',
    state: 'main.school.program-changes-and-gap-plans',
    availableSdcViews: [],
  },

  NEXT_TERM_PLANNING_TABLE: {
    humanName: 'Next Term Planning',
    state: 'main.school.next-term-planning',
    availableSdcViews: [],
  },
};
