import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ICurrentUserState } from './../reducers/current-user-reducer';

const getPortalState = createFeatureSelector('PORTAL_STORE');

export const getCurrentUserState = createSelector(
  getPortalState,
  (state: any) => {
    return state.currentUser;
  },
);

export const getCurrentUser = createSelector(
  getCurrentUserState,
  (state: ICurrentUserState) => {
    return state.userEntity;
  },
);

export const getCurrentUserLoadingStatus = createSelector(
  getCurrentUserState,
  (state: ICurrentUserState) => {
    return state.loading;
  },
);

export const getCurrentUserLoadedStatus = createSelector(
  getCurrentUserState,
  (state: ICurrentUserState) => {
    return state.loaded;
  },
);

export const getCurrentUserErrorStatus = createSelector(
  getCurrentUserState,
  (state: ICurrentUserState) => {
    return state.error;
  },
);
