import { Injectable } from '@angular/core';

@Injectable()
export class GraphQLCollegeNowHelperService {
  public getSendInitialEmailQuery (studentId: string): string {
    const mutation = `mutation { 
      sendInitialEmail(studentId: "${studentId}")
    }`;

    return mutation;
  }
}
