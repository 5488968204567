import { Pipe, PipeTransform } from '@angular/core';
import { PATH_CATEGORY_KEYS } from '../../../../constants/student-paths.constant';
import { IACOption } from 'projects/shared/nvps-libraries/design/nv-textbox/nv-textbox.interface';
import { IStudentCollegePath } from '../../../../typings/interfaces/studentPaths.interface';

@Pipe({ name: 'excludeCollegesAlreadyPaths' })
export class ExcludeCollegesAlreadyPaths implements PipeTransform {
  transform (options: IACOption[], studentPaths: IStudentCollegePath[]): IACOption[] {
    if (studentPaths) {
      const collegePathNames = studentPaths
        .filter(({ path }) => path.pathCategory === PATH_CATEGORY_KEYS.COLLEGE)
        .map(({ path }) => path.college);

      return options.filter(
        ({ human }) => !collegePathNames.includes(human),
      );
    }
    return options;
  }
};
