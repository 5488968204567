
<div class="spinner-container">
  <div class="spinner-background-container">
    <!-- First mat-spinner is acting as circular track -->
    <mat-progress-spinner 
      class="track layer1" 
      mode="determinate" 
      value="100"
      >
    </mat-progress-spinner>
    <mat-progress-spinner
      class="layer2"
      [mode]="mode"
      [value]="progress"
    >
    </mat-progress-spinner>
  </div>
  <div class="text-center">{{data.title}}</div>
</div>



