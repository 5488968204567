import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { unsubscribeComponent } from '../../../helpers/unsubscribe-decorator/unsubscribe-decorators.helper';

@Component({
  selector: 'support-categories-pills',
  templateUrl: './support-categories-pills.component.html',
  styleUrls: ['./support-categories-pills.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
@unsubscribeComponent
export class SupportCategoriesPills implements OnInit {
  @Input() input: any;
  @Output() output: any = new EventEmitter();

  categories: string[] = [];

  ngOnInit (): void {
    if (this.input && this.input.column && this.input.column.data) {
      this.categories = this.input.column.data;
    }
  }
}
