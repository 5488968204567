import { NgModule } from '@angular/core';
import { NvTextboxModule as NvSharedTextboxModule } from 'projects/shared/nvps-libraries/design/nv-textbox/nv-textbox.module';


@NgModule({
  imports: [
    NvSharedTextboxModule,
  ],
  exports: [
    NvSharedTextboxModule,
  ],
})
export class NvTextboxModule {}
