import { CommonModule } from '@angular/common';
import { RowGroupChildCellRenderer } from './row-group-child-cell-renderer.component';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [CommonModule],
  exports: [RowGroupChildCellRenderer],
  declarations: [RowGroupChildCellRenderer],
})
export class RowgGroupChildCellRendererModule { }
