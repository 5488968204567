import { Component, ViewEncapsulation } from '@angular/core';
import { ILoadingOverlayAngularComp } from '@ag-grid-community/angular';
import { ILoadingOverlayParams } from '@ag-grid-community/core';
import { skeletonDataGridLineTheme } from 'Src/ng2/shared/constants/skeleton-loader.constant';

@Component({
  selector: 'loading-overlay',
  templateUrl: './loading-overlay.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class LoadingOverlayComponent implements ILoadingOverlayAngularComp {
  public params!: ILoadingOverlayParams & { loadingMessage: string };
  public skeletonDataGridTheme = skeletonDataGridLineTheme;

  agInit (params: ILoadingOverlayParams & { loadingMessage: string }): void {
    this.params = params;
  }
}
