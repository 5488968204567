// TODO UPDATE AT REGENTS ADMIN FLIP: Make sure this is up-to-date each Regents Admin
// Use RegentsPlans.JAN_[current school year end] if next regents admin is jan
// Use RegentsPlans.JUN_[current school year end]; if next regents admin is june
// Use RegentsPlans.AUG_[current school year end]; if next regents admin is aug

import { RegentsPlans } from './regents-plans.constant';
export const NextRegentsAdminDate = RegentsPlans.JAN_2025;
export const RegentsResultsAdminDate = 'Jan 2024';
export const RegentsResultsDataAsOfDate = 'Jan 22, 2024';
export const MockRegentsAdminDate = 'Spring 2024';
