// tslint:disable:max-classes-per-file
import { Action } from '@ngrx/store';
import { ColDef } from '@ag-grid-community/core';
export const CLEAR_GRID_DATA = '[Portal] Clear Grid Data';
export const LOAD_GRID_DATA = '[Portal] Load Grid Data';
export const LOAD_GRID_DATA_SUCCESS = '[Portal] Load Grid Data Success';
export const SAVE_GRID_DATA = '[Portal] Save Grid Data';
export const REFRESH_GRID_DATA = '[Portal] Refresh Grid Data';
export const REFRESH_GRID_DATA_SUCCESS = '[Portal] Refresh Grid Data Success';
export const SET_REFRESH_GRID_DATA_IN_PROGRESS = '[Portal] Set Refresh Grid Data in Progress';

export class ClearGridData implements Action {
  readonly type = CLEAR_GRID_DATA;
}

export class LoadGridData implements Action {
  readonly type = LOAD_GRID_DATA;
  constructor (public payload: any) {}
}

export class LoadGridDataSuccess implements Action {
  readonly type = LOAD_GRID_DATA_SUCCESS;
  constructor (public payload: any) {}
}

export class SaveGridData implements Action {
  readonly type = SAVE_GRID_DATA;
  constructor (public payload: any) {}
}

export class SetRefreshGridDataInProgress implements Action {
  readonly type = SET_REFRESH_GRID_DATA_IN_PROGRESS;
  constructor (public payload: {[status: string]: boolean}) {}
}

export class RefreshGridData implements Action {
  readonly type = REFRESH_GRID_DATA;
  constructor (public payload: { studentIds?: string[]; selectedColumnDefs?: ColDef[] }) {}
}

export class RefreshGridDataSuccess implements Action {
  readonly type = REFRESH_GRID_DATA_SUCCESS;
  constructor (public payload: any) {}
}

// action types
export type GridDataActions =
  | ClearGridData
  | LoadGridData
  | LoadGridDataSuccess
  | SaveGridData
  | RefreshGridData
  | RefreshGridDataSuccess
  | SetRefreshGridDataInProgress;
