<div class="input-label">
  <label [for]="_label" [ngClass]="{ 'is-invalid': isInvalid && showInvalid }">
    {{ label }}
    <ng-container *ngIf="labelIcon && labelIconTooltip">
      <nv-icon
        [color]="isInvalid && showInvalid ? 'red' : 'blue'"
        [name]="labelIcon"
        nvTooltip
        [tooltipData]="labelIconTooltip"
      ></nv-icon>
    </ng-container>
  </label>
</div>
<div #inputEl class="input-element"><ng-content class="content"></ng-content></div>
