import { set } from 'lodash';
interface IOldPatch {
  newValue: any;
  path: string;
  _ids: string[];
}

interface ISingleStudentPatch {
  _id: string;
  value: Array<object>;
  dataType: string;
}

interface INewPatch {
  patch: any;
  _ids: string[];
}

interface INewPatches {
  patches: INewPatch[];
}

export const PatchUtilities = {
  // Is used for finding the patch path and value for updating a
  // single student on the Store; currently only works with a single field
  getBottomLevelKeyandPathOfObj(obj: any, path: string = ''): { path: string; value: any } {
    if (obj && typeof obj === 'object' && !Array.isArray(obj)) {
      const key = Object.keys(obj)[0];
      if (key) {
        const value = obj[key];
        const newPath = path === '' ? path + key : path + '.' + key;
        if (typeof value === 'object' && !Array.isArray(value))
          return PatchUtilities.getBottomLevelKeyandPathOfObj(obj[key], newPath);
        else return { path: newPath, value };
      }
    }
    return { path, value: obj[path] };
  },

  // Used to correctly shape patches for BulkUpdateStudentsSuccess action
  // Will also be used in the Batch Edit Point People Bug fix
  // (JIRA Card: https://newvisions.atlassian.net/browse/PFD-5303)
  shapePatchesForStore(patches: Array<IOldPatch>): INewPatches {
    const patchesWithExpectedShape = patches.map(patch => {
      const { path, newValue, _ids } = patch;
      const newPatch = set({}, path, newValue);
      return { patch: newPatch, _ids };
    });
    return { patches: patchesWithExpectedShape };
  },

  // Workaround for Point People and other array patches
  // JIRA Card for future work: https://newvisions.atlassian.net/browse/PI-1091
  // TODO: should we follow this pattern for future supports work as well?
  validatePatch(patch: any, id: string): any {
    let newValue: any;
    let { path, value } = PatchUtilities.getBottomLevelKeyandPathOfObj(patch);
    if (Array.isArray(value)) {
      const singleStudentPatch: ISingleStudentPatch = value.find(({ _id }) => _id === id);
      if (singleStudentPatch) {
        newValue = singleStudentPatch.value;
      }
    }
    const newPatch = newValue && value !== newValue ? set({}, path, newValue) : patch;
    return newPatch;
  },
};
