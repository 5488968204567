// credit to: https://netbasal.com/automagically-unsubscribe-in-angular-4487e9853a88. (CM)

/*
  This decorator iterates over the properties on a class,
  finds any observable subscriptions that have been attached to the instance of the class (e.g. `this`)
  and unsubscribes on ngOnDestroy. (CM)

  Note: To use this decorator, the observable subscription must be reference on a property
  belonging to the class e.g. `this`.
  e.g. `this.studentEntitiesSubscription = store.select(getStudentEntities).subscription((students) => ...;`
*/
export function unsubscribeComponent(constructor): void {
  const original = constructor.prototype.ngOnDestroy;

  constructor.prototype.ngOnDestroy = function() {
    /* tslint:disable-next-line:forin */
    for (let prop in this) {
      const property = this[prop];
      if (property && typeof property.unsubscribe === 'function') {
        property.unsubscribe();
      }
    }

    if (original && typeof original === 'function') {
      original.apply(this, arguments);
    }
  };
}
