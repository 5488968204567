export const STUDENT_SEL_SUPPORTS_PANEL_COLUMNS = [
  {
    columnName: 'SEL Supports',
    columnOrder: 1,
    graphQlKey: 'SEL_SUPPORTS',
  },
  {
    columnName: 'Tier',
    columnOrder: 2,
    graphQlKey: 'TIER',
  },
  {
    columnName: 'Student support status',
    columnOrder: 3,
    graphQlKey: 'STUDENT_SUPPORT_STATUS',
  },
  {
    columnName: 'Frequency',
    columnOrder: 4,
    graphQlKey: 'FREQUENCY',
  },
  {
    columnName: 'Student start and end date',
    columnOrder: 5,
    graphQlKey: 'STUDENT_START_AND_END_DATE',
  },
];
