
import {
  LOAD_STUDENT_ACADIENCE,
  LOAD_STUDENT_ACADIENCE_FAIL,
  LOAD_STUDENT_ACADIENCE_SUCCESS,
  StudentAcadienceAction,
} from '../../actions/student-acadience-actions/student-acadience-actions';

export interface IStudentAcadienceEntity {
  COMPOSITE_SCORE: string;
  ACADIENCE_HISTORICAL_DATA_TABLE_FOR_PANEL: any
}

export interface IStudentAcadienceState {
  loading: boolean;
  loaded: boolean;
  studentAcadienceEntities: {
    [id: string]: IStudentAcadienceEntity;
  };
}

export const initialState = {
  loading: false,
  loaded: false,
  studentAcadienceEntities: {},
};

export function reducer (state = initialState, action: StudentAcadienceAction) {
  switch (action.type) {
    case LOAD_STUDENT_ACADIENCE:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case LOAD_STUDENT_ACADIENCE_SUCCESS:
      const studentAcadienceData = action.payload;
      const studentId = action.studentId;
      const mergedStudentAcadienceData: IStudentAcadienceState['studentAcadienceEntities'] = {
        ...state.studentAcadienceEntities[studentId],
        ...studentAcadienceData,
      };
      const mergedStudentAcadienceEntities = {
        ...state.studentAcadienceEntities,
        ...{ [studentId]: mergedStudentAcadienceData },
      };
      return {
        ...state,
        loading: false,
        loaded: true,
        studentAcadienceEntities: mergedStudentAcadienceEntities,
      };
    case LOAD_STUDENT_ACADIENCE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    default:
      return state;
  }
}
