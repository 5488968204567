<div id="maintenance-mode-container">
  <div class="wrapper">
    <div class="content">
      <img
        class="image"
        src="../../../../assets/v3/img/maintenance.png"
        srcset="../../../../assets/v3/img/maintenance@2x.png 2x, ../../../../assets/v3/img/maintenance@3x.png 3x"
        alt="Maintenance Mode Page"
      />
  
      <div class="message">
        <h1>Down for Maintenance</h1>
        <p>{{activeMaintenanceMessage}}</p>
      </div>
    </div>
  </div>
</div>
