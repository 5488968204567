import { Injectable } from '@angular/core';
import { IColumnStuLvl } from '../../models/list-models';

@Injectable()
export class WildcardService {
  /* CELL TOOLTIP */
 getCellTooltipCalcKey({ madlibModel, column }): string {
    const { cellTooltip, cellTooltipRequiresWildcard, cellTooltipWildcardKey } = column;
    const _wildcardKey = cellTooltipWildcardKey || (cellTooltipRequiresWildcard && madlibModel?.focus.graphQlKey);
    return this._composeKey({ graphQlKey: cellTooltip, wildcardKey: _wildcardKey });
  }

  static getCellTooltipCalcKey({ madlibModel, column }) {
    const service = new this();
    return service.getCellTooltipCalcKey({ madlibModel, column });
  }

  /* GROUPING */
 getListsGroupingCalcKey({ focusKey, grouping }): string {
    const { graphQlKey, requiresWildcard, wildcardKey } = grouping;
    const _wildcardKey = wildcardKey || (requiresWildcard && focusKey);
    return this._composeKey({ graphQlKey, wildcardKey: _wildcardKey });
  }

 getStuLvlGroupingCalcKey({ groupingOptions, groupingKey }): string {
    const stuLvlGroupingOption = groupingOptions.find(option => option.graphQlKey === groupingKey);
    const { graphQlKey, wildcardKey } = stuLvlGroupingOption;
    return this._composeKey({ graphQlKey, wildcardKey });
  }

  /* COLUMNS */
 getListsColCalcKey({ focusKey, column }): string {
    const { graphQlKey, wildcardKey, requiresWildcard } = column;
    const _wildcardKey = wildcardKey || (requiresWildcard && focusKey);
    return this._composeKey({ graphQlKey, wildcardKey: _wildcardKey });
  }

 getStuLvlColCalcKey({ column }): string {
    const { graphQlKey, wildcardKey } = column;
    return this._composeKey({ graphQlKey, wildcardKey });
  }

 getRollupColCalcKey({ column }): string {
    const { graphQlKey, wildcardKey } = column;
    return this._composeKey({ graphQlKey, wildcardKey });
  }

 getListsColsWithCalcKeysComposed({ focusKey, columns }): IColumnStuLvl[] {
    const columnsWithKeys = columns.map(column => {
      const calcKey = this.getListsColCalcKey({ focusKey, column });
      const newColumn = { ...column, graphQlKey: calcKey };
      return newColumn;
    });
    return columnsWithKeys;
  }

 getStuLvlColsWithCalcKeysComposed({ columns }): IColumnStuLvl[] {
    const columnsWithKeys = columns.map(column => {
      const calcKey = this.getStuLvlColCalcKey({ column });
      const newColumn = { ...column, graphQlKey: calcKey };
      return newColumn;
    });
    return columnsWithKeys;
  }

  private _composeKey({ graphQlKey, wildcardKey }): string {
    let composedKey = null;
    if (wildcardKey) {
      const sortedWildcards = wildcardKey
      .split(',')
      .map((k: any) => k.trim())
      .sort()
      .join(',');
      composedKey = `${graphQlKey}=${sortedWildcards}`;
    } else {
      composedKey = graphQlKey;
    }
    return composedKey;
  }
}
