import { IAttendanceRecord } from 'Src/ng2/shared/typings/interfaces/attendance-record.interface';
import { UpdateAttendanceRecord, CreateAttendanceRecord } from './../../../../store/actions/attendance-records-actions';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { ApiService } from '../../../../shared/services/api-service/api-service';
import {
  BulkCreateAttendanceRecords,
  BulkUpdateAttendanceRecords,
} from '../../../../store/actions/attendance-records-actions';
import { ISupportGroupingsPayload } from '../../../../store/actions/support-actions/support-groupings.actions';
import { generateQuery } from '../../../../store/effects/projections/support-groupings';

export interface IFilter {
  filterKey: string;
  label: string;
  filterOptions: Array<{ graphQlKey: string; label: string }>;
}

export interface IGrouping {
  graphQlKey: string;
  label: string;
}

export interface IFocus {
  graphQlKey: string;
  label: string;
  isDefault: boolean;
  filters: IFilter[];
  groupings: IGrouping[];
}

@Injectable()
export class SupportAttendanceFormDataService {
  private fociCache$: Observable<any> = null;

  constructor (public apiService: ApiService, private store: Store<any>) {}

  getSupportFocusData$ (schoolId: string, focusKey?: string): Observable<{ data: { SupportFocus: IFocus[] } }> {
    if (!this.fociCache$) {
      const payload = this.getSupportFocusQuery(schoolId, focusKey);
      this.fociCache$ = this.apiService.getStudentsGraphQL(payload).pipe(shareReplay(1));
    }
    return this.fociCache$;
  }

  getSupportGroupingData$ (payload: ISupportGroupingsPayload, eventDate: string) {
    payload.filterKeys.push(
      'NON_COLLEGE_CAREER_SUPPORTS',
      'NON_DELETED_STUDENT_SUPPORTS', // Hidden filter to not show deleted student supports
      `ATTENDANCE_RECORD_EVENT_DATE="${eventDate}"`, // Hidden filter to only get attendance records within the event date
      `EXPECTED_STUDENT_SUPPORTS="${eventDate}"`, // Hidden filter that filters out only students expected to attend this support
    );

    const query = generateQuery({ payload });
    return this.apiService.getStudentsGraphQL({ query, fetchPolicy: 'no-cache' });
  }

  getSupportFocusQuery (schoolId: string, focusKey?: string) {
    const payload = focusKey ? `schoolId:"${schoolId}", focusKey:"${focusKey}"` : `schoolId:"${schoolId}"`;

    const query = `{
      SupportFocus(${payload}) {
        graphQlKey
        label
        isDefault
        category
        categoryOrder
        filters {
          label
          filterOptions {
            graphQlKey
            label
            isDefault
            filterOptions {
              graphQlKey
              label
            }
          }
        }
        groupings {
          graphQlKey
          label
          requiresWildcard
          wildcardKey
          columns {
            graphQlKey
            label
            requiresWildcard
            wildcardKey
            columnDataFormat
            cellConfig
            cellTooltip
            headerTooltip {
              ...on SimpleTooltip {
                simpleContent
              }
              ...on TableTooltip {
                tableContent {
                  headers
                  rowData
                }
              }
            }
            cellTooltipWildcardKey
          }
        }

      }
    }`;
    return { query, fetchPolicy: 'no-cache' };
  }

  clearFociCache (): void {
    this.fociCache$ = null;
  }

  createPayloadObj (payload = {}, attendanceRecord) {
    return Object.keys(payload).reduce(
      (acc, status) => {
        const { studentIds = {}, attendanceRecordIds = [] } = payload[status];

        // Construct payload to create attendance records
        if (Object.keys(studentIds).length) {
          acc.createPayload.push({
            attendanceRecord: { ...attendanceRecord, status },
            studentIds,
          });
        }
        // Construct payload to update attendance records
        if (attendanceRecordIds.length) {
          acc.updatePayload.push({
            attendanceRecordIds,
            status,
          });
        }
        return acc;
      },
      { createPayload: [], updatePayload: [] },
    );
  }

  markAttendanceRecordStatus (payload, attendanceRecord) {
    const { createPayload, updatePayload } = this.createPayloadObj(payload, attendanceRecord);
    if (updatePayload.length) {
      this.store.dispatch(new BulkUpdateAttendanceRecords(updatePayload));
    }
    if (createPayload.length) {
      this.store.dispatch(new BulkCreateAttendanceRecords(createPayload));
    }
  }

  updateSingleAttendanceRecord (id: string, patch: { status: IAttendanceRecord['status'] }) {
    const payload = { id, patch };
    this.store.dispatch(new UpdateAttendanceRecord(payload));
  }

  createSingleAttendanceRecord ({ attendanceRecord, schoolId }) {
    const payload = { attendanceRecord, schoolId };
    this.store.dispatch(new CreateAttendanceRecord(payload));
  }
}
