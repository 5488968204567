import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { NvPusherSocketId } from './nv-pusher-interceptor';

@NgModule({})
export class NvPusherSocketIdInterceptor {
  static forRoot() {
    return {
      ngModule: NvPusherSocketIdInterceptor,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: NvPusherSocketId,
          multi: true,
        },
      ],
    };
  }
}
