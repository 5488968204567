<page-header [pageHeaderMeta]="pageHeaderMeta" [filterFormControl]="columnFilter">
  <div top-right>
    <ng-container *ngIf="selectedColumns">
      <nv-button
        [disabled]="!selectedColumns.length"
        [nvMatTooltip]="!selectedColumns.length ? 'Please select at least one column' : ''"
        nvMatTooltipClass="grid-apply-btn-tooltip"
        (clickButton)="onApplySelectedColumns()"
        >Save</nv-button
      >
    </ng-container>
  </div>

  <div middle>
    <edit-grid-columns-madlib
      [categories]="data.categories"
      [tags]="data.tags"
      (uncheckAll)="onUncheckAll()"
      (selectedFilters)="onMadlibFilter($event)"
    ></edit-grid-columns-madlib>
  </div>
</page-header>

<edit-grid-columns-list
  class="edit-grid-columns-list"
  [columns]="columns$ | async"
  [tags]="tags$ | async"
  [categories]="categories$ | async"
  [searchString]="searchString$ | async"
  (selectColumn)="selectColumn()"
  (setShowNullState)="setShowNullState($event)"
></edit-grid-columns-list>

<div *ngIf="(showNullState$ | async)" class="null-state">
  <span>No columns to display</span>
</div>
