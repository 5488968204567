/* tslint:disable */

import { Action } from '@ngrx/store';

export const LOAD_COLLEGE_PATH = '[Portal] Load College Path';
export const LOAD_COLLEGE_PATH_SUCCESS = '[Portal] Load College Path Success';
export const LOAD_COLLEGE_PATH_FAIL = '[Portal] Load College Path Fail';
export const CREATE_COLLEGE_PATH = '[Portal] Create College Path';
export const CREATE_COLLEGE_PATH_SUCCESS = '[Portal] Create College Path Success';
export const CREATE_COLLEGE_PATH_FAIL = '[Portal] Create College Path Fail';

export class LoadCollegePath implements Action {
  readonly type = LOAD_COLLEGE_PATH;
  constructor(public payload: any) {}
}

export class LoadCollegePathSuccess implements Action {
  readonly type = LOAD_COLLEGE_PATH_SUCCESS;
  constructor(public payload: any) {}
}

export class LoadCollegePathFail implements Action {
  readonly type = LOAD_COLLEGE_PATH_FAIL;
  constructor(public payload) {}
}

export type CollegePathAction = LoadCollegePath | LoadCollegePathSuccess | LoadCollegePathFail;
