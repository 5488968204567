<ng-container *ngIf="v4ModeIsOn; else oldSkin">
  <div class="base-modal-shell">
    <div class="dialog-title">
      <div>
        <h1>{{ title }}</h1>
        <div class="dialog-subtitle"><span>{{ subtitle}}</span></div>
      </div>
      <nv-icon-button [name]="iconName" (clickButton)="onCancel()"></nv-icon-button>
    </div>
    <div class="dialog-dropdown">
      <nv-form-field label="Subject"></nv-form-field>
      <nv-multi-picker
        id="subjectSelect"
        [options]="subjectOptions" 
        [(selectedOptions)]="selectedSubjects"
        placeholder="Select a subject"
        [hasAllButton]="false"
      >
      </nv-multi-picker>
      <nv-note size="small" priority="info">Only preps assigned in the portal can be removed.</nv-note>
    </div>
    <div class="dialog-actions">
      <nv-button type="secondary" (click)="onCancel()">Cancel</nv-button>
      <nv-button (click)="onConfirm()">Remove</nv-button>
    </div>
  </div>
</ng-container>
<!--TODO: v4 cleanup: remove oldSkin-->
<ng-template #oldSkin>
  <div class="base-modal-shell">
    <div class="dialog-title">
      <nv-icon-button [name]="iconName" (clickButton)="onCancel()"></nv-icon-button>
      <div>
        <h1>{{ title }}</h1>
        <div class="dialog-subtitle"><span>{{ subtitle}}</span></div>
      </div>
    </div>
    <div class="dialog-dropdown">
      <nv-form-field label="Subject"></nv-form-field>
      <nv-multi-picker
        id="subjectSelect"
        [options]="subjectOptions" 
        [(selectedOptions)]="selectedSubjects"
        placeholder="Select a subject"
        [hasAllButton]="false"
      >
      </nv-multi-picker>
      <nv-note size="small" priority="info">Only preps assigned in the portal can be removed.</nv-note>
    </div>
    <div class="dialog-actions">
      <nv-button type="secondary" (click)="onCancel()">Cancel</nv-button>
      <nv-button (click)="onConfirm()">Remove</nv-button>
    </div>
  </div>
</ng-template>