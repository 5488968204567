<base-modal class="base-modal" [title]="title" [itemCount]="itemCount" [itemType]="itemType">
  <!-- where conditional value field is a select or autocomplete that upon choice, reveals a 
  second select, input, or text area -->

  <form class="modal-variant modal-variant-conditional-value-field" [formGroup]="form" (submit)="submit(form)" >
    <mat-dialog-content>
      <nv-form-field class="search" label="Student field">
        <nv-textbox
          icon="search-large-black"
          placeholder="Find a student field..."
          [hasClearBtn]="true"
          [restrictToOptions]="true"
          [textControl]="form.controls.searchField"
          [autocompleteOptions]="filteredEditableFields$ | async"
          [returnObject]="true"
          (selectOption)="onSelectField($event)"
          (clearValue)="onClearFieldSelection()"
        ></nv-textbox>
      </nv-form-field>

      <ng-container *ngIf="field">
        <nv-form-field class="edit-value" [label]="editLabel">
          <batch-edit-modal-input
            [field]="field"
            (newValueChange)="onNewValueChange($event)"
          ></batch-edit-modal-input>
        </nv-form-field>
      </ng-container>
    </mat-dialog-content>

    <!-- 
        Previously, we were using *ngIf="form.valid" to determine whether required fields were selected - 
        if not, the button would be disabled. However, if a user selection is cleared, 
        it is unclear how to update the form validity without resetting the entire form, which IS an option,
        but doing so seems to lead to another issue where the reset form is immediately filtering the dropdown 
        options using the users previous choice.

        That said, it doesn't seem necessary to use the Validators.required function on the form controls
        anyways since that does nothing other than determine whether a required field has been selected,
        and we are doing that using `fieldSelected` which accomplishes the same thing - JYR
    -->
    <mat-dialog-actions class="dialog-actions">
      <nv-button *ngIf="buttons.cancel" type="secondary" (clickButton)="cancel()">
        {{ buttons.cancel.text }}
      </nv-button>
      
      <!-- No click handler. The submit button click event fires the form's onsubmit event -->
      <nv-button
        *ngIf="buttons.confirm"
        type="primary"
        htmlType="submit"
        [disabled]="!isValueSelected"
      >
        <ng-container [ngPlural]="itemCount">
          Edit {{ itemCount }} 
          <ng-template ngPluralCase="=1">student</ng-template>
          <ng-template ngPluralCase="other">students</ng-template>
        </ng-container>
      </nv-button>
    </mat-dialog-actions>
  </form>
</base-modal>
