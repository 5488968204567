import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { WizardFilterActionsModule } from 'Src/ng2/school/sdc/wizard-filter-actions/wizard-filter-actions.module';
import { NgModule } from '@angular/core';
import { ReadinessElaStep } from './readiness-ela-step.component';

@NgModule({
  imports: [WizardFilterActionsModule, MatButtonModule, CommonModule],
  exports: [ReadinessElaStep],
  declarations: [ReadinessElaStep],
  providers: [],
})
export class ReadinessElaStepModule { }
