import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { IStudentGroupingsPayload } from 'Src/ng2/store/actions/student-groupings-actions/student-groupings-actions';
import { generateQuery } from 'Src/ng2/store/effects/projections/student-groupings';
import { ApiService } from '../../../../shared/services/api-service/api-service';

export interface IFilter {
  filterKey: string;
  label: string;
  filterOptions: Array<{ graphQlKey: string; label: string }>;
}

export interface IGrouping {
  graphQlKey: string;
  label: string;
}

export interface IFocus {
  graphQlKey: string;
  label: string;
  isDefault: boolean;
  category: string;
  categoryOrder: string;
  fociOrder: string;
  filters: IFilter[];
  groupings: IGrouping[];
}

@Injectable()
export class FormativeAssessmentsDataService {
  private fociCache$: Observable<any> = null;

  constructor (public apiService: ApiService) {}

  getFormativeAssessmentsFocusData$ (schoolId: string, focusKey?: string): Observable<{ data: { FormativeAssessmentsFocus: IFocus[] } }> {
    const payload = this.getFormativeAssessmentsFocusQuery(schoolId, focusKey);

    if (!focusKey) {
      if (!this.fociCache$) this.fociCache$ = this.apiService.getStudentsGraphQL(payload).pipe(shareReplay(1));
      return this.fociCache$;
    } else {
      return this.apiService.getStudentsGraphQL(payload);
    }
  }

  getFormativeAssessmentsGroupingData$ (payload: {
    schoolId: string;
    filterKeys: string[];
    groupingKey: string;
    columns: IStudentGroupingsPayload['columns'];
  }) {
    // only want active students, so we append this filter behind the scenes
    payload.filterKeys.push('ACTIVE_ONLY');

    const query = generateQuery({ payload });
    return this.apiService.getStudentsGraphQL({ query, fetchPolicy: 'no-cache' });
  }

  getFormativeAssessmentsFocusQuery (schoolId: string, focusKey?: string) {
    const payload = focusKey ? `schoolId:"${schoolId}", focusKey:"${focusKey}"` : `schoolId:"${schoolId}"`;

    const query = `{
      FormativeAssessmentsFocus(${payload}) {
        graphQlKey
        label
        key
        human
        options {
          key
          human
          graphQlKey
          pillPlaceholder
          isDefault
        }
        filters {
          key
          human
          graphQlKey
          label
          isDefault
          options {
            key
            human
            graphQlKey
            label
            isDefault
            options {
              graphQlKey
              key
              human
              label
            }
          }
        }
        groupings {
          key
          human
          graphQlKey
          label
          requiresWildcard
          wildcardKey
          groupingSubgroups {
            label
            headerTooltip {
              ...on SimpleTooltip {
                simpleContent
              }
              ...on TableTooltip {
                tableContent {
                  headers
                  rowData
                }
              }
            }
          }
          columns {
            graphQlKey
            label
            requiresWildcard
            wildcardKey
            columnDataFormat
            cellConfig
            cellTooltip
            headerTooltip {
              ...on SimpleTooltip {
                simpleContent
              }
              ...on TableTooltip {
                tableContent {
                  headers
                  rowData
                }
              }
            }
            cellTooltipRequiresWildcard
            cellTooltipWildcardKey
          }
        }
      }
    }`;
    return { query, fetchPolicy: 'no-cache' };
  }

  clearFociCache (): void {
    this.fociCache$ = null;
  }
}
