// Refers to the column data type used in list view component, which takes
// in a JSON object of columns and their data. This constant helps in rendering
// the data, which can be displayed as-is or might need further processing to
// include custom styling/formating
export enum COLUMN_DATA_TYPE {
  RAW = 'RAW',
  GENERIC = 'GENERIC',
  SECTION_HEADER = 'SECTION_HEADER',
  FLAG = 'FLAG',
  GRADIENT = 'GRADIENT',
  GRADIENT_CUSTOM = 'GRADIENT_CUSTOM',
  PERCENTAGE = 'PERCENTAGE',
  DATE = 'DATE',
  POINT_PERSON = 'POINT_PERSON',
  OTHER_STAFF = 'OTHER_STAFF',
  ARRAY = 'ARRAY',
  FORMATTED_DATA = 'FORMATTED_DATA',
  MILESTONE = 'MILESTONE',
  STUDENT_PATH = 'STUDENT_PATH',
  CUNY_COLLEGE_READINESS = 'CUNY_COLLEGE_READINESS',
  IREADY = 'IREADY',
  STATE_EXAM = 'STATE_EXAM',
  READING_GROWTH = 'READING_GROWTH',
  MORE_INFO = 'MORE_INFO',
  F_P_SCORE = 'F_P_SCORE',
  SUPPORT_ACTIONS = 'SUPPORT_ACTIONS',
}

// Use this constant to represent cells where the value is a
// calculation tied to a specific path (JYR)
export enum COLUMN_CALCULATION_TYPE {
  MILESTONE = 'MILESTONE',
  STUDENT_PATH = 'STUDENT_PATH',
  FINANCIAL_AID = 'FINANCIAL_AID',
}
