<div class="list-content-wrapper">
  <div #listContent class="list-content">
    <page-header [pageHeaderMeta]="pageHeaderMeta" [filterFormControl]="filterFormControl"></page-header>
    <div class="batch-edit-tool-bar" *ngIf="(batchActionsSelectedIds$ | async).length !== 0">
      <div class="selected-text">{{ (batchActionsSelectedIds$ | async).length }} SELECTED</div>
      <div>&#124;</div>
      <div>
        <nv-button size="small" type="secondary" icon="pencil-small-blue" [matMenuTriggerFor]="dropdownMenu">Edit attendance
        </nv-button>
        <mat-menu #dropdownMenu="matMenu" panelClass="nv-mat-menu">
          <nv-dropdown-item *ngFor="let option of batchEditOptions" [option]="option"
            (selectItem)="onBatchEditAttendanceStatus($event)">
          </nv-dropdown-item>
        </mat-menu>
      </div>
      <div>
        <nv-button size="small" type="secondary" icon="reset-small-blue" (clickButton)="onUncheckAll()">Uncheck selections
        </nv-button>
      </div>
    </div>
    <!-- fixed to infinite list entry -->
    <ng-container #entry></ng-container>
  </div>
</div>