import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { NvIconModule } from 'projects/shared/nvps-libraries/design/nv-icon/nv-icon.module';
import { NvBoxButtonComponent } from './nv-box-button.component';

@NgModule({
  declarations: [NvBoxButtonComponent],
  exports: [NvBoxButtonComponent],
  imports: [CommonModule, MatRippleModule, NvIconModule],
})
export class NvBoxButtonModule {}
