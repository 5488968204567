import { IModalsConfig } from './../modals.config.interface';

export enum FlagIgnoreModalRadioButtonChoices {
  OtherAction = "I've already taken care of this",
  Ignore = 'This is not an issue',
}

export const FLAG_IGNORE_MODAL_CONFIG: IModalsConfig = {
  radiobuttons: {
    choices: [
      {
        text: FlagIgnoreModalRadioButtonChoices.OtherAction,
        value: FlagIgnoreModalRadioButtonChoices.OtherAction,
      },
      {
        text: FlagIgnoreModalRadioButtonChoices.Ignore,
        value: FlagIgnoreModalRadioButtonChoices.Ignore,
      },
    ],
    defaultVal: FlagIgnoreModalRadioButtonChoices.Ignore,
  },
  textarea: {
    placeholder: 'Leave a note...',
  },
  buttons: {
    confirm: {
      text: 'Ignore',
      disableButtonRipple: true,
    },
    cancel: {
      text: 'Cancel',
      disableButtonRipple: true,
    },
  },
};
