import { CurrentSchoolYear } from './../../../../shared/constants/current-school-year.constant';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { ApiService } from '../../../../shared/services/api-service/api-service';
import { ISupportGroupingsPayload } from '../../../../store/actions/support-actions/support-groupings.actions';
import { generateQuery } from '../../../../store/effects/projections/support-groupings';

export interface IFilter {
  filterKey: string;
  label: string;
  filterOptions: Array<{ graphQlKey: string; label: string }>;
}

export interface IGrouping {
  graphQlKey: string;
  label: string;
}

export interface IFocus {
  graphQlKey: string;
  label: string;
  isDefault: boolean;
  filters: IFilter[];
  groupings: IGrouping[];
}

@Injectable()
export class StudentSupportsPanelListDataService {
  private fociCaches = {
    SUPPORT_STUDENT_PROFILE: null,
    STUDENT_SUPPORT_HISTORICAL_DATA: null,
  };

  constructor (public apiService: ApiService, private store: Store<any>) {}

  getSupportFocusData$ (schoolId: string, focusKey?: string): Observable<{ data: { SupportFocus: IFocus[] } }> {
    if (!this.fociCaches[focusKey]) {
      const payload = this.getSupportFocusQuery(schoolId, focusKey);
      this.fociCaches[focusKey] = this.apiService.getStudentsGraphQL(payload).pipe(shareReplay(1));
    }
    return this.fociCaches[focusKey];
  }

  getSupportGroupingData$ (payload: ISupportGroupingsPayload, studentId: string, showAllSchoolYears: boolean) {
    // Hidden filter to not show deleted supports and student supports
    // and to only fetch records for the student
    payload.filterKeys = [
      `STUDENT_SUPPORTS_STUDENT="${studentId}"`,
      `ATTENDANCE_RECORD_STUDENT="${studentId}"`,
      'NON_DELETED_SUPPORTS',
      'NON_DELETED_STUDENT_SUPPORTS',
      'NON_COLLEGE_CAREER_SUPPORTS',
    ];

    if (!showAllSchoolYears) {
      payload.filterKeys.push(`SUPPORT_SCHOOL_YEARS="${CurrentSchoolYear.WITH_SY_PREFIX}"`);
    }

    const query = generateQuery({ payload });
    return this.apiService.getStudentsGraphQL({ query, fetchPolicy: 'no-cache' });
  }

  getAllHistoricalData$ (payload: ISupportGroupingsPayload, studentId: string) {
    payload.groupingKey = `STUDENT_SUPPORT_HISTORICAL_DATA`;

    payload.filterKeys = [
      `STUDENT_SUPPORTS_STUDENT="${studentId}"`,
      'NON_DELETED_SUPPORTS',
      'NON_DELETED_STUDENT_SUPPORTS',
    ];

    const query = generateQuery({ payload });
    return this.apiService.getStudentsGraphQL({ query, fetchPolicy: 'no-cache' });
  }

  getSupportFocusQuery (schoolId: string, focusKey?: string) {
    const payload = focusKey ? `schoolId:"${schoolId}", focusKey:"${focusKey}"` : `schoolId:"${schoolId}"`;

    const query = `{
      SupportFocus(${payload}) {
        graphQlKey
        label
        isDefault
        category
        categoryOrder
        filters {
          label
          filterOptions {
            graphQlKey
            label
            isDefault
            filterOptions {
              graphQlKey
              label
            }
          }
        }
        groupings {
          graphQlKey
          label
          requiresWildcard
          wildcardKey
          columns {
            graphQlKey
            label
            requiresWildcard
            wildcardKey
            columnDataFormat
            cellConfig
            cellTooltip
            headerTooltip {
              ...on SimpleTooltip {
                simpleContent
              }
              ...on TableTooltip {
                tableContent {
                  headers
                  rowData
                }
              }
            }
            cellTooltipWildcardKey
          }
        }

      }
    }`;
    return { query, fetchPolicy: 'no-cache' };
  }

  clearFociCache (): void {
    this.fociCaches.STUDENT_SUPPORT_HISTORICAL_DATA = null;
    this.fociCaches.SUPPORT_STUDENT_PROFILE = null;
  }
}
