import { Action } from '@ngrx/store';
import { TStudentAcadienceCalcConfig } from 'Src/ng2/shared/services/graphql-helpers/student-acadience/student-acadience-queries.service';
import { IStudentAcadienceEntity } from '../../reducers/student-acadience-reducer/student-acadience-reducer';
export const LOAD_STUDENT_ACADIENCE = '[Portal] Load StudentAcadience';
export const LOAD_STUDENT_ACADIENCE_SUCCESS = '[Portal] Load StudentAcadience Success';
export const LOAD_STUDENT_ACADIENCE_FAIL = '[Portal] Load StudentAcadience Failure';

export interface ILoadStudentAcadiencePayload {
  schoolId: string;
  studentId: string;
  calcConfig?: TStudentAcadienceCalcConfig[];
}

export class LoadStudentAcadience implements Action {
  readonly type = LOAD_STUDENT_ACADIENCE;
  constructor (public payload: ILoadStudentAcadiencePayload) {}
}

export class LoadStudentAcadienceSuccess implements Action {
  readonly type = LOAD_STUDENT_ACADIENCE_SUCCESS;
  constructor (public studentId: string, public payload: IStudentAcadienceEntity) {}
}

export class LoadStudentAcadienceFail implements Action {
  readonly type = LOAD_STUDENT_ACADIENCE_FAIL;
  constructor (public payload: any) {}
}

// action types
export type StudentAcadienceAction =
  | LoadStudentAcadience
  | LoadStudentAcadienceSuccess
  | LoadStudentAcadienceFail;
