import { Toggles } from 'Src/ng2/shared/constants/toggles.constant';
import { GOOGLE_CLASSROOM_MIDLEVEL } from '../../../shared/constants/google-classroom.constant';
import { REMOTE_INTERACTION_MIDLEVEL } from '../../../shared/constants/remote-interaction.constant';
import { NYCT_DASH_MIDLEVEL } from '../../../shared/constants/nyct-dash.constant';
import { LoadToggles } from '../../../store/actions/toggle-actions';
import { tap, filter, switchMap, take } from 'rxjs/operators';
import { getToggleLoadedStatus, getToggleEntities } from '../../../store/selectors/toggles-selectors';
import { Store, select } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UrlPathService } from 'Src/ng2/shared/services/url-path-service/url-path.service';

@Injectable()
export class DashboardToggleGuard implements CanActivate {
  constructor (
    private store: Store<any>,
    private router: Router,
    private urlPathService: UrlPathService,
  ) {}

  getTogglesFromStoreOrAPI (currentSchoolId: string): Observable<any> {
    return this.store.pipe(
      select(getToggleLoadedStatus),
      tap(loaded => {
        if (!loaded) this.store.dispatch(new LoadToggles({ currentSchoolId }));
      }),
      filter(loaded => loaded),
      switchMap(() => this.store.pipe(select(getToggleEntities))),
      take(1),
    );
  }

  canActivate (snapshot: ActivatedRouteSnapshot):Observable<boolean | UrlTree> {
    const { schoolId } = snapshot.params;
    const focus = snapshot.queryParams.focus;
    const toggle = this.getDashboardToggle(focus);

    if (!toggle) return of(true);

    const toggles$ = this.getTogglesFromStoreOrAPI(schoolId);
    return toggles$.pipe(
      switchMap(toggles => {
        const hasAccess = toggles[toggle];
        if (!hasAccess) {
          const url = this.urlPathService.computeDistrictUrlPath(`/school/${schoolId}/lists/tiles`);
          return of(this.router.createUrlTree([url]));
        } else return of(true);
      }));
  }

  private getDashboardToggle (focusName: string): string {
    const isNYCTFocus = NYCT_DASH_MIDLEVEL[focusName];
    const isRemoteInteractionFocus = REMOTE_INTERACTION_MIDLEVEL[focusName];
    const isGoogleClassroomFocus = GOOGLE_CLASSROOM_MIDLEVEL[focusName];
    if (isNYCTFocus) return Toggles.TOGGLE_NYCT_MVP;
    else if (isRemoteInteractionFocus) return Toggles.TOGGLE_REMOTE_INTERACTION;
    else if (isGoogleClassroomFocus) return Toggles.TOGGLE_GOOGLE_CLASSROOM;
  };
}
