import { NgModule } from '@angular/core';
import { TrendsVizComponent } from './trends-viz.component';
import { CommonModule } from '@angular/common';
import { VizD3Service } from '../../services/viz-services/viz-d3-service';
import { VizDataService } from '../../services/viz-services/viz-data-service';

@NgModule({
  declarations: [TrendsVizComponent],
  imports: [CommonModule],
  exports: [TrendsVizComponent],
  providers: [VizD3Service, VizDataService],
})
export class TrendsVizModule {}
