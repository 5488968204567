import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvButtonModule } from 'projects/shared/nvps-libraries/design/nv-button/nv-button.module';
import { BaseModalModule } from '../base-modal.module';
import { BehavioralIncidentDetailModal } from './behavioral-incident-detail.component';

@NgModule({
  declarations: [BehavioralIncidentDetailModal],
  imports: [BaseModalModule, CommonModule, NvButtonModule],
  exports: [BehavioralIncidentDetailModal],
})
export class BehavioralIncidentDetailModalModule {}
