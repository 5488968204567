import * as rollupGroupingActions from '../../actions/rollup-grouping-actions/rollup-grouping-actions';

export const initialState = {
  loading: false,
  loaded: false,
  groupingData: {},
};

export function reducer(state = initialState, action: rollupGroupingActions.RollupGroupingAction) {
  switch (action.type) {
    case rollupGroupingActions.LOAD_ROLLUP_GROUPING:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case rollupGroupingActions.RESET_ROLLUP_GROUPING:
      return { loaded: false, loading: false, groupingData: state.groupingData };

    case rollupGroupingActions.LOAD_ROLLUP_GROUPING_SUCCESS:
      const groupingData = action.payload;
      return {
        ...state,
        loading: false,
        loaded: true,
        groupingData: { ...groupingData },
      };
    default:
      return state;
  }
}
