import * as studentGroupingsActions from '../../actions/student-groupings-actions/student-groupings-actions';

export const initialState = {
  loading: false,
  loaded: false,
  studentGroupingsData: [],
};

export function reducer(state = initialState, action: studentGroupingsActions.StudentGroupingsAction) {
  switch (action.type) {
    case studentGroupingsActions.LOAD_STUDENT_GROUPINGS:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case studentGroupingsActions.RESET_STUDENT_GROUPINGS:
      return {
        loading: false,
        loaded: false,
        studentGroupingsData: state.studentGroupingsData, // TODO: set as a reference.
      };

    case studentGroupingsActions.LOAD_STUDENT_GROUPINGS_SUCCESS:
      const studentGroupingsData = action.payload;
      return {
        ...state,
        loading: false,
        loaded: true,
        studentGroupingsData,
      };
    default:
      return state;
  }
}
