import { map } from 'rxjs/operators';
import { ApiService } from '../../../shared/services/api-service/api-service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ContactInfoService {
  constructor (private apiService: ApiService) {}

  public findIsContactRestricted ({ firstName, lastName, phoneNumber }): Observable<{ fullName: boolean; phoneNumber: boolean; }> {
    const query = `{
        IsContactInfoOnNoContactList(firstName: "${firstName}", lastName: "${lastName}", phoneNumber: "${phoneNumber}") {
          fullName
          phoneNumber
        }
      }`;
    const payload = { query, fetchPolicy: 'no-cache' };
    return this.apiService.findIsContactRestricted(payload).pipe(
      map((res: any) => res.data.IsContactInfoOnNoContactList),
    );
  }
}
