import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridModule } from '@ag-grid-community/angular';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';

import { TasksNotesActivityTableComponent } from './tasks-notes-activity-table.component';
import { MenuCellRendererModule } from '../server-side-grid/menu-cell-renderer/menu-cell-renderer.module';
import { PillCellRendererModule } from '../server-side-grid/pill-cell-renderer/pill-cell-renderer.module';
import { PaginatorControlsModule } from '../server-side-grid/paginator/paginator-controls.module';
import { NvIconModule } from 'projects/shared/nvps-libraries/design/nv-icon/nv-icon.module';
import { NvButtonModule } from 'projects/shared/nvps-libraries/design/nv-button/nv-button.module';
import { NvSegmentedControlModule } from '../../../../nvps-libraries/design/nv-segmented-control/nv-segmented-control.module';
import { StudentPanelService } from 'Src/ng2/student/common-panels/student-panel.service';

@NgModule({
  declarations: [TasksNotesActivityTableComponent],
  imports: [
    CommonModule,
    AgGridModule,
    MatMenuModule,
    NvSegmentedControlModule,
    NvButtonModule,
    NvIconModule,
    MatIconModule,
    PaginatorControlsModule,
    MenuCellRendererModule,
    PillCellRendererModule,
  ],
  exports: [TasksNotesActivityTableComponent],
  providers: [StudentPanelService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class TasksNotesActivityTableModule {}
