import { BACKGROUND_JOB_TIMEOUT_THRESHOLDS, BACKGROUND_JOB_STATUS_TYPES } from './../../shared/services/background-job/background-job.service';
import { BatchActionsEffectsUtilities } from './../utilities/batch-actions-effects-utilities';
import { ModalsService } from './../../shared/modals/modals.service';
import { forwardRef, Inject, Injectable, Injector } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, switchMap, take, tap, map, timeout, filter } from 'rxjs/operators';
import { NvToastComponent } from '../../../nvps-libraries/design/nv-toast/nv-toast.component';
import { ApiService } from '../../shared/services/api-service/api-service';
import { BackgroundJob } from '../../shared/services/background-job/background-job.service';
import { IDataLoadParams } from '../../shared/typings/interfaces/data-load.interface';
import { LoadAllStudentPaths } from '../actions';
import * as dataLoadsActions from '../actions/data-loads-actions';
import { identity } from 'lodash';

@Injectable()
export class DataLoadsEffects {
  constructor (
    private actions$: Actions,
    private apiService: ApiService,
    private backgroundJob: BackgroundJob,
    private snackBar: MatSnackBar,
    @Inject(forwardRef(() => ModalsService)) private modalsService: ModalsService,
    private injector: Injector,
    private store: Store<any>,
  ) {}

  snackBarRef: MatSnackBarRef<NvToastComponent>;

  loadDataLoads$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dataLoadsActions.LOAD_DATA_LOADS),
      switchMap((action: { type: string; payload: any }) => {
        const { schoolId, where } = action.payload;
        return this.apiService.getDataLoads(schoolId, where).pipe(
          switchMap((dataLoads: any) => [new dataLoadsActions.LoadDataLoadsSuccess(dataLoads)]),
          catchError(error => of(new dataLoadsActions.LoadDataLoadsFail(error))),
        );
      }),
    );
  });

  createDataLoad$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dataLoadsActions.CREATE_DATA_LOAD),
      switchMap((action: { type: string; payload: IDataLoadParams }) => {
        const { payload } = action;
        const { context } = payload;
        return this.apiService.createDataLoad(payload).pipe(
          take(1),
          filter(({ data: { createDataLoad: { backgroundJobId } } }) => backgroundJobId),
          map(response => BatchActionsEffectsUtilities.getJobSubject(this.backgroundJob, { response, jobId: response.data.createDataLoad.backgroundJobId })),
          tap(subject => {
            this.modalsService.openBackgroundJobSpinnerModal({ backgroundJobSubject: subject, title: 'Uploading CSV' });
          }),
          switchMap(identity),
          timeout(BACKGROUND_JOB_TIMEOUT_THRESHOLDS.BATCH_ACTIONS),
          tap(({ type }) => {
            if (type === BACKGROUND_JOB_STATUS_TYPES.RESOLVE) {
              const toastText =
              context === 'College list uploaded successfully';
              // re-load student paths after bulk upload (JE)
              this.store.dispatch(new LoadAllStudentPaths({ schoolId: payload.schoolId }));
              this.snackBar.openFromComponent(NvToastComponent, {
                data: {
                  toastText,
                  hasClearButton: false,
                  actionText: null,
                },
                duration: 3000,
              });
            }
          }),
          catchError(() => {
            const data = {
              message:
                'The data in the CSV file could not be applied to all students in the Data Portal. You can retry the upload which will only update data that has not yet been modified in the Data Portal.',
              title: 'Unable to complete CSV upload',
              confirmText: 'Ok',
            };
            this.modalsService.openErrorModal(data);
            return EMPTY;
          }),
        );
      }),
    );
  }, { dispatch: false });
}
