<div class="paginator-container">
    <div class="entries-info-wrapper">
        {{ getCurrentEntriesRange() }}
    </div>
    
    <div class="paginator-controls-wrapper">
        <nv-icon-button [name]="'caret-left-selected'" [disabled]="isFirstPage" (clickButton)="onPrevPage()"></nv-icon-button>
        <div class="page-info-text">Page <span class="bold-text">{{ currentPage }}</span> of <span class="bold-text">{{ totalPages }}</span></div>
        <nv-icon-button [name]="'caret-right-selected'" [disabled]="isLastPage" (clickButton)="onNextPage()"></nv-icon-button>
    </div>
</div>
