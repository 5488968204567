import { EventFormatterService } from './../../services/mixpanel/event-formatter.service';
import { MixpanelService } from 'Src/ng2/shared/services/mixpanel/mixpanel.service';
import { ColDef } from '@ag-grid-community/core';
import { debounceTime, tap } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { FormControl } from '@angular/forms';
import { IEditGridColumnFilters } from './edit-grid-columns-madlib/edit-grid-columns-madlib.component';
import { BehaviorSubject, Unsubscribable } from 'rxjs';
import { IPageHeaderMeta } from './../../components/header/page-header/page-header-meta.interface';
import { Component, Inject, OnInit, ViewChild, ViewEncapsulation, OnDestroy, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EditGridColumnsListComponent } from './edit-grid-columns-list/edit-grid-columns-list.component';
import { PORTAL_TYPES } from '../../typings/interfaces/portal.interface';
import { ToggleService } from '../../services/toggle/toggle.service';
import { Toggles } from '../../constants/toggles.constant';

export interface IEditGridColumnsModalComponentData {
  columns: any[];
  selectedColumns: any[];
  categories: string[];
  tags: string[];
  origin: keyof typeof PORTAL_TYPES;
  titleLabel?: string;
  iconName?: string;
}

@Component({
  selector: 'edit-grid-columns-modal',
  templateUrl: './edit-grid-columns-modal.component.html',
  styleUrls: ['./edit-grid-columns-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EditGridColumnsModalComponent implements OnInit, OnDestroy {
  @ViewChild(EditGridColumnsListComponent)

  private editGridColumnsListComponent: EditGridColumnsListComponent;

  public pageHeaderMeta: IPageHeaderMeta;
  public titleLabel: string;
  public iconName: string;
  public iconTooltip: string;
  public madlibFilters: IEditGridColumnFilters;
  public columnFilter: FormControl = new FormControl('');
  public formControlSub: Unsubscribable;
  public v4ModeIsOn: boolean;

  public columns$: BehaviorSubject<ColDef[]>;
  public selectedColumns: ColDef[];
  public tags$: BehaviorSubject<string[]>;
  public categories$: BehaviorSubject<string[]>;
  public searchString$: BehaviorSubject<string>;
  public showNullState$: BehaviorSubject<boolean>;
  private columnCount: number;

  constructor (
    public dialogRef: MatDialogRef<EditGridColumnsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IEditGridColumnsModalComponentData,
    private eventFormatterService: EventFormatterService,
    private mixpanelService: MixpanelService,
    private toggleService: ToggleService,
    private elementRef: ElementRef,
  ) { }

  ngOnInit (): void {
    this.toggleV4NewSkinMode();
    const selectedAllColumns = this.applySelectedToAllColumns(this.data.columns, this.data.selectedColumns);
    this.selectedColumns = this.data.selectedColumns;
    this.columnCount = this.data.columns.length;
    this.titleLabel = this.data.titleLabel || 'Edit columns';
    this.iconTooltip = (this.titleLabel === 'New view') ? 'Back' : 'Close';
    this.iconName = this.data.iconName || 'close-large-blue';

    this.pageHeaderMeta = this.getPageHeaderState();
    this.columns$ = new BehaviorSubject(selectedAllColumns);
    this.tags$ = new BehaviorSubject([]);
    this.categories$ = new BehaviorSubject([]);
    this.searchString$ = new BehaviorSubject('');
    this.showNullState$ = new BehaviorSubject(false);

    this.formControlSub = this.columnFilter.valueChanges.pipe(
      debounceTime(200),
      tap((searchTerm) => {
        this.searchString$.next(searchTerm);
      }),
    ).subscribe();
  }

  ngOnDestroy () {
    this.formControlSub.unsubscribe();
  }

  toggleV4NewSkinMode () : void {
    this.v4ModeIsOn = this.toggleService.getToggleState(Toggles.TOGGLE_V4_NEW_SKIN_MODE);
    if (this.v4ModeIsOn) {
      this.elementRef.nativeElement.classList.add('v4');
    }
  }

  // this.data.columns is always the columns in the initial state of the grid load
  // in the edit column modal list we are manually checking and selecting columns and taking all the columns
  // that means we need to manually update the columns with the selected columns to have the correct selected state
  private applySelectedToAllColumns (allColumns, selectedColumns): ColDef[] {
    const hash = selectedColumns.reduce((hash: { [key: string]: boolean }, col: ColDef) => {
      hash[col.field] = true;
      return hash;
    }, {});

    return allColumns.map((column: ColDef) => {
      const columnClone = cloneDeep(column);
      if (hash[column.field]) {
        columnClone.hide = null;
      } else {
        columnClone.hide = true;
      }
      return columnClone;
    });
  }

  public onApplySelectedColumns (): void {
    const selectedCols = this.editGridColumnsListComponent.getSelectedColumns();
    this.trackEditColumnsEvent(selectedCols);
    this.dialogRef.close(selectedCols);
  }

  private trackEditColumnsEvent (selectedCols): void {
    const oldColumns = this.data.selectedColumns.map(({ headerName }) => headerName);
    const newColumns = selectedCols.map(({ headerName }) => headerName);
    const columnsAdded = newColumns.filter(col => !oldColumns.includes(col));
    const columnsRemoved = oldColumns.filter(col => !newColumns.includes(col));
    const event = this.eventFormatterService.getGridColumnActionEvent({
      portal: this.data.origin,
      gridInfo: {
        columnsAdded,
        columnsRemoved,
      },
    });
    this.mixpanelService.trackEvents([event]);
  }

  private onCancel (): void {
    this.dialogRef.close(false);
  }

  public onUncheckAll (): void {
    this.editGridColumnsListComponent.uncheckAll();
  }

  public onMadlibFilter ($event: IEditGridColumnFilters): void {
    const { tags = [], categories = [] } = $event;
    this.categories$.next(categories);
    this.tags$.next(tags);
  }

  private getPageHeaderState (): IPageHeaderMeta {
    return {
      title: this.titleLabel,
      icon: {
        name: this.iconName,
        handler: this.onCancel.bind(this),
        tooltip: this.iconTooltip,
      },
      textboxPlaceholder: 'Find a column...',
    };
  }

  public selectColumn () {
    this.selectedColumns = this.editGridColumnsListComponent.getSelectedColumns();
  }

  public setShowNullState (count: number) {
    this.columnCount = count;
    this.showNullState$.next(this.columnCount === 0);
  }
}
