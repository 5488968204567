import { MatButtonModule } from '@angular/material/button';
import { OnTrackRegentsStep } from './on-track-regents-step.component';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [MatButtonModule],
  exports: [OnTrackRegentsStep],
  declarations: [OnTrackRegentsStep],
  providers: [],
})
export class OnTrackRegentsStepModule { }
