import { RollbarService } from './../rollbar/rollbar.service';
import { UserRolePermissionsForModelService } from 'Src/ng2/shared/services/user-role-permissions-for-model/user-role-permissions-for-model.service';
import { Inject, Injectable } from '@angular/core';
import * as _ from 'lodash';
import { CourseDiffStatuses } from 'Src/ng2/shared/constants/course-diff-statuses.constant';
import { CreditRequirements } from 'Src/ng2/shared/constants/credit-requirements.constant';
import { ImSchool } from 'Src/ng2/shared/services/im-models/im-school';
import { ICourseDiff } from 'Src/ng2/shared/typings/interfaces/course-diff.interface';
import { ICourse } from 'Src/ng2/shared/typings/interfaces/school.interface';
import * as Rollbar from 'rollbar';

const MODEL_NAME = 'COURSE_DIFF';
const relationToUserPath = 'createdBy.userId';

@Injectable()
export class ImCourseDiff {
  constructor (
    private imSchool: ImSchool,
    @Inject(RollbarService) private rollbar: Rollbar,
    private permissions: UserRolePermissionsForModelService,
  ) {
    // empty block
  }

  /**
   * Checks to see if a course in school.masterSchedule conflicts with the
   * equivalent course in school.masterSchedule belonging to a courseDiff (based on courseId)
   * A conflict occurs if the period of the two courses are the same and there is at least one overlap in cycle days
   * @param {Object} school
   * @param {String} courseId of a course on school.masterSchedule
   * @return {Object} of course on school.masterSchedule belonging to the
   * courseDiff if a conflict exists or undefined if no conflict exists
   */

  scheduleOfCourseToBeAddedConflicts (courseDiff: ICourseDiff, school, courseIdOfCourseToBeAdded) {
    if (courseDiff.action === 'ADD') {
      const masterSchedule: ICourse[] = school.masterSchedule;
      const courseOfCourseDiff: ICourse = _.find(masterSchedule, { courseId: courseDiff.courseId });

      if (!courseOfCourseDiff) {
        const warning = `CourseDiff with courseId ${courseDiff.courseId} no longer on school [${
          school._id
        }] master schedule`;
        this.rollbar.warning(new Error(warning));
        // No confict because courseDiff is no longer valid
        return null;
      }

      const courseToBeAdded: ICourse = _.find(masterSchedule, { courseId: courseIdOfCourseToBeAdded });
      const VALID_CYCLE_DAYS = ['M', 'T', 'W', 'R', 'F'];
      const periodsMatch = courseOfCourseDiff.period === courseToBeAdded.period;
      const cycleDaysMatch = _.some(courseOfCourseDiff.cycleDay, char => {
        return _.includes(VALID_CYCLE_DAYS, char) && _.includes(courseToBeAdded.cycleDay, char);
      });
      const conflictExists = periodsMatch && cycleDaysMatch;

      return conflictExists ? courseOfCourseDiff : null;
    }
    return null;
  }

  getFrontendStatus (courseDiff: ICourseDiff) {
    // only status that currently differs btw front and backend
    if (this.isDeleted(courseDiff)) return CourseDiffStatuses.frontend.REMOVED;
    else return courseDiff.status;
  }

  isDeleted (courseDiff: ICourseDiff): boolean {
    const backendStatus = courseDiff.status;
    return backendStatus === CourseDiffStatuses.backend.DELETED;
  }

  getCourseCode (courseDiff: ICourseDiff) {
    const { courseId } = courseDiff;
    const courseCode = courseId.split('-')[2];

    return courseCode;
  }

  filterForPendingCurrentTerm (courseDiffs: ICourseDiff[], currentTermYear) {
    return _.filter(courseDiffs, { termYear: currentTermYear, status: 'PENDING' });
  }

  filterForAddCourseDiffs (courseDiffs: ICourseDiff[], optFilter?) {
    const filter = { action: 'ADD' };
    if (optFilter) {
      const extendedFilter = _.assign(filter, optFilter);
      return _.filter(courseDiffs, extendedFilter);
    }
    return _.filter(courseDiffs, { action: 'ADD' });
  }

  filterForDropCourseDiffs (courseDiffs: ICourseDiff[], optFilter?) {
    const filter = { action: 'DROP' };
    if (optFilter) {
      const extendedFilter = _.assign(filter, optFilter);
      return _.filter(courseDiffs, extendedFilter);
    }
    return _.filter(courseDiffs, { action: 'DROP' });
  }

  getAddDropsForGradReq (courseDiffs: ICourseDiff[], gradReq, school) {
    const creditRequirement = _.find(CreditRequirements, { camelCase: gradReq });
    const includedCreditRequirements = creditRequirement ? creditRequirement.includedCreditRequirements : null;
    const filtered = {
      add: [],
      drop: [],
    };
    const courseDiffsGradReqs = {};

    // cache gradReq for courseDiff
    _.each(courseDiffs, courseDiff => {
      const gradReq = this.imSchool.getGradReqForCourse(school, courseDiff.courseId);

      courseDiffsGradReqs[courseDiff._id] = gradReq;
    });

    // CreditRequirements.TOTAL.camelCase is only exception
    if (gradReq === CreditRequirements.TOTAL.camelCase) {
      _.each(courseDiffs, courseDiff => {
        if (courseDiff.action === 'ADD') filtered.add.push(courseDiff);
        if (courseDiff.action === 'DROP') filtered.drop.push(courseDiff);
      });
    } else {
      _.each(includedCreditRequirements, includedGradReq => {
        _.each(courseDiffs, courseDiff => {
          if (courseDiffsGradReqs[courseDiff._id] === includedGradReq) {
            if (courseDiff.action === 'ADD') filtered.add.push(courseDiff);
            if (courseDiff.action === 'DROP') filtered.drop.push(courseDiff);
          }
        });
      });
    }

    return filtered;
  }

  getTemplate (): Partial<ICourseDiff> {
    const template: Partial<ICourseDiff> = {
      _id: null,
      student: {
        studentId: null,
        lastFirst: null,
      },
      schoolId: null,
      courseId: null,
      termYear: null,
      action: 'ADD',
    };

    return _.cloneDeep(template);
  }

  canView (user, courseDiff) {
    return this.permissions.canViewPartial(MODEL_NAME, relationToUserPath)(courseDiff, user);
  }
}
