import { IRegentsCategory, RegentsCategory } from '../../../ng2/shared/constants/regents.constant';

export const RegentsCategoryByCategory9 = {
  required: [
    RegentsCategory.ELA,
    RegentsCategory.ALGEBRA,
    RegentsCategory.GEOMETRY,
    RegentsCategory.TRIGONOMETRY,
    RegentsCategory.LIFE_SCIENCE,
    RegentsCategory.GLOBAL,
    RegentsCategory.US,
    RegentsCategory.PHYSICAL_SCIENCE,
    RegentsCategory.LOTE,
  ] as IRegentsCategory[],
  optional: [] as IRegentsCategory[],
};
