interface IMemoized {
  (func, options?): any;
  _memoize?: any;
  _isPrimitive?: any;
  unmemoized?: Function;
}

export function isPrimitive (val) {
  const type = typeof val;
  return val === null || (type !== 'object' && type !== 'function');
}

class Node {
  public val;
  private _weakMap;
  private _map;

  constructor () {
    this._weakMap = new WeakMap();
    this._map = new Map();
    this.val = undefined;
  }

  set (arg, val) {
    if (isPrimitive(arg)) {
      return this._map.set(arg, val);
    } else {
      return this._weakMap.set(arg, val);
    }
  }

  get (arg) {
    if (isPrimitive(arg)) {
      return this._map.get(arg);
    } else {
      return this._weakMap.get(arg);
    }
  }

  has (arg) {
    if (isPrimitive(arg)) {
      return this._map.has(arg);
    } else {
      return this._weakMap.has(arg);
    }
  }
}

export function memoize (func, options?) {
  const root = new Node();

  options = options || {};
  const exceptWhen = options.exceptWhen;

  const memoized: IMemoized = function (...args) {
    let node = root;
    for (let i = 0; i < args.length; i++) {
      const arg = arguments[i];
      if (exceptWhen && exceptWhen(arg, i)) {
        return func.apply(this, arguments);
      }

      node = node.get(arg);
      if (typeof node === 'undefined') {
        break;
      }
    }
    if (node && typeof node.val !== 'undefined') {
      return node.val;
    } else {
      const val = func.apply(this, arguments);
      node = root;
      for (let i = 0; i < args.length; i++) {
        const arg = arguments[i];
        let nextNode = node.get(arg);
        if (typeof nextNode === 'undefined') {
          nextNode = new Node();
          node.set(arg, nextNode);
        }
        node = nextNode;
      }
      node.val = val;
      return val;
    }
  };

  // Switched from Object.defineProperty to Reflect.defineProperty, which is supported by babel-polyfill
  // More info: https://github.com/Microsoft/TypeScript/issues/2836
  Reflect.defineProperty(memoized, 'name', {
    writable: false,
    enumerable: false,
    configurable: true,
    value: func.name + '_memoized',
  });

  memoized.unmemoized = func;

  return memoized;
}

/**
 * Decorator to mark a method as memoized
 *
 * Some notes on how this works:
 *   - When you decorate a method, it remembers the previous arguments that were used to call the method. If you
 *     call the method more than once with the same arguments, it returns the previous value of the method call
 *     instead of actually calling the method again.
 *   - When arguments are objects, they are compared for "shallow equality" (ie, reference equality, `===`).
 *     Therefore if you call this twice with the same object, but some of the props have changed, *it still
 *     returns the cached value*! This is important, and means that this decorator is most useful when the
 *     method arguments are primitives or immutable objects (or immutable arrays)
 *   - You can tell a method to not memoize some arguments that match a pattern with an `exceptWhen` option. See
 *     specs for details.
 *   - Don't just memoize everything that could be feasibly memoized, because memoization will slow down
 *     non-memoized calls. This is especially true if the function has more than one or two arguments.
 *
 * @param options {object} optional options object
 *   exceptWhen - function that takes arg and index and returns true if the return value should not be memoized
 *                when a particular argument is used
 * @return {*}
 */
export const memoized: IMemoized = function (options) {
  if (arguments.length === 3) {
    // when used like @memoized without a function call, will be called with target, key, descriptor
    const descriptor = arguments[2];
    descriptor.value = memoize(descriptor.value);
    return descriptor;
  } else {
    // otherwise, this was called like @memoized({ /* options */ })
    return function memoizedDecorator (target, key, descriptor) {
      descriptor.value = memoize(descriptor.value, options);
      return descriptor;
    };
  }
};
