import { IStudentMini } from './student.interface';
import { ISupportMini } from './support.interface';
import { IUserMini } from './user.interface';
import { TValidPartnerTypes } from './partner.interface';

export type TValidNoteStatus = 'ACTIVE' | 'DELETED';
export type TValidNoteCategories =
  'Attendance'
  | 'Course Progress'
  | 'Credits'
  | 'General'
  | 'Graduation'
  | 'Postsecondary'
  | 'Regents'
  | 'Remote Learning'
  | 'Screeners'
  | 'Supports'
  | 'ECFIK';

export interface IStudentSupportMini {
  studentSupportId: string;
  support: ISupportMini;
}

export interface IStudentAndStudentSupport {
  student: IStudentMini;
  studentSupport: IStudentSupportMini;
}

export interface INote {
  _id?: string | null;
  createdBy?: IUserMini;
  dateCreated?: string;
  lastEditedAt?: string;
  deletedAt?: string;
  deletedBy?: IUserMini;
  body: string;
  status?: TValidNoteStatus;
  contextPartnerType: TValidPartnerTypes;
}

export type IValidNoteModes = 'CREATE' | 'EDIT_PROFILE' | 'CREATE_SHELTER' | 'CREATE_BULK_SHELTER' | 'CREATE_BULK_SCHOOL' | 'EDIT_MODAL';

export enum VALID_NOTE_MODES {
  /* eslint-disable */
  CREATE = 'CREATE',
  EDIT_PROFILE = 'EDIT_PROFILE',
  EDIT_MODAL = 'EDIT_MODAL',
  CREATE_SHELTER = 'CREATE_SHELTER',
  CREATE_BULK_SHELTER = 'CREATE_BULK_SHELTER',
  CREATE_BULK_SCHOOL = 'CREATE_BULK_SCHOOL',
}

// SHELTER
export interface IShelterStudentMini {
  caresId: string;
  firstLast: string;
}

export type TValidShelterNoteCategories = 'Success Mentoring' | 'General';

export interface ISchoolNote extends INote {
  schoolId: string;
  categories: TValidNoteCategories[];
  attachments: {
    student?: IStudentMini;
    studentSupport?: IStudentSupportMini;
    support?: ISupportMini;
  };
  partnerType?: TValidPartnerTypes;
  ecfik?: IECFIKNoteFields;
}
export interface IShelterNote extends INote {
  shelterId: string;
  categories: TValidShelterNoteCategories[];
  attachments: {
    shelterStudent?: IShelterStudentMini;
    caresIds?: string[];
  };
  partnerType?: TValidPartnerTypes;
  ecfik?: IECFIKNoteFields;
}

export interface IShelterNoteBulkData {
  caresIds: string[];
  note: IShelterNote | ISchoolNote;
}

export interface IECFIKNoteFields {
  logDate: string;
  type: string;
  status: 'Completed' | 'No Response' | 'Inactive Phone';
  needs: string[];
  serviceReferral: boolean;
}
