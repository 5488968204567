import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { NvIconButtonModule } from '../nv-icon-button/nv-icon-button.module';
import { NvIconModule } from '../nv-icon/nv-icon.module';
import { NvAutocompleteActionComponent } from './nv-autocomplete-action/nv-autocomplete-action.component';
import { NvTextboxComponent } from './nv-textbox.component';
import { NgxMaskModule } from 'ngx-mask';
// pipes
import { TransformToMatDisplayOptions } from './pipes/transform-to-mat-display-options/transform-to-mat-display-options.pipe';
import { FilterMatDisplayOptions } from './pipes/filter-mat-display-options/filter-mat-display-options.pipe';
import { SortByPriorityOptions } from './pipes/sort-by-priority-options/sort-by-priority-options.pipe';
import { LimitMatDisplayOptions } from './pipes/limit-mat-display-options/limit-mat-display-options.pipe';
import { AppendEmptyStateOption } from './pipes/append-empty-state-option/append-empty-state-option.pipe';

@NgModule({
  imports: [
    CommonModule,
    NvIconModule,
    NvIconButtonModule,
    MatAutocompleteModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
  ],
  declarations: [
    NvAutocompleteActionComponent,
    NvTextboxComponent,
    TransformToMatDisplayOptions,
    FilterMatDisplayOptions,
    SortByPriorityOptions,
    LimitMatDisplayOptions,
    AppendEmptyStateOption,
  ],
  exports: [
    NvTextboxComponent,
    NvAutocompleteActionComponent,
    TransformToMatDisplayOptions,
    FilterMatDisplayOptions,
    SortByPriorityOptions,
    LimitMatDisplayOptions,
    AppendEmptyStateOption,
  ],
})
export class NvTextboxModule {}
