<div *ngIf="networkExternalFilterOptions">
  Show data for
  <nv-dropdown-box
    [options]="networkExternalFilterOptions"
    [selected]="activeNetworkExternalFilterOptions"
    (selectOption)="networkExternalFilterOptionChange.emit($event)"
  ></nv-dropdown-box>
</div>
<nv-pill-button-v4 *ngIf="!hideEditColumns"
  color="purple"
  icon="edit-column-small-purple"
  iconPosition="left"
  [text]="columnsButtonText"
  (click)="onEditColumns.emit()"
></nv-pill-button-v4>
<div #filterSummary>
  <nv-pill-button-v4
    color="purple"
    icon="filter-small-purple"
    iconPosition="left"
    [isActive]="isFilterSummaryVisible"
    [isDisabled]="isFilterButtonDisabled"
    [text]="filtersButtonText"
    (click)="onFilterSummaryClick()"
  ></nv-pill-button-v4>
</div>
<div #sortSummary>
  <nv-pill-button-v4
    color="purple"
    icon="sort-small-purple"
    iconPosition="left"
    [isDisabled]="isSortsButtonDisabled"
    [text]="sortsButtonText"
    [isActive]="isSortSummaryVisible"
    (click)="onSortSummaryClick()"
  ></nv-pill-button-v4>
</div>
