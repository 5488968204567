import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatTreeModule } from '@angular/material/tree';
import { NvSharedTooltipModule } from 'projects/shared/nvps-libraries/design/nv-tooltip/nv-shared-tooltip.module';
import { NvDropdownItemModule } from '../nv-dropdown-item/nv-dropdown-item.module';
import { NvSidebarItemModule } from '../nv-sidebar-item/nv-sidebar-item.module';
import { NvSidebarListHeaderModule } from './nv-sidebar-list-header/nv-sidebar-list-header.module';
import { NvSidebarListComponent } from './nv-sidebar-list.component';
import { NvButtonModule } from 'projects/shared/nvps-libraries/design/nv-button/nv-button.module';

@NgModule({
  imports: [
    A11yModule,
    CommonModule,
    MatMenuModule,
    MatTreeModule,
    NvDropdownItemModule,
    NvSidebarItemModule,
    NvSidebarListHeaderModule,
    NvSharedTooltipModule,
    NvButtonModule,
  ],
  declarations: [NvSidebarListComponent],
  exports: [NvSidebarListComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class NvSidebarListModule {}
