import { EventFormatterService } from './../../services/mixpanel/event-formatter.service';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { cloneDeep, filter, sortBy } from 'lodash';
import { identity, throwError, EMPTY } from 'rxjs';
import { catchError, tap, map as rxjsMap, switchMap, filter as rxjsFilter } from 'rxjs/operators';
import moment from 'moment';

import { BaseModalComponent } from '../base-modal.component';
import { IBaseModalData, ModalsService } from '../modals.service';
import { TValidPartnerTypes } from '../../typings/interfaces/partner.interface';
import { ISchool } from '../../typings/interfaces/school.interface';
import { IUser, IUserMini } from '../../typings/interfaces/user.interface';
import { ITask, IValidTaskModes, TaskStatus } from '../../typings/interfaces/task.interface';
import { IPickerOption } from 'projects/shared/nvps-libraries/design/nv-multi-picker/nv-multi-picker.interface';
import { ValidTaskModeType } from './../../typings/interfaces/task.interface';
import { ImSchool } from '../../services/im-models/im-school';
import { ImUser } from '../../services/im-models/im-user';
import { TASK_CATEGORIES_OPTIONS } from './task-modal.config';
import { ApiService } from '../../services/api-service/api-service';
import { IACOption } from '../../../../../projects/shared/nvps-libraries/design/interfaces/design-library.interface';
import { BackgroundJobNotificationService, TValidBackgroundJob } from '../../services/background-job-notification-service/background-job-notification-service';
import { CurrentSchoolYear } from '../../constants/current-school-year.constant';
import { EM_DASH } from '../../constants/em-dash.constant';
import { IStudent } from '../../typings/interfaces/student.interface';
import { BatchActionsEffectsUtilities } from 'Src/ng2/store/utilities/batch-actions-effects-utilities';
import { BACKGROUND_JOB_STATUS_TYPES, BackgroundJob } from '../../services/background-job/background-job.service';
import { TDistricts } from '../../typings/interfaces/district.interface';
import { TFormatTaskEventArgs } from '../../services/mixpanel/event-interfaces/task-action';
import { IFormatBatchActionEventArgs } from '../../services/mixpanel/event-interfaces/batch-action';
import { TBatchActionsOrigin } from '../../components/nv-actions/nv-actions.interface';

export interface IEditTaskPayload {
  _id: string;
  comment?: string;
  status?: string;
  description?: string;
  categories?: string;
  assigneeId?: string;
}

export type TStudentPanelOrigin = 'student-tasks-notes-panel' | 'student-data-grid';

export interface ITaskData extends IBaseModalData {
  mode: IValidTaskModes;
  currentUser: IUser;
  partnerType?: TValidPartnerTypes;
  school?: ISchool;
  origin?: TBatchActionsOrigin | TStudentPanelOrigin;
  studentIds?: string[];
  student?: IStudent;
  docId?: string;
  task?: ITask;
  schoolUsers?: IUser[];
  studentOptions?: any[];
  canEditTask?: boolean;
  studentData?: { studentId: string; studentName: string; };
  currentExam?: string;
}
@Component({
  selector: 'task-modal',
  templateUrl: './task-modal.component.html',
  styleUrls: ['./task-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TaskModalComponent extends BaseModalComponent implements OnInit {
  public title: 'Create task' | 'Delete task' | 'Edit task' | 'Select students' | 'Task details';
  public primaryBtnName: 'Create' | 'Delete' | 'Mark complete' | 'Next' | 'Save';
  public itemType: string = 'student';
  public itemCount: number;
  public isProfileMode: boolean;
  public mode: string;
  private studentIds: string[];
  private schoolId: string;
  private selectedUser: IUser;
  private allUsers: IUser[];
  private partnerType: TValidPartnerTypes;
  private district: TDistricts;
  private task: ITask;
  public recipientPermissionWarning: string = 'Please note this user has view only access. Viewers are able to record notes, but not edit student plans.';
  public isViewOnlyUser: boolean = false;
  private createTaskBackgroundJobResults: { validStudentIds: string[]; invalidStudentIds: string[] };
  private createTaskBackgroundJobType: TValidBackgroundJob;
  // private student: IStudent;
  private studentData: { studentId: string; studentName: string; };
  private origin: TBatchActionsOrigin | TStudentPanelOrigin;

  // Form
  public studentOptions: any[];
  public taskForm: FormGroup;
  public taskCategoriesOptions: IPickerOption[];
  public canEditComment: boolean;
  public editCommentMode: boolean;
  public showActionButtons: boolean;
  public editIsRestricted: boolean;
  public schoolUsersOptions: IACOption[];
  public deleteConfirmationMessage: string =
    'Are you sure you would like to delete this task? This task will be removed from this student\'s records.';

  constructor (
    dialogRef: MatDialogRef<TaskModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ITaskData,
    private formBuilder: FormBuilder,
    private imSchoolService: ImSchool,
    private apiService: ApiService,
    private ImUser: ImUser,
    private backgroundJob: BackgroundJob,
    private backgroundJobNotificationService: BackgroundJobNotificationService,
    public modalsService: ModalsService,
    private eventFormatterService: EventFormatterService,
  ) {
    super(dialogRef);
  }

  ngOnInit (): void {
    const data = cloneDeep(this.data);
    this.mode = data.mode;
    this.origin = data.origin;

    let isHighSchool;
    switch (this.mode) {
      case ValidTaskModeType.CREATE_SINGLE_TASK_SCHOOL:
        this.studentData = data.studentData;
        this.allUsers = data.schoolUsers;
        this.schoolId = data.school._id;
        this.partnerType = data.partnerType;
        this.district = data.school.district;
        isHighSchool = this.imSchoolService.isHighSchool(data.school);
        this.taskCategoriesOptions = isHighSchool ? TASK_CATEGORIES_OPTIONS.HS : TASK_CATEGORIES_OPTIONS.ES;
        this.itemCount = 1;
        this.isProfileMode = true;
        this.title = 'Create task';
        this.primaryBtnName = 'Create';
        this.showActionButtons = true;
        this.editIsRestricted = true;
        this._initFormControl();
        break;
      case ValidTaskModeType.CREATE_BULK_TASK_SCHOOL:
        this.studentIds = data.studentIds;
        this.allUsers = data.schoolUsers;
        this.schoolId = data.school._id;
        this.partnerType = data.partnerType;
        this.district = data.school.district;
        isHighSchool = this.imSchoolService.isHighSchool(data.school);
        this.taskCategoriesOptions = isHighSchool ? TASK_CATEGORIES_OPTIONS.HS : TASK_CATEGORIES_OPTIONS.ES;
        this.itemCount = data.studentIds.length;
        this.isProfileMode = false;
        this.title = 'Create task';
        this.primaryBtnName = 'Create';
        this.showActionButtons = true;
        this.editIsRestricted = true;
        this._initFormControl();
        break;
      case ValidTaskModeType.EDIT_TASK_SCHOOL:
        this.allUsers = data.schoolUsers;
        this.task = data.task;
        isHighSchool = this.imSchoolService.isHighSchool(data.school);
        this.taskCategoriesOptions = isHighSchool ? TASK_CATEGORIES_OPTIONS.HS : TASK_CATEGORIES_OPTIONS.ES;
        this.title = 'Edit task';
        this.isProfileMode = true;
        this.primaryBtnName = 'Save';
        this.showActionButtons = true;
        this.editIsRestricted = true;
        this._initFormControl(this.task);
        break;
      case ValidTaskModeType.DELETE_TASK_SCHOOL:
        this.task = data.task;
        this.title = 'Delete task';
        this.isProfileMode = true;
        this.primaryBtnName = 'Delete';
        this.showActionButtons = true;
        break;
      case ValidTaskModeType.TASK_DETAILS_SCHOOL:
        this.task = data.task;
        this.title = 'Task details';
        this.isProfileMode = true;

        if (this.task.status === TaskStatus.ACTIVE) {
          this.primaryBtnName = 'Mark complete';
          this.showActionButtons = true;
          if (this.origin === 'student-tasks-notes-panel') {
            const canMarkComplete = this.task.assignee.userId === this.data.currentUser._id || data.canEditTask;
            this.editIsRestricted = !canMarkComplete;
            this.editCommentMode = canMarkComplete;
          } else {
            this.editIsRestricted = false;
            this.editCommentMode = true;
          }
        } else if (this.task.status === TaskStatus.COMPLETE) {
          this.primaryBtnName = 'Save';
          this.showActionButtons = false;
          this.editCommentMode = false;
          if (this.origin === 'student-tasks-notes-panel') {
            this.editIsRestricted = !data.canEditTask;
            this.canEditComment = this.task.resolvedBy.userId === this.data.currentUser._id || data.canEditTask;
          } else {
            this.editIsRestricted = true;
            /**
             * The school user who completed the task
             * Any school admin or delegated school admin, even if they did not complete the task
             */
            this.canEditComment =
              this.task.resolvedBy.userId === this.data.currentUser._id ||
              this.ImUser.isTaskCommentEditor(this.data.currentUser);
          }
        }
        this._initTaskDetailFormControl(this.task);
        break;
      case ValidTaskModeType.CREATE_TASK_SCHOOL:
        this.editIsRestricted = true;
        this.title = 'Create task';
        this.primaryBtnName = 'Next';
        this.itemCount = 0;
        this.showActionButtons = true;
        this.studentOptions = data.studentOptions;
        this.taskForm = this.formBuilder.group({
          studentOptions: [data.studentOptions, Validators.required],
        });
        break;
    }
  }

  private _initTaskDetailFormControl (taskData: ITask): void {
    const { name, createdBy, createdAt, status, description, categories, assignee, comment, resolvedBy, resolvedAt } = taskData;

    const initialForm = {
      studentName: name,
      categories: categories?.length > 0 ? this.parsedCategoryText(categories) : EM_DASH,
      description,
      assignedBy: `${createdBy.firstName} ${createdBy.lastName} on ${this.formatDate(createdAt)}`,
      assignedTo: `${assignee.firstName} ${assignee.lastName}`,
      resolvedBy: resolvedBy ? `${resolvedBy.firstName} ${resolvedBy.lastName} on ${this.formatDate(resolvedAt)}` : EM_DASH,
      status: {
        status: status === TaskStatus.COMPLETE ? 'Complete' : 'To Do',
        color: status === TaskStatus.COMPLETE
          ? 'green-light-1 dark-text font-weight-normal'
          : 'blue-light-7 dark-text font-weight-normal',
      },
      comment: comment || (status === TaskStatus.ACTIVE ? null : EM_DASH),
    };

    this.taskForm = this.formBuilder.group({
      studentName: [initialForm.studentName, Validators.required],
      categories: [initialForm.categories, Validators.required],
      description: [initialForm.description, Validators.required],
      assignedBy: [initialForm.assignedBy, Validators.required],
      assignedTo: [initialForm.assignedTo, Validators.required],
      resolvedBy: [initialForm.resolvedBy, Validators.required],
      status: [initialForm.status, Validators.required],
      comment: [initialForm.comment],
    });
  }

  private _initFormControl (task?: ITask): void {
    const initialForm = {
      description: task?.description || '',
      selectedCategories: task?.categories || ['General'],
      recipient: (task && `${this.task.assignee.firstName} ${this.task.assignee.lastName}`) || null,
    };

    this.taskForm = this.formBuilder.group({
      description: [initialForm.description, Validators.required],
      selectedCategories: [initialForm.selectedCategories, Validators.required],
      recipient: [initialForm.recipient, Validators.required],
    });

    this._setAutoCompleteOptions();
  }

  private _setAutoCompleteOptions (): void {
    this.schoolUsersOptions = this._filterForUsersThatCanBeRecipient(this.allUsers, this.schoolId).map((user: IUser) => {
      const { _id, name: { firstName, lastName } } = user;
      const userName = `${firstName} ${lastName}`;
      return { key: _id, human: userName };
    });
  }

  public handleSelectedCategories (selectedCats: string[]): void {
    this.taskForm.controls.selectedCategories.setValue(selectedCats);
    this.taskForm.controls.selectedCategories.markAsDirty();
  }

  public onClearFieldSelection (fieldKey: string): void {
    this.taskForm.controls[fieldKey].setValue(null);
    this.taskForm.controls[fieldKey].markAsDirty();
  }

  public onFieldSelect (option: any, fieldKey: string): void {
    this.taskForm.controls[fieldKey].setValue(option.human);
    this.selectedUser = this.allUsers.find((user: IUser) => user._id === option.key);
    this.isViewOnlyUser = this.ImUser.isViewType(this.selectedUser);
  }

  public optionsPredicateCb (option: IACOption, searchValue: string): boolean {
    const sanitizedSearchWordList = searchValue
      ?.trim()
      .toLowerCase()
      .replace(/[^\w]|_/g, ' ')
      .split(/[\s,]+/) || [];

    const { human, tags } = option;
    const tagsAsConcatString = tags?.reduce(
      (acc, { human }) => {
        acc += ` ${human}`;
        return acc;
      }, '',
    );

    const fullPathString = (
      tags
        ? `${human}${tagsAsConcatString}`
        : `${human}`
    ).toLowerCase();

    return sanitizedSearchWordList.every(val => fullPathString.includes(val));
  }

  public onClickPrimaryBtn (): void {
    if (this.mode === ValidTaskModeType.CREATE_BULK_TASK_SCHOOL) {
      const payload = this._prepPayloadForApi();
      const taskMeta: TFormatTaskEventArgs = {
        view: 'BATCH-ACTION',
        portal: 'SCHOOL',
        actionInfo: [],
        currentExam: this.data.currentExam ?? null,
      };
      const batchActionMeta: IFormatBatchActionEventArgs = {
        item: 'Task',
        view: this.origin,
        portal: 'SCHOOL',
        currentExam: this.data.currentExam ?? null,
      };
      const taskEvent = this.eventFormatterService.getCreateTaskEvent(taskMeta);
      const batchActionEvent = this.eventFormatterService.getCreateBatchActionEvent(null, batchActionMeta);
      const mixpanelEvents = [taskEvent, batchActionEvent];
      this.apiService.bulkCreateTasks$(this.studentIds, payload, this.partnerType, mixpanelEvents).pipe(
        rxjsFilter(response => !!response),
        rxjsMap((response) => {
          const metaData = response.data.bulkCreateTask.metaData;
          this.createTaskBackgroundJobResults = metaData;
          this.createTaskBackgroundJobType = metaData.invalidStudentIds.length > 0 ? 'BulkStudentTaskPartialCreate' : 'BulkStudentTaskCreate';
          this.dialogRef.close(response.data.bulkCreateTask);
          return BatchActionsEffectsUtilities.getJobSubjectFromGraphql(this.backgroundJob, { response, queryName: 'bulkCreateTask' });
        }),
        tap(subject => this.modalsService.openBackgroundJobSpinnerModal({ backgroundJobSubject: subject, title: 'Creating tasks' })),
        switchMap(identity),
        tap(({ type }) => {
          if (type === BACKGROUND_JOB_STATUS_TYPES.RESOLVE) {
            this.backgroundJobNotificationService.sendMessage({ backgroundJob: this.createTaskBackgroundJobType, metaData: this.createTaskBackgroundJobResults });
          }
        }),
        catchError((err: any) => {
          return throwError(err);
        }),
      ).subscribe();
    } else if (this.mode === ValidTaskModeType.TASK_DETAILS_SCHOOL) {
      const payload: IEditTaskPayload = this._prepDetailsPayloadForApi();
      const mixpanelEvent = this.eventFormatterService.getUpdateTaskEvent(payload, this.task, {
        view: this.isProfileMode ? 'STUDENT-PROFILE' : 'MY-TASKS',
        portal: 'SCHOOL',
      });
      this.apiService.editTask$(payload, mixpanelEvent).pipe(
        tap((updateTask: { _id: string; }) => {
          // TODO: expand / abstract logic here
          this.dialogRef.close(updateTask);
        }),
        catchError((err: any) => {
          return throwError(err);
        }),
      ).subscribe();
    } else if (this.mode === ValidTaskModeType.EDIT_TASK_SCHOOL) {
      const payload: IEditTaskPayload = {
        _id: this.task._id,
        description: this.taskForm.controls.description.value,
        categories: this.taskForm.controls.selectedCategories.value,
      };
      if (this.selectedUser) payload.assigneeId = this.selectedUser?._id; // truthy only if assignee changed

      const mixpanelEvent = this.eventFormatterService.getUpdateTaskEvent(payload, this.task, {
        view: this.isProfileMode ? 'STUDENT-PROFILE' : 'MY-TASKS',
        portal: 'SCHOOL',
      });

      this.apiService.editTask$(payload, mixpanelEvent).pipe(
        tap((updateTask: { _id: string; }) => {
          // TODO: expand / abstract logic here
          this.dialogRef.close(updateTask);
        }),
        catchError((err: any) => {
          this.dialogRef.close({ errors: err[0].message });
          return EMPTY;
        }),
      ).subscribe();
    } else if (this.mode === ValidTaskModeType.DELETE_TASK_SCHOOL) {
      const payload = {
        _id: this.task._id,
        status: TaskStatus.DELETED,
      };

      const mixpanelEvent = this.eventFormatterService.getDeleteTaskEvent({
        view: this.isProfileMode ? 'STUDENT-PROFILE' : 'MY-TASKS',
        portal: 'SCHOOL',
        actionInfo: [],
      });

      this.apiService.editTask$(payload, mixpanelEvent).pipe(
        tap((updateTask: { _id: string; status: string; }) => {
          // TODO: expand / abstract logic here
          this.dialogRef.close(updateTask);
        }),
        catchError((err: any) => {
          return throwError(err);
        }),
      ).subscribe();
    } else if (this.mode === ValidTaskModeType.CREATE_TASK_SCHOOL) {
      const {
        value: { studentOptions: studentIds },
      } = this.taskForm;
      this.data.studentIds = studentIds;
      this.data.mode = ValidTaskModeType.CREATE_BULK_TASK_SCHOOL;
      this.ngOnInit();
    } else if (this.mode === ValidTaskModeType.CREATE_SINGLE_TASK_SCHOOL) {
      const payload = this._prepPayloadForApi();
      const taskEvent = this.eventFormatterService.getCreateTaskEvent({
        view: 'STUDENT-PROFILE',
        portal: 'SCHOOL',
        actionInfo: [],
      });
      this.apiService.createSingleTask$(this.studentData, payload, this.partnerType, taskEvent).pipe(
        tap((createdTask: { _id: string; }) => {
          this.dialogRef.close(createdTask);
        }),
        catchError((err: any) => {
          this.dialogRef.close({ errors: err[0].message });
          return EMPTY;
        }),
      ).subscribe();
    }
  }

  public onCancel (): void {
    this.dialogRef.close();
  }

  public onEditCommentButtonClick (): void {
    if (this.taskForm.controls.comment.value === EM_DASH) this.taskForm.controls.comment.setValue(null);
    this.editCommentMode = true;
    this.showActionButtons = true;
  }

  private _prepPayloadForApi (): any {
    const description = this.taskForm.controls.description.value;
    const categories = this.taskForm.controls.selectedCategories.value;
    const { _id, name: { firstName, lastName }, gafeEmail, doeEmail, dhsEmail } = this.selectedUser;
    const recipient: IUserMini = {
      userId: _id,
      firstName,
      lastName,
      gafeEmail,
      doeEmail,
      dhsEmail,
    };

    const payload = {
      description,
      categories,
      recipient,
      schoolId: this.schoolId,
      district: this.district,
      schoolYear: CurrentSchoolYear.WITH_SY_PREFIX,
    };

    return payload;
  }

  private _prepDetailsPayloadForApi (): IEditTaskPayload {
    const status = this.task.status === TaskStatus.ACTIVE ? TaskStatus.COMPLETE : this.task.status;
    const payload: IEditTaskPayload = {
      _id: this.task._id,
      comment: this.taskForm.controls.comment.value || null,
    };
    if (status) payload.status = status;

    return payload;
  }

  private parsedCategoryText (categories: string[]): string {
    if (categories.length < 4) {
      return categories.join(', ');
    } else if (categories.length > 3) {
      const additional = categories.length - 3;
      return `${categories.slice(0, 3).join(', ')}, +${additional}`;
    } else {
      return EM_DASH;
    }
  }

  private _filterForUsersThatCanBeRecipient (users: IUser[], schoolId: string): IUser[] {
    const filteredUsers = filter(users, (user: IUser) => {
      const valid = !this.ImUser.isNoAccessUser(user, { partnerId: schoolId }) && this.ImUser.isActive(user); // Ensure the user authorizationStatus is not DE_AUTHORIZED

      return valid;
    });

    const sortedFilteredUsers = sortBy(filteredUsers, (user: any) => {
      return user.name.firstName;
    });

    return sortedFilteredUsers;
  }

  private formatDate (date: string): string {
    const format = 'MMM D, YYYY';
    const formattedDate = moment.utc(date).local().format(format);
    return formattedDate;
  }

  public onSelectedOptionsChange (e: string[]): void {
    this.itemCount = e.length;
    this.taskForm.controls.studentOptions.setValue(e);
    this.taskForm.controls.studentOptions.markAsDirty();
  }
}
