export const StudentSupportStatuses = {
  backend: {
    ACTIVE: 'ACTIVE',
    COMPLETED: 'COMPLETED',
    DELETED: 'DELETED',
  },
  frontend: {
    NOT_STARTED: 'NOT STARTED', // ACTIVE
    IN_PROGRESS: 'IN PROGRESS', // ACTIVE
    COMPLETED: 'COMPLETED', // COMPLETED
    REMOVED: 'REMOVED', // DELETED
  },
};
