import { Injectable } from '@angular/core';
import { compact, find, isNil, reduce } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { IRowData } from '../../models/list-models';
import * as moment from 'moment';

export type TSortDirections = 'asc' | 'desc';
export interface IStudentDetails {
  studentName: string;
  studentId: string;
}

/* eslint-disable no-unused-vars */
export enum Direction {
  asc = 'asc',
  desc = 'desc',
}

enum DataType {
  number = 'number',
  string = 'string',
}

const statusOrderMap = {
  'Needs confirmation': 4,
  Complete: 3,
  Incomplete: 2,
  'In progress': 1,
  Upcoming: 0,
};

const DASH = '—';

/* istanbul ignore next */
@Injectable()
export class SortAndFilterService {
  static updateSortCol (
    colKey: string,
    sortKey$: BehaviorSubject<string>,
    sortDirection$: BehaviorSubject<TSortDirections>,
  ) {
    const shouldToggleSort = colKey === sortKey$.value;
    if (shouldToggleSort) sortDirection$.next(SortAndFilterService.getToggledSort(sortDirection$.value));
    else sortDirection$.next('desc');
    sortKey$.next(colKey);
  }

  static sortRollupTableRowData (rowData: IRowData[][], sortKeyIndex: number, sortDirection: TSortDirections) {
    return [...rowData].sort((a, b) => {
      let aSortValue: string | number;
      let bSortValue: string | number;

      if (sortKeyIndex === 0) {
        const aMeta = JSON.parse(a[sortKeyIndex].meta);
        const bMeta = JSON.parse(b[sortKeyIndex].meta);
        aSortValue = aMeta.sort ?? aMeta.data;
        bSortValue = bMeta.sort ?? bMeta.data;
      } else {
        aSortValue = a[sortKeyIndex].data;
        bSortValue = b[sortKeyIndex].data;
        if (aSortValue === '—') aSortValue = 0;
        if (bSortValue === '—') bSortValue = 0;
      }

      if (aSortValue > bSortValue) return sortDirection === 'asc' ? 1 : -1;
      if (aSortValue < bSortValue) return sortDirection === 'asc' ? -1 : 1;
      return 0;
    });
  }

  static sortTableRowData (rowData: IRowData[], sortKeyIndex: number, sortDirection: TSortDirections, colKey?: string) {
    return [...rowData].sort((a, b) => {
      if (!isNil(sortKeyIndex)) {
        const [parsedAVal, parsedBVal] = SortAndFilterService.getSortValues(
          a[sortKeyIndex].data,
          b[sortKeyIndex].data,
          colKey,
        );
        return SortAndFilterService._sortValues(parsedAVal, parsedBVal, sortDirection);
      } else {
        let aData;
        let bData;
        if (a.meta && a.meta.isoDate) {
          aData = a.meta.isoDate;
          bData = b.meta.isoDate;
        } else {
          aData = a.data;
          bData = b.data;
        }
        return SortAndFilterService._sortValues(aData, bData, sortDirection);
      }
    });
  }

  static getSortValues (a, b, key?: string): string[] | number[] {
    let aSortValue;
    let bSortValue;
    switch (key) {
      case 'CURRENT_STREAK':
      case 'CURRENT_REMOTE_INTERACTION_STREAK': {
        const getStatus = streak => {
          if (streak === null) return -1;
          const [num] = streak.split(' ');
          return parseInt(num);
        };
        aSortValue = getStatus(a);
        bSortValue = getStatus(b);
        break;
      }
      default:
        aSortValue = a;
        bSortValue = b;
    }
    return [aSortValue, bSortValue];
  }

  static studentDetailsParser (rows: IRowData[]) {
    let studentDetails: IStudentDetails;
    const [nameRow] = rows;
    const { meta } = nameRow;
    if (!meta) {
      studentDetails = reduce(
        rows,
        (reducedRows, studentDetails) => {
          if (studentDetails.columnKey === 'Student Name') reducedRows.studentName = studentDetails.data;
          if (studentDetails.columnKey === 'Student Id') reducedRows.studentId = studentDetails.data;
          return reducedRows;
        },
        { studentName: null, studentId: null },
      );
    } else {
      const filterCol = rows[0];
      const { data: studentName, meta } = filterCol;
      const studentId = JSON.parse(meta).data;
      studentDetails = { studentName, studentId };
    }
    return studentDetails;
  }

  static filterRows (args: { filterTerm: string; listType: string; rowData: IRowData[][] }) {
    const { filterTerm, listType, rowData } = args;
    let filteredRows;
    if (!filterTerm || !filterTerm.trim() || rowData.length === 0) {
      filteredRows = rowData;
    } else if (listType === 'USER') {
      filteredRows = SortAndFilterService.filterStubColumn({ filterTerm, rowData });
    } else if (listType === 'STUDENT') {
      filteredRows = SortAndFilterService.filterStudentRows({ filterTerm, rowData });
    } else if (listType === 'NETWORK') {
      filteredRows = SortAndFilterService.filterStubColumn({ filterTerm, rowData });
    } else if (listType === 'USER_SCHOOL_PORTFOLIO') {
      filteredRows = SortAndFilterService.filterSchoolRows({ filterTerm, rowData });
    } else if (listType === 'GRID_COLUMNS') {
      filteredRows = SortAndFilterService.filterGridColRows({ filterTerm, rowData });
    } else if (listType === 'USER_MANAGEMENT') {
      filteredRows = SortAndFilterService.filterUserManagement({ filterTerm, rowData });
    } else if (listType === 'PROGRAM_CHANGES') {
      filteredRows = SortAndFilterService.filterProgramChangesRows({ filterTerm, rowData });
    } else if (listType === 'MOCK_REGENTS') {
      filteredRows = SortAndFilterService.filterOverflowStub({ filterTerm, rowData });
    } else {
      filteredRows = SortAndFilterService.filterStudentRows({ filterTerm, rowData });
    }
    return filteredRows;
  }

  static filterStudentRows (args: { filterTerm: string; rowData: IRowData[][] }) {
    const { filterTerm, rowData } = args;
    const allFilterTerms = compact(filterTerm.split(' '));

    return [...rowData].filter((rows: IRowData[]) => {
      const studentDetails: IStudentDetails = SortAndFilterService.studentDetailsParser(rows);
      const { studentName, studentId } = studentDetails;

      return allFilterTerms.some(filterTerm => {
        let nameMatch, studentIdMatch;
        if (studentName) {
          nameMatch = studentName
            .toString()
            .toLowerCase()
            .includes(filterTerm.toString().toLowerCase());
        }

        if (studentId) {
          studentIdMatch = studentId
            .toString()
            .toLowerCase()
            .includes(filterTerm.toString().toLowerCase());
        }

        if (nameMatch || studentIdMatch) return true;
      });
    });
  }

  static filterStubColumn (args: { filterTerm: string; rowData: IRowData[][] }) {
    const { filterTerm, rowData } = args;
    let allFilterTerms;

    if (filterTerm.includes(',')) {
      allFilterTerms = filterTerm.split(',').map(v => v.trim());
    } else {
      allFilterTerms = filterTerm.split(' ');
    }
    allFilterTerms = allFilterTerms.filter(term => term !== '');

    return [...rowData].filter((rows: IRowData[]) => {
      const [{ data }] = rows;
      return allFilterTerms.some(filterTerm => {
        return data
          .toString()
          .toLowerCase()
          .includes(filterTerm.toString().toLowerCase());
      });
    });
  }

  static filterSchoolRows (args: { filterTerm: string; rowData: IRowData[][] }) {
    const { filterTerm, rowData } = args;
    let allFilterTerms;

    if (filterTerm.includes(',')) {
      allFilterTerms = filterTerm.split(',').map(v => v.trim());
    } else {
      allFilterTerms = filterTerm.split(' ');
    }
    allFilterTerms = allFilterTerms.filter(term => term !== '');

    return [...rowData].filter((rows: IRowData[]) => {
      const col = find(rows, { columnKey: 'STUB' });
      const meta = JSON.parse(col.meta);
      const schoolName = col.data;
      const schoolId = meta.data;
      const schoolNickname = meta.nickname;
      return allFilterTerms.some(filterTerm => {
        const nameMatch = schoolName
          .toString()
          .toLowerCase()
          .includes(filterTerm.toString().toLowerCase());

        const idMatch = schoolId
          .toString()
          .toLowerCase()
          .includes(filterTerm.toString().toLowerCase());

        const nicknameMatch = schoolNickname
          .toString()
          .toLowerCase()
          .includes(filterTerm.toString().toLowerCase());

        if (nameMatch || idMatch || nicknameMatch) return true;
      });
    });
  }

  static filterGridColRows (args: { filterTerm: string; rowData: IRowData[][] }) {
    const { filterTerm, rowData } = args;
    const allFilterTerms = filterTerm
      .split(',')
      .map(term => term.trim().toLowerCase())
      .filter(term => !!term);

    return [...rowData].filter((rows: IRowData[]) => {
      const stubCol = find(rows, { columnKey: 'STUB' });
      const descriptionCol = find(rows, { columnKey: 'DESCRIPTION' });

      const fieldName = stubCol.data.toString().toLowerCase();
      const description = descriptionCol && descriptionCol.data && descriptionCol.data.toString().toLowerCase();
      const category = JSON.parse(stubCol.meta)
        .category.toString()
        .toLowerCase();

      return allFilterTerms.some(filterTerm => {
        const fieldNameMatch = fieldName.includes(filterTerm);
        // description is optional so don't run check if we don't have a description
        const descriptionMatch = description && description.includes(filterTerm);
        const categoryMatch = category.includes(filterTerm);
        return fieldNameMatch || descriptionMatch || categoryMatch;
      });
    });
  }

  static filterUserManagement (args: { filterTerm: string; rowData: IRowData[][] }) {
    const { filterTerm, rowData } = args;
    let allFilterTerms;

    if (filterTerm.includes(',')) {
      allFilterTerms = filterTerm.split(',').map(v => v.trim());
    } else {
      allFilterTerms = filterTerm.split(' ');
    }
    allFilterTerms = allFilterTerms.filter(term => term !== '');

    return [...rowData].filter((rows: IRowData[]) => {
      const stubCol = find(rows, { columnKey: 'STUB' });
      const meta = JSON.parse(stubCol.meta);
      const userName = stubCol.data;
      const doeEmail = meta.doeEmail;
      const gafeEmail = meta.gafeEmail;

      let nameMatch;
      let doeEmailMatch;
      let gafeEmailMatch;

      return allFilterTerms.some(filterTerm => {
        if (userName) {
          nameMatch = userName
            .toString()
            .toLowerCase()
            .includes(filterTerm.toString().toLowerCase());
        }

        if (doeEmail) {
          doeEmailMatch = doeEmail
            .toString()
            .toLowerCase()
            .includes(filterTerm.toString().toLowerCase());
        }

        if (gafeEmail) {
          gafeEmailMatch = gafeEmail
            .toString()
            .toLowerCase()
            .includes(filterTerm.toString().toLowerCase());
        }

        if (nameMatch || doeEmailMatch || gafeEmailMatch) return true;
      });
    });
  }

  static filterProgramChangesRows (args: { filterTerm: string; rowData: IRowData[][] }) {
    const { filterTerm, rowData } = args;
    const allFilterTerms = compact(filterTerm.split(' '));

    return [...rowData].filter((rows: IRowData[]) => {
      const stubCol = find(rows, { columnKey: 'STUB' });
      const meta = JSON.parse(stubCol.meta);
      const studentName = stubCol.data;
      const studentId = meta.studentId;

      return allFilterTerms.some(filterTerm => {
        let nameMatch, studentIdMatch;
        if (studentName) {
          nameMatch = studentName
            .toString()
            .toLowerCase()
            .includes(filterTerm.toString().toLowerCase());
        }

        if (studentId) {
          studentIdMatch = studentId
            .toString()
            .toLowerCase()
            .includes(filterTerm.toString().toLowerCase());
        }

        if (nameMatch || studentIdMatch) return true;
      });
    });
  }

  static filterOverflowStub (args: {filterTerm: string; rowData: IRowData[][]}) {
    const { filterTerm, rowData } = args;
    let allFilterTerms;

    if (filterTerm.includes(',')) {
      allFilterTerms = filterTerm.split(',').map(v => v.trim());
    } else {
      allFilterTerms = filterTerm.split(' ');
    }
    allFilterTerms = allFilterTerms.filter(term => term !== '');

    return [...rowData].filter((rows: IRowData[]) => {
      const [{ originalData }] = rows;
      return allFilterTerms.some(filterTerm => {
        return originalData
          .toString()
          .toLowerCase()
          .includes(filterTerm.toString().toLowerCase());
      });
    });
  }

  static _sortValues (aVal: string | number, bVal: string | number, sortDirection: TSortDirections): -1 | 0 | 1 {
    // If one of the two values is null or DASH,
    // it doesn't matter the type we are sorting
    // just return the proper num. `sortDirection` supports toggling
    if ((isNil(aVal) || aVal === DASH) && sortDirection === Direction.asc) return -1;
    if ((isNil(aVal) || aVal === DASH) && sortDirection === Direction.desc) return 1;
    if ((isNil(bVal) || bVal === DASH) && sortDirection === Direction.asc) return 1;
    if ((isNil(bVal) || bVal === DASH) && sortDirection === Direction.desc) return -1;
    if ((isNil(aVal) || aVal === DASH) && (isNil(bVal) || aVal === DASH)) return 0;
    // Determine which type to cast to
    const dataType = SortAndFilterService._isNumeric(aVal) ? DataType.number : DataType.string;
    // Targets
    let sortValueA: number | string | Date;
    let sortValueB: number | string | Date;
    // Cast values
    if (dataType === DataType.number) {
      sortValueA = +aVal;
      sortValueB = +bVal;
    } else {
      if (SortAndFilterService._isDate(aVal)) {
        sortValueA = new Date(aVal);
        sortValueB = new Date(bVal);
      } else {
        sortValueA = aVal.toString().toLowerCase();
        sortValueB = bVal.toString().toLowerCase();
      }
    }
    // Make the comparision
    if (sortValueA > sortValueB) return sortDirection === 'asc' ? 1 : -1;
    if (sortValueA < sortValueB) return sortDirection === 'asc' ? -1 : 1;
    // Casted values are equal
    return 0;
  }

  static _isNumeric (val) {
    return !isNaN(val);
  }

  static _isDate (val) {
    return moment(val, 'MMM DD, YYYY', true).isValid() || moment(val, 'MMM D, YYYY', true).isValid();
  }

  static getToggledSort (sort: TSortDirections): TSortDirections {
    return sort === Direction.asc ? Direction.desc : Direction.asc;
  }
}
