import { ISchool } from '../../../ng2/shared/typings/interfaces/school.interface';

export class SdcStateServiceBaseModel {
  constructor() {
    //
  }

  private _school: ISchool;

  init(currentSchool: ISchool) {
    this._school = currentSchool;
  }

  getSchool() {
    if (!this._school) {
      throw new Error(
        `A currentSchool is required for this config, but none has been loaded via #sdc-state service #init,
        check configsRequiringSchool`,
      );
    }
    return this._school;
  }
}
