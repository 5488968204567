import { LOAD_SHELTER, LOAD_SHELTER_SUCCESS, ShelterActions } from '../../actions/shelter-actions/shelter-actions';

export const initialState = {
  shelterId: null,
};

export function reducer(state = initialState, action: ShelterActions) {
  let payload: any;
  switch (action.type) {
    case LOAD_SHELTER:
      return {
        ...state,
      };

    case LOAD_SHELTER_SUCCESS:
      payload = action.payload;
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
}
