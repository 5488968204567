<ng-container *ngIf="showMoreButton">
  <nv-icon-button 
    [matMenuTriggerFor]="menu"
    name="more-large-blue"
    [color]="buttonColor"
    [ngClass]="buttonClass"
    [disabled]="isStatic"
    nvTooltip
    [tooltipData]="buttonTooltip">
  </nv-icon-button>
  <mat-menu #menu="matMenu" panelClass="nv-mat-menu">
    <nv-dropdown-item *ngFor="let option of options" [option]="option" (selectItem)="onSelect($event)">
    </nv-dropdown-item>
  </mat-menu>
</ng-container>