import { NvTooltipModule } from 'Src/ng2/shared/nv-tooltip/nv-tooltip.module';
import { FinancialAidModalDropdown } from './financial-aid-modal-dropdown/financial-aid-modal-dropdown.component';
import { BaseModalModule } from './../base-modal.module';
import { NvButtonModule } from 'projects/shared/nvps-libraries/design/nv-button/nv-button.module';
import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { PostsecFinancialAidModalComponent } from './postsec-financial-aid-modal.component';
import { NvDropdownModule } from 'Src/nvps-libraries/design/nv-dropdown/nv-dropdown.module';
import { NvFormFieldModule } from 'Src/nvps-libraries/design/nv-form-field/nv-form-field.module';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    imports: [
      BaseModalModule,
      CommonModule,
      NvButtonModule,
      NvDropdownModule,
      NvFormFieldModule,
      MatDialogModule,
      NvTooltipModule
    ],
    exports: [],
    declarations: [PostsecFinancialAidModalComponent, FinancialAidModalDropdown],
    providers: [],
})
export class PostsecFinancialAidModalModule { }
