/* tslint:disable */
import { Action } from '@ngrx/store';
import { IDataLoad, IDataLoadParams } from '../../shared/typings/interfaces/data-load.interface';

export const LOAD_DATA_LOADS = '[Portal] Load DataLoads';
export const LOAD_DATA_LOADS_SUCCESS = '[Portal] Load DataLoads Success';
export const LOAD_DATA_LOADS_FAIL = '[Portal] Load DataLoads Failure';

export const CREATE_DATA_LOAD = '[Portal] Create DataLoad';
export const CREATE_DATA_LOAD_SUCCESS = '[Portal] Create DataLoad Success';
export const CREATE_DATA_LOAD_FAIL = '[Portal] Create DataLoad Failure';

export interface ILoadDataLoadsPayload {
  schoolId: string;
}

// register store actions
export class LoadDataLoads implements Action {
  readonly type = LOAD_DATA_LOADS;
  constructor(public payload: ILoadDataLoadsPayload) {}
}

export class LoadDataLoadsSuccess implements Action {
  readonly type = LOAD_DATA_LOADS_SUCCESS;
  constructor(public payload: { data: { DataLoads: IDataLoad[] } }) {}
}

export class LoadDataLoadsFail implements Action {
  readonly type = LOAD_DATA_LOADS_FAIL;
  constructor(public payload: any) {}
}

export class CreateDataLoad implements Action {
  readonly type = CREATE_DATA_LOAD;
  constructor(public payload: IDataLoadParams) {}
}

export class CreateDataLoadSuccess implements Action {
  readonly type = CREATE_DATA_LOAD_SUCCESS;
  constructor(public payload: { dataLoad: IDataLoad }) {}
}

export class CreateDataLoadFail implements Action {
  readonly type = CREATE_DATA_LOAD_FAIL;
  constructor(public payload: any) {}
}

// action types
export type DataLoadsAction =
  | LoadDataLoads
  | LoadDataLoadsSuccess
  | LoadDataLoadsFail
  | CreateDataLoad
  | CreateDataLoadSuccess
  | CreateDataLoadFail;
