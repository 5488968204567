<p class="mat-body-2">
  Based on the On-Track Regents metrics you set previously, {{totalPastDueExams}} students have not passed
  and are not scheduled for Regents exams that they should pass this administration or should have passed
  previously.
</p>

<ul class="mat-body-2" *ngFor="let data of pastDueStudentExamsByExamName|keyvalue">
  <li *ngIf="data.value._ids.length > 0">
    <wizard-filter-actions [data]="data.value.studentIds" [options]="options"></wizard-filter-actions>

    <ng-container [ngPlural]="data.value._ids.length">
      <ng-template ngPluralCase="0"> students are </ng-template>
      <ng-template ngPluralCase="1"> student is </ng-template>
      <ng-template ngPluralCase="few"> students are </ng-template>
      <ng-template ngPluralCase="other"> students are </ng-template>
    </ng-container>

    past-due in {{data.key}}
  </li>
</ul>

<button
  mat-raised-button
  class="step-action-button"
  (click)="patchStudents(patches, totalPastDueExams)"
  [disabled]="!totalPastDueExams"
>
  Plan {{totalPastDueExams}}

  <ng-container [ngPlural]="totalPastDueExams">
    <ng-template ngPluralCase="0"> students </ng-template>
    <ng-template ngPluralCase="1"> student </ng-template>
    <ng-template ngPluralCase="few"> students </ng-template>
    <ng-template ngPluralCase="other"> students </ng-template>
  </ng-container>

</button>

