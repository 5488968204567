import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'task-details',
  templateUrl: './task-details.component.html',
  styleUrls: ['./task-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TaskDetailsComponent implements OnInit {
  @Input() taskForm: FormGroup;
  @Input() editCommentMode: boolean;
  @Input() canEditComment: boolean;
  @Output() editCommentClick = new EventEmitter();

  constructor () {}

  ngOnInit (): void {}

  public onEditCommentButtonClick (): void {
    this.editCommentClick.emit();
  }
}
