<ng-container *ngIf="(autoCompleteOptions$ | async) as autoCompleteOptions">
  <nv-form-field label="Organization">
    <nv-textbox
      [textControl]="formGroup.controls.organization"
      icon="search-large-black"
      placeholder="Select an organization..."
      [restrictToOptions]="true"
      [autocompleteOptions]="autoCompleteOptions.organization"
      [emptyStateText]="emptyStateOrganizations"
      [hasClearBtn]="true"
      (clearValue)="onClearFieldSelection('organization')"
      [returnObject]="true"
      (selectOption)="onFieldSelect($event, 'organization')"
      [optionsPredicateCb]="optionsPredicateCb"
      [optionsLimit]="optionsLimit"
    >
      <nv-autocomplete-action [icon]="emptyStateIcon" (click)="onCreateOption('organization')">
        <span class="autocomplete-text"> Add new organization </span>
      </nv-autocomplete-action>
    </nv-textbox>
  </nv-form-field>

  <nv-form-field label="Specialty area (optional)">
    <nv-textbox
      [textControl]="formGroup.controls.specialtyArea"
      icon="search-large-black"
      placeholder="Select a specialty area..."
      [restrictToOptions]="true"
      [autocompleteOptions]="autoCompleteOptions.specialtyArea"
      [emptyStateText]="emptyStateSpecialtyArea"
      [hasClearBtn]="true"
      (clearValue)="onClearFieldSelection('specialtyArea')"
      [returnObject]="true"
      (selectOption)="onFieldSelect($event, 'specialtyArea')"
      [optionsPredicateCb]="optionsPredicateCb"
      [optionsLimit]="optionsLimit"
    >
      <nv-autocomplete-action [icon]="emptyStateIcon" (click)="onCreateOption('specialtyArea')">
        <span class="autocomplete-text"> Add new specialty area </span>
      </nv-autocomplete-action>
    </nv-textbox>
  </nv-form-field>

  <nv-form-field label="Area of study">
    <nv-dropdown
      placeholder="Select an area of study"
      [options]="autoCompleteOptions.areaOfStudy"
      (selectOption)="onFieldSelect($event, 'areaOfStudy')"
      [returnObject]="true"
    ></nv-dropdown>
  </nv-form-field>

  <nv-form-field label="Transition support organization (optional)">
    <nv-textbox
      [textControl]="formGroup.controls.transitionSupportOrg"
      icon="search-large-black"
      placeholder="Select a transition support organization..."
      [restrictToOptions]="true"
      [autocompleteOptions]="autoCompleteOptions.transitionSupportOrg"
      [emptyStateText]="emptyStateTransitionSupportOrg"
      [hasClearBtn]="true"
      (clearValue)="onClearFieldSelection('transitionSupportOrg')"
      [returnObject]="true"
      (selectOption)="onFieldSelect($event, 'transitionSupportOrg')"
      [optionsPredicateCb]="optionsPredicateCb"
      [optionsLimit]="optionsLimit"
    >
      <nv-autocomplete-action [icon]="emptyStateIcon" (click)="onCreateOption('transitionSupportOrg')">
        <span class="autocomplete-text"> Add new transition support organization </span>
      </nv-autocomplete-action>
    </nv-textbox>
  </nv-form-field>

  <status-dropdown
    pathCategory="careerTrainingProgram"
    [studentPaths]="studentPaths"
    (selectStatus)="onSelectStatus($event)"
    [selectedStatusOption]="selectedStatusOption"
  ></status-dropdown>

  <submit-button
    [isDisabled]="!this.formGroup.valid"
    [confirmationButtonLabel]="action"
    (handleClick)="handleClick()"
    (handleClose)="onEmitCloseModal()"
  ></submit-button>
</ng-container>
