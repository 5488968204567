<nv-dropdown
  *ngIf="!isViewOnlyUser"
  class="postsec-list-dropdown"
  [options]="options"
  [selected]="selectedOption"
  (selectOption)="onStatusChange($event)"
></nv-dropdown>

<nv-pill
  *ngIf="isViewOnlyUser"
  [text]="selectedOption"
  [textIsPlaceholder]="isViewOnlyUser"
></nv-pill>
