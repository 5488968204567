<base-modal class="base-modal" [title]="title" [isProfileMode]="isProfileMode">
  <form>
    <mat-dialog-content>
      <div>
        <span
          *ngFor="let careerType of careerTypeBtns"
          class="btn-toggle"
          [ngClass]="{ selected: careerType.selected }"
          (click)="onCareerTypeChange(careerType.key)"
        >
          {{ careerType.humanName }}
        </span>
      </div>
      <mat-form-field floatLabel="never">
        <input
          type="text"
          [placeholder]="placeholder"
          aria-label="enter career name"
          matInput
          [formControl]="form"
          [matAutocomplete]="auto"
          (input)="onChange()"
        />
        <mat-autocomplete
          #auto="matAutocomplete"
          [displayWith]="displayFn"
          (optionSelected)="onOptionSelection($event)"
        >
          <mat-option
            *ngFor="let option of (filteredOptions$ | async)"
            [value]="option"
            class="nv-mat-option college-path-autocomplete-option"
          >
            {{ option.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions class="dialog-actions">
      <button mat-button class="v3-btn secondary" (click)="onCancel()">{{ buttons.cancel.text }}</button>
      <button mat-button class="v3-btn primary" [ngClass]="{ disabled: !addBtnEnabled }" (click)="onConfirm()">
        {{ buttons.confirm.text }}
      </button>
    </mat-dialog-actions>
  </form>
</base-modal>
