import { IPartner, PartnerTypes, TValidPartnerTypes } from '../../typings/interfaces/partner.interface';
import { ImUser } from '../../services/im-models/im-user';
import { IUser } from '../../typings/interfaces/user.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownOption } from '../../../../../projects/shared/nvps-libraries/design/interfaces/design-library.interface';
import { Component, OnInit, Input, ViewEncapsulation, ElementRef } from '@angular/core';
import { UrlPathService } from '../../services/url-path-service/url-path.service';
import { SessionStorageService } from '../../services/web-storage/session-storage/session-storage.service';
import { districtsConfig } from 'Src/ng2/shared/constants/districts-config.constant';
import { PORTAL_TYPES, PORTAL_TYPE_MAP_TO_PARTNER_TYPE, TPortalLocation } from '../../typings/interfaces/portal.interface';
import { PORTAL_SWITCHER_CONFIG } from './portal-switcher-config';
import { NetDashTileDataService } from 'Src/ng2/network/network-top-level/net-dash-tile-data/net-dash-tile-data.service';

interface IPortalSwitcherDropdownOption extends IDropdownOption {
  permissions: {
    isSuperAdmin: {
      needsSuperAdminAccess: boolean;
    } | null;
    isClusterUser: {
      needsHybridAccess: boolean;
      needsNetworkAccess: boolean;
      needsMultiSchoolOrShelterAccess: boolean;
      needsAtLeastOneSchoolOrShelterAccess: boolean;
    } | null;
    isHybridUser: {
      needsHybridAccess: boolean;
      needsNetworkAccess: boolean;
      needsMultiSchoolOrShelterAccess: boolean;
      needsAtLeastOneSchoolOrShelterAccess: boolean;
    } | null;
    isMultiSchoolUser: {
      needsAtLeastTwoSchoolsAccess: boolean;
    },
    isMultiDistrictUser: boolean;
    multiSubLocations: boolean;
  };
  viewType: TPortalLocation;
}

interface IPortalSwitcherUserPermission {
  isClusterUser: boolean;
  isHybridUser: boolean;
  hasHybridAccess: boolean;
  hasNetworkAccess: boolean;
  hasAccessToMultiSchoolsOrShelters: boolean;
  hasAccessToAtLeastOneSchoolOrShelter: boolean;
  isSuperAdmin: boolean;
  isMultiSchoolUser: boolean;
  hasAccessToAtLeastTwoSchools: boolean;
  isMultiDistrictUser: boolean;
}

@Component({
  selector: 'portal-switcher',
  templateUrl: './portal-switcher.component.html',
  styleUrls: ['./portal-switcher.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PortalSwitcherComponent implements OnInit {
  @Input() currentUser: IUser;
  @Input() selectedView: TPortalLocation;
  @Input() label: 'string';

  public options: IPortalSwitcherDropdownOption[];

  private partner: IPartner;
  public partnerType: TValidPartnerTypes;

  constructor (
    private imUser: ImUser,
    private router: Router,
    private route: ActivatedRoute,
    private urlPathService: UrlPathService,
    private sessionStorageService: SessionStorageService,
    private netDashTileDataService: NetDashTileDataService,
  ) {}

  ngOnInit (): void {
    this.partner = this.route.snapshot.data.shelter ?? null;
    this.partnerType = PORTAL_TYPE_MAP_TO_PARTNER_TYPE[this.selectedView];
    this.options = this._setDropdownOptions(this.selectedView, this.currentUser);
  }

  selectOption (event: string): void {
    let url: string;
    let queryParams = {};
    switch (event) {
      case 'Switch School':
      case 'Switch to a School':
        url = this.urlPathService.computeDistrictUrlPath('school-picker');
        break;
      case 'Switch to a Shelter':
        url = this.urlPathService.computeDistrictUrlPath('shelter-picker');
        break;
      case 'Explore Schools':
        const hasAccessToOneSchoolOnly /* eslint-disable-line */ =
          this.imUser.isClusterUser(this.currentUser, PartnerTypes.SCHOOL) &&
          this.imUser.isSingleClusterUser(this.currentUser, PartnerTypes.SCHOOL_NETWORK);
        const isNetworkOnly = this.imUser.isNetworkOnly(this.currentUser, PartnerTypes.SCHOOL_NETWORK); /* eslint-disable-line */
        const hasNoSchoolButIsNetSchool = this.imUser.hasNoSchoolButIsNetSchool(this.currentUser); /* eslint-disable-line */
        this.netDashTileDataService.resetTileCache();
        if (isNetworkOnly || hasNoSchoolButIsNetSchool) {
          url = this.urlPathService.computeDistrictUrlPath(`/network/school/${this.currentUser.nvRole.clusterId}`);
        } else if (this.currentUser._role_clusterSchoolIds && hasAccessToOneSchoolOnly) {
          const schoolId = this.currentUser._role_clusterSchoolIds[0];
          url = this.urlPathService.computeDistrictUrlPath(`/school/${schoolId}/lists/tiles`);
        } else if (this.currentUser.nvRole.schoolId) {
          url = this.urlPathService.computeDistrictUrlPath(`/school/${this.currentUser.nvRole.schoolId}/lists/tiles`);
        } else {
          url = this.urlPathService.computeDistrictUrlPath('school-picker');
        }
        break;
      case 'View Network':
        if (this.selectedView === 'SHELTER') {
          url = this.urlPathService.computeDistrictUrlPath(`/network/shelter/${this.currentUser.nvRoleShelter.shelterClusterId}/dashboard`);
        } else {
          url = this.urlPathService.computeDistrictUrlPath(`/network/school/${this.currentUser.nvRole.clusterId}`);
        }
        break;
      case 'Switch District':
        url = 'district-picker';
        break;
      case 'Switch Shelter':
        url = this.urlPathService.computeDistrictUrlPath('shelter-picker');
        break;
      case 'Explore Shelters':
        const hasAccessToOneShelterOnly = /* eslint-disable-line */
        this.imUser.isClusterUser(this.currentUser, PartnerTypes.SHELTER) &&
        this.imUser.isSingleClusterUser(this.currentUser, PartnerTypes.SHELTER_NETWORK);
        this.netDashTileDataService.resetTileCache();
        if (hasAccessToOneShelterOnly && this.currentUser._role_shelterClusterShelterIds.length) {
          url = this.urlPathService.computeDistrictUrlPath(`shelter/${this.currentUser._role_shelterClusterShelterIds[0]}`);
        } else if (this.currentUser.nvRoleShelter.shelterId) {
          url = this.urlPathService.computeDistrictUrlPath(`shelter/${this.currentUser.nvRoleShelter.shelterId}`);
        } else {
          url = this.urlPathService.computeDistrictUrlPath('shelter-picker');
        }
        break;
      case 'Switch Shelter Location':
        url = this.urlPathService.computeDistrictUrlPath('shelter-picker');
        queryParams = {
          shelterId: this.route.snapshot.params.shelterId,
        };
        break;
    }
    if (url) {
      this.router.navigate([url], { queryParams });
    }
  }

  private _getDropdownOptions (selectedView: TPortalLocation): IPortalSwitcherDropdownOption[] {
    return PORTAL_SWITCHER_CONFIG[selectedView];
  }

  private _getUserPermissions (user: IUser): IPortalSwitcherUserPermission {
    const userPermissions = {
      isClusterUser: false,
      isHybridUser: false,
      hasHybridAccess: false,
      hasAccessToMultiSchoolsOrShelters: false,
      hasAccessToAtLeastOneSchoolOrShelter: false,
      isSuperAdmin: false,
      isMultiSchoolUser: false,
      hasAccessToAtLeastTwoSchools: false,
      isMultiDistrictUser: false,
      hasNetworkAccess: false,
    };
    const userDistricts = this.sessionStorageService.getItem('districts') || [];

    const isSuperAdmin = this.imUser.isSuperAdmin(user, this.partnerType);
    const isClusterUser = this.imUser.isClusterUser(user, this.partnerType);
    const hasNetworkAccess = this.imUser.canViewNetwork(user, this.partnerType);
    const isNetworkOnly = this.imUser.isNetworkOnly(user, this.partnerType);
    const isSingleClusterUser = this.imUser.isSingleClusterUser(user, this.partnerType);
    const hasNoSchoolButIsNetSchool = this.imUser.hasNoSchoolButIsNetSchool(user);
    const isHybridUser = this.imUser.isHybridUser(user) && this.imUser.isActive(user, PartnerTypes.HYBRID);
    const isClusterAdmin = this.imUser.isClusterAdmin(user, this.partnerType);
    const isMultiSchoolUser = this.imUser.isMultiSchoolUser(user);
    const isSingleMultiSchoolUser = isMultiSchoolUser && this.imUser.isSingleMultiSchoolUser(user);
    const isMultiDistrictUser = this.imUser.isMultiDistrictUser(userDistricts);

    if (isSuperAdmin) userPermissions.isSuperAdmin = true;
    if (isClusterUser) userPermissions.isClusterUser = true;
    if (hasNetworkAccess) userPermissions.hasNetworkAccess = true;
    if (isMultiSchoolUser) userPermissions.isMultiSchoolUser = true;
    if ((!isSingleClusterUser && !isNetworkOnly) || isClusterAdmin) {
      userPermissions.hasAccessToAtLeastOneSchoolOrShelter = true;
      userPermissions.hasAccessToMultiSchoolsOrShelters = true;
    }
    if (isSingleClusterUser && !hasNoSchoolButIsNetSchool && !isNetworkOnly && !isClusterAdmin) {
      userPermissions.hasAccessToAtLeastOneSchoolOrShelter = true;
    }
    if (isMultiSchoolUser && !isSingleMultiSchoolUser) {
      userPermissions.hasAccessToAtLeastTwoSchools = true;
    }
    if (isHybridUser) {
      userPermissions.hasHybridAccess = true;
      userPermissions.isHybridUser = true;
    }
    if (isMultiDistrictUser) userPermissions.isMultiDistrictUser = true;

    return userPermissions;
  }

  private _setDropdownOptions (selectedView: TPortalLocation, user: IUser): IPortalSwitcherDropdownOption[] {
    const defaultDropdownOptions = this._getDropdownOptions(selectedView);
    const userPermissions = this._getUserPermissions(user);
    return defaultDropdownOptions.filter(defaultOption => {
      const isValidOptForView = this.isValidDropdownOpt({ option: defaultOption, selectedView });
      if (!isValidOptForView) return false;
      return (
        this._isOptionAccessible_superAdmin(userPermissions, defaultOption) ||
        this._isOptionAccessible_clusterUser(userPermissions, defaultOption) ||
        this._isOptionAccessible_multiSchoolUser(userPermissions, defaultOption) ||
        this._isOptionAccessible_multiDistrictUser(userPermissions, defaultOption) ||
        this._isOptionAccessible_hybridUser(userPermissions, defaultOption)
      );
    });
  }

  private isValidDropdownOpt ({ option, selectedView }: {option: any, selectedView: TPortalLocation }): boolean {
    // If the user currently does not have multiple sublocations, they should not see the option to switch sublocations
    const hasMultiSubLocations = this.partner && this.partner.subLocations && this.partner.subLocations.length > 1;
    if ((selectedView === PORTAL_TYPES.SHELTER || selectedView === PORTAL_TYPES.SHELTER_NETWORK) && option.permissions.multiSubLocations && !hasMultiSubLocations) {
      return false;
    }
    return true;
  }

  private _isOptionAccessible_superAdmin (
    { isSuperAdmin }: IPortalSwitcherUserPermission,
    { permissions: { isSuperAdmin: superAdminConfig } }: IPortalSwitcherDropdownOption,
  ): boolean {
    return isSuperAdmin && superAdminConfig && superAdminConfig.needsSuperAdminAccess;
  }

  private _isOptionAccessible_hybridUser (
    {
      isHybridUser,
      hasHybridAccess,
    }: IPortalSwitcherUserPermission,
    { permissions: { isHybridUser: hybridUserConfig } }: IPortalSwitcherDropdownOption,
  ): boolean {
    return isHybridUser && hybridUserConfig && (!hybridUserConfig.needsHybridAccess || hasHybridAccess === hybridUserConfig.needsHybridAccess);
  }

  private _isOptionAccessible_clusterUser (
    {
      isClusterUser,
      hasHybridAccess,
      hasAccessToMultiSchoolsOrShelters,
      hasAccessToAtLeastOneSchoolOrShelter,
      hasNetworkAccess,
    }: IPortalSwitcherUserPermission,
    { key, permissions: { isClusterUser: clusterUserConfig } }: IPortalSwitcherDropdownOption,
  ): boolean {
    const isViewNetworkAccessible = key === 'View Network' ? this.isViewNetworkEnabledForDistrict() : true;
    return (
      isViewNetworkAccessible &&
      isClusterUser &&
      clusterUserConfig &&
      (!clusterUserConfig.needsHybridAccess || hasHybridAccess === clusterUserConfig.needsHybridAccess) &&
      (!clusterUserConfig.needsMultiSchoolOrShelterAccess ||
        hasAccessToMultiSchoolsOrShelters === clusterUserConfig.needsMultiSchoolOrShelterAccess) &&
      (!clusterUserConfig.needsAtLeastOneSchoolOrShelterAccess ||
        hasAccessToAtLeastOneSchoolOrShelter === clusterUserConfig.needsAtLeastOneSchoolOrShelterAccess) &&
      (
        !clusterUserConfig.needsNetworkAccess ||
        clusterUserConfig.needsNetworkAccess === hasNetworkAccess
      )
    );
  }

  private _isOptionAccessible_multiSchoolUser (
    {
      isMultiSchoolUser,
      hasAccessToAtLeastTwoSchools,
    }: IPortalSwitcherUserPermission,
    { permissions: { isMultiSchoolUser: multiSchoolUserConfig } }: IPortalSwitcherDropdownOption,
  ): boolean {
    return isMultiSchoolUser && multiSchoolUserConfig?.needsAtLeastTwoSchoolsAccess && (multiSchoolUserConfig?.needsAtLeastTwoSchoolsAccess === hasAccessToAtLeastTwoSchools);
  }

  private _isOptionAccessible_multiDistrictUser (
    { isMultiDistrictUser }: IPortalSwitcherUserPermission,
    { permissions: { isMultiDistrictUser: multiDistrictUserConfig } }: IPortalSwitcherDropdownOption,
  ): boolean {
    return isMultiDistrictUser && multiDistrictUserConfig;
  }

  private isViewNetworkEnabledForDistrict () {
    const currentDistrict = this.sessionStorageService.getItem('currentDistrict');
    return !districtsConfig.DISTRICTS_WITH_VIEW_NETWORK_DISABLED.includes(currentDistrict);
  };
}
