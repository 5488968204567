import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface IConfirmUploadModalComponentData {
  title: string;
  recordCount: number;
  mp?: number;
}

const EMPTY_UPLOAD_MESSAGE = {
  'College list upload':
    'The Data Portal already contains all the Postsecondary information outlined in the selected CSV file.',
};

@Component({
  templateUrl: './csv-upload-confirm-modal.component.html',
  styleUrls: ['./csv-upload-confirm-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CsvUploadConfirmModalComponent {
  title: string;
  recordCount: number;
  studentText: string;
  mp: number;
  emptyMessage: string;

  constructor (
    public dialogRef: MatDialogRef<CsvUploadConfirmModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IConfirmUploadModalComponentData,
  ) {}

  ngOnInit (): void {
    this.dialogRef.disableClose = true;
    this.title = this.data.title;
    this.emptyMessage = EMPTY_UPLOAD_MESSAGE[this.title];
    this.recordCount = this.data.recordCount;
    this.studentText = this.recordCount > 1 ? 'students' : 'student';
    this.mp = this.data.mp;
  }

  onConfirm (): void {
    if (this.recordCount > 0) {
      this.dialogRef.close(true);
    }
  }

  onCancel (): void {
    this.dialogRef.close(false);
  }
}
