<div
  *ngIf="options.length; else disabledSwitcher"
  class="portal-switcher-container active"
  [matMenuTriggerFor]="dropdownMenu"
>
  <span class="school-name">{{ label }}</span>
  <nv-icon class="sidebar-item-dropdown" name="dropdown-blue"></nv-icon>
  <mat-menu class="portal-switcher-menu" #dropdownMenu="matMenu" [overlapTrigger]="false">
    <nv-dropdown-item
      *ngFor="let suboption of options"
      (selectItem)="selectOption($event)"
      [option]="suboption"
    ></nv-dropdown-item>
  </mat-menu>
</div>

<ng-template #disabledSwitcher>
  <div class="portal-switcher-container">
    <span class="school-name">{{ label }}</span>
  </div>
</ng-template>
