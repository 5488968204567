import { IStudentMini } from './student.interface';
import { IUserMini } from './user.interface';

/* eslint-disable no-unused-vars */
export enum ValidTaskModeType {
  CREATE_TASK_SCHOOL = 'CREATE_TASK_SCHOOL',
  CREATE_BULK_TASK_SCHOOL = 'CREATE_BULK_TASK_SCHOOL',
  EDIT_TASK_SCHOOL = 'EDIT_TASK_SCHOOL',
  DELETE_TASK_SCHOOL = 'DELETE_TASK_SCHOOL',
  EDIT_BULK_TASK_SCHOOL = 'EDIT_BULK_TASK_SCHOOL',
  TASK_DETAILS_SCHOOL = 'TASK_DETAILS_SCHOOL',
  CREATE_SINGLE_TASK_SCHOOL = 'CREATE_SINGLE_TASK_SCHOOL',
  UPDATE_SINGLE_TASK_SCHOOL = 'UPDATE_SINGLE_TASK_SCHOOL',
}

export enum TaskStatus {
  ACTIVE = 'ACTIVE',
  COMPLETE = 'COMPLETE',
  DELETED = 'DELETED',
}

export type IValidTaskModes =
  ValidTaskModeType.CREATE_TASK_SCHOOL |
  ValidTaskModeType.EDIT_TASK_SCHOOL |
  ValidTaskModeType.DELETE_TASK_SCHOOL |
  ValidTaskModeType.CREATE_BULK_TASK_SCHOOL |
  ValidTaskModeType.EDIT_BULK_TASK_SCHOOL |
  ValidTaskModeType.TASK_DETAILS_SCHOOL |
  ValidTaskModeType.CREATE_SINGLE_TASK_SCHOOL |
  ValidTaskModeType.UPDATE_SINGLE_TASK_SCHOOL
  ;

export type TTaskStatus =
  TaskStatus.ACTIVE |
  TaskStatus.COMPLETE |
  TaskStatus.DELETED;

export type TValidTaskCategories =
  'Academics'
  | 'Attendance'
  | 'Attendance Outreach'
  | 'Credits'
  | 'General'
  | 'Graduation'
  | 'Point People'
  | 'Postsecondary'
  | 'Regents'
  | 'Remote Learning'
  | 'Screeners / Assessments'
  | 'Supports';

export type ITask = {
  _id: string;
  student: IStudentMini;
  schoolId: string;
  schoolYear: string;
  createdBy: IUserMini;
  createdAt: string;
  status: TTaskStatus;
  attachments?: {
    attOutreachDate: string;
  }
  description: string;
  categories: TValidTaskCategories[];
  assignee: IUserMini;
  lastEditedBy?: IUserMini;
  lastEditedOn?: string;
  resolvedBy?: IUserMini;
  resolvedAt?: string;
  comment?: string;
  name?: string;
}
