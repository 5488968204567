<base-modal
  class="base-modal course-plan-modal"
  [title]="title"
  [itemCount]="itemCount"
  [itemType]="itemType"
  [isProfileMode]="isProfileMode"
  [buttonMode]="null"
  (page1)="resetModalType($event)"
  [titleTooltipInfo]="titleTooltipInfo"
  [childHasSecondPage]="updateNavIcon"
>
      <div class="course-plan-modal-container">
        <ng-container *ngIf="modalType === 'INITIAL' && !updateNavIcon">
          <form [formGroup]="form" autocomplete="off">
            <nv-form-field label="Select plan type">
              <mat-radio-group class="nv-mat-radio-group" formControlName="selectedOption">
                <mat-radio-button value="COURSE_SCHEDULED" class="radio-button-vertical">
                  Select course from master schedule or code deck
                </mat-radio-button>
                <mat-radio-button value="COURSE_MANUAL" class="radio-button-vertical">
                  Manually enter plan
                </mat-radio-button>
              </mat-radio-group>
            </nv-form-field>
  
            <mat-dialog-actions class="course-plans-actions">
              <nv-button type="secondary" (clickButton)="close()"> Cancel </nv-button>
              <nv-button (clickButton)="submit(form)"> Next </nv-button>
            </mat-dialog-actions>
          </form>
        </ng-container>
      </div>
        <ng-container *ngIf="(schoolCourseDiffs$ | async) as schoolCourseDiffs">
          <ng-container *ngIf="(schoolGapPlans$ | async) as schoolGapPlans">
        <ng-container *ngIf="modalType === 'COURSE_SCHEDULED'">
          <scheduled-course-plan 
            [schoolCourseDiffs]="schoolCourseDiffs"
            [schoolGapPlans]="schoolGapPlans"
          >
          </scheduled-course-plan>
        </ng-container>
  
        <ng-container *ngIf="modalType === 'COURSE_MANUAL'">
          <manual-course-plan />
        </ng-container>
    </ng-container>
  </ng-container>
</base-modal>