import { ILoadSchoolAssessmentsPayload } from './../../../../store/actions/assessments-actions/school-assessments-actions';
import { Injectable } from '@angular/core';
import { each } from 'lodash';
import { IBulkUpdateSchoolAssessmentsPayload, ICreateSchoolAssessmentPayload } from '../../../../store';

@Injectable()
export class GraphQLSchoolAssessmentsHelperService {
  constructor() {
    //
  }

  getSchoolAssessmentsQuery({ schoolId, schoolYear = '', where = '{}' }: ILoadSchoolAssessmentsPayload): string {
    return `{
      SchoolAssessments(schoolId: "${schoolId}", schoolYear: "${schoolYear}", where: "${where}"){
        _id
        startDate
        endDate
        adminNumber
        status
        gradeLevels
        assessment {
          assessmentNickName
          assessmentTitle
          items {
            section
            validValues
          }
        }
        lastEditedBy {
          firstName
          lastName
        }
        lastEditedOn
      }
    }`;
  }

  bulkCreateSchoolAssessmentsQuery(schoolAssessmentParams: ICreateSchoolAssessmentPayload): string {
    const { schoolId, assessmentKey, payload } = schoolAssessmentParams;
    let payloadString = '';
    each(payload, assessmentDetail => {
      const { adminNumber, startDate, endDate, gradeLevels, status } = assessmentDetail;
      payloadString += `{adminNumber: ${adminNumber}, `;
      if (startDate) payloadString += `startDate: "${startDate}", `;
      if (endDate) payloadString += `endDate: "${endDate}", `;
      if (status) payloadString += `status: "${status}", `;
      if (gradeLevels) {
        // required to stringify array of strings into shape that graphql will accept (JE)
        let gradeLevelsString = '';
        each(gradeLevels, level => (gradeLevelsString += `"${level}", `));
        gradeLevelsString = gradeLevelsString.substring(0, gradeLevelsString.length - 2);
        payloadString += `gradeLevels: [${gradeLevelsString}], `;
      }
      payloadString = payloadString.substring(0, payloadString.length - 2);
      payloadString += '}';
    });
    const optionsString = `schoolId: "${schoolId}", assessmentKey: "${assessmentKey}", payload: [${payloadString}]`;
    return `mutation{
      bulkCreateSchoolAssessment(options: {${optionsString}}){
        backgroundJobId
      }
    }`;
  }

  bulkUpdateSchoolAssessmentsQuery(schoolAssessmentParams: IBulkUpdateSchoolAssessmentsPayload): string {
    const { payload } = schoolAssessmentParams;
    let payloadString = '';
    each(payload, assessmentDetail => {
      const { schoolAssessmentId, startDate, endDate, status, gradeLevels } = assessmentDetail;
      payloadString += `{schoolAssessmentId: "${schoolAssessmentId}", `;
      if (startDate) payloadString += `startDate: "${startDate}", `;
      if (endDate) payloadString += `endDate: "${endDate}", `;
      if (status) payloadString += `status: "${status}", `;
      if (gradeLevels) {
        // required to stringify array of strings into shape that graphql will accept (JE)
        let gradeLevelsString = '';
        each(gradeLevels, level => (gradeLevelsString += `"${level}", `));
        gradeLevelsString = gradeLevelsString.substring(0, gradeLevelsString.length - 2);
        payloadString += `gradeLevels: [${gradeLevelsString}], `;
      }
      payloadString = payloadString.substring(0, payloadString.length - 2);
      payloadString += '}';
    });
    return `mutation{
      bulkUpdateSchoolAssessment(patch: { payload: [${payloadString}]}){
        backgroundJobId
      }
    }`;
  }
}
