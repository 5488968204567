/* tslint:disable */

import { Action } from '@ngrx/store';
import { ISchoolAssessment } from '../../../shared/typings/interfaces/school-assessment.interface';

export const LOAD_SCHOOL_ASSESSMENTS = '[Portal] Load SchoolAssessments';
export const LOAD_SCHOOL_ASSESSMENTS_SUCCESS = '[Portal] Load SchoolAssessments Success';
export const LOAD_SCHOOL_ASSESSMENTS_FAIL = '[Portal] Load SchoolAssessments Failure';

export const BULK_UPDATE_SCHOOL_ASSESSMENTS = '[Portal] Bulk Update SchoolAssessments';
export const BULK_UPDATE_SCHOOL_ASSESSMENTS_SUCCESS = '[Portal] Bulk Update SchoolAssessments Success';
export const BULK_UPDATE_SCHOOL_ASSESSMENTS_FAIL = '[Portal] Bulk Update SchoolAssessments Failure';

export const BULK_CREATE_SCHOOL_ASSESSMENTS = '[Portal] Bulk Create ShoolAssessments';
export const BULK_CREATE_SCHOOL_ASSESSMENTS_SUCCESS = '[Portal] Bulk Create ShoolAssessments Success';
export const BULK_CREATE_SCHOOL_ASSESSMENTS_FAIL = '[Portal] Bulk Create ShoolAssessments Failure';

export interface ILoadSchoolAssessmentsPayload {
  schoolId: string;
  schoolYear?: string;
  where?: string;
}

export interface ISchoolAssessmentUpdateOption {
  schoolAssessmentId: string;
  startDate?: string;
  endDate?: string;
  status?: string;
  gradeLevels?: string[];
}
export interface IBulkUpdateSchoolAssessmentsPayload {
  payload: ISchoolAssessmentUpdateOption[];
}

export interface ISchoolAssessmentCreateOption {
  adminNumber: number;
  startDate?: string;
  endDate?: string;
  gradeLevels?: string[];
  status?: string;
}

export interface ICreateSchoolAssessmentPayload {
  schoolId: string;
  assessmentKey: string;
  payload: ISchoolAssessmentCreateOption[];
}

// register store actions
export class LoadSchoolAssessments implements Action {
  readonly type = LOAD_SCHOOL_ASSESSMENTS;
  constructor(public payload: ILoadSchoolAssessmentsPayload) {}
}

export class LoadSchoolAssessmentsSuccess implements Action {
  readonly type = LOAD_SCHOOL_ASSESSMENTS_SUCCESS;
  constructor(public payload: { data: { SchoolAssessments: ISchoolAssessment[] } }) {}
}

export class LoadSchoolAssessmentsFail implements Action {
  readonly type = LOAD_SCHOOL_ASSESSMENTS_FAIL;
  constructor(public payload: any) {}
}

export class BulkCreateSchoolAssessments implements Action {
  readonly type = BULK_CREATE_SCHOOL_ASSESSMENTS;
  constructor(public payload: ICreateSchoolAssessmentPayload) {}
}

export class BulkCreateSchoolAssessmentsSuccess implements Action {
  readonly type = BULK_CREATE_SCHOOL_ASSESSMENTS_SUCCESS;
  constructor(public payload: { createSchoolAssessment: ISchoolAssessment }) {}
}

export class BulkCreateSchoolAssessmentsFail implements Action {
  readonly type = BULK_CREATE_SCHOOL_ASSESSMENTS_FAIL;
  constructor(public payload: any) {}
}

export class BulkUpdateSchoolAssessments implements Action {
  readonly type = BULK_UPDATE_SCHOOL_ASSESSMENTS;
  constructor(public payload: IBulkUpdateSchoolAssessmentsPayload) {}
}

export class BulkUpdateSchoolAssessmentsSuccess implements Action {
  readonly type = BULK_UPDATE_SCHOOL_ASSESSMENTS_SUCCESS;
  constructor(public payload: any) {}
}

export class BulkUpdateSchoolAssessmentsFail implements Action {
  readonly type = BULK_UPDATE_SCHOOL_ASSESSMENTS_FAIL;
  constructor(public payload: any) {}
}

// action types
export type SchoolAssessmentsAction =
  | LoadSchoolAssessments
  | LoadSchoolAssessmentsSuccess
  | LoadSchoolAssessmentsFail
  | BulkCreateSchoolAssessments
  | BulkCreateSchoolAssessmentsSuccess
  | BulkCreateSchoolAssessmentsFail
  | BulkUpdateSchoolAssessments
  | BulkUpdateSchoolAssessmentsSuccess
  | BulkUpdateSchoolAssessmentsFail;
