import { TMatDisplayOption } from '../transform-to-mat-display-options/transform-to-mat-display-options.pipe';
import { Pipe, PipeTransform } from '@angular/core';

/**
 * This pipe implements transform as a pure function that sorts display options based on how close the search value matches the option.
 */

export type WeightedOptionsMap = {
  [optionKey: string]: number
};

export type WeightedOptionsMapsCache = Map<string, WeightedOptionsMap>;

@Pipe({ name: 'sortByPriorityOptions' })
export class SortByPriorityOptions implements PipeTransform {
  private weightedOptionsMapsCache: WeightedOptionsMapsCache = new Map();

  private setWeightedOptionsMapsCache (searchValue: string, weightedOptionsMap: WeightedOptionsMap): void {
    this.weightedOptionsMapsCache.set(searchValue, weightedOptionsMap);
  }

  // creates map based on how close the search value matches the option 0 being a complete match
  private generateWeightedOptionsMap (options: TMatDisplayOption[], searchValue: string): WeightedOptionsMap {
    const weightedOptionsMap: WeightedOptionsMap = options.reduce((acc, option) => {
      if ((option.human).toLowerCase().includes(searchValue.toLowerCase())) {
        const difference = option.human.length - searchValue.length;
        acc[option.human] = difference;
      } else {
        acc[option.human] = option.human.length;
      }
      return acc;
    }, {});
    return weightedOptionsMap;
  }

  // .sort() mutates array
  private sortOptions = (sortedOptions: TMatDisplayOption[], weightedOptionsMap: WeightedOptionsMap): TMatDisplayOption[] => {
    const sortedOptionsCopy = [...sortedOptions];
    sortedOptionsCopy.sort((a, b) => {
      return weightedOptionsMap[a.human] - weightedOptionsMap[b.human];
    });
    return sortedOptionsCopy;
  };

  transform (options: TMatDisplayOption[], searchValue: string, weightedOptionsSortingEnabled: boolean): TMatDisplayOption[] {
    let sortedOptions = options;
    if (weightedOptionsSortingEnabled) {
      if (searchValue) {
        if (this.weightedOptionsMapsCache.has(searchValue)) {
          sortedOptions = this.sortOptions(sortedOptions, this.weightedOptionsMapsCache.get(searchValue));
        } else {
          const newWeightedOptionsMap = this.generateWeightedOptionsMap(options, searchValue);
          this.setWeightedOptionsMapsCache(searchValue, newWeightedOptionsMap);
          sortedOptions = this.sortOptions(sortedOptions, newWeightedOptionsMap);
        }
      }
    }
    return sortedOptions;
  }
}
