import { PartnerTypes } from 'Src/ng2/shared/typings/interfaces/partner.interface';
import { IStudentGroupingsPayload } from '../../actions/student-groupings-actions/student-groupings-actions';

// @ts-ignore
export const generateQuery = ({ payload }: { payload: IStudentGroupingsPayload }) => {
  const { contextPartnerId, contextPartnerType, filterKeys, groupingKey, columns, id, subLocationId } = payload;
  const _contextPartnerType = contextPartnerType || PartnerTypes.SCHOOL;
  const _subLocationId = subLocationId || '';

  const columnKeys: string[] = columns.map(({ graphQlKey }) => graphQlKey);
  // TODO: handle wildcard in groupingKey
  const filterKeysArray: string[] = filterKeys.reduce((accum: string[], key: string) => {
    if (key.includes('="')) {
      // Escape the wildcard value passed from filter dropdown, in addition to the one from rollup table
      const [calcKey, wildcard] = key.split('=');
      const formattedWildcard = wildcard.replace(/"/g, '\\"');
      accum.push(`"${calcKey}=${formattedWildcard}"`);
    } else {
      accum.push(`"${key}"`);
    }
    return accum;
  }, []);
  const idArg = id ? `"${id}"` : 'null';

  return `{
    StudentGroupings(
      contextPartnerId: "${contextPartnerId}",
      contextPartnerType: "${_contextPartnerType}",
      filterKeys: [${filterKeysArray}],
      groupingKey: "${groupingKey}",
      columns: ${JSON.stringify(columnKeys)},
      id: ${idArg},
      subLocationId: "${_subLocationId}"
    ){
        key
        human
        tooltip
        rowData {
          data
          meta
          columnKey
        }
    }
  }
  `;
};

export const STUDENT_GROUPINGS_JOINS = [];
