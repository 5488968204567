/* eslint-disable no-unused-vars */
// Entity refers to the what unit each row represents in the grid.
// If the entity is shelter, every row is a shelter, if the entity is student every row is a student etc.
export enum EntityId {
  School = 'school',
  Shelter = 'shelter',
  Student = 'student',
}

export type Entity = {
  active: Boolean;
  breadcrumb: string;
  icon: string;
  id: EntityId;
  searchBoxPlaceHolder: string;
  selected: Boolean;
  selectedIcon: string;
  text: string;
};
