import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

// services
import { WizardHelpers } from 'Src/ng2/school/sdc/services/wizard-helpers.service';
import { CreditGapsPriorityGroupingsTransferService } from 'Src/ng2/school/sdc/services/credit-gaps-priority-groupings/credit-gaps-priority-groupings-transfer.service';
import { ObjectCache } from 'Src/ng2/shared/services/object-cache/object-cache.service';

// modules
import { WizardFilterActionsModule } from 'Src/ng2/school/sdc/wizard-filter-actions/wizard-filter-actions.module';

// components
import { ReviewGapsStep } from './review-gaps-component';

@NgModule({
  imports: [CommonModule, WizardFilterActionsModule, MatButtonModule],
  exports: [ReviewGapsStep],
  declarations: [ReviewGapsStep],
  providers: [ObjectCache, WizardHelpers, CreditGapsPriorityGroupingsTransferService],
})
export class ReviewGapsStepModule { }
