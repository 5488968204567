export const RegentsRecommendation = {
  metric: 'Student needs to pass this exam based on your metric',
  commonCore: 'Student must sit for the common core exam',
  course: 'Student is in a course culminating in the Regents exam',
  collegeReadiness: 'Student can resit to meet the CUNY benchmark',
  pastDue: 'Student is overdue to pass this exam by your metric',
  previousAttempts: 'Student has attempted exam previously and still needs to pass it',
  dueNow: 'Student is due to pass this exam by the next Regents administration on your metric',
  creditsEarned: 'Student has earned credits related to this exam and still needs to pass it',
};
