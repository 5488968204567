import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import * as dashboardActions from '../actions/dashboard-actions';
import { ApiService } from './../../shared/services/api-service/api-service';
import {
  DASHBOARD_STUDENT_SUPPORTS_PROJECTION,
  DASHBOARD_STUDENTS_JOINS,
  DASHBOARD_STUDENTS_PROJECTION,
} from './projections/dashboard-students';

export interface IDashboardPayload {
  schoolId: string;
  graphName: string;
  graphQuery?: string;
}

@Injectable()
export class DashboardEffects {
  constructor(private actions$: Actions, private apiService: ApiService, private store$: Store<any>) {}

  loadDashboard$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dashboardActions.LOAD_DASHBOARD),
      mergeMap((action: { type: string; payload: IDashboardPayload }) => {
        const { schoolId, graphName, graphQuery } = action.payload;
        return this.apiService.getGraph(schoolId, graphName, graphQuery).pipe(
          mergeMap(graph => [new dashboardActions.LoadDashboardSuccess(graph)]),
          catchError(error => of(new dashboardActions.LoadDashboardFail({ error, graphName }))),
        );
      }),
    );
  });

  loadDashboardStudents$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dashboardActions.LOAD_DASHBOARD_STUDENTS),
      switchMap((action: { type: string; payload: any }) => {
        const { schoolId } = action.payload;
        const projection = DASHBOARD_STUDENTS_PROJECTION;
        const joins = DASHBOARD_STUDENTS_JOINS;
        const where = { schoolStatus: { $in: ['A'] } }; // do we only want active students? confirm.
        const payload = { schoolId, projection, joins, where };
        return this.apiService.getStudents(payload).pipe(
          mergeMap(students => [new dashboardActions.LoadDashboardStudentsSuccess(students)]),
          catchError(error => from([new dashboardActions.LoadDashboardStudentsFail(error)])),
        );
      }),
    );
  });

  // studentSupports
  loadDashboardStudentSupports$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dashboardActions.LOAD_DASHBOARD_STUDENT_SUPPORTS),
      switchMap((action: { type: string; payload: any }) => {
        const { schoolId } = action.payload;
        const projection = DASHBOARD_STUDENT_SUPPORTS_PROJECTION;
        return this.apiService.getStudentSupports(schoolId, {}, projection).pipe(
          mergeMap((studentSupports: any) => [
            new dashboardActions.LoadDashboardStudentSupportsSuccess(studentSupports.data),
          ]),
          catchError(error => from([new dashboardActions.LoadDashboardStudentSupportsFail(error)])),
        );
      }),
    );
  });
}
