export const HIGH_SCHOOL_TYPES = ['Small HS', 'Large HS', '6 to 12', 'Transfer', 'K to 12'];

export const EMS_SCHOOL_TYPES = ['K to 5', 'K to 8', 'K to 12', '6 to 8', '6 to 12'];

export const MIDDLE_SCHOOL_TYPES = ['K to 8', 'K to 12', '6 to 8', '6 to 12'];

export const ELEMENTARY_SCHOOL_TYPES = ['K to 5', 'K to 8', 'K to 12'];

export const HIGH_SCHOOL_9_TO_12_TYPES = ['Small HS', 'Large HS'];

export const AIM_SCHOOLS_DBNS = ['84X200', '84K395'];
