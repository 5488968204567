<p class="mat-body-2">
  Of the {{filteredStudents.length}} students
</p>

<table class="mat-body-2 priority-table">
  <tr *ngFor="let group of priorityGroupsTable">
    <td>
      <span class="description">Priority {{group.priorityVal}}:</span>
      <span>
        <wizard-filter-actions [data]="group.studentIds" [options]="options"></wizard-filter-actions>
        
        <ng-container [ngPlural]="group.studentIds.length">
          <ng-template ngPluralCase="0"> students are </ng-template>
          <ng-template ngPluralCase="1"> student is </ng-template>
          <ng-template ngPluralCase="few"> students are </ng-template>
          <ng-template ngPluralCase="other"> students are </ng-template>
        </ng-container>
        
        {{group.priorityLabel}}
      </span>
    </td>
    <td>
      <button
        class="step-action-button"
        mat-raised-button
        [disabled]="!group.studentIds.length"
        (click)="reviewStudents(group.studentIds)"
        >Review {{group.studentIds.length}}</button
      >
    </td>
  </tr>
</table>

