import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { NvIconModule } from '../nv-icon/nv-icon.module';
import { NvPillModule } from '../nv-pill/nv-pill.module';
import { NvPillButtonComponent } from './nv-pill-button.component';
import { NvAriaLabelModule } from '../directives/nv-aria-label/nv-aria-label.module';

@NgModule({
  imports: [CommonModule, NvPillModule, NvIconModule, MatRippleModule, NvAriaLabelModule],
  declarations: [NvPillButtonComponent],
  exports: [NvPillButtonComponent],
})
export class NvPillButtonModule {}
