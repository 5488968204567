import { UrlMatchResult, UrlSegment } from '@angular/router';

export function deprecatedNetworkRoutesMatcher(segments: UrlSegment[]): UrlMatchResult {
  if (segments.length === 0) {
    // matches ''
    return { consumed: segments };
  }

  if (segments.length === 1) {
    // matches dashboard, midlevel, studentLevel, settings, grid
    const [urlSegment] = segments;
    if (['dashboard', 'midlevel', 'studentLevel', 'settings', 'grid'].includes(urlSegment.path)) {
      return { consumed: segments };
    }
  }

  if (segments.length === 2) {
    // matches shelter/settings
    const [urlSegment1, urlSegment2] = segments;
    if (urlSegment1.path === 'shelter' && urlSegment2.path === 'settings') {
      return { consumed: segments };
    }
  }
  return <UrlMatchResult>(null as any);
}
