import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { IBatchData } from '../../../../school/student-level-dashboard/student-level-dash.component';
import { IPageHeaderMeta } from '../../../components/header/page-header/page-header-meta.interface';
import { IGroupData, IListConfig, IRowData } from '../../../models/list-models';
import { TSortDirections } from '../../../services/list-services/sort-and-filter.service';
import { IDropdownOption } from '../../../../../../projects/shared/nvps-libraries/design/interfaces/design-library.interface';
import { PortfolioGroupingService } from './portfolio-grouping.service';

@Component({
  selector: 'cluster-user-portfolio-list',
  templateUrl: './cluster-user-portfolio-list.component.html',
  styleUrls: ['./cluster-user-portfolio-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ClusterUserPortfolioListComponent implements OnInit {
  // HEADER PART
  @Input() user: {
    name: string;
    id: string | null;
  };

  @Input() iconName: string;
  @Input() btnName: string;
  public pageHeaderMeta: IPageHeaderMeta;

  // BATCH EDIT TOOL BAR
  public batchEditOptions: IDropdownOption[] = [
    {
      key: 'no_access',
      human: 'Set to No access',
    },
    {
      key: 'view_all',
      human: 'Set to View only',
    },
    {
      key: 'edit_all',
      human: 'Set to Can edit',
    },
  ];

  private dropdownHuman = {
    no_access: 'No access',
    view_all: 'View only',
    edit_all: 'Can edit',
  };

  // needed by list-container
  @Input() groupingData$: BehaviorSubject<null | IGroupData[]>;
  @Input() portfolioListComponentConfig: any;
  public searchFilter: FormControl = new FormControl('');
  public batchActionsMode$: Observable<boolean>;
  public batchActionsSelectedIds$: BehaviorSubject<string[]>;
  public batchActions: any;
  public listConfig: IListConfig;
  public dynamicComponentTrigger: boolean;

  // OUTPUT EVENT
  @Output() clickedPrimaryBtn = new EventEmitter();
  @Output() clickedSeconBtn = new EventEmitter();

  constructor(
    private groupingService: PortfolioGroupingService
  ) {}

  ngOnInit (): void {
    this.pageHeaderMeta = {
      textboxPlaceholder: this.portfolioListComponentConfig.searchboxPlaceholder,
      icon: {
        name: this.iconName,
        tooltip: null,
        handler: this.onClickSeconBtn.bind(this),
      },
    };
    this.batchActionsMode$ = of(true);
    this.batchActions = this._initializeBatchActions();
  }

  public onBatchEditAccessLevel (destination: string): void {
    const idsToMove = this.groupingService.hashIdsToMove(this.batchActionsSelectedIds$.value);
    const newGroupings = this.groupingService.getNewGroupings({
      groupData: this.groupingData$.value,
      idsToMove,
      destination,
      dropdownHuman: this.dropdownHuman,
      groupingColumnKey: this.portfolioListComponentConfig.groupingColumnKey,
    });
    this.groupingData$.next(newGroupings);
    this.batchActions.reset();
  }

  public onSingleAccessLevel ($event: { selectedKey: string; row: IRowData[] }): void {
    const { selectedKey, row } = $event;
    const meta = JSON.parse(row[0].meta);
    const schoolId = meta.data; // DBN
    const idsToMove = { [schoolId]: true };

    const destination = Object.keys(this.dropdownHuman).find(key => {
      return this.dropdownHuman[key] === selectedKey;
    });

    const newGroupings = this.groupingService.getNewGroupings({
      groupData: this.groupingData$.value,
      idsToMove,
      destination,
      dropdownHuman: this.dropdownHuman,
      groupingColumnKey: this.portfolioListComponentConfig.groupingColumnKey,
    });
    this.groupingData$.next(newGroupings);
  }

  public onUncheckAll (): void {
    // save pending checkboxes selections(empty []) in parent's prop
    // which will re-enable all permission cells' dropdowns
    this.batchActions.reset();
  }

  // BATCH ACTION PART
  public onBatchAction ($event: IBatchData): void {
    // save pending checkboxes selections(non-empty array) in parent's prop
    // which will disable all permission cell's dropdowns
    let ids: string[];
    const { updateAll, data, level } = $event;
    switch (level) {
      case 'SECTION':
        ids = (data as IRowData[][]).map((row: IRowData[]) => this.groupingService.getRowIdFromRow(row));
        break;
      case 'ROW':
        ids = Array.of(this.groupingService.getRowIdFromRow(data as IRowData[]));
        break;
      default:
        ids = [];
    }
    this.batchActions.updateSchoolIds({ updateAll, ids });
  }

  public onFocusedGroup ($event: {
    groupData: IGroupData;
    sortKey: string;
    sortDirection: TSortDirections;
    groupIndx: number;
  }) {
    const { groupIndx } = $event;
    const newGroupings: IGroupData[] = this.groupingService.getNewGroupingsWithTargetGroupingExpanded(this.groupingData$.value, groupIndx);
    this.groupingData$.next(newGroupings);
  }

  // either create or update
  public onClickPrimaryBtn (): void {
    const permissionsChanges = this.groupingService.getClusterPortfolioPermissionsChanges(this.groupingData$.value);
    this.clickedPrimaryBtn.emit({ permissions: permissionsChanges });
  }

  // either back or cancel
  public onClickSeconBtn (): void {
    const currentGrouping = this.groupingService.getNewGroupingsWithShowAllAsFalseForAll(this.groupingData$.value);
    this.clickedSeconBtn.emit({ currentGrouping });
  }

  private _initializeBatchActions () {
    let idHash = {};
    const batchActions = {
      updateSchoolIds: ($event: { updateAll: boolean; ids: string[] }): void => {
        const { updateAll, ids } = $event;
        ids.forEach((id: string) => {
          if (idHash[id] && !updateAll) delete idHash[id];
          else idHash[id] = true;
          return id;
        });
        const idsToSend = Object.keys(idHash);
        this.batchActionsSelectedIds$.next(idsToSend);
        if (idsToSend.length) {
          this.dynamicComponentTrigger = false;
        } else {
          this.dynamicComponentTrigger = true;
        }
      },
      reset: (): void => {
        idHash = {};
        this.batchActionsSelectedIds$.next([]);
        this.dynamicComponentTrigger = true;
      },
      init: (): void => {
        this.batchActionsSelectedIds$ = new BehaviorSubject([]);
        this.dynamicComponentTrigger = true;
      },
    };
    this.batchActionsSelectedIds$ = new BehaviorSubject([]);
    this.dynamicComponentTrigger = true;
    return batchActions;
  }
}
