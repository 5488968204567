import { Injectable, SecurityContext } from '@angular/core';
import { map, camelCase } from 'lodash';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class CsvImporterService {
  constructor(private sanitizer: DomSanitizer) {
    //
  }

  //  @param columns: a projection of fields that will be on the returned object
  //  @param ignoreCase: if true, column headers will be sanitized to ignore casing
  csvToJson(csv: string, columns?: { [key: string]: number }, headerCase?: string): Object {
    let sanitizedCsv = this.sanitizer.sanitize(SecurityContext.HTML, csv);
    // sanitized string replaces newline '/n 'with unicode newline '&#10;'. revert this change.
    const fixedSanitizedCsv = sanitizedCsv.replace(/&#10;/g, '\n').replace(/&#34;/g, '"');
    const csvArray = this.csvToArray(fixedSanitizedCsv);
    const restrictColumns = Object.keys(columns).length;
    const result = [];
    const headers = map(csvArray[0], header => {
      let cleanedHeader = header.trim().replace(/ /g, '_');
      if (headerCase === 'ignore') cleanedHeader = cleanedHeader.toLowerCase();
      else if (headerCase === 'camel') cleanedHeader = camelCase(cleanedHeader);
      return cleanedHeader;
    });
    for (let i = 1; i < csvArray.length; i++) {
      const obj = {};
      const currentLine = csvArray[i];
      for (let j = 0; j < headers.length; j++) {
        const header = headers[j];
        if (restrictColumns) {
          const columnHasHeader = columns[header];
          if (columnHasHeader) {
            const item = currentLine[j];
            obj[header] = item;
          }
        } else {
          obj[header] = currentLine[j];
        }
      }
      result.push(obj);
    }
    return result;
  }

  // see https://stackoverflow.com/questions/36288375/how-to-parse-csv-data-that-contains-newlines-in-field-using-javascript
  // for more information (JE)
  csvToArray(csv: string, delimiter = ',') {
    const pattern = new RegExp( // regular expression to parse the CSV values. // Delimiters:
      '(\\' +
        delimiter +
        '|\\r?\\n|\\r|^)' +
        // Quoted fields.
        '(?:"([^"]*(?:""[^"]*)*)"|' +
        // Standard fields.
        '([^"\\' +
        delimiter +
        '\\r\\n]*))',
      'gi',
    );

    const rows = [[]]; // array to hold our data. First row is column headers.
    // array to hold our individual pattern matching groups:
    let matches = null; // null if we don't find any matches
    // Loop until we no longer find a regular expression match
    /* tslint:disable-next-line: no-conditional-assignment */
    while ((matches = pattern.exec(csv))) {
      const matched_delimiter = matches[1]; // Get the matched delimiter
      // Check if the delimiter has a length (and is not the start of string)
      // and if it matches field delimiter. If not, it is a row delimiter.
      if (matched_delimiter.length && matched_delimiter !== delimiter) {
        // Since this is a new row of data, add an empty row to the array.
        rows.push([]);
      }
      let matched_value;
      // Once we have eliminated the delimiter, check to see
      // what kind of value was captured (quoted or unquoted):
      if (matches[2]) {
        // found quoted value. unescape any double quotes.
        matched_value = matches[2].replace(new RegExp('""', 'g'), '"');
      } else {
        // found a non-quoted value
        matched_value = matches[3];
      }
      // Now that we have our value string, let's add
      // it to the data array.
      rows[rows.length - 1].push(matched_value);
    }
    return rows; // Return the parsed data Array
  }

  async readCsv(fileInput): Promise<string> {
    const reader = new FileReader();

    return new Promise((resolve: (string) => void, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new Error('Error uploading csv'));
      };
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.readAsText(fileInput);
    });
  }
}
