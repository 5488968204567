export const IDLENESS_TIME_TO_SHOW_WARNING = 60 * 14; // 14 min
export const IDLENESS_WARNING = 60; // 60 seconds
export const INACTIVITY_ERR_MESSAGE = "You've been logged out due to inactivity.";
// Matches error returned by API on auth errors (CM).
// https://github.com/NewVisionsForPublicSchools/nv-student-api/blob/master/lib/auth/nvApiAuth.js#L107
export const API_AUTH_FAILURE_MESSAGE = 'Invalid token';
export const API_AUTH_MISSING_MESSAGE = 'Missing authentication';
export const API_AUTH_INVALID_FORMAT = 'Invalid token format';
export const API_AUTH_NOT_FOUND = 'Not Found';
export const API_AUTH_INVALID_CREDENTIALS = 'Invalid credentials';
