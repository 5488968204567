export const GRAD_PLAN_MAPPING = {
  NON_TRANSFER: {
    ADVANCED_REGENTS_4_YEAR_MAR: {
      human: 'Advanced Regents 4 Year March',
      key: 'Advanced Regents 4 Year March',
      filterValue: 'Advanced Regents 4 Year March',
      order: 0,
    },
    ADVANCED_REGENTS_4_YEAR_JUNE: {
      human: 'Advanced Regents 4 Year June',
      key: 'Advanced Regents 4 Year June',
      filterValue: 'Advanced Regents 4 Year June',
      order: 1,
    },
    ADVANCED_REGENTS_4_YEAR_AUG: {
      human: 'Advanced Regents 4 Year Aug',
      key: 'Advanced Regents 4 Year Aug',
      filterValue: 'Advanced Regents 4 Year Aug',
      order: 2,
    },
    ADVANCED_REGENTS_5_YEAR: {
      human: 'Advanced Regents 5 Year',
      key: 'Advanced Regents 5 Year',
      filterValue: 'Advanced Regents 5 Year',
      order: 3,
    },
    ADVANCED_REGENTS_6_PLUS_YEAR: {
      human: 'Advanced Regents 6+ Year',
      key: 'Advanced Regents 6+ Year',
      filterValue: 'Advanced Regents 6+ Year',
      order: 4,
    },
    REGENTS_4_YEAR_MAR: {
      human: 'Regents 4 Year March',
      key: 'Regents 4 Year March',
      filterValue: 'Regents 4 Year March',
      order: 5,
    },
    REGENTS_4_YEAR_JUNE: {
      human: 'Regents 4 Year June',
      key: 'Regents 4 Year June',
      filterValue: 'Regents 4 Year June',
      order: 6,
    },
    REGENTS_4_YEAR_AUG: {
      human: 'Regents 4 Year Aug',
      key: 'Regents 4 Year Aug',
      filterValue: 'Regents 4 Year Aug',
      order: 7,
    },
    REGENTS_5_YEAR: {
      human: 'Regents 5 Year',
      key: 'Regents 5 Year',
      filterValue: 'Regents 5 Year',
      order: 8,
    },
    REGENTS_6_PLUS_YEAR: {
      human: 'Regents 6+ Year',
      key: 'Regents 6+ Year',
      filterValue: 'Regents 6+ Year',
      order: 9,
    },
    LOCAL_4_YEAR_MAR: {
      human: 'Local 4 Year March',
      key: 'Local 4 Year March',
      filterValue: 'Local 4 Year March',
      order: 10,
    },
    LOCAL_4_YEAR_JUNE: {
      human: 'Local 4 Year June',
      key: 'Local 4 Year June',
      filterValue: 'Local 4 Year June',
      order: 11,
    },
    LOCAL_4_YEAR_AUG: {
      human: 'Local 4 Year Aug',
      key: 'Local 4 Year Aug',
      filterValue: 'Local 4 Year Aug',
      order: 12,
    },
    LOCAL_5_YEAR: {
      human: 'Local 5 Year',
      key: 'Local 5 Year',
      filterValue: 'Local 5 Year',
      order: 13,
    },
    LOCAL_6_PLUS_YEAR: {
      human: 'Local 6+ Year',
      key: 'Local 6+ Year',
      filterValue: 'Local 6+ Year',
      order: 14,
    },
    PLAN_IN_PAST: {
      human: 'Plan in Past',
      key: 'Plan in Past',
      filterValue: 'Plan in Past',
      order: 15,
    },
    PLAN_INCOMPLETE: {
      human: 'Plan Incomplete',
      key: 'Plan Incomplete',
      filterValue: 'Plan Incomplete',
      order: 16,
    },
    GRADUATE: {
      human: 'Graduate',
      key: 'Graduate',
      filterValue: 'Graduate',
      order: 17,
    },
    NON_GRADUATE: {
      human: 'Non-Graduate',
      key: 'Non-Graduate',
      filterValue: 'Non-Graduate',
      order: 18,
    },
    NEGATIVE_DISCHARGE: {
      human: 'Negative Discharge',
      key: 'Negative Discharge',
      filterValue: 'Negative Discharge',
      order: 19,
    },
    NONE: {
      human: 'None',
      key: 'None',
      filterValue: 'None',
      order: 20,
    },
  },

  // TODO UPDATE AT TERM 1 FLIP: remove graduated cohort, add freshman cohort
  // Update order values
  TRANSFER: {
    // 2025
    ADVANCED_REGENTS_JAN_2025: {
      key: 'Advanced Regents Jan 2025',
      filterValue: 'Advanced Regents Jan 2025',
      order: 0,
    },
    ADVANCED_REGENTS_MARCH_2025: {
      key: 'Advanced Regents March 2025',
      filterValue: 'Advanced Regents March 2025',
      order: 1,
    },
    ADVANCED_REGENTS_JUNE_2025: {
      key: 'Advanced Regents June 2025',
      filterValue: 'Advanced Regents June 2025',
      order: 2,
    },
    ADVANCED_REGENTS_AUG_2025: {
      key: 'Advanced Regents Aug 2025',
      filterValue: 'Advanced Regents Aug 2025',
      order: 3,
    },
    // 2026
    ADVANCED_REGENTS_JAN_2026: {
      key: 'Advanced Regents Jan 2026',
      filterValue: 'Advanced Regents Jan 2026',
      order: 4,
    },
    ADVANCED_REGENTS_MARCH_2026: {
      key: 'Advanced Regents March 2026',
      filterValue: 'Advanced Regents March 2026',
      order: 5,
    },
    ADVANCED_REGENTS_JUNE_2026: {
      key: 'Advanced Regents June 2026',
      filterValue: 'Advanced Regents June 2026',
      order: 6,
    },
    ADVANCED_REGENTS_AUG_2026: {
      key: 'Advanced Regents Aug 2026',
      filterValue: 'Advanced Regents Aug 2026',
      order: 7,
    },
    // 2027
    ADVANCED_REGENTS_JAN_2027: {
      key: 'Advanced Regents Jan 2027',
      filterValue: 'Advanced Regents Jan 2027',
      order: 8,
    },
    ADVANCED_REGENTS_MARCH_2027: {
      key: 'Advanced Regents March 2027',
      filterValue: 'Advanced Regents March 2027',
      order: 9,
    },
    ADVANCED_REGENTS_JUNE_2027: {
      key: 'Advanced Regents June 2027',
      filterValue: 'Advanced Regents June 2027',
      order: 10,
    },
    ADVANCED_REGENTS_AUG_2027: {
      key: 'Advanced Regents Aug 2027',
      filterValue: 'Advanced Regents Aug 2027',
      order: 11,
    },
    // 2028
    ADVANCED_REGENTS_JAN_2028: {
      key: 'Advanced Regents Jan 2028',
      filterValue: 'Advanced Regents Jan 2028',
      order: 12,
    },
    ADVANCED_REGENTS_MARCH_2028: {
      key: 'Advanced Regents March 2028',
      filterValue: 'Advanced Regents March 2028',
      order: 13,
    },
    ADVANCED_REGENTS_JUNE_2028: {
      key: 'Advanced Regents June 2028',
      filterValue: 'Advanced Regents June 2028',
      order: 14,
    },
    ADVANCED_REGENTS_AUG_2028: {
      key: 'Advanced Regents Aug 2028',
      filterValue: 'Advanced Regents Aug 2028',
      order: 15,
    },
    // 2025
    REGENTS_JAN_2025: {
      key: 'Regents Jan 2025',
      filterValue: 'Regents Jan 2025',
      order: 16,
    },
    REGENTS_MARCH_2025: {
      key: 'Regents March 2025',
      filterValue: 'Regents March 2025',
      order: 17,
    },
    REGENTS_JUNE_2025: {
      key: 'Regents June 2025',
      filterValue: 'Regents June 2025',
      order: 18,
    },
    REGENTS_AUG_2025: {
      key: 'Regents Aug 2025',
      filterValue: 'Regents Aug 2025',
      order: 19,
    },
    // 2026
    REGENTS_JAN_2026: {
      key: 'Regents Jan 2026',
      filterValue: 'Regents Jan 2026',
      order: 20,
    },
    REGENTS_MARCH_2026: {
      key: 'Regents March 2026',
      filterValue: 'Regents March 2026',
      order: 21,
    },
    REGENTS_JUNE_2026: {
      key: 'Regents June 2026',
      filterValue: 'Regents June 2026',
      order: 22,
    },
    REGENTS_AUG_2026: {
      key: 'Regents Aug 2026',
      filterValue: 'Regents Aug 2026',
      order: 23,
    },
    // 2027
    REGENTS_JAN_2027: {
      key: 'Regents Jan 2027',
      filterValue: 'Regents Jan 2027',
      order: 24,
    },
    REGENTS_MARCH_2027: {
      key: 'Regents March 2027',
      filterValue: 'Regents March 2027',
      order: 25,
    },
    REGENTS_JUNE_2027: {
      key: 'Regents June 2027',
      filterValue: 'Regents June 2027',
      order: 26,
    },
    REGENTS_AUG_2027: {
      key: 'Regents Aug 2027',
      filterValue: 'Regents Aug 2027',
      order: 27,
    },
    // 2028
    REGENTS_JAN_2028: {
      key: 'Regents Jan 2028',
      filterValue: 'Regents Jan 2028',
      order: 28,
    },
    REGENTS_MARCH_2028: {
      key: 'Regents March 2028',
      filterValue: 'Regents March 2028',
      order: 29,
    },
    REGENTS_JUNE_2028: {
      key: 'Regents June 2028',
      filterValue: 'Regents June 2028',
      order: 30,
    },
    REGENTS_AUG_2028: {
      key: 'Regents Aug 2028',
      filterValue: 'Regents Aug 2028',
      order: 31,
    },
    // 2025
    LOCAL_JAN_2025: {
      key: 'Local Jan 2025',
      filterValue: 'Local Jan 2025',
      order: 32,
    },
    LOCAL_MARCH_2025: {
      key: 'Local March 2025',
      filterValue: 'Local March 2025',
      order: 33,
    },
    LOCAL_JUNE_2025: {
      key: 'Local June 2025',
      filterValue: 'Local June 2025',
      order: 34,
    },
    LOCAL_AUG_2025: {
      key: 'Local Aug 2025',
      filterValue: 'Local Aug 2025',
      order: 35,
    },
    // 2026
    LOCAL_JAN_2026: {
      key: 'Local Jan 2026',
      filterValue: 'Local Jan 2026',
      order: 36,
    },
    LOCAL_MARCH_2026: {
      key: 'Local March 2026',
      filterValue: 'Local March 2026',
      order: 37,
    },
    LOCAL_JUNE_2026: {
      key: 'Local June 2026',
      filterValue: 'Local June 2026',
      order: 38,
    },
    LOCAL_AUG_2026: {
      key: 'Local Aug 2026',
      filterValue: 'Local Aug 2026',
      order: 39,
    },
    // 2027
    LOCAL_JAN_2027: {
      key: 'Local Jan 2027',
      filterValue: 'Local Jan 2027',
      order: 40,
    },
    LOCAL_MARCH_2027: {
      key: 'Local March 2027',
      filterValue: 'Local March 2027',
      order: 41,
    },
    LOCAL_JUNE_2027: {
      key: 'Local June 2027',
      filterValue: 'Local June 2027',
      order: 42,
    },
    LOCAL_AUG_2027: {
      key: 'Local Aug 2027',
      filterValue: 'Local Aug 2027',
      order: 43,
    },
    // 2028
    LOCAL_JAN_2028: {
      key: 'Local Jan 2028',
      filterValue: 'Local Jan 2028',
      order: 44,
    },
    LOCAL_MARCH_2028: {
      key: 'Local March 2028',
      filterValue: 'Local March 2028',
      order: 45,
    },
    LOCAL_JUNE_2028: {
      key: 'Local June 2028',
      filterValue: 'Local June 2028',
      order: 46,
    },
    LOCAL_AUG_2028: {
      key: 'Local Aug 2028',
      filterValue: 'Local Aug 2028',
      order: 47,
    },
    PLAN_IN_PAST: {
      human: 'Plan in Past',
      key: 'Plan in Past',
      filterValue: 'Plan in Past',
      order: 48,
    },
    PLAN_INCOMPLETE: {
      human: 'Plan Incomplete',
      key: 'Plan Incomplete',
      filterValue: 'Plan Incomplete',
      order: 49,
    },
    GRADUATE: {
      human: 'Graduate',
      key: 'Graduate',
      filterValue: 'Graduate',
      order: 50,
    },
    NON_GRADUATE: {
      human: 'Non-Graduate',
      key: 'Non-Graduate',
      filterValue: 'Non-Graduate',
      order: 51,
    },
    NEGATIVE_DISCHARGE: {
      human: 'Negative Discharge',
      key: 'Negative Discharge',
      filterValue: 'Negative Discharge',
      order: 52,
    },
    NONE: {
      human: 'None',
      key: 'None',
      filterValue: 'None',
      order: 53,
    },
  },
};
