import { createFeatureSelector, createSelector } from '@ngrx/store';

const getPortalState = createFeatureSelector('PORTAL_STORE');

export const getSdcSlice = createSelector(
  getPortalState,
  (state: any) => state.sdcState,
);

export const getSdcLoadingStatus = createSelector(
  getSdcSlice,
  (state: any) => state.loading,
);

export const getSdcLoadedStatus = createSelector(
  getSdcSlice,
  (state: any) => state.loaded,
);

export const getSdcStudents = createSelector(
  getSdcSlice,
  (state: any) => state.students,
);

export const getSdcFlattenedStudentList = createSelector(
  getSdcSlice,
  //The null check shouldn't be needed after PI-3861. But, unlikely to hurt.
  (state: any) => Object.values(state.flattenedStudents ?? [])
);

export const getSdcProjection = createSelector(
  getSdcSlice,
  (state: any) => state.projection,
);

export const getSdcJoins = createSelector(
  getSdcSlice,
  (state: any) => state.joins,
);

export const getSdcColumnKeys = createSelector(
  getSdcSlice,
  (state: any) => state.columnKeys,
);

export const getSdcGridState = (sdcKey) => createSelector(
  getSdcSlice,
  (state) => state.gridStates[sdcKey]
);
