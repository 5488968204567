import { IPostsecondaryFuturePlan } from './../../../../typings/interfaces/student.interface';
import { IExperience } from './../../../../typings/interfaces/experiences.interface';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { map, switchMap, tap } from 'rxjs/operators';
import { ExperienceModalService } from '../experience-modal-service/experience-modal-service';

export interface IModalConfig {
  key: string;
  human: string;
  options?: IModalConfig[];
  meta?: any;
}

interface IExperienceFormEditObject extends IExperience, IPostsecondaryFuturePlan {}

@Component({
  selector: 'experience-form',
  templateUrl: 'experience-form.component.html',
  encapsulation: ViewEncapsulation.None,
})

export class ExperienceFormComponent implements OnInit {
  @Input() formConfigKey$: BehaviorSubject<string>;
  @Input() modalFocus: string;
  @Input() editObject: IExperienceFormEditObject;

  @Output() save = new EventEmitter();
  @Output() cancel = new EventEmitter();

  public formConfig$: Observable<IModalConfig>;
  public selectedOption: string;
  public formGroup: FormGroup;

  constructor (private experienceModalService: ExperienceModalService) { }

  ngOnInit () {
    this.formConfig$ = this.formConfigKey$.pipe(
      switchMap(formConfigKey => this.experienceModalService.getExperienceModalConfig$(this.modalFocus, formConfigKey)),
      map(payload => {
        const [config] = payload;
        return config;
      }),
      tap(this.initFormGroup.bind(this)),
    );
  }

  private getDateFormGroup () {
    return new FormGroup({
      startDate: new FormControl(this.editObject?.startDate || '', Validators.required),
      endDate: new FormControl(this.editObject?.endDate || '', Validators.required),
    });
  }

  private initFormGroup (formConfig: IModalConfig): void {
    const formControls = formConfig.options.reduce((acc, { key, meta, options }) => {
      const validator = meta.required ? Validators.required : null;
      let initFormValue;
      if (this.editObject && this.editObject[key]) {
        initFormValue = this.editObject[key];
      } else {
        initFormValue = options?.length === 1 ? options[0].human : null;
      }
      acc[key] = meta.controlType === 'date-picker' ? this.getDateFormGroup() : new FormControl(initFormValue, validator);
      return acc;
    }, {});

    this.formGroup = new FormGroup({
      ...formControls,
    });
  }

  onOptionChange ($event, formKey: string): void {
    this.formGroup.controls[formKey].setValue($event.human);
  }

  private formatPayload <T> (payload: T): T {
    const formattedPayload = Object.keys(payload).reduce(
      (acc, key) => {
        acc[key] = payload[key] || null;
        return acc;
      }, {},
    );

    return formattedPayload as T;
  }

  onSave (): void {
    const experienceObj = { ...this.formGroup.value };
    if (this.editObject?._id) experienceObj._id = this.editObject._id;
    const formattedPayload = this.formatPayload(experienceObj);
    this.save.emit(formattedPayload);
  }

  onCancel (): void {
    this.cancel.emit();
  }
}
