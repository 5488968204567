import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { BaseModalModule } from '../base-modal.module';
import { EditContactInfoModalComponent } from './edit-contact-info-modal.component';
import { NvTextboxModule } from './../../../../nvps-libraries/design/nv-textbox/nv-textbox.module';
import { NvFormFieldModule } from './../../../../nvps-libraries/design/nv-form-field/nv-form-field.module';
import { NvButtonModule } from 'projects/shared/nvps-libraries/design/nv-button/nv-button.module';
import { NvIconButtonModule } from './../../../../nvps-libraries/design/nv-icon-button/nv-icon-button.module';
import { NvIconModule } from 'projects/shared/nvps-libraries/design/nv-icon/nv-icon.module';
import { NvNoteModule } from '../../../../../projects/shared/nvps-libraries/design/nv-note/nv-note.module';

@NgModule({
  declarations: [EditContactInfoModalComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    BaseModalModule,
    MatDialogModule,
    NvTextboxModule,
    NvFormFieldModule,
    NvButtonModule,
    NvIconButtonModule,
    NvIconModule,
    NvNoteModule,
  ],
  exports: [EditContactInfoModalComponent],
  providers: [],
})
export class EditContactInfoModalModule {}
