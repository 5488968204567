import { RegentsPlanningWizardContainer } from './regents-planning-wizard-container.component';
import { WizardFilterResolver } from '../../../../routing/resolvers/sdc/wizard-filter/wizard-filter.resolver';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RouteGuard } from '../../../../routing/guards/route-guard/route-guard';
import { USER_ROLE_PERMISSIONS_FOR_GUARDS } from '../../../../routing/guards/user-role-permissions-for-route-guards.constant';
const routes = [
  {
    path: '',
    partnerType: 'school',
    authenticationRequired: true,
    component: RegentsPlanningWizardContainer,
    rolePermissions: USER_ROLE_PERMISSIONS_FOR_GUARDS['school/:schoolId/other-tools/regents-planning/wizard'],
    canActivate: [RouteGuard],
    data: {
      sdcKey: 'regents-planning',
    },
    resolve: {
      filter: WizardFilterResolver,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RegentsPlanningWizardRoutingModule { }
