import { LocalStorageService } from './../../../shared/services/web-storage/local-storage/local-storage.service';
import { Observable, of } from 'rxjs';
import { CanActivate, ActivatedRouteSnapshot, UrlTree, Router } from '@angular/router';
import { Injectable } from '@angular/core';

const LAST_KNOWN_URL = 'lastKnownUrl';

@Injectable()
export class LastKnownUrlGuard implements CanActivate {
  constructor (
    private router: Router,
    private localStorage: LocalStorageService,
  ) {}

  canActivate (): Observable<true|UrlTree> {
    const lastKnownUrlWithParams = this.localStorage.getItem(LAST_KNOWN_URL);

    if (!lastKnownUrlWithParams) return of(true);

    this.localStorage.removeItem(LAST_KNOWN_URL);
    return of(this.router.parseUrl(lastKnownUrlWithParams));
  }
}
