<base-modal
  class="base-modal"
  [title]="title"
  [isProfileMode]="true"
>
  <div class="edit-contact-info-modal">
    <mat-dialog-content>
      <!-- 1. Student Mobile Input -->
      <div class="student-mobile" >
        <nv-form-field
          label="Student mobile"
          [isInvalid]="contactInfoForm.controls['mobile'].invalid"
          [showInvalid]="contactInfoForm.controls['mobile'].invalid && !contactInfoForm.controls['mobile'].pristine"
        >
          <nv-textbox
            [hasClearBtn]="true"
            [showInvalid]="true"
            [showInvalidOnPristine]="true"
            [textControl]="contactInfoForm.controls['mobile']"
            (clearValue)="onClearInput('mobile')"
            inputType="phoneNumber"
            ></nv-textbox>
          <!-- ERROR MESSAGE: for phone number input -->
          <ng-container *ngIf="contactInfoForm.controls['mobile'].errors as reqPhoneNumberErrs">
            <ng-container>
              <nv-note class="phone-hint" size="small" priority="danger"
                >Enter a valid phone number</nv-note>
            </ng-container>
          </ng-container>
        </nv-form-field>
      </div>
      <!-- 2. Student Email Input -->
      <div class="email-input">
        <nv-form-field
          [label]="'Student email'"
          [isInvalid]="contactInfoForm.controls['email'].invalid"
          [showInvalid]="
            contactInfoForm.controls['email'].invalid && !contactInfoForm.controls['email'].pristine
          "
        >
          <nv-textbox
            placeholder="Enter student email"
            [hasClearBtn]="true"
            [showInvalid]="true"
            [showInvalidOnPristine]="false"
            [textControl]="contactInfoForm.controls['email']"
            (clearValue)="onClearInput('email')"
          ></nv-textbox>
          <!-- ERROR MESSAGE: for student email input -->
          <ng-container *ngIf="contactInfoForm.controls['email'].errors as reqEmailErrs">
            <ng-container *ngIf="(reqEmailErrs)">
              <nv-note class="email-hint" size="small" priority="danger"
                >Enter a valid email</nv-note>
            </ng-container>
          </ng-container>
        </nv-form-field>
      </div>
      <!-- 3. Parent Email Input -->
      <div class="email-input">
        <nv-form-field
          [label]="'Parent email'"
          [isInvalid]="contactInfoForm.controls['parentEmail'].invalid"
          [showInvalid]="
            contactInfoForm.controls['parentEmail'].invalid && !contactInfoForm.controls['parentEmail'].pristine
          "
        >
          <nv-textbox
            placeholder="Enter parent email"
            [hasClearBtn]="true"
            [showInvalid]="true"
            [showInvalidOnPristine]="false"
            [textControl]="contactInfoForm.controls['parentEmail']"
            (clearValue)="onClearInput('parentEmail')"
          ></nv-textbox>
          <!-- ERROR MESSAGE: for student email input -->
          <ng-container *ngIf="contactInfoForm.controls['parentEmail'].errors as reqEmailErrs">
            <ng-container *ngIf="(reqEmailErrs)">
              <nv-note class="email-hint" size="small" priority="danger"
                >Enter a valid email</nv-note>
            </ng-container>
          </ng-container>
        </nv-form-field>
      </div>
    </mat-dialog-content>

    <!-- Cancel / Save buttons -->
    <div class="dialog-actions">
      <nv-button type="secondary" (clickButton)="onCancel()">
        {{ buttons.cancel.text }}
      </nv-button>
      <nv-button (clickButton)="onSave()" [disabled]="contactInfoForm.pristine || contactInfoForm.invalid">
        {{ buttons.confirm.text }}
      </nv-button>
    </div>
  </div>
</base-modal>
