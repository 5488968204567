import * as studentActions from '../actions/single-student-actions';
import * as bulkStudentActions from '../actions/students-actions';
import { MergeUtilities } from './../utilities/merge-utilities';
import { PatchUtilities } from './../utilities/patch-utilities';

export interface IStudent {
  loading: boolean;
  loaded: boolean;
  studentEntities: object;
}

export const initialState: IStudent = {
  loading: false,
  loaded: false,
  studentEntities: {},
};

export function reducer (state: IStudent = initialState, action: any): IStudent {
  switch (action.type) {
    case studentActions.LOAD_STUDENT:
    case studentActions.LOAD_STUDENT_FROM_OTHER_SCHOOL: {
      return { ...state, loading: true, loaded: false };
    }

    case studentActions.LOAD_STUDENT_SUCCESS:
    case studentActions.LOAD_STUDENT_FROM_OTHER_SCHOOL_SUCCESS: {
      const student = action.payload;
      const studentEntities = { ...state.studentEntities, [student._id]: student };

      return {
        ...state,
        loading: false,
        loaded: true,
        studentEntities,
      };
    }
    case studentActions.LOAD_STUDENT_FAIL:
    case studentActions.LOAD_STUDENT_FROM_OTHER_SCHOOL_FAIL: {
      return { ...state, loading: false, loaded: false };
    }

    case studentActions.UPDATE_STUDENT_SUCCESS: {
      const student = action.payload;
      const updatedStudentId = student._id;
      const studentEntities = Object.assign({}, state.studentEntities);
      const originalStudent = studentEntities[updatedStudentId] || {};
      studentEntities[updatedStudentId] = { ...originalStudent, ...student };
      return {
        ...state,
        studentEntities,
      };
    }

    case studentActions.UPDATE_STUDENT_SDC_STORE_SUCCESS: {
      const student = action.payload;
      const updatedStudentId = student._id;
      const studentEntities = Object.assign({}, state.studentEntities);
      studentEntities[updatedStudentId] = student;
      return {
        ...state,
        studentEntities,
      };
    }

    case bulkStudentActions.BULK_UPDATE_STUDENTS_SUCCESS: {
      const { patches } = action.payload;
      const studentEntities = patches.reduce(
        (studentEntities, { _ids, patch }) => {
          _ids.forEach((_id: string) => {
            if (studentEntities[_id]) {
              const newPatch = PatchUtilities.validatePatch(patch, _id);
              studentEntities[_id] = MergeUtilities.deepMergeObjs(studentEntities[_id], newPatch);
            }
          });
          return studentEntities;
        },
        { ...state.studentEntities },
      );
      return { ...state, studentEntities };
    }

    case studentActions.UPDATE_STUDENT_FROM_PUSHER: {
      const { patches } = action.payload;

      const studentEntities = Object.assign({}, state.studentEntities);

      patches.forEach(({ _ids, patch }) => {
        _ids.forEach(_id => {
          if (studentEntities[_id]) {
            studentEntities[_id] = MergeUtilities.deepMergeObjs(studentEntities[_id], patch);
          }
        });
        return studentEntities;
      });
      return {
        ...state,
        studentEntities,
      };
    }

    default:
      return state;
  }
}
