import { reduce } from 'lodash';
import { ITool } from '../../shared/typings/interfaces/tool.interface';
import * as toolsActions from '../actions/tools-actions';

export interface IToolsState {
  loading: boolean;
  loaded: boolean;
  toolsEntities: {
    [id: string]: ITool;
  };
}

export const initialState: IToolsState = {
  loading: false,
  loaded: false,
  toolsEntities: {},
};

export function reducer(state: IToolsState = initialState, action: toolsActions.ToolsAction): IToolsState {
  switch (action.type) {
    case toolsActions.LOAD_TOOLS: {
      return { ...state, loading: true };
    }
    case toolsActions.LOAD_TOOLS_SUCCESS: {
      const tools = action.payload;
      const toolsEntities: IToolsState['toolsEntities'] = reduce(
        tools,
        (acc, tool: ITool) => {
          acc[tool._id] = tool;
          return acc;
        },
        {},
      );
      return { ...state, loading: false, loaded: true, toolsEntities };
    }
    case toolsActions.CREATE_SYNC_TOOL_SUCCESS: {
      const createdSyncTool = action.payload;
      const toolsEntities: IToolsState['toolsEntities'] = Object.assign({}, state.toolsEntities);
      toolsEntities[createdSyncTool._id] = createdSyncTool;
      return { ...state, toolsEntities };
    }
    case toolsActions.CREATE_SYNC_TOOL_FAIL: {
      const failedSyncTool = action.payload;
      state.toolsEntities[failedSyncTool._id] = undefined;
      state.toolsEntities = JSON.parse(JSON.stringify(state.toolsEntities));
      const toolsEntities: IToolsState['toolsEntities'] = state.toolsEntities;
      return { ...state, toolsEntities };
    }
    case toolsActions.QUEUE_CREATE_SYNC_TOOL_SUCCESS: {
      const queuedSyncTool = action.payload;
      const toolsEntities: IToolsState['toolsEntities'] = Object.assign({}, state.toolsEntities);
      toolsEntities[queuedSyncTool._id] = queuedSyncTool;
      return { ...state, toolsEntities };
    }
    case toolsActions.DELETE_SYNC_TOOL_SUCCESS: {
      const deletedSyncTool = action.payload;
      const toolsEntities: IToolsState['toolsEntities'] = Object.assign({}, state.toolsEntities);
      toolsEntities[deletedSyncTool._id] = deletedSyncTool;
      return { ...state, toolsEntities };
    }
    case toolsActions.UPDATE_SYNC_TOOL_SUCCESS: {
      const updatedSyncTool = action.payload;
      const toolsEntities: IToolsState['toolsEntities'] = Object.assign({}, state.toolsEntities);
      toolsEntities[updatedSyncTool._id] = updatedSyncTool;
      return { ...state, toolsEntities };
    }
    case toolsActions.QUEUE_UPDATE_SYNC_TOOL_SUCCESS: {
      const queuedSyncTool = action.payload;
      const toolsEntities: IToolsState['toolsEntities'] = Object.assign({}, state.toolsEntities);
      toolsEntities[queuedSyncTool._id] = queuedSyncTool;
      return { ...state, toolsEntities };
    }
    case toolsActions.UPDATE_SYNC_TOOL_FAIL: {
      const failedSyncTool = action.payload;
      const toolsEntities: IToolsState['toolsEntities'] = Object.assign({}, state.toolsEntities);
      toolsEntities[failedSyncTool._id] = failedSyncTool;
      return { ...state, toolsEntities };
    }
    default:
      return state;
  }
}
