import { createFeatureSelector, createSelector } from '@ngrx/store';

const getPortalState = createFeatureSelector('PORTAL_STORE');

export const getCourseMarksGridSlice = createSelector(
  getPortalState,
  (state: any) => state.courseMarksGrid,
);

export const getCourseMarksGridLoadedState = createSelector(
  getCourseMarksGridSlice,
  (state: any) => state.loaded,
);

export const getCourseMarksGridState = createSelector(
  getCourseMarksGridSlice,
  (state: any) => state.gridState,
);
