import { Component, ElementRef, ViewChild } from '@angular/core';
import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IHeaderParams } from '@ag-grid-community/core';
import { ServerSideGridService } from 'Src/ng2/school/server-side-grid/services/server-side-grid.service';
import { Unsubscribable } from 'rxjs';

export interface ICustomHeaderParams {
  menuIcon: string;
}

export const SORT_ICON = {
  ASCENDING: { icon: 'ascending', subIcon: 'asc' },
  DESCENDING: { icon: 'descending', subIcon: 'desc' },
  NONE: { icon: '', subIcon: '' },
};

@Component({
  selector: 'select-all',
  templateUrl: './select-all.component.html',
  styleUrls: ['./select-all.component.scss'],
})
export class SelectAllComponent implements IHeaderAngularComp {
  public params!: IHeaderParams & ICustomHeaderParams;
  headerName: string;
  showCheckBox: boolean = true;
  sortIcon: string = SORT_ICON.NONE.icon;
  sortSubIcon: string = SORT_ICON.NONE.subIcon;
  sortOrder: number = 0;

  @ViewChild('menuButton', { read: ElementRef }) public menuButton;
  public filteredBySelectAllColumn: boolean;
  filterBySelectAllSub: Unsubscribable;

  constructor (private ssgService: ServerSideGridService) {
    this.filterBySelectAllSub = this.ssgService.filteredBySelectAllColumn$.subscribe((newFilterValue) => {
      this.filteredBySelectAllColumn = newFilterValue;
    });
  }

  agInit (params: IHeaderParams & ICustomHeaderParams): void {
    this.params = params;
    this.params.menuIcon = 'fa-bars';
    this.headerName = this.params?.displayName;
    this.showCheckBox = this.params?.context?.parentComponent.batchActionMode;

    const sortModel = this.params.columnApi.getColumnState().filter(c => c.sort).map(({ sort, colId }) => ({ sort, colId }));
    const uniqueId = sortModel?.filter(x => x.colId === 'OSIS_NUMBER' || x.colId === 'CARES_ID');
    uniqueId?.length > 0 ? this.setDefaultSortIcon(uniqueId[0].sort) : this.setDefaultSortIcon('');
  }

  private toggleSelect (): void {
    this.params.context.parentComponent.isSelectAll = !this.params.context.parentComponent.isSelectAll;
    this.params?.context?.parentComponent.selectAllByFilter();
  }

  private onSortRequested (event: any): void {
    const currentColState = this.params?.columnApi?.getColumnState().filter(column => column.colId === 'OSIS_NUMBER' || column.colId === 'CARES_ID');
    if (currentColState?.length > 0 && !currentColState[0].sort) {
      this.sortIcon = SORT_ICON.NONE.icon;
      this.sortSubIcon = SORT_ICON.NONE.subIcon;
    }
    this.setSortIcon();
    this.params.progressSort(event.shiftKey);
  }

  public refresh (params: IHeaderParams): boolean {
    return false;
  }

  private setDefaultSortIcon (sort: any): void {
    this.sortIcon = sort === 'desc' ? SORT_ICON.DESCENDING.icon : sort === 'asc' ? SORT_ICON.ASCENDING.icon : SORT_ICON.NONE.icon;
    this.sortSubIcon = sort === 'desc' ? SORT_ICON.DESCENDING.subIcon : sort === 'asc' ? SORT_ICON.ASCENDING.subIcon : SORT_ICON.NONE.subIcon;
  }

  private setSortIcon (): void {
    if (this.sortIcon === SORT_ICON.DESCENDING.icon) {
      this.sortIcon = SORT_ICON.NONE.icon;
      this.sortSubIcon = SORT_ICON.NONE.subIcon;
    } else if (this.sortIcon === SORT_ICON.ASCENDING.icon) {
      this.sortIcon = SORT_ICON.DESCENDING.icon;
      this.sortSubIcon = SORT_ICON.DESCENDING.subIcon;
    } else if (this.sortIcon === SORT_ICON.NONE.icon) {
      this.sortIcon = SORT_ICON.ASCENDING.icon;
      this.sortSubIcon = SORT_ICON.ASCENDING.subIcon;
    }
  }

  private onMenuClicked () {
    this.params?.showColumnMenu(this.menuButton?.nativeElement);
  }

  ngOnDestroy (): void {
    this.filterBySelectAllSub.unsubscribe();
  }
}
