<list-container
  [groupingData$]="groupingData$"
  [sortKey$]="sortKey$"
  [sortDirection$]="sortDirection$"
  [filterFormControl]="filterFormControl"
  [batchActionsMode$]="batchActionsMode$"
  [columns]="columns"
  [columnIndexMap]="columnIndexMap"
  [listConfig]="listConfig"
  [dynamicComponentTrigger]="dynamicComponentTrigger"
  [dynamicComponentInputData]="dynamicComponentInputData"
  (sortEmitter)="onUpdateSort($event)"
>
</list-container>
