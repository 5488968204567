// credit to : https://medium.com/@dmyl/mixins-as-class-decorators-in-typescript-angular2-8e09f1bc1f02

/**
 * Allows multiple inheritance
 * Could be used along with other decorators, such as `@Component()`, `@Injectable()`, etc
 *
 * Exmaple use:
 *
 * @Mixin([BaseClass_A, BaseClass_B, ...])
 * class DerivedClass_C {}
 *
 * C inherits all methods and properties from the array of base classes passed into Mixin
 *
 *
 * @export
 * @param {Function[]} BaseCtors
 */
export function Mixin (BaseCtors: Function[]) {
  return function (DerivedCtor: Function) {
    BaseCtors.forEach(BaseCtor => {
      // copy over non-static methods
      Object.getOwnPropertyNames(BaseCtor.prototype).forEach(name => {
        if (name !== 'constructor') {
          DerivedCtor.prototype[name] = BaseCtor.prototype[name];
        }
      });
      // copy over static methods
      Object.getOwnPropertyNames(BaseCtor).forEach(name => {
        if (['length', 'name', 'prototype'].includes(name)) return;
        DerivedCtor[name] = BaseCtor[name];
      });
    });
    // preserves contructor
    DerivedCtor.prototype.constructor = DerivedCtor;
  };
}
