import { IUser } from '../../shared/typings/interfaces/user.interface';
/* tslint:disable */
import { Action } from '@ngrx/store';

export const LOAD_CURRENT_USER = '[Portal] Load Current User';
export const LOAD_CURRENT_USER_SUCCESS = '[Portal] Load Current User Success';
export const LOAD_CURRENT_USER_FAIL = '[Portal] Load Current User Failure';

// register store actions
export class LoadCurrentUser implements Action {
  readonly type = LOAD_CURRENT_USER;
  constructor (public payload: { email: string }) {}
}

export class LoadCurrentUserSuccess implements Action {
  readonly type = LOAD_CURRENT_USER_SUCCESS;
  constructor(public payload: IUser) {}
}

export class LoadCurrentUserFail implements Action {
  readonly type = LOAD_CURRENT_USER_FAIL;
  constructor(public payload: any) {}
}

// action types
export type CurrentUserAction = LoadCurrentUser | LoadCurrentUserFail | LoadCurrentUserSuccess;
