import { MatDialogConfig } from '@angular/material/dialog';

// Mat Dialog Configs (CM).

// TODO-jchu: confirm with team whether we could consolidate nv-mat-dialog with nv-mat-dialog-common-markup
export const MODALS_CONFIG: MatDialogConfig = {
  autoFocus: false,
  panelClass: 'nv-mat-dialog', // custom class applied to overlay panel
};

// to be used only to open modals which should share a common markup structure.
// examples - see batch actions - `Generate report` and `Edit Fields` modals
export const MODALS_CONFIG_COMMON_MARKUP: MatDialogConfig = {
  autoFocus: false,
  panelClass: 'nv-mat-dialog-common-markup',
};

export const MODALS_CONFIG_WIDE = {
  panelClass: 'nv-mat-dialog-wide',
};

export const MODALS_CONFIG_EXTRA_WIDE = {
  panelClass: 'nv-mat-dialog-extra-wide',
};

export const MODALS_CONFIG_MED = {
  panelClass: 'nv-mat-dialog-med',
};

export const MODALS_CONFIG_MED_NO_PADDING = {
  panelClass: 'nv-mat-dialog-med-no-padding',
};

export const MODALS_CONFIG_WIDE_NO_PADDING = {
  autoFocus: false,
  panelClass: 'nv-mat-dialog-wide-no-padding',
};

export const MODALS_CONFIG_NO_PADDING_NO_OVERFLOW_WIDE = {
  panelClass: 'nv-mat-dialog-no-padding-no-overflow-wide',
};
