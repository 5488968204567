import { MatStepperModule } from '@angular/material/stepper';
import { AssignSupportsStep } from './assign-supports-step.component';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [AssignSupportsStep],
  exports: [AssignSupportsStep],
  imports: [
    MatStepperModule,
  ],
})
export class AssignSupportsStepModule {}
