import { IModalsConfig } from './../modals.config.interface';

export const ENDORSEMENTS_CREDENTIALS_MODAL_CONFIG: IModalsConfig = {
  buttons: {
    confirm: {
      text: 'Edit student',
      disableButtonRipple: true,
    },
    cancel: {
      text: 'Cancel',
      disableButtonRipple: true,
    },
  },
};
