import { Injectable } from '@angular/core';
import { Toggles } from '../../constants/toggles.constant';
import { TGridShelter } from '../../typings/interfaces/shelter.interface';
import { ToggleService } from '../toggle/toggle.service';

@Injectable()
export class ImShelter {
  constructor (
    private toggleService: ToggleService,
  ) {}

  public getShelterGridType (): TGridShelter {
    const isSummer = this.toggleService.getToggleState(Toggles.TOGGLE_SUMMER_SCHOOL);
    if (isSummer) return 'summer_shelter';
    return 'shelter';
  }
}
