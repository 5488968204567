import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { NvIconButtonModule } from '../nv-icon-button/nv-icon-button.module';
import { NvIconModule } from 'projects/shared/nvps-libraries/design/nv-icon/nv-icon.module';
import { NvTimePickerComponent } from './nv-time-picker.component';

@NgModule({
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatMenuModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    NvIconModule,
    NvIconButtonModule,
  ],
  declarations: [NvTimePickerComponent],
  exports: [NvTimePickerComponent],
})
export class NvTimePickerModule {}
