
<p class="mat-body-2">
  <wizard-filter-actions 
    [data]="studentIdsNotPlannedForNeededExams"
    [options]="studentsNotPlannedForNeededExamsOptions"
  >
  </wizard-filter-actions>

  <ng-container [ngPlural]="studentIdsNotPlannedForNeededExams.length">
    <ng-template ngPluralCase="1"> student is not planned to sit for exams they need to be on track for their diploma type </ng-template>
    <ng-template ngPluralCase="other"> students are not planned to sit for exams they need to be on track for their diploma types. </ng-template>
  </ng-container>
</p>

<p class="mat-body-2">
  <wizard-filter-actions [data]="studentIdsNotScheduledInStars" [options]="starsMismatchOptions"></wizard-filter-actions>
  
  <ng-container [ngPlural]="studentIdsNotScheduledInStars.length">
    <ng-template ngPluralCase="1"> student has </ng-template>
    <ng-template ngPluralCase="other"> students have </ng-template>
  </ng-container>

  have been planned for exams in the Portal <b>but are not scheduled in STARS</b>.
</p>

<p class="mat-body-2">
  <wizard-filter-actions [data]="studentIdsNotPlannedInPortal" [options]="starsMismatchOptions"></wizard-filter-actions>

  <ng-container [ngPlural]="studentIdsNotPlannedInPortal.length">
    <ng-template ngPluralCase="1"> student has </ng-template>
    <ng-template ngPluralCase="other"> students have </ng-template>
  </ng-container>

  have been scheduled for exams in STARS <b>but are not planned in the Portal</b>.
</p>

<button class="step-action-button" mat-raised-button (click)="goToRegentsPlanningWizard()">
  Go to Regents Planning Wizard
</button>