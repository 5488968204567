import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ITooltipContent } from 'projects/shared/nvps-libraries/design/nv-tooltip/nv-shared-tooltip.interface';
import { ApiService } from 'Src/ng2/shared/services/api-service/api-service';

@Injectable()
export class ClusterUserModalsDataService {
  constructor(private apiService: ApiService) {}

  public getPortfolioGroupings({ userId, clusterId }): Observable<any> {
    return this.apiService.getClusterUserSchools({ userId, clusterId });
  }

  public getClusterUserDetail({ userId }): Observable<any> {
    return this.apiService.getClusterUserDetail({ userId });
  }

  // STATIC TOOLTIP DATA
  TOOLTIP_DATA_ACCESS_LEVEL: {
    [key: string]: ITooltipContent;
  } = {
    tableContent: {
      rowData: [
        ['Network and school level', 'Can login, access network views, and enter school portals'],
        ['Network level only', "Can login, access network views, but can't enter school portals"],
        ['No access', 'Cannot login'],
      ],
    },
  };

  // STATIC TOOLTIP DATA
  TOOLTIP_DATA_MANAGE_USERS = 'Allows this user to create new users, and update permissions for existing users';
}
