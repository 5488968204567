/* eslint-disable no-unused-vars */
export enum PortalLanguages {
  ENGLISH = 'English',
  ARABIC = 'Arabic',
  BENGALI = 'Bengali',
  CHINESE = 'Chinese',
  FRENCH = 'French',
  HAITIAN_CREOLE = 'Haitian Creole',
  KOREAN = 'Korean',
  RUSSIAN = 'Russian',
  SPANISH = 'Spanish',
  URDU = 'Urdu',
  OTHER = 'Other',
};
