import { SheetDataType } from './../../../../shared/modals/other-tools/synced-sheet-modal/synced-sheet-modal.component';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { IDropdownOption } from '../../../../../../projects/shared/nvps-libraries/design/interfaces/design-library.interface';
import { TOtherToolsMoreButtonActionMode } from '../other-tools.constant';

export interface IOtherToolsMoreButtonEmitData {
  action: TOtherToolsMoreButtonActionMode,
  sheetData: SheetDataType;
}

@Component({
  selector: 'other-tools-more-button-component',
  templateUrl: './other-tools-more-button.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class OtherToolsMoreButtonComponent implements OnInit {
  @Input() input: any;
  @Output() output = new EventEmitter();
  showMoreButton: boolean;
  options: IDropdownOption[] = [
    { key: 'Update', human: 'Update now' },
    { key: 'Edit', human: 'Edit' },
    { key: 'Delete', human: 'Delete' },
  ];

  ngOnInit () {
    this.showMoreButton = this.input.column.data && this.input.column.meta.syncId && !this.input.column.meta.pending;
  }

  onSelect (e: string): void {
    let action: TOtherToolsMoreButtonActionMode;
    switch (e) {
      case 'Update': {
        action = 'UPDATE';
        break;
      }
      case 'Edit': {
        action = 'EDIT';
        break;
      }
      case 'Delete': {
        action = 'DELETE';
        break;
      }
    }
    const emitData: IOtherToolsMoreButtonEmitData = {
      action,
      sheetData: {
        spreadsheetName: this.input.column.meta.name,
        spreadsheetId: this.input.column.meta.sheetId,
        schoolId: this.input.column.meta.currentSchoolId,
        columnKeys: this.input.column.meta.columnKeys,
        filters: this.input.column.meta.filters,
        autoUpdateSchedule: this.input.column.meta.autoUpdateSchedule,
      },
    };
    this.output.emit(emitData);
  }
}
