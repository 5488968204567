<base-modal 
  *ngIf="(options$ | async) as options" 
  class="base-modal experiences-modal" 
  [title]="title" 
  [isProfileMode]="isProfileMode"
  [itemCount]="itemCount"
  itemType="student"
>
  <nv-form-field label="Milestone">
    <nv-dropdown
      [options]="options"
      [selected]="selectedOption$.value"
      [placeholder]="ddPlaceHolder"
      (selectOption)="onOptionChange($event)"
    >
    </nv-dropdown>
  </nv-form-field>

  <experience-form
    *ngIf="selectedOption$.value; else buttonGroup"
    [formConfigKey$]="selectedOption$"
    (save)="onSave($event)"
    (cancel)="onCancel()"
    [modalFocus]="modalFocus"
    [editObject]="data.editExperience"
  ></experience-form>

  <ng-template #buttonGroup>
    <button-group
      isSaveDisabled="true"
      (cancel)="onCancel()"
    ></button-group>
  </ng-template>
</base-modal>
