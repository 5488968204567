import { ObjectCache } from 'Src/ng2/shared/services/object-cache/object-cache.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, from } from 'rxjs';

interface ICacheObject {
  id: string;
  json: string;
}

@Injectable()
export class WizardFilterResolver implements Resolve<any> {
  constructor (private objectCache: ObjectCache) {}

  resolve (route: ActivatedRouteSnapshot): Observable<ICacheObject> {
    const { filter: filterHash } = route.queryParams;
    const cacheObjectPromise: Promise<ICacheObject> = filterHash ? this.objectCache.getObject(filterHash): Promise.resolve(null);
    return from(cacheObjectPromise);
  }
}
