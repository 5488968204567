<mat-menu #childMenu="matMenu" [overlapTrigger]="false" class="{{customClass}}" aria-multiselectable="false">
  <ng-container *ngFor="let option of options; trackBy: trackByKey">
    <ng-container *ngIf="option.options && option.options.length > 0">
      <button
        mat-menu-item
        [id]="option.key"
        [disabled]="option.disabled"
        [matMenuTriggerFor]="menu.childMenu"
        [ngClass]="{ 'parent-of-selection': getBtnClass(option) }"
      >
        <span class="dropdown-item-label {{option.customClass}}">{{ option.human }}</span>
        <nv-dropdown-menu
          #menu
          (selectItem)="handleSelectSuboption($event)"
          [options]="option.options"
          [ngClass]="{ 'parent-of-selection': getBtnClass(option) }"
          (clickIconAction)="handleIconClick($event)"
          [customClass]="customClass"
        ></nv-dropdown-menu>
        <nv-icon name="arrow-right-default"></nv-icon>
      </button>
    </ng-container>
    <ng-container *ngIf="!option.options || option.options.length === 0">
      <span class="option-row" [ngClass]="{ 'selection' : getBtnClass(option)}">
      <button
        mat-menu-item
        (click)="handleItemClick(option)"
        [id]="option.key"
        [disabled]="option.disabled"
        [ngClass]="{ selection: isCurrentSelected(option) }">
          <div class="primary-label-wrapper">
            <span class="dropdown-item-label">
              <span class="{{option.customClass}}">{{ option.human }}</span>  
            </span>
            <span class="dropdown-item-icon" [ngClass]="{ selection: isCurrentSelected(option) }">
              <nv-icon-button class="dropdown-item-icon" *ngIf="option.actionIcon" 
              (clickButton)="handleIconClick(option)"
              [name]="option.actionIcon" [ngClass]="{ selection: isCurrentSelected(option) }"
              ></nv-icon-button>
            </span>
          </div>
          <div *ngIf="option.tags" class="dropdown-item-tags">
            <span class="dropdown-item-tag" *ngFor="let tag of option.tags">{{tag}}</span>
          </div>
      </button>
      </span> 
    </ng-container>
  </ng-container>
</mat-menu>
