import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute } from '@angular/router';

// services
import { WizardHelpers } from 'Src/ng2/school/sdc/services/wizard-helpers.service';
import { CreditGapsPriorityGroupingsService } from 'Src/ng2/school/sdc/services/credit-gaps-priority-groupings/credit-gaps-priority-groupings.service';
import { ImSchool } from 'Src/ng2/shared/services/im-models/im-school';

// modules
import { WizardFilterActionsModule } from 'Src/ng2/school/sdc/wizard-filter-actions/wizard-filter-actions.module';

// components
import { ReviewGapsStep } from './review-gaps-component';

@NgModule({
  imports: [CommonModule, WizardFilterActionsModule, MatButtonModule],
  exports: [ReviewGapsStep],
  declarations: [ReviewGapsStep],
  providers: [WizardHelpers, CreditGapsPriorityGroupingsService, ImSchool],
})
export class ReviewGapsStepModule { }
