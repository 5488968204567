import { Injectable } from "@angular/core";
import { LoteExamHumanName } from "../../constants/lote-exam-names.constant";

@Injectable()
export class LoteSchedulerModalService {

  getFormattedExamName (examName?) {
    if (!examName) {
      return '';
    } else {
      return LoteExamHumanName[examName];
    }
  }
}