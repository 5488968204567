import { ApiService } from 'Src/ng2/shared/services/api-service/api-service';
import { getSchool } from './../../../../store/selectors/school-selectors';
import { ISchool, TGrid } from 'Src/ng2/shared/typings/interfaces/school.interface';
import { Observable } from 'rxjs';
import { StoreDataService } from './../../../../shared/services/store-data-service/store-data.service';
import { Injectable } from '@angular/core';
import { NEXT_TERM_PLANNING_NEW_DATA_GRID_COLUMNS } from './next-term-planning-new-data-grid-columns.constant';

@Injectable()
export class NextTermPlanningDataService {
  constructor (private apiService: ApiService, private storeDataService: StoreDataService) {}

  getGapPlans$ (schoolId, grouping, projection): Observable<any> {
    return this.apiService.getGapPlansGraphQl({
      schoolId,
      grouping,
      projection,
    });
  }

  getSchool$ (): Observable<ISchool> {
    return this.storeDataService.getDataFromStore$(getSchool);
  }

  getGapPlansColumns (): {columnName: string, columnOrder: number, graphQlKey: string}[] {
    const GAP_PLANS_COLUMNS = [
      {
        columnName: 'DEPARTMENT_NAME',
        columnOrder: 0,
        graphQlKey: 'PATH_DEPARTMENT_NAME',
      },
      {
        columnName: 'STUDENTS',
        columnOrder: 1,
        graphQlKey: 'PATH_STUDENTS',
      },
    ];
    return GAP_PLANS_COLUMNS;
  }

  getColumnFieldsForDataGrid (gridType: TGrid): string[] {
    const isTransfer = ['transfer', 'summer_transfer'].includes(gridType);
    if (isTransfer) {
      return NEXT_TERM_PLANNING_NEW_DATA_GRID_COLUMNS.TRANSFER_HS;
    } else {
      return NEXT_TERM_PLANNING_NEW_DATA_GRID_COLUMNS.NON_TRANSFER_HS;
    }
  }
}
