import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

// services
import { WizardHelpers } from 'Src/ng2/school/sdc/services/wizard-helpers.service';

// modules
import { WizardFilterActionsModule } from 'Src/ng2/school/sdc/wizard-filter-actions/wizard-filter-actions.module';

// components
import { GradPlanningStep } from './grad-planning-step-component';

@NgModule({
  imports: [CommonModule, WizardFilterActionsModule, MatButtonModule],
  exports: [GradPlanningStep],
  declarations: [GradPlanningStep],
  providers: [WizardHelpers],
})
export class GradPlanningStepModule { }
