<div class="confirm-dialog csv-upload-error-modal">
  <div class="dialog-title">
    <div class="dialog-title-left">
      <nv-icon-button name="close-large-blue" (clickButton)="onCancel()"></nv-icon-button>
      <span>Unable to upload CSV file</span>
    </div>
  </div>
  <div class="dialog-body">
    <mat-dialog-content>
      <p>The CSV file does not meet all the requirements.</p>
      <div *ngFor="let errorMsg of errorMsgs">
        <nv-note [size]="noteSize" [priority]="notePriority">{{ errorMsg }}</nv-note>
      </div>
      <p>
        Please review the CSV upload <a href="{{ requirementsUrl }}" target="_blank">requirements guide</a> and try
        again.
      </p>
    </mat-dialog-content>
  </div>
  <div class="dialog-actions"><nv-button (clickButton)="onConfirm()">Ok</nv-button></div>
</div>
