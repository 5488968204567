'use strict';
import { ImStudentIReady } from '../services/im-models/im-student-iready';

export const FALL = 'Fall';
export const WINTER = 'Winter';
export const SPRING = 'Spring';

export const VALID_IREADY_TERMS = [FALL, SPRING, WINTER];
export type TValidiReadyTermName = typeof VALID_IREADY_TERMS[number];
export const IREADY_OLD_GRID_TERM_NAMES = {
  FALL: {
    termName: FALL,
  },
  WINTER: {
    termName: WINTER,
  },
  SPRING: {
    termName: SPRING,
  },
};

export const IREADY_ELA = 'ELA';
export const IREADY_MATH = 'Math';
export const VALID_IREADY_SUBJECTS = [IREADY_ELA, IREADY_MATH] as const;
export type TValidiReadySubject = typeof VALID_IREADY_SUBJECTS[number];
export const IREADY_OLD_GRID_SUBJECT_NAMES = {
  ELA: {
    queryKey: IREADY_ELA,
    humanName: 'Reading',
    colName: IREADY_ELA,
  },
  MATH: {
    queryKey: IREADY_MATH,
    humanName: IREADY_MATH,
    colName: IREADY_MATH,
  },
};

export const VALID_IREADY_GROWTH_TERMS = ['Winter', 'Spring']; // Fall is the initial term

export const ONE_BELOW = '1 Grade Level Below';
export const ONE_BELOW_SHORT = '1 below';
export const TWO_BELOW = '2 Grade Levels Below';
export const TWO_BELOW_SHORT = '2 below';
export const THREE_PLUS_BELOW = '3 or More Grade Levels Below';
export const THREE_PLUS_BELOW_SHORT = '3+ below';
export const EARLY_ON = 'Early On Grade Level';
export const EARLY_ON_SHORT = 'Early On';
export const MID_OR_ABOVE = 'Mid or Above Grade Level';
export const MID_OR_ABOVE_SHORT = 'Mid or Above';

export const IREADY_OLD_GRID_COLUMNS = {
  baselineDesiredFields: {
    scoreDate: {
      humanName: 'Score Date',
      colName: 'ScoreDate',
      desiredField: 'date',
      headerTooltip: function (term: string, subject: string): string {
        return `The date of the student's ${term} i-Ready ${subject.toLocaleLowerCase()} assessment this school year. i-Ready data is provided by Curriculum Associates and updated nightly.`;
      },
    },
    scaledScore: {
      humanName: 'Scaled Score',
      colName: 'ScaledScore',
      desiredField: 'score',
      headerTooltip: function (term: string, subject: string): string {
        return `The student's scaled score on the ${term} i-Ready ${subject.toLowerCase()} assessment this school year.`;
      },
    },
    overallRelativeLevel: {
      humanName: 'Overall Relative Placement',
      colName: 'OverallRelativePlacement',
      desiredField: 'relLvl',
      headerTooltip: function (term: string, subject: string): string {
        return `The student's overall relative placement on the ${term} i-Ready ${subject.toLowerCase()} assessment this school year. The relative placement level compares the student to their expected placement.`;
      },
      additionalCalculation: function (relLvl: string): string {
        return ImStudentIReady.shortenRelLvl(relLvl);
      },
    },
    overallPlacementLevel: {
      humanName: 'Overall Placement',
      colName: 'OverallPlacement',
      desiredField: 'absLvl',
      headerTooltip: function (term: string, subject: string): string {
        return `The student's overall placement on the ${term} i-Ready ${subject.toLowerCase()} assessment this school year.`;
      },
    },
  },
  generalDataDesiredFields: {
    typicalGrowth: {
      // EMSAC177
      humanName: 'Progress on Typical Annual Growth',
      colName: 'ProgressOnTypicalAnnualGrowth',
      desiredField: 'progressGrowthTypical',
      headerTooltip: function (subject: string, term:string): string {
        return `Shows the amount of progress a student has made toward Typical Growth from the Fall term to the ${term} term i-Ready ${subject.toLowerCase()} assessment. Typical Growth is the average annual growth for students at this grade and initial placement level.`;
      },
      additionalCalculation: function (progressGrowthData: number | string): string {
        return ImStudentIReady.formatIreadyGrowthData(progressGrowthData);
      },
    },
    stretchGrowth: {
      // EMSAC178
      humanName: 'Progress on Stretch Annual Growth',
      colName: 'ProgressOnStretchAnnualGrowth',
      desiredField: 'progressGrowthStretch',
      headerTooltip: function (subject: string, term: string): string {
        return `Shows the amount of progress a student has made toward Stretch Growth from the Fall term to the ${term} term i-Ready ${subject.toLowerCase()} assessment. Stretch Growth is an ambitious, but attainable, level of annual growth that puts students who are below grade level on a path to proficiency and students who are on grade level on a path to advanced proficiency levels.`;
      },
      additionalCalculation: function (progressGrowthData: number | string): string {
        return ImStudentIReady.formatIreadyGrowthData(progressGrowthData);
      },
    },
  },
  lexileFields: {
    maxDate: {
      // IREADY_MAX_LEXILE_DATE_IN_TERM_EMSAC382
      humanName: 'Max Date',
      colName: 'MaxDate',
      desiredField: 'date',
      headerTooltip: function (term:string): string {
        return `Date of the highest i-Ready lexile score achieved within the ${term} term`;
      },
    },
    maxScore: {
      // IREADY_MAX_LEXILE_SCORE_IN_TERM_EMSAC374
      humanName: 'Max Score',
      colName: 'MaxScore',
      desiredField: 'lexileMeasure',
      headerTooltip: function (term:string): string {
        return `Value of the highest i-Ready lexile score achieved within the ${term} term`;
      },
    },
  },
};
