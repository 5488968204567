import { Auth } from './../../../shared/auth/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router, ActivatedRouteSnapshot } from '@angular/router';
import { PortalConfig } from 'Src/ng2/shared/services/portal-config';
import { Observable, of } from 'rxjs';
import { ApiService } from 'Src/ng2/shared/services/api-service/api-service';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { ModalsService } from 'Src/ng2/shared/modals/modals.service';
import { LocalStorageService } from 'Src/ng2/shared/services/web-storage/local-storage/local-storage.service';
import { INACTIVITY_ERR_MESSAGE } from 'Src/ng2/shared/constants/auth/auth.constant';

@Injectable()
export class LoginGuard implements CanActivate {
  constructor (
    private router: Router,
    private auth: Auth,
    private portalConfig: PortalConfig,
    private apiService: ApiService,
    private modalsService: ModalsService,
    private localStorage: LocalStorageService,
  ) {}

  canActivate (route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.isMaintenanceMode(route).pipe(
      switchMap((modeOn) => {
        const isAuthenticated = this.auth.isAuthenticated();
        if (modeOn) return of(true);
        else if (isAuthenticated) return of(this.router.createUrlTree(['home']));
        else {
          this.handleLoginWithRedirect();
          return of(false);
        }
      }),
    );
  }

  private isMaintenanceMode (route: ActivatedRouteSnapshot) {
    // DISPLAY_ENV_PATH is only set in deployed environments, therefore maintenance mode is never enabled locally
    if (this.portalConfig.publicConfig.DISPLAY_ENV_PATH) {
      return this.apiService.getApiStatus().pipe(
        map(() => false),
        catchError((err) => {
          route.data = { ...route.data, isMaintenanceMode: true, isMaintenanceModeError: err };
          return of(true);
        }),
      );
    }
    return of(false);
  }

  private handleLoginWithRedirect () {
    const errorMessage = this.localStorage.getItem('errorMessage');
    if (errorMessage) { // handle error message
      this.localStorage.removeItem('errorMessage'); // remove errorMessage from localStore so when the user refresh the page, it doesn't show the same error
      const title = errorMessage === INACTIVITY_ERR_MESSAGE ? 'Logged out due to inactivity' : 'Something went wrong';
      this.modalsService.openErrorModal({ title, message: errorMessage })
        .afterClosed()
        .pipe(
          take(1),
          tap(() => this.auth.loginWithRedirect())) // redirect to auth login page once the user closes the modal
        .subscribe();
    } else {
      this.auth.loginWithRedirect(); // if there's no error, redirect to auth login page
    }
  }
}
