import { UserRolePermissionsForModelService } from './../user-role-permissions-for-model/user-role-permissions-for-model.service';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { UtilitiesService } from '../../../../ng2/shared/services/utilities/utilities.service';
import { CreditRequirements } from '../../constants/credit-requirements.constant';
import { GapPlanStatuses, TValidGapPlanFrontendStatuses } from '../../constants/gap-plan-statuses.constant';
import { ValidCourseCodeRegex } from '../../constants/valid-course-code-regex.constant';
import { IGapPlan } from '../../typings/interfaces/gap-plan.interface';
import { DateHelpers } from '../../../../../projects/shared/services/date-helpers/date-helpers.service';

const MODEL_NAME = 'GAP_PLAN';
const relationToUserPath = 'createdBy.userId';

/* istanbul ignore next */
@Injectable()
export class ImGapPlan {
  constructor (
    private permissions: UserRolePermissionsForModelService,
  ) {
    // empty block
  }

  getFrontendStatus (gapPlan: IGapPlan, currentTermYear: number, district = 'NYC'): TValidGapPlanFrontendStatuses {
    // NOT included in net gap calc
    // DO NOT CHANGE ORDER

    if (this.isDeleted(gapPlan)) return GapPlanStatuses.frontend.REMOVED;

    if (this.isApplied(gapPlan)) return GapPlanStatuses.frontend.APPLIED;

    if (this.isActiveForPastTerm(gapPlan, currentTermYear)) return GapPlanStatuses.frontend.PAST;

    if (this.isActiveAndInvalid(gapPlan, currentTermYear, district)) return GapPlanStatuses.frontend.INVALID;
    // included in net gap calc

    if (this.isActiveForCurrentTerm(gapPlan, currentTermYear, district)) return GapPlanStatuses.frontend.PENDING;

    if (this.isActiveForFutureTerm(gapPlan, currentTermYear, district)) return GapPlanStatuses.frontend.FUTURE;
  }

  _isActive (gapPlan: IGapPlan): boolean {
    const backendStatus = gapPlan.status;

    return backendStatus === GapPlanStatuses.backend.ACTIVE;
  }

  _isInvalid (gapPlan: IGapPlan, district = 'NYC'): boolean {
    const nyDistricts = ['NYC'];
    const { note, plan } = gapPlan;

    if (!plan) return true;
    else if (!nyDistricts.includes(district)) return false;
    else if (note && !plan) return false;
    else { let gapPlanMatcher = plan.split(' ')[0];
      gapPlanMatcher = gapPlanMatcher.split('-')[0];
      gapPlanMatcher = _.toUpper(gapPlanMatcher);
      const isValid = ValidCourseCodeRegex.test(gapPlanMatcher);

      return !isValid;
    }
  }

  _isActiveAndValid (gapPlan: IGapPlan, district = 'NYC'): boolean {
    const isActive = this._isActive(gapPlan);
    const isInvalid = this._isInvalid(gapPlan, district);

    return isActive && !isInvalid;
  }

  // REMOVED - these DONT get included in credit gap calculations
  isDeleted (gapPlan: IGapPlan): boolean {
    const backendStatus = gapPlan.status;

    return backendStatus === GapPlanStatuses.backend.DELETED;
  }

  // APPLIED - these DONT get included in credit gap calculations
  isApplied (gapPlan: IGapPlan): boolean {
    const backendStatus = gapPlan.status;

    return backendStatus === GapPlanStatuses.backend.APPLIED;
  }

  // INVALID - these DONT get included in credit gap calculations
  isActiveAndInvalid (gapPlan: IGapPlan, currentTermYear: number, district = 'NYC'): boolean {
    const isActive = this._isActive(gapPlan);
    const isInvalid = this._isInvalid(gapPlan, district);

    return isActive && isInvalid;
  }

  // VALID PENDING - these DO get included in credit gap calculations
  isActiveForCurrentTerm (gapPlan: IGapPlan, currentTermYear: number, district = 'NYC'): boolean {
    const termYear = gapPlan.termYear;
    const isActive = this._isActiveAndValid(gapPlan, district);
    const isCurrentTerm = termYear === currentTermYear;

    return isActive && isCurrentTerm;
  }

  // VALID FUTURE - these DO get included in credit gap calculations
  isActiveForFutureTerm (gapPlan: IGapPlan, currentTermYear: number, district?): boolean {
    const termYear = gapPlan.termYear;
    const isActive = this._isActiveAndValid(gapPlan, district);
    const isFutureTerm = termYear > currentTermYear;

    return isActive && isFutureTerm;
  }

  // PAST - these DONT get included in credit gap calculations
  isActiveForPastTerm (gapPlan: IGapPlan, currentTermYear: number): boolean {
    const termYear = gapPlan.termYear;
    const isActive = this._isActive(gapPlan);
    const isPastTerm = termYear < currentTermYear;

    return isActive && isPastTerm;
  }

  getDisplayString (gapPlan: IGapPlan): string {
    if (gapPlan.termYear === 999) return 'Not a gap: ' + gapPlan.creditValue + 'cr';

    return gapPlan.plan + ': ' + gapPlan.creditValue + 'cr (' + gapPlan.termYear + ')';
  }

  isBeyondStudentGradDate (gapPlan: IGapPlan, effectiveGradDate: string, currentTermYear): boolean {
    const termYear = gapPlan.termYear; // 171, 172, 177, 181, 182, etc.
    const isActiveForFutureTerm = this.isActiveForFutureTerm(gapPlan, currentTermYear);

    if (!isActiveForFutureTerm) return false;

    if (termYear === 999) return false;
    const monthYearDate = UtilitiesService.prototype.getMonthYearDateFromTermYear(termYear);
    const gradDateMoment = DateHelpers.prototype.getMomentObjForMonthYearDate(effectiveGradDate);
    const monthYearMoment = DateHelpers.prototype.getMomentObjForMonthYearDate(monthYearDate);

    if (gradDateMoment.isBefore(monthYearMoment)) {
      return true;
    }

    return false;
  }

  filterForPending (_gapPlans, currentTermYear, district): IGapPlan[] {
    return _.reduce(
      _gapPlans,
      (result, _gapPlan: IGapPlan) => {
        const frontendStatus = this.getFrontendStatus(_gapPlan, currentTermYear, district);
        const isPending = frontendStatus === GapPlanStatuses.frontend.PENDING;

        if (isPending) result.push(_gapPlan);

        return result;
      },
      [],
    );
  }

  filterForFuture (_gapPlans, currentTermYear, district): IGapPlan[] {
    return _.reduce(
      _gapPlans,
      (result, _gapPlan: IGapPlan) => {
        const frontendStatus = this.getFrontendStatus(_gapPlan, currentTermYear, district);
        const isFuture = frontendStatus === GapPlanStatuses.frontend.FUTURE;

        if (isFuture) result.push(_gapPlan);

        return result;
      },
      [],
    );
  }

  filterForPendingAndFuture (_gapPlans, currentTermYear, district): IGapPlan[] {
    return _.reduce(
      _gapPlans,
      (result, _gapPlan: IGapPlan) => {
        const frontendStatus = this.getFrontendStatus(_gapPlan, currentTermYear, district);
        const isPendingOrFuture =
          frontendStatus === GapPlanStatuses.frontend.PENDING || frontendStatus === GapPlanStatuses.frontend.FUTURE;

        if (isPendingOrFuture) result.push(_gapPlan);

        return result;
      },
      [],
    );
  }

  filterForPendingFutureAndInvalid (_gapPlans, currentTermYear, district = 'NYC'): IGapPlan[] {
    return _.reduce(
      _gapPlans,
      (result, _gapPlan: IGapPlan) => {
        const frontendStatus = this.getFrontendStatus(_gapPlan, currentTermYear, district);
        const isPendingFutureOrInvalid =
          frontendStatus === GapPlanStatuses.frontend.PENDING ||
          frontendStatus === GapPlanStatuses.frontend.FUTURE ||
          frontendStatus === GapPlanStatuses.frontend.INVALID;

        if (isPendingFutureOrInvalid) result.push(_gapPlan);

        return result;
      },
      [],
    );
  }

  filterForBeyondGradDate (_gapPlans, effectiveGradDate, currentTermYear): IGapPlan[] {
    return _.reduce(
      _gapPlans,
      (result, _gapPlan: IGapPlan) => {
        const isBeyond = this.isBeyondStudentGradDate(_gapPlan, effectiveGradDate, currentTermYear);

        if (isBeyond) result.push(_gapPlan);

        return result;
      },
      [],
    );
  }

  filterForGradReq (_gapPlans, gradReq: string, currentTermYear: number, district): IGapPlan[] {
    const creditRequirement = _.find(CreditRequirements, { camelCase: gradReq });
    const includedCreditRequirements = creditRequirement ? creditRequirement.includedCreditRequirements : null;
    const filtered = _.reduce(
      _gapPlans,
      (result, _gapPlan: IGapPlan) => {
        const frontendStatus = this.getFrontendStatus(_gapPlan, currentTermYear, district);
        const isPendingOrFuture =
          frontendStatus === GapPlanStatuses.frontend.PENDING || frontendStatus === GapPlanStatuses.frontend.FUTURE;

        if (isPendingOrFuture) {
          // CreditRequirements.TOTAL.camelCase is only exception

          if (gradReq === CreditRequirements.TOTAL.camelCase) {
            result.push(_gapPlan);
          } else {
            const gradReqIsIncluded = _.includes(includedCreditRequirements, _gapPlan.gradReq);

            if (gradReqIsIncluded) result.push(_gapPlan);
          }
        }

        return result;
      },
      [],
    );

    return filtered;
  }

  isIGapPlan (_gapPlan): _gapPlan is IGapPlan {
    return _gapPlan.singleUpdate !== undefined;
  }

  checkIsInstance (_gapPlan): IGapPlan {
    const isInstance = this.isIGapPlan(_gapPlan);

    if (!isInstance) return _gapPlan;

    return _gapPlan;
  }

  createNew (studentId, schoolId, student = null) {
    // TODO: TMP - Remove studentId when there is more time (JC)

    // adding this check because batch actions do not pass in a `student`
    studentId = studentId || student?._id;
    const lastFirst = student?.studentDetails.name.lastFirst || null;

    const template = {
      _id: UtilitiesService.prototype.createV4Uuid(),
      version: '0.1.0',
      student: {
        lastFirst,
        studentId,
      },
      schoolId,
      gradReq: null,
      plan: null,
      termYear: null,
      creditValue: null,
      note: null,
    };

    return template;
  }

  canView (user, courseDiff) {
    return this.permissions.canViewPartial(MODEL_NAME, relationToUserPath)(courseDiff, user);
  }
}
