import { Component, OnInit, ViewEncapsulation } from '@angular/core';
@Component({
  selector: '<maintenance-mode></maintenance-mode>',
  templateUrl: './maintenance-mode.component.html',
  styleUrls: ['./maintenance-mode.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MaintenanceMode implements OnInit { 

  public activeMaintenanceMessage: string;

  private readonly MAINTENANCE_MESSAGES = [
    {
      type: 'DEFAULT',
      message: 'As part of the Portal team’s commitment to ensuring all provided services are secure and reliable, we are conducting periodic maintenance. Please reach out to schoolsupport@newvisions.org with any questions.',
      isActive: false,
    },
    {
      type: 'FEATURE',
      message: 'Thank you for visiting the New Visions Network Portal! We are currently working on updates to improve your experience in the Network Portal, which should be complete within the hour. Please reach out to schoolsupport@newvisions.org with any questions.',
      isActive: true,
    },
  ]

  ngOnInit() {
    const foundActiveMessage = this.MAINTENANCE_MESSAGES.find(item=>item.isActive);
    const defaultMessage = this.MAINTENANCE_MESSAGES.find(item=>item.type === 'DEFAULT');
    this.activeMaintenanceMessage = foundActiveMessage ? foundActiveMessage.message : defaultMessage.message;
  }
 }
