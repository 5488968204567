import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { WizardHelpers } from './../../../../services/wizard-helpers.service';
import { CulminatingCourseStep } from './culminating-course-step.component';
import { NgModule } from '@angular/core';
import { WizardFilterActionsModule } from 'Src/ng2/school/sdc/wizard-filter-actions/wizard-filter-actions.module';

@NgModule({
  imports: [WizardFilterActionsModule, CommonModule, MatButtonModule],
  exports: [CulminatingCourseStep],
  declarations: [CulminatingCourseStep],
  providers: [WizardHelpers],
})
export class CulminatingCourseStepModule { }
