  <div *ngIf="(searchableStaffOptions$ | async) as searchableStaffOptions">
    <nv-form-field label="School staff"></nv-form-field>
    <nv-textbox 
      placeholder="Search for school staff..."
      [autocompleteOptions]="searchableStaffOptions"
      (selectOption)="onStaffSelect($event)"
      [textControl]="form.controls.staff"
      returnObject="true"
      hasClearBtn="true"
      (clearValue)="clearTextBoxValue()"
      ></nv-textbox>
  </div>

  <div class="languages">
    <nv-form-field label="Language(s) spoken"></nv-form-field>
    <nv-multi-picker
      [options]="languageOptions"
      [selectedOptions]="selectedLanguages.value"
      (selectedOptionsChange)="onLanguageSelect($event)"
      hasAllButton="false"
    ></nv-multi-picker>
  </div>

  <div *ngIf="(infoNote$ | async) as infoNote" class="info-note">
    <nv-note>
      {{ infoNote }}
    </nv-note>
  </div>
