import { ISchool } from './school.interface';
import { IShelter } from './shelter.interface';

export enum PartnerTypes {
  // eslint-disable-next-line no-unused-vars
  CUNY = 'cuny',
  // eslint-disable-next-line no-unused-vars
  EXPANDED = 'expandEd',
  // eslint-disable-next-line no-unused-vars
  HYBRID = 'hybrid',
  // eslint-disable-next-line no-unused-vars
  SCHOOL = 'school',
  // eslint-disable-next-line no-unused-vars
  SCHOOL_NETWORK = 'school_network',
  // eslint-disable-next-line no-unused-vars
  SHELTER = 'shelter',
  // eslint-disable-next-line no-unused-vars
  SHELTER_NETWORK = 'shelter_network',
}
export type TValidPartnerTypes =
  | PartnerTypes.CUNY
  | PartnerTypes.EXPANDED
  | PartnerTypes.HYBRID
  | PartnerTypes.SCHOOL
  | PartnerTypes.SCHOOL_NETWORK
  | PartnerTypes.SHELTER
  | PartnerTypes.SHELTER_NETWORK;

// IPartner could be extended to include other partner interface
export interface IPartner extends Partial<IShelter>, Partial<ISchool> {}

export const ALL_PARTNER_TYPES = [
  PartnerTypes.CUNY,
  PartnerTypes.EXPANDED,
  PartnerTypes.HYBRID,
  PartnerTypes.SCHOOL,
  PartnerTypes.SCHOOL_NETWORK,
  PartnerTypes.SHELTER,
  PartnerTypes.SHELTER_NETWORK,
];
