import { MatButtonModule } from '@angular/material/button';
import { WizardFilterActionsModule } from 'Src/ng2/school/sdc/wizard-filter-actions/wizard-filter-actions.module';
import { CommonModule } from '@angular/common';
import { StarsMismatchStep } from './stars-mismatch-step.component';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [CommonModule, WizardFilterActionsModule, MatButtonModule],
  exports: [StarsMismatchStep],
  declarations: [StarsMismatchStep],
  providers: [],
})
export class StarsMismatchStepModule { }
