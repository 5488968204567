import { UserRoleTypeToAccessLevelMapping } from './../../../constants/manage-users.constant';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PortalLanguages } from 'Src/ng2/shared/constants/portal-languages.constant';
import { unsubscribeComponent } from 'Src/ng2/shared/helpers/unsubscribe-decorator/unsubscribe-decorators.helper';
import { ImUserSchool } from 'Src/ng2/shared/services/im-models/im-user/im-user-school';
import { PickerService } from 'Src/ng2/shared/services/picker/picker.service';
import { StoreDataService } from 'Src/ng2/shared/services/store-data-service/store-data.service';
import { IACOption } from 'projects/shared/nvps-libraries/design/interfaces/design-library.interface';
import { LoadUsers, getUsersEntities, getUsersLoadedStatus } from 'Src/ng2/store';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@unsubscribeComponent
@Component({
  selector: 'add-caring-adult',
  templateUrl: 'add-caring-adult.component.html',
  styleUrls: ['./add-caring-adult.component.scss'],
})

export class AddCaringAdultComponent implements OnInit {
  @Input() schoolId: string;

  public languageOptions: IACOption[];
  public selectedLanguages = new BehaviorSubject([]);
  public searchableStaffOptions$: Observable<IACOption[]>;
  public form: FormGroup;
  public selectedStaffId = new BehaviorSubject(null);
  public infoNote$: Observable<string>;

  constructor (
    private storeDataService: StoreDataService,
    private formBuilder: FormBuilder,
    private pickerService: PickerService,
    private imUserSchool: ImUserSchool,
  ) {}

  ngOnInit () {
    this.form = this.getFormGroup();

    const staffOptions$ = this.getStaffOptions$();

    this.searchableStaffOptions$ = combineLatest([
      this.form.controls.staff.valueChanges.pipe(startWith('')),
      staffOptions$,
    ]).pipe(
      map(([searchVal, staffOptions]) => this.pickerService.filterAcOptions((staffOptions || []), searchVal)),
    );

    this.infoNote$ = combineLatest([
      this.selectedStaffId,
      staffOptions$,
    ]).pipe(
      map(([userId, staffOptions]) => this.getInfoNote(userId, staffOptions)),
    );

    this.languageOptions = this.getLanguageOptions();
    this.selectedLanguages.next(['English']); // default to English
  }

  private getInfoNote (userId, staffOptions): string {
    const user = staffOptions.find(user => user.key === userId);
    const noAccessMessage = 'This user currently has no Portal access. Their Portal permissions will be updated to caseload editor';
    const mapDelegatedRoleInfoNote = {
      view_caseload: 'This user’s permissions will be updated to caseload editor',
      view_all: 'This user’s permissions will be updated to school editor',
      no_access: noAccessMessage,
      school: noAccessMessage,
    };
    const infoNote = mapDelegatedRoleInfoNote[user?.tags[0]?.key] || '';
    return infoNote;
  }

  private getStaffOptions$ (): Observable<IACOption[]> {
    const staffOptions$ = this.storeDataService.loadDataAndGetStream$({ schoolId: this.schoolId }, getUsersLoadedStatus, LoadUsers, getUsersEntities).pipe(
      map(users => this.formatStaffOptions(this.filterUsers(users))),
    );
    return staffOptions$;
  }

  private filterUsers (users) {
    const filteredUsers = users.filter(({ permissions, authorizationStatus }) => {
      const isCaringAdultForSchool = permissions?.ecfik?.studentCaseload?.find(caseload => caseload.schoolId === this.schoolId && caseload.role === 'CARING_ADULT');
      const hasFullAccess = authorizationStatus === 'FULL_ACCESS';
      return !isCaringAdultForSchool && hasFullAccess;
    });
    return filteredUsers;
  }

  private formatStaffOptions (users): IACOption[] {
    const options = users.map(
      user => {
        const { _id, delegatedRole, name: { firstName, lastName } } = user;
        const tags = [];

        if (delegatedRole) {
          tags.push({
            key: delegatedRole,
            human: UserRoleTypeToAccessLevelMapping[delegatedRole],
          });
        } else {
          const accessRole = this.getSchoolAccessRole(user, this.schoolId);
          tags.push({
            key: accessRole,
            human: UserRoleTypeToAccessLevelMapping[accessRole] || 'No access',
          });
        }

        return {
          key: _id,
          human: `${firstName} ${lastName}`,
          tags,
        };
      },
    );
    return options;
  }

  private getSchoolAccessRole (user, schoolId): string {
    let role;
    if (user.nvRole.type === 'school') {
      role = 'school';
    } else if (this.imUserSchool.isSchoolUser(user)) {
      role = user.nvRole.type;
    } else { // multi_school case
      const permissions = user.portfolio.permissions;
      const [accessRole] = Object.entries(permissions).find(([role, schoolIds]: [role: string, schoolIds: string[]]) => schoolIds.includes(schoolId));
      role = accessRole;
    }
    return role;
  }

  private getLanguageOptions (): IACOption[] {
    const options = Object.values(PortalLanguages).map(
      language => ({ key: language, human: language }),
    );
    return options;
  }

  private getFormGroup (): FormGroup {
    const fg = this.formBuilder.group({
      staff: [null, Validators.required],
    });
    return fg;
  }

  public onLanguageSelect ($event): void {
    this.selectedLanguages.next($event);
  }

  public onStaffSelect ($event): void {
    this.selectedStaffId.next($event.key);
    this.form.controls.staff.setValue($event.human);
  }

  public clearTextBoxValue (): void {
    this.selectedStaffId.next('');
    this.form.controls.staff.setValue('');
  }

  public getPayload () {
    const payload = {
      selectedStaffId: this.selectedStaffId.value,
      selectedLanguages: this.selectedLanguages.value,
      schoolId: this.schoolId,
    };
    return payload;
  }
}
