import { Injectable } from '@angular/core';
import { TShelterClusterUserModalViewMode } from '../../../../modals/user-management/cluster-user/shelter-cluster-user/shelter-cluster-user-modals.config';

@Injectable()
export class GraphQlShelterClusterUserHelperService {
  public getShelterClusterUsersQuery({ clusterId, projection }): string {
    const validClusterId = clusterId ? `"${clusterId}"` : 'null';
    const columnKeys = this.getColumnKeys(projection);
    return `{
      ShelterClusterUserGrouping(
        clusterId: ${validClusterId}
        columns: [${columnKeys}]
      ) {
        groupData {
          human
          rowData {
            data
            meta
            columnKey
          }
        }
      }
    }`;
  }

  public getShelterClusterUserDetailQuery({ userId }): string {
    const validatedUserId = userId === null ? `${userId}` : `"${userId}"`;
    return `{
      ShelterClusterUserDetail(
        userId: ${validatedUserId}
      ) {
          approvedDomains
          loginMethod {
            required
            optional
          }
          clusterId
          shelterIds
          userBasic {
            firstName
            lastName
            doeEmail
            dhsEmail
            gafeEmail
            title
            delegatedRoleShelter
            isShelterClusterTrustedUser
          }
        }
    }`;
  }

  public getShelterClusterUserSheltersQuery({ userId, clusterId }): string {
    const validatedUserId = userId === null ? `${userId}` : `"${userId}"`;
    const validatedClusterId = clusterId === null ? `${clusterId}` : `"${clusterId}"`;
    return `{
      ClusterUserShelters(
        userId: ${validatedUserId}
        clusterId: ${validatedClusterId}
      ) {
        key
        human
        showAll
        rowData {
          data
          meta
          columnKey
        }
      }
    }`;
  }

  public getShelterClusterUserMutationQuery(
    { userPayload: payload, columns },
    mode: TShelterClusterUserModalViewMode,
  ): string {
    const { permissions, userId, clusterId } = payload;
    let mutationName;
    let options = '';

    switch (mode) {
      case 'CREATE':
        mutationName = 'createShelterClusterUser';
        break;
      case 'EDIT':
        mutationName = 'updateShelterClusterUser';
        break;
      case 'REMOVE':
        mutationName = 'deleteShelterClusterUser';
        break;
    }

    options += this._getBasicInfoOptions(payload);
    options += this._getPermissionOptions(permissions, payload.delegatedRoleShelter);

    if (userId) {
      options += `userId: "${userId}"`;
    }

    if (clusterId) {
      options += `clusterId: "${clusterId}"`;
    }

    const columnKeys = this.getColumnKeys(columns);

    return `
    mutation {
      ${mutationName}(
        options: {${options}},
        columns: [${columnKeys}]
      ) {
        groupData {
          human
          rowData {
            data
            meta
            columnKey
          }
        }
        groupingOptions{
          human
          key
        }
      }
    }`;
  }

  private getColumnKeys(projection: string[]): string[] {
    return projection.map(key => {
      return `"${key}"`;
    });
  }

  private _getBasicInfoOptions(payload): string {
    const { delegatedRoleShelter, doeEmail, firstName, gafeEmail, lastName, title, dhsEmail } = payload;
    let options = '';
    if (firstName) {
      // if firstName does not exists assume no other userBasic information exists and we are editing a portfolio not
      // userDetails
      const validatedDelegatedRoleShelter = delegatedRoleShelter === 'no_access' ? 'null' : `"${delegatedRoleShelter}"`;

      options = options += `
        title: "${title}",
        name: {
          firstName: "${firstName}",
          lastName: "${lastName}",
        },
        delegatedRoleShelter: ${validatedDelegatedRoleShelter},
      `;

      if (gafeEmail || doeEmail || dhsEmail) {
        // when it is undefined?
        // 1. no optional login method
        // 2. formControls associated with them are disabled
        // when it is empty string?
        // 1. email type is optional
        const validatedGafeEmail = gafeEmail ? `"${gafeEmail}"` : 'null';
        const validatedDoeEmail = doeEmail ? `"${doeEmail}"` : 'null';
        const validatedDhsEmail = dhsEmail ? `"${dhsEmail}"` : 'null';
        options += `
        gafeEmail: ${validatedGafeEmail},
        doeEmail: ${validatedDoeEmail},
        dhsEmail: ${validatedDhsEmail},
        `;
      }
    }
    return options;
  }

  private _getPermissionOptions(permissions, delegatedRoleShelter): string {
    let options = '';
    if (permissions) {
      const permissionsString = Object.keys(permissions)
        .map(key => {
          return `
          ${key}: ${JSON.stringify(permissions[key])}`;
        })
        .join(', ');
      options += `
        shelterPortfolio: {
          permissions: { ${permissionsString}
          }
        }
      `;
    } else if (['no_access', 'network_only'].includes(delegatedRoleShelter)) {
      // only when validatedDelegatedRoleShelter is null or network_only, then porfolio should be null - new or update mode
      options += `
        shelterPortfolio: null
      `;
    }
    return options;
  }
}
