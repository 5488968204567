import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { StandardLayoutComponent } from './standard-layout.component';
import { SideNavNgModule } from '../../../shell/side-nav/side-nav.module';

@NgModule({
  declarations: [StandardLayoutComponent],
  imports: [CommonModule, RouterModule, SideNavNgModule],
  exports: [StandardLayoutComponent],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class StandardLayoutModule {}
