<base-modal class="base-modal" isProfileMode="true" [title]="title">
  <form [formGroup]="gridViewForm" autocomplete="off" *ngIf="gridViewForm" class="modal-variant modal-variant-conditional-value-field" (submit)="submit(form)">
  <mat-dialog-content>
    <p class="subtitle" *ngIf="subtitle">{{ subtitle }}</p>
    <nv-form-field class="gridviewName" label="View name">
      <nv-textbox class="nameText" cdkFocusInitial 
        [textControl]="gridViewForm.controls.gridViewName"
        [hasClearBtn]="true"
        (clearValue)="onClearInput()"
        [placeholder]="placeholder"
        [showInvalid]="isInvalidGridViewName"
        [restrictToOptions]="false">
      </nv-textbox>
    </nv-form-field>
    <p class="character-counter" [ngClass]="isInvalidGridViewName ? 'warning-message' : ''" name="character-counter"> {{ gridViewForm.get('gridViewName').value?.length }}/{{ characterLimit }}</p>
    <nv-note *ngIf="isInvalidGridViewName" [priority]="'danger'">{{ warningMessage }}</nv-note>
  </mat-dialog-content>
  <mat-dialog-actions class="dialog-actions">
    <div class="button-wrapper">
      <nv-button (click)="onCancel()" type="secondary">Cancel</nv-button>
      <nv-button (click)="onSave()" [disabled]="gridViewForm.pristine || gridViewForm.invalid || isExceededCharLimit || isExistingGridViewName || isNameInValid"> {{ mainButtonLabel }}</nv-button>
    </div>
  </mat-dialog-actions>
</form>
</base-modal>
