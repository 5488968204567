import { TMatDisplayOption } from '../transform-to-mat-display-options/transform-to-mat-display-options.pipe';
import { Pipe, PipeTransform } from '@angular/core';

/**
 * This pipe implements transform as a pure function that filters display options, running a callback
 * predicate on each option to determine if it should be displayed when auto-complete is in use.
 */
@Pipe({ name: 'filterMatDisplayOptions' })
export class FilterMatDisplayOptions implements PipeTransform {
  transform (options: TMatDisplayOption[], searchValue: string, callback): TMatDisplayOption[] {
    let filteredOptions = options;

    // the first time the template pipes, the callback may not yet be instantiated.
    if (callback) {
      filteredOptions = options.filter(option => callback(option, searchValue));
    }
    return filteredOptions;
  }
}
