import { animate, state, style, transition, trigger } from '@angular/animations';

export function collapseOutAni() {
  return trigger('collapseOutAni', [
    state(
      'collapsed',
      style({
        opacity: 0,
        transform: 'scaleY(0.1)',
      }),
    ),
    state(
      'expanded',
      style({
        opacity: 1,
        transform: 'scaleY(1)',
      }),
    ),
    transition('expanded => collapsed', [animate(200)]),
  ]);
}
