export interface IGradPlanTransferValue {
  readonly humanName: string;
  readonly date?: string;
  readonly diploma?: string;
}

// TODO UPDATE AT ATS FLIP: add grad dates for younger cohorts
export const GraduationPlanTransfer = {
  NEGATIVE_DISCHARGE: {
    humanName: 'Negative Discharge',
  } as IGradPlanTransferValue,
  GRADUATE: {
    humanName: 'Graduate',
  } as IGradPlanTransferValue,
  PLAN_IN_PAST: {
    humanName: 'Plan in Past',
  } as IGradPlanTransferValue,
  PLAN_INCOMPLETE: {
    humanName: 'Plan Incomplete',
  } as IGradPlanTransferValue,
  NON_GRADUATE: {
    humanName: 'Non-Graduate',
  } as IGradPlanTransferValue,
  // LOCAL
  // 2018
  LOCAL_JAN_2018: {
    humanName: 'Local Jan 2018',
    date: 'Jan 2018',
    diploma: 'Local',
  } as IGradPlanTransferValue,
  LOCAL_MARCH_2018: {
    humanName: 'Local March 2018',
    date: 'March 2018',
    diploma: 'Local',
  } as IGradPlanTransferValue,
  LOCAL_JUNE_2018: {
    humanName: 'Local June 2018',
    date: 'June 2018',
    diploma: 'Local',
  } as IGradPlanTransferValue,
  LOCAL_AUG_2018: {
    humanName: 'Local Aug 2018',
    date: 'Aug 2018',
    diploma: 'Local',
  } as IGradPlanTransferValue,
  // 2019
  LOCAL_JAN_2019: {
    humanName: 'Local Jan 2019',
    date: 'Jan 2019',
    diploma: 'Local',
  } as IGradPlanTransferValue,
  LOCAL_MARCH_2019: {
    humanName: 'Local March 2019',
    date: 'March 2019',
    diploma: 'Local',
  } as IGradPlanTransferValue,
  LOCAL_JUNE_2019: {
    humanName: 'Local June 2019',
    date: 'June 2019',
    diploma: 'Local',
  } as IGradPlanTransferValue,
  LOCAL_AUG_2019: {
    humanName: 'Local Aug 2019',
    date: 'Aug 2019',
    diploma: 'Local',
  } as IGradPlanTransferValue,
  // 2020
  LOCAL_JAN_2020: {
    humanName: 'Local Jan 2020',
    date: 'Jan 2020',
    diploma: 'Local',
  } as IGradPlanTransferValue,
  LOCAL_MARCH_2020: {
    humanName: 'Local March 2020',
    date: 'March 2020',
    diploma: 'Local',
  } as IGradPlanTransferValue,
  LOCAL_JUNE_2020: {
    humanName: 'Local June 2020',
    date: 'June 2020',
    diploma: 'Local',
  } as IGradPlanTransferValue,
  LOCAL_AUG_2020: {
    humanName: 'Local Aug 2020',
    date: 'Aug 2020',
    diploma: 'Local',
  } as IGradPlanTransferValue,
  // 2021
  LOCAL_JAN_2021: {
    humanName: 'Local Jan 2021',
    date: 'Jan 2021',
    diploma: 'Local',
  } as IGradPlanTransferValue,
  LOCAL_MARCH_2021: {
    humanName: 'Local March 2021',
    date: 'March 2021',
    diploma: 'Local',
  } as IGradPlanTransferValue,
  LOCAL_JUNE_2021: {
    humanName: 'Local June 2021',
    date: 'June 2021',
    diploma: 'Local',
  } as IGradPlanTransferValue,
  LOCAL_AUG_2021: {
    humanName: 'Local Aug 2021',
    date: 'Aug 2021',
    diploma: 'Local',
  } as IGradPlanTransferValue,
  // 2022
  LOCAL_JAN_2022: {
    humanName: 'Local Jan 2022',
    date: 'Jan 2022',
    diploma: 'Local',
  } as IGradPlanTransferValue,
  LOCAL_MARCH_2022: {
    humanName: 'Local March 2022',
    date: 'March 2022',
    diploma: 'Local',
  } as IGradPlanTransferValue,
  LOCAL_JUNE_2022: {
    humanName: 'Local June 2022',
    date: 'June 2022',
    diploma: 'Local',
  } as IGradPlanTransferValue,
  LOCAL_AUG_2022: {
    humanName: 'Local Aug 2022',
    date: 'Aug 2022',
    diploma: 'Local',
  } as IGradPlanTransferValue,
  // 2023
  LOCAL_JAN_2023: {
    humanName: 'Local Jan 2023',
    date: 'Jan 2023',
    diploma: 'Local',
  } as IGradPlanTransferValue,
  LOCAL_MARCH_2023: {
    humanName: 'Local March 2023',
    date: 'March 2023',
    diploma: 'Local',
  } as IGradPlanTransferValue,
  LOCAL_JUNE_2023: {
    humanName: 'Local June 2023',
    date: 'June 2023',
    diploma: 'Local',
  } as IGradPlanTransferValue,
  LOCAL_AUG_2023: {
    humanName: 'Local Aug 2023',
    date: 'Aug 2023',
    diploma: 'Local',
  } as IGradPlanTransferValue,
  // 2024
  LOCAL_JAN_2024: {
    humanName: 'Local Jan 2024',
    date: 'Jan 2024',
    diploma: 'Local',
  } as IGradPlanTransferValue,
  LOCAL_MARCH_2024: {
    humanName: 'Local March 2024',
    date: 'March 2024',
    diploma: 'Local',
  } as IGradPlanTransferValue,
  LOCAL_JUNE_2024: {
    humanName: 'Local June 2024',
    date: 'June 2024',
    diploma: 'Local',
  } as IGradPlanTransferValue,
  LOCAL_AUG_2024: {
    humanName: 'Local Aug 2024',
    date: 'Aug 2024',
    diploma: 'Local',
  } as IGradPlanTransferValue,
  // 2025
  LOCAL_JAN_2025: {
    humanName: 'Local Jan 2025',
    date: 'Jan 2025',
    diploma: 'Local',
  } as IGradPlanTransferValue,
  LOCAL_MARCH_2025: {
    humanName: 'Local March 2025',
    date: 'March 2025',
    diploma: 'Local',
  } as IGradPlanTransferValue,
  LOCAL_JUNE_2025: {
    humanName: 'Local June 2025',
    date: 'June 2025',
    diploma: 'Local',
  } as IGradPlanTransferValue,
  LOCAL_AUG_2025: {
    humanName: 'Local Aug 2025',
    date: 'Aug 2025',
    diploma: 'Local',
  } as IGradPlanTransferValue,
  // 2026
  LOCAL_JAN_2026: {
    humanName: 'Local Jan 2026',
    date: 'Jan 2026',
    diploma: 'Local',
  } as IGradPlanTransferValue,
  LOCAL_MARCH_2026: {
    humanName: 'Local March 2026',
    date: 'March 2026',
    diploma: 'Local',
  } as IGradPlanTransferValue,
  LOCAL_JUNE_2026: {
    humanName: 'Local June 2026',
    date: 'June 2026',
    diploma: 'Local',
  } as IGradPlanTransferValue,
  LOCAL_AUG_2026: {
    humanName: 'Local Aug 2026',
    date: 'Aug 2026',
    diploma: 'Local',
  } as IGradPlanTransferValue,

  // 2027
  LOCAL_JAN_2027: {
    humanName: 'Local Jan 2027',
    date: 'Jan 2027',
    diploma: 'Local',
  } as IGradPlanTransferValue,
  LOCAL_MARCH_2027: {
    humanName: 'Local March 2027',
    date: 'March 2027',
    diploma: 'Local',
  } as IGradPlanTransferValue,
  LOCAL_JUNE_2027: {
    humanName: 'Local June 2027',
    date: 'June 2027',
    diploma: 'Local',
  } as IGradPlanTransferValue,
  LOCAL_AUG_2027: {
    humanName: 'Local Aug 2027',
    date: 'Aug 2027',
    diploma: 'Local',
  } as IGradPlanTransferValue,
  // 2028
  LOCAL_JAN_2028: {
    humanName: 'Local Jan 2028',
    date: 'Jan 2028',
    diploma: 'Local',
  } as IGradPlanTransferValue,
  LOCAL_MARCH_2028: {
    humanName: 'Local March 2028',
    date: 'March 2028',
    diploma: 'Local',
  } as IGradPlanTransferValue,
  LOCAL_JUNE_2028: {
    humanName: 'Local June 2028',
    date: 'June 2028',
    diploma: 'Local',
  } as IGradPlanTransferValue,
  LOCAL_AUG_2028: {
    humanName: 'Local Aug 2028',
    date: 'Aug 2028',
    diploma: 'Local',
  } as IGradPlanTransferValue,


  // REGENTS
  // 2018
  REGENTS_JAN_2018: {
    humanName: 'Regents Jan 2018',
    date: 'Jan 2018',
    diploma: 'Regents',
  } as IGradPlanTransferValue,
  REGENTS_MARCH_2018: {
    humanName: 'Regents March 2018',
    date: 'March 2018',
    diploma: 'Regents',
  } as IGradPlanTransferValue,
  REGENTS_JUNE_2018: {
    humanName: 'Regents June 2018',
    date: 'June 2018',
    diploma: 'Regents',
  } as IGradPlanTransferValue,
  REGENTS_AUG_2018: {
    humanName: 'Regents Aug 2018',
    date: 'Aug 2018',
    diploma: 'Regents',
  } as IGradPlanTransferValue,
  // 2019
  REGENTS_JAN_2019: {
    humanName: 'Regents Jan 2019',
    date: 'Jan 2019',
    diploma: 'Regents',
  } as IGradPlanTransferValue,
  REGENTS_MARCH_2019: {
    humanName: 'Regents March 2019',
    date: 'March 2019',
    diploma: 'Regents',
  } as IGradPlanTransferValue,
  REGENTS_JUNE_2019: {
    humanName: 'Regents June 2019',
    date: 'June 2019',
    diploma: 'Regents',
  } as IGradPlanTransferValue,
  REGENTS_AUG_2019: {
    humanName: 'Regents Aug 2019',
    date: 'Aug 2019',
    diploma: 'Regents',
  } as IGradPlanTransferValue,
  // 2020
  REGENTS_JAN_2020: {
    humanName: 'Regents Jan 2020',
    date: 'Jan 2020',
    diploma: 'Regents',
  } as IGradPlanTransferValue,
  REGENTS_MARCH_2020: {
    humanName: 'Regents March 2020',
    date: 'March 2020',
    diploma: 'Regents',
  } as IGradPlanTransferValue,
  REGENTS_JUNE_2020: {
    humanName: 'Regents June 2020',
    date: 'June 2020',
    diploma: 'Regents',
  } as IGradPlanTransferValue,
  REGENTS_AUG_2020: {
    humanName: 'Regents Aug 2020',
    date: 'Aug 2020',
    diploma: 'Regents',
  } as IGradPlanTransferValue,
  // 2021
  REGENTS_JAN_2021: {
    humanName: 'Regents Jan 2021',
    date: 'Jan 2021',
    diploma: 'Regents',
  } as IGradPlanTransferValue,
  REGENTS_MARCH_2021: {
    humanName: 'Regents March 2021',
    date: 'March 2021',
    diploma: 'Regents',
  } as IGradPlanTransferValue,
  REGENTS_JUNE_2021: {
    humanName: 'Regents June 2021',
    date: 'June 2021',
    diploma: 'Regents',
  } as IGradPlanTransferValue,
  REGENTS_AUG_2021: {
    humanName: 'Regents Aug 2021',
    date: 'Aug 2021',
    diploma: 'Regents',
  } as IGradPlanTransferValue,
  // 2022
  REGENTS_JAN_2022: {
    humanName: 'Regents Jan 2022',
    date: 'Jan 2022',
    diploma: 'Regents',
  } as IGradPlanTransferValue,
  REGENTS_MARCH_2022: {
    humanName: 'Regents March 2022',
    date: 'March 2022',
    diploma: 'Regents',
  } as IGradPlanTransferValue,
  REGENTS_JUNE_2022: {
    humanName: 'Regents June 2022',
    date: 'June 2022',
    diploma: 'Regents',
  } as IGradPlanTransferValue,
  REGENTS_AUG_2022: {
    humanName: 'Regents Aug 2022',
    date: 'Aug 2022',
    diploma: 'Regents',
  } as IGradPlanTransferValue,
  // 2023
  REGENTS_JAN_2023: {
    humanName: 'Regents Jan 2023',
    date: 'Jan 2023',
    diploma: 'Regents',
  } as IGradPlanTransferValue,
  REGENTS_MARCH_2023: {
    humanName: 'Regents March 2023',
    date: 'March 2023',
    diploma: 'Regents',
  } as IGradPlanTransferValue,
  REGENTS_JUNE_2023: {
    humanName: 'Regents June 2023',
    date: 'June 2023',
    diploma: 'Regents',
  } as IGradPlanTransferValue,
  REGENTS_AUG_2023: {
    humanName: 'Regents Aug 2023',
    date: 'Aug 2023',
    diploma: 'Regents',
  } as IGradPlanTransferValue,
  // 2024
  REGENTS_JAN_2024: {
    humanName: 'Regents Jan 2024',
    date: 'Jan 2024',
    diploma: 'Regents',
  } as IGradPlanTransferValue,
  REGENTS_MARCH_2024: {
    humanName: 'Regents March 2024',
    date: 'March 2024',
    diploma: 'Regents',
  } as IGradPlanTransferValue,
  REGENTS_JUNE_2024: {
    humanName: 'Regents June 2024',
    date: 'June 2024',
    diploma: 'Regents',
  } as IGradPlanTransferValue,
  REGENTS_AUG_2024: {
    humanName: 'Regents Aug 2024',
    date: 'Aug 2024',
    diploma: 'Regents',
  } as IGradPlanTransferValue,
  // 2025
  REGENTS_JAN_2025: {
    humanName: 'Regents Jan 2025',
    date: 'Jan 2025',
    diploma: 'Regents',
  } as IGradPlanTransferValue,
  REGENTS_MARCH_2025: {
    humanName: 'Regents March 2025',
    date: 'March 2025',
    diploma: 'Regents',
  } as IGradPlanTransferValue,
  REGENTS_JUNE_2025: {
    humanName: 'Regents June 2025',
    date: 'June 2025',
    diploma: 'Regents',
  } as IGradPlanTransferValue,
  REGENTS_AUG_2025: {
    humanName: 'Regents Aug 2025',
    date: 'Aug 2025',
    diploma: 'Regents',
  } as IGradPlanTransferValue,
  // 2026
  REGENTS_JAN_2026: {
    humanName: 'Regents Jan 2026',
    date: 'Jan 2026',
    diploma: 'Regents',
  } as IGradPlanTransferValue,
  REGENTS_MARCH_2026: {
    humanName: 'Regents March 2026',
    date: 'March 2026',
    diploma: 'Regents',
  } as IGradPlanTransferValue,
  REGENTS_JUNE_2026: {
    humanName: 'Regents June 2026',
    date: 'June 2026',
    diploma: 'Regents',
  } as IGradPlanTransferValue,
  REGENTS_AUG_2026: {
    humanName: 'Regents Aug 2026',
    date: 'Aug 2026',
    diploma: 'Regents',
  } as IGradPlanTransferValue,
  // 2027
  REGENTS_JAN_2027: {
    humanName: 'Regents Jan 2027',
    date: 'Jan 2027',
    diploma: 'Regents',
  } as IGradPlanTransferValue,
  REGENTS_MARCH_2027: {
    humanName: 'Regents March 2027',
    date: 'March 2027',
    diploma: 'Regents',
  } as IGradPlanTransferValue,
  REGENTS_JUNE_2027: {
    humanName: 'Regents June 2027',
    date: 'June 2027',
    diploma: 'Regents',
  } as IGradPlanTransferValue,
  REGENTS_AUG_2027: {
    humanName: 'Regents Aug 2027',
    date: 'Aug 2027',
    diploma: 'Regents',
  } as IGradPlanTransferValue,
  // 2028
  REGENTS_JAN_2028: {
    humanName: 'Regents Jan 2028',
    date: 'Jan 2028',
    diploma: 'Regents',
  } as IGradPlanTransferValue,
  REGENTS_MARCH_2028: {
    humanName: 'Regents March 2028',
    date: 'March 2028',
    diploma: 'Regents',
  } as IGradPlanTransferValue,
  REGENTS_JUNE_2028: {
    humanName: 'Regents June 2028',
    date: 'June 2028',
    diploma: 'Regents',
  } as IGradPlanTransferValue,
  REGENTS_AUG_2028: {
    humanName: 'Regents Aug 2028',
    date: 'Aug 2028',
    diploma: 'Regents',
  } as IGradPlanTransferValue,

  // ADVANCED REGENTS
  // 2018
  ADVANCED_REGENTS_JAN_2018: {
    humanName: 'Advanced Regents Jan 2018',
    date: 'Jan 2018',
    diploma: 'Advanced Regents',
  } as IGradPlanTransferValue,
  ADVANCED_REGENTS_MARCH_2018: {
    humanName: 'Advanced Regents March 2018',
    date: 'March 2018',
    diploma: 'Advanced Regents',
  } as IGradPlanTransferValue,
  ADVANCED_REGENTS_JUNE_2018: {
    humanName: 'Advanced Regents June 2018',
    date: 'June 2018',
    diploma: 'Advanced Regents',
  } as IGradPlanTransferValue,
  ADVANCED_REGENTS_AUG_2018: {
    humanName: 'Advanced Regents Aug 2018',
    date: 'Aug 2018',
    diploma: 'Advanced Regents',
  } as IGradPlanTransferValue,
  // 2019
  ADVANCED_REGENTS_JAN_2019: {
    humanName: 'Advanced Regents Jan 2019',
    date: 'Jan 2019',
    diploma: 'Advanced Regents',
  } as IGradPlanTransferValue,
  ADVANCED_REGENTS_MARCH_2019: {
    humanName: 'Advanced Regents March 2019',
    date: 'March 2019',
    diploma: 'Advanced Regents',
  } as IGradPlanTransferValue,
  ADVANCED_REGENTS_JUNE_2019: {
    humanName: 'Advanced Regents June 2019',
    date: 'June 2019',
    diploma: 'Advanced Regents',
  } as IGradPlanTransferValue,
  ADVANCED_REGENTS_AUG_2019: {
    humanName: 'Advanced Regents Aug 2019',
    date: 'Aug 2019',
    diploma: 'Advanced Regents',
  } as IGradPlanTransferValue,
  // 2020
  ADVANCED_REGENTS_JAN_2020: {
    humanName: 'Advanced Regents Jan 2020',
    date: 'Jan 2020',
    diploma: 'Advanced Regents',
  } as IGradPlanTransferValue,
  ADVANCED_REGENTS_MARCH_2020: {
    humanName: 'Advanced Regents March 2020',
    date: 'March 2020',
    diploma: 'Advanced Regents',
  } as IGradPlanTransferValue,
  ADVANCED_REGENTS_JUNE_2020: {
    humanName: 'Advanced Regents June 2020',
    date: 'June 2020',
    diploma: 'Advanced Regents',
  } as IGradPlanTransferValue,
  ADVANCED_REGENTS_AUG_2020: {
    humanName: 'Advanced Regents Aug 2020',
    date: 'Aug 2020',
    diploma: 'Advanced Regents',
  } as IGradPlanTransferValue,
  // 2021
  ADVANCED_REGENTS_JAN_2021: {
    humanName: 'Advanced Regents Jan 2021',
    date: 'Jan 2021',
    diploma: 'Advanced Regents',
  } as IGradPlanTransferValue,
  ADVANCED_REGENTS_MARCH_2021: {
    humanName: 'Advanced Regents March 2021',
    date: 'March 2021',
    diploma: 'Advanced Regents',
  } as IGradPlanTransferValue,
  ADVANCED_REGENTS_JUNE_2021: {
    humanName: 'Advanced Regents June 2021',
    date: 'June 2021',
    diploma: 'Advanced Regents',
  } as IGradPlanTransferValue,
  ADVANCED_REGENTS_AUG_2021: {
    humanName: 'Advanced Regents Aug 2021',
    date: 'Aug 2021',
    diploma: 'Advanced Regents',
  } as IGradPlanTransferValue,
  // 2022
  ADVANCED_REGENTS_JAN_2022: {
    humanName: 'Advanced Regents Jan 2022',
    date: 'Jan 2022',
    diploma: 'Advanced Regents',
  } as IGradPlanTransferValue,
  ADVANCED_REGENTS_MARCH_2022: {
    humanName: 'Advanced Regents March 2022',
    date: 'March 2022',
    diploma: 'Advanced Regents',
  } as IGradPlanTransferValue,
  ADVANCED_REGENTS_JUNE_2022: {
    humanName: 'Advanced Regents June 2022',
    date: 'June 2022',
    diploma: 'Advanced Regents',
  } as IGradPlanTransferValue,
  ADVANCED_REGENTS_AUG_2022: {
    humanName: 'Advanced Regents Aug 2022',
    date: 'Aug 2022',
    diploma: 'Advanced Regents',
  } as IGradPlanTransferValue,
  // 2023
  ADVANCED_REGENTS_JAN_2023: {
    humanName: 'Advanced Regents Jan 2023',
    date: 'Jan 2023',
    diploma: 'Advanced Regents',
  } as IGradPlanTransferValue,
  ADVANCED_REGENTS_MARCH_2023: {
    humanName: 'Advanced Regents March 2023',
    date: 'March 2023',
    diploma: 'Advanced Regents',
  } as IGradPlanTransferValue,
  ADVANCED_REGENTS_JUNE_2023: {
    humanName: 'Advanced Regents June 2023',
    date: 'June 2023',
    diploma: 'Advanced Regents',
  } as IGradPlanTransferValue,
  ADVANCED_REGENTS_AUG_2023: {
    humanName: 'Advanced Regents Aug 2023',
    date: 'Aug 2023',
    diploma: 'Advanced Regents',
  } as IGradPlanTransferValue,
  // 2024
  ADVANCED_REGENTS_JAN_2024: {
    humanName: 'Advanced Regents Jan 2024',
    date: 'Jan 2024',
    diploma: 'Advanced Regents',
  } as IGradPlanTransferValue,
  ADVANCED_REGENTS_MARCH_2024: {
    humanName: 'Advanced Regents March 2024',
    date: 'March 2024',
    diploma: 'Advanced Regents',
  } as IGradPlanTransferValue,
  ADVANCED_REGENTS_JUNE_2024: {
    humanName: 'Advanced Regents June 2024',
    date: 'June 2024',
    diploma: 'Advanced Regents',
  } as IGradPlanTransferValue,
  ADVANCED_REGENTS_AUG_2024: {
    humanName: 'Advanced Regents Aug 2024',
    date: 'Aug 2024',
    diploma: 'Advanced Regents',
  } as IGradPlanTransferValue,

  // 2025
  ADVANCED_REGENTS_JAN_2025: {
    humanName: 'Advanced Regents Jan 2025',
    date: 'Jan 2025',
    diploma: 'Advanced Regents',
  } as IGradPlanTransferValue,
  ADVANCED_REGENTS_MARCH_2025: {
    humanName: 'Advanced Regents March 2025',
    date: 'March 2025',
    diploma: 'Advanced Regents',
  } as IGradPlanTransferValue,
  ADVANCED_REGENTS_JUNE_2025: {
    humanName: 'Advanced Regents June 2025',
    date: 'June 2025',
    diploma: 'Advanced Regents',
  } as IGradPlanTransferValue,
  ADVANCED_REGENTS_AUG_2025: {
    humanName: 'Advanced Regents Aug 2025',
    date: 'Aug 2025',
    diploma: 'Advanced Regents',
  } as IGradPlanTransferValue,
  // 2026
  ADVANCED_REGENTS_JAN_2026: {
    humanName: 'Advanced Regents Jan 2026',
    date: 'Jan 2026',
    diploma: 'Advanced Regents',
  } as IGradPlanTransferValue,
  ADVANCED_REGENTS_MARCH_2026: {
    humanName: 'Advanced Regents March 2026',
    date: 'March 2026',
    diploma: 'Advanced Regents',
  } as IGradPlanTransferValue,
  ADVANCED_REGENTS_JUNE_2026: {
    humanName: 'Advanced Regents June 2026',
    date: 'June 2026',
    diploma: 'Advanced Regents',
  } as IGradPlanTransferValue,
  ADVANCED_REGENTS_AUG_2026: {
    humanName: 'Advanced Regents Aug 2026',
    date: 'Aug 2026',
    diploma: 'Advanced Regents',
  } as IGradPlanTransferValue,
  // 2027
  ADVANCED_REGENTS_JAN_2027: {
    humanName: 'Advanced Regents Jan 2027',
    date: 'Jan 2027',
    diploma: 'Advanced Regents',
  } as IGradPlanTransferValue,
  ADVANCED_REGENTS_MARCH_2027: {
    humanName: 'Advanced Regents March 2027',
    date: 'March 2027',
    diploma: 'Advanced Regents',
  } as IGradPlanTransferValue,
  ADVANCED_REGENTS_JUNE_2027: {
    humanName: 'Advanced Regents June 2027',
    date: 'June 2027',
    diploma: 'Advanced Regents',
  } as IGradPlanTransferValue,
  ADVANCED_REGENTS_AUG_2027: {
    humanName: 'Advanced Regents Aug 2027',
    date: 'Aug 2027',
    diploma: 'Advanced Regents',
  } as IGradPlanTransferValue,
  // 2028
  ADVANCED_REGENTS_JAN_2028: {
    humanName: 'Advanced Regents Jan 2028',
    date: 'Jan 2028',
    diploma: 'Advanced Regents',
  } as IGradPlanTransferValue,
  ADVANCED_REGENTS_MARCH_2028: {
    humanName: 'Advanced Regents March 2028',
    date: 'March 2028',
    diploma: 'Advanced Regents',
  } as IGradPlanTransferValue,
  ADVANCED_REGENTS_JUNE_2028: {
    humanName: 'Advanced Regents June 2028',
    date: 'June 2028',
    diploma: 'Advanced Regents',
  } as IGradPlanTransferValue,
  ADVANCED_REGENTS_AUG_2028: {
    humanName: 'Advanced Regents Aug 2028',
    date: 'Aug 2028',
    diploma: 'Advanced Regents',
  } as IGradPlanTransferValue,
};
