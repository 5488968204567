import { Component, EventEmitter, Inject, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  IMultiSelectFilterModalData,
  IMultiSelectFilterParentOption,
} from 'Src/ng2/network/network-mid-level/network-dashboard-mad-lib/network-dashboard-mad-lib.component';
import { BaseModalComponent } from '../base-modal.component';

@Component({
  selector: 'multi-select-filter-modal',
  templateUrl: './multi-select-filter-modal.component.html',
  styleUrls: ['./multi-select-filter-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MultiSelectFilterModalComponent extends BaseModalComponent implements OnInit {
  public modalTitle: string;
  public modalOptions: IMultiSelectFilterParentOption[];
  public filterPatch: Map<string, Array<string | number>>;
  public currentlySelected: any;
  public rangeFloor: number = 0;
  public rangeCeiling: number = 100;
  public isFormClean: boolean = true;
  public isFormValid: boolean = false;

  @Output() applyFilters: EventEmitter<any> = new EventEmitter();

  constructor (
    dialogRef: MatDialogRef<MultiSelectFilterModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IMultiSelectFilterModalData,
  ) {
    super(dialogRef);
    this.modalTitle = data.modalTitle;
    this.modalOptions = data.parents;
    this.currentlySelected = data.currentlySelected;
  }

  ngOnInit (): void {
    this.populateFilterPatch();
  }

  public populateFilterPatch (): void {
    this.filterPatch = new Map();
  }

  public onOptionChosen ($event: any): void {
    const { optionId, options, optionType, event, value, highValue } = $event;
    if (optionType === 'radio') {
      this.filterPatch.set(optionId, [event.value]);
    } else if (optionType === 'checkbox') {
      let chosenBoxes = options.filter((option: any) => option.chosen === true);
      chosenBoxes = chosenBoxes.map((option: any) => {
        return option.value;
      });
      this.filterPatch.set(optionId, chosenBoxes);
    } else if (optionType === 'range') {
      this.filterPatch.set(optionId, [value, highValue]);
    }
    this.isFormValid = this.checkValidForm();
    this.isFormClean = false;
  }

  private checkValidForm (): boolean {
    let isValid = true;
    this.filterPatch.forEach((value, key) => {
      const currentOptionType = this.checkOptionType(key);
      if (currentOptionType === 'checkbox') {
        if (value.length === 0) {
          isValid = false;
        }
      }
    });
    return isValid;
  }

  private checkOptionType (key: any): string {
    let optionType = '';
    this.modalOptions.forEach((option) => {
      if (key === option.id) {
        optionType = option.optionType;
      }
    });
    return optionType;
  }

  public emitFiltersConfirmation () {
    this.applyFilters.emit({ filters: this.filterPatch });
    this.dialogRef.close();
  }

  public buttonDisabled () {
    if (this.isFormClean) {
      return true;
    } else {
      if (this.isFormValid) {
        return false;
      } else {
        return true;
      }
    }
  }
}
