<base-modal class="base-modal" [title]="title" [isProfileMode]="isProfileMode">
  <dialog-content>
    <mat-table [dataSource]="summaryData" class="summary-table-modal">
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>{{ groupHumanName }}</mat-header-cell>
        <!-- the or op is temporary. This should be removed once all the lists are refactored-->
        <mat-cell *matCellDef="let group">{{ group.name || group.human }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="count" class="count-col">
        <mat-header-cell *matHeaderCellDef>{{countLabel}}</mat-header-cell>
        <!-- the or op is temporary. This should be removed once all the lists are refactored-->
        <mat-cell *matCellDef="let group">{{ group.count || group.rowData.length }}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
      <mat-row *matRowDef="let group; columns: tableColumns; let index = index" (click)="onRowClick(index)"></mat-row>
    </mat-table>

    <mat-paginator [pageSize]="itemsPerPage" hidePageSize (page)="pageEvent = $event"> </mat-paginator>
  </dialog-content>
</base-modal>
