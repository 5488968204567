<form [formGroup]="manualCoursePlanForm" autocomplete="off">
  <!-- TERM SELECTOR DROPDOWN -->
  <nv-form-field label="Select term">
    <nv-dropdown
      id="term"
      placeholder="Select Term"
      [options]="allTermYears"
      [selected]="manualCoursePlanForm.controls.termYear.value"
      (selectOption)="setTermYear($event)"
    ></nv-dropdown>
  </nv-form-field>
  
  <!-- CREDIT REQUIREMENT AREA DROPDOWN -->
  <nv-form-field label="Select credit requirement area">
    <nv-dropdown
      placeholder="Select Credit Area"
      id="subjectArea"
      [options]="creditReqs"
      [selected]="manualCoursePlanForm.controls.gradReq.value"
      (selectOption)="setGradReq($event)"
    ></nv-dropdown>
  </nv-form-field>

  <!-- CREDIT VALUE TEXTBOX -->
  <nv-form-field
    label="Credit value"
    [isInvalid]="manualCoursePlanForm.controls.creditValue.invalid"
    [showInvalid]="manualCoursePlanForm.controls.creditValue.invalid && !manualCoursePlanForm.controls.creditValue.pristine"
    >
    <nv-textbox
      id="creditValueStyle"
      [textControl]="manualCoursePlanForm.controls.creditValue"
      placeholder="Enter credit value"
      [hasClearBtn]="true"
      (clearValue)="clearSelection('creditValue')"
      [showInvalid]="true"
      [showInvalidOnPristine]="false"
    >
    </nv-textbox>
    <!-- ERROR MESSAGE: for credit value input -->
      <ng-container *ngIf="manualCoursePlanForm.controls['creditValue'].invalid && !manualCoursePlanForm.controls['creditValue'].pristine">
        <nv-note class="credit-value-hint" size="small" priority="danger"
          >Value must be between 0 and 5</nv-note>
      </ng-container>
  </nv-form-field>

  <!-- COURSE CODE (OPTIONAL)-->
  <nv-form-field label="Course code (optional)">
    <nv-textbox
      id="courseCodeStyle"
      [textControl]="manualCoursePlanForm.controls.courseCode"
      placeholder="Enter course code"
      [hasClearBtn]="true"
      (clearValue)="clearSelection('courseCode')"
      (click)="markAsTouched(manualCoursePlanForm.get('courseCode'))"
    >
    </nv-textbox>
    <!-- ERROR MESSAGE: for course code input; only shown in NYC -->
    <ng-container *ngIf="this.isNYC && !hideErrorMessage">
      <nv-note class="credit-value-hint" size="small" priority="danger">
        Must be a valid course code. Uppercase letters only. <a href="{{ codeDeckUrl }}" target="_blank">Click here</a> to view code deck.
      </nv-note>  
    </ng-container>
  </nv-form-field>

  <!-- NOTE (OPTIONAL) -->
  <nv-form-field label="Note (optional)">
    <nv-textarea
      id="notesStyle"
      [formControl]="manualCoursePlanForm.controls.note"
      [maxCharacters]="150"
      placeholder="Enter note for plan added."
    ></nv-textarea>
  </nv-form-field>

  <mat-dialog-actions class="course-plans-actions">
    <nv-button type="secondary" (clickButton)="close()">
      Cancel
    </nv-button>
    <nv-button
      [disabled]="isButtonDisabled"
      (clickButton)="onApply()"
    >
      Assign
    </nv-button>
  </mat-dialog-actions>
</form>