import { Injectable } from '@angular/core';
import { reduce } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IListViewData } from '../../../../typings/interfaces/list-view.interface';
import { IDashboardStudent } from './../../../../../store/effects/projections/dashboard-students';
import { COLUMN_DATA_TYPE } from './../../../../constants/list-view/cell-type.constant';

type TValidModes = 'SUBJECT' | 'COURSE' | 'TEACHER';

interface ICourseCodeConfig {
  listType: any;
  columns: any[];
  predicateValue?: string;
  mode?: 'TEACHER' | 'COURSE' | 'SUBJECT';
}

interface ICourse {
  pf: 'P' | 'F';
  code: string;
  section: string;
  mostRecent: boolean;
  subj: string;
  teacher: { nickName: string };
}

@Injectable()
export class CourseCodeGrouping {
  public getGrouping(filteredStudents$: Observable<IDashboardStudent[]>, config: ICourseCodeConfig): Observable<any> {
    const { mode, predicateValue } = config;

    return filteredStudents$.pipe(
      map((students: IDashboardStudent[]) => {
        const formattedList: IListViewData = this.getFormattedListShell(config);
        const studentFailsByCourse = this.getFailedStudentsByCourseCode(students, { mode, predicateValue });
        const args = { studentFailsByCourse, formattedList, ...config };
        const grouping = this.generateGrouping(args);
        return grouping;
      }),
    );
  }

  private getFormattedListShell(args: { columns: any[]; listType: any }): IListViewData {
    const { columns, listType } = args;
    return {
      listType,
      columns: this.formatColumnsForList(columns),
      sections: [],
    };
  }

  private formatColumnsForList(columns: any[]) {
    const formattedColumns = columns.reduce((formattedList, col) => {
      if (col.cellType !== COLUMN_DATA_TYPE.SECTION_HEADER) {
        formattedList[col.key] = {
          name: col.human,
          cellType: col.cellType,
          cellConfig: col.cellConfig,
          dataType: col.dataType,
          orderBy: col.orderBy,
          tooltip: col.tooltip,
        };
      }
      return formattedList;
    }, {});
    return formattedColumns;
  }

  private getFailedStudentsByCourseCode(
    students: IDashboardStudent[],
    config: { mode: string; predicateValue: string },
  ): { [key: string]: any[] } {
    return students.reduce((courseData, student: IDashboardStudent) => {
      const {
        currProgram: { grades },
      } = student;

      grades.forEach((grade: ICourse) => {
        // only push students enrolled and failing a course aligned to the predicateValue/mode
        const isValidCourse = this.validateCourse(grade, config);
        if (!isValidCourse) return;

        const { pf, code, section, mostRecent, subj } = grade;
        const codeSectionCombo = `${code}-${section}`;
        const isFailingRecentCourse = pf === 'F' && mostRecent;

        if (isFailingRecentCourse) {
          const hasSectionBeenCreated = !!courseData[codeSectionCombo];
          // configs we pass here are needed for the differnt column calculations
          if (!hasSectionBeenCreated) courseData[codeSectionCombo] = { students: [], config: { subject: subj } };
          courseData[codeSectionCombo].students.push(student);
        }
      });
      return courseData;
    }, {});
  }

  private generateGrouping(args) {
    const { studentFailsByCourse, columns, formattedList } = args;

    return reduce(
      studentFailsByCourse,
      (formattedList, data: any, code) => {
        const {
          students,
          config: { subject },
        } = data;
        const section = {
          name: code,
          count: 0,
          data: [],
          defaultDisplayCount: 10,
        };
        // column calc needs the courseCode minus the section
        const courseCode = code.split('-')[0];
        const config = { courseCode, subject };

        students.forEach(student => {
          const studentRow = columns.reduce((result, { key, getValueAtPath }) => {
            result[key] = getValueAtPath(student, config);
            return result;
          }, {});
          // construct section
          section.data.push(studentRow);
          section.count++;
        });
        // push to formattedList
        formattedList.sections.push(section);
        return formattedList;
      },
      formattedList,
    );
  }

  private validateCourse(course: ICourse, config: { mode: string; predicateValue: string }): boolean {
    const {
      code,
      subj,
      teacher: { nickName },
    } = course;
    const { predicateValue, mode } = config;
    switch (mode) {
      case 'COURSE':
        return this.checkCourseAlignment(code, predicateValue);
      case 'SUBJECT':
        return this.checkCourseAlignment(subj, predicateValue);
      case 'TEACHER':
        return this.checkCourseAlignment(nickName, predicateValue);
      default:
        return false;
    }
  }

  private checkCourseAlignment(val1: string, val2: string): boolean {
    return val1 === val2;
  }
}
