import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'button-group',
  templateUrl: 'button-group.component.html',
})

export class ButtonGroupComponent {
  @Input() isSaveDisabled: boolean;

  @Output() cancel = new EventEmitter();
  @Output() save = new EventEmitter();

  constructor () { };

  onCancel (): void {
    this.cancel.emit();
  }

  onSave ($event): void {
    this.save.emit($event);
  }
}
