import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

import {
  CREATE_ATTENDANCE_RECORD_SUCCESS,
  UPDATE_ATTENDANCE_RECORD_SUCCESS,
} from './../../../store/actions/attendance-records-actions';
import { ofType } from '@ngrx/effects';
import { IDropdownOption } from 'projects/shared/nvps-libraries/design/interfaces/design-library.interface';
import {
  TSortDirections,
  Direction,
  SortAndFilterService,
} from 'Src/ng2/shared/services/list-services/sort-and-filter.service';
import { IAttendanceRecord } from 'Src/ng2/shared/typings/interfaces/attendance-record.interface';
import { IListData } from './../../../shell/content-tools/content-tools.component';
import { UtilitiesService } from 'Src/ng2/shared/services/utilities/utilities.service';
import {
  Component,
  ComponentFactoryResolver,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store, ActionsSubject } from '@ngrx/store';
import { BehaviorSubject, Observable, throwError, Unsubscribable, of } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { FixedToInfiniteViewComponent } from 'Src/ng2/shared/components/list/fixed-to-infinite-view-container/fixed-to-infinite-view.component';
import { IRowData, IGroupData } from 'Src/ng2/shared/models/list-models';
import { ObjectCache } from 'Src/ng2/shared/services/object-cache/object-cache.service';
import { IListConfig } from '../../../shared/models/list-models';
import {
  BackgroundJobNotificationService,
  TValidBackgroundJob,
} from '../../../shared/services/background-job-notification-service/background-job-notification-service';
import { ToggleBatchActions, UpdateSelectedStudentIds } from '../../../store/actions/batch-actions-actions';
import { IFocus } from '../academic-list-v2/academic-list-data/academic-data.service';
import { BatchActionsService } from '../services/batch-actions/batch-actions.service';
import { MadlibService } from '../services/madlib/madlib.service';
import { getBatchActionsSelectedStudentIds } from './../../../store/selectors/batch-actions-selectors';
import { SupportAttendanceFormDataService } from './support-attendance-form-list-v2-data/support-attendance-form-list-v2-data.service';

@Component({
  selector: 'support-attendance-form-list-v2',
  templateUrl: './support-attendance-form-list-v2.component.html',
  styleUrls: ['./support-attendance-form-list-v2.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
// @ts-ignore
export class SupportAttendanceFormListV2Component extends FixedToInfiniteViewComponent implements OnInit {
  public fociData$: Observable<any>;
  public batchActionsHash: any;
  public madlibModel: any;
  public dataSource$: Observable<any>;
  public foci: IFocus;
  public fociByCategory: Array<{ categoryLabel: string; foci: IFocus[] }>;
  public activeList: 'INFINITE' | 'FIXED';

  // The background jobs does this view need to refetch on?
  public backgroundJobDependencies: TValidBackgroundJob[] = [
    'BulkAttendanceRecordsCreate',
    'BulkAttendanceRecordsUpdate',
    'BulkStudentSupportUpdate',
    'SupportMiniSchemaUpdate',
  ];

  // The store effects this view needs to refetch on
  public storeEffectDependencies: any[] = [CREATE_ATTENDANCE_RECORD_SUCCESS, UPDATE_ATTENDANCE_RECORD_SUCCESS];

  public listData: IListData;

  public pageHeaderMeta: any = {
    title: '',
    subTitle: null,
    icon: {},
  };

  // BATCH EDIT TOOL BAR
  public batchEditOptions: IDropdownOption[] = [
    {
      key: 'DELETED',
      human: '-',
    },
    {
      key: 'PRESENT',
      human: 'Mark present',
    },
    {
      key: 'LATE',
      human: 'Mark late',
    },
    {
      key: 'ABSENT',
      human: 'Mark absent',
    },
    {
      key: 'EXCUSED',
      human: 'Mark excused',
    },
  ];

  // SuperProps
  public onFixed: Function;
  public onInfinite: Function = (instance: string) => instance; // need to define default function
  public onRowClick: Function;
  public onBatchAction: Function;
  public onDynamicComponentClick: Function;
  public columns;
  public dynamicColumns = new BehaviorSubject(null);
  public dynamicColumnIndexMap = new BehaviorSubject(null);
  public columnIndexMap: { [columnKey: string]: number };
  public groupingData$ = new BehaviorSubject(null);

  public filterFormControl: FormControl;
  public sortKey$: BehaviorSubject<string>;
  public sortDirection$: BehaviorSubject<TSortDirections>;
  public updateSort: Function = () => null;

  public listConfig: IListConfig = {
    listType: 'STUDENT',
    noDataMessage: 'No students to display',
    sortableColumns: true,
    allowEmptyTable: false,
  };

  public noDataMessage: any;
  public onUiRowDataUpdate: Function;
  public uiRowData: any;
  public dynamicComponentInputData: any;

  // SubSinks
  public notificationServiceSub: Unsubscribable;
  public batchActionsModeSubscription: Unsubscribable;
  public batchActionsSelectedIds$: Observable<string[]> = null;
  public batchActionsSelectedIdsSub: Unsubscribable;
  public getFociDataSub: Unsubscribable;
  public actionsSubscription: Unsubscribable;
  // NOTE: This component is required to implement every abstract member defined on super.
  // but for support settings list, we don't do anything with batchActions related code.
  // until a more ideal solution is determined, need to keep these two defined

  // Bindings
  @Input() schoolId: string;
  @Input() batchActionsMode$: Observable<any>;
  @Input() supportId: string;
  @Input() eventDate: string;

  // Refs
  @ViewChild('entry', { read: ViewContainerRef, static: false }) entry: ViewContainerRef;
  @ViewChild('listContent', { static: false }) listContent: ElementRef;

  @Output() goBackToSessionList = new EventEmitter();

  // Used for bulk updated student attendance
  public payload: any = {};
  public attendanceRecord: any = {};
  public updateSingleAttendanceRecordSub: Observable<any>;
  public createSingleAttendanceRecordSub: Observable<any>;

  // DI
  constructor (
    public resolver: ComponentFactoryResolver,
    public madlibService: MadlibService,
    public objectCache: ObjectCache,
    public notificationService: BackgroundJobNotificationService,
    public supportAttendanceFormDataService: SupportAttendanceFormDataService,
    private store: Store<any>,
    private batchActionsService: BatchActionsService,
    private utilitiesService: UtilitiesService,
    private actionsSubject: ActionsSubject,
    private route: ActivatedRoute,
  ) {
    // We are extending the fixed-to-infinite functionality
    super(resolver, batchActionsService, store);
  }

  ngOnInit (): void {
    // Clear the cache on initialization. User *may* have changed schools.
    this.supportAttendanceFormDataService.clearFociCache();
    // Set page header meta ata
    this.setPageHeaderMeta();
    // Set initial state
    this.filterFormControl = new FormControl();
    this.sortKey$ = new BehaviorSubject('SUPPORT_SESSION_DATE');
    this.sortDirection$ = new BehaviorSubject<TSortDirections>(Direction.asc);
    // Super props
    this.updateSort = (sortKey: string) => {
      SortAndFilterService.updateSortCol(sortKey, this.sortKey$, this.sortDirection$);

      const studentIds = this.batchActionsHash.getStudentIds({ sortKey: this.sortKey$.value, sortDirection: this.sortDirection$.value });
      this.store.dispatch(new UpdateSelectedStudentIds(studentIds));
    };
    // Overwrite super methods -- how we respond to user events [batchActions & rowClick]
    this.onBatchAction = this.formatAndFireBatchActionEvt;
    this.onUiRowDataUpdate = ($event: IGroupData) => {
      const groupings = Object.values($event);
      this.uiRowData = groupings;
    };
    this.onDynamicComponentClick = this.statusDropdownHandler;
    // Listen for completed background jobs and refetch data as needed
    this.notificationServiceSub = this.notificationService
      .getMessage()
      .pipe(
        filter(({ backgroundJob }) => this.backgroundJobDependencies.includes(backgroundJob)),
        tap(() => this.initializeAttendanceLogsFormList(this.schoolId, this.route.snapshot.queryParams, false)),
      )
      .subscribe();
    this.batchActionsHash = this.batchActionsService.initializeStudentBatchActionsHash();
    this.batchActionsModeSubscription = this.batchActionsMode$
      .pipe(tap((mode: boolean) => (!mode ? this.resetBatchActions() : null)))
      .subscribe();
    this.batchActionsSelectedIds$ = this.store.select(getBatchActionsSelectedStudentIds);
    // create base object for bulk creating records
    this.setBaseAttendanceRecordForBulkActions();
    // Kick off data pipeline `getFoci$---generateMadlibModel---getGrouping$---createListContainer--|`

    this.actionsSubscription = this.actionsSubject
      .pipe(ofType(CREATE_ATTENDANCE_RECORD_SUCCESS, UPDATE_ATTENDANCE_RECORD_SUCCESS))
      .subscribe(
        (data): any => {
          // @ts-ignore
          if (this.storeEffectDependencies.includes(data.type)) {
            this.initializeAttendanceLogsFormList(this.schoolId, this.route.snapshot.queryParams, false);
          }
        },
        err => throwError(err),
      );
    this.initializeAttendanceLogsFormList(this.schoolId, this.route.snapshot.queryParams, true);
  }

  ngOnDestroy (): void {
    if (this.notificationServiceSub) this.notificationServiceSub.unsubscribe();
    if (this.actionsSubscription) this.actionsSubscription.unsubscribe();
    if (this.batchActionsModeSubscription) this.batchActionsModeSubscription.unsubscribe();
    if (this.getFociDataSub) this.getFociDataSub.unsubscribe();
    if (this.batchActionsSelectedIdsSub) this.batchActionsSelectedIdsSub.unsubscribe();
    if (this.batchActionsModeSubscription) this.batchActionsModeSubscription.unsubscribe();
  }

  // getFocusData---createMadlibModel---getGroupingData---createListContainer--|
  initializeAttendanceLogsFormList (schoolId: string, params, loadContainer: boolean): void {
    this.getFociDataSub = this.getFociData$(schoolId)
      .pipe(
        // Format and set the partials to fit the nv-dropdown
        tap(fociPartials => (this.fociByCategory = this.madlibService.getFormattedFoci(fociPartials))),
        // Once we know the default focus, fetch complete data to populate corresponding filter and grouping options
        switchMap(() => this.getFociData$(schoolId)),
        switchMap(([completeFocusData]) =>
          of(
            this.madlibService.setMadlibModel(
              this.madlibService.formatFocus(completeFocusData),
              completeFocusData.filters,
            ),
          ),
        ),
        // Set madlib model with focus containing all data
        tap(madlibModel => (this.madlibModel = madlibModel)),
        switchMap(() => this.getSupportGroupingData$()),
        // Set side-bar summary/csv-export etc.
        tap(groupingData => {
          this.updateGroupingDependentProps(groupingData);
          const $event = {
            groupData: this.groupingData$.value[0],
            sortKey: null,
            sortDirection: null,
          };
          if (loadContainer) this.createInfiniteStudentTableComponent($event);
        }),
      )
      .subscribe();
  }

  getSupportGroupingData$ () {
    const groupingPayload: any = this.madlibService.getGroupingDataPayload(this.schoolId, this.madlibModel.value);
    groupingPayload.baseCollectionName = 'students';
    groupingPayload.id = `${this.supportId}`;
    const isoEventDate = moment(this.eventDate).format('YYYY-MM-DD');
    return this.supportAttendanceFormDataService.getSupportGroupingData$({ ...groupingPayload, schoolId: this.schoolId }, isoEventDate).pipe(
      switchMap(({ data: { SupportGroupings } }) => {
        return of(SupportGroupings);
      }),
    );
  }

  private setPageHeaderMeta () {
    this.pageHeaderMeta.title = this.utilitiesService.getDateWithoutWeekday(this.eventDate, { formatMonth: 'long' });
    this.pageHeaderMeta.icon = this.getGoBackIcon();
  }

  getFociData$ (schoolId: string): Observable<any> {
    return this.supportAttendanceFormDataService.getSupportFocusData$(schoolId, 'SUPPORT_ATTENDANCE_FORM').pipe(
      switchMap(({ data: { SupportFocus } }) => {
        return of(SupportFocus);
      }),
      take(1),
    );
  }

  resetBatchActions (): void {
    this.batchActionsHash.reset();
    this.store.dispatch(new UpdateSelectedStudentIds([]));
    this.store.dispatch(new ToggleBatchActions(false));
  }

  updateGroupingDependentProps (groupings): void {
    // Update columns and mapping
    const { columns, columnIndexMap } = this.madlibService.getColumnDataBasedOnMadlibModel(this.madlibModel);
    this.dynamicColumns.next(columns);
    this.dynamicColumnIndexMap.next(columnIndexMap);
    // Update all fields dependent on the new grouping data
    this.groupingData$.next(groupings);
  }

  checkIsFormDirty () {
    // Check if there are checked students
    let isFormDirty = false;
    this.batchActionsSelectedIdsSub = this.batchActionsSelectedIds$
      .pipe(
        tap(batchEditIds => {
          if (batchEditIds && batchEditIds.length) isFormDirty = true;
        }),
        take(1),
      )
      .subscribe();
    return isFormDirty;
  }

  getGoBackIcon (): { name: string; tooltip: string; handler: Function } {
    return {
      name: 'arrow-left-default',
      tooltip: 'Go back to Attendance Logs',
      handler: () => {
        const isFormDirty = false;
        this.goBackToSessionList.emit(isFormDirty);
      },
    };
  }

  private onUncheckAll (): void {
    this.batchActionsHash.reset();
    this.store.dispatch(new UpdateSelectedStudentIds([]));
  }

  onBatchEditAttendanceStatus (status: IAttendanceRecord['status']) {
    this.batchActionsSelectedIds$
      .pipe(
        tap(batchEditIds => {
          if (batchEditIds.length) {
            const batchEditIdsMap = this._hashIdsToMove(batchEditIds);
            const groupData = this.groupingData$.value[0].rowData;
            const payload = groupData.reduce(
              (acc, row) => {
                const { data, studentIds = {}, attendanceRecordIds = [] } = JSON.parse(row[0].meta);
                const isSelected = batchEditIdsMap[data];
                if (isSelected) {
                  acc.studentIds = { ...acc.studentIds, ...studentIds };
                  acc.attendanceRecordIds = [...acc.attendanceRecordIds, ...attendanceRecordIds];
                }
                return acc;
              },
              { studentIds: {}, attendanceRecordIds: [] },
            );
            this.payload = { [status]: payload };
            this.executeBatchAction();
          }
        }),
        take(1),
      )
      .subscribe();
  }

  private _hashIdsToMove (idsToMove) {
    return idsToMove.reduce((acc, id) => {
      const _id = `${id}${this.schoolId}`;
      acc[_id] = true;
      return acc;
    }, {});
  }

  statusDropdownHandler ({ action, meta }) {
    // handler fires immediately due to being bound to super. Args
    // don't have the shape required at that point. Prevent execution.
    if (!action || !meta) return;
    const { studentIds = {}, attendanceRecordIds = [], data: studentId } = meta;
    if (Object.keys(studentIds).length) {
      const { studentSupportId, attendanceRecordId: _id } = studentIds[studentId];
      this.supportAttendanceFormDataService.createSingleAttendanceRecord({
        attendanceRecord: {
          _id,
          studentId,
          studentSupportId,
          status: action,
          ...this.attendanceRecord,
        },
        schoolId: this.schoolId,
      });
    }
    if (attendanceRecordIds.length) {
      this.supportAttendanceFormDataService.updateSingleAttendanceRecord(attendanceRecordIds[0], { status: action });
    }
  }

  private resetPayload () {
    this.payload = {};
  }

  private setBaseAttendanceRecordForBulkActions () {
    this.attendanceRecord = {
      schoolId: this.schoolId,
      supportId: this.supportId,
      eventDate: this.eventDate,
    };
  }

  executeBatchAction (): void {
    this.supportAttendanceFormDataService.markAttendanceRecordStatus(this.payload, this.attendanceRecord);
    this.onUncheckAll();
    this.resetPayload();
  }
}
