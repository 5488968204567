import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { IFlag } from '../../shared/typings/interfaces/flags.interface';
import * as flagActions from '../actions/flag-actions';
import { ApiService } from './../../shared/services/api-service/api-service';
import { IUpdatePayload } from './../actions/flag-actions';

interface IAction {
  type: string;
  payload: IFlag;
}

interface IUpdateAction {
  type: string;
  payload: IUpdatePayload<IFlag>;
}

@Injectable()
export class FlagEffects {
  constructor(private actions$: Actions, private apiService: ApiService, private store$: Store<any>) {}

  loadFlags$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(flagActions.LOAD_FLAGS),
      switchMap((action: IAction) => {
        const { schoolId } = action.payload;
        return this.apiService.getFlags(schoolId).pipe(
          mergeMap((flags: IFlag[]) => {
            return [new flagActions.LoadFlagsSuccess(flags)];
          }),
          catchError(error => {
            return of(new flagActions.LoadFlagsFail(error));
          }),
        );
      }),
    );
  });

  updateFlag$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(flagActions.UPDATE_FLAG),
      switchMap((action: IUpdateAction) => {
        const { id, patch } = action.payload;
        return this.apiService.patchFlag(id, patch).pipe(
          switchMap((flag: IFlag) => {
            return [new flagActions.UpdateFlagSuccess(flag)];
          }),
          catchError(error => {
            return of(new flagActions.UpdateFlagFail(error));
          }),
        );
      }),
    );
  });
}
