import { Action } from '@ngrx/store';
import { TBatchActionsOrigin } from 'Src/ng2/shared/components/nv-actions/nv-actions.interface';
import { IGapPlan } from 'Src/ng2/shared/typings/interfaces/gap-plan.interface';

export const CREATE_GAP_PLANS = '[Portal] Create Gap Plans';
export const CREATE_GAP_PLANS_SUCCESS = '[Portal] Create Gap Plans Success';
export const CREATE_GAP_PLANS_FAILURE = '[Portal] Create Gap Plans Failure';
export const BULK_CREATE_GAP_PLANS = '[Portal] Bulk Create Gap Plans';
export const BULK_CREATE_GAP_PLANS_SUCCESS = '[Portal] Bulk Create Gap Plans Success';
export const BULK_CREATE_GAP_PLANS_FAILURE = '[Portal] Bulk Create Gap Plans Failure';

export interface IBulkCreateGapPlans {
  gapPlans: IGapPlan;
  studentIds: string[];
  origin?: TBatchActionsOrigin;
}

// register store actions

export class CreateGapPlans implements Action {
  readonly type = CREATE_GAP_PLANS;
  constructor (
    public payload: { gapPlans: IGapPlan; studentId: string; schoolId: string },
  ) { }
}

export class CreateGapPlansSuccess implements Action {
  readonly type = CREATE_GAP_PLANS_SUCCESS;
  constructor (public payload: IGapPlan) { }
}

export class CreateGapPlansFailure implements Action {
  readonly type = CREATE_GAP_PLANS_FAILURE;
  constructor (public payload: any) { }
}

export class BulkCreateGapPlans implements Action {
  readonly type = BULK_CREATE_GAP_PLANS;
  constructor (public payload: IBulkCreateGapPlans) { }
}

export class BulkCreateGapPlansSuccess implements Action {
  readonly type = BULK_CREATE_GAP_PLANS_SUCCESS;
  constructor (public payload: any) { }
}

export class BulkCreateGapPlansFail implements Action {
  readonly type = BULK_CREATE_GAP_PLANS_FAILURE;
  constructor (public payload: any) { }
}

// action types
export type GapPlansAction =
  | CreateGapPlans
  | CreateGapPlansSuccess
  | CreateGapPlansFailure
  | BulkCreateGapPlans
  | BulkCreateGapPlansSuccess
  | BulkCreateGapPlansFail;
