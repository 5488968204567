// used by list display component & services to process column data type
// with a gradient background
export enum GRADIENT_TYPE {
  ATTENDANCE_TODAY,
  ATTENDANCE_YEARLY_CHANGE,
  ATTENDANCE_THIS_TERM,
  ACADEMIC_IREADY,
  ACADEMIC_GROWTH,
  ACADEMIC_FAILING,
}
