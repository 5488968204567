import { reduce } from 'lodash';
import { IStudentAssessment } from '../../../shared/typings/interfaces/student-assessment.interface';
import * as studentAssessmentsActions from './../../actions/assessments-actions/student-assessments-actions';

export interface IStudentAssessmentsState {
  loading: boolean;
  loaded: boolean;
  studentAssessmentsEntities: {
    [id: string]: IStudentAssessment;
  };
}

export const initialState: IStudentAssessmentsState = {
  loading: false,
  loaded: false,
  studentAssessmentsEntities: {},
};

export function reducer(
  state: IStudentAssessmentsState = initialState,
  action: studentAssessmentsActions.StudentAssessmentsAction,
): IStudentAssessmentsState {
  let studentAssessment;
  let studentAssessmentsEntities;
  switch (action.type) {
    case studentAssessmentsActions.LOAD_STUDENT_ASSESSMENTS:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case studentAssessmentsActions.LOAD_STUDENT_ASSESSMENTS_SUCCESS:
      const studentAssessments = action.payload.data.StudentAssessments;
      const loadedStudentAssessments: IStudentAssessmentsState['studentAssessmentsEntities'] = reduce(
        studentAssessments,
        (acc, studentAssessment: IStudentAssessment) => {
          acc[studentAssessment._id] = studentAssessment;

          return acc;
        },
        {},
      );
      const mergedStudentAssessments = { ...state.studentAssessmentsEntities, ...loadedStudentAssessments };
      return {
        ...state,
        loading: false,
        loaded: true,
        studentAssessmentsEntities: mergedStudentAssessments,
      };
    case studentAssessmentsActions.LOAD_STUDENT_ASSESSMENTS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case studentAssessmentsActions.UPDATE_STUDENT_ASSESSMENT_SUCCESS:
      const { itemId, patch } = action.payload;
      studentAssessmentsEntities = Object.assign({}, state.studentAssessmentsEntities);
      if (studentAssessmentsEntities[itemId]) {
        studentAssessment = Object.assign(studentAssessmentsEntities[itemId], patch);
        studentAssessmentsEntities[studentAssessment._id] = studentAssessment;
      }
      return {
        ...state,
        studentAssessmentsEntities,
      };
    default:
      return state;
  }
}
