import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { BatchEditService } from '../../services/batch-edit-service/batch-edit-service';
import { BaseModalModule } from '../base-modal.module';
import { BatchEditModalComponent } from './batch-edit-modal.component';
import { BatchEditModalInputComponent } from './input/batch-edit-modal-input.component';
import { NvIconModule } from 'projects/shared/nvps-libraries/design/nv-icon/nv-icon.module';
import { NvButtonModule } from 'projects/shared/nvps-libraries/design/nv-button/nv-button.module';
import { NvIconButtonModule } from '../../../../nvps-libraries/design/nv-icon-button/nv-icon-button.module';
import { NvTextboxModule } from '../../../../nvps-libraries/design/nv-textbox/nv-textbox.module';
import { NvFormFieldModule } from '../../../../nvps-libraries/design/nv-form-field/nv-form-field.module';
import { NvDropdownModule } from '../../../../nvps-libraries/design/nv-dropdown/nv-dropdown.module';

@NgModule({
  declarations: [BatchEditModalComponent, BatchEditModalInputComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatSelectModule,
    MatRadioModule,
    MatDialogModule,
    BaseModalModule,
    NvButtonModule,
    NvIconModule,
    NvIconButtonModule,
    NvTextboxModule,
    NvFormFieldModule,
    NvDropdownModule,
  ],
  exports: [BatchEditModalComponent, BatchEditModalInputComponent],
  providers: [BatchEditService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BatchEditModule {}
