import { ApiService } from 'Src/ng2/shared/services/api-service/api-service';
import { getSchool } from 'Src/ng2/store';
import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { every, filter, find, forEach, includes, isEqual, map, reduce, some, sortBy } from 'lodash';
import { Subject, Observable } from 'rxjs';
import { tap, switchMap, take, catchError } from 'rxjs/operators';
import { ICollegePath } from 'Src/ng2/shared/typings/interfaces/paths.interface';
import { IACOption } from 'projects/shared/nvps-libraries/design/nv-textbox/nv-textbox.interface';
import { IStudentIdCollegeMap } from '../../../../school/data-uploads/college-list/college-list-upload.component';
import {
  ICollegeNamePendingGrouping,
  IStudentIdPendingGrouping,
} from '../../../../school/data-uploads/college-list/college-list-upload.interface';
import { TValidStudentPathStatuses } from '../../../../shared/typings/types/student-path.types';

const STUDENTS_PROJECTION = {
  _id: true,
  studentId: true,
  'studentDetails.name': true,
  'studentDetails.classOf': true,
};

interface ICsvModalStudent {
  _id: string;
  studentId: string;
  studentDetails: {
    name: { first: string; last: string };
    classOf: string;
  };
}

interface ICsvFixIdRow {
  name: string;
  id: string;
  fixedId?: string;
  key: string;
}

interface ICsvFixCollegeNameRow {
  studentIds: { entered: string; full: string }[];
  collegeName: string;
  fixedCollegeName?: string;
}

@Component({
  templateUrl: './csv-upload-fix-errors-modal.component.html',
  styleUrls: ['./csv-upload-fix-errors-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CsvUploadFixErrorsModalComponent implements OnInit, OnDestroy {
  displayedColumns: ['studentName', 'studentId', 'search'];
  studentIdTableData: ICsvFixIdRow[];
  collegeNameTableData: ICsvFixCollegeNameRow[];
  findStudentPlaceholder = 'Find a student...';
  findCollegeNamePlaceholder = 'Find a college...';
  studentIdSelectionsForm: FormGroup;
  selectedStudentIds: { [key: string]: string } = {};
  selectedCollegeNames: { [key: string]: ICollegePath } = {};
  collegeNameSelectionsForm: FormGroup;

  studentModalVerbiage: string =
    'Please use the fields below to verify student IDs that do not match the Portal. If you proceed without verifying, data for these students will not be uploaded.';

  collegeModalVerbiage: string =
    'Please use the fields below to verify colleges that do not match the Portal. If you proceed without verifying, data for these colleges will not be uploaded.';

  students: IACOption[];
  matchedStudents: IACOption[];
  filteredStudents: IACOption[];

  matchedColleges: IACOption[];
  filteredColleges: IACOption[];
  colleges: IACOption[];

  destroy$: Subject<boolean> = new Subject<boolean>();
  errorStudentIds: string[];
  errorCollegeNames: string[];
  validatedStudentIds: string[] = [];
  validatedCollegeNames: string[];
  studentIdCollegeMap: IStudentIdCollegeMap;
  showShadow: boolean;
  moreOptionsText: string;
  emptyStateText: string;
  showStudentIdTable: boolean;
  showCollegeNameTable: boolean;
  modalTitle: string;

  constructor (
    private dialogRef: MatDialogRef<CsvUploadFixErrorsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<any>,
    private apiService: ApiService,
  ) {}

  get verifyStudentActionButtonText (): string {
    return some(this.selectedStudentIds, id => id !== null) ? 'Next' : 'Skip';
  }

  get verifyCollegeActionButtonText (): string {
    return some(this.selectedCollegeNames, id => id !== null) ? 'Next' : 'Skip';
  }

  get calculateUnverifiedIdCount (): number {
    return filter(this.selectedStudentIds, id => id === null).length;
  }

  get calculateUnverifiedCollegeCount (): number {
    return this.collegeNameTableData.length;
  }

  /* istanbul ignore next */
  ngOnInit () {
    this.dialogRef.disableClose = true;
    const { pending, studentIdCollegeMap } = this.data;
    this.errorStudentIds = Object.keys(pending.studentId);
    this.errorCollegeNames = Object.keys(pending.collegeName).map(name => name.toLowerCase().trim());

    this.studentIdCollegeMap = studentIdCollegeMap;

    this.selectedStudentIds = reduce(
      this.errorStudentIds,
      (res, id) => {
        res[id] = null;
        return res;
      },
      {},
    );

    this.selectedCollegeNames = reduce(
      this.errorCollegeNames,
      (res, id) => {
        const parsedId = id.toLowerCase().trim();
        res[parsedId] = null;
        return res;
      },
      {},
    );

    this.studentIdTableData = this._parseStudentIdTableData(pending.studentId);
    this.collegeNameTableData = this._parseCollegeNameTableData(pending.collegeName);

    // show/hide correct tables
    this.showStudentIdTable = Object.keys(pending.studentId).length > 0;
    if (this.showStudentIdTable) this.modalTitle = 'Verify students';
    this.showCollegeNameTable = Object.keys(pending.collegeName).length > 0 && !this.showStudentIdTable;
    if (this.showCollegeNameTable) this.modalTitle = 'Verify colleges';

    this.colleges = map(Object.keys(this.data.collegePathsMap), collegeKey => {
      const college = this.data.collegePathsMap[collegeKey];
      const { name, city, state } = college;
      return {
        key: collegeKey,
        human: name,
        tags: [{ key: city, human: city }, { key: state, human: state }],
      };
    }).sort((a, b) => (a.human > b.human ? 1 : -1));
    this.filteredColleges = this.colleges.slice(0, 20);

    this._setFormGroups();

    this.getStudents$()
      .pipe(
        tap(students => {
          this.students = map(students, student => {
            const {
              _id,
              studentId,
              studentDetails: {
                name: { first, last },
                classOf,
              },
            } = student;
            const firstLast = `${first} ${last}`;
            const classOfVal = `CLASS OF ${classOf}`;
            return {
              key: _id,
              human: firstLast,
              tags: [{ key: studentId, human: studentId }, { key: classOfVal, human: classOfVal }],
            };
          }).sort((a, b) => (a.human > b.human ? 1 : -1));

          this.matchedStudents = this.students;
          this.filteredStudents = this.matchedStudents.slice(0, 20);
          this._setMoreOptionsText(this.matchedStudents, this.filteredStudents);
        }),
        take(1),
      )
      .subscribe();
  }

  private getStudents$ (): Observable<Array<ICsvModalStudent>> {
    return this.store.select(getSchool).pipe(
      switchMap(({ _id: schoolId }) => {
        const projection = STUDENTS_PROJECTION;
        const joins = [];
        const where = { schoolStatus: { $in: ['A'] } };
        const payload = { schoolId, projection, joins, where };
        return this.apiService.getStudents(payload) as Observable<Array<ICsvModalStudent>>;
      }),
      catchError(() => new Array()),
    );
  }

  ngOnDestroy () {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onCancel (): void {
    this.dialogRef.close(false);
  }

  _parseStudentIdTableData (studentIds: IStudentIdPendingGrouping): ICsvFixIdRow[] {
    const rows = map(Object.keys(studentIds), key => {
      const { schools, name, _id, providedId } = studentIds[key];
      return { name, schools, id: _id, key, providedId };
    });
    return sortBy(rows, row => row.name);
  }

  _parseCollegeNameTableData (collegeNames: ICollegeNamePendingGrouping): ICsvFixCollegeNameRow[] {
    const rows = map(Object.keys(collegeNames), key => {
      const { studentIds } = collegeNames[key];
      return { studentIds, collegeName: key };
    });
    return sortBy(rows, row => row.collegeName);
  }

  _setFormGroups (): void {
    const studentIdForm = new FormGroup({});
    const collegeNameForm = new FormGroup({});
    forEach(this.studentIdTableData, row => {
      // add row to form for input displays
      const formControl = new FormControl(null, Validators.required);
      formControl.valueChanges.subscribe(filterVal => {
        if (!filterVal || filterVal === '') {
          // clear icon clicked
          this.matchedStudents = this.students;
          this.filteredStudents = [];
        } else {
          let lowerFilter;
          // filter value is a string when typing, but an object on click selection (JE)
          if (filterVal.human) lowerFilter = filterVal.human.toLowerCase().split(/[\s,]+/);
          else lowerFilter = filterVal.toLowerCase().split(/[\s,]+/);
          // filter all students by input filter
          this.matchedStudents = filter(this.students, student => {
            const { human, tags } = student;
            const id = tags[0].human;
            const classOf = tags[1].human;
            const fullStudent = `${human} ${id} ${classOf}`.toLowerCase();
            // every string in filter needs to match human, id or classOf (JE)
            return every(lowerFilter, val => {
              return fullStudent.includes(val);
            });
          });
          this.filteredStudents = this.matchedStudents.slice(0, 20);
        }
        this._setMoreOptionsText(this.matchedStudents, this.filteredStudents);
      });
      studentIdForm.addControl(row.key, formControl);
    });
    this.studentIdSelectionsForm = studentIdForm;

    forEach(this.collegeNameTableData, row => {
      // add row to form for input displays
      const formControl = new FormControl(null, Validators.required);
      formControl.valueChanges.subscribe(filterVal => {
        if (!filterVal || filterVal === '') {
          // clear icon clicked
          this.matchedColleges = this.colleges;
          this.filteredColleges = [];
        } else {
          let lowerFilter;
          // filter value is a string when typing, but an object on click selection (JE)
          if (filterVal.human) lowerFilter = filterVal.human.toLowerCase().split(/[\s,]+/);
          else lowerFilter = filterVal.toLowerCase().split(/[\s,]+/);
          // filter all colleges by input filter
          this.matchedColleges = filter(this.colleges, college => {
            const { human, tags } = college;
            const city = tags[0].human;
            const state = tags[1].human;
            const fullCollege = `${human} ${city} ${state}`.toLowerCase();
            // every string in filter needs to match human, city or state (JE)
            return every(lowerFilter, val => {
              return fullCollege.includes(val);
            });
          });
          this.filteredColleges = this.matchedColleges.slice(0, 20);
        }
        this._setMoreOptionsText(this.matchedColleges, this.filteredColleges);
      });
      collegeNameForm.addControl(row.collegeName, formControl);
    });
    this.collegeNameSelectionsForm = collegeNameForm;
  }

  clearStudentInput (id: string): void {
    this.studentIdSelectionsForm.controls[id].setValue(null);
    this.selectedStudentIds[id] = null;
    this.filteredStudents = this.students.slice(0, 20);
  }

  clearCollegeNameInput (key: string): void {
    this.collegeNameSelectionsForm.controls[key].setValue(null);
    const parsedKey = key.toLowerCase().trim();
    this.selectedCollegeNames[parsedKey] = null;
    this.filteredColleges = this.colleges.slice(0, 20);
  }

  selectStudent (id, event): void {
    this.studentIdSelectionsForm.controls[id].setValue(event.human);
    this.selectedStudentIds[id] = event.key;
    this.matchedStudents = this.students;
    this.filteredStudents = this.students.slice(0, 20);
    this._setMoreOptionsText(this.matchedStudents, this.filteredStudents);
  }

  selectCollegeName (id, event): void {
    this.collegeNameSelectionsForm.controls[id].setValue(event.human);
    const college = this.data.collegePathsMap[event.key];
    const parsedId = id.toLowerCase().trim();
    this.selectedCollegeNames[parsedId] = college;
    this.matchedColleges = this.colleges;
    this.filteredColleges = this.matchedColleges.slice(0, 20);
    this._setMoreOptionsText(this.matchedColleges, this.filteredColleges);
  }

  submitStudentIdForm (): void {
    this.validatedStudentIds = this.errorStudentIds.filter(id => this.selectedStudentIds[id] !== null);
    this._updateCollegeNameTableData();
    if (this.collegeNameTableData.length > 0) {
      this.showStudentIdTable = false;
      this.showCollegeNameTable = true;
      this.modalTitle = 'Verify colleges';
    } else {
      this.submitFixes();
    }
  }

  _updateCollegeNameTableData (): void {
    // Check to see if there are unvalidated student ids
    if (!isEqual(Object.keys(this.validatedStudentIds), Object.keys(this.errorStudentIds))) {
      // removes invalid student Ids from collegeNameTableData
      // updating this variable can modify the colleges displayed in the college name modal
      const unvalidatedStudentIds = this.errorStudentIds.filter(id => this.selectedStudentIds[id] === null);
      const pendingData = this.data.pending.collegeName;
      const newCollegeNameTable = this.collegeNameTableData.filter(name => {
        const studentIds = [...pendingData[name.collegeName].studentIds];
        const updatedStudentIds = studentIds.filter(id => !includes(unvalidatedStudentIds, id.fullWithName));
        if (updatedStudentIds.length > 0) {
          name.studentIds = updatedStudentIds;
          return true;
        }
        return false;
      });
      this.collegeNameTableData = newCollegeNameTable;
    }
  }

  submitCollegeNameForm (): void {
    this.validatedCollegeNames = this.errorCollegeNames.filter(name => this.selectedCollegeNames[name] !== null);
    this.submitFixes();
  }

  /* istanbul ignore next */
  submitFixes (): void {
    // fix rows for pending studentIds
    const fixedRows = reduce(
      this.validatedStudentIds,
      (res, id) => {
        const studentId = this.selectedStudentIds[id];
        // colleges and statuses for this corrected studentId in the csv
        const pendingSchools = this.data.pending.studentId[id].schools;
        // check studentPaths already on the student
        const colleges = this.studentIdCollegeMap[studentId] ? this.studentIdCollegeMap[studentId] : [];
        const collegeNames = Object.keys(colleges);
        forEach(pendingSchools, (pendingSchool: { name: string; status: TValidStudentPathStatuses }) => {
          const trimmedName = pendingSchool.name.toLowerCase().trim();
          // check if school is in student's school list
          const assignedSchoolName = find(collegeNames, college => {
            const preAssignedCollege = trimmedName === college.toLowerCase().trim();
            const fixedCollege =
              this.selectedStudentIds[trimmedName] &&
              this.selectedCollegeNames[trimmedName].name.toLowerCase().trim() === college;
            return preAssignedCollege || fixedCollege;
          });
          if (assignedSchoolName) {
            // add entry to 'toUpdate'
            const assignedSchool = colleges[assignedSchoolName];
            // only add to 'toUpdate' if status is different
            if (assignedSchool.status !== pendingSchool.status) {
              const update = {
                studentId,
                studentPathId: assignedSchool.studentPathId,
                status: pendingSchool.status,
              };
              // check if update already exists for this student
              const matchingUpdate = find(this.data.update, pendingUpdate => {
                const { studentId, studentPathId } = pendingUpdate;
                return studentId === update.studentId && studentPathId === assignedSchool.studentPathId;
              });
              if (!matchingUpdate || matchingUpdate.status !== update.status) {
                res.toUpdate = [...res.toUpdate, update];
              }
            }
          } else if (!this.data.collegePathsMap[trimmedName] && !this.selectedCollegeNames[trimmedName]) {
            // check to see if this.selectedCollegeNames[trimmedName] exists
            // if not, do not make create object
          } else {
            // add entry to 'toCreate'
            // check if college path name has been fixed in modal
            const pathId = this.data.collegePathsMap[trimmedName]
              ? this.data.collegePathsMap[trimmedName]._id
              : this.selectedCollegeNames[trimmedName]._id;
            const create = {
              studentId,
              pathId,
              status: pendingSchool.status,
            };

            // check if create already exists for this student
            const matchingCreate = find(this.data.create, pendingCreate => {
              const { studentId, pathId } = pendingCreate;
              return studentId === create.studentId && pathId === create.pathId;
            });

            // check if student already has the assigned college
            const preAssignedCollege = find(this.studentIdCollegeMap[studentId], college => {
              return college.pathId === pathId;
            });
            const validCreate = !matchingCreate || matchingCreate.status !== create.status;
            if (!preAssignedCollege && validCreate) {
              res.toCreate = [...res.toCreate, create];
            }
          }
        });
        return res;
      },
      { toCreate: [], toUpdate: [] },
    );

    const createsAndUpdates = [...fixedRows.toCreate, ...fixedRows.toUpdate];
    const pendingCollegeKeys = Object.keys(this.data.pending.collegeName);
    const collegeNameMap = reduce(
      pendingCollegeKeys,
      (acc, name) => {
        const parsedName = name.toLowerCase().trim();
        acc[parsedName] = name;
        return acc;
      },
      {},
    );
    // fix rows for remaining students in pending.collegeName
    const fixedWithColleges = reduce(
      this.validatedCollegeNames,
      (res, name) => {
        const selectedCollege = this.selectedCollegeNames[name];
        // get studentIds from pending.collegeName for this name
        // map used to match selected name to entered name, regardless of case
        const parsedName = collegeNameMap[name];
        const studentIds = this.data.pending.collegeName[parsedName].studentIds;
        const validStudentIds = studentIds.filter(id => {
          let isValid = true;
          if (includes(this.errorStudentIds, id.fullWithName)) {
            isValid = !!includes(this.validatedStudentIds, id.fullWithName);
          }
          return isValid;
        });
        // determine if create/update for each studentId
        const lowerName = selectedCollege.name.toLowerCase();
        forEach(validStudentIds, studId => {
          let sanitizedStudId;
          if (this.selectedStudentIds[studId.entered]) sanitizedStudId = this.selectedStudentIds[studId.entered];
          else if (this.selectedStudentIds[studId.fullWithName])
            {sanitizedStudId = this.selectedStudentIds[studId.fullWithName];}
          else sanitizedStudId = studId.full;
          const existingCreateOrUpdate = find(createsAndUpdates, change => {
            return change.pathId === selectedCollege._id && change.studentId === studId.full;
          });

          // only add to create/update if path is not already added
          if (!existingCreateOrUpdate) {
            // check if the student already has this college
            const existingCollegePath = this.studentIdCollegeMap[sanitizedStudId]
              ? this.studentIdCollegeMap[sanitizedStudId][lowerName]
              : null;
            // if yes, add to update
            if (existingCollegePath && existingCollegePath.status !== 'Plans to apply') {
              const update = {
                studentId: sanitizedStudId,
                studentPathId: existingCollegePath.studentPathId,
                status: 'Plans to apply', // only valid status for MVP (JE)
              };
              // add to update
              res.toUpdate = [...res.toUpdate, update];
            } else if (!existingCollegePath) {
              // if no, add to create
              const create = {
                studentId: sanitizedStudId,
                pathId: selectedCollege._id,
                status: 'Plans to apply',
              };
              res.toCreate = [...res.toCreate, create];
            }
          }
        });
        return res;
      },
      fixedRows,
    );

    // close this modal
    this.dialogRef.close(fixedWithColleges);
  }

  checkShadowAndStickyHeader ($event): void {
    this.showShadow = $event.target.scrollTop !== 0;
  }

  _setMoreOptionsText (matched: IACOption[], filtered: IACOption[]) {
    if (!filtered) {
      this.moreOptionsText = null;
      this.emptyStateText = null;
    } else {
      const diffCount = matched.length - filtered.length;
      if (filtered && diffCount >= 1) {
        const res = diffCount === 1 ? 'result' : 'results';
        this.moreOptionsText = `${diffCount} more ${res}. Refine your search.`;
        this.emptyStateText = null;
      } else {
        this.moreOptionsText = null;
        this.emptyStateText = 'No options found';
      }
    }
  }
}
