<ng-container *ngIf="!isFullScreen">
  <div class="sdc-root-standard-screen-content-wrapper">
    <div class="list-content-wrapper">
      <div class="list-content">
        <navigation-header [header]="header" [navItems]="navItems" [sdcKey]="sdcKey" [isFullScreen]="isFullScreen"></navigation-header>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="isFullScreen">
  <div class="sdc-root-full-screen-content-wrapper">
    <navigation-header [header]="header" [navItems]="navItems" [sdcKey]="sdcKey" [isFullScreen]="isFullScreen"></navigation-header>
    <router-outlet></router-outlet>
  </div>
</ng-container>
