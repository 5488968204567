import { Store, select } from '@ngrx/store';
import { identity } from 'lodash';
import { LoadSchool } from './../../../store/actions/school-actions';
import { take, tap, switchMap, filter } from 'rxjs/operators';
import { getSchoolLoadedStatus, getSchool } from './../../../store/selectors/school-selectors';
import { Observable, of } from 'rxjs';
import { ImSchool } from './../../../shared/services/im-models/im-school';
import { CanActivate, Router, UrlTree, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { ISummerSchool } from '../../../shared/typings/interfaces/school.interface';

@Injectable()
export class PbatSchoolGuard implements CanActivate {
  constructor (public imSchool:ImSchool, public router: Router, public store: Store<any>) {}

  getSchoolFromStoreOrAPI (schoolId: string): Observable<ISummerSchool> {
    return this.store.pipe(
      select(getSchoolLoadedStatus),
      tap(loaded => {
        if (!loaded) this.store.dispatch(new LoadSchool(schoolId));
      }),
      filter(identity),
      switchMap(() => this.store.pipe(select(getSchool))),
      take(1),
    );
  }

  canActivate (snapshot: ActivatedRouteSnapshot): Observable<UrlTree | boolean> {
    const { schoolId } = snapshot.params;
    const allowedPbatType = snapshot.data.allowedPbatType;

    return this.getSchoolFromStoreOrAPI(schoolId).pipe(
      switchMap(school => {
        const isPbatSchool = this.imSchool.isPbatSchool(school);
        if (isPbatSchool !== allowedPbatType) return of(this.router.createUrlTree(['home'])) as Observable<UrlTree>;
        else return of(true) as Observable<boolean>;
      }),
    );
  }
}
