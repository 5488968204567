<base-modal
  class="base-modal-shell task-modal"
  [title]="title"
  [itemCount]="itemCount"
  [itemType]="itemType"
  [isProfileMode]="isProfileMode"
>
  <ng-container [ngSwitch]="mode">
    <task-details
      *ngSwitchCase="'TASK_DETAILS_SCHOOL'"
      [taskForm]="taskForm"
      [editCommentMode]="editCommentMode"
      [canEditComment]="canEditComment"
      (editCommentClick)="onEditCommentButtonClick()"
    ></task-details>

    <ng-container *ngSwitchCase="'CREATE_BULK_TASK_SCHOOL'">
      <ng-container *ngTemplateOutlet="addEditTask"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'EDIT_TASK_SCHOOL'">
      <ng-container *ngTemplateOutlet="addEditTask"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'CREATE_SINGLE_TASK_SCHOOL'">
      <ng-container *ngTemplateOutlet="addEditTask"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'DELETE_TASK_SCHOOL'"> {{ deleteConfirmationMessage }} </ng-container>

    <ng-container *ngSwitchCase="'CREATE_TASK_SCHOOL'">
      <ng-container *ngTemplateOutlet="addTask"></ng-container>
    </ng-container>
  </ng-container>

  <nv-note *ngIf="isViewOnlyUser" size="large" priority="info" class="warning-note">
    {{ recipientPermissionWarning }}
  </nv-note>

  <!-- Buttons -->
  <mat-dialog-actions *ngIf="showActionButtons" class="dialog-actions">
    <nv-button type="secondary" (clickButton)="close()"> Cancel </nv-button>
    <nv-button
      htmlType="submit"
      [disabled]="editIsRestricted && (taskForm.pristine || taskForm.invalid)"
      (clickButton)="onClickPrimaryBtn()"
    >
      {{ primaryBtnName }}
    </nv-button>
  </mat-dialog-actions>
</base-modal>

<ng-template #addEditTask>
  <form [formGroup]="taskForm" autocomplete="off" *ngIf="taskForm">
    <nv-form-field label="Description">
      <nv-textarea
        [formControl]="taskForm.controls.description"
        [maxCharacters]="150"
        [placeholder]="'Write a description...'"
      ></nv-textarea>
    </nv-form-field>

    <nv-form-field label="Categories" class="categories-field">
      <nv-multi-picker
        [options]="taskCategoriesOptions"
        [hasClearButton]="true"
        [hasAllButton]="false"
        [placeholder]="'Search for categories'"
        [selectedOptions]="taskForm.controls.selectedCategories.value"
        (selectedOptionsChange)="handleSelectedCategories($event)"
      ></nv-multi-picker>
    </nv-form-field>

    <nv-form-field
      *ngIf="schoolUsersOptions"
      label=" Assign to"
      class="recipient-field"
      [isInvalid]="!taskForm.controls.recipient.pristine && taskForm.controls.recipient.invalid"
      [showInvalid]="!taskForm.controls.recipient.pristine && taskForm.controls.recipient.invalid"
    >
      <nv-textbox
        [textControl]="taskForm.controls.recipient"
        icon="search-large-black"
        placeholder="Search for people"
        [restrictToOptions]="true"
        [autocompleteOptions]="schoolUsersOptions"
        [emptyStateText]="'No Active Users'"
        [hasClearBtn]="true"
        (clearValue)="onClearFieldSelection('recipient')"
        [returnObject]="true"
        (selectOption)="onFieldSelect($event, 'recipient')"
        [optionsPredicateCb]="optionsPredicateCb"
        [optionsLimit]="20"
        [weightedOptionsSortingEnabled]="true"
        [showInvalid]="!taskForm.controls.recipient.pristine && taskForm.controls.recipient.invalid"
      >
      </nv-textbox>
    </nv-form-field>
  </form>
</ng-template>

<ng-template #addTask>
  <form [formGroup]="taskForm" autocomplete="off" *ngIf="taskForm">
    Students
    <nv-form-field>
      <nv-multi-picker
        [hasAllButton]="false"
        [hasClearButton]="false"
        [options]="studentOptions"
        [placeholder]="'Select students'"
        (selectedOptionsChange)="onSelectedOptionsChange($event)"
      ></nv-multi-picker>
    </nv-form-field>
  </form>
</ng-template>
