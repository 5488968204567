import { createSelector, createFeatureSelector } from '@ngrx/store';

const getPortalState = createFeatureSelector('PORTAL_STORE');

export const getToggleState = createSelector(
  getPortalState,
  (state: any) => state.toggles,
);

export const getToggleEntities = createSelector(
  getToggleState,
  (state: any) => state.toggleEntities,
);

export const getToggleLoadedStatus = createSelector(
  getToggleState,
  (state: any) => state.loaded,
);
