import { AdminLayoutComponent } from '../../../../admin/admin-layout/admin-layout.component';
import { AdminGuard } from 'Src/ng2/routing/guards/admin-guard/admin-guard';

export const adminRoute = {
  path: 'admin',
  url: '/admin',
  canActivate: [AdminGuard],
  component: AdminLayoutComponent,
  loadChildren: () =>
    import('../../../../admin/admin-routing.module').then(m => m.AdminRoutingModule),
};
