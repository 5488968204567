import { difference, uniq, cloneDeep, each, includes } from 'lodash';

export class BooleanYesNoFilter {
  private filterChangedCallback;
  private field;
  private model;
  private filter;
  private numOfFilterChoices;
  private _eGui;

  private OPTION_TEMPLATE =
    '<div style="margin-bottom: 4px">' +
    '  <label>' +
    '    <input CHECKED type="checkbox"/>OPTION' +
    '  </label>' +
    '</div>';

  constructor () {}

  init (params) {
    this.filterChangedCallback = params.filterChangedCallback;
    this.field = params.colDef.field;
    // Examples of this.model:
    // * { in: [true] } - display only where the value matches "true"
    // * { in: [true, false] } - display where the value matches "true" or "false"
    // * undefined - display all values (filter disabled)
    this.model = undefined;
    this.numOfFilterChoices = 3;
    this._updateFilterFunction(); // always call this after this.model changes
  }

  _updateFilterFunction () {
    const model = this.model || { in: [true, false, null] };
    this.filter = this.makeBooleanYesNoFilter(this.field, model);
  }

  makeBooleanYesNoFilter (sorterColumn, filter) {
    return flattenedStudent => {
      return includes(filter.in, flattenedStudent[sorterColumn]);
    };
  }

  _optionChecked (value, checked) {
    const model = this.model || { in: [true, false, null] };
    if (checked) {
      model.in.push(value);
    } else {
      model.in = difference(model.in, [value]);
    }

    model.in = uniq(model.in);
    if (model.in.length === this.numOfFilterChoices) {
      this.model = undefined;
    } else {
      this.model = model;
    }
  }

  _isOptionChecked (value) {
    if (!this.model) return true;
    return includes(this.model.in, value);
  };

  _buildGui () {
    this._eGui = this._eGui || document.createElement('div');
    const eGui = this._eGui;
    eGui.style.padding = '8px';

    const inputs = [{ label: '(Blanks)', value: null }, { label: 'Yes', value: true }, { label: 'No', value: false }];
    this.numOfFilterChoices = inputs.length;

    // Clear out eGui
    while (eGui.firstChild) {
      eGui.removeChild(eGui.firstChild);
    }

    each(inputs, (option) => {
      // HTML escape hack
      const div = document.createElement('div');
      div.appendChild(document.createTextNode(option.label));

      let html = this.OPTION_TEMPLATE.replace('OPTION', div.innerHTML);
      html = html.replace('CHECKED', this._isOptionChecked(option.value) ? 'checked' : '');
      const eDiv = document.createElement('div');
      eDiv.innerHTML = html;

      const eCheckbox = eDiv.querySelector('input');
      eCheckbox.addEventListener('click', () => {
        this._optionChecked(option.value, eCheckbox.checked);
        this._updateFilterFunction();
        this.filterChangedCallback();
      });

      eGui.appendChild(eDiv);
    });
  }

  getGui () {
    this._buildGui();
    return this._eGui;
  }

  doesFilterPass (params) {
    return this.filter(params.data);
  }

  isFilterActive () {
    return this.model && this.model.in.length < this.numOfFilterChoices;
  };

  getModel () {
    if (this.isFilterActive()) {
      return cloneDeep(this.model);
    }
    return null;
  };

  setModel (m) {
    this.model = m;
    this._updateFilterFunction();
    this._buildGui();
  }
}
