import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvIconModule } from 'projects/shared/nvps-libraries/design/nv-icon/nv-icon.module';
import { NvBoxComponent } from './nv-box.component';

@NgModule({
  declarations: [NvBoxComponent],
  exports: [NvBoxComponent],
  imports: [CommonModule, NvIconModule],
})
export class NvBoxModule {}
