import { NvDesignLibraryModule } from '../../../../nvps-libraries/design/nv-design-library.module';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { BaseModalModule } from '../base-modal.module';
import { GridViewNameModalComponent } from './gridview-name-modal.component';
import { NvTextboxModule } from './../../../../nvps-libraries/design/nv-textbox/nv-textbox.module';
import { NvFormFieldModule } from './../../../../nvps-libraries/design/nv-form-field/nv-form-field.module';
import { NvButtonModule } from 'projects/shared/nvps-libraries/design/nv-button/nv-button.module';
import { NvIconModule } from 'projects/shared/nvps-libraries/design/nv-icon/nv-icon.module';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [GridViewNameModalComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    BaseModalModule,
    NvDesignLibraryModule,
    MatDialogModule,
    MatButtonModule,
    NvTextboxModule,
    NvFormFieldModule,
    NvButtonModule,
    NvIconModule,
  ],
  exports: [GridViewNameModalComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class GridViewNameModalModule {}
