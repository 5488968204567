export type TSupportStatusesFrontend = 'NOT STARTED' | 'IN PROGRESS' | 'COMPLETED' | 'REMOVED';

const backend: {
  [key: string]: string;
} = {
  COMPLETED: 'COMPLETED',
  ACTIVE: 'ACTIVE',
  DELETED: 'DELETED',
};
const frontend: {
  [key: string]: TSupportStatusesFrontend;
} = {
  NOT_STARTED: 'NOT STARTED', // ACTIVE
  IN_PROGRESS: 'IN PROGRESS', // ACTIVE
  COMPLETED: 'COMPLETED', // ACTIVE
  REMOVED: 'REMOVED', // DELETED
};
const frontendHumanReadable: {
  [key: string]: string;
} = {
  [frontend.NOT_STARTED]: 'Not Started',
  [frontend.IN_PROGRESS]: 'In Progress',
  [frontend.COMPLETED]: 'Completed',
  [frontend.REMOVED]: 'Removed',
};

export const SupportStatuses = {
  backend,
  frontend,
  frontendHumanReadable,
};
