<base-modal class="base-modal notes-modal" [title]="title" isProfileMode="false">
  <!-- Forms -->
  <ng-container [ngSwitch]="modalControl">
    <add-check-in-log
    *ngSwitchCase="'ADD_CHECKIN_LOG'"
    [schoolId]="schoolId"
    [studentId]="studentId"
    [studentName]="studentName"
    [guardianName]="guardianName"
    (changeHandler)="onOptionChange($event)"
    ></add-check-in-log>
  </ng-container>

  <!-- Buttons -->
  <mat-dialog-actions class="dialog-actions button-group">
    <nv-button type="secondary" (clickButton)="onCancel()">
      Cancel
    </nv-button>
    <nv-button htmlType="submit" (clickButton)="onSave()" [disabled]="!canSave">
      Add
    </nv-button>
  </mat-dialog-actions>
</base-modal>