import { Component, Input, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ITooltipContent } from '../../nv-shared-tooltip.interface';

interface IShapedData {
  isHeader: boolean;
  content: string[];
}

@Component({
  selector: 'nv-grouped-tables-tooltip',
  templateUrl: './nv-grouped-tables-tooltip.component.html',
  styleUrls: ['./nv-grouped-tables-tooltip.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NvGroupedTablesTooltipComponent {
  @Input() tooltipData: ITooltipContent;
  constructor (private _domSanitizer: DomSanitizer) {}

  shapedData: IShapedData[];
  ngOnInit () {
    this.shapedData = this.shapeData(this.tooltipData);
  }

  shapeData (tooltipData: ITooltipContent): IShapedData[] {
    const { headers, rowData } = tooltipData;
    const res = [];
    headers.forEach((header, index) => {
      const headerRow = {
        isHeader: true,
        content: header,
      };
      res.push(headerRow);
      const tableRows = rowData[index];
      tableRows.forEach(row => {
        res.push({ content: row });
      });
    });
    return res;
  }
}
