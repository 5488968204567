<ng-container *ngIf="(formConfig$ | async) as formConfig">
  <form [formGroup]="formGroup">
    <ng-container *ngFor="let config of formConfig.options">
      <form-item
        [config]="config"
        [form]="formGroup.controls[config.key]"
        (changeHandler)="onOptionChange($event, config.key)"
      ></form-item>
    </ng-container>

    <button-group
      [isSaveDisabled]="!formGroup.valid"
      (cancel)="onCancel()"
      (save)="onSave()"
    ></button-group>
  </form>
</ng-container>