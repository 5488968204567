<div class="revoke-consent-status-container">
  <p>Are you sure you want to revoke Every Child and Family is Known program consent on behalf of this family?</p>
  <div class="revoking-consent-description">
    <p class="bold-text">Revoking consent will:</p>
    <ul>
      <li>Remove guardian access to the Family Portal</li>
      <li>End program participation</li>
    </ul>
  </div>
  <p>This action cannot be undone. To participate and regain access, the primary guardian must sign a new consent form.</p>
</div>
