import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Auth } from '../../../shared/auth/auth.service';
import { LocalStorageService } from '../../../shared/services/web-storage/local-storage/local-storage.service';

@Injectable()
export class AdminGuard implements CanActivate {
  constructor (
    private authService: Auth,
    private router: Router,
    private LocalStorage: LocalStorageService,
  ) {}

  canActivate (snapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UrlTree|boolean> {
    const isAuthenticated = this.authService.isAuthenticated();
    if (!isAuthenticated) return this.redirectToLogin$(state.url);
    return of(true);
  }

  private redirectToLogin$ (lastKnownUrl: string): Observable<UrlTree> {
    if (lastKnownUrl) this.LocalStorage.setItem('lastKnownUrl', lastKnownUrl);
    return of(this.router.createUrlTree(['/login']));
  }
}
