import { BehaviorSubject } from 'rxjs';
import { TNvTooltipData } from 'Src/ng2/shared/nv-tooltip/nv-tooltip.interface';
import { IDropdownOption } from 'projects/shared/nvps-libraries/design/interfaces/design-library.interface';
import { ECFIKStatusOptions, NeedsOptions, ECFIKFormOptionsFields } from './../check-in-log-modal.component';
import { DateHelpers } from '../../../../../../projects/shared/services/date-helpers/date-helpers.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { unsubscribeComponent } from 'Src/ng2/shared/helpers/unsubscribe-decorator/unsubscribe-decorators.helper';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { IPickerOption } from 'projects/shared/nvps-libraries/design/nv-multi-picker/nv-multi-picker.interface';
import { ISchoolNote } from 'Src/ng2/shared/typings/interfaces/note.interface';
import { PartnerTypes } from 'Src/ng2/shared/typings/interfaces/partner.interface';

export type ECFIKFormOptionsMap = { [field: string]: { options: IDropdownOption[] | IPickerOption[] | null, description?: TNvTooltipData } };

@unsubscribeComponent
@Component({
  selector: 'add-check-in-log',
  templateUrl: 'add-check-in-log.component.html',
  styleUrls: ['./add-check-in-log.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddCheckInLogComponent implements OnInit {
  @Input() schoolId: string;
  @Input() studentId: string;
  @Input() studentName: string;
  @Input() guardianName: string;
  @Output() changeHandler = new EventEmitter();

  public noteForm: FormGroup;
  public ecfikFormOptionsMap: ECFIKFormOptionsMap;
  public today: string;
  readonly DATE_FORMAT: string = 'YYYY-MM-DD';

  public selectedNeedsOptions = new BehaviorSubject([]);

  constructor (
    private formBuilder: FormBuilder,
    private dateHelpers: DateHelpers,
  ) {}

  ngOnInit (): void {
    this.today = this.dateHelpers.getFormattedNow('YYYY-MM-DD');
    this.ecfikFormOptionsMap = this.initEcfikFormOptionsMap();
    this.noteForm = this.initNoteForm();
    this.noteForm.valueChanges.subscribe((val) => {
      this.noteForm.markAsDirty();
      this.changeHandler.emit(this.noteForm.status);
    });
  }

  private initEcfikFormOptionsMap (): ECFIKFormOptionsMap {
    return {
      type: {
        options: [
          { key: 'Student', human: 'Student' },
          { key: 'Student', human: 'Family' },
        ],
      },
      status: {
        options: Object.values(ECFIKStatusOptions).map((val) => {
          return { key: val, human: val };
        }),
      },
      needs: {
        options: Object.values(NeedsOptions).map((val) => {
          return { key: val, human: val };
        }),
      },
      serviceReferral: {
        options: null,
        description: 'Select this option if there was a service referral for this student',
      },
    };
  }

  private initNoteForm (): FormGroup {
    const todaysDate = this.dateHelpers.getFormattedNow(this.DATE_FORMAT);
    const initialForm = {
      note: '',
      selectedCategories: ['ECFIK'],
      logDate: todaysDate,
      type: null,
      status: null,
      needs: [],
      serviceReferral: false,
    };

    return this.formBuilder.group({
      note: [initialForm.note, Validators.required],
      selectedCategories: [initialForm.selectedCategories, Validators.required],
      logDate: [initialForm.logDate, Validators.required],
      type: [initialForm.type, Validators.required],
      status: [initialForm.status, Validators.required],
      needs: [initialForm.needs],
      serviceReferral: [initialForm.serviceReferral],
    });
  }

  public handleDropdownSelect ($event, field: ECFIKFormOptionsFields) {
    this.noteForm.controls[field].setValue($event);
  }

  public handleNeedsOptionClick ($event) {
    this.selectedNeedsOptions.next($event);
    this.noteForm.controls[ECFIKFormOptionsFields.Needs].setValue(this.selectedNeedsOptions.value);
  }

  public getPayload (): ISchoolNote {
    const payload = {
      schoolId: this.schoolId,
      body: this.noteForm.value.note,
      categories: this.noteForm.value.selectedCategories,
      ecfik: {
        logDate: this.noteForm.value.logDate,
        type: this.noteForm.value.type,
        status: this.noteForm.value.status,
        needs: this.noteForm.value.needs,
        serviceReferral: this.noteForm.value.serviceReferral,
      },
      attachments: {
        student: {
          studentId: this.studentId,
          lastFirst: this.studentName,
        },
      },
      contextPartnerType: PartnerTypes.SCHOOL,
    };
    return payload;
  }
}
