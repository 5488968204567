export const PROGRAM_CHANGES_GROUPING_COLUMNS = {
  gradReq: [
    {
      columnName: 'STUDENT NAME',
      columnOrder: 0,
      graphQlKey: 'PATH_PROGRAM_CHANGES_STUDENT_NAME',
    },
    {
      columnName: 'CREATED ON',
      columnOrder: 1,
      graphQlKey: 'PATH_PROGRAM_CHANGES_CREATED_ON',
      cellTooltip: 'CREATED_BY_PROGRAM_CHANGES_USER_INFO',
    },
    {
      columnName: 'COURSE',
      columnOrder: 2,
      graphQlKey: 'PATH_PROGRAM_CHANGES_COURSE',
    },
    {
      columnName: 'CREDITS',
      columnOrder: 3,
      graphQlKey: 'PATH_PROGRAM_CHANGES_CREDITS',
    },
    {
      columnName: 'TERM',
      columnOrder: 4,
      graphQlKey: 'PATH_PROGRAM_CHANGES_TERM',
    },
    {
      columnName: 'TYPE',
      columnOrder: 5,
      graphQlKey: 'PATH_PROGRAM_CHANGES_TYPE',
    },
    {
      columnName: 'STATUS',
      columnOrder: 6,
      graphQlKey: 'PATH_PROGRAM_CHANGES_STATUS',
    },
  ],
  status: [
    {
      columnName: 'STUDENT NAME',
      columnOrder: 0,
      graphQlKey: 'PATH_PROGRAM_CHANGES_STUDENT_NAME',
    },
    {
      columnName: 'CREATED ON',
      columnOrder: 1,
      graphQlKey: 'PATH_PROGRAM_CHANGES_CREATED_ON',
      cellTooltip: 'CREATED_BY_PROGRAM_CHANGES_USER_INFO',
    },
    {
      columnName: 'GRAD REQ',
      columnOrder: 2,
      graphQlKey: 'PATH_PROGRAM_CHANGES_GRAD_REQ',
    },
    {
      columnName: 'COURSE',
      columnOrder: 3,
      graphQlKey: 'PATH_PROGRAM_CHANGES_COURSE',
    },
    {
      columnName: 'CREDITS',
      columnOrder: 4,
      graphQlKey: 'PATH_PROGRAM_CHANGES_CREDITS',
    },
    {
      columnName: 'TERM',
      columnOrder: 5,
      graphQlKey: 'PATH_PROGRAM_CHANGES_TERM',
    },
    {
      columnName: 'TYPE',
      columnOrder: 6,
      graphQlKey: 'PATH_PROGRAM_CHANGES_TYPE',
    },
  ],
  courseOrplan: [
    {
      columnName: 'STUDENT NAME',
      columnOrder: 0,
      graphQlKey: 'PATH_PROGRAM_CHANGES_STUDENT_NAME',
    },
    {
      columnName: 'CREATED ON',
      columnOrder: 1,
      graphQlKey: 'PATH_PROGRAM_CHANGES_CREATED_ON',
      cellTooltip: 'CREATED_BY_PROGRAM_CHANGES_USER_INFO',
    },
    {
      columnName: 'GRAD REQ',
      columnOrder: 2,
      graphQlKey: 'PATH_PROGRAM_CHANGES_GRAD_REQ',
    },
    {
      columnName: 'CREDITS',
      columnOrder: 3,
      graphQlKey: 'PATH_PROGRAM_CHANGES_CREDITS',
    },
    {
      columnName: 'TERM',
      columnOrder: 4,
      graphQlKey: 'PATH_PROGRAM_CHANGES_TERM',
    },
    {
      columnName: 'TYPE',
      columnOrder: 5,
      graphQlKey: 'PATH_PROGRAM_CHANGES_TYPE',
    },
    {
      columnName: 'STATUS',
      columnOrder: 6,
      graphQlKey: 'PATH_PROGRAM_CHANGES_STATUS',
    },
  ],
  type: [
    {
      columnName: 'STUDENT NAME',
      columnOrder: 0,
      graphQlKey: 'PATH_PROGRAM_CHANGES_STUDENT_NAME',
    },
    {
      columnName: 'CREATED ON',
      columnOrder: 1,
      graphQlKey: 'PATH_PROGRAM_CHANGES_CREATED_ON',
      cellTooltip: 'CREATED_BY_PROGRAM_CHANGES_USER_INFO',
    },
    {
      columnName: 'GRAD REQ',
      columnOrder: 2,
      graphQlKey: 'PATH_PROGRAM_CHANGES_GRAD_REQ',
    },
    {
      columnName: 'COURSE',
      columnOrder: 3,
      graphQlKey: 'PATH_PROGRAM_CHANGES_COURSE',
    },
    {
      columnName: 'CREDITS',
      columnOrder: 4,
      graphQlKey: 'PATH_PROGRAM_CHANGES_CREDITS',
    },
    {
      columnName: 'TERM',
      columnOrder: 5,
      graphQlKey: 'PATH_PROGRAM_CHANGES_TERM',
    },
    {
      columnName: 'TYPE',
      columnOrder: 6,
      graphQlKey: 'PATH_PROGRAM_CHANGES_TYPE',
    },
  ],
};

export const PROGRAM_CHANGES_CSV_OTHER_COLUMNS = {
  gradReq: ['Created On', 'Course', 'Credits', 'Term', 'Type', 'Status'],
  status: ['Created On', 'Grad Req', 'Course', 'Credits', 'Term', 'Type'],
  courseOrplan: ['Created On', 'Grad Req', 'Credits', 'Term', 'Type', 'Status'],
  type: ['Created On', 'Grad Req', 'Course', 'Credits', 'Term', 'Type'],
};

export const PROGRAM_CHANGES_GROUPING_OPTIONS = [
  {
    human: 'Type',
    key: 'type',
  },
  {
    human: 'Status',
    key: 'status',
  },
  {
    human: 'Course',
    key: 'courseOrplan',
  },
  {
    human: 'Grad req',
    key: 'gradReq',
  },
];
