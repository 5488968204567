import { EventFormatterService } from './../../shared/services/mixpanel/event-formatter.service';
import { BACKGROUND_JOB_TIMEOUT_THRESHOLDS, BACKGROUND_JOB_STATUS_TYPES } from './../../shared/services/background-job/background-job.service';
import { identity } from 'lodash';
import { ModalsService } from 'Src/ng2/shared/modals/modals.service';
import { UpdateSdcStudentNoRequest } from './../actions/sdc-actions/sdc-actions';
import { BulkUpdateStudentsSuccess } from './../actions/students-actions';
import { PatchUtilities } from './../utilities/patch-utilities';
import { HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Injector, forwardRef } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, from, Observable, of } from 'rxjs';
import { catchError, mergeMap, switchMap, take, tap, withLatestFrom, timeout, map } from 'rxjs/operators';
import { BackgroundJobNotificationService } from 'Src/ng2/shared/services/background-job-notification-service/background-job-notification-service';
import { BackgroundJob } from 'Src/ng2/shared/services/background-job/background-job.service';
import { LoadDocLogs } from '../actions/doc-logs-actions';
import { UpdatePatchProcessing } from '../actions/patch-processing-actions';
import * as singleStudentActions from '../actions/single-student-actions';
import * as studentActions from '../actions/students-actions';
import { ApiService } from './../../shared/services/api-service/api-service';
import { ISnackBarData, SnackBarService } from './../../shared/services/snackbar/snackbar.service';
import { BatchActionsEffectsUtilities } from '../utilities/batch-actions-effects-utilities';
import { UpdateSdcStudentsNoRequest } from '../actions/sdc-actions/sdc-actions';
import { StudentUpdateNotificationService } from 'Src/ng2/shared/services/student-update-notification-service.ts/student-update-notification.service';
import { OTHER_SINGLE_STUDENT_UPDATE_SUCCESS, UPDATE_STUDENT_SUCCESS } from './../actions/single-student-actions';
import { CREATE_STUDENT_SUPPORT_SUCCESS, UPDATE_STUDENT_SUPPORT_SUCCESS } from './../actions/student-supports-actions';
import { CREATE_STUDENT_PATH_SUCCESS, UPDATE_STUDENT_PATH_SUCCESS } from './../actions/student-paths-actions';
import { StudentOtherSchoolType } from 'Src/ng2/shared/typings/interfaces/student.interface';

@Injectable()
export class StudentEffects {
  constructor (
    private actions$: Actions,
    private apiService: ApiService,
    private store$: Store<any>,
    private snackBarService: SnackBarService,
    private backgroundJob: BackgroundJob,
    private injector: Injector,
    @Inject(forwardRef(() => ModalsService)) public modalsService: ModalsService,
    private backgroundJobNotificationService: BackgroundJobNotificationService,
    private studentUpdateNotificationService: StudentUpdateNotificationService,
    private eventFormatterService: EventFormatterService,
  ) {}

  snackBarData: ISnackBarData = { message: '', duration: 2000 };

  /* istanbul ignore next */
  updateStudents$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<any>(studentActions.BULK_UPDATE_STUDENTS),
      withLatestFrom(this.store$),
      switchMap(([action, storeState]) => {
        const { patches, origin } = action.payload;
        const httpResponseObsv$: Observable<HttpResponse<any>> = this.apiService.patchStudents(patches);
        return httpResponseObsv$.pipe(
          take(1),
          map(response => {
            const jobId = response.headers.get('nv-background-jobs');
            return BatchActionsEffectsUtilities.getJobSubject(this.backgroundJob, { response, jobId });
          }),
          tap(subject => {
            this.modalsService.openBackgroundJobSpinnerModal({ backgroundJobSubject: subject, title: 'Updating Students' });
          }),
          switchMap(identity),
          timeout(BACKGROUND_JOB_TIMEOUT_THRESHOLDS.BATCH_ACTIONS),
          tap(({ type }) => {
            if (type === BACKGROUND_JOB_STATUS_TYPES.RESOLVE) {
              BatchActionsEffectsUtilities.sendSnack(this.snackBarService, { success: true, origin });
              this.backgroundJobNotificationService.sendMessage({ backgroundJob: 'BulkStudentUpdate' });
              const newPatches = PatchUtilities.shapePatchesForStore([patches]);

              const actions = [new BulkUpdateStudentsSuccess(newPatches), new UpdateSdcStudentsNoRequest({ patches: newPatches.patches.map(({ patch, _ids }) => ({ patch, _ids })) })];
              actions.forEach(action => this.store$.dispatch(action));
            }
          }),
          catchError((error) => {
            BatchActionsEffectsUtilities.sendSnack(this.snackBarService, { success: false, origin });
            return error;
          }),
        );
      }),
    );
  }, { dispatch: false });

  loadSingleStudent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(singleStudentActions.LOAD_STUDENT),
      switchMap((action: any) => {
        const { studentId, isSummerSchoolTerm } = action.payload;
        return this.apiService.getSingleStudent(studentId, isSummerSchoolTerm).pipe(
          mergeMap(students => [new singleStudentActions.LoadSingleStudentSuccess(students)]),
          catchError(error => from([new singleStudentActions.LoadSinglesStudentFail(error)])),
        );
      }),
    );
  });

  loadSingleStudentFromOtherSchool$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(singleStudentActions.LOAD_STUDENT_FROM_OTHER_SCHOOL),
      switchMap((action: any) => {
        const { studentId, otherSchoolType = StudentOtherSchoolType.SUMMER, isSummerSchoolTerm } = action.payload;
        return this.apiService.getSingleStudentFromOtherSchool(studentId, otherSchoolType, isSummerSchoolTerm).pipe(
          mergeMap(students => [new singleStudentActions.LoadSingleStudentFromOtherSchoolSuccess(students)]),
          catchError(error => from([new singleStudentActions.LoadSinglesStudentFromOtherSchoolFail(error)])),
        );
      }),
    );
  });

  updateSingleStudent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(singleStudentActions.UPDATE_STUDENT),
      switchMap((action: { type: string; payload: any }) => {
        const { payload } = action;
        return this.apiService.patchSingleStudent(payload).pipe(
          mergeMap(students => {
            const { _id, schoolId } = students;
            const docLogFilter = { studentId: _id, schoolId };
            return [
              new singleStudentActions.UpdateSingleStudentSuccess(students),
              new UpdateSdcStudentNoRequest(students),
              new LoadDocLogs(docLogFilter),
              new UpdatePatchProcessing(),
            ];
          }),
          catchError(error => {
            return from([new singleStudentActions.UpdateSingleStudentFail(error), new UpdatePatchProcessing()]);
          }),
        );
      }),
    );
  });

  sendStudentUpdateMessage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<any>(
        UPDATE_STUDENT_SUCCESS,
        CREATE_STUDENT_PATH_SUCCESS,
        UPDATE_STUDENT_PATH_SUCCESS,
        CREATE_STUDENT_SUPPORT_SUCCESS,
        UPDATE_STUDENT_SUPPORT_SUCCESS,
        OTHER_SINGLE_STUDENT_UPDATE_SUCCESS,
      ),
      withLatestFrom(this.store$),
      switchMap(([action, storeState]) => {
        const { payload } = action;
        switch (action.type) {
          case UPDATE_STUDENT_SUCCESS: {
            const { _id } = payload;
            this.studentUpdateNotificationService.sendStudentUpdateNotificationMessage({ update: 'StudentUpdate', studentId:  _id });
            break;
          }
          case CREATE_STUDENT_SUPPORT_SUCCESS:
          case UPDATE_STUDENT_SUPPORT_SUCCESS: {
            const { student } = payload;
            this.studentUpdateNotificationService.sendStudentUpdateNotificationMessage({ update: 'StudentSupportUpdate', studentId: student.studentId });
            break;
          }
          case CREATE_STUDENT_PATH_SUCCESS: {
            const { studentId } = payload;
            this.studentUpdateNotificationService.sendStudentUpdateNotificationMessage({ update: 'StudentPathUpdate', studentId });
            break;
          }
          case UPDATE_STUDENT_PATH_SUCCESS: {
            const { student, studentId } = payload;
            this.studentUpdateNotificationService.sendStudentUpdateNotificationMessage({ update: 'StudentPathUpdate', studentId: student ? student.studentId :  studentId });
            break;
          }
          case OTHER_SINGLE_STUDENT_UPDATE_SUCCESS: {
            const { studentId } = payload;
            this.studentUpdateNotificationService.sendStudentUpdateNotificationMessage({ update: 'StudentUpdate', studentId });
            break;
          } 
          default:
            break;
        }
        return EMPTY;
      })
    )
  }, { dispatch: false })
}
