import { Component, Input, OnInit, EventEmitter, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'sync-summary',
  templateUrl: './sync-summary.component.html',
  styleUrls: ['./sync-summary.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SyncSummaryComponent implements OnInit {
  @Input() queueUpdateSyncToolStatus: 'Success' | 'Fail' | 'Loading';
  @Input() isEditMode: boolean;
  @Output() completed: EventEmitter<void> = new EventEmitter<void>();
  @Output() abandoned: EventEmitter<void> = new EventEmitter<void>();

  actionWordPast: string;
  actionWordPresent: string;

  constructor () {}

  ngOnInit () {
    this.actionWordPast = this.isEditMode ? 'updated' : 'created';
    this.actionWordPresent = this.isEditMode ? 'update' : 'create';
  }

  onOK () {
    this.completed.emit();
  }

  onBack () {
    this.abandoned.emit();
  }
}
