import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';

// modules
import { WizardFilterActionsModule } from 'Src/ng2/school/sdc/wizard-filter-actions/wizard-filter-actions.module';

// services
import { ImSchool } from 'Src/ng2/shared/services/im-models/im-school';
import { RollbarErrorHandler } from 'Src/ng2/shared/services/rollbar/rollbar.service';

// components
import { CreditGapsWizardClassTable } from './credit-gaps-class-table.component';

@NgModule({
  imports: [CommonModule, WizardFilterActionsModule, MatButtonModule, MatRadioModule, FormsModule],
  exports: [CreditGapsWizardClassTable],
  declarations: [CreditGapsWizardClassTable],
  providers: [ImSchool, RollbarErrorHandler],
})
export class CreditGapsWizardClassTableModule { }
