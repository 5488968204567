<base-modal 
  class="base-modal add-mock-regents-exams"
  [title]="title"
  [isProfileMode]="isProfileMode"
>
  <div *ngIf="actionItems.length" class="action-items-container">
    <ng-container *ngFor="let item of actionItems">
      <div class="action-item-container">
        <nv-icon class='action-icon' name={{item.icon}}></nv-icon>
          <div>
            <b>{{item.step}}</b> {{item.title}} <nv-pill class="notification-pill" *ngIf="item.hasPill" [text]="item.pillText" [color]="'blue-light-7'" [isInverted]="true"></nv-pill>
            <p class="item-description">{{item.description}}</p>
            <ng-container *ngIf="item.hasButton">
              <nv-button
                (clickButton)="goToURL(item.link)"
                [disabled]="item.buttonDisabled"
                [size]="'small'"
                >
                {{item.buttonText}}
              </nv-button>
            </ng-container>
          </div>
      </div>
    </ng-container>
  </div>
</base-modal>