export const CREDIT_REQS = {
  elaCore: {
    humanName: 'ELA',
    gradReq: {
      NYC: 8,
      Lansing: 4,
      Schenectady: 4,
      Uniondale: 4,
      GreatOaks: 4,
      BroomeStreet: 4,
    },
  },
  math: {
    humanName: 'Math',
    gradReq: {
      NYC: 6,
      Lansing: 4,
      Schenectady: 3,
      Uniondale: 3,
      GreatOaks: 3,
      BroomeStreet: 3,
    },
  },
  ssGlobal: {
    humanName: 'Global',
    gradReq: {
      NYC: 4,
      Lansing: 1,
      Schenectady: 2,
      Uniondale: 2,
      GreatOaks: 2,
      BroomeStreet: 2,
    },
  },
  ssUs: {
    humanName: 'US',
    gradReq: {
      NYC: 2,
      Lansing: 1,
      Schenectady: 1,
      Uniondale: 1,
      GreatOaks: 1,
      BroomeStreet: 1,
    },
  },
  ssGovt: {
    humanName: 'Govt',
    gradReq: {
      NYC: 1,
      Lansing: 0.5,
      Schenectady: 0.5,
      Uniondale: 0.5,
      GreatOaks: 0.5,
      BroomeStreet: 0.5,
    },
  },
  ssEcon: {
    humanName: 'Econ',
    gradReq: {
      NYC: 1,
      Lansing: 0.5,
      Schenectady: 0.5,
      Uniondale: 0.5,
      GreatOaks: 0.5,
      BroomeStreet: 0.5,
    },
  },
  ssGovtEcon: {
    humanName: 'Govt / Econ',
    gradReq: {
      NYC: 2,
      Lansing: 1,
      Schenectady: 1,
      Uniondale: 1,
      GreatOaks: 1,
      BroomeStreet: 1,
    },
  },
  sciTotal: {
    humanName: 'Sci',
    gradReq: {
      NYC: 6,
      Lansing: 3,
      Schenectady: 3,
      Uniondale: 3,
      GreatOaks: 3,
      BroomeStreet: 3,
    },
  },
  sciPhysical: {
    humanName: 'Physical',
    gradReq: {
      NYC: 2,
      Lansing: 1,
      Schenectady: 1,
      Uniondale: 1,
      GreatOaks: 1,
      BroomeStreet: 1,
    },
  },
  sciLife: {
    humanName: 'Life',
    gradReq: {
      NYC: 2,
      Lansing: 1,
      Schenectady: 1,
      Uniondale: 1,
      GreatOaks: 1,
      BroomeStreet: 1,
    },
  },
  lote: {
    humanName: 'LOTE',
    gradReq: {
      NYC: 2,
      Lansing: 2,
      Schenectady: 1,
      Uniondale: 1,
      GreatOaks: 1,
      BroomeStreet: 1,
    },
  },
  cte: {
    humanName: 'CTE',
    gradReq: {
      NYC: 0,
      Lansing: 0,
      Schenectady: 0,
      Uniondale: 0,
      GreatOaks: 0,
      BroomeStreet: 0,
    },
  },
  art: {
    humanName: 'Art',
    gradReq: {
      NYC: 2,
      Lansing: 1,
      Schenectady: 1,
      GreatOaks: 1,
      BroomeStreet: 1,
    },
  },
  pe: {
    humanName: 'PE',
    gradReq: {
      NYC: 4,
      Lansing: 0.5,
      Schenectady: 2,
      Uniondale: 2,
      GreatOaks: 2,
      BroomeStreet: 2,
    },
  },
  health: {
    humanName: 'Health',
    gradReq: {
      NYC: 1,
      Lansing: 0.5,
      Schenectady: 0.5,
      Uniondale: 0.5,
      GreatOaks: 0.5,
      BroomeStreet: 0.5,
    },
  },
  total: {
    humanName: 'Total',
    gradReq: {
      NYC: 44,
      Lansing: 22,
      Schenectady: 22,
      Uniondale: 22,
      GreatOaks: 22,
      BroomeStreet: 22,
    },
  },
};
