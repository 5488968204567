import { IRollupGroupingPayload } from '../../actions/rollup-grouping-actions/rollup-grouping-actions';

export const generateQuery = ({ payload }: { payload: IRollupGroupingPayload }) => {
  const { schoolId, filterKeys, groupingKey, columns, focusKey } = payload;

  const columnsArray = [];
  const filterKeysArray = [];

  columns.forEach((col: string) => columnsArray.push(`"${col}"`));

  filterKeys.forEach((key: string) => {
    if (key.includes('=')) {
      const filterVal = key.split('=');
      let filterString = filterVal[1];
      if (filterVal[1].includes('"')) {
        filterString = filterVal[1].replace(/\"/g, '\\"');
      }
      filterKeysArray.push(`"${filterVal[0]}=${filterString}"`);
    } else {
      filterKeysArray.push(`"${key}"`);
    }
  });

  return `{
    RollupGrouping(schoolId: "${schoolId}", filterKeys: [${filterKeysArray}], groupingKey: "${groupingKey}", columns: [${columnsArray}], focusKey: "${focusKey}"){
        key
        human
        tooltip
        sortByGrouping
        sortDirection
        rowData {
          data
          meta
          columnKey
        } 
    }
  }
  `;
};

export const ROLLUP_GROUPING_JOINS = [];
