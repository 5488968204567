import { Injectable } from '@angular/core';

export interface TStudentDessaCalcConfig {
  field: string;
  graphQlKey: string;
  wildcardKey: string;
}

@Injectable()
export class GraphQLStudentDessaHelperService {
  getStudentDessaQuery ({ schoolId, studentId }): string {
    return `
    query ($calcConfig: [StudentDessaCalcConfig!]) {
      StudentDessa(schoolId: "${schoolId}", studentId: "${studentId}", calcConfig: $calcConfig){
        MOST_RECENT_DESSA_SEC_COMPOSITE_T_SCORE
        DESSA_SCREENER_DATA_FOR_PANEL {
          performanceEachAdmin {
            secTScore
            schoolYear
            raterName
            ratingDate
            ratingPeriodName
            secPercentile
            secDescription
            domains {
              name
              percentile
              description
              tScore
            }
          }
          progressBetweenAdmin {
            preAssessmentDescriptiveRange
            postAssessmentDescriptiveRange
            preAssessmentSecTScore
            postAssessmentSecTScore
            changeInSecTScore
            ratingPeriodName
            schoolYear
          }
        }
      }
    }`;
  }

  getStudentSelSupportsQuery ({ schoolId, studentId }): string {
    return `{
      ApertureStudentSupport(schoolId: "${schoolId}", studentId: "${studentId}") {
        _id
        support {
          interventionType
          status
          startDate
          endDate
          scope
          tier
          frequency
          schoolYear
        }
      }
    }`;
  }
};
