import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvSharedTooltipModule } from '../nv-tooltip/nv-shared-tooltip.module';
import { NvIconModule } from '../nv-icon/nv-icon.module';
import { NvFormFieldComponent } from './nv-form-field.component';

@NgModule({
  imports: [CommonModule, NvSharedTooltipModule, NvIconModule],
  declarations: [NvFormFieldComponent],
  exports: [NvFormFieldComponent],
})
export class NvFormFieldModule {}
