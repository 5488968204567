import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvButtonModule } from 'projects/shared/nvps-libraries/design/nv-button/nv-button.module';
import { BaseModalModule } from '../base-modal.module';
import { AssignPlansComponent } from './assign-plans.component';

@NgModule({
  declarations: [AssignPlansComponent],
  imports: [BaseModalModule, CommonModule, NvButtonModule],
  exports: [AssignPlansComponent],
})
export class AssignPlansModule {}
