import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { filter } from 'lodash';

export interface ICSVUploadErrors {
  studentId?: string[];
  collegeName?: string[];
  status?: string[];
  format?: string[];
  rows?: string[];
  recordLimit?: string[];
  fileSize?: string[];
  context?: string;
}

const REQUIREMENTS_GUIDES = {
  COLLEGE_LIST: 'https://docs.google.com/document/d/1K3HmZTG4_8MmohYCusaoim1SYztHd_Ujv-Bk3FB94bg/',
};
@Component({
  templateUrl: './csv-upload-error-modal.component.html',
  styleUrls: ['./csv-upload-error-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CsvUploadErrorModalComponent implements OnInit {
  requirementsUrl: string;
  errorMsgs: string[];
  noteSize: string = 'small';
  notePriority: string = 'danger';

  constructor (
    public dialogRef: MatDialogRef<CsvUploadErrorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ICSVUploadErrors,
  ) {}

  ngOnInit () {
    this.dialogRef.disableClose = true;
    this.errorMsgs = this._buildErrorMsgs();
    this.requirementsUrl = REQUIREMENTS_GUIDES[this.data.context];
  }

  onConfirm (): void {
    this.dialogRef.close(true);
  }

  onCancel (): void {
    this.dialogRef.close(false);
  }

  _buildErrorMsgs (): string[] {
    const { status, format, rows, recordLimit, fileSize, context } = this.data;
    const allErrors = filter([status, format, recordLimit, fileSize], errorType => {
      return !!errorType;
    });
    const errors = [].concat.apply([], allErrors);
    // rows errors are caused by missing student names
    if (rows && rows.length) {
      // TODO: refactor to account for more diverse set of row errors in future builds (JE)
      let err;
      if (context === 'COLLEGE_LIST') {
        err = 'Name field is missing on one or more rows where colleges have been entered';
      }
      errors.push(err);
    }
    return errors;
  }
}
