<div class="flag-modal">
  <form [formGroup]="form" novalidate class="solution">
    <!-- HEADER -->
    <h2>
      <span>{{ data.type }}</span>
    </h2>

    <!-- RADIO BUTTONS -->
    <div class="solutions" *ngIf="radiobuttons">
      <div class="choices">
        <mat-radio-group class="nv-mat-radio-group choices" formControlName="radioChoice">
          <mat-radio-button class="choice" *ngFor="let choice of radiobuttons.choices" [value]="choice.value">
            {{ choice.text }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <!-- TEXT AREA -->
    <textarea *ngIf="textarea" [placeholder]="textarea.placeholder" formControlName="textarea"> </textarea>

    <!-- ACTION BUTTONS -->
    <div class="actions" *ngIf="buttons">
      <nv-button *ngIf="buttons.confirm && form.valid" (click)="onSubmit(form)"> {{ buttons.confirm.text }} </nv-button>
      <nv-button type="secondary" (click)="onCancel()"> {{ buttons.cancel.text }} </nv-button>
    </div>
  </form>
</div>
