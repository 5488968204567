<div class="credit-gaps-transfer-wizard">
    <ng-container *ngIf="(flattenedStudents$|async) as flattenedStudents">
        <sdc-dropdown [label]="'Class of'" [options]="classOfDropdownOptions" [selection]="dropdownSelection.value"
            (dropdownChange)="handleDropdownChange($event)">
        </sdc-dropdown>
        <credit-gaps-transfer-table [filteredStudents]="flattenedStudents" [currentFilter]="dropdownSelection.value">
        </credit-gaps-transfer-table>
        <credit-gaps-subject-table [filteredStudents]="flattenedStudents" [currentFilter]="dropdownSelection.value">
        </credit-gaps-subject-table>
    </ng-container>

    <div class="stepper">
        <ng-container *ngIf="flattenedStudents$|async">
            <div class="app-headline">
                <h3>Review All Unaddressed Credit Gaps</h3>
            </div>
        </ng-container>

        <mat-vertical-stepper [linear]="isLinear" #stepper (selectionChange)="onStepChange($event)"
            [selectedIndex]="selectedStepIndex">
            <ng-container *ngIf="(flattenedStudents$|async) as flattenedStudents">
                <ng-container *ngIf="(school$|async) as school">

                    <mat-step label={{instructionStep?.stepLabel}}>
                        <instruction-step [description]="instructionDescription"></instruction-step>
                        <ng-container [ngTemplateOutlet]="navigation"></ng-container>
                    </mat-step>

                    <mat-step label={{gradPlanningStep?.stepLabel}}>
                        <grad-plans-step [filteredStudents]="flattenedStudents">
                        </grad-plans-step>
                        <ng-container [ngTemplateOutlet]="navigation"></ng-container>
                    </mat-step>

                    <mat-step label={{reviewGapsStep?.stepLabel}}>
                        <review-gaps-step [filteredStudents]="flattenedStudents"
                            [currentFilter]="dropdownSelection.value">
                        </review-gaps-step>
                        <ng-container [ngTemplateOutlet]="navigation"></ng-container>
                    </mat-step>

                    <mat-step label={{doFinalCheckStep?.stepLabel}}>
                        <do-final-check [filteredStudents]="flattenedStudents" [currentFilter]="dropdownSelection.value" [school]="school"></do-final-check>
                        <ng-container [ngTemplateOutlet]="navigation"></ng-container>
                    </mat-step>
                </ng-container>
            </ng-container>

            <ng-template #navigation>
                <div>
                    <button *ngIf="stepper?.selectedIndex !== 0" mat-button matStepperPrevious>BACK</button>
                    <button *ngIf="stepper?.selectedIndex !== stepper?.steps?.length-1" mat-button
                        matStepperNext>CONTINUE</button>
                </div>
            </ng-template>
        </mat-vertical-stepper>
    </div>

</div>