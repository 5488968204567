import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ImSchool } from 'Src/ng2/shared/services/im-models/im-school';

export type TValidPriorityGroupKeys =
  | 'ONE_TERM_AWAY'
  | 'TWO_TERMS_AWAY'
  | 'THREE_TERMS_AWAY'
  | 'FOUR_TERMS_AWAY'
  | 'PLANNED_BEYOND_4'
  | 'PLANNED_NON_GRAD'
  | 'PLAN_INCOMPLETE'
  | 'NONE';

export interface IPriorityGroupDefinition {
  key: TValidPriorityGroupKeys;
  priorityVal: number;
  priorityLabel: string;
  studentPriorityGroupingValue: string;
  plannedGradDateFilter?: string;
}

@Injectable()
export class RegentsPrepPriorityGroupingsTransferService {
  constructor (private ImSchool: ImSchool) {}

  private next4GradDates = this.ImSchool.getNextFourGradDatesForTransfer();
  private oneTermAway = this.next4GradDates[0];
  private twoTermsAway = this.next4GradDates[1];
  private threeTermsAway = this.next4GradDates[2];
  private fourTermsAway = this.next4GradDates[3];

  private _priorityGroupings: IPriorityGroupDefinition[] = [
    {
      key: 'ONE_TERM_AWAY',
      priorityVal: 1,
      priorityLabel: `Planned ${this.oneTermAway.humanName} Grads needing Regents prep support.`,
      studentPriorityGroupingValue: `Planned ${this.oneTermAway.humanName} Grad needing Regents prep support`,
      plannedGradDateFilter: this.oneTermAway.humanName,
    } as IPriorityGroupDefinition,

    {
      key: 'TWO_TERMS_AWAY',
      priorityVal: 2,
      priorityLabel: `Planned ${this.twoTermsAway.humanName} Grads needing Regents prep support.`,
      studentPriorityGroupingValue: `Planned ${this.twoTermsAway.humanName} Grad needing Regents prep support`,
      plannedGradDateFilter: this.twoTermsAway.humanName,
    } as IPriorityGroupDefinition,

    {
      key: 'THREE_TERMS_AWAY',
      priorityVal: 3,
      priorityLabel: `Planned ${this.threeTermsAway.humanName} Grads needing Regents prep support.`,
      studentPriorityGroupingValue: `Planned ${this.threeTermsAway.humanName} Grad needing Regents prep support`,
      plannedGradDateFilter: this.threeTermsAway.humanName,
    } as IPriorityGroupDefinition,

    {
      key: 'FOUR_TERMS_AWAY',
      priorityVal: 4,
      priorityLabel: `Planned ${this.fourTermsAway.humanName} Grads needing Regents prep support.`,
      studentPriorityGroupingValue: `Planned ${this.fourTermsAway.humanName} Grad needing Regents prep support`,
      plannedGradDateFilter: this.fourTermsAway.humanName,
    } as IPriorityGroupDefinition,

    {
      key: 'PLANNED_BEYOND_4',
      priorityVal: 5,
      priorityLabel: `Planned to graduate beyond ${this.fourTermsAway.humanName} needing Regents prep support.`,
      studentPriorityGroupingValue: `Planned Beyond ${this.fourTermsAway.humanName} Grad needing Regents prep support`,
      plannedGradDateFilter: `Beyond ${this.fourTermsAway.humanName}`,
    } as IPriorityGroupDefinition,

    {
      key: 'PLANNED_NON_GRAD',
      priorityVal: 6,
      priorityLabel: 'Planned Non-Graduates needing Regents prep support.',
      studentPriorityGroupingValue: 'Planned Non-Grad needing Regents prep support',
      plannedGradDateFilter: 'Planned Non-Grad',
    } as IPriorityGroupDefinition,

    {
      key: 'PLAN_INCOMPLETE',
      priorityVal: 7,
      priorityLabel: 'Students with incomplete grad plans needing Regents prep support.',
      studentPriorityGroupingValue: 'No Grad Plan needing Regents prep support',
      plannedGradDateFilter: 'Plan Incomplete',
    } as IPriorityGroupDefinition,

    {
      key: 'NONE',
      priorityVal: 8,
      priorityLabel: 'Students not needing Regents prep support.',
      studentPriorityGroupingValue: 'All planned exams supported',
      plannedGradDateFilter: 'None',
    } as IPriorityGroupDefinition,
  ];

  private _getPriorityGroupByKey (key: TValidPriorityGroupKeys): IPriorityGroupDefinition {
    return _.find(this._priorityGroupings, { key });
  }

  public getStudentValueForPriorityGroupByKey (key: TValidPriorityGroupKeys): string {
    const priorityGroup = this._getPriorityGroupByKey(key);
    return priorityGroup.studentPriorityGroupingValue;
  }

  public getPriorityGroupsByPlannedGradDateFilter (plannedGradDateFilter?): IPriorityGroupDefinition[] {
    return plannedGradDateFilter
      ? (_.filter(this._priorityGroupings, { plannedGradDateFilter }) as IPriorityGroupDefinition[])
      : this._priorityGroupings;
  }
}
