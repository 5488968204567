import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { NvPillComponent } from '../nv-pill/nv-pill.component';

/**
 *
 * The Pill Button extends the Pill and adds interactivity to it.
 *
 * Use the same inputs on the Pill Button as you would a Pill
 *
 */
@Component({
  selector: 'nv-pill-button',
  templateUrl: './nv-pill-button.component.html',
  styleUrls: ['./nv-pill-button.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NvPillButtonComponent extends NvPillComponent {
  // Inputs inherited

  /**
   *
   * The HTML `type` attribute to apply to the button.
   */
  @Input() htmlType: 'submit' | 'reset' | 'button' = 'button';

  /**
   *
   *
   */
  @Output() clickButton: EventEmitter<any> = new EventEmitter<any>();

  @Input() ariaLabelData?: {
    iconAriaLabel?: string;
  } = {
      iconAriaLabel: null,
    };

  get usesDarkRipple () {
    return !this.isInverted;
  }

  handleClick (event?: Event) {
    if (this.isDisabled !== true) {
      this.clickButton.emit(true); // Emit the `press` event
    } else {
      return false;
    }
  }
}
