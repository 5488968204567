import { Injectable } from '@angular/core';
import { IColumn } from '../../models/list-models';
import { TValidGraphType } from '../../models/viz-models';

@Injectable()
export class RollupGroupingStackedBarService {
  getHeaderBorderStyle ({ focusName, columnKey }: { focusName: string; columnKey: string }): string {
    switch (focusName) {
      case 'Attendance risk groups':
      case 'Attendance risk groups (transfer)':
      case 'Chronically absent students':
      case '80% or less attendance':
        return this.getHeaderBorderStyleForAttRiskGroups(columnKey);
      case 'Current term course scheduling status based on source system data':
        return this.getHeaderBorderStyleForSchedToEndTermOnTrack(columnKey);
      case 'Course planning status based on Portal plans':
        return this.getHeaderBorderStyleForCoursePlanningStatus(columnKey);
      case 'Have Complete Grad Plans':
        return this.getHeaderBorderStyleForGradPlan(columnKey);
      case 'Upcoming Admin Regents Scheduling Status':
        return this.getHeaderBorderStyleForRegentsSchedulingStatus(columnKey);
      case 'Regents Planning Status':
        return this.getHeaderBorderStyleForRegentsPlanningStatus(columnKey);
      case 'ML Students':
        return this.getHeaderBorderStyleForMlStudents(columnKey);
      case 'Latest MP course status':
        return this.getHeaderBorderStyleForLatestMPCourseStatus(columnKey);
      default:
        return '';
    }
  }

  getHeaderBorderStyleForAttRiskGroups (columnKey: string): string {
    let style: string;
    switch (columnKey) {
      case 'NV_ATT_RISK_GROUP_LESS_60':
      case 'NV_TRANSFER_ATT_RISK_GROUP_LESS_33':
        style = 'color-border-lta';
        break;
      case 'NV_ATT_RISK_GROUP_60_TO_79':
      case 'NV_TRANSFER_ATT_RISK_GROUP_33_TO_59':
        style = 'color-border-severe';
        break;
      case 'NV_ATT_RISK_GROUP_80_TO_89':
      case 'NV_TRANSFER_ATT_RISK_GROUP_60_TO_79':
        style = 'color-border-chron';
        break;
      case 'NV_ATT_RISK_GROUP_90_TO_94':
      case 'NV_TRANSFER_ATT_RISK_GROUP_80_TO_90':
        style = 'color-border-at-risk';
        break;
      case 'NV_ATT_RISK_GROUP_95_TO_100':
      case 'NV_TRANSFER_ATT_RISK_GROUP_90_TO_100':
        style = 'color-border-not-at-risk';
        break;
      case 'NV_ATT_RISK_GROUP_NO_DATA':
      case 'NV_TRANSFER_ATT_RISK_GROUP_NO_DATA':
        style = 'color-border-no-data';
        break;
      default:
        style = '';
        break;
    }
    return style;
  }

  private getHeaderBorderStyleForCoursePlanningStatus (columnKey: string): string {
    let style: string;
    switch (columnKey) {
      case 'SCHEDULED_TO_END_TERM_ON_TRACK_IN_CREDITS':
        style = 'color-border-sched-to-end-term-on-track-in-credits';
        break;
      case 'FULLY_PLANNED_FOR_ALL_GAPS':
        style = 'color-border-fully-planned-for-all-gaps';
        break;
      case 'MISSING_PLANS_TO_ADDRESS_CREDIT_GAPS':
        style = 'color-border-missing-plans-to-address-credit-gaps';
        break;
      default:
        style = '';
        break;
    }
    return style;
  }

  private getHeaderBorderStyleForSchedToEndTermOnTrack (columnKey: string): string {
    let style: string;
    switch (columnKey) {
      case 'SCHED_TO_END_TERM_ON_TRACK':
        style = 'color-border-sched-to-end-term-on-track';
        break;
      case 'ZERO_ONE_TO_ONE_GAPS':
        style = 'color-border-sched-zero-one-to-one';
        break;
      case 'ONE_TO_TWO_GAPS':
        style = 'color-border-sched-one-to-two';
        break;
      case 'TWO_TO_FIVE_GAPS':
        style = 'color-border-sched-two-to-five';
        break;
      case 'FIVE_PLUS_GAPS':
        style = 'color-border-sched-five-plus';
        break;
      case 'CREDITS_GAPS_SECOND_BAND':
        style = 'color-border-sched-credits-gaps-second-band';
        break;
      case 'CREDITS_GAPS_THIRD_BAND':
        style = 'color-border-sched-credits-gaps-third-band';
        break;
      case 'CREDITS_GAPS_FOURTH_BAND':
        style = 'color-border-sched-credits-gaps-fourth-band';
        break;
      case 'CREDITS_GAPS_FIFTH_BAND':
        style = 'color-border-sched-credits-gaps-fifth-band';
        break;
      default:
        style = '';
        break;
    }
    return style;
  }

  private getHeaderBorderStyleForGradPlan (columnKey: string): string {
    let style: string;
    switch (columnKey) {
      case 'GRAD_PLAN_WITHIN_REACH':
        style = 'color-border-grad-plan-within-reach';
        break;
      case 'GRAD_PLAN_MORE_AMBITIOUS_POSSIBLE':
        style = 'color-border-grad-plan-ambitious';
        break;
      case 'GRAD_PLAN_PLANNED_NON_GRADS':
        style = 'color-border-grad-plan-non-grads';
        break;
      case 'GRAD_PLAN_AT_RISK':
        style = 'color-border-grad-plan-at-risk';
        break;
      case 'NO_GRAD_PLAN':
        style = 'color-border-no-grad-plan';
        break;
      default:
        style = '';
        break;
    }
    return style;
  }

  private getHeaderBorderStyleForRegentsSchedulingStatus (columnKey: string): string {
    let style: string;
    switch (columnKey) {
      case 'FULFILLED_ALL_REG_REQ':
        style = 'color-border-regents-fulfilled-all-reqs';
        break;
      case '0_REG_NOT_SCHED':
        style = 'color-border-regents-0-reg-not-sched';
        break;
      case '1_REG_NOT_SCHED':
        style = 'color-border-regents-1-reg-not-sched';
        break;
      case '2_TO_3_REG_NOT_SCHED':
        style = 'color-border-regents-2-3-reg-not-sched';
        break;
      case '4_PLUS_REG_NOT_SCHED':
        style = 'color-border-regents-4-plus-reg-not-sched';
        break;
      default:
        style = '';
        break;
    }
    return style;
  }

  private getHeaderBorderStyleForRegentsPlanningStatus (columnKey: string): string {
    let style: string;
    switch (columnKey) {
      case 'FULFILLED_ALL_REG_REQ':
        style = 'color-border-regents-fulfilled-all-reqs';
        break;
      case 'FULLY_PLANNED_NEXT_ADMIN':
        style = 'color-border-regents-0-reg-not-sched';
        break;
      case 'FULLY_PLANNED_FUTURE_ADMIN':
        style = 'color-border-regents-fully-planned-reg';
        break;
      case 'MISSING_PLANS_REG':
        style = 'color-border-regents-missing-plans-reg';
        break;
      default:
        style = '';
        break;
    }
    return style;
  }

  private getHeaderBorderStyleForMlStudents (columnKey: string) {
    let style: string;
    switch (columnKey) {
      case 'CURRENT_ELL':
        style = 'color-border-current-ell';
        break;
      case 'FORMER_ELL':
        style = 'color-border-former-ell';
        break;
      case 'EVER_ELL':
        style = 'color-border-ever-ell';
        break;
      case 'NEVER_ELL':
        style = 'color-border-never-ell';
        break;
      default:
        style = '';
        break;
    }
    return style;
  }

  private getHeaderBorderStyleForLatestMPCourseStatus (columnKey: string) {
    let style: string;
    switch (columnKey) {
      case 'PASSING_ALL_COURSES':
        style = 'color-border-passing-all-courses';
        break;
      case 'FAILING_ONE_COURSE':
        style = 'color-border-failing-one-course';
        break;
      case 'FAILING_TWO_COURSES':
        style = 'color-border-failing-two-courses';
        break;
      case 'FAILING_THREE_PLUS_COURSES':
        style = 'color-border-failing-three-plus-courses';
        break;
      default:
        style = '';
        break;
    }
    return style;
  }

  addStackedBarColorConfigToColumns ({
    graphType,
    focusName,
    columns,
    targetColumnKeys,
  }: {
    graphType: TValidGraphType;
    focusName: string;
    columns: IColumn[];
    targetColumnKeys: string[];
  }): IColumn[] | undefined {
    if (graphType === 'Stacked Bar Percent') {
      const columnsWithCellConfig = columns.map((col: any) => {
        const style = this.getHeaderBorderStyle({ focusName, columnKey: col.graphQlKey });
        const shouldAddConfig = targetColumnKeys.includes(col.graphQlKey);
        const newCol = shouldAddConfig
          ? Object.assign({}, col, { cellConfig: 'GRAPH_INDICATOR_BG', style })
          : Object.assign({}, col, { cellConfig: null, style });
        return newCol;
      });
      return columnsWithCellConfig;
    }
  }
}
