import { RevokeConsentStatusComponent } from './revoke-consent-status/revoke-consent-status.component';
import { RequestConsentComponent } from './request-consent/request-consent.component';
import { NvDropdownModule } from '../../../../nvps-libraries/design/nv-dropdown/nv-dropdown.module';
import { BaseModalModule } from '../base-modal.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvButtonModule } from '../../../../../projects/shared/nvps-libraries/design/nv-button/nv-button.module';
import { MatDialogModule } from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { EditConsentStatusComponent } from './edit-consent-status/edit-consent-status.component';
import { ConsentStatusModalComponent } from './consent-status-modal.component';
import { DeclineConsentStatusComponent } from './decline-consent-status/decline-consent-status.component';

@NgModule({
  imports: [
    CommonModule,
    BaseModalModule,
    NvDropdownModule,
    NvButtonModule,
    MatDialogModule,
    ReactiveFormsModule,
  ],
  exports: [],
  declarations: [
    EditConsentStatusComponent,
    RevokeConsentStatusComponent,
    RequestConsentComponent,
    DeclineConsentStatusComponent,
    ConsentStatusModalComponent,
  ],
  providers: [],
})
export class ConsentStatusGuardianModule {}
