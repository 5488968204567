import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import * as schoolActions from '../actions/school-actions';
import { ApiService } from './../../shared/services/api-service/api-service';

@Injectable()
export class SchoolEffects {
  constructor (private actions$: Actions, private apiService: ApiService) {}

  loadSchool$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(schoolActions.LOAD_SCHOOL),
      switchMap((action: { type: string; payload: string }) => {
        const schoolId = action.payload;
        return this.apiService.getSchool(schoolId).pipe(
          switchMap(school => [new schoolActions.LoadSchoolSuccess(school)]),
          catchError(error => of(new schoolActions.LoadSchoolFail(error))),
        );
      }),
    );
  });

  updateSchool$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(schoolActions.UPDATE_SCHOOL),
      switchMap((action: { type: string; payload: any}) => {
        const { payload } = action;
        return this.apiService.updateSchool(payload).pipe(
          switchMap(school => {
            return [
              new schoolActions.UpdateSchoolSuccess(school),
            ];
          }),
          catchError(error => {
            return from([new schoolActions.UpdateSchoolFail(error)]);
          }),
        );
      }),
    );
  });
}
