import * as schoolActions from '../actions/school-actions';

interface ISchoolState {
  loading: boolean;
  loaded: boolean;
  school: object;
  updated: boolean;
}

export const initialState: ISchoolState = {
  loading: false,
  loaded: false,
  school: {},
  updated: null,
};

export function reducer (state: ISchoolState = initialState, action: schoolActions.SchoolAction): ISchoolState {
  switch (action.type) {
    case schoolActions.LOAD_SCHOOL: {
      return { ...state, loading: true, loaded: false };
    }

    case schoolActions.LOAD_SCHOOL_SUCCESS: {
      const school = action.payload;
      return {
        ...state,
        loading: false,
        loaded: true,
        school,
      };
    }

    case schoolActions.LOAD_SCHOOL_FAIL: {
      return { ...state, loading: false, loaded: false };
    }

    case schoolActions.UPDATE_SCHOOL_SUCCESS: {
      const school = action.payload;
      return {
        ...state,
        loading: false,
        loaded: true,
        updated: true,
        school,
      };
    }

    case schoolActions.UPDATE_SCHOOL_FAIL: {
      return { ...state, loading: false, loaded: true, updated: false };
    }

    default:
      return state;
  }
}
