export const TermsToGradMetrics = {
  1: {
    humanName: '1 term',
    creditReq: 38.5,
    regentsReq: 4,
  },
  2: {
    humanName: '2 terms',
    creditReq: 33,
    regentsReq: 3,
  },
  3: {
    humanName: '3 terms',
    creditReq: 27.5,
    regentsReq: 2,
  },
  4: {
    humanName: '4 terms',
    creditReq: 22,
    regentsReq: 1,
  },
  5: {
    humanName: '5 terms',
    creditReq: 16.5,
    regentsReq: 0,
  },
  6: {
    humanName: '6 terms',
    creditReq: 11,
    regentsReq: 0,
  },
  7: {
    humanName: '7 terms',
    creditReq: 5.5,
    regentsReq: 0,
  },
  8: {
    humanName: '8 terms',
    creditReq: 0,
    regentsReq: 0,
  },
};
