import { ApiService } from 'Src/ng2/shared/services/api-service/api-service';
import { Component, Input, OnInit, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { catchError, map, startWith, switchMap, shareReplay } from 'rxjs/operators';
import { IPickerOption } from '../../../../../../../projects/shared/nvps-libraries/design/nv-multi-picker/nv-multi-picker.interface';
import { IDropdownOption } from '../../../../../../../projects/shared/nvps-libraries/design/interfaces/design-library.interface';
import { combineLatest, concat, EMPTY, Observable, of, throwError } from 'rxjs';
import { ISyncToolFilters } from '../../../../../store';

export interface IGridData {
  PLANNED_GRAD_YEAR_YEAR_ONLY?: string;
  GRADE?: string;
  CLASS?: number;
  OFFICIAL_CLASS?: string;
  STATUS_CATEGORY: string;
}
export interface IStudentSelectorOutput {
  spreadsheetName: string;
  filters: ISyncToolFilters;
};

export type IStudentSelectorInput = Partial<IStudentSelectorOutput>;

/* eslint-disable */
export enum StudentFilterCategories {
  BY_CLASS = 'class',
  BY_GRADE = 'grade',
  BY_OFFICIAL_CLASS = 'officialClass',
  BY_PLANNED_GRAD_DATE = 'plannedGradDate'
}

const STUDENT_FILTER_CATEGORY_KEYS = Object.values(StudentFilterCategories);

enum FilterTypes {
  BY_STATUS = 'byStatus',
  BY_STUDENTS = 'byStudents'
}

const STUDENT_FILTER_CATEGORY_KEY_TO_LABEL = {
  [StudentFilterCategories.BY_CLASS]: 'CLASSES',
  [StudentFilterCategories.BY_GRADE]: 'GRADES',
  [StudentFilterCategories.BY_OFFICIAL_CLASS]: 'OFFICIAL CLASSES',
  [StudentFilterCategories.BY_PLANNED_GRAD_DATE]: 'PLANNED GRAD DATE',
};

const DEFAULT_STUDENT_FILTER_CATEGORY = 'All students';
const DEFAULT_STATUS_FILTER_OPTION_KEY = 'active';

const hasStudentFilterOptions = (studentFilterCategory) => {
  return studentFilterCategory !== DEFAULT_STUDENT_FILTER_CATEGORY;
};

@Component({
  selector: 'student-selector',
  templateUrl: './student-selector.component.html',
  styleUrls: ['./student-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class StudentSelectorComponent implements OnInit {
  @Input() schoolId: string;
  @Input() isEditMode: boolean;
  @Input() initialFormData: IStudentSelectorInput;
  @Output() completed: EventEmitter<IStudentSelectorOutput> = new EventEmitter<IStudentSelectorOutput>();

  form: FormGroup;
  studentFilterOptions$: Observable<IPickerOption[]>;
  showStudentFilterOptions: boolean = false;
  studentFilterCategoryLabel: string = '';

  reqdOptions$: Observable<{statusOptions: IPickerOption[], studentFilterCategories: IDropdownOption[]}>;
  studentCount$: Observable<string>;
  statusTooltip: string = 'Active includes "A" students. Active-NDG includes "A-NDG" (active, non-degree) students. Discharged Graduates includes "D-grad" (discharged graduate) students. Negative Discharges includes "D-neg" (negative discharge) and "D-NDG" (discharged, non-degree) students.';

  constructor (
    private apiService: ApiService,
    private formBuilder: FormBuilder,
  ) {
  }

  ngOnInit () {
    this.form = this.buildForm();
    this.studentFilterCategoryLabel = STUDENT_FILTER_CATEGORY_KEY_TO_LABEL[this.form.controls.studentFilterCategory.value] || '';

    if (hasStudentFilterOptions(this.form.controls.studentFilterCategory.value)) {
      this.showStudentFilterOptions = true;
    }

    const statusOptions$ = this.querySyncedSheetFilters$(FilterTypes.BY_STATUS).pipe(
      shareReplay(1)
    );

    this.reqdOptions$ = combineLatest([
      statusOptions$,
      this.querySyncedSheetFilters$(FilterTypes.BY_STUDENTS),
    ]).pipe(
      map(([statusOptions, studentFilterCategories]) => ({ statusOptions, studentFilterCategories }))
    );

    this.studentFilterOptions$ = this.form.controls.studentFilterCategory.valueChanges.pipe(
      startWith(this.form.controls.studentFilterCategory.value),
      switchMap(studentFilterCategory => {
        if (hasStudentFilterOptions(studentFilterCategory)) {
          return concat(
            // of([]) resets the observable for studentFilterOptions while we wait for the api to return the query
            of([]),
            this.querySyncedSheetFilters$(studentFilterCategory),
          )
        }
        return of([])
      }),
      shareReplay(1)
    )
    
    this.studentCount$ = combineLatest([
      this.form.controls.studentFilterCategory.valueChanges.pipe(
        startWith(this.form.controls.studentFilterCategory.value)
      ),
      statusOptions$,
      this.form.controls.statusOptions.valueChanges.pipe(
        startWith(this.form.controls.statusOptions.value)
      ),
      this.studentFilterOptions$,
      this.form.controls.studentFilterOptions.valueChanges.pipe(
        startWith(this.form.controls.studentFilterOptions.value)
      ),
    ]).pipe(
      switchMap(filterData => {
          const [
            selectedStudentFilterCat,
            statusOptions,
            selectedStatusOptions,
            studentFilterOptions,
            selectedStudentFilterOptions, 
          ] = filterData;
          const shouldQueryCount = selectedStudentFilterCat === DEFAULT_STUDENT_FILTER_CATEGORY || !!selectedStudentFilterOptions.length;

          if (!shouldQueryCount) {
            return of('0');
          }

          const clientFilters = {
            status: selectedStatusOptions,
          }
          if (hasStudentFilterOptions(selectedStudentFilterCat)) {
            clientFilters[selectedStudentFilterCat] = selectedStudentFilterOptions;
          }
        return this.queryStudentCount$(clientFilters);
      }),
      startWith('0')
    )
  }

  private queryStudentCount$ (clientFilters): Observable<string> {
    const query = `
      query ($clientFilters: ClientFilters) {
        SyncedSheetStudentCount(
          schoolId: "${this.schoolId}",
          clientFilters: $clientFilters
        ) {
          count
        }
      }
    `;

    const fetchObject = {
      query,
      fetchPolicy: 'no-cache',
      variables: { clientFilters },
    };

    return this.apiService.getStudentsGraphQL(fetchObject).pipe(
      map((res) => res.data.SyncedSheetStudentCount.count.toString()),
      catchError(err => {
        if (err) {
          return throwError(EMPTY);
        }
      }),
    );
  }

  private buildForm (): FormGroup {
    const studentFilterCategoryInit = (
      STUDENT_FILTER_CATEGORY_KEYS.find(
        (key) => !!((this.initialFormData.filters || {})[key])
      )
      || DEFAULT_STUDENT_FILTER_CATEGORY
    );

    return this.formBuilder.group(
      {
        spreadsheetName: [this.initialFormData?.spreadsheetName || '', Validators.required],
        studentFilterCategory: [studentFilterCategoryInit, Validators.required],
        studentFilterOptions: [(this.initialFormData.filters || {})[studentFilterCategoryInit] || []],
        statusOptions: [this.initialFormData.filters?.status || [DEFAULT_STATUS_FILTER_OPTION_KEY]],
      },
      {
        validators: (control) => {
          const studentFilterCategory = control.get('studentFilterCategory');
          const studentFilterOptions = control.get('studentFilterOptions');
          const studentFilterOptionsRequired = studentFilterCategory && hasStudentFilterOptions(studentFilterCategory.value);
          const activeStatusSelected = control.get('statusOptions').value.length > 0;
          if (!activeStatusSelected || (studentFilterOptionsRequired && studentFilterOptions && studentFilterOptions.value.length === 0)) {
            return { selectedOptionsMissing: true };
          }
          return null;
        },
      },
    );
  }

  private querySyncedSheetFilters$ (filter: string): Observable<IPickerOption[]> {
    const query = `
        {
          SyncedSheetFilters(schoolId: "${this.schoolId}", filter: "${filter}") {
            key
            human
          }
      }`;
    const fetchObject = {
      query,
      fetchPolicy: 'no-cache',
    };

    return this.apiService.getStudentsGraphQL(fetchObject).pipe(
      map((res: { data: { SyncedSheetFilters: IPickerOption[] }}) => {
        const filters = res.data.SyncedSheetFilters;
        const currYearPgFilterOptions = this.initialFormData.filters?.plannedGradDate || [];
        return filter === 'plannedGradDate' ? updatePlannedGradFilterOptions(
          currYearPgFilterOptions,
          filters
        ) : filters
      }),
      catchError(err => {
        if (err) {
          return throwError(EMPTY);
        }
      }),
    );
  }

  onStudentFilterCategoryChange ($event): void {
    this.form.controls.studentFilterCategory.setValue($event);
    this.form.controls.studentFilterOptions.setValue([]);
    this.showStudentFilterOptions = hasStudentFilterOptions($event);
    this.studentFilterCategoryLabel = STUDENT_FILTER_CATEGORY_KEY_TO_LABEL[$event];
  }

  onStatusOptionsChange ($event): void {
    this.form.controls.statusOptions.setValue($event);
  }

  onStudentFilterOptionsChange ($event): void {
    this.form.controls.studentFilterOptions.setValue($event);
  }

  onNext (): void {
    const filters = STUDENT_FILTER_CATEGORY_KEYS.reduce((acc, key) => {
      if (!acc[key]) {
        acc[key] = null;
      }
      if (key === this.form.controls.studentFilterCategory.value) {
        acc[key] = this.form.controls.studentFilterOptions.value;
      }
      return acc;
    }, { status: this.form.controls.statusOptions.value });

    this.completed.emit({
      spreadsheetName: this.form.controls.spreadsheetName.value,
      filters
    });
  }
}

export const updatePlannedGradFilterOptions = (
  keys: string[], 
  currYearPgFilterOptions: IPickerOption[]
): IPickerOption[] => {
  const currFilterOptionKeys = currYearPgFilterOptions.map(
    ({ key }) => key
  )
  const pastYearPgFilterOptions = keys.reduce(
    (acc, key) => {
      const plannedGradFilterOption = `Planned ${key} grads`;
      const isFilterOpInExistingOptions = currFilterOptionKeys.includes(key);
      if (!isFilterOpInExistingOptions) {
        acc.push({ key: key, human: plannedGradFilterOption });
      }
      return acc;
    }, []
  )
  return [
    ...pastYearPgFilterOptions,
    ...currYearPgFilterOptions
  ];
}
