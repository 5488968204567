import { createFeatureSelector, createSelector } from '@ngrx/store';

const getPortalState = createFeatureSelector('PORTAL_STORE');

export const getCsvDataState = createSelector(
  getPortalState,
  (state: any) => {
    return state.csvData;
  },
);
