/* tslint:disable */
import { IPathSubdoc } from './../../shared/typings/interfaces/studentPaths.interface';
import { Action } from '@ngrx/store';
import { TValidStudentPathStatuses } from '../../shared/typings/types/student-path.types';
import { TBatchActionsOrigin } from 'Src/ng2/shared/components/nv-actions/nv-actions.interface';
export const LOAD_ALL_STUDENT_PATHS = '[Portal] Load All Student Paths';
export const LOAD_ALL_STUDENT_PATHS_FAIL = '[Portal] Load All Student Paths Fail';
export const LOAD_ALL_STUDENT_PATHS_SUCCESS = '[Portal] Load All Student Paths Success';
export const LOAD_STUDENT_PATH = '[Portal] Load Student Path';
export const LOAD_STUDENT_PATH_SUCCESS = '[Portal] Load Student Path Success';
export const LOAD_STUDENT_PATH_FAIL = '[Portal] Load Student Path Fail';
export const CREATE_STUDENT_PATH = '[Portal] Create Student Path';
export const CREATE_STUDENT_PATH_SUCCESS = '[Portal] Create Student Path Success';
export const CREATE_STUDENT_PATH_FAIL = '[Portal] Create Student Path Fail';
export const BULK_CREATE_STUDENT_PATH = '[Portal] Bulk Create Student Path';
export const BULK_CREATE_STUDENT_PATH_SUCCESS = '[Portal] Bulk Create Student Path Success';
export const BULK_CREATE_STUDENT_PATH_FAIL = '[Portal] Bulk Create Student Path Fail';
export const BULK_UPDATE_STUDENT_PATH = '[Portal] Bulk Update Student Path';
export const BULK_UPDATE_STUDENT_PATH_SUCCESS = '[Portal] Bulk Update Student Path Success';
export const BULK_UPDATE_STUDENT_PATH_FAIL = '[Portal] Bulk Update Student Path Fail';
export const UPDATE_STUDENT_PATH = '[Portal] Update Student Path';
export const UPDATE_STUDENT_PATH_SUCCESS = '[Portal] Update Student Path Success';
export const UPDATE_STUDENT_PATH_FAIL = '[Portal] Update Student Path Fail';

export interface IStudentPathCreatePayload {
  schoolId: string;
  studentId: string;
  pathId?: string;
  pathData: {
    path: IPathSubdoc,
    status: string
  };
}

export interface IStudentPathBulkCreatePayload {
  schoolId: string;
  studentIds: string[];
  college: string;
  origin?: TBatchActionsOrigin;
}

export interface IStudentPathBulkUpdatePayload {
  studentPathIds: string[];
  downgradeMap?: {
    accepted: string[];
    acceptedWithSpecialOp: string[];
  };
  studentIds?: string[];
  // College is optional because the delete payload does not require it
  college?: string;
  schoolId?: string;
  status: TValidStudentPathStatuses;
  origin?: TBatchActionsOrigin;
}

export interface IStudentPathListPayload {
  schoolId: string;
  studentId: string;
}

export interface IStudentPathUpdatePayload {
  studentPathId: string;
  status: string;
  schoolId?: string;
  studentId?: string;
}

export class LoadAllStudentPaths implements Action {
  readonly type = LOAD_ALL_STUDENT_PATHS;
  constructor (public payload: any) {}
}

export class LoadAllStudentPathSuccess implements Action {
  readonly type = LOAD_ALL_STUDENT_PATHS_SUCCESS;
  constructor (public payload: Array<any>) {}
}

export class LoadAllStudentPathFail implements Action {
  readonly type = LOAD_ALL_STUDENT_PATHS_FAIL;
  constructor (public payload) {}
}

export class LoadStudentPath implements Action {
  readonly type = LOAD_STUDENT_PATH;
  constructor (public payload: IStudentPathListPayload) {}
}

export class LoadStudentPathSuccess implements Action {
  readonly type = LOAD_STUDENT_PATH_SUCCESS;
  constructor (public payload: any) {}
}

export class LoadStudentPathFail implements Action {
  readonly type = LOAD_STUDENT_PATH_FAIL;
  constructor (public payload) {}
}

export class CreateStudentPath implements Action {
  readonly type = CREATE_STUDENT_PATH;
  constructor (public payload: IStudentPathCreatePayload) {}
}

export class CreateStudentPathSuccess implements Action {
  readonly type = CREATE_STUDENT_PATH_SUCCESS;
  constructor (public payload: any) {}
}

export class CreateStudentPathFail implements Action {
  readonly type = CREATE_STUDENT_PATH_FAIL;
  constructor (public payload) {}
}

export class BulkCreateStudentPath implements Action {
  readonly type = BULK_CREATE_STUDENT_PATH;
  constructor (public payload: IStudentPathBulkCreatePayload) {}
}

export class BulkCreateStudentPathSuccess implements Action {
  readonly type = BULK_CREATE_STUDENT_PATH_SUCCESS;
  constructor (public payload: any) {}
}

export class BulkCreateStudentPathFail implements Action {
  readonly type = BULK_CREATE_STUDENT_PATH_FAIL;
  constructor (public payload) {}
}

export class BulkUpdateStudentPath implements Action {
  readonly type = BULK_UPDATE_STUDENT_PATH;
  constructor (public payload: IStudentPathBulkUpdatePayload) {}
}

export class BulkUpdateStudentPathSuccess implements Action {
  readonly type = BULK_UPDATE_STUDENT_PATH_SUCCESS;
  constructor (public payload: any) {}
}

export class BulkUpdateStudentPathFail implements Action {
  readonly type = BULK_UPDATE_STUDENT_PATH_FAIL;
  constructor (public payload) {}
}

export class UpdateStudentPath implements Action {
  readonly type = UPDATE_STUDENT_PATH;
  constructor (public payload: IStudentPathUpdatePayload) {}
}

export class UpdateStudentPathSuccess implements Action {
  readonly type = UPDATE_STUDENT_PATH_SUCCESS;
  constructor (public payload: any) {}
}

export class UpdateStudentPathFail implements Action {
  readonly type = UPDATE_STUDENT_PATH_FAIL;
  constructor (public payload) {}
}

// action types
export type StudentPathAction =
  | LoadAllStudentPaths
  | LoadAllStudentPathSuccess
  | LoadAllStudentPathFail
  | LoadStudentPath
  | LoadStudentPathSuccess
  | LoadStudentPathFail
  | CreateStudentPath
  | CreateStudentPathSuccess
  | CreateStudentPathFail
  | UpdateStudentPath
  | UpdateStudentPathSuccess
  | UpdateStudentPathFail
  | BulkCreateStudentPath
  | BulkCreateStudentPathSuccess
  | BulkCreateStudentPathFail
  | BulkUpdateStudentPath
  | BulkUpdateStudentPathSuccess
  | BulkUpdateStudentPathFail;
