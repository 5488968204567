import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
/* istanbul ignore next */
export class NvPusherSocketId implements HttpInterceptor {
  static nvPusherSocketId = '';

  static setSocketId(socketId) {
    NvPusherSocketId.nvPusherSocketId = socketId;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        'Nv-Pusher-Socket-Id': NvPusherSocketId.nvPusherSocketId,
      },
    });
    return next.handle(request);
  }
}
