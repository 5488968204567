import { NgModule } from '@angular/core';
import { WindowRef } from '../../../../../projects/shared/services/window-ref/windowRef';
import { LocalStorageService } from './local-storage/local-storage.service';
import { SessionStorageService } from './session-storage/session-storage.service';

@NgModule({
  providers: [LocalStorageService, SessionStorageService, WindowRef],
})
export class WebStorageServicesModule {
  //
}
