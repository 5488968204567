import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { PrepReviewStep } from './prep-review-step.component';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [PrepReviewStep],
  imports: [CommonModule, MatButtonModule],
  exports: [PrepReviewStep],
})
export class PrepReviewStepModule {}
