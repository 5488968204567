import { getSchool } from 'Src/ng2/store';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { ImSchool } from 'Src/ng2/shared/services/im-models/im-school';
import { TValidSdcColumns } from './../../sdc-columns.type';
import { SdcStateServiceBaseModel } from './../sdc-state-basemodel';
import { sdcStateConfigConstant } from './../sdc-state-config.constant';

import { DEFAULT_SORT } from './../default-sort.constant';
import { Injectable } from '@angular/core';
import { tap, take } from 'rxjs/operators';
@Injectable()
export class GradPlanningConfigService extends SdcStateServiceBaseModel {
  constructor (
    private ImSchool: ImSchool,
    private store: Store<any>,
  ) {
    super();
  }

  get humanName () {
    return sdcStateConfigConstant.GRAD_PLANNING.humanName;
  }

  get state () {
    return sdcStateConfigConstant.GRAD_PLANNING.state;
  }

  get gridColumns () {
    return _.difference(this.columns, this.hiddenColumns);
  }

  get columns (): TValidSdcColumns[] {
    const school = this.getSchool();
    const isTransfer = this.ImSchool.isTransferSchool(school);

    const sharedColumns: TValidSdcColumns[] = [
      'studentId',
      'studentName',
      'cohort',
      'seniorFlag',
      'status',
      'grade',
      'officialClass',
      'atsCounselor',
      'advisor',

      // google classroom
      'classroomRemoteEngagementLast5',
      'classroomRemoteEngagement',
      'classroomTotalAssignments',
      'classroomTotalEngagedAssignments',
      'classroomTotalEnrolled',
      'classroomStudentEmail',

      'dob',
      'ageOn1231OfCurrentSy',
      'yearOfEntry',
      'admitDate',
      'dischargeCode',
      'dischargeDate',

      'iep',
      'potentiallyEligibleForSafetyNet',
      'schoolVerifiedSafetyNetEligibility',
      'isSafetyNetEligible',
      'ell',
      'homeLanguage',
      'attendancePriorSy',
      'attendanceYtd',

      'overageUnderCredited',
      'mostAtRisk',

      'progressToGraduationCollegeReadiness',
      'distanceFromGraduation',
      'plannedDiplomaType',
      'plannedGraduationDate',
      'gradPlan',
      'calculatedGradPlan',
      'gradPlanWarnings',
      'plannedEndorsements',
      'plannedCreds',
      'progressToCunyBenchmarks',
      'currentCourses',
      'currentCourseCodes',
      'transcriptGradeAverage',
      'totalCreditsAttempted',
      'totalCreditsSched',
      'creditsEarnedPriorToCurrentSy',
      'totalCreditsEarned',
      'creditsEarnedCurrentSchedTotal',
      'creditsEarnedCte',
      'creditsEarnedCurrentSchedCte',
      'creditsEarnedLote',
      'creditsEarnedCurrentSchedLote',
      'countOfRegentsPassedAt65PlusPriorToFirstEntry',
      'countOfRegentsPassedOf5PriorToCurrentSy',
      'countOfRegentsPassedForGrad',
      'countOfRegentsPassedAt65Of5',
      'countOfRegentsPassedAt55Of5SpedOnly',
      'countOfRegentsPassedAt65Of9',
      'countOfRegentsPassedForDiploma',

      'totalCountOfGradThreats',
      'attdThreat',
      'courseFailureThreat',
      'creditGapsThreat',
      'regentsThreat',

      'regentsPassedForDiploma',
      'regentsNeededForOnTrackDiploma',
      'regentsNeededForCollegeReadiness',
      'regentsNeededForAdvancedRegentsDiploma',
      'englishRegentsCr',
      'mathRegentsCr',
      'englishAndMathCr',
      'collegeReadyOnMathRegentsOrSatMath',
      'collegeReadyOnElaRegentsOrSatCr',

      // added for community schools on 3/22
      'schoolPriorityFlag',
    ];

    const transferColumns: TValidSdcColumns[] = [];

    const nonTransferColumns: TValidSdcColumns[] = [];

    if (isTransfer) {
      return [...sharedColumns, ...transferColumns];
    } else {
      return [...sharedColumns, ...nonTransferColumns];
    }
  }

  get hiddenColumns (): TValidSdcColumns[] {
    return [];
  }

  get gridViews () {
    return {
      advancedRegentsAssignment: [
        'studentId',
        'studentName',
        'cohort',
        'seniorFlag',
        'status',
        'grade',
        'officialClass',
        'iep',
        'isSafetyNetEligible',
        'ell',
        'attendanceYtd',
        'plannedDiplomaType',
        'plannedGraduationDate',
        'gradPlan',
        'calculatedGradPlan',
        'plannedEndorsements',
        'plannedCreds',
        'totalCreditsEarned',
        'creditsEarnedCurrentSchedTotal',
        'creditsEarnedCte',
        'creditsEarnedCurrentSchedCte',
        'creditsEarnedLote',
        'creditsEarnedCurrentSchedLote',
        'countOfRegentsPassedAt55Of5SpedOnly',
        'countOfRegentsPassedAt65Of5',
        'countOfRegentsPassedAt65Of9',
        'regentsNeededForOnTrackDiploma',
        'regentsNeededForAdvancedRegentsDiploma',
      ],
      regentsAssignment: [
        'studentId',
        'studentName',
        'cohort',
        'seniorFlag',
        'status',
        'grade',
        'officialClass',
        'iep',
        'isSafetyNetEligible',
        'ell',
        'attendanceYtd',
        'plannedDiplomaType',
        'plannedGraduationDate',
        'gradPlan',
        'calculatedGradPlan',
        'plannedEndorsements',
        'plannedCreds',
        'totalCreditsEarned',
        'creditsEarnedCurrentSchedTotal',
        'creditsEarnedCte',
        'creditsEarnedCurrentSchedCte',
        'countOfRegentsPassedAt55Of5SpedOnly',
        'countOfRegentsPassedAt65Of5',
        'regentsNeededForOnTrackDiploma',
      ],
      localAssignment: [
        'studentId',
        'studentName',
        'cohort',
        'seniorFlag',
        'status',
        'grade',
        'officialClass',
        'iep',
        'isSafetyNetEligible',
        'ell',
        'attendanceYtd',
        'plannedDiplomaType',
        'plannedGraduationDate',
        'gradPlan',
        'calculatedGradPlan',
        'plannedEndorsements',
        'plannedCreds',
        'totalCreditsEarned',
        'creditsEarnedCurrentSchedTotal',
        'creditsEarnedCte',
        'creditsEarnedCurrentSchedCte',
        'countOfRegentsPassedAt55Of5SpedOnly',
        'countOfRegentsPassedAt65Of5',
        'regentsNeededForOnTrackDiploma',
      ],
      notOnTarget: [
        'studentId',
        'studentName',
        'cohort',
        'seniorFlag',
        'status',
        'grade',
        'officialClass',
        'iep',
        'isSafetyNetEligible',
        'ell',
        'attendanceYtd',
        'plannedDiplomaType',
        'plannedGraduationDate',
        'gradPlan',
        'calculatedGradPlan',
        'plannedEndorsements',
        'plannedCreds',
        'totalCreditsEarned',
        'creditsEarnedCurrentSchedTotal',
        'creditsEarnedCte',
        'creditsEarnedCurrentSchedCte',
        'countOfRegentsPassedAt55Of5SpedOnly',
        'countOfRegentsPassedAt65Of5',
        'regentsNeededForOnTrackDiploma',
      ],
      safetyNetEligibility: [
        'studentId',
        'studentName',
        'cohort',
        'seniorFlag',
        'status',
        'grade',
        'officialClass',
        'iep',
        'potentiallyEligibleForSafetyNet',
        'schoolVerifiedSafetyNetEligibility',
        'isSafetyNetEligible',
        'attendanceYtd',
      ],
    };
  }

  get filter (): { cohort?: string[] } {
    const school = this.getSchool();

    const filter = {
      // Grad Planning does not filter for active students like the other SDCs
      // This is because we want the wizard to include all accountable students including A-NDG and discharged students
    };

    // Transfer schools should not be filtered by cohort
    const isTransfer = this.ImSchool.isTransferSchool(school);
    if (isTransfer) return filter;

    const seniorAndSuperCohorts = this.ImSchool.getSeniorAndSuperCohortsForGradPlanning(school);

    let mostSeniorCohort;
    if (seniorAndSuperCohorts.length === 0) {
      mostSeniorCohort = _.min(school.uniqueCohorts);
    }

    // Otherwise return either senior + super senior cohorts, or the single most senior cohort
    const cohort = _.size(seniorAndSuperCohorts) ? seniorAndSuperCohorts : [mostSeniorCohort];

    (filter as any).cohort = { values: cohort };

    return filter;
  }

  get sort () {
    return DEFAULT_SORT;
  }

  get availableSdcViews () {
    return sdcStateConfigConstant.GRAD_PLANNING.availableSdcViews;
  }

  getSchool () {
    let school;
    this.store.select(getSchool).pipe(
      tap(storeSchool => (school = storeSchool)), take(1)).subscribe();
    return school;
  }
}
