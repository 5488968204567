import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { difference } from 'lodash';
import { EMPTY, iif, of } from 'rxjs';
import { map as rxMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { GridService } from '../../school/grid/services/grid.service';
import {
  LoadGridDataSuccess,
  LOAD_GRID_DATA,
  RefreshGridDataSuccess,
  REFRESH_GRID_DATA,
  SaveGridData,
} from '../actions/grid-actions';
import { OTHER_SINGLE_STUDENT_UPDATE_SUCCESS, UPDATE_STUDENT_SUCCESS } from '../actions/single-student-actions';
import { CREATE_STUDENT_SUPPORT_SUCCESS, UPDATE_STUDENT_SUPPORT_SUCCESS } from '../actions/student-supports-actions';
import { UPDATE_STUDENT_PATH_SUCCESS, CREATE_STUDENT_PATH_SUCCESS } from '../actions/student-paths-actions';

@Injectable()
export class GridDataEffects {
  constructor (private actions$: Actions, private store: Store<any>, private gridService: GridService) {}

  private getNewColumnDefsToFetch ({ currentLoadedColumnDefs, unLoadedColumnDefs }) {
    const osisNumberColumnDef = currentLoadedColumnDefs[0];
    const newColumnDefsToFetch = unLoadedColumnDefs.concat(osisNumberColumnDef);
    return newColumnDefsToFetch;
  }

  loadGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<any>(LOAD_GRID_DATA),
      withLatestFrom(this.store),
      switchMap(([{ payload }, storeState]) => {
        const {
          PORTAL_STORE: {
            gridData: { columnDefs },
          },
        } = storeState;
        const { schoolId, gridType, urlPassedColumnState } = payload;
        return iif(
          () => columnDefs.length,
          of({ urlPassedColumnState, columnDefs }),
          this.gridService
            .getGridConfig$({ schoolId, gridType })
            .pipe(rxMap(columnDefs => ({ urlPassedColumnState, columnDefs }))),
        );
      }),
      withLatestFrom(this.store),
      switchMap(([{ urlPassedColumnState, columnDefs }, storeState]) => {
        const {
          PORTAL_STORE: {
            gridData: { rowData, schoolId, backgroundJobRunning, hasInitialLoad },
          },
        } = storeState;
        const loadedColumnDefs = this.gridService.getColumnDefsForInitialFetch({ urlPassedColumnState, columnDefs });
        this.store.dispatch(new SaveGridData({ columnDefs, loadedColumnDefs }));
        return iif(
          () => !hasInitialLoad || backgroundJobRunning,
          this.gridService.fetchGridData$({ schoolId, columnDefs: loadedColumnDefs }),
          of(rowData),
        );
      }),
      rxMap(data => new LoadGridDataSuccess({ rowData: data })),
    );
  });

  refreshGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<any>(
        CREATE_STUDENT_PATH_SUCCESS,
        CREATE_STUDENT_SUPPORT_SUCCESS,
        OTHER_SINGLE_STUDENT_UPDATE_SUCCESS,
        REFRESH_GRID_DATA,
        UPDATE_STUDENT_PATH_SUCCESS,
        UPDATE_STUDENT_SUCCESS,
        UPDATE_STUDENT_SUPPORT_SUCCESS,
      ),
      withLatestFrom(this.store),
      switchMap(([action, storeState]) => {
        const {
          PORTAL_STORE: {
            gridData: { loaded, loading, loadedColumnDefs: currentLoadedColumnDefs, rowData },
            school: {
              school: { _id: schoolId },
            },
          },
        } = storeState;
        if (!loaded && !loading) return EMPTY;
        if (action.type === UPDATE_STUDENT_SUCCESS) {
          action.payload.studentIds = [action.payload._id];
          action.payload.selectedColumnDefs = currentLoadedColumnDefs;
        }
        if (action.type === CREATE_STUDENT_SUPPORT_SUCCESS || action.type === UPDATE_STUDENT_SUPPORT_SUCCESS) {
          action.payload.studentIds = [action.payload.student.studentId];
          action.payload.selectedColumnDefs = currentLoadedColumnDefs;
        }
        if (action.type === CREATE_STUDENT_PATH_SUCCESS ||
            action.type === UPDATE_STUDENT_PATH_SUCCESS ||
            action.type === OTHER_SINGLE_STUDENT_UPDATE_SUCCESS
        ) {
          action.payload.studentIds = [action.payload.studentId];
          action.payload.selectedColumnDefs = currentLoadedColumnDefs;
        }
        const { payload } = action;
        const { studentIds, selectedColumnDefs } = payload;
        if (studentIds) {
          return this.gridService.fetchGridData$({ schoolId, columnDefs: currentLoadedColumnDefs, studentIds });
        }
        if (selectedColumnDefs) {
          const unLoadedColumnDefs = difference(selectedColumnDefs, currentLoadedColumnDefs);
          if (unLoadedColumnDefs.length) {
            this.store.dispatch(new SaveGridData({ loadedColumnDefs: selectedColumnDefs }));
            const newColumnDefsToFetch = this.getNewColumnDefsToFetch({ currentLoadedColumnDefs, unLoadedColumnDefs });
            return this.gridService.fetchGridData$({ schoolId, columnDefs: newColumnDefsToFetch });
          }
        }
        return of(rowData);
      }),
      rxMap(data => new RefreshGridDataSuccess({ rowData: data })),
    );
  });
}
