import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { NvMatTooltipModule } from '../../components/nv-mat-tooltip/nv-mat-tooltip.module';
import { BaseModalModule } from '../base-modal.module';
import { CoursePlansModalShellComponent } from './course-plans-modal-shell.component';
import { NvButtonModule } from 'projects/shared/nvps-libraries/design/nv-button/nv-button.module';
import { MatRadioModule } from '@angular/material/radio';
import { NvFormFieldModule } from 'Src/nvps-libraries/design/nv-form-field/nv-form-field.module';
import { ManualCoursePlanModule } from '../../components/course-plans/manual-course-plan/manual-course-plan.module';
import { ScheduledCoursePlanModule } from '../../components/course-plans/scheduled-course-plan/scheduled-course-plan.module';

@NgModule({
  declarations: [CoursePlansModalShellComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule,
    MatRadioModule,
    BaseModalModule,
    NvFormFieldModule,
    NvButtonModule,
    NvMatTooltipModule,
    ManualCoursePlanModule,
    ScheduledCoursePlanModule,
  ],
  exports: [CoursePlansModalShellComponent],
  providers: [],
})

export class CoursePlansModalShellModule {}
