/* tslint:disable */
import { Action } from '@ngrx/store';
import { IFocus } from 'Src/ng2/school/tiles/interfaces/dashboard.interfaces';
export const LOAD_STUDENT_GROUPINGS = '[Portal] Load Student Groupings';
export const RESET_STUDENT_GROUPINGS = '[Portal] Reset Student Groupings';
export const LOAD_STUDENT_GROUPINGS_SUCCESS = '[Portal] Load Student Groupings Success';
export const LOAD_STUDENT_GROUPINGS_FAIL = '[Portal] Load Student Groupings Fail';

export interface IStudentGroupingsPayload {
  schoolId?: string; // deprecated
  contextPartnerType?: string;
  contextPartnerId?: string;
  focusKey?: string;
  filterKeys: string[];
  groupingKey: string;
  columns: IFocus['studentLevelColumns'];
  id?: string; // optional student identifier
  subLocationId?: string;
}

export class LoadStudentGroupings implements Action {
  readonly type = LOAD_STUDENT_GROUPINGS;
  constructor(public payload: IStudentGroupingsPayload) {}
}

export class ResetStudentGroupings implements Action {
  readonly type = RESET_STUDENT_GROUPINGS;
  constructor() {}
}
export class LoadStudentGroupingsSuccess implements Action {
  readonly type = LOAD_STUDENT_GROUPINGS_SUCCESS;
  constructor(public payload: any) {}
}

// interface is error$?
export class LoadStudentGroupingsFail implements Action {
  readonly type = LOAD_STUDENT_GROUPINGS_FAIL;
  constructor(public payload: any) {}
}

// action types
export type StudentGroupingsAction =
  | LoadStudentGroupings
  | ResetStudentGroupings
  | LoadStudentGroupingsSuccess
  | LoadStudentGroupingsFail;
