import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UpdateStudentPath, IStudentPathUpdatePayload } from 'Src/ng2/store/actions/student-paths-actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'student-postsec-panel-list-trash-can',
  templateUrl: './student-postsec-panel-list-trash-can.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class StudentPostsecPanelListTrashCanComponent implements OnInit {
  @Input() input: any;
  @Output() output = new EventEmitter();
  showTrash: boolean;

  constructor (private store: Store<any>) {}

  public ngOnInit () {
    this.showTrash = !this.input.column.meta.isViewOnlyUser;
  }

  onStatusChange (): void {
    const { studentPathId, schoolId, studentId } = this.input.column.meta;
    const newStatus = 'DELETED';
    const payload: IStudentPathUpdatePayload = {
      studentPathId,
      status: newStatus,
      schoolId,
      studentId,
    };
    this.store.dispatch(new UpdateStudentPath(payload));
  }
}
