import { ApiService } from 'Src/ng2/shared/services/api-service/api-service';
import { getSchool } from './../../../../store/selectors/school-selectors';
import { ISchool } from '../../../../shared/typings/interfaces/school.interface';
import { Observable } from 'rxjs';
import { StoreDataService } from './../../../../shared/services/store-data-service/store-data.service';
import { Injectable } from '@angular/core';
import { PROGRAM_CHANGES_GROUPING_COLUMNS } from './course-diffs-and-gap-plans-columns.constants';

@Injectable()
export class CourseDiffsAndGapPlansDataService {
  constructor (
    private storeDataService: StoreDataService,
    private apiService: ApiService,
  ) {}

  getProgramChanges$ (schoolId, fociOption, groupingOption, projection): Observable<any> {
    return this.apiService.getProgramChangesData({
      schoolId,
      fociOption,
      groupingOption,
      projection,
    });
  }

  getSchool$ (): Observable<ISchool> {
    return this.storeDataService.getDataFromStore$(getSchool);
  }

  getProgramChangesColumns (groupingOption): {columnName: string, columnOrder: number, graphQlKey: string}[] {
    return PROGRAM_CHANGES_GROUPING_COLUMNS[groupingOption];
  }
}
