import {
  ObjectCacheActions,
  FETCH_OBJECT_CACHE_SUCCESS,
  CREATE_OBJECT_CACHE_SUCCESS,
  FETCH_OBJECT_CACHE,
  FETCH_OBJECT_CACHE_FAIL,
  CREATE_OBJECT_CACHE,
  CREATE_OBJECT_CACHE_FAIL,
} from '../actions/object-cache-actions';

export interface IObjectCache {
  [key: string]: any;
}

export interface IObjectCacheState {
  loaded: IObjectCache;
  objectCache: IObjectCache;
}

export const initialState: IObjectCacheState = {
  loaded: {},
  objectCache: {},
};

export function reducer(state = initialState, action: ObjectCacheActions) {
  let objectCache: IObjectCache;
  let loaded: any;
  switch (action.type) {
    case CREATE_OBJECT_CACHE:
      loaded = {
        ...state.loaded,
        [action.payload.key]: false,
      };
      return { loaded, objectCache: { ...state.objectCache } };
    case CREATE_OBJECT_CACHE_FAIL:
      loaded = {
        ...state.loaded,
        [action.payload]: true,
      };
      objectCache = {
        [action.payload]: null,
      };
      return { loaded, objectCache: { ...state.objectCache, ...objectCache } };
    case CREATE_OBJECT_CACHE_SUCCESS:
      let { key, object } = action.payload;
      loaded = {
        ...state.loaded,
        [key]: true,
      };
      return { loaded, objectCache: { ...state.objectCache, ...object } };
    case FETCH_OBJECT_CACHE:
      loaded = {
        ...state.loaded,
        [action.payload]: false,
      };
      return { loaded, objectCache: { ...state.objectCache } };
    case FETCH_OBJECT_CACHE_SUCCESS:
      objectCache = action.payload;
      const [hash] = Object.keys(objectCache);
      loaded = {
        ...state.loaded,
        [hash]: true,
      };
      return { loaded, objectCache: { ...state.objectCache, ...objectCache } };
    case FETCH_OBJECT_CACHE_FAIL:
      loaded = {
        ...state.loaded,
        [action.payload]: true,
      };
      objectCache = {
        [action.payload]: null,
      };
      return { loaded, objectCache: { ...state.objectCache, ...objectCache } };
    default:
      return state;
  }
}
