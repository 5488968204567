import { Component, Input, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

/**
 *
 * The date range picker takes two date picker components
 * and combines them to allow users to select a date range.
 *
 * The component has internal logic to ensure logical date ranges.
 *
 */
@Component({
  selector: 'nv-date-range-picker',
  templateUrl: './nv-date-range-picker.component.html',
  styleUrls: ['./nv-date-range-picker.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NvDateRangePickerComponent {
  /**
   *
   * Use a FormGroup to manage the dates within the Range Picker
   * @required
   */
  @Input() dateRangeForm: FormGroup;

  /**
   *
   * The earliest start limit for the first Date Picker
   */
  @Input() startLimit: string; // YYYY-MM-DD

  /**
   *
   * The end date limit for the second Date Picker
   */
  @Input() endLimit: string; // YYYY-MM-DD

  /**
   *
   * The end date limit for the first Date Picker
   */
  @Input() startMaxLimit: string; // YYYY-MM-DD

  /**
   *
   * The start date limit for the second Date Picker
   */
  @Input() endMinLimit: string; // YYYY-MM-DD

  /**
   *
   *
   */

  @Input() startPlaceholder: string = 'Date'; // YYYY-MM-DD

  /**
   *
   *
   */
  @Input() endPlaceholder: string = 'Date'; // YYYY-MM-DD

  /**
   *
   *
   */
  @Input() isInverted: boolean = true;

  /**
   *
   * disables both date pickers
   */
  @Input() isDisabled: boolean = false;

  public startMin: string;
  public startMax: string;
  public endMin: string;
  public endMax: string;
  public isOneValid: boolean = true;

  constructor (
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    // set limits based on start/end values
    const {
      value: { endDate, startDate },
    } = this.dateRangeForm;

    this.startMax = endDate || this.endLimit;
    this.endMin = startDate || this.startLimit;
    this.startMin = this.startLimit;
    this.endMax = this.endLimit;

    this.setIsOneValid();

    this.dateRangeForm.valueChanges.subscribe(changes => {
      const { startDate, endDate } = changes;
      if (startDate) this.endMin = startDate;
      if (endDate) this.startMax = endDate;
      this.setIsOneValid();
    });
  }

  ngAfterViewChecked () {
    this.cdr.detectChanges();
  }

  selectStartDate(value): void {
    this.dateRangeForm.patchValue({ startDate: value });
    this.endMin = value;
    this.setIsOneValid();
  }

  selectEndDate(value): void {
    this.dateRangeForm.patchValue({ endDate: value });
    this.startMax = value;
    this.setIsOneValid();
  }

  setIsOneValid() {
    this.isOneValid = this.dateRangeForm.controls.startDate.valid || this.dateRangeForm.controls.endDate.valid;
  }
}
