import { Injectable } from '@angular/core';
import { reduce, orderBy } from 'lodash';
import { IBatchData } from 'Src/ng2/school/student-level-dashboard/student-level-dash.component';
import { IRowData } from './../../../../shared/models/list-models';

interface IUpdateUserIdsOptions {
  forbiddenIds?: string[]
}

interface IIdHash {
  [id: string]: boolean
}
@Injectable()
export class BatchActionsService {
  public initializeStudentBatchActionsHash (): { updateStudentIds: Function; reset: Function } {
    // leverage closure to maintain access to this internal state
    let idHash = {};

    const batchActions = {
      updateStudentIds: ($event: { updateAll: boolean; students: any[] }): void => {
        const { updateAll, students } = $event;
        // mutate hash
        students.forEach((student) => {
          // ? check after data change
          const studentId = student.studentId.slice(0, -6);
          student.studentId = studentId;
          if (idHash[studentId] && !updateAll) delete idHash[studentId];
          else idHash[studentId] = student; // this is the student row data
        });
      },

      getStudentIds: (sort?): any[] => {
        const studentIds = Object.values(idHash).map(({ studentId }) => ({ studentId }));
        if (sort) {
          return orderBy(Object.values(idHash), ['section', sort.sortKey], ['asc', sort.sortDirection]).map(({ studentId }) => ({ studentId }));
        }

        return studentIds;
      },

      reset: (): void => {
        idHash = {};
      },
    };

    return batchActions;
  }

  public formatBatchActionEvt ($event: IBatchData) {
    let students: any[];
    const { updateAll, data, level, section, stubReplacement } = $event;
    // TODO: format to a st
    switch (level) {
      case 'SECTION':
        students = (data as IRowData[]).map((row: IRowData) => this.getStudentDataFromRow(row, section, stubReplacement));
        break;
      case 'ROW':
        students = Array.of(this.getStudentDataFromRow(data, section, stubReplacement));
        break;
      default:
        students = [];
    }
    const batchData = { updateAll, students };

    return batchData;
  }

  // a function returns a student shape and this shape make sorting the priority {[columnKey]: data}
  public getStudentDataFromRow (row, section, stubReplacement) {
    const studentId = this.getStudentIdFromRow(row);
    const caresId = this.getCaresIdFromRow(row); // Shelter students
    return row.reduce((acc, { columnKey, data }) => {
      // handles case when sorting on student's name column, because studentName === 'STUB'
      const key = columnKey === 'STUB' ? stubReplacement : columnKey;
      acc[key] = data;

      if(caresId) acc.caresId = caresId; // Add caresId for shelter lists

      return acc;
    }, { studentId, section });
  }

  public getStudentIdFromRow (row): string {
    const [stub] = row;
    const metaData = JSON.parse(stub.meta);
    const studentId = metaData.data;
    return studentId;
  }

  public getCaresIdFromRow (row): string {
    const [stub] = row;
    const metaData = JSON.parse(stub.meta);
    const caresId = metaData.caresId;
    return caresId;
  }

  // USER MANAGEMENT
  public initializeUserBatchActionsHash (): { updateUserIds: Function; reset: Function } {
    // leverage closure to maintain access to this internal state
    let idHash = {};

    const batchActions = {

      updateUserIds: ($event: { updateAll: boolean; students: any[] }, options: IUpdateUserIdsOptions = {}): void => {
        const { updateAll, students } = $event;
        const { forbiddenIds } = options;
        const studentIds = students.map((student) => student.studentId);
        // mutate hash
        const hasForbiddenIds = forbiddenIds?.length;
        if (hasForbiddenIds) {
          idHash = this._updateUserIdHashWithForbiddenIds({ idHash, allIds: studentIds, forbiddenIds, updateAll });
        } else {
          idHash = this._updateUserIdHashWithoutForbiddenIds({ idHash, allIds: studentIds, updateAll });
        }
      },

      getUserIds: (): string[] => {
        const userIds = reduce(idHash, (result, _val, key) => (result = [...result, { studentId: key }]), []);
        return userIds;
      },

      reset: (): void => {
        idHash = {};
      },
    };

    return batchActions;
  }

  // MASTER PROGRAM
  public initializeCoursesBatchActionsHash (): { updateCoursesIds: Function; reset: Function } {
    // leverage closure to maintain access to this internal state
    let idHash = {};

    const batchActions = {
      updateCoursesIds: ($event: { updateAll: boolean; students: any[] }): void => {
        const { updateAll, students } = $event;
        // mutate hash
        idHash = students.reduce((acc: any, student: any) => {
          const userId = student.studentId;
          if (idHash[userId] && !updateAll) {
            delete idHash[userId];
            delete acc[userId];
            return { ...idHash, ...acc };
          } else {
            acc[userId] = true;
            return { ...idHash, ...acc };
          }
        }, {});
      },

      getCoursesIds: (): string[] => {
        const coursesIds = reduce(idHash, (result, _val, key) => (result = [...result, { studentId: key }]), []);
        return coursesIds;
      },

      reset: (): void => {
        idHash = {};
      },
    };

    return batchActions;
  }

  // SHELTER ATTENDANCE LIST
  public initializeShelterStudentBatchActionsHash (): { updateStudentIds: Function; reset: Function } {
    // leverage closure to maintain access to this internal state
    let idHash = {};

    const batchActions = {
      updateStudentIds: ($event: { updateAll: boolean; students: any[] }): void => {
        const { updateAll, students } = $event;
        // mutate hash
        students.forEach((student) => {
          const caresId = student.caresId;
          student.caresId = caresId;
          if (idHash[caresId] && !updateAll) delete idHash[caresId];
          else idHash[caresId] = student; // this is the student row data
        });
      },

      getStudentIds: (sort?): any[] => {
        const caresIds = reduce(idHash, (result, _val, key) => (result = [...result, { studentId: key }]), []);
        return caresIds;
      },

      reset: (): void => {
        idHash = {};
      },
    };

    return batchActions;
  }

  _updateUserIdHashWithoutForbiddenIds ({ idHash, allIds, updateAll }): IIdHash {
    let newIdHash = Object.assign({}, idHash);
    if (updateAll) {
      newIdHash = this._addUserIdsToHash({ idHash, ids: allIds });
    } else {
      newIdHash = this._toggleUserIdsInHash({ idHash, ids: allIds });
    }
    return newIdHash;
  }

  _updateUserIdHashWithForbiddenIds ({ idHash, allIds, forbiddenIds, updateAll }): IIdHash {
    let newIdHash = Object.assign({}, idHash);
    const validIds = allIds.filter((id) => !forbiddenIds.includes(id));
    const currentSelectedIds = Object.keys(newIdHash);
    if (updateAll) {
      // user clickes on section header
      const selectAllUsers = currentSelectedIds.length < validIds.length;
      const deselectAllUsers = !selectAllUsers;
      if (selectAllUsers) newIdHash = this._addUserIdsToHash({ idHash, ids: validIds });
      if (deselectAllUsers) newIdHash = this._removeUserIdsFromHash({ idHash, ids: validIds });
    } else {
      // user clicks on row
      newIdHash = this._toggleUserIdsInHash({ idHash, ids: validIds });
    }
    return newIdHash;
  }

  _toggleUserIdsInHash ({ idHash, ids }): IIdHash {
    const newIdHash = Object.assign({}, idHash);
    ids.forEach((id) => {
      if (newIdHash[id]) {
        delete newIdHash[id];
      } else {
        newIdHash[id] = true;
      }
    });
    return newIdHash;
  }

  _addUserIdsToHash ({ idHash, ids }): IIdHash {
    const newIdHash = Object.assign({}, idHash);
    ids.forEach(id => { newIdHash[id] = true; });
    return newIdHash;
  }

  _removeUserIdsFromHash ({ idHash, ids }): IIdHash {
    const newIdHash = Object.assign({}, idHash);
    ids.forEach(id => { delete newIdHash[id]; });
    return newIdHash;
  }
}
