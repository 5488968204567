import { WizardHelpers } from './../../../../services/wizard-helpers.service';
import { ActivatedRoute } from '@angular/router';
import { Component, ViewEncapsulation } from '@angular/core';
import { UrlPathService } from 'Src/ng2/shared/services/url-path-service/url-path.service';

@Component({
  selector: 'on-track-regents-step',
  templateUrl: './on-track-regents-step.component.html',
  styleUrls: ['../shared-step-styles.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class OnTrackRegentsStep {
  public stepLabel: string = 'Review your school\'s On-Track Regents Metrics';

  constructor (
    private activatedRoute: ActivatedRoute,
    private wizardHelpers:WizardHelpers,
    private urlPathService: UrlPathService,
  ) {}

  navigateToRegentsSettings () {
    const url = this.urlPathService.computeDistrictUrlPath(`/school/${this.activatedRoute.snapshot.params.schoolId}/settings/regents`);
    this.wizardHelpers.goToStateInNewTab(url);
  }
}
