import { IStudent } from '../../typings/interfaces/student.interface';
import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseModalComponent } from '../base-modal.component';
import { IBaseModalData } from '../modals.service';
import { Observable } from 'rxjs';

export interface IStudentSupportsHistoricalData extends IBaseModalData {
  student: IStudent;
  batchActionsMode$: Observable<any>;
}

@Component({
  selector: 'student-supports-historical-data-modal',
  templateUrl: './student-supports-historical-data-modal.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class StudentSupportsHistoricalDataModalComponent extends BaseModalComponent implements OnInit, OnDestroy {
  // Props needed for BaseModal
  public title: string = 'Historical data';
  public isProfileMode: boolean = true;

  // Props needed for Historical data list
  public student: IStudent;
  public batchActionsMode$: Observable<any>;
  public fociKey: string = 'STUDENT_SUPPORT_HISTORICAL_DATA';

  constructor (
    dialogRef: MatDialogRef<StudentSupportsHistoricalDataModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IStudentSupportsHistoricalData,
  ) {
    super(dialogRef);
  }

  ngOnInit (): void {
    const { student, batchActionsMode$ } = this.data;
    this.student = student;
    this.batchActionsMode$ = batchActionsMode$;
  }
}
