import { RollbarService } from './../rollbar/rollbar.service';
import { ErrorHandler, Inject, Injectable } from '@angular/core';
import * as Rollbar from 'rollbar';
import { ErrorToaster } from '../error-toaster/error-toaster.service';
// custom service for uncaught exceptions (Carlos)
// documentation for custom error handler: https://angular.io/api/core/ErrorHandler (Carlos)
@Injectable()
export class CustomErrorHandlerService extends ErrorHandler {
  constructor (
    @Inject(RollbarService) private rollbar: Rollbar,
    private errorToaster: ErrorToaster
  ) {
    super();
  }

  handleError (error: Error) {
    this.errorToaster.handleError(error);
    this.rollbar.error(error);

    // call default angular error handler (Carlos)
    super.handleError(error);
  }
}
