import { districtRoute } from './routes/district/district.route';
import { districtPickerRoute } from './routes/district-picker/district-picker.route';
import { homeRoute } from './routes/home/home.route';
import { redirectRoute } from './routes/redirect/redirect.route';
import { callbackRoute } from './routes/callback/callback.route';
import { loginRoute } from './routes/login/login.route';
import { rootRoute } from './routes/root/root.route';
import { Route } from '@angular/router';
import { TValidUserRoles } from '../shared/typings/interfaces/user.interface';
import { notFoundRoute } from './routes/not-found/not-found.route';
import { shelterRoute } from './routes/shelter/shelter.route';
import { shelterPickerRoute } from './routes/shelter-picker/shelter-picker.route';
import { adminRoute } from './routes/shared/admin/admin.route';
import { TValidPartnerTypes } from './../shared/typings/interfaces/partner.interface';

export interface IRouteConfigsOpts extends Route {
  url?: string;
  data?: { [key: string]: any };
  rolePermissions: TValidUserRoles[];
  authenticationRequired: boolean;
  // won't pass a partner at root, login, home etc
  partnerType?: TValidPartnerTypes | 'school' | 'shelter' | 'hybrid'; // TODO: refactor to remove string
}

export interface IRouteConfigs {
  [routeName: string]: IRouteConfigsOpts;
}

export const ROUTE_CONFIGS = new Map();

/**
 * Add routes here { file : fileCameled }
 * routes/shelter/shelter.route.ts --> ROUTE_CONFIGS.set('shelter.route', shelterRoute);
 */
ROUTE_CONFIGS.set('root.route', rootRoute);
ROUTE_CONFIGS.set('login.route', loginRoute);
ROUTE_CONFIGS.set('shelter-picker.route', shelterPickerRoute);
ROUTE_CONFIGS.set('shelter.route', shelterRoute);
ROUTE_CONFIGS.set('callback.route', callbackRoute);
ROUTE_CONFIGS.set('home.route', homeRoute);
ROUTE_CONFIGS.set('district-picker.route', districtPickerRoute);
ROUTE_CONFIGS.set('not-found.route', notFoundRoute);
ROUTE_CONFIGS.set('admin.route', adminRoute);
ROUTE_CONFIGS.set('redirect.route', redirectRoute);
ROUTE_CONFIGS.set('district.route', districtRoute);
/**
 * IMPORTANT! THIS WILL BREAK THE APP. THIS SHOULD ALWAYS BE THE LAST ROUTE!
 * THIS IS CATCHING EVERY ROUTE THAT FAILS TO MATCH
 */
ROUTE_CONFIGS.set('wildcard.route', { path: '**', redirectTo: '/not-found' });
