<div class="title-wrapper">
  <ng-container *ngIf="pageHeaderMeta.icon && pageHeaderMeta.icon.name">
    <nv-icon-button
      [name]="pageHeaderMeta.icon.name"
      nvMatTooltip="{{ pageHeaderMeta.icon.tooltip }}"
      nvMatTooltipClass="tooltip"
      (clickButton)="pageHeaderMeta.icon.handler()"
    ></nv-icon-button>
  </ng-container>
  <h1 *ngIf="pageHeaderMeta.title" class="page-title">
    {{ pageHeaderMeta.title }}
    <nv-icon *ngIf="pageHeaderMeta.infoIcon" name="info-large-hover" nvTooltip [tooltipData]="pageHeaderMeta.infoIcon.tooltipData"></nv-icon>
  </h1>
  <span *ngIf="pageHeaderMeta.showBetaFlag"class="page-header-beta-flag">beta</span>
  <div class="beta-message-banner" *ngIf="pageHeaderMeta.betaMessageData">
      <span>
        <span class="bold-feature-name">{{pageHeaderMeta.betaMessageData.betaFeatureName}}</span> is in Beta, and we're eager for your feedback to enhance its features. 
      </span>
      <a *ngIf="pageHeaderMeta.betaMessageData.betaLearnMoreUrl"class="beta-message-link" href="{{pageHeaderMeta.betaMessageData.betaLearnMoreUrl}}" target="_blank">Learn More</a>
      <span *ngIf="pageHeaderMeta.betaMessageData.betaShareFeedback" class="task-intercom-feedback">Share Your Thoughts</span>
  </div>
  <!-- styling for the projected content should be defined where it's injected -->
  <ng-content select="[title]"></ng-content>
</div>
<div [ngClass]="pageActionsStyle ? pageActionsStyle + '-page-actions' : 'page-actions'">
  <ng-container *ngIf="showSearchBox">
    <nv-textbox
      [textControl]="filterFormControl"
      [placeholder]="pageHeaderMeta.textboxPlaceholder"
      icon="search-large-black"
      [hasClearBtn]="true"
      (clearValue)="onClear()"
    ></nv-textbox>
  </ng-container>
  <ng-container *ngIf="pageHeaderMeta.action && pageHeaderMeta.action.label">
    <nv-button (clickButton)="pageHeaderMeta.action.trigger()"> {{ pageHeaderMeta.action.label }} </nv-button>
  </ng-container>
  <ng-content select="[top-right]"></ng-content>
</div>
<div class="middle">
  <!--
    header subtitle is often dynamic from one list to the next
    this allows us to pass in custom templates
  -->
  <ng-content></ng-content>
</div>
