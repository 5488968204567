import { EventFormatterService } from './../../shared/services/mixpanel/event-formatter.service';
import { IStudentPath } from 'Src/ng2/shared/typings/interfaces/studentPaths.interface';
import { ModalsService } from 'Src/ng2/shared/modals/modals.service';
import { HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Injector, forwardRef } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, identity } from 'lodash';
import { of, Observable } from 'rxjs';

import { catchError, mergeMap, switchMap, takeLast, tap, map, timeout } from 'rxjs/operators';
import { BackgroundJobNotificationService } from 'Src/ng2/shared/services/background-job-notification-service/background-job-notification-service';
import { ApiService } from '../../shared/services/api-service/api-service';
import * as studentPathsActions from '../actions/student-paths-actions';
import { IStudentPathBulkCreatePayload, IStudentPathBulkUpdatePayload } from '../actions/student-paths-actions';
import { BatchActionsEffectsUtilities } from '../utilities/batch-actions-effects-utilities';
import { BackgroundJob, BACKGROUND_JOB_TIMEOUT_THRESHOLDS, BACKGROUND_JOB_STATUS_TYPES } from './../../shared/services/background-job/background-job.service';
import { ISnackBarData, SnackBarService } from './../../shared/services/snackbar/snackbar.service';
import { STUDENT_PATH_PROJECTION } from './projections';

@Injectable()
export class StudentPathsEffects {
  constructor (
    private actions$: Actions,
    private apiService: ApiService,
    private injector: Injector,
    private backgroundJob: BackgroundJob,
    private snackBarService: SnackBarService,
    @Inject(forwardRef(() => ModalsService)) public modalsService: ModalsService,
    private backgroundJobNotificationService: BackgroundJobNotificationService,
    private eventFormatterService: EventFormatterService,
  ) {}

  snackBarData: ISnackBarData = { message: '', duration: 2000 };

  loadAllStudentPaths$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(studentPathsActions.LOAD_ALL_STUDENT_PATHS),
      switchMap((action: { type: string; payload: any }) => {
        const { schoolId } = action.payload;
        const projection = STUDENT_PATH_PROJECTION;
        const where = { status: { $nin: ['DELETED', 'CLOSED'] } };
        const queries = {
          where,
        };
        const payload = { schoolId, projection, queries };
        return this.apiService.getStudentPaths(payload).pipe(
          switchMap(studentPaths => {
            // temporary fix to remove 'DELETED' and 'CLOSED' studentPaths, remove once backend is updated to apply filter (JE)
            const filteredPaths = filter(studentPaths, (studentPath: IStudentPath) => {
              return studentPath.status !== 'DELETED' && studentPath.status !== 'CLOSED';
            });
            return [new studentPathsActions.LoadAllStudentPathSuccess(filteredPaths)];
          }),
          catchError(error => of(new studentPathsActions.LoadAllStudentPathFail(error))),
        );
      }),
    );
  });

  loadStudentPaths$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(studentPathsActions.LOAD_STUDENT_PATH),
      switchMap((action: { type: string; payload: any }) => {
        const { schoolId, studentId } = action.payload;
        const payload = { schoolId, studentId };
        return this.apiService.getStudentPaths(payload).pipe(
          switchMap(paths => {
            return [new studentPathsActions.LoadStudentPathSuccess({ paths, studentId })];
          }),
          catchError(error => of(new studentPathsActions.LoadStudentPathFail(error))),
        );
      }),
    );
  });

  createStudentPath$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(studentPathsActions.CREATE_STUDENT_PATH),
      switchMap((action: any) => {
        const { schoolId, studentId, pathData } = action.payload;
        const mixpanelEvent = this.eventFormatterService.getCreateStudentPathEvent({ view: 'STUDENT-PROFILE', portal: 'SCHOOL' });
        return this.apiService.createStudentPath(schoolId, studentId, pathData, mixpanelEvent).pipe(
          mergeMap(path => {
            return [new studentPathsActions.CreateStudentPathSuccess(path)];
          }),
          catchError(error => {
            return of(new studentPathsActions.CreateStudentPathFail(error));
          }),
        );
      }),
    );
  });

  bulkCreateStudentPaths$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(studentPathsActions.BULK_CREATE_STUDENT_PATH),
      switchMap((action: any) => {
        const payload: IStudentPathBulkCreatePayload = action.payload;
        const { origin } = payload;
        const studentPathEvent = this.eventFormatterService.getCreateStudentPathEvent({ view: 'BATCH-ACTION', portal: 'SCHOOL' });
        const mixpanelEvents = [studentPathEvent];
        const httpResponseObsv$: Observable<HttpResponse<object>> = this.apiService.bulkCreateStudentPath(payload, mixpanelEvents as any);
        return httpResponseObsv$.pipe(
          takeLast(1),
          map(response => BatchActionsEffectsUtilities.getJobSubject(this.backgroundJob, { response })),
          tap(subject => {
            this.modalsService.openBackgroundJobSpinnerModal({ backgroundJobSubject: subject, title: 'Creating postsecondary paths' });
          }),
          switchMap(identity),
          timeout(BACKGROUND_JOB_TIMEOUT_THRESHOLDS.BATCH_ACTIONS),
          tap(({ type }) => {
            if (type === BACKGROUND_JOB_STATUS_TYPES.RESOLVE) {
              BatchActionsEffectsUtilities.sendSnack(this.snackBarService, { success: true, origin });
              this.backgroundJobNotificationService.sendMessage({ backgroundJob: 'BulkStudentPathCreate' });
            }
          }),
          catchError(error => {
            BatchActionsEffectsUtilities.sendSnack(this.snackBarService, { success: false, origin });
            return of(new studentPathsActions.BulkCreateStudentPathFail(error));
          }),
        );
      }),
    );
  }, { dispatch: false });

  updateStudentPath$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(studentPathsActions.UPDATE_STUDENT_PATH),
      switchMap((action: any) => {
        const { studentPathId, status, planDowngrade, schoolId, studentId } = action.payload;
        // two paths can't have a 'Plans to enroll' status, so the planDowngrade property
        // is used to make sure an old path is downgraded before a new path is upgraded (JE)
        if (planDowngrade) {
          const { pathToDowngrade, newStatus } = planDowngrade;
          const payload = { studentPathId, status };
          return this.apiService.updateStudentPath(pathToDowngrade, newStatus).pipe(
            mergeMap(path => {
              return [
                new studentPathsActions.UpdateStudentPathSuccess(path),
                new studentPathsActions.UpdateStudentPath(payload),
              ];
            }),
            catchError(error => {
              return of(new studentPathsActions.UpdateStudentPathFail(error));
            }),
          );
        } else {
          return this.apiService.updateStudentPath(studentPathId, status).pipe(
            mergeMap(path => {
              return [
                new studentPathsActions.UpdateStudentPathSuccess(path),
              ];
            }),
            catchError(error => {
              return of(new studentPathsActions.UpdateStudentPathFail(error));
            }),
          );
        }
      }),
    );
  });

  bulkUpdateStudentPaths$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(studentPathsActions.BULK_UPDATE_STUDENT_PATH),
      switchMap((action: any) => {
        const payload: IStudentPathBulkUpdatePayload = action.payload;
        const { origin } = payload;
        const httpResponseObsv$: Observable<HttpResponse<object>> = this.apiService.patchStudentPaths(payload);
        return httpResponseObsv$.pipe(
          takeLast(1),
          map(response => BatchActionsEffectsUtilities.getJobSubject(this.backgroundJob, { response })),
          tap(subject => {
            this.modalsService.openBackgroundJobSpinnerModal({ backgroundJobSubject: subject, title: 'Updating postsecondary paths' });
          }),
          switchMap(identity),
          timeout(BACKGROUND_JOB_TIMEOUT_THRESHOLDS.BATCH_ACTIONS),
          tap(({ type }) => {
            if (type === BACKGROUND_JOB_STATUS_TYPES.RESOLVE) {
              BatchActionsEffectsUtilities.sendSnack(this.snackBarService, { success: true, origin });
              this.backgroundJobNotificationService.sendMessage({ backgroundJob: 'BulkStudentPathUpdate' });
            }
          }),
          catchError(error => {
            BatchActionsEffectsUtilities.sendSnack(this.snackBarService, { success: false, origin });
            return of(new studentPathsActions.BulkUpdateStudentPathFail(error));
          }),
        );
      }),
    );
  }, { dispatch: false });
}
