import { UtilitiesService } from 'Src/ng2/shared/services/utilities/utilities.service';
import { WizardFilterActionsModule } from '../../../../wizard-filter-actions/wizard-filter-actions.module';
import { CommonModule } from '@angular/common';
import { RegentsScheduledTable } from './regents-scheduled-table.component';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [RegentsScheduledTable],
  exports: [RegentsScheduledTable],
  providers: [UtilitiesService],
  imports: [CommonModule, WizardFilterActionsModule],
})
export class RegentsScheduledTableModule {}
