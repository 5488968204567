import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { WindowRef } from '../../../../../../projects/shared/services/window-ref/windowRef';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  constructor (@Inject(DOCUMENT) private document: any, private windowRef: WindowRef) {}

  public getRawCookies (): string {
    return this.document.cookie;
  }

  public getCookieArray (): string[] {
    return this.getRawCookies().split('; ');
  }

  // sets expiration date in the past and max age to 0, browser will automatically delete
  public expireCookie (cookie: string): void {
    const expiration = ' expires=Thu, 01 Jan 1970 00:00:00 UTC; Max-Age=0;';
    const path = ' path=/;';
    const hostname = this.windowRef.nativeWindow.location.hostname;
    const domain = ' domain=' + hostname + ';';
    document.cookie = cookie + expiration + path + domain;
    if (hostname?.includes('.')) {
      const urlSection = hostname.split('.');
      urlSection.shift(); // get rid of first value which will be the subdomain
      const noSubDomain = ' domain=.' + urlSection.join('.') + ';';
      document.cookie = cookie + expiration + path + noSubDomain;
    }
  }
}
