<nv-pill-button
  [icon]="'dropdown-blue'"
  [text]="pillLabel"
  [matMenuTriggerFor]="dropdownMenu.menu"
  [textIsPlaceholder]="!selected"
  [isInverted]="isInverted"
></nv-pill-button>

<nv-dropdown-menu
  #dropdownMenu="nvDropdownMenu"
  (selectItem)="handleSelectSuboption($event)"
  [options]="options"
  [selected]="selected"
  [customClass]="customClass" 
></nv-dropdown-menu>
