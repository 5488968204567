import { Injectable } from '@angular/core';
import { reverse } from 'lodash';
import STANDARD_BAR_COLOR_CONFIG from './../viz-services/viz-standard-bar-color-config.json';
import Variables from '../../../../../projects/shared/nvps-libraries/design/styles/variables/variables.json';

@Injectable()
export class DashboardCircleService {
  getColors (data: any, ColorConfig?: any): Array<{ regular: string; bright?: string }> {
    const { multiColor } = STANDARD_BAR_COLOR_CONFIG;
    const _ColorConfig = ColorConfig || multiColor;
    const colorCount = _ColorConfig.length;
    const colorArray = [];
    let m = 0;
    [...data].forEach(i => {
      if (m === colorCount) m = 0;
      const color = _ColorConfig[m];
      const parsedColor = color.bright ? { ...color } : { regular: `var(${color.regular})` };
      colorArray.push(parsedColor);
      m++;
    });
    return colorArray;
  }

  sortColors (colorArray: Array<{ regular: string; bright?: string }>): Array<{ regular: string; bright?: string }> {
    return reverse(colorArray);
  }

  public shadeColor (color: string, percent: number): string {
    if (color.includes('var(')) {
      const colorVar = color.replace('var(', '').replace(')', '');
      color = Variables.ColorPaletteVariables[`${colorVar}`] || Variables.VizColorVariables[`${colorVar}`];
    }

    if (color.toLowerCase().includes('rgb')) {
      return this.shadeRGBColor(color, percent);
    }

    if (color.includes('#')) {
      return this.shadeHexColor(color, percent);
    }
  }

  // lighten or darken RGB color
  // positive percent = lighten
  // negative percent = darken
  private shadeRGBColor (color: string, percent: number): string {
    let f = color.split(',');
    let t = percent < 0 ? 0 : 255;
    let p = percent < 0 ? percent * -1 : percent;
    let R = parseInt(f[0].slice(4));
    let G = parseInt(f[1]);
    let B = parseInt(f[2]);
    return (
      'rgb(' +
      (Math.round((t - R) * p) + R) +
      ',' +
      (Math.round((t - G) * p) + G) +
      ',' +
      (Math.round((t - B) * p) + B) +
      ')'
    );
  }

  // lighten or darken HEX color
  // positive percent = lighten
  // negative percent = darken
  private shadeHexColor (color: string, percent: number): string {
    // hex to rgb
    let R = parseInt(color.substring(1, 3), 16);
    let G = parseInt(color.substring(3, 5), 16);
    let B = parseInt(color.substring(5, 7), 16);

    const t = percent < 0 ? 0 : 255;
    const p = percent < 0 ? percent * -1 : percent;

    R = Math.round((t - R) * p) + R;
    G = Math.round((t - G) * p) + G;
    B = Math.round((t - B) * p) + B;

    // rgb to hex
    const RR = R.toString(16).toUpperCase();
    const GG = G.toString(16).toUpperCase();
    const BB = B.toString(16).toUpperCase();

    return `#${RR}${GG}${BB}`;
  }
}
