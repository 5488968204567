export const GraduationPlanWarnings = {
  incompleteGradPlan: 'Incomplete graduation plan',
  lessThan90AttendanceLastYear: 'Less than 90% attendance in prior school year',
  lessThan65AttendanceLastYear: 'Less than 65% attendance in prior school year',
  lessThanCreditsRegentsCheck: 'Check credits and Regents',
  greaterOrEqualTo2CreditGaps: '2 plus credit gaps',
  ageOut: 'Aged out or at risk of aging out',
  localEligiblePlannedForRegents: 'Local eligible planned for Regents',
};

export const GraduationPlanWarningsCreditAndRegentsThresholds = {
  4: {
    lessThanCredits: 30,
    lessThanRegents: 2,
  },
  3: {
    lessThanCredits: 20,
    lessThanRegents: 1,
  },
  2: {
    lessThanCredits: 10,
    lessThanRegents: null,
  },
  1: {
    lessThanCredits: null,
    lessThanRegents: null,
  },
};
