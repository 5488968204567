<div class="nv-wizard-table credit-gaps-generic-table">
    <div class="app-headline mat-padding no-pad-left">
        <h3> {{ tableTitle }} </h3>
    </div>

    <div class="row">
        <div class="table-container">
            <table class="nv-table nv-table-dense">
                <thead>
                    <tr *ngIf="currentFilter">
                        <th></th>
                        <th>Total</th>
                        <th>
                            <div>Jan / Mar</div>
                            <div>{{ currentFilter }}</div>
                        </th>
                        <th>
                            <div>June</div>
                            <div>{{ currentFilter }}</div>
                        </th>
                        <th>
                            <div>Aug</div>
                            <div>{{ currentFilter }}</div>
                        </th>

                        <th>
                            <div>Beyond</div>
                            <div>{{ currentFilter }}</div>
                        </th>
                        <th>
                            <div>Plan</div>
                            <div>Incomplete</div>
                        </th>
                        <th>
                            <div>Planned</div>
                            <div>Non-Grad</div>
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <!-- FIVE PLUS -->
                    <tr class="red">
                        <td class="mat-subtitle-2">
                            <p>5.01+</p>
                        </td>
                        <td [ngClass]="{ 'is-hovered': fivePlusHover && rowTotals.fivePlus.length > 0 }"
                            (mouseenter)='fivePlusHover=true' (mouseleave)="fivePlusHover=false">
                            <wizard-filter-actions [data]="rowTotals.fivePlus" [options]="filterOptions">
                            </wizard-filter-actions>
                        </td>
                        <td *ngFor="let cell of table.rowFivePlus | keyvalue: orderTableRows"
                            [ngClass]="{'is-hovered': cell.hover && cell.value.students.length > 0 }"
                            (mouseenter)="cell.hover=true" (mouseleave)="cell.hover=false">
                            <wizard-filter-actions [data]="cell.value.students" [options]="filterOptions">
                            </wizard-filter-actions>
                        </td>
                    </tr>

                    <!-- TWO TO FIVE -->
                    <tr class="pink">
                        <td class="mat-subtitle-2">
                            <p>2.01 - 5.0</p>
                        </td>
                        <td [ngClass]="{'is-hovered': fiveHover && rowTotals.twoFive.length > 0 }"
                            (mouseenter)="fiveHover=true" (mouseleave)="fiveHover=false">
                            <wizard-filter-actions [data]="rowTotals.twoFive" [options]="filterOptions">
                            </wizard-filter-actions>
                        </td>
                        <td *ngFor="let cell of table.rowTwoFive | keyvalue: orderTableRows"
                            [ngClass]="{'is-hovered': cell.hover && cell.value.students.length > 0 }"
                            (mouseenter)="cell.hover=true" (mouseleave)="cell.hover=false">
                            <wizard-filter-actions [data]="cell.value.students" [options]="filterOptions">
                            </wizard-filter-actions>
                        </td>
                    </tr>

                    <!-- ONE TO TWO -->
                    <tr class="orange">
                        <td class="mat-subtitle-2">
                            <p>1.01 - 2.0</p>
                        </td>
                        <td [ngClass]="{'is-hovered': twoHover && rowTotals.oneTwo.length > 0 }"
                            (mouseenter)="twoHover=true" (mouseleave)="twoHover=false">
                            <wizard-filter-actions [data]="rowTotals.oneTwo" [options]="filterOptions">
                            </wizard-filter-actions>
                        </td>
                        <td *ngFor="let cell of table.rowOneTwo | keyvalue: orderTableRows"
                            [ngClass]="{'is-hovered': cell.hover && cell.value.students.length > 0 }"
                            (mouseenter)="cell.hover=true" (mouseleave)="cell.hover=false">
                            <wizard-filter-actions [data]="cell.value.students" [options]="filterOptions">
                            </wizard-filter-actions>
                        </td>
                    </tr>

                    <!-- ZERO TO ONE -->
                    <tr class="yellow">
                        <td class="mat-subtitle-2">
                            <p>0.01 - 1.0</p>
                        </td>
                        <td [ngClass]="{'is-hovered': oneHover && rowTotals.zeroOne.length > 0 }"
                            (mouseenter)="oneHover=true" (mouseleave)="oneHover=false">
                            <wizard-filter-actions [data]="rowTotals.zeroOne" [options]="filterOptions">
                            </wizard-filter-actions>
                        </td>
                        <td *ngFor="let cell of table.rowZeroOne | keyvalue: orderTableRows"
                            [ngClass]="{'is-hovered': cell.hover && cell.value.students.length > 0 }"
                            (mouseenter)="cell.hover=true" (mouseleave)="cell.hover=false">
                            <wizard-filter-actions [data]="cell.value.students" [options]="filterOptions">
                            </wizard-filter-actions>
                        </td>
                    </tr>

                    <!-- ZERO -->
                    <tr class="green">
                        <td class="mat-subtitle-2">
                            <p>0</p>
                        </td>
                        <td [ngClass]="{'is-hovered': zeroHover && rowTotals.zero.length > 0 }"
                            (mouseenter)="zeroHover=true" (mouseleave)="zeroHover=false">
                            <wizard-filter-actions [data]="rowTotals.zero" [options]="filterOptions">
                            </wizard-filter-actions>
                        </td>
                        <td *ngFor="let cell of table.rowZero | keyvalue: orderTableRows"
                            [ngClass]="{'is-hovered': cell.hover && cell.value.students.length > 0 }"
                            (mouseenter)="cell.hover=true" (mouseleave)="cell.hover=false">
                            <wizard-filter-actions [data]="cell.value.students" [options]="filterOptions">
                            </wizard-filter-actions>
                        </td>
                    </tr>

                    <tr class="white">
                        <td class="mat-subtitle-2">
                            <div>Total</div>
                        </td>
                        <td *ngFor="let total of colTotals | keyvalue: orderTableRows">
                            <wizard-filter-actions [data]="total.value" [options]="filterOptions">
                            </wizard-filter-actions>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- CALCULATIONS -->
        <div class="summary-container summary-layout">
            <div class="wizard-table-summary">
                <div class="summary">
                    <div *ngIf="studentFieldForTableRows === gapCols.creditMaxGapsNoPlan">
                        <span class="count linked">
                            <wizard-filter-actions [data]="studentsWithCreditMaxGapsNoPlanIds"
                                [options]="filterOptions">
                            </wizard-filter-actions>
                        </span>
                        <div class="description">{{ labels.unaddressed }}</div>
                    </div>

                    <div *ngIf="studentFieldForTableRows === gapCols.creditMaxGaps">
                        <span class="count linked">
                            <wizard-filter-actions [data]="studentsWithCreditMaxGapsIds" [options]="filterOptions">
                            </wizard-filter-actions>
                        </span>
                        <div class="description">{{ labels.addressed }}</div>
                    </div>
                    <mat-radio-group class="nv-mat-radio-group" [(ngModel)]="studentFieldForTableRows" (change)="toggleTable()">
                        <mat-radio-button [value]="gapCols.creditMaxGaps">
                            <span class="mat-caption">View by students with credit gaps</span>
                        </mat-radio-button>

                        <mat-radio-button [value]="gapCols.creditMaxGapsNoPlan">
                            <span class="mat-caption">View by students with unaddressed credit gaps</span>
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
        </div>
    </div>
</div>