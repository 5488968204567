import { GraphQLExperiencesHelperService } from './../../../../services/graphql-helpers/experiences/experiences-queries.service';
import { ApiService } from './../../../../services/api-service/api-service';
import { IModalConfig } from './../experience-form/experience-form.component';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ExperienceModalService {
  constructor (private apiService: ApiService, private graphQLExperiencesHelperService: GraphQLExperiencesHelperService) {}

  getExperienceModalConfig$ (modalFocus, formConfigKey?): Observable<IModalConfig[]> {
    const query = this.graphQLExperiencesHelperService.getModalConfigQuery(modalFocus, formConfigKey);

    const data = {
      query,
      fetchPolicy: 'no-cache',
    };

    return this.apiService.getStudentsGraphQL(data).pipe(
      map(({ data: { ModalConfig } }) => ModalConfig),
    );
  }
}
