import { Injectable } from '@angular/core';

@Injectable()
export class GraphQLActivitiesHelperService {
  constructor() {
    //
  }

  getPartnerOrgsQuery(schoolId, where = '{}'): string {
    return `{
      PartnerOrgs(schoolId: "${schoolId}", where: "${where}"){
        _id
        name
      }
    }`;
  }

  updatePartnerOrgQuery(partnerOrgId, patch): string {
    return `mutation{
      updatePartnerOrg(activityId: "${partnerOrgId}", patch: ${patch}){
        _id
        name
      }
    }`;
  }

  createPartnerOrgQuery(schoolId: string, partnerOrgParams: { name: string }): string {
    const { name } = partnerOrgParams;
    return `mutation{
      createPartnerOrg(options: {schoolId: "${schoolId}", name: "${name}"}){
        _id
        name
      }
    }`;
  }
}
