import { createSelector, createFeatureSelector } from '@ngrx/store';

const getPortalState = createFeatureSelector('PORTAL_STORE');
export const getSchoolState = createSelector(
  getPortalState,
  (state: any) => state.school,
);
export const getSchoolLoadedStatus = createSelector(
  getSchoolState,
  (state: any) => state.loaded,
);
export const getSchoolLoadingStatus = createSelector(
  getSchoolState,
  (state: any) => state.loading,
);
export const getSchool = createSelector(
  getSchoolState,
  (state: any) => state.school,
);
export const getSchoolUpdatedResult = createSelector(
  getSchoolState,
  (state: any) => state.updated,
);
