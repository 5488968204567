import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseModalComponent } from '../../base-modal.component';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { IDropdownOption } from '../../../../../../projects/shared/nvps-libraries/design/interfaces/design-library.interface';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IUserMini } from '../../../typings/interfaces/user.interface';

export interface ICollegeNowEligibilityModalData {
  currentUser: IUserMini;
}

@Component({
  templateUrl: 'college-now-eligibility-modal.component.html',
  styleUrls: ['./college-now-eligibility-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class CollegeNowEligibilityModalComponent extends BaseModalComponent implements OnInit {
  readonly title = 'CUNY College Now Eligibility';
  readonly isProfileMode: boolean = true;
  readonly eligibilityOptions: IDropdownOption[] = [
    { key: 'Eligible', human: 'Eligible' },
    { key: 'Not eligible', human: 'Not eligible' },
  ];

  public currentUser: IUserMini;
  public selectedOption: string = this.eligibilityOptions[1].key;
  public isEligibleSelected: boolean;

  public formControl: FormGroup;

  constructor (
    dialogRef: MatDialogRef<CollegeNowEligibilityModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ICollegeNowEligibilityModalData,
  ) {
    super(dialogRef);
  }

  ngOnInit () {
    this.currentUser = this.data.currentUser;
    this.formControl = new FormGroup({
      comment: new FormControl('', Validators.compose([Validators.required])),
    });
  }

  onOptionChange (optionKey: string) {
    this.isEligibleSelected = optionKey === this.eligibilityOptions[0].key;
    this.selectedOption = optionKey;
  }

  onCancel (): void {
    super.close();
  }

  onSave () {
    const patch = this.formatEligibilityPatch();
    super.close(patch);
  }

  private formatEligibilityPatch () {
    const manualUpdateDate = new Date().toISOString();
    const patch = {
      collegeNowEditable: {
        cunyCollegeNowEligible: true,
        manuallyUpdatedAt: manualUpdateDate,
        manuallyUpdatedBy: this.currentUser,
        eligibilityComment: this.formControl.controls.comment.value,
      },
    } as any;

    return patch;
  }
}
