import { TCreditSubjAreas, TValidGradReqs } from '../../../ng2/shared/typings/interfaces/school.interface';
interface IStringTMap<T> {
  [key: string]: {
    [key: string]: T
  }
}

// (DS) Use this spreadsheet to update codes
// https://docs.google.com/spreadsheets/d/1cwReHlTV8XX-Dad22ya112JYYQ8tAeqSSvr8IihIYUk/edit#gid=1197221804

export interface ICodeDeckValues {
  courseCode: string;
  // (DS) gradReq is a credit requirement key that can be found in CreditRequirements constant
  gradReq: TValidGradReqs;
  creditValue: number;
  subjectArea: TCreditSubjAreas;
}

export interface ICodeDeck {
  // (DS) elaCore is a subject key that can be found in SubjectAreas constant
  elaCore?: ICodeDeckValues[];
  mathAlg?: ICodeDeckValues[];
  mathGeom?: ICodeDeckValues[];
  mathTrig?: ICodeDeckValues[];
  sciChem?: ICodeDeckValues[];
  sciEarth?: ICodeDeckValues[];
  sciLiving?: ICodeDeckValues[];
  sciPhysics?: ICodeDeckValues[];
  ssGlobal?: ICodeDeckValues[];
  ssUs?: ICodeDeckValues[];
  health?: ICodeDeckValues[];
  ssGovtEcon?: ICodeDeckValues[];
  ssGovt?: ICodeDeckValues[];
  ssEcon?: ICodeDeckValues[];
  sciBio?: ICodeDeckValues[];
  art?: ICodeDeckValues[];
  elaElect?: ICodeDeckValues[];
  lote?: ICodeDeckValues[];
  advisory?: ICodeDeckValues[];
  mathCalc?: ICodeDeckValues[];
  mathPreCalc?: ICodeDeckValues[];
  mathStats?: ICodeDeckValues[];
  pe?: ICodeDeckValues[];
  sciPhysical?: ICodeDeckValues[];
  sciForensics?: ICodeDeckValues[];
  sciLife?: ICodeDeckValues[];
  cte?: ICodeDeckValues[];
  elaEsl?: ICodeDeckValues[];
  guidance?: ICodeDeckValues[];
  ssElective?: ICodeDeckValues[];
  misc?: ICodeDeckValues[];
  mathOther?: ICodeDeckValues[];
  sciOther?: ICodeDeckValues[];
  functionalId?: ICodeDeckValues[];
}

export const CodeDeckDistrict: IStringTMap<ICodeDeck> = {
  NYC: {
    ANNUALIZED: {
      art: [
        {
          courseCode: 'AGN11',
          gradReq: 'art',
          creditValue: 2,
          subjectArea: 'art',
        },
        {
          courseCode: 'ANN31',
          gradReq: 'art',
          creditValue: 2,
          subjectArea: 'art',
        },
      ],
      elaCore: [
        {
          courseCode: 'EEN41',
          gradReq: 'elaCore',
          creditValue: 2,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EEN42',
          gradReq: 'elaCore',
          creditValue: 2,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EEN43',
          gradReq: 'elaCore',
          creditValue: 2,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EEN44',
          gradReq: 'elaCore',
          creditValue: 2,
          subjectArea: 'elaCore',
        },
      ],
      health: [
        {
          courseCode: 'PHN11',
          gradReq: 'health',
          creditValue: 1,
          subjectArea: 'health',
        },
      ],
      lote: [
        {
          courseCode: 'FSN31',
          gradReq: 'lote',
          creditValue: 2,
          subjectArea: 'lote',
        },
        {
          courseCode: 'FSN32',
          gradReq: 'lote',
          creditValue: 2,
          subjectArea: 'lote',
        },
        {
          courseCode: 'FSN41',
          gradReq: 'lote',
          creditValue: 2,
          subjectArea: 'lote',
        },
        {
          courseCode: 'FSN42',
          gradReq: 'lote',
          creditValue: 2,
          subjectArea: 'lote',
        },
        {
          courseCode: 'FSN43',
          gradReq: 'lote',
          creditValue: 2,
          subjectArea: 'lote',
        },
      ],
      mathCalc: [
        {
          courseCode: 'MCN11',
          gradReq: 'math',
          creditValue: 2,
          subjectArea: 'mathCalc',
        },
      ],
      mathAlg: [
        {
          courseCode: 'MEN11',
          gradReq: 'math',
          creditValue: 2,
          subjectArea: 'mathAlg',
        },
        {
          courseCode: 'MEN21',
          gradReq: 'math',
          creditValue: 2,
          subjectArea: 'mathAlg',
        },
        {
          courseCode: 'MEN22',
          gradReq: 'math',
          creditValue: 2,
          subjectArea: 'mathAlg',
        },
      ],
      mathGeom: [
        {
          courseCode: 'MGN11',
          gradReq: 'math',
          creditValue: 2,
          subjectArea: 'mathGeom',
        },
        {
          courseCode: 'MGN22',
          gradReq: 'math',
          creditValue: 2,
          subjectArea: 'mathGeom',
        },
      ],
      mathPreCalc: [
        {
          courseCode: 'MPN11',
          gradReq: 'math',
          creditValue: 2,
          subjectArea: 'mathPreCalc',
        },
      ],
      mathTrig: [
        {
          courseCode: 'MRN11',
          gradReq: 'math',
          creditValue: 2,
          subjectArea: 'mathTrig',
        },
        {
          courseCode: 'MRN21',
          gradReq: 'math',
          creditValue: 2,
          subjectArea: 'mathTrig',
        },
        {
          courseCode: 'MRN22',
          gradReq: 'math',
          creditValue: 2,
          subjectArea: 'mathTrig',
        },
      ],
      mathStats: [
        {
          courseCode: 'MSN11',
          gradReq: 'math',
          creditValue: 2,
          subjectArea: 'mathStats',
        },
      ],
      elaElect: [
        {
          courseCode: 'EWN11',
          gradReq: 'misc',
          creditValue: 2,
          subjectArea: 'elaElect',
        },
      ],
      advisory: [
        {
          courseCode: 'GAN41',
          gradReq: 'misc',
          creditValue: 1,
          subjectArea: 'advisory',
        },
        {
          courseCode: 'GAN42',
          gradReq: 'misc',
          creditValue: 1,
          subjectArea: 'advisory',
        },
        {
          courseCode: 'GAN43',
          gradReq: 'misc',
          creditValue: 1,
          subjectArea: 'advisory',
        },
        {
          courseCode: 'GAN44',
          gradReq: 'misc',
          creditValue: 1,
          subjectArea: 'advisory',
        },
      ],
      pe: [
        {
          courseCode: 'PPN11',
          gradReq: 'pe',
          creditValue: 1,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PPN41',
          gradReq: 'pe',
          creditValue: 1,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PPN42',
          gradReq: 'pe',
          creditValue: 1,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PPN43',
          gradReq: 'pe',
          creditValue: 1,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PPN44',
          gradReq: 'pe',
          creditValue: 1,
          subjectArea: 'pe',
        },
      ],
      sciBio: [
        {
          courseCode: 'SBN11',
          gradReq: 'sciLife',
          creditValue: 2,
          subjectArea: 'sciBio',
        },
      ],
      sciLiving: [
        {
          courseCode: 'SLN11',
          gradReq: 'sciLife',
          creditValue: 2,
          subjectArea: 'sciLiving',
        },
        {
          courseCode: 'SLN21',
          gradReq: 'sciLife',
          creditValue: 2,
          subjectArea: 'sciLiving',
        },
      ],
      sciLife: [
        {
          courseCode: 'SWN11',
          gradReq: 'sciLife',
          creditValue: 2,
          subjectArea: 'sciLife',
        },
      ],
      sciChem: [
        {
          courseCode: 'SCN11',
          gradReq: 'sciPhysical',
          creditValue: 2,
          subjectArea: 'sciChem',
        },
        {
          courseCode: 'SCN21',
          gradReq: 'sciPhysical',
          creditValue: 2,
          subjectArea: 'sciChem',
        },
      ],
      sciPhysical: [
        {
          courseCode: 'SDN11',
          gradReq: 'sciPhysical',
          creditValue: 2,
          subjectArea: 'sciPhysical',
        },
      ],
      sciEarth: [
        {
          courseCode: 'SEN11',
          gradReq: 'sciPhysical',
          creditValue: 2,
          subjectArea: 'sciEarth',
        },
      ],
      sciForensics: [
        {
          courseCode: 'SFN11',
          gradReq: 'sciOther',
          creditValue: 2,
          subjectArea: 'sciForensics',
        },
      ],
      sciPhysics: [
        {
          courseCode: 'SPN11',
          gradReq: 'sciPhysical',
          creditValue: 2,
          subjectArea: 'sciPhysics',
        },
        {
          courseCode: 'SPN21',
          gradReq: 'sciPhysical',
          creditValue: 2,
          subjectArea: 'sciPhysics',
        },
      ],
      ssGlobal: [
        {
          courseCode: 'HGN21',
          gradReq: 'ssGlobal',
          creditValue: 2,
          subjectArea: 'ssGlobal',
        },
        {
          courseCode: 'HGN22',
          gradReq: 'ssGlobal',
          creditValue: 2,
          subjectArea: 'ssGlobal',
        },
      ],
      ssGovt: [
        {
          courseCode: 'HVN11',
          gradReq: 'ssGovt',
          creditValue: 1,
          subjectArea: 'ssGovt',
        },
      ],
      ssGovtEcon: [
        {
          courseCode: 'HFN11',
          gradReq: 'ssGovtEcon',
          creditValue: 2,
          subjectArea: 'ssGovtEcon',
        },
      ],
      ssUs: [
        {
          courseCode: 'HUN11',
          gradReq: 'ssUs',
          creditValue: 2,
          subjectArea: 'ssUs',
        },
      ],
    },
    SEMESTER: {
      art: [
        {
          courseCode: 'ADS21',
          gradReq: 'art',
          creditValue: 1,
          subjectArea: 'art',
        },
        {
          courseCode: 'ADS22',
          gradReq: 'art',
          creditValue: 1,
          subjectArea: 'art',
        },
        {
          courseCode: 'AES21',
          gradReq: 'art',
          creditValue: 1,
          subjectArea: 'art',
        },
        {
          courseCode: 'AGS21',
          gradReq: 'art',
          creditValue: 1,
          subjectArea: 'art',
        },
        {
          courseCode: 'AGS22',
          gradReq: 'art',
          creditValue: 1,
          subjectArea: 'art',
        },
        {
          courseCode: 'AHS11',
          gradReq: 'art',
          creditValue: 1,
          subjectArea: 'art',
        },
        {
          courseCode: 'AHS21',
          gradReq: 'art',
          creditValue: 1,
          subjectArea: 'art',
        },
        {
          courseCode: 'AHS22',
          gradReq: 'art',
          creditValue: 1,
          subjectArea: 'art',
        },
        {
          courseCode: 'AKS21',
          gradReq: 'art',
          creditValue: 1,
          subjectArea: 'art',
        },
        {
          courseCode: 'AKS22',
          gradReq: 'art',
          creditValue: 1,
          subjectArea: 'art',
        },
        {
          courseCode: 'AMS21',
          gradReq: 'art',
          creditValue: 1,
          subjectArea: 'art',
        },
        {
          courseCode: 'AMS22',
          gradReq: 'art',
          creditValue: 1,
          subjectArea: 'art',
        },
        {
          courseCode: 'ANS11',
          gradReq: 'art',
          creditValue: 1,
          subjectArea: 'art',
        },
        {
          courseCode: 'ANS21',
          gradReq: 'art',
          creditValue: 1,
          subjectArea: 'art',
        },
        {
          courseCode: 'ANS22',
          gradReq: 'art',
          creditValue: 1,
          subjectArea: 'art',
        },
        {
          courseCode: 'AQS11',
          gradReq: 'art',
          creditValue: 1,
          subjectArea: 'art',
        },
        {
          courseCode: 'AQS21',
          gradReq: 'art',
          creditValue: 1,
          subjectArea: 'art',
        },
        {
          courseCode: 'AQS22',
          gradReq: 'art',
          creditValue: 1,
          subjectArea: 'art',
        },
        {
          courseCode: 'AUS21',
          gradReq: 'art',
          creditValue: 1,
          subjectArea: 'art',
        },
        {
          courseCode: 'AUS22',
          gradReq: 'art',
          creditValue: 1,
          subjectArea: 'art',
        },
        {
          courseCode: 'AWS21',
          gradReq: 'art',
          creditValue: 1,
          subjectArea: 'art',
        },
        {
          courseCode: 'AWS22',
          gradReq: 'art',
          creditValue: 1,
          subjectArea: 'art',
        },
        {
          courseCode: 'AYS21',
          gradReq: 'art',
          creditValue: 1,
          subjectArea: 'art',
        },
        {
          courseCode: 'AYS22',
          gradReq: 'art',
          creditValue: 1,
          subjectArea: 'art',
        },
        {
          courseCode: 'CRS21',
          gradReq: 'art',
          creditValue: 1,
          subjectArea: 'art',
        },
        {
          courseCode: 'CRS22',
          gradReq: 'art',
          creditValue: 1,
          subjectArea: 'art',
        },
        {
          courseCode: 'UAS11',
          gradReq: 'art',
          creditValue: 1,
          subjectArea: 'art',
        },
        {
          courseCode: 'UAS21',
          gradReq: 'art',
          creditValue: 1,
          subjectArea: 'art',
        },
        {
          courseCode: 'UAS22',
          gradReq: 'art',
          creditValue: 1,
          subjectArea: 'art',
        },
        {
          courseCode: 'UDS11',
          gradReq: 'art',
          creditValue: 1,
          subjectArea: 'art',
        },
        {
          courseCode: 'UGS21',
          gradReq: 'art',
          creditValue: 1,
          subjectArea: 'art',
        },
        {
          courseCode: 'ULS11',
          gradReq: 'art',
          creditValue: 1,
          subjectArea: 'art',
        },
        {
          courseCode: 'UQS21',
          gradReq: 'art',
          creditValue: 1,
          subjectArea: 'art',
        },
        {
          courseCode: 'UQS22',
          gradReq: 'art',
          creditValue: 1,
          subjectArea: 'art',
        },
        {
          courseCode: 'UVS11',
          gradReq: 'art',
          creditValue: 1,
          subjectArea: 'art',
        },
        {
          courseCode: 'UVS21',
          gradReq: 'art',
          creditValue: 1,
          subjectArea: 'art',
        },
        {
          courseCode: 'UVS22',
          gradReq: 'art',
          creditValue: 1,
          subjectArea: 'art',
        },
      ],
      cte: [
        {
          courseCode: 'BCS11',
          gradReq: 'cte',
          creditValue: 1,
          subjectArea: 'cte',
        },
        {
          courseCode: 'BNS21',
          gradReq: 'cte',
          creditValue: 1,
          subjectArea: 'cte',
        },
        {
          courseCode: 'BNS22',
          gradReq: 'cte',
          creditValue: 1,
          subjectArea: 'cte',
        },
        {
          courseCode: 'TWS21',
          gradReq: 'cte',
          creditValue: 1,
          subjectArea: 'cte',
        },
        {
          courseCode: 'TWS22',
          gradReq: 'cte',
          creditValue: 1,
          subjectArea: 'cte',
        },
      ],
      misc: [
        {
          courseCode: 'KLS11',
          gradReq: 'cte',
          creditValue: 1,
          subjectArea: 'misc',
        },
      ],
      elaCore: [
        {
          courseCode: 'EES11',
          gradReq: 'elaCore',
          creditValue: 1,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EES21',
          gradReq: 'elaCore',
          creditValue: 1,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EES22',
          gradReq: 'elaCore',
          creditValue: 1,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EES41',
          gradReq: 'elaCore',
          creditValue: 1,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EES42',
          gradReq: 'elaCore',
          creditValue: 1,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EES43',
          gradReq: 'elaCore',
          creditValue: 1,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EES44',
          gradReq: 'elaCore',
          creditValue: 1,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EES61',
          gradReq: 'elaCore',
          creditValue: 1,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EES81',
          gradReq: 'elaCore',
          creditValue: 1,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EES82',
          gradReq: 'elaCore',
          creditValue: 1,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EES83',
          gradReq: 'elaCore',
          creditValue: 1,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EES84',
          gradReq: 'elaCore',
          creditValue: 1,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EES85',
          gradReq: 'elaCore',
          creditValue: 1,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EES86',
          gradReq: 'elaCore',
          creditValue: 1,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EES87',
          gradReq: 'elaCore',
          creditValue: 1,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EES88',
          gradReq: 'elaCore',
          creditValue: 1,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EESG3',
          gradReq: 'elaCore',
          creditValue: 1,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EESG4',
          gradReq: 'elaCore',
          creditValue: 1,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EESG9',
          gradReq: 'elaCore',
          creditValue: 1,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EESGF',
          gradReq: 'elaCore',
          creditValue: 0.5,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EESGG',
          gradReq: 'elaCore',
          creditValue: 0.5,
          subjectArea: 'elaCore',
        },
      ],
      health: [
        {
          courseCode: 'PHS11',
          gradReq: 'health',
          creditValue: 1,
          subjectArea: 'health',
        },
        {
          courseCode: 'PHS21',
          gradReq: 'health',
          creditValue: 0.5,
          subjectArea: 'health',
        },
        {
          courseCode: 'PHS22',
          gradReq: 'health',
          creditValue: 0.5,
          subjectArea: 'health',
        },
        {
          courseCode: 'PHS41',
          gradReq: 'health',
          creditValue: 0.25,
          subjectArea: 'health',
        },
      ],
      lote: [
        {
          courseCode: 'FFS61',
          gradReq: 'lote',
          creditValue: 1,
          subjectArea: 'lote',
        },
        {
          courseCode: 'FFS62',
          gradReq: 'lote',
          creditValue: 1,
          subjectArea: 'lote',
        },
        {
          courseCode: 'FSS21',
          gradReq: 'lote',
          creditValue: 1,
          subjectArea: 'lote',
        },
        {
          courseCode: 'FSS22',
          gradReq: 'lote',
          creditValue: 1,
          subjectArea: 'lote',
        },
        {
          courseCode: 'FSS41',
          gradReq: 'lote',
          creditValue: 1,
          subjectArea: 'lote',
        },
        {
          courseCode: 'FSS42',
          gradReq: 'lote',
          creditValue: 1,
          subjectArea: 'lote',
        },
        {
          courseCode: 'FSS61',
          gradReq: 'lote',
          creditValue: 1,
          subjectArea: 'lote',
        },
        {
          courseCode: 'FSS62',
          gradReq: 'lote',
          creditValue: 1,
          subjectArea: 'lote',
        },
        {
          courseCode: 'FSS63',
          gradReq: 'lote',
          creditValue: 1,
          subjectArea: 'lote',
        },
        {
          courseCode: 'FSS64',
          gradReq: 'lote',
          creditValue: 1,
          subjectArea: 'lote',
        },
        {
          courseCode: 'FSS65',
          gradReq: 'lote',
          creditValue: 1,
          subjectArea: 'lote',
        },
        {
          courseCode: 'FSS66',
          gradReq: 'lote',
          creditValue: 1,
          subjectArea: 'lote',
        },
        {
          courseCode: 'FSS81',
          gradReq: 'lote',
          creditValue: 1,
          subjectArea: 'lote',
        },
        {
          courseCode: 'FSS82',
          gradReq: 'lote',
          creditValue: 1,
          subjectArea: 'lote',
        },
        {
          courseCode: 'FSS83',
          gradReq: 'lote',
          creditValue: 1,
          subjectArea: 'lote',
        },
        {
          courseCode: 'FSS84',
          gradReq: 'lote',
          creditValue: 1,
          subjectArea: 'lote',
        },
        {
          courseCode: 'FSS85',
          gradReq: 'lote',
          creditValue: 1,
          subjectArea: 'lote',
        },
        {
          courseCode: 'FSS86',
          gradReq: 'lote',
          creditValue: 1,
          subjectArea: 'lote',
        },
        {
          courseCode: 'FTS61',
          gradReq: 'lote',
          creditValue: 1,
          subjectArea: 'lote',
        },
        {
          courseCode: 'FTS62',
          gradReq: 'lote',
          creditValue: 1,
          subjectArea: 'lote',
        },
      ],
      mathCalc: [
        {
          courseCode: 'MCS21',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathCalc',
        },
        {
          courseCode: 'MCS22',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathCalc',
        },
      ],
      mathAlg: [
        {
          courseCode: 'MES11',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathAlg',
        },
        {
          courseCode: 'MES21',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathAlg',
        },
        {
          courseCode: 'MES22',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathAlg',
        },
        {
          courseCode: 'MES31',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathAlg',
        },
        {
          courseCode: 'MES32',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathAlg',
        },
        {
          courseCode: 'MES33',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathAlg',
        },
        {
          courseCode: 'MES41',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathAlg',
        },
        {
          courseCode: 'MES42',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathAlg',
        },
        {
          courseCode: 'MES43',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathAlg',
        },
        {
          courseCode: 'MES44',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathAlg',
        },
        {
          courseCode: 'MES61',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathAlg',
        },
        {
          courseCode: 'MES62',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathAlg',
        },
      ],
      mathGeom: [
        {
          courseCode: 'MGS21',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathGeom',
        },
        {
          courseCode: 'MGS22',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathGeom',
        },
        {
          courseCode: 'MGS31',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathGeom',
        },
        {
          courseCode: 'MGS32',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathGeom',
        },
        {
          courseCode: 'MGS33',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathGeom',
        },
        {
          courseCode: 'MGS41',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathGeom',
        },
        {
          courseCode: 'MGS42',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathGeom',
        },
        {
          courseCode: 'MGS43',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathGeom',
        },
        {
          courseCode: 'MGS44',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathGeom',
        },
        {
          courseCode: 'MGS81',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathGeom',
        },
        {
          courseCode: 'MGS82',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathGeom',
        },
      ],
      mathOther: [
        {
          courseCode: 'MKS21',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathOther',
        },
        {
          courseCode: 'MKS22',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathOther',
        },
        {
          courseCode: 'MQS11',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathOther',
        },
        {
          courseCode: 'MQS21',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathOther',
        },
        {
          courseCode: 'MQS22',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathOther',
        },
      ],
      mathPreCalc: [
        {
          courseCode: 'MPS11',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathPreCalc',
        },
        {
          courseCode: 'MPS21',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathPreCalc',
        },
        {
          courseCode: 'MPS22',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathPreCalc',
        },
      ],
      mathTrig: [
        {
          courseCode: 'MRS21',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathTrig',
        },
        {
          courseCode: 'MRS22',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathTrig',
        },
        {
          courseCode: 'MRS31',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathTrig',
        },
        {
          courseCode: 'MRS32',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathTrig',
        },
        {
          courseCode: 'MRS33',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathTrig',
        },
        {
          courseCode: 'MRS41',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathTrig',
        },
        {
          courseCode: 'MRS42',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathTrig',
        },
        {
          courseCode: 'MRS43',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathTrig',
        },
        {
          courseCode: 'MRS44',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathTrig',
        },
      ],
      mathStats: [
        {
          courseCode: 'MSS21',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathStats',
        },
        {
          courseCode: 'MSS22',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathStats',
        },
      ],
      elaElect: [
        {
          courseCode: 'ECS11',
          gradReq: 'misc',
          creditValue: 1,
          subjectArea: 'elaElect',
        },
        {
          courseCode: 'ECS21',
          gradReq: 'misc',
          creditValue: 1,
          subjectArea: 'elaElect',
        },
        {
          courseCode: 'ECS22',
          gradReq: 'misc',
          creditValue: 1,
          subjectArea: 'elaElect',
        },
        {
          courseCode: 'ELS11',
          gradReq: 'misc',
          creditValue: 1,
          subjectArea: 'elaElect',
        },
        {
          courseCode: 'EQS21',
          gradReq: 'misc',
          creditValue: 1,
          subjectArea: 'elaElect',
        },
        {
          courseCode: 'EQS22',
          gradReq: 'misc',
          creditValue: 1,
          subjectArea: 'elaElect',
        },
        {
          courseCode: 'EWS11',
          gradReq: 'misc',
          creditValue: 1,
          subjectArea: 'elaElect',
        },
        {
          courseCode: 'EWS21',
          gradReq: 'misc',
          creditValue: 1,
          subjectArea: 'elaElect',
        },
        {
          courseCode: 'EWS22',
          gradReq: 'misc',
          creditValue: 1,
          subjectArea: 'elaElect',
        },
      ],
      elaEsl: [
        {
          courseCode: 'ESS11',
          gradReq: 'misc',
          creditValue: 1,
          subjectArea: 'elaEsl',
        },
        {
          courseCode: 'ESS22',
          gradReq: 'misc',
          creditValue: 1,
          subjectArea: 'elaEsl',
        },
        {
          courseCode: 'ESS42',
          gradReq: 'misc',
          creditValue: 1,
          subjectArea: 'elaEsl',
        },
        {
          courseCode: 'ESS43',
          gradReq: 'misc',
          creditValue: 1,
          subjectArea: 'elaEsl',
        },
        {
          courseCode: 'ESS64',
          gradReq: 'misc',
          creditValue: 1,
          subjectArea: 'elaEsl',
        },
        {
          courseCode: 'ESS81',
          gradReq: 'misc',
          creditValue: 1,
          subjectArea: 'elaEsl',
        },
        {
          courseCode: 'ESS82',
          gradReq: 'misc',
          creditValue: 1,
          subjectArea: 'elaEsl',
        },
        {
          courseCode: 'ESS83',
          gradReq: 'misc',
          creditValue: 1,
          subjectArea: 'elaEsl',
        },
        {
          courseCode: 'ESS84',
          gradReq: 'misc',
          creditValue: 1,
          subjectArea: 'elaEsl',
        },
        {
          courseCode: 'ESS85',
          gradReq: 'misc',
          creditValue: 1,
          subjectArea: 'elaEsl',
        },
        {
          courseCode: 'ESS86',
          gradReq: 'misc',
          creditValue: 1,
          subjectArea: 'elaEsl',
        },
        {
          courseCode: 'ESS87',
          gradReq: 'misc',
          creditValue: 1,
          subjectArea: 'elaEsl',
        },
      ],
      advisory: [
        {
          courseCode: 'GAS11',
          gradReq: 'misc',
          creditValue: 1,
          subjectArea: 'advisory',
        },
        {
          courseCode: 'GAS21',
          gradReq: 'misc',
          creditValue: 0.5,
          subjectArea: 'advisory',
        },
        {
          courseCode: 'GAS22',
          gradReq: 'misc',
          creditValue: 0.25,
          subjectArea: 'advisory',
        },
        {
          courseCode: 'GAS81',
          gradReq: 'misc',
          creditValue: 0.5,
          subjectArea: 'advisory',
        },
        {
          courseCode: 'GAS82',
          gradReq: 'misc',
          creditValue: 0.5,
          subjectArea: 'advisory',
        },
        {
          courseCode: 'GAS83',
          gradReq: 'misc',
          creditValue: 0.5,
          subjectArea: 'advisory',
        },
        {
          courseCode: 'GAS84',
          gradReq: 'misc',
          creditValue: 0.5,
          subjectArea: 'advisory',
        },
        {
          courseCode: 'GAS85',
          gradReq: 'misc',
          creditValue: 0.5,
          subjectArea: 'advisory',
        },
        {
          courseCode: 'GAS86',
          gradReq: 'misc',
          creditValue: 0.5,
          subjectArea: 'advisory',
        },
        {
          courseCode: 'GAS87',
          gradReq: 'misc',
          creditValue: 0.5,
          subjectArea: 'advisory',
        },
        {
          courseCode: 'GAS88',
          gradReq: 'misc',
          creditValue: 0.5,
          subjectArea: 'advisory',
        },
      ],
      guidance: [
        {
          courseCode: 'GLS21',
          gradReq: 'misc',
          creditValue: 1,
          subjectArea: 'guidance',
        },
        {
          courseCode: 'GMS11',
          gradReq: 'misc',
          creditValue: 1,
          subjectArea: 'guidance',
        },
        {
          courseCode: 'GQS11',
          gradReq: 'misc',
          creditValue: 1,
          subjectArea: 'guidance',
        },
        {
          courseCode: 'GQS21',
          gradReq: 'misc',
          creditValue: 0.5,
          subjectArea: 'guidance',
        },
        {
          courseCode: 'GQS22',
          gradReq: 'misc',
          creditValue: 0.33,
          subjectArea: 'guidance',
        },
      ],
      ssElective: [
        {
          courseCode: 'HBS11',
          gradReq: 'misc',
          creditValue: 1,
          subjectArea: 'ssElective',
        },
        {
          courseCode: 'HBS21',
          gradReq: 'misc',
          creditValue: 1,
          subjectArea: 'ssElective',
        },
        {
          courseCode: 'HBS22',
          gradReq: 'misc',
          creditValue: 1,
          subjectArea: 'ssElective',
        },
        {
          courseCode: 'HLS11',
          gradReq: 'misc',
          creditValue: 1,
          subjectArea: 'ssElective',
        },
        {
          courseCode: 'HLS21',
          gradReq: 'misc',
          creditValue: 1,
          subjectArea: 'ssElective',
        },
        {
          courseCode: 'HLS22',
          gradReq: 'misc',
          creditValue: 1,
          subjectArea: 'ssElective',
        },
        {
          courseCode: 'HPS21',
          gradReq: 'misc',
          creditValue: 1,
          subjectArea: 'ssElective',
        },
        {
          courseCode: 'HQS11',
          gradReq: 'misc',
          creditValue: 1,
          subjectArea: 'ssElective',
        },
        {
          courseCode: 'HQS21',
          gradReq: 'misc',
          creditValue: 1,
          subjectArea: 'ssElective',
        },
        {
          courseCode: 'HQS22',
          gradReq: 'misc',
          creditValue: 1,
          subjectArea: 'ssElective',
        },
        {
          courseCode: 'HRS11',
          gradReq: 'misc',
          creditValue: 1,
          subjectArea: 'ssElective',
        },
        {
          courseCode: 'HRS21',
          gradReq: 'misc',
          creditValue: 1,
          subjectArea: 'ssElective',
        },
        {
          courseCode: 'HRS22',
          gradReq: 'misc',
          creditValue: 1,
          subjectArea: 'ssElective',
        },
      ],
      pe: [
        {
          courseCode: 'PFS11',
          gradReq: 'pe',
          creditValue: 0.58,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PFS21',
          gradReq: 'pe',
          creditValue: 0.5,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PFS81',
          gradReq: 'pe',
          creditValue: 0.5,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PFS82',
          gradReq: 'pe',
          creditValue: 0.5,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PGS11',
          gradReq: 'pe',
          creditValue: 0.58,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PPS11',
          gradReq: 'pe',
          creditValue: 0.58,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PPS21',
          gradReq: 'pe',
          creditValue: 0.5,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PPS22',
          gradReq: 'pe',
          creditValue: 0.5,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PPS31',
          gradReq: 'pe',
          creditValue: 0.5,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PPS41',
          gradReq: 'pe',
          creditValue: 0.58,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PPS42',
          gradReq: 'pe',
          creditValue: 0.58,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PPS71',
          gradReq: 'pe',
          creditValue: 0.58,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PPS72',
          gradReq: 'pe',
          creditValue: 0.58,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PPS73',
          gradReq: 'pe',
          creditValue: 0.58,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PPS74',
          gradReq: 'pe',
          creditValue: 0.58,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PPS75',
          gradReq: 'pe',
          creditValue: 0.58,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PPS76',
          gradReq: 'pe',
          creditValue: 0.58,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PPS77',
          gradReq: 'pe',
          creditValue: 0.58,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PPS81',
          gradReq: 'pe',
          creditValue: 0.5,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PPS82',
          gradReq: 'pe',
          creditValue: 0.5,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PPS83',
          gradReq: 'pe',
          creditValue: 0.5,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PPS84',
          gradReq: 'pe',
          creditValue: 0.5,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PPS85',
          gradReq: 'pe',
          creditValue: 0.5,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PPS86',
          gradReq: 'pe',
          creditValue: 0.5,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PPS87',
          gradReq: 'pe',
          creditValue: 0.5,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PPS88',
          gradReq: 'pe',
          creditValue: 0.5,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PQS11',
          gradReq: 'pe',
          creditValue: 0.58,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PQS22',
          gradReq: 'pe',
          creditValue: 0.5,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PSS11',
          gradReq: 'pe',
          creditValue: 0.58,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PTS11',
          gradReq: 'pe',
          creditValue: 0.58,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PWS11',
          gradReq: 'pe',
          creditValue: 0.58,
          subjectArea: 'pe',
        },
      ],
      sciBio: [
        {
          courseCode: 'SBS11',
          gradReq: 'sciLife',
          creditValue: 1,
          subjectArea: 'sciBio',
        },
        {
          courseCode: 'SBS21',
          gradReq: 'sciLife',
          creditValue: 1,
          subjectArea: 'sciBio',
        },
        {
          courseCode: 'SBS22',
          gradReq: 'sciLife',
          creditValue: 1,
          subjectArea: 'sciBio',
        },
        {
          courseCode: 'SBS41',
          gradReq: 'sciLife',
          creditValue: 1,
          subjectArea: 'sciBio',
        },
        {
          courseCode: 'SBS42',
          gradReq: 'sciLife',
          creditValue: 1,
          subjectArea: 'sciBio',
        },
        {
          courseCode: 'SBS43',
          gradReq: 'sciLife',
          creditValue: 1,
          subjectArea: 'sciBio',
        },
        {
          courseCode: 'SBS44',
          gradReq: 'sciLife',
          creditValue: 1,
          subjectArea: 'sciBio',
        },
      ],
      sciLiving: [
        {
          courseCode: 'SLS21',
          gradReq: 'sciLife',
          creditValue: 1,
          subjectArea: 'sciLiving',
        },
        {
          courseCode: 'SLS22',
          gradReq: 'sciLife',
          creditValue: 1,
          subjectArea: 'sciLiving',
        },
        {
          courseCode: 'SLS33',
          gradReq: 'sciLife',
          creditValue: 1,
          subjectArea: 'sciLiving',
        },
        {
          courseCode: 'SLS41',
          gradReq: 'sciLife',
          creditValue: 1,
          subjectArea: 'sciLiving',
        },
        {
          courseCode: 'SLS42',
          gradReq: 'sciLife',
          creditValue: 1,
          subjectArea: 'sciLiving',
        },
        {
          courseCode: 'SLS43',
          gradReq: 'sciLife',
          creditValue: 1,
          subjectArea: 'sciLiving',
        },
        {
          courseCode: 'SLS44',
          gradReq: 'sciLife',
          creditValue: 1,
          subjectArea: 'sciLiving',
        },
        {
          courseCode: 'SLS61',
          gradReq: 'sciLife',
          creditValue: 1,
          subjectArea: 'sciLiving',
        },
        {
          courseCode: 'SLS62',
          gradReq: 'sciLife',
          creditValue: 1,
          subjectArea: 'sciLiving',
        },
      ],
      sciLife: [
        {
          courseCode: 'SWS11',
          gradReq: 'sciLife',
          creditValue: 1,
          subjectArea: 'sciLife',
        },
        {
          courseCode: 'SWS21',
          gradReq: 'sciLife',
          creditValue: 1,
          subjectArea: 'sciLife',
        },
        {
          courseCode: 'SWS22',
          gradReq: 'sciLife',
          creditValue: 1,
          subjectArea: 'sciLife',
        },
      ],
      sciOther: [
        {
          courseCode: 'SQS11',
          gradReq: 'sciTotal',
          creditValue: 1,
          subjectArea: 'sciOther',
        },
        {
          courseCode: 'SQS21',
          gradReq: 'sciTotal',
          creditValue: 1,
          subjectArea: 'sciOther',
        },
        {
          courseCode: 'SQS22',
          gradReq: 'sciTotal',
          creditValue: 1,
          subjectArea: 'sciOther',
        },
      ],
      sciChem: [
        {
          courseCode: 'SCS21',
          gradReq: 'sciPhysical',
          creditValue: 1,
          subjectArea: 'sciChem',
        },
        {
          courseCode: 'SCS22',
          gradReq: 'sciPhysical',
          creditValue: 1,
          subjectArea: 'sciChem',
        },
        {
          courseCode: 'SCS41',
          gradReq: 'sciPhysical',
          creditValue: 1,
          subjectArea: 'sciChem',
        },
        {
          courseCode: 'SCS42',
          gradReq: 'sciPhysical',
          creditValue: 1,
          subjectArea: 'sciChem',
        },
      ],
      sciPhysical: [
        {
          courseCode: 'SDS21',
          gradReq: 'sciPhysical',
          creditValue: 1,
          subjectArea: 'sciPhysical',
        },
        {
          courseCode: 'SDS22',
          gradReq: 'sciPhysical',
          creditValue: 1,
          subjectArea: 'sciPhysical',
        },
      ],
      sciEarth: [
        {
          courseCode: 'SES21',
          gradReq: 'sciPhysical',
          creditValue: 1,
          subjectArea: 'sciEarth',
        },
        {
          courseCode: 'SES22',
          gradReq: 'sciPhysical',
          creditValue: 1,
          subjectArea: 'sciEarth',
        },
        {
          courseCode: 'SES41',
          gradReq: 'sciPhysical',
          creditValue: 1,
          subjectArea: 'sciEarth',
        },
        {
          courseCode: 'SES42',
          gradReq: 'sciPhysical',
          creditValue: 1,
          subjectArea: 'sciEarth',
        },
        {
          courseCode: 'SES43',
          gradReq: 'sciPhysical',
          creditValue: 1,
          subjectArea: 'sciEarth',
        },
        {
          courseCode: 'SES44',
          gradReq: 'sciPhysical',
          creditValue: 1,
          subjectArea: 'sciEarth',
        },
      ],
      sciForensics: [
        {
          courseCode: 'SFS11',
          gradReq: 'sciOther',
          creditValue: 1,
          subjectArea: 'sciForensics',
        },
        {
          courseCode: 'SFS21',
          gradReq: 'sciOther',
          creditValue: 1,
          subjectArea: 'sciForensics',
        },
        {
          courseCode: 'SFS22',
          gradReq: 'sciOther',
          creditValue: 1,
          subjectArea: 'sciForensics',
        },
      ],
      sciPhysics: [
        {
          courseCode: 'SPS21',
          gradReq: 'sciPhysical',
          creditValue: 1,
          subjectArea: 'sciPhysics',
        },
        {
          courseCode: 'SPS22',
          gradReq: 'sciPhysical',
          creditValue: 1,
          subjectArea: 'sciPhysics',
        },
        {
          courseCode: 'SPS31',
          gradReq: 'sciPhysical',
          creditValue: 1,
          subjectArea: 'sciPhysics',
        },
        {
          courseCode: 'SPS41',
          gradReq: 'sciPhysical',
          creditValue: 1,
          subjectArea: 'sciPhysics',
        },
        {
          courseCode: 'SPS42',
          gradReq: 'sciPhysical',
          creditValue: 1,
          subjectArea: 'sciPhysics',
        },
      ],
      ssEcon: [
        {
          courseCode: 'HES11',
          gradReq: 'ssEcon',
          creditValue: 1,
          subjectArea: 'ssEcon',
        },
        {
          courseCode: 'HES21',
          gradReq: 'ssEcon',
          creditValue: 1,
          subjectArea: 'ssEcon',
        },
        {
          courseCode: 'HES32',
          gradReq: 'ssEcon',
          creditValue: 0.5,
          subjectArea: 'ssEcon',
        },
        {
          courseCode: 'HES33',
          gradReq: 'ssEcon',
          creditValue: 0.5,
          subjectArea: 'ssEcon',
        },
      ],
      ssGlobal: [
        {
          courseCode: 'HGS11',
          gradReq: 'ssGlobal',
          creditValue: 1,
          subjectArea: 'ssGlobal',
        },
        {
          courseCode: 'HGS21',
          gradReq: 'ssGlobal',
          creditValue: 2,
          subjectArea: 'ssGlobal',
        },
        {
          courseCode: 'HGS22',
          gradReq: 'ssGlobal',
          creditValue: 2,
          subjectArea: 'ssGlobal',
        },
        {
          courseCode: 'HGS41',
          gradReq: 'ssGlobal',
          creditValue: 1,
          subjectArea: 'ssGlobal',
        },
        {
          courseCode: 'HGS42',
          gradReq: 'ssGlobal',
          creditValue: 1,
          subjectArea: 'ssGlobal',
        },
        {
          courseCode: 'HGS43',
          gradReq: 'ssGlobal',
          creditValue: 1,
          subjectArea: 'ssGlobal',
        },
        {
          courseCode: 'HGS44',
          gradReq: 'ssGlobal',
          creditValue: 1,
          subjectArea: 'ssGlobal',
        },
        {
          courseCode: 'HGS61',
          gradReq: 'ssGlobal',
          creditValue: 1,
          subjectArea: 'ssGlobal',
        },
        {
          courseCode: 'HGS62',
          gradReq: 'ssGlobal',
          creditValue: 1,
          subjectArea: 'ssGlobal',
        },
        {
          courseCode: 'HGSE3',
          gradReq: 'ssGlobal',
          creditValue: 1,
          subjectArea: 'ssGlobal',
        },
        {
          courseCode: 'HGSE4',
          gradReq: 'ssGlobal',
          creditValue: 1,
          subjectArea: 'ssGlobal',
        },
      ],
      ssGovt: [
        {
          courseCode: 'HVS11',
          gradReq: 'ssGovt',
          creditValue: 1,
          subjectArea: 'ssGovt',
        },
        {
          courseCode: 'HVS21',
          gradReq: 'ssGovt',
          creditValue: 1,
          subjectArea: 'ssGovt',
        },
        {
          courseCode: 'HVS22',
          gradReq: 'ssGovt',
          creditValue: 1,
          subjectArea: 'ssGovt',
        },
        {
          courseCode: 'HVS31',
          gradReq: 'ssGovt',
          creditValue: 1,
          subjectArea: 'ssGovt',
        },
      ],
      ssGovtEcon: [
        {
          courseCode: 'HFS21',
          gradReq: 'ssGovtEcon',
          creditValue: 1,
          subjectArea: 'ssGovtEcon',
        },
        {
          courseCode: 'HFS22',
          gradReq: 'ssGovtEcon',
          creditValue: 1,
          subjectArea: 'ssGovtEcon',
        },
      ],
      ssUs: [
        {
          courseCode: 'HUS11',
          gradReq: 'ssUs',
          creditValue: 1,
          subjectArea: 'ssUs',
        },
        {
          courseCode: 'HUS21',
          gradReq: 'ssUs',
          creditValue: 1,
          subjectArea: 'ssUs',
        },
        {
          courseCode: 'HUS22',
          gradReq: 'ssUs',
          creditValue: 1,
          subjectArea: 'ssUs',
        },
        {
          courseCode: 'HUS41',
          gradReq: 'ssUs',
          creditValue: 1,
          subjectArea: 'ssUs',
        },
        {
          courseCode: 'HUS42',
          gradReq: 'ssUs',
          creditValue: 1,
          subjectArea: 'ssUs',
        },
        {
          courseCode: 'HUS43',
          gradReq: 'ssUs',
          creditValue: 1,
          subjectArea: 'ssUs',
        },
        {
          courseCode: 'HUS61',
          gradReq: 'ssUs',
          creditValue: 1,
          subjectArea: 'ssUs',
        },
      ],
    },
    TRIMESTER: {
      art: [
        {
          courseCode: 'ADT11',
          gradReq: 'art',
          creditValue: 1,
          subjectArea: 'art',
        },
        {
          courseCode: 'ANT11',
          gradReq: 'art',
          creditValue: 1,
          subjectArea: 'art',
        },
        {
          courseCode: 'ANT21',
          gradReq: 'art',
          creditValue: 1,
          subjectArea: 'art',
        },
        {
          courseCode: 'ANT22',
          gradReq: 'art',
          creditValue: 1,
          subjectArea: 'art',
        },
        {
          courseCode: 'APT11',
          gradReq: 'art',
          creditValue: 1,
          subjectArea: 'art',
        },
        {
          courseCode: 'AWT11',
          gradReq: 'art',
          creditValue: 1,
          subjectArea: 'art',
        },
      ],
      misc: [
        {
          courseCode: 'RQT21',
          gradReq: 'cte',
          creditValue: 0.34,
          subjectArea: 'misc',
        },
        {
          courseCode: 'RZT61',
          gradReq: 'cte',
          creditValue: 0.1,
          subjectArea: 'misc',
        },
        {
          courseCode: 'RZT62',
          gradReq: 'cte',
          creditValue: 0.1,
          subjectArea: 'misc',
        },
      ],
      elaCore: [
        {
          courseCode: 'EET31',
          gradReq: 'elaCore',
          creditValue: 1,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EET32',
          gradReq: 'elaCore',
          creditValue: 1,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EET33',
          gradReq: 'elaCore',
          creditValue: 1,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EET81',
          gradReq: 'elaCore',
          creditValue: 1,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EET82',
          gradReq: 'elaCore',
          creditValue: 1,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EET83',
          gradReq: 'elaCore',
          creditValue: 1,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EET84',
          gradReq: 'elaCore',
          creditValue: 1,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EET85',
          gradReq: 'elaCore',
          creditValue: 1,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EET86',
          gradReq: 'elaCore',
          creditValue: 1,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EET87',
          gradReq: 'elaCore',
          creditValue: 1,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EET88',
          gradReq: 'elaCore',
          creditValue: 1,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EETAA',
          gradReq: 'elaCore',
          creditValue: 1,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EETBB',
          gradReq: 'elaCore',
          creditValue: 1,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EETC1',
          gradReq: 'elaCore',
          creditValue: 1,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EETC2',
          gradReq: 'elaCore',
          creditValue: 1,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EETC3',
          gradReq: 'elaCore',
          creditValue: 1,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EETC4',
          gradReq: 'elaCore',
          creditValue: 1,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EETC5',
          gradReq: 'elaCore',
          creditValue: 1,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EETC6',
          gradReq: 'elaCore',
          creditValue: 1,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EETC7',
          gradReq: 'elaCore',
          creditValue: 1,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EETC8',
          gradReq: 'elaCore',
          creditValue: 1,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EETC9',
          gradReq: 'elaCore',
          creditValue: 1,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EETCA',
          gradReq: 'elaCore',
          creditValue: 1,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EETCB',
          gradReq: 'elaCore',
          creditValue: 1,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EETCC',
          gradReq: 'elaCore',
          creditValue: 1,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EETGG',
          gradReq: 'elaCore',
          creditValue: 1,
          subjectArea: 'elaCore',
        },
      ],
      health: [
        {
          courseCode: 'PHT11',
          gradReq: 'health',
          creditValue: 1,
          subjectArea: 'health',
        },
        {
          courseCode: 'PHT21',
          gradReq: 'health',
          creditValue: 0.5,
          subjectArea: 'health',
        },
        {
          courseCode: 'PHT22',
          gradReq: 'health',
          creditValue: 0.5,
          subjectArea: 'health',
        },
      ],
      lote: [
        {
          courseCode: 'FST21',
          gradReq: 'lote',
          creditValue: 1,
          subjectArea: 'lote',
        },
        {
          courseCode: 'FST22',
          gradReq: 'lote',
          creditValue: 1,
          subjectArea: 'lote',
        },
        {
          courseCode: 'FST31',
          gradReq: 'lote',
          creditValue: 1,
          subjectArea: 'lote',
        },
        {
          courseCode: 'FST61',
          gradReq: 'lote',
          creditValue: 1,
          subjectArea: 'lote',
        },
        {
          courseCode: 'FST62',
          gradReq: 'lote',
          creditValue: 1,
          subjectArea: 'lote',
        },
        {
          courseCode: 'FST63',
          gradReq: 'lote',
          creditValue: 1,
          subjectArea: 'lote',
        },
        {
          courseCode: 'FST64',
          gradReq: 'lote',
          creditValue: 1,
          subjectArea: 'lote',
        },
        {
          courseCode: 'FST65',
          gradReq: 'lote',
          creditValue: 1,
          subjectArea: 'lote',
        },
        {
          courseCode: 'FST66',
          gradReq: 'lote',
          creditValue: 1,
          subjectArea: 'lote',
        },
        {
          courseCode: 'FYT21',
          gradReq: 'lote',
          creditValue: 1,
          subjectArea: 'lote',
        },
      ],
      mathAlg: [
        {
          courseCode: 'MET11',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathAlg',
        },
        {
          courseCode: 'MET21',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathAlg',
        },
        {
          courseCode: 'MET22',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathAlg',
        },
        {
          courseCode: 'MET31',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathAlg',
        },
        {
          courseCode: 'MET32',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathAlg',
        },
        {
          courseCode: 'MET33',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathAlg',
        },
        {
          courseCode: 'MET41',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathAlg',
        },
        {
          courseCode: 'MET42',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathAlg',
        },
        {
          courseCode: 'MET43',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathAlg',
        },
        {
          courseCode: 'MET44',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathAlg',
        },
      ],
      mathGeom: [
        {
          courseCode: 'MGT21',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathGeom',
        },
        {
          courseCode: 'MGT22',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathGeom',
        },
        {
          courseCode: 'MGT31',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathGeom',
        },
        {
          courseCode: 'MGT32',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathGeom',
        },
        {
          courseCode: 'MGT33',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathGeom',
        },
      ],
      mathOther: [
        {
          courseCode: 'MQT11',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathOther',
        },
        {
          courseCode: 'MQT31',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathOther',
        },
        {
          courseCode: 'MQT32',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathOther',
        },
        {
          courseCode: 'MQT33',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathOther',
        },
        {
          courseCode: 'MQT44',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathOther',
        },
      ],
      mathTrig: [
        {
          courseCode: 'MRT31',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathTrig',
        },
        {
          courseCode: 'MRT32',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathTrig',
        },
        {
          courseCode: 'MRT33',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathTrig',
        },
      ],
      mathStats: [
        {
          courseCode: 'MST11',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathStats',
        },
        {
          courseCode: 'MST31',
          gradReq: 'math',
          creditValue: 1,
          subjectArea: 'mathStats',
        },
      ],
      elaElect: [
        {
          courseCode: 'EWT32',
          gradReq: 'misc',
          creditValue: 1,
          subjectArea: 'elaElect',
        },
      ],
      advisory: [
        {
          courseCode: 'GAT11',
          gradReq: 'misc',
          creditValue: 0.5,
          subjectArea: 'advisory',
        },
        {
          courseCode: 'GAT22',
          gradReq: 'misc',
          creditValue: 0.34,
          subjectArea: 'advisory',
        },
        {
          courseCode: 'GAT31',
          gradReq: 'misc',
          creditValue: 0.5,
          subjectArea: 'advisory',
        },
        {
          courseCode: 'GAT32',
          gradReq: 'misc',
          creditValue: 0.5,
          subjectArea: 'advisory',
        },
        {
          courseCode: 'GAT33',
          gradReq: 'misc',
          creditValue: 0.5,
          subjectArea: 'advisory',
        },
        {
          courseCode: 'GAT61',
          gradReq: 'misc',
          creditValue: 0.34,
          subjectArea: 'advisory',
        },
        {
          courseCode: 'GAT62',
          gradReq: 'misc',
          creditValue: 0.34,
          subjectArea: 'advisory',
        },
        {
          courseCode: 'GAT63',
          gradReq: 'misc',
          creditValue: 0.34,
          subjectArea: 'advisory',
        },
        {
          courseCode: 'GAT88',
          gradReq: 'misc',
          creditValue: 1,
          subjectArea: 'advisory',
        },
        {
          courseCode: 'GAT99',
          gradReq: 'misc',
          creditValue: 1,
          subjectArea: 'advisory',
        },
      ],
      guidance: [
        {
          courseCode: 'GGT11',
          gradReq: 'misc',
          creditValue: 1,
          subjectArea: 'guidance',
        },
        {
          courseCode: 'GLT11',
          gradReq: 'misc',
          creditValue: 1,
          subjectArea: 'guidance',
        },
        {
          courseCode: 'GMT11',
          gradReq: 'misc',
          creditValue: 1,
          subjectArea: 'guidance',
        },
      ],
      ssElective: [
        {
          courseCode: 'HQT11',
          gradReq: 'misc',
          creditValue: 1,
          subjectArea: 'ssElective',
        },
      ],
      pe: [
        {
          courseCode: 'PFT11',
          gradReq: 'pe',
          creditValue: 0.58,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PPT11',
          gradReq: 'pe',
          creditValue: 0.5,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PPT22',
          gradReq: 'pe',
          creditValue: 0.34,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PPT31',
          gradReq: 'pe',
          creditValue: 0.58,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PPT32',
          gradReq: 'pe',
          creditValue: 0.59,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PPT33',
          gradReq: 'pe',
          creditValue: 0.34,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PPT41',
          gradReq: 'pe',
          creditValue: 0.5,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PPT71',
          gradReq: 'pe',
          creditValue: 0.58,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PPT74',
          gradReq: 'pe',
          creditValue: 0.5,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PPTC1',
          gradReq: 'pe',
          creditValue: 0.34,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PPTC2',
          gradReq: 'pe',
          creditValue: 0.34,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PPTC3',
          gradReq: 'pe',
          creditValue: 0.34,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PPTC4',
          gradReq: 'pe',
          creditValue: 0.34,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PPTC5',
          gradReq: 'pe',
          creditValue: 0.34,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PPTC6',
          gradReq: 'pe',
          creditValue: 0.34,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PPTC7',
          gradReq: 'pe',
          creditValue: 0.34,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PPTC8',
          gradReq: 'pe',
          creditValue: 0.34,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PPTC9',
          gradReq: 'pe',
          creditValue: 0.34,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PPTCA',
          gradReq: 'pe',
          creditValue: 0.34,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PPTCB',
          gradReq: 'pe',
          creditValue: 0.34,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PPTCC',
          gradReq: 'pe',
          creditValue: 0.34,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PTT11',
          gradReq: 'pe',
          creditValue: 0.5,
          subjectArea: 'pe',
        },
      ],
      sciLiving: [
        {
          courseCode: 'SLT11',
          gradReq: 'sciLife',
          creditValue: 0.5,
          subjectArea: 'sciLiving',
        },
        {
          courseCode: 'SLT21',
          gradReq: 'sciLife',
          creditValue: 1,
          subjectArea: 'sciLiving',
        },
        {
          courseCode: 'SLT22',
          gradReq: 'sciLife',
          creditValue: 1,
          subjectArea: 'sciLiving',
        },
        {
          courseCode: 'SLT31',
          gradReq: 'sciLife',
          creditValue: 1,
          subjectArea: 'sciLiving',
        },
        {
          courseCode: 'SLT32',
          gradReq: 'sciLife',
          creditValue: 1,
          subjectArea: 'sciLiving',
        },
        {
          courseCode: 'SLT33',
          gradReq: 'sciLife',
          creditValue: 1,
          subjectArea: 'sciLiving',
        },
        {
          courseCode: 'SLT43',
          gradReq: 'sciLife',
          creditValue: 1,
          subjectArea: 'sciLiving',
        },
      ],
      sciLife: [
        {
          courseCode: 'SWT11',
          gradReq: 'sciLife',
          creditValue: 1,
          subjectArea: 'sciLife',
        },
        {
          courseCode: 'SWT31',
          gradReq: 'sciLife',
          creditValue: 1,
          subjectArea: 'sciLife',
        },
      ],
      sciChem: [
        {
          courseCode: 'SCT31',
          gradReq: 'sciPhysical',
          creditValue: 1,
          subjectArea: 'sciChem',
        },
        {
          courseCode: 'SCT32',
          gradReq: 'sciPhysical',
          creditValue: 1,
          subjectArea: 'sciChem',
        },
        {
          courseCode: 'SCT33',
          gradReq: 'sciPhysical',
          creditValue: 1,
          subjectArea: 'sciChem',
        },
      ],
      sciPhysical: [
        {
          courseCode: 'SDT11',
          gradReq: 'sciPhysical',
          creditValue: 1,
          subjectArea: 'sciPhysical',
        },
        {
          courseCode: 'SDT21',
          gradReq: 'sciPhysical',
          creditValue: 1,
          subjectArea: 'sciPhysical',
        },
        {
          courseCode: 'SDT22',
          gradReq: 'sciPhysical',
          creditValue: 1,
          subjectArea: 'sciPhysical',
        },
      ],
      sciEarth: [
        {
          courseCode: 'SET21',
          gradReq: 'sciPhysical',
          creditValue: 1,
          subjectArea: 'sciEarth',
        },
        {
          courseCode: 'SET22',
          gradReq: 'sciPhysical',
          creditValue: 1,
          subjectArea: 'sciEarth',
        },
        {
          courseCode: 'SET31',
          gradReq: 'sciPhysical',
          creditValue: 1,
          subjectArea: 'sciEarth',
        },
        {
          courseCode: 'SET32',
          gradReq: 'sciPhysical',
          creditValue: 1,
          subjectArea: 'sciEarth',
        },
        {
          courseCode: 'SET33',
          gradReq: 'sciPhysical',
          creditValue: 1,
          subjectArea: 'sciEarth',
        },
      ],
      sciForensics: [
        {
          courseCode: 'SFT21',
          gradReq: 'sciOther',
          creditValue: 1,
          subjectArea: 'sciForensics',
        },
        {
          courseCode: 'SFT22',
          gradReq: 'sciOther',
          creditValue: 1,
          subjectArea: 'sciForensics',
        },
      ],
      ssEcon: [
        {
          courseCode: 'HET11',
          gradReq: 'ssEcon',
          creditValue: 1,
          subjectArea: 'ssEcon',
        },
      ],
      ssGlobal: [
        {
          courseCode: 'HGT11',
          gradReq: 'ssGlobal',
          creditValue: 1,
          subjectArea: 'ssGlobal',
        },
        {
          courseCode: 'HGT22',
          gradReq: 'ssGlobal',
          creditValue: 1,
          subjectArea: 'ssGlobal',
        },
        {
          courseCode: 'HGT31',
          gradReq: 'ssGlobal',
          creditValue: 1,
          subjectArea: 'ssGlobal',
        },
        {
          courseCode: 'HGT32',
          gradReq: 'ssGlobal',
          creditValue: 1,
          subjectArea: 'ssGlobal',
        },
        {
          courseCode: 'HGT33',
          gradReq: 'ssGlobal',
          creditValue: 1,
          subjectArea: 'ssGlobal',
        },
        {
          courseCode: 'HGT41',
          gradReq: 'ssGlobal',
          creditValue: 1,
          subjectArea: 'ssGlobal',
        },
        {
          courseCode: 'HGT42',
          gradReq: 'ssGlobal',
          creditValue: 1,
          subjectArea: 'ssGlobal',
        },
        {
          courseCode: 'HGT43',
          gradReq: 'ssGlobal',
          creditValue: 1,
          subjectArea: 'ssGlobal',
        },
        {
          courseCode: 'HGT44',
          gradReq: 'ssGlobal',
          creditValue: 1,
          subjectArea: 'ssGlobal',
        },
      ],
      ssGovt: [
        {
          courseCode: 'HVT11',
          gradReq: 'ssGovt',
          creditValue: 1,
          subjectArea: 'ssGovt',
        },
      ],
      ssUs: [
        {
          courseCode: 'HUT21',
          gradReq: 'ssUs',
          creditValue: 1,
          subjectArea: 'ssUs',
        },
        {
          courseCode: 'HUT22',
          gradReq: 'ssUs',
          creditValue: 1,
          subjectArea: 'ssUs',
        },
        {
          courseCode: 'HUT31',
          gradReq: 'ssUs',
          creditValue: 1,
          subjectArea: 'ssUs',
        },
        {
          courseCode: 'HUT32',
          gradReq: 'ssUs',
          creditValue: 1,
          subjectArea: 'ssUs',
        },
        {
          courseCode: 'HUT33',
          gradReq: 'ssUs',
          creditValue: 1,
          subjectArea: 'ssUs',
        },
      ],
    },
    QUARTERLY: {
      art: [
        {
          courseCode: 'UAF41',
          gradReq: 'art',
          creditValue: 0.25,
          subjectArea: 'art',
        },
        {
          courseCode: 'UAF42',
          gradReq: 'art',
          creditValue: 0.25,
          subjectArea: 'art',
        },
        {
          courseCode: 'UAF43',
          gradReq: 'art',
          creditValue: 0.25,
          subjectArea: 'art',
        },
        {
          courseCode: 'UAF44',
          gradReq: 'art',
          creditValue: 0.25,
          subjectArea: 'art',
        },
      ],
      elaCore: [
        {
          courseCode: 'EEF21',
          gradReq: 'elaCore',
          creditValue: 0.5,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EEF22',
          gradReq: 'elaCore',
          creditValue: 0.5,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EEF41',
          gradReq: 'elaCore',
          creditValue: 0.5,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EEF42',
          gradReq: 'elaCore',
          creditValue: 0.5,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EEF43',
          gradReq: 'elaCore',
          creditValue: 0.5,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EEF44',
          gradReq: 'elaCore',
          creditValue: 0.5,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EEFG1',
          gradReq: 'elaCore',
          creditValue: 0.5,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EEFG2',
          gradReq: 'elaCore',
          creditValue: 0.5,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EEFG3',
          gradReq: 'elaCore',
          creditValue: 0.5,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EEFG4',
          gradReq: 'elaCore',
          creditValue: 0.5,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EEFG5',
          gradReq: 'elaCore',
          creditValue: 0.5,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EEFG6',
          gradReq: 'elaCore',
          creditValue: 0.5,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EEFG7',
          gradReq: 'elaCore',
          creditValue: 0.5,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EEFG8',
          gradReq: 'elaCore',
          creditValue: 0.5,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EEFG9',
          gradReq: 'elaCore',
          creditValue: 0.5,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EEFGA',
          gradReq: 'elaCore',
          creditValue: 0.5,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EEFGB',
          gradReq: 'elaCore',
          creditValue: 0.5,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EEFGC',
          gradReq: 'elaCore',
          creditValue: 0.5,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EEFGD',
          gradReq: 'elaCore',
          creditValue: 0.5,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EEFGE',
          gradReq: 'elaCore',
          creditValue: 0.5,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EEFGF',
          gradReq: 'elaCore',
          creditValue: 0.5,
          subjectArea: 'elaCore',
        },
        {
          courseCode: 'EEFGG',
          gradReq: 'elaCore',
          creditValue: 0.5,
          subjectArea: 'elaCore',
        },
      ],
      health: [
        {
          courseCode: 'PHF41',
          gradReq: 'health',
          creditValue: 0.25,
          subjectArea: 'health',
        },
        {
          courseCode: 'PHF42',
          gradReq: 'health',
          creditValue: 0.25,
          subjectArea: 'health',
        },
        {
          courseCode: 'PHF43',
          gradReq: 'health',
          creditValue: 0.25,
          subjectArea: 'health',
        },
        {
          courseCode: 'PHF44',
          gradReq: 'health',
          creditValue: 0.25,
          subjectArea: 'health',
        },
      ],
      lote: [
        {
          courseCode: 'FMFC9',
          gradReq: 'lote',
          creditValue: 0.5,
          subjectArea: 'lote',
        },
        {
          courseCode: 'FMFCA',
          gradReq: 'lote',
          creditValue: 0.5,
          subjectArea: 'lote',
        },
        {
          courseCode: 'FMFCB',
          gradReq: 'lote',
          creditValue: 0.5,
          subjectArea: 'lote',
        },
        {
          courseCode: 'FMFCC',
          gradReq: 'lote',
          creditValue: 0.5,
          subjectArea: 'lote',
        },
        {
          courseCode: 'FSFC1',
          gradReq: 'lote',
          creditValue: 0.5,
          subjectArea: 'lote',
        },
        {
          courseCode: 'FSFC2',
          gradReq: 'lote',
          creditValue: 0.5,
          subjectArea: 'lote',
        },
        {
          courseCode: 'FSFC3',
          gradReq: 'lote',
          creditValue: 0.5,
          subjectArea: 'lote',
        },
        {
          courseCode: 'FSFC4',
          gradReq: 'lote',
          creditValue: 0.5,
          subjectArea: 'lote',
        },
        {
          courseCode: 'FSFC5',
          gradReq: 'lote',
          creditValue: 0.5,
          subjectArea: 'lote',
        },
        {
          courseCode: 'FSFC6',
          gradReq: 'lote',
          creditValue: 0.5,
          subjectArea: 'lote',
        },
        {
          courseCode: 'FSFC7',
          gradReq: 'lote',
          creditValue: 0.5,
          subjectArea: 'lote',
        },
        {
          courseCode: 'FSFC8',
          gradReq: 'lote',
          creditValue: 0.5,
          subjectArea: 'lote',
        },
        {
          courseCode: 'FSFC9',
          gradReq: 'lote',
          creditValue: 0.5,
          subjectArea: 'lote',
        },
        {
          courseCode: 'FSFCA',
          gradReq: 'lote',
          creditValue: 0.5,
          subjectArea: 'lote',
        },
        {
          courseCode: 'FSFCB',
          gradReq: 'lote',
          creditValue: 0.5,
          subjectArea: 'lote',
        },
        {
          courseCode: 'FSFCC',
          gradReq: 'lote',
          creditValue: 0.5,
          subjectArea: 'lote',
        },
      ],
      mathAlg: [
        {
          courseCode: 'MEF41',
          gradReq: 'math',
          creditValue: 0.5,
          subjectArea: 'mathAlg',
        },
        {
          courseCode: 'MEF42',
          gradReq: 'math',
          creditValue: 0.5,
          subjectArea: 'mathAlg',
        },
        {
          courseCode: 'MEF43',
          gradReq: 'math',
          creditValue: 0.5,
          subjectArea: 'mathAlg',
        },
        {
          courseCode: 'MEF44',
          gradReq: 'math',
          creditValue: 0.5,
          subjectArea: 'mathAlg',
        },
        {
          courseCode: 'MEF81',
          gradReq: 'math',
          creditValue: 0.5,
          subjectArea: 'mathAlg',
        },
        {
          courseCode: 'MEF82',
          gradReq: 'math',
          creditValue: 0.5,
          subjectArea: 'mathAlg',
        },
        {
          courseCode: 'MEF83',
          gradReq: 'math',
          creditValue: 0.5,
          subjectArea: 'mathAlg',
        },
        {
          courseCode: 'MEF84',
          gradReq: 'math',
          creditValue: 0.5,
          subjectArea: 'mathAlg',
        },
        {
          courseCode: 'MEF85',
          gradReq: 'math',
          creditValue: 0.25,
          subjectArea: 'mathAlg',
        },
        {
          courseCode: 'MEF86',
          gradReq: 'math',
          creditValue: 0.25,
          subjectArea: 'mathAlg',
        },
        {
          courseCode: 'MEF87',
          gradReq: 'math',
          creditValue: 0.25,
          subjectArea: 'mathAlg',
        },
        {
          courseCode: 'MEF88',
          gradReq: 'math',
          creditValue: 0.25,
          subjectArea: 'mathAlg',
        },
      ],
      mathGeom: [
        {
          courseCode: 'MGF41',
          gradReq: 'math',
          creditValue: 0.5,
          subjectArea: 'mathGeom',
        },
        {
          courseCode: 'MGF42',
          gradReq: 'math',
          creditValue: 0.5,
          subjectArea: 'mathGeom',
        },
        {
          courseCode: 'MGF43',
          gradReq: 'math',
          creditValue: 0.5,
          subjectArea: 'mathGeom',
        },
        {
          courseCode: 'MGF44',
          gradReq: 'math',
          creditValue: 0.5,
          subjectArea: 'mathGeom',
        },
        {
          courseCode: 'MGF85',
          gradReq: 'math',
          creditValue: 0.25,
          subjectArea: 'mathGeom',
        },
        {
          courseCode: 'MGF86',
          gradReq: 'math',
          creditValue: 0.25,
          subjectArea: 'mathGeom',
        },
        {
          courseCode: 'MGF87',
          gradReq: 'math',
          creditValue: 0.25,
          subjectArea: 'mathGeom',
        },
        {
          courseCode: 'MGF88',
          gradReq: 'math',
          creditValue: 0.25,
          subjectArea: 'mathGeom',
        },
      ],
      mathTrig: [
        {
          courseCode: 'MRF41',
          gradReq: 'math',
          creditValue: 0.5,
          subjectArea: 'mathTrig',
        },
        {
          courseCode: 'MRF42',
          gradReq: 'math',
          creditValue: 0.5,
          subjectArea: 'mathTrig',
        },
        {
          courseCode: 'MRF43',
          gradReq: 'math',
          creditValue: 0.5,
          subjectArea: 'mathTrig',
        },
        {
          courseCode: 'MRF44',
          gradReq: 'math',
          creditValue: 0.5,
          subjectArea: 'mathTrig',
        },
      ],
      pe: [
        {
          courseCode: 'PGF41',
          gradReq: 'pe',
          creditValue: 0.25,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PGF42',
          gradReq: 'pe',
          creditValue: 0.25,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PGF43',
          gradReq: 'pe',
          creditValue: 0.25,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PGF44',
          gradReq: 'pe',
          creditValue: 0.25,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PPF41',
          gradReq: 'pe',
          creditValue: 0.25,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PPF42',
          gradReq: 'pe',
          creditValue: 0.25,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PPF43',
          gradReq: 'pe',
          creditValue: 0.25,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PPF44',
          gradReq: 'pe',
          creditValue: 0.25,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PTF21',
          gradReq: 'pe',
          creditValue: 0.29,
          subjectArea: 'pe',
        },
        {
          courseCode: 'PTF22',
          gradReq: 'pe',
          creditValue: 0.29,
          subjectArea: 'pe',
        },
      ],
      sciLiving: [
        {
          courseCode: 'SLF41',
          gradReq: 'sciLife',
          creditValue: 0.5,
          subjectArea: 'sciLiving',
        },
        {
          courseCode: 'SLF42',
          gradReq: 'sciLife',
          creditValue: 0.5,
          subjectArea: 'sciLiving',
        },
        {
          courseCode: 'SLF43',
          gradReq: 'sciLife',
          creditValue: 0.5,
          subjectArea: 'sciLiving',
        },
        {
          courseCode: 'SLF44',
          gradReq: 'sciLife',
          creditValue: 0.5,
          subjectArea: 'sciLiving',
        },
        {
          courseCode: 'SLF81',
          gradReq: 'sciLife',
          creditValue: 0.5,
          subjectArea: 'sciLiving',
        },
        {
          courseCode: 'SLF82',
          gradReq: 'sciLife',
          creditValue: 0.5,
          subjectArea: 'sciLiving',
        },
        {
          courseCode: 'SLF83',
          gradReq: 'sciLife',
          creditValue: 0.5,
          subjectArea: 'sciLiving',
        },
        {
          courseCode: 'SLF84',
          gradReq: 'sciLife',
          creditValue: 0.5,
          subjectArea: 'sciLiving',
        },
        {
          courseCode: 'SLF85',
          gradReq: 'sciLife',
          creditValue: 0.25,
          subjectArea: 'sciLiving',
        },
        {
          courseCode: 'SLF86',
          gradReq: 'sciLife',
          creditValue: 0.25,
          subjectArea: 'sciLiving',
        },
        {
          courseCode: 'SLF87',
          gradReq: 'sciLife',
          creditValue: 0.25,
          subjectArea: 'sciLiving',
        },
      ],
      sciLife: [
        {
          courseCode: 'SWF41',
          gradReq: 'sciLife',
          creditValue: 0.5,
          subjectArea: 'sciLife',
        },
        {
          courseCode: 'SWF42',
          gradReq: 'sciLife',
          creditValue: 0.5,
          subjectArea: 'sciLife',
        },
        {
          courseCode: 'SWF43',
          gradReq: 'sciLife',
          creditValue: 0.5,
          subjectArea: 'sciLife',
        },
        {
          courseCode: 'SWF44',
          gradReq: 'sciLife',
          creditValue: 0.5,
          subjectArea: 'sciLife',
        },
      ],
      sciChem: [
        {
          courseCode: 'SCF11',
          gradReq: 'sciPhysical',
          creditValue: 1,
          subjectArea: 'sciChem',
        },
        {
          courseCode: 'SCF41',
          gradReq: 'sciPhysical',
          creditValue: 0.5,
          subjectArea: 'sciChem',
        },
        {
          courseCode: 'SCF42',
          gradReq: 'sciPhysical',
          creditValue: 0.5,
          subjectArea: 'sciChem',
        },
        {
          courseCode: 'SCF43',
          gradReq: 'sciPhysical',
          creditValue: 0.5,
          subjectArea: 'sciChem',
        },
        {
          courseCode: 'SCF44',
          gradReq: 'sciPhysical',
          creditValue: 0.5,
          subjectArea: 'sciChem',
        },
        {
          courseCode: 'SCF85',
          gradReq: 'sciPhysical',
          creditValue: 0.25,
          subjectArea: 'sciChem',
        },
        {
          courseCode: 'SCF86',
          gradReq: 'sciPhysical',
          creditValue: 0.25,
          subjectArea: 'sciChem',
        },
        {
          courseCode: 'SCF87',
          gradReq: 'sciPhysical',
          creditValue: 0.25,
          subjectArea: 'sciChem',
        },
        {
          courseCode: 'SCF88',
          gradReq: 'sciPhysical',
          creditValue: 0.25,
          subjectArea: 'sciChem',
        },
      ],
      sciEarth: [
        {
          courseCode: 'SEF21',
          gradReq: 'sciPhysical',
          creditValue: 1,
          subjectArea: 'sciEarth',
        },
        {
          courseCode: 'SEF41',
          gradReq: 'sciPhysical',
          creditValue: 0.5,
          subjectArea: 'sciEarth',
        },
        {
          courseCode: 'SEF42',
          gradReq: 'sciPhysical',
          creditValue: 0.5,
          subjectArea: 'sciEarth',
        },
        {
          courseCode: 'SEF43',
          gradReq: 'sciPhysical',
          creditValue: 0.5,
          subjectArea: 'sciEarth',
        },
        {
          courseCode: 'SEF44',
          gradReq: 'sciPhysical',
          creditValue: 0.5,
          subjectArea: 'sciEarth',
        },
        {
          courseCode: 'SEF85',
          gradReq: 'sciPhysical',
          creditValue: 0.25,
          subjectArea: 'sciEarth',
        },
        {
          courseCode: 'SEF86',
          gradReq: 'sciPhysical',
          creditValue: 0.25,
          subjectArea: 'sciEarth',
        },
        {
          courseCode: 'SEF87',
          gradReq: 'sciPhysical',
          creditValue: 0.25,
          subjectArea: 'sciEarth',
        },
        {
          courseCode: 'SEF88',
          gradReq: 'sciPhysical',
          creditValue: 0.25,
          subjectArea: 'sciEarth',
        },
      ],
      sciPhysics: [
        {
          courseCode: 'SPF41',
          gradReq: 'sciPhysical',
          creditValue: 0.5,
          subjectArea: 'sciPhysics',
        },
        {
          courseCode: 'SPF42',
          gradReq: 'sciPhysical',
          creditValue: 0.5,
          subjectArea: 'sciPhysics',
        },
        {
          courseCode: 'SPF43',
          gradReq: 'sciPhysical',
          creditValue: 0.5,
          subjectArea: 'sciPhysics',
        },
        {
          courseCode: 'SPF44',
          gradReq: 'sciPhysical',
          creditValue: 0.5,
          subjectArea: 'sciPhysics',
        },
      ],
      ssEcon: [
        {
          courseCode: 'HEF11',
          gradReq: 'ssEcon',
          creditValue: 1,
          subjectArea: 'ssEcon',
        },
      ],
      ssGlobal: [
        {
          courseCode: 'HGF81',
          gradReq: 'ssGlobal',
          creditValue: 0.5,
          subjectArea: 'ssGlobal',
        },
        {
          courseCode: 'HGF82',
          gradReq: 'ssGlobal',
          creditValue: 0.5,
          subjectArea: 'ssGlobal',
        },
        {
          courseCode: 'HGF83',
          gradReq: 'ssGlobal',
          creditValue: 0.5,
          subjectArea: 'ssGlobal',
        },
        {
          courseCode: 'HGF84',
          gradReq: 'ssGlobal',
          creditValue: 0.5,
          subjectArea: 'ssGlobal',
        },
        {
          courseCode: 'HGF85',
          gradReq: 'ssGlobal',
          creditValue: 0.5,
          subjectArea: 'ssGlobal',
        },
        {
          courseCode: 'HGF86',
          gradReq: 'ssGlobal',
          creditValue: 0.5,
          subjectArea: 'ssGlobal',
        },
        {
          courseCode: 'HGF87',
          gradReq: 'ssGlobal',
          creditValue: 0.5,
          subjectArea: 'ssGlobal',
        },
        {
          courseCode: 'HGF88',
          gradReq: 'ssGlobal',
          creditValue: 0.5,
          subjectArea: 'ssGlobal',
        },
        {
          courseCode: 'HGFC9',
          gradReq: 'ssGlobal',
          creditValue: 0.25,
          subjectArea: 'ssGlobal',
        },
        {
          courseCode: 'HGFCA',
          gradReq: 'ssGlobal',
          creditValue: 0.25,
          subjectArea: 'ssGlobal',
        },
        {
          courseCode: 'HGFCB',
          gradReq: 'ssGlobal',
          creditValue: 0.25,
          subjectArea: 'ssGlobal',
        },
        {
          courseCode: 'HGFCC',
          gradReq: 'ssGlobal',
          creditValue: 0.25,
          subjectArea: 'ssGlobal',
        },
      ],
      ssGovtEcon: [
        {
          courseCode: 'HFF41',
          gradReq: 'ssGovtEcon',
          creditValue: 0.5,
          subjectArea: 'ssGovtEcon',
        },
        {
          courseCode: 'HFF42',
          gradReq: 'ssGovtEcon',
          creditValue: 0.5,
          subjectArea: 'ssGovtEcon',
        },
        {
          courseCode: 'HFF43',
          gradReq: 'ssGovtEcon',
          creditValue: 0.5,
          subjectArea: 'ssGovtEcon',
        },
        {
          courseCode: 'HFF44',
          gradReq: 'ssGovtEcon',
          creditValue: 0.5,
          subjectArea: 'ssGovtEcon',
        },
      ],
      ssUs: [
        {
          courseCode: 'HUF41',
          gradReq: 'ssUs',
          creditValue: 0.5,
          subjectArea: 'ssUs',
        },
        {
          courseCode: 'HUF42',
          gradReq: 'ssUs',
          creditValue: 0.5,
          subjectArea: 'ssUs',
        },
        {
          courseCode: 'HUF43',
          gradReq: 'ssUs',
          creditValue: 0.5,
          subjectArea: 'ssUs',
        },
        {
          courseCode: 'HUF44',
          gradReq: 'ssUs',
          creditValue: 0.5,
          subjectArea: 'ssUs',
        },
        {
          courseCode: 'HUF85',
          gradReq: 'ssUs',
          creditValue: 0.25,
          subjectArea: 'ssUs',
        },
      ],
    },
  },
  Lansing: {
    ANNUALIZED: {
      elaCore: [],
      mathAlg: [],
      mathGeom: [],
      mathTrig: [],
      sciChem: [],
      sciEarth: [],
      sciLiving: [],
      sciPhysics: [],
      ssGlobal: [],
      ssUs: [],
      health: [],
      ssGovtEcon: [],
      ssGovt: [],
      ssEcon: [],
      sciBio: [],
      art: [],
      elaElect: [],
      lote: [],
      advisory: [],
      mathCalc: [],
      mathPreCalc: [],
      mathStats: [],
      pe: [],
      sciPhysical: [],
      sciForensics: [],
      sciLife: [],
      cte: [],
      elaEsl: [],
      guidance: [],
      ssElective: [],
      misc: [],
      mathOther: [],
      sciOther: [],
      functionalId: [],
    },
    SEMESTER: {
      elaCore: [],
      mathAlg: [],
      mathGeom: [],
      mathTrig: [],
      sciChem: [],
      sciEarth: [],
      sciLiving: [],
      sciPhysics: [],
      ssGlobal: [],
      ssUs: [],
      health: [],
      ssGovtEcon: [],
      ssGovt: [],
      ssEcon: [],
      sciBio: [],
      art: [],
      elaElect: [],
      lote: [],
      advisory: [],
      mathCalc: [],
      mathPreCalc: [],
      mathStats: [],
      pe: [],
      sciPhysical: [],
      sciForensics: [],
      sciLife: [],
      cte: [],
      elaEsl: [],
      guidance: [],
      ssElective: [],
      misc: [],
      mathOther: [],
      sciOther: [],
      functionalId: [],
    },
    TRIMESTER: {
      elaCore: [],
      mathAlg: [],
      mathGeom: [],
      mathTrig: [],
      sciChem: [],
      sciEarth: [],
      sciLiving: [],
      sciPhysics: [],
      ssGlobal: [],
      ssUs: [],
      health: [],
      ssGovtEcon: [],
      ssGovt: [],
      ssEcon: [],
      sciBio: [],
      art: [],
      elaElect: [],
      lote: [],
      advisory: [],
      mathCalc: [],
      mathPreCalc: [],
      mathStats: [],
      pe: [],
      sciPhysical: [],
      sciForensics: [],
      sciLife: [],
      cte: [],
      elaEsl: [],
      guidance: [],
      ssElective: [],
      misc: [],
      mathOther: [],
      sciOther: [],
      functionalId: [],
    },
    QUARTERLY: {
      elaCore: [],
      mathAlg: [],
      mathGeom: [],
      mathTrig: [],
      sciChem: [],
      sciEarth: [],
      sciLiving: [],
      sciPhysics: [],
      ssGlobal: [],
      ssUs: [],
      health: [],
      ssGovtEcon: [],
      ssGovt: [],
      ssEcon: [],
      sciBio: [],
      art: [],
      elaElect: [],
      lote: [],
      advisory: [],
      mathCalc: [],
      mathPreCalc: [],
      mathStats: [],
      pe: [],
      sciPhysical: [],
      sciForensics: [],
      sciLife: [],
      cte: [],
      elaEsl: [],
      guidance: [],
      ssElective: [],
      misc: [],
      mathOther: [],
      sciOther: [],
      functionalId: [],
    },
  }
};
