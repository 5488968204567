import { MockRegentsGridAction } from './../../actions/mock-regents-grid-actions/mock-regents-grid-actions';
import * as mockRegentsGridActions from '../../actions/mock-regents-grid-actions/mock-regents-grid-actions';

export const initialState = {
  loaded: false,
  gridState: null,
};

export function reducer (state = initialState, action: MockRegentsGridAction) {
  switch (action.type) {
    case mockRegentsGridActions.SET_MOCK_REGENTS_GRID_STATE:
      return {
        gridState: { ...action.payload },
        loaded: true,
      };
    default:
      return state;
  }
}
