import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { NvDesignLibraryModule } from '../../../../nvps-libraries/design/nv-design-library.module';
import { NvMatTooltipModule } from '../../components/nv-mat-tooltip/nv-mat-tooltip.module';
import { BaseModalModule } from '../base-modal.module';
import { SupportAttendanceFormListV2Module } from './../../../school/lists/support-attendance-form-list-v2/support-attendance-form-list-v2.module';
import { SupportAttendanceLogsListV2Module } from './../../../school/lists/support-attendance-logs-list-v2/support-attendance-logs-list-v2.module';
import { SupportAttendanceLogsModalComponent } from './support-attendance-logs-modal.component';

@NgModule({
  declarations: [SupportAttendanceLogsModalComponent],
  imports: [
    SupportAttendanceLogsListV2Module,
    SupportAttendanceFormListV2Module,
    CommonModule,
    BaseModalModule,
    NvDesignLibraryModule,
    NvMatTooltipModule,
  ],
  exports: [SupportAttendanceLogsModalComponent],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SupportAttendanceLogsModalModule {}
