import { NvIconModule } from 'projects/shared/nvps-libraries/design/nv-icon/nv-icon.module';
import { NvTooltipModule } from './../../nv-tooltip/nv-tooltip.module';
import { NvDropdownModule } from 'Src/nvps-libraries/design/nv-dropdown/nv-dropdown.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NvDatePickerModule } from './../../../../nvps-libraries/design/nv-date-picker/nv-date-picker.module';
import { MixpanelService } from 'Src/ng2/shared/services/mixpanel/mixpanel.service';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';

import { NvTextareaModule } from 'Src/nvps-libraries/design/nv-textarea/nv-textarea.module';
import { NvDesignLibraryModule } from '../../../../nvps-libraries/design/nv-design-library.module';
import { NvMatTooltipModule } from '../../components/nv-mat-tooltip/nv-mat-tooltip.module';
import { BaseModalModule } from '../base-modal.module';
import { NotesModalShellComponent } from './notes-modal-shell.component';

@NgModule({
  declarations: [NotesModalShellComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule,
    BaseModalModule,
    NvDesignLibraryModule,
    NvMatTooltipModule,
    NvTextareaModule,
    NvDatePickerModule,
    NvDropdownModule,
    NvIconModule,
    NvTooltipModule,
    MatCheckboxModule,
  ],
  exports: [NotesModalShellComponent],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class NotesModalShellModule {}
