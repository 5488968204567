import { Injectable } from '@angular/core';
import { IEditGridViewParams, IGridViewColDef, IGridViewFilter, IGridViewSort } from '../../../typings/interfaces/grid-view.interface';
import { PartnerTypes, TValidPartnerTypes } from 'Src/ng2/shared/typings/interfaces/partner.interface';

@Injectable()
export class GraphQLGridViewHelperService {
  constructor () {
    //
  }

  public updateGridViewQuery (gridViewParams: IEditGridViewParams, contextPartnerType: TValidPartnerTypes): string {
    return this.getGridViewMutationQuery(gridViewParams, 'EDIT', contextPartnerType);
  }

  public deleteGridViewQuery (gridViewParams: IEditGridViewParams, contextPartnerType: TValidPartnerTypes): string {
    return this.getGridViewMutationQuery(gridViewParams, 'DELETE', contextPartnerType);
  }

  public createGridViewQuery (gridViewParams: IEditGridViewParams, contextPartnerType: TValidPartnerTypes): string {
    return this.getGridViewMutationQuery(gridViewParams, 'CREATE', contextPartnerType);
  }

  public getGridViewMutationQuery (
    gridViewParams: IEditGridViewParams,
    mode: string,
    contextPartnerType: TValidPartnerTypes,
  ): string {
    const {
      _id,
      gridViewId,
      gridViewName,
      gridViewType,
      columnDefs,
      sorts,
      filters,
      accessPermissions,
      entity,
    } = gridViewParams;

    let mutationName;
    let options = '';

    switch (mode) {
      case 'CREATE':
        mutationName = 'createGridView';
        break;
      case 'EDIT':
        mutationName = 'updateGridView';
        break;
      case 'REMOVE':
      case 'DELETE':
        mutationName = 'deleteGridView';
        break;
    }

    options = `_id: "${_id}", gridViewId: "${gridViewId}", gridViewName: "${gridViewName}", gridViewType: "${gridViewType}", contextPartnerType: "${contextPartnerType}"`;
    if (entity) options = options + `, entity: "${entity}"`;
    if (columnDefs) {
      const columnDefString = this._getColumnDefs(columnDefs);
      options += `, columnDefs: [${columnDefString}]`;
    }

    if (sorts) {
      const sortString = this._getSorts(sorts);
      options += `, sorts: ${sortString}`;
    }

    if (filters) {
      const filterString = this._getFilters(filters);
      options += `, filters: ${filterString}`;
    }

    if (accessPermissions) {
      const accessPermissionsString = this._getAccessPermissions(accessPermissions, contextPartnerType);
      if (accessPermissionsString) {
        options += `, accessPermissions: ${accessPermissionsString}`;
      }
    }

    const contextPath = this.getContextPath(contextPartnerType) || '';

    return `
    mutation {
      ${mutationName}(
        options: {${options}}
      ){
        _id,
        gridViewId,
        gridViewName,
        gridViewType,
        columnDefs {
          order,
          headerName,
          portalLogicId,
          field,
          masterOrder,
          width,
          pinned,
        },
        filters {
          colId,
          values,
          filterType,
          operator,
          condition1,
          condition2,
          type,
          filter,
          filterTo,
          dateFrom,
          dateTo
        }
        sorts {
          colId,
          sort,
          sortIndex
        }
        accessPermissions {
          districts,
          gridTypes,
          userIds,
          ${contextPath},
        },
        createdBy {
          userId,
          firstName,
          lastName,
          gafeEmail,
        }
      }
    }`;
  }

  private _getColumnDefs (colDefs: IGridViewColDef[]): string {
    let columnDefs = '';
    for (const colDef of colDefs) {
      let columnDefString = '{';
      const { order, headerName, portalLogicId, field, masterOrder, width, pinned } = colDef;
      if (field) {
        columnDefString += `field: "${field}"`;
      }
      if (order) {
        columnDefString += `, order: ${order}`;
      }
      if (headerName) {
        columnDefString += `, headerName: "${headerName}"`;
      }
      if (portalLogicId) {
        columnDefString += `, portalLogicId: "${portalLogicId}"`;
      }
      if (masterOrder) {
        columnDefString += `, masterOrder: ${masterOrder}`;
      }
      if (width) {
        columnDefString += `, width: ${width}`;
      }
      if (pinned) {
        columnDefString += `, pinned: "${pinned}"`;
      }
      columnDefString += '},';
      columnDefs += columnDefString;
    }
    return columnDefs.substring(0, columnDefs.length - 1);
  }

  private _getSorts (sorts: IGridViewSort[]): string {
    if (sorts?.length > 0) {
      let sortListString = '[';
      for (const sortObj of sorts) {
        let sortString = '{';
        const { colId, sort, sortIndex } = sortObj;
        if (colId) {
          sortString += `colId: "${colId}"`;
        }
        if (sort) {
          sortString += `, sort: "${sort}"`;
        }
        if (sortIndex) {
          sortString += `, sortIndex: ${sortIndex}`;
        }

        sortString += '},';
        sortListString += sortString;
      }
      return sortListString.length > 0 ? sortListString.substring(0, sortListString.length - 1) + ']' : null;
    } else {
      return '[]';
    }
  }

  private _getFilters (filters: IGridViewFilter[]): string {
    let filterListString = '[';

    for (const filterObj of filters) {
      let filterString = '{';
      const { colId, filterType, values, operator, condition1, condition2, type, filter, filterTo, dateFrom, dateTo, filterModels } = filterObj;
      if (colId) {
        filterString += `colId: "${colId}"`;
      }
      if (values) {
        const valueString = values.map(val => `"${val}"`).join(', ');
        filterString += `, values: [${valueString}]`;
      }
      if (filterType) {
        filterString += `, filterType: "${filterType}"`;
      }
      if (operator) {
        filterString += `, operator: "${operator}"`;
      }
      if (condition1) {
        filterString += `, condition1: ${JSON.stringify(JSON.stringify(condition1))}`;
      }
      if (condition2) {
        filterString += `, condition2: ${JSON.stringify(JSON.stringify(condition2))}`;
      }
      if (type) {
        filterString += `, type: "${type}"`;
      }
      if (filter) {
        filterString += `, filter: "${filter}"`;
      }
      if (filterTo) {
        filterString += `, filterTo: "${filterTo}"`;
      }
      if (dateFrom) {
        filterString += `, dateFrom: "${dateFrom}"`;
      }
      if (dateTo) {
        filterString += `, dateTo: "${dateTo}"`;
      }
      if (filterModels) {
        const filterModelStrings = filterModels
          .filter(fm => fm !== null) // Filter out null values
          .map(fm => this._constructFilterString(fm))
          .join(', ');

        filterString += `, filterModels: [${filterModelStrings}]`;
      }

      filterString += '},';
      filterListString += filterString;
    }
    return filterListString + ']';
  }

  private _constructFilterString (filterModel: any): string {
    if (filterModel.operator) {
      return `{ filterType: "${filterModel.filterType}", operator: "${filterModel.operator}", condition1: { filterType: "${filterModel.condition1.filterType}", type: "${filterModel.condition1.type}", filter: "${filterModel.condition1.filter}" }, condition2: { filterType: "${filterModel.condition2.filterType}", type: "${filterModel.condition2.type}", filter: "${filterModel.condition2.filter}" } }`;
    } else {
      return `{ filterType: "${filterModel.filterType}", type: "${filterModel.type}", filter: "${filterModel.filter}" }`;
    }
  }

  private _getAccessPermissions (accessPermissions: any, contextPartnerType: TValidPartnerTypes): string | null {
    const contextPath = this.getContextPath(contextPartnerType);
    const partnerIds = (contextPath && accessPermissions[contextPath]) || [];
    // only custom/non-template/non-admin grid views should be saved
    // these grid views are of type=custom and expected to be available only for the current partnerType (school/shelter/networks/etc...).
    // hence we will not add district/gridtype info here
    const partnerIdsString = partnerIds?.map(partnerId => `"${partnerId}"`).join(', ');
    const accessString = contextPath ? `{ ${contextPath}: [${partnerIdsString}] }` : null;
    // TODO: shared custom views will populate userIds shared with  //, userIds: "${userIds}",
    return accessString;
  }

  public getContextPath (contextPartnerType: TValidPartnerTypes): string | null {
    switch (contextPartnerType) {
      case PartnerTypes.SCHOOL:
        return 'schoolIds';
      case PartnerTypes.SCHOOL_NETWORK:
        return 'schoolClusterIds';
      case PartnerTypes.SHELTER:
        return 'shelterIds';
      case PartnerTypes.SHELTER_NETWORK:
        return 'shelterClusterIds';
      default:
        return null;
    }
  }
}
