import { IServerSideGetRowsParams } from '@ag-grid-community/core';
import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'status-component',
  styleUrls: ['./status-bar.component.scss'],
  templateUrl: './status-bar.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class StatusBarComponent {
  public filteredRowCount: number;
  public params: IServerSideGetRowsParams;
  public totalRowCount: number;

  public agInit (params: IServerSideGetRowsParams): void {
    this.params = params;
  }

  public setTotalRows (num: number): void {
    this.totalRowCount = num;
  }

  public setFilteredRows (num: number): void {
    this.filteredRowCount = num;
  }
}
