export type TValidCourseDiffBackendStatuses = 'APPLIED' | 'PENDING' | 'DELETED';

export type TValidCourseDiffFrontendStatuses = 'APPLIED' | 'PENDING' | 'REMOVED';

export interface ICourseDiffStatuses {
  backend: {
    APPLIED: TValidCourseDiffBackendStatuses;
    PENDING: TValidCourseDiffBackendStatuses;
    DELETED: TValidCourseDiffBackendStatuses;
  };
  frontend: {
    APPLIED: TValidCourseDiffFrontendStatuses;
    PENDING: TValidCourseDiffFrontendStatuses;
    REMOVED: TValidCourseDiffFrontendStatuses;
  };
}

export const CourseDiffStatuses: ICourseDiffStatuses = {
  backend: {
    APPLIED: 'APPLIED',
    PENDING: 'PENDING',
    DELETED: 'DELETED',
  },
  frontend: {
    APPLIED: 'APPLIED',
    PENDING: 'PENDING',
    REMOVED: 'REMOVED',
  },
};
