<header class="sidebar-nav-header">
  <nv-icon-button
    name="arrow-left-selected"
    nvMatTooltip="Back"
    nvMatTooltipClass="tooltip"
    (clickButton)="goToMyStudents()"
  ></nv-icon-button>
  <h1>Settings</h1>
</header>
<div class="side-content-wrap">
<nv-sidebar-list 
  [listData]="routeOptions" 
  [selectedKey]="selectedSidebarItem"
  (sidebarListItemSelect)="changeView($event)">
</nv-sidebar-list>
</div>