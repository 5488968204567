import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { IACOption } from '../../../../../../projects/shared/nvps-libraries/design/interfaces/design-library.interface';
import { CONSENT_STATUS, TConsentEvent } from 'projects/shared/typings/interfaces/ecfik-consent.interface';

export enum ConsentStatusOptions {
  // eslint-disable-next-line no-unused-vars
  NOT_REQUESTED = CONSENT_STATUS.NOT_REQUESTED,
  // eslint-disable-next-line no-unused-vars
  YES = CONSENT_STATUS.YES,
  // eslint-disable-next-line no-unused-vars
  NO = CONSENT_STATUS.NO,
  // eslint-disable-next-line no-unused-vars
  REVOKED = CONSENT_STATUS.REVOKED,
};

@Component({
  selector: 'edit-consent-status',
  templateUrl: 'edit-consent-status.component.html',
  styleUrls: ['./edit-consent-status.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EditConsentStatusComponent implements OnInit {
  @Input() initialConsentStatus: CONSENT_STATUS;

  public ecfikEmail: string = 'caringadults@schools.nyc.gov';
  public form: FormGroup;
  public consentStatusForm: FormGroup;
  public consentStatusOptions: IACOption[];
  public selectedConsentStatus = new BehaviorSubject(null);

  constructor (
    private formBuilder: FormBuilder,
  ) {}

  // eslint-disable-next-line no-unused-vars
  get consentStatusToConsentEventMap (): { [key in ConsentStatusOptions]: TConsentEvent } {
    return {
      [ConsentStatusOptions.NOT_REQUESTED]: 'consentNotRequested',
      [ConsentStatusOptions.YES]: 'completed',
      [ConsentStatusOptions.NO]: 'declined',
      [ConsentStatusOptions.REVOKED]: 'consentRevoked',
    };
  }

  ngOnInit () {
    this.form = this.getFormGroup();
    this.consentStatusOptions = this.getConsentStatusOptions();
    this.setInitialConsentValue(this.initialConsentStatus);
  }

  private getFormGroup (): FormGroup {
    const fg = this.formBuilder.group({
      consentEvent: [null, Validators.required],
    });
    return fg;
  }

  private getConsentStatusOptions (): IACOption[] {
    const options = Object.values(ConsentStatusOptions)
      .filter(option => {
        if (option === ConsentStatusOptions.REVOKED) {
          if (this.initialConsentStatus === CONSENT_STATUS.YES) {
            return true;
          }
          return false;
        } else if (option === ConsentStatusOptions.NOT_REQUESTED) {
          if (this.initialConsentStatus === CONSENT_STATUS.NO) {
            return true;
          }
          return false;
        } else {
          return true;
        }
      })
      .map(option => ({ key: this.getConsentEventValue(option), human: option }));
    return options;
  }

  private setInitialConsentValue (consentStatus: CONSENT_STATUS): void {
    const showConsentStatusYes = [CONSENT_STATUS.REVOKED, CONSENT_STATUS.NOT_REQUESTED, CONSENT_STATUS.PENDING].includes(consentStatus);
    const initialConsentStatus = showConsentStatusYes ? ConsentStatusOptions.YES : consentStatus;
    this.selectedConsentStatus.next(this.getConsentEventValue(initialConsentStatus));
  }

  private getConsentEventValue (consentStatus: string): string {
    return this.consentStatusToConsentEventMap[consentStatus];
  }

  public onConsentStatusSelect ($event): void {
    this.selectedConsentStatus.next($event);
  }

  public getPayload () {
    const consentEvent = this.selectedConsentStatus.value;
    const payload = {
      consentEvent,
    };
    return payload;
  }
}
