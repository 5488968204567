import { ColDef, ColumnState } from '@ag-grid-community/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Observable, merge, take, tap } from 'rxjs';
import { IDropdownOption } from '../../../../../projects/shared/nvps-libraries/design/interfaces/design-library.interface';
import { GridFilterSummaryComponent } from '../grid-filter-summary/grid-filter-summary.component';
import { GridSortSummaryComponent } from '../grid-sort-summary/grid-sort-summary.component';

export enum GridTableControlButtonType {
  // eslint-disable-next-line no-unused-vars
  FILTER = 'filter',
  // eslint-disable-next-line no-unused-vars
  UPDATE = 'update',
}

export enum GridTableControlActionType {
  // eslint-disable-next-line no-unused-vars
  CLEAR_FILTER = 'clear-filter',
  // eslint-disable-next-line no-unused-vars
  CLEAR_SORT = 'clear-sort',
}

@Component({
  selector: 'grid-table-controls',
  templateUrl: './grid-table-controls.component.html',
  styleUrl: './grid-table-controls.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class GridTableControlsComponent implements OnInit {
  @Input() columnDefs: ColDef[];
  @Input() gridData$: Observable<{
    columnState: ColumnState[];
    filterModel: any;
    type: GridTableControlButtonType;
  }>;

  @Input() networkExternalFilterOptions: IDropdownOption[];
  @Input() hideEditColumns: boolean = false;
  @Output() gridTableControlAction = new EventEmitter<GridTableControlActionType>();
  @Output() gridTableControlDataRequest = new EventEmitter<GridTableControlButtonType>();
  @Output() networkExternalFilterOptionChange = new EventEmitter<IDropdownOption>();
  @Output() onEditColumns = new EventEmitter<null>();
  @ViewChild('filterSummary', { static: false }) filterSummary: ElementRef;
  @ViewChild('sortSummary', { static: false }) sortSummary: ElementRef;

  private columnState: ColumnState[];
  private filterModel: any;
  public columnsButtonText: string = 'Edit columns';
  public filtersButtonText: string;
  public isFilterButtonDisabled: boolean = true;
  public isFilterSummaryVisible: boolean = false;
  public isSortSummaryVisible: boolean = false;
  public isSortsButtonDisabled: boolean = true;
  public sortsButtonText: string;

  constructor (private overlay: Overlay) {}

  ngOnInit (): void {
    this.gridData$.subscribe(data => {
      const { columnState, filterModel, type } = data;
      this.columnState = columnState;
      this.filterModel = filterModel;
      this.afterDataRefresh(type);
    });
  }

  private afterDataRefresh (type: GridTableControlButtonType) {
    switch (type) {
      case GridTableControlButtonType.UPDATE: {
        this.updateTableControls();
        break;
      }
    }
  }

  private updateTableControls (): void {
    const filtersCount = Object.keys(this.filterModel).length;
    const sortsCount = this.columnState.reduce((acc, c) => {
      if (c.sort) acc++;
      return acc;
    }, 0);

    if (filtersCount === 1) {
      this.filtersButtonText = `${filtersCount} filter`;
    } else {
      this.filtersButtonText = `${filtersCount} filters`;
    }

    if (filtersCount === 0) {
      this.isFilterButtonDisabled = true;
    } else {
      this.isFilterButtonDisabled = false;
    }

    if (sortsCount === 1) {
      this.sortsButtonText = `${sortsCount} sort`;
    } else {
      this.sortsButtonText = `${sortsCount} sorts`;
    }

    if (sortsCount === 0) {
      this.isSortsButtonDisabled = true;
    } else {
      this.isSortsButtonDisabled = false;
    }
  }

  public onFilterSummaryClick (): void {
    if (this.isFilterButtonDisabled) return;
    const filterData = this.getFilterData();
    const overlayRef = this.getSummaryOverlay(this.filterSummary);
    this.openFilterSummary(overlayRef, filterData);
  }

  private getFilterData (): any[] {
    const filterModelKeys = Object.keys(this.filterModel);
    return this.columnState
      .filter(c => {
        return !c.hide && filterModelKeys.includes(c.colId);
      })
      .map(c => {
        const { headerName } = this.columnDefs.find(cd => cd.field === c.colId);
        const columnFilterModel = this.filterModel[c.colId];
        const filterDataRow = this.getFilterDataRow(columnFilterModel, headerName);
        return filterDataRow;
      });
  }

  private getFilterDataRow (filterModel: any, headerName: string): any {
    const { dateFrom, dateTo, filter, filterTo, filterType, operator, type } = filterModel;

    const filterDataRow = {
      headerName,
      type: null,
      filter: null,
      filterTo: null,
    };

    switch (filterType) {
      case 'set': {
        const { values } = filterModel;
        const len = values.length;
        if (len === 0) {
          filterDataRow.filter = 'none';
        } else if (len === 1) {
          filterDataRow.filter = values[0];
        } else {
          filterDataRow.filter = 'multiple';
        }
        break;
      }
      case 'date': {
        const humanDateFrom = this.getHumanDate(dateFrom);
        const humanDateTo = this.getHumanDate(dateTo);
        if (operator) {
          filterDataRow.filter = 'multiple';
        } else if (type === 'blank') {
          filterDataRow.filter = 'Blank';
        } else if (type === 'notBlank') {
          filterDataRow.filter = 'Not blank';
        } else if (type === 'inRange') {
          filterDataRow.filter = humanDateFrom;
          filterDataRow.filterTo = humanDateTo;
          filterDataRow.type = this.getHumanFilterType(type);
        } else {
          filterDataRow.filter = humanDateFrom;
          filterDataRow.type = this.getHumanFilterType(type);
        }
        break;
      }
      case 'number':
      case 'text':
        if (operator) {
          filterDataRow.filter = 'multiple';
        } else {
          this.applyNumberOrTextFilter(type, filter, filterTo, filterDataRow);
        }
        break;
      case 'multi': {
        const { filterModels } = filterModel;
        // Extract valid filters from filterModels (in agMultiColumnFilter, a filter model may be null if not used)
        const validFilters: any[] = filterModels.filter((filter: any) => filter !== null);
        // Check if more than one filter applies to the column
        const isMultipleFilter = validFilters.length > 1;
        if (isMultipleFilter) {
          filterDataRow.filter = 'multiple';
        } else {
          const {
            filterType: multiFilterType,
            type: multiType,
            filter: filterValue,
            filterTo: filterToValue,
          } = validFilters[0];

          switch (multiFilterType) {
            case 'number':
            case 'text':
              this.applyNumberOrTextFilter(multiType, filterValue, filterToValue, filterDataRow);
              break;
            default:
              throw new Error(`Unsupported multi filter type: ${multiFilterType}`);
          }
        }
        break;
      }
      default: {
        throw new Error(`Error: Unrecognized filterType: ${filterType}`);
      }
    }

    return filterDataRow;
  }

  private getHumanDate (date: string): null | string {
    if (!date) return null;
    const jsDate = new Date(date);
    const month = jsDate.getMonth() + 1;
    const numDate = jsDate.getDate();
    const year = jsDate.getFullYear();
    return `${month}/${numDate}/${year}`;
  }

  private getHumanFilterType (type: string): string {
    let humanType = type;
    switch (type) {
      case 'contains': {
        humanType = 'Contains';
        break;
      }
      case 'empty': {
        humanType = 'Empty';
        break;
      }
      case 'endsWith': {
        humanType = 'Ends with';
        break;
      }
      case 'equals': {
        humanType = 'Equals';
        break;
      }
      case 'greaterThan': {
        humanType = 'Greater than';
        break;
      }
      case 'greaterThanOrEqual': {
        humanType = 'Greater than or equals';
        break;
      }
      case 'inRange': {
        humanType = 'In range';
        break;
      }
      case 'lessThan': {
        humanType = 'Less than';
        break;
      }
      case 'lessThanOrEqual': {
        humanType = 'Less than or equals';
        break;
      }
      case 'notContains': {
        humanType = 'Not contains';
        break;
      }
      case 'notEqual': {
        humanType = 'Not equal';
        break;
      }
      case 'startsWith': {
        humanType = 'Starts with';
        break;
      }
    }
    return humanType;
  }

  private applyNumberOrTextFilter (type: string, filterValue: any, filterTo: any, filterDataRow: any): void {
    switch (type) {
      case 'blank':
        filterDataRow.filter = 'Blank';
        break;
      case 'notBlank':
        filterDataRow.filter = 'Not blank';
        break;
      case 'inRange':
        filterDataRow.filter = filterValue;
        filterDataRow.filterTo = filterTo;
        filterDataRow.type = this.getHumanFilterType(type);
        break;
      default:
        filterDataRow.filter = filterValue;
        filterDataRow.type = this.getHumanFilterType(type);
        break;
    }
  }

  private getSummaryOverlay (hostElement: ElementRef): OverlayRef {
    const overlayRef = this.overlay.create({
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(hostElement)
        .withPositions([
          { offsetY: 8, originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' },
          { offsetY: 8, originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom' },
        ])
        .withFlexibleDimensions(true)
        .withPush(false),
    });
    return overlayRef;
  }

  private openFilterSummary (overlayRef: OverlayRef, filterData: string[]): void {
    this.isFilterSummaryVisible = true;
    const component = new ComponentPortal(GridFilterSummaryComponent);
    const componentRef = overlayRef.attach(component);
    componentRef.instance.filterData = filterData;

    merge(componentRef.instance.clearAllClick, overlayRef.outsidePointerEvents())
      .pipe(
        take(1),
        tap((event: string | PointerEvent) => {
          if (event === 'clear') {
            // reset all filters
            this.gridTableControlAction.emit(GridTableControlActionType.CLEAR_FILTER);
          }
          this.closeSummary(overlayRef);
        }),
      )
      .subscribe();
  }

  private closeSummary (overlayRef: OverlayRef): void {
    this.isFilterSummaryVisible = false;
    this.isSortSummaryVisible = false;
    overlayRef.detach();
  }

  public onSortSummaryClick (): void {
    if (this.isSortsButtonDisabled) return;
    const sortData = this.getSortData();
    const overlayRef = this.getSummaryOverlay(this.sortSummary);
    this.openSortSummary(overlayRef, sortData);
  }

  private getSortData (): { colId: string; headerName: string; sort: string; sortIndex: number }[] {
    const sortData = this.columnState
      .reduce((acc, c) => {
        if (!c.hide) {
          if (c.sort) {
            const sortHuman = c.sort === 'asc' ? 'Ascending' : 'Descending';
            acc.push({ colId: c.colId, sort: sortHuman, sortIndex: c.sortIndex });
          }
        }
        return acc;
      }, [])
      .map(c => {
        const columnDefinition = this.columnDefs.find(cd => cd.field === c.colId);
        return { headerName: columnDefinition.headerName, ...c };
      });
    sortData.sort((a, b) => {
      return a.sortIndex > b.sortIndex ? 1 : -1;
    });
    return sortData;
  }

  private openSortSummary (overlayRef: OverlayRef, sortData: any): void {
    this.isSortSummaryVisible = true;
    const component = new ComponentPortal(GridSortSummaryComponent);
    const componentRef = overlayRef.attach(component);
    componentRef.instance.sortData = sortData;
    merge(componentRef.instance.clearAllClick, overlayRef.outsidePointerEvents())
      .pipe(
        take(1),
        tap((event: string | PointerEvent) => {
          if (event === 'clear') {
            // reset all sorts
            this.gridTableControlAction.emit(GridTableControlActionType.CLEAR_SORT);
          }
          this.closeSummary(overlayRef);
        }),
      )
      .subscribe();
  }
}
