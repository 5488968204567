import { BaseModalComponent } from 'Src/ng2/shared/modals/base-modal.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { AddCaringAdultComponent } from '../add-caring-adult/add-caring-adult.component';
import { RemoveCaringAdultComponent } from '../remove-caring-adult/remove-caring-adult.component';
import { ManageCaseloadComponent } from '../manage-caseload/manage-caseload.component';
import { ManageCaringAdultComponent } from '../manage-caring-adult/manage-caring-adult.component';

export interface ICaringAdultInfo { // this is subject to change based on data shape that flows from caller
  id: string;
  firstName: string;
  lastName: string;
  caseload?: {
    schoolId: string;
    studentIds: string[];
    role: 'CARING_ADULT';
  }
}

export type TModalControl = 'ADD_CARING_ADULT' | 'REMOVE_CARING_ADULT' | 'MANAGE_CASELOAD' | 'ASSIGN_CARING_ADULT' | 'MANAGE_CARING_ADULT';

export interface IProgramPointModalData {
  modalControl: TModalControl;
  schoolId: string;
  studentId?: string;
  caringAdultInfo?: ICaringAdultInfo;
}

@Component({
  selector: 'program-point-modal',
  templateUrl: 'program-point-modal.component.html',
  styleUrls: ['./program-point-modal.component.scss'],
})

export class ProgramPointModal extends BaseModalComponent implements OnInit {
  public modalControl: TModalControl;
  public schoolId: string;
  public studentId: string; // modal loaded from student page will get a student ID
  public caringAdultInfo: ICaringAdultInfo;
  public title: string;
  public submitButtonText: string;

  @ViewChild(AddCaringAdultComponent) addCaringAdult: AddCaringAdultComponent;
  @ViewChild(RemoveCaringAdultComponent) removeCaringAdult: RemoveCaringAdultComponent;
  @ViewChild(ManageCaseloadComponent) manageCaseload: ManageCaseloadComponent;
  @ViewChild(ManageCaringAdultComponent) assignCaringAdult: ManageCaringAdultComponent;

  constructor (
    dialogRef: MatDialogRef<ProgramPointModal>,
    @Inject(MAT_DIALOG_DATA) public data: IProgramPointModalData,
  ) {
    super(dialogRef);
  }

  ngOnInit () {
    this.modalControl = this.data.modalControl;
    this.schoolId = this.data.schoolId;
    this.studentId = this.data.studentId;
    this.caringAdultInfo = this.data.caringAdultInfo;
    this.title = this.getTitle(this.modalControl);
    this.submitButtonText = this.getSubmitButtonText(this.modalControl);
  }

  private getTitle (modalControl: TModalControl): string {
    const mapModalControlToTitle = {
      ADD_CARING_ADULT: 'Add Caring Adult',
      REMOVE_CARING_ADULT: 'Remove Caring Adult',
      MANAGE_CASELOAD: 'Manage caseload',
      ASSIGN_CARING_ADULT: 'Assign Caring Adult',
      MANAGE_CARING_ADULT: 'Manage Caring Adult',
    };
    const title = mapModalControlToTitle[modalControl];
    return title;
  }

  private getSubmitButtonText (modalControl: TModalControl): string {
    const buttonText = modalControl === 'REMOVE_CARING_ADULT' ? 'Remove' : 'Save';
    return buttonText;
  }

  public onSave (): void {
    const mapModalControlToChildForm = {
      ADD_CARING_ADULT: 'addCaringAdult',
      REMOVE_CARING_ADULT: 'removeCaringAdult',
      MANAGE_CASELOAD: 'manageCaseload',
      ASSIGN_CARING_ADULT: 'assignCaringAdult',
      MANAGE_CARING_ADULT: 'assignCaringAdult',
    };

    const childForm = mapModalControlToChildForm[this.modalControl];
    const payload = this[childForm].getPayload();
    super.close(payload);
  }

  public onCancel () {
    super.close();
  }
}
