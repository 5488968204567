<ng-container class="notifications-modal">
  <div class="dialog-title">
    <div class="title-left">
      <nv-icon-button name="close-large-blue" (clickButton)="onCancel()"></nv-icon-button>
      <h1 class="title-header">{{ title }}</h1>
    </div>
    <nv-icon-button *ngIf="mode === 'Add'" class="title-right" name="clipboard-large-blue" (clickButton)="onPasteNotification()"></nv-icon-button>
  </div>
  <div class="info-container">
    <nv-note *ngIf="disabled['info']" priority="info" size="small">{{ disabled.info }}</nv-note>
    <nv-note *ngIf="errorMessage" priority="danger" size="small">{{ errorMessage }}</nv-note>
  </div>
  <form [formGroup]="notificationForm" autocomplete="off" *ngIf="notificationForm">
    <!-- title -->
    <nv-form-field
      label="Title"
      [ngClass]="{ disabled : disabled['title'] }"
      [isInvalid]="notificationForm.controls['title'].invalid"
      [showInvalid]="notificationForm.controls['title'].invalid && !notificationForm.controls['title'].pristine">
      <nv-textbox
        placeholder="title"
        [showInvalid]="true"
        [showInvalidOnPristine]="false"
        [textControl]="notificationForm.controls['title']"
        (clearValue)="onClearInput('title')">
      </nv-textbox>
    </nv-form-field>

    <!-- messsage -->
    <nv-form-field label="Message">
      <nv-textarea
        [ngClass]="{ disabled : disabled['title'] }"
        [formControl]="notificationForm.controls.message"
        [maxCharacters]="600"
        [placeholder]="'Enter a message'"
      ></nv-textarea>
    </nv-form-field>

    <!-- date inputs -->
    <nv-form-field label="Start and end date" [ngClass]="{ disabled : disabled['dateRange'] }">
      <nv-date-range-picker
        [dateRangeForm]="notificationForm.controls.dateRange"
        
        [startPlaceholder]="'Date'"
        [endPlaceholder]="'Date'"
      ></nv-date-range-picker>
    </nv-form-field>

    <!--  time range picker-->
    <nv-form-field label="Start and end time" [ngClass]="{ disabled : disabled['timeRange'] }">
      <nv-time-range-picker
        [timeRangeForm]="notificationForm.controls.timeRange"
        [hasClearBtn]="true"
        [rangeStartTime]="rangeStartTime"
        [rangeEndTime]="rangeEndTime"
        [interval]="interval"
      ></nv-time-range-picker>
    </nv-form-field>
      
    <div class="drop-down-items">
      <div class="row">
        <!-- status -->
        <nv-form-field label="Status" [ngClass]="{ disabled : disabled['status'] }">
          <nv-dropdown
            [options]="dropDownOptions.options['status']"
            [selected]="selectedDropDownOptions['status'].key"
            (selectOption)="onDropDownOptionChange('status', $event)"
          >
          </nv-dropdown>
        </nv-form-field>
  
        <!-- priority -->
        <nv-form-field label="Priority" [ngClass]="{ disabled : disabled['priority'] }">
          <nv-dropdown
            [options]="dropDownOptions.options['priority']"
            [selected]="selectedDropDownOptions['priority'].key"
            (selectOption)="onDropDownOptionChange('priority', $event)"
          >
          </nv-dropdown>
        </nv-form-field>
        
        <!-- target -->
        <!-- disabled by default. Always set to ALL -->
        <nv-form-field *ngIf="!disabledDropDownOptions['target']" label="Target" [ngClass]="{ disabled : disabled['target'] }">
          <nv-dropdown
            [options]="dropDownOptions.options['target']"
            [selected]="selectedDropDownOptions['target'].key"
            (selectOption)="onDropDownOptionChange('target', $event)"
          >
          </nv-dropdown>
        </nv-form-field>

        <!-- type -->
        <!-- disabled by default. Always set to ALERT -->
        <nv-form-field *ngIf="!disabledDropDownOptions['type']" label="Type" [ngClass]="{ disabled : disabled['type'] }">
          <nv-dropdown
            [options]="dropDownOptions.options['type']"
            [selected]="selectedDropDownOptions['type'].key"
            (selectOption)="onDropDownOptionChange('type', $event)"
          >
          </nv-dropdown>
        </nv-form-field>

        <!-- isDismissable -->
        <nv-form-field label="Dismissable" [ngClass]="{ disabled : disabled['isDismissable'] }">
          <nv-dropdown
            [options]="dropDownOptions.options['isDismissable']"
            [selected]="selectedDropDownOptions['isDismissable'].key"
            (selectOption)="onDropDownOptionChange('isDismissable', $event)"
          >
          </nv-dropdown>
        </nv-form-field>
      </div>
    </div>
  </form>

  <!-- Buttons -->
  <mat-dialog-actions class="dialog-actions">
    <nv-button type="secondary" (clickButton)="onCancel()">
      Cancel
    </nv-button>
    <nv-button
      htmlType="submit"
      [disabled]="mode === 'Add' ? notificationForm.pristine || notificationForm.invalid : notificationForm.pristine"
      (clickButton)="onClickPrimaryBtn()"
    >
      {{ mode }}
    </nv-button>
  </mat-dialog-actions>
</ng-container>