import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { ApiService } from '../../../../shared/services/api-service/api-service';
import { ISupportGroupingsPayload } from '../../../../store/actions/support-actions/support-groupings.actions';
import { generateQuery } from '../../../../store/effects/projections/support-groupings';
import {
  BulkCreateAttendanceRecords,
  BulkUpdateAttendanceRecords,
} from './../../../../store/actions/attendance-records-actions';

export interface IFilter {
  filterKey: string;
  label: string;
  filterOptions: Array<{ graphQlKey: string; label: string }>;
}

export interface IGrouping {
  graphQlKey: string;
  label: string;
}

export interface IFocus {
  graphQlKey: string;
  label: string;
  isDefault: boolean;
  filters: IFilter[];
  groupings: IGrouping[];
}

@Injectable()
export class SupportAttendanceLogsDataService {
  private fociCache$: Observable<any> = null;

  constructor (public apiService: ApiService, private store: Store<any>) {}

  getSupportFocusData$ (schoolId: string, focusKey?: string): Observable<{ data: { SupportFocus: IFocus[] } }> {
    if (!this.fociCache$) {
      const payload = this.getSupportFocusQuery(schoolId, focusKey);
      this.fociCache$ = this.apiService.getStudentsGraphQL(payload).pipe(shareReplay(1));
    }
    return this.fociCache$;
  }

  getSupportGroupingData$ (payload: ISupportGroupingsPayload) {
    // Hidden filter to not show deleted student supports
    payload.filterKeys.push('NON_DELETED_STUDENT_SUPPORTS', 'NON_COLLEGE_CAREER_SUPPORTS');

    const query = generateQuery({ payload });
    return this.apiService.getStudentsGraphQL({ query, fetchPolicy: 'no-cache' });
  }

  getSupportFocusQuery (schoolId: string, focusKey?: string) {
    const payload = focusKey ? `schoolId:"${schoolId}", focusKey:"${focusKey}"` : `schoolId:"${schoolId}"`;

    const query = `{
      SupportFocus(${payload}) {
        graphQlKey
        label
        isDefault
        category
        categoryOrder
        filters {
          label
          filterOptions {
            graphQlKey
            label
            isDefault
            filterOptions {
              graphQlKey
              label
            }
          }
        }
        groupings {
          graphQlKey
          label
          requiresWildcard
          wildcardKey
          columns {
            graphQlKey
            label
            requiresWildcard
            wildcardKey
            columnDataFormat
            cellConfig
            cellTooltip
            headerTooltip {
              ...on SimpleTooltip {
                simpleContent
              }
              ...on TableTooltip {
                tableContent {
                  headers
                  rowData
                }
              }
            }
            cellTooltipWildcardKey
          }
        }

      }
    }`;
    return { query, fetchPolicy: 'no-cache' };
  }

  clearFociCache (): void {
    this.fociCache$ = null;
  }

  markAttendanceRecordStatus ({ status, schoolId, supportId, eventDate, attendanceRecordIds = [], studentIds = {} }) {
    if (attendanceRecordIds.length) {
      this.store.dispatch(new BulkUpdateAttendanceRecords([{ attendanceRecordIds, status }]));
    }
    if (Object.keys(studentIds).length) {
      this.store.dispatch(
        new BulkCreateAttendanceRecords([
          {
            attendanceRecord: {
              schoolId,
              supportId,
              eventDate,
              status,
            },
            studentIds,
          },
        ]),
      );
    }
  }
}
