import { NvNoteSummerComponent } from './nv-note-summer.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvNoteModule } from '../../../../projects/shared/nvps-libraries/design/nv-note/nv-note.module';

@NgModule({
  imports: [
    CommonModule,
    NvNoteModule,
  ],
  declarations: [NvNoteSummerComponent],
  exports: [NvNoteSummerComponent],
})
export class NvNoteSummerModule {}
