'use strict';

import { MAP_GROWTH_CURR_TERM } from './current-school-year.constant';

export const LANGUAGE_USAGE = 'Language Usage';
export const READING = 'Reading';
export const MATH_K_12 = 'Math K-12';
export const ALGEBRA_1 = 'Algebra 1';
export const ALGEBRA_2 = 'Algebra 2';
export const GEOMETRY = 'Geometry';
export const FALL = 'Fall';
export const WINTER = 'Winter';
export const SPRING = 'Spring';
export const VALID_MAP_GROWTH_TERMS = [FALL, WINTER, SPRING];
export const FALL_TO_WINTER = 'fallToWinter';
export const WINTER_TO_SPRING = 'winterToSpring';
export const FALL_TO_SPRING = 'fallToSpring';
export const VALID_MAP_GROWTH_PROGRESS_TERMS = [FALL_TO_WINTER, WINTER_TO_SPRING, FALL_TO_SPRING];
export const PROGRESS_TERMS_HUMAN_MAP = {
  fallToWinter: 'Fall to Winter',
  winterToSpring: 'Winter to Spring',
  fallToSpring: 'Fall to Spring',
};

export const PROGRESS_TERMS_TOOLTIPS_MAP = {
  fallToWinter: ['Fall', 'Winter'],
  winterToSpring: ['Winter', 'Spring'],
  fallToSpring: ['Fall', 'Spring'],
};

export const MapColumns = {
  termFields: {
    SCORE_DATE: {
      humanName: 'Score Date',
      colName: 'ScoreDate',
      desiredField: 'testStartDate',
      headerTooltip: function (term: string, course: string): string {
        return `The date of the student's ${term} MAP Growth ${course} assessment this school year. Data is provided by NWEA and updated nightly`;
      },
    },
    RIT_SCORE: {
      humanName: 'RIT Score',
      colName: 'RitScore',
      desiredField: 'testRitScore',
      headerTooltip: function (term: string, course: string): string {
        return `${term} RIT score for the ${course} assessment`;
      },
    },
    PERCENTILE: {
      humanName: 'Percentile',
      colName: 'Percentile',
      desiredField: 'testPercentile',
      headerTooltip: function (term: string, course: string): string {
        return `A student's ranking based on their ${course} RIT score for the ${term}`;
      },
    },
    ACHIEVEMENT_QUINTILE: {
      humanName: 'Achievement Quintile',
      colName: 'AchievementQuintile',
      desiredField: 'achievementQuintile',
      headerTooltip: function (term: string, course: string): string {
        return `Indicates a student's ${course} ${term} term proficiency level based on their percentile value`;
      },
    },
  },
  growthDesiredField: {
    METPROJECTEDGROWTH: {
      humanName: 'Met Projected Growth',
      colName: 'MetProjectedGrowth',
      desiredField: 'metProjectedGrowth',
      headerTooltip: function (course: string, growthTerm: string): string {
        const growTermsArr = PROGRESS_TERMS_TOOLTIPS_MAP[growthTerm];
        return `Indicates if the student met the projected growth for ${course} from the ${growTermsArr[0]} term to the ${growTermsArr[1]} term. Asterisk (*) = Observed Growth Standard Error could be large enough to put the outcome in question, so evaluate in combination with other points of data.`;
      },
    },
    CONDITIONAL_GROWTH_PERCENTILE: { // MAP_CONDITIONAL_GROWTH_PERCENTILE_EMSAC359
      humanName: 'Conditional Growth Percentile',
      colName: 'ConditionalGrowthPercentile',
      desiredField: 'conditionalGrowthPercentile',
      headerTooltip: function (course?: string, growthTerm?: string): string {
        const growTermsArr = PROGRESS_TERMS_TOOLTIPS_MAP[growthTerm];
        return `Calculation that enables you to compare student growth. Measures growth from the ${growTermsArr[0]} term to the ${growTermsArr[1]} term.`;
      },
    },
    GROWTH_QUINTILE: {
      humanName: 'Growth Quintile',
      colName: 'GrowthQuintile',
      desiredField: 'growthQuintile',
      headerTooltip: function (course?: string, growthTerm?: string): string {
        const growTermsArr = PROGRESS_TERMS_TOOLTIPS_MAP[growthTerm];
        return `Indicates a quintile for the ${growTermsArr[0]} term to the ${growTermsArr[1]} term. ConditionalGrowthPercentile: Up to 20th percentile = "Low" 21st to 40th percentile = "LoAvg" 41st to 60th percentile = "Avg" 61st to 80th percentile = "HiAvg" 81st percentile and above = "High"`;
      },
    },
  },
  domainDesiredFields: {
    MAP_GROWTH_DOMAIN_SCORE: {
      humanName: 'RIT Score',
      colName: 'RitScore',
      desiredField: 'goalRitScore',
      headerTooltip: function (domain: string): string {
        return `Indicates ${domain} RIT Score (${MAP_GROWTH_CURR_TERM})`;
      },
    },
    MAP_GROWTH_DOMAIN_ACHIEVE_QUINTILE: {
      humanName: 'Achievement Level',
      colName: 'AchievementLevel',
      desiredField: 'goalAdjective',
      headerTooltip: function (domain: string): string {
        return `Indicates ${domain} Achievement Level (${MAP_GROWTH_CURR_TERM})`;
      },
    },
  },
  lexileFields: {
    maxScore: {
      // MAP_GROWTH_MAX_LEXILE_SCORE_IN_TERM
      humanName: 'Max Score',
      colName: 'MaxScore',
      desiredField: 'lexileScore',
      headerTooltip: function (term:string): string {
        return `Value of the highest Map Growth lexile score achieved within the ${term} term`;
      },
    },
    maxDate: {
      // MAP_GROWTH_MAX_LEXILE_DATE_IN_TERM
      humanName: 'Max Score Date',
      colName: 'MaxDate',
      desiredField: 'testStartDate',
      headerTooltip: function (term:string): string {
        return `Date of the highest Map Growth lexile score achieved within the ${term} term`;
      },
    },
  },
};
