import { HistoryAllModalMoreButtonComponent } from './history-all-modal-more-button.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NvPillModule } from '../../../../../nvps-libraries/design/nv-pill/nv-pill.module';
import { NvDropdownItemModule } from 'Src/nvps-libraries/design/nv-dropdown-item/nv-dropdown-item.module';
import { NvIconButtonModule } from 'Src/nvps-libraries/design/nv-icon-button/nv-icon-button.module';
import { MatMenuModule } from '@angular/material/menu';
import { NvTooltipModule } from 'Src/ng2/shared/nv-tooltip/nv-tooltip.module';

@NgModule({
  imports: [
    CommonModule,
    NvPillModule,
    NvDropdownItemModule,
    NvIconButtonModule,
    MatMenuModule,
    NvTooltipModule,
  ],
  declarations: [HistoryAllModalMoreButtonComponent],
  exports: [HistoryAllModalMoreButtonComponent],
})
export class HistoryAllModalMoreButtonModule { }
