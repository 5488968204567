import { Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IPageHeaderMeta } from './page-header-meta.interface';

@Component({
  selector: 'page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PageHeaderComponent implements OnInit {
  @Input() pageHeaderMeta: IPageHeaderMeta;
  @Input() filterFormControl: FormControl;
  @Input() showSearchBox: boolean = true;
  @Input() pageActionsStyle?: string;
  @Input() isInverted: boolean = false;
  @Input() isSummerSchoolToggleOn: boolean;

  @HostBinding('class.is-inverted') get isInvertedClass () {
    return this.isInverted;
  }

  public placeholder: string;
  public newSkinModeIsOn: boolean;

  constructor () {}

  ngOnInit () {
    this.pageHeaderMeta.textboxPlaceholder = this.pageHeaderMeta.textboxPlaceholder ?? 'Search by name or ID';
  }

  public onClear () {
    this.filterFormControl.setValue('');
  }

  public onClick () {
    this.pageHeaderMeta.action.trigger();
  }
}
