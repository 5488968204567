<ng-container *ngIf="(autoCompleteOptions$ | async) as autoCompleteOptions">
  <nv-form-field label="Employer (optional)">
    <nv-textbox
      [textControl]="formGroup.controls.employer"
      icon="search-large-black"
      placeholder="Enter an employer name"
      [restrictToOptions]="true"
      [hasClearBtn]="true"
      (clearValue)="onClearFieldSelection('employer')"
      [returnObject]="true"
    >
    </nv-textbox>
  </nv-form-field>

  <nv-form-field label="Employer industry">
    <nv-dropdown
      placeholder="Select an employer industry"
      [options]="autoCompleteOptions.employerIndustry"
      (selectOption)="onFieldSelect($event, 'employerIndustry')"
      [returnObject]="true"
    ></nv-dropdown>
  </nv-form-field>

  <nv-form-field label="Job type (optional)">
    <nv-textbox
      [textControl]="formGroup.controls.jobType"
      icon="search-large-black"
      placeholder="Select a job type..."
      [restrictToOptions]="true"
      [autocompleteOptions]="autoCompleteOptions.jobType"
      [emptyStateText]="emptyStateJobType"
      [hasClearBtn]="true"
      (clearValue)="onClearFieldSelection('jobType')"
      [returnObject]="true"
      (selectOption)="onFieldSelect($event, 'jobType')"
      [optionsPredicateCb]="optionsPredicateCb"
      [optionsLimit]="optionsLimit"
    >
      <nv-autocomplete-action [icon]="emptyStateIcon" (click)="onCreateOption('jobType')">
        <span class="autocomplete-text"> Add new job type </span>
      </nv-autocomplete-action>
    </nv-textbox>
  </nv-form-field>

  <nv-form-field label="Job status (optional)">
    <nv-dropdown
      placeholder="Select job status"
      [options]="autoCompleteOptions.jobStatus"
      (selectOption)="onFieldSelect($event, 'jobStatus')"
      [returnObject]="true"
    ></nv-dropdown>
  </nv-form-field>

  <nv-form-field label="Transition support organization (optional)">
    <nv-textbox
      [textControl]="formGroup.controls.transitionSupportOrg"
      icon="search-large-black"
      placeholder="Select a transition support organization..."
      [restrictToOptions]="true"
      [autocompleteOptions]="autoCompleteOptions.transitionSupportOrg"
      [emptyStateText]="emptyStateTransitionSupportOrg"
      [hasClearBtn]="true"
      (clearValue)="onClearFieldSelection('transitionSupportOrg')"
      [returnObject]="true"
      (selectOption)="onFieldSelect($event, 'transitionSupportOrg')"
      [optionsPredicateCb]="optionsPredicateCb"
      [optionsLimit]="optionsLimit"
    >
      <nv-autocomplete-action [icon]="emptyStateIcon" (click)="onCreateOption('transitionSupportOrg')">
        <span class="autocomplete-text"> Add new transition support organization </span>
      </nv-autocomplete-action>
    </nv-textbox>
  </nv-form-field>

  <status-dropdown
    pathCategory="employment"
    [studentPaths]="studentPaths"
    (selectStatus)="onSelectStatus($event)"
    [selectedStatusOption]="selectedStatusOption"
  ></status-dropdown>

  <submit-button
    [isDisabled]="!this.formGroup.valid"
    [confirmationButtonLabel]="action"
    (handleClick)="handleClick()"
    (handleClose)="onEmitCloseModal()"
  ></submit-button>
</ng-container>
