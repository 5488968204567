<button
  matRipple
  class="button"
  ngClass="{{ pillClass }} {{ usesDarkRipple ? 'mat-ripple-inverted' : '' }}"
  [type]="htmlType"
  (click)="handleClick($event)"
>
  <ng-container *ngIf="hasText; else elseTemplate">
    <span>{{ text }}</span>
  </ng-container>
  <ng-template #elseTemplate
    ><span #textElement> <ng-content></ng-content> </span
  ></ng-template>
  <nv-icon
    *ngIf="hasIcon"
    [name]="icon"
    [color]="color"
    [isInverted]="!isInverted"
    nvAriaLabel
    [ariaLabelText]="ariaLabelData.iconAriaLabel"
    ariaTargetElement="svg"
  ></nv-icon>
</button>
