<form [formGroup]="noteForm" autocomplete="off" *ngIf="noteForm" class="ecfik-note-form">
  <nv-form-field label="Categories">
    <div class="ecfik-note-category">ECFIK</div>
  </nv-form-field>

  <!-- note input -->
  <nv-form-field label="Note" class="note">
    <nv-textarea
      [formControl]="noteForm.controls.note"
      [maxCharacters]="600"
      [placeholder]="'Enter a note'"
    ></nv-textarea>
  </nv-form-field>
  <nv-form-field label="Date">
    <nv-date-picker
      [dateControl]="noteForm.controls.logDate"
      startDate="startDate"
      [endLimit]="today"
    ></nv-date-picker>
  </nv-form-field>

  <nv-form-field label="Type">
    <nv-dropdown
      [placeholder]="'Select student or family'"
      [options]="ecfikFormOptionsMap.type.options"
      [selected]="noteForm.controls.type.value"
      (selectOption)="handleDropdownSelect($event, 'type')"
    ></nv-dropdown>
  </nv-form-field>

  <nv-form-field label="Status">
    <nv-dropdown
      [placeholder]="'Select status'"
      [options]="ecfikFormOptionsMap.status.options"
      [selected]="noteForm.controls.status.value"
      (selectOption)="handleDropdownSelect($event, 'status')"
    ></nv-dropdown>
  </nv-form-field>

  <nv-form-field label="Needs (optional)">
    <nv-multi-picker
      [placeholder]="'Select need(s)'"
      [options]="ecfikFormOptionsMap.needs.options"
      [selectedOptions]="selectedNeedsOptions.value"
      (selectedOptionsChange)="handleNeedsOptionClick($event)"
    ></nv-multi-picker>
  </nv-form-field>

  <div class="service-escalation-field">
    <mat-checkbox
      [formControl]="noteForm.controls.serviceReferral"
      class="nv-mat-checkbox"
    >
    Service referral
    </mat-checkbox>
    <nv-icon name="info-small-hover" nvTooltip [tooltipData]="ecfikFormOptionsMap.serviceReferral.description"></nv-icon>
  </div>
</form>