import { RegentsPrepTransferWizardModule } from './transfer/regents-prep-transfer-wizard.module';
import { CommonModule } from '@angular/common';
import { RegentsPrepWizardContainer } from './regent-prep-wizard-container.component';
import { NgModule } from '@angular/core';
import { RegentsPrepWizardRoutingModule } from './regents-prep-wizard-container-routing.module';
import { RegentsPrepNonTransferWizardModule } from './non-transfer/regents-prep-non-transfer-wizard.module';

@NgModule({
  declarations: [RegentsPrepWizardContainer],
  imports: [
    RegentsPrepNonTransferWizardModule,
    RegentsPrepTransferWizardModule,
    RegentsPrepWizardRoutingModule,
    CommonModule,
  ],
  exports: [RegentsPrepWizardContainer],
})
export class RegentsPrepWizardModule { }
