export const studentSupportMap = {
  _id: 'id',
  schoolId: 'School ID',
  assignedOn: 'Assigned on',
  assignedBy: 'Assigned by',
  'assignedBy.userId': 'Assigned by',
  'assignedBy.firstName': 'Assigned by',
  'assignedBy.lastName': 'Assigned by',
  'assignedBy.gafeEmail': 'Assigned by',
  'assignedBy.doeeEmail': 'Assigned by',
  completedAt: 'Completed at',
  markedCompletedBy: 'Marked completed by',
  'markedCompletedBy.userId': 'Marked completed by',
  'markedCompletedBy.firstName': 'Marked completed by',
  'markedCompletedBy.lastName': 'Marked completed by',
  'markedCompletedBy.gafeEmail': 'Marked completed by',
  'markedCompletedBy.doeEmail': 'Marked completed by',
  deletedAt: 'Deleted at',
  deletedBy: 'Deleted by',
  'deletedBy.userId': 'Deleted by',
  'deletedBy.firstName': 'Deleted by',
  'deletedBy.lastName': 'Deleted by',
  'deletedBy.gafeEmail': 'Deleted by',
  'deletedBy.doeEmail': 'Deleted by',
  startsOn: 'Support start date',
  reviewOn: 'Support review date(s)',
  initialPrimaryOutcomeMetrics: 'Initial primary outcome metrics',
  student: 'Student',
  'student.studentId': 'Student ID',
  'student.lastFirst': 'Student name',
  status: 'Status',
  support: 'Support',
  'support.supportId': 'Support ID',
  'support.name': 'Support name',
  'support.category': 'Support category',
  'support.metaData': 'Support details',
  'support.metaData.examSubject': 'Support exam subject',
};
