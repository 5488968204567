import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { NvButtonModule } from '../../../../../projects/shared/nvps-libraries/design/nv-button/nv-button.module';

@Component({
  selector: 'grid-filter-summary',
  standalone: true,
  imports: [CommonModule, NvButtonModule],
  templateUrl: './grid-filter-summary.component.html',
  styleUrl: './grid-filter-summary.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class GridFilterSummaryComponent {
  @Input() filterData: any[];
  @Output() clearAllClick = new EventEmitter<string>();

  public onClearAll (): void {
    this.clearAllClick.emit('clear');
  }
}
