import { ManageCaringAdultComponent } from '../manage-caring-adult/manage-caring-adult.component';
import { ManageCaseloadComponent } from './../manage-caseload/manage-caseload.component';
import { ProgramPointModal } from './program-point-modal.component';
import { NvTextboxModule } from 'Src/nvps-libraries/design/nv-textbox/nv-textbox.module';
import { BaseModalModule } from '../../base-modal.module';
import { AddCaringAdultComponent } from '../add-caring-adult/add-caring-adult.component';
import { NgModule } from '@angular/core';
import { NvFormFieldModule } from 'Src/nvps-libraries/design/nv-form-field/nv-form-field.module';
import { StoreDataService } from 'Src/ng2/shared/services/store-data-service/store-data.service';
import { MatDialogModule } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { NvMultipickerModule } from 'Src/nvps-libraries/design/nv-multi-picker/nv-multi-picker.module';
import { NvButtonModule } from 'projects/shared/nvps-libraries/design/nv-button/nv-button.module';
import { ECFIKManagementService } from 'Src/ng2/school/ecfik-management/ecfik-management.service';
import { NvNoteModule } from 'projects/shared/nvps-libraries/design/nv-note/nv-note.module';
import { RemoveCaringAdultComponent } from '../remove-caring-adult/remove-caring-adult.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ImUserSchool } from 'Src/ng2/shared/services/im-models/im-user/im-user-school';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    ReactiveFormsModule,
    BaseModalModule,
    NvTextboxModule,
    NvFormFieldModule,
    NvMultipickerModule,
    NvButtonModule,
    NvNoteModule,
  ],
  exports: [],
  declarations: [
    ProgramPointModal,
    AddCaringAdultComponent,
    RemoveCaringAdultComponent,
    ManageCaseloadComponent,
    ManageCaringAdultComponent,
  ],
  providers: [StoreDataService, ECFIKManagementService, ImUserSchool],
})

export class ProgramPointModalModule { }
