import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HybridClusterUserAlertComponent } from './hybrid-cluster-user-alert.component';
import { NvNoteModule } from 'projects/shared/nvps-libraries/design/nv-note/nv-note.module';

@NgModule({
  imports: [CommonModule, NvNoteModule],
  declarations: [HybridClusterUserAlertComponent],
  exports: [HybridClusterUserAlertComponent],
  providers: [],
})
export class HybridClusterUserAlertModule {}
