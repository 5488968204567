import { IExperience } from './../../../../../shared/typings/interfaces/experiences.interface';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { IDropdownOption } from '../../../../../../../projects/shared/nvps-libraries/design/interfaces/design-library.interface';

export type TExperienceMoreButtonActionMode = 'EDIT' | 'DELETE';
export interface IExperienceMoreButtonEmitData {
  action: TExperienceMoreButtonActionMode,
  experience: IExperience;
}

@Component({
  selector: 'experience-more-button-component',
  templateUrl: './experience-more-button.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class ExperienceMoreButtonComponent implements OnInit {
  @Input() input: any;
  @Output() output = new EventEmitter();
  showMoreButton: boolean;
  public buttonClass: string;
  public buttonTooltip: string = '';
  public buttonColor: string;
  public isStatic: boolean;
  options: IDropdownOption[] = [
    { key: 'edit', human: 'Edit' },
    { key: 'delete', human: 'Delete' },
  ];

  ngOnInit () {
    this.showMoreButton = this.input.column.meta.showMoreButton;
    if (this.showMoreButton) this.setButtonProperties();
  }

  setButtonProperties () : void {
    this.isStatic = this.input.column.meta.isStatic;
    this.buttonClass = this.isStatic ? 'buttonDisabled' : '';
    this.buttonColor = this.isStatic ? 'grey' : 'blue';
    if (this.isStatic) this.buttonTooltip = this.input.column.meta.buttonTooltip;
  }

  onSelect (e: string): void {
    let action: TExperienceMoreButtonActionMode;
    switch (e) {
      case 'edit': {
        action = 'EDIT';
        break;
      }
      case 'delete': {
        action = 'DELETE';
        break;
      }
    }
    const emitData: IExperienceMoreButtonEmitData = {
      action,
      experience: this.input.column.meta.experience,
    };
    this.output.emit(emitData);
  }
}
