import { IFetchStudentParams, StudentSet } from './../../../shared/services/student-set/student-set.service';
import { ModalsService } from 'Src/ng2/shared/modals/modals.service';
import { HttpResponse } from '@angular/common/http';
import { UtilitiesService } from 'Src/ng2/shared/services/utilities/utilities.service';
import { BackgroundJob } from 'Src/ng2/shared/services/background-job/background-job.service';
import { ApiService } from 'Src/ng2/shared/services/api-service/api-service';
import { Inject, Injectable, forwardRef } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, from, iif, of } from 'rxjs';
import { switchMap, withLatestFrom, mergeMap, map, tap, filter } from 'rxjs/operators';
import * as sdcActions from '../../actions/sdc-actions/sdc-actions';
import { CREATE_STUDENT_PATH_SUCCESS, CREATE_STUDENT_SUPPORT_SUCCESS, OTHER_SINGLE_STUDENT_UPDATE_SUCCESS, UPDATE_STUDENT_PATH_SUCCESS, UPDATE_STUDENT_SUCCESS, UPDATE_STUDENT_SUPPORT_SUCCESS } from '../../actions';
import { IStudent } from 'Src/ng2/shared/typings/interfaces/student.interface';
import { IProjection } from 'Src/ng2/shared/services/student-fetch/student-fetch.service';

@Injectable()
export class SdcEffects {
  constructor (
    private actions$: Actions,
    private store: Store<any>,
    private apiService: ApiService,
    private backgroundJob: BackgroundJob,
    private utilService: UtilitiesService,
    private studentSet: StudentSet,
    @Inject(forwardRef(() => ModalsService)) public modalsService: ModalsService,
  ) {}

  loadSdcStudents$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<any>(sdcActions.LOAD_SDC_STUDENTS),
      withLatestFrom(this.store),
      switchMap(([{ payload }, { PORTAL_STORE }]) => {
        const { schoolId, columnKeys, joins } = payload;
        const { loadedProjection = {}, joins: loadedJoins = [] } = PORTAL_STORE.sdcState;
        return this.fetchSdcStudents({ schoolId, columnKeys, joins, loadedJoins, loadedProjection })
          .pipe(
            map((data: any) => ({
              ...data,
              columnKeys,
              students: data.students.reduce((acc, student) => {
                acc[student._id] = student;
                return acc;
              }, {}),
              flattenedStudents: this.flattenStudents(data.students, PORTAL_STORE.school.school, columnKeys)
                .reduce((acc, student) => {
                  acc[student._id] = student;
                  return acc;
                }, {}),
            })));
      }),
      mergeMap((data: any) => ([new sdcActions.LoadSdcStudentsSuccess(data)])),
    );
  });

  refreshSdcStudent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<any>(
        CREATE_STUDENT_PATH_SUCCESS,
        CREATE_STUDENT_SUPPORT_SUCCESS,
        OTHER_SINGLE_STUDENT_UPDATE_SUCCESS,
        UPDATE_STUDENT_PATH_SUCCESS,
        UPDATE_STUDENT_SUCCESS,
        UPDATE_STUDENT_SUPPORT_SUCCESS
      ),
      withLatestFrom(this.store),
      switchMap(([{ type, payload }, { PORTAL_STORE }]) => {
        const { sdcState: { columnKeys, loaded, loading }, school: { school: { _id: schoolId } } } = PORTAL_STORE;
        if (!loaded && !loading) return EMPTY;
        let studentId;
        if (type === UPDATE_STUDENT_SUCCESS) {
          studentId = payload._id;
        }
        if (
          type === CREATE_STUDENT_SUPPORT_SUCCESS ||
          type === UPDATE_STUDENT_SUPPORT_SUCCESS) 
        {
          studentId = payload.student.studentId;
        }
        if (
          type === CREATE_STUDENT_PATH_SUCCESS ||
          type === UPDATE_STUDENT_PATH_SUCCESS ||
          type === OTHER_SINGLE_STUDENT_UPDATE_SUCCESS
        ) {
          studentId = payload.studentId;
        }
        return this.fetchSdcStudents({ schoolId, columnKeys, whereFilter: { _id: studentId } });
      }),
      switchMap((data: { noNewData: boolean } | { students: IStudent[], projection: IProjection, joins: string[] })=> {
        const { noNewData, students } = data as any;
        const studentToUpdateNoRequest = !noNewData && students && students[0];
        return iif(
          ()=> studentToUpdateNoRequest,
          of(new sdcActions.UpdateSdcStudentNoRequest(studentToUpdateNoRequest)),
          EMPTY,
        );
      })
    );
  });  

  updateSdcStudentNoRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<any>(sdcActions.UPDATE_SDC_STUDENT_NO_REQUEST),
      withLatestFrom(this.store),
      switchMap(([action, store]) => {
        const { columnKeys } = store.PORTAL_STORE.sdcState;
        const { school } = store.PORTAL_STORE.school;
        const { _id: studentId } = action.payload;
        const storeStudent = store.PORTAL_STORE.sdcState.students ? store.PORTAL_STORE.sdcState.students[studentId] : {};
        const student = { ...storeStudent, ...action.payload };
        const patches = {
          students: action.payload,
          flattenedStudents: this.flattenStudents([student], school, columnKeys)
            .reduce((acc, student) => {
              acc[student._id] = student;
              return acc;
            }, {}),
        };
        return [new sdcActions.UpdateSdcStudentsSuccess(patches)];
      }),
    );
  });

    updateSdcStudentsNoRequest$ = createEffect(() => {
      return this.actions$.pipe(
        ofType<any>(sdcActions.UPDATE_SDC_STUDENTS_NO_REQUEST),
        withLatestFrom(this.store),
        switchMap(([action, store]) => {
          const { patches } = (action as any).payload;
          const { students, columnKeys } = store.PORTAL_STORE.sdcState;
          const { school } = store.PORTAL_STORE.school;
          const patchedStudentEntities = this.getUpdatedStudentsFromPatches(students, patches);
          return of({
            students: patchedStudentEntities,
            flattenedStudents: this.flattenStudents(patchedStudentEntities, school, columnKeys)
              .reduce((acc, student) => {
                acc[student._id] = student;
                return acc;
              }, {}),
          });
        }),
        mergeMap((patches) => ([new sdcActions.UpdateSdcStudentsSuccess(patches)])),
      );
    });

  updateSdcStudents$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<any>(sdcActions.UPDATE_SDC_STUDENTS),
      switchMap(({ payload }) => {
        const { patches } = payload;
        const formattedPatches = this.formatPatches(patches);
        return this.apiService.patchSdcStudents(formattedPatches);
      }),
      tap((response) => this.fireDeterminateSpinner(response)),
      withLatestFrom(this.actions$, this.store),
      map(([_, action, store]) => {
        const { patches } = (action as any).payload;
        const { sdcState: sdcStore, school: schoolStore } = store.PORTAL_STORE;
        return this.getSdcStudentsFromPatches(patches, { sdcStore, schoolStore });
      }),
      mergeMap((patches) => ([new sdcActions.UpdateSdcStudentsSuccess(patches)])),
    );
  });

  updateSdcStudent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<any>(sdcActions.UPDATE_SDC_STUDENT),
      switchMap(({ payload }) => {
        return this.apiService.patchSingleStudent(payload);
      }),
      withLatestFrom(this.store),
      map(([student, store]) => {
        const { sdcState: { columnKeys }, school } = store.PORTAL_STORE;
        return {
          student,
          flattenedStudent: this.flattenStudents([student], school, columnKeys)
            .reduce((acc, student) => {
              acc[student._id] = student;
              return acc;
            }, {}),
        };
      }),
      mergeMap((updatedStudent) => ([new sdcActions.UpdateSdcStudentSuccess(updatedStudent)])),
    );
  });

  private getSdcStudentsFromPatches (patches, { sdcStore, schoolStore }) {
    const { students, columnKeys } = sdcStore;
    const { school } = schoolStore;
    const formattedPatches = this.formatPatches(patches);
    const patchedStudentEntities = this.getUpdatedStudentsFromPatches(students, formattedPatches);
    return {
      students: patchedStudentEntities,
      flattenedStudents: this.flattenStudents(patchedStudentEntities, school, columnKeys)
        .reduce((acc, student) => {
          acc[student._id] = student;
          return acc;
        }, {}),
    };
  }

  private formatPatches (patches) {
    return patches.map((patch: any) => {
      return {
        studentIds: patch._ids,
        _ids: patch._ids,
        patch: this.utilService.setFieldByPath({}, patch.path, patch.newValue),
      };
    });
  }

  private getUpdatedStudentsFromPatches (students, formattedPatches) {
    return formattedPatches.reduce((acc, { patch, _ids }) => {
      _ids.forEach(_id => {
        // if we have already updated the student, let's pull them from the acc and not the store to avoid accidently losing the initial patch
        const student = acc[_id]
          ? Object.assign({}, acc[_id])
          : students
            ? Object.assign({}, students[_id])
            : {};
        this.utilService.patchObject(student, patch);
        // patchObject is mutating the array. Order matters here.
        acc[_id] = student;
      });
      return acc;
    }, { });
  }

  private fetchSdcStudents ({ schoolId, columnKeys, joins, loadedJoins, loadedProjection, whereFilter = null }: IFetchStudentParams) {
    return from(this.studentSet.fetchStudents<IStudent>({ schoolId, columnKeys, joins, loadedJoins, loadedProjection, whereFilter }));
  }

  private flattenStudents (students, school, columnKeys) {
    return this.studentSet.flattenStudents(students, school, columnKeys);
  }

  private fireDeterminateSpinner (response: HttpResponse<any>) {
    const jobId = (response as any).headers.get('nv-background-jobs');
    const backgroundJobSubject = (this.backgroundJob as any)(jobId).subject();
    this.modalsService.openBackgroundJobSpinnerModal({ backgroundJobSubject, title: 'Updating Students' });
  }
}
