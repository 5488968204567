export const SorterColumnDataType = {
  STRING: 'STRING',
  BOOLEAN_YES_NO: 'BOOLEAN_YES_NO',
  NUMERIC: 'NUMERIC',
  ENUM: 'ENUM',
  ARRAY: 'ARRAY',
  USER_MINI: 'USER_MINI',
  RADIO: 'RADIO',
  REGENTS_ADMIN: 'REGENTS_ADMIN',
};

export type TSorterColumnDataType =
  | 'STRING'
  | 'BOOLEAN_YES_NO'
  | 'NUMERIC'
  | 'ENUM'
  | 'ARRAY'
  | 'USER_MINI'
  | 'RADIO'
  | 'REGENTS_ADMIN';
