interface IStringTMap<T> {
  [key: string]: T;
}

export const OnTrackStatus: IStringTMap<string> = {
  ON: 'On Track',
  OFF: 'Needs Support',
  ALMOST: 'Almost on Track',
  COLLEGE: 'College Ready',
  NOT_ELIGIBLE: 'Not Eligible',
};
