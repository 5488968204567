<base-modal class="base-modal" [title]="title" [itemCount]="itemCount" [itemType]="itemType">
  <nv-note [size]="'small'" [priority]="'info'">
    {{ infoText }}
  </nv-note>
  <div class="row-group-container">
    <div *ngFor="let group of groupData | keyvalue" class="row-group">
    {{ getFormattedRowText(group) }}
    </div>
  </div>
  <div class="dialog-actions">
    <nv-button type="secondary" (clickButton)="onCancel()">Cancel</nv-button>
    <nv-button (clickButton)="onClickPrimaryBtn()">Apply</nv-button>
  </div>
</base-modal>
