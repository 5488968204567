<!-- part II - Create a User Form -->
<ng-container *ngIf="isCreateOrUpdateMode">
  <ng-container  >
    <!-- <ng-container *ngIf="(userDetail$ | async) as userDetail"> -->
    <div class="base-modal-shell school-user-modal-shell">
      <ng-container *ngIf="v4ModeIsOn; else oldSkin">
        <div class="dialog-title">
          <h1>{{ title }}</h1>
          <nv-icon-button [name]="iconName" (clickButton)="onCancel()"></nv-icon-button>
        </div>
      </ng-container>
      <!--TODO: v4 cleanup: remove oldSkin-->
      <ng-template #oldSkin>
        <div class="dialog-title">
          <nv-icon-button [name]="iconName" (clickButton)="onCancel()"></nv-icon-button>
          <h1>{{ title }}</h1>
          <div></div>
        </div>
      </ng-template>
      <ng-container *ngIf="userBasicForm">
        <mat-dialog-content>
          <!-- 1. first name, last name input -->
          <div class="people-name-fields">
            <nv-form-field
              label="First name"
              [isInvalid]="userBasicForm.controls['firstName'].invalid"
              [showInvalid]="userBasicForm.controls['firstName'].invalid && !userBasicForm.controls['firstName'].pristine"
            >
              <nv-textbox
                placeholder="First name"
                icon="person-large-black"
                [hasClearBtn]="true"
                [showInvalid]="true"
                [showInvalidOnPristine]="false"
                [textControl]="userBasicForm.controls['firstName']"
                (clearValue)="onClearInput('firstName')"
              ></nv-textbox>
            </nv-form-field>
            <nv-form-field
              label="Last name"
              [isInvalid]="userBasicForm.controls['lastName'].invalid"
              [showInvalid]="userBasicForm.controls['lastName'].invalid && !userBasicForm.controls['lastName'].pristine"
            >
              <nv-textbox
                placeholder="Last name"
                icon="person-large-black"
                [hasClearBtn]="true"
                [showInvalid]="true"
                [showInvalidOnPristine]="false"
                [textControl]="userBasicForm.controls['lastName']"
                (clearValue)="onClearInput('lastName')"
              ></nv-textbox>
            </nv-form-field>
          </div>
            <!-- check for doe employee -->
          <div class="checkbox-field-container" *ngIf="isTrustedAtSchoolLevel || isCreateOnlyMode">
            <mat-checkbox
              class="nv-mat-checkbox"
              disableRipple
              [checked]="userBasicForm.controls['isDOEemployee'].value"
              (change)="onSelectEmployeeType($event.checked)"
              > This user is a DOE employee
            </mat-checkbox>
            <nv-icon name="info-small-hover" nvTooltip [tooltipData]="'DOE Employees should have a valid @schools.nyc.gov email address'"></nv-icon>
          </div>
          <!-- 2. DOE email input. Only show: if the user is a DOE employee -->
          <nv-form-field
          *ngIf="isDOEemployee"
            [label]="'DOE email'"
            [isInvalid]="userBasicForm.controls['doeEmail'].invalid"
            [showInvalid]="
              userBasicForm.controls['doeEmail'].invalid && !userBasicForm.controls['doeEmail'].pristine
            "
          >
            <nv-textbox
              [placeholder]="'e.g. username@schools.nyc.gov'"
              icon="email-large-black"
              [hasClearBtn]="true"
              [showInvalid]="true"
              [showInvalidOnPristine]="false"
              [textControl]="userBasicForm.controls['doeEmail']"
              (clearValue)="onClearInput('doeEmail')"
            ></nv-textbox>
            <!-- ERROR MESSAGES: for DOE email input -->
            <ng-container *ngIf="userBasicForm.controls['doeEmail'].errors as reqEmailErrs">
              <ng-container *ngIf="reqEmailErrs.domain || reqEmailErrs.email">
                <nv-note class="email-hint" size="small" priority="danger"
                  >Enter a valid DOE email that ends with "&#64;schools.nyc.gov"</nv-note>
              </ng-container>
            </ng-container>
          </nv-form-field>

          <!-- 3. Google email input. It is optional for DOE employee, required for other users -->
          <ng-container>
            <nv-form-field
              [label]= "isDOEemployee ? 'Google email (optional)' : 'Google email' "
              [isInvalid]="userBasicForm.controls['gafeEmail'].invalid"
              [showInvalid]="userBasicForm.controls['gafeEmail'].invalid && !userBasicForm.controls['gafeEmail'].pristine"
            >
              <nv-textbox
                [placeholder]="'e.g. username@myschool.org'"
                icon="email-large-black"
                [hasClearBtn]="true"
                [showInvalid]="true"
                [showInvalidOnPristine]="false"
                [textControl]="userBasicForm.controls['gafeEmail']"
                (clearValue)="onClearInput('gafeEmail')"
              ></nv-textbox>

              <!-- ERROR MESSAGES: for google email input -->
              <ng-container *ngIf="userBasicForm.controls['gafeEmail'].errors as optEmailErrs">
                <ng-container *ngIf="optEmailErrs.domain || optEmailErrs.email">
                  <nv-note class="email-hint" size="small" priority="danger">Enter a valid email </nv-note>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="userBasicForm.controls['gafeEmail'].errors as optEmailErrs">
                <ng-container *ngIf="optEmailErrs.schools">
                  <nv-note class="email-hint" size="small" priority="danger">Enter "&#64;schools.nyc.gov" emails in the DOE Email field</nv-note>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="userBasicForm.controls['gafeEmail'].errors as optEmailErrs">
                <ng-container *ngIf="optEmailErrs.gmail">
                  <nv-note class="email-hint" size="small" priority="danger">Enter a valid Google email that does not contain "&#64;gmail.com"</nv-note>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="userBasicForm.controls['gafeEmail'].errors as optEmailErrs">
                <ng-container *ngIf="optEmailErrs.googlemail">
                  <nv-note class="email-hint" size="small" priority="danger">Enter a valid Google email that does not contain "&#64;googlemail.com"</nv-note>
                </ng-container>
              </ng-container>
            </nv-form-field>
          </ng-container>

          <!-- 4. optional role input -->
          <nv-form-field label="Job role (optional)" class="dropdown-options">
            <nv-dropdown
              [options]="jobRoles"
              [selected]="selectedJobRoleKey"
              (selectOption)="onSelectJobRole($event)"
              placeholder="Select job role"
            ></nv-dropdown>
          </nv-form-field>

          <!-- 5. access level dropdown -->
          <nv-form-field label="Access level" class="dropdown-options">
            <nv-dropdown
              [options]="accessLevels"
              [selected]="selectedAccessLevelKey"
              (selectOption)="onSelectAccessLevel($event)"
              placeholder="Select access level"
            ></nv-dropdown>
          </nv-form-field>

          <!-- Caseload Access level message -->
          <ng-container *ngIf="userBasicForm.controls['delegatedRole'].value === 'edit_caseload'">
            <nv-note size="small" priority="warning">
              <span>Learn more about how to modify a user's caseload <a href="{{manageUsersLink}}" target="_blank">here</a> </span>
            </nv-note>
          </ng-container>

          <!-- Error msg for when a dupe record found in db based on email -->
          <ng-container *ngIf="emailDupesErrMsg">
            <ng-container *ngIf="needsHelpLink; else shortTemplate">
              <nv-note size="small" priority="danger">
                <span> {{ emailDupesErrMsg }} Please <a href="javascript:void(0);" (click)="getHelp()">contact support</a>. </span>
              </nv-note>
            </ng-container>
            <ng-template #shortTemplate>
              <nv-note size="small" priority="danger">
                <span> {{ emailDupesErrMsg }} </span>
              </nv-note>
            </ng-template>
          </ng-container>

          <!-- Error msg for all other errors -->
          <ng-container *ngIf="generalErrMsg">
            <nv-note size="small" priority="danger">
              <span> {{ generalErrMsg }} </span>
            </nv-note>
          </ng-container>
        </mat-dialog-content>

        <!-- Cancel / Create buttons -->
        <div class="dialog-actions">
          <nv-button type="secondary" (clickButton)="onCancel()">Cancel</nv-button>
          <nv-button (clickButton)="onClickPrimaryBtn()" [disabled]="userBasicForm.pristine || userBasicForm.invalid">
            {{primaryBtnName}}
          </nv-button>
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-container>

<!-- part III - Confirm trusted status Screeen: Only show for non DOE employees -->
<ng-container *ngIf="canProceedToConfirm">
  <ng-container  >
    <div class="base-modal-shell school-user-modal-shell">
      <div class="dialog-title">
        <nv-icon-button name="arrow-left-default" (clickButton)="onBack()"></nv-icon-button>
        <h1>Confirm trusted status</h1>
      </div>
        <ng-container >
          <mat-dialog-content>
            <p>By clicking confirm, you acknowledge that
              <b> {{userBasicForm.controls['firstName'].value}} {{userBasicForm.controls['lastName'].value}} </b>
              has received the necessary authorization from the New York City Department of Education to access
              confidential student data.
            </p>
          </mat-dialog-content>
        </ng-container>
        <div class="dialog-actions">
          <nv-button type="secondary" (clickButton)="onCancelCreateUser()">Cancel</nv-button>
          <nv-button (clickButton)="onConfirmTrustedUser()">Confirm</nv-button>
        </div>
    </div>
  </ng-container>
</ng-container>
