import { NvTooltipModule } from './../../nv-tooltip/nv-tooltip.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { ApiService } from 'Src/ng2/shared/services/api-service/api-service';
import { ImCourseDiff } from 'Src/ng2/shared/services/im-models/im-course-diff';
import { ImGapPlan } from 'Src/ng2/shared/services/im-models/im-gap-plan';
import { ImSchool } from './../../services/im-models/im-school';
import { ModalsService } from './../../modals/modals.service';
import { UtilitiesService } from './../../services/utilities/utilities.service';
import { ImStudentCreditGaps } from './../../services/im-models/im-student-credit-gaps/im-student-credit-gaps';
import { NgModule } from '@angular/core';
import { FindACourseTable } from './find-a-course-table.component';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
  imports: [
    MatInputModule,
    MatSelectModule,
    CommonModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    NvTooltipModule,
  ],
  exports: [FindACourseTable],
  declarations: [FindACourseTable],
  providers: [
    ImStudentCreditGaps,
    UtilitiesService,
    ModalsService,
    ImSchool,
    ImGapPlan,
    ImCourseDiff,
    ApiService,
  ],
})
export class FindACourseTableModule { }
