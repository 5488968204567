import { MatDialogModule } from '@angular/material/dialog';
import { NgModule } from '@angular/core';
import { ErrorModalComponent } from './error-modal.component';
import { NvButtonModule } from 'projects/shared/nvps-libraries/design/nv-button/nv-button.module';
import { NvIconModule } from '../../../../../projects/shared/nvps-libraries/design/nv-icon/nv-icon.module';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [ErrorModalComponent],
  imports: [
    MatDialogModule,
    NvButtonModule,
    NvIconModule,
    CommonModule,
  ],
  exports: [ErrorModalComponent],
})
export class ErrorModalModule {}
