import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { findLast, includes } from 'lodash';
import { RouterExitService } from 'Src/ng2/routing/services/router-exit.service';

export interface IStateGoOpts {
  url? : string;
  queryParams? : any;
  name: string;
  params: object;
  callbackFunction?: () => {};
  relativeTo?: ActivatedRoute;
  isExpandEd: boolean;
  isCUNY: boolean;
  logout?: boolean;
}

export interface IUrlNavigateOpts {
  url: string;
  queryParams?: { [key: string]: string | number };
  callbackFunction?(): any;
  isExpandEd?: boolean;
  isCUNY?: boolean;
  logout?: boolean;
}

export interface ICurrentGrouping {
  grouping: {
    columns: object;
    listType: string;
    sections: Array<{}>;
  };
  groupHumanName: string;
}

/* istanbul ignore next */
@Injectable()
export class PrevStateService {
  // class properties
  readonly INVALID_URL_PATH_INCLUSIONS = [
    // appended forward slash to make check more specific, as we now have routes such as ../student-level(JJ)
    '/student/',
    '/profile',
    'supports/new', // prevent ssv from returning to the create new support stepper view
  ];

  constructor (private routerExitService: RouterExitService) {
    //
  }

  isUrlValid (url: string): boolean {
    const urlIsValid = this.INVALID_URL_PATH_INCLUSIONS.every(urlString => !includes(url, urlString));
    return urlIsValid;
  }

  /**
   * Should be used in current route to get previous url options (url & queryParams)
   * Result should be passed to <exit-view>
   * <exit-view> can then use result to go back to previous url (jchu)
   */
  getPrevUrlNavigateOpts (opts: { designatedPrevUrls?: string[]; fallbackUrl?: string } = {}): IUrlNavigateOpts {
    const _fallbackUrl = opts.fallbackUrl || '/home';
    const _fallbackUrlOpts = { url: _fallbackUrl };

    if (opts && opts.designatedPrevUrls) {
      const prevUrlStack = this.routerExitService.previousUrlStack$.value;
      const designatedPrevUrlOpts: IUrlNavigateOpts | undefined = findLast(prevUrlStack, prevUrlOpts =>
        opts.designatedPrevUrls.some((designatedPrevUrl) => prevUrlOpts.url.includes(designatedPrevUrl))
        ,
      );
      return designatedPrevUrlOpts || _fallbackUrlOpts;
    }

    const prevUrl = this.routerExitService.previousUrl$.value;
    if (!prevUrl || (prevUrl && prevUrl.url.includes('callback'))) {
      return _fallbackUrlOpts;
    }

    return prevUrl;
  }
}
