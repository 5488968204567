<base-modal class="base-modal student-paths-modal" [title]="action" [itemCount]="itemCount" [itemType]="itemType"
  [isProfileMode]="isProfileMode">
  <nv-form-field label="Pathway" *ngIf="isProfileMode">
    <nv-dropdown placeholder="Select pathway" [options]="pathCategoryOptions"
      (selectOption)="onPathCategorySelect($event)"></nv-dropdown>
  </nv-form-field>

  <ng-container [ngSwitch]="selectedPathCategory">
    <career-training-program-form 
      *ngSwitchCase="PATH_CATEGORY_KEYS.CAREER_TRAINING_PROGRAM" 
      [schoolId]="schoolId"
      [studentPaths]="studentPaths"
      (submit)="submit($event)"
      (close)="close()"
      [action]="action"
      >
    </career-training-program-form>

    <certificate-program-form 
      *ngSwitchCase="PATH_CATEGORY_KEYS.CERTIFICATE_PROGRAM" 
      [schoolId]="schoolId"
      [studentPaths]="studentPaths"
      (submit)="submit($event)"
      (close)="close()"
      [action]="action"
      >
    </certificate-program-form>

    <college-form 
      *ngSwitchCase="PATH_CATEGORY_KEYS.COLLEGE" 
      [schoolId]="schoolId"
      [isProfileMode]="isProfileMode"
      [isEditMode]="isEditMode"
      [studentPaths]="studentPaths"
      (submit)="submit($event)"
      (close)="close()"
      [action]="action"
      >
    </college-form>

    <employment-form 
      *ngSwitchCase="PATH_CATEGORY_KEYS.EMPLOYMENT" 
      [schoolId]="schoolId"
      [studentPaths]="studentPaths"
      (submit)="submit($event)"
      (close)="close()"
      [action]="action"
      >
    </employment-form>

    <military-form 
      *ngSwitchCase="PATH_CATEGORY_KEYS.MILITARY" 
      [schoolId]="schoolId"
      [studentPaths]="studentPaths"
      (submit)="submit($event)"
      (close)="close()"
      [action]="action"
      >
    </military-form>

    <other-form 
      *ngSwitchCase="PATH_CATEGORY_KEYS.OTHER" 
      [schoolId]="schoolId"
      [studentPaths]="studentPaths"
      (submit)="submit($event)"
      (close)="close()"
      [action]="action"
      >
    </other-form>

    <public-service-form 
      *ngSwitchCase="PATH_CATEGORY_KEYS.PUBLIC_SERVICE" 
      [schoolId]="schoolId"
      [studentPaths]="studentPaths"
      (submit)="submit($event)"
      (close)="close()"
      [action]="action"
      >
    </public-service-form>

    <ng-container *ngSwitchDefault>
      <!-- <h1> DEFUALT </h1> -->
      <mat-dialog-actions class="dialog-actions">
        <nv-button type="secondary" (clickButton)="close()">
          Cancel
        </nv-button>
      </mat-dialog-actions>
    </ng-container>
  </ng-container>
</base-modal>
