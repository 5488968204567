import { NextGraduationDate } from 'Src/ng2/shared/constants/graduation-date.constant';
import { CurrentSchoolYear } from 'Src/ng2/shared/constants/current-school-year.constant';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';

export type TValidPriorityGroupKeys =
  | 'PLANNED_JAN_MARCH'
  | 'PLANNED_JUNE'
  | 'PLANNED_AUG'
  | 'PLANNED_BEYOND_AUG'
  | 'PLANNED_NON_GRAD'
  | 'PLAN_INCOMPLETE'
  | 'JUNIORS'
  | 'SOPHOMORES'
  | 'FRESHMAN'
  | 'NONE';

export type TValidStudentYearFilters = 1 | 2 | 3 | 4;

export interface IPriorityGroupDefinition {
  key: TValidPriorityGroupKeys;
  priorityVal: number;
  priorityLabel: string;
  studentPriorityGroupingValue: string;
  studentYearFilter: TValidStudentYearFilters;
  studentIds?: string[];
}
@Injectable()
export class CreditGapsPriorityGroupingsService {
  private _priorityGroupings: IPriorityGroupDefinition[];

  private currentSchoolYearEnd = Number(CurrentSchoolYear.ENDFULL);
  private juniorCohort = this.currentSchoolYearEnd + 1;
  private sophomoreCohort = this.currentSchoolYearEnd + 2;
  private freshmanCohort = this.currentSchoolYearEnd + 3;

  private priorityGroupDefinitions = [
    {
      key: 'PLANNED_JAN_MARCH',
      priorityVal: null,
      priorityLabel: `Planned January or March ${this.currentSchoolYearEnd} Grads with unaddressed credit gaps.`,
      studentPriorityGroupingValue:
        'Planned Jan/March ' + this.currentSchoolYearEnd + ' Grad w/ Unaddressed Credit Gaps',
      studentYearFilter: 4,
    } as IPriorityGroupDefinition,
    {
      key: 'PLANNED_JUNE',
      priorityVal: null,
      priorityLabel: `Planned June ${this.currentSchoolYearEnd} Grads with unaddressed credit gaps.`,
      studentPriorityGroupingValue:
        'Planned June/March ' + this.currentSchoolYearEnd + ' Grad w/ Unaddressed Credit Gaps',
      studentYearFilter: 4,
    } as IPriorityGroupDefinition,
    {
      key: 'PLANNED_AUG',
      priorityVal: null,
      priorityLabel: `Planned Aug ${this.currentSchoolYearEnd} Grads with unaddressed credit gaps.`,
      studentPriorityGroupingValue: 'Planned Aug ' + this.currentSchoolYearEnd + ' Grad w/ Unaddressed Credit Gaps',
      studentYearFilter: 4,
    } as IPriorityGroupDefinition,
    {
      key: 'PLANNED_BEYOND_AUG',
      priorityVal: null,
      priorityLabel: `Students planned to graduate beyond Aug ${
        this.currentSchoolYearEnd
      } with unaddressed credit gaps.`,
      studentPriorityGroupingValue:
        'Planned Beyond Aug ' + this.currentSchoolYearEnd + ' Grad w/ Unaddressed Credit Gaps',
      studentYearFilter: 4,
    } as IPriorityGroupDefinition,
    {
      key: 'PLANNED_NON_GRAD',
      priorityVal: null,
      priorityLabel: 'Planned Non-Graduates with unaddressed credit gaps.',
      studentPriorityGroupingValue: 'Planned Non-Grad w/ Unaddressed Credit Gaps',
      studentYearFilter: 4,
    } as IPriorityGroupDefinition,
    {
      key: 'PLAN_INCOMPLETE',
      priorityVal: null,
      priorityLabel: 'Seniors with incomplete grad plans with unaddressed credit gaps.',
      studentPriorityGroupingValue: 'No Grad Plan w/ Unaddressed Credit Gaps',
      studentYearFilter: 4,
    } as IPriorityGroupDefinition,
    {
      key: 'JUNIORS',
      priorityVal: null,
      priorityLabel: 'Active class of ' + this.juniorCohort + ' with unaddressed credit gaps.',
      studentPriorityGroupingValue: this.juniorCohort + ' w/ Unaddressed Credit Gaps',
      studentYearFilter: 3,
      studentIds: [],
    } as IPriorityGroupDefinition,
    {
      key: 'SOPHOMORES',
      priorityVal: null,
      priorityLabel: `Active class ${this.sophomoreCohort} students with unaddressed credit gaps.`,
      studentPriorityGroupingValue: this.sophomoreCohort + ' w/ Unaddressed Credit Gaps',
      studentYearFilter: 2,
    } as IPriorityGroupDefinition,
    {
      key: 'FRESHMAN',
      priorityVal: null,
      priorityLabel: `Active class of ${this.freshmanCohort} students with unaddressed credit gaps.`,
      studentPriorityGroupingValue: `${this.freshmanCohort} w/ Unaddressed Credit Gaps`,
      studentYearFilter: 1,
    } as IPriorityGroupDefinition,
    {
      key: 'NONE',
      priorityVal: null,
      priorityLabel: 'Students with no unaddressed Credit gaps.',
      studentPriorityGroupingValue: 'No Unaddressed Credit Gaps',
      studentYearFilter: null,
    } as IPriorityGroupDefinition,
  ];

  private setCreditGapPriorityGroupingDetails () {
    this._priorityGroupings = [];
    // figure out priority numbers
    const nextGradDateMonthOrder = NextGraduationDate.monthOrder;
    const janIsNext = nextGradDateMonthOrder === 1;
    const marchIsNext = nextGradDateMonthOrder === 2;
    const juneIsNext = nextGradDateMonthOrder === 3;
    const augIsNext = nextGradDateMonthOrder === 4;

    let groupings;
    if (janIsNext || marchIsNext) groupings = new Array(10);
    if (juneIsNext) groupings = new Array(9);
    if (augIsNext) groupings = new Array(8);

    let startingIndex;
    if (janIsNext || marchIsNext) startingIndex = 0;
    if (juneIsNext) startingIndex = 1;
    if (augIsNext) startingIndex = 2;

    _.forEach(groupings, (group, i) => {
      const groupIndex = startingIndex + i;
      this._priorityGroupings.push(this.priorityGroupDefinitions[groupIndex]);
      // set priority val prop
      const priorityVal = i + 1;
      this._priorityGroupings[i].priorityVal = priorityVal;
      // overwrite studentPriorityGroupingValue to include priority val
      this._priorityGroupings[i].studentPriorityGroupingValue =
        priorityVal + '. ' + this._priorityGroupings[i].studentPriorityGroupingValue;
    });
  }

  get priorityGroupings (): IPriorityGroupDefinition[] {
    if (!this._priorityGroupings) this.setCreditGapPriorityGroupingDetails();
    return this._priorityGroupings;
  }

  private _getPriorityGroupByKey (key: TValidPriorityGroupKeys): IPriorityGroupDefinition {
    const priorityGroupings = this.priorityGroupings;
    return _.find(priorityGroupings, { key });
  }

  public getStudentValueForPriorityGroupByKey (key: TValidPriorityGroupKeys): string {
    const prioirtyGroup = this._getPriorityGroupByKey(key);
    return prioirtyGroup.studentPriorityGroupingValue;
  }

  // Jaime, the method below CreditGapsPriorityGroupingsService.getPriorityGroupsByStudentYearFilter()
  // is really the only function you should call from the wizard.
  // You'll need to pass in a student year, which represents the cohort filtered for in the wizard dropdown
  //  i.e. Senior cohort and Super Seniors === 4
  //      Junior cohort === 3
  //      Sophomore cohort === 2
  //      Freshman cohort === 1
  // This service will take care of returning the correct priority groups based
  // where we are in the school year.
  // Step 4 should contain a priority table - one row per priority grouping returned from this function
  // Use the .studentPriorityGroupingValue field on the priority grouping to count the number of students in that group
  //  i.e. all the students where student.creditGapPrioirtyGroup === priorityGroup.studentPriorityGroupingValue
  // Display prioirtyVal and priorityLabel in each row
  // If the wizard is filtered for the Junior, Sophomore, or Freshman cohort
  // there is only one priority grouping so don't display priority value
  public getPriorityGroupsByStudentYearFilter (
    studentYearFilter?: TValidStudentYearFilters,
  ): IPriorityGroupDefinition[] {
    const priorityGroupings = this.priorityGroupings;
    if (studentYearFilter) {
      return _.filter(priorityGroupings, { studentYearFilter });
    } else {
      return priorityGroupings;
    }
  }
}
