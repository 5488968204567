import { UrlMatchResult, UrlSegment } from '@angular/router';

export class SdcProfileMatcher {
  static isValidSdcKey (key): boolean {
    const validSdcKeys = [
      'regents-planning',
      'regents-prep',
      'grad-planning',
      'credit-gaps',
      'attendance',
      'default-sdc',
      'marking-period',
    ];
    return validSdcKeys.includes(key);
  }

  static matchSdcProfileUrl (segments: UrlSegment[]): UrlMatchResult {
    if (segments.length > 0) {
      if (segments.length === 1 && segments[0].path === 'profile') {
        return { consumed: segments, posParams: {} };
      }
      if (segments.length > 1) {
        const [urlSdcKeySegment, urlStudentSegment, urlStudentIdSegment] = segments;
        if (this.isValidSdcKey(urlSdcKeySegment.path) && urlStudentSegment.path === 'student' && urlStudentIdSegment) {
          return { consumed: segments, posParams: { studentId: urlStudentIdSegment } };
        }
      }
    }
    return <UrlMatchResult>(null as any);
  }
}
