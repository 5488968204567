import { Injectable } from '@angular/core';
import { IDistrict } from '../../auth/auth.service';
import { SessionStorageService } from '../web-storage/session-storage/session-storage.service';

@Injectable()
export class UrlPathService {
  constructor (
    private sessionStorageService: SessionStorageService,
  ) { }

  public computeDistrictUrlPath (urlPath: string) {
    const shouldOmitDistrict = !this.isValidDistrictRoute(urlPath);
    if (shouldOmitDistrict) return urlPath;

    const districtId = this.sessionStorageService.getItem('currentDistrict');
    const districts = this.sessionStorageService.getItem('districts');
    const urlDisplayName = this.getDistrictUrlDisplayName(districtId, districts);

    const computedUrl = urlPath[0] === '/' ? `/${urlDisplayName}${urlPath}` : `${urlDisplayName}/${urlPath}`;
    return computedUrl;
  }

  public getDistrictUrlDisplayName (districtId: string, districts: IDistrict[]) {
    const displayName = districts?.find(d => d._id === districtId)?.displayName;
    return displayName ? this.toLowerCaseNoWhitespace(displayName) : '';
  }

  public getDistrictIdFromDistrictUrlDisplayName (districtUrlDisplayName: string, districts: IDistrict[]) {
    const districtId = districts?.find(d => this.toLowerCaseNoWhitespace(d.displayName) === districtUrlDisplayName)?._id;
    return districtId || '';
  }

  private toLowerCaseNoWhitespace (str: string) {
    return str?.toLocaleLowerCase().replace(/\s/g, '');
  }

  private isValidDistrictRoute (urlPath: string) {
    const urlPathNoFirstSlash = urlPath[0] === '/' ? urlPath.replace('/', '') : urlPath;
    const isShelterRoute = urlPathNoFirstSlash.indexOf('shelter') === 0;
    const isNotFoundRoute = urlPathNoFirstSlash.indexOf('not-found') === 0;
    const isHomeRoute = urlPathNoFirstSlash.indexOf('home') === 0;
    const isCallbackRoute = urlPathNoFirstSlash.indexOf('callback') === 0;
    const isDistrictPickerRoute = urlPathNoFirstSlash.indexOf('district-picker') === 0;
    const isLoginRoute = urlPathNoFirstSlash.indexOf('login') === 0;
    const isAdminRoute = urlPathNoFirstSlash.indexOf('admin') === 0;
    if (isShelterRoute || isNotFoundRoute || isHomeRoute || isCallbackRoute || isDistrictPickerRoute || isLoginRoute || isAdminRoute) return false;
    return true;
  }
}
