import { CommonModule } from '@angular/common';
import { NgModule , CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { SpinnerModalComponent } from './spinner-modal.component';
import { SpinnerService } from './spinner-modal.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';


@NgModule({
  imports: [CommonModule, MatProgressSpinnerModule, MatDialogModule],
  declarations: [SpinnerModalComponent],
  exports: [SpinnerModalComponent],
  providers: [SpinnerService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SpinnerModalModule {}
