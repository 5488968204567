import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../../../services/api-service/api-service';

@Injectable()
export class ShelterClusterUserModalsDataService {
  constructor(private apiService: ApiService) {}

  public getShelterPortfolioGroupings({ userId, clusterId }): Observable<any> {
    return this.apiService.getShelterClusterUserShelters({ userId, clusterId });
  }

  public getShelterClusterUserDetail({ userId }): Observable<any> {
    return this.apiService.getShelterClusterUserDetail({ userId });
  }
}
