import { Injectable } from '@angular/core';
import { IRowData } from '../../../shared/models/list-models';
import { find, result, round, orderBy } from 'lodash';
import { IBarGraphData, IStackedBarGraphData, IxAxisRotation } from './../../models/viz-models';
import { ILineGraphData } from '../../components/trends-viz/trends-viz.component';
import { timeParse, timeFormat } from 'd3';
import { staticMonths } from '../../../network/attendance-trends/attendance-trends.models';

@Injectable()
export class VizDataService {
  formatBarGraphData (rowData: IRowData[][], vizGroupingKey: string): IBarGraphData[] {
    const data = [];
    [...rowData].forEach(row => {
      const group = result(find(row, { columnKey: 'STUB' }), 'data');
      const value = result(find(row, { columnKey: vizGroupingKey }), 'data');
      const stringifiedGroupVal: IRowData['meta'] = result(find(row, { columnKey: 'STUB' }), 'meta');
      const groupSortVal = JSON.parse(stringifiedGroupVal).sort;
      data.push({ group, value, groupSortVal });
    });
    return data;
  }

  // Note from CMAC:
  // I talked with Danielle and for now we're going to have the rollup grouping data continue to return a number rather than a percent.
  // That means we do the calculation here. If this changes, we can consolidate this function with the formatStackedBarCountData function below.
  formatStackedBarPercentData (
    rowData: IRowData[][],
    vizStackedBarKeys: string[],
    vizGroupingKey: string,
  ): IStackedBarGraphData[] {
    const data = [];
    rowData.map(row => {
      let stackedDataObj = {};
      const group: string = result(find(row, { columnKey: 'STUB' }), 'data');
      // add vizGroupingKey to use for sort.
      const value: string = result(find(row, { columnKey: vizGroupingKey }), 'data');
      let total: number = result(find(row, { columnKey: 'TOTAL' }), 'data');
      if (!total) total = row[row.length - 1].data;
      const stringifiedGroupVal: IRowData['meta'] = result(find(row, { columnKey: 'STUB' }), 'meta');
      const groupSortVal = JSON.parse(stringifiedGroupVal).sort;
      stackedDataObj = { ...stackedDataObj, group, groupSortVal, [vizGroupingKey]: value };
      vizStackedBarKeys.map(stackedKey => {
        const data: number = result(find(row, { columnKey: stackedKey }), 'data');
        const percent = round((data / total) * 100, 2);
        stackedDataObj = { ...stackedDataObj, ...{ [stackedKey]: percent } };
      });
      data.push(stackedDataObj);
    });
    return data;
  }

  // Note from CMAC:
  // This isn't being used yet but I added it for when the stacked bar count chart is added.
  formatStackedBarCountData (
    rowData: IRowData[][],
    vizStackedBarKeys: string[],
    vizGroupingKey: string,
  ): IStackedBarGraphData[] {
    const data = [];
    rowData.map(row => {
      let stackedDataObj = {};
      const group: string = result(find(row, { columnKey: 'STUB' }), 'data');
      // add vizGroupingKey to use for sort.
      const value: string = result(find(row, { columnKey: vizGroupingKey }), 'data');
      stackedDataObj = { ...stackedDataObj, group, [vizGroupingKey]: value };
      vizStackedBarKeys.map(stackedKey => {
        const count: number = result(find(row, { columnKey: stackedKey }), 'data');
        stackedDataObj = { ...stackedDataObj, ...{ [stackedKey]: count } };
      });
      data.push(stackedDataObj);
    });
    return data;
  }

  formatLineGraphData (vizData): ILineGraphData[] {
    const data = [];
    vizData.forEach(d => {
      const date = timeParse('%Y-%m-%d')(d.subset);
      const dateString = timeFormat('%b %d')(date);
      const tooltipDate = timeFormat('%A %b %d')(date);
      const rank = d.rnk;
      const group = d.currentPeriod;
      const denominator = d.schoolCount || d.studentCount;
      const studentCount = d.studentCount;
      const num = Math.round(d.value * 100);
      // currently getting current (1) and historic (0) data. only want current.
      if (group === '1') data.push({ dateString, value: num, tooltipDate, rank, group, denominator, studentCount });
    });

    return data;
  }

  formatMonthlyLineGraphData (vizData): ILineGraphData[] {
    const data = [];
    vizData.forEach(d => {
      const date = new Date(d.description);
      const dateString = timeFormat('%b')(date);
      const tooltipDate = timeFormat('%B %Y')(date);
      const rank = d.rnk;
      const group = d.currentPeriod;
      const denominator = d.schoolCount || d.studentCount;
      const studentCount = d.studentCount;
      const num = Math.round(d.value * 100);
      data.push({ dateString, value: num, tooltipDate, rank, group, denominator, studentCount });
    });

    return data;
  }

  sortBarGraphData (data: IBarGraphData[], sortDirection: 'asc' | 'desc'): IBarGraphData[] {
    if(sortDirection === 'asc'){
      return data.sort((a, b) => {
        if (typeof b.value === 'string') return -1;
        return a.value - b.value;
      });
    }
    return data.sort((a, b) => {
      if (typeof b.value === 'string') return -1;
      return b.value - a.value;
    });
  }

  sortBarGraphDataByGrouping (data: IBarGraphData[], sortDirection: 'asc' | 'desc'): IBarGraphData[] {
    if(!data.length) return data;
    const { groupSortVal } = data[0];
    return groupSortVal || groupSortVal === 0 ? orderBy(data,'groupSortVal', sortDirection)
    : orderBy(data,'group', sortDirection);
  }

  // maintain same sort as bar graphs. sort greatest to least based on viz grouping key.(cmac)
  sortStackedBarData (data: IStackedBarGraphData[], vizGroupingKey: string, sortDirection: 'asc' | 'desc'): IStackedBarGraphData[] {
    if(sortDirection === 'asc'){
      return data.sort((a, b) => {
        // @ts-ignore
        return a[vizGroupingKey] - b[vizGroupingKey];
      });
    }
    return data.sort((a, b) => {
      // @ts-ignore
      return b[vizGroupingKey] - a[vizGroupingKey];
    });
  }

  sortStackedBarDataByGrouping (data: IStackedBarGraphData[], sortDirection: 'asc' | 'desc'): IStackedBarGraphData[] {
    if(!data.length) return data;
    const { groupSortVal } = data[0];
    return groupSortVal || groupSortVal === 0 ? orderBy(data,'groupSortVal', sortDirection)
    : orderBy(data,'group', sortDirection);
  }

  getLabelRotation (data: IBarGraphData[] | IStackedBarGraphData[], rotateLabels: boolean = true, maxLength: number = 8): IxAxisRotation {
    let rotation;
    const groupCount = data.length;
    const applyRotateLabels = (groupCount > maxLength) && rotateLabels;
    const labelAnchor = applyRotateLabels ? 'start' : 'middle';
    if (groupCount > 100) rotation = 45;
    else rotation = applyRotateLabels ? 15 : 0;
    const axisRotation = { labelAnchor, rotation };
    return axisRotation;
  }

  getStaticMonthValues (): { dateString: string }[] {
    return staticMonths.map(month => {
      return { dateString: month };
    });
  }
}
