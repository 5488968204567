import { Router } from '@angular/router';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { unsubscribeComponent } from '../../helpers/unsubscribe-decorator/unsubscribe-decorators.helper';
import { ModalsService } from '../../modals/modals.service';
import { IStateGoOpts } from '../../services/prev-state/prev-state.service';
import { Auth } from '../../auth/auth.service';

/* istanbul ignore next */
@Component({
  selector: 'exit-view',
  templateUrl: './exit-view.component.html',
  styleUrls: ['./exit-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
@unsubscribeComponent
export class ExitViewComponent {
  @Input() exitState?: IStateGoOpts; // Go back to previous view in SSV
  @Input() warningTitle?: string; // opens modal with warning message if present
  @Input() warningMessage?: string; // opens modal with warning message if present
  @Input() icon?: string = 'close-large-blue'; // which icon is displayed
  @Input() isInverted?: boolean = false; // should the hovering state of the icon be inverted

  constructor (
    private store: Store<any>,
    private modalsService: ModalsService,
    private router: Router,
    private authService: Auth,
  ) {}

  private goHome () {
    this.router.navigate(['home']);
  }

  public goBack (): void {
    if (this.exitState.isExpandEd) {
      this.authService.logout();
    } else if (this.exitState.isCUNY && this.exitState.logout) {
      // Note - this is a special case for CUNY users.
      // when a cuny user is on the student profile, they should go back to the previous url(cuny data grid) on exit.
      // but if they're exiting the data grid, they should log out. we use logout here to differentiate the two cases.
      this.authService.logout();
    } else if (this.warningMessage || this.warningTitle) {
      this.openWarningModal(this.warningTitle, this.warningMessage);
    } else if (this.exitState && this.exitState.url) {
      const { url, relativeTo, queryParams } = this.exitState;
      relativeTo ? this.router.navigate([url], { relativeTo, queryParams }) : this.router.navigate([url], { queryParams });
      if (this.exitState.callbackFunction) this.exitState.callbackFunction();
    } else {
      this.goHome();
    }
  }

  public openWarningModal (title, message): void {
    const data = {
      title,
      message,
      confirmText: 'Yes',
    };
    this.modalsService
      .openConfirmModal(data, 'med')
      .afterClosed()
      .subscribe((confirmed: false | true) => {
        if (confirmed) {
          this.goHome();
        }
      });
  }
}
