import { Component, HostBinding, Input, Output, EventEmitter, SimpleChanges, ViewEncapsulation } from '@angular/core';

/**
 *
 * Use the Note component to highlight a piece of text in a UI.
 *
 * Pass any HTML into the note as you would a `<span>` element
 */
@Component({
  selector: 'nv-note',
  templateUrl: './nv-note.component.html',
  styleUrls: ['./nv-note.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NvNoteComponent {
  /**
   *
   * A small note is inline—typically for one sentence.
   *
   * A large note spans the full width (`block`)—typically for more than on sentence.
   *
   */
  @Input() size: 'small' | 'large' = 'small';

  /**
   *
   * Info notes are blue.
   * Warning notes are yellow.
   * Danger notes are red.
   *
   */
  @Input() priority: 'warning' | 'danger' | 'success' | 'info' | 'message' | 'error' = 'info';

  @Input() hasAction: boolean = false;

  @Input() actionText: string = 'Action Button';

  @Input() hasIcon: boolean = true;

  @Output() clickButton: EventEmitter<any> = new EventEmitter<any>();

  @HostBinding('class') classString = '';

  get iconName () {
    switch (this.priority) {
      case ('warning'):
        if (this.size === 'large') {
          return 'warning-large';
        } else {
          return 'warning-small';
        }
      case ('danger'):
        if (this.size === 'large') {
          return 'error-large';
        } else {
          return 'error-small';
        }
      case ('info'):
        if (this.size === 'large') {
          return 'info-large-default';
        } else {
          return 'info-small-default';
        }
      case ('success'):
        if (this.size === 'small') {
          return 'check-circle-small-green';
        } else {
          return 'check-circle-small-green';
        }
      case ('error'):
        if (this.size === 'small') {
          return 'not-available-small';
        } else {
          return 'not-available-large';
        }
      case ('message'):
        return null;
      default:
        return 'info-large-default';
    }
  }

  handleClick (event?: Event) {
    if (this.hasAction) {
      this.clickButton.emit(true);
    }
  }

  ngOnChanges (changes: SimpleChanges): void {
    if (changes.size || changes.priority) {
      this.classString = `${this.size} ${this.priority}`;
    }
  }
}
