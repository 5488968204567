import { ModalsService } from './../../../../../../shared/modals/modals.service';
import { map } from 'lodash';
import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'print-regents-step',
  templateUrl: './print-regents-step.component.html',
  styleUrls: ['../shared-step-styles.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PrintRegentsStep {
  @Input() filteredStudents;
  public stepLabel: string = 'Print student-friendly exam and prep schedules';
  public filteredStudentsIds;
  public count;

  constructor (
    private modalsService: ModalsService,
  ) { }

  ngOnInit () {
    this.filteredStudentsIds = map(this.filteredStudents, '_id');
    this.count = this.filteredStudentsIds.length;
  }

  ngOnChanges ({ filteredStudents }) {
    if (filteredStudents && !filteredStudents.isFirstChange()) {
      this.ngOnInit();
    }
  }

  openStudentReportModal () {
    this.modalsService.openStudentReportModal({ students: this.filteredStudents, defaultReport: 'regentsSched' } as any);
  };
}
