import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { cloneDeep, reduce, set } from 'lodash';
import { FormValidatorsService } from './../../services/form-validators/form-validators.service';
import { IFlag } from './../../typings/interfaces/flags.interface';
import { IModalsConfig, IModalSelectsConfig } from './../modals.config.interface';
import { FLAG_LOG_ACTION_MODAL_CONFIG } from './flag-log-action-modal.config';

interface IFormGroupConfig {
  checkboxes: FormGroup;
  textarea: string;
}

export interface IModalFormGroup extends FormGroup {
  controls: {
    checkboxes: FormGroup;
    textarea: FormControl;
  };
}

interface ICheckboxChoices {
  [key: string]: boolean;
}

export interface IFlagLogActionModalResult {
  otherActions?: IFlag['otherActions'];
  note?: IFlag['note'];
}

@Component({
  selector: 'flag-log-action-modal',
  templateUrl: './flag-log-action-modal.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class FlagLogActionModalComponent implements OnInit {
  public form: FormGroup;

  // Modal Configurations
  readonly checkboxes = FLAG_LOG_ACTION_MODAL_CONFIG.checkboxes;
  readonly textarea = FLAG_LOG_ACTION_MODAL_CONFIG.textarea;
  readonly buttons = FLAG_LOG_ACTION_MODAL_CONFIG.buttons;

  constructor (
    public dialogRef: MatDialogRef<FlagLogActionModalComponent>,
    private formBuilder: FormBuilder,
    private formValidatorsService: FormValidatorsService,
    @Inject(MAT_DIALOG_DATA) public data: IFlag,
  ) {}

  public ngOnInit (): void {
    // clone data to avoid mutating the passed in data. (CM)
    this.data = cloneDeep(this.data);
    this.form = this.buildForm();
  }

  private buildForm (): FormGroup {
    const checkboxChoices = this.createCheckboxChoices(this.checkboxes.choices);
    const formGroupConfig: IFormGroupConfig = {
      checkboxes: this.formBuilder.group(checkboxChoices),
      textarea: undefined,
    };
    const form = this.formBuilder.group(formGroupConfig, this._formValidator);

    return form;
  }

  // made public to make easier to test (CM).
  public get _formValidator () {
    const { atLeastOneFormControlIsTrueValidator } = this.formValidatorsService;

    return {
      // For `formBuilder.group` validator methods,
      // returning `null` signifies that there are no validation errors (CM).
      validator (formGroup: IModalFormGroup): ValidationErrors | null {
        const { checkboxes } = formGroup.controls;
        const validationErrors = atLeastOneFormControlIsTrueValidator(checkboxes);

        return validationErrors;
      },
    };
  }

  public onCancel (): void {
    this.dialogRef.close();
  }

  public onSubmit (form: FormGroup): void {
    const patch = this._processForm(form);

    this.dialogRef.close(patch);
  }

  // made public to make easier to test (CM).
  public _processForm (form: FormGroup): IFlagLogActionModalResult {
    const value: { checkboxes: ICheckboxChoices; textarea: string } = form.value;
    const { checkboxes, textarea } = value;
    const patch: IFlagLogActionModalResult = {};

    if (checkboxes) {
      const patchPath = 'otherActions';
      const processedCheckboxes = this.processOtherActionsFromCheckboxes(checkboxes);

      set(patch, patchPath, processedCheckboxes);
    }

    if (textarea) {
      const patchPath = 'note';

      set(patch, patchPath, textarea);
    }

    return patch;
  }

  private processOtherActionsFromCheckboxes (checkboxChoices: ICheckboxChoices): IFlag['otherActions'] {
    const otherActions = reduce(
      checkboxChoices,
      (acc, value, key) => {
        if (value) acc.push(key);

        return acc;
      },
      [],
    );

    return otherActions;
  }

  /**
   * Takes Modal checkboxes config and returns a key/value object, where the key is the text to display
   * and value is a boolean that determines whether a box is checked or not on modal init (CM).
   * @param checkboxes {IModalsConfig['checkboxes']['choices']}
   * @returns ICheckboxChoices
   */
  private createCheckboxChoices (checkboxes: IModalsConfig['checkboxes']['choices']) {
    const checkboxChoices = reduce<IModalSelectsConfig, ICheckboxChoices>(
      this.checkboxes.choices,
      (acc: ICheckboxChoices, choice) => {
        // set all checkboxes to false ("unchecked"). (CM)
        acc[choice.value] = false;

        return acc;
      },
      {},
    );

    return checkboxChoices;
  }
}
