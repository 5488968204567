<div class="button-container">
  <nv-button type="secondary" (clickButton)="onBack()">Back</nv-button>
  <nv-button (clickButton)="onNext()">Next</nv-button>
</div>
<nv-form-field label="Columns to include">
  <nv-textbox
    [textControl]="this.form.controls.colSearch"
    placeholder="Find a column to add..."
    [autocompleteOptions]="ACOptions"
    (selectOption)="columnSelected($event)"
    [hasClearBtn]="true"
    (clearValue)="onClear()"
  >
  </nv-textbox>
</nv-form-field>
<nv-form-field label="Selected columns ({{selectedCols.length}})">
  <div class="selected-cols" #rowContainer (scroll)="onTableScroll()">
    <div class="row body-row" *ngFor="let col of selectedCols">
      <div class="col-name">{{ col.human }}</div>
      <div class="nv-icon-wrapper" *ngIf="col.canDelete; else required">
        <nv-icon-button (clickButton)="columnDeleted(col.human)" name="trash-small-blue"></nv-icon-button>
      </div>
      <ng-template #required><div class="required">Required</div></ng-template>
    </div>
  </div>
</nv-form-field>
