import { HUMAN_TO_ROUTE, OtherToolsSideNavConfig } from './other-tools.constant';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { USER_ROLE_PERMISSIONS_FOR_GUARDS } from '../../../routing/guards/user-role-permissions-for-route-guards.constant';
import { RouteGuard } from '../../../routing/guards/route-guard/route-guard';
import { SchoolTypeGuard } from '../../../routing/guards/school-type-guard/school-type-guard';
import { OtherToolsLayoutComponent } from '../../layouts/other-tools-layout/other-tools-layout.component';
import { OtherToolsFirstDefaultRouteGuard } from 'Src/ng2/routing/guards/other-tools-first-default-route-guard/other-tools-first-default-route-guard';
import { DistrictGuard } from 'Src/ng2/routing/guards/district-guard/district-guard';
import { SyncSheetGuard } from 'Src/ng2/routing/guards/sync-sheet-guard/sync-sheet-guard';
import { IRouteConfigsOpts } from 'Src/ng2/routing/route.config';

export const otherToolsRoutes: IRouteConfigsOpts[] = [
  {
    path: '',
    pathMatch: 'full',
    component: OtherToolsLayoutComponent,
    canActivate: [OtherToolsFirstDefaultRouteGuard], // handles redirect to the first default route
  } as IRouteConfigsOpts,
  // GOOGLE SHEETS
  {
    path: HUMAN_TO_ROUTE['Synced Sheets'],
    partnerType: 'school',
    authenticationRequired: true,
    rolePermissions: USER_ROLE_PERMISSIONS_FOR_GUARDS[`school/:schoolId/other-tools/${HUMAN_TO_ROUTE['Synced Sheets']}`],
    canActivate: [RouteGuard, SchoolTypeGuard, DistrictGuard, SyncSheetGuard],
    data: {
      schoolTypes: OtherToolsSideNavConfig.sheets.SYNCED_SHEETS.schoolTypes,
      districts: OtherToolsSideNavConfig.sheets.SYNCED_SHEETS.districts,
    },
    loadChildren: () => import('./other-tools.module').then(m => m.OtherToolsModule),
  },
  {
    path: HUMAN_TO_ROUTE['Attendance Heat Map'],
    partnerType: 'school',
    authenticationRequired: true,
    rolePermissions: USER_ROLE_PERMISSIONS_FOR_GUARDS[`school/:schoolId/other-tools/${HUMAN_TO_ROUTE['Attendance Heat Map']}`],
    canActivate: [RouteGuard, SchoolTypeGuard, DistrictGuard],
    data: {
      schoolTypes: OtherToolsSideNavConfig.sheets.ATTENDANCE_HEAT_MAP.schoolTypes,
      districts: OtherToolsSideNavConfig.sheets.ATTENDANCE_HEAT_MAP.districts,
    },
    loadChildren: () => import('./other-tools.module').then(m => m.OtherToolsModule),
  },
  {
    path: HUMAN_TO_ROUTE['Mock Regents'],
    partnerType: 'school',
    authenticationRequired: true,
    rolePermissions: USER_ROLE_PERMISSIONS_FOR_GUARDS[`school/:schoolId/other-tools/${HUMAN_TO_ROUTE['Mock Regents']}`],
    canActivate: [RouteGuard, SchoolTypeGuard, DistrictGuard],
    data: {
      schoolTypes: OtherToolsSideNavConfig.sheets.MOCK_REGENTS.schoolTypes,
      districts: OtherToolsSideNavConfig.sheets.MOCK_REGENTS.districts,
    },
    loadChildren: () => import('./other-tools.module').then(m => m.OtherToolsModule),
  },
  {
    path: HUMAN_TO_ROUTE['Regents Appeals'],
    partnerType: 'school',
    authenticationRequired: true,
    rolePermissions: USER_ROLE_PERMISSIONS_FOR_GUARDS[`school/:schoolId/other-tools/${HUMAN_TO_ROUTE['Regents Appeals']}`],
    canActivate: [RouteGuard, SchoolTypeGuard, DistrictGuard],
    data: {
      schoolTypes: OtherToolsSideNavConfig.sheets.REGENTS_APPEALS.schoolTypes,
      districts: OtherToolsSideNavConfig.sheets.REGENTS_APPEALS.districts,
    },
    loadChildren: () => import('./other-tools.module').then(m => m.OtherToolsModule),
  },
  {
    path: HUMAN_TO_ROUTE['Regents Results'],
    partnerType: 'school',
    authenticationRequired: true,
    rolePermissions: USER_ROLE_PERMISSIONS_FOR_GUARDS[`school/:schoolId/other-tools/${HUMAN_TO_ROUTE['Regents Results']}`],
    canActivate: [RouteGuard, SchoolTypeGuard, DistrictGuard],
    data: {
      schoolTypes: OtherToolsSideNavConfig.sheets.REGENTS_RESULTS.schoolTypes,
      districts: OtherToolsSideNavConfig.sheets.REGENTS_RESULTS.districts,
    },
    loadChildren: () => import('./other-tools.module').then(m => m.OtherToolsModule),
  },
  {
    path: HUMAN_TO_ROUTE['Marking Period'],
    partnerType: 'school',
    authenticationRequired: true,
    rolePermissions: USER_ROLE_PERMISSIONS_FOR_GUARDS[`school/:schoolId/other-tools/${HUMAN_TO_ROUTE['Marking Period']}`],
    canActivate: [RouteGuard, SchoolTypeGuard, DistrictGuard],
    data: {
      schoolTypes: OtherToolsSideNavConfig.sheets.MARKING_PERIOD.schoolTypes,
      districts: OtherToolsSideNavConfig.sheets.MARKING_PERIOD.districts,
    },
    loadChildren: () => import('./other-tools.module').then(m => m.OtherToolsModule),
  },
  {
    path: HUMAN_TO_ROUTE['Remote Learning'],
    partnerType: 'school',
    authenticationRequired: true,
    rolePermissions: USER_ROLE_PERMISSIONS_FOR_GUARDS[`school/:schoolId/other-tools/${HUMAN_TO_ROUTE['Remote Learning']}`],
    canActivate: [RouteGuard, SchoolTypeGuard, DistrictGuard],
    data: {
      schoolTypes: OtherToolsSideNavConfig.sheets.REMOTE_LEARNING.schoolTypes,
      districts: OtherToolsSideNavConfig.sheets.REMOTE_LEARNING.districts,
    },
    loadChildren: () => import('./other-tools.module').then(m => m.OtherToolsModule),
  },
  {
    path: HUMAN_TO_ROUTE['SPELL Management'],
    partnerType: 'school',
    authenticationRequired: true,
    rolePermissions: USER_ROLE_PERMISSIONS_FOR_GUARDS[`school/:schoolId/other-tools/${HUMAN_TO_ROUTE['SPELL Management']}`],
    canActivate: [RouteGuard, SchoolTypeGuard, DistrictGuard],
    data: {
      schoolTypes: OtherToolsSideNavConfig.sheets.SPELL_MANAGEMENT.schoolTypes,
      districts: OtherToolsSideNavConfig.sheets.SPELL_MANAGEMENT.districts,
    },
    loadChildren: () => import('./other-tools.module').then(m => m.OtherToolsModule),
  },
  // LEGACY TOOLS
  {
    path: HUMAN_TO_ROUTE['Grad Plans'],
    loadChildren: () => import('../../sdc/grad-planning/grad-planning-container.module').then(m => m.GradPlanningContainerModule),
  } as IRouteConfigsOpts,
  {
    path: HUMAN_TO_ROUTE['Next Term Planning'],
    loadChildren: () => import('../../sdc/next-term-planning/next-term-planning-container.module').then(m => m.NextTermPlanningContainerModule),
  } as IRouteConfigsOpts,
  {
    path: HUMAN_TO_ROUTE['Regents Planning'],
    loadChildren: () => import('../../sdc/regents-planning/regents-planning-container.module').then(m => m.RegentsPlanningContainerModule),
  } as IRouteConfigsOpts,
  {
    path: HUMAN_TO_ROUTE['Regents Prep'],
    loadChildren: () => import('../../sdc/regents-prep/regents-prep-container.module').then(m => m.RegentsPrepContainerModule),
  } as IRouteConfigsOpts,
  {
    path: HUMAN_TO_ROUTE['Credit Gaps'],
    loadChildren: () => import('../../sdc/credit-gaps/credit-gaps-container.module').then(m => m.CreditGapsContainerModule),
  } as IRouteConfigsOpts,
  {
    path: HUMAN_TO_ROUTE['Formative Assessments'],
    loadChildren: () => import('../../lists/formative-assessments-list-v2/formative-assessments-list-v2.module').then(m => m.FormativeAssessmentsListV2Module),
  } as IRouteConfigsOpts,
];

@NgModule({
  imports: [RouterModule.forChild(otherToolsRoutes as Routes)],
  exports: [RouterModule],
})
export class OtherToolsRoutingModule {}
